import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { RPCUser } from "../../common/fetchCurrentUser";
import supabaseClient from "../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import { Sentence, Word } from "../../types/supabase";
import MenuLayout from "../Layout/MenuLayout";
import VStack from "../Unknown/VStack";
import { allDifferenceWords } from "../Difference/Page";
import splitMultipleAndTrim from "../../common/splitMultipleAndTrim";
import useCreateAudio from "../../common/useCreateAudio";
import getSentenceWords from "../../common/getSentenceWords";
import filterSkipedWords from "../../common/filterSkipedWords";
import getSkipWords from "../../common/getSkipWords";
import NewLessonWords from "../CreatePersonalLesson/NewLessonWords";
import removePunctuationFromString from "../../common/removePunctuationFromString";
import CopyToClipboard from "react-copy-to-clipboard";
import checkNewWords from "../../common/checkNewWords";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import createNewNotExistingSentenceFromRowSentences from "../CreatePersonalLesson/createNewNotExistingSentenceFromRowSentences";
import getExistingAndNotExistingSentences from "../CreatePersonalLesson/getExistingAndNotExistingSentences";
import AdminCreateSentenceFields from "../AdminCreateSentenceFields";
import upperCaseWords from "../../common/uppercaseWords";

const courseWords = {
  1: "I, speak, English, you, we, they, he, speaks, she, know, knows, do, does, don't, doesn't, study, in, school, studies, and, work, works, every, day, it, learn, learns, very, well, go, to, today",
};

const skipWords = ["/", "not"];

const AdminCreateBanglaGrammarCourse: React.FC = () => {
  const [text, setText] = useState("");
  const [topic, setTopic] = useState("");
  const [setnences, setSetnences] = useState<string[]>([]);
  const [newWords, setNewWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [words, setWords] = useState<string[]>([]);
  const { createAudio } = useCreateAudio();
  const [knowWordsInTheTopic, setKnowWordsInTheTopic] = useState<string[]>([]);
  const [newWordsInTheTopic, setNewWordsInTheTopic] = useState<string[]>([]);
  const [enValue, setEnValue] = useState("");
  const [module, setModule] = useState(0);
  const [uaValue, setUaValue] = useState("");
  const [meaningValue, setMeaningValue] = useState("");

  useEffect(() => {
    const allWords = getSentenceWords(enValue);
    console.log("🚀 ~ allWords:", allWords);
    const uniqueWords = Array.from(new Set(allWords)).filter(Boolean);
    console.log("🚀 ~ uniqueWords:", uniqueWords);
    setWords(uniqueWords);
  }, [enValue]);

  const authUser = useLessonsStore((state) => state.authUser);

  const getTextWords = (text: string) => {
    return Array.from(
      new Set(
        text
          .split(/[\s\n]+/)
          .map((word) => {
            if (upperCaseWords.includes(word)) {
              return removePunctuationFromString(word);
            }
            return removePunctuationFromString(word).toLowerCase();
          })
          .filter(Boolean),
      ),
    );
  };

  return (
    <MenuLayout>
      <Box p={5} sx={{ backgroundColor: "white" }}>
        <Grid container>
          <Grid container>
            {/* {!!words.length && (
            <Grid item xs={12}>
              <CopyToClipboard
                text={`
                    Here are words: ${words.join(", ")}

                    1. Create some simple text of A1-A2 English level to train these word in speaking. It is for English student.
                    Use these words as much as possible. It will be very good if you give me a topic text to cover all these words. Also use other words as much as possible.

                    2. Maybe there is some verse or poem, maybe for children to cover these words as much as possible.

                    So I need the topic text and verse (or poem) if it exists.
                  `}
                onCopy={() => {}}
              >
                <Button variant="outlined">Copy for Gemini</Button>
              </CopyToClipboard>
              <CopyToClipboard text={words.join(", ")} onCopy={() => {}}>
                <Button variant="outlined">Copy string words</Button>
              </CopyToClipboard>
              <CopyToClipboard text={JSON.stringify(words, null, 2)} onCopy={() => {}}>
                <Button variant="outlined">Copy json words</Button>
              </CopyToClipboard>
            </Grid>
          )} */}
            <Grid item xs={12}>
              Words count: {words.length}
            </Grid>
            {/* <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="info"
              onClick={async () => {
                setIsLoading(true);
                const { data } = await supabaseClient.from("words").select("name").is("is_upper", true);

                console.log("🚀 ~ data:", data);
                if (data) {
                  navigator.clipboard.writeText(JSON.stringify(data.map((i) => i.name)));
                }

                setNewWords(newWords);
                setIsLoading(false);
              }}
            >
              Copy uppercase words
            </LoadingButton> */}
            {/* <br /> */}
            {/* <Typography>Copy all course words for ai new word ask</Typography>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="info"
              onClick={async () => {
                setIsLoading(true);
                const { data } = await supabaseClient.from("user-words").select("word").eq("user", "7dd6fd1b-a333-4fbf-98a7-245491416070");
                console.log("🚀 ~ data:", data);

                if (data) {
                  navigator.clipboard.writeText(`
                  
                  
                  Give me 50 simple sentences with main words: [what, who, love]
                  
                  For building sentences use only main and next words:
                  ${JSON.stringify(data.map((i) => i.word))}

                  Important: each sentence must contain at leas on word from the main words list!!!
                  `);
                }

                setNewWords(newWords);
                setIsLoading(false);
              }}
            >
              Copy
            </LoadingButton> */}
            {/* <br /> */}
            <Grid item xs={12}>
              <Typography>Copy all course words</Typography>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="info"
                onClick={async () => {
                  setIsLoading(true);
                  const { data } = await supabaseClient.from("user-words").select("word").eq("user", "7dd6fd1b-a333-4fbf-98a7-245491416070");
                  console.log("🚀 ~ data:", data);

                  if (data) {
                    navigator.clipboard.writeText(data.map((i) => i.word).join(", "));
                  }

                  setNewWords(newWords);
                  setIsLoading(false);
                }}
              >
                Copy
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              {/* <br />
              <Typography>Copy new module words</Typography>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="info"
                onClick={async () => {
                  setIsLoading(true);
                  const { data } = await supabaseClient.from("grammar_course").select("words");
                  const allModulesWords = data!.map((m) => m.words).flat();
                  console.log("🚀 ~ allModulesWords:", allModulesWords);

                  const newWords = words.filter((w) => !allModulesWords.includes(w) && !skipWords.includes(w));
                  console.log("🚀 ~ newWords:", newWords);

                  navigator.clipboard.writeText(newWords.join(", "));

                  setIsLoading(false);
                }}
              >
                Copy
              </LoadingButton> */}
            </Grid>
            {/* <br /> */}
            {/* <Typography>If need to add additional sentences</Typography> */}
            {/* <LoadingButton
              loading={isLoading}
              variant="contained"
              color="info"
              onClick={async () => {
                setIsLoading(true);
                const { data: allWords } = await supabaseClient.from("user-words").select("word").eq("user", "7dd6fd1b-a333-4fbf-98a7-245491416070");
                const { data: moduleWords } = await supabaseClient.from("grammar_course").select("words");
                // console.log("🚀 ~ data:", data);

                if (allWords && moduleWords) {
                  const rowAllWords = allWords.map((w) => w.word);
                  console.log("🚀 ~ rowAllWords:", rowAllWords);
                  const rowModuleWords = moduleWords.map((i) => i.words).flat();
                  const words = rowModuleWords.filter((w) => !rowAllWords.includes(w));
                  console.log("🚀 ~ words:", words);
                  // navigator.clipboard.writeText(`${JSON.stringify()}`);
                }
                setNewWords(newWords);
                setIsLoading(false);
              }}
            >
              Copy current module words
            </LoadingButton> */}
            <br />
            {!!newWords.length && <CopyNewWordsForGemini words={newWords} />}
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="info"
              onClick={async () => {
                setIsLoading(true);
                const { words: returnWords, newWords } = await checkNewWords(words);
                console.log("🚀 ~ newWords:", newWords);
                console.log("🚀 ~ returnWords:", returnWords);

                setNewWords(newWords);
                setIsLoading(false);
              }}
            >
              Check new words
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              variant="contained"
              onClick={async () => {
                setIsLoading(true);
                console.log(words);
                await Promise.all(
                  words.map((word) =>
                    supabaseClient.from("user-words").insert({
                      id: `7dd6fd1b-a333-4fbf-98a7-245491416070-${word}`,
                      user: "7dd6fd1b-a333-4fbf-98a7-245491416070",
                      word,
                    }),
                  ),
                );

                setIsLoading(false);
              }}
            >
              Upload new word to grammar user
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              variant="contained"
              onClick={async () => {
                try {
                  const userUuid = authUser?.uuid;

                  if (!userUuid) return;

                  setIsLoading(true);

                  const rowEnSentences = enValue
                    .split("\n")
                    .map((s) => s.trim())
                    .filter(Boolean);

                  const { notExistingSentences } = await getExistingAndNotExistingSentences({
                    currentUserUuid: userUuid,
                    textFieldMultipleString: enValue,
                    textFiledTranslationMultipleString: uaValue,
                    meanings: meaningValue,
                  });

                  console.log("notExistingSentences", notExistingSentences);

                  const sentencesToInsert = await createNewNotExistingSentenceFromRowSentences({
                    notExistingSentences: notExistingSentences,
                    createAudio,
                  });

                  console.log("sentencesToInsert", sentencesToInsert);

                  // try {
                  // } catch (err) {
                  //   console.log("🚀 ~ err:", err);
                  // }
                  const uploadNewSetnencesResult = await Promise.all(sentencesToInsert.map((s) => supabaseClient.from("sentences").insert(s)));
                  console.log("🚀 ~ uploadNewSetnencesResult:", uploadNewSetnencesResult);
                  // }

                  await Promise.all(
                    rowEnSentences.map((sentence, index) =>
                      supabaseClient.from("grammar_course_sentences").insert({
                        id: sentence,
                        sentence,
                        module: module,
                        order: index,
                      }),
                    ),
                  );

                  await supabaseClient
                    .from("grammar_course")
                    .insert({
                      module: module,
                      sentence_count: rowEnSentences.length,
                      title: `Урок ${module}`,
                    })
                    .throwOnError();
                } catch (err) {
                  console.log("🚀 ~ err:", err);
                }

                setIsLoading(false);
              }}
            >
              Upload sentences
            </LoadingButton>
          </Grid>
          <Grid item xs={12} gap={4} display="flex" flexWrap="wrap">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(`
Translate the following text to Ukrainian language. The response is a list of Ukrainian language strings without quotes at the beginning and at the end. Each translated sentence starts on the new line.
${enValue}`);
              }}
            >
              Copy for gemini translation
            </Button>
            <TextField
              color="primary"
              value={module}
              multiline={true}
              placeholder="Module"
              onChange={(event) => {
                const module = event.target.value;

                setModule(+module);
              }}
              type="text"
              variant="outlined"
            />
            <AdminCreateSentenceFields
              columnSize={400}
              onUpdateValues={(en, ua, meaning) => {
                setEnValue(en);
                setUaValue(ua);
                setMeaningValue(meaning);
              }}
            />
            {/* <TextField
              fullWidth
              color="primary"
              value={text}
              multiline={true}
              placeholder="English sentences"
              onChange={(event) => {
                const text = event.target.value;

                const sentences = text
                  .split(/[\n]+/)
                  .map((sentence) => sentence.trim())
                  .filter(Boolean);

                setSetnences(sentences);

                const words = getTextWords(text);

                setWords(Array.from(new Set(words)));
                setText(text);
              }}
              type="text"
              variant="outlined"
            /> */}
          </Grid>
          {/* <Grid item xs={12}>
          <TextField
            fullWidth
            color="primary"
            value={topic}
            multiline={true}
            placeholder="Topic"
            onChange={(event) => {
              setTopic(event.target.value);

              const cWords = Object.values(courseWords)
                .map((words) => words.split(", "))
                .flat();
              console.log("🚀 ~ cWords:", cWords);
              const topicWords = getTextWords(topic);
              console.log("🚀 ~ topicWords:", topicWords);
              setKnowWordsInTheTopic(topicWords.filter((w) => cWords.includes(w)));
              setNewWordsInTheTopic(topicWords.filter((w) => !cWords.includes(w)));
            }}
            type="text"
            variant="outlined"
          />
        </Grid>
        {!!knowWordsInTheTopic.length && (
          <Grid item xs={12}>
            Topic known words count: {knowWordsInTheTopic.length}
          </Grid>
        )}
        {!!newWordsInTheTopic.length && (
          <Grid item xs={12}>
            Topic new words count: {newWordsInTheTopic.length}
          </Grid>
        )}
        {!!newWordsInTheTopic.length && (
          <Grid item xs={12}>
            New words:{" "}
            {newWordsInTheTopic.map((w) => (
              <Typography p={1}>{w}</Typography>
            ))}
          </Grid>
        )} */}
        </Grid>
      </Box>
    </MenuLayout>
  );
};

export default AdminCreateBanglaGrammarCourse;
