import { Word } from "../../../types/supabase";
import WordExampleSentence from "../../Word/WordTranslationSection/WordExampleSentence";
import Box from "../Box";
import VStack from "../VStack";

interface WordExampleProps {
  currentWord: Word;
  exists?: boolean;
  prev?: boolean;
  currentRowWord: string;
}

const WordExample: React.FC<WordExampleProps> = ({
  currentWord,
  currentRowWord,
  exists,
  prev,
}) => {
  return (
    <Box
      maxWidth={600}
      pb={10}
      sx={{
        backgroundColor:
          exists && !prev ? "#ffcfcf" : prev ? "#94ff94" : "transparent",
      }}
    >
      <hr />
      <VStack alignItems="flex-start" px={5}>
        {currentWord.examples.map(
          (example: { en: string; ua: string }, index: number) => (
            <WordExampleSentence
              key={index}
              en={example.en}
              ua={example.ua}
              highlight={currentRowWord}
            />
          ),
        )}
      </VStack>
    </Box>
  );
};

export default WordExample;
