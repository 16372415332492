import { Box, CircularProgress } from "@mui/material";

const FullScreenLoader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      zIndex={99999}
      width="100%"
      height="100%"
      top={0}
      left={0}
      sx={{
        backgroundColor: "#fff",
        opacity: 0.9,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default FullScreenLoader;
