import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { adminUserId } from "../../common/constants";
import { RPCUser } from "../../common/fetchCurrentUser";
import { useLessonsStore } from "../../store/lessonsStore";

const AdminUsers: React.FC<{
  users: RPCUser[];
  onChange: (user: RPCUser) => void;
}> = ({ users, onChange }) => {
  const authUserId = useLessonsStore((state) => state.authUser?.id);
  const [currentUser, setCurrentUser] = useState<RPCUser | null>(null);

  if (authUserId !== adminUserId) return null;

  return (
    <Select
      value={currentUser?.id || ""}
      variant="standard"
      style={{
        height: "16px",
        fontSize: "12px",
        minHeight: 0,
        minWidth: 200,
      }}
      onChange={(value) => {
        const newUser = users.find((u) => u.id === value.target.value)!;
        setCurrentUser(newUser);
        onChange(newUser);
      }}
    >
      {users.map((w, index) => (
        <MenuItem key={w.id} value={w.id}>
          {w.id}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AdminUsers;
