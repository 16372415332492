const translations = {
  "Молочні продукти - сироватка кисла": "Sour whey",
  "Молоко - людське": "Human milk",
  "Мед - бджолиний": "Honey",
  "Молоко - овече": "Sheep milk",
  "Молоко коров'яче - знежирене": "Skimmed cow's milk",
  "Молоко коров'яче - ціле": "Whole cow's milk",
  "Молочні продукти - йогурт цілий": "Whole milk yogurt",
  "Яйце - перепелине, сире": "Raw quail egg",
  "Сир - моцарела": "Mozzarella cheese",
  "Сир - чеддер": "Cheddar cheese",
  "Молоко коров'яче - згущ. зваре": "Boiled condensed cow's milk",
  "Молочні продукти - сироватка солодкая": "Sweet whey",
  "Молочні продукти - масло": "Butter",
  "Колбаса доктора - куряча+індича+свиняча": "Doctor's sausage - chicken+turkey+pork",
  "Корова, м'ясо (туш.) - лопатка": "Beef (carcass) - shoulder",
  "Колбаса доктора - свиняча+індича+коров'яча": "Doctor's sausage - pork+turkey+beef",
  "Яйце - жовток": "Egg yolk",
  "Сьомга - атлантична, маринована": "Marinated Atlantic salmon",
  "Сир - фета (овечий або козий)": "Feta cheese (sheep or goat)",
  "Корова, органи (варені) - легені": "Boiled beef lungs",
  "Молочні продукти - вершки легкі": "Light cream",
  "Зелений горошок - морожений": "Frozen green peas",
  "Корова, органи (варені) - язик": "Boiled beef tongue",
  "Корова, органи (варені) - серце": "Boiled beef heart",
  "Корова, м'ясо (туш.) - ребро": "Beef (carcass) - ribs",
  "Сир - швейцарський": "Swiss cheese",
  "Сир - вершковий": "Cream cheese",
  "Корова, органи (варені) - нирки": "Boiled beef kidneys",
  "Свинина - окорок, солоний": "Salted pork shoulder",
  "Сухий горох - сирий": "Raw dry peas",
  "Пасифлора (пурпурна) - свіжовичавлений сік": "Freshly squeezed purple passion fruit juice",
  "Молоко коров'яче - згущене": "Condensed cow's milk",
  "Салямі - свиняча": "Pork salami",
  "Молочні продукти - вершки важкі": "Heavy cream",
  "Сир - блакитний (з пліснявою)": "Blue cheese (with mold)",
  "Корова, органи (варені) - печінка": "Boiled beef liver",
  "Сухий горох - варений": "Boiled dry peas",
  "Корова, м'ясо (туш.) - поперекова частина": "Beef (carcass) - loin",
  "Лосось - кéta, гриль": "Grilled keta salmon",
  "Тунець - смугастий, гриль": "Grilled striped tuna",
  "Соєві вироби - місо": "Miso",
  "Соєві вироби - темпе": "Tempeh",
  "Фасоль - біла, сира": "Raw white beans",
  "Маш (боби мунг) - пророщений": "Sprouted mung beans",
  "Цукор - білий": "White sugar",
  "Апельсин - консервований сік без шкірки": "Canned orange juice without peel",
  "Колбаса доктора - коров'яча+свиняча": "Doctor's sausage - beef+pork",
  "Свинина - лопатка, туш. ": "Pork shoulder, carcass",
  "Сьомга - атлантична, гриль": "Grilled Atlantic salmon",
  "Соя - суха, сира": "Raw dry soybeans",
  "Соєві вироби - протеїн (SUPRO)": "Soy protein (SUPRO)",
  "Соєві вироби - наповнювач": "Soy filler",
  "Соєва мука - низької жирності": "Low-fat soy flour",
  "Тунець - жовтий, гриль": "Grilled yellowfin tuna",
  "Соєвий сир (тофу) - солоний, ферментований": "Salted, fermented tofu",
  "Фасоль - жовта, варена": "Boiled yellow beans",
  "Ну́т (турецький горох) - варений": "Boiled chickpeas",
  "Апельсин - цедра": "Orange zest",
  "Апельсин - сирий": "Raw orange",
  "Яблуко - сире без кісточок": "Raw apple without seeds",
  "Грейпфрут - сирий": "Raw grapefruit",
  "Виноград - консервований сік": "Canned grape juice",
  "Зелений горошок - консервований без солі": "Canned green peas without salt",
  "Індичка - м'ясо+шкіра, смажена": "Fried turkey meat+skin",
  "Салямі - коров'яча+свиняча": "Salami - beef+pork",
  "Молоко коров'яче - сухе": "Dried cow's milk",
  "Курятина - м'ясо+шкіра, смажена": "Fried chicken meat+skin",
  "Соя - зелена, сира": "Raw green soybeans",
  "Лосось - сьомга, консервований в олії": "Canned salmon in oil",
  "Соя - зелена, варена": "Boiled green soybeans",
  "Соєві вироби - сосиски": "Soy sausages",
  "Яйце - сире": "Raw egg",
  "Соєвий сир (тофу) - м'який (з сульфатом кальцію та хлоридом магнію)": "Soft tofu (with calcium sulfate and magnesium chloride)",
  "Молочні продукти - сир": "Cheese",
  "Яйце - білок": "Egg white",
  "Фасоль - чорна, сира": "Raw black beans",
  "Молочні продукти - пахта": "Buttermilk",
  "Корова, м'ясо (туш.) - грудина": "Beef (carcass) - breast",
  "Скумбрія - атлантична, гриль": "Grilled Atlantic mackerel",
  "Корова, органи (варені) - мозок": "Boiled beef brain",
  "Соєві вироби - молоко": "Soy milk",
  "Фасоль - бруснична, сира": "Raw lingonberry beans",
  "Зелений горошок - варений": "Boiled green peas",
  "Анчоус - європейський, консервований в олії": "Canned European anchovy in oil",
  "Соєві вироби - нато": "Natto",
  "Соєва мука - знежирена": "Defatted soy flour",
  "Форель - райдужна, гриль": "Grilled rainbow trout",
  "Соєвий сир (тофу) - сирий (з сульфатом кальцію)": "Raw tofu (with calcium sulfate)",
  "Соя - суха, варена": "Boiled dry soybeans",
  "Соєвий сир (тофу) - смажений": "Fried tofu",
  "Свинина - бекон, смажений": "Fried bacon",
  "Фасоль - стручкова, варена": "Boiled green beans",
  "Фасоль - адзукі, сира": "Raw adzuki beans",
  "Оцет - дистильований": "Distilled vinegar",
  "Банан - сушений": "Dried banana",
  "Лимон - сирий": "Raw lemon",
  "Яблуко - консервоване, з цукром": "Canned apple with sugar",
  "Лимон - свіжовичавлений сік": "Freshly squeezed lemon juice",
  "Диня - кассаба": "Cantaloupe",
  "Інжир - сушений, сирий": "Dried fig",
  "Мандарин - консервований сік без шкірки": "Canned mandarin juice without peel",
  "Гранат - сирий": "Raw pomegranate",
  "Фейхоа - сира": "Raw feijoa",
  "Свинина - поперекова частина, туш.": "Pork loin, carcass",
  "Тунець - білий, консервований в олії": "Canned white tuna in oil",
  "Соєвий сир (тофу) - екстра-твердий (з хлоридом магнію)": "Extra-firm tofu (with magnesium chloride)",
  "Фасоль - рожева, варена": "Boiled pink beans",
  "Квасоля садова - консервована": "Canned green beans",
  "Чечевиця - варена": "Boiled lentils",
  "Оцет - винний (червоний)": "Red wine vinegar",
  "Яблуко - мікрохвильове": "Microwaved apple",
  "Яблуко - сире": "Raw apple",
  "Абрикос - сушений": "Dried apricot",
  "Персик - сирий": "Raw peach",
  "Слива - сира": "Raw plum",
  "Фінік - Деглет Нур": "Deglet Nour dates",
  "Фінік - Меджул": "Medjool dates",
  "Вишня - заморожена": "Frozen cherries",
  "Печериця портобелло - гриль": "Grilled portobello mushroom",
  "Лосось - кéta, консервований в олії": "Canned keta salmon in oil",
  "Фасоль - червона, сира": "Raw red beans",
  "Соєвий сир (тофу) - твердий (з хлоридом магнію)": "Firm tofu (with magnesium chloride)",
  "Фасоль - червона, консервована": "Canned red beans",
  "Соєві вироби - окара": "Okara",
  "Маш (боби мунг) - сирий": "Raw mung beans",
  "Фасоль - жовта, сира": "Raw yellow beans",
  "Сіль - харчова": "Table salt",
  "Яблуко - варене, без кісточок": "Boiled apple without seeds",
  "Бальзамічний оцет": "Balsamic vinegar",
  "Ківі - сирий, зелений": "Raw green kiwi",
  "Виноград - американський": "American grapes",
  "Диня - звичайна": "Common melon",
  "Ананас - консервований сік": "Canned pineapple juice",
  "Пасифлора (пурпурна) - сира": "Raw purple passion fruit",
  "Ревінь - сирий": "Raw rhubarb",
  "Олива - солоні, зелені, консервовані": "Canned green olives",
  "Сардина - атлантична, консервована в олії": "Canned Atlantic sardine in oil",
  "Соєві вироби - м'ясо": "Soy meat",
  "Фасоль - чорна, варена": "Boiled black beans",
  "Фасоль - біла, консервована": "Canned white beans",
  "Кавун - сирий": "Raw watermelon",
  "Смородина - чорна": "Black currant",
  "Яблуко - сушене": "Dried apple",
  "Груша - сушена": "Dried pear",
  "Груша - сира": "Raw pear",
  "Кава - еспресо": "Espresso",
  "Печериця портобелло - сира": "Raw portobello mushroom",
  "Пальмова олія": "Palm oil",
  "Тунець - блакитний, гриль": "Grilled bluefin tuna",
  "Фасоль - бруснична, варена": "Boiled lingonberry beans",
  "Фасоль - рожева, сира": "Raw pink beans",
  "Соєвий сир (тофу) - сирий": "Raw tofu",
  "Квасоля садова - сира": "Raw green beans",
  "Дріжджі хлібопекарські - пресовані": "Pressed baker's yeast",
  "Оцет - яблучний": "Apple cider vinegar",
  "Лимон - цедра": "Lemon zest",
  "Ківі - сирий, золотистий": "Raw golden kiwi",
  "Абрикос - сирий": "Raw apricot",
  "Вишня - сира": "Raw cherry",
  "Манго - сирий": "Raw mango",
  "Манго - консервований нектар": "Mango nectar",
  "Єжевика - консервована": "Canned gooseberry",
  "Банан - сирий": "Raw banana",
  "Абрикос - консервований": "Canned apricot",
  "Апельсин - свіжовичавлений сік": "Freshly squeezed orange juice",
  "Ревінь - заморожений": "Frozen rhubarb",
  "Клюква - сира": "Raw cranberry",
  "Клюква - сік": "Cranberry juice",
  "Папайя - сира": "Raw papaya",
  "Кава - з зерен": "Coffee beans",
  "Какао - порошок": "Cocoa powder",
  "Печериця портобелло - гриль, УФ": "Grilled portobello mushroom, UV",
  "Фісташка - сира": "Raw pistachio",
  "Капуста білокачанна - сира": "Raw white cabbage",
  "Капуста білокачанна - варена": "Boiled white cabbage",
  "Капуста білокачанна - квашена": "Fermented white cabbage",
  "Свинина - лопатка, туш.": "Pork - shoulder, carcass",
  "Сосиска - свиняча, приготована": "Sausage - pork, boiled",
  "Яйце - зварене (вкруту)": "Egg - boiled (hard)",
  "Зелений горошок - сирий": "Green peas - raw",
  "Лосось - сьомга, гриль": "Salmon - salmon, grilled",
  "Соєва мука - сира": "Soy flour - raw",
  "Фасоль - адзукі, варена": "Beans - adzuki, boiled",
  "Фасоль - стручкова, сира": "Beans - green, raw",
  "Чечевиця - сира": "Lentils - raw",
  "Малина - заморожена з цукром": "Raspberry - frozen with sugar",
  "Манго - сире": "Mango - raw",
  "Єжевика - консервована в сиропі": "Blackberry - canned in syrup",
  "Полуниця - консервована в сиропі": "Strawberry - canned in syrup",
  "Слива - консервована": "Plum - canned",
  "Айва - сира": "Quince - raw",
  "Фасоль - червона, варена": "Beans - red, boiled",
  "Цукор - коричневий": "Sugar - brown",
  "Ну́т (турецький горох) - борошно": "Chickpeas (garbanzo beans) - flour",
  "Грейпфрут - свіжовичавлений сік": "Grapefruit - freshly squeezed juice",
  "Малина - консервована в сиропі": "Raspberry - canned in syrup",
  "Єжевика - сира": "Blackberry - raw",
  "Гранат - сік у пляшці": "Pomegranate - juice in a bottle",
  "Інжир - сирий": "Fig - raw",
  "Шиітаке - сире": "Shiitake - raw",
  "Вешенка звичайна - сира": "Oyster mushroom - raw",
  "Фасоль - плямиста, сира": "Beans - pinto, raw",
  "Фасоль - плямиста, варена": "Beans - pinto, boiled",
  "Ну́т (турецький горох) - сирий": "Chickpeas (garbanzo beans) - raw",
  "Груша - консервована без шкірки": "Pear - canned without skin",
  "Груша - консервована в сиропі": "Pear - canned in syrup",
  "Мандарин - свіжовичавлений сік": "Tangerine - freshly squeezed juice",
  "Ананас - консервований": "Pineapple - canned",
  "Ізюм - голден без кісточки": "Raisins - golden seedless",
  "Вишня - заморожена з цукром": "Cherry - frozen with sugar",
  "Єжевика - консервований сік": "Blackberry - canned juice",
  "Інжир - сушений, в'ялений": "Fig - dried, dried",
  "Лисичка звичайна - сира": "Chanterelle - raw",
  "Нектарин - сирий": "Nectarine - raw",
  "Соєвий сир (тофу) - сирий твердий (з сульфатом кальцію)": "Soy cheese (tofu) - raw firm (with calcium sulfate)",
  "Маш (боби мунг) - варений": "Mung beans - boiled",
  "Ну́т (турецький горох) - консервований": "Chickpeas (garbanzo beans) - canned",
  "Яблуко - заморожене": "Apple - frozen",
  "Єжевика - заморожена": "Blackberry - frozen",
  "Кивано (рогата диня) - сира": "Horned melon - raw",
  "Опеньок зимовий - сирий": "Oyster mushroom - raw",
  "Фасоль - біла, варена": "Beans - white, boiled",
  "Квасоля садова - варена": "Garden beans - boiled",
  "Кленовий сироп": "Maple syrup",
  "Ізюм - без кісточки": "Raisins - seedless",
  "Смородина - сушена": "Currant - dried",
  "Вишня - консервована": "Cherry - canned",
  "Виноград - Томпсон": "Grapes - Thompson",
  "Смородина - червона / біла": "Currant - red / white",
  "Олива - консервована": "Olive - canned",
  "Хурма японська - сира": "Persimmon - raw",
  "Чорниця - заморожена": "Blueberry - frozen",
  "Помело (Шеддок) - сирий": "Pomelo - raw",
  "Шоколад - білий": "Chocolate - white",
  "Шоколад - молочний": "Chocolate - milk",
  "Шиітаке - смажене на воді": "Shiitake - sauteed in water",
  "Мандарин - сирий": "Tangerine - raw",
  "Полуниця - сира": "Strawberry - raw",
  "Полуниця - заморожена": "Strawberry - frozen",
  "Ізюм - з кісточкою": "Raisins - with pits",
  "Малина - сира": "Raspberry - raw",
  "Інжир - консервований": "Fig - canned",
  "Тутовник - сирий": "Mulberry - raw",
  "Папайя - консервований нектар": "Papaya - canned nectar",
  "Какао - напій": "Cocoa - drink",
  "Лісовий горіх / фундук - смажений": "Hazelnut - roasted",
  "Кукурудзяні зерна (без шкірки) - смажені": "Corn kernels (without skin) - roasted",
  "Арахіс - сирий": "Peanut - raw",
  "Гірчична олія": "Mustard oil",
  "Олія авокадо": "Avocado oil",
  "Кешью - сирий": "Cashew - raw",
  "Ананас - сирий": "Pineapple - raw",
  "Слива - чорнослив": "Plum - prune",
  "Чорниця - сира": "Blueberry - raw",
  "Аґрус - сирий": "Gooseberry - raw",
  "Печериця двоспорова (біла) - варена": "Button mushroom - boiled",
  "Бузина - сира": "Elderberry - raw",
  "Печериця двоспорова (біла) - мікрохвильовка": "Button mushroom - microwaved",
  "Печериця королівська (коричнева) - сира": "Portobello mushroom - raw",
  "Арахіс - смажений": "Peanut - roasted",
  "Вино - сухе": "Wine - dry",
  "Насіння маку": "Poppy seeds",
  "Кукурудзяні зерна (зі шкіркою) - сирі, сушені": "Corn kernels (with husk) - raw, dried",
  "Кокос - молоко": "Coconut - milk",
  "Рапсова (канола) олія": "Rapeseed (canola) oil",
  "Кіндза (коріандр) - сушена": "Cilantro - dried",
  "Фенхель - насіння": "Fennel - seeds",
  "Авокадо - сире": "Avocado - raw",
  "Клементин - сирий": "Clementine - raw",
  "Печериця двоспорова (біла) - сира": "Button mushroom - raw",
  "Шиітаке - приготовлене": "Shiitake - prepared",
  "Вино - біле": "Wine - white",
  "Кедровий горіх - сирий, сушений": "Pine nut - raw, dried",
  "Олія з рисових висівок": "Rice bran oil",
  "Кукурудзяні зерна (зі шкіркою) - смажені": "Corn kernels (with husk) - roasted",
  "Кріп - насіння": "Dill - seeds",
  "Кріп - свіжий": "Dill - fresh",
  "Олія з зародків пшениці": "Wheat germ oil",
  "Гірчиця - готова": "Mustard - ready",
  Паприка: "Paprika",
  "Помідор - жовтий": "Tomato - yellow",
  "Олія з кісточок абрикоса": "Apricot kernel oil",
  "Гірчиця - листя сире": "Mustard - raw leaves",
  "Печериця двоспорова (біла) - смажена на воді": "Button mushroom (white) - fried in water",
  "Клюква - сушена в цукрі": "Cranberry - dried in sugar",
  "Шиітаке - сушене": "Shiitake - dried",
  "Вино - червоне": "Wine - red",
  "Сморчок - сирий": "Morel - raw",
  "Лісовий горіх / фундук - бланшований": "Hazelnut / filbert - blanched",
  "Мигдаль - смажений": "Almond - roasted",
  "Кукурудзяні зерна (без шкірки) - сирі, сушені": "Corn kernels (without husk) - raw, dried",
  "Мигдальна олія": "Almond oil",
  "Маккова олія": "Poppy seed oil",
  "Тмин - насіння": "Cumin - seeds",
  "Лляна олія - холодного віджиму": "Flaxseed oil - cold pressed",
  "Перець мелений - червоний": "Red pepper - ground",
  "Помідор - помаранчевий": "Tomato - orange",
  "М'ята перцева - свіжа": "Peppermint - fresh",
  "Тим'ян (чабрець) - сушений": "Thyme - dried",
  "Шоколад - чорний, 60-69%": "Chocolate - dark, 60-69%",
  "Печериця портобелло - сира, УФ": "Portobello mushroom - raw, UV",
  "Насіння соняшника - смажене": "Sunflower seeds - roasted",
  "Насіння льону - сире": "Flax seeds - raw",
  "Соняшникова олія - пром.": "Sunflower oil - industrial",
  "Арахісова олія": "Peanut oil",
  "Кукуряча олія": "Corn oil",
  "Селера - насіння": "Celery - seeds",
  "Душиця (орегано) - сушена": "Oregano - dried",
  "Рукола - свіжа": "Arugula - fresh",
  Шафран: "Saffron",
  "Картопля - печена без/с": "Potato - baked without/with skin",
  "Помідор - сік концентрований без солі": "Tomato - concentrated juice without salt",
  "Вольварієлла їстівна - консервована": "Edible volvariella - canned",
  "Фісташка - смажена": "Pistachio - roasted",
  "Кокос - м'якоть сушена": "Coconut - dried flesh",
  "Шоколад - солодкий": "Chocolate - sweet",
  "Насіння гарбуза і кабачка - смажене": "Pumpkin and zucchini seeds - roasted",
  "Кріп - сушений": "Dill - dried",
  "Петрушка - свіжа": "Parsley - fresh",
  "Олія волоського горіха": "Walnut oil",
  "Кокосова олія": "Coconut oil",
  "Перець солодкий - жовтий": "Sweet pepper - yellow",
  "Помідор - суш. на сонці": "Tomato - sun-dried",
  "Тим'ян (чабрець) - свіжий": "Thyme - fresh",
  "Мускатний горіх - шкаралупа, мелена": "Nutmeg - shell, ground",
  "Помідор - червоний": "Tomato - red",
  "Брюссельська капуста - сира": "Brussels sprouts - raw",
  "Кава - швидка": "Coffee - instant",
  "Кешью - смажений": "Cashew - roasted",
  "Кокос - м'якоть сира": "Coconut - raw flesh",
  "Мигдаль - бланшований": "Almond - blanched",
  "Кукурудзяна олія - пром.": "Corn oil - industrial",
  "Олія з пальмових ядер": "Palm kernel oil",
  "Базилік - свіжий": "Basil - fresh",
  "Імбир - мелений": "Ginger - ground",
  "Огірок - сирий с/с": "Cucumber - raw with skin",
  "Помідор - тушкований з сіллю": "Tomato - stewed with salt",
  "Огірок - солоний, кошерний": "Cucumber - salted, kosher",
  "Перець солодкий - зелений": "Sweet pepper - green",
  "Цвітна капуста - сира": "Cauliflower - raw",
  "Броколі - сире": "Broccoli - raw",
  "Баклажан - сирий": "Eggplant - raw",
  "Кокос - рідина": "Coconut - liquid",
  "Чай - чорний": "Tea - black",
  "Волоський горіх - сирий": "Walnut - raw",
  "Грифола курчава - сира": "Hen of the woods mushroom - raw",
  "Мигдаль - сирий": "Almond - raw",
  "Насіння соняшника - сире, сушене": "Sunflower seeds - raw, dried",
  "Петрушка - сушена": "Parsley - dried",
  "Перець мелений - чорний": "Black pepper - ground",
  "Бавовняна олія": "Cottonseed oil",
  "Імбир - корінь, сирий": "Ginger - root, raw",
  "Оливкова олія": "Olive oil",
  "Мускатний горіх - мелений": "Nutmeg - ground",
  "Капуста пекінська - варена": "Peking cabbage - boiled",
  "М'ята кучерява - сушена": "Curly mint - dried",
  "Кіндза (коріандр) - насіння": "Cilantro (coriander) - seeds",
  "Лісовий горіх / фундук - сирий": "Hazelnut / filbert - raw",
  "Пиво - всі види": "Beer - all kinds",
  "Насіння гарбуза і кабачка - сире, сушене": "Pumpkin and zucchini seeds - raw, dried",
  "Бразильський горіх - сирий, сушений": "Brazil nut - raw, dried",
  "Базилік - сушений": "Basil - dried",
  "Ваниль - екстракт": "Vanilla - extract",
  "Куркума - мелена": "Turmeric - ground",
  "Перець солодкий - червоний": "Sweet pepper - red",
  "Капуста савойська - сира": "Savoy cabbage - raw",
  "Гвоздика - мелена": "Clove - ground",
  "Чабер - мелений": "Savory - ground",
  "Кольрабі - сире": "Kohlrabi - raw",
  "Кольрабі - варене": "Kohlrabi - boiled",
  "Картопля - варена": "Potato - boiled",
  "Кабачок цукіні - сирий з шкіркою": "Zucchini - raw with skin",
  "Щавель - сирий": "Sorrel - raw",
  "Какао-олія": "Cocoa butter",
  "Олія з кісточок винограду": "Grape seed oil",
  "Соєва олія": "Soybean oil",
  "Лавр - лист": "Bay leaf",
  "М'ята кучерява - свіжа": "Curly mint - fresh",
  "Цвітна капуста - варена": "Cauliflower - boiled",
  "Огірок - солоний, квашений": "Cucumber - pickled, salted",
  "Кіндза (коріандр) - свіжа": "Cilantro (coriander) - fresh",
  "Буряк - варений": "Beetroot - boiled",
  "Огірок - сирий без/с": "Cucumber - raw with/without skin",
  "Буряк - сирий": "Beetroot - raw",
  "Спаржа - морожена, приготована": "Asparagus - frozen, boiled",
  "Топінамбур - сирий": "Jerusalem artichoke - raw",
  "Пастернак - варений": "Parsnip - boiled",
  "Спіруліна - сушена": "Spirulina - dried",
  "Агар - сушений": "Agar - dried",
  "Агар - сирий": "Agar - raw",
  "Перець мелений - білий": "White pepper - ground",
  "Гірчиця - листя варене": "Mustard greens - boiled",
  "Майоран - сушений": "Marjoram - dried",
  "Хрін - готовий": "Horseradish - prepared",
  "Перець солодкий - варений без солі": "Bell pepper - boiled without salt",
  "Капуста червонокачанна - сира": "Red cabbage - raw",
  "Броколі - варене": "Broccoli - boiled",
  "Шавлія - мелена": "Sage - ground",
  "Брюссельська капуста - варена": "Brussels sprouts - boiled",
  "Перець солодкий - соте": "Bell pepper - sauteed",
  "Кабачок цукіні - варений з шкіркою": "Zucchini - boiled with skin",
  "Салат-латук - зелений": "Lettuce - green",
  "Цибуля-батун - сира": "Scallion - raw",
  "Брюква - сира": "Rutabaga - raw",
  "Ірландський мох (караген) - сирий": "Irish moss (carrageenan) - raw",
  "Листя репи - морожені": "Turnip greens - frozen",
  "Листя репи - консервовані": "Turnip greens - canned",
  "Гірчиця - насіння, мелене": "Mustard - seeds, ground",
  "Капуста савойська - варена": "Savoy cabbage - boiled",
  "Перець солодкий - морожений": "Bell pepper - frozen",
  "Капуста білокачанна - квашена малосольна": "White cabbage - lightly fermented",
  "Картопля - сира с/с": "Potato - raw peeled",
  "Картопля - в мундирі": "Potato - baked in skin",
  "Цвітна капуста - морожена, приготована": "Cauliflower - frozen, boiled",
  "Редиска крижана сосулька - сира": "Icicle radish - raw",
  "Редиска - сира": "Radish - raw",
  "Редька дайкон - варена": "Daikon radish - boiled",
  "Артишок - сирий": "Artichoke - raw",
  "Пшениця - дурум, тверда": "Wheat - durum, hard",
  "Фенхель - сирий": "Fennel - raw",
  "Пшениця - озима м'яка червона (SRW)": "Wheat - winter soft red (SRW)",
  "Вироби з пшениці - хліб": "Wheat products - bread",
  "Вироби з пшениці - хліб цільнозерновий комерційний": "Wheat products - whole wheat bread commercial",
  "Кропива - бланширована": "Nettle - blanched",
  "Кориця - мелена": "Cinnamon - ground",
  "Естрагон (тархун) - сушений": "Tarragon - dried",
  "Капуста червонокачанна - варена": "Red cabbage - boiled",
  "Морква - сира": "Carrot - raw",
  "Кабачок літній - варений": "Summer squash - boiled",
  "Кабачок зимовий - сирий": "Winter squash - raw",
  "Цибуля - варена": "Onion - boiled",
  "Цибуля - консервована": "Onion - canned",
  "Селера - сира": "Celery - raw",
  "Порей - варений": "Leek - boiled",
  "Вакамэ (ундарія периста) - сира": "Wakame (Undaria pinnatifida) - raw",
  "Вироби з пшениці - макаронні": "Wheat products - pasta",
  "Батат (солодкий картофель) - печений с/с": "Sweet potato - baked peeled",
  "Вироби з пшениці - хліб з висівками": "Wheat products - bread with bran",
  "Жито - хліб": "Rye - bread",
  "Помідор - зелений": "Tomato - green",
  "Виноград - листя": "Grape - leaves",
  "Морква - сік концентрований": "Carrot - juice concentrated",
  "Баклажан - варений": "Eggplant - boiled",
  "Спаржа - сира": "Asparagus - raw",
  "Шпинат - консервований": "Spinach - canned",
  "Цибуля-шалот - сира": "Shallot - raw",
  "Артишок - варений": "Artichoke - boiled",
  "Пшениця - ярова тверда червона (HRS)": "Wheat - spring hard red (HRS)",
  "Пшеничне борошно - цільнозернове, м'яке": "Wheat flour - whole wheat, soft",
  "Мангольд - сирий": "Swiss chard - raw",
  "Вівсянка - висівки сирі": "Oat - bran raw",
  "Вівсянка - зерно": "Oat - grain",
  "Рис білий - сирий": "White rice - raw",
  "Рисові вироби - локшина варена": "Rice products - noodles boiled",
  "Кукурудза - сира": "Corn - raw",
  "Кукурудза - макарони, варені": "Corn - pasta, boiled",
  "Капуста пекінська - сира": "Napa cabbage - raw",
  "Картопля - печена с/с": "Potato - baked peeled",
  "Морква - варена": "Carrot - boiled",
  "Спаржа - варена": "Asparagus - boiled",
  "Салат-латук - червоний": "Lettuce - red",
  "Редька дайкон - сира": "Daikon radish - raw",
  "Щавель - варений": "Sorrel - boiled",
  "Фізаліс - сирий": "Physalis - raw",
  "Ендиві - сирий": "Endive - raw",
  "Вироби з пшениці - хліб цільнозерновий за рецептом": "Wheat products - whole wheat bread homemade",
  "Жито - борошно світле": "Rye - flour light",
  "Жито - борошно темне": "Rye - flour dark",
  "Рис дикий (цицанія) - сирий": "Wild rice (Zizania) - raw",
  "Гречка - сира (зелена)": "Buckwheat - raw (green)",
  "Гречка - борошно цільнозернове": "Buckwheat - flour whole grain",
  "Буряк - солоний/маринований": "Beetroot - salted/marinated",
  "Кабачок зимовий - печений": "Winter squash - baked",
  "Цибуля - сира": "Onion - raw",
  "Порей - сирий": "Leek - raw",
  "Часник - сирий": "Garlic - raw",
  "Артишок - морожений": "Artichoke - frozen",
  "Пшениця - тверда біла (HW)": "Wheat - hard white (HW)",
  "Нори (порфіра) - сира": "Nori (porphyra) - raw",
  "Пшениця - м'яка біла (SW)": "Wheat - soft white (SW)",
  "Брюква - варена": "Rutabaga - boiled",
  "Жито - хлібні тости": "Rye - bread toast",
  "Вівсянка - хліб з висівок": "Oatmeal - bread with bran",
  "Рисові вироби - борошно біле": "Rice products - white flour",
  "Гречка - жарена, суха": "Buckwheat - roasted, dry",
  "Сорго - сире": "Sorghum - raw",
  "Кукурудза - крохмаль": "Corn - starch",
  "Кабачок літній - сирий": "Summer squash - raw",
  "Баклажан - солоний/маринований": "Eggplant - salted/marinated",
  "Гарбуз - варений": "Pumpkin - boiled",
  "Шпинат - сирий": "Spinach - raw",
  "Цибуля - морожена": "Onion - frozen",
  "Ламінарія (морська капуста) - сира": "Laminaria (seaweed) - raw",
  "Пшениця - пророщена": "Wheat - sprouted",
  "Пшениця - висівки, необроблені": "Wheat - bran, unprocessed",
  "Пшениця - зародок, необроблений - НЕ ПРОРОСТОК!": "Wheat - germ, unprocessed - NOT A SPROUT!",
  "Вироби з пшениці - готові макарони/спагетті": "Wheat products - ready-made pasta/spaghetti",
  "Вироби з пшениці - хлібні тости": "Wheat products - bread toast",
  "Вівсянка - висівки оброблені": "Oatmeal - processed bran",
  "Рис дикий (цицанія) - варений": "Wild rice (zizania) - boiled",
  "Кукурудза - макарони, сирі": "Corn - macaroni, raw",
  "Ячмінь - солодове борошно": "Barley - malt flour",
  "Гарбуз - сирий": "Pumpkin - raw",
  "Шпинат - варений": "Spinach - boiled",
  "Пастернак - сирий": "Parsnip - raw",
  "Селера - варена": "Celery - boiled",
  "Листя репи - сирі": "Turnip greens - raw",
  "Репа - варена": "Turnip - boiled",
  "Батат (солодкий картофель) - варений без/с": "Sweet potato - boiled with/without skin",
  "Батат (солодкий картофель) - сирий": "Sweet potato - raw",
  "Вівсянка - борошно очищене": "Oatmeal - purified flour",
  "Рис білий - варений": "White rice - boiled",
  "Рисові вироби - борошно коричневе": "Rice products - brown flour",
  "Пшоно - варене": "Millet - boiled",
  "Кукурудза - морожена, приготована": "Corn - frozen, boiled",
  "Рис білий - круглозернистий сирий": "White rice - short-grain raw",
  "Цибуля зелена - сира": "Green onion - raw",
  "Репа - сира": "Turnip - raw",
  "Спіруліна - сира": "Spirulina - raw",
  "Пшениця - озима тверда червона (HRW)": "Wheat - hard red winter (HRW)",
  "Пшеничне борошно - відбілене, промислове, біле": "Wheat flour - bleached, industrial, white",
  "Листя репи - варені": "Turnip greens - boiled",
  "Репа - морожена": "Turnip - frozen",
  "Жито - борошно середнє": "Rye - medium flour",
  "Рис білий - довгозернистий варений": "White rice - long-grain boiled",
  "Рис коричневий - довгозернистий сирий": "Brown rice - long-grain raw",
  "Гречка - жарена, варена": "Buckwheat - roasted, boiled",
  "Кукурудза - борошно, очищене": "Corn - flour, purified",
  "Сорго - борошно": "Sorghum - flour",
  "Пшеничне борошно - цільнозернове": "Wheat flour - whole grain",
  "Мангольд - варений": "Swiss chard - boiled",
  "Жито - зерно": "Rye - grain",
  "Рис білий - круглозернистий варений": "White rice - short-grain boiled",
  "Рис коричневий - сирий": "Brown rice - raw",
  "Рисові вироби - локшина сира": "Rice products - raw noodles",
  "Ячмінь - очищений, сирий": "Barley - hulled, raw",
  "Кукурудза - пластівці, сухі, незбагачені": "Corn - flakes, dry, unenriched",
  "Вироби з пшениці - хліб із зародком": "Wheat products - bread with germ",
  "Рис білий - довгозернистий сирий": "White rice - long-grain raw",
  "Рис коричневий - варений": "Brown rice - boiled",
  "Ячмінь - борошно": "Barley - flour",
  "Попкорн - масляний": "Popcorn - buttered",
  "Кукурудза - варена": "Corn - boiled",
  "Вівсянка - пластівці приготовані, незбагачені": "Oatmeal - boiled flakes, unenriched",
  "Рисові вироби - висівки сирі, грубі": "Rice products - raw bran, coarse",
  "Рисові вироби - хліб з висівок": "Rice products - bread with bran",
  "Пшоно - борошно": "Millet - flour",
  "Ячмінь - неочищений": "Barley - unhulled",
  "Попкорн - повітряний": "Popcorn - air-popped",
  "Вівсянка - пластівці сухі, незбагачені": "Oatmeal - flakes, dry, unenriched",
  "Рис коричневий - довгозернистий варений": "Brown rice - long-grain boiled",
  "Пшоно - сире": "Millet - raw",
  "Кукурудза - зерно": "Corn - grain",
  "Кукурудза - висівки, грубі": "Corn - bran, coarse",
  "Кукурудза - консервована": "Corn - canned",
  "Ячмінь - очищений, варений": "Barley - hulled, boiled",
  "Кукурудза - борошно, цільнозернове": "Corn - flour, whole grain",
};

export default translations;
