import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import VStack from "../../VStack";
import WordAudio from "../../../Audio/WordAudio";
import HStack from "../../HStack";
import { EditText } from "react-edit-text";

interface WordExampleSentenceProps {
  ua: string;
  en: string;
  url?: string;
  isVisible?: boolean;
  person?: string;
  enVisible: boolean;
  uaVisible: boolean;
  highlight?: string;
  onUaChanged: ({ ua, en }: { ua: string; en: string }) => void;
}

const DialogRepl = ({
  en,
  ua: uaInput,
  url,
  person,
  isVisible,
  enVisible,
  uaVisible,
  onUaChanged,
}: WordExampleSentenceProps) => {
  const [isTranslated, setIsTranslated] = useState(false);
  const [ua, setUa] = useState(uaInput);

  useEffect(() => {
    setUa(uaInput);
  }, [uaInput]);

  useEffect(() => {
    onUaChanged({
      ua,
      en,
    });
  }, [ua]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (isTranslated) {
      timeout = setTimeout(() => {
        if (isTranslated) {
          setIsTranslated(false);
        }
      }, 60000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isTranslated]);

  if (isVisible === false)
    return (
      <Box mb={3}>
        <Typography fontSize={30} color="gray" py={0}>
          ...............
        </Typography>
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <VStack position="relative" mb={1} alignItems={"flex-start"}>
        {uaVisible && (
          <Typography
            color="#919096"
            fontWeight={500}
            fontSize={18}
            width="100%"
          >
            <EditText
              value={ua}
              style={{
                width: "100%",
                fontSize: "18px",
              }}
              onChange={(e) => {
                setUa(e.target.value);
              }}
            />
          </Typography>
        )}
        {enVisible && (
          <HStack sx={{ position: "relative" }}>
            {!isTranslated && (
              <Box
                position="absolute"
                width="calc(100% + 10px)"
                height="100%"
                minWidth={280}
                zIndex={3}
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                fontSize={14}
                onClick={() => setIsTranslated(true)}
                display={!isTranslated ? "flex" : "none"}
                sx={{
                  // transform: "translate(-50%)",
                  backdropFilter: "blur(7px)",
                  backgroundColor: "rbga(0, 0, 0, 0)",
                  // left: "0",
                  // cursor: "pointer",
                  // backgroundColor: "black",
                  // backgroundImage:
                  //   "linear-gradient(135deg, #919096 25%, transparent 25%), linear-gradient(225deg, #919096 25%, transparent 25%), linear-gradient(45deg, #919096 25%, transparent 25%), linear-gradient(315deg, #919096 25%, #ffffff 25%)",
                  // backgroundSize: "20px 20px",
                  // backgroundPosition: "6px -4px,6px -4px,-4px -4px,-4px -4px",
                }}
              />
            )}
            <Typography
              color="secondary.dark"
              fontWeight={500}
              fontSize={18}
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
            >
              <Typography component="span">
                {url && <WordAudio small url={url} />}
              </Typography>
              {en}
            </Typography>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

export default DialogRepl;
