import { Box } from "@mui/material";

const blue1 = "#99CCFF";
const blue2 = "#6699CC";
const blue3 = "#336699";
const blue4 = "#003366";

const Preview: React.FC = () => {
  return (
    <Box
      sx={{
        width: 160 * 5,
        height: 90 * 5,
        backgroundColor: "#3C7DA6",
      }}
    >
      <Box
        sx={
          {
            // backgroundColor: "yellow",
          }
        }
      >
        <Box
          sx={{
            color: "#401201",
            fontSize: 50,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F2E8C9",
          }}
        >
          <Box
            p={2}
            sx={{ color: "white", width: "33.3%", backgroundColor: "#735B2F" }}
          >
            A1-A2
          </Box>
          <Box p={2} sx={{ width: "33.3%" }}>
            B1-B2
          </Box>
          <Box p={2} sx={{ width: "33.3%" }}>
            C1-C2
          </Box>
        </Box>
        <Box
          sx={{
            color: "white",
            fontSize: 50,
          }}
        >
          Більше 100 слів з подібним перекладом в англійській мові
        </Box>
        <Box
          sx={{
            color: "#F2E8C9",
            fontSize: 50,
          }}
        >
          do - робити
        </Box>
        <Box
          sx={{
            color: "#96D2D9",
            fontSize: 50,
          }}
        >
          work - робити
        </Box>
      </Box>
    </Box>
  );
};

export default Preview;
