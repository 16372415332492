import { Box, Typography } from "@mui/material";
import { memo } from "react";
import WordAudio from "../../Audio/WordAudio";
import UkrainianWord, { HighlightWord } from "../UkrainianWord";

interface SanskritEnglishSentenceProps {
  romanId: string;
  en: string;
  ua: string;
  script: string | null;
  isVisible: boolean;
  transcription?: string;
}

const SanskritEnglishSentence = ({
  romanId,
  en,
  ua,
  script,
  isVisible,
  transcription,
}: SanskritEnglishSentenceProps) => {
  if (!isVisible) return null;

  return (
    <Box position="relative">
      <Typography
        variant="h6"
        fontSize={30}
        // fontStyle="italic"
        color="primary"
        fontWeight={400}
      >
        {en}
      </Typography>
      <Typography
        variant="h6"
        fontSize={30}
        // fontStyle="italic"
        color="primary"
        fontWeight={400}
      >
        {romanId}
      </Typography>
      <Typography
        variant="h6"
        fontSize={30}
        // fontStyle="italic"
        color="primary"
        fontWeight={400}
      >
        {script}
      </Typography>
      {/* <Box mb={2}>
        {transcription && (
          <Typography variant="h6" fontSize={25} fontWeight={400}> */}
      {/* {transcription} */}
      {/* {transcription.split(",").map((t, index) => {
              return (
                <HighlightWord
                  text={t.trim()}
                  row={false}
                  isLast={transcription.split(",").length - 1 === index}
                />
              );
            })} */}
      {/* </Typography>
        )}
      </Box> */}
      {/* {audioUrl && <WordAudio url={audioUrl} autoPlay={true} boxHide={false} />} */}
    </Box>
  );
};

export default memo(SanskritEnglishSentence);
