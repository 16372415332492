import CloseIcon from "@mui/icons-material/Close";
import { Drawer, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { LearnWordsSectionProps } from ".";
import usePersonalLesson from "../../../hooks/usePersonalLesson";
import { useLessonsStore } from "../../../store/lessonsStore";
import LinearProgress from "../../User/LinearProgress";
import ExampleSentenceItem from "../../Word/WordTranslationSection/ExampleSentenceItem";
import Box from "../Box";
import Button from "../Button";
import FullScreenLoader from "../FullScreenLoader";
import HStack from "../HStack";
import IconButton from "../IconButton";
import supabaseClient from "../../../common/supabaseClient";

export const SentenceListeningSection: React.FC<LearnWordsSectionProps> = ({
  sliderId,
  lessonId,
  lessonType,
  partSentencesIds,
  differenceSentences,
}) => {
  const [slider, setSlider] = useState<Slider | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const sliderIndex = useLessonsStore(
    (state) => state.englishGrammarCourseSliderPositions[sliderId] || 0,
  );
  const setSliderIndex = useLessonsStore(
    (state) => state.setEnglishGrammarCourseSliderPositions,
  );

  const {
    personalLessonsSentences,
    setPersonalLessonsSentences,
    fetchPersonalSentences,
  } = usePersonalLesson(lessonId, partSentencesIds);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const handleSlideChange = async (index: number) => {
    setSliderIndex(sliderId, index);
  };

  const [isOpenEnd, setIsOpenEnd] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (isOpen) {
      timeout = setTimeout(() => {
        setIsOpenEnd(true);
      }, 300);
    } else {
      setIsOpenEnd(false);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isOpen]);

  const [resetingSlider, setResetingSlider] = useState(false);
  const resetSlider = () => {
    setResetingSlider(true);
    setSlider(null);
    handleSlideChange(0);
    setTimeout(() => {
      setSlider(null);
      setResetingSlider(false);
    }, 200);
  };

  if (lessonType === "difference") {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={async () => {
              setIsOpen(true);
              if (!personalLessonsSentences?.length) {
                await fetchPersonalSentences();
              }
            }}
          >
            Аудіювання речень ({differenceSentences?.length})
          </Button>
          <Drawer open={isOpen} onClose={handleDrawerClose} anchor="bottom">
            <Box sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
              {differenceSentences?.length && (
                <LinearProgress
                  elementsCount={differenceSentences.length - 1}
                  currentValue={sliderIndex || 0}
                />
              )}

              {(!isOpenEnd ||
                resetingSlider ||
                !Boolean(differenceSentences?.length)) && <FullScreenLoader />}
              {differenceSentences && (
                <Typography variant="caption" p={2}>
                  {differenceSentences.length !== sliderIndex + 1 &&
                    `${sliderIndex + 1} / ${differenceSentences.length}`}
                </Typography>
              )}
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 8,
                  zIndex: 2,
                }}
              >
                <CloseIcon />
              </IconButton>
              {!resetingSlider && differenceSentences?.length && (
                <Slider
                  lazyLoad="ondemand"
                  infinite={false}
                  ref={(slider) => setSlider(slider)}
                  swipe={false}
                  initialSlide={sliderIndex}
                  afterChange={handleSlideChange}
                >
                  {differenceSentences?.map((sentence, index) => {
                    // if (sentence.isGreeting) {
                    //   return (
                    //     <LastSentenceItem
                    //       nextButtonText="Почати з початку"
                    //       successText="Всі речення прослухані 🙌"
                    //     >
                    //       <Button variant="contained" onClick={resetSlider}>
                    //         Почати з початку
                    //       </Button>
                    //       <Button
                    //         variant="outlined"
                    //         onClick={() => {
                    //           setIsOpen(false);
                    //           resetSlider();
                    //         }}
                    //       >
                    //         Закрити
                    //       </Button>
                    //     </LastSentenceItem>
                    //   );
                    // }
                    return (
                      <ExampleSentenceItem
                        en={sentence.en.en}
                        key={index}
                        audioSmall={false}
                        slideIndex={index}
                        sliderId={sliderId}
                        fullScreen
                        isCentered
                        lessonWords={[]}
                        audioUrl={sentence.en.en_audio_url}
                        ua={sentence.en.ua}
                      >
                        <HStack>
                          <Button
                            variant="outlined"
                            color="warning"
                            onClick={() => {
                              const newIndex = sliderIndex + 9;
                              const updatedSentences = [
                                ...personalLessonsSentences,
                              ];
                              if (newIndex < personalLessonsSentences?.length) {
                                updatedSentences.splice(
                                  newIndex,
                                  0,
                                  updatedSentences[sliderIndex],
                                );
                              } else {
                                updatedSentences.push(
                                  updatedSentences[sliderIndex],
                                );
                              }
                              setPersonalLessonsSentences(
                                lessonId,
                                updatedSentences,
                              );
                              slider?.slickNext();
                            }}
                          >
                            складно
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={async () => {
                              // const updatedSentences = [
                              //   ...sentences,
                              //   {
                              //     en: "",
                              //     ua: "",
                              //     audioUrl: "",
                              //     words: [],
                              //     isGreeting: true,
                              //   },
                              // ];

                              if (
                                sliderIndex ===
                                personalLessonsSentences?.length - 1
                              ) {
                                // setSentences(updatedSentences);
                                resetSlider();
                              } else {
                                slider?.slickNext();
                              }
                            }}
                          >
                            легко
                          </Button>
                        </HStack>
                      </ExampleSentenceItem>
                    );
                  })}
                </Slider>
              )}
            </Box>
          </Drawer>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={async () => {
            setIsOpen(true);
            if (!personalLessonsSentences?.length) {
              await fetchPersonalSentences();
            }
          }}
        >
          {!!partSentencesIds &&
            `Аудіювання речень (${partSentencesIds.length})`}
          {!!differenceSentences &&
            `Аудіювання речень (${differenceSentences.length})`}
        </Button>
        <Drawer open={isOpen} onClose={handleDrawerClose} anchor="bottom">
          <Box sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
            {personalLessonsSentences && (
              <LinearProgress
                elementsCount={personalLessonsSentences.length - 1}
                currentValue={sliderIndex || 0}
              />
            )}
            {differenceSentences && (
              <LinearProgress
                elementsCount={differenceSentences.length - 1}
                currentValue={sliderIndex || 0}
              />
            )}

            {(!isOpenEnd ||
              resetingSlider ||
              !Boolean(personalLessonsSentences?.length)) && (
              <FullScreenLoader />
            )}
            {personalLessonsSentences && (
              <Typography variant="caption" p={2}>
                {personalLessonsSentences.length !== sliderIndex + 1 &&
                  `${sliderIndex + 1} / ${personalLessonsSentences.length}`}
              </Typography>
            )}
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 8,
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
            {!resetingSlider && personalLessonsSentences?.length && (
              <Slider
                lazyLoad="ondemand"
                infinite={false}
                ref={(slider) => setSlider(slider)}
                swipe={false}
                initialSlide={sliderIndex}
                afterChange={handleSlideChange}
              >
                {personalLessonsSentences?.map((sentence, index) => {
                  // if (sentence.isGreeting) {
                  //   return (
                  //     <LastSentenceItem
                  //       nextButtonText="Почати з початку"
                  //       successText="Всі речення прослухані 🙌"
                  //     >
                  //       <Button variant="contained" onClick={resetSlider}>
                  //         Почати з початку
                  //       </Button>
                  //       <Button
                  //         variant="outlined"
                  //         onClick={() => {
                  //           setIsOpen(false);
                  //           resetSlider();
                  //         }}
                  //       >
                  //         Закрити
                  //       </Button>
                  //     </LastSentenceItem>
                  //   );
                  // }
                  return (
                    <ExampleSentenceItem
                      en={sentence.en}
                      key={index}
                      audioSmall={false}
                      slideIndex={index}
                      sliderId={sliderId}
                      fullScreen
                      isCentered
                      lessonWords={[]}
                      audioUrl={sentence.en_audio_url}
                      ua={sentence.ua}
                    >
                      <HStack>
                        <Button
                          variant="outlined"
                          color="warning"
                          onClick={() => {
                            const newIndex = sliderIndex + 9;
                            const updatedSentences = [
                              ...personalLessonsSentences,
                            ];
                            if (newIndex < personalLessonsSentences?.length) {
                              updatedSentences.splice(
                                newIndex,
                                0,
                                updatedSentences[sliderIndex],
                              );
                            } else {
                              updatedSentences.push(
                                updatedSentences[sliderIndex],
                              );
                            }
                            setPersonalLessonsSentences(
                              lessonId,
                              updatedSentences,
                            );
                            slider?.slickNext();
                          }}
                        >
                          не зрозуміла
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            // const updatedSentences = [
                            //   ...sentences,
                            //   {
                            //     en: "",
                            //     ua: "",
                            //     audioUrl: "",
                            //     words: [],
                            //     isGreeting: true,
                            //   },
                            // ];
                            if (
                              sliderIndex ===
                              personalLessonsSentences?.length - 1
                            ) {
                              // setSentences(updatedSentences);
                              resetSlider();
                            } else {
                              slider?.slickNext();
                            }
                          }}
                        >
                          зрозуміла
                        </Button>
                      </HStack>
                    </ExampleSentenceItem>
                  );
                })}
              </Slider>
            )}
          </Box>
        </Drawer>
      </Grid>
    </Grid>
  );
};
