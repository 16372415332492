import { Box, Button, Typography } from "@mui/material";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import shuffle from "../../../common/shuffle";
import useIsInViewport from "../../../hooks/useIsInViewport";
import { Sentence, Word } from "../../../types/supabase";
import WordAudio from "../../Audio/WordAudio";
import UkrainianAudioSentence from "../UkrainianAudioSentence";
import WordPazleItem from "../WordPazleItem";
import classNames from "classnames";
import HoverDetails from "../../Word/HoverDetails";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import upperCaseWords from "../../../common/uppercaseWords";
import isEqual from "deep-equal";
import HStack from "../../Unknown/HStack";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import supabaseClient from "../../../common/supabaseClient";
import AudioAndBelongsToUserStar from "../../AudioAndBelongsToUserStar";

interface SentencePazleItemProps {
  isFirst?: boolean;
  sentence: Sentence;
  fullHeight?: boolean;
  belongsToUser?: boolean;
  firstSelected?: boolean;
  moduleWordsMap: Record<string, Word>;
  handleNext: () => void;
}

export const buildSentenceAudioWrapClassName = "s6ed02a88055d4e2c900e81cc5aa33d94";
export const buildSentenceButtonNextClassName = "s211a93da248344acb5d86b96fe393e58";

const SentencePazleItem = ({
  isFirst,
  sentence,
  belongsToUser,
  fullHeight = true,
  firstSelected = false,
  moduleWordsMap,
  // isInViewport,
  handleNext,
}: SentencePazleItemProps) => {
  const ref = useRef<HTMLElement>();

  const isInViewport = useIsInViewport(ref);

  const [partialSentence, setPartialSentence] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!isInViewport) {
      setCount(0);
      setPartialSentence("");
    }
  }, [isInViewport]);

  const [sentenceMap, setSentenceMap] = useState<
    Record<string, { word: string; visible: boolean }>
  >({});

  useEffect(() => {
    setSentenceMap(
      sentence.en
        .split(" ")
        .filter(Boolean)
        .reduce(
          (prevMap, word, index) => ({
            ...prevMap,
            [index.toString()]: {
              word,
              visible: true,
            },
          }),
          {},
        ),
    );
  }, [sentence.en, count]);

  const [wordsToSelect, setWordsToSelect] = useState<any[]>([]);
  // const wordsToSelect = useMemo(() => shuffle(Object.entries(sentenceMap)), [sentenceMap]);

  useEffect(() => {
    const wordMapBuilt = Object.keys(sentenceMap).length;
    if (!wordsToSelect.length && wordMapBuilt) {
      try {
        // setWordsToSelect(shuffle([...Object.entries(sentenceMap), ["999", { word: getRandomLetter(), visible: true }]]));
        setWordsToSelect(shuffle([...Object.entries(sentenceMap)]));
      } catch (err) {
        console.log("🚀 ~ err:", err);
      }
    } else if (wordMapBuilt) {
      try {
        const newWordsToSelect = wordsToSelect.map((item) => {
          const itemMapIndex = item[0];
          if (sentenceMap[itemMapIndex].visible !== item[1].visible) {
            return [
              item[0],
              {
                ...item[1],
                // visible: sentenceMap[item[0]].visible,
                visible: false,
              },
            ];
          }
          return item;
        });
        if (!isEqual(newWordsToSelect, wordsToSelect)) {
          setWordsToSelect(newWordsToSelect);
        }
      } catch (err) {
        console.log("🚀 ~ err:", err);
      }
    }
  }, [sentenceMap, wordsToSelect]);

  useEffect(() => {
    if (count === 0) {
      setWordsToSelect([]);
    }
  }, [count]);

  const isSuccess = wordsToSelect.length === count;

  return (
    <Box display="flex" alignItems="center" justifyContent="center" position="relative">
      <Box
        textAlign="center"
        width="100%"
        gap={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        {/* center box to detect i in viewport */}
        <Box
          ref={ref}
          width={10}
          height={10}
          // sx={{
          //   backgroundColor: "red",
          // }}
        />
        <UkrainianAudioSentence
          // isAudioPlay={isInViewport}
          sentence={sentence.ua}
          // audioUrl={sentence.ua_audio_url}
        />
        {sentence.en_audio_url && isSuccess && (
          <AudioAndBelongsToUserStar
            text={sentence.en}
            audio={sentence.en_audio_url}
            belongsToUser={!!belongsToUser}
          />
        )}
        <HoverDetails
          isCenter
          words={
            Object.values(sentenceMap).map(({ word: w }: any, index: number) => {
              if (index < count) {
                const cleanWord = removePunctuationFromString(w);
                const word = moduleWordsMap[cleanWord] || moduleWordsMap[cleanWord.toLowerCase()];

                return {
                  ...(word ? word : {}),
                  word: word?.name || cleanWord,
                  label: w,
                };
              }

              return {
                label: "_".repeat(w.length),
              };
            }) as any
          }
        />
        <Box gap={2} display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
          {wordsToSelect.map(([mapIndex, { word, visible }]) => {
            const wordWithoutPunctuation = word.replace(/[.!\,?]/g, "");

            return (
              <WordPazleItem
                word={
                  upperCaseWords.includes(wordWithoutPunctuation)
                    ? wordWithoutPunctuation
                    : wordWithoutPunctuation.toLowerCase()
                }
                label={word}
                // audio={moduleWordsMap[word] ? moduleWordsMap[word].audio_url : ""}
                initialSentence={sentence.en}
                partialSentence={partialSentence}
                key={mapIndex}
                visible={visible}
                wordIndex={Number(mapIndex)}
                onAnswer={(newPartialSentence) => {
                  if (newPartialSentence) {
                    setPartialSentence(newPartialSentence);

                    const newMap = {
                      ...sentenceMap,
                    };
                    if (newMap[mapIndex]) newMap[mapIndex].visible = false;
                    setSentenceMap(newMap);
                    setCount((prev) => prev + 1);
                  }
                }}
              />
            );
          })}
        </Box>
        <Box pt={5} pb={3}>
          <Button
            variant="contained"
            disabled={!isSuccess}
            onClick={() => {
              setCount(0);
              setPartialSentence("");
              handleNext();
            }}
          >
            продовжити
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(SentencePazleItem);
