function startsWithSubstringAndNonLetter(str: string, substring: string) {
  // Check if the string starts with the substring
  if (!str.startsWith(substring)) {
    return false;
  }

  // Check if the character after the substring is not a letter
  const nextChar = str[substring.length];
  return !/[a-zA-Z]/.test(nextChar);
}

export default startsWithSubstringAndNonLetter;
