const a2Words = [
  {
    word: "ability",
    topics: ["General", "Skills", "Talent", "Potential"],
  },
  {
    word: "able",
    topics: ["General", "Capability", "Skills", "Capacity"],
  },
  {
    word: "abroad",
    topics: ["Travel", "Geography", "International", "Foreign"],
  },
  {
    word: "accept",
    topics: ["General", "Agreement", "Tolerance", "Response"],
  },
  {
    word: "accident",
    topics: ["Events", "Safety", "Unexpected", "Mishap"],
  },
  {
    word: "according to",
    topics: ["Information", "Source", "Reference", "Citation"],
  },
  {
    word: "achieve",
    topics: ["Goals", "Success", "Accomplishment", "Effort"],
  },
  {
    word: "act",
    topics: ["Action", "Behavior", "Performance", "Drama"],
  },
  {
    word: "active",
    topics: ["General", "Energy", "Engaged", "Movement"],
  },
  {
    word: "actually",
    topics: ["Truth", "Real", "Fact", "Correction"],
  },
  {
    word: "adult",
    topics: ["Age", "Maturity", "Responsibility", "Growth"],
  },
  {
    word: "advantage",
    topics: ["Benefit", "Positive", "Favorable", "Competitive"],
  },
  {
    word: "adventure",
    topics: ["Travel", "Excitement", "Risk", "Exploration"],
  },
  {
    word: "advertise",
    topics: ["Marketing", "Promotion", "Media", "Business"],
  },
  {
    word: "advertisement",
    topics: ["Marketing", "Media", "Visuals", "Commercial"],
  },
  {
    word: "advertising",
    topics: ["Marketing", "Business", "Media", "Industry"],
  },
  {
    word: "affect",
    topics: ["Influence", "Impact", "Change", "Emotion"],
  },
  {
    word: "after",
    topics: ["Time", "Sequence", "Following", "Consequence"],
  },
  {
    word: "against",
    topics: ["Opposition", "Conflict", "Contrast", "Direction"],
  },
  {
    word: "ah",
    topics: ["Exclamation", "Surprise", "Pain", "Relief"],
  },
  {
    word: "airline",
    topics: ["Travel", "Transportation", "Aviation", "Industry"],
  },
  {
    word: "alive",
    topics: ["Life", "Existence", "Living", "Survival"],
  },
  {
    word: "all",
    topics: ["Quantity", "Whole", "Everything", "General"],
  },
  {
    word: "all right",
    topics: ["Agreement", "Okay", "Acceptable", "Fine"],
  },
  {
    word: "allow",
    topics: ["Permission", "Permit", "Enable", "Grant"],
  },
  {
    word: "almost",
    topics: ["Quantity", "Near", "Close", "Not quite"],
  },
  {
    word: "alone",
    topics: ["Companionship", "Solitude", "Isolated", "Independent"],
  },
  {
    word: "along",
    topics: ["Direction", "Movement", "Together", "With"],
  },
  {
    word: "already",
    topics: ["Time", "Past", "Previously", "Done"],
  },
  {
    word: "alternative",
    topics: ["Choice", "Option", "Different", "Substitute"],
  },
  {
    word: "although",
    topics: ["Contrast", "Despite", "Even though", "However"],
  },
  {
    word: "among",
    topics: ["Location", "Group", "Included", "Within"],
  },
  {
    word: "amount",
    topics: ["Quantity", "Number", "Size", "Extent"],
  },
  {
    word: "ancient",
    topics: ["History", "Old", "Past", "Civilization"],
  },
  {
    word: "ankle",
    topics: ["Body", "Part", "Injury", "Movement"],
  },
  {
    word: "any",
    topics: ["Quantity", "Some", "One or more", "Unspecified"],
  },
  {
    word: "anybody",
    topics: ["People", "Someone", "Person", "Unspecified"],
  },
  {
    word: "any more",
    topics: ["Quantity", "No longer", "Not anymore", "Finished"],
  },
  {
    word: "anyway",
    topics: ["Despite", "Regardless", "Nevertheless", "Moving on"],
  },
  {
    word: "anywhere",
    topics: ["Location", "Place", "Unspecified", "Everywhere"],
  },
  {
    word: "app",
    topics: ["Technology", "Software", "Mobile", "Application"],
  },
  {
    word: "appear",
    topics: ["Visibility", "Show", "Seem", "Become visible"],
  },
  {
    word: "appearance",
    topics: ["Look", "Visual", "Style", "Outward impression"],
  },
  {
    word: "apply",
    topics: ["Action", "Use", "Request", "Put into practice"],
  },
  {
    word: "architect",
    topics: ["Profession", "Building", "Design", "Construction"],
  },
  {
    word: "architecture",
    topics: ["Building", "Design", "Style", "Structure"],
  },
  {
    word: "argue",
    topics: ["Disagreement", "Debate", "Reason", "Persuade"],
  },
  {
    word: "argument",
    topics: ["Disagreement", "Debate", "Reasoning", "Dispute"],
  },
  {
    word: "army",
    topics: ["Military", "Force", "Soldiers", "Defense"],
  },
  {
    word: "arrange",
    topics: ["Organize", "Plan", "Order", "Prepare"],
  },
  {
    word: "arrangement",
    topics: ["Plan", "Order", "Organization", "Structure"],
  },
  {
    word: "as",
    topics: ["Comparison", "Because", "In the same way", "While"],
  },
  {
    word: "asleep",
    topics: ["Sleep", "Rest", "Unconscious", "Sleeping"],
  },
  {
    word: "assistant",
    topics: ["Help", "Support", "Aider", "Employee"],
  },
  {
    word: "athlete",
    topics: ["Sport", "Competition", "Fitness", "Performance"],
  },
  {
    word: "attack",
    topics: ["Violence", "Aggression", "Assault", "Military"],
  },
  {
    word: "attend",
    topics: ["Event", "Be present", "Go to", "Participate"],
  },
  {
    word: "attention",
    topics: ["Focus", "Concentration", "Notice", "Interest"],
  },
  {
    word: "attractive",
    topics: ["Appearance", "Beautiful", "Pleasant", "Appealing"],
  },
  {
    word: "audience",
    topics: ["People", "Spectators", "Viewers", "Listeners"],
  },
  {
    word: "author",
    topics: ["Writer", "Book", "Literature", "Creation"],
  },
  {
    word: "available",
    topics: ["Access", "Present", "Accessible", "Obtainable"],
  },
  {
    word: "average",
    topics: ["General", "Typical", "Common", "Middling"],
  },
  {
    word: "avoid",
    topics: ["Prevent", "Escape", "Keep away", "Stay clear"],
  },
  {
    word: "award",
    topics: ["Recognition", "Prize", "Honour", "Achievement"],
  },
  {
    word: "awful",
    topics: ["Bad", "Terrible", "Unpleasant", "Horrible"],
  },
  {
    word: "back",
    topics: ["Direction", "Behind", "Opposite", "Support"],
  },
  {
    word: "background",
    topics: ["History", "Context", "Scene", "Past"],
  },
  {
    word: "badly",
    topics: ["Quality", "Poorly", "Negatively", "Unwell"],
  },
  {
    word: "bar",
    topics: ["Place", "Drink", "Obstacle", "Metal"],
  },
  {
    word: "baseball",
    topics: ["Sport", "Game", "Team", "Baseball"],
  },
  {
    word: "based",
    topics: ["Location", "Foundation", "Origin", "Derived"],
  },
  {
    word: "basketball",
    topics: ["Sport", "Game", "Team", "Basketball"],
  },
  {
    word: "bean",
    topics: ["Food", "Vegetable", "Plant", "Ingredient"],
  },
  {
    word: "bear (animal)",
    topics: ["Animal", "Wildlife", "Forest", "Nature"],
  },
  {
    word: "beat",
    topics: ["Music", "Sound", "Overcome", "Win"],
  },
  {
    word: "beef",
    topics: ["Food", "Meat", "Animal", "Ingredient"],
  },
  {
    word: "before",
    topics: ["Time", "Earlier", "Prior", "Preceding"],
  },
  {
    word: "behave",
    topics: ["Action", "Conduct", "Manners", "Act"],
  },
  {
    word: "behaviour",
    topics: ["Action", "Conduct", "Manners", "Personality"],
  },
  {
    word: "belong",
    topics: ["Ownership", "Possession", "Part of", "Fit in"],
  },
  {
    word: "belt",
    topics: ["Clothing", "Accessory", "Object", "Leather"],
  },
  {
    word: "benefit",
    topics: ["Advantage", "Good", "Positive", "Help"],
  },
  {
    word: "best",
    topics: ["Quality", "Superior", "Excellent", "Top"],
  },
  {
    word: "better",
    topics: ["Comparison", "Improved", "More good", "Superior"],
  },
  {
    word: "between",
    topics: ["Location", "Middle", "Two things", "Shared"],
  },
  {
    word: "billion",
    topics: ["Numbers", "Quantity", "Large", "Money"],
  },
  {
    word: "bin",
    topics: ["Waste", "Trash", "Container", "Recycling"],
  },
  {
    word: "biology",
    topics: ["Science", "Life", "Living things", "Study"],
  },
  {
    word: "birth",
    topics: ["Life", "Beginning", "Baby", "Event"],
  },
  {
    word: "biscuit",
    topics: ["Food", "Snack", "Bakery", "Treat"],
  },
  {
    word: "bit",
    topics: ["Small amount", "Piece", "Part", "Computer"],
  },
  {
    word: "blank",
    topics: ["Empty", "Unfilled", "Space", "Paper"],
  },
  {
    word: "blood",
    topics: ["Body", "Fluid", "Medical", "Life"],
  },
  {
    word: "blow",
    topics: ["Air", "Wind", "Action", "Sound"],
  },
  {
    word: "board",
    topics: ["Surface", "Wood", "Game", "Meeting"],
  },
  {
    word: "boil",
    topics: ["Heat", "Water", "Cook", "Process"],
  },
  {
    word: "bone",
    topics: ["Body", "Skeleton", "Structure", "Medical"],
  },
  {
    word: "book",
    topics: ["Literature", "Reading", "Story", "Paper"],
  },
  {
    word: "borrow",
    topics: ["Lend", "Take", "Use", "Temporary"],
  },
  {
    word: "boss",
    topics: ["Work", "Leader", "Manager", "Authority"],
  },
  {
    word: "bottom",
    topics: ["Position", "Lowest", "End", "Base"],
  },
  {
    word: "bowl",
    topics: ["Container", "Food", "Dish", "Round"],
  },
  {
    word: "brain",
    topics: ["Body", "Mind", "Thinking", "Intelligence"],
  },
  {
    word: "bridge",
    topics: ["Structure", "Construction", "Passage", "Connection"],
  },
  {
    word: "bright",
    topics: ["Light", "Color", "Intelligent", "Cheerful"],
  },
  {
    word: "brilliant",
    topics: ["Exceptional", "Smart", "Amazing", "Excellent"],
  },
  {
    word: "broken",
    topics: ["Damaged", "Repaired", "Not working", "Broken"],
  },
  {
    word: "brush",
    topics: ["Cleaning", "Hair", "Tool", "Paint"],
  },
  {
    word: "burn",
    topics: ["Fire", "Heat", "Damage", "Pain"],
  },
  {
    word: "businessman",
    topics: ["Business", "Work", "Professional", "Leader"],
  },
  {
    word: "button",
    topics: ["Clothing", "Object", "Control", "Press"],
  },
  {
    word: "camp",
    topics: ["Outdoors", "Nature", "Tent", "Camping"],
  },
  {
    word: "camping",
    topics: ["Outdoors", "Nature", "Tent", "Holiday"],
  },
  {
    word: "can",
    topics: ["Ability", "Possible", "Container", "Food"],
  },
  {
    word: "care",
    topics: ["Attention", "Concern", "Love", "Responsibility"],
  },
  {
    word: "careful",
    topics: ["Cautious", "Attentive", "Safe", "Mindful"],
  },
  {
    word: "carefully",
    topics: ["Manner", "Cautiously", "Attentively", "Precisely"],
  },
  {
    word: "carpet",
    topics: ["Floor", "Covering", "Material", "Soft"],
  },
  {
    word: "cartoon",
    topics: ["Animation", "Drawing", "Film", "Funny"],
  },
  {
    word: "case",
    topics: ["Situation", "Reason", "Box", "Legal"],
  },
  {
    word: "cash",
    topics: ["Money", "Payment", "Currency", "Coins"],
  },
  {
    word: "castle",
    topics: ["Building", "History", "King", "Defense"],
  },
  {
    word: "catch",
    topics: ["Action", "Grab", "Receive", "Sport"],
  },
  {
    word: "cause",
    topics: ["Reason", "Origin", "Effect", "Problem"],
  },
  {
    word: "celebrate",
    topics: ["Event", "Party", "Happy", "Special"],
  },
  {
    word: "celebrity",
    topics: ["Famous", "Star", "Person", "Media"],
  },
  {
    word: "certain",
    topics: ["Sure", "Confident", "Specific", "Without doubt"],
  },
  {
    word: "certainly",
    topics: ["Sure", "Definitely", "Without doubt", "Affirmation"],
  },
  {
    word: "chance",
    topics: ["Opportunity", "Possibility", "Luck", "Risk"],
  },
  {
    word: "character",
    topics: ["Personality", "Person", "Story", "Role"],
  },
  {
    word: "charity",
    topics: ["Help", "Donation", "Organization", "Kindness"],
  },
  {
    word: "chat",
    topics: ["Talk", "Conversation", "Informal", "Friendly"],
  },
  {
    word: "check",
    topics: ["Examine", "Verify", "Look at", "Inspect"],
  },
  {
    word: "chef",
    topics: ["Cook", "Food", "Restaurant", "Profession"],
  },
  {
    word: "chemistry",
    topics: ["Science", "Elements", "Reactions", "Study"],
  },
  {
    word: "chip",
    topics: ["Food", "Small piece", "Computer", "Technology"],
  },
  {
    word: "choice",
    topics: ["Decision", "Option", "Selection", "Preference"],
  },
  {
    word: "church",
    topics: ["Religion", "Building", "Worship", "Community"],
  },
  {
    word: "cigarette",
    topics: ["Smoking", "Tobacco", "Health", "Habit"],
  },
  {
    word: "circle",
    topics: ["Shape", "Round", "Geometry", "Symbol"],
  },
  {
    word: "classical",
    topics: ["Music", "Art", "Traditional", "Old"],
  },
  {
    word: "clear",
    topics: ["Visible", "Understandable", "Obvious", "Clean"],
  },
  {
    word: "clearly",
    topics: ["Obviously", "Understandably", "Plainly", "Precisely"],
  },
  {
    word: "clever",
    topics: ["Smart", "Intelligent", "Talented", "Skillful"],
  },
  {
    word: "climate",
    topics: ["Weather", "Environment", "Temperature", "Nature"],
  },
  {
    word: "close",
    topics: ["Near", "Shut", "Relationship", "End"],
  },
  {
    word: "closed",
    topics: ["Shut", "Not open", "Finished", "Locked"],
  },
  {
    word: "clothing",
    topics: ["Clothes", "Garments", "Fashion", "Wear"],
  },
  {
    word: "cloud",
    topics: ["Weather", "Sky", "Water", "Technology"],
  },
  {
    word: "coach",
    topics: ["Sports", "Training", "Teacher", "Guidance"],
  },
  {
    word: "coast",
    topics: ["Geography", "Sea", "Shore", "Beach"],
  },
  {
    word: "code",
    topics: ["Computer", "Programming", "Secret", "Language"],
  },
  {
    word: "colleague",
    topics: ["Work", "Coworker", "Team", "Professional"],
  },
  {
    word: "collect",
    topics: ["Gather", "Assemble", "Hobby", "Items"],
  },
  {
    word: "column",
    topics: ["Structure", "Shape", "Newspaper", "Writing"],
  },
  {
    word: "comedy",
    topics: ["Entertainment", "Humor", "Funny", "Film"],
  },
  {
    word: "comfortable",
    topics: ["Feeling", "Relaxed", "Cozy", "Pleasant"],
  },
  {
    word: "comment",
    topics: ["Opinion", "Feedback", "Response", "Discussion"],
  },
  {
    word: "communicate",
    topics: ["Talk", "Share", "Connect", "Express"],
  },
  {
    word: "community",
    topics: ["People", "Group", "Local", "Together"],
  },
  {
    word: "compete",
    topics: ["Challenge", "Race", "Game", "Against"],
  },
  {
    word: "competition",
    topics: ["Challenge", "Race", "Contest", "Sport"],
  },
  {
    word: "complain",
    topics: ["Dissatisfied", "Unhappy", "Problem", "Express"],
  },
  {
    word: "completely",
    topics: ["Totally", "Entirely", "Fully", "Absolutely"],
  },
  {
    word: "condition",
    topics: ["State", "Health", "Situation", "Requirement"],
  },
  {
    word: "conference",
    topics: ["Meeting", "Event", "Discussion", "Professional"],
  },
  {
    word: "connect",
    topics: ["Link", "Join", "Relate", "Connect"],
  },
  {
    word: "connected",
    topics: ["Linked", "Related", "Joined", "Together"],
  },
  {
    word: "consider",
    topics: ["Think", "Evaluate", "Reflect", "Decide"],
  },
  {
    word: "contain",
    topics: ["Hold", "Include", "Have", "Inside"],
  },
  {
    word: "context",
    topics: ["Situation", "Background", "Meaning", "Understanding"],
  },
  {
    word: "continent",
    topics: ["Geography", "Landmass", "World", "Large"],
  },
  {
    word: "continue",
    topics: ["Keep going", "Carry on", "Proceed", "Last"],
  },
  {
    word: "control",
    topics: ["Power", "Manage", "Command", "Influence"],
  },
  {
    word: "cook",
    topics: ["Food", "Prepare", "Chef", "Kitchen"],
  },
  {
    word: "cooker",
    topics: ["Kitchen", "Appliance", "Heat", "Cooking"],
  },
  {
    word: "copy",
    topics: ["Duplicate", "Imitate", "Take", "Clone"],
  },
  {
    word: "corner",
    topics: ["Position", "Edge", "Location", "Angle"],
  },
  {
    word: "correctly",
    topics: ["Right", "Accurate", "Precisely", "Without errors"],
  },
  {
    word: "count",
    topics: ["Number", "Quantity", "Calculate", "Add"],
  },
  {
    word: "couple",
    topics: ["Two", "Pair", "Relationship", "Small group"],
  },
  {
    word: "cover",
    topics: ["Protect", "Conceal", "Surface", "Book"],
  },
  {
    word: "crazy",
    topics: ["Mad", "Unreasonable", "Strange", "Excited"],
  },
  {
    word: "creative",
    topics: ["Imaginative", "Artistic", "Original", "Innovative"],
  },
  {
    word: "credit",
    topics: ["Money", "Loan", "Trust", "Recognition"],
  },
  {
    word: "crime",
    topics: ["Illegal", "Wrongdoing", "Law", "Punishment"],
  },
  {
    word: "criminal",
    topics: ["Lawbreaker", "Guilty", "Offender", "Prisoner"],
  },
  {
    word: "cross",
    topics: ["Shape", "Symbol", "Direction", "Go over"],
  },
  {
    word: "crowd",
    topics: ["People", "Many", "Group", "Gather"],
  },
  {
    word: "crowded",
    topics: ["Full", "Busy", "Packed", "Many people"],
  },
  {
    word: "cry",
    topics: ["Tears", "Sadness", "Emotion", "Sound"],
  },
  {
    word: "cupboard",
    topics: ["Storage", "Kitchen", "Cabinet", "Shelves"],
  },
  {
    word: "curly",
    topics: ["Hair", "Shape", "Appearance", "Style"],
  },
  {
    word: "cycle",
    topics: ["Bike", "Ride", "Movement", "Repeat"],
  },
  {
    word: "daily",
    topics: ["Time", "Regular", "Everyday", "Routine"],
  },
  {
    word: "danger",
    topics: ["Risk", "Threat", "Hazard", "Unsafe"],
  },
  {
    word: "dark",
    topics: ["Light", "Color", "Night", "Mystery"],
  },
  {
    word: "data",
    topics: ["Information", "Facts", "Computer", "Statistics"],
  },
  {
    word: "dead",
    topics: ["Life", "Death", "No longer alive", "Passed away"],
  },
  {
    word: "deal",
    topics: ["Agreement", "Business", "Action", "Handle"],
  },
  {
    word: "dear",
    topics: ["Love", "Affectionate", "Expensive", "Valued"],
  },
  {
    word: "death",
    topics: ["Life", "End", "Passing", "Sadness"],
  },
  {
    word: "decision",
    topics: ["Choice", "Choice", "Action", "Mind"],
  },
  {
    word: "deep",
    topics: ["Depth", "Distance", "Strong", "Intense"],
  },
  {
    word: "definitely",
    topics: ["Sure", "Certainly", "Without doubt", "Affirmation"],
  },
  {
    word: "degree",
    topics: ["Education", "Qualification", "Temperature", "Extent"],
  },
  {
    word: "dentist",
    topics: ["Health", "Teeth", "Doctor", "Profession"],
  },
  {
    word: "department",
    topics: ["Organization", "Section", "Work", "Division"],
  },
  {
    word: "depend",
    topics: ["Reliance", "Trust", "Condition", "Factor"],
  },
  {
    word: "desert",
    topics: ["Geography", "Dry", "Sand", "Hot"],
  },
  {
    word: "designer",
    topics: ["Fashion", "Art", "Create", "Professional"],
  },
  {
    word: "destroy",
    topics: ["Damage", "Ruin", "Break", "Wipe out"],
  },
  {
    word: "detective",
    topics: ["Crime", "Mystery", "Investigate", "Police"],
  },
  {
    word: "develop",
    topics: ["Grow", "Progress", "Improve", "Create"],
  },
  {
    word: "device",
    topics: ["Technology", "Tool", "Gadget", "Equipment"],
  },
  {
    word: "diary",
    topics: ["Writing", "Personal", "Record", "Journal"],
  },
  {
    word: "differently",
    topics: ["Change", "Variation", "Not the same", "Uniquely"],
  },
  {
    word: "digital",
    topics: ["Technology", "Computer", "Online", "Electronic"],
  },
  {
    word: "direct",
    topics: ["Straight", "Control", "Lead", "Command"],
  },
  {
    word: "direction",
    topics: ["Way", "Course", "Path", "Guidance"],
  },
  {
    word: "director",
    topics: ["Film", "Leader", "Manage", "Control"],
  },
  {
    word: "disagree",
    topics: ["Opinion", "Conflict", "Different", "Opposite"],
  },
  {
    word: "disappear",
    topics: ["Vanish", "Gone", "Hide", "Invisible"],
  },
  {
    word: "disaster",
    topics: ["Problem", "Tragedy", "Bad event", "Catastrophe"],
  },
  {
    word: "discover",
    topics: ["Find", "Learn", "Explore", "Uncover"],
  },
  {
    word: "discovery",
    topics: ["Finding", "New knowledge", "Exploration", "Science"],
  },
  {
    word: "discussion",
    topics: ["Talk", "Conversation", "Debate", "Share ideas"],
  },
  {
    word: "disease",
    topics: ["Illness", "Health", "Medical", "Sickness"],
  },
  {
    word: "distance",
    topics: ["Space", "Length", "Far", "Separation"],
  },
  {
    word: "divorced",
    topics: ["Relationship", "Marriage", "Separated", "Legal"],
  },
  {
    word: "document",
    topics: ["Paper", "Information", "Record", "Proof"],
  },
  {
    word: "double",
    topics: ["Two", "Twice", "Increase", "Amount"],
  },
  {
    word: "download",
    topics: ["Computer", "Internet", "File", "Get"],
  },
  {
    word: "downstairs",
    topics: ["Location", "Below", "Building", "Direction"],
  },
  {
    word: "drama",
    topics: ["Story", "Play", "Emotion", "Entertainment"],
  },
  {
    word: "drawing",
    topics: ["Art", "Picture", "Sketch", "Create"],
  },
  {
    word: "dream",
    topics: ["Sleep", "Imagination", "Goal", "Fantasy"],
  },
  {
    word: "drive",
    topics: ["Car", "Travel", "Force", "Motivation"],
  },
  {
    word: "driving",
    topics: ["Car", "Travel", "Action", "Transportation"],
  },
  {
    word: "drop",
    topics: ["Fall", "Release", "Reduce", "Action"],
  },
  {
    word: "drug",
    topics: ["Medicine", "Substance", "Health", "Illegal"],
  },
  {
    word: "dry",
    topics: ["Water", "Weather", "Clean", "Not wet"],
  },
  {
    word: "earn",
    topics: ["Money", "Work", "Salary", "Get"],
  },
  {
    word: "earth",
    topics: ["Planet", "World", "Ground", "Nature"],
  },
  {
    word: "easily",
    topics: ["Effort", "Simple", "Without difficulty", "Quickly"],
  },
  {
    word: "education",
    topics: ["Learning", "School", "Knowledge", "Development"],
  },
  {
    word: "effect",
    topics: ["Result", "Impact", "Change", "Consequence"],
  },
  {
    word: "either",
    topics: ["Choice", "Option", "One or the other", "Alternative"],
  },
  {
    word: "electric",
    topics: ["Power", "Energy", "Electricity", "Current"],
  },
  {
    word: "electrical",
    topics: ["Power", "Energy", "Electricity", "Systems"],
  },
  {
    word: "electricity",
    topics: ["Power", "Energy", "Current", "Lights"],
  },
  {
    word: "electronic",
    topics: ["Technology", "Digital", "Devices", "Gadgets"],
  },
  {
    word: "employ",
    topics: ["Work", "Hire", "Job", "Business"],
  },
  {
    word: "employee",
    topics: ["Work", "Person", "Job", "Company"],
  },
  {
    word: "employer",
    topics: ["Work", "Company", "Boss", "Business"],
  },
  {
    word: "empty",
    topics: ["Content", "Void", "Unfilled", "Lacking"],
  },
  {
    word: "ending",
    topics: ["Finish", "Conclusion", "Last part", "Termination"],
  },
  {
    word: "energy",
    topics: ["Power", "Strength", "Force", "Activity"],
  },
  {
    word: "engine",
    topics: ["Machine", "Car", "Power", "Movement"],
  },
  {
    word: "engineer",
    topics: ["Profession", "Design", "Build", "Technology"],
  },
  {
    word: "enormous",
    topics: ["Size", "Huge", "Massive", "Very large"],
  },
  {
    word: "enter",
    topics: ["Go in", "Join", "Start", "Access"],
  },
  {
    word: "environment",
    topics: ["Nature", "Surroundings", "Ecology", "World"],
  },
  {
    word: "equipment",
    topics: ["Tools", "Gear", "Machines", "Supplies"],
  },
  {
    word: "error",
    topics: ["Mistake", "Fault", "Problem", "Wrong"],
  },
  {
    word: "especially",
    topics: ["Emphasis", "Mainly", "Specifically", "Particularly"],
  },
  {
    word: "essay",
    topics: ["Writing", "School", "Argument", "Literature"],
  },
  {
    word: "everyday",
    topics: ["Normal", "Common", "Regular", "Routine"],
  },
  {
    word: "everywhere",
    topics: ["Location", "All places", "Widely", "Everywhere"],
  },
  {
    word: "evidence",
    topics: ["Proof", "Facts", "Support", "Investigation"],
  },
  {
    word: "exact",
    topics: ["Precise", "Accurate", "Specific", "Correct"],
  },
  {
    word: "exactly",
    topics: ["Precisely", "Accurately", "Without doubt", "Completely"],
  },
  {
    word: "excellent",
    topics: ["Good", "Amazing", "Superb", "Wonderful"],
  },
  {
    word: "except",
    topics: ["Not including", "But", "Besides", "Excluding"],
  },
  {
    word: "exist",
    topics: ["Be", "Live", "Present", "Real"],
  },
  {
    word: "expect",
    topics: ["Anticipate", "Think", "Hope", "Believe"],
  },
  {
    word: "experience",
    topics: ["Events", "Knowledge", "Learning", "Live"],
  },
  {
    word: "experiment",
    topics: ["Science", "Test", "Try", "Discover"],
  },
  {
    word: "expert",
    topics: ["Knowledge", "Skill", "Professional", "Specialist"],
  },
  {
    word: "explanation",
    topics: ["Reason", "Answer", "Understanding", "Description"],
  },
  {
    word: "express",
    topics: ["Show", "Say", "Communicate", "Feel"],
  },
  {
    word: "expression",
    topics: ["Words", "Face", "Art", "Communication"],
  },
  {
    word: "extreme",
    topics: ["Very", "High", "Strong", "Unusual"],
  },
  {
    word: "extremely",
    topics: ["Very", "Really", "Intensely", "Highly"],
  },
  {
    word: "factor",
    topics: ["Reason", "Part", "Cause", "Influence"],
  },
  {
    word: "factory",
    topics: ["Industry", "Production", "Machines", "Work"],
  },
  {
    word: "fail",
    topics: ["Not succeed", "Fail", "Mistake", "Problem"],
  },
  {
    word: "fair",
    topics: ["Just", "Equal", "Honest", "Event"],
  },
  {
    word: "fall",
    topics: ["Drop", "Descend", "Autumn", "Action"],
  },
  {
    word: "fan",
    topics: ["Air", "Device", "Support", "Enthusiast"],
  },
  {
    word: "farm",
    topics: ["Agriculture", "Land", "Food", "Animals"],
  },
  {
    word: "farming",
    topics: ["Agriculture", "Work", "Land", "Food"],
  },
  {
    word: "fashion",
    topics: ["Style", "Clothes", "Trend", "Design"],
  },
  {
    word: "fat",
    topics: ["Body", "Food", "Heavy", "Unhealthy"],
  },
  {
    word: "fear",
    topics: ["Emotion", "Anxiety", "Danger", "Phobia"],
  },
  {
    word: "feature",
    topics: ["Characteristic", "Part", "Detail", "Highlight"],
  },
  {
    word: "feed",
    topics: ["Food", "Give food", "Nourish", "Animals"],
  },
  {
    word: "female",
    topics: ["Gender", "Woman", "Girl", "Sex"],
  },
  {
    word: "fiction",
    topics: ["Story", "Book", "Imagination", "Literature"],
  },
  {
    word: "field",
    topics: ["Land", "Farm", "Sports", "Area"],
  },
  {
    word: "fight",
    topics: ["Conflict", "Battle", "Violence", "Action"],
  },
  {
    word: "figure",
    topics: ["Shape", "Number", "Person", "Understand"],
  },
  {
    word: "film",
    topics: ["Movie", "Cinema", "Entertainment", "Art"],
  },
  {
    word: "final",
    topics: ["Last", "End", "Conclusion", "Decision"],
  },
  {
    word: "finally",
    topics: ["Time", "End", "At last", "After a long time"],
  },
  {
    word: "finger",
    topics: ["Body", "Hand", "Point", "Touch"],
  },
  {
    word: "finish",
    topics: ["End", "Complete", "Stop", "Done"],
  },
  {
    word: "first",
    topics: ["Order", "Beginning", "Number one", "Initial"],
  },
  {
    word: "firstly",
    topics: ["Order", "Start", "First step", "Beginning"],
  },
  {
    word: "fish",
    topics: ["Animal", "Water", "Food", "Sport"],
  },
  {
    word: "fishing",
    topics: ["Sport", "Hobby", "Water", "Catch"],
  },
  {
    word: "fit",
    topics: ["Healthy", "Size", "Suitable", "Match"],
  },
  {
    word: "fix",
    topics: ["Repair", "Mend", "Solve", "Correct"],
  },
  {
    word: "flat",
    topics: ["Shape", "Level", "Apartment", "Tire"],
  },
  {
    word: "flu",
    topics: ["Illness", "Sickness", "Virus", "Cough"],
  },
  {
    word: "fly",
    topics: ["Bird", "Airplane", "Move", "Action"],
  },
  {
    word: "flying",
    topics: ["Airplane", "Bird", "Movement", "Air"],
  },
  {
    word: "focus",
    topics: ["Attention", "Concentrate", "Target", "Direction"],
  },
  {
    word: "following",
    topics: ["After", "Next", "Next step", "Sequence"],
  },
  {
    word: "foreign",
    topics: ["Country", "Language", "Different", "International"],
  },
  {
    word: "forest",
    topics: ["Nature", "Trees", "Woods", "Wildlife"],
  },
  {
    word: "fork",
    topics: ["Eating", "Utensil", "Metal", "Food"],
  },
  {
    word: "formal",
    topics: ["Style", "Serious", "Official", "Proper"],
  },
  {
    word: "fortunately",
    topics: ["Good", "Lucky", "Happily", "Positive"],
  },
  {
    word: "forward",
    topics: ["Direction", "Ahead", "Progress", "Future"],
  },
  {
    word: "free",
    topics: ["Cost", "Liberty", "Available", "Without"],
  },
  {
    word: "fresh",
    topics: ["New", "Clean", "Food", "Healthy"],
  },
  {
    word: "fridge",
    topics: ["Kitchen", "Food", "Cold", "Storage"],
  },
  {
    word: "frog",
    topics: ["Animal", "Amphibian", "Water", "Green"],
  },
  {
    word: "fun",
    topics: ["Enjoyment", "Pleasure", "Happy", "Entertainment"],
  },
  {
    word: "furniture",
    topics: ["Home", "Chairs", "Table", "Objects"],
  },
  {
    word: "further",
    topics: ["Distance", "More", "Additional", "Beyond"],
  },
  {
    word: "future",
    topics: ["Time", "To come", "Tomorrow", "Prediction"],
  },
  {
    word: "gallery",
    topics: ["Art", "Museum", "Exhibition", "Pictures"],
  },
  {
    word: "gap",
    topics: ["Space", "Difference", "Hole", "Missing"],
  },
  {
    word: "gas",
    topics: ["Fuel", "Energy", "Substance", "Air"],
  },
  {
    word: "gate",
    topics: ["Entrance", "Barrier", "Open", "Close"],
  },
  {
    word: "general",
    topics: ["Common", "Typical", "Overall", "Not specific"],
  },
  {
    word: "gift",
    topics: ["Present", "Special", "Giving", "Occasion"],
  },
  {
    word: "goal",
    topics: ["Aim", "Target", "Objective", "Desire"],
  },
  {
    word: "god",
    topics: ["Religion", "Spirituality", "Belief", "Creator"],
  },
  {
    word: "gold",
    topics: ["Metal", "Precious", "Color", "Wealth"],
  },
  {
    word: "golf",
    topics: ["Sport", "Game", "Club", "Course"],
  },
  {
    word: "good",
    topics: ["Quality", "Positive", "Nice", "Excellent"],
  },
  {
    word: "government",
    topics: ["Country", "Politics", "Rules", "Leaders"],
  },
  {
    word: "grass",
    topics: ["Nature", "Green", "Field", "Ground"],
  },
  {
    word: "greet",
    topics: ["Hello", "Welcome", "Say hello", "Formal"],
  },
  {
    word: "ground",
    topics: ["Earth", "Surface", "Soil", "Below"],
  },
  {
    word: "guest",
    topics: ["Visitor", "Stay", "Hotel", "Welcome"],
  },
  {
    word: "guide",
    topics: ["Help", "Show", "Information", "Leader"],
  },
  {
    word: "gun",
    topics: ["Weapon", "Fire", "Dangerous", "Shoot"],
  },
  {
    word: "guy",
    topics: ["Person", "Man", "Informal", "Friend"],
  },
  {
    word: "habit",
    topics: ["Routine", "Regular", "Custom", "Behavior"],
  },
  {
    word: "half",
    topics: ["Part", "Two halves", "Portion", "Amount"],
  },
  {
    word: "hall",
    topics: ["Building", "Room", "Entrance", "Large"],
  },
  {
    word: "happily",
    topics: ["Happy", "Joyfully", "With pleasure", "Gladly"],
  },
  {
    word: "have",
    topics: ["Possess", "Own", "Experience", "Get"],
  },
  {
    word: "headache",
    topics: ["Pain", "Head", "Illness", "Medical"],
  },
  {
    word: "heart",
    topics: ["Body", "Love", "Feeling", "Emotion"],
  },
  {
    word: "heat",
    topics: ["Temperature", "Warm", "Fire", "Energy"],
  },
  {
    word: "heavy",
    topics: ["Weight", "Difficult", "Serious", "Large"],
  },
  {
    word: "height",
    topics: ["Tall", "Measure", "Distance", "Size"],
  },
  {
    word: "helpful",
    topics: ["Kind", "Assist", "Support", "Useful"],
  },
  {
    word: "hero",
    topics: ["Brave", "Courage", "Story", "Admiration"],
  },
  {
    word: "hers",
    topics: ["Possession", "Belongs to her", "Female", "Ownership"],
  },
  {
    word: "herself",
    topics: ["Self", "Alone", "Independent", "Reflexive"],
  },
  {
    word: "hide",
    topics: ["Secret", "Conceal", "Disappear", "Protect"],
  },
  {
    word: "high",
    topics: ["Height", "Level", "Large", "Loud"],
  },
  {
    word: "hill",
    topics: ["Geography", "Land", "Slope", "Mountain"],
  },
  {
    word: "himself",
    topics: ["Self", "Alone", "Independent", "Reflexive"],
  },
  {
    word: "his",
    topics: ["Possession", "Belongs to him", "Male", "Ownership"],
  },
  {
    word: "hit",
    topics: ["Strike", "Touch", "Action", "Sound"],
  },
  {
    word: "hockey",
    topics: ["Sport", "Game", "Ice", "Stick"],
  },
  {
    word: "hold",
    topics: ["Grip", "Keep", "Possess", "Action"],
  },
  {
    word: "hole",
    topics: ["Empty space", "Gap", "Dig", "Ground"],
  },
  {
    word: "home",
    topics: ["House", "Place", "Family", "Live"],
  },
  {
    word: "hope",
    topics: ["Desire", "Wish", "Believe", "Future"],
  },
  {
    word: "huge",
    topics: ["Size", "Big", "Enormous", "Large"],
  },
  {
    word: "human",
    topics: ["Person", "People", "Species", "Life"],
  },
  {
    word: "hurt",
    topics: ["Pain", "Damage", "Injury", "Emotion"],
  },
  {
    word: "ideal",
    topics: ["Perfect", "Best", "Desired", "Goal"],
  },
  {
    word: "identify",
    topics: ["Recognize", "Know", "Find", "Label"],
  },
  {
    word: "ill",
    topics: ["Sick", "Unhealthy", "Disease", "Not well"],
  },
  {
    word: "illness",
    topics: ["Sickness", "Disease", "Health", "Medical"],
  },
  {
    word: "image",
    topics: ["Picture", "Visual", "Idea", "Representation"],
  },
  {
    word: "immediately",
    topics: ["Quickly", "Right away", "At once", "Without delay"],
  },
  {
    word: "impossible",
    topics: ["Unlikely", "Not possible", "Difficult", "Unachievable"],
  },
  {
    word: "included",
    topics: ["Part of", "Inside", "Contained", "Included"],
  },
  {
    word: "including",
    topics: ["With", "Together with", "Also", "In addition to"],
  },
  {
    word: "increase",
    topics: ["Grow", "Larger", "More", "Rise"],
  },
  {
    word: "incredible",
    topics: ["Amazing", "Unbelievable", "Wonderful", "Great"],
  },
  {
    word: "independent",
    topics: ["Free", "Alone", "Self-sufficient", "Autonomous"],
  },
  {
    word: "individual",
    topics: ["Person", "Unique", "Single", "Separate"],
  },
  {
    word: "industry",
    topics: ["Work", "Business", "Production", "Factories"],
  },
  {
    word: "informal",
    topics: ["Casual", "Relaxed", "Unofficial", "Friendly"],
  },
  {
    word: "injury",
    topics: ["Damage", "Hurt", "Pain", "Medical"],
  },
  {
    word: "insect",
    topics: ["Animal", "Small", "Bug", "Nature"],
  },
  {
    word: "inside",
    topics: ["Location", "Within", "Interior", "Opposite of outside"],
  },
  {
    word: "instead",
    topics: ["Alternative", "Change", "Rather", "In place of"],
  },
  {
    word: "instruction",
    topics: ["Direction", "Order", "Guide", "Teaching"],
  },
  {
    word: "instructor",
    topics: ["Teacher", "Trainer", "Guide", "Leader"],
  },
  {
    word: "instrument",
    topics: ["Music", "Tool", "Device", "Equipment"],
  },
  {
    word: "intelligent",
    topics: ["Smart", "Clever", "Smart", "Thinking"],
  },
  {
    word: "international",
    topics: ["Global", "World", "Foreign", "Countries"],
  },
  {
    word: "introduction",
    topics: ["Start", "Beginning", "Welcome", "Presentation"],
  },
  {
    word: "invent",
    topics: ["Create", "Design", "New", "Discover"],
  },
  {
    word: "invention",
    topics: ["Creation", "New thing", "Technology", "Discovery"],
  },
  {
    word: "invitation",
    topics: ["Request", "Party", "Event", "Welcome"],
  },
  {
    word: "invite",
    topics: ["Ask", "Request", "Welcome", "Party"],
  },
  {
    word: "involve",
    topics: ["Include", "Participate", "Be part of", "Action"],
  },
  {
    word: "item",
    topics: ["Object", "Thing", "Product", "List"],
  },
  {
    word: "itself",
    topics: ["Self", "Alone", "Independent", "Reflexive"],
  },
  {
    word: "jam",
    topics: ["Food", "Sweet", "Fruit", "Sticky"],
  },
  {
    word: "jazz",
    topics: ["Music", "Style", "Genre", "Improvisation"],
  },
  {
    word: "jewellery",
    topics: ["Jewelry", "Gems", "Fashion", "Precious"],
  },
  {
    word: "joke",
    topics: ["Humor", "Funny", "Laugh", "Entertainment"],
  },
  {
    word: "journalist",
    topics: ["News", "Writing", "Media", "Reporter"],
  },
  {
    word: "jump",
    topics: ["Action", "Move", "Leap", "High"],
  },
  {
    word: "kid",
    topics: ["Child", "Young", "Informal", "Person"],
  },
  {
    word: "kill",
    topics: ["Death", "Violence", "Murder", "Action"],
  },
  {
    word: "king",
    topics: ["Royal", "Ruler", "Power", "History"],
  },
  {
    word: "knee",
    topics: ["Body", "Leg", "Joint", "Movement"],
  },
  {
    word: "knife",
    topics: ["Kitchen", "Tool", "Sharp", "Cut"],
  },
  {
    word: "knock",
    topics: ["Sound", "Door", "Action", "Hit"],
  },
  {
    word: "knowledge",
    topics: ["Learning", "Information", "Understanding", "Facts"],
  },
  {
    word: "lab",
    topics: ["Science", "Experiment", "Research", "Work"],
  },
  {
    word: "lady",
    topics: ["Woman", "Female", "Person", "Formal"],
  },
  {
    word: "lake",
    topics: ["Water", "Nature", "Geography", "Large"],
  },
  {
    word: "lamp",
    topics: ["Light", "Illumination", "Object", "Home"],
  },
  {
    word: "land",
    topics: ["Earth", "Ground", "Country", "Geography"],
  },
  {
    word: "laptop",
    topics: ["Computer", "Technology", "Portable", "Device"],
  },
  {
    word: "last",
    topics: ["Time", "Final", "End", "Previous"],
  },
  {
    word: "later",
    topics: ["Time", "After", "Next", "Future"],
  },
  {
    word: "laughter",
    topics: ["Sound", "Happy", "Fun", "Emotion"],
  },
  {
    word: "law",
    topics: ["Rules", "Justice", "Government", "Legal"],
  },
  {
    word: "lawyer",
    topics: ["Legal", "Profession", "Court", "Advice"],
  },
  {
    word: "lazy",
    topics: ["Tired", "Unmotivated", "Slow", "Not working"],
  },
  {
    word: "lead",
    topics: ["Guide", "Control", "Direction", "Metal"],
  },
  {
    word: "leader",
    topics: ["Boss", "Group", "Control", "Influence"],
  },
  {
    word: "learning",
    topics: ["Education", "Knowledge", "Study", "Process"],
  },
  {
    word: "least",
    topics: ["Quantity", "Smallest", "Minimum", "Not much"],
  },
  {
    word: "lecture",
    topics: ["Talk", "Education", "University", "Teacher"],
  },
  {
    word: "lemon",
    topics: ["Fruit", "Sour", "Yellow", "Food"],
  },
  {
    word: "lend",
    topics: ["Give", "Borrow", "Temporary", "Money"],
  },
  {
    word: "less",
    topics: ["Quantity", "Smaller", "Fewer", "Not as much"],
  },
  {
    word: "level",
    topics: ["Height", "Stage", "Grade", "Degree"],
  },
  {
    word: "lifestyle",
    topics: ["Habits", "Way of life", "Health", "Choices"],
  },
  {
    word: "lift",
    topics: ["Raise", "Move", "Up", "Action"],
  },
  {
    word: "light (a lamp)",
    topics: ["Illumination", "Brightness", "Device", "See"],
  },
  {
    word: "light (not heavy)",
    topics: ["Weight", "Opposite of heavy", "Easy", "Gentle"],
  },
  {
    word: "likely",
    topics: ["Probability", "Possible", "Chance", "Expectation"],
  },
  {
    word: "link",
    topics: ["Connect", "Join", "Relationship", "Website"],
  },
  {
    word: "listener",
    topics: ["Audience", "Hear", "Pay attention", "Communication"],
  },
  {
    word: "little",
    topics: ["Small", "Few", "Not much", "Tiny"],
  },
  {
    word: "lock",
    topics: ["Security", "Door", "Close", "Key"],
  },
  {
    word: "look",
    topics: ["See", "Observe", "Appearance", "Action"],
  },
  {
    word: "lorry",
    topics: ["Truck", "Vehicle", "Transport", "Large"],
  },
  {
    word: "lost",
    topics: ["Missing", "Gone", "Unable to find", "Sad"],
  },
  {
    word: "loud",
    topics: ["Sound", "Volume", "Noisy", "Strong"],
  },
  {
    word: "loudly",
    topics: ["Sound", "Noisy", "Clearly", "Strongly"],
  },
  {
    word: "lovely",
    topics: ["Beautiful", "Nice", "Pleasant", "Wonderful"],
  },
  {
    word: "low",
    topics: ["Height", "Low", "Quantity", "Weak"],
  },
  {
    word: "luck",
    topics: ["Chance", "Fortune", "Good fortune", "Random"],
  },
  {
    word: "lucky",
    topics: ["Fortunate", "Chance", "Good luck", "Happy"],
  },
  {
    word: "mail",
    topics: ["Letter", "Post", "Delivery", "Communication"],
  },
  {
    word: "major",
    topics: ["Important", "Large", "Significant", "Main"],
  },
  {
    word: "male",
    topics: ["Gender", "Man", "Boy", "Sex"],
  },
  {
    word: "manage",
    topics: ["Control", "Organize", "Lead", "Handle"],
  },
  {
    word: "manager",
    topics: ["Boss", "Leader", "Control", "Business"],
  },
  {
    word: "manner",
    topics: ["Behavior", "Style", "Way", "Politeness"],
  },
  {
    word: "mark",
    topics: ["Sign", "Point", "Score", "Grade"],
  },
  {
    word: "marry",
    topics: ["Wedding", "Relationship", "Husband/Wife", "Love"],
  },
  {
    word: "material",
    topics: ["Substance", "Fabric", "Object", "Stuff"],
  },
  {
    word: "mathematics",
    topics: ["Math", "Numbers", "Science", "Study"],
  },
  {
    word: "maths",
    topics: ["Math", "Numbers", "School", "Study"],
  },
  {
    word: "matter",
    topics: ["Importance", "Subject", "Issue", "Problem"],
  },
  {
    word: "may modal",
    topics: ["Possibility", "Permission", "Future", "Uncertainty"],
  },
  {
    word: "media",
    topics: ["News", "Information", "TV", "Internet"],
  },
  {
    word: "medical",
    topics: ["Health", "Doctor", "Hospital", "Treatment"],
  },
  {
    word: "medicine",
    topics: ["Health", "Drugs", "Treatment", "Doctor"],
  },
  {
    word: "memory",
    topics: ["Mind", "Remember", "Past", "Brain"],
  },
  {
    word: "mention",
    topics: ["Say", "Talk about", "Refer to", "Mention"],
  },
  {
    word: "metal",
    topics: ["Substance", "Hard", "Shiny", "Element"],
  },
  {
    word: "method",
    topics: ["Way", "Process", "Technique", "Steps"],
  },
  {
    word: "middle",
    topics: ["Position", "Center", "Between", "Location"],
  },
  {
    word: "might",
    topics: ["Possibility", "Perhaps", "Maybe", "Uncertainty"],
  },
  {
    word: "mind",
    topics: ["Brain", "Thoughts", "Thinking", "Opinion"],
  },
  {
    word: "mine (belongs to me)",
    topics: ["Possession", "Ownership", "Belong", "Personal"],
  },
  {
    word: "mirror",
    topics: ["Reflection", "Glass", "See", "Object"],
  },
  {
    word: "missing",
    topics: ["Lost", "Gone", "Not found", "Absent"],
  },
  {
    word: "mobile",
    topics: ["Phone", "Technology", "Move", "Portable"],
  },
  {
    word: "monkey",
    topics: ["Animal", "Jungle", "Primate", "Funny"],
  },
  {
    word: "moon",
    topics: ["Space", "Night", "Light", "Round"],
  },
  {
    word: "mostly",
    topics: ["Mainly", "Generally", "Primarily", "Not all"],
  },
  {
    word: "motorcycle",
    topics: ["Vehicle", "Transport", "Bike", "Fast"],
  },
  {
    word: "movement",
    topics: ["Action", "Move", "Motion", "Change"],
  },
  {
    word: "musical",
    topics: ["Music", "Sound", "Art", "Instruments"],
  },
  {
    word: "musician",
    topics: ["Music", "Artist", "Instrument", "Play"],
  },
  {
    word: "myself",
    topics: ["Self", "Alone", "Independent", "Reflexive"],
  },
  {
    word: "narrow",
    topics: ["Width", "Small", "Thin", "Limited"],
  },
  {
    word: "national",
    topics: ["Country", "Nation", "Government", "International"],
  },
  {
    word: "nature",
    topics: ["World", "Animals", "Plants", "Environment"],
  },
  {
    word: "nearly",
    topics: ["Almost", "Close", "Not quite", "Nearly"],
  },
  {
    word: "necessary",
    topics: ["Important", "Needed", "Essential", "Required"],
  },
  {
    word: "neck",
    topics: ["Body", "Part", "Head", "Necklace"],
  },
  {
    word: "need",
    topics: ["Want", "Requirement", "Desire", "Lack"],
  },
  {
    word: "neither",
    topics: ["Choice", "Not one or the other", "Neither", "None"],
  },
  {
    word: "nervous",
    topics: ["Worried", "Anxious", "Fearful", "Uneasy"],
  },
  {
    word: "network",
    topics: ["Connection", "Internet", "People", "Communication"],
  },
  {
    word: "noise",
    topics: ["Sound", "Loud", "Unwanted", "Disturbing"],
  },
  {
    word: "noisy",
    topics: ["Loud", "Sound", "Unpleasant", "Disturbing"],
  },
  {
    word: "none",
    topics: ["Quantity", "Zero", "Not any", "No one"],
  },
  {
    word: "normal",
    topics: ["Usual", "Typical", "Average", "Regular"],
  },
  {
    word: "normally",
    topics: ["Usually", "Typically", "Regularly", "Often"],
  },
  {
    word: "notice",
    topics: ["See", "Observe", "Recognize", "Pay attention"],
  },
  {
    word: "novel",
    topics: ["Book", "Story", "Fiction", "Literature"],
  },
  {
    word: "nowhere",
    topics: ["Location", "Not anywhere", "No place", "Nowhere"],
  },
  {
    word: "number",
    topics: ["Quantity", "Count", "Mathematics", "Digit"],
  },
  {
    word: "nut",
    topics: ["Food", "Plant", "Small", "Hard"],
  },
  {
    word: "ocean",
    topics: ["Water", "Sea", "Large", "Geography"],
  },
  {
    word: "offer",
    topics: ["Give", "Provide", "Propose", "Suggestion"],
  },
  {
    word: "officer",
    topics: ["Police", "Job", "Authority", "Military"],
  },
  {
    word: "oil",
    topics: ["Liquid", "Fuel", "Substance", "Cooking"],
  },
  {
    word: "onto",
    topics: ["Direction", "On top of", "Move", "Position"],
  },
  {
    word: "opportunity",
    topics: ["Chance", "Possibility", "Time", "Good luck"],
  },
  {
    word: "option",
    topics: ["Choice", "Alternative", "Decision", "Selection"],
  },
  {
    word: "ordinary",
    topics: ["Normal", "Usual", "Typical", "Common"],
  },
  {
    word: "organisation",
    topics: ["Group", "Company", "Business", "Structure"],
  },
  {
    word: "organise",
    topics: ["Plan", "Arrange", "Structure", "Manage"],
  },
  {
    word: "original",
    topics: ["First", "Unique", "New", "Not copied"],
  },
  {
    word: "ourselves",
    topics: ["Self", "Together", "Independent", "Reflexive"],
  },
  {
    word: "outside",
    topics: ["Location", "Opposite of inside", "Exterior", "Out"],
  },
  {
    word: "oven",
    topics: ["Kitchen", "Cooking", "Heat", "Appliance"],
  },
  {
    word: "own",
    topics: ["Possession", "Belong", "Have", "Personal"],
  },
  {
    word: "owner",
    topics: ["Possession", "Person", "Property", "Business"],
  },
  {
    word: "pack",
    topics: ["Bag", "Put in", "Prepare", "Travel"],
  },
  {
    word: "pain",
    topics: ["Hurt", "Sickness", "Unpleasant", "Emotion"],
  },
  {
    word: "painter",
    topics: ["Art", "Artist", "Create", "Paint"],
  },
  {
    word: "palace",
    topics: ["Building", "Royal", "History", "Grand"],
  },
  {
    word: "pants",
    topics: ["Clothing", "Trousers", "Bottom", "Fashion"],
  },
  {
    word: "parking",
    topics: ["Car", "Space", "Area", "Transport"],
  },
  {
    word: "particular",
    topics: ["Specific", "Certain", "Unique", "Special"],
  },
  {
    word: "pass",
    topics: ["Go by", "Move", "Permission", "Test"],
  },
  {
    word: "passenger",
    topics: ["Travel", "Transport", "Bus", "Plane"],
  },
  {
    word: "past",
    topics: ["Time", "Before", "History", "Memories"],
  },
  {
    word: "patient",
    topics: ["Ill", "Doctor", "Hospital", "Waiting"],
  },
  {
    word: "pattern",
    topics: ["Design", "Repeat", "Shape", "Structure"],
  },
  {
    word: "pay",
    topics: ["Money", "Give", "Salary", "Cost"],
  },
  {
    word: "peace",
    topics: ["Harmony", "Quiet", "Calm", "Tranquility"],
  },
  {
    word: "penny",
    topics: ["Money", "Coin", "Small", "Currency"],
  },
  {
    word: "per",
    topics: ["For each", "Every", "Rate", "Calculation"],
  },
  {
    word: "per cent",
    topics: ["Percentage", "Amount", "Rate", "Calculation"],
  },
  {
    word: "perform",
    topics: ["Act", "Play", "Show", "Music"],
  },
  {
    word: "perhaps",
    topics: ["Maybe", "Possibly", "Doubt", "Uncertainty"],
  },
  {
    word: "permission",
    topics: ["Allow", "Permit", "Request", "Authority"],
  },
  {
    word: "personality",
    topics: ["Character", "Traits", "Behavior", "Individual"],
  },
  {
    word: "pet",
    topics: ["Animal", "Companion", "Home", "Love"],
  },
  {
    word: "petrol",
    topics: ["Fuel", "Car", "Gasoline", "Liquid"],
  },
  {
    word: "photograph",
    topics: ["Picture", "Camera", "Image", "Memory"],
  },
  {
    word: "physical",
    topics: ["Body", "Real", "Material", "Not mental"],
  },
  {
    word: "physics",
    topics: ["Science", "Study", "Movement", "Energy"],
  },
  {
    word: "pick",
    topics: ["Choose", "Select", "Take", "Gather"],
  },
  {
    word: "pilot",
    topics: ["Airplane", "Fly", "Profession", "Control"],
  },
  {
    word: "planet",
    topics: ["Space", "Earth", "Sun", "Astronomy"],
  },
  {
    word: "plant",
    topics: ["Nature", "Tree", "Flower", "Grow"],
  },
  {
    word: "plastic",
    topics: ["Material", "Synthetic", "Object", "Unnatural"],
  },
  {
    word: "plate",
    topics: ["Food", "Dish", "Table", "Dinner"],
  },
  {
    word: "platform",
    topics: ["Structure", "Stage", "Train", "Level"],
  },
  {
    word: "please",
    topics: ["Request", "Kindness", "Polite", "Favor"],
  },
  {
    word: "pleased",
    topics: ["Happy", "Content", "Satisfied", "Enjoy"],
  },
  {
    word: "pocket",
    topics: ["Clothing", "Storage", "Small", "Money"],
  },
  {
    word: "polite",
    topics: ["Manners", "Respectful", "Kind", "Good behavior"],
  },
  {
    word: "pollution",
    topics: ["Environment", "Damage", "Air", "Water"],
  },
  {
    word: "pop",
    topics: ["Music", "Sound", "Short for popular", "Drink"],
  },
  {
    word: "population",
    topics: ["People", "Country", "Number", "City"],
  },
  {
    word: "position",
    topics: ["Place", "Location", "Role", "Job"],
  },
  {
    word: "possession",
    topics: ["Belonging", "Ownership", "Object", "Having"],
  },
  {
    word: "possibility",
    topics: ["Chance", "Maybe", "Potential", "Could be"],
  },
  {
    word: "poster",
    topics: ["Picture", "Sign", "Advertisement", "Paper"],
  },
  {
    word: "power",
    topics: ["Energy", "Control", "Strength", "Electricity"],
  },
  {
    word: "predict",
    topics: ["Future", "Guess", "Foretell", "Anticipate"],
  },
  {
    word: "present",
    topics: ["Now", "Gift", "Show", "Give"],
  },
  {
    word: "president",
    topics: ["Leader", "Country", "Politics", "Government"],
  },
  {
    word: "prevent",
    topics: ["Stop", "Avoid", "Protect", "Hinder"],
  },
  {
    word: "print",
    topics: ["Paper", "Copy", "Document", "Printer"],
  },
  {
    word: "printer",
    topics: ["Technology", "Paper", "Print", "Device"],
  },
  {
    word: "prison",
    topics: ["Crime", "Punishment", "Jail", "Locked up"],
  },
  {
    word: "prize",
    topics: ["Award", "Reward", "Competition", "Winning"],
  },
  {
    word: "process",
    topics: ["Steps", "Action", "Method", "Sequence"],
  },
  {
    word: "produce",
    topics: ["Make", "Create", "Factory", "Food"],
  },
  {
    word: "professional",
    topics: ["Job", "Skill", "Expert", "Work"],
  },
  {
    word: "professor",
    topics: ["Teacher", "University", "Expert", "Knowledge"],
  },
  {
    word: "profile",
    topics: ["Information", "Person", "Description", "Social media"],
  },
  {
    word: "program",
    topics: ["Computer", "Software", "Plan", "Schedule"],
  },
  {
    word: "progress",
    topics: ["Improvement", "Development", "Forward", "Advance"],
  },
  {
    word: "promise",
    topics: ["Agreement", "Word", "Commitment", "Future"],
  },
  {
    word: "pronounce",
    topics: ["Speak", "Sound", "Language", "Words"],
  },
  {
    word: "protect",
    topics: ["Safe", "Guard", "Keep safe", "Defense"],
  },
  {
    word: "provide",
    topics: ["Give", "Offer", "Supply", "Support"],
  },
  {
    word: "pub",
    topics: ["Bar", "Drink", "Social", "Place"],
  },
  {
    word: "public",
    topics: ["People", "Community", "Known", "Open"],
  },
  {
    word: "publish",
    topics: ["Book", "Newspaper", "Share", "Print"],
  },
  {
    word: "pull",
    topics: ["Action", "Move", "Tug", "Take"],
  },
  {
    word: "purpose",
    topics: ["Reason", "Goal", "Aim", "Objective"],
  },
  {
    word: "push",
    topics: ["Action", "Move", "Force", "Press"],
  },
  {
    word: "quality",
    topics: ["Goodness", "Standard", "Level", "Excellence"],
  },
  {
    word: "quantity",
    topics: ["Amount", "Number", "Size", "Extent"],
  },
  {
    word: "queen",
    topics: ["Royal", "Ruler", "Woman", "History"],
  },
  {
    word: "question",
    topics: ["Ask", "Inquiry", "Doubt", "Problem"],
  },
  {
    word: "quietly",
    topics: ["Sound", "Softly", "Calmly", "Silently"],
  },
  {
    word: "race (run)",
    topics: ["Sport", "Competition", "Running", "Speed"],
  },
  {
    word: "railway",
    topics: ["Transport", "Train", "Tracks", "Travel"],
  },
  {
    word: "raise",
    topics: ["Lift", "Increase", "Grow", "Action"],
  },
  {
    word: "rate",
    topics: ["Speed", "Price", "Measure", "Value"],
  },
  {
    word: "rather",
    topics: ["Preference", "Instead", "More", "Alternative"],
  },
  {
    word: "reach",
    topics: ["Arrive", "Touch", "Get to", "Extend"],
  },
  {
    word: "react",
    topics: ["Respond", "Action", "Change", "Emotion"],
  },
  {
    word: "realize",
    topics: ["Understand", "Know", "Discover", "See"],
  },
  {
    word: "receive",
    topics: ["Get", "Accept", "Take", "Communication"],
  },
  {
    word: "recent",
    topics: ["Time", "New", "Latest", "Past"],
  },
  {
    word: "recently",
    topics: ["Time", "Not long ago", "Lately", "Past"],
  },
  {
    word: "reception",
    topics: ["Welcome", "Area", "Hotel", "Event"],
  },
  {
    word: "recipe",
    topics: ["Food", "Instructions", "Cooking", "Ingredients"],
  },
  {
    word: "recognise",
    topics: ["Know", "Identify", "Remember", "See"],
  },
  {
    word: "recommend",
    topics: ["Suggest", "Advice", "Favorable", "Opinion"],
  },
  {
    word: "record",
    topics: ["Write", "Save", "Information", "Music"],
  },
  {
    word: "recording",
    topics: ["Music", "Sound", "Audio", "Save"],
  },
  {
    word: "recycle",
    topics: ["Environment", "Waste", "Reuse", "Protect"],
  },
  {
    word: "reduce",
    topics: ["Less", "Smaller", "Decrease", "Lower"],
  },
  {
    word: "refer",
    topics: ["Mention", "Direct", "Point to", "Guide"],
  },
  {
    word: "refuse",
    topics: ["Deny", "Reject", "Say no", "Disagree"],
  },
  {
    word: "region",
    topics: ["Area", "Place", "Geography", "Location"],
  },
  {
    word: "regular",
    topics: ["Usual", "Normal", "Often", "Routine"],
  },
  {
    word: "relationship",
    topics: ["Connection", "People", "Friendship", "Love"],
  },
  {
    word: "remove",
    topics: ["Take away", "Delete", "Move", "Eliminate"],
  },
  {
    word: "repair",
    topics: ["Fix", "Mend", "Improve", "Fix"],
  },
  {
    word: "replace",
    topics: ["Change", "Substitute", "New", "Old"],
  },
  {
    word: "reply",
    topics: ["Answer", "Respond", "Communication", "Say"],
  },
  {
    word: "report",
    topics: ["Information", "News", "Document", "News"],
  },
  {
    word: "reporter",
    topics: ["Journalist", "News", "Media", "Write"],
  },
  {
    word: "request",
    topics: ["Ask", "Want", "Need", "Formal"],
  },
  {
    word: "research",
    topics: ["Study", "Science", "Information", "Learn"],
  },
  {
    word: "researcher",
    topics: ["Scientist", "Study", "Learn", "Discover"],
  },
  {
    word: "respond",
    topics: ["Answer", "React", "Reply", "Communication"],
  },
  {
    word: "response",
    topics: ["Answer", "Reaction", "Reply", "Communication"],
  },
  {
    word: "rest",
    topics: ["Sleep", "Relax", "Break", "Stop"],
  },
  {
    word: "review",
    topics: ["Opinion", "Critique", "Evaluate", "Assessment"],
  },
  {
    word: "ride",
    topics: ["Travel", "Bike", "Horse", "Action"],
  },
  {
    word: "ring",
    topics: ["Sound", "Jewelry", "Phone", "Circle"],
  },
  {
    word: "ring",
    topics: ["Sound", "Bell", "Phone", "Action"],
  },
  {
    word: "rise",
    topics: ["Go up", "Increase", "Get higher", "Sun"],
  },
  {
    word: "rock",
    topics: ["Stone", "Nature", "Music", "Hard"],
  },
  {
    word: "role",
    topics: ["Job", "Function", "Part", "Responsibility"],
  },
  {
    word: "roof",
    topics: ["Building", "Top", "Protection", "Structure"],
  },
  {
    word: "round",
    topics: ["Shape", "Circle", "Around", "Circular"],
  },
  {
    word: "route",
    topics: ["Path", "Direction", "Travel", "Road"],
  },
  {
    word: "rubbish",
    topics: ["Waste", "Trash", "Garbage", "Unwanted"],
  },
  {
    word: "rude",
    topics: ["Disrespectful", "Unkind", "Impoliteness", "Bad manners"],
  },
  {
    word: "run",
    topics: ["Move", "Sport", "Action", "Fast"],
  },
  {
    word: "runner",
    topics: ["Sport", "Athlete", "Race", "Fast"],
  },
  {
    word: "running",
    topics: ["Sport", "Action", "Movement", "Fast"],
  },
  {
    word: "sadly",
    topics: ["Sad", "Unhappily", "Unfortunately", "Regretfully"],
  },
  {
    word: "safe",
    topics: ["Secure", "Protected", "Not dangerous", "Healthy"],
  },
  {
    word: "sail",
    topics: ["Boat", "Water", "Wind", "Travel"],
  },
  {
    word: "sailing",
    topics: ["Boat", "Water", "Sport", "Travel"],
  },
  {
    word: "salary",
    topics: ["Money", "Work", "Pay", "Income"],
  },
  {
    word: "sale",
    topics: ["Shop", "Discount", "Buy", "Sell"],
  },
  {
    word: "sauce",
    topics: ["Food", "Flavor", "Liquid", "Ingredient"],
  },
  {
    word: "save",
    topics: ["Keep", "Protect", "Money", "Rescue"],
  },
  {
    word: "scared",
    topics: ["Fear", "Afraid", "Anxious", "Worried"],
  },
  {
    word: "scary",
    topics: ["Frightening", "Dangerous", "Spook", "Unpleasant"],
  },
  {
    word: "scene",
    topics: ["Place", "View", "Movie", "Event"],
  },
  {
    word: "schedule",
    topics: ["Plan", "Timetable", "Time", "Organize"],
  },
  {
    word: "score",
    topics: ["Points", "Game", "Result", "Number"],
  },
  {
    word: "screen",
    topics: ["TV", "Computer", "Show", "Display"],
  },
  {
    word: "search",
    topics: ["Look for", "Find", "Internet", "Investigate"],
  },
  {
    word: "season",
    topics: ["Time", "Year", "Weather", "Nature"],
  },
  {
    word: "seat",
    topics: ["Chair", "Sit", "Airplane", "Transport"],
  },
  {
    word: "second (next)",
    topics: ["Time", "After first", "Number", "Order"],
  },
  {
    word: "secondly",
    topics: ["Order", "After first", "Next", "Sequence"],
  },
  {
    word: "secret",
    topics: ["Hidden", "Private", "Information", "Mystery"],
  },
  {
    word: "secretary",
    topics: ["Office", "Job", "Assistant", "Help"],
  },
  {
    word: "seem",
    topics: ["Appear", "Look like", "Impress", "Feel"],
  },
  {
    word: "sense",
    topics: ["Understanding", "Feeling", "Meaning", "Reason"],
  },
  {
    word: "separate",
    topics: ["Divide", "Apart", "Different", "Individual"],
  },
  {
    word: "series",
    topics: ["Group", "Set", "Sequence", "TV show"],
  },
  {
    word: "serious",
    topics: ["Important", "Worried", "Problem", "Not funny"],
  },
  {
    word: "serve",
    topics: ["Give", "Help", "Food", "Work"],
  },
  {
    word: "service",
    topics: ["Help", "Work", "Support", "Industry"],
  },
  {
    word: "several",
    topics: ["Quantity", "Many", "Few", "More than two"],
  },
  {
    word: "shake",
    topics: ["Move", "Tremble", "Action", "Hand"],
  },
  {
    word: "shall",
    topics: ["Will", "Future", "Formal", "Decide"],
  },
  {
    word: "shape",
    topics: ["Form", "Outline", "Design", "Geometry"],
  },
  {
    word: "sheet",
    topics: ["Paper", "Flat", "Bed", "Material"],
  },
  {
    word: "ship",
    topics: ["Boat", "Water", "Transport", "Large"],
  },
  {
    word: "shoulder",
    topics: ["Body", "Part", "Carry", "Upper body"],
  },
  {
    word: "shout",
    topics: ["Sound", "Loud", "Call", "Action"],
  },
  {
    word: "shut",
    topics: ["Close", "Door", "Action", "Stop"],
  },
  {
    word: "side",
    topics: ["Direction", "Edge", "Location", "Part"],
  },
  {
    word: "sign",
    topics: ["Symbol", "Information", "Notice", "Write"],
  },
  {
    word: "silver",
    topics: ["Metal", "Shiny", "Color", "Precious"],
  },
  {
    word: "simple",
    topics: ["Easy", "Basic", "Not complex", "Straightforward"],
  },
  {
    word: "since",
    topics: ["Time", "From then", "Because", "Reason"],
  },
  {
    word: "singing",
    topics: ["Music", "Sound", "Voice", "Art"],
  },
  {
    word: "single",
    topics: ["One", "Alone", "Only", "Individual"],
  },
  {
    word: "sir",
    topics: ["Man", "Formal", "Respectful", "Title"],
  },
  {
    word: "site",
    topics: ["Location", "Place", "Website", "Internet"],
  },
  {
    word: "size",
    topics: ["Big", "Small", "Measure", "Dimension"],
  },
  {
    word: "ski",
    topics: ["Sport", "Snow", "Winter", "Action"],
  },
  {
    word: "skiing",
    topics: ["Sport", "Snow", "Winter", "Action"],
  },
  {
    word: "skin",
    topics: ["Body", "Covering", "Animal", "Human"],
  },
  {
    word: "sky",
    topics: ["Weather", "Clouds", "High", "Blue"],
  },
  {
    word: "sleep",
    topics: ["Rest", "Night", "Dream", "Action"],
  },
  {
    word: "slowly",
    topics: ["Speed", "Gradually", "Not quickly", "Slowly"],
  },
  {
    word: "smartphone",
    topics: ["Technology", "Phone", "Mobile", "Device"],
  },
  {
    word: "smell",
    topics: ["Nose", "Sense", "Scent", "Fragrance"],
  },
  {
    word: "smile",
    topics: ["Happy", "Face", "Expression", "Action"],
  },
  {
    word: "smoke",
    topics: ["Fire", "Cigarette", "Air", "Substance"],
  },
  {
    word: "smoking",
    topics: ["Cigarette", "Habit", "Health", "Unhealthy"],
  },
  {
    word: "soap",
    topics: ["Cleaning", "Wash", "Liquid", "Bubble"],
  },
  {
    word: "soccer",
    topics: ["Sport", "Football", "Game", "Team"],
  },
  {
    word: "social",
    topics: ["People", "Group", "Community", "Interaction"],
  },
  {
    word: "society",
    topics: ["People", "Culture", "Group", "Community"],
  },
  {
    word: "sock",
    topics: ["Clothing", "Foot", "Wear", "Fashion"],
  },
  {
    word: "soft",
    topics: ["Texture", "Gentle", "Not hard", "Comfortable"],
  },
  {
    word: "soldier",
    topics: ["Army", "Military", "Fight", "Uniform"],
  },
  {
    word: "solution",
    topics: ["Answer", "Problem", "Solve", "Fix"],
  },
  {
    word: "solve",
    topics: ["Answer", "Fix", "Problem", "Solution"],
  },
  {
    word: "somewhere",
    topics: ["Location", "Place", "Unspecified", "Not here"],
  },
  {
    word: "sort",
    topics: ["Type", "Kind", "Category", "Organize"],
  },
  {
    word: "source",
    topics: ["Origin", "Information", "Cause", "Beginning"],
  },
  {
    word: "speaker",
    topics: ["Talk", "Sound", "Person", "Device"],
  },
  {
    word: "specific",
    topics: ["Exact", "Particular", "Detail", "Precise"],
  },
  {
    word: "speech",
    topics: ["Talk", "Formal", "Presentation", "Communication"],
  },
  {
    word: "speed",
    topics: ["Fast", "Velocity", "Movement", "Rate"],
  },
  {
    word: "spider",
    topics: ["Animal", "Insect", "Web", "Eight legs"],
  },
  {
    word: "spoon",
    topics: ["Kitchen", "Utensil", "Metal", "Food"],
  },
  {
    word: "square",
    topics: ["Shape", "Geometry", "Four sides", "Box"],
  },
  {
    word: "stage",
    topics: ["Performance", "Theater", "Level", "Platform"],
  },
  {
    word: "stair",
    topics: ["Steps", "Building", "Up", "Down"],
  },
  {
    word: "stamp",
    topics: ["Mail", "Postage", "Stick", "Paper"],
  },
  {
    word: "star",
    topics: ["Space", "Light", "Famous", "Shine"],
  },
  {
    word: "start",
    topics: ["Begin", "Begin", "First", "Action"],
  },
  {
    word: "state",
    topics: ["Country", "Condition", "Say", "Government"],
  },
  {
    word: "stay",
    topics: ["Remain", "Live", "Continue", "Action"],
  },
  {
    word: "steal",
    topics: ["Crime", "Take", "Illegal", "Rob"],
  },
  {
    word: "step",
    topics: ["Foot", "Action", "Move", "Stair"],
  },
  {
    word: "stomach",
    topics: ["Body", "Part", "Hungry", "Digest"],
  },
  {
    word: "stone",
    topics: ["Rock", "Hard", "Nature", "Building"],
  },
  {
    word: "store",
    topics: ["Shop", "Buy", "Sell", "Keep"],
  },
  {
    word: "storm",
    topics: ["Weather", "Rain", "Wind", "Bad weather"],
  },
  {
    word: "straight",
    topics: ["Line", "Direction", "Honest", "Not curved"],
  },
  {
    word: "strange",
    topics: ["Unusual", "Different", "Odd", "Weird"],
  },
  {
    word: "strategy",
    topics: ["Plan", "Game", "Business", "Method"],
  },
  {
    word: "stress",
    topics: ["Pressure", "Worry", "Tension", "Emotion"],
  },
  {
    word: "structure",
    topics: ["Building", "Organization", "Form", "Design"],
  },
  {
    word: "stupid",
    topics: ["Silly", "Unintelligent", "Not smart", "Foolish"],
  },
  {
    word: "succeed",
    topics: ["Achieve", "Win", "Success", "Goal"],
  },
  {
    word: "successful",
    topics: ["Achieved", "Winning", "Good", "Effective"],
  },
  {
    word: "such",
    topics: ["Like", "So", "Very", "Example"],
  },
  {
    word: "suddenly",
    topics: ["Unexpected", "Quickly", "Without warning", "Surprise"],
  },
  {
    word: "suggest",
    topics: ["Idea", "Propose", "Recommend", "Offer"],
  },
  {
    word: "suggestion",
    topics: ["Idea", "Proposal", "Advice", "Recommendation"],
  },
  {
    word: "suit",
    topics: ["Clothes", "Match", "Formal", "Fit"],
  },
  {
    word: "support",
    topics: ["Help", "Assist", "Encourage", "Believe"],
  },
  {
    word: "suppose",
    topics: ["Think", "Assume", "Maybe", "Believe"],
  },
  {
    word: "sure",
    topics: ["Certain", "Confident", "No doubt", "Yes"],
  },
  {
    word: "surprise",
    topics: ["Unexpected", "Astonishment", "Shock", "Wonder"],
  },
  {
    word: "surprised",
    topics: ["Shocked", "Astonished", "Unexpected", "Amazed"],
  },
  {
    word: "surprising",
    topics: ["Unexpected", "Amazing", "Unbelievable", "Strange"],
  },
  {
    word: "survey",
    topics: ["Research", "Question", "Opinion", "Data"],
  },
  {
    word: "sweet",
    topics: ["Taste", "Sugar", "Nice", "Pleasant"],
  },
  {
    word: "symbol",
    topics: ["Sign", "Representation", "Meaning", "Image"],
  },
  {
    word: "system",
    topics: ["Technology", "Organization", "Process", "Structure"],
  },
  {
    word: "tablet",
    topics: ["Technology", "Device", "Computer", "Medicine"],
  },
  {
    word: "talk",
    topics: ["Communication", "Conversation", "Speak", "Discuss"],
  },
  {
    word: "target",
    topics: ["Goal", "Aim", "Objective", "Aim"],
  },
  {
    word: "task",
    topics: ["Work", "Job", "Activity", "To-do"],
  },
  {
    word: "taste",
    topics: ["Sense", "Food", "Flavor", "Experience"],
  },
  {
    word: "teaching",
    topics: ["Education", "Learning", "Instructor", "School"],
  },
  {
    word: "technology",
    topics: ["Computers", "Devices", "Innovation", "Progress"],
  },
  {
    word: "teenage",
    topics: ["Age", "Teenager", "Young", "Adolescence"],
  },
  {
    word: "temperature",
    topics: ["Heat", "Weather", "Body", "Measure"],
  },
  {
    word: "term",
    topics: ["Time", "Word", "School", "Definition"],
  },
  {
    word: "text",
    topics: ["Writing", "Message", "Communication", "Phone"],
  },
  {
    word: "themselves",
    topics: ["Self", "Group", "Independent", "Reflexive"],
  },
  {
    word: "thick",
    topics: ["Size", "Dense", "Not thin", "Heavy"],
  },
  {
    word: "thief",
    topics: ["Crime", "Steal", "Criminal", "Robber"],
  },
  {
    word: "thin",
    topics: ["Size", "Narrow", "Not thick", "Light"],
  },
  {
    word: "thinking",
    topics: ["Mind", "Brain", "Ideas", "Consider"],
  },
  {
    word: "third",
    topics: ["Number", "Order", "After second", "Third"],
  },
  {
    word: "thought",
    topics: ["Idea", "Mind", "Thinking", "Believe"],
  },
  {
    word: "throw",
    topics: ["Action", "Move", "Toss", "Launch"],
  },
  {
    word: "tidy",
    topics: ["Clean", "Organized", "Neat", "Orderly"],
  },
  {
    word: "tie",
    topics: ["Clothing", "Neck", "Knot", "Fashion"],
  },
  {
    word: "tip",
    topics: ["Advice", "Point", "Money", "Top"],
  },
  {
    word: "tool",
    topics: ["Equipment", "Work", "Device", "Help"],
  },
  {
    word: "top",
    topics: ["Position", "Highest", "Best", "Surface"],
  },
  {
    word: "touch",
    topics: ["Feel", "Action", "Contact", "Sense"],
  },
  {
    word: "tour",
    topics: ["Travel", "Visit", "Guide", "Sightseeing"],
  },
  {
    word: "tourism",
    topics: ["Travel", "Industry", "Sightseeing", "Vacation"],
  },
  {
    word: "towards",
    topics: ["Direction", "Moving", "Aim", "Toward"],
  },
  {
    word: "towel",
    topics: ["Bath", "Dry", "Soft", "Clean"],
  },
  {
    word: "tower",
    topics: ["Building", "Tall", "Structure", "High"],
  },
  {
    word: "toy",
    topics: ["Play", "Child", "Fun", "Object"],
  },
  {
    word: "track",
    topics: ["Path", "Train", "Follow", "Music"],
  },
  {
    word: "tradition",
    topics: ["Custom", "Culture", "History", "Habit"],
  },
  {
    word: "traditional",
    topics: ["Custom", "Old", "Classic", "Usual"],
  },
  {
    word: "train",
    topics: ["Transport", "Railway", "Travel", "Learn"],
  },
  {
    word: "trainer",
    topics: ["Coach", "Teacher", "Sport", "Help"],
  },
  {
    word: "training",
    topics: ["Learn", "Practice", "Skill", "Education"],
  },
  {
    word: "transport",
    topics: ["Travel", "Vehicle", "Move", "Car"],
  },
  {
    word: "traveller",
    topics: ["Tourist", "Travel", "Person", "Vacation"],
  },
  {
    word: "trouble",
    topics: ["Problem", "Difficulty", "Worry", "Issue"],
  },
  {
    word: "truck",
    topics: ["Vehicle", "Transport", "Large", "Delivery"],
  },
  {
    word: "twin",
    topics: ["Two", "Identical", "Sibling", "Pair"],
  },
  {
    word: "typical",
    topics: ["Usual", "Normal", "Common", "Average"],
  },
  {
    word: "underground",
    topics: ["Subway", "Below ground", "Transport", "Tunnel"],
  },
  {
    word: "understanding",
    topics: ["Knowledge", "Comprehend", "Meaning", "Aware"],
  },
  {
    word: "unfortunately",
    topics: ["Sad", "Bad luck", "Regret", "Unhappily"],
  },
  {
    word: "unhappy",
    topics: ["Sad", "Unpleasant", "Disappointed", "Worried"],
  },
  {
    word: "uniform",
    topics: ["Clothing", "School", "Army", "Same"],
  },
  {
    word: "unit",
    topics: ["Part", "Group", "Measurement", "Section"],
  },
  {
    word: "united",
    topics: ["Together", "Joined", "Group", "Country"],
  },
  {
    word: "unusual",
    topics: ["Strange", "Different", "Unique", "Odd"],
  },
  {
    word: "upstairs",
    topics: ["Location", "Above", "Building", "Direction"],
  },
  {
    word: "use",
    topics: ["Employ", "Action", "Apply", "Purpose"],
  },
  {
    word: "used to",
    topics: ["Past habit", "Previously", "No longer", "Change"],
  },
  {
    word: "user",
    topics: ["Person", "Computer", "Technology", "Customer"],
  },
  {
    word: "usual",
    topics: ["Normal", "Typical", "Common", "Regular"],
  },
  {
    word: "valley",
    topics: ["Geography", "Low land", "Mountains", "Nature"],
  },
  {
    word: "van",
    topics: ["Vehicle", "Transport", "Small truck", "Delivery"],
  },
  {
    word: "variety",
    topics: ["Different", "Many", "Range", "Selection"],
  },
  {
    word: "vehicle",
    topics: ["Transport", "Car", "Bus", "Truck"],
  },
  {
    word: "view",
    topics: ["Sight", "Scene", "Opinion", "Look"],
  },
  {
    word: "virus",
    topics: ["Illness", "Disease", "Computer", "Infection"],
  },
  {
    word: "voice",
    topics: ["Sound", "Speak", "Communication", "Person"],
  },
  {
    word: "wait",
    topics: ["Time", "Pause", "Expect", "Action"],
  },
  {
    word: "war",
    topics: ["Conflict", "Fight", "Army", "Violence"],
  },
  {
    word: "wash",
    topics: ["Clean", "Water", "Soap", "Action"],
  },
  {
    word: "washing",
    topics: ["Clean", "Clothes", "Laundry", "Action"],
  },
  {
    word: "wave",
    topics: ["Water", "Hand", "Action", "Movement"],
  },
  {
    word: "weak",
    topics: ["Strength", "Not strong", "Ill", "Unhealthy"],
  },
  {
    word: "web",
    topics: ["Internet", "Website", "Spider", "Network"],
  },
  {
    word: "wedding",
    topics: ["Marriage", "Ceremony", "Party", "Love"],
  },
  {
    word: "weight",
    topics: ["Heavy", "Measure", "Scale", "Size"],
  },
  {
    word: "welcome",
    topics: ["Greeting", "Happy", "Friendly", "Invitation"],
  },
  {
    word: "wet",
    topics: ["Water", "Damp", "Rain", "Opposite of dry"],
  },
  {
    word: "wheel",
    topics: ["Car", "Round", "Move", "Transport"],
  },
  {
    word: "while",
    topics: ["Time", "During", "Meanwhile", "Contrast"],
  },
  {
    word: "whole",
    topics: ["Complete", "All", "Entire", "Not part"],
  },
  {
    word: "whose",
    topics: ["Possession", "Belong", "Question", "Ownership"],
  },
  {
    word: "wide",
    topics: ["Size", "Broad", "Large", "Open"],
  },
  {
    word: "wild",
    topics: ["Nature", "Untamed", "Free", "Animal"],
  },
  {
    word: "wind",
    topics: ["Weather", "Air", "Blowing", "Strong"],
  },
  {
    word: "winner",
    topics: ["Competition", "Success", "First place", "Victory"],
  },
  {
    word: "wish",
    topics: ["Desire", "Hope", "Want", "Dream"],
  },
  {
    word: "wood",
    topics: ["Tree", "Material", "Forest", "Nature"],
  },
  {
    word: "wooden",
    topics: ["Material", "Tree", "Hard", "Made of wood"],
  },
  {
    word: "working",
    topics: ["Job", "Active", "Busy", "Employ"],
  },
  {
    word: "worried",
    topics: ["Anxious", "Fearful", "Uneasy", "Nervous"],
  },
  {
    word: "worry",
    topics: ["Concern", "Fear", "Problem", "Anxiety"],
  },
  {
    word: "worse",
    topics: ["Bad", "Worse", "Worse than", "Negative"],
  },
  {
    word: "worst",
    topics: ["Bad", "Most bad", "Terrible", "Negative"],
  },
  {
    word: "wow",
    topics: ["Surprise", "Exclamation", "Amazement", "Impressed"],
  },
  {
    word: "yet",
    topics: ["Time", "Still", "Not yet", "But"],
  },
  {
    word: "yours",
    topics: ["Possession", "Belong", "Ownership", "Personal"],
  },
  {
    word: "zero",
    topics: ["Number", "Nothing", "None", "Start"],
  },
];

export default a2Words;
