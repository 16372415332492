import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Button } from "@mui/material";
import classNames from "classnames";
import { useEffect, useRef } from "react";
import AudioPlayer from "react-h5-audio-player";

interface WordAudioProps {
  url: string;
  autoPlay?: boolean;
  boxHide?: boolean;
  small?: boolean;
}

const WordAudio = ({ url, autoPlay, boxHide, small }: WordAudioProps) => {
  const ref = useRef<any>();

  useEffect(() => {
    if (ref?.current) ref.current.audio.current.playbackRate = 0.85;
    // if (autoPlay) ref.current.audio.current.play();
  }, [ref]);

  return (
    <AudioPlayer
      src={url}
      ref={ref}
      className={classNames("audio-player", {
        "autoplay-not-visible-audio-player": boxHide,
        "small-audio-player": small,
      })}
      autoPlay={autoPlay}
      showJumpControls={false}
      showFilledProgress={false}
      hasDefaultKeyBindings={false}
      customProgressBarSection={[]}
      customVolumeControls={[]}
      customAdditionalControls={[]}
      autoPlayAfterSrcChange={false}
      customIcons={{
        play: <VolumeUpIcon />,
        pause: <PauseRoundedIcon />,
      }}
    />
  );
};

export default WordAudio;
