import { Container, Typography } from "@mui/material";
import Box from "../../Box";
import filterRules from "./utils/filterRules";
import AccentWord from "../../../Difference/components/AccentWord";
import { RuleType } from "../../Root/grammarMap";
import { useLessonsStore } from "../../../../store/lessonsStore";
import AccordionRule from "./AccordionRule";

export const differenceWords: RuleType[] = [
  {
    name: "Різниця між словами",
    rules: [
      {
        title: "climb / grow",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              зростати, підніматися
            </Typography>
          </Typography>,
        ],
        lesson: [5000],
        examples: [
          <>
            <AccentWord word="Climb" /> a ladder.
            <br />
            <AccentWord word="Climb" /> the corporate ladder.
            <br />
            <AccentWord word="Climb" /> Mount Everest.
            <br />
          </>,
          <>
            <AccentWord word="Grow" /> a beard.
            <br />
            <AccentWord word="Grow" /> old.
            <br />
            <AccentWord word="Grow" /> a business.
            <br />
            <AccentWord word="Grow" /> in confidence.
          </>,
        ],
      },
      {
        title: "like / as",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              як
            </Typography>
          </Typography>,
        ],
        lesson: [5000],
        examples: [
          <>
            <Typography component="span" fontWeight={700}>
              Використовується для порівняння.
            </Typography>
            <Typography component="span" fontWeight={700}>
              Використовується для перерахунку. Можна замінити словом "наприклад"
            </Typography>
            <br />
            <AccentWord word="Climb" /> a ladder.
            <br />
            <AccentWord word="Climb" /> the corporate ladder.
            <br />
            <AccentWord word="Climb" /> Mount Everest.
            <br />
          </>,
          <>
            <AccentWord word="Grow" /> a beard.
            <br />
            <AccentWord word="Grow" /> old.
            <br />
            <AccentWord word="Grow" /> a business.
            <br />
            <AccentWord word="Grow" /> in confidence.
          </>,
        ],
      },
      {
        title: "tell / say / speak",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              говорити, казати
            </Typography>
          </Typography>,
        ],
        lesson: [10],
        examples: [
          <>
            <Typography component="span">Використовується для звернення до людини.</Typography>
            <br />
            <AccentWord word="Tell" /> me what is wrong?
            <br />
            She doesn't want to <AccentWord word="tell" /> me about that.
            <br />
          </>,
          <>
            <Typography component="span">
              Використовується у загальному значенні без звернення до людини.
              <br />
              Також використовується у прямій мові. (сказав, сказала, сказали)
            </Typography>
            <br />
            I want to <AccentWord word="say" /> something.
            <br />
            Do you want to <AccentWord word="say" /> something?
            <br />
          </>,
          <>
            <Typography component="span">Вміння або здатність говорити.</Typography>
            <br />
            I <AccentWord word="speak" /> English.
            <br />
            She can't <AccentWord word="speak" /> English.
            <br />
          </>,
        ],
      },
      {
        title: "speak to / speak with",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              цe, цей
            </Typography>
          </Typography>,
        ],
        lesson: [10],
        examples: [
          <>
            <Typography component="span">
              Коли хтось говорить в направленні до іншого/iнших
            </Typography>
            <br />
            The president <AccentWord word="speaks to" /> the people.
            <br />
          </>,
          <>
            <Typography component="span">
              Коли хтось говорить з кимось. Коли є дискусія/діалог.
            </Typography>
            <br />
            They're <AccentWord word="speaking with" /> the doctor.
            <br />
          </>,
        ],
      },
      {
        title: "this / that",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              цe, цей
            </Typography>
          </Typography>,
        ],
        lesson: [10],
        examples: [
          <>
            <Typography component="span">
              Вказівний займенник який вказує на те що знаходиться близько до нас.
              <br />
              Часто використовується з поточним днем. (цей день) <br /> Може вказувати на те що було
              тільки що сказано.
            </Typography>
            <br />
            <AccentWord word="This" /> is a beautiful day.
            <br />
            <AccentWord word="This" /> tea tastes delicious.
            <br />
          </>,
          <>
            <Typography component="span">
              Вказівний займенник який вказує на те що знаходиться вдалині від нас. <br /> Також
              може вказувати на те що було тільки що сказано.
            </Typography>
            <br />
            Do you see <AccentWord word="that" />?
            <br />
            Please give me <AccentWord word="that" /> candy.
            <br />
          </>,
        ],
      },
      {
        title: "like / love",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              любити
            </Typography>
          </Typography>,
          "Важливо зазначити що кожна людина вкладає свій власний зміст у ці слова залежно від свого досвіду та цінностей.",
        ],
        lesson: [3],
        examples: [
          <>
            I <AccentWord word="like" /> you.
            <br />
            <Typography fontSize={14}>
              Симпатія та прив'язаність: Ця фраза виражає позитивні почуття до людини, інтерес до
              неї, симпатію та прихильність.
              <br />
              Дружба та романтика: Вона може використовуватись як в дружньому, так і в романтичному
              контексті, але на початкових етапах стосунків.
              <br />
              Повага до особистих меж: "I like you" не передбачає глибокої емоційної залежності та
              поваги до особистих меж.
            </Typography>
          </>,
          <>
            I <AccentWord word="love" /> you.
            <br />
            <Typography fontSize={14}>
              Глибоке та сильне почуття: Ця фраза вказує на глибоке, всеосяжне та тривале кохання.
              Вона виходить за рамки простої симпатії.
              <br />
              Відданість та близькість: "I love you" говорить про емоційну близькість, відданість,
              бажання бути разом у довготривалій перспективі.
              <br />
              Серйозність намірів: Використання цієї фрази часто свідчить про серйозність намірів та
              глибину почуттів.
            </Typography>
          </>,
        ],
      },
      {
        title: "study / learn",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              навчатися
            </Typography>
          </Typography>,
        ],
        lesson: [1],
        examples: [
          <>
            I <AccentWord word="learn" /> English.
            <br />
            <Typography fontSize={14}>
              Підкреслює сам процес засвоєння мови, набуття знань та навичок. Можете означати
              вивчення англійської пасивно, наприклад, дивлячись фільми. Часто вивчення мови
              самостійно.
            </Typography>
          </>,
          <>
            I <AccentWord word="study" /> English.
            <br />
            <Typography fontSize={14}>
              Акцент на свідомому та цілеспрямованому зусиллі вивчення мови. Часто вивчення мови у
              навчальному закладі.
            </Typography>
          </>,
        ],
      },
    ],
  },
];

const WordsDifference = () => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  return (
    <>
      {differenceWords.map((rule) => {
        if (rule.isVisible === false) return null;
        return (
          <Box key={rule.name} mb={7}>
            <Container>
              <Typography
                sx={{
                  marginBottom: 4,
                }}
                variant="h3"
              >
                {rule.name}
              </Typography>
            </Container>
            {filterRules(rule.rules, lessonNumber).map((rule, index, rules) => (
              <>
                <AccordionRule
                  raw={rule.raw}
                  isActive={rule.lesson.includes(lessonNumber)}
                  title={rule.title}
                  description={rule.description}
                  examples={rule.examples}
                />
                {/* {index + 1 !== rules?.length ? ", " : ""} */}
              </>
            ))}
          </Box>
        );
      })}
    </>
  );
};

export default WordsDifference;
