import {
  Badge,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import ModalCloseButton from "../../../ModalCloseButton";
import useGlobalWords from "../../../../hooks/getGlobalWords";
import WordAudio from "../../../../Audio/WordAudio";
import VStack from "../../../VStack";
import WordTranscription from "../../../../Word/WordTranscription";
import useLessonWords from "../../../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import { fullScreenModalStyle } from "../../../../../common/constants";
import AccentStrong from "../../../../Description/ModuleDescription/utils/AccentString";
import AccentWord from "../../../../Difference/components/AccentWord";
import removePunctuationFromString from "../../../../../common/removePunctuationFromString";

type CreateDataProp = {
  title?: any;
  en: any;
  lesson: number;
};

function createData(item1: CreateDataProp, item2: CreateDataProp, item3: CreateDataProp) {
  return {
    item1,
    item2,
    item3,
  };
}

const rows = (lessonNumber: number) => [
  createData(
    {
      en: <Typography fontWeight={700}>that</Typography>,
      lesson: 5,
    },
    {
      en: (
        <>
          <Typography fontWeight={700}>той, та, те</Typography>
          <Typography>вказує на предмет, що знаходиться далі</Typography>
        </>
      ),
      lesson: 5,
    },
    {
      en: (
        <>
          <Typography>
            Where is <strong>that</strong> boy?
          </Typography>
          <Typography>
            Please give me <strong>that</strong> candy.
          </Typography>
          <Typography>
            <strong>That</strong> parrot is very beautiful.
          </Typography>
        </>
      ),
      lesson: 5,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>this</Typography>,
      lesson: 900,
    },
    {
      en: (
        <>
          <Typography fontWeight={700}>цей, ця, це</Typography>
          <Typography>вказує на предмет, що знаходиться близько</Typography>
        </>
      ),
      lesson: 7,
    },
    {
      en: (
        <>
          <Typography>
            <strong>This</strong> is a beautiful day.
          </Typography>
          <Typography>
            <strong>This</strong> candy is delicious.
          </Typography>
          <Typography>
            <strong>This</strong> tea tastes delicious.
          </Typography>
        </>
      ),
      lesson: 7,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>these</Typography>,
      lesson: 1000,
    },
    {
      en: (
        <>
          <Typography fontWeight={700}>ці</Typography>
          <Typography>множина від this</Typography>
        </>
      ),
      lesson: 1000,
    },
    {
      en: (
        <>
          <Typography>Where is that boy?</Typography>
          <Typography>Please give me that candy.</Typography>
          <Typography>That parrot is very beautiful.</Typography>
        </>
      ),
      lesson: 1000,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>those</Typography>,
      lesson: 1000,
    },
    {
      en: (
        <>
          <Typography fontWeight={700}>ті</Typography>
          <Typography>множина від that</Typography>
        </>
      ),
      lesson: 1000,
    },
    {
      en: (
        <>
          <Typography>Where is that boy?</Typography>
          <Typography>Please give me that candy.</Typography>
          <Typography>That parrot is very beautiful.</Typography>
        </>
      ),
      lesson: 1000,
    },
  ),
];

const DemonstrativePronounsTable: React.FC<{}> = ({}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [isHeaderCellModalOpen, setIsHeaderCellModalOpen] = useState(false);
  const [activeWord, setActiveWord] = useState("");
  const [activeTranslation, setActiveTranslation] = useState("");
  const [activeDescription, setActiveDescription] = useState("");
  const [activeExamples, setActiveExamples] = useState<any[]>([]);
  const [headerCellModalBody, setHeaderCellModalBody] = useState<any>(null);
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  const globalWordsOptions = useMemo(() => {
    if (activeWord) {
      return { words: [activeWord] };
    }
    return { words: [] };
  }, [activeWord]);

  const [word] = useGlobalWords(globalWordsOptions);

  return (
    <Box>
      <Modal
        open={isCellModalOpen}
        onClose={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsCellModalOpen(false)} />
          <VStack alignItems="flex-start">
            <Typography variant="h3">{activeWord}</Typography>
            <Typography>{activeTranslation}</Typography>
            {word && <WordTranscription word={word} play small={false} autoplay={false} />}
            <Typography>{activeDescription}</Typography>
            <VStack alignItems="flex-start">
              {activeExamples.map((e) => {
                return (
                  <Typography variant="h3" key={e.title}>
                    {e.title}
                  </Typography>
                );
              })}
            </VStack>
          </VStack>
        </Box>
      </Modal>
      <Modal
        open={isHeaderCellModalOpen}
        onClose={() => {
          setIsHeaderCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsHeaderCellModalOpen(false)} />
          <VStack alignItems="flex-start">{headerCellModalBody}</VStack>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            {/* <TableRow>
              <TableCell sx={{ width: 120, minWidth: 120 }}>скорочення</TableCell>
              <TableCell sx={{ width: 230, minWidth: 230 }}>повністю</TableCell>
              <TableCell sx={{ width: 400, minWidth: 400 }}>приклади</TableCell>
            </TableRow> */}
          </TableHead>
          <TableBody>
            {rows(lessonNumber)
              .filter((r) => r.item1.lesson <= lessonNumber)
              .map((row, index) => (
                <TableRow
                  key={row.item1?.title || ""}
                  sx={
                    {
                      // "&:last-child td, &:last-child th": { border: 0 }
                    }
                  }
                >
                  <CustomTableCell width={120} lesson={row.item1.lesson} en={row.item1.en} />
                  <CustomTableCell
                    width={170}
                    lesson={row.item2.lesson}
                    en={row.item2.en}
                  ></CustomTableCell>
                  <CustomTableCell
                    width={400}
                    lesson={row.item3.lesson}
                    en={row.item3.en}
                  ></CustomTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CustomTableCell: React.FC<{
  en: string;
  lesson: number;
  width: number;
}> = ({ en, lesson, width }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady = lesson <= lessonNumber;

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        m: 0,
        width,
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lesson === lessonNumber ? "#ddffc5" : lesson <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        py: 1,
      }}
    >
      {en}
    </TableCell>
  );
};

export default DemonstrativePronounsTable;
