import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LearnWordSection from "../../../Unknown/LearnWordsSection";

const WordsCarousel: React.FC<{
  currentWord: string | null;
  words: string[];
}> = ({ currentWord, words }) => {
  const { lessonId } = useParams();
  const [currentWords, setCurrentWords] = useState<string[]>(words);

  useEffect(() => {
    if (!currentWords.length) setCurrentWords(words);
  }, [words]);

  if (!currentWord) return null;

  return (
    <LearnWordSection
      currentWords={currentWords}
      withoutExamples
      lessonId={`grammar_words_carousel_lesson_${lessonId}`}
      onUpdateWords={(newWords) => {
        setCurrentWords(newWords);
      }}
    />
  );
};

export default WordsCarousel;
