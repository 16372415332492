import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  &: first-child {
    margin-left: 0;
  }
`;

const Title = styled.span`
  font-size: 12px;
  margin-bottom: 5px;
`;

const DigitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
`;

// background-color: #404549;
const SingleDigit = styled.span`
  position: relative;
  display: flex;
  flex: 0 1 25%;
  font-size: 120px;
  background-color: #191b1d;
  border-radius: 10px;
  padding: 0px 27px;
  line-height: 1.2;
  color: white;
  user-select: none;
  font-weight: bold;
  &:first-child {
    margin-right: 2px;
  }
  &:after {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    bottom: 50%;
    content: "";
    width: "100%";
    height: 2px;
    opacity: 0.4;
  }
`;
// background-color: #232323;

export default function Digit({ value, title, onClick }: any) {
  const leftDigit = value >= 10 ? value.toString()[0] : "0";
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
  return (
    <Container>
      {/* <Title>{title}</Title> */}
      <DigitContainer>
        <SingleDigit
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          {leftDigit}
        </SingleDigit>
        <SingleDigit
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          {rightDigit}
        </SingleDigit>
      </DigitContainer>
    </Container>
  );
}
