import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { memo, useState } from "react";
import { Word } from "../../../types/supabase";
import WordExample from "../../Unknown/LearnWordsSection/WordExample";
import WordTranscription from "../../Word/WordTranscription";

interface UpdateWordTranslationItemProps {
  word: Word;
  refreshData: () => void;
}

const UpdateWordTranslationItem: React.FC<UpdateWordTranslationItemProps> = ({
  word,
  refreshData,
}) => {
  const [translation, setTranslation] = useState(word.translation);

  return (
    <Grid key={word.name} item xs={12} gap={2} display="flex">
      <Grid container>
        {/* <Grid item xs="auto">
          <CustomIconButton
            variant="filled"
            onClick={() => handleUpdate(word, translation)}
          >
            <UpgradeIcon />
          </CustomIconButton>
        </Grid> */}
        <Grid item xs={12} display="flex" alignItems="center" gap={2}>
          {!word.audio_url && <Typography>No audio url.</Typography>}
          <WordTranscription word={word} play autoplay={false} small={false} />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              color="primary"
              value={translation}
              onChange={(event) => {
                setTranslation(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid container item xs={12} my={10}>
          <WordExample currentWord={word} currentRowWord={word.name} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(UpdateWordTranslationItem);
