import supabaseClient from "../../common/supabaseClient";
import { Sentence, UserSentence } from "../../types/supabase";

const getExistingAndNotExistingSentences = async ({
  meanings,
  currentUserUuid,
  textFieldMultipleString,
  textFiledTranslationMultipleString,
}: {
  meanings?: string;
  currentUserUuid: string;
  textFieldMultipleString: string;
  textFiledTranslationMultipleString: string;
}): Promise<{
  notExistingSentences: { en: string; ua: string }[];
  newUserLessonSentences: string[];
  existingSentences: Sentence[];
  existingRowSentences: string[];
}> => {
  const rowEnSentences = textFieldMultipleString
    .split("\n")
    .map((s) => s.trim())
    .filter(Boolean);

  const rowMeaningSentences = meanings
    ?.split("\n")
    .map((s) => s.trim())
    .filter(Boolean);

  const rowUaSentences = textFiledTranslationMultipleString
    .split("\n")
    .map((s) => s.trim())
    .filter(Boolean);

  const { data: existingSentences } = await supabaseClient
    .from("sentences")
    .select()
    .in(
      "en",
      rowEnSentences.map((s) => s.replace(/"/g, "'")),
    );

  const doubleQuotesSentences = rowEnSentences.filter((s) => s.includes('"'));
  console.log("🚀 ~ doubleQuotesSentences:", doubleQuotesSentences);

  const allExistingSentences = [...existingSentences!];

  if (doubleQuotesSentences.length) {
    for (let i = 0; i < doubleQuotesSentences.length; i++) {
      const { data: existingSentence } = await supabaseClient.from("sentences").select().eq("en", doubleQuotesSentences[i]).maybeSingle();
      console.log("🚀 ~ existingSentence:", existingSentence);

      if (existingSentence) allExistingSentences.push(existingSentence);
    }
  }
  console.log("🚀 ~ allExistingSentences:", allExistingSentences);

  const existingRowSentences: string[] = allExistingSentences.map((s) => s.en);
  console.log("🚀 ~ existingRowSentences:", existingRowSentences);

  const notExistingSentences: { en: string; ua: string }[] = rowEnSentences
    .map((s, i) => {
      return {
        en: s,
        ua: rowUaSentences[i],
        meaning: rowMeaningSentences && rowMeaningSentences[i] !== "-" ? rowMeaningSentences[i] : "",
      };
    })
    .filter((sentence) => !existingRowSentences.includes(sentence.en));
  console.log("🚀 ~ notExistingSentences:", notExistingSentences);

  const { data: userExistingSentences } = await supabaseClient
    .from("user-sentences")
    .select()
    .in(
      "id",
      Array.from(new Set(rowEnSentences)).map((s) => `${currentUserUuid}-${s}`),
    )
    .returns<UserSentence[]>();

  const rowUserExistingSentences = userExistingSentences!.map((s) => s.en);

  console.log("🚀 ~ rowEnSentences:", rowEnSentences);
  console.log("🚀 ~ rowUserExistingSentences:", rowUserExistingSentences);
  const newUserLessonSentences: string[] = rowEnSentences.filter((sentence) => !rowUserExistingSentences.includes(sentence));
  console.log("🚀 ~ newUserLessonSentences:", newUserLessonSentences);

  return {
    notExistingSentences,
    existingSentences: allExistingSentences,
    newUserLessonSentences,
    existingRowSentences,
  };
};

export default getExistingAndNotExistingSentences;
