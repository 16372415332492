import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import NotFound from "../../Unknown/NotFound";
import { allDifferenceWords } from "../Page";
import ItThis from "../components/ItThis";
import { DifferenceWithSentence } from "../../../types/supabase";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import SentenceListeningSection from "../../Unknown/SentenceListeningSection";
import { SentenceTranslationSection } from "../../Unknown/SentenceTranslationSection";
import LearnWordSection from "../../Unknown/LearnWordsSection";
import Loader from "../../Unknown/Loader";
import DoWorkJob from "../components/doWorkJob";
import { usePrevious } from "react-use";
import ReceiveGet from "../components/ReceiveGet";
import lessonVideoUrlMap from "./lessonVideoUrlMap";
import VideoModal from "../../Unknown/VideoModal";
import BigLargeGreat from "../components/BigLargeGreat";
import BadBadly from "../components/BadBadly";

export const Difference: React.FC = () => {
  const { subject } = useParams();
  const prevSubject = usePrevious(subject);
  const authUser = useLessonsStore((state) => state.authUser);

  const [currentWords, setCurrentWords] = useState<string[] | null>(null);
  const [sentences, setSentences] = useState<DifferenceWithSentence[] | null>(
    null,
  );

  useEffect(() => {
    if (subject !== prevSubject) {
      setSentences(null);
      setCurrentWords(null);
    }
  }, [subject, prevSubject]);

  useEffect(() => {
    (async () => {
      if (authUser && sentences?.length && !currentWords) {
        const { data: userWords, error } = await supabaseClient
          .from("difference-user-words")
          .select("id, words")
          .eq("id", `${authUser.uuid}-${subject}`)
          .maybeSingle();

        if (userWords?.words.length) {
          setCurrentWords(userWords.words);
          return;
        }

        const sentenceWords = Array.from(
          new Set(sentences.map((s) => s.words).flat()),
        );

        const { data: words } = await supabaseClient
          .from("user-words")
          .select("id")
          .in(
            "id",
            sentenceWords.map((word) => `${authUser.uuid}-${word}`),
          );

        if (!words) return;
        const existingWords = words.map((w) =>
          w.id.replace(`${authUser.uuid}-`, ""),
        );

        const notExistingWords = sentenceWords.filter(
          (w) => !existingWords.includes(w),
        );
        setCurrentWords(notExistingWords);

        await supabaseClient.rpc("add_difference_user_words", {
          words: notExistingWords,
          topic: subject,
        });
      }
    })();
  }, [currentWords, sentences, authUser]);

  // const sentences = useMemo(() => {
  //   if (subject === "it, this") return itThisSentences;
  //   return [];
  // }, [subject]);
  // const [currentWord] = currentWords;

  useMountedWaitAsyncEffect(async () => {
    setSentences([]);

    if (!subject) return;

    const { data: sentences } = await supabaseClient
      .from("difference")
      .select("*, en(*)")
      .eq("combination", subject)
      .returns<DifferenceWithSentence[]>();

    if (sentences) setSentences(sentences);
  }, [subject]);

  if (!subject) return null;

  if (!allDifferenceWords.includes(subject)) return <NotFound />;

  if (!currentWords || !sentences) return <Loader />;

  return (
    // <MenuLayout withDifferenceMenu>
    <Grid container textAlign="center">
      <Grid item xs={12}>
        <Typography variant="h2" component="h1">
          Різниця між: {decodeURIComponent(subject.split("-").join(", "))}
        </Typography>
      </Grid>
      <Grid item xs={12} mb={5}>
        {decodeURIComponent(subject) === "it-this" && <ItThis />}
        {decodeURIComponent(subject) === "do-work-job" && <DoWorkJob />}
        {decodeURIComponent(subject) === "receive-get" && <ReceiveGet />}
        {decodeURIComponent(subject) === "big-large-great" && <BigLargeGreat />}
        {decodeURIComponent(subject) === "bad-badly" && <BadBadly />}
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {currentWords.length !== 0 && (
            <Grid item xs={12}>
              <LearnWordSection
                currentWords={currentWords}
                lessonId={`difference_slider_${subject}`}
                onUpdateWords={(newWords) => {
                  setCurrentWords(newWords);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SentenceListeningSection
              sliderId={`difference-slider-${subject}_3b81d37a-30f3-4df1-a146-d6ea461b0edf`}
              lessonId={subject}
              lessonType="difference"
              differenceSentences={sentences}
            />
          </Grid>
          <Grid item xs={12}>
            <SentenceTranslationSection
              sliderId={`difference-slider-${subject}_028188e6-d01c-4a65-9441-e261ecd8a984`}
              lessonId={subject}
              lessonType="difference"
              differenceSentences={sentences.sort((a, b) => a.order - b.order)}
            />
          </Grid>
          {lessonVideoUrlMap[subject] && (
            <Grid item xs={12}>
              <VideoModal
                buttonLabel="Відео пояснення"
                buttonVariant="outlined"
                position="center"
                videoUrl={lessonVideoUrlMap[subject]}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
    // </MenuLayout>
  );
};
