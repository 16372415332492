import { Box, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import firstLetterUppercase from "../../../common/firstLetterUppercase";
import WordAudio from "../../Audio/WordAudio";

interface UkrainianAudioSentenceProps {
  // audioUrl: string;
  sentence: string;
  fontSize?: number;
  isSuccess?: boolean;
  // isAudioPlay: boolean;
}

const UkrainianAudioSentence = ({
  // audioUrl,
  sentence,
  fontSize,
  isSuccess,
}: // isAudioPlay,
UkrainianAudioSentenceProps) => {
  const s = useMemo(() => {
    if (sentence.match(" / ")) {
      const [first, second] = sentence.split(" / ");
      return (
        <Box component="span">
          {firstLetterUppercase(first)}
          <br />
          {second}
        </Box>
      );
    }

    return <Box component="span">{firstLetterUppercase(sentence)}</Box>;
  }, [sentence]);

  return (
    <Box position="relative">
      <Typography
        variant="h6"
        // color={isSuccess ? "#347406" : "primary.second"}
        color={"primary.second"}
        fontSize={fontSize || 20}
        // fontStyle="italic"
        // textAlign="left"
        lineHeight={1.2}
        fontWeight={400}
      >
        {s}
      </Typography>
      {/* {isAudioPlay && (
        <WordAudio url={audioUrl} autoPlay={true} boxHide={true} />
      )} */}
    </Box>
  );
};

export default memo(UkrainianAudioSentence);
