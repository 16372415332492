import { Link as MuiLink, Box, Typography, Icon, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { Module, Sentence, Word } from "../../../types/supabase";
import MenuLayout from "../../Layout/MenuLayout";
import Loader from "../../Unknown/Loader";

const grammarMapp = [
  {
    id: 1,
    block: "nouns",
    rule: "Можуть бути в однині (singular) і в множині (plural)",
  },
  {
    id: 2,
    block: "nouns",
    rule: "Можуть бути власними (proper/specific) та загальними (common/general)",
  },
  {
    id: 3,
    block: "nouns",
    rule: "Власні (proper/specific) іменники пишуться з великої букви",
  },
  {
    id: 4,
    block: "nouns",
    rule: "Власні (proper/specific) іменники пишуться з великої букви",
  },
  {
    id: 5,
    block: "nouns",
    rule: "Можуть бути правильними (reqular) і неправильними (irregular)",
  },
  {
    id: 6,
    block: "plural-nouns",
    rule: "Правильні (regular plural) іменники: [singular word] + [s/es]",
    examples: ["dog -> dogs", "cat -> cats"],
  },
  {
    id: 7,
    block: "plural-nouns",
    title: "Неправильні з закінченням на [f]",
    rule: "[f] замінюється на [ves]",
    examples: ["leaf -> leaves", "loaf -> loaves", "calf -> calves"],
  },
  {
    id: 8,
    block: "plural-nouns",
    title: "Чотири неправильні іменники з закінченням на [ren]",
    rule: "[singular word] + [ren]",
    examples: ["child -> children", "ox -> oxen", "brother -> brotheren", "sister -> sisteren"],
  },
  {
    id: 9,
    block: "plural-nouns",
    title: "Слово в однині одночасно є і формою множини (base plural)",
    rule: "[singular word] + [ren]",
    examples: [
      "sheep -> sheep",
      "deer -> deer",
      "fish -> fish",
      "bison -> bison",
      "fruit -> fruit",
      "spacecraft -> spacecraft",
      "cod -> cod",
      "squid -> squid",
    ],
  },
  {
    id: 10,
    block: "plural-nouns",
    title: "Винятки (base plural), один вид (irregular/base plural) / багато видів (regular)",
    rule: "Деякі іменники можуть бути одночасно як base plural так і правильними іменникам (reqular)",
    examples: ["fruit -> fruits", "fish -> fishes"],
  },
  {
    id: 11,
    block: "plural-nouns",
    title: "Сім слів мутантів",
    rule: "Слова які змінюють голосну літеру при утворенні множини",
    examples: [
      "foot -> feet",
      "woman -> women",
      "man -> men",
      "tooth -> teeth",
      "goose -> geese",
      "mouse -> mice",
      "louse -> lice",
    ],
  },
  {
    id: 12,
    block: "plural-nouns",
    title: "Слова іншомовного походження. Можуть бути як неправильними, так і правильними.",
    rule: [
      {
        title: "a -> ae (latin)",
        examples: [
          "larva -> larvas (informal) / larvae (formal)",
          "antenna -> antennas (informal) / antennae (formal)",
        ],
      },
      {
        title: "us -> i (latin)",
        examples: [
          "fungus -> funguses (informal) / fungi (formal)",
          "cactus -> cactuses (informal) / cacti (formal)",
        ],
      },
      {
        title: "um -> a (latin)",
        examples: ["datum -> - / data"],
      },
      {
        title: "ex -> ices (latin)",
        examples: ["index -> indexes (informal) / indices (formal)"],
      },
      {
        title: "is -> es (greek)",
        examples: [
          "axis -> - / axes (formal)",
          "crisis -> - / crises (formal)",
          "thesis -> thesises (informal) / theses (formal)",
        ],
      },
      {
        title: "on -> a (greek)",
        examples: ["criterion -> - / criteria (formal)"],
      },
    ],
  },
  {
    id: 13,
    block: "build-sentence",
    title: "Базова структура речення",
    rule: "[підмет/особа] + [дієслово/присудок] + ...",
    examples: ["Я сижу на стільці"],
  },
  {
    id: 14,
    block: "verbs",
    title: "Кожне речення повинно мати дієслово.",
    // rule: "[підмет/особа] + [дієслово/присудок] + ...",
    examples: ["I go.", "It is beautiful."],
  },
  {
    id: 14,
    block: "verbs",
    title: "Не більше однієї ʼ-sʼ в речені.",
    // rule: "[підмет/особа] + [дієслово/присудок] + ...",
    examples: ["The dog barks. -> The dogs bark."],
    incorect: ["The dogs barks."],
  },
  {
    id: 15,
    block: "tenses",
    title: "Три основні часи: минулий, теперішній, майбутній",
    // rule: "[підмет/особа] + [дієслово/присудок] + ...",
    // examples: ["I worked. -> I work. -> I will work."],
    // incorect: ["The dogs barks."],
  },
  {
    id: 16,
    block: "tenses",
    title: "Три форми часу: питальна, стверджувальна, заперечна",
    // rule: "[підмет/особа] + [дієслово/присудок] + ...",
    examples: [
      "Do they work?. -> They work. -> They don't work.",
      "Does she work?. -> Works works. -> She doesn't work.",
    ],
    // incorect: ["The dogs barks."],
  },
  {
    id: 17,
    block: "verbs",
    title: "Дієслова дії (action verbs) та дієслова звʼязування (linking verbs)",
    // rule: "[підмет/особа] + [дієслово/присудок] + ...",
    examples: [
      "The bear eats. / The bear is hungry.",
      "The bear looks at me. / The bear looks lonely.",
    ],
    // incorect: ["The dogs barks."],
  },
  {
    id: 18,
    block: "verbs",
    title: "Допоміжні дієслова (helping/auxiliary verbs)",
    // rule: "[підмет/особа] + [дієслово/присудок] + ...",
    examples: [],
    words: ["to have", "to be", "might", "should"],
    // incorect: ["The dogs barks."],
  },
  {
    id: 19,
    block: "verbs",
    title: "Дієслова стану (linking verbs)",
    // rule: "[підмет/особа] + [дієслово/присудок] + ...",
    // examples: [""],
    words: ["to be", "look", "smell"],
    // incorect: ["The dogs barks."],
  },
  {
    id: 19,
    block: "verbs",
    title: "Irregular verbs vowel shifts",
    // rule: "[підмет/особа] + [дієслово/присудок] + ...",
    // examples: [""],
    // words: ["to be", "look", "smell"],
    // incorect: ["The dogs barks."],
  },
  {
    id: 20,
    block: "articles",
    title: "The артикль",
    rule: "Відоме / те про що говоримо / щось конкретне (specific / definite)",
    examples: ["The car is red."],
  },
  {
    id: 21,
    block: "articles",
    title: "A/an артикль",
    rule: "Загальне твердження, один елемент, те що не визначене (not specific / indefinite)",
    examples: ["An elephant is a big animal.", "I want a new table.", "I want an orange."],
  },
  {
    id: 22,
    block: "build-sentence",
    title: "Порядок слів",
    rules: [
      {
        rule: [
          "Прямий порядок слів (direct word order):",
          "підмет (хто? що?)",
          "присудок / дія (що робить?)",
          "додаток (кому? для кого? що? чим? ким? чому? кого?)",
          "обставина (де? коли? як? куди?)",
        ],
        examples: ["I want to do it today.", "I want to buy a new phone in the new store."],
      },
      {
        title: [
          "Непрямий порядок слів (indirect word order) або інверсія – порядок слів, в якому підмет стоїть після присудка або ж певної частини присудка.",
        ],
        rules: [
          {
            rule: "Використовується в більшості питальних речень. Перед підметом ставиться тільки частина присудка (допоміжне або модальне дієслово).",
            examples: ["Do you know it?"],
          },
          {
            rule: "Якщо присудок виражений смисловим дієсловом to be (бути) або to have (мати), то вони ставляться перед підметом та не потребують допоміжних дієслів.",
            examples: ["Have you a pen?", "Is she at home now?"],
          },
          {
            rule: "Використовується в реченнях, що починаються з there (конструкція there is / there are).",
            examples: [
              "There is an apply on the table.",
              "There worked a lot of people for that plant.",
              "There plays Jane with her son in the garden.",
            ],
          },
          {
            rule: "Використовується в реченнях, що починаються зі слова here (ось, тут). Однак якщо підмет виражений займенником, тоді використовується прямий порядок слів.",
            examples: ["Here is your teddy.", "Here is the house where Jack lives."],
            exceptions: ["Here he is!", "Here we go!"],
          },
          {
            rule: "Зустрічається у другій частині складних речень після so (також як і) та neither (також не). В такому випадку в залежній (підрядній) частині використовується таке ж саме допоміжне дієслово, як і в головній частині.",
            examples: ["Most of French are good at cooking and so are you, right? "],
          },
          {
            rule: "Використовується в простих окличних реченнях, що виражають побажання, напутні слова.",
            examples: ["Be it so!", "May you never be sad!"],
          },
          {
            rule: "Використовується для більшої виразності речення або ж для смислового підкреслення певної частини речення. Це залежить не від типу речення, а більше від бажання та намірів автора і використовується в художній літературі. Часто непрямий порядок слів вживається, коли на початок речення виноситься обставина.",
            examples: [
              "Silently and attentively did the man listen to the priest.",
              "In the dark wood with no paths stood and shouted two boys.",
            ],
          },
          {
            rule: "Може бути використаним, якщо на початку речення стоять обставини in vain (даремно), never (ніколи), little (зовсім не), що передають негативне значення, а також слова only (тільки), hardly (навряд чи), no sooner (одразу після) ",
            examples: [
              "In vain were we trying to find Mary in the wood.",
              "Never before have I seen such beautiful park.",
              "Little does he think that he doesn’t need our help.",
            ],
          },
          {
            rule: "Зустрічається також після слів so (так), thus (таким чином), now (зараз, тоді), then (тоді, після), що стоять на початку речення. Якщо підмет виражений займенником, то у реченні використовується прямий порядок слів.",
            examples: [
              "Now was the time to attack.",
              "So spent he his holidays in Paris.",
              "Thus spoke Mr. Jameson standing near the door.",
            ],
          },
          {
            rule: "Може використовуватися після приcлівників, що вказують на напрямок, наприклад, in, out, down, away, up, якщо вони стоять на початку речення. Але якщо підмет виражений займенником, то в таких реченнях використовується прямий порядок слів.",
            examples: ["Away ran children.", "Down fell Peter.", "Up flew Kate’s baloon."],
            exceptions: ["I fell down.", "It flew away."],
          },
          {
            rule: "Вживається в умовних реченнях без сполучників, якщо присудок підрядної частини виражений дієсловами was, were, had, could або ж should. З непрямим порядком слів речення звучать емоційніше та яскравіше. ",
            examples: ["Even was Ann starving, she would never ask for money. "],
          },
        ],
      },
      {
        title: "Додаток у реченні.",
        rules: [
          {
            rule: "В розповідному реченні (стверджувальному або заперечному) в більшості випадків ставиться після присудка.",
            examples: ["I want to do it.", "I want to buy a new phone."],
          },
          {
            rule: "В окличних реченнях прямий додаток може стояти на початку речення.",
            examples: ["What a nice day we have today!"],
          },
          {
            rule: "Інколи прямий додаток може ставитися в кінець речення, коли він відокремлюється від присудка іншими членами речення (обставиною). Це робиться для того, щоб інтонаційно підкреслити  або виділити додаток.",
            examples: [
              "Matt saw in the morning paper his own article.",
              "She took from her bag and gave John a big chocolate bar.",
            ],
          },
          {
            rule: "Непрямий прийменниковий додаток інколи може вживатися на початку речень для їхньої виразності. Це характерно для англійської розмовної мови.",
            examples: ["In God we trust.", "For Mark it wasn’t a big problem."],
          },
          {
            rule: "Інколи після непрямого прийменникового додатка на початку речення може використовуватися інверсія (непрямий порядок слів: присудок перед підметом), часто це зустрічається в художній літературі.",
            examples: ["For this lady were written all my poems."],
          },
        ],
      },
    ],
    exceptions: [
      "What a nice day we have today. (окличне речення)",
      "I gave him this yesterday. (інтонація)",
      "In God we trust. (щоб надати виразності)",
    ],
  },
  {
    id: 22,
    block: "build-sentence",
    title: "Означення у реченні",
    rules: [
      {
        rule: "у більшості випадків вживається перед додатком (який? чого? кого? якого?)",
        examples: ["I want a new phone"],
      },
      {
        rule: "після додатку якщо означення закінчується на -able, -ible",
        examples: ["I want a beautiful big phone.", "I want the best phone possible."],
      },
      {
        rule: "після іменника у деяких сталих висловах",
        examples: [
          "wealth untold",
          "from times immemorial",
          "a poet laureate",
          "a generations unborn",
          "cour martial",
          "sum total",
          "four years running",
        ],
      },
      {
        rule: "Означення proper в значенні «у власному значенні, як такий» та present в значенні «присутній» стоять після іменника, до якого відносяться.",
        examples: [
          "This book tells us about art proper.",
          "All the people present clapped when the actor appeared on the stage.",
        ],
      },
      {
        rule: "Означення, виражені кількісними числівниками, що вказують на номер серії або ж місце предмета, завжди стоять після іменника. В такому випадку артиклі не використовуються.",
        examples: ["You can find it on page ten.", "I live on the street Shevchenka five."],
      },
      {
        rule: "після неозначених іменників: somebody, anybody, nobody, someone, something...",
        examples: ["I want to eat something sweet.", "He did nothing useful."],
      },
      {
        rule: "після іменників щоб підкреслити або виділити",
        examples: [
          "Jack, tired and pale, fell on the sofa",
          "I looked in her eyes, large, blue and beautiful.",
        ],
      },
    ],
  },
  {
    id: 23,
    block: "build-sentence",
    title: "Обставина у реченні",
    rules: [
      {
        rule: "перед присудкума та після додатку",
        examples: ["Paul always buys bread here."],
      },
      {
        rule: "обставина часу може стояти спочатку або у кінці речення",
        examples: ["Tommorow I will do it.", "I want to do it today."],
      },
      {
        rule: "обставини now (зараз) та then (тоді) можуть стояти будь де",
        examples: [""],
      },
      {
        rule: "обставина часу (morning, night, day, everning...) завжди стоїть після конкретного часу або ж його проміжку",
        examples: ["I will come at 8 in the morning.", "At 11 in the evening my phone rang."],
      },
      {
        rule: "обставина місця (here, there, in the park...) в більшості випадків ставиться на початок або кінець речення",
        examples: ["Here my mother works.", "I like walking in this park."],
      },
      {
        rule: "якщо у реченні декілька обставин, то обставина місця ставиться перед обставинами часу та причини",
        examples: ["I am going to the country next week."],
      },
      {
        rule: "обставини частотності (ever, never, always, occasionally...) у більшості випадків ставиться перед смисловим дієсловом",
        examples: ["He always does it.", "Have you ever been to London?"],
      },
      {
        rule: "Обставина способу (loudly) ставиться відразу після неперехідного дієслова.",
        examples: ['"Just do it!" she said loudly.'],
      },
      {
        rule: "Обставина способу (loudly) ставиться відразу після прямого додатку перехідного дієслова.",
        examples: ["Kate read the contract attentively and then signed it."],
      },
      {
        rule: "Якщо у присудка є прийменниковий додаток, тоді обставина способу дії ставиться між ними (після присудка та перед прийменниковим додатком).",
        examples: ["Charles asked politely for a cup of tea and some sugar."],
      },
      {
        rule: "Обставина ступеня та міри (entirely, quite, almost) завжди ставиться перед присудком. Якщо це складений присудок, то обставина ставиться після допоміжного дієслова.",
        examples: ["We entirely agree with you.", "I am quite tired at the moment."],
      },
      {
        rule: "Обставина ступеня enough (достатньо) ставиться після прикметника до якого відноситься. Якщо це слово відноситься до іменника, то воно може стояти як перед, так і після нього. ",
        examples: [
          "My sister is clever enough but really lazy. ",
          "I have enough money, don’t worry.",
          "We have time enough to rest a bit.",
        ],
      },
    ],
  },
  {
    id: 24,
    block: "verbs",
    title: "Перехідні дієслова (transitive verbs)",
    rules: [
      {
        rule: "Перехідні дієслова (transitive verbs). Дія переходить на додаток. (присудок + додаток)",
        examples: ["I'm writing a letter.", "I wash my dog."],
      },
      {
        rule: "Неперехідні дієслова (intransitive verbs). Дія яка не потребує після себе додатку.",
        examples: ["She is laughing.", "I fell from the stairs yesterday."],
      },
      {
        rule: "Багато дієслів можу бути одночасно перехідними та неперехідними.",
        examples: ["She is laughing.", "I fell from the stairs yesterday."],
      },
      // verbs types
      {
        rule: "Смислові дієслова (notional verbs) є самостійними дієсловами, що мають своє власне лексичне значення і не мають необхідності в інших додаткових словах.",
        examples: ["I love this world!", "I work."],
      },
    ],
  },
  {
    id: 25,
    block: "verbs",
    title: "Допоміжні дієслова (auxiliary verbs)",
    description:
      "дієслова, що повністю втратили своє лексичне значення (вони не перекладаються українською) та використовуються для утворення граматичних конструкцій та форм дієслова.",
    words: ["to do", "to have", "shall", "will", "to be", "may", "would", "should"],
    // rules: [
    //   {
    //   }
    // ],
  },
];

const GrammarScreen: React.FC = () => {
  const [lessonSentences, setLessonSentences] = useState<Sentence[]>([]);
  const [module, setModule] = useState<Module>();
  const [modules, setModules] = useState<Module[]>([]);
  const [currentModuleWords, setCurrentModuleWords] = useState<Word[]>([]);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const authUser = useLessonsStore((state) => state.authUser);

  // const fetchPageWordsWithAdditional = async (): Promise<FunctionGetWordsWithAdditionalReturn[]> => {
  // const { data } = await supabaseClient
  //   .rpc("get_words_with_additional", {
  //     book_id: "harry-potter-and-the-sorcerer-stone",
  //     page_number: 1,
  //   })
  //   .returns<FunctionGetWordsWithAdditionalReturn[]>();

  // return data!;
  // };
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  // const [allWords, setAllWords] = useState<any[]>([]);
  // useMountedWaitAsyncEffect(async () => {
  //   const { data } = await supabaseClient
  //     .from("grammar_course")
  //     .select()
  //     .gt("module", 0)
  //     .lt("module", 10);

  //   // setAllWords(data!.map((w) => w.write_words).flat());
  //   setAllWords(data!);
  // }, []);

  useEffect(() => {
    if (module) {
      const map = globalWords
        .filter(
          (w) =>
            module.words.includes(w.name) || module.words.includes((w.name || "").toLowerCase()),
        )
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [(w.name || "").toLowerCase()]: w,
          };
        }, {});
      console.log("🚀 ~ map:", map);

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, module]);

  useMountedWaitAsyncEffect(async () => {
    if (module?.words) {
      const existingWords = globalWords.filter(
        (w) => module.words.includes(w.name) || module.words.includes(w.name.toLowerCase()),
      );

      if (existingWords.length === module.words.length) {
        setCurrentModuleWords(existingWords);
      } else {
        const rowExistingWords = existingWords.map((w) => w.name);
        const notExistingWords = module.words.filter(
          (w) => !rowExistingWords.includes(w) && !rowExistingWords.includes(w.toLowerCase()),
        );

        const { data } = await supabaseClient.from("words").select().in("name", notExistingWords);

        if (data) {
          setCurrentModuleWords([...existingWords, ...data]);

          setGlobalWords(data);
        }
      }
    }
  }, [module]);

  useMountedWaitAsyncEffect(async () => {
    if (!modules?.length) {
      const { data: modules } = await supabaseClient
        .from("grammar_course")
        .select()
        .order("module")
        .returns<Module[]>();
      if (modules) setModules(modules);
    }
  }, []);

  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const setLessonNumber = useLessonsStore((state) => state.setLessonNumber);

  useEffect(() => {
    if (!lessonNumber && lessonNumber !== 0) {
      setLessonNumber(authUser?.grammar_lesson || 0);
    }
  }, [lessonNumber, authUser]);

  return (
    <MenuLayout isFluid>
      {/* {allWords.map((w) => (
        <Box component="span" sx={{ p: 1, display: "inline-block" }}>
          <br />
          ---
          <br />
          module: {w.module}
          <br />
          ---
          <br />
          {w.words?.map((w: any) => (
            <Box component="span" sx={{ p: 1, display: "inline-block" }}>
              {w}
            </Box>
          ))}
        </Box>
      ))} */}
      {/* <Container> */}
      {/* <Typography>Відео</Typography>
        <Box>
          <Typography>The Alphabet</Typography>
        </Box> */}
      {/* </Container> */}
      <Container sx={{ backgroundColor: "white", py: 5 }}>
        <Typography>Уроки</Typography>
        {!modules.length && <Loader />}
        {!!modules.length &&
          modules.map((module) => {
            const linkText = (
              <Typography pb={1}>
                {module.module}.{module.description}
                <Typography component="span" color="primary" sx={{ fontSize: 12 }}>
                  {" "}
                  (
                  {module.module === 1
                    ? module.words_count
                    : `${module?.words_count} / +${module?.new_words_count}`}
                  )
                </Typography>
              </Typography>
            );

            if (!authUser || !authUser.grammar_lesson || authUser.grammar_lesson < module.module) {
              return <Box sx={{ color: "grey" }}>{linkText}</Box>;
            }

            return (
              <MuiLink
                key={module.module}
                color="secondary.dark"
                component={Link}
                to={`/grammar/grammar-lesson/${module.module}/build-words-list`}
              >
                {linkText}
              </MuiLink>
            );
          })}
      </Container>
    </MenuLayout>
  );
};

export default GrammarScreen;
