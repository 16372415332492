import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";

const ItThis = () => {
  return (
    <>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          <Typography component="span" variant="h3" color="primary">
            It&nbsp;
          </Typography>
          is my brother.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          <Typography component="span" variant="h3" color="primary">
            This&nbsp;
          </Typography>
          is my brother.
        </Typography>
      </Box>
      ❓
      <Typography variant="h3" gutterBottom fontWeight={400}>
        <Typography component="span" variant="h3" color="primary">
          It&nbsp;
        </Typography>
        table.
      </Typography>
      <Typography variant="h3" gutterBottom fontWeight={400}>
        <Typography component="span" variant="h3" color="primary">
          It&nbsp;
        </Typography>
        is table.
      </Typography>
      <Typography variant="h3" fontWeight={400}>
        <Typography component="span" variant="h3" color="primary">
          This&nbsp;
        </Typography>
        table.
      </Typography>
    </>
  );
};

export default ItThis;
