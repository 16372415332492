import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { RPCUser } from "../../common/fetchCurrentUser";
import supabaseClient from "../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import { Sentence } from "../../types/supabase";
import MenuLayout from "../Layout/MenuLayout";
import VStack from "../Unknown/VStack";
import NewLessonSentences from "./NewLessonSentences";
import NewLessonWords from "./NewLessonWords";
import UserNewLessonSentences from "./UserNewLessonSentences";
import UserNewLessonWords from "./UserNewLessonWords";
import analyzeAllSentencesWords from "./analyzeAllSentencesWords";
import getExistingAndNotExistingSentences from "./getExistingAndNotExistingSentences";
import AdminCreateSentenceFields from "../AdminCreateSentenceFields";

const CreatePersonalLesson: React.FC = () => {
  const [users, setUsers] = useState<RPCUser[]>([]);
  const [currentUser, setCurrentUser] = useState<RPCUser | null>(null);
  console.log("🚀 ~ currentUser:", currentUser);
  const resetPersonalLessons = useLessonsStore((state) => state.resetPersonalLessons);
  const authUser = useLessonsStore((state) => state.authUser);

  useEffect(() => {
    if (users.length && authUser) {
      const user = users.find((u) => u.id === authUser.uuid);
      if (user) setCurrentUser(user);
    }
  }, [users, authUser]);

  useEffect(() => {
    (async () => {
      if (users.length) return;
      const { data: spUsers } = await supabaseClient.from("users").select("id, uuid, lessons").returns<RPCUser[]>();

      if (spUsers) setUsers(spUsers);
    })();
  }, [users]);

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }} p={5}>
        <Grid container>
          <Grid item xs={12}>
            {
              <Select
                value={currentUser?.id || ""}
                variant="standard"
                fullWidth
                style={{
                  height: "16px",
                  fontSize: "12px",
                  minHeight: 0,
                }}
                onChange={(value) => {
                  resetPersonalLessons();
                  setCurrentUser(users.find((u) => u.id === value.target.value)!);
                }}
              >
                {users.map((w, index) => (
                  <MenuItem key={w.id} value={w.id}>
                    {w.id}
                  </MenuItem>
                ))}
              </Select>
            }
          </Grid>
          {currentUser && (
            <Grid item xs={12}>
              <LessonCreation userUuid={currentUser.uuid} />
            </Grid>
          )}
        </Grid>
      </Box>
    </MenuLayout>
  );
};

const LessonCreation: React.FC<{
  userUuid: string;
}> = ({ userUuid }) => {
  const [enValue, setEnValue] = useState("");
  const [uaValue, setUaValue] = useState("");
  const [meaningValue, setMeaningValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [lessonName, setLessonName] = useState("");

  const [spUser, setSpUser] = useState<RPCUser>();

  useMountedWaitAsyncEffect(async () => {
    if (userUuid) {
      const { data: user } = await supabaseClient.from("users").select().eq("uuid", userUuid).single();

      if (!user) throw new Error("User not found");

      setSpUser(user);
    }
  }, [userUuid]);

  const [sentencesLoading, setSentencesLoading] = useState(false);

  // const [lessonWords, setLessonWords] = useState<Word[]>([]);
  const [newLessonWords, setNewLessonWords] = useState<string[]>([]);
  console.log("🚀 ~ newLessonWords:", newLessonWords);
  const [userLessonWordsForLessonCreation, setUserLessonWordsForLessonCreation] = useState<string[]>([]);
  console.log("userLessonWordsForLessonCreation", userLessonWordsForLessonCreation);

  const [userLessonSentencesForLessonCreation, setUserLessonSentencesForLessonCreation] = useState<string[]>([]);
  console.log("userLessonSentencesForLessonCreation", userLessonSentencesForLessonCreation);
  const [userNewLessonWords, setUserNewLessonWords] = useState<string[]>([]);
  const [newLessonSentences, setNewLessonSentences] = useState<{ en: string; ua: string }[]>([]);
  const [userNewLessonSentences, setUserNewLessonSentences] = useState<string[]>([]);

  const findLessonWords = async () => {
    setSentencesLoading(true);
    // words: new at all and new for current user;
    const {
      // lessonWords,
      newRowLessonWords,
      // allSentenceWords,
      // rowUserExistingWords,
      newUserLessonWords,
    } = await analyzeAllSentencesWords({
      currentUserUuid: userUuid,
      textFieldMultipleString: enValue,
    });
    // setLessonWords(lessonWords);

    // record only once, then reset after lesson created
    if (!userLessonWordsForLessonCreation.length) {
      setUserLessonWordsForLessonCreation(newUserLessonWords);
    }
    // create new words first, then words for current user
    if (newRowLessonWords.length) {
      setNewLessonWords(newRowLessonWords);
      setSentencesLoading(false);
      return;
    } else {
      setNewLessonWords([]);
    }
    // create new USER words after new words created
    if (newUserLessonWords.length) {
      setUserNewLessonWords(newUserLessonWords);
      setSentencesLoading(false);
      return;
    } else {
      setUserNewLessonWords([]);
    }
    setSentencesLoading(false);
  };

  const findLessonSentences = async () => {
    setSentencesLoading(true);
    // sentences: new at all and new for current user;
    const {
      notExistingSentences,
      newUserLessonSentences,
      // existingSentences,
      // existingRowSentences,
    } = await getExistingAndNotExistingSentences({
      currentUserUuid: userUuid,
      textFieldMultipleString: enValue,
      textFiledTranslationMultipleString: uaValue,
    });
    console.log("🚀 ~ newUserLessonSentences:", newUserLessonSentences);
    console.log("🚀 ~ notExistingSentences:", notExistingSentences);

    // record only once, then reset after lesson created
    if (!userLessonSentencesForLessonCreation.length) {
      setUserLessonSentencesForLessonCreation(newUserLessonSentences);
    }
    // create new sentences first, then sentences for current user
    if (notExistingSentences.length) {
      setNewLessonSentences(notExistingSentences);
      setSentencesLoading(false);
      return;
    } else {
      setNewLessonSentences([]);
    }
    // create new USER sentences after new sentences created
    if (newUserLessonSentences.length) {
      setUserNewLessonSentences(newUserLessonSentences);
      setSentencesLoading(false);
      return;
    } else {
      setUserNewLessonSentences([]);
    }
    setSentencesLoading(false);
  };

  const handleCheckSentencesAndWords = async () => {
    await findLessonWords();
    await findLessonSentences();
  };

  const handleCreateLesson = async () => {
    if (!spUser) throw new Error("No user.");

    setIsLoading(true);

    console.log("🚀 ~ userLessonSentencesForLessonCreation:", userLessonSentencesForLessonCreation);
    // get sentences ids for the user lesson
    const { data: existingSentences } = await supabaseClient.from("sentences").select("en, id").in("en", userLessonSentencesForLessonCreation).returns<Sentence[]>();
    console.log("🚀 ~ existingSentences:", existingSentences);

    if (!existingSentences) throw new Error("No sentences found");

    const userSentencesIds = existingSentences.map(({ id }) => id);
    console.log("🚀 ~ userSentencesIds:", userSentencesIds);

    await supabaseClient
      .from("users")
      .update({
        lessons: {
          ...spUser.lessons,
          [spUser.last_personal_lesson + 1]: {
            id: spUser.last_personal_lesson + 1,
            name: lessonName,
            ids: userSentencesIds.filter(Boolean),
            words: userLessonWordsForLessonCreation,
            uuid1: uuidv4(),
            uuid2: uuidv4(),
          },
        },
        last_personal_lesson: spUser.last_personal_lesson + 1,
      })
      .eq("uuid", userUuid);

    setIsLoading(false);

    setUserLessonWordsForLessonCreation([]);
    setUserLessonSentencesForLessonCreation([]);
  };

  console.log("en", enValue);
  console.log("meaning", uaValue);
  console.log("meaning", meaningValue);

  return (
    <VStack alignItems="flex-start">
      <Button
        onClick={() => {
          navigator.clipboard.writeText(`
Translate the following text to Ukrainian language. The response is a list of Ukrainian language strings without quotes at the beginning and at the end. Each translated sentence starts on the new line.
${enValue}`);
        }}
      >
        Copy
      </Button>

      <AdminCreateSentenceFields
        onUpdateValues={(en, ua, meaning) => {
          setEnValue(en);
          setUaValue(ua);
          setMeaningValue(meaning);
        }}
      />
      <LoadingButton loading={sentencesLoading} variant="contained" onClick={handleCheckSentencesAndWords}>
        Check sentences / words
      </LoadingButton>

      <VStack gap={5} alignItems="flex-start">
        <NewLessonWords words={newLessonWords} onUpdateLessonWords={findLessonWords} />
        <UserNewLessonWords words={userNewLessonWords} userUuid={userUuid} onNewLessonWordsInserted={findLessonWords} />
        <NewLessonSentences sentences={newLessonSentences} onInsertSentences={findLessonSentences} />
        <UserNewLessonSentences sentences={userNewLessonSentences} userUuid={userUuid} onInsertUserSentences={findLessonSentences} />
      </VStack>

      <TextField
        type="text"
        color="primary"
        value={lessonName}
        variant="outlined"
        placeholder="Lesson name"
        multiline
        fullWidth
        onChange={(event) => {
          setLessonName(event.target.value);
        }}
      />
      <LoadingButton loading={isLoading} onClick={handleCreateLesson}>
        Create lesson
      </LoadingButton>
    </VStack>
  );
};

export default CreatePersonalLesson;
