import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { RPCUser } from "../../common/fetchCurrentUser";
import supabaseClient from "../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import { Sentence, Word } from "../../types/supabase";
import MenuLayout from "../Layout/MenuLayout";
import VStack from "../Unknown/VStack";
import { allDifferenceWords } from "../Difference/Page";
import checkDuplication from "./checkDuplication";
import splitMultipleAndTrim from "../../common/splitMultipleAndTrim";
import checkSentencesExistence from "./checkSentencesExistence";
import useCreateAudio from "../../common/useCreateAudio";
import getSentenceWords from "../../common/getSentenceWords";
import filterSkipedWords from "../../common/filterSkipedWords";
import getSkipWords from "../../common/getSkipWords";
import NewLessonWords from "../CreatePersonalLesson/NewLessonWords";

const AdminCreateDifferenceLesson: React.FC = () => {
  const [users, setUsers] = useState<RPCUser[]>([]);
  const [currentCombination, setCurrentCombination] = useState<string>("");
  console.log("🚀 ~ currentCombination:", currentCombination);
  const resetPersonalLessons = useLessonsStore(
    (state) => state.resetPersonalLessons,
  );
  const authUser = useLessonsStore((state) => state.authUser);

  useEffect(() => {
    (async () => {
      if (users.length) return;
      const { data: spUsers } = await supabaseClient
        .from("users")
        .select("id, uuid, lessons")
        .returns<RPCUser[]>();

      if (spUsers) setUsers(spUsers);
    })();
  }, [users]);

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }} p={5}>
        <Grid container width="2000px">
          <Grid item xs={12}>
            {
              <Select
                value={currentCombination}
                variant="standard"
                fullWidth
                style={{
                  height: "16px",
                  fontSize: "12px",
                  minHeight: 0,
                }}
                onChange={({ target }) => {
                  resetPersonalLessons();
                  setCurrentCombination(target.value);
                }}
              >
                {allDifferenceWords.map((w, index) => (
                  <MenuItem key={index} value={w}>
                    {w}
                  </MenuItem>
                ))}
              </Select>
            }
          </Grid>
          {currentCombination && (
            <Grid item xs={12}>
              <AdminLessonCreation combination={currentCombination} />
            </Grid>
          )}
        </Grid>
      </Box>
    </MenuLayout>
  );
};

const AdminLessonCreation: React.FC<{
  combination: string;
}> = ({ combination }) => {
  const [enValue, setEnValue] = useState("");
  const [uaValue, setUaValue] = useState("");
  const [meaningValue, setMeaningValue] = useState("");
  const { createAudio } = useCreateAudio();

  const [isLoading, setIsLoading] = useState(false);
  const [lessonName, setLessonName] = useState("");
  const [newLessonWords, setNewLessonWords] = useState<string[]>([]);

  const handleCreateLesson = async () => {
    setIsLoading(true);

    const enSentences = splitMultipleAndTrim(enValue);
    const uaSentences = splitMultipleAndTrim(uaValue);
    const mSentences = splitMultipleAndTrim(meaningValue);

    if (
      enSentences.length !== uaSentences.length ||
      uaSentences.length !== mSentences.length
    ) {
      console.error("Columns are not equal!");
      return setIsLoading(false);
    }

    const uniqueEnSentences = Array.from(new Set(enSentences));

    const hasDuplication = checkDuplication({
      sentences1: enSentences,
      sentences2: uniqueEnSentences,
    });

    console.log("🚀 ~ hasDuplication:", hasDuplication);
    if (hasDuplication) return setIsLoading(false);

    const skipWords = await getSkipWords(
      getSentenceWords(enSentences.join(" ")),
    );
    const sentenceWords = filterSkipedWords(
      getSentenceWords(enSentences.join(" ")),
      skipWords,
    );

    const { data: existingWords } = await supabaseClient
      .from("words")
      .select()
      .in("name", sentenceWords)
      .returns<Word[]>();

    const rowExistingWords = existingWords!.map((w) => w.name);
    const newRowLessonWords = sentenceWords.filter(
      (w) => !rowExistingWords.includes(w),
    );
    console.log("🚀 ~ newRowLessonWords:", newRowLessonWords);

    if (newRowLessonWords.length) {
      setNewLessonWords(newRowLessonWords);
    } else {
      try {
        await checkSentencesExistence({
          enS: enSentences,
          uaS: uaSentences,
          mS: mSentences,
          combination,
          createAudio,
        });
        setEnValue("");
        setUaValue("");
        setMeaningValue("");
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    }

    setIsLoading(false);
  };

  return (
    <VStack alignItems="flex-start" gap={5} py={5}>
      <Box
        width="100%"
        sx={{
          overflowX: "scroll",
        }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-start"
          sx={{
            width: "auto",
            overflowX: "scroll",
          }}
        >
          <Box sx={{ width: 600 }}>
            <TextField
              type="text"
              color="primary"
              value={enValue}
              variant="outlined"
              multiline
              sx={{ width: 600 }}
              onChange={(event) => {
                setEnValue(event.target.value);
              }}
            />
          </Box>
          <Box sx={{ width: 600 }}>
            <TextField
              type="text"
              color="primary"
              value={uaValue}
              variant="outlined"
              multiline
              sx={{ width: 600 }}
              onChange={(event) => {
                setUaValue(event.target.value);
              }}
            />
          </Box>
          <Box sx={{ width: 600 }}>
            <TextField
              type="text"
              color="primary"
              value={meaningValue}
              variant="outlined"
              multiline
              sx={{ width: 600 }}
              onChange={(event) => {
                setMeaningValue(event.target.value);
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* <TextField
        type="text"
        color="primary"
        value={lessonName}
        variant="outlined"
        placeholder="Lesson name"
        multiline
        fullWidth
        onChange={(event) => {
          setLessonName(event.target.value);
        }}
      /> */}
      <LoadingButton
        variant="contained"
        loading={isLoading}
        onClick={handleCreateLesson}
      >
        Create lesson
      </LoadingButton>

      <NewLessonWords
        words={newLessonWords}
        onUpdateLessonWords={handleCreateLesson}
      />
    </VStack>
  );
};

export default AdminCreateDifferenceLesson;
