import { Box, Typography } from "@mui/material";
import { PropsWithChildren, memo } from "react";
import WordAudio from "../../Audio/WordAudio";
import UkrainianWord, { HighlightWord } from "../UkrainianWord";
import AudioAndBelongsToUserStar from "../../AudioAndBelongsToUserStar";

interface EnglishAudioSentenceProps extends PropsWithChildren {
  audioUrl?: string;
  sentence: string;
  isVisible: boolean;
  belongsToUser?: boolean;
  transcription?: string;
}

const EnglishAudioSentence = ({
  audioUrl,
  sentence,
  isVisible,
  belongsToUser,
  transcription,
  children,
}: EnglishAudioSentenceProps) => {
  if (!isVisible) return null;

  console.log("🚀 ~ transcription:", transcription);
  return (
    <Box position="relative">
      <Typography
        variant="h6"
        fontSize={30}
        // fontStyle="italic"
        color="primary"
        fontWeight={400}
      >
        {!children && sentence}
        {children && children}
      </Typography>
      <Box mb={2}>
        {transcription && (
          <Typography variant="h6" fontSize={25} fontWeight={400}>
            {/* {transcription} */}
            {transcription.split(",").map((t, index) => {
              return (
                <HighlightWord
                  key={t}
                  text={t.trim()}
                  row={false}
                  isLast={transcription.split(",").length - 1 === index}
                />
              );
            })}
          </Typography>
        )}
      </Box>
      {audioUrl && typeof belongsToUser !== "boolean" && (
        <WordAudio url={audioUrl} autoPlay={true} boxHide={false} />
      )}
      {audioUrl && typeof belongsToUser === "boolean" && (
        <Box pt={4}>
          <AudioAndBelongsToUserStar
            text={sentence}
            audio={audioUrl}
            belongsToUser={!!belongsToUser}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(EnglishAudioSentence);
