import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  Typography,
} from "@mui/material";
import React, { memo, useMemo } from "react";
import ReactPlayer from "react-player";
import { Outlet, useParams } from "react-router-dom";
import MenuLayout from "../../Layout/MenuLayout";
import { SideBarNavigationButton } from "../../Unknown/Navigation";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";
import { esistingLessons } from "../DifferenceSubject";

const drawerWidth = 300;

const A1A2Words = [
  "it, this",
  "do, work, job",
  "receive, get",
  "big, large, great",
  "bad, badly",
  "sad, sadly",
  "afterwards, after, later ",
  "almost, nearby",
  "too, also, as well",
  "anyone, anybody, someone, somebody",
  "around, round, about",
  "a, an",
  "do, make",
  "bank, bench, sear",
  "bathe, bath",
  "bill, check, no",
  "boat, ship",
  "bring, take, fetch",
  "be able to, can, could",
  "chair, chairperson, chairman, chairwoman",
  "cheap, cheaply",
  "heavy, strong, hard",
  "cloakroom, checkroom",
  "comic, comical, funny",
  "cooker, cook",
  "cot, crib, camp bed",
  "cup, mug, glass",
  "cupboard, wardrobe, closet",
  "desert, dessert",
  "dinner, lunch",
  "everyday, every day",
  "everyone, everybody, every one",
  "exciting, excited",
  "few, a few",
  "first name, forename, given name, christian name",
  "funny, fun",
  "petrol, gas",
  "good, well",
  "half, half of",
  "have, take",
  "high, tall",
  "vacation, holiday",
  "warm, hot",
  "housework, homework",
  "sick, ill",
  "information, news",
  "instead, instead of",
  "interested, interesting",
  "its, it's, their",
  "let's, let us",
  "library, bookstore, bookshop",
  "elevator, lift",
  "little, a little, small",
  "lucky, happy",
  "magazine, shop",
  "might, may",
  "near, close",
  "possible, possibly",
  "post, mail",
  "price, prize, cost",
  "programme, program",
  "receipt, recipe, check",
  "different, difference",
  "remember, remind",
  "lettuce, salad",
  "university, uni, school",
  "watch, see, look at",
  "send, sent",
  "else, more",
  "shall, will",
  "store, shop, save",
  "so, very, too",
  "noise, sound",
  "speak, say, tell, talk",
  "start, begin",
  "stationary, stationery",
  "unusual, strange",
  "pathway, path, lane, road",
  "underground, subway, metro",
  "sweets, candy",
  "that, these, those",
  "usual, usually",
  "whether, weather",
  "who, whom",
  "you're, your",
  "other, another, different",
  "food, foods",
  "fruit, fruits",
  "lite, light",
  "baking, cooking",
];

const B1B2Words = [
  "middle, medium",
  "address, solve",
  "intend, plan, motive",
  "haste, rush, hurry",
  "as, like, the way",
  "called, named",
  "spend, spent, pass",
  "achieve, gain, get, earn, win",
  "acquire, obtain",
  "attain, obtain",
  "yourselves, yourself, themselves, themself, myself, himself, herself",
  "sometimes, sometime, some time, occasionally, from time to time",
  "borrow, lend",
  "pretty, enough, quite, ample",
  "accommodate, adapt",
  "essentially, in fact, basically, actually",
  "major, main, serious, expert",
  "achieve, rich",
  "sensible, smart",
  "extremely, super",
  "impairment, disability",
  "operate, drive, work",
  "leverage, use",
  "preserve, save",
  "gift, present",
  "keep, save",
  "seller, vendor",
  "defend, protect",
  "objective, goal, purpose, aim",
  "readily, easily",
  "fee, payment",
  "misconception, misperception, misunderstanding",
  "adapt, tailor",
  "wiz, major",
  "expert, master",
  "preference, priority",
  "fairly, deservedly",
  "trade, field",
  `large, huge, tremendous, enormous, sizable, sizeable, vast, jumbo,
   astronomical, colossal, elephantine, gargantuan, giant, gigantic, ginormous,
   goodly, hefty, immense, mahoosive, mammoth, massive, outsize, oversize, prodigious,
   stupendous, thumping, tidy, voluminous, walloping, whopping`,
  "extraordinary, great",
  "able, capable",
  "above, over",
  "accept, except",
  "accuse, charge",
  "advice, advise",
  "affect, effect, result",
  "frightened, afraid",
  "allow, permit, let",
  "lonely, alone",
  "loudly, aloud",
  "alternative, alternate",
  "although, though",
  "anniversary, birthday",
  "announcement, advertisement",
  "latter, former",
  "appear, emerge",
  "arrive, reach",
  "embarrassed, ashamed",
  "assignment, homework",
  "assure, ensure, insure",
  "athletics, athletic",
  "aural, oral ",
  "bare, bare",
  "bass, base",
  "beach, coast, shore",
  "bear, bare ",
  "beside, besides",
  "blame, fault",
  "bonnet, hood",
  "boot, trunk",
  "brand, make",
  "breathe, breath",
  "raise, bring up, educate",
  "britain, british, briton",
  "burglarize, burgle",
  "coach, bus",
  "careless, careful",
  "carry, take",
  "certain, sure",
  "chef, chief",
  "pharmacist, chemist",
  "drugstore, pharmacy, chemist",
  "childlike, childish",
  "class, form, grade, year",
  "close, shut",
  "clothes, clothing, cloth",
  "comment, commentary, mention, remark",
  "comprehension, understanding",
  "concerto, concert",
  "confidant, confident",
  "persuade, convince",
  "customer, client",
  "postpone, delay",
  "destroy, spoil, ruin",
  "detail, details",
  "difference, distinction",
  "directly, direct",
  "disc, disk",
  "discussion, argument",
  "dislike, not like",
  "each other, one another",
  "efficient, effective",
  "electronic, electric, electrical",
  "migration, immigration, emigration",
  "especially, specially",
  "eventually, finally",
  "exam, examination",
  "experiment, experience",
  "explode, blow up",
  "fair, fairly",
  "investigate, explore, research, study",
  "feminine, female",
  "fine, finely",
  "first, firstly",
  "suit, fit",
  "apartment, flat",
  "freely, free",
  "frighten, frightened",
  "generally, mainly",
  "glad, happy, cheerful, pleased, excited",
  "enter, get in, get into, get on, go into",
  "get out, go out, get off",
  "ground floor, first floor",
  "custom, habit",
  "hardly, hard",
  "hire, rent, let",
  "disease, illness",
  "show, indicate",
  "indoors, indoor",
  "in spite of, despite, regardless",
  "journey, trip, voyage, excursion",
  "lastly, last",
  "lately, late",
  "lay, lie",
  "loose, lose",
  "truck, lorry",
  "luggage, baggage",
  "masculine, male",
  "arrange, manage",
  "manufacture, factory",
  "marmalade, jelly, jam",
  "marriage, wedding",
  "married, marry",
  "mathematics, math, maths",
  "intention, opinion, meaning",
  "annoyed, irritated, anxious, nervous",
  "northern, north",
  "obligation, duty",
  "occasion, chance, opportunity",
  "offer, give, invite",
  "outdoors, outdoor",
  "couple, pair",
  "parcel, package, packet",
  "parking, car park",
  "people, person, human being",
  "percentage, percent",
  "point of view, view, opinion",
  "politics, policy, political",
  "position, post, job",
  "possibility, opportunity",
  "produce, product",
  "professor, teacher",
  "quiet, quite",
  "regret, be sorry",
  "relation, relative, relationship",
  "remain, stay",
  "remove, move",
  "call, ring",
  "rise, raise",
  "steal, rob",
  "role, roll",
  "secure, safe",
  "wages, salary",
  "salute, greet",
  "similar, same",
  "scenery, scene, sight, view, landscape",
  "sensible, sensitive",
  "shadow, shade",
  "sheep, lamb",
  "briefly, shortly, short",
  "ought to, should",
  "skilled, skillful, skilful",
  "asleep, sleep",
  "sociable, cocial",
  "kind of, sort of, somewhat, rather, a bit, to some extent, in a way",
  "storey, floor",
  "terrible, terribly",
  "thief, robber, burglar",
  "headline, title",
  "transportation, transport",
  "come true, true, become true",
  "attempt, try",
  "beneath, under, below",
  "realize, understand",
  "till, until",
  "backwards, downwards, eastwards, forwards, homewards, inwards, northwards, onwards, outwards, southwards, upwards, westwards",
  "wide, broad",
  "widower, widow",
  "defeat, beat, win",
  "lady, women ",
  "person, human, human being",
  "span, cover, range",
  "bring, provide",
  "reside, live, locate",
  "conceptualize, visualization",
  "exhausted, depleted",
  "consist, contain",
  "mean, stand for",
  "period, term, time",
  "flat, home",
  "made, crafted, fashioned",
];

const C1C2Words = [
  "rise, arise, appear, emerge",
  "teamwork, collaboration",
  "misuse, abuse",
  "else, yet",
  "decision, resolution",
  "concern, problem, issue",
  "solve, resolve",
  "honesty, integrity",
  "chase, pursue",
  "variety, diversity",
  "scarce, scarcely",
  "evolve, develop",
  "hinder, prevent",
  "standpoint, viewpoint",
  "persistent, diligent, persevering",
  "retain, save",
  "invader, attacker",
  "pursue, follow",
  "likelihood, probability, chance",
  "refine, improve",
  "evaluate, assess",
  "ability, capability, capacity",
  "alternatively, alternately",
  "arise, rise",
  "frontier, boudary, border",
  "borrow, lend",
  "careless, carefree",
  "carriage, car",
  "classic, classical",
  "collaborate, cooperate",
  "complement, compliment",
  "comprehensive, comprehensible",
  "conscious, consciousness, conscience, conscientious",
  "constant, continual, continuous",
  "council, counsel",
  "critic, critical",
  "cry, weep",
  "curb, kerb",
  "dispose of, get rid of",
  "easily, easy",
  "employ, use",
  "exhaustive, exhausted, exhausting",
  "ground, flat",
  "heap, stack, pile",
  "industrial, industrious",
  "strength, power",
  "principal, principle",
  "relieve, relief",
  "statistics, statistical",
  "naturally, surely, definitely, certainly",
  "casualty, victim",
  "resilient, stable",
  "portray, represent",
];

export const allDifferenceWords = [
  ...A1A2Words,
  ...B1B2Words,
  ...C1C2Words,
].map((w) => w.split(", ").join("-"));

interface CustomAccordionProps {
  label: string;
  words: string[];
  color1: string;
  color2: string;
  subject?: string;
  startIndex: number;
  isDefaultExpanded: boolean;
  onDrawerClose: () => void;
}

const CustomAccordion: React.FC<CustomAccordionProps> = memo(
  ({
    label,
    words,
    color1,
    color2,
    subject,
    startIndex,
    isDefaultExpanded,
    onDrawerClose,
  }) => {
    return (
      <Accordion
        disableGutters
        defaultExpanded={isDefaultExpanded}
        TransitionProps={{
          timeout: 300,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ backgroundColor: color1, height: 50 }}
        >
          <Typography variant="h3">{label}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ overflow: "hidden", position: "relative" }}>
          <Box
            sx={{
              top: 0,
              left: 0,
              width: 7,
              height: "100%",
              position: "absolute",
              backgroundColor: color2,
            }}
          />
          <List>
            {words.map((words, index) => {
              return (
                <Box key={words}>
                  <SideBarNavigationButton
                    to={`${encodeURIComponent(words)}`}
                    isVisible={esistingLessons.includes(words)}
                    isActive={
                      !!subject &&
                      encodeURIComponent(subject) ===
                        encodeURIComponent(words.split(", ").join("-"))
                    }
                    label={`${startIndex + index + 1}. ${words
                      .split("-")
                      .join(", ")}`}
                    onClick={onDrawerClose}
                  />
                </Box>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  },
);

export const DifferenceMenu: React.FC<{ handleDrawerClose: () => void }> = memo(
  ({ handleDrawerClose }) => {
    const { subject } = useParams();

    return (
      <>
        <CustomAccordion
          label="A1-A2"
          words={A1A2Words.map((w) => w.split(", ").join("-"))}
          color1="#c9ffb8"
          color2="#c9ffb8"
          subject={subject}
          startIndex={0}
          isDefaultExpanded={A1A2Words.map((w) =>
            w.split(", ").join("-"),
          ).includes(subject || "")}
          onDrawerClose={handleDrawerClose}
        />
        <CustomAccordion
          label="B1-B2"
          words={B1B2Words.map((w) => w.split(", ").join("-"))}
          color1="#ffe6ac"
          color2="#ffe6ac"
          subject={subject}
          startIndex={A1A2Words.length}
          onDrawerClose={handleDrawerClose}
          isDefaultExpanded={B1B2Words.map((w) =>
            w.split(", ").join("-"),
          ).includes(subject || "")}
        />
        <CustomAccordion
          label="C1-C2"
          words={C1C2Words.map((w) => w.split(", ").join("-"))}
          color1="#b5afff"
          color2="#b5afff"
          subject={subject}
          startIndex={A1A2Words.length + B1B2Words.length}
          onDrawerClose={handleDrawerClose}
          isDefaultExpanded={C1C2Words.map((w) =>
            w.split(", ").join("-"),
          ).includes(subject || "")}
        />
      </>
    );
  },
);

const Difference: React.FC = () => {
  const { subject } = useParams();

  const outlet = useMemo(() => {
    if (!subject) return null;

    return (
      <WhiteBoxWrap>
        <Outlet />
      </WhiteBoxWrap>
    );
  }, [subject]);

  return (
    <MenuLayout withDifferenceMenu>
      {!subject && (
        <Box textAlign="center">
          <WhiteBoxWrap>
            <Typography variant="h2" component="h1" gutterBottom>
              Вітаю у курсі: <br /> "Різниця між словами"
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              maxWidth={500}
              mx="auto"
            >
              більше ніж 300 комбінацій слів які часто плутаються в англійській
              мові
            </Typography>
            <Typography component="h3" fontSize={20}>
              <Typography variant="h3" component="span" color="primary">
                тут зібрані слова які:
              </Typography>
              <br />
              мають подібне використання
              <br />
              однаковий переклад
              <br />
              однаково вимовляються, але мають різне значення
              <br />
              слова синоніми, які мають тонку різницю в різних контекстах
            </Typography>
          </WhiteBoxWrap>
          <WhiteBoxWrap>
            <Typography
              variant="h5"
              fontWeight={400}
              gutterBottom
              maxWidth={400}
              mx="auto"
            >
              У цьому відео я розповідаю як проходити цей курс
            </Typography>
            <Box
              mx="auto"
              paddingBottom={{
                xs: "52.2%",
                sm: "56%",
                lg: "46%",
              }}
              width="100%"
              maxWidth={700}
              position="relative"
            >
              <ReactPlayer
                controls
                width="100%"
                height="100%"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                url="https://youtu.be/RKCI3JJiI-s"
              />
            </Box>
          </WhiteBoxWrap>
        </Box>
      )}

      {outlet}
    </MenuLayout>
  );
};

export default Difference;
