import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Menu, Item, Separator, Submenu, useContextMenu as useContextMenuContexify } from "react-contexify";
import Loader from "../components/Unknown/Loader";
import LoadingButton from "@mui/lab/LoadingButton";
import supabaseClient from "../common/supabaseClient";
import SearchForFoodItems from "../components/Food/SearchForFoodItems";
import { FoodItem, FoodTrackingRow } from "../types/supabase";

const useContextMenuFoodTrackingRecodItem = ({ onUpdate }: { onUpdate: () => Promise<void> }) => {
  const [MENU_ID] = useState(() => (Math.random() * Math.random()).toString());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [typeOfUpdate, setTypeOfUpdate] = useState<"update-weight" | "update-product">();
  const [updatedValue, setUpdatedValue] = useState<any>();
  const [activeItem, setActiveItem] = useState<any>();
  const inputRef = useRef<any>(null);
  const inputRef2 = useRef<any>(null);
  const menuRef = useRef(null);
  const modalRef = useRef(null);

  // useEffect(() => {
  //   if (activeItem) {
  //    setUpdatedWeight
  //  }
  // }, [activeItem])

  useEffect(() => {
    setTimeout(() => {
      if (isModalOpen && inputRef.current) {
        inputRef.current.focus();
      }
      if (isModalOpen && inputRef2.current) {
        inputRef2.current.focus();
      }
    }, 200);
  }, [isModalOpen, inputRef, inputRef2]);

  const { show } = useContextMenuContexify({
    id: MENU_ID,
  });

  function handleContextMenu(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: any) {
    setActiveItem(data);

    show({
      event,
      props: {
        data,
      },
    });
  }

  const handleChangeWeight = (event: any) => {
    const { data } = event.props;
    setUpdatedValue({
      ...data,
      initialWeight: data.weight,
    });
    setActiveItem(data);
    setTypeOfUpdate("update-weight");
    setIsModalOpen(true);
  };

  const handleChangeProduct = async (event: any) => {
    const { data } = event.props;

    const { data: foodItemData } = await supabaseClient.from("food_items").select().eq("name", data.item).single().throwOnError();

    setUpdatedValue(data);
    setActiveItem({
      ...data,
      ...foodItemData,
    });
    setTypeOfUpdate("update-product");
    setIsModalOpen(true);
  };

  const handleDelete = async (event: any) => {
    const { data } = event.props;
    const { data: otherPersonItem } = await supabaseClient
      .from("food_tracking")
      .select()
      .eq("key", data.key)
      .eq("date", data.date)
      .eq("user", data.user === "Valmiki" ? "Dayanvita" : "Valmiki")
      .maybeSingle();

    if (otherPersonItem) {
      await supabaseClient.from("food_tracking").delete().eq("id", otherPersonItem.id).throwOnError();
    }
    await supabaseClient.from("food_tracking").delete().eq("id", data.id).throwOnError();
    onUpdate();
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !(menuRef.current as any).contains(event.target) && modalRef.current && !(modalRef.current as any).contains(event.target)) {
        setActiveItem(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    if (!activeItem) {
      setIsModalOpen(false);
    }
  }, [activeItem]);

  const menu = useMemo(() => {
    return (
      <>
        <Box ref={menuRef as any}>
          <Menu id={MENU_ID}>
            <Item id="changeWeight" onClick={handleChangeWeight}>
              Change weight
            </Item>
            <Item id="changeProduct" onClick={handleChangeProduct}>
              Change product
            </Item>
            <Box component={Item} id="deleteWeight" sx={{ "& div": { color: "red" } }} onClick={handleDelete}>
              Delete
            </Box>
          </Menu>
        </Box>
        <Modal
          open={isModalOpen}
          ref={modalRef}
          onClose={() => {
            setIsModalOpen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "30px",
              left: "50%",
              maxHeight: "calc(100% - 60px)",
              overflow: "auto",
              transform: "translate(-50%, 0%)",
              width: 320,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 7,
            }}
          >
            {/* {isLoading && <Loader />} */}
            <Grid container pt={2}>
              {typeOfUpdate === "update-weight" && (
                <Grid item xs={12}>
                  <Box mb={4}>
                    <Typography variant="h3" gutterBottom>
                      Change weight
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    // multiline={typeOfUpdate === "add-description" || typeOfUpdate === "add-row-examples" || typeOfUpdate === "add-question-answer"}
                    inputRef={inputRef}
                    value={updatedValue.weight}
                    onChange={(e) => {
                      setUpdatedValue((prev: any) => {
                        return {
                          ...prev,
                          weight: +e.target.value,
                          // initialWeight: prev.weight,
                        };
                      });
                    }}
                  />
                </Grid>
              )}
              {typeOfUpdate === "update-product" && (
                <Grid item xs={12}>
                  <Box mb={4}>
                    <Typography variant="h3" gutterBottom>
                      Change product
                    </Typography>
                  </Box>
                  {/* inputRef={inputRef} */}
                  <SearchForFoodItems
                    type="en"
                    initial={activeItem?.en_name}
                    onItemChange={async (item: FoodItem) => {
                      setUpdatedValue((prev: any) => {
                        return {
                          ...prev,
                          item: item.name,
                        };
                      });
                      setActiveItem(item);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  onClick={async () => {
                    setIsLoading(true);

                    try {
                      if (!activeItem) throw new Error("No active item");
                      if (typeOfUpdate === "update-weight") {
                        if (updatedValue.key) {
                          const { data: otherPersonItem } = await supabaseClient
                            .from("food_tracking")
                            .select()
                            .eq("key", updatedValue.key)
                            .eq("date", updatedValue.date)
                            .eq("user", updatedValue.user === "Valmiki" ? "Dayanvita" : "Valmiki")
                            .maybeSingle();

                          if (otherPersonItem) {
                            let newWeightForOtherPerson = otherPersonItem.weight + updatedValue.initialWeight - updatedValue.weight;

                            await supabaseClient
                              .from("food_tracking")
                              .update({
                                weight: newWeightForOtherPerson,
                              })
                              .eq("id", otherPersonItem.id)
                              .throwOnError();
                          }

                          await supabaseClient
                            .from("food_tracking")
                            .update({
                              weight: updatedValue.weight,
                            })
                            .eq("id", updatedValue.id)
                            .throwOnError();
                        } else {
                          await supabaseClient
                            .from("food_tracking")
                            .update({
                              weight: updatedValue.weight,
                            })
                            .eq("id", updatedValue.id)
                            .throwOnError();
                        }
                      } else if (typeOfUpdate === "update-product") {
                        // console.log("🚀 ~ typeOfUpdate:", typeOfUpdate);
                        // console.log("🚀 ~ updatedValue:", updatedValue);
                        // const { data: updatedItem } = await supabaseClient.from("food_items").select().eq("name", updatedValue.name).single().throwOnError();
                        // console.log("🚀 ~ updatedItem:", updatedItem);

                        // return null;
                        if (updatedValue.key) {
                          // console.log("🚀 ~ updatedValue:", updatedValue);
                          const { data: otherPersonItem } = await supabaseClient
                            .from("food_tracking")
                            .select()
                            .eq("key", updatedValue.key)
                            .eq("date", updatedValue.date)
                            .eq("user", updatedValue.user === "Valmiki" ? "Dayanvita" : "Valmiki")
                            .maybeSingle();
                          // console.log("🚀 ~ otherPersonItem:", otherPersonItem);

                          if (otherPersonItem) {
                            // const deleteResult = await supabaseClient.from("food_tracking").delete().eq("id", otherPersonItem.id);
                            // console.log("🚀 ~ deleteResult:", deleteResult);
                            // const insertResult = await supabaseClient.from("food_tracking").insert({ ...otherPersonItem, item: updatedValue.name });
                            // console.log("🚀 ~ insertResult:", insertResult);

                            const result = await supabaseClient
                              .from("food_tracking")
                              .update({
                                item: updatedValue.item,
                              })
                              .eq("id", otherPersonItem.id)
                              .throwOnError();
                            // console.log("🚀 ~ result:", result);
                          }

                          // const { data: currentItem } = await supabaseClient.from("food_tracking").select().eq("id", updatedValue.id).single();

                          // const deleteResult = await supabaseClient.from("food_tracking").delete().eq("id", currentItem.id);
                          // console.log("🚀 ~ deleteResult:", deleteResult);
                          // const insertResult = await supabaseClient.from("food_tracking").insert({ ...currentItem, item: updatedValue.name });
                          // console.log("🚀 ~ insertResult:", insertResult);

                          await supabaseClient
                            .from("food_tracking")
                            .update({
                              item: updatedValue.item,
                            })
                            .eq("id", updatedValue.id)
                            .throwOnError();
                          // console.log("🚀 ~ result2:", result2);
                        } else {
                          await supabaseClient
                            .from("food_tracking")
                            .update({
                              item: updatedValue.item,
                            })
                            .eq("id", updatedValue.id)
                            .throwOnError();
                        }
                      }
                    } catch (err) {
                      console.log("🚀 ~ err:", err);
                    }

                    setIsLoading(false);
                    setIsModalOpen(false);
                    onUpdate();
                  }}
                >
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
    );
  }, [MENU_ID, isModalOpen, updatedValue]);

  const handleFoodItemRecordContextMenu = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: any) => {
    handleContextMenu(event, data);
  };

  return {
    menu,
    activeItem,
    handleFoodItemRecordContextMenu,
  };
};

export default useContextMenuFoodTrackingRecodItem;
