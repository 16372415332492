import { Box, Container, Typography } from "@mui/material";
import HStack from "../../HStack";
import { PropsWithChildren } from "react";
import { useLessonsStore } from "../../../../store/lessonsStore";
import TensesTable from "../Feed/TensesTable";
import ArticleTable from "../ArticleTable";

const LessonHighlight: React.FC<PropsWithChildren & { lesson: number }> = ({
  children,
  lesson,
}) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  if (lessonNumber < lesson) return null;

  if (lesson !== lessonNumber) return <>{children}</>;

  return (
    <Box sx={{ position: "relative", pl: 3 }}>
      <Box
        sx={{
          position: "absolute",
          width: 5,
          backgroundColor: "green",
          height: "100%",
          left: 0,
          top: 0,
        }}
      />
      {children}
    </Box>
  );
};

const Articles: React.FC = () => {
  return (
    <Container>
      <Box>
        <Typography variant="h3">Артиклі</Typography>
        <Typography>
          Артикль – це особлива частка, що вживається з іменниками, вказує на їх визначеність або
          невизначеність та допомагає відрізняти їх від дієслів, прикметників та інших частин мови.
        </Typography>
        <Box gap={1}>
          <Box>
            <Typography fontWeight={700}>to study</Typography>
            <Typography>навчатися</Typography>
          </Box>
          <Box>
            <Typography fontWeight={700}>a study</Typography>
            <Typography>
              процес навчання, дослідження, кабінет{" "}
              <Typography variant="caption">(у якому навчаються або працюють)</Typography>
            </Typography>
          </Box>
        </Box>
        <Box py={7}>
          <ArticleTable />
        </Box>
        {/* <Box py={3}>
          <Typography gutterBottom variant="h3">
            Артиклі і прикметники в реченні
          </Typography>
          <Typography gutterBottom>
            Всі прикметники, що відповідають на питання «який?», «яка?», «яке?», «які?» будуть
            стояти між артиклем та іменником, до якого відносяться. Кількість таких прикметників
            необмежена.
          </Typography>
          <Typography gutterBottom variant="h4">
            He is a good boy.
          </Typography>
          <Typography gutterBottom variant="h4">
            He is a very good boy.
          </Typography>
        </Box> */}
        <Box py={3}>
          {/* <Typography gutterBottom variant="h3">
            Неозначений артикль a / an
          </Typography> */}
          {/* <Box mb={3}>
            <Typography gutterBottom>
              Неозначений артикль <strong>a</strong> або <strong>an</strong> використовується з
              обчислювальними іменниками у формі однини. <strong>A / an</strong> – це частка, що
              утворилася від числівника <strong>one</strong> (один, єдиний) і означає, що цей
              предмет – один з множини подібних, такий же, як і інші предмети.
            </Typography>
            <Typography gutterBottom variant="h4">
              He is a boy.
            </Typography>
            <Typography gutterBottom variant="h4">
              She is a girl.
            </Typography>
          </Box> */}
          {/* <LessonHighlight lesson={5}>
            <Box mb={3}>
              <Typography gutterBottom>
                Артикль <strong>a / an</strong> використовується, коли певний предмет згадується
                вперше і не виокремлюється з ряду інших подібних предметів.
              </Typography>
              <Typography gutterBottom variant="h4">
                I want to buy a new phone.
              </Typography>
            </Box>
          </LessonHighlight> */}
          <LessonHighlight lesson={11115}>
            <Box mb={3}>
              <Typography gutterBottom>
                Вибір артикля <strong>a</strong> або <strong>an</strong> залежить від звуку, яким
                починається наступне слово після артикля. <strong>A</strong> використовується перед
                приголосними звуками. <strong>An</strong> ставиться перед голосними звуками. Слід
                зазначити, що неважливо, з якої букви пишеться слово, важливо з якого звуку воно
                вимовляється.
              </Typography>
              <Typography gutterBottom variant="h4">
                a message
              </Typography>
              <Typography gutterBottom variant="h4">
                a phone
              </Typography>
              <Typography gutterBottom variant="h4">
                an hour
              </Typography>
              <Typography gutterBottom variant="h4">
                an orange
              </Typography>
            </Box>
          </LessonHighlight>
          <LessonHighlight lesson={11115}>
            <Box mb={3}>
              <Typography gutterBottom variant="h3">
                Означений артикль the
              </Typography>
              <Typography gutterBottom>
                Означений артикль <strong>the</strong> утворився від вказівних займенників{" "}
                <strong>this (цей, ця, це)</strong>, <strong>these (ці)</strong>,{" "}
                <strong>that (той, та, те)</strong>, <strong>those (ті)</strong>. Він виокремлює
                предмет з загальної множини подібних предметів і вказує на нього як на «той самий»
                предмет.
              </Typography>
              <Typography gutterBottom>
                Артикль <strong>the</strong> можна використовувати з іменниками в множині та
                необчислювальними іменниками, чим він і відрізняється від неозначеного артикля{" "}
                <strong>a / an</strong>, що використовується тільки з обчислювальними іменниками в
                однині.
              </Typography>
              <Typography gutterBottom variant="h4">
                The flowers are beautiful.
              </Typography>
            </Box>
          </LessonHighlight>
        </Box>
      </Box>
    </Container>
  );
};

export default Articles;
