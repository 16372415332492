import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useLessonsStore } from "../../../store/lessonsStore";
import isAdmin from "../../../common/isAdmin";
import Loader from "../Loader";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AdminModalChange: React.FC<PropsWithChildren & { typeOfUpdate: string; currentValue: string; open: boolean; onClose: () => void; onUpdate: (value: string) => Promise<void> }> = ({ children, typeOfUpdate, currentValue, onUpdate, open, onClose }) => {
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const [updatedValue, setUpdatedValue] = useState(currentValue);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setUpdatedValue(currentValue);
  }, [currentValue]);
  // const handleChange = () => {
  //   console.log("click");
  //   setOpen(true);
  // };

  // const handleUpdate = async () => {
  //   setIsLoading(true);

  //   await onUpdate(updatedValue);

  //   setIsLoading(false);
  //   setOpen(false);
  // };

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, [open, inputRef]);

  if (!isAdmin(authUser?.id)) return <>{children}</>;

  return (
    <>
      <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          {isLoading && <Loader />}
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline={typeOfUpdate === "add-description" || typeOfUpdate === "add-row-examples" || typeOfUpdate === "add-question-answer"}
                inputRef={inputRef}
                value={updatedValue}
                onChange={(e) => {
                  setUpdatedValue(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => onUpdate(updatedValue)}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* <Box sx={{ cursor: "pointer" }} onClick={handleChange}>
        {children}
      </Box> */}
    </>
  );
};

export default AdminModalChange;
