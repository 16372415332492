import { Typography } from "@mui/material";

const AccentWord: React.FC<{ word: string; weight?: number }> = ({ word, weight }) => {
  return (
    <Typography
      component="span"
      variant="h3"
      fontSize={18}
      color="primary"
      fontWeight={weight || 700}
    >
      {word}
    </Typography>
  );
};

export default AccentWord;
