import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { adminUserId } from "../../common/constants";
import { RPCUser, UserLesson } from "../../common/fetchCurrentUser";
import supabaseClient from "../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import MenuLayout from "../Layout/MenuLayout";
import LearnWordSection from "../Unknown/LearnWordsSection";
import Loader from "../Unknown/Loader";
import SentenceListeningSection from "../Unknown/SentenceListeningSection";
import { SentenceTranslationSection } from "../Unknown/SentenceTranslationSection";
import VStack from "../Unknown/VStack";
import WhiteBoxWrap from "../Unknown/WhiteBoxWrap";
import AdminUsers from "../AdminUsers";

const PersonalLessons: React.FC = () => {
  const [lessons, setLessons] = useState<UserLesson[] | null>(null);
  const [users, setUsers] = useState<RPCUser[] | null>(null);
  const [changedUserUuid, setChangedUserUuid] = useState<string | null>(null);
  const authUser = useLessonsStore((state) => state.authUser);

  useMountedWaitAsyncEffect(async () => {
    if (authUser && users) return;

    const { data: spUsers } = await supabaseClient.from("users").select();

    if (spUsers) {
      setUsers(spUsers);
    } else {
      setUsers([]);
    }
  }, [users, authUser]);

  useEffect(() => {
    (async () => {
      if (!authUser) return;
      if (!authUser) return;

      if (authUser.id !== adminUserId) {
        const reversedLessons = Object.values(authUser.lessons).reverse() as UserLesson[];
        setLessons(reversedLessons);
        return;
      }

      let user: RPCUser | null = null;
      // for admin user;
      if (changedUserUuid) {
        ({ data: user } = await supabaseClient.from("users").select().eq("id", changedUserUuid).single());
      }

      if (changedUserUuid && !user) throw new Error("User not found");

      const { lessons } = user || authUser;

      const reversedLessons = Object.values(lessons).reverse() as UserLesson[];
      setLessons(reversedLessons);
    })();
  }, [authUser, changedUserUuid]);

  if (!lessons)
    return (
      <MenuLayout>
        <WhiteBoxWrap>
          <Loader />
        </WhiteBoxWrap>
      </MenuLayout>
    );

  if (!Boolean(lessons.length)) {
    return (
      <MenuLayout>
        <Box sx={{ backgroundColor: "white" }} pt={5} pb={10} textAlign="center">
          {users && authUser?.id === adminUserId && (
            <Box mb={5}>
              <AdminUsers
                users={users}
                onChange={(user) => {
                  setChangedUserUuid(user.uuid);
                }}
              />
            </Box>
          )}
          Уроків не знайдено.
        </Box>
      </MenuLayout>
    );
  }

  return (
    <MenuLayout>
      <WhiteBoxWrap>
        {users && authUser?.id === adminUserId && (
          <Box mb={5}>
            <AdminUsers
              users={users}
              onChange={(user) => {
                setChangedUserUuid(user.id);
              }}
            />
          </Box>
        )}
        <VStack>
          {lessons.map((lesson) => (
            <LessonLearningItem key={lesson.id} lesson={lesson} />
          ))}
        </VStack>
      </WhiteBoxWrap>
    </MenuLayout>
  );
};

const LessonLearningItem: React.FC<{ lesson: UserLesson }> = ({ lesson }) => {
  const [currentWords, setCurrentWords] = useState<string[]>([]);

  useEffect(() => {
    setCurrentWords(lesson.words || []);
  }, [lesson.words]);

  return (
    <Box key={lesson.ids.join(",")} width="100%">
      <Accordion sx={{ padding: 3 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">{lesson.name}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ overflow: "hidden", position: "relative" }}>
          <Box
            sx={{
              top: 0,
              left: 0,
              width: 7,
              height: "100%",
              position: "absolute",
            }}
          />
          <Grid container>
            <Grid item xs={12}>
              {Boolean(currentWords.length) && (
                <LearnWordSection
                  currentWords={currentWords}
                  lessonId={`personal_lesson_${lesson.id}`}
                  onUpdateWords={(newWords) => {
                    setCurrentWords(newWords);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <SentenceListeningSection sliderId={`slider_3b81d37a-30f3-4df1-a146-d6ea461b0edf_${lesson.id}`} lessonId={`personal_lesson_${lesson.id}`} lessonType="personal" partSentencesIds={lesson.ids} />
            </Grid>
            <Grid item xs={12}>
              <SentenceTranslationSection sliderId={`slider_028188e6-d01c-4a65-9441-e261ecd8a984_${lesson.id}`} lessonId={`personal_lesson_${lesson.id}`} lessonType="personal" partSentencesIds={lesson.ids} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PersonalLessons;
