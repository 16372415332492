import { Box, Button, Container, Grid, Link, TextField, Typography } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FormikHelpers, useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useFirebaseApp } from "reactfire";

import LoadingButton from "@mui/lab/LoadingButton";
import { EditText, EditTextarea } from "react-edit-text";
import getGCRandomVoice from "../../../common/getGCRandomVoice";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import {
  BanglaSentence,
  BanglaWord,
  Word,
  WordExample as WordExampleType,
} from "../../../types/supabase";
import WordExample from "../../Unknown/LearnWordsSection/WordExample";
import VStack from "../../Unknown/VStack";
import messages from "./messages";
import WordA1C2ExamplesCreation from "../WordA1C2ExamplesCreation";
import getImageName from "../../Food/utils/getImageName";
import WordAudio from "../../Audio/WordAudio";
import HStack from "../../Unknown/HStack";

const initialValues = {
  word: "",
  enText: "",
  uaText: "",
  jsonExamples: "",
};

interface JSONWordProps {
  item: {
    type?: string;
    transliteration?: string;
    scriptWord?: string;
    en?: string[];
    ua?: string[];
    script_definition?: string[];
    word: string;
    pronunciation?: string;
    description: string;
    translation: string;
    part_of_speech?: string[];
    transcription: string;
    languageParts?: string[];
    ipa_pronunciation?: string;
    examples: { en: string; ua: string }[];
    conjugation?: {
      I: string;
      "You (formal)": string;
      "You (informal)": string;
      "You (plural formal)": string;
      "You (plural informal)": string;
      We: string;
      They: string;
      "He/She": string;
      It: string;
    };
  };
  onRemoveItem: () => void;
}

const JSONWord: React.FC<JSONWordProps> = ({ item, onRemoveItem }) => {
  console.log("🚀 ~ item:", item);
  const [examples, setExamples] = useState(item.examples);
  const [languageParts, setLanguageParts] = useState<string[]>([]);

  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");

  const textToSpeechFunction = httpsCallable(
    functions,
    "recordEnglishTextToSpeechAndReturnStorageUrl",
  );

  const createAudio = async (word: string) => {
    try {
      const result = await textToSpeechFunction({
        en: word.replace("(verb) ", ""),
        voiceName: getGCRandomVoice(),
      });
      return result;
    } catch (err) {
      console.log("🚀 try/catch create autio error", err);
    }
  };
  const [transcription, setTranscription] = useState(item.transcription);
  const [translation, setTranslation] = useState(item.translation);
  const [description, setDescription] = useState(item.description);
  const [word, setWord] = useState(item.word);
  const [isLoading, setIsLoading] = useState(false);

  const [existingItem, setExistingItem] = useState<Word | null>(null);
  console.log("🚀 ~ existingItem:", existingItem);
  const [isCheckedExistence, setIsCheckedExistence] = useState(false);

  useMountedWaitAsyncEffect(async () => {
    if (!isCheckedExistence && !existingItem) {
      const { data: existingWord } = await supabaseClient
        .from("words")
        .select()
        .eq("name", word)
        .maybeSingle();

      setIsCheckedExistence(true);
      if (existingWord) setExistingItem(existingWord);
    }
  }, [isCheckedExistence, existingItem, word]);

  useEffect(() => {
    if (existingItem) {
      setDescription(existingItem.description || "");
      setExamples((examples) => [
        ...existingItem.examples.map((e) => ({ ...e, prev: true })),
        ...examples,
      ]);
    }
  }, [existingItem]);

  const existingRowEnExamples = useMemo(() => {
    if (existingItem) {
      return existingItem.examples.map((e) => e.en);
    }

    return [];
  }, [existingItem]);

  useEffect(() => {
    setTranscription(item.transcription);
    setTranslation(
      Array.isArray(item.translation) ? item.translation.join(", ") : item.translation,
    );
    setDescription(item.description);
    setExamples(item.examples);
    setWord(item.word);
    setLanguageParts(item.languageParts || []);
  }, [item]);

  const handleTogleLanguagePart = (languagePart: string) => {
    setLanguageParts((prev) => {
      if (prev.includes(languagePart)) {
        return prev.filter((item) => item !== languagePart);
      } else {
        return [...prev, languagePart];
      }
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    console.log("🚀 ~ description:", description);

    const audioResponse: any = await createAudio(word);
    console.log("🚀 ~ existingItem:", existingItem, word);

    if (existingItem) {
      const updateWordResult = await supabaseClient
        .from("words")
        .update({
          examples,
          translation,
          description,
          transcription,
          language_parts: languageParts || [],
          audio_url: audioResponse?.data?.url,
          image_name: existingItem.image_name || getImageName(word),
        })
        .eq("name", word)
        .throwOnError();
      // console.log("🚀 ~ updateWordResult:", updateWordResult);

      setIsLoading(false);
      // onRemoveItem();
      return;
    }

    const newDocProps = {
      name: word,
      examples,
      audio_url: audioResponse?.data?.url,
      verified: false,
      language_parts: languageParts || [],
      description,
      translation,
      transcription,
      image_name: getImageName(word),
    };
    console.log("🚀 ~ newDocProps:", newDocProps);

    try {
      const createDocResult = await supabaseClient.from("words").insert(newDocProps).throwOnError();
      const { data } = await supabaseClient.from("words").select().eq("name", word);
      console.log("🚀 ~ created doc data:", data);
      setIsLoading(false);
      // onRemoveItem();
      setDescription("");
    } catch (err) {
      console.log("🚀 ~ err:", err);
      setIsLoading(false);
    }
  };

  const [wordsToCheckCount, setWordsToCheckCount] = useState<string[]>([item.word]);
  const [similarWords, setSimilarWords] = useState<{ id: string; count: number }[]>([]);

  useMountedWaitAsyncEffect(async () => {}, [wordsToCheckCount]);

  const handleCheckCount = async () => {
    // const { data } = await supabaseClient.from("harry-potter-word-map").select().in("id", wordsToCheckCount);
    let word = item.word;
    if (item.word.endsWith("ed")) {
      word = item.word.slice(0, item.word.length - 2);
    } else if (item.word.endsWith("ing")) {
      word = item.word.slice(0, item.word.length - 3);
    } else if (item.word.endsWith("s")) {
      word = item.word.slice(0, item.word.length - 1);
    }

    const { data } = await supabaseClient
      .from("harry-potter-word-map")
      .select()
      .like("id", `${word}%`);
    if (data) setSimilarWords(data);
    console.log("🚀 ~ data:", data);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3">
            <EditText
              value={word}
              style={{
                width: "100%",
                fontSize: "20px",
              }}
              onChange={(e) => {
                setWord(e.target.value);
              }}
            />
          </Typography>
          <Typography variant="h3">
            <EditText
              value={transcription}
              style={{
                width: "100%",
                fontSize: "20px",
              }}
              onChange={(e) => {
                setTranscription(e.target.value);
              }}
            />
          </Typography>
          <Button onClick={handleCheckCount}>check count</Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(word);
            }}
          >
            Copy word
          </Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(examples.map((e) => e.en)));
            }}
          >
            Copy sentences
          </Button>
          {similarWords.map((i) => {
            return (
              <Typography>
                {i.id}({i.count})
              </Typography>
            );
          })}
          <Typography variant="h3">
            <EditText
              value={translation}
              style={{
                width: "100%",
                fontSize: "20px",
              }}
              onChange={(e) => {
                setTranslation(e.target.value);
              }}
            />
          </Typography>
          <Typography variant="h3">
            <EditTextarea
              value={description}
              placeholder="Description"
              style={{
                width: "100%",
                fontSize: "20px",
                // minHeight: "40px",
              }}
              onSave={(e) => {
                console.log("🚀 ~ e:", e);
                setDescription(e.value);
              }}
              onChange={(e) => {
                console.log("🚀 ~ e:", e);
                setDescription(e.target.value);
              }}
            />
          </Typography>
          {/* <Box>
            <Button variant={languageParts.includes("іменник") ? "contained" : "outlined"} onClick={() => handleTogleLanguagePart("іменник")}>
              іменник
            </Button>
            <Button variant={languageParts.includes("дієслово") ? "contained" : "outlined"} onClick={() => handleTogleLanguagePart("дієслово")}>
              дієслово
            </Button>
            <Button variant={languageParts.includes("прислівник") ? "contained" : "outlined"} onClick={() => handleTogleLanguagePart("прислівник")}>
              прислівник
            </Button>
            <Button variant={languageParts.includes("прикметник") ? "contained" : "outlined"} onClick={() => handleTogleLanguagePart("прикметник")}>
              прикметник
            </Button>
            <Button variant={languageParts.includes("сполучник") ? "contained" : "outlined"} onClick={() => handleTogleLanguagePart("сполучник")}>
              сполучник
            </Button>
            <Button variant={languageParts.includes("займенник") ? "contained" : "outlined"} onClick={() => handleTogleLanguagePart("займенник")}>
              займенник
            </Button>
            <Button variant={languageParts.includes("числівник") ? "contained" : "outlined"} onClick={() => handleTogleLanguagePart("числівник")}>
              числівник
            </Button>
            <Button variant={languageParts.includes("частка") ? "contained" : "outlined"} onClick={() => handleTogleLanguagePart("частка")}>
              частка
            </Button>
            <Button variant={languageParts.includes("вигук") ? "contained" : "outlined"} onClick={() => handleTogleLanguagePart("вигук")}>
              вигук
            </Button>
          </Box> */}
        </Grid>
      </Grid>
      {examples?.map((example: any, index: number) => {
        return (
          <Grid container key={index} width="100%">
            <Grid item xs="auto">
              <Button
                onClick={() => {
                  setExamples(examples.filter((e) => e.en !== example.en));
                }}
              >
                remove
              </Button>
            </Grid>
            <Grid item xs={10}>
              <WordExample
                currentWord={
                  {
                    name: word,
                    examples: [example],
                  } as any
                }
                exists={existingRowEnExamples.includes(example.en)}
                prev={example.prev}
                currentRowWord={word}
              />
              {/* <Box>{example.en}</Box>
              <Box>{example.ua}</Box> */}
            </Grid>
          </Grid>
        );
      })}
      <Box>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit}>
          Submit
        </LoadingButton>
      </Box>
    </>
  );
};

const EnglisAndUkrainianAudioRecord = () => {
  const intl = useIntl();
  const [spWord, setSpWord] = useState<Word | null>(null);

  const onSubmit = useCallback(
    async (
      { enText, uaText }: typeof initialValues,
      { resetForm }: FormikHelpers<typeof initialValues>,
    ) => {
      if (!spWord) throw new Error("Sp word not found");
      try {
        const uas = uaText.split("\n").filter(Boolean);
        const ens = enText.split("\n").filter(Boolean);

        const rowExistingExamples = spWord.examples.map((e) => e.en);
        const updatedExamples: WordExampleType[] = [...spWord.examples];

        ens.forEach((en, index) => {
          const ua = uas[index];

          if (!ua) throw new Error("No ua text");

          if (!rowExistingExamples.includes(en)) {
            updatedExamples.push({
              en,
              ua,
            });
          }
        });

        const createWordResult = await supabaseClient
          .from("words")
          .update({ ...spWord, examples: updatedExamples })
          .eq("name", spWord.name)
          .throwOnError();
        console.log("🚀 ~ createWordResult:", createWordResult);

        resetForm();
      } catch (error) {
        console.log("🚀 try/catch onSubmit error:", error);
      }
    },

    [spWord],
  );

  const { values, isSubmitting, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues,
    onSubmit,
  });

  useMountedWaitAsyncEffect(async () => {
    const { data: spWord } = await supabaseClient
      .from("words")
      .select()
      .eq("name", values.word)
      .returns<Word>()
      .maybeSingle();

    setSpWord(spWord);
  }, [values.word]);

  const [prevGoodJson, setPrevGoodJson] = useState("[]");

  const addExamplesForm = useMemo(() => {
    return (
      <>
        <Grid item xs={6}>
          <TextField
            name="enText"
            multiline
            InputProps={{
              style: { lineHeight: 1.495 },
            }}
            value={values.enText}
            fullWidth
            label={intl.formatMessage(messages.inputEnLable)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="uaText"
            multiline
            InputProps={{
              style: { lineHeight: 1.495 },
            }}
            value={values.uaText}
            fullWidth
            label={intl.formatMessage(messages.inputUaLable)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading
            loadingPosition="start"
            variant="contained"
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
          >
            Submit
          </LoadingButton>
        </Grid>
      </>
    );
  }, [values, handleChange, handleSubmit, intl, isSubmitting]);

  const [parsedJSonExamples, setParsedJSonExamples] = useState<JSONWordProps["item"][]>([]);
  console.log("🚀 ~ parsedJSonExamples:", parsedJSonExamples);

  useEffect(() => {
    if (values.jsonExamples) {
      try {
        setParsedJSonExamples(JSON.parse(values.jsonExamples));
      } catch (e) {
        console.log("🚀 ~ e:", e);
      }
    }
  }, [values.jsonExamples]);

  const type = useMemo(() => {
    return parsedJSonExamples[0]?.type;
  }, [parsedJSonExamples]);

  const jsonValue = useMemo(() => {
    return values.jsonExamples
      ? (function sdfdsf(): any {
          try {
            const str = JSON.stringify(JSON.parse(values.jsonExamples), null, 2);

            setPrevGoodJson(str);

            return str;
          } catch (err) {
            console.log("🚀 ~ err:", err);

            return prevGoodJson;
          }
        })()
      : "";
  }, [values.jsonExamples]);

  const [isLoading, setIsLoading] = useState(false);
  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");
  const textToSpeechFunction = httpsCallable(
    functions,
    "recordBengaliTextToSpeechAndReturnStorageUrlFunction",
  );
  const textToSpeechFunctionStandardC = httpsCallable(
    functions,
    "recordBengaliTextToSpeechAndReturnStorageUrlFunctionStandardC",
  );
  const [banglaVoice, setBanglaVoice] = useState("bn-IN-Standard-D");
  const createBanglaAudio = async (sentence: string, voice?: string) => {
    try {
      const result: any = await textToSpeechFunction({
        bn: sentence,
        // voiceName: ["bn-IN-Standard-D", "bn-IN-Standard-C", "bn-IN-Standard-B", "bn-IN-Standard-A"][Math.floor(Math.random() * 4)],
        voiceName: banglaVoice,
      });
      console.log("🚀 ~ result:", result);
      return result?.data?.url;
    } catch (err) {
      console.log("🚀 try/catch create audio error:", err);
    }
  };
  const createBanglaAudioStandardC = async (sentence: string, voice?: string) => {
    try {
      const result: any = await textToSpeechFunctionStandardC({
        bn: sentence,
        // voiceName: ["bn-IN-Standard-D", "bn-IN-Standard-C", "bn-IN-Standard-B", "bn-IN-Standard-A"][Math.floor(Math.random() * 4)],
        voiceName: "bn-IN-Standard-C",
      });
      console.log("🚀 ~ result:", result);
      return result?.data?.url;
    } catch (err) {
      console.log("🚀 try/catch create audio error:", err);
    }
  };

  const [banglaAudio, setBanglaAudio] = useState("");

  // for Bangla
  const [pronunciation, setPronunciation] = useState("");
  const [definition, setDefinition] = useState("");
  const [ipa, setIpa] = useState("");
  const [banglaUaTranslation, setBanglaUaTranslation] = useState("");

  useEffect(() => {
    if (!pronunciation) {
      console.log("🚀 ~ pronunciation:", pronunciation);
      setPronunciation(parsedJSonExamples[0]?.pronunciation || "");
    }
  }, [parsedJSonExamples, pronunciation]);

  useEffect(() => {
    if (!definition) {
      console.log("🚀 ~ definition:", definition);
      setDefinition(parsedJSonExamples[0]?.script_definition?.join("\n") || "");
    }
  }, [parsedJSonExamples, definition]);

  useEffect(() => {
    if (!ipa) {
      setIpa(parsedJSonExamples[0]?.ipa_pronunciation || "");
    }
  }, [parsedJSonExamples, ipa]);

  useEffect(() => {
    if (!banglaUaTranslation) {
      setBanglaUaTranslation(parsedJSonExamples[0]?.ua?.join(", ") || "");
    }
  }, [parsedJSonExamples, banglaUaTranslation]);

  const examples = useMemo(() => {
    console.log("🚀 ~ type:", type);
    if (type === "Bengali") {
      return (
        <VStack alignItems="flex-start" width="100%">
          {parsedJSonExamples.map((item: JSONWordProps["item"], index: number) => (
            <Box key={item.en?.join(",")} py={2} width="100%">
              {/* <JSONWord
                item={item}
                onRemoveItem={() => {
                  const updatedWords = parsedJSonExamples.filter((i) => i.word !== item.word);
                  setParsedJSonExamples(updatedWords);
                }}
              /> */}
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      setBanglaAudio("");
                      setDefinition("");
                      setPronunciation("");
                      setIpa("");
                      resetForm();
                      setParsedJSonExamples([]);
                      setBanglaUaTranslation("");
                      setIsLoading(false);
                    }}
                  >
                    reset
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2">{item.scriptWord}</Typography>
                  <Typography variant="h2">{item.transliteration}</Typography>
                  <br />
                  <Typography variant="h3">Translation</Typography>
                  <Typography>{item.en?.join(", ")}</Typography>
                  <Box width="100%">
                    <TextField
                      fullWidth
                      color="primary"
                      value={banglaUaTranslation}
                      multiline={true}
                      onChange={(event) => {
                        setBanglaUaTranslation(event.target.value);
                      }}
                      type="text"
                      variant="outlined"
                    />
                  </Box>
                  {/* <Typography>{item.ua?.join(", ")}</Typography> */}
                </Grid>
                {banglaAudio && (
                  <Grid item xs={12}>
                    <WordAudio url={banglaAudio} autoPlay={true} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <HStack>
                    <HStack>
                      <Button
                        variant={banglaVoice === "bn-IN-Standard-D" ? "contained" : "outlined"}
                        onClick={() => {
                          setBanglaVoice("bn-IN-Standard-D");
                        }}
                      >
                        bn-IN-Standard-D
                      </Button>
                      <Button
                        variant={banglaVoice === "bn-IN-Standard-C" ? "contained" : "outlined"}
                        onClick={() => {
                          setBanglaVoice("bn-IN-Standard-C");
                        }}
                      >
                        bn-IN-Standard-C
                      </Button>
                      <Button
                        variant={banglaVoice === "bn-IN-Standard-B" ? "contained" : "outlined"}
                        onClick={() => {
                          setBanglaVoice("bn-IN-Standard-B");
                        }}
                      >
                        bn-IN-Standard-B
                      </Button>
                      <Button
                        variant={banglaVoice === "bn-IN-Standard-A" ? "contained" : "outlined"}
                        onClick={() => {
                          setBanglaVoice("bn-IN-Standard-A");
                        }}
                      >
                        bn-IN-Standard-B
                      </Button>
                      {/* "bn-IN-Standard-D", "bn-IN-Standard-C", "bn-IN-Standard-B", "bn-IN-Standard-A" */}
                    </HStack>
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      onClick={async () => {
                        console.log("🚀 ~ item:", item);
                        if (!item.scriptWord) return;
                        setBanglaAudio("");
                        setIsLoading(true);
                        const audio: any = await createBanglaAudio(item.scriptWord);
                        console.log("🚀 ~ audio:", audio);
                        if (audio) setBanglaAudio(audio as any);
                        setIsLoading(false);
                      }}
                    >
                      Create audio
                    </LoadingButton>
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      onClick={async () => {
                        console.log("🚀 ~ item:", item);
                        if (!item.scriptWord) return;
                        setBanglaAudio("");
                        setIsLoading(true);
                        const audio: any = await createBanglaAudioStandardC(item.scriptWord);
                        console.log("🚀 ~ audio:", audio);
                        if (audio) setBanglaAudio(audio as any);
                        setIsLoading(false);
                      }}
                    >
                      Create standard C audio
                    </LoadingButton>
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      onClick={async () => {
                        console.log("🚀 ~ item:", item);
                        // if (!item.scriptWord) return;
                        // setBanglaAudio("");
                        setIsLoading(true);
                        const audio: any = await createBanglaAudio("------");
                        console.log("🚀 ~ audio:", audio);
                        // if (audio) setBanglaAudio(audio as any);
                        setIsLoading(false);
                      }}
                    >
                      ---
                    </LoadingButton>
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      onClick={async () => {
                        setIsLoading(true);

                        const newWord: Omit<BanglaWord, "created_at"> = {
                          id: `${(item.transliteration || "").toLowerCase()}-${
                            item.scriptWord || ""
                          }`,
                          script: item.scriptWord || "",
                          en: item.en?.join(", ") || "",
                          ua: banglaUaTranslation || "",
                          script_definition: definition?.split("\n"),
                          transliteration: (item.transliteration || "").toLowerCase(),
                          audio_url: banglaAudio,
                          pronunciation: pronunciation,
                          ipa,
                          part_of_speech: item.part_of_speech?.join(", ") || "",
                          conjugation:
                            item.conjugation && !!Object.keys(item.conjugation)
                              ? item.conjugation
                              : null,
                        };
                        console.log("🚀 ~ newWord:", newWord);

                        const result = await supabaseClient.from("bangla-words").insert(newWord);
                        console.log("🚀 ~ result:", result);

                        setBanglaAudio("");
                        setDefinition("");
                        setPronunciation("");
                        setIpa("");
                        resetForm();
                        setBanglaUaTranslation("");
                        setParsedJSonExamples([]);
                        setIsLoading(false);
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </HStack>
                </Grid>
                <Grid item xs={12}>
                  <HStack>
                    <Link
                      target="_blank"
                      href={`https://en.glosbe.com/bn/en/${encodeURI(item.scriptWord || "")}`}
                    >
                      Glosbe
                    </Link>
                    <Link
                      target="_blank"
                      href={`https://www.shabdkosh.com/search-dictionary?lc=bn&sl=en&tl=bn&e=${encodeURI(
                        item.scriptWord || "",
                      )}`}
                    >
                      Shabdkosh
                    </Link>
                    <Link
                      target="_blank"
                      href={`https://dsal.uchicago.edu/cgi-bin/app/biswas-bangala_query.py?qs=${encodeURI(
                        item.scriptWord || "",
                      )}&matchtype=default`}
                    >
                      Uchicago
                    </Link>
                  </HStack>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">IPA</Typography>
                  <Box width="100%">
                    <TextField
                      fullWidth
                      color="primary"
                      value={ipa}
                      multiline={true}
                      onChange={(event) => {
                        setIpa(event.target.value);
                      }}
                      type="text"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">Part of speech</Typography>
                  <Box width="100%">
                    <Typography>{item.part_of_speech?.join(", ")}</Typography>
                    {/* <TextField
                      fullWidth
                      color="primary"
                      value={ipa}
                      multiline={true}
                      onChange={(event) => {
                        setIpa(event.target.value);
                      }}
                      type="text"
                      variant="outlined"
                    /> */}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">Definition</Typography>
                  <VStack alignItems="flex-start">
                    <Box width="100%">
                      <TextField
                        fullWidth
                        color="primary"
                        value={definition}
                        multiline={true}
                        onChange={(event) => {
                          setDefinition(event.target.value);
                        }}
                        type="text"
                        variant="outlined"
                      />
                    </Box>
                  </VStack>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">Pronunciation</Typography>
                  <Box width="100%">
                    <TextField
                      fullWidth
                      color="primary"
                      value={pronunciation}
                      multiline={true}
                      onChange={(event) => {
                        setPronunciation(event.target.value);
                      }}
                      type="text"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                {item.conjugation && !!Object.keys(item.conjugation).length && (
                  <Grid item xs={12}>
                    <Typography variant="h3">Відмінювання</Typography>
                    {Object.entries(item.conjugation).map(([person, verb]) => {
                      return (
                        <Typography key={person}>
                          {person} -{" "}
                          <Typography component="span" fontWeight={700}>
                            {verb}
                          </Typography>
                        </Typography>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </Box>
          ))}
        </VStack>
      );
    }

    return (
      <VStack alignItems="flex-start" width="100%">
        {parsedJSonExamples.map((item: JSONWordProps["item"], index: number) => (
          <Box key={item.word} py={2} width="100%">
            <JSONWord
              item={item}
              onRemoveItem={() => {
                const updatedWords = parsedJSonExamples.filter((i) => i.word !== item.word);
                setParsedJSonExamples(updatedWords);
              }}
            />
          </Box>
        ))}
      </VStack>
    );
  }, [
    parsedJSonExamples,
    type,
    banglaUaTranslation,
    isLoading,
    banglaAudio,
    ipa,
    definition,
    pronunciation,
    banglaVoice,
  ]);

  return (
    <Container>
      {/* <Button
        onClick={async () => {
          // const { data: bs } = await supabaseClient.from("bangla-sentences").select().returns<BanglaSentence[]>();

          // const map = bs!.reduce((prev, curr) => {
          //   return {
          //     ...prev,
          //     [curr.script]: "",
          //   };
          // }, {});
          // console.log("🚀 ~ map:", map);

          // navigator.clipboard.writeText(JSON.stringify(map, null, 2));
          await Promise.all(
            Object.entries({

            }).map(([script, transliteration]) => {
              return supabaseClient
                .from("bangla-sentences")
                .update({
                  transliteration,
                })
                .eq("script", script);
            }),
          );
        }}
      >
        Get bangla sentences map
      </Button> */}
      <Box display="flex" flexWrap="wrap" gap={5} mb={2}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <TextField name="word" value={values.word} fullWidth label="Word" onChange={handleChange} />
          </Grid>
          {spWord && addExamplesForm}
          <Grid item xs={12} mt={20}>
            <WordA1C2ExamplesCreation />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              sx={{ width: 2000, maxHeight: 1500, overflow: "hidden" }}
              name="jsonExamples"
              value={jsonValue}
              // value={parsedJSonExamples}
              fullWidth
              multiline
              placeholder="English JSON words"
              label="English JSON words"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            {examples}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default EnglisAndUkrainianAudioRecord;
