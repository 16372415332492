function removePunctuationFromString(text: string, withAlert?: boolean) {
  if (withAlert) alert(text);
  let updatedText = [...text]
    .filter((l) => ![".", ",", "‘", "!", "?", ";", ",", '"'].includes(l))
    .join("");
  // .replace(/[\.\,\‘\!\?\;\,\"]/g, "");
  if (withAlert) alert(updatedText);

  if (updatedText.match(/\:/)) {
    const text = updatedText.replace(/\:/g, "");
    const number = +text;
    if (!number) {
      updatedText = text;
    }
  }
  if (withAlert) alert(updatedText);

  // console.log("🚀 ~ updatedText:", updatedText);

  if (updatedText.startsWith("'") && updatedText.endsWith("'")) {
    return updatedText.replace(/'/g, "");
  }
  if (updatedText.startsWith("'") || updatedText.endsWith("'")) {
    return updatedText.replace(/'/g, "");
  }
  if (updatedText.startsWith('"') && updatedText.endsWith('"')) {
    // console.log("🚀 ~ updatedText:", updatedText);
    return updatedText.replace(/"/g, "");
  }
  if (updatedText.startsWith('"') || updatedText.endsWith('"')) {
    // console.log("🚀 ~ updatedText:", updatedText);
    return updatedText.replace(/"/g, "");
  }
  if (updatedText.startsWith("(")) {
    return updatedText.replace(/[\(\)]/g, "");
  }
  if (updatedText.endsWith(")")) {
    return updatedText.replace(/[\)\(]/g, "");
  }
  if (withAlert) alert(updatedText);
  return updatedText;
}

export default removePunctuationFromString;
