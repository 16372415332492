import { useCallback } from "react";
import getGCRandomVoice from "./getGCRandomVoice";
import { useFirebaseApp } from "reactfire";
import { getFunctions, httpsCallable } from "firebase/functions";

interface CreateAudioResponse {
  url: string;
}

const useCreateAudio = () => {
  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");
  const textToSpeechFunction = httpsCallable<
    { en: string; voiceName: string },
    CreateAudioResponse
  >(functions, "recordEnglishTextToSpeechAndReturnStorageUrl");

  const createAudio = useCallback(
    async (text: string): Promise<string> => {
      try {
        const result = await textToSpeechFunction({
          en: text,
          voiceName: getGCRandomVoice(),
        });
        return result?.data?.url || "";
      } catch (err) {
        console.log("🚀 try/catch crate audio error", err);
      }
      return "";
    },
    [textToSpeechFunction],
  );

  return {
    createAudio,
  };
};

export default useCreateAudio;
