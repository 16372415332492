import { TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { FoodItem } from "../../../types/supabase";
import Box from "../../Unknown/Box";
import FoodItemImage from "../FoodItemImage";
import getImageName from "../utils/getImageName";

const SearchForFoodItems: React.FC<{ type: "en" | "ua"; initial?: string; onItemChange?: (item: FoodItem) => Promise<void> }> = ({ type, initial, onItemChange }) => {
  console.log("🚀 ~ initial:", initial);
  const [text, setText] = useState(initial || "");
  const [rows, setRows] = useState<FoodItem[]>([]);
  console.log("🚀 ~ rows:", rows);

  useEffect(() => {
    if (initial) {
      setText(initial);
    }
  }, [initial]);

  useMountedWaitAsyncEffect(
    async () => {
      if (text) {
        const { data } = await supabaseClient
          .from("food_items")
          .select()
          .ilike(type === "ua" ? "name" : "en_name", `%${text}%`);
        if (data) setRows(data);
      }
    },
    [text, type],
    500,
  );

  return (
    <Box width="100%">
      <Box mb={3}>
        <TextField
          fullWidth
          color="primary"
          value={text}
          placeholder="Search for food items"
          multiline={true}
          onChange={(event) => {
            setText(event.target.value);
          }}
          type="text"
          variant="outlined"
        />
      </Box>

      <Box>
        {rows.map((row) => {
          return (
            <Box
              key={row.en_name}
              mb={3}
              onClick={() => {
                if (onItemChange) onItemChange(row);
              }}
            >
              <Typography variant="caption">{row.en_name}</Typography>
              <Typography color={type === "en" && initial && row.en_name === initial ? "primary" : "black"}>{row.name}</Typography>
              {row.image && <FoodItemImage image={getImageName(row.en_name)} />}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SearchForFoodItems;
