import { Box, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import firstLetterUppercase from "../../../common/firstLetterUppercase";
import VStack from "../../Unknown/VStack";
import getSentenceWordMatch from "../../Word/WordTranslationSection/getSentenceWordMatch";

interface WordExampleSentenceProps {
  ua: string;
  en: string;
  highlight?: string;
}

const ExampleItem = ({ en: inputEn, ua: inputUa, highlight }: WordExampleSentenceProps) => {
  const enSentence = useMemo(() => {
    if (highlight) return getSentenceWordMatch(inputEn, highlight);

    return firstLetterUppercase(inputEn);
  }, [highlight, inputEn]);

  return (
    <Box display="flex" flexDirection="column">
      <VStack gap={0} position="relative" mb={3} alignItems={"flex-start"}>
        <Typography color="secondary.dark" fontWeight={500}>
          {enSentence}
        </Typography>
        {inputUa && (
          <Typography color="#919096" fontWeight={500}>
            {inputUa}
          </Typography>
        )}
      </VStack>
    </Box>
  );
};

export default memo(ExampleItem);
