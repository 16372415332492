import quotes2 from "./quotes2";
import quotes3 from "./quotes3";
import quotes4 from "./quotes4";
import quotes5 from "./quotes5";
import quotes6 from "./quotes6";
import quotes7 from "./quotes7";
import quotes8 from "./quotes8";
import quotes9 from "./quotes9";

const quotesData: any[] = [
  {
    row_new_words: null,
    words: ["if", "you", "think", "can", "do", "it"],
    id: "If you think you can do it, you can.",
    author: "John Burroughs",
    grammar: null,
    word_count: 6,
    all_intersection_count: 6,
    new_words_count: 0,
  },
  {
    row_new_words: null,
    words: ["you", "can", "be", "happy", "where", "are"],
    id: "You can be happy where you are.",
    author: "Joel Osteen",
    grammar: null,
    word_count: 6,
    all_intersection_count: 6,
    new_words_count: 0,
  },
  {
    row_new_words: null,
    words: ["every", "day", "is", "a", "learning"],
    id: "Every day is a learning day.",
    author: "Winston Marshall",
    grammar: null,
    word_count: 5,
    all_intersection_count: 5,
    new_words_count: 0,
  },
  {
    row_new_words: null,
    words: ["don't", "think", "just", "do"],
    id: "Don't think, just do.",
    author: "Horace",
    grammar: null,
    word_count: 4,
    all_intersection_count: 4,
    new_words_count: 0,
  },
  {
    row_new_words: null,
    words: ["let", "them", "eat", "cake"],
    id: "Let them eat cake.",
    author: "Marie Antoinette",
    grammar: null,
    word_count: 4,
    all_intersection_count: 4,
    new_words_count: 0,
  },
  {
    row_new_words: ["happiness"],
    words: ["to", "me", "flowers", "are", "happiness"],
    id: "To me, flowers are happiness.",
    author: "Stefano Gabbana",
    grammar: null,
    word_count: 5,
    all_intersection_count: 4,
    new_words_count: 1,
  },
  {
    row_new_words: ["teaching"],
    words: ["it's", "not", "the", "teaching", "learning"],
    id: "It's not the teaching, it's the learning.",
    author: "Sly Stone",
    grammar: null,
    word_count: 5,
    all_intersection_count: 4,
    new_words_count: 1,
  },
  {
    row_new_words: ["dream"],
    words: ["if", "you", "can", "dream", "it", "do"],
    id: "If you can dream it, you can do it.",
    author: "Walt Disney",
    grammar: null,
    word_count: 6,
    all_intersection_count: 5,
    new_words_count: 1,
  },
  {
    row_new_words: ["things"],
    words: ["we", "love", "things", "what", "they", "are"],
    id: "We love things we love what they are.",
    author: "Robert Frost",
    grammar: null,
    word_count: 6,
    all_intersection_count: 5,
    new_words_count: 1,
  },
  {
    row_new_words: ["judge"],
    words: ["we", "do", "not", "judge", "the", "people", "love"],
    id: "We do not judge the people we love.",
    author: "Jean-Paul Sartre",
    grammar: null,
    word_count: 7,
    all_intersection_count: 6,
    new_words_count: 1,
  },
  {
    row_new_words: ["jealous"],
    words: ["he", "that", "is", "jealous", "not", "in", "love"],
    id: "He that is jealous is not in love.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 6,
    new_words_count: 1,
  },
  {
    row_new_words: ["things"],
    words: ["things", "are", "beautiful", "if", "you", "love", "them"],
    id: "Things are beautiful if you love them.",
    author: "Jean Anouilh",
    grammar: null,
    word_count: 7,
    all_intersection_count: 6,
    new_words_count: 1,
  },
  {
    row_new_words: ["become"],
    words: ["what", "we", "think", "become"],
    id: "What we think, we become.",
    author: "Buddha",
    grammar: null,
    word_count: 4,
    all_intersection_count: 3,
    new_words_count: 1,
  },
  {
    row_new_words: ["blind"],
    words: ["love", "is", "blind"],
    id: "Love is blind.",
    author: "Geoffrey Chaucer",
    grammar: null,
    word_count: 3,
    all_intersection_count: 2,
    new_words_count: 1,
  },
  {
    row_new_words: ["more"],
    words: ["the", "more", "we", "do", "can"],
    id: "The more we do, the more we can do.",
    author: "William Hazlitt",
    grammar: null,
    word_count: 5,
    all_intersection_count: 4,
    new_words_count: 1,
  },
  {
    row_new_words: ["act"],
    words: ["to", "love", "is", "act"],
    id: "To love is to act.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 4,
    all_intersection_count: 3,
    new_words_count: 1,
  },
  {
    row_new_words: ["silent"],
    words: ["this", "love", "is", "silent"],
    id: "This love is silent.",
    author: "T. S. Eliot",
    grammar: null,
    word_count: 4,
    all_intersection_count: 3,
    new_words_count: 1,
  },
  {
    row_new_words: ["tide"],
    words: ["love", "has", "a", "tide"],
    id: "Love has a tide!",
    author: "Helen Hunt Jackson",
    grammar: null,
    word_count: 4,
    all_intersection_count: 3,
    new_words_count: 1,
  },
  {
    row_new_words: ["i'm"],
    words: ["i'm", "learning", "every", "day"],
    id: "I'm learning every day.",
    author: "Laura Harrier",
    grammar: null,
    word_count: 4,
    all_intersection_count: 3,
    new_words_count: 1,
  },
  {
    row_new_words: ["simplify"],
    words: ["simplify"],
    id: "Simplify, simplify.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 1,
    all_intersection_count: 0,
    new_words_count: 1,
  },
  {
    row_new_words: ["lying"],
    words: ["if", "you're", "lying"],
    id: "If you're lying, you're lying.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 3,
    all_intersection_count: 2,
    new_words_count: 1,
  },
  {
    row_new_words: ["begin"],
    words: ["to", "begin"],
    id: "To begin, begin.",
    author: "William Wordsworth",
    grammar: null,
    word_count: 2,
    all_intersection_count: 1,
    new_words_count: 1,
  },
  {
    row_new_words: ["time"],
    words: ["time", "is", "money"],
    id: "Time is money.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 3,
    all_intersection_count: 2,
    new_words_count: 1,
  },
  {
    row_new_words: ["prospering"],
    words: ["prospering", "just", "doesn't", "have", "to", "do", "with", "money"],
    id: "Prospering just doesn't have to do with money.",
    author: "Joel Osteen",
    grammar: null,
    word_count: 8,
    all_intersection_count: 7,
    new_words_count: 1,
  },
  {
    row_new_words: ["best"],
    words: ["learn", "to", "say", "no", "the", "good", "so", "you", "can", "yes", "best"],
    id: "Learn to say 'no' to the good so you can say 'yes' to the best.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 10,
    new_words_count: 1,
  },
  {
    row_new_words: ["success"],
    words: ["success", "is", "not", "in", "what", "you", "have", "but", "who", "are"],
    id: "Success is not in what you have, but who you are.",
    author: "Bo Bennett",
    grammar: null,
    word_count: 10,
    all_intersection_count: 9,
    new_words_count: 1,
  },
  {
    row_new_words: ["how"],
    words: ["learning", "what", "you", "don't", "want", "is", "how", "know", "do"],
    id: "Learning what you don't want is how you know what you do want.",
    author: "Robin Wright",
    grammar: null,
    word_count: 9,
    all_intersection_count: 8,
    new_words_count: 1,
  },
  {
    row_new_words: ["beauty"],
    words: ["let", "the", "beauty", "of", "what", "you", "love", "be", "do"],
    id: "Let the beauty of what you love be what you do.",
    author: "Rumi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 8,
    new_words_count: 1,
  },
  {
    row_new_words: ["may"],
    words: ["we", "know", "what", "are", "but", "not", "may", "be"],
    id: "We know what we are, but know not what we may be.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 7,
    new_words_count: 1,
  },
  {
    row_new_words: ["get", "life"],
    words: ["you", "don't", "get", "what", "want", "in", "life", "who", "are"],
    id: "You don't get what you want in life. You get who you are!",
    author: "Les Brown",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["air", "intensity"],
    words: ["people", "happy", "in", "love", "have", "an", "air", "of", "intensity"],
    id: "People happy in love have an air of intensity.",
    author: "Stendhal",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["reason", "everything"],
    words: ["have", "a", "very", "good", "reason", "for", "everything", "you", "do"],
    id: "Have a very good reason for everything you do.",
    author: "Laurence Olivier",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "hate"],
    words: ["i", "don't", "love", "studying", "hate", "like", "learning", "is", "beautiful"],
    id: "I don't love studying. I hate studying. I like learning. Learning is beautiful.",
    author: "Natalie Portman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["things", "tell"],
    words: ["the", "things", "that", "we", "love", "tell", "us", "what", "are"],
    id: "The things that we love tell us what we are.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["can't", "one"],
    words: ["if", "you", "can't", "be", "with", "the", "one", "love", "you're"],
    id: "If you can't be with the one you love, love the one you're with.",
    author: "Stephen Stills",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["start", "use"],
    words: ["start", "where", "you", "are", "use", "what", "have", "do", "can"],
    id: "Start where you are. Use what you have. Do what you can.",
    author: "Arthur Ashe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["when", "happiness"],
    words: ["when", "what", "we", "are", "is", "want", "to", "be", "that's", "happiness"],
    id: "When what we are is what we want to be, that's happiness.",
    author: "Malcolm Forbes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 8,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "important"],
    words: ["i", "don't", "know", "what", "am", "and", "it's", "not", "important"],
    id: "I don't know what I am. And it's not important.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["god", "recognize"],
    words: ["people", "see", "god", "every", "day", "they", "just", "don't", "recognize", "him"],
    id: "People see God every day, they just don't recognize him.",
    author: "Pearl Bailey",
    grammar: null,
    word_count: 10,
    all_intersection_count: 8,
    new_words_count: 2,
  },
  {
    row_new_words: ["birth", "family"],
    words: ["you", "don't", "have", "to", "give", "birth", "someone", "a", "family"],
    id: "You don't have to give birth to someone to have a family.",
    author: "Sandra Bullock",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["would", "round"],
    words: ["some", "people", "would", "like", "me", "to", "be", "round", "again"],
    id: "Some people would like me to be round again.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["will", "soon"],
    words: ["what", "is", "beautiful", "good", "and", "who", "will", "soon", "be"],
    id: "What is beautiful is good, and who is good will soon be beautiful.",
    author: "Vita Sackville-West",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "fight"],
    words: ["i", "do", "not", "care", "where", "fight", "just", "want", "to"],
    id: "I do not care where I fight; I just want to fight.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["true", "knowledge"],
    words: ["to", "know", "what", "you", "and", "do", "not", "that", "is", "true", "knowledge"],
    id: "To know what you know and what you do not know, that is true knowledge.",
    author: "Confucius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 9,
    new_words_count: 2,
  },
  {
    row_new_words: ["somebody", "chance"],
    words: ["we", "need", "somebody", "to", "give", "us", "a", "chance"],
    id: "We need somebody to give us a chance.",
    author: "Tony Dungy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["problem", "solving"],
    words: ["you", "are", "not", "a", "problem", "that", "needs", "solving"],
    id: "You are not a problem that needs solving.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["lover", "forever"],
    words: ["he", "is", "not", "a", "lover", "who", "does", "love", "forever"],
    id: "He is not a lover who does not love forever.",
    author: "Euripides",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["there", "rich"],
    words: ["there", "are", "people", "who", "have", "money", "and", "rich"],
    id: "There are people who have money and people who are rich.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["recognition", "worthy"],
    words: ["don't", "work", "for", "recognition", "but", "do", "worthy", "of"],
    id: "Don't work for recognition, but do work worthy of recognition.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["errors", "all"],
    words: ["love", "has", "no", "errors", "for", "all", "are", "the", "want"],
    id: "Love has no errors, for all errors are the want for love.",
    author: "William Law",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["more", "less"],
    words: ["the", "more", "you", "know", "less", "need", "to", "say"],
    id: "The more you know the less you need to say.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["or", "question"],
    words: ["to", "be", "or", "not", "that", "is", "the", "question"],
    id: "To be, or not to be, that is the question.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["pilot", "storm"],
    words: ["you", "learn", "to", "know", "a", "pilot", "in", "storm"],
    id: "You learn to know a pilot in a storm.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["laugh", "sunshine"],
    words: ["a", "good", "laugh", "is", "sunshine", "in", "the", "house"],
    id: "A good laugh is sunshine in the house.",
    author: "William Makepeace Thackeray",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["living", "really"],
    words: ["if", "you", "are", "not", "living", "this", "moment", "really"],
    id: "If you are not living this moment, you are not really living.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["i'm", "gut"],
    words: ["i'm", "going", "to", "gut", "you", "like", "a", "fish"],
    id: "I'm going to gut you like a fish.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["reach", "point"],
    words: ["you", "reach", "a", "point", "where", "don't", "work", "for", "money"],
    id: "You reach a point where you don't work for money.",
    author: "Walt Disney",
    grammar: null,
    word_count: 9,
    all_intersection_count: 7,
    new_words_count: 2,
  },
  {
    row_new_words: ["cannot", "concentrate"],
    words: ["if", "you", "cannot", "concentrate", "are", "not", "so", "happy"],
    id: "If you cannot concentrate, you are not so happy.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["brighter", "more"],
    words: ["the", "brighter", "you", "are", "more", "have", "to", "learn"],
    id: "The brighter you are, the more you have to learn.",
    author: "Don Herold",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["influence", "believe"],
    words: ["the", "people", "who", "influence", "you", "are", "believe", "in"],
    id: "The people who influence you are the people who believe in you.",
    author: "Henry Drummond",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["hard", "wisdom"],
    words: ["it's", "very", "hard", "to", "know", "what", "wisdom", "is"],
    id: "It's very hard to know what wisdom is.",
    author: "James Hillman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["missing", "one"],
    words: [
      "if",
      "you",
      "don't",
      "think",
      "every",
      "day",
      "is",
      "a",
      "good",
      "just",
      "try",
      "missing",
      "one",
    ],
    id: "If you don't think every day is a good day, just try missing one.",
    author: "Cavett Robert",
    grammar: null,
    word_count: 13,
    all_intersection_count: 11,
    new_words_count: 2,
  },
  {
    row_new_words: ["experience", "happens"],
    words: ["experience", "is", "not", "what", "happens", "to", "you", "it's", "do", "with"],
    id: "Experience is not what happens to you; it's what you do with what happens to you.",
    author: "Aldous Huxley",
    grammar: null,
    word_count: 10,
    all_intersection_count: 8,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "right"],
    words: ["i", "just", "know", "what", "is", "right", "and", "want", "to", "do"],
    id: "I just know what is right and I want to do what is right.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 10,
    all_intersection_count: 8,
    new_words_count: 2,
  },
  {
    row_new_words: ["change", "times"],
    words: ["if", "you", "don't", "change", "with", "the", "times", "are", "going", "to"],
    id: "If you don't change with the times, the times are going to change you.",
    author: "Marv Levy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 8,
    new_words_count: 2,
  },
  {
    row_new_words: ["right", "cowardice"],
    words: ["to", "see", "the", "right", "and", "not", "do", "it", "is", "cowardice"],
    id: "To see the right and not to do it is cowardice.",
    author: "Confucius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 8,
    new_words_count: 2,
  },
  {
    row_new_words: ["everyone", "truth"],
    words: ["not", "everyone", "can", "see", "the", "truth", "but", "he", "be", "it"],
    id: "Not everyone can see the truth, but he can be it.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 10,
    all_intersection_count: 8,
    new_words_count: 2,
  },
  {
    row_new_words: ["right", "importance"],
    words: ["it", "is", "not", "who", "right", "but", "what", "that", "of", "importance"],
    id: "It is not who is right, but what is right, that is of importance.",
    author: "Thomas Huxley",
    grammar: null,
    word_count: 10,
    all_intersection_count: 8,
    new_words_count: 2,
  },
  {
    row_new_words: ["best", "preparation"],
    words: [
      "the",
      "best",
      "preparation",
      "for",
      "good",
      "work",
      "tomorrow",
      "is",
      "to",
      "do",
      "today",
    ],
    id: "The best preparation for good work tomorrow is to do good work today.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 11,
    all_intersection_count: 9,
    new_words_count: 2,
  },
  {
    row_new_words: ["true", "yourself"],
    words: ["you", "just", "have", "to", "be", "true", "yourself", "and", "do", "what", "love"],
    id: "You just have to be true to yourself and do what you love to do.",
    author: "Mickie James",
    grammar: null,
    word_count: 11,
    all_intersection_count: 9,
    new_words_count: 2,
  },
  {
    row_new_words: ["true", "knowledge"],
    words: ["to", "know", "that", "we", "what", "and", "do", "not", "is", "true", "knowledge"],
    id: "To know that we know what we know, and to know that we do not know what we do not know, that is true knowledge.",
    author: "Nicolaus Copernicus",
    grammar: null,
    word_count: 11,
    all_intersection_count: 9,
    new_words_count: 2,
  },
  {
    row_new_words: ["conquers", "all"],
    words: ["love", "conquers", "all"],
    id: "Love conquers all.",
    author: "Virgil",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["genius", "patience"],
    words: ["genius", "is", "patience"],
    id: "Genius is patience.",
    author: "Isaac Newton",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["momentum", "important"],
    words: ["momentum", "is", "very", "important"],
    id: "Momentum is very important.",
    author: "Sarfaraz Ahmed",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["whatever", "intensely"],
    words: ["do", "whatever", "you", "intensely"],
    id: "Do whatever you do intensely.",
    author: "Robert Henri",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["can't", "happiness"],
    words: ["money", "can't", "buy", "happiness"],
    id: "Money can't buy happiness.",
    author: "Howard Hughes",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "friendship"],
    words: ["i", "like", "friendship"],
    id: "I like friendship.",
    author: "Juergen Teller",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["conquers", "endures"],
    words: ["he", "conquers", "who", "endures"],
    id: "He conquers who endures.",
    author: "Persius",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["beauty", "health"],
    words: ["beauty", "is", "health"],
    id: "Beauty is health. Health is beauty.",
    author: "Andre Leon Talley",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["everybody's", "filmmaker"],
    words: ["everybody's", "a", "filmmaker", "today"],
    id: "Everybody's a filmmaker today.",
    author: "John Milius",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["i'll", "back"],
    words: ["i'll", "be", "back"],
    id: "I'll be back.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["independence", "happiness"],
    words: ["independence", "is", "happiness"],
    id: "Independence is happiness.",
    author: "Susan B. Anthony",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["time", "bunk"],
    words: ["time", "is", "bunk"],
    id: "Time is bunk.",
    author: "Douglas Adams",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["living", "risking"],
    words: ["living", "is", "risking"],
    id: "Living is risking.",
    author: "Jeanne Moreau",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "therefore"],
    words: ["i", "can", "therefore", "am"],
    id: "I can, therefore I am.",
    author: "Simone Weil",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["trembling", "happiness"],
    words: ["love", "is", "trembling", "happiness"],
    id: "Love is trembling happiness.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["my", "time"],
    words: ["my", "time", "is", "now"],
    id: "My time is now.",
    author: "John Turner",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["success", "breeds"],
    words: ["success", "breeds"],
    id: "Success breeds success.",
    author: "Mia Hamm",
    grammar: null,
    word_count: 2,
    all_intersection_count: 0,
    new_words_count: 2,
  },
  {
    row_new_words: ["i'm", "patient"],
    words: ["i'm", "patient"],
    id: "I'm patient.",
    author: "Michael Jordan",
    grammar: null,
    word_count: 2,
    all_intersection_count: 0,
    new_words_count: 2,
  },
  {
    row_new_words: ["kindness", "wisdom"],
    words: ["kindness", "is", "wisdom"],
    id: "Kindness is wisdom.",
    author: "Philip James Bailey",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["i'm", "always"],
    words: ["i'm", "always", "learning"],
    id: "I'm always learning.",
    author: "Jude Bellingham",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["nothing", "come"],
    words: ["nothing", "can", "come", "of"],
    id: "Nothing can come of nothing.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["enough", "never"],
    words: ["good", "enough", "never", "is"],
    id: "Good enough never is.",
    author: "Debbi Fields",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["education", "freedom"],
    words: ["education", "is", "freedom"],
    id: "Education is freedom.",
    author: "Paulo Freire",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["equals", "freedom"],
    words: ["money", "equals", "freedom"],
    id: "Money equals freedom.",
    author: "Kevin O'Leary",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["never", "up"],
    words: ["never", "give", "up"],
    id: "Never, never, never give up.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 3,
    all_intersection_count: 1,
    new_words_count: 2,
  },
  {
    row_new_words: ["actor", "patience"],
    words: ["to", "be", "an", "actor", "you", "have", "patience"],
    id: "To be an actor, you have to have patience.",
    author: "Tripti Dimri",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["friendship", "sheltering"],
    words: ["love", "is", "flower", "like", "friendship", "a", "sheltering", "tree"],
    id: "Love is flower like; Friendship is like a sheltering tree.",
    author: "Samuel Taylor Coleridge",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "loved"],
    words: ["i", "just", "want", "to", "love", "and", "be", "loved"],
    id: "I just want to love and be loved.",
    author: "Jennie Garth",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["intense", "measure"],
    words: ["intense", "love", "does", "not", "measure", "it", "just", "gives"],
    id: "Intense love does not measure, it just gives.",
    author: "Mother Teresa",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["all", "duty"],
    words: ["we", "all", "have", "the", "duty", "to", "do", "good"],
    id: "We all have the duty to do good.",
    author: "Pope Francis",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["made", "god"],
    words: ["we", "are", "made", "to", "know", "and", "love", "god"],
    id: "We are made to know and love God.",
    author: "Nicolas Malebranche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["patience", "legislator"],
    words: ["you", "have", "to", "patience", "be", "a", "legislator"],
    id: "You have to have patience to be a legislator.",
    author: "Gene Green",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["your", "life"],
    words: ["be", "happy", "for", "this", "moment", "is", "your", "life"],
    id: "Be happy for this moment. This moment is your life.",
    author: "Omar Khayyam",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["pardoning", "pardoned"],
    words: ["it", "is", "in", "pardoning", "that", "we", "are", "pardoned"],
    id: "It is in pardoning that we are pardoned.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["giving", "receive"],
    words: ["for", "it", "is", "in", "giving", "that", "we", "receive"],
    id: "For it is in giving that we receive.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["east", "west"],
    words: ["if", "you", "want", "to", "go", "east", "don't", "west"],
    id: "If you want to go east, don't go west.",
    author: "Ramakrishna",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["hole", "heart"],
    words: ["love", "is", "a", "hole", "in", "the", "heart"],
    id: "Love is a hole in the heart.",
    author: "Ben Hecht",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["easy", "alone"],
    words: ["it", "is", "very", "easy", "to", "love", "alone"],
    id: "It is very easy to love alone.",
    author: "Gertrude Stein",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["blind", "fortune"],
    words: ["it", "is", "we", "that", "are", "blind", "not", "fortune"],
    id: "It is we that are blind, not fortune.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["something", "everything"],
    words: ["try", "to", "learn", "something", "about", "everything", "and"],
    id: "Try to learn something about everything and everything about something.",
    author: "Thomas Huxley",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["right", "true"],
    words: ["if", "it", "is", "not", "right", "do", "true", "say"],
    id: "If it is not right do not do it; if it is not true do not say it.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["life", "crazy"],
    words: ["life", "is", "beautiful", "but", "people", "are", "crazy"],
    id: "Life is beautiful but people are crazy.",
    author: "Charles Osgood",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["life", "process"],
    words: ["this", "life", "is", "a", "process", "of", "learning"],
    id: "This life is a process of learning.",
    author: "Lauryn Hill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["emotion", "sense"],
    words: ["people", "think", "love", "is", "an", "emotion", "good", "sense"],
    id: "People think love is an emotion. Love is good sense.",
    author: "Ken Kesey",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["reason", "loving"],
    words: ["do", "you", "have", "to", "a", "reason", "for", "loving"],
    id: "Do you have to have a reason for loving?",
    author: "Brigitte Bardot",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["only", "prepared"],
    words: ["people", "only", "see", "what", "they", "are", "prepared", "to"],
    id: "People only see what they are prepared to see.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 6,
    new_words_count: 2,
  },
  {
    row_new_words: ["pardon", "extent"],
    words: ["we", "pardon", "to", "the", "extent", "that", "love"],
    id: "We pardon to the extent that we love.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["will", "show"],
    words: ["what", "you", "are", "will", "show", "in", "do"],
    id: "What you are will show in what you do.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["all", "there"],
    words: ["love", "and", "work", "that's", "all", "there", "is"],
    id: "Love and work... work and love, that's all there is.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["more", "things"],
    words: ["the", "more", "things", "you", "do", "can"],
    id: "The more things you do, the more you can do.",
    author: "Lucille Ball",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["gateway", "adventure"],
    words: ["learning", "is", "the", "gateway", "to", "adventure"],
    id: "Learning is the gateway to adventure.",
    author: "Princess Beatrice of York",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["best", "enemy"],
    words: ["the", "best", "is", "enemy", "of", "good"],
    id: "The best is the enemy of the good.",
    author: "Voltaire",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["faith", "god"],
    words: ["have", "faith", "in", "god", "has", "you"],
    id: "Have faith in God; God has faith in you.",
    author: "Edwin Louis Cole",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "shall"],
    words: ["some", "day", "i", "shall", "be", "president"],
    id: "Some day I shall be President.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "all"],
    words: ["i", "think", "and", "that", "is", "all", "am"],
    id: "I think and that is all that I am.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["beauty", "light"],
    words: ["to", "love", "beauty", "is", "see", "light"],
    id: "To love beauty is to see light.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["stroke", "words"],
    words: ["you", "can", "stroke", "people", "with", "words"],
    id: "You can stroke people with words.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["isn't", "how"],
    words: ["it", "isn't", "what", "you", "do", "but", "how"],
    id: "It isn't what you do, but how you do it.",
    author: "John Wooden",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["age", "anxiety"],
    words: ["now", "is", "the", "age", "of", "anxiety"],
    id: "Now is the age of anxiety.",
    author: "W. H. Auden",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["never", "stop"],
    words: ["we", "never", "stop", "working", "and", "learning"],
    id: "We never stop working and learning.",
    author: "Mick Schumacher",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["life", "progress"],
    words: ["life", "is", "a", "work", "in", "progress"],
    id: "Life is a work in progress.",
    author: "Jeff Rich",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["believes", "impossible"],
    words: ["who", "so", "loves", "believes", "the", "impossible"],
    id: "Who so loves believes the impossible.",
    author: "Elizabeth Barrett Browning",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["beauty", "soul"],
    words: ["love", "is", "the", "beauty", "of", "soul"],
    id: "Love is the beauty of the soul.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "win"],
    words: ["i", "like", "seeing", "good", "people", "win"],
    id: "I like seeing good people win.",
    author: "Mac Miller",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["outing", "experience"],
    words: ["every", "outing", "is", "a", "learning", "experience"],
    id: "Every outing is a learning experience.",
    author: "Drew Pomeranz",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["forget", "oneself"],
    words: ["to", "forget", "oneself", "is", "be", "happy"],
    id: "To forget oneself is to be happy.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["success", "unique"],
    words: ["if", "you", "want", "success", "be", "unique"],
    id: "If you want success, be unique.",
    author: "Natalie Massenet",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["dominate", "cultivates"],
    words: ["love", "does", "not", "dominate", "it", "cultivates"],
    id: "Love does not dominate; it cultivates.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["beggars", "kings"],
    words: ["and", "love", "is", "in", "beggars", "kings"],
    id: "And love is love in beggars and in kings.",
    author: "Edward Dyer",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["future", "starts"],
    words: ["the", "future", "starts", "today", "not", "tomorrow"],
    id: "The future starts today, not tomorrow.",
    author: "Pope John Paul II",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["there's", "lurking"],
    words: ["there's", "a", "snake", "lurking", "in", "the", "grass"],
    id: "There's a snake lurking in the grass.",
    author: "Virgil",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["purpose", "life"],
    words: ["the", "purpose", "of", "life", "is", "a"],
    id: "The purpose of life is a life of purpose.",
    author: "Robert Byrne",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["life", "jungle"],
    words: ["life", "is", "a", "zoo", "in", "jungle"],
    id: "Life is a zoo in a jungle.",
    author: "Peter De Vries",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["show", "their"],
    words: ["they", "do", "not", "love", "that", "show", "their"],
    id: "They do not love that do not show their love.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["expect", "problems"],
    words: ["expect", "problems", "and", "eat", "them", "for", "breakfast"],
    id: "Expect problems and eat them for breakfast.",
    author: "Alfred A. Montapert",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["faith", "technology"],
    words: ["it's", "not", "a", "faith", "in", "technology", "people"],
    id: "It's not a faith in technology. It's faith in people.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["whatever", "one"],
    words: ["whatever", "you", "are", "be", "a", "good", "one"],
    id: "Whatever you are, be a good one.",
    author: "William Makepeace Thackeray",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["respect", "owe"],
    words: ["respect", "is", "what", "we", "owe", "love", "give"],
    id: "Respect is what we owe; love, what we give.",
    author: "Philip James Bailey",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["wished", "loved"],
    words: ["if", "you", "wished", "to", "be", "loved", "love"],
    id: "If you wished to be loved, love.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["patience", "will"],
    words: ["he", "that", "can", "have", "patience", "what", "will"],
    id: "He that can have patience can have what he will.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["humble", "great"],
    words: ["don't", "be", "humble", "you're", "not", "that", "great"],
    id: "Don't be humble... you're not that great.",
    author: "Golda Meir",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["palestinian", "life"],
    words: ["palestinian", "people", "are", "in", "love", "with", "life"],
    id: "Palestinian people are in love with life.",
    author: "Mahmoud Darwish",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["all", "takes"],
    words: ["love", "is", "all", "it", "gives", "and", "takes"],
    id: "Love is all, it gives all, and it takes all.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["life", "miss"],
    words: ["love", "is", "life", "and", "if", "you", "miss"],
    id: "Love is life. And if you miss love, you miss life.",
    author: "Leo Buscaglia",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "can't"],
    words: ["i", "am", "for", "people", "can't", "help", "it"],
    id: "I am for people. I can't help it.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["baseball", "been"],
    words: ["baseball", "has", "been", "very", "good", "to", "me"],
    id: "Baseball has been very good to me.",
    author: "Roberto Clemente",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["happiness", "there"],
    words: ["happiness", "no", "it's", "not", "there", "for", "me"],
    id: "Happiness? No, it's not there for me.",
    author: "Robert Mapplethorpe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["energy", "life"],
    words: ["love", "is", "energy", "of", "life"],
    id: "Love is energy of life.",
    author: "Robert Browning",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["life", "liver"],
    words: ["life", "loves", "the", "liver", "of", "it"],
    id: "Life loves the liver of it.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["great", "beautifier"],
    words: ["love", "is", "a", "great", "beautifier"],
    id: "Love is a great beautifier.",
    author: "Louisa May Alcott",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["there", "dignity"],
    words: ["where", "there", "is", "no", "work", "dignity"],
    id: "Where there is no work, there is no dignity.",
    author: "Pope Francis",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["old", "days"],
    words: ["the", "good", "old", "days", "are", "now"],
    id: "The good old days are now.",
    author: "Tom Clancy",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["advise", "compel"],
    words: ["to", "advise", "is", "not", "compel"],
    id: "To advise is not to compel.",
    author: "Anton Chekhov",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["only", "gold"],
    words: ["love", "is", "the", "only", "gold"],
    id: "Love is the only gold.",
    author: "Alfred Lord Tennyson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["words", "fools"],
    words: ["words", "are", "the", "money", "of", "fools"],
    id: "Words are the money of fools.",
    author: "Thomas Hobbes",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["substitute", "knowledge"],
    words: ["the", "substitute", "for", "knowledge", "is", "money"],
    id: "The substitute for knowledge is money.",
    author: "Gordon Bethune",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["sun", "each"],
    words: ["the", "sun", "is", "new", "each", "day"],
    id: "The sun is new each day.",
    author: "Heraclitus",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["always", "listening"],
    words: ["always", "be", "listening", "and", "learning"],
    id: "Always be listening and learning.",
    author: "Adena Friedman",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["friendship", "sheltering"],
    words: ["friendship", "is", "a", "sheltering", "tree"],
    id: "Friendship is a sheltering tree.",
    author: "Samuel Taylor Coleridge",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["keeps", "goals"],
    words: ["what", "keeps", "me", "going", "is", "goals"],
    id: "What keeps me going is goals.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["health", "important"],
    words: ["health", "is", "very", "important", "to", "me"],
    id: "Health is very important to me.",
    author: "Niki Taylor",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["everything", "experience"],
    words: ["everything", "is", "a", "learning", "experience"],
    id: "Everything is a learning experience.",
    author: "Angus T. Jones",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["succeed", "because"],
    words: ["they", "succeed", "because", "think", "can"],
    id: "They succeed, because they think they can.",
    author: "Virgil",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["endless", "process"],
    words: ["learning", "is", "an", "endless", "process"],
    id: "Learning is an endless process.",
    author: "Fala Chen",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["forget", "yourself"],
    words: ["don't", "forget", "to", "love", "yourself"],
    id: "Don't forget to love yourself.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["all", "each"],
    words: ["we", "all", "need", "each", "other"],
    id: "We all need each other.",
    author: "Leo Buscaglia",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["will", "blood"],
    words: ["it", "will", "have", "blood", "they", "say"],
    id: "It will have blood, they say; blood will have blood.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["having", "getting"],
    words: ["it's", "not", "the", "having", "getting"],
    id: "It's not the having, it's the getting.",
    author: "Elizabeth Taylor",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["make", "happen"],
    words: ["you", "have", "to", "make", "it", "happen"],
    id: "You have to make it happen.",
    author: "Denis Diderot",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["better", "enemy"],
    words: ["better", "is", "the", "enemy", "of", "good"],
    id: "Better is the enemy of good.",
    author: "Voltaire",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["friendship", "oil"],
    words: ["friendship", "and", "money", "oil", "water"],
    id: "Friendship and money: oil and water.",
    author: "Mario Puzo",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["reciprocal", "torture"],
    words: ["love", "is", "a", "reciprocal", "torture"],
    id: "Love is a reciprocal torture.",
    author: "Marcel Proust",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["when", "madness"],
    words: ["when", "love", "is", "not", "madness", "it"],
    id: "When love is not madness, it is not love.",
    author: "Pedro Calderon de la Barca",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["lady", "turning"],
    words: ["this", "lady", "is", "not", "for", "turning"],
    id: "This lady is not for turning.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["guarantee", "success"],
    words: ["money", "does", "not", "guarantee", "success"],
    id: "Money does not guarantee success.",
    author: "Jose Mourinho",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["french", "cars"],
    words: ["who", "buys", "french", "cars", "not", "me"],
    id: "Who buys French cars? Not me.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["god", "details"],
    words: ["god", "is", "in", "the", "details"],
    id: "God is in the details.",
    author: "Ludwig Mies van der Rohe",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["friendship", "sacred"],
    words: ["friendship", "to", "me", "is", "sacred"],
    id: "Friendship to me is sacred.",
    author: "Ramya Krishnan",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["all", "things"],
    words: ["do", "all", "things", "with", "love"],
    id: "Do all things with love.",
    author: "Og Mandino",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["universe", "aren't"],
    words: ["you", "are", "the", "universe", "aren't", "in"],
    id: "You are the universe, you aren't in the universe.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["much", "mistaken"],
    words: ["he", "that", "speaks", "much", "is", "mistaken"],
    id: "He that speaks much, is much mistaken.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["voice", "humbug"],
    words: ["the", "voice", "of", "people", "is", "humbug"],
    id: "The voice of the people is the voice of humbug.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["state", "being"],
    words: ["love", "is", "a", "state", "of", "being"],
    id: "Love is a state of being.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["grace", "forgiveness"],
    words: ["we", "need", "grace", "and", "forgiveness"],
    id: "We need Grace and forgiveness.",
    author: "Pope Benedict XVI",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["factory", "machine"],
    words: ["the", "factory", "is", "machine", "that", "builds"],
    id: "The factory is the machine that builds the machine.",
    author: "Elon Musk",
    grammar: null,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["relax", "life"],
    words: ["relax", "life", "is", "beautiful"],
    id: "Relax! Life is beautiful!",
    author: "David L. Wolper",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["there", "life"],
    words: ["where", "there", "is", "love", "life"],
    id: "Where there is love there is life.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["never", "up"],
    words: ["never", "give", "in", "and", "up"],
    id: "Never give in and never give up.",
    author: "Hubert H. Humphrey",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["cleverness", "wisdom"],
    words: ["cleverness", "is", "not", "wisdom"],
    id: "Cleverness is not wisdom.",
    author: "Euripides",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["when", "doubt"],
    words: ["when", "in", "doubt", "don't"],
    id: "When in doubt, don't.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["things", "change"],
    words: ["things", "do", "not", "change", "we"],
    id: "Things do not change; we change.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "through"],
    words: ["if", "i", "am", "through", "learning"],
    id: "If I am through learning, I am through.",
    author: "John Wooden",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["pray", "god"],
    words: ["pray", "and", "let", "god", "worry"],
    id: "Pray, and let God worry.",
    author: "Martin Luther",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["football", "unconditional"],
    words: ["football", "is", "unconditional", "love"],
    id: "Football is unconditional love.",
    author: "Tom Brady",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["metaphysical", "gravity"],
    words: ["love", "is", "metaphysical", "gravity"],
    id: "Love is metaphysical gravity.",
    author: "R. Buckminster Fuller",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["movies", "subjective"],
    words: ["movies", "are", "very", "subjective"],
    id: "Movies are very subjective.",
    author: "Jeff Bridges",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["liberation", "deliverance"],
    words: ["liberation", "is", "not", "deliverance"],
    id: "Liberation is not deliverance.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["never", "motivates"],
    words: ["you", "never", "know", "what", "motivates"],
    id: "You never know what motivates you.",
    author: "Cicely Tyson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["beware", "hobby"],
    words: ["beware", "the", "hobby", "that", "eats"],
    id: "Beware the hobby that eats.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["man's", "gift"],
    words: ["tomorrow", "is", "no", "man's", "gift"],
    id: "Tomorrow is no man's gift.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["change", "before"],
    words: ["change", "before", "you", "have", "to"],
    id: "Change before you have to.",
    author: "Jack Welch",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["i", "still"],
    words: ["i", "am", "still", "learning"],
    id: "I am still learning.",
    author: "Michelangelo",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["conquer", "believe"],
    words: ["they", "can", "conquer", "who", "believe"],
    id: "They can conquer who believe they can.",
    author: "Virgil",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["story", "counts"],
    words: ["it's", "the", "story", "that", "counts"],
    id: "It's the story that counts.",
    author: "Vincente Minnelli",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["remember", "credit"],
    words: ["remember", "that", "credit", "is", "money"],
    id: "Remember that credit is money.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["always", "believing"],
    words: ["seeing", "is", "not", "always", "believing"],
    id: "Seeing is not always believing.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["cinema", "happiness"],
    words: ["for", "me", "cinema", "is", "happiness"],
    id: "For me, cinema is happiness.",
    author: "Rajkumar Hirani",
    grammar: null,
    word_count: 5,
    all_intersection_count: 3,
    new_words_count: 2,
  },
  {
    row_new_words: ["ultimately", "everything"],
    words: ["ultimately", "love", "is", "everything"],
    id: "Ultimately love is everything.",
    author: "M. Scott Peck",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["cannot", "happiness"],
    words: ["money", "cannot", "buy", "happiness"],
    id: "Money cannot buy happiness.",
    author: "Frida Lyngstad",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["love's", "reward"],
    words: ["love", "is", "love's", "reward"],
    id: "Love is love's reward.",
    author: "John Dryden",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["change", "indecision"],
    words: ["change", "is", "not", "indecision"],
    id: "Change is not indecision.",
    author: "Chris Ofili",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["football", "patience"],
    words: ["football", "has", "no", "patience"],
    id: "Football has no patience.",
    author: "Marquinhos",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["how", "sweet"],
    words: ["how", "sweet", "it", "is"],
    id: "How sweet it is!",
    author: "Jackie Gleason",
    grammar: null,
    word_count: 4,
    all_intersection_count: 2,
    new_words_count: 2,
  },
  {
    row_new_words: ["modesty", "color", "virtue"],
    words: ["modesty", "is", "the", "color", "of", "virtue"],
    id: "Modesty is the color of virtue.",
    author: "Diogenes",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["everything", "change", "heartbeat"],
    words: ["everything", "can", "change", "in", "a", "heartbeat"],
    id: "Everything can change in a heartbeat.",
    author: "Travis Pastrana",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["politics", "relation", "morals"],
    words: ["politics", "have", "no", "relation", "to", "morals"],
    id: "Politics have no relation to morals.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["blockheads", "read", "wrote"],
    words: ["let", "blockheads", "read", "what", "wrote"],
    id: "Let blockheads read what blockheads wrote.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["success", "sweetest", "revenge"],
    words: ["success", "is", "the", "sweetest", "revenge"],
    id: "Success is the sweetest revenge.",
    author: "Vanessa Williams",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["secret", "humor", "surprise"],
    words: ["the", "secret", "to", "humor", "is", "surprise"],
    id: "The secret to humor is surprise.",
    author: "Aristotle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["devil's", "name", "dullness"],
    words: ["the", "devil's", "name", "is", "dullness"],
    id: "The devil's name is dullness.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["much", "teach", "understanding"],
    words: ["much", "learning", "does", "not", "teach", "understanding"],
    id: "Much learning does not teach understanding.",
    author: "Heraclitus",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["abandon", "marriage", "family"],
    words: ["if", "we", "abandon", "marriage", "the", "family"],
    id: "If we abandon marriage, we abandon the family.",
    author: "Michael Enzi",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["forgive", "those", "hurt"],
    words: ["forgive", "those", "who", "have", "hurt", "you"],
    id: "Forgive those who have hurt you.",
    author: "Les Brown",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "passionate", "life"],
    words: ["i'm", "passionate", "about", "learning", "life"],
    id: "I'm passionate about learning. I'm passionate about life.",
    author: "Tom Cruise",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["morality", "herd-instinct", "individual"],
    words: ["morality", "is", "the", "herd-instinct", "in", "individual"],
    id: "Morality is the herd-instinct in the individual.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["describe", "happiness", "diminish"],
    words: ["to", "describe", "happiness", "is", "diminish", "it"],
    id: "To describe happiness is to diminish it.",
    author: "Stendhal",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["fight", "problem", "decide"],
    words: ["don't", "fight", "the", "problem", "decide", "it"],
    id: "Don't fight the problem, decide it.",
    author: "George C. Marshall",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["american", "dream", "freedom"],
    words: ["the", "american", "dream", "is", "about", "freedom"],
    id: "The American dream is about freedom.",
    author: "Nancy Pelosi",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["friendship", "something", "cultivated"],
    words: ["friendship", "is", "something", "that", "cultivated"],
    id: "Friendship is something that is cultivated.",
    author: "Thalia",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["ain't", "over", "till"],
    words: ["it", "ain't", "over", "till", "it's"],
    id: "It ain't over till it's over.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["chief", "never", "lord"],
    words: ["be", "the", "chief", "but", "never", "lord"],
    id: "Be the chief but never the lord.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["non-violence", "article", "faith"],
    words: ["non-violence", "is", "the", "article", "of", "faith"],
    id: "Non-violence is the article of faith.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["family", "nucleus", "civilization"],
    words: ["the", "family", "is", "nucleus", "of", "civilization"],
    id: "The family is the nucleus of civilization.",
    author: "Will Durant",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "darkness", "ignorance"],
    words: ["there", "is", "no", "darkness", "but", "ignorance"],
    id: "There is no darkness but ignorance.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["having", "nothing", "lose"],
    words: ["having", "nothing", "can", "he", "lose"],
    id: "Having nothing, nothing can he lose.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["courage", "kind", "salvation"],
    words: ["courage", "is", "a", "kind", "of", "salvation"],
    id: "Courage is a kind of salvation.",
    author: "Plato",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["act", "enthusiastic", "will"],
    words: ["act", "enthusiastic", "and", "you", "will", "be"],
    id: "Act enthusiastic and you will be enthusiastic.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["looking", "best", "revenge"],
    words: ["looking", "good", "is", "the", "best", "revenge"],
    id: "Looking good is the best revenge.",
    author: "Ivana Trump",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["patience", "art", "hoping"],
    words: ["patience", "is", "the", "art", "of", "hoping"],
    id: "Patience is the art of hoping.",
    author: "Luc de Clapiers",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "struggle", "progress"],
    words: ["if", "there", "is", "no", "struggle", "progress"],
    id: "If there is no struggle, there is no progress.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["success", "journey", "destination"],
    words: ["success", "is", "a", "journey", "not", "destination"],
    id: "Success is a journey, not a destination.",
    author: "Ben Sweetland",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["shut", "up", "sometimes"],
    words: ["it's", "good", "to", "shut", "up", "sometimes"],
    id: "It's good to shut up sometimes.",
    author: "Marcel Marceau",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "watch", "movies"],
    words: ["i", "love", "to", "watch", "movies"],
    id: "I love to watch movies.",
    author: "Judy Blume",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["fall", "forward", "succeed"],
    words: ["we", "fall", "forward", "to", "succeed"],
    id: "We fall forward to succeed.",
    author: "Mary Kay Ash",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["failure", "knowledge", "success"],
    words: ["failure", "is", "knowledge", "and", "success"],
    id: "Failure is knowledge, and knowledge is success.",
    author: "Mahira Khan",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["patience", "best", "medicine"],
    words: ["patience", "is", "the", "best", "medicine"],
    id: "Patience is the best medicine.",
    author: "John Florio",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["responsibility", "price", "freedom"],
    words: ["responsibility", "is", "the", "price", "of", "freedom"],
    id: "Responsibility is the price of freedom.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["freedom", "recognition", "necessity"],
    words: ["freedom", "is", "the", "recognition", "of", "necessity"],
    id: "Freedom is the recognition of necessity.",
    author: "Friedrich Engels",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["one", "chance", "all"],
    words: ["one", "chance", "is", "all", "you", "need"],
    id: "One chance is all you need.",
    author: "Jesse Owens",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["smallest", "feline", "masterpiece"],
    words: ["the", "smallest", "feline", "is", "a", "masterpiece"],
    id: "The smallest feline is a masterpiece.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["beauty", "promise", "happiness"],
    words: ["beauty", "is", "the", "promise", "of", "happiness"],
    id: "Beauty is the promise of happiness.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["world", "mud-luscious", "puddle-wonderful"],
    words: ["the", "world", "is", "mud-luscious", "and", "puddle-wonderful"],
    id: "The world is mud-luscious and puddle-wonderful.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["unnatural", "too", "natural"],
    words: ["the", "unnatural", "that", "too", "is", "natural"],
    id: "The unnatural, that too is natural.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["past", "cannot", "cured"],
    words: ["the", "past", "cannot", "be", "cured"],
    id: "The past cannot be cured.",
    author: "Elizabeth I",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["goal", "dream", "deadline"],
    words: ["a", "goal", "is", "dream", "with", "deadline"],
    id: "A goal is a dream with a deadline.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "longing", "repetition"],
    words: ["happiness", "is", "the", "longing", "for", "repetition"],
    id: "Happiness is the longing for repetition.",
    author: "Milan Kundera",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["knowledge", "life", "mind"],
    words: ["knowledge", "is", "the", "life", "of", "mind"],
    id: "Knowledge is the life of the mind.",
    author: "Abu Bakr",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["wisdom", "sacred", "communion"],
    words: ["wisdom", "is", "a", "sacred", "communion"],
    id: "Wisdom is a sacred communion.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["god", "perfect", "poet"],
    words: ["god", "is", "the", "perfect", "poet"],
    id: "God is the perfect poet.",
    author: "Robert Browning",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["those", "knowledge", "predict"],
    words: ["those", "who", "have", "knowledge", "don't", "predict"],
    id: "Those who have knowledge, don't predict. Those who predict, don't have knowledge.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["friends", "born", "made"],
    words: ["friends", "are", "born", "not", "made"],
    id: "Friends are born, not made.",
    author: "Henry Adams",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["intellectual", "hatred", "worst"],
    words: ["an", "intellectual", "hatred", "is", "the", "worst"],
    id: "An intellectual hatred is the worst.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["blind", "lover's", "eyes"],
    words: ["who", "can", "blind", "lover's", "eyes"],
    id: "Who can blind lover's eyes?",
    author: "Virgil",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["secrecy", "chastity", "friendship"],
    words: ["secrecy", "is", "the", "chastity", "of", "friendship"],
    id: "Secrecy is the chastity of friendship.",
    author: "Jeremy Taylor",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["engaging", "ignorance", "wisdom"],
    words: ["not", "engaging", "in", "ignorance", "is", "wisdom"],
    id: "Not engaging in ignorance is wisdom.",
    author: "Bodhidharma",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["right", "happiness", "fundamental"],
    words: ["the", "right", "to", "happiness", "is", "fundamental"],
    id: "The right to happiness is fundamental.",
    author: "Anna Pavlova",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["being", "stupid", "together"],
    words: ["love", "is", "being", "stupid", "together"],
    id: "Love is being stupid together.",
    author: "Paul Valery",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["fall", "forward", "success"],
    words: ["people", "fall", "forward", "to", "success"],
    id: "People fall forward to success.",
    author: "Mary Kay Ash",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["precondition", "freedom", "security"],
    words: ["the", "precondition", "to", "freedom", "is", "security"],
    id: "The precondition to freedom is security.",
    author: "Rand Beers",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["as", "shall", "become"],
    words: ["as", "you", "think", "so", "shall", "become"],
    id: "As you think, so shall you become.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["reason", "enemy", "faith"],
    words: ["reason", "is", "the", "enemy", "of", "faith"],
    id: "Reason is the enemy of faith.",
    author: "Martin Luther",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["friendship", "means", "everything"],
    words: ["friendship", "means", "everything", "to", "me"],
    id: "Friendship means everything to me.",
    author: "Jackky Bhagnani",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["without", "conversation", "impossible"],
    words: ["love", "without", "conversation", "is", "impossible"],
    id: "Love without conversation is impossible.",
    author: "Mortimer Adler",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "god", "also"],
    words: ["where", "love", "is", "there", "god", "also"],
    id: "Where love is, there God is also.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "consist", "self-love"],
    words: ["happiness", "does", "not", "consist", "in", "self-love"],
    id: "Happiness does not consist in self-love.",
    author: "Joseph Butler",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["filmmaking", "fine", "art"],
    words: ["filmmaking", "can", "be", "a", "fine", "art"],
    id: "Filmmaking can be a fine art.",
    author: "Terri Windling",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "property", "injustice"],
    words: ["where", "there", "is", "no", "property", "injustice"],
    id: "Where there is no property there is no injustice.",
    author: "John Locke",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["first", "wealth", "health"],
    words: ["the", "first", "wealth", "is", "health"],
    id: "The first wealth is health.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["silence", "criticism", "freedom"],
    words: ["to", "silence", "criticism", "is", "freedom"],
    id: "To silence criticism is to silence freedom.",
    author: "Sidney Hook",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["war", "defeat", "humanity"],
    words: ["war", "is", "a", "defeat", "for", "humanity"],
    id: "War is a defeat for humanity.",
    author: "Pope John Paul II",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["forgiven", "usually", "remembered"],
    words: ["what", "is", "forgiven", "usually", "well", "remembered"],
    id: "What is forgiven is usually well remembered.",
    author: "Louis Dudek",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["liar", "should", "memory"],
    words: ["a", "liar", "should", "have", "good", "memory"],
    id: "A liar should have a good memory.",
    author: "Quintilian",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["such", "man", "trusted"],
    words: ["let", "no", "such", "man", "be", "trusted"],
    id: "Let no such man be trusted.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["hungry", "man", "free"],
    words: ["a", "hungry", "man", "is", "not", "free"],
    id: "A hungry man is not a free man.",
    author: "Adlai Stevenson I",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["business", "yourself", "by"],
    words: ["in", "business", "for", "yourself", "not", "by"],
    id: "In business for yourself, not by yourself.",
    author: "William James",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["speed", "boss", "team"],
    words: ["the", "speed", "of", "boss", "is", "team"],
    id: "The speed of the boss is the speed of the team.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["words", "lot", "power"],
    words: ["words", "have", "a", "lot", "of", "power"],
    id: "Words have a lot of power.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["religion", "opium", "masses"],
    words: ["religion", "is", "the", "opium", "of", "masses"],
    id: "Religion is the opium of the masses.",
    author: "Karl Marx",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["perplexity", "beginning", "knowledge"],
    words: ["perplexity", "is", "the", "beginning", "of", "knowledge"],
    id: "Perplexity is the beginning of knowledge.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["those", "understand", "teach"],
    words: ["those", "that", "know", "do", "understand", "teach"],
    id: "Those that know, do. Those that understand, teach.",
    author: "Aristotle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["once", "done", "forever"],
    words: ["what", "is", "once", "well", "done", "forever"],
    id: "What is once well done is done forever.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["taking", "risks", "energy"],
    words: ["taking", "risks", "gives", "me", "energy"],
    id: "Taking risks gives me energy.",
    author: "Jay Chiat",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "before", "forced"],
    words: ["change", "before", "you're", "forced", "to"],
    id: "Change before you're forced to change.",
    author: "Roger Goodell",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["woman's", "health", "capital"],
    words: ["a", "woman's", "health", "is", "her", "capital"],
    id: "A woman's health is her capital.",
    author: "Harriet Beecher Stowe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["noblest", "search", "excellence"],
    words: ["the", "noblest", "search", "is", "for", "excellence"],
    id: "The noblest search is the search for excellence.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["family", "foundation", "everything"],
    words: ["the", "family", "is", "foundation", "of", "everything"],
    id: "The family is the foundation of everything.",
    author: "Robinho",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["when", "through", "changing"],
    words: ["when", "you", "are", "through", "changing"],
    id: "When you are through changing, you are through.",
    author: "Bruce Barton",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["diligence", "bring", "luck"],
    words: ["care", "and", "diligence", "bring", "luck"],
    id: "Care and diligence bring luck.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["america", "health", "insurance"],
    words: ["america", "doesn't", "have", "health", "insurance"],
    id: "America doesn't have health insurance.",
    author: "Joe Biden",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["freedom", "expression", "important"],
    words: ["freedom", "of", "expression", "is", "very", "important"],
    id: "Freedom of expression is very important.",
    author: "Rodrigo Duterte",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["poverty", "mother", "crime"],
    words: ["poverty", "is", "the", "mother", "of", "crime"],
    id: "Poverty is the mother of crime.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["uncut", "hair", "graves"],
    words: ["the", "beautiful", "uncut", "hair", "of", "graves"],
    id: "The beautiful uncut hair of graves.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["knowledge", "light", "vision"],
    words: ["knowledge", "is", "love", "and", "light", "vision"],
    id: "Knowledge is love and light and vision.",
    author: "Helen Keller",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["nothing", "will", "unless"],
    words: ["nothing", "will", "work", "unless", "you", "do"],
    id: "Nothing will work unless you do.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "horizontal", "fall"],
    words: ["life", "is", "a", "horizontal", "fall"],
    id: "Life is a horizontal fall.",
    author: "Jean Cocteau",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["friend", "all", "none"],
    words: ["a", "friend", "to", "all", "is", "none"],
    id: "A friend to all is a friend to none.",
    author: "Aristotle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["ratings", "last", "journalism"],
    words: ["ratings", "don't", "last", "good", "journalism", "does"],
    id: "Ratings don't last. Good journalism does.",
    author: "Dan Rather",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["simplicity", "glory", "expression"],
    words: ["simplicity", "is", "the", "glory", "of", "expression"],
    id: "Simplicity is the glory of expression.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["luck", "chance", "happen"],
    words: ["give", "luck", "a", "chance", "to", "happen"],
    id: "Give luck a chance to happen.",
    author: "Tom Kite",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "jack", "lemmon"],
    words: ["happiness", "is", "working", "with", "jack", "lemmon"],
    id: "Happiness is working with Jack Lemmon.",
    author: "Billy Wilder",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["remember", "i", "human"],
    words: ["remember", "that", "i", "am", "a", "human"],
    id: "Remember that I am a human.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["patience", "virtue", "grace"],
    words: ["patience", "is", "a", "virtue", "grace"],
    id: "Patience is a virtue; virtue is a grace.",
    author: "Jacob Rees-Mogg",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["little", "man", "still"],
    words: ["the", "little", "man", "is", "still", "a"],
    id: "The little man is still a man.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "god's", "hand"],
    words: ["life", "is", "in", "god's", "hand"],
    id: "Life is in God's hand.",
    author: "John Piper",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["democracy", "road", "socialism"],
    words: ["democracy", "is", "the", "road", "to", "socialism"],
    id: "Democracy is the road to socialism.",
    author: "Karl Marx",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["makes", "time", "pass"],
    words: ["love", "makes", "the", "time", "pass"],
    id: "Love makes the time pass. Time makes love pass.",
    author: "Euripides",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "such", "hard"],
    words: ["change", "is", "such", "hard", "work"],
    id: "Change is such hard work.",
    author: "Billy Crystal",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["nature", "art", "god"],
    words: ["nature", "is", "the", "art", "of", "god"],
    id: "Nature is the art of God.",
    author: "Dante Alighieri",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["music", "shorthand", "emotion"],
    words: ["music", "is", "the", "shorthand", "of", "emotion"],
    id: "Music is the shorthand of emotion.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["movies", "expensive", "business"],
    words: ["movies", "are", "an", "expensive", "business"],
    id: "Movies are an expensive business.",
    author: "Albert Brooks",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["will", "never", "change"],
    words: ["some", "people", "will", "never", "change"],
    id: "Some people will never change.",
    author: "Penelope Wilton",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["luck", "its", "storms"],
    words: ["good", "luck", "has", "its", "storms"],
    id: "Good luck has its storms.",
    author: "George Lucas",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["admiration", "daughter", "ignorance"],
    words: ["admiration", "is", "the", "daughter", "of", "ignorance"],
    id: "Admiration is the daughter of ignorance.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "simple", "minimal"],
    words: ["happiness", "is", "very", "simple", "and", "minimal"],
    id: "Happiness is very simple and minimal.",
    author: "Tablo",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["freedom", "oxygen", "soul"],
    words: ["freedom", "is", "the", "oxygen", "of", "soul"],
    id: "Freedom is the oxygen of the soul.",
    author: "Moshe Dayan",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["what's", "dangerous", "evolve"],
    words: ["what's", "dangerous", "is", "not", "to", "evolve"],
    id: "What's dangerous is not to evolve.",
    author: "Jeff Bezos",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["liberty", "worth", "paying"],
    words: ["liberty", "is", "worth", "paying", "for"],
    id: "Liberty is worth paying for.",
    author: "Jules Verne",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "always", "i"],
    words: ["i'm", "always", "learning", "i", "have", "to"],
    id: "I'm always learning. I have to.",
    author: "Neymar",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["everything", "life", "luck"],
    words: ["everything", "in", "life", "is", "luck"],
    id: "Everything in life is luck.",
    author: "Donald Trump",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["medici", "created", "destroyed"],
    words: ["the", "medici", "created", "and", "destroyed", "me"],
    id: "The Medici created and destroyed me.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["brevity", "soul", "wit"],
    words: ["brevity", "is", "the", "soul", "of", "wit"],
    id: "Brevity is the soul of wit.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["passion", "momentary", "enduring"],
    words: ["passion", "is", "momentary", "love", "enduring"],
    id: "Passion is momentary; love is enduring.",
    author: "John Wooden",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["superman", "seat", "belt"],
    words: ["superman", "don't", "need", "no", "seat", "belt"],
    id: "Superman don't need no seat belt.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "make", "movies"],
    words: ["i", "make", "movies", "want", "to", "see"],
    id: "I make movies I want to see.",
    author: "Neil LaBute",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["never", "regret", "write"],
    words: ["never", "regret", "what", "you", "don't", "write"],
    id: "Never regret what you don't write.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "pets", "especially"],
    words: ["i", "love", "pets", "especially", "dogs"],
    id: "I love pets, especially dogs.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["washington", "adult", "supervision"],
    words: ["what", "washington", "needs", "is", "adult", "supervision"],
    id: "What Washington needs is adult supervision.",
    author: "Barack Obama",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["prefers", "twilight", "daylight"],
    words: ["love", "prefers", "twilight", "to", "daylight"],
    id: "Love prefers twilight to daylight.",
    author: "Oliver Wendell Holmes, Sr.",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["simple", "jiggles", "fat"],
    words: ["it's", "simple", "if", "it", "jiggles", "fat"],
    id: "It's simple, if it jiggles, it's fat.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "americans", "patriotic"],
    words: ["i", "think", "americans", "are", "very", "patriotic"],
    id: "I think Americans are very patriotic.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["create", "something", "must"],
    words: ["to", "create", "something", "you", "must", "be"],
    id: "To create something you must be something.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["revolutions", "locomotives", "history"],
    words: ["revolutions", "are", "the", "locomotives", "of", "history"],
    id: "Revolutions are the locomotives of history.",
    author: "Karl Marx",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "anguished", "tormented"],
    words: ["no", "i'm", "not", "anguished", "and", "tormented"],
    id: "No, I'm not anguished and tormented.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["you'll", "when", "believe"],
    words: ["you'll", "see", "it", "when", "you", "believe"],
    id: "You'll see it when you believe it.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["free", "men", "strongest"],
    words: ["free", "men", "are", "the", "strongest"],
    id: "Free men are the strongest men.",
    author: "Wendell Willkie",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["loss", "life", "terrible"],
    words: ["every", "loss", "of", "life", "is", "terrible"],
    id: "Every loss of life is terrible.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "imagination", "horror"],
    words: ["where", "there", "is", "no", "imagination", "horror"],
    id: "Where there is no imagination there is no horror.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "my", "family"],
    words: ["i", "am", "my", "family", "love"],
    id: "I am my family. I love my family.",
    author: "Ray J",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "sell", "soul"],
    words: ["to", "buy", "happiness", "is", "sell", "soul"],
    id: "To buy happiness is to sell soul.",
    author: "Douglas Horton",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["wisest", "most", "authority"],
    words: ["the", "wisest", "have", "most", "authority"],
    id: "The wisest have the most authority.",
    author: "Plato",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["empires", "future", "mind"],
    words: ["the", "empires", "of", "future", "are", "mind"],
    id: "The empires of the future are the empires of the mind.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "wealth", "life"],
    words: ["there", "is", "no", "wealth", "but", "life"],
    id: "There is no wealth but life.",
    author: "John Ruskin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["usually", "attracted", "pursued"],
    words: ["money", "is", "usually", "attracted", "not", "pursued"],
    id: "Money is usually attracted, not pursued.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["through", "life", "grow"],
    words: ["don't", "go", "through", "life", "grow"],
    id: "Don't go through life, grow through life.",
    author: "Eric Butterworth",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["philosophy", "highest", "music"],
    words: ["philosophy", "is", "the", "highest", "music"],
    id: "Philosophy is the highest music.",
    author: "Plato",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["listen", "many", "few"],
    words: ["listen", "to", "many", "speak", "a", "few"],
    id: "Listen to many, speak to a few.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["never", "wound", "kill"],
    words: ["never", "wound", "a", "snake", "kill", "it"],
    id: "Never wound a snake; kill it.",
    author: "Harriet Tubman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["live", "by", "die"],
    words: ["what", "we", "live", "by", "die"],
    id: "What we live by we die by.",
    author: "Robert Frost",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["hermits", "peer", "pressure"],
    words: ["hermits", "have", "no", "peer", "pressure"],
    id: "Hermits have no peer pressure.",
    author: "Steven Wright",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "all", "time"],
    words: ["people", "change", "all", "the", "time"],
    id: "People change all the time.",
    author: "Tony Shalhoub",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["battle", "orgy", "disorder"],
    words: ["battle", "is", "an", "orgy", "of", "disorder"],
    id: "Battle is an orgy of disorder.",
    author: "George S. Patton",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["body", "sacred", "garment"],
    words: ["the", "body", "is", "a", "sacred", "garment"],
    id: "The body is a sacred garment.",
    author: "Martha Graham",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "nothing", "will"],
    words: ["if", "you", "change", "nothing", "will"],
    id: "If you change nothing, nothing will change.",
    author: "Rajkummar Rao",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["necessity", "mother", "invention"],
    words: ["necessity", "the", "mother", "of", "invention"],
    id: "Necessity... the mother of invention.",
    author: "Plato",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["children", "keys", "paradise"],
    words: ["children", "are", "the", "keys", "of", "paradise"],
    id: "Children are the keys of paradise.",
    author: "Eric Hoffer",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["sweet", "honest", "always"],
    words: ["be", "sweet", "good", "and", "honest", "always"],
    id: "Be sweet, be good, and honest always.",
    author: "Emma Bunton",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["blushing", "color", "virtue"],
    words: ["blushing", "is", "the", "color", "of", "virtue"],
    id: "Blushing is the color of virtue.",
    author: "Diogenes",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["ain't", "heat", "humility"],
    words: ["it", "ain't", "the", "heat", "it's", "humility"],
    id: "It ain't the heat, it's the humility.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["modesty", "conscience", "body"],
    words: ["modesty", "is", "the", "conscience", "of", "body"],
    id: "Modesty is the conscience of the body.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["free", "best", "anything"],
    words: ["free", "is", "the", "best", "anything", "good"],
    id: "Free is the best. Anything free is good.",
    author: "Sandra Bullock",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "takes", "all"],
    words: ["time", "takes", "all", "and", "gives"],
    id: "Time takes all and gives all.",
    author: "Giordano Bruno",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["lead", "walk", "behind"],
    words: ["to", "lead", "people", "walk", "behind", "them"],
    id: "To lead people walk behind them.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["leisure", "mother", "philosophy"],
    words: ["leisure", "is", "the", "mother", "of", "philosophy"],
    id: "Leisure is the Mother of Philosophy.",
    author: "Thomas Hobbes",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "powerful", "contagious"],
    words: ["happiness", "is", "very", "powerful", "and", "contagious"],
    id: "Happiness is very powerful and contagious.",
    author: "Ant Anstead",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["worst", "crime", "faking"],
    words: ["the", "worst", "crime", "is", "faking", "it"],
    id: "The worst crime is faking it.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["mob", "mother", "tyrants"],
    words: ["the", "mob", "is", "mother", "of", "tyrants"],
    id: "The mob is the mother of tyrants.",
    author: "Diogenes",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["stops", "being", "better"],
    words: ["he", "who", "stops", "being", "better", "good"],
    id: "He who stops being better stops being good.",
    author: "Oliver Cromwell",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["education", "problem", "opportunity"],
    words: ["education", "is", "not", "a", "problem", "an", "opportunity"],
    id: "Education is not a problem. Education is an opportunity.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["curiosity", "lying", "secret"],
    words: ["curiosity", "is", "lying", "in", "wait", "for", "every", "secret"],
    id: "Curiosity is lying in wait for every secret.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["course", "i", "everything"],
    words: ["of", "course", "i", "do", "everything", "for", "money"],
    id: "Of course, I do everything for money.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["american", "become", "heartless"],
    words: ["the", "american", "people", "have", "not", "become", "heartless"],
    id: "The American people have not become heartless.",
    author: "Joe Biden",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["live", "rainbow", "chaos"],
    words: ["we", "live", "in", "a", "rainbow", "of", "chaos"],
    id: "We live in a rainbow of chaos.",
    author: "Paul Cezanne",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["mediator", "inexpressible", "art"],
    words: ["the", "mediator", "of", "inexpressible", "is", "work", "art"],
    id: "The mediator of the inexpressible is the work of art.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["age", "limit", "death"],
    words: ["love", "has", "no", "age", "limit", "and", "death"],
    id: "Love has no age, no limit; and no death.",
    author: "John Galsworthy",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["each", "scholar", "yesterday"],
    words: ["each", "day", "is", "the", "scholar", "of", "yesterday"],
    id: "Each day is the scholar of yesterday.",
    author: "Publilius Syrus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["word", "wise", "infuriating"],
    words: ["a", "word", "to", "the", "wise", "is", "infuriating"],
    id: "A word to the wise is infuriating.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["costs", "win", "patience"],
    words: ["it", "costs", "to", "win", "you", "need", "patience"],
    id: "It costs to win, you need patience.",
    author: "Marcelo",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "person", "socializes"],
    words: ["i'm", "not", "a", "person", "that", "socializes", "very", "well"],
    id: "I'm not a person that socializes very well.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["wise", "most", "least"],
    words: ["who", "is", "wise", "in", "love", "most", "say", "least"],
    id: "Who is wise in love, love most, say least.",
    author: "Alfred Lord Tennyson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "free", "i"],
    words: ["you", "can", "only", "be", "free", "if", "i", "am"],
    id: "You can only be free if I am free.",
    author: "Clarence Darrow",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["supposed", "keep", "stagnate"],
    words: ["you're", "supposed", "to", "keep", "learning", "not", "stagnate"],
    id: "You're supposed to keep learning. You're not supposed to stagnate.",
    author: "Brad Stevens",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "natural", "duty"],
    words: ["happiness", "is", "the", "natural", "flower", "of", "duty"],
    id: "Happiness is the natural flower of duty.",
    author: "Phillips Brooks",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "lobby", "god"],
    words: ["i", "just", "want", "to", "lobby", "for", "god"],
    id: "I just want to lobby for God.",
    author: "Billy Graham",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["art", "best", "enough"],
    words: ["in", "art", "the", "best", "is", "good", "enough"],
    id: "In art the best is good enough.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["way", "sky", "heart"],
    words: ["the", "way", "is", "not", "in", "sky", "heart"],
    id: "The way is not in the sky. The way is in the heart.",
    author: "Unknown",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "conservative", "nut"],
    words: ["i'm", "conservative", "but", "not", "a", "nut", "about", "it"],
    id: "I'm conservative, but I'm not a nut about it.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["greatest", "pleasure", "life"],
    words: ["the", "greatest", "pleasure", "of", "life", "is", "love"],
    id: "The greatest pleasure of life is love.",
    author: "Euripides",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["picture", "equivalent", "deed"],
    words: ["a", "good", "picture", "is", "equivalent", "to", "deed"],
    id: "A good picture is equivalent to a good deed.",
    author: "Vincent Van Gogh",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["will", "i", "success"],
    words: ["will", "i", "be", "a", "success", "don't", "know"],
    id: "Will I be a success? I don't know.",
    author: "Pep Guardiola",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "abnormality", "incapacity"],
    words: ["the", "only", "abnormality", "is", "incapacity", "to", "love"],
    id: "The only abnormality is the incapacity to love.",
    author: "Anais Nin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["believe", "halfway", "there"],
    words: ["believe", "you", "can", "and", "you're", "halfway", "there"],
    id: "Believe you can and you're halfway there.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["freedom", "what's", "right"],
    words: ["we", "know", "what", "works", "freedom", "what's", "right", "is"],
    id: "We know what works. Freedom Works. We know what's right. Freedom is right.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "absence", "striving"],
    words: ["happiness", "is", "the", "absence", "of", "striving", "for"],
    id: "Happiness is the absence of the striving for happiness.",
    author: "Zhuangzi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["wisdom", "humble", "more"],
    words: ["wisdom", "is", "humble", "that", "he", "knows", "no", "more"],
    id: "Wisdom is humble that he knows no more.",
    author: "William Cowper",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["belief", "gets", "way"],
    words: ["belief", "gets", "in", "the", "way", "of", "learning"],
    id: "Belief gets in the way of learning.",
    author: "Jeremy Collier",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["lack", "knowledge", "problem"],
    words: ["lack", "of", "knowledge", "that", "is", "the", "problem"],
    id: "Lack of knowledge... that is the problem.",
    author: "W. Edwards Deming",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "tide", "man"],
    words: ["time", "and", "tide", "wait", "for", "no", "man"],
    id: "Time and tide wait for no man.",
    author: "Geoffrey Chaucer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "substitute", "hard"],
    words: ["there", "is", "no", "substitute", "for", "hard", "work"],
    id: "There is no substitute for hard work.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["patience", "nothing", "impossible"],
    words: ["with", "love", "and", "patience", "nothing", "is", "impossible"],
    id: "With love and patience, nothing is impossible.",
    author: "Daisaku Ikeda",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["none", "as", "all"],
    words: ["none", "of", "us", "is", "as", "good", "all"],
    id: "None of Us is as Good as All of Us.",
    author: "Ray Kroc",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["patience", "secret", "food"],
    words: ["patience", "is", "the", "secret", "to", "good", "food"],
    id: "Patience is the secret to good food.",
    author: "Gail Simmons",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["attempt", "deed", "confounds"],
    words: ["the", "attempt", "and", "not", "deed", "confounds", "us"],
    id: "The attempt and not the deed confounds us.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["freedom", "entitles", "something"],
    words: ["to", "me", "freedom", "entitles", "you", "do", "something", "not"],
    id: "To me, freedom entitles you to do something, not to not do something.",
    author: "Shel Silverstein",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["courage", "knowing", "fear"],
    words: ["courage", "is", "knowing", "what", "not", "to", "fear"],
    id: "Courage is knowing what not to fear.",
    author: "Plato",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["america", "gigantic", "mistake"],
    words: ["yes", "america", "is", "gigantic", "but", "a", "mistake"],
    id: "Yes, America is gigantic, but a gigantic mistake.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["man", "imagination", "wings"],
    words: ["the", "man", "who", "has", "no", "imagination", "wings"],
    id: "The man who has no imagination has no wings.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["live", "world", "when"],
    words: ["we", "live", "in", "the", "world", "when", "love", "it"],
    id: "We live in the world when we love it.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["beginning", "wisdom", "desire"],
    words: ["the", "beginning", "of", "wisdom", "is", "to", "desire", "it"],
    id: "The beginning of wisdom is to desire it.",
    author: "Solomon Ibn Gabirol",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["book", "read", "won't"],
    words: ["the", "book", "you", "don't", "read", "won't", "help"],
    id: "The book you don't read won't help.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["god", "burn", "church"],
    words: ["if", "you", "love", "god", "burn", "a", "church"],
    id: "If you love god, burn a church.",
    author: "Jello Biafra",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["kind", "fun", "impossible"],
    words: ["it's", "kind", "of", "fun", "to", "do", "the", "impossible"],
    id: "It's kind of fun to do the impossible.",
    author: "Walt Disney",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["health", "hope", "everything"],
    words: ["for", "he", "who", "has", "health", "hope", "and", "everything"],
    id: "For he who has health has hope; and he who has hope, has everything.",
    author: "Owen Arthur",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["year's", "man's", "birthday"],
    words: ["new", "year's", "day", "is", "every", "man's", "birthday"],
    id: "New Year's Day is every man's birthday.",
    author: "Charles Lamb",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["length", "life", "depth"],
    words: ["it", "is", "not", "length", "of", "life", "but", "depth"],
    id: "It is not length of life, but depth of life.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["wildness", "preservation", "world"],
    words: ["in", "wildness", "is", "the", "preservation", "of", "world"],
    id: "In wildness is the preservation of the world.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["commitment", "act", "word"],
    words: ["commitment", "is", "an", "act", "not", "a", "word"],
    id: "Commitment is an act, not a word.",
    author: "Jean-Paul Sartre",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["long", "time", "politics"],
    words: ["a", "week", "is", "long", "time", "in", "politics"],
    id: "A week is a long time in politics.",
    author: "Harold Wilson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["truthful", "words", "persuasive"],
    words: ["truthful", "words", "are", "not", "beautiful", "good", "persuasive"],
    id: "Truthful words are not beautiful; beautiful words are not truthful. Good words are not persuasive; persuasive words are not good.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["garden", "may", "weeds"],
    words: ["a", "good", "garden", "may", "have", "some", "weeds"],
    id: "A good garden may have some weeds.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["disagreeable", "thing", "whipped"],
    words: ["it's", "a", "disagreeable", "thing", "to", "be", "whipped"],
    id: "It's a disagreeable thing to be whipped.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["thought", "idea", "transit"],
    words: ["a", "thought", "is", "an", "idea", "in", "transit"],
    id: "A thought is an idea in transit.",
    author: "Pythagoras",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["talking", "soul", "itself"],
    words: ["thinking", "the", "talking", "of", "soul", "with", "itself"],
    id: "Thinking: the talking of the soul with itself.",
    author: "Plato",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "way", "prosperity"],
    words: ["there", "is", "no", "way", "to", "prosperity", "the"],
    id: "There is no way to prosperity, prosperity is the way.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["sum", "our", "possessions"],
    words: ["we", "are", "not", "the", "sum", "of", "our", "possessions"],
    id: "We are not the sum of our possessions.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["failure", "step", "success"],
    words: ["every", "failure", "is", "a", "step", "to", "success"],
    id: "Every failure is a step to success.",
    author: "William Whewell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["absurdity", "champion", "defend"],
    words: ["every", "absurdity", "has", "a", "champion", "to", "defend", "it"],
    id: "Every absurdity has a champion to defend it.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["poet", "condition", "profession"],
    words: ["to", "be", "a", "poet", "is", "condition", "not", "profession"],
    id: "To be a poet is a condition, not a profession.",
    author: "Robert Frost",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["manners", "hypocrisy", "nation"],
    words: ["manners", "are", "the", "hypocrisy", "of", "a", "nation"],
    id: "Manners are the hypocrisy of a nation.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["future", "ain't", "used"],
    words: ["the", "future", "ain't", "what", "it", "used", "to", "be"],
    id: "The future ain't what it used to be.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "without", "forgiveness"],
    words: ["there", "is", "no", "love", "without", "forgiveness", "and"],
    id: "There is no love without forgiveness, and there is no forgiveness without love.",
    author: "Bryant H. McGill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["prepared", "half", "victory"],
    words: ["to", "be", "prepared", "is", "half", "the", "victory"],
    id: "To be prepared is half the victory.",
    author: "Miguel de Cervantes",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["everybody", "chance", "find"],
    words: ["everybody", "needs", "a", "chance", "to", "find", "love"],
    id: "Everybody needs a chance to find love.",
    author: "Eva Longoria",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["my", "message", "god"],
    words: ["my", "message", "is", "that", "god", "a", "good"],
    id: "My message is that God is a good God.",
    author: "Joel Osteen",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["claim", "possession", "freedom"],
    words: ["love", "does", "not", "claim", "possession", "but", "gives", "freedom"],
    id: "Love does not claim possession, but gives freedom.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["born", "our", "mother"],
    words: ["we", "are", "born", "of", "love", "is", "our", "mother"],
    id: "We are born of love; Love is our mother.",
    author: "Rumi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["your", "health", "anything"],
    words: ["if", "you", "don't", "have", "your", "health", "anything"],
    id: "If you don't have your health, you don't have anything.",
    author: "Chuck Pagano",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["seek", "highest", "live"],
    words: ["to", "seek", "the", "highest", "good", "is", "live", "well"],
    id: "To seek the highest good is to live well.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["present", "point", "passed"],
    words: ["the", "present", "is", "a", "point", "just", "passed"],
    id: "The present is a point just passed.",
    author: "David Russell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["god", "from", "necessity"],
    words: ["god", "from", "a", "beautiful", "necessity", "is", "love"],
    id: "God, from a beautiful necessity, is Love.",
    author: "Martin Farquhar Tupper",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["thyself", "dress", "others"],
    words: ["eat", "to", "please", "thyself", "but", "dress", "others"],
    id: "Eat to please thyself, but dress to please others.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["friendship", "warm", "steady"],
    words: ["a", "friendship", "that", "like", "love", "is", "warm", "steady"],
    id: "A friendship that like love is warm; A love like friendship, steady.",
    author: "Thomas Moore",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["such", "as", "made"],
    words: ["such", "as", "we", "are", "made", "of", "be"],
    id: "Such as we are made of, such we be.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "freedom", "whatever"],
    words: ["i", "want", "to", "have", "the", "freedom", "do", "whatever"],
    id: "I want to have the freedom to do whatever I want.",
    author: "Paul Walker",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["friendship", "set", "music"],
    words: ["love", "is", "a", "friendship", "set", "to", "music"],
    id: "Love is a friendship set to music.",
    author: "Joseph Campbell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "short", "precious"],
    words: ["life", "is", "short", "and", "every", "moment", "precious"],
    id: "Life is short, and every moment is precious.",
    author: "Gad Saad",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["britain", "iron", "lady"],
    words: ["what", "britain", "needs", "is", "an", "iron", "lady"],
    id: "What Britain needs is an iron lady.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "because", "last"],
    words: ["life", "is", "beautiful", "because", "it", "doesn't", "last"],
    id: "Life is beautiful because it doesn't last.",
    author: "Brit Marling",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["abundantly", "live", "forever"],
    words: ["to", "love", "abundantly", "is", "live", "and", "forever"],
    id: "To love abundantly is to live abundantly, and to love forever is to live forever.",
    author: "Henry Drummond",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["diligence", "mother", "luck"],
    words: ["diligence", "is", "the", "mother", "of", "good", "luck"],
    id: "Diligence is the mother of good luck.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["clothing", "passport", "happiness"],
    words: ["good", "clothing", "is", "a", "passport", "to", "happiness"],
    id: "Good clothing is a passport to happiness.",
    author: "Yves Saint Laurent",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["disease", "system", "health"],
    words: ["we", "have", "a", "disease", "care", "system", "not", "health"],
    id: "We have a disease care system, not a health care system.",
    author: "Matthew Heineman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["hearing", "man", "unhappy"],
    words: ["happy", "is", "the", "hearing", "man", "unhappy", "speaking"],
    id: "Happy is the hearing man; unhappy the speaking man.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["friend", "gift", "yourself"],
    words: ["a", "friend", "is", "gift", "you", "give", "yourself"],
    id: "A friend is a gift you give yourself.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["farm", "mute", "gospel"],
    words: ["what", "is", "a", "farm", "but", "mute", "gospel"],
    id: "What is a farm but a mute gospel?",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["ancestor", "action", "thought"],
    words: ["the", "ancestor", "of", "every", "action", "is", "a", "thought"],
    id: "The ancestor of every action is a thought.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["laugh", "because", "-"],
    words: ["we", "don't", "laugh", "because", "we're", "happy", "-"],
    id: "We don't laugh because we're happy - we're happy because we laugh.",
    author: "William James",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["one", "curvy", "women"],
    words: ["no", "one", "wants", "to", "see", "curvy", "women"],
    id: "No one wants to see curvy women.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["forgiveness", "gift", "yourself"],
    words: ["forgiveness", "is", "a", "gift", "you", "give", "yourself"],
    id: "Forgiveness is a gift you give yourself.",
    author: "Suzanne Somers",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["enjoy", "life", "here"],
    words: ["enjoy", "life", "that's", "what", "we're", "here", "for"],
    id: "Enjoy life... that's what we're here for.",
    author: "David Faustino",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["riot", "language", "unheard"],
    words: ["a", "riot", "is", "the", "language", "of", "unheard"],
    id: "A riot is the language of the unheard.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["sometimes", "make", "movie"],
    words: ["sometimes", "you", "have", "to", "make", "a", "movie", "money"],
    id: "Sometimes you have to make a movie to make money.",
    author: "Robin Williams",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "bowl", "pits"],
    words: ["life", "is", "just", "a", "bowl", "of", "pits"],
    id: "Life is just a bowl of pits.",
    author: "Rodney Dangerfield",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["one", "crazy", "when"],
    words: ["one", "is", "very", "crazy", "when", "in", "love"],
    id: "One is very crazy when in love.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["victor", "belongs", "spoils"],
    words: ["the", "victor", "belongs", "to", "spoils"],
    id: "The victor belongs to the spoils.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "laughing", "matter"],
    words: ["happiness", "is", "no", "laughing", "matter"],
    id: "Happiness is no laughing matter.",
    author: "Richard Whately",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["husband", "makes", "wife"],
    words: ["a", "good", "husband", "makes", "wife"],
    id: "A good husband makes a good wife.",
    author: "John Florio",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["fortune", "favours", "bold"],
    words: ["fortune", "favours", "the", "bold"],
    id: "Fortune favours the bold.",
    author: "Virgil",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["seeks", "shall", "find"],
    words: ["who", "seeks", "shall", "find"],
    id: "Who seeks shall find.",
    author: "Sophocles",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["research", "creating", "knowledge"],
    words: ["research", "is", "creating", "new", "knowledge"],
    id: "Research is creating new knowledge.",
    author: "Neil Armstrong",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["medicine", "increases", "disease"],
    words: ["the", "medicine", "increases", "disease"],
    id: "The medicine increases the disease.",
    author: "Virgil",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "our", "dictionary"],
    words: ["life", "is", "our", "dictionary"],
    id: "Life is our dictionary.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["historian", "unsuccessful", "novelist"],
    words: ["historian", "an", "unsuccessful", "novelist"],
    id: "Historian: an unsuccessful novelist.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["success", "unshared", "failure"],
    words: ["success", "unshared", "is", "failure"],
    id: "Success unshared is failure.",
    author: "John Paul DeJoria",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "terrible", "patience"],
    words: ["i'm", "terrible", "with", "patience"],
    id: "I'm terrible with patience.",
    author: "Katherine Heigl",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i've", "learned", "patience"],
    words: ["i've", "learned", "patience"],
    id: "I've learned patience.",
    author: "Shannon Briggs",
    grammar: null,
    word_count: 3,
    all_intersection_count: 0,
    new_words_count: 3,
  },
  {
    row_new_words: ["freedom", "from", "within"],
    words: ["freedom", "is", "from", "within"],
    id: "Freedom is from within.",
    author: "Frank Lloyd Wright",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["chrysler", "great", "cars"],
    words: ["chrysler", "builds", "great", "cars"],
    id: "Chrysler builds great cars.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["when", "doubt", "abstain"],
    words: ["when", "you", "doubt", "abstain"],
    id: "When you doubt, abstain.",
    author: "Ambrose Bierce",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "believe", "friendship"],
    words: ["i", "believe", "in", "friendship"],
    id: "I believe in friendship.",
    author: "Rory MacDonald",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "my", "family"],
    words: ["i", "love", "my", "family"],
    id: "I love my family.",
    author: "Manute Bol",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "blue", "green"],
    words: ["no", "water", "life", "blue", "green"],
    id: "No water, no life. No blue, no green.",
    author: "Sylvia Earle",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "human", "feelings"],
    words: ["i", "have", "no", "human", "feelings"],
    id: "I have no human feelings.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["sincere", "words", "fine"],
    words: ["sincere", "words", "are", "not", "fine"],
    id: "Sincere words are not fine; fine words are not sincere.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["i've", "never", "stopped"],
    words: ["i've", "never", "stopped", "learning"],
    id: "I've never stopped learning.",
    author: "Mick Taylor",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["success", "great", "deodorant"],
    words: ["success", "is", "a", "great", "deodorant"],
    id: "Success is a great deodorant.",
    author: "Elizabeth Taylor",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["movie", "magic", "acting"],
    words: ["movie", "magic", "is", "and", "acting"],
    id: "Movie magic is movie magic and acting magic is acting magic.",
    author: "Ben Kingsley",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "always", "coincidence"],
    words: ["happiness", "is", "always", "a", "coincidence"],
    id: "Happiness is always a coincidence.",
    author: "Jose Bergamin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "believe", "forgiveness"],
    words: ["i", "believe", "in", "forgiveness"],
    id: "I believe in forgiveness.",
    author: "Pam Bondi",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["my", "life", "message"],
    words: ["my", "life", "is", "message"],
    id: "My life is my message.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["doubt", "grows", "knowledge"],
    words: ["doubt", "grows", "with", "knowledge"],
    id: "Doubt grows with knowledge.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["my", "family", "sanctuary"],
    words: ["my", "family", "is", "sanctuary"],
    id: "My family is my sanctuary.",
    author: "Pierce Brosnan",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["labor", "birth", "ideas"],
    words: ["labor", "gives", "birth", "to", "ideas"],
    id: "Labor gives birth to ideas.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["sleep", "best", "meditation"],
    words: ["sleep", "is", "the", "best", "meditation"],
    id: "Sleep is the best meditation.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["plodding", "wins", "race"],
    words: ["plodding", "wins", "the", "race"],
    id: "Plodding wins the race.",
    author: "Aesop",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["patience", "sorrow's", "salve"],
    words: ["patience", "is", "sorrow's", "salve"],
    id: "Patience is sorrow's salve.",
    author: "Charles Churchill",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "cadillac", "ats"],
    words: ["i", "love", "that", "cadillac", "ats"],
    id: "I love that Cadillac ATS!",
    author: "Joe Biden",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "inside", "job"],
    words: ["happiness", "is", "an", "inside", "job"],
    id: "Happiness is an inside job.",
    author: "William Arthur Ward",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["never", "exhausts", "mind"],
    words: ["learning", "never", "exhausts", "the", "mind"],
    id: "Learning never exhausts the mind.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["thy", "thoughts", "tongue"],
    words: ["give", "thy", "thoughts", "no", "tongue"],
    id: "Give thy thoughts no tongue.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["laughter", "god's", "blessing"],
    words: ["laughter", "is", "god's", "blessing"],
    id: "Laughter is God's blessing.",
    author: "Joseph Prince",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["my", "audience", "god"],
    words: ["my", "audience", "is", "god"],
    id: "My audience is God.",
    author: "Jeremy Lin",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["renunciation", "life", "begins"],
    words: ["with", "renunciation", "life", "begins"],
    id: "With renunciation life begins.",
    author: "Amelia Barr",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["genius", "eternal", "patience"],
    words: ["genius", "is", "eternal", "patience"],
    id: "Genius is eternal patience.",
    author: "Michelangelo",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "was", "activist"],
    words: ["i", "was", "not", "an", "activist"],
    id: "I was not an activist.",
    author: "Joe Biden",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["my", "friends", "there"],
    words: ["my", "friends", "there", "are", "no"],
    id: "My friends, there are no friends.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["when", "finished", "changing"],
    words: ["when", "you're", "finished", "changing"],
    id: "When you're finished changing, you're finished.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "believe", "momentum"],
    words: ["i", "believe", "in", "momentum"],
    id: "I believe in momentum.",
    author: "Fernando Tatis Jr.",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "comes", "pain"],
    words: ["life", "comes", "with", "pain"],
    id: "Life comes with pain.",
    author: "Rod Wave",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["plato", "was", "bore"],
    words: ["plato", "was", "a", "bore"],
    id: "Plato was a bore.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["humility", "attentive", "patience"],
    words: ["humility", "is", "attentive", "patience"],
    id: "Humility is attentive patience.",
    author: "Simone Weil",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["physical", "pain", "temporary"],
    words: ["physical", "pain", "is", "temporary"],
    id: "Physical pain is temporary.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["nothing", "recedes", "success"],
    words: ["nothing", "recedes", "like", "success"],
    id: "Nothing recedes like success.",
    author: "Walter Winchell",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "come", "win"],
    words: ["i", "come", "to", "win"],
    id: "I come to win.",
    author: "Leo Durocher",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["true", "time", "four-dimensional"],
    words: ["true", "time", "is", "four-dimensional"],
    id: "True time is four-dimensional.",
    author: "Martin Heidegger",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["football", "brings", "happiness"],
    words: ["football", "brings", "happiness"],
    id: "Football brings happiness.",
    author: "Lucas Vazquez",
    grammar: null,
    word_count: 3,
    all_intersection_count: 0,
    new_words_count: 3,
  },
  {
    row_new_words: ["science", "organized", "knowledge"],
    words: ["science", "is", "organized", "knowledge"],
    id: "Science is organized knowledge.",
    author: "Herbert Spencer",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["doer", "alone", "learneth"],
    words: ["the", "doer", "alone", "learneth"],
    id: "The doer alone learneth.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["leave", "stone", "unturned"],
    words: ["leave", "no", "stone", "unturned"],
    id: "Leave no stone unturned.",
    author: "Euripides",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "passes", "irrevocably"],
    words: ["time", "passes", "irrevocably"],
    id: "Time passes irrevocably.",
    author: "Virgil",
    grammar: null,
    word_count: 3,
    all_intersection_count: 0,
    new_words_count: 3,
  },
  {
    row_new_words: ["thoughts", "bear", "bad"],
    words: ["good", "thoughts", "bear", "fruit", "bad"],
    id: "Good thoughts bear good fruit, bad thoughts bear bad fruit.",
    author: "James Allen",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "taking", "chances"],
    words: ["i", "love", "taking", "chances"],
    id: "I love taking chances.",
    author: "Wayne Newton",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["appearances", "often", "deceiving"],
    words: ["appearances", "are", "often", "deceiving"],
    id: "Appearances are often deceiving.",
    author: "Aesop",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["wisdom", "begins", "wonder"],
    words: ["wisdom", "begins", "in", "wonder"],
    id: "Wisdom begins in wonder.",
    author: "Socrates",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["ain't", "nothing", "family"],
    words: ["ain't", "nothing", "like", "family"],
    id: "Ain't nothing like family.",
    author: "Young Dolph",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["when", "one", "must"],
    words: ["when", "one", "must", "can"],
    id: "When one must, one can.",
    author: "Charlotte Whitton",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["act", "god", "will"],
    words: ["act", "and", "god", "will"],
    id: "Act, and God will act.",
    author: "Joan of Arc",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["forever", "composed", "nows"],
    words: ["forever", "is", "composed", "of", "nows"],
    id: "Forever is composed of nows.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["my", "life", "editing"],
    words: ["my", "life", "needs", "editing"],
    id: "My life needs editing.",
    author: "Mort Sahl",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["disagreement", "something", "normal"],
    words: ["disagreement", "is", "something", "normal"],
    id: "Disagreement is something normal.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["fatigue", "best", "pillow"],
    words: ["fatigue", "is", "the", "best", "pillow"],
    id: "Fatigue is the best pillow.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["deeds", "match", "words"],
    words: ["let", "deeds", "match", "words"],
    id: "Let deeds match words.",
    author: "Plautus",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["nothing", "succeeds", "success"],
    words: ["nothing", "succeeds", "like", "success"],
    id: "Nothing succeeds like success.",
    author: "Alexandre Dumas",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["quick", "decisions", "unsafe"],
    words: ["quick", "decisions", "are", "unsafe"],
    id: "Quick decisions are unsafe decisions.",
    author: "Sophocles",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["honesty", "best", "policy"],
    words: ["honesty", "is", "the", "best", "policy"],
    id: "Honesty is the best policy.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["africa", "on", "rise"],
    words: ["africa", "is", "on", "the", "rise"],
    id: "Africa is on the rise.",
    author: "Bill Gates",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "takes", "courage"],
    words: ["change", "takes", "courage"],
    id: "Change takes courage.",
    author: "Alexandria Ocasio-Cortez",
    grammar: null,
    word_count: 3,
    all_intersection_count: 0,
    new_words_count: 3,
  },
  {
    row_new_words: ["suit", "action", "word"],
    words: ["suit", "the", "action", "to", "word"],
    id: "Suit the action to the word, the word to the action.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["penny", "saved", "earned"],
    words: ["a", "penny", "saved", "is", "earned"],
    id: "A penny saved is a penny earned.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["being", "great", "explainer"],
    words: ["being", "is", "the", "great", "explainer"],
    id: "Being is the great explainer.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["patience", "pays", "off"],
    words: ["patience", "pays", "off"],
    id: "Patience pays off.",
    author: "Mayte Garcia",
    grammar: null,
    word_count: 3,
    all_intersection_count: 0,
    new_words_count: 3,
  },
  {
    row_new_words: ["hunger", "best", "pickle"],
    words: ["hunger", "is", "the", "best", "pickle"],
    id: "Hunger is the best pickle.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["choose", "your", "choice"],
    words: ["choose", "your", "love", "choice"],
    id: "Choose your love, Love your choice.",
    author: "Thomas S. Monson",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["action", "expresses", "priorities"],
    words: ["action", "expresses", "priorities"],
    id: "Action expresses priorities.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 3,
    all_intersection_count: 0,
    new_words_count: 3,
  },
  {
    row_new_words: ["movies", "magic", "tricks"],
    words: ["movies", "are", "like", "magic", "tricks"],
    id: "Movies are like magic tricks.",
    author: "Jeff Bridges",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["patience", "passion", "tamed"],
    words: ["patience", "is", "passion", "tamed"],
    id: "Patience is passion tamed.",
    author: "Lyman Abbott",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["great", "fortune", "slavery"],
    words: ["a", "great", "fortune", "is", "slavery"],
    id: "A great fortune is a great slavery.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["confine", "yourself", "present"],
    words: ["confine", "yourself", "to", "the", "present"],
    id: "Confine yourself to the present.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["get", "older", "better"],
    words: ["you", "don't", "get", "older", "better"],
    id: "You don't get older, you get better.",
    author: "Shirley Bassey",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["beginning", "makes", "end"],
    words: ["a", "good", "beginning", "makes", "end"],
    id: "A good beginning makes a good end.",
    author: "Louis L'Amour",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["football", "my", "happiness"],
    words: ["football", "is", "my", "happiness"],
    id: "Football is my happiness.",
    author: "Son Heung-min",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["boredom", "desire", "desires"],
    words: ["boredom", "the", "desire", "for", "desires"],
    id: "Boredom: the desire for desires.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["success", "happen", "overnight"],
    words: ["success", "doesn't", "happen", "overnight"],
    id: "Success doesn't happen overnight.",
    author: "Petra Collins",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "discovers", "truth"],
    words: ["time", "discovers", "truth"],
    id: "Time discovers truth.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 3,
    all_intersection_count: 0,
    new_words_count: 3,
  },
  {
    row_new_words: ["funny", "old", "world"],
    words: ["it's", "a", "funny", "old", "world"],
    id: "It's a funny old world.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "ipod", "person"],
    words: ["i'm", "an", "ipod", "person"],
    id: "I'm an iPod person.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["shot", "kills", "ricochets"],
    words: ["every", "shot", "that", "kills", "ricochets"],
    id: "Every shot that kills ricochets.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["chess", "taught", "patience"],
    words: ["chess", "taught", "me", "patience"],
    id: "Chess taught me patience.",
    author: "Yuzvendra Chahal",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["much", "effort", "prosperity"],
    words: ["much", "effort", "prosperity"],
    id: "Much effort, much prosperity.",
    author: "Euripides",
    grammar: null,
    word_count: 3,
    all_intersection_count: 0,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "educated", "free"],
    words: ["only", "the", "educated", "are", "free"],
    id: "Only the educated are free.",
    author: "Epictetus",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["endurance", "patience", "concentrated"],
    words: ["endurance", "is", "patience", "concentrated"],
    id: "Endurance is patience concentrated.",
    author: "Thomas Carlyle",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "motivational", "speaker"],
    words: ["i'm", "a", "motivational", "speaker"],
    id: "I'm a motivational speaker.",
    author: "Young Jeezy",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["real", "living", "others"],
    words: ["real", "living", "is", "for", "others"],
    id: "Real living is living for others.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "brings", "opportunity"],
    words: ["change", "brings", "opportunity"],
    id: "Change brings opportunity.",
    author: "Nido Qubein",
    grammar: null,
    word_count: 3,
    all_intersection_count: 0,
    new_words_count: 3,
  },
  {
    row_new_words: ["fortune", "befriends", "bold"],
    words: ["fortune", "befriends", "the", "bold"],
    id: "Fortune befriends the bold.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["cautious", "seldom", "err"],
    words: ["the", "cautious", "seldom", "err"],
    id: "The cautious seldom err.",
    author: "Confucius",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["heart", "forever", "inexperienced"],
    words: ["the", "heart", "is", "forever", "inexperienced"],
    id: "The heart is forever inexperienced.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["will", "finds", "way"],
    words: ["a", "will", "finds", "way"],
    id: "A will finds a way.",
    author: "Orison Swett Marden",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "pointless", "goal"],
    words: ["happiness", "is", "a", "pointless", "goal"],
    id: "'Happiness' is a pointless goal.",
    author: "Jordan Peterson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["film", "lovers", "sick"],
    words: ["film", "lovers", "are", "sick", "people"],
    id: "Film lovers are sick people.",
    author: "Francois Truffaut",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["joys", "divided", "increased"],
    words: ["joys", "divided", "are", "increased"],
    id: "Joys divided are increased.",
    author: "Josiah Gilbert Holland",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "trouble", "sleeping"],
    words: ["i", "have", "no", "trouble", "sleeping"],
    id: "I have no trouble sleeping.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["sense", "wanting", "everything"],
    words: ["where", "sense", "is", "wanting", "everything"],
    id: "Where sense is wanting, everything is wanting.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["ticket", "take", "ride"],
    words: ["buy", "the", "ticket", "take", "ride"],
    id: "Buy the ticket, take the ride.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["dread", "night", "not-night"],
    words: ["dread", "of", "night", "not-night"],
    id: "Dread of night. Dread of not-night.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "young", "die"],
    words: ["only", "the", "young", "die", "good"],
    id: "Only the young die good.",
    author: "Oliver Herford",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["your", "friend", "answered"],
    words: ["your", "friend", "is", "needs", "answered"],
    id: "Your friend is your needs answered.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 5,
    all_intersection_count: 2,
    new_words_count: 3,
  },
  {
    row_new_words: ["done", "yourself", "others"],
    words: ["what", "you", "do", "not", "want", "done", "to", "yourself", "others"],
    id: "What you do not want done to yourself, do not do to others.",
    author: "Confucius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["pure", "true", "gender"],
    words: ["love", "is", "pure", "and", "true", "knows", "no", "gender"],
    id: "Love is pure and true; love knows no gender.",
    author: "Tori Spelling",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "until", "die"],
    words: ["i", "think", "you're", "working", "and", "learning", "until", "you", "die"],
    id: "I think you're working and learning until you die.",
    author: "J. K. Rowling",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["secret", "film", "illusion"],
    words: ["the", "secret", "to", "film", "is", "that", "it's", "an", "illusion"],
    id: "The secret to film is that it's an illusion.",
    author: "George Lucas",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["finding", "out", "already"],
    words: ["learning", "is", "finding", "out", "what", "you", "already", "know"],
    id: "Learning is finding out what you already know.",
    author: "Richard Bach",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["yourself", "why", "shouldn't"],
    words: ["go", "see", "it", "and", "for", "yourself", "why", "you", "shouldn't"],
    id: "Go see it and see for yourself why you shouldn't go see it.",
    author: "Samuel Goldwyn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["fun", "hopelessly", "dangerous"],
    words: ["it's", "fun", "to", "be", "hopelessly", "in", "love", "dangerous", "but"],
    id: "It's fun to be hopelessly in love. It's dangerous, but it's fun.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["man", "idle", "miserable"],
    words: ["it", "is", "the", "working", "man", "who", "happy", "idle", "miserable"],
    id: "It is the working man who is the happy man. It is the idle man who is the miserable man.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["power", "similarity", "dissimilar"],
    words: ["love", "is", "the", "power", "to", "see", "similarity", "in", "dissimilar"],
    id: "Love is the power to see similarity in the dissimilar.",
    author: "Theodor W. Adorno",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["opposite", "hate", "indifference"],
    words: ["the", "opposite", "of", "love", "is", "not", "hate", "it's", "indifference"],
    id: "The opposite of love is not hate, it's indifference.",
    author: "Elie Wiesel",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["gentiles", "mayonnaise", "reason"],
    words: ["gentiles", "are", "people", "who", "eat", "mayonnaise", "for", "no", "reason"],
    id: "Gentiles are people who eat mayonnaise for no reason.",
    author: "Robin Williams",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "believe", "forgiveness"],
    words: ["i", "see", "the", "good", "in", "people", "and", "believe", "forgiveness"],
    id: "I see the good in people, and I believe in forgiveness.",
    author: "Karrueche Tran",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["lies", "our", "power"],
    words: ["what", "it", "lies", "in", "our", "power", "to", "do", "not"],
    id: "What it lies in our power to do, it lies in our power not to do.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "importance", "living"],
    words: ["i", "am", "learning", "the", "importance", "of", "living", "in", "moment"],
    id: "I am learning the importance of living in the moment.",
    author: "Sushant Singh Rajput",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["least", "done", "classroom"],
    words: ["the", "least", "of", "work", "learning", "is", "done", "in", "classroom"],
    id: "The least of the work of learning is done in the classroom.",
    author: "Thomas Merton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "when", "bad"],
    words: ["life", "is", "good", "when", "we", "think", "it's", "bad", "don't"],
    id: "Life is good when we think it's good. Life is bad when we don't think.",
    author: "Douglas Horton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["uncharted", "territory", "place"],
    words: ["uncharted", "territory", "is", "a", "good", "place", "to", "be", "in"],
    id: "Uncharted territory is a good place to be in.",
    author: "Bo Burnham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "patience", "non-creator"],
    words: ["i", "don't", "have", "the", "patience", "to", "be", "a", "non-creator"],
    id: "I don't have the patience to be a non-creator.",
    author: "Virgil Abloh",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "limit", "success"],
    words: ["for", "me", "there", "is", "no", "limit", "to", "success"],
    id: "For me, there is no limit to success.",
    author: "Jay-Jay Okocha",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["teach", "best", "most"],
    words: ["you", "teach", "best", "what", "most", "need", "to", "learn"],
    id: "You teach best what you most need to learn.",
    author: "Richard Bach",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["when", "gone", "alone"],
    words: ["when", "the", "people", "you", "love", "are", "gone", "you're", "alone"],
    id: "When the people you love are gone, you're alone.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["grief", "price", "pay"],
    words: ["grief", "is", "the", "price", "we", "pay", "for", "love"],
    id: "Grief is the price we pay for love.",
    author: "Queen Elizabeth II",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["comes", "unseen", "only"],
    words: ["love", "comes", "unseen", "we", "only", "see", "it", "go"],
    id: "Love comes unseen; we only see it go.",
    author: "Henry Austin Dobson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["education", "didn't", "even"],
    words: ["education", "is", "learning", "what", "you", "didn't", "even", "know"],
    id: "Education is learning what you didn't even know you didn't know.",
    author: "Daniel J. Boorstin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["man", "machine", "badly"],
    words: ["man", "is", "a", "beautiful", "machine", "that", "works", "very", "badly"],
    id: "Man is a beautiful machine that works very badly.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["light", "dark", "miracle"],
    words: ["every", "moment", "of", "light", "and", "dark", "is", "a", "miracle"],
    id: "Every moment of light and dark is a miracle.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["music", "search", "word"],
    words: ["music", "is", "love", "in", "search", "of", "a", "word"],
    id: "Music is love in search of a word.",
    author: "Sidney Lanier",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["never", "get", "enough"],
    words: ["you", "can", "never", "get", "enough", "of", "what", "don't", "want"],
    id: "You can never get enough of what you don't want.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["sometimes", "magic", "illusion"],
    words: ["love", "can", "sometimes", "be", "magic", "but", "just", "an", "illusion"],
    id: "Love can sometimes be magic. But magic can sometimes... just be an illusion.",
    author: "Javan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "chiefly", "valued"],
    words: ["not", "life", "but", "good", "is", "to", "be", "chiefly", "valued"],
    id: "Not life, but good life, is to be chiefly valued.",
    author: "Socrates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["things", "seed", "genius"],
    words: ["to", "see", "things", "in", "the", "seed", "that", "is", "genius"],
    id: "To see things in the seed, that is genius.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["cornerstones", "our", "humanness"],
    words: ["love", "and", "work", "are", "the", "cornerstones", "of", "our", "humanness"],
    id: "Love and work are the cornerstones of our humanness.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["control", "insurance", "companies"],
    words: ["you", "know", "we're", "going", "to", "control", "the", "insurance", "companies"],
    id: "You know we're going to control the insurance companies.",
    author: "Joe Biden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["key", "change", "fear"],
    words: ["the", "key", "to", "change", "is", "let", "go", "of", "fear"],
    id: "The key to change... is to let go of fear.",
    author: "Rosanne Cash",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["plan", "your", "then"],
    words: ["plan", "your", "work", "for", "today", "and", "every", "day", "then"],
    id: "Plan your work for today and every day, then work your plan.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "which", "honey"],
    words: ["life", "is", "the", "flower", "for", "which", "love", "honey"],
    id: "Life is the flower for which love is the honey.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["confidence", "patience", "everything"],
    words: ["if", "you", "have", "confidence", "patience", "that", "is", "everything"],
    id: "If you have confidence you have patience. Confidence, that is everything.",
    author: "Ilie Nastase",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "still", "life"],
    words: ["i'm", "still", "learning", "and", "that's", "what", "life", "is", "about"],
    id: "I'm still learning, and that's what life is about.",
    author: "Cary Elwes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["picture", "been", "opportunity"],
    words: ["every", "picture", "has", "been", "a", "learning", "opportunity", "for", "me"],
    id: "Every picture has been a learning opportunity for me.",
    author: "Sam Raimi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "enemy", "hurts"],
    words: ["no", "i'm", "enemy", "to", "learning", "it", "hurts", "not", "me"],
    id: "No, I'm no enemy to learning; it hurts not me.",
    author: "William Congreve",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "uh", "wasn't"],
    words: ["the", "other", "day", "i", "uh", "no", "that", "wasn't", "me"],
    id: "The other day I... uh, no, that wasn't me.",
    author: "Steven Wright",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["proud", "greatest", "ignorance"],
    words: ["to", "be", "proud", "of", "learning", "is", "the", "greatest", "ignorance"],
    id: "To be proud of learning is the greatest ignorance.",
    author: "Jeremy Taylor",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["wonder", "desire", "knowledge"],
    words: ["wonder", "is", "the", "desire", "for", "knowledge"],
    id: "Wonder is the desire for knowledge.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["sovereign", "cure", "prayer"],
    words: ["the", "sovereign", "cure", "for", "worry", "is", "prayer"],
    id: "The sovereign cure for worry is prayer.",
    author: "William James",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["secret", "success", "sincerity"],
    words: ["the", "secret", "of", "success", "is", "sincerity"],
    id: "The secret of success is sincerity.",
    author: "Jean Giraudoux",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["pain", "root", "knowledge"],
    words: ["pain", "is", "the", "root", "of", "knowledge"],
    id: "Pain is the root of knowledge.",
    author: "Simone Veil",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["rich", "content", "nobody"],
    words: ["who", "is", "rich", "he", "that", "content", "nobody"],
    id: "Who is rich? He that is content. Who is that? Nobody.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["fashioned", "hell", "inquisitive"],
    words: ["he", "fashioned", "hell", "for", "the", "inquisitive"],
    id: "He fashioned hell for the inquisitive.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["punishment", "justice", "unjust"],
    words: ["punishment", "is", "justice", "for", "the", "unjust"],
    id: "Punishment is justice for the unjust.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["events", "matter", "chance"],
    words: ["events", "are", "not", "a", "matter", "of", "chance"],
    id: "Events are not a matter of chance.",
    author: "Gamal Abdel Nasser",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["tyranny", "multitude", "multiplied"],
    words: ["the", "tyranny", "of", "a", "multitude", "is", "multiplied"],
    id: "The tyranny of a multitude is a multiplied tyranny.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["difficulty", "life", "choice"],
    words: ["the", "difficulty", "in", "life", "is", "choice"],
    id: "The difficulty in life is the choice.",
    author: "George A. Moore",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["trust", "cool", "invest"],
    words: ["trust", "me", "it's", "cool", "to", "invest"],
    id: "Trust me: it's cool to invest.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["procrastination", "thief", "time"],
    words: ["procrastination", "is", "the", "thief", "of", "time"],
    id: "Procrastination is the thief of time.",
    author: "Edward Young",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["patience", "companion", "wisdom"],
    words: ["patience", "is", "the", "companion", "of", "wisdom"],
    id: "Patience is the companion of wisdom.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["art", "daughter", "freedom"],
    words: ["art", "is", "the", "daughter", "of", "freedom"],
    id: "Art is the daughter of freedom.",
    author: "Friedrich Schiller",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["one", "must", "trifle"],
    words: ["one", "must", "not", "trifle", "with", "love"],
    id: "One must not trifle with love.",
    author: "Alfred de Musset",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["habit", "nursery", "errors"],
    words: ["habit", "is", "the", "nursery", "of", "errors"],
    id: "Habit is the nursery of errors.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["few", "imagination", "reality"],
    words: ["few", "people", "have", "the", "imagination", "for", "reality"],
    id: "Few people have the imagination for reality.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["can't", "hire", "practice"],
    words: ["you", "can't", "hire", "someone", "to", "practice", "for"],
    id: "You can't hire someone to practice for you.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["compulsory", "neither", "survival"],
    words: ["learning", "is", "not", "compulsory", "neither", "survival"],
    id: "Learning is not compulsory... neither is survival.",
    author: "W. Edwards Deming",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["woman", "age", "deserves"],
    words: ["a", "woman", "has", "the", "age", "she", "deserves"],
    id: "A woman has the age she deserves.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["overflow", "converts", "bad"],
    words: ["an", "overflow", "of", "good", "converts", "to", "bad"],
    id: "An overflow of good converts to bad.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["end", "everything", "gag"],
    words: ["in", "the", "end", "everything", "is", "a", "gag"],
    id: "In the end, everything is a gag.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["once", "success", "maintain"],
    words: ["once", "you", "have", "success", "to", "maintain"],
    id: "Once you have success, you have to maintain success.",
    author: "Travis Kelce",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["winter", "our", "discontent"],
    words: ["now", "is", "the", "winter", "of", "our", "discontent"],
    id: "Now is the winter of our discontent.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["art", "thing", "way"],
    words: ["art", "is", "not", "a", "thing", "it", "way"],
    id: "Art is not a thing; it is a way.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["mother-in-law", "centre", "family"],
    words: ["the", "mother-in-law", "is", "centre", "of", "a", "family"],
    id: "The mother-in-law is the centre of a family.",
    author: "Les Dawson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["wit", "epitaph", "emotion"],
    words: ["wit", "is", "the", "epitaph", "of", "an", "emotion"],
    id: "Wit is the epitaph of an emotion.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["power", "change", "unmasks"],
    words: ["power", "does", "not", "change", "you", "it", "unmasks"],
    id: "Power does not change you, it unmasks you.",
    author: "John Kennedy",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["quickness", "essence", "war"],
    words: ["quickness", "is", "the", "essence", "of", "war"],
    id: "Quickness is the essence of the war.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["modesty", "forbids", "law"],
    words: ["modesty", "forbids", "what", "the", "law", "does", "not"],
    id: "Modesty forbids what the law does not.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["forgiveness", "final", "form"],
    words: ["forgiveness", "is", "the", "final", "form", "of", "love"],
    id: "Forgiveness is the final form of love.",
    author: "Reinhold Niebuhr",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["youth", "age", "understand"],
    words: ["in", "youth", "we", "learn", "age", "understand"],
    id: "In youth we learn; in age we understand.",
    author: "Marie von Ebner-Eschenbach",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["one", "forgives", "degree"],
    words: ["one", "forgives", "to", "the", "degree", "that", "loves"],
    id: "One forgives to the degree that one loves.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["remember", "days", "moments"],
    words: ["we", "do", "not", "remember", "days", "moments"],
    id: "We do not remember days, we remember moments.",
    author: "Cesare Pavese",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["serious", "mental", "disease"],
    words: ["love", "is", "a", "serious", "mental", "disease"],
    id: "Love is a serious mental disease.",
    author: "Plato",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["familiar", "acts", "through"],
    words: ["familiar", "acts", "are", "beautiful", "through", "love"],
    id: "Familiar acts are beautiful through love.",
    author: "Percy Bysshe Shelley",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "cessation", "suffering"],
    words: ["happiness", "is", "the", "cessation", "of", "suffering"],
    id: "Happiness is the cessation of suffering.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["future", "advertising", "internet"],
    words: ["the", "future", "of", "advertising", "is", "internet"],
    id: "The future of advertising is the Internet.",
    author: "Bill Gates",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["folks", "wise", "otherwise"],
    words: ["some", "folks", "are", "wise", "and", "otherwise"],
    id: "Some folks are wise and some are otherwise.",
    author: "Tobias Smollett",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["acquire", "strength", "overcome"],
    words: ["we", "acquire", "the", "strength", "have", "overcome"],
    id: "We acquire the strength we have overcome.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["price", "greatness", "responsibility"],
    words: ["the", "price", "of", "greatness", "is", "responsibility"],
    id: "The price of greatness is responsibility.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["nice", "night", "evening"],
    words: ["what", "a", "nice", "night", "for", "an", "evening"],
    id: "What a nice night for an evening.",
    author: "Steven Wright",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["god", "verb", "noun"],
    words: ["god", "is", "a", "verb", "not", "noun"],
    id: "God is a verb, not a noun.",
    author: "R. Buckminster Fuller",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["idleness", "parent", "psychology"],
    words: ["idleness", "is", "the", "parent", "of", "psychology"],
    id: "Idleness is the parent of psychology.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["much", "duty", "more"],
    words: ["love", "can", "do", "much", "but", "duty", "more"],
    id: "Love can do much, but duty more.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["friendship", "exclude", "each"],
    words: ["love", "and", "friendship", "exclude", "each", "other"],
    id: "Love and friendship exclude each other.",
    author: "Jean de la Bruyere",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["or", "listen", "those"],
    words: ["know", "or", "listen", "to", "those", "who"],
    id: "Know or listen to those who know.",
    author: "Baltasar Gracian",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["american", "abhor", "vacuum"],
    words: ["the", "american", "people", "abhor", "a", "vacuum"],
    id: "The American people abhor a vacuum.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["things", "happen", "made"],
    words: ["things", "do", "not", "happen", "are", "made", "to"],
    id: "Things do not happen. Things are made to happen.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["proper", "mankind", "man"],
    words: ["the", "proper", "study", "of", "mankind", "is", "man"],
    id: "The proper study of Mankind is Man.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "women", "alone"],
    words: ["i", "don't", "eat", "with", "beautiful", "women", "alone"],
    id: "I don't eat with beautiful women alone.",
    author: "Billy Graham",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "ukrainian", "fighting"],
    words: ["i", "am", "ukrainian", "and", "fighting", "like", "a"],
    id: "I am Ukrainian and fighting like a Ukrainian.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["punctuality", "virtue", "bored"],
    words: ["punctuality", "is", "the", "virtue", "of", "bored"],
    id: "Punctuality is the virtue of the bored.",
    author: "Evelyn Waugh",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "hesitate", "through"],
    words: ["the", "time", "to", "hesitate", "is", "through"],
    id: "The time to hesitate is through.",
    author: "Robby Krieger",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "scenery", "everything"],
    words: ["a", "change", "of", "scenery", "can", "help", "everything"],
    id: "A change of scenery can help everything.",
    author: "Drew Pomeranz",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["thou", "art", "home"],
    words: ["where", "thou", "art", "that", "is", "home"],
    id: "Where thou art, that is home.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "grateful", "god"],
    words: ["i'm", "happy", "and", "grateful", "to", "god"],
    id: "I'm happy and grateful to God.",
    author: "Ronald Acuna Jr.",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["life's", "fight", "faith"],
    words: ["life's", "a", "fight", "it's", "good", "of", "faith"],
    id: "Life's a fight. It's a good fight of faith.",
    author: "Joel Osteen",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["never", "cut", "untie"],
    words: ["never", "cut", "what", "you", "can", "untie"],
    id: "Never cut what you can untie.",
    author: "Joseph Joubert",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["god", "bigger", "than"],
    words: ["god", "is", "bigger", "than", "people", "think"],
    id: "God is bigger than people think.",
    author: "Jimmy Dean",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["perfection", "child", "time"],
    words: ["perfection", "is", "the", "child", "of", "time"],
    id: "Perfection is the child of time.",
    author: "Joseph Hall",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["simply", "-", "something"],
    words: ["be", "not", "simply", "good", "-", "for", "something"],
    id: "Be not simply good - be good for something.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["suspicion", "cancer", "friendship"],
    words: ["suspicion", "is", "the", "cancer", "of", "friendship"],
    id: "Suspicion is the cancer of friendship.",
    author: "Petrarch",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["god", "entrusted", "myself"],
    words: ["god", "has", "entrusted", "me", "with", "myself"],
    id: "God has entrusted me with myself.",
    author: "Epictetus",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "patience", "homophobia"],
    words: ["i", "have", "no", "patience", "for", "homophobia"],
    id: "I have no patience for homophobia.",
    author: "Dan Levy",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "friends", "neighbours"],
    words: ["you", "can", "change", "friends", "but", "not", "neighbours"],
    id: "You can change friends but not neighbours.",
    author: "Atal Bihari Vajpayee",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["freedom", "declared", "exercised"],
    words: ["freedom", "is", "not", "just", "declared", "it", "exercised"],
    id: "Freedom is not just declared; it is exercised.",
    author: "Ferdinand Marcos",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["fear", "mother", "morality"],
    words: ["fear", "is", "the", "mother", "of", "morality"],
    id: "Fear is the mother of morality.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "forever", "optimist"],
    words: ["well", "you", "know", "i'm", "the", "forever", "optimist"],
    id: "Well, you know, I'm the forever optimist.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["being", "seventy", "sin"],
    words: ["being", "seventy", "is", "not", "a", "sin"],
    id: "Being seventy is not a sin.",
    author: "Golda Meir",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "has-been", "will"],
    words: ["i", "am", "not", "a", "has-been", "will", "be"],
    id: "I am not a has-been. I am a will be.",
    author: "Lauren Bacall",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["secret", "marriage", "remains"],
    words: ["the", "secret", "of", "a", "happy", "marriage", "remains"],
    id: "The secret of a happy marriage remains a secret.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["violence", "bible", "appalling"],
    words: ["the", "violence", "in", "bible", "is", "appalling"],
    id: "The violence in the Bible is appalling.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["anxiety", "dizziness", "freedom"],
    words: ["anxiety", "is", "the", "dizziness", "of", "freedom"],
    id: "Anxiety is the dizziness of freedom.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "still", "live"],
    words: ["i", "still", "live", "think", "have", "to", "for"],
    id: "I still live, I still think: I still have to live, for I still have to think.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["deja-vu", "all", "over"],
    words: ["it's", "like", "deja-vu", "all", "over", "again"],
    id: "It's like deja-vu, all over again.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["live", "will", "really"],
    words: ["live", "to", "learn", "and", "you", "will", "really"],
    id: "Live to learn, and you will really learn to live.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["game", "won", "patience"],
    words: ["every", "game", "is", "won", "with", "patience"],
    id: "Every game is won with patience.",
    author: "Mellody Hobson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["best", "teacher", "interactive"],
    words: ["the", "best", "teacher", "is", "very", "interactive"],
    id: "The best teacher is very interactive.",
    author: "Bill Gates",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["cannot", "creative", "around"],
    words: ["you", "cannot", "be", "creative", "with", "people", "around"],
    id: "You cannot be creative with people around you.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["desire", "ease", "forsake"],
    words: ["if", "you", "desire", "ease", "forsake", "learning"],
    id: "If you desire ease, forsake learning.",
    author: "Nagarjuna",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["friend", "power", "lost"],
    words: ["a", "friend", "in", "power", "is", "lost"],
    id: "A friend in power is a friend lost.",
    author: "Henry Adams",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["nothing", "happiest", "life"],
    words: ["to", "know", "nothing", "is", "the", "happiest", "life"],
    id: "To know nothing is the happiest life.",
    author: "Desiderius Erasmus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["news", "at", "4:30"],
    words: ["no", "news", "at", "4:30", "am", "is", "good"],
    id: "No news at 4:30 a.m. is good.",
    author: "Lady Bird Johnson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["lie", "condition", "life"],
    words: ["the", "lie", "is", "a", "condition", "of", "life"],
    id: "The lie is a condition of life.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["tell", "truth", "slant"],
    words: ["tell", "the", "truth", "but", "it", "slant"],
    id: "Tell the truth, but tell it slant.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["conscience", "continual", "christmas"],
    words: ["a", "good", "conscience", "is", "continual", "christmas"],
    id: "A good conscience is a continual Christmas.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "adventure", "forgiveness"],
    words: ["life", "is", "an", "adventure", "in", "forgiveness"],
    id: "Life is an adventure in forgiveness.",
    author: "Norman Cousins",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["forgiveness", "virtue", "brave"],
    words: ["forgiveness", "is", "a", "virtue", "of", "the", "brave"],
    id: "Forgiveness is a virtue of the brave.",
    author: "Indira Gandhi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "truly", "great"],
    words: ["they're", "only", "truly", "great", "who", "are", "good"],
    id: "They're only truly great who are truly good.",
    author: "George Chapman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["fear", "always", "motivator"],
    words: ["fear", "is", "always", "a", "good", "motivator"],
    id: "Fear is always a good motivator.",
    author: "James Dyson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["before", "friendship", "faded"],
    words: ["before", "the", "flowers", "of", "friendship", "faded"],
    id: "Before the flowers of friendship faded friendship faded.",
    author: "Gertrude Stein",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "most", "patient"],
    words: ["i'm", "not", "the", "most", "patient", "of", "people"],
    id: "I'm not the most patient of people.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["art", "largely", "persistence"],
    words: ["the", "art", "of", "love", "is", "largely", "persistence"],
    id: "The art of love is largely the art of persistence.",
    author: "Albert Ellis",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["luck", "residue", "preparation"],
    words: ["good", "luck", "is", "a", "residue", "of", "preparation"],
    id: "Good luck is a residue of preparation.",
    author: "Jack Youngblood",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "friendship", "trade"],
    words: ["there", "is", "no", "friendship", "in", "trade"],
    id: "There is no friendship in trade.",
    author: "Cornelius Vanderbilt",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["remembered", "rules", "break"],
    words: ["you", "are", "remembered", "for", "the", "rules", "break"],
    id: "You are remembered for the rules you break.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["moral", "contemplate", "existence"],
    words: ["let", "us", "be", "moral", "contemplate", "existence"],
    id: "Let us be moral. Let us contemplate existence.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["best", "proof", "trust"],
    words: ["the", "best", "proof", "of", "love", "is", "trust"],
    id: "The best proof of love is trust.",
    author: "Joyce Brothers",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["didn't", "wanted", "wednesday"],
    words: ["they", "didn't", "want", "it", "good", "wanted", "wednesday"],
    id: "They didn't want it good, they wanted it Wednesday.",
    author: "Robert A. Heinlein",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["as", "wish", "seem"],
    words: ["be", "as", "you", "wish", "to", "seem"],
    id: "Be as you wish to seem.",
    author: "Socrates",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["knowledge", "knowing", "cannot"],
    words: ["knowledge", "is", "knowing", "that", "we", "cannot", "know"],
    id: "Knowledge is knowing that we cannot know.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["momentum", "everything", "league"],
    words: ["momentum", "is", "everything", "in", "this", "league"],
    id: "Momentum is everything in this league.",
    author: "Malcolm Brogdon",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "direction", "place"],
    words: ["happiness", "is", "a", "direction", "not", "place"],
    id: "Happiness is a direction, not a place.",
    author: "Sydney J. Harris",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["all", "will", "none"],
    words: ["please", "all", "and", "you", "will", "none"],
    id: "Please all, and you will please none.",
    author: "Aesop",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "remember", "understand"],
    words: ["i", "hear", "know", "see", "remember", "do", "understand"],
    id: "I hear, I know. I see, I remember. I do, I understand.",
    author: "Confucius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["best", "how", "little"],
    words: ["he", "who", "knows", "best", "how", "little"],
    id: "He who knows best knows how little he knows.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "isn't", "everything"],
    words: ["money", "doesn't", "buy", "happiness", "but", "isn't", "everything"],
    id: "Money doesn't buy happiness. But happiness isn't everything.",
    author: "Jean Seberg",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "substitute", "victory"],
    words: ["there", "is", "no", "substitute", "for", "victory"],
    id: "There is no substitute for victory.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "goal", "by-product"],
    words: ["happiness", "is", "not", "a", "goal", "it", "by-product"],
    id: "Happiness is not a goal; it is a by-product.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["health", "hope", "everything"],
    words: ["he", "who", "has", "health", "hope", "and", "everything"],
    id: "He who has health, has hope; and he who has hope, has everything.",
    author: "Thomas Carlyle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["knowledge", "prime", "hour"],
    words: ["knowledge", "is", "the", "prime", "need", "of", "hour"],
    id: "Knowledge is the prime need of the hour.",
    author: "Mary McLeod Bethune",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["first", "second", "nothing"],
    words: ["if", "you", "are", "first", "second", "nothing"],
    id: "If you are first you are first. If you are second you are nothing.",
    author: "Bill Shankly",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["silver", "lining", "cloud"],
    words: ["every", "silver", "lining", "has", "a", "cloud"],
    id: "Every silver lining has a cloud.",
    author: "Mary Kay Ash",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "forgiveness", "nature"],
    words: ["there", "is", "no", "forgiveness", "in", "nature"],
    id: "There is no forgiveness in nature.",
    author: "Ugo Betti",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["passions", "gales", "life"],
    words: ["passions", "are", "the", "gales", "of", "life"],
    id: "Passions are the gales of life.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "never", "stop"],
    words: ["i", "think", "you", "never", "stop", "learning"],
    id: "I think you never stop learning.",
    author: "Norman Foster",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["must", "things", "cannot"],
    words: ["you", "must", "do", "the", "things", "think", "cannot"],
    id: "You must do the things you think you cannot do.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["having", "geography", "boundaries"],
    words: ["love", "having", "no", "geography", "knows", "boundaries"],
    id: "Love, having no geography, knows no boundaries.",
    author: "Truman Capote",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["short", "forgetting", "long"],
    words: ["love", "is", "so", "short", "forgetting", "long"],
    id: "Love is so short, forgetting is so long.",
    author: "Pablo Neruda",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["compassion", "basis", "morality"],
    words: ["compassion", "is", "the", "basis", "of", "morality"],
    id: "Compassion is the basis of morality.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["without", "laughter", "wasted"],
    words: ["a", "day", "without", "laughter", "is", "wasted"],
    id: "A day without laughter is a day wasted.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "forever", "changing"],
    words: ["i", "am", "forever", "learning", "and", "changing"],
    id: "I am forever learning and changing.",
    author: "W. Edwards Deming",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["verdict", "world", "conclusive"],
    words: ["the", "verdict", "of", "world", "is", "conclusive"],
    id: "The verdict of the world is conclusive.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "patience", "dinosaurs"],
    words: ["i", "have", "no", "patience", "with", "dinosaurs"],
    id: "I have no patience with dinosaurs.",
    author: "Adam West",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "only", "poem"],
    words: ["the", "moment", "of", "change", "is", "only", "poem"],
    id: "The moment of change is the only poem.",
    author: "Adrienne Rich",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["failure", "part", "success"],
    words: ["failure", "is", "a", "part", "of", "success"],
    id: "Failure is a part of success.",
    author: "Hank Aaron",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["education", "transmission", "civilization"],
    words: ["education", "is", "the", "transmission", "of", "civilization"],
    id: "Education is the transmission of civilization.",
    author: "Will Durant",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["crime", "loving", "forgetting"],
    words: ["the", "crime", "of", "loving", "is", "forgetting"],
    id: "The crime of loving is forgetting.",
    author: "Maurice Chevalier",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["through", "hell", "keep"],
    words: ["if", "you're", "going", "through", "hell", "keep"],
    id: "If you're going through hell, keep going.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["creature", "word", "god"],
    words: ["every", "creature", "is", "a", "word", "of", "god"],
    id: "Every creature is a word of God.",
    author: "Meister Eckhart",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["success", "sum", "details"],
    words: ["success", "is", "the", "sum", "of", "details"],
    id: "Success is the sum of details.",
    author: "Harvey S. Firestone",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["applause", "receipt", "bill"],
    words: ["applause", "is", "a", "receipt", "not", "bill"],
    id: "Applause is a receipt, not a bill.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "athlete", "warrior"],
    words: ["i", "am", "an", "athlete", "a", "warrior"],
    id: "I am an athlete, a warrior.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["curiosity", "lust", "mind"],
    words: ["curiosity", "is", "the", "lust", "of", "mind"],
    id: "Curiosity is the lust of the mind.",
    author: "Thomas Hobbes",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["at", "91", "birthday"],
    words: ["at", "91", "every", "day", "is", "a", "birthday"],
    id: "At 91, every day is a birthday.",
    author: "Charlotte Rae",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["place", "everything", "its"],
    words: ["a", "place", "for", "everything", "in", "its"],
    id: "A place for everything, everything in its place.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["ear", "avenue", "heart"],
    words: ["the", "ear", "is", "avenue", "to", "heart"],
    id: "The ear is the avenue to the heart.",
    author: "Voltaire",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "never", "guru"],
    words: ["i", "never", "say", "am", "a", "guru"],
    id: "I never say I am a guru.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["less", "only", "more"],
    words: ["less", "is", "only", "more", "where", "no", "good"],
    id: "Less is only more where more is no good.",
    author: "Frank Lloyd Wright",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["hopelessness", "surprised", "patience"],
    words: ["hopelessness", "has", "surprised", "me", "with", "patience"],
    id: "Hopelessness has surprised me with patience.",
    author: "Margaret J. Wheatley",
    grammar: null,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["health", "ocean", "means"],
    words: ["health", "to", "the", "ocean", "means", "for", "us"],
    id: "Health to the ocean means health for us.",
    author: "Sylvia Earle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["first", "duty", "listen"],
    words: ["the", "first", "duty", "of", "love", "is", "to", "listen"],
    id: "The first duty of love is to listen.",
    author: "Paul Tillich",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["rainbow", "someone's", "cloud"],
    words: ["try", "to", "be", "a", "rainbow", "in", "someone's", "cloud"],
    id: "Try to be a rainbow in someone's cloud.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["concept", "happiness", "reorganised"],
    words: ["the", "concept", "of", "happiness", "has", "to", "be", "reorganised"],
    id: "The concept of happiness has to be reorganised.",
    author: "Daniel Kahneman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["failure", "success", "from"],
    words: ["failure", "is", "success", "if", "we", "learn", "from", "it"],
    id: "Failure is success if we learn from it.",
    author: "Malcolm Forbes",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["leadership", "indispensable", "each"],
    words: ["leadership", "and", "learning", "are", "indispensable", "to", "each", "other"],
    id: "Leadership and learning are indispensable to each other.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["god", "through", "anyone"],
    words: ["if", "god", "can", "work", "through", "me", "he", "anyone"],
    id: "If God can work through me, he can work through anyone.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["content", "seem", "really"],
    words: ["be", "content", "to", "seem", "what", "you", "really", "are"],
    id: "Be content to seem what you really are.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["art", "scream", "freedom"],
    words: ["the", "work", "of", "art", "is", "a", "scream", "freedom"],
    id: "The work of art is a scream of freedom.",
    author: "Christo",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["life", "how", "death"],
    words: ["if", "we", "don't", "know", "life", "how", "can", "death"],
    id: "If we don't know life, how can we know death?",
    author: "Confucius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["always", "make", "friends"],
    words: ["it", "is", "always", "good", "to", "make", "new", "friends"],
    id: "It is always good to make new friends.",
    author: "Jose Feliciano",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "influence", "habit"],
    words: ["there", "is", "no", "influence", "like", "the", "of", "habit"],
    id: "There is no influence like the influence of habit.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["clothes", "size", "wear"],
    words: ["buy", "clothes", "the", "size", "you", "want", "to", "wear"],
    id: "Buy clothes the size you want to wear.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["i'm", "conservative", "nut"],
    words: ["i'm", "a", "conservative", "but", "not", "nut", "about", "it"],
    id: "I'm a conservative, but I'm not a nut about it.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["ignorance", "death", "knowledge"],
    words: ["not", "ignorance", "but", "of", "is", "the", "death", "knowledge"],
    id: "Not ignorance, but ignorance of ignorance, is the death of knowledge.",
    author: "Alfred North Whitehead",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "ideal", "imagination"],
    words: ["happiness", "is", "ideal", "it", "the", "work", "of", "imagination"],
    id: "Happiness is ideal, it is the work of the imagination.",
    author: "Marquis de Sade",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["adultery", "application", "democracy"],
    words: ["adultery", "is", "the", "application", "of", "democracy", "to", "love"],
    id: "Adultery is the application of democracy to love.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "observing", "important"],
    words: ["i", "do", "think", "observing", "is", "important", "in", "learning"],
    id: "I do think observing is important in learning.",
    author: "Roger Deakins",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "more", "talk"],
    words: ["i", "like", "learning", "more", "about", "people", "to", "talk"],
    id: "I like learning more about people; I like to talk to people.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["begin", "will", "hereafter"],
    words: ["begin", "to", "be", "now", "what", "you", "will", "hereafter"],
    id: "Begin to be now what you will be hereafter.",
    author: "William James",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["secret", "happiness", "something"],
    words: ["the", "secret", "of", "happiness", "is", "something", "to", "do"],
    id: "The secret of happiness is something to do.",
    author: "John Burroughs",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["wish", "writer", "write"],
    words: ["if", "you", "wish", "to", "be", "a", "writer", "write"],
    id: "If you wish to be a writer, write.",
    author: "Epictetus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "i'll", "revolution"],
    words: ["give", "me", "time", "and", "i'll", "you", "a", "revolution"],
    id: "Give me time and I'll give you a revolution.",
    author: "Alexander McQueen",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["great", "idea", "lot"],
    words: ["to", "have", "a", "great", "idea", "lot", "of", "them"],
    id: "To have a great idea, have a lot of them.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["show", "loser", "i'll"],
    words: ["show", "me", "a", "good", "loser", "and", "i'll", "you"],
    id: "Show me a good loser, and I'll show you a loser.",
    author: "Vince Lombardi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "take", "our"],
    words: ["we", "have", "not", "the", "time", "to", "take", "our"],
    id: "We have not the time to take our time.",
    author: "Eugene Ionesco",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "by", "loving"],
    words: ["we", "can", "only", "learn", "to", "love", "by", "loving"],
    id: "We can only learn to love by loving.",
    author: "Iris Murdoch",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["started", "never", "finished"],
    words: ["what", "is", "not", "started", "today", "never", "finished", "tomorrow"],
    id: "What is not started today is never finished tomorrow.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "thing", "ever"],
    words: ["the", "only", "thing", "you", "ever", "have", "is", "now"],
    id: "The only thing you ever have is now.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["titles", "honor", "men"],
    words: ["it", "is", "not", "titles", "that", "honor", "men", "but"],
    id: "It is not titles that honor men, but men that honor titles.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["hero", "right", "change"],
    words: ["a", "hero", "is", "someone", "right", "who", "doesn't", "change"],
    id: "A hero is someone right who doesn't change.",
    author: "George Foreman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["man", "live", "also"],
    words: ["where", "a", "man", "can", "live", "he", "also", "well"],
    id: "Where a man can live, he can also live well.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["religion", "compulsory", "schools"],
    words: ["religion", "is", "compulsory", "in", "english", "schools", "you", "know"],
    id: "Religion is compulsory in English schools, you know.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["slave", "4th", "july"],
    words: ["what", "to", "the", "slave", "is", "4th", "of", "july"],
    id: "What to the Slave is the 4th of July.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["would", "loved", "loveable"],
    words: ["if", "you", "would", "be", "loved", "love", "and", "loveable"],
    id: "If you would be loved, love, and be loveable.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["measure", "measurable", "make"],
    words: ["measure", "what", "is", "measurable", "and", "make", "not", "so"],
    id: "Measure what is measurable, and make measurable what is not so.",
    author: "Galileo Galilei",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["away", "only", "keep"],
    words: ["the", "love", "we", "give", "away", "is", "only", "keep"],
    id: "The love we give away is the only love we keep.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "on", "internet"],
    words: ["i", "don't", "want", "to", "be", "on", "the", "internet"],
    id: "I don't want to be on the Internet.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "event", "process"],
    words: ["change", "is", "not", "an", "event", "it's", "a", "process"],
    id: "Change is not an event, it's a process.",
    author: "Cheryl James",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["get", "old", "avoid"],
    words: ["don't", "get", "old", "if", "you", "can", "avoid", "it"],
    id: "Don't get old, if you can avoid it.",
    author: "Billy Graham",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["comes", "strength", "thoughts"],
    words: ["with", "the", "new", "day", "comes", "strength", "and", "thoughts"],
    id: "With the new day comes new strength and new thoughts.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["pretty", "far", "seem"],
    words: ["it's", "pretty", "far", "but", "it", "doesn't", "seem", "like"],
    id: "It's pretty far, but it doesn't seem like it.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["trots", "finds", "bone"],
    words: ["the", "dog", "that", "trots", "about", "finds", "a", "bone"],
    id: "The dog that trots about finds a bone.",
    author: "Golda Meir",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["block", "community", "family"],
    words: ["the", "building", "block", "of", "every", "community", "is", "family"],
    id: "The building block of every community is family.",
    author: "Paul Singer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["cross-cultural", "abroad", "transformative"],
    words: [
      "cross-cultural",
      "learning",
      "and",
      "studying",
      "abroad",
      "can",
      "be",
      "transformative",
    ],
    id: "Cross-cultural learning and studying abroad can be transformative.",
    author: "Amal Clooney",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["nothing", "leads", "natural"],
    words: ["nothing", "leads", "to", "good", "that", "is", "not", "natural"],
    id: "Nothing leads to good that is not natural.",
    author: "Friedrich Schiller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["always", "motivational", "speeches"],
    words: ["people", "always", "need", "to", "hear", "good", "motivational", "speeches"],
    id: "People always need to hear good motivational speeches.",
    author: "Rudy Ruettiger",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["how", "dancer", "from"],
    words: ["how", "can", "we", "know", "the", "dancer", "from", "dance"],
    id: "How can we know the dancer from the dance?",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "always", "right"],
    words: ["the", "time", "is", "always", "right", "to", "do", "what"],
    id: "The time is always right to do what is right.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "tyrants", "slaves"],
    words: ["there", "can", "be", "no", "tyrants", "where", "are", "slaves"],
    id: "There can be no tyrants where there are no slaves.",
    author: "Jose Rizal",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["avoid", "criticism", "nothing"],
    words: ["to", "avoid", "criticism", "do", "nothing", "say", "and", "be"],
    id: "To avoid criticism, do nothing, say nothing, and be nothing.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["chastity", "continence", "yet"],
    words: ["give", "me", "chastity", "and", "continence", "but", "not", "yet"],
    id: "Give me chastity and continence, but not yet.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["curiosity", "wick", "candle"],
    words: ["curiosity", "is", "the", "wick", "in", "candle", "of", "learning"],
    id: "Curiosity is the wick in the candle of learning.",
    author: "William Arthur Ward",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["beginning", "compunction", "life"],
    words: ["the", "beginning", "of", "compunction", "is", "a", "new", "life"],
    id: "The beginning of compunction is the beginning of a new life.",
    author: "George Eliot",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["mind", "everything", "become"],
    words: ["the", "mind", "is", "everything", "what", "you", "think", "become"],
    id: "The mind is everything. What you think you become.",
    author: "Buddha",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "admit", "feelings"],
    words: ["i", "can", "admit", "and", "say", "that", "have", "feelings"],
    id: "I can admit and say that I have feelings.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "always", "bad"],
    words: ["change", "is", "not", "always", "bad", "can", "be", "good"],
    id: "Change is not always bad. Change can be good.",
    author: "Dries van Noten",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "change", "unfolds"],
    words: ["time", "does", "not", "change", "us", "it", "just", "unfolds"],
    id: "Time does not change us. It just unfolds us.",
    author: "Max Frisch",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["my", "movies", "watch"],
    words: ["if", "you", "don't", "like", "my", "movies", "watch", "them"],
    id: "If you don't like my movies, don't watch them.",
    author: "Dario Argento",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["another", "also", "himself"],
    words: ["he", "that", "does", "good", "to", "another", "also", "himself"],
    id: "He that does good to another does good also to himself.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["sin", "result", "collaboration"],
    words: ["every", "sin", "is", "the", "result", "of", "a", "collaboration"],
    id: "Every sin is the result of a collaboration.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["york", "comfortable", "strange"],
    words: ["for", "me", "new", "york", "is", "comfortable", "not", "strange"],
    id: "For me, New York is comfortable, not strange.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["weird", "here", "or"],
    words: ["is", "it", "weird", "in", "here", "or", "just", "me"],
    id: "Is it weird in here, or is it just me?",
    author: "Steven Wright",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["admire", "heart", "mind"],
    words: ["to", "love", "is", "admire", "with", "the", "heart", "mind"],
    id: "To love is to admire with the heart; to admire is to love with the mind.",
    author: "Theophile Gautier",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["faith", "wanting", "true"],
    words: ["faith", "not", "wanting", "to", "know", "what", "is", "true"],
    id: "Faith: not wanting to know what is true.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["change", "get", "must"],
    words: ["to", "change", "what", "you", "get", "must", "who", "are"],
    id: "To change what you get, you must change who you are.",
    author: "Vernon Howard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["too", "young", "conscience"],
    words: ["love", "is", "too", "young", "to", "know", "what", "conscience"],
    id: "Love is too young to know what conscience is.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["untroubled", "ever", "dawned"],
    words: ["no", "untroubled", "day", "has", "ever", "dawned", "for", "me"],
    id: "No untroubled day has ever dawned for me.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["quality", "act", "habit"],
    words: ["quality", "is", "not", "an", "act", "it", "a", "habit"],
    id: "Quality is not an act, it is a habit.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "look", "excuses"],
    words: ["it's", "not", "the", "time", "to", "look", "for", "excuses"],
    id: "It's not the time to look for excuses.",
    author: "Rafael Nadal",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["god", "strives", "himself"],
    words: ["god", "loves", "to", "help", "him", "who", "strives", "himself"],
    id: "God loves to help him who strives to help himself.",
    author: "Aeschylus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["problem", "yanks", "wimps"],
    words: ["the", "problem", "with", "yanks", "is", "they", "are", "wimps"],
    id: "The problem with Yanks is they are wimps.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["certain", "sense", "comfortless"],
    words: ["in", "a", "certain", "sense", "the", "good", "is", "comfortless"],
    id: "In a certain sense the Good is comfortless.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["living", "matters", "rightly"],
    words: ["it", "is", "not", "living", "that", "matters", "but", "rightly"],
    id: "It is not living that matters, but living rightly.",
    author: "Socrates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["big-hearted", "hips", "match"],
    words: ["she's", "a", "big-hearted", "girl", "with", "hips", "to", "match"],
    id: "She's a big-hearted girl with hips to match.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["would", "departing", "peacefully"],
    words: ["we", "would", "like", "to", "see", "you", "departing", "peacefully"],
    id: "We would like to see you departing peacefully.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 5,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "best", "fight"],
    words: [
      "i",
      "just",
      "want",
      "to",
      "do",
      "what",
      "best",
      "and",
      "that's",
      "fight",
      "love",
      "it",
    ],
    id: "I just want to do what I do best, and that's fight. I love it.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["friend", "one", "same"],
    words: [
      "a",
      "friend",
      "is",
      "one",
      "who",
      "knows",
      "you",
      "and",
      "loves",
      "just",
      "the",
      "same",
    ],
    id: "A friend is one who knows you and loves you just the same.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "why", "because"],
    words: [
      "i",
      "know",
      "why",
      "they",
      "don't",
      "like",
      "me",
      "because",
      "want",
      "the",
      "money",
      "have",
    ],
    id: "I know why they don't like me because they want the money I have.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "when", "harmony"],
    words: [
      "happiness",
      "is",
      "when",
      "what",
      "you",
      "think",
      "say",
      "and",
      "do",
      "are",
      "in",
      "harmony",
    ],
    id: "Happiness is when what you think, what you say, and what you do are in harmony.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["important", "latch", "onto"],
    words: [
      "it's",
      "important",
      "for",
      "us",
      "to",
      "latch",
      "onto",
      "the",
      "people",
      "that",
      "we",
      "love",
    ],
    id: "It's important for us to latch onto the people that we love.",
    author: "Connie Stevens",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["blind", "trauma", "sees"],
    words: [
      "they",
      "say",
      "that",
      "love",
      "is",
      "blind",
      "but",
      "it's",
      "trauma",
      "that's",
      "sees",
      "what",
    ],
    id: "They say that love is blind, but it's trauma that's blind. Love sees what is.",
    author: "Neil Strauss",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["art", "living", "alive"],
    words: [
      "for",
      "me",
      "art",
      "is",
      "about",
      "learning",
      "and",
      "living",
      "with",
      "people",
      "it's",
      "alive",
    ],
    id: "For me, art is about learning and about living with people. It's alive.",
    author: "Miuccia Prada",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["talk", "done", "or"],
    words: [
      "don't",
      "talk",
      "about",
      "what",
      "you",
      "have",
      "done",
      "or",
      "are",
      "going",
      "to",
      "do",
    ],
    id: "Don't talk about what you have done or what you are going to do.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["way", "treat", "become"],
    words: [
      "the",
      "way",
      "you",
      "see",
      "people",
      "is",
      "treat",
      "them",
      "and",
      "what",
      "they",
      "become",
    ],
    id: "The way you see people is the way you treat them, and the way you treat them is what they become.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["best", "way", "relax"],
    words: ["if", "you", "do", "what", "love", "it", "is", "the", "best", "way", "to", "relax"],
    id: "If you do what you love, it is the best way to relax.",
    author: "Christian Louboutin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "remedy", "more"],
    words: ["there", "is", "no", "remedy", "for", "love", "but", "to", "more"],
    id: "There is no remedy for love but to love more.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["tears", "prepare", "shed"],
    words: ["if", "you", "have", "tears", "prepare", "to", "shed", "them", "now"],
    id: "If you have tears, prepare to shed them now.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["gardening", "fun", "always"],
    words: [
      "gardening",
      "is",
      "learning",
      "that's",
      "the",
      "fun",
      "of",
      "them",
      "you're",
      "always",
    ],
    id: "Gardening is learning, learning, learning. That's the fun of them. You're always learning.",
    author: "Helen Mirren",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["right", "criticize", "heart"],
    words: ["he", "has", "a", "right", "to", "criticize", "who", "heart", "help"],
    id: "He has a right to criticize, who has a heart to help.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["never", "despair", "on"],
    words: ["never", "despair", "but", "if", "you", "do", "work", "on", "in"],
    id: "Never despair, but if you do, work on in despair.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["without", "woman", "typewriter"],
    words: ["you", "can", "do", "without", "a", "woman", "but", "not", "typewriter"],
    id: "You can do without a woman but not a typewriter.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["forward-looking", "must", "government"],
    words: ["we", "are", "a", "forward-looking", "people", "and", "must", "have", "government"],
    id: "We are a forward-looking people, and we must have a forward-looking government.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["wealth", "his", "enjoys"],
    words: ["wealth", "is", "not", "his", "that", "has", "it", "but", "enjoys"],
    id: "Wealth is not his that has it, but his that enjoys it.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["tis", "part", "whole"],
    words: ["tis", "but", "a", "part", "we", "see", "and", "not", "whole"],
    id: "Tis but a part we see, and not a whole.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["god", "nuts", "crack"],
    words: ["god", "gives", "the", "nuts", "but", "he", "does", "not", "crack", "them"],
    id: "God gives the nuts, but he does not crack them.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["giving", "education", "itself"],
    words: ["the", "giving", "of", "love", "is", "an", "education", "in", "itself"],
    id: "The giving of love is an education in itself.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["50", "woes", "one"],
    words: ["he", "who", "loves", "50", "people", "has", "woes", "no", "one"],
    id: "He who loves 50 people has 50 woes; he who loves no one has no woes.",
    author: "Buddha",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["those", "how", "teachers"],
    words: ["those", "who", "know", "how", "to", "think", "need", "no", "teachers"],
    id: "Those who know how to think need no teachers.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["look", "at", "matters"],
    words: ["it's", "not", "what", "you", "look", "at", "that", "matters", "see"],
    id: "It's not what you look at that matters, it's what you see.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["our", "bait", "used"],
    words: ["our", "money", "is", "bait", "and", "not", "to", "be", "used"],
    id: "Our money is bait money, and bait money is not to be used.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "been", "representatives"],
    words: ["i", "have", "been", "very", "happy", "in", "the", "house", "of", "representatives"],
    id: "I have been very happy in the House of Representatives.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["bragging", "back", "up"],
    words: ["it's", "not", "bragging", "if", "you", "can", "back", "it", "up"],
    id: "It's not bragging if you can back it up.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["enough", "will", "always"],
    words: ["he", "who", "knows", "that", "enough", "is", "will", "always", "have"],
    id: "He who knows that enough is enough will always have enough.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["hate", "place", "god"],
    words: ["hate", "has", "no", "place", "in", "the", "house", "of", "god"],
    id: "Hate has no place in the house of God.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["others", "practice", "compassion"],
    words: ["if", "you", "want", "others", "to", "be", "happy", "practice", "compassion"],
    id: "If you want others to be happy, practice compassion. If you want to be happy, practice compassion.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["extraordinary", "wisdom", "forerunner"],
    words: ["you", "need", "to", "have", "extraordinary", "wisdom", "be", "the", "forerunner"],
    id: "You need to have extraordinary wisdom to be the forerunner.",
    author: "Ma Huateng",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "something", "feel"],
    words: ["love", "is", "not", "only", "something", "you", "feel", "it", "do"],
    id: "Love is not only something you feel, it is something you do.",
    author: "David Wilkerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["privilege", "lifetime", "being"],
    words: ["the", "privilege", "of", "a", "lifetime", "is", "being", "who", "you", "are"],
    id: "The privilege of a lifetime is being who you are.",
    author: "Joseph Campbell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["write", "because", "something"],
    words: ["you", "don't", "write", "because", "want", "to", "say", "something", "have"],
    id: "You don't write because you want to say something, you write because you have something to say.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["when", "means", "illegal"],
    words: ["when", "the", "president", "does", "it", "that", "means", "it's", "not", "illegal"],
    id: "When the President does it, that means that it's not illegal.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["loser", "how", "win"],
    words: ["to", "be", "a", "good", "loser", "is", "learn", "how", "win"],
    id: "To be a good loser is to learn how to win.",
    author: "Carl Sandburg",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["dream", "chance", "happen"],
    words: ["if", "you", "have", "a", "dream", "give", "it", "chance", "to", "happen"],
    id: "If you have a dream, give it a chance to happen.",
    author: "Richard M. DeVos",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["hard", "individual", "japan"],
    words: ["it", "is", "hard", "to", "be", "an", "individual", "in", "japan"],
    id: "It is hard to be an individual in Japan.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["bear", "cannot", "change"],
    words: ["happy", "he", "who", "learns", "to", "bear", "what", "cannot", "change"],
    id: "Happy he who learns to bear what he cannot change.",
    author: "Friedrich Schiller",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["most", "content", "least"],
    words: ["he", "has", "the", "most", "who", "is", "content", "with", "least"],
    id: "He has the most who is most content with the least.",
    author: "Diogenes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["innocent", "enemy", "time"],
    words: ["the", "innocent", "and", "beautiful", "have", "no", "enemy", "but", "time"],
    id: "The innocent and the beautiful have no enemy but time.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["one", "usually", "done"],
    words: ["what", "one", "has", "to", "do", "usually", "can", "be", "done"],
    id: "What one has to do usually can be done.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["purpose", "our", "lives"],
    words: ["the", "purpose", "of", "our", "lives", "is", "to", "be", "happy"],
    id: "The purpose of our lives is to be happy.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 9,
    all_intersection_count: 6,
    new_words_count: 3,
  },
  {
    row_new_words: ["fight", "heaps", "momentum"],
    words: ["you", "want", "to", "fight", "people", "that", "have", "heaps", "of", "momentum"],
    id: "You want to fight people that have heaps of momentum.",
    author: "Dan Hooker",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "lie", "achievement"],
    words: ["happiness", "does", "not", "lie", "in", "but", "the", "achievement", "of", "it"],
    id: "Happiness does not lie in happiness, but in the achievement of it.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["understand", "hurting", "criticize"],
    words: [
      "you",
      "have",
      "to",
      "understand",
      "that",
      "people",
      "are",
      "hurting",
      "going",
      "criticize",
    ],
    id: "You have to understand that people that are hurting are going to criticize.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["miss", "meal", "book"],
    words: ["miss", "a", "meal", "if", "you", "have", "to", "but", "don't", "book"],
    id: "Miss a meal if you have to, but don't miss a book.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["solitude", "fine", "tell"],
    words: ["solitude", "is", "fine", "but", "you", "need", "someone", "to", "tell", "that"],
    id: "Solitude is fine, but you need someone to tell you that solitude is fine.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["enjoys", "doing", "done"],
    words: ["he", "who", "enjoys", "doing", "and", "what", "has", "done", "is", "happy"],
    id: "He who enjoys doing and enjoys what he has done is happy.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "pay", "therapy"],
    words: ["money", "doesn't", "buy", "happiness", "but", "it", "does", "pay", "for", "therapy"],
    id: "Money doesn't buy happiness, but it does pay for therapy.",
    author: "Travis Kalanick",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["when", "dry", "worth"],
    words: ["when", "the", "well", "is", "dry", "they", "know", "worth", "of", "water"],
    id: "When the well is dry, they know the worth of water.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["there", "deep", "disappointment"],
    words: ["there", "can", "be", "no", "deep", "disappointment", "where", "is", "not", "love"],
    id: "There can be no deep disappointment where there is not deep love.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "shall", "loved"],
    words: ["if", "you", "do", "not", "love", "me", "i", "shall", "be", "loved"],
    id: "If you do not love me I shall not be loved If I do not love you I shall not love.",
    author: "Samuel Beckett",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["can't", "pay", "heavily"],
    words: ["you", "can't", "buy", "love", "but", "can", "pay", "heavily", "for", "it"],
    id: "You can't buy love, but you can pay heavily for it.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["after", "all", "counts"],
    words: ["it's", "what", "you", "learn", "after", "know", "it", "all", "that", "counts"],
    id: "It's what you learn after you know it all that counts.",
    author: "John Wooden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["smarter", "than", "tell"],
    words: ["be", "smarter", "than", "other", "people", "just", "don't", "tell", "them", "so"],
    id: "Be smarter than other people, just don't tell them so.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["more", "exhausting", "than"],
    words: ["a", "day", "of", "worry", "is", "more", "exhausting", "than", "week", "work"],
    id: "A day of worry is more exhausting than a week of work.",
    author: "John Lubbock",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["pay", "i", "walk"],
    words: ["the", "pay", "is", "good", "and", "i", "can", "walk", "to", "work"],
    id: "The pay is good and I can walk to work.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "business", "yours"],
    words: ["if", "i", "love", "you", "what", "business", "is", "it", "of", "yours"],
    id: "If I love you, what business is it of yours?",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["happiness", "thing", "practiced"],
    words: ["happiness", "is", "a", "thing", "to", "be", "practiced", "like", "the", "violin"],
    id: "Happiness is a thing to be practiced, like the violin.",
    author: "John Lubbock",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["how", "something", "untill"],
    words: ["how", "do", "you", "know", "you're", "going", "to", "something", "untill", "it"],
    id: "How do you know you're going to do something, untill you do it?",
    author: "J. D. Salinger",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["important", "life", "result"],
    words: ["what", "is", "important", "in", "life", "and", "not", "the", "result", "of"],
    id: "What is important in life is life, and not the result of life.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["your", "worth", "consists"],
    words: ["your", "worth", "consists", "in", "what", "you", "are", "and", "not", "have"],
    id: "Your worth consists in what you are and not in what you have.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["servant", "will", "master"],
    words: ["he", "who", "is", "not", "a", "good", "servant", "will", "be", "master"],
    id: "He who is not a good servant will not be a good master.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["silver", "fork", "food"],
    words: ["you", "don't", "need", "a", "silver", "fork", "to", "eat", "good", "food"],
    id: "You don't need a silver fork to eat good food.",
    author: "Paul Prudhomme",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["friendship", "cultivated", "time"],
    words: ["a", "friendship", "has", "to", "be", "cultivated", "you", "have", "give", "time"],
    id: "A friendship has to be cultivated. A friendship you have to give time.",
    author: "Ruth Westheimer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["watch", "clock", "keep"],
    words: ["don't", "watch", "the", "clock", "do", "what", "it", "does", "keep", "going"],
    id: "Don't watch the clock; do what it does. Keep going.",
    author: "Sam Levenson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 7,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "responsible", "also"],
    words: ["you", "are", "not", "only", "responsible", "for", "what", "say", "but", "also", "do"],
    id: "You are not only responsible for what you say, but also for what you do not say.",
    author: "Martin Luther",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["keep", "from", "role"],
    words: ["just", "keep", "learning", "from", "the", "role", "and", "not", "go", "for", "money"],
    id: "Just keep learning from the role and not just go for the money.",
    author: "Haley Joel Osment",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "one", "smile"],
    words: [
      "if",
      "you",
      "have",
      "only",
      "one",
      "smile",
      "in",
      "give",
      "it",
      "to",
      "the",
      "people",
      "love",
    ],
    id: "If you have only one smile in you give it to the people you love.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 13,
    all_intersection_count: 10,
    new_words_count: 3,
  },
  {
    row_new_words: ["isn't", "trouble", "ain't"],
    words: [
      "it",
      "isn't",
      "what",
      "we",
      "don't",
      "know",
      "that",
      "gives",
      "us",
      "trouble",
      "it's",
      "ain't",
      "so",
    ],
    id: "It isn't what we don't know that gives us trouble, it's what we know that ain't so.",
    author: "Will Rogers",
    grammar: null,
    word_count: 13,
    all_intersection_count: 10,
    new_words_count: 3,
  },
  {
    row_new_words: ["how", "things", "change"],
    words: [
      "if",
      "you",
      "don't",
      "like",
      "how",
      "things",
      "are",
      "change",
      "it",
      "you're",
      "not",
      "a",
      "tree",
    ],
    id: "If you don't like how things are, change it! You're not a tree.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 13,
    all_intersection_count: 10,
    new_words_count: 3,
  },
  {
    row_new_words: ["faith", "believe", "reward"],
    words: [
      "faith",
      "is",
      "to",
      "believe",
      "what",
      "you",
      "do",
      "not",
      "see",
      "the",
      "reward",
      "of",
      "this",
    ],
    id: "Faith is to believe what you do not see; the reward of this faith is to see what you believe.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 13,
    all_intersection_count: 10,
    new_words_count: 3,
  },
  {
    row_new_words: ["clever", "never", "trying"],
    words: [
      "it's",
      "the",
      "people",
      "who",
      "try",
      "to",
      "be",
      "clever",
      "never",
      "are",
      "think",
      "of",
      "trying",
    ],
    id: "It's the people who try to be clever who never are; the people who are clever never think of trying to be.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 13,
    all_intersection_count: 10,
    new_words_count: 3,
  },
  {
    row_new_words: ["measure", "man", "power"],
    words: ["the", "measure", "of", "a", "man", "is", "what", "he", "does", "with", "power"],
    id: "The measure of a man is what he does with power.",
    author: "Plato",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["keep", "growing", "stagnate"],
    words: [
      "if",
      "you",
      "don't",
      "keep",
      "learning",
      "and",
      "growing",
      "you're",
      "going",
      "to",
      "stagnate",
    ],
    id: "If you don't keep learning and growing, you're going to stagnate.",
    author: "Bruce Cockburn",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["afraid", "up", "great"],
    words: ["don't", "be", "afraid", "to", "give", "up", "the", "good", "go", "for", "great"],
    id: "Don't be afraid to give up the good to go for the great.",
    author: "John D. Rockefeller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["justice", "would", "impiety"],
    words: ["not", "to", "help", "justice", "in", "her", "need", "would", "be", "an", "impiety"],
    id: "Not to help justice in her need would be an impiety.",
    author: "Plato",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["right", "relation", "creation"],
    words: [
      "right",
      "now",
      "we",
      "don't",
      "have",
      "a",
      "very",
      "good",
      "relation",
      "with",
      "creation",
    ],
    id: "Right now, we don't have a very good relation with creation.",
    author: "Pope Francis",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["only", "tears", "luxury"],
    words: ["it", "is", "only", "to", "the", "happy", "that", "tears", "are", "a", "luxury"],
    id: "It is only to the happy that tears are a luxury.",
    author: "Thomas Moore",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["man", "dissatisfied", "himself"],
    words: [
      "the",
      "man",
      "who",
      "is",
      "dissatisfied",
      "with",
      "himself",
      "what",
      "can",
      "he",
      "do",
    ],
    id: "The man who is dissatisfied with himself, what can he do?",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["more", "there's", "hate"],
    words: ["the", "more", "people", "love", "you", "there's", "going", "to", "be", "that", "hate"],
    id: "The more people love you, the more there's going to be people that hate you.",
    author: "Kylie Jenner",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["time", "relax", "when"],
    words: ["the", "time", "to", "relax", "is", "when", "you", "don't", "have", "for", "it"],
    id: "The time to relax is when you don't have time for it.",
    author: "Sydney J. Harris",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["terrible", "thing", "vision"],
    words: ["it", "is", "a", "terrible", "thing", "to", "see", "and", "have", "no", "vision"],
    id: "It is a terrible thing to see and have no vision.",
    author: "Helen Keller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["action", "consequence", "always"],
    words: [
      "every",
      "action",
      "has",
      "a",
      "consequence",
      "so",
      "always",
      "try",
      "to",
      "be",
      "good",
    ],
    id: "Every action has a consequence, so always try to be good.",
    author: "Richard Eyre",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["first", "meaning", "then"],
    words: ["first", "learn", "the", "meaning", "of", "what", "you", "say", "and", "then", "speak"],
    id: "First learn the meaning of what you say, and then speak.",
    author: "Epictetus",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["i", "fall", "situations"],
    words: [
      "i",
      "fall",
      "in",
      "love",
      "every",
      "day",
      "not",
      "with",
      "people",
      "but",
      "situations",
    ],
    id: "I fall in love every day. Not with people but with situations.",
    author: "Amy Winehouse",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["meaning", "life", "desire"],
    words: ["what", "do", "you", "want", "a", "meaning", "for", "life", "is", "desire", "not"],
    id: "What do you want a meaning for? Life is a desire, not a meaning.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["heavenly", "minded", "earthly"],
    words: [
      "some",
      "people",
      "are",
      "so",
      "heavenly",
      "minded",
      "that",
      "they",
      "no",
      "earthly",
      "good",
    ],
    id: "Some people are so heavenly minded that they are no earthly good.",
    author: "Oliver Wendell Holmes, Sr.",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["purchased", "affection", "price"],
    words: ["love", "is", "not", "to", "be", "purchased", "and", "affection", "has", "no", "price"],
    id: "Love is not to be purchased, and affection has no price.",
    author: "St. Jerome",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["fun", "same", "decade"],
    words: ["it", "is", "fun", "to", "be", "in", "the", "same", "decade", "with", "you"],
    id: "It is fun to be in the same decade with you.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["can't", "reputation", "on"],
    words: ["you", "can't", "build", "a", "reputation", "on", "what", "are", "going", "to", "do"],
    id: "You can't build a reputation on what you are going to do.",
    author: "Henry Ford",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["future", "promised", "one"],
    words: ["go", "for", "it", "now", "the", "future", "is", "promised", "to", "no", "one"],
    id: "Go for it now. The future is promised to no one.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 8,
    new_words_count: 3,
  },
  {
    row_new_words: ["hire", "man", "your"],
    words: [
      "do",
      "not",
      "hire",
      "a",
      "man",
      "who",
      "does",
      "your",
      "work",
      "for",
      "money",
      "but",
      "him",
      "it",
      "love",
      "of",
    ],
    id: "Do not hire a man who does your work for money, but him who does it for love of it.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 16,
    all_intersection_count: 13,
    new_words_count: 3,
  },
  {
    row_new_words: ["resign", "grace", "all"],
    words: [
      "learn",
      "to",
      "be",
      "what",
      "you",
      "are",
      "and",
      "resign",
      "with",
      "a",
      "good",
      "grace",
      "all",
      "that",
      "not",
    ],
    id: "Learn to... be what you are, and learn to resign with a good grace all that you are not.",
    author: "Henri Frederic Amiel",
    grammar: null,
    word_count: 15,
    all_intersection_count: 12,
    new_words_count: 3,
  },
  {
    row_new_words: ["everything", "life", "will", "get"],
    words: [
      "you",
      "can",
      "have",
      "everything",
      "in",
      "life",
      "want",
      "if",
      "will",
      "just",
      "help",
      "other",
      "people",
      "get",
      "what",
      "they",
    ],
    id: "You can have everything in life you want, if you will just help other people get what they want.",
    author: "Zig Ziglar",
    grammar: null,
    word_count: 16,
    all_intersection_count: 12,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "embrace", "alternative", "disaster"],
    words: [
      "some",
      "people",
      "don't",
      "like",
      "change",
      "but",
      "you",
      "need",
      "to",
      "embrace",
      "if",
      "the",
      "alternative",
      "is",
      "disaster",
    ],
    id: "Some people don't like change, but you need to embrace change if the alternative is disaster.",
    author: "Elon Musk",
    grammar: null,
    word_count: 15,
    all_intersection_count: 11,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "others", "much", "character"],
    words: [
      "i",
      "care",
      "not",
      "what",
      "others",
      "think",
      "of",
      "do",
      "but",
      "very",
      "much",
      "about",
      "that",
      "is",
      "character",
    ],
    id: "I care not what others think of what I do, but I care very much about what I think of what I do! That is character!",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 15,
    all_intersection_count: 11,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "short", "must", "done"],
    words: [
      "life",
      "is",
      "very",
      "short",
      "and",
      "what",
      "we",
      "have",
      "to",
      "do",
      "must",
      "be",
      "done",
      "in",
      "the",
      "now",
    ],
    id: "Life is very short and what we have to do must be done in the now.",
    author: "Audre Lorde",
    grammar: null,
    word_count: 16,
    all_intersection_count: 12,
    new_words_count: 4,
  },
  {
    row_new_words: ["course", "great", "because", "important"],
    words: [
      "of",
      "course",
      "to",
      "have",
      "money",
      "is",
      "just",
      "great",
      "because",
      "you",
      "can",
      "do",
      "what",
      "think",
      "important",
    ],
    id: "Of course, to have money is just great because you can do what you think is important to you.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 15,
    all_intersection_count: 11,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "football", "all", "green"],
    words: [
      "what",
      "a",
      "great",
      "day",
      "for",
      "football",
      "all",
      "we",
      "need",
      "is",
      "some",
      "green",
      "grass",
      "and",
      "ball",
    ],
    id: "What a great day for football, all we need is some green grass and a ball.",
    author: "Bill Shankly",
    grammar: null,
    word_count: 15,
    all_intersection_count: 11,
    new_words_count: 4,
  },
  {
    row_new_words: ["enough", "busy", "ants", "question"],
    words: [
      "it",
      "is",
      "not",
      "enough",
      "to",
      "be",
      "busy",
      "so",
      "are",
      "the",
      "ants",
      "question",
      "what",
      "we",
      "about",
    ],
    id: "It is not enough to be busy. So are the ants. The question is: What are we busy about?",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 15,
    all_intersection_count: 11,
    new_words_count: 4,
  },
  {
    row_new_words: ["motivation", "art", "getting", "because"],
    words: [
      "motivation",
      "is",
      "the",
      "art",
      "of",
      "getting",
      "people",
      "to",
      "do",
      "what",
      "you",
      "want",
      "them",
      "because",
      "they",
      "it",
    ],
    id: "Motivation is the art of getting people to do what you want them to do because they want to do it.",
    author: "Dwight D. Eisenhower",
    grammar: null,
    word_count: 16,
    all_intersection_count: 12,
    new_words_count: 4,
  },
  {
    row_new_words: ["news", "computers", "tell", "bad"],
    words: [
      "the",
      "good",
      "news",
      "about",
      "computers",
      "is",
      "that",
      "they",
      "do",
      "what",
      "you",
      "tell",
      "them",
      "to",
      "bad",
    ],
    id: "The good news about computers is that they do what you tell them to do. The bad news is that they do what you tell them to do.",
    author: "Ted Nelson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 11,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "football", "intensity", "liverpool"],
    words: [
      "i",
      "love",
      "football",
      "and",
      "the",
      "intensity",
      "of",
      "in",
      "liverpool",
      "this",
      "is",
      "what",
      "very",
      "good",
      "for",
      "me",
    ],
    id: "I love football and the intensity of football in Liverpool, this is what is very good for me.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 16,
    all_intersection_count: 12,
    new_words_count: 4,
  },
  {
    row_new_words: ["how", "change", "their", "minds"],
    words: [
      "how",
      "can",
      "people",
      "change",
      "their",
      "minds",
      "about",
      "us",
      "if",
      "they",
      "don't",
      "know",
      "who",
      "we",
      "are",
    ],
    id: "How can people change their minds about us if they don't know who we are?",
    author: "Harvey Milk",
    grammar: null,
    word_count: 15,
    all_intersection_count: 11,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "martial", "art", "respect"],
    words: [
      "when",
      "you",
      "are",
      "learning",
      "about",
      "a",
      "martial",
      "art",
      "it",
      "is",
      "respect",
    ],
    id: "When you are learning about a martial art, it is about respect.",
    author: "Jackie Chan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["realize", "understand", "virtue", "defect"],
    words: [
      "to",
      "realize",
      "that",
      "you",
      "do",
      "not",
      "understand",
      "is",
      "a",
      "virtue",
      "defect",
    ],
    id: "To realize that you do not understand is a virtue; Not to realize that you do not understand is a defect.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["art", "reproduce", "rather", "makes"],
    words: ["art", "does", "not", "reproduce", "what", "we", "see", "rather", "it", "makes", "us"],
    id: "Art does not reproduce what we see; rather, it makes us see.",
    author: "Paul Klee",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "sometimes", "things", "offend"],
    words: [
      "people",
      "are",
      "going",
      "to",
      "say",
      "what",
      "they",
      "i",
      "know",
      "sometimes",
      "things",
      "offend",
    ],
    id: "People are going to say what they say. I know sometimes I say things; I offend people.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["mental", "health", "counselors", "one"],
    words: [
      "we",
      "need",
      "mental",
      "health",
      "counselors",
      "in",
      "every",
      "school",
      "that",
      "needs",
      "one",
    ],
    id: "We need mental health counselors in every school that needs one.",
    author: "Tim Ryan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["more", "yourself", "patience", "others"],
    words: [
      "the",
      "more",
      "you",
      "know",
      "yourself",
      "patience",
      "have",
      "for",
      "what",
      "see",
      "in",
      "others",
    ],
    id: "The more you know yourself, the more patience you have for what you see in others.",
    author: "Erik Erikson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["anything", "stick", "long", "enough"],
    words: ["we", "can", "do", "anything", "want", "to", "if", "stick", "it", "long", "enough"],
    id: "We can do anything we want to if we stick to it long enough.",
    author: "Helen Keller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["will", "happiness", "part", "key"],
    words: ["money", "will", "not", "give", "you", "happiness", "it's", "part", "of", "the", "key"],
    id: "Money will not give you happiness; it's part of the key.",
    author: "Logic",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["even", "minority", "one", "truth"],
    words: ["even", "if", "you", "are", "a", "minority", "of", "one", "the", "truth", "is"],
    id: "Even if you are a minority of one, the truth is the truth.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "loser's", "locker", "room"],
    words: ["no", "one", "knows", "what", "to", "say", "in", "the", "loser's", "locker", "room"],
    id: "No one knows what to say in the loser's locker room.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["whatever", "things", "tell", "nothing"],
    words: [
      "whatever",
      "good",
      "things",
      "people",
      "say",
      "of",
      "us",
      "they",
      "tell",
      "nothing",
      "new",
    ],
    id: "Whatever good things people say of us, they tell us nothing new.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["flattered", "worthy", "o", "flatterer"],
    words: [
      "he",
      "that",
      "loves",
      "to",
      "be",
      "flattered",
      "is",
      "worthy",
      "o",
      "the",
      "flatterer",
    ],
    id: "He that loves to be flattered is worthy o' the flatterer.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["always", "those", "admire", "whom"],
    words: ["we", "always", "love", "those", "who", "admire", "us", "but", "do", "not", "whom"],
    id: "We always love those who admire us, but we do not always love those whom we admire.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "learned", "those", "enough"],
    words: ["i", "have", "learned", "that", "to", "be", "with", "those", "like", "is", "enough"],
    id: "I have learned that to be with those I like is enough.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "being", "person", "treating"],
    words: [
      "success",
      "to",
      "me",
      "is",
      "being",
      "a",
      "good",
      "person",
      "treating",
      "people",
      "well",
    ],
    id: "Success to me is being a good person, treating people well.",
    author: "David LaChapelle",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["absolutely", "i", "opportunity", "chance"],
    words: [
      "absolutely",
      "i",
      "think",
      "every",
      "day",
      "is",
      "a",
      "new",
      "opportunity",
      "and",
      "chance",
    ],
    id: "Absolutely, I think every day is a new day, every day is a new opportunity, and every day is a new chance.",
    author: "Jennifer Winget",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "happiness", "at", "expense"],
    words: [
      "there",
      "is",
      "no",
      "happiness",
      "for",
      "people",
      "at",
      "the",
      "expense",
      "of",
      "other",
    ],
    id: "There is no happiness for people at the expense of other people.",
    author: "Anwar Sadat",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["prevent", "crime", "when", "encourages"],
    words: ["he", "who", "does", "not", "prevent", "a", "crime", "when", "can", "encourages", "it"],
    id: "He who does not prevent a crime when he can, encourages it.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["on", "court", "another", "side"],
    words: [
      "what",
      "people",
      "see",
      "on",
      "court",
      "is",
      "another",
      "side",
      "of",
      "me",
      "it's",
      "not",
    ],
    id: "What people see on court is another side of me; it's not me.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "attitudes", "start", "behavior"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "change",
      "attitudes",
      "start",
      "with",
      "a",
      "in",
      "behavior",
    ],
    id: "If you want to change attitudes, start with a change in behavior.",
    author: "William Glasser",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["dead", "world", "belief", "another"],
    words: ["he", "is", "dead", "in", "this", "world", "who", "has", "no", "belief", "another"],
    id: "He is dead in this world who has no belief in another.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "power", "right", "person"],
    words: [
      "knowledge",
      "is",
      "power",
      "if",
      "you",
      "know",
      "it",
      "about",
      "the",
      "right",
      "person",
    ],
    id: "Knowledge is power, if you know it about the right person.",
    author: "Ethel Watts Mumford",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["okay", "because", "any", "feelings"],
    words: [
      "it's",
      "okay",
      "to",
      "eat",
      "fish",
      "because",
      "they",
      "don't",
      "have",
      "any",
      "feelings",
    ],
    id: "It's okay to eat fish because they don't have any feelings.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "days", "bad", "one"],
    words: ["there", "are", "good", "days", "and", "bad", "this", "is", "one", "of", "them"],
    id: "There are good days and there are bad days, and this is one of them.",
    author: "Lawrence Welk",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["liberty", "right", "i", "license"],
    words: ["liberty", "is", "the", "right", "to", "do", "what", "i", "like", "license", "you"],
    id: "Liberty is the right to do what I like; license, the right to do what you like.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "fell", "maldives", "place"],
    words: [
      "i",
      "fell",
      "in",
      "love",
      "with",
      "the",
      "maldives",
      "it's",
      "a",
      "beautiful",
      "place",
    ],
    id: "I fell in love with the Maldives. It's a beautiful place!",
    author: "Tridha Choudhury",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "one", "freedom", "mind"],
    words: ["i", "know", "but", "one", "freedom", "and", "that", "is", "the", "of", "mind"],
    id: "I know but one freedom, and that is the freedom of the mind.",
    author: "Antoine de Saint-Exupery",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["pursuit", "happiness", "find", "fulfillment"],
    words: [
      "it",
      "is",
      "not",
      "in",
      "the",
      "pursuit",
      "of",
      "happiness",
      "that",
      "we",
      "find",
      "fulfillment",
    ],
    id: "It is not in the pursuit of happiness that we find fulfillment, it is in the happiness of pursuit.",
    author: "Denis Waitley",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "as", "surround", "yourself"],
    words: [
      "you're",
      "only",
      "as",
      "good",
      "the",
      "people",
      "who",
      "you",
      "surround",
      "yourself",
      "with",
    ],
    id: "You're only as good as the people who you surround yourself with.",
    author: "Marcela Valladolid",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "answer", "makes", "difference"],
    words: ["money", "is", "not", "the", "only", "answer", "but", "it", "makes", "a", "difference"],
    id: "Money is not the only answer, but it makes a difference.",
    author: "Barack Obama",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["could", "paint", "hand", "eye"],
    words: ["if", "we", "could", "but", "paint", "with", "the", "hand", "what", "see", "eye"],
    id: "If we could but paint with the hand what we see with the eye.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["characteristic", "wisdom", "desperate", "things"],
    words: [
      "it",
      "is",
      "a",
      "characteristic",
      "of",
      "wisdom",
      "not",
      "to",
      "do",
      "desperate",
      "things",
    ],
    id: "It is a characteristic of wisdom not to do desperate things.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["because", "things", "difficult", "dare"],
    words: [
      "it",
      "is",
      "not",
      "because",
      "things",
      "are",
      "difficult",
      "that",
      "we",
      "do",
      "dare",
      "they",
    ],
    id: "It is not because things are difficult that we do not dare, it is because we do not dare that they are difficult.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["impossible", "repent", "sin", "exist"],
    words: [
      "it",
      "is",
      "impossible",
      "to",
      "repent",
      "of",
      "love",
      "the",
      "sin",
      "does",
      "not",
      "exist",
    ],
    id: "It is impossible to repent of love. The sin of love does not exist.",
    author: "Muriel Spark",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "means", "stupid", "i"],
    words: ["freedom", "means", "the", "to", "be", "stupid", "and", "that's", "what", "i", "want"],
    id: "Freedom means the freedom to be stupid, and that's what I want.",
    author: "Penn Jillette",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["movies", "enough", "make", "one"],
    words: ["if", "you", "just", "love", "movies", "enough", "can", "make", "a", "good", "one"],
    id: "If you just love movies enough, you can make a good one.",
    author: "Quentin Tarantino",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "experience", "being", "person"],
    words: [
      "for",
      "me",
      "life",
      "is",
      "about",
      "experience",
      "and",
      "being",
      "a",
      "good",
      "person",
    ],
    id: "For me, life is about experience and being a good person.",
    author: "Chris Hemsworth",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["act", "as", "makes", "difference"],
    words: ["act", "as", "if", "what", "you", "do", "makes", "a", "difference", "it", "does"],
    id: "Act as if what you do makes a difference. It does.",
    author: "William James",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["most", "job", "world", "happiness"],
    words: [
      "it's",
      "the",
      "most",
      "beautiful",
      "job",
      "in",
      "world",
      "to",
      "give",
      "happiness",
      "people",
    ],
    id: "It's the most beautiful job in the world to give happiness to people.",
    author: "Hubert de Givenchy",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "always", "brings", "place"],
    words: ["change", "is", "always", "good", "it", "brings", "you", "to", "a", "new", "place"],
    id: "Change is always good. It brings you to a new place.",
    author: "Lee Ranaldo",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["art", "order", "die", "truth"],
    words: ["we", "have", "art", "in", "order", "not", "to", "die", "of", "the", "truth"],
    id: "We have art in order not to die of the truth.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["which", "bee-hive", "cannot", "bees"],
    words: ["that", "which", "is", "not", "good", "for", "the", "bee-hive", "cannot", "be", "bees"],
    id: "That which is not good for the bee-hive cannot be good for the bees.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["required", "one", "necessity", "soul"],
    words: ["money", "is", "not", "required", "to", "buy", "one", "necessity", "of", "the", "soul"],
    id: "Money is not required to buy one necessity of the soul.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'd", "really", "control", "machines"],
    words: [
      "what",
      "i'd",
      "really",
      "like",
      "to",
      "control",
      "is",
      "not",
      "machines",
      "but",
      "people",
    ],
    id: "What I'd really like to control is not machines, but people.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["thing", "or", "can't", "right"],
    words: ["if", "you", "think", "can", "do", "a", "thing", "or", "can't", "you're", "right"],
    id: "If you think you can do a thing or think you can't do a thing, you're right.",
    author: "Henry Ford",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "meaning", "true", "knowledge"],
    words: [
      "to",
      "know",
      "is",
      "that",
      "you",
      "nothing",
      "the",
      "meaning",
      "of",
      "true",
      "knowledge",
    ],
    id: "To know, is to know that you know nothing. That is the meaning of true knowledge.",
    author: "Socrates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["trouble", "referees", "rules", "game"],
    words: [
      "the",
      "trouble",
      "with",
      "referees",
      "is",
      "that",
      "they",
      "know",
      "rules",
      "but",
      "do",
      "not",
      "game",
    ],
    id: "The trouble with referees is that they know the rules, but they do not know the game.",
    author: "Bill Shankly",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "only", "way", "each"],
    words: [
      "love",
      "is",
      "all",
      "we",
      "have",
      "the",
      "only",
      "way",
      "that",
      "each",
      "can",
      "help",
      "other",
    ],
    id: "Love is all we have, the only way that each can help the other.",
    author: "Euripides",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["financial", "freedom", "available", "those"],
    words: [
      "financial",
      "freedom",
      "is",
      "available",
      "to",
      "those",
      "who",
      "learn",
      "about",
      "it",
      "and",
      "work",
      "for",
    ],
    id: "Financial freedom is available to those who learn about it and work for it.",
    author: "Robert Kiyosaki",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["example", "mankind", "will", "at"],
    words: [
      "example",
      "is",
      "the",
      "school",
      "of",
      "mankind",
      "and",
      "they",
      "will",
      "learn",
      "at",
      "no",
      "other",
    ],
    id: "Example is the school of mankind, and they will learn at no other.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "goes", "on", "whatever"],
    words: [
      "time",
      "goes",
      "on",
      "so",
      "whatever",
      "you're",
      "going",
      "to",
      "do",
      "it",
      "now",
      "don't",
      "wait",
    ],
    id: "Time goes on. So whatever you're going to do, do it. Do it now. Don't wait.",
    author: "Robert De Niro",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["health", "expensive", "til", "free"],
    words: [
      "if",
      "you",
      "think",
      "health",
      "care",
      "is",
      "expensive",
      "now",
      "just",
      "wait",
      "til",
      "it's",
      "free",
    ],
    id: "If you think health care is expensive now, just wait 'til it's free.",
    author: "P. J. O'Rourke",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "movies", "theater", "event"],
    words: [
      "i",
      "do",
      "like",
      "to",
      "go",
      "movies",
      "be",
      "in",
      "the",
      "theater",
      "event",
      "of",
      "it",
    ],
    id: "I do like to go to movies. I like to be in the theater. I like the event of it.",
    author: "Jane Lynch",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["real", "freedom", "when", "look"],
    words: [
      "you",
      "have",
      "a",
      "real",
      "freedom",
      "when",
      "don't",
      "to",
      "worry",
      "about",
      "what",
      "look",
      "like",
    ],
    id: "You have a real freedom when you don't have to worry about what you look like.",
    author: "Charlotte Hope",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["can't", "inspiration", "after", "club"],
    words: [
      "you",
      "can't",
      "wait",
      "for",
      "inspiration",
      "have",
      "to",
      "go",
      "after",
      "it",
      "with",
      "a",
      "club",
    ],
    id: "You can't wait for inspiration. You have to go after it with a club.",
    author: "Jack London",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "afraid", "seen", "yesterday"],
    words: [
      "i",
      "am",
      "not",
      "afraid",
      "of",
      "tomorrow",
      "for",
      "have",
      "seen",
      "yesterday",
      "and",
      "love",
      "today",
    ],
    id: "I am not afraid of tomorrow, for I have seen yesterday and I love today!",
    author: "William Allen White",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["been", "republican", "how", "democrat"],
    words: [
      "you",
      "have",
      "to",
      "been",
      "a",
      "republican",
      "know",
      "how",
      "good",
      "it",
      "is",
      "be",
      "democrat",
    ],
    id: "You have to have been a Republican to know how good it is to be a Democrat.",
    author: "Jackie Kennedy",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["barbie", "popular", "why", "friends"],
    words: [
      "if",
      "barbie",
      "is",
      "so",
      "popular",
      "why",
      "do",
      "you",
      "have",
      "to",
      "buy",
      "her",
      "friends",
    ],
    id: "If Barbie is so popular, why do you have to buy her friends?",
    author: "Steven Wright",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "short", "nobody's", "hate"],
    words: [
      "love",
      "who",
      "you",
      "want",
      "to",
      "life",
      "is",
      "short",
      "nobody's",
      "going",
      "hate",
      "for",
      "it",
    ],
    id: "Love who you want to love. Life is short. Nobody's going to hate you for it.",
    author: "Shane Dawson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["kill", "name", "god", "blasphemy"],
    words: [
      "to",
      "say",
      "that",
      "you",
      "can",
      "kill",
      "in",
      "the",
      "name",
      "of",
      "god",
      "is",
      "blasphemy",
    ],
    id: "To say that you can kill in the name of God is blasphemy.",
    author: "Pope Francis",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["serious", "all", "time", "job"],
    words: [
      "you",
      "don't",
      "have",
      "to",
      "be",
      "serious",
      "all",
      "the",
      "time",
      "do",
      "a",
      "good",
      "job",
    ],
    id: "You don't have to be serious all the time to do a good job.",
    author: "Simone Biles",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["anywhere", "i", "suffering", "doing"],
    words: [
      "anywhere",
      "i",
      "see",
      "suffering",
      "that",
      "is",
      "where",
      "want",
      "to",
      "be",
      "doing",
      "what",
      "can",
    ],
    id: "Anywhere I see suffering, that is where I want to be, doing what I can.",
    author: "Princess Diana",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["question", "direct", "i'm", "answer"],
    words: [
      "it's",
      "a",
      "very",
      "good",
      "question",
      "direct",
      "and",
      "i'm",
      "not",
      "going",
      "to",
      "answer",
      "it",
    ],
    id: "It's a very good question, very direct, and I'm not going to answer it.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["getting", "merely", "side", "liking"],
    words: [
      "getting",
      "people",
      "to",
      "like",
      "you",
      "is",
      "merely",
      "the",
      "other",
      "side",
      "of",
      "liking",
      "them",
    ],
    id: "Getting people to like you is merely the other side of liking them.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "skate", "puck", "been"],
    words: [
      "i",
      "skate",
      "to",
      "where",
      "the",
      "puck",
      "is",
      "going",
      "be",
      "not",
      "it",
      "has",
      "been",
    ],
    id: "I skate to where the puck is going to be, not where it has been.",
    author: "Wayne Gretzky",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "friend", "his", "return"],
    words: [
      "no",
      "one",
      "is",
      "a",
      "friend",
      "to",
      "his",
      "who",
      "does",
      "not",
      "love",
      "in",
      "return",
    ],
    id: "No one is a friend to his friend who does not love in return.",
    author: "Plato",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["world", "full", "willing", "rest"],
    words: [
      "the",
      "world",
      "is",
      "full",
      "of",
      "willing",
      "people",
      "some",
      "to",
      "work",
      "rest",
      "let",
      "them",
    ],
    id: "The world is full of willing people; some willing to work, the rest willing to let them.",
    author: "Robert Frost",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["looking", "when", "come", "from"],
    words: [
      "you",
      "don't",
      "have",
      "to",
      "go",
      "looking",
      "for",
      "love",
      "when",
      "it's",
      "where",
      "come",
      "from",
    ],
    id: "You don't have to go looking for love when it's where you come from.",
    author: "Werner Erhard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "always", "present", "seed"],
    words: [
      "he",
      "is",
      "a",
      "man",
      "who",
      "to",
      "be",
      "the",
      "fruit",
      "always",
      "present",
      "in",
      "seed",
    ],
    id: "He is a man, who is to be a man, the fruit is always present in the seed.",
    author: "Tertullian",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["you'll", "never", "your", "life"],
    words: [
      "if",
      "you",
      "do",
      "what",
      "love",
      "you'll",
      "never",
      "work",
      "a",
      "day",
      "in",
      "your",
      "life",
    ],
    id: "If you do what you love, you'll never work a day in your life.",
    author: "Marc Anthony",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "long", "goodness", "demand"],
    words: [
      "no",
      "one",
      "can",
      "be",
      "good",
      "for",
      "long",
      "if",
      "goodness",
      "is",
      "not",
      "in",
      "demand",
    ],
    id: "No one can be good for long if goodness is not in demand.",
    author: "Bertolt Brecht",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["treat", "art", "prince", "first"],
    words: [
      "treat",
      "a",
      "work",
      "of",
      "art",
      "like",
      "prince",
      "let",
      "it",
      "speak",
      "to",
      "you",
      "first",
    ],
    id: "Treat a work of art like a prince. Let it speak to you first.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 9,
    new_words_count: 4,
  },
  {
    row_new_words: ["successful", "simple", "doing", "believe"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "be",
      "successful",
      "it's",
      "just",
      "this",
      "simple",
      "know",
      "what",
      "are",
      "doing",
      "love",
      "and",
      "believe",
      "in",
    ],
    id: "If you want to be successful, it's just this simple. Know what you are doing. Love what you are doing. And believe in what you are doing.",
    author: "Will Rogers",
    grammar: null,
    word_count: 18,
    all_intersection_count: 14,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "all", "times", "one"],
    words: [
      "this",
      "time",
      "like",
      "all",
      "times",
      "is",
      "a",
      "very",
      "good",
      "one",
      "if",
      "we",
      "but",
      "know",
      "what",
      "to",
      "do",
      "with",
      "it",
    ],
    id: "This time, like all times, is a very good one, if we but know what to do with it.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 19,
    all_intersection_count: 15,
    new_words_count: 4,
  },
  {
    row_new_words: ["culture", "live", "attempts", "exclusive"],
    words: ["no", "culture", "can", "live", "if", "it", "attempts", "to", "be", "exclusive"],
    id: "No culture can live if it attempts to be exclusive.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["even", "when", "physical", "hardships"],
    words: ["even", "when", "we", "have", "physical", "hardships", "can", "be", "very", "happy"],
    id: "Even when we have physical hardships, we can be very happy.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["remember", "times", "i", "bad"],
    words: ["people", "say", "we", "remember", "the", "good", "times", "well", "i", "bad"],
    id: "People say, 'We remember the good times.' Well I remember the bad times.",
    author: "Ricky Hatton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["experience", "or", "bad", "from"],
    words: ["every", "experience", "good", "or", "bad", "you", "have", "to", "learn", "from"],
    id: "Every experience, good or bad, you have to learn from.",
    author: "Patrick Mahomes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "everything", "life", "experience"],
    words: [
      "i",
      "think",
      "everything",
      "you",
      "do",
      "in",
      "life",
      "is",
      "a",
      "learning",
      "experience",
    ],
    id: "I think everything you do in life is a learning experience.",
    author: "Emeraude Toubia",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["truth", "beginning", "gods", "man"],
    words: ["truth", "is", "the", "beginning", "of", "every", "good", "to", "gods", "and", "man"],
    id: "Truth is the beginning of every good to the gods, and of every good to man.",
    author: "Plato",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["nice", "personality", "human", "being"],
    words: ["you", "have", "a", "nice", "personality", "but", "not", "for", "human", "being"],
    id: "You have a nice personality, but not for a human being.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "without", "blossoms", "or"],
    words: ["life", "without", "love", "is", "like", "a", "tree", "blossoms", "or", "fruit"],
    id: "Life without love is like a tree without blossoms or fruit.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["cannot", "lonely", "person", "alone"],
    words: [
      "you",
      "cannot",
      "be",
      "lonely",
      "if",
      "like",
      "the",
      "person",
      "you're",
      "alone",
      "with",
    ],
    id: "You cannot be lonely if you like the person you're alone with.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "problems", "out", "game"],
    words: ["the", "man", "who", "has", "no", "problems", "is", "out", "of", "game"],
    id: "The man who has no problems is out of the game.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["feeling", "happiness", "willingness", "sacrifice"],
    words: [
      "love",
      "is",
      "not",
      "a",
      "feeling",
      "of",
      "happiness",
      "willingness",
      "to",
      "sacrifice",
    ],
    id: "Love is not a feeling of happiness. Love is a willingness to sacrifice.",
    author: "Michael Novak",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "must", "also", "something"],
    words: ["not", "only", "must", "we", "be", "good", "but", "also", "for", "something"],
    id: "Not only must we be good, but we must also be good for something.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["universe", "expanding", "should", "traffic"],
    words: [
      "they",
      "say",
      "the",
      "universe",
      "is",
      "expanding",
      "that",
      "should",
      "help",
      "with",
      "traffic",
    ],
    id: "They say the universe is expanding. That should help with the traffic.",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["fault", "amended", "never", "comes"],
    words: [
      "tomorrow",
      "every",
      "fault",
      "is",
      "to",
      "be",
      "amended",
      "but",
      "that",
      "never",
      "comes",
    ],
    id: "Tomorrow, every Fault is to be amended; but that Tomorrow never comes.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "murder", "order", "condemn"],
    words: ["i", "don't", "have", "to", "see", "a", "murder", "in", "order", "condemn"],
    id: "I don't have to see a murder in order to condemn murder.",
    author: "Billy Graham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["makes", "life", "dreary", "motive"],
    words: ["what", "makes", "life", "dreary", "is", "the", "want", "of", "a", "motive"],
    id: "What makes life dreary is the want of a motive.",
    author: "George Eliot",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["men", "at", "cheating", "lying"],
    words: ["some", "men", "are", "just", "very", "good", "at", "cheating", "and", "lying"],
    id: "Some men are just very good at cheating and lying.",
    author: "Coleen Nolan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["o", "lord", "pure", "yet"],
    words: ["o", "lord", "help", "me", "to", "be", "pure", "but", "not", "yet"],
    id: "O Lord, help me to be pure, but not yet.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["experience", "fine", "tune", "yourself"],
    words: [
      "every",
      "day",
      "is",
      "a",
      "learning",
      "experience",
      "where",
      "you",
      "fine",
      "tune",
      "yourself",
    ],
    id: "Every day is a learning experience where you fine tune yourself.",
    author: "Ramya Krishnan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["invent", "imagination", "pile", "junk"],
    words: ["to", "invent", "you", "need", "a", "good", "imagination", "and", "pile", "of", "junk"],
    id: "To invent, you need a good imagination and a pile of junk.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "count", "rounds", "afterwards"],
    words: [
      "i",
      "just",
      "need",
      "to",
      "count",
      "the",
      "rounds",
      "and",
      "me",
      "money",
      "afterwards",
    ],
    id: "I just need to count the rounds and count me money afterwards.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["friendship", "breathing", "sweets", "fold"],
    words: [
      "but",
      "friendship",
      "is",
      "the",
      "breathing",
      "rose",
      "with",
      "sweets",
      "in",
      "every",
      "fold",
    ],
    id: "But friendship is the breathing rose, with sweets in every fold.",
    author: "Oliver Wendell Holmes, Sr.",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["early-morning", "walk", "blessing", "whole"],
    words: ["an", "early-morning", "walk", "is", "a", "blessing", "for", "the", "whole", "day"],
    id: "An early-morning walk is a blessing for the whole day.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["kills", "skunk", "publicity", "itself"],
    words: ["what", "kills", "a", "skunk", "is", "the", "publicity", "it", "gives", "itself"],
    id: "What kills a skunk is the publicity it gives itself.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "pure", "heart", "make"],
    words: ["only", "the", "pure", "in", "heart", "can", "make", "a", "good", "soup"],
    id: "Only the pure in heart can make a good soup.",
    author: "Ludwig van Beethoven",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["aids", "disease", "hard", "talk"],
    words: ["the", "aids", "is", "a", "disease", "that", "hard", "to", "talk", "about"],
    id: "The AIDS is a disease that is hard to talk about.",
    author: "Bill Gates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["any", "product", "manual", "broken"],
    words: ["any", "product", "that", "needs", "a", "manual", "to", "work", "is", "broken"],
    id: "Any product that needs a manual to work is broken.",
    author: "Elon Musk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["secrets", "things", "others", "keep"],
    words: ["secrets", "are", "things", "we", "give", "to", "others", "keep", "for", "us"],
    id: "Secrets are things we give to others to keep for us.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["body", "perishes", "art", "dies"],
    words: ["a", "beautiful", "body", "perishes", "but", "work", "of", "art", "dies", "not"],
    id: "A beautiful body perishes, but a work of art dies not.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "maximum", "utilization", "ability"],
    words: [
      "success",
      "is",
      "the",
      "maximum",
      "utilization",
      "of",
      "ability",
      "that",
      "you",
      "have",
    ],
    id: "Success is the maximum utilization of the ability that you have.",
    author: "Zig Ziglar",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["company", "only", "as", "keeps"],
    words: ["a", "company", "is", "only", "as", "good", "the", "people", "it", "keeps"],
    id: "A company is only as good as the people it keeps.",
    author: "Mary Kay Ash",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["jealously", "always", "born", "die"],
    words: ["jealously", "is", "always", "born", "with", "love", "but", "it", "does", "not", "die"],
    id: "Jealously is always born with love but it does not die with it.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["utility", "beyond", "certain", "point"],
    words: ["money", "has", "no", "utility", "to", "me", "beyond", "a", "certain", "point"],
    id: "Money has no utility to me beyond a certain point.",
    author: "Bill Gates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["trick", "life", "how", "deal"],
    words: ["the", "trick", "in", "life", "is", "learning", "how", "to", "deal", "with", "it"],
    id: "The trick in life is learning how to deal with it.",
    author: "Helen Mirren",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["whence", "came", "nor", "why"],
    words: ["drink", "for", "you", "know", "not", "whence", "came", "nor", "why", "go", "where"],
    id: "Drink! for you know not whence you came nor why: drink! for you know not why you go, nor where.",
    author: "Omar Khayyam",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "interested", "on", "screen"],
    words: [
      "i",
      "think",
      "that",
      "people",
      "are",
      "interested",
      "seeing",
      "me",
      "on",
      "the",
      "screen",
    ],
    id: "I think that people are interested seeing me on the screen.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["liberty", "as", "practice", "speeches"],
    words: ["liberty", "doesn't", "work", "as", "well", "in", "practice", "it", "does", "speeches"],
    id: "Liberty doesn't work as well in practice as it does in speeches.",
    author: "Will Rogers",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["american", "their", "descendants", "will"],
    words: [
      "if",
      "the",
      "american",
      "people",
      "don't",
      "love",
      "me",
      "their",
      "descendants",
      "will",
    ],
    id: "If the American people don't love me, their descendants will.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "matters", "whether", "heart"],
    words: ["i", "think", "it", "matters", "whether", "someone", "has", "a", "good", "heart"],
    id: "I think it matters whether someone has a good heart.",
    author: "Elon Musk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["nobody", "else", "omit", "anything"],
    words: ["do", "what", "nobody", "else", "can", "for", "you", "omit", "to", "anything"],
    id: "Do what nobody else can do for you. Omit to do anything else.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["citizen", "adds", "strength", "nation"],
    words: ["every", "good", "citizen", "adds", "to", "the", "strength", "of", "a", "nation"],
    id: "Every good citizen adds to the strength of a nation.",
    author: "Gordon B. Hinckley",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["smoke", "made", "fume", "sighs"],
    words: ["love", "is", "a", "smoke", "made", "with", "the", "fume", "of", "sighs"],
    id: "Love is a smoke made with the fume of sighs.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["neck", "starts", "at", "43"],
    words: ["the", "neck", "starts", "to", "go", "at", "43", "and", "that's", "that"],
    id: "The neck starts to go at 43, and that's that.",
    author: "Nora Ephron",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["christian", "pray", "those", "govern"],
    words: ["a", "christian", "who", "does", "not", "pray", "for", "those", "govern", "is", "good"],
    id: "A Christian who does not pray for those who govern is not a good Christian.",
    author: "Pope Francis",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "i", "myself", "result"],
    words: ["when", "i", "am", "myself", "happy", "and", "have", "a", "good", "result"],
    id: "When I am myself, I am happy and have a good result.",
    author: "Jack Ma",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["little", "when", "vanity", "make"],
    words: ["we", "say", "little", "when", "vanity", "does", "not", "make", "us", "speak"],
    id: "We say little, when vanity does not make us speak.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "will", "over", "i"],
    words: ["one", "day", "it", "will", "be", "over", "and", "i", "don't", "care"],
    id: "One day it will be over, and I don't care.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["more", "schools", "less", "activism"],
    words: [
      "what",
      "we",
      "want",
      "is",
      "more",
      "learning",
      "in",
      "schools",
      "and",
      "less",
      "activism",
    ],
    id: "What we want is more learning in schools and less activism in schools.",
    author: "Scott Morrison",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["name", "god", "only", "habit"],
    words: ["we", "say", "the", "name", "of", "god", "but", "that", "is", "only", "habit"],
    id: "We say the name of God, but that is only habit.",
    author: "Nikita Khrushchev",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["government", "end", "preservation", "property"],
    words: [
      "government",
      "has",
      "no",
      "other",
      "end",
      "but",
      "the",
      "preservation",
      "of",
      "property",
    ],
    id: "Government has no other end, but the preservation of property.",
    author: "John Locke",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["should", "friendship", "united", "states"],
    words: [
      "we",
      "should",
      "have",
      "a",
      "good",
      "working",
      "friendship",
      "with",
      "the",
      "united",
      "states",
    ],
    id: "We should have a good working friendship with the United States.",
    author: "Justin Trudeau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["sad", "much", "sadder", "able"],
    words: ["it", "is", "sad", "not", "to", "love", "but", "much", "sadder", "be", "able"],
    id: "It is sad not to love, but it is much sadder not to be able to love.",
    author: "Miguel de Unamuno",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["nature", "all", "greatness", "exact"],
    words: ["it", "is", "the", "nature", "of", "all", "greatness", "not", "to", "be", "exact"],
    id: "It is the nature of all greatness not to be exact.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["falls", "himself", "will", "rivals"],
    words: ["he", "who", "falls", "in", "love", "with", "himself", "will", "have", "no", "rivals"],
    id: "He who falls in love with himself will have no rivals.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["as", "father", "loved", "i"],
    words: ["as", "the", "father", "has", "loved", "me", "so", "have", "i", "you"],
    id: "As the Father has loved me, so have I loved you.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["was", "act", "terrorism", "war"],
    words: ["this", "was", "not", "an", "act", "of", "terrorism", "but", "it", "war"],
    id: "This was not an act of terrorism, but it was an act of war.",
    author: "George W. Bush",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "sometimes", "superfluous", "when"],
    words: [
      "knowledge",
      "is",
      "sometimes",
      "superfluous",
      "when",
      "we",
      "need",
      "it",
      "have",
      "not",
    ],
    id: "Knowledge is sometimes superfluous: when we need it, we have it not.",
    author: "Saint Bernard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["cannot", "become", "by", "remaining"],
    words: ["we", "cannot", "become", "what", "need", "to", "be", "by", "remaining", "are"],
    id: "We cannot become what we need to be by remaining what we are.",
    author: "Max de Pree",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["words", "cheap", "biggest", "thing"],
    words: [
      "words",
      "are",
      "cheap",
      "the",
      "biggest",
      "thing",
      "you",
      "can",
      "say",
      "is",
      "elephant",
    ],
    id: "Words are cheap. The biggest thing you can say is 'elephant'.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["nature", "books", "belong", "eyes"],
    words: ["nature", "and", "books", "belong", "to", "the", "eyes", "that", "see", "them"],
    id: "Nature and books belong to the eyes that see them.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["found", "stand", "seeker", "seeks"],
    words: ["if", "you", "want", "to", "be", "found", "stand", "where", "the", "seeker", "seeks"],
    id: "If you want to be found stand where the seeker seeks.",
    author: "Sidney Lanier",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "consists", "man", "all"],
    words: ["life", "consists", "in", "what", "a", "man", "is", "thinking", "of", "all", "day"],
    id: "Life consists in what a man is thinking of all day.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["unable", "loved", "die", "immortality"],
    words: ["unable", "are", "the", "loved", "to", "die", "for", "love", "is", "immortality"],
    id: "Unable are the loved to die, for love is immortality.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["wrong", "state", "mind", "thankful"],
    words: ["we", "are", "in", "a", "wrong", "state", "of", "mind", "if", "not", "thankful"],
    id: "We are in a wrong state of mind if we are not in a thankful state of mind.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["home", "feel", "at", "treated"],
    words: ["home", "is", "where", "you", "feel", "at", "and", "are", "treated", "well"],
    id: "Home is where you feel at home and are treated well.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["without", "health", "may", "virtue"],
    words: ["if", "you", "can", "be", "well", "without", "health", "may", "happy", "virtue"],
    id: "If you can be well without health, you may be happy without virtue.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["living", "thing", "there", "desire"],
    words: ["in", "every", "living", "thing", "there", "is", "the", "desire", "for", "love"],
    id: "In every living thing there is the desire for love.",
    author: "D. H. Lawrence",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["even", "old", "men", "wisdom"],
    words: ["it", "is", "good", "even", "for", "old", "men", "to", "learn", "wisdom"],
    id: "It is good even for old men to learn wisdom.",
    author: "Aeschylus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["gentleman", "will", "insult", "man"],
    words: ["a", "gentleman", "will", "not", "insult", "me", "and", "no", "man", "can"],
    id: "A gentleman will not insult me, and no man not a gentleman can insult me.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "bread", "salt", "all"],
    words: ["let", "there", "be", "work", "bread", "water", "and", "salt", "for", "all"],
    id: "Let there be work, bread, water and salt for all.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "fall", "grows", "into"],
    words: ["one", "does", "not", "fall", "in", "love", "grows", "into", "and", "him"],
    id: "One does not fall in love; one grows into love, and love grows in him.",
    author: "Karl A. Menninger",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["making", "art", "business", "best"],
    words: ["making", "money", "is", "art", "and", "working", "good", "business", "the", "best"],
    id: "Making money is art and working is art and good business is the best art.",
    author: "Andy Warhol",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["real", "success", "finding", "lifework"],
    words: [
      "real",
      "success",
      "is",
      "finding",
      "you",
      "lifework",
      "in",
      "the",
      "work",
      "that",
      "love",
    ],
    id: "Real success is finding you lifework in the work that you love.",
    author: "David McCullough",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "being", "atheist", "something"],
    words: ["i", "think", "being", "an", "atheist", "is", "something", "you", "are", "not", "do"],
    id: "I think being an atheist is something you are, not something you do.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 7,
    new_words_count: 4,
  },
  {
    row_new_words: ["must", "change", "wish", "world"],
    words: ["you", "must", "be", "the", "change", "wish", "to", "see", "in", "world"],
    id: "You must be the change you wish to see in the world.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["roots", "education", "bitter", "sweet"],
    words: ["the", "roots", "of", "education", "are", "bitter", "but", "fruit", "is", "sweet"],
    id: "The roots of education are bitter, but the fruit is sweet.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["believe", "something", "live", "dishonest"],
    words: ["to", "believe", "in", "something", "and", "not", "live", "it", "is", "dishonest"],
    id: "To believe in something, and not to live it, is dishonest.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["punished", "our", "sins", "by"],
    words: ["we", "are", "not", "punished", "for", "our", "sins", "but", "by", "them"],
    id: "We are not punished for our sins, but by them.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["thing", "forced", "into", "stuff"],
    words: [
      "the",
      "thing",
      "about",
      "school",
      "is",
      "that",
      "if",
      "you're",
      "forced",
      "into",
      "learning",
      "stuff",
      "not",
      "going",
      "to",
      "be",
      "it",
    ],
    id: "The thing about school is that if you're forced into learning stuff, you're not going to be into it.",
    author: "Joey Ramone",
    grammar: null,
    word_count: 17,
    all_intersection_count: 13,
    new_words_count: 4,
  },
  {
    row_new_words: ["lot", "easier", "when", "words"],
    words: [
      "it's",
      "a",
      "lot",
      "easier",
      "to",
      "do",
      "good",
      "work",
      "when",
      "you",
      "have",
      "words",
      "say",
      "and",
      "with",
      "people",
    ],
    id: "It's a lot easier to do good work when you have good words to say and work with good people.",
    author: "Mark Harmon",
    grammar: null,
    word_count: 16,
    all_intersection_count: 12,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "i'm", "truth", "free"],
    words: [
      "i",
      "know",
      "where",
      "i'm",
      "going",
      "and",
      "the",
      "truth",
      "don't",
      "have",
      "to",
      "be",
      "what",
      "you",
      "want",
      "me",
      "free",
    ],
    id: "I know where I'm going and I know the truth, and I don't have to be what you want me to be. I'm free to be what I want.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 17,
    all_intersection_count: 13,
    new_words_count: 4,
  },
  {
    row_new_words: ["period", "longer", "more", "whom"],
    words: [
      "waiting",
      "is",
      "a",
      "period",
      "of",
      "learning",
      "the",
      "longer",
      "we",
      "wait",
      "more",
      "hear",
      "about",
      "him",
      "for",
      "whom",
      "are",
    ],
    id: "Waiting is a period of learning. The longer we wait, the more we hear about him for whom we are waiting.",
    author: "Henri Nouwen",
    grammar: null,
    word_count: 17,
    all_intersection_count: 13,
    new_words_count: 4,
  },
  {
    row_new_words: ["right", "courage", "or", "principle"],
    words: [
      "to",
      "see",
      "what",
      "is",
      "right",
      "and",
      "not",
      "do",
      "it",
      "want",
      "of",
      "courage",
      "or",
      "principle",
    ],
    id: "To see what is right and not to do it is want of courage, or of principle.",
    author: "Confucius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["america", "closed", "world", "open"],
    words: [
      "we",
      "don't",
      "want",
      "an",
      "america",
      "that",
      "is",
      "closed",
      "to",
      "the",
      "world",
      "what",
      "a",
      "open",
    ],
    id: "We don't want an America that is closed to the world. What we want is a world that is open to America.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["call", "only", "process", "recollection"],
    words: [
      "we",
      "do",
      "not",
      "learn",
      "and",
      "what",
      "call",
      "learning",
      "is",
      "only",
      "a",
      "process",
      "of",
      "recollection",
    ],
    id: "We do not learn; and what we call learning is only a process of recollection.",
    author: "Plato",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["come", "ballpark", "how", "stop"],
    words: [
      "if",
      "people",
      "don't",
      "want",
      "to",
      "come",
      "the",
      "ballpark",
      "how",
      "are",
      "you",
      "going",
      "stop",
      "them",
    ],
    id: "If people don't want to come to the ballpark, how are you going to stop them?",
    author: "Yogi Berra",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["busy", "tricks", "trade", "never"],
    words: [
      "some",
      "people",
      "are",
      "so",
      "busy",
      "learning",
      "the",
      "tricks",
      "of",
      "trade",
      "that",
      "they",
      "never",
      "learn",
    ],
    id: "Some people are so busy learning the tricks of the trade that they never learn the trade.",
    author: "Vernon Law",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "free", "spirit", "way"],
    words: [
      "i",
      "like",
      "to",
      "be",
      "a",
      "free",
      "spirit",
      "some",
      "don't",
      "that",
      "but",
      "that's",
      "the",
      "way",
      "am",
    ],
    id: "I like to be a free spirit. Some don't like that, but that's the way I am.",
    author: "Princess Diana",
    grammar: null,
    word_count: 15,
    all_intersection_count: 11,
    new_words_count: 4,
  },
  {
    row_new_words: ["can't", "world", "next", "year"],
    words: [
      "you",
      "can't",
      "learn",
      "in",
      "school",
      "what",
      "the",
      "world",
      "is",
      "going",
      "to",
      "do",
      "next",
      "year",
    ],
    id: "You can't learn in school what the world is going to do next year.",
    author: "Henry Ford",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["lost", "thinks", "great", "danger"],
    words: [
      "he",
      "who",
      "learns",
      "but",
      "does",
      "not",
      "think",
      "is",
      "lost",
      "thinks",
      "learn",
      "in",
      "great",
      "danger",
    ],
    id: "He who learns but does not think, is lost! He who thinks but does not learn is in great danger.",
    author: "Confucius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["treatment", "trifles", "person", "shows"],
    words: [
      "it",
      "is",
      "in",
      "the",
      "treatment",
      "of",
      "trifles",
      "that",
      "a",
      "person",
      "shows",
      "what",
      "they",
      "are",
    ],
    id: "It is in the treatment of trifles that a person shows what they are.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["possible", "human", "being", "too"],
    words: [
      "it's",
      "possible",
      "to",
      "love",
      "a",
      "human",
      "being",
      "if",
      "you",
      "don't",
      "know",
      "them",
      "too",
      "well",
    ],
    id: "It's possible to love a human being if you don't know them too well.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["our", "will", "always", "own"],
    words: [
      "our",
      "will",
      "is",
      "always",
      "for",
      "own",
      "good",
      "but",
      "we",
      "do",
      "not",
      "see",
      "what",
      "that",
    ],
    id: "Our will is always for our own good, but we do not always see what that is.",
    author: "Jean-Jacques Rousseau",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "minds", "looking", "attention"],
    words: [
      "you",
      "are",
      "not",
      "going",
      "to",
      "change",
      "the",
      "minds",
      "of",
      "people",
      "who",
      "looking",
      "for",
      "attention",
    ],
    id: "You are not going to change the minds of people who are looking for attention.",
    author: "Buzz Aldrin",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "purring", "one", "cannot"],
    words: [
      "what",
      "do",
      "i",
      "care",
      "about",
      "the",
      "purring",
      "of",
      "one",
      "who",
      "cannot",
      "love",
      "like",
      "cat",
    ],
    id: "What do I care about the purring of one who cannot love, like the cat?",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["elves", "counsel", "will", "both"],
    words: [
      "go",
      "not",
      "to",
      "the",
      "elves",
      "for",
      "counsel",
      "they",
      "will",
      "say",
      "both",
      "no",
      "and",
      "yes",
    ],
    id: "Go not to the Elves for counsel, for they will say both no and yes.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["those", "religion", "nothing", "politics"],
    words: [
      "those",
      "who",
      "say",
      "religion",
      "has",
      "nothing",
      "to",
      "do",
      "with",
      "politics",
      "not",
      "know",
      "what",
      "is",
    ],
    id: "Those who say religion has nothing to do with politics do not know what religion is.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "always", "end", "time"],
    words: [
      "and",
      "know",
      "that",
      "i",
      "am",
      "with",
      "you",
      "always",
      "yes",
      "to",
      "the",
      "end",
      "of",
      "time",
    ],
    id: "And know that I am with you always; yes, to the end of time.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "may", "fool", "married"],
    words: [
      "a",
      "man",
      "may",
      "be",
      "fool",
      "and",
      "not",
      "know",
      "it",
      "but",
      "if",
      "he",
      "is",
      "married",
    ],
    id: "A man may be a fool and not know it, but not if he is married.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["family", "important", "nothing", "matter"],
    words: [
      "to",
      "me",
      "family",
      "is",
      "what",
      "important",
      "if",
      "you",
      "don't",
      "have",
      "it",
      "nothing",
      "going",
      "matter",
    ],
    id: "To me, family is what is important. If you don't have it, nothing is going to matter.",
    author: "Nadia Comaneci",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["end", "persuaded", "by", "understand"],
    words: [
      "in",
      "the",
      "end",
      "people",
      "are",
      "persuaded",
      "not",
      "by",
      "what",
      "we",
      "say",
      "but",
      "they",
      "understand",
    ],
    id: "In the end, people are persuaded not by what we say, but by what they understand.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["worst", "part", "success", "finding"],
    words: [
      "the",
      "worst",
      "part",
      "of",
      "success",
      "is",
      "to",
      "try",
      "finding",
      "someone",
      "who",
      "happy",
      "for",
      "you",
    ],
    id: "The worst part of success is to try finding someone who is happy for you.",
    author: "Bette Midler",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "make", "choice", "itself"],
    words: [
      "when",
      "you",
      "have",
      "to",
      "make",
      "a",
      "choice",
      "and",
      "don't",
      "it",
      "that",
      "is",
      "in",
      "itself",
    ],
    id: "When you have to make a choice and don't make it, that is in itself a choice.",
    author: "William James",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "decide", "time", "given"],
    words: [
      "all",
      "we",
      "have",
      "to",
      "decide",
      "is",
      "what",
      "do",
      "with",
      "the",
      "time",
      "that",
      "given",
      "us",
    ],
    id: "All we have to decide is what to do with the time that is given us.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 14,
    all_intersection_count: 10,
    new_words_count: 4,
  },
  {
    row_new_words: ["loudly", "i", "can't", "saying"],
    words: [
      "who",
      "you",
      "are",
      "speaks",
      "so",
      "loudly",
      "i",
      "can't",
      "hear",
      "what",
      "you're",
      "saying",
    ],
    id: "Who you are speaks so loudly I can't hear what you're saying.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["spirit", "hand", "there", "art"],
    words: [
      "where",
      "the",
      "spirit",
      "does",
      "not",
      "work",
      "with",
      "hand",
      "there",
      "is",
      "no",
      "art",
    ],
    id: "Where the spirit does not work with the hand, there is no art.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "thing", "freedom", "worrying"],
    words: [
      "the",
      "only",
      "thing",
      "money",
      "gives",
      "you",
      "is",
      "freedom",
      "of",
      "not",
      "worrying",
      "about",
    ],
    id: "The only thing money gives you is the freedom of not worrying about money.",
    author: "Johnny Carson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["nobody", "missing", "couple", "payments"],
    words: [
      "if",
      "you",
      "think",
      "nobody",
      "cares",
      "about",
      "try",
      "missing",
      "a",
      "couple",
      "of",
      "payments",
    ],
    id: "If you think nobody cares about you, try missing a couple of payments.",
    author: "Steven Wright",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["design", "looks", "feels", "how"],
    words: [
      "design",
      "is",
      "not",
      "just",
      "what",
      "it",
      "looks",
      "like",
      "and",
      "feels",
      "how",
      "works",
    ],
    id: "Design is not just what it looks like and feels like. Design is how it works.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["ultimate", "aim", "ego", "something"],
    words: [
      "the",
      "ultimate",
      "aim",
      "of",
      "ego",
      "is",
      "not",
      "to",
      "see",
      "something",
      "but",
      "be",
    ],
    id: "The ultimate aim of the ego is not to see something, but to be something.",
    author: "Muhammad Iqbal",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["girls", "young", "men", "promise"],
    words: [
      "girls",
      "we",
      "love",
      "for",
      "what",
      "they",
      "are",
      "young",
      "men",
      "promise",
      "to",
      "be",
    ],
    id: "Girls we love for what they are; young men for what they promise to be.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["first", "yourself", "would", "then"],
    words: [
      "first",
      "say",
      "to",
      "yourself",
      "what",
      "you",
      "would",
      "be",
      "and",
      "then",
      "do",
      "have",
    ],
    id: "First say to yourself what you would be; and then do what you have to do.",
    author: "Epictetus",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["wine", "constant", "proof", "god"],
    words: [
      "wine",
      "is",
      "constant",
      "proof",
      "that",
      "god",
      "loves",
      "us",
      "and",
      "to",
      "see",
      "happy",
    ],
    id: "Wine is constant proof that God loves us and loves to see us happy.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "failure", "temporary", "get"],
    words: [
      "success",
      "and",
      "failure",
      "is",
      "temporary",
      "so",
      "don't",
      "let",
      "them",
      "get",
      "to",
      "you",
    ],
    id: "Success and failure is temporary. So don't let them get to you.",
    author: "Sai Pallavi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["doing", "result", "capitalism", "all"],
    words: [
      "doing",
      "well",
      "is",
      "the",
      "result",
      "of",
      "good",
      "that's",
      "what",
      "capitalism",
      "all",
      "about",
    ],
    id: "Doing well is the result of doing good. That's what capitalism is all about.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["or", "question", "compromise", "either"],
    words: [
      "to",
      "be",
      "or",
      "not",
      "is",
      "a",
      "question",
      "of",
      "compromise",
      "either",
      "you",
      "don't",
    ],
    id: "To be or not to be is not a question of compromise. Either you be or you don't be.",
    author: "Golda Meir",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["happens", "how", "react", "matters"],
    words: [
      "it's",
      "not",
      "what",
      "happens",
      "to",
      "you",
      "but",
      "how",
      "react",
      "it",
      "that",
      "matters",
    ],
    id: "It's not what happens to you, but how you react to it that matters.",
    author: "Epictetus",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "life", "culmination", "past"],
    words: [
      "my",
      "life",
      "is",
      "every",
      "moment",
      "of",
      "it",
      "not",
      "a",
      "culmination",
      "the",
      "past",
    ],
    id: "My life is every moment of my life. It is not a culmination of the past.",
    author: "Hugh Leonard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "made", "enemies", "probably"],
    words: [
      "a",
      "man",
      "who",
      "has",
      "made",
      "no",
      "enemies",
      "is",
      "probably",
      "not",
      "very",
      "good",
    ],
    id: "A man who has made no enemies is probably not a very good man.",
    author: "Antonin Scalia",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["nearly", "everything", "importance", "important"],
    words: [
      "nearly",
      "everything",
      "you",
      "do",
      "is",
      "of",
      "no",
      "importance",
      "but",
      "it",
      "important",
      "that",
    ],
    id: "Nearly everything you do is of no importance, but it is important that you do it.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["luxury", "opposite", "poverty", "vulgarity"],
    words: [
      "some",
      "people",
      "think",
      "luxury",
      "is",
      "the",
      "opposite",
      "of",
      "poverty",
      "it",
      "not",
      "vulgarity",
    ],
    id: "Some people think luxury is the opposite of poverty. It is not. It is the opposite of vulgarity.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["pay", "man", "costs", "counts"],
    words: [
      "it's",
      "not",
      "what",
      "you",
      "pay",
      "a",
      "man",
      "but",
      "he",
      "costs",
      "that",
      "counts",
    ],
    id: "It's not what you pay a man, but what he costs you that counts.",
    author: "Will Rogers",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["future", "happen", "all", "time"],
    words: [
      "the",
      "future",
      "doesn't",
      "just",
      "happen",
      "we",
      "are",
      "building",
      "it",
      "and",
      "all",
      "time",
    ],
    id: "The future doesn't just happen. We are building it, and we are building it all the time.",
    author: "Hannah Fry",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["too", "free", "everything", "one"],
    words: [
      "it",
      "is",
      "not",
      "good",
      "to",
      "be",
      "too",
      "free",
      "have",
      "everything",
      "one",
      "wants",
    ],
    id: "It is not good to be too free. It is not good to have everything one wants.",
    author: "Blaise Pascal",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "come", "always", "you'd"],
    words: [
      "change",
      "has",
      "to",
      "come",
      "it's",
      "not",
      "always",
      "what",
      "you'd",
      "like",
      "other",
      "people",
    ],
    id: "Change has to come. It's not always what you'd like. It's what other people like.",
    author: "Ruskin Bond",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["end", "even", "beginning", "perhaps"],
    words: [
      "now",
      "this",
      "is",
      "not",
      "the",
      "end",
      "it",
      "even",
      "beginning",
      "of",
      "but",
      "perhaps",
    ],
    id: "Now this is not the end. It is not even the beginning of the end. But it is, perhaps, the end of the beginning.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["forfeit", "three-quarters", "ourselves", "order"],
    words: [
      "we",
      "forfeit",
      "three-quarters",
      "of",
      "ourselves",
      "in",
      "order",
      "to",
      "be",
      "like",
      "other",
      "people",
    ],
    id: "We forfeit three-quarters of ourselves in order to be like other people.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["world", "action", "moping", "droning"],
    words: [
      "this",
      "is",
      "a",
      "world",
      "of",
      "action",
      "and",
      "not",
      "for",
      "moping",
      "droning",
      "in",
    ],
    id: "This is a world of action, and not for moping and droning in.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["miracles", "contrary", "nature", "only"],
    words: [
      "miracles",
      "are",
      "not",
      "contrary",
      "to",
      "nature",
      "but",
      "only",
      "what",
      "we",
      "know",
      "about",
    ],
    id: "Miracles are not contrary to nature, but only contrary to what we know about nature.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["wise", "man", "communicate", "language"],
    words: [
      "think",
      "like",
      "a",
      "wise",
      "man",
      "but",
      "communicate",
      "in",
      "the",
      "language",
      "of",
      "people",
    ],
    id: "Think like a wise man but communicate in the language of the people.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "equalizer", "health", "screwed"],
    words: [
      "the",
      "great",
      "equalizer",
      "is",
      "health",
      "if",
      "you",
      "don't",
      "have",
      "it",
      "you're",
      "screwed",
    ],
    id: "The great equalizer is health. If you don't have it, you're screwed.",
    author: "Jami Gertz",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "thing", "i", "win"],
    words: [
      "the",
      "one",
      "thing",
      "that",
      "i",
      "know",
      "is",
      "you",
      "win",
      "with",
      "good",
      "people",
    ],
    id: "The one thing that I know is that you win with good people.",
    author: "Don Shula",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["caring", "more", "than", "freedom"],
    words: [
      "not",
      "caring",
      "more",
      "about",
      "what",
      "other",
      "people",
      "think",
      "than",
      "you",
      "that's",
      "freedom",
    ],
    id: "Not caring more about what other people think than what you think. That's freedom.",
    author: "Demi Moore",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["thing", "being", "i", "whatever"],
    words: [
      "that's",
      "the",
      "good",
      "thing",
      "about",
      "being",
      "president",
      "i",
      "can",
      "do",
      "whatever",
      "want",
    ],
    id: "That's the good thing about being president, I can do whatever I want.",
    author: "Barack Obama",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["evolve", "change", "order", "initiate"],
    words: [
      "we",
      "have",
      "to",
      "evolve",
      "change",
      "and",
      "in",
      "order",
      "do",
      "that",
      "initiate",
      "the",
    ],
    id: "We have to evolve, we have to change, and in order to do that, we have to initiate the change.",
    author: "Edwin Catmull",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "movies", "violent", "i"],
    words: [
      "people",
      "say",
      "that",
      "my",
      "movies",
      "are",
      "violent",
      "i",
      "do",
      "not",
      "think",
      "so",
    ],
    id: "People say that my movies are violent. I do not think so.",
    author: "Takashi Miike",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["idealist", "person", "helps", "prosperous"],
    words: [
      "an",
      "idealist",
      "is",
      "a",
      "person",
      "who",
      "helps",
      "other",
      "people",
      "to",
      "be",
      "prosperous",
    ],
    id: "An idealist is a person who helps other people to be prosperous.",
    author: "Henry Ford",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "looking", "out", "there"],
    words: ["what", "i", "am", "looking", "for", "is", "not", "out", "there", "it", "in", "me"],
    id: "What I am looking for is not out there, it is in me.",
    author: "Helen Keller",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["fortune", "woman", "wench", "gear"],
    words: [
      "well",
      "if",
      "fortune",
      "be",
      "a",
      "woman",
      "she's",
      "good",
      "wench",
      "for",
      "this",
      "gear",
    ],
    id: "Well, if Fortune be a woman, she's a good wench for this gear.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["face", "facts", "being", "changes"],
    words: [
      "face",
      "the",
      "facts",
      "of",
      "being",
      "what",
      "you",
      "are",
      "for",
      "that",
      "is",
      "changes",
    ],
    id: "Face the facts of being what you are, for that is what changes what you are.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["exceptional", "alone", "life", "warrior"],
    words: [
      "to",
      "be",
      "exceptional",
      "you",
      "have",
      "alone",
      "that's",
      "the",
      "life",
      "of",
      "a",
      "warrior",
    ],
    id: "To be exceptional you have to be alone, that's the life of a warrior.",
    author: "Chris Eubank Sr.",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "those", "how", "live"],
    words: ["i", "love", "those", "who", "do", "not", "know", "how", "to", "live", "for", "today"],
    id: "I love those who do not know how to live for today.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["public", "opinion", "more", "than"],
    words: [
      "public",
      "opinion",
      "is",
      "no",
      "more",
      "than",
      "this",
      "what",
      "people",
      "think",
      "that",
      "other",
    ],
    id: "Public opinion is no more than this: what people think that other people think.",
    author: "Alfred Austin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["cannot", "change", "person", "way"],
    words: [
      "you",
      "cannot",
      "change",
      "a",
      "person",
      "let",
      "them",
      "be",
      "the",
      "way",
      "they",
      "are",
    ],
    id: "You cannot change a person. Let them be. Let them be the way they are.",
    author: "Melania Trump",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["been", "said", "80%", "visual"],
    words: [
      "it",
      "has",
      "been",
      "said",
      "that",
      "80%",
      "of",
      "what",
      "people",
      "learn",
      "is",
      "visual",
    ],
    id: "It has been said that 80% of what people learn is visual.",
    author: "Allen Klein",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["fool", "all", "time", "cannot"],
    words: [
      "you",
      "can",
      "fool",
      "all",
      "the",
      "people",
      "some",
      "of",
      "time",
      "and",
      "but",
      "cannot",
    ],
    id: "You can fool all the people some of the time, and some of the people all the time, but you cannot fool all the people all the time.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["president's", "hardest", "task", "right"],
    words: [
      "a",
      "president's",
      "hardest",
      "task",
      "is",
      "not",
      "to",
      "do",
      "what",
      "right",
      "but",
      "know",
    ],
    id: "A President's hardest task is not to do what is right, but to know what is right.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "emotional", "guy", "all"],
    words: [
      "i",
      "am",
      "a",
      "very",
      "emotional",
      "guy",
      "what",
      "love",
      "do",
      "with",
      "all",
      "have",
    ],
    id: "I am a very emotional guy. What I love, I do with all I have.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["ill", "man", "all", "everybody"],
    words: [
      "speak",
      "ill",
      "of",
      "no",
      "man",
      "but",
      "all",
      "the",
      "good",
      "you",
      "know",
      "everybody",
    ],
    id: "Speak ill of no man, but speak all the good you know of everybody.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["important", "make", "decisions", "show"],
    words: [
      "it",
      "is",
      "very",
      "important",
      "to",
      "know",
      "who",
      "you",
      "are",
      "make",
      "decisions",
      "show",
    ],
    id: "It is very important to know who you are. To make decisions. To show who you are.",
    author: "Malala Yousafzai",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["already", "often", "prevents", "from"],
    words: [
      "it",
      "is",
      "what",
      "we",
      "know",
      "already",
      "that",
      "often",
      "prevents",
      "us",
      "from",
      "learning",
    ],
    id: "It is what we know already that often prevents us from learning.",
    author: "Claude Bernard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "friendship", "parent", "child"],
    words: [
      "there",
      "is",
      "no",
      "friendship",
      "love",
      "like",
      "that",
      "of",
      "the",
      "parent",
      "for",
      "child",
    ],
    id: "There is no friendship, no love, like that of the parent for the child.",
    author: "Henry Ward Beecher",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "guilty", "all", "did"],
    words: ["every", "man", "is", "guilty", "of", "all", "the", "good", "he", "did", "not", "do"],
    id: "Every man is guilty of all the good he did not do.",
    author: "Voltaire",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "man", "bridge", "goal"],
    words: ["what", "is", "great", "in", "man", "that", "he", "a", "bridge", "and", "not", "goal"],
    id: "What is great in man is that he is a bridge and not a goal.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["friend", "total", "freedom", "yourself"],
    words: [
      "a",
      "friend",
      "is",
      "someone",
      "who",
      "gives",
      "you",
      "total",
      "freedom",
      "to",
      "be",
      "yourself",
    ],
    id: "A friend is someone who gives you total freedom to be yourself.",
    author: "Jim Morrison",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "sprig", "shoots", "uninteresting"],
    words: [
      "there",
      "is",
      "not",
      "a",
      "sprig",
      "of",
      "grass",
      "that",
      "shoots",
      "uninteresting",
      "to",
      "me",
    ],
    id: "There is not a sprig of grass that shoots uninteresting to me.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 8,
    new_words_count: 4,
  },
  {
    row_new_words: ["important", "thing", "should", "patience"],
    words: ["the", "very", "important", "thing", "you", "should", "have", "is", "patience"],
    id: "The very important thing you should have is patience.",
    author: "Jack Ma",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "wisdom", "head", "heart"],
    words: ["there", "is", "a", "wisdom", "of", "the", "head", "and", "heart"],
    id: "There is a wisdom of the head, and a wisdom of the heart.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["punished", "by", "our", "sins"],
    words: ["we", "are", "punished", "by", "our", "sins", "not", "for", "them"],
    id: "We are punished by our sins, not for them.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["pleasurable", "doing", "height", "enjoyment"],
    words: ["learning", "is", "pleasurable", "but", "doing", "the", "height", "of", "enjoyment"],
    id: "Learning is pleasurable but doing is the height of enjoyment.",
    author: "Novalis",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["microsoft", "greed", "innovation", "fairness"],
    words: ["microsoft", "is", "not", "about", "greed", "it's", "innovation", "and", "fairness"],
    id: "Microsoft is not about greed. It's about innovation and fairness.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["lives", "harmony", "himself", "universe"],
    words: ["he", "who", "lives", "in", "harmony", "with", "himself", "the", "universe"],
    id: "He who lives in harmony with himself lives in harmony with the universe.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["decides", "what's", "windows", "customers"],
    words: ["who", "decides", "what's", "in", "windows", "the", "customers", "buy", "it"],
    id: "Who decides what's in Windows? The customers who buy it.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["friend", "heart", "all", "time"],
    words: ["a", "friend", "is", "what", "the", "heart", "needs", "all", "time"],
    id: "A friend is what the heart needs all the time.",
    author: "Henry Van Dyke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "flies", "up", "navigator"],
    words: ["time", "flies", "it's", "up", "to", "you", "be", "the", "navigator"],
    id: "Time flies. It's up to you to be the navigator.",
    author: "Robert Orben",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["best", "thing", "all", "god"],
    words: ["the", "best", "thing", "of", "all", "is", "god", "with", "us"],
    id: "The best thing of all is God is with us.",
    author: "John Wesley",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["fortitude", "guard", "support", "virtues"],
    words: ["fortitude", "is", "the", "guard", "and", "support", "of", "other", "virtues"],
    id: "Fortitude is the guard and support of the other virtues.",
    author: "John Locke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["memories", "key", "past", "future"],
    words: ["memories", "are", "the", "key", "not", "to", "past", "but", "future"],
    id: "Memories are the key not to the past, but to the future.",
    author: "Corrie Ten Boom",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["really", "change", "world", "enough"],
    words: ["you", "really", "can", "change", "the", "world", "if", "care", "enough"],
    id: "You really can change the world if you care enough.",
    author: "Marian Wright Edelman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["committed", "crime", "profits", "by"],
    words: ["he", "has", "committed", "the", "crime", "who", "profits", "by", "it"],
    id: "He has committed the crime who profits by it.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["words", "wind", "nothing", "ergo"],
    words: ["words", "are", "but", "wind", "and", "learning", "is", "nothing", "ergo"],
    id: "Words are but wind; and learning is nothing but words; ergo, learning is nothing but wind.",
    author: "Jonathan Swift",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["might", "always", "get", "expect"],
    words: ["you", "might", "not", "always", "get", "what", "want", "but", "expect"],
    id: "You might not always get what you want, but you always get what you expect.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["value", "idea", "lies", "using"],
    words: ["the", "value", "of", "an", "idea", "lies", "in", "using", "it"],
    id: "The value of an idea lies in the using of it.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["movies", "dreams", "on", "subliminally"],
    words: ["movies", "are", "dreams", "and", "they", "work", "on", "you", "subliminally"],
    id: "Movies are dreams! And they work on you subliminally.",
    author: "Carrie Fisher",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "job", "represent", "washington"],
    words: ["my", "job", "is", "not", "to", "represent", "washington", "you", "but"],
    id: "My job is not to represent Washington to you, but to represent you to Washington.",
    author: "Barack Obama",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["trust", "enough", "will", "trusted"],
    words: ["he", "who", "does", "not", "trust", "enough", "will", "be", "trusted"],
    id: "He who does not trust enough, Will not be trusted.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["compared", "ought", "half", "awake"],
    words: ["compared", "to", "what", "we", "ought", "be", "are", "half", "awake"],
    id: "Compared to what we ought to be, we are half awake.",
    author: "William James",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "guns", "around", "carry"],
    words: ["i", "like", "to", "have", "guns", "around", "don't", "carry", "them"],
    id: "I like to have guns around. I don't like to carry them.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["age", "whatever", "as", "old"],
    words: ["age", "is", "whatever", "you", "think", "it", "are", "as", "old"],
    id: "Age is whatever you think it is. You are as old as you think you are.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["after", "win", "momentum", "confidence"],
    words: ["after", "every", "good", "win", "you", "build", "momentum", "and", "confidence"],
    id: "After every good win, you build momentum and confidence.",
    author: "Evan Fournier",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "around", "sad", "making"],
    words: ["i", "don't", "like", "people", "around", "me", "sad", "making", "happy"],
    id: "I don't like people around me sad. I like making people happy.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["politics", "game", "earnest", "business"],
    words: ["politics", "is", "not", "a", "game", "it", "an", "earnest", "business"],
    id: "Politics is not a game. It is an earnest business.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "busy", "wonderful", "family"],
    words: ["i'm", "happy", "to", "be", "busy", "have", "a", "wonderful", "family"],
    id: "I'm happy to be busy. I'm happy to have a wonderful family.",
    author: "John Williams",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "safety", "conquered", "expect"],
    words: ["the", "only", "safety", "for", "conquered", "is", "to", "expect", "no"],
    id: "The only safety for the conquered is to expect no safety.",
    author: "Virgil",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["without", "loving", "never", "giving"],
    words: ["you", "can", "give", "without", "loving", "but", "never", "love", "giving"],
    id: "You can give without loving, but you can never love without giving.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["reason", "stronger", "than", "death"],
    words: ["it", "is", "love", "not", "reason", "that", "stronger", "than", "death"],
    id: "It is love, not reason, that is stronger than death.",
    author: "Thomas Mann",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["whatever", "things", "end", "up"],
    words: ["whatever", "good", "things", "we", "build", "end", "up", "building", "us"],
    id: "Whatever good things we build end up building us.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["rich", "rejoices", "his", "portion"],
    words: ["who", "is", "rich", "he", "that", "rejoices", "in", "his", "portion"],
    id: "Who is rich? He that rejoices in his portion.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["hardest", "thing", "life", "change"],
    words: ["the", "hardest", "thing", "in", "life", "to", "do", "is", "change"],
    id: "The hardest thing in life to do is to change.",
    author: "Mickey Rourke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["action", "prompted", "by", "motive"],
    words: ["every", "action", "needs", "to", "be", "prompted", "by", "a", "motive"],
    id: "Every action needs to be prompted by a motive.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "free", "master", "himself"],
    words: ["no", "man", "is", "free", "who", "not", "master", "of", "himself"],
    id: "No man is free who is not master of himself.",
    author: "Epictetus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["minute", "i", "believe", "dead"],
    words: ["the", "minute", "that", "you're", "not", "learning", "i", "believe", "dead"],
    id: "The minute that you're not learning I believe you're dead.",
    author: "Jack Nicholson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["end", "picture", "always", "life"],
    words: ["the", "end", "of", "a", "picture", "is", "always", "an", "life"],
    id: "The end of a picture is always an end of a life.",
    author: "Sam Peckinpah",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "stream", "i", "a-fishing"],
    words: ["time", "is", "but", "the", "stream", "i", "go", "a-fishing", "in"],
    id: "Time is but the stream I go a-fishing in.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["best", "avoid", "beginnings", "evil"],
    words: ["it", "is", "best", "to", "avoid", "the", "beginnings", "of", "evil"],
    id: "It is best to avoid the beginnings of evil.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["child", "laughter", "peace", "aids"],
    words: ["give", "a", "child", "love", "laughter", "and", "peace", "not", "aids"],
    id: "Give a child love, laughter and peace, not AIDS.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "embody", "truth", "cannot"],
    words: ["man", "can", "embody", "truth", "but", "he", "cannot", "know", "it"],
    id: "Man can embody truth but he cannot know it.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "adventure", "package", "tour"],
    words: ["life", "is", "an", "adventure", "it's", "not", "a", "package", "tour"],
    id: "Life is an adventure, it's not a package tour.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["shall", "show", "mercy", "ask"],
    words: ["we", "shall", "show", "mercy", "but", "not", "ask", "for", "it"],
    id: "We shall show mercy, but we shall not ask for it.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "tough", "tougher", "stupid"],
    words: ["life", "is", "tough", "but", "it's", "tougher", "if", "you're", "stupid"],
    id: "Life is tough, but it's tougher if you're stupid.",
    author: "Unknown",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["how", "old", "would", "didn't"],
    words: ["how", "old", "would", "you", "be", "if", "didn't", "know", "are"],
    id: "How old would you be if you didn't know how old you are?",
    author: "Satchel Paige",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["painting", "self-discovery", "artist", "paints"],
    words: ["painting", "is", "self-discovery", "every", "good", "artist", "paints", "what", "he"],
    id: "Painting is self-discovery. Every good artist paints what he is.",
    author: "Jackson Pollock",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "will", "never", "right"],
    words: ["don't", "wait", "the", "time", "will", "never", "be", "just", "right"],
    id: "Don't wait. The time will never be just right.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["leave", "authority", "existing", "responsible"],
    words: ["leave", "no", "authority", "existing", "not", "responsible", "to", "the", "people"],
    id: "Leave no authority existing not responsible to the people.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["cease", "loving", "ourselves", "one"],
    words: ["we", "cease", "loving", "ourselves", "if", "no", "one", "loves", "us"],
    id: "We cease loving ourselves if no one loves us.",
    author: "Madame de Stael",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["greatest", "happiness", "source", "unhappiness"],
    words: ["the", "greatest", "happiness", "is", "to", "know", "source", "of", "unhappiness"],
    id: "The greatest happiness is to know the source of unhappiness.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "culture", "something", "describe"],
    words: ["i", "don't", "think", "culture", "is", "something", "you", "can", "describe"],
    id: "I don't think culture is something you can describe.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "lucky", "enjoy", "life"],
    words: ["i", "am", "lucky", "to", "do", "what", "and", "enjoy", "life"],
    id: "I am lucky to do what I do and enjoy life.",
    author: "Boban Marjanovic",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["nobody", "how", "much", "until"],
    words: ["nobody", "cares", "how", "much", "you", "know", "until", "they", "care"],
    id: "Nobody cares how much you know, until they know how much you care.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "best", "life", "starts"],
    words: ["what", "i", "love", "best", "in", "life", "is", "new", "starts"],
    id: "What I love best in life is new starts.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["failure", "option", "everyone", "succeed"],
    words: ["failure", "is", "not", "an", "option", "everyone", "has", "to", "succeed"],
    id: "Failure is not an option. Everyone has to succeed.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["confession", "evil", "first", "beginning"],
    words: ["the", "confession", "of", "evil", "works", "is", "first", "beginning", "good"],
    id: "The confession of evil works is the first beginning of good works.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["lovers", "right", "betray", "friends"],
    words: ["lovers", "have", "a", "right", "to", "betray", "you", "friends", "don't"],
    id: "Lovers have a right to betray you... friends don't.",
    author: "Judy Holliday",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["language", "friendship", "words", "meanings"],
    words: ["the", "language", "of", "friendship", "is", "not", "words", "but", "meanings"],
    id: "The language of friendship is not words but meanings.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "acting", "all", "bonus"],
    words: ["i", "love", "acting", "but", "it's", "all", "just", "a", "bonus"],
    id: "I love acting, but it's all just a bonus.",
    author: "Tim Vine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["egoism", "essence", "noble", "soul"],
    words: ["egoism", "is", "the", "very", "essence", "of", "a", "noble", "soul"],
    id: "Egoism is the very essence of a noble soul.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["most", "security", "world", "liberty"],
    words: ["most", "people", "want", "security", "in", "this", "world", "not", "liberty"],
    id: "Most people want security in this world, not liberty.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["ultimate", "expression", "will", "live"],
    words: ["love", "is", "the", "ultimate", "expression", "of", "will", "to", "live"],
    id: "Love is the ultimate expression of the will to live.",
    author: "Tom Wolfe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "most", "use", "worst"],
    words: ["time", "is", "what", "we", "want", "most", "but", "use", "worst"],
    id: "Time is what we want most, but what we use worst.",
    author: "William Penn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["art", "living", "dying", "one"],
    words: ["the", "art", "of", "living", "well", "and", "dying", "are", "one"],
    id: "The art of living well and the art of dying well are one.",
    author: "Epicurus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["alters", "when", "alteration", "finds"],
    words: ["love", "is", "not", "that", "alters", "when", "it", "alteration", "finds"],
    id: "Love is not love that alters when it alteration finds.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["generation", "americans", "rendezvous", "destiny"],
    words: ["this", "generation", "of", "americans", "has", "a", "rendezvous", "with", "destiny"],
    id: "This generation of Americans has a rendezvous with destiny.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["realists", "fear", "results", "their"],
    words: ["realists", "do", "not", "fear", "the", "results", "of", "their", "study"],
    id: "Realists do not fear the results of their study.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["despair", "even", "over", "fact"],
    words: ["don't", "despair", "not", "even", "over", "the", "fact", "that", "you"],
    id: "Don't despair, not even over the fact that you don't despair.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "pure", "spiritual", "leader"],
    words: ["i", "want", "to", "be", "just", "a", "pure", "spiritual", "leader"],
    id: "I want to be just a pure spiritual leader.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["too", "much", "thing", "wonderful"],
    words: ["too", "much", "of", "a", "good", "thing", "can", "be", "wonderful"],
    id: "Too much of a good thing can be wonderful.",
    author: "Mae West",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["truth", "rare", "delightful", "tell"],
    words: ["truth", "is", "so", "rare", "that", "it", "delightful", "to", "tell"],
    id: "Truth is so rare that it is delightful to tell it.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["women", "wear", "perfume", "future"],
    words: ["a", "women", "who", "doesn't", "wear", "perfume", "has", "no", "future"],
    id: "A women who doesn't wear perfume has no future.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["natural", "fact", "symbol", "spiritual"],
    words: ["every", "natural", "fact", "is", "a", "symbol", "of", "some", "spiritual"],
    id: "Every natural fact is a symbol of some spiritual fact.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["means", "ever", "having", "sorry"],
    words: ["love", "means", "not", "ever", "having", "to", "say", "you're", "sorry"],
    id: "Love means not ever having to say you're sorry.",
    author: "Erich Segal",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["england", "stadiums", "middle", "city"],
    words: ["in", "england", "you", "have", "stadiums", "the", "middle", "of", "city"],
    id: "In England, you have stadiums in the middle of the city.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "scarcity", "friendship", "friends"],
    words: ["there", "is", "a", "scarcity", "of", "friendship", "but", "not", "friends"],
    id: "There is a scarcity of friendship, but not of friends.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["difficulties", "things", "show", "person"],
    words: ["difficulties", "are", "things", "that", "show", "a", "person", "what", "they"],
    id: "Difficulties are things that show a person what they are.",
    author: "Epictetus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["loved", "was", "more", "than"],
    words: ["we", "loved", "with", "a", "love", "that", "was", "more", "than"],
    id: "We loved with a love that was more than love.",
    author: "Edgar Allan Poe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["health", "condition", "matter", "mind"],
    words: ["health", "is", "not", "a", "condition", "of", "matter", "but", "mind"],
    id: "Health is not a condition of matter, but of Mind.",
    author: "Mary Baker Eddy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["bears", "nice", "as", "long"],
    words: ["bears", "are", "very", "nice", "as", "long", "you", "to", "them"],
    id: "Bears are very nice, as long as you are nice to them.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["sun", "gone", "i", "light"],
    words: ["the", "sun", "is", "gone", "but", "i", "have", "a", "light"],
    id: "The sun is gone, but I have a light.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["highest", "revelation", "god", "man"],
    words: ["the", "highest", "revelation", "is", "that", "god", "in", "every", "man"],
    id: "The highest revelation is that God is in every man.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["die", "nothing", "rage", "live"],
    words: ["and", "die", "of", "nothing", "but", "a", "rage", "to", "live"],
    id: "And die of nothing but a rage to live.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["humility", "from", "those", "around"],
    words: ["have", "the", "humility", "to", "learn", "from", "those", "around", "you"],
    id: "Have the humility to learn from those around you.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["how", "dissimulate", "knowledge", "kings"],
    words: ["to", "know", "how", "dissimulate", "is", "the", "knowledge", "of", "kings"],
    id: "To know how to dissimulate is the knowledge of kings.",
    author: "Cardinal Richelieu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["make", "enemies", "change", "something"],
    words: ["if", "you", "want", "to", "make", "enemies", "try", "change", "something"],
    id: "If you want to make enemies, try to change something.",
    author: "Woodrow Wilson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "i", "change", "world"],
    words: ["all", "i", "want", "to", "do", "is", "change", "the", "world"],
    id: "All I want to do is change the world.",
    author: "W. Clement Stone",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "thing", "fear", "itself"],
    words: ["the", "only", "thing", "we", "have", "to", "fear", "is", "itself"],
    id: "The only thing we have to fear is fear itself.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["world", "canvas", "our", "imagination"],
    words: ["this", "world", "is", "but", "a", "canvas", "to", "our", "imagination"],
    id: "This world is but a canvas to our imagination.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "100", "percent", "gym"],
    words: ["i", "give", "100", "percent", "every", "day", "in", "the", "gym"],
    id: "I give 100 percent every day in the gym.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["facts", "mind", "food", "body"],
    words: ["facts", "are", "to", "the", "mind", "what", "food", "is", "body"],
    id: "Facts are to the mind what food is to the body.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["business", "makes", "nothing", "poor"],
    words: ["a", "business", "that", "makes", "nothing", "but", "money", "is", "poor"],
    id: "A business that makes nothing but money is a poor business.",
    author: "Henry Ford",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["once", "quit", "becomes", "habit"],
    words: ["once", "you", "learn", "to", "quit", "it", "becomes", "a", "habit"],
    id: "Once you learn to quit, it becomes a habit.",
    author: "Vince Lombardi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["more", "your", "history", "liberated"],
    words: ["the", "more", "you", "know", "of", "your", "history", "liberated", "are"],
    id: "The more you know of your history, the more liberated you are.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["best", "preparation", "doing", "your"],
    words: ["the", "best", "preparation", "for", "tomorrow", "is", "doing", "your", "today"],
    id: "The best preparation for tomorrow is doing your best today.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["too", "much", "thing", "taxing"],
    words: ["too", "much", "of", "a", "good", "thing", "can", "be", "taxing"],
    id: "Too much of a good thing can be taxing.",
    author: "Mae West",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["parting", "there", "image", "death"],
    words: ["in", "every", "parting", "there", "is", "an", "image", "of", "death"],
    id: "In every parting there is an image of death.",
    author: "George Eliot",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["failure", "fatal", "change", "might"],
    words: ["failure", "is", "not", "fatal", "but", "to", "change", "might", "be"],
    id: "Failure is not fatal, but failure to change might be.",
    author: "John Wooden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["countryman", "between", "two", "lawyers"],
    words: ["a", "countryman", "between", "two", "lawyers", "is", "like", "fish", "cats"],
    id: "A countryman between two lawyers is like a fish between two cats.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["health", "never", "out", "fashion"],
    words: ["health", "is", "never", "going", "to", "go", "out", "of", "fashion"],
    id: "Health is never going to go out of fashion.",
    author: "Magnus Scheving",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["presumptuous", "how", "somebody", "feels"],
    words: ["it's", "presumptuous", "to", "say", "you", "know", "how", "somebody", "feels"],
    id: "It's presumptuous to say you know how somebody feels.",
    author: "Joe Biden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["self-love", "source", "all", "our"],
    words: ["self-love", "is", "the", "source", "of", "all", "our", "other", "loves"],
    id: "Self-love is the source of all our other loves.",
    author: "Pierre Corneille",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["intellectual", "property", "shelf", "life"],
    words: ["intellectual", "property", "has", "the", "shelf", "life", "of", "a", "banana"],
    id: "Intellectual property has the shelf life of a banana.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["on", "given", "circumstance", "limit"],
    words: ["on", "a", "given", "day", "circumstance", "you", "think", "have", "limit"],
    id: "On a given day, a given circumstance, you think you have a limit.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["music", "food", "play", "on"],
    words: ["if", "music", "be", "the", "food", "of", "love", "play", "on"],
    id: "If music be the food of love, play on.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "limit", "power", "loving"],
    words: ["there", "is", "no", "limit", "to", "the", "power", "of", "loving"],
    id: "There is no limit to the power of loving.",
    author: "John Morton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["heaven", "grant", "patience", "man"],
    words: ["heaven", "grant", "us", "patience", "with", "a", "man", "in", "love"],
    id: "Heaven grant us patience with a man in love.",
    author: "Rudyard Kipling",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["takes", "great", "man", "listener"],
    words: ["it", "takes", "a", "great", "man", "to", "be", "good", "listener"],
    id: "It takes a great man to be a good listener.",
    author: "Calvin Coolidge",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "language", "proust", "context"],
    words: ["i", "like", "the", "language", "in", "proust", "but", "not", "context"],
    id: "I like the language in Proust but not the context.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "changes", "change", "circumstances"],
    words: ["i", "don't", "know", "that", "love", "changes", "people", "change", "circumstances"],
    id: "I don't know that love changes. People change. Circumstances change.",
    author: "Nicholas Sparks",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["writing", "hard", "bad", "health"],
    words: ["writing", "is", "hard", "work", "and", "bad", "for", "the", "health"],
    id: "Writing is hard work and bad for the health.",
    author: "E. B. White",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["talk", "absent", "whenever", "opportunity"],
    words: ["talk", "well", "of", "the", "absent", "whenever", "you", "have", "opportunity"],
    id: "Talk well of the absent whenever you have the opportunity.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["strange", "mistake", "change", "progress"],
    words: ["it", "is", "not", "strange", "to", "mistake", "change", "for", "progress"],
    id: "It is not strange... to mistake change for progress.",
    author: "Millard Fillmore",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["hard", "profession", "teaching", "acting"],
    words: ["but", "it", "is", "a", "hard", "it's", "profession", "teaching", "acting"],
    id: "But it is a hard, it's a hard profession teaching acting.",
    author: "Dabney Coleman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["cinema", "mirror", "change", "world"],
    words: ["cinema", "is", "a", "mirror", "that", "can", "change", "the", "world"],
    id: "Cinema is a mirror that can change the world.",
    author: "Diego Luna",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "afraid", "was", "born"],
    words: ["i", "am", "not", "afraid", "was", "born", "to", "do", "this"],
    id: "I am not afraid... I was born to do this.",
    author: "Joan of Arc",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "knowledge", "lost", "information"],
    words: ["where", "is", "all", "the", "knowledge", "we", "lost", "with", "information"],
    id: "Where is all the knowledge we lost with information?",
    author: "T. S. Eliot",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["everybody", "little", "bit", "watergate"],
    words: ["everybody", "has", "a", "little", "bit", "of", "watergate", "in", "him"],
    id: "Everybody has a little bit of Watergate in him.",
    author: "Billy Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "bubble", "gum", "smell"],
    words: ["i", "don't", "eat", "bubble", "gum", "but", "like", "the", "smell"],
    id: "I don't eat bubble gum, but I like the smell.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "afterlife", "i", "convinced"],
    words: ["there", "is", "an", "afterlife", "i", "am", "convinced", "of", "this"],
    id: "There is an afterlife. I am convinced of this.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["smart", "time", "i'm", "looking"],
    words: ["let's", "just", "be", "smart", "this", "time", "i'm", "looking", "for"],
    id: "Let's just be smart this time. I'm looking for smart.",
    author: "Joe Biden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["obstinacy", "bad", "cause", "constancy"],
    words: ["obstinacy", "in", "a", "bad", "cause", "is", "but", "constancy", "good"],
    id: "Obstinacy in a bad cause is but constancy in a good.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["more", "vacation", "-", "vocation"],
    words: ["it", "is", "not", "more", "vacation", "we", "need", "-", "vocation"],
    id: "It is not more vacation we need - it is more vocation.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "my", "permanent", "muses"],
    words: ["i", "have", "my", "permanent", "muses", "and", "of", "the", "moment"],
    id: "I have my permanent muses and my muses of the moment.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["ready", "wit", "tell", "when"],
    words: ["you", "have", "a", "ready", "wit", "tell", "me", "when", "it's"],
    id: "You have a ready wit. Tell me when it's ready.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["gentle", "way", "shake", "world"],
    words: ["in", "a", "gentle", "way", "you", "can", "shake", "the", "world"],
    id: "In a gentle way, you can shake the world.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "close", "friendship", "skaters"],
    words: ["i", "have", "a", "very", "close", "friendship", "with", "the", "skaters"],
    id: "I have a very close friendship with the skaters.",
    author: "Eric Heiden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["better", "hammer", "than", "anvil"],
    words: ["it", "is", "better", "to", "be", "the", "hammer", "than", "anvil"],
    id: "It is better to be the hammer than the anvil.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "dawns", "which", "awake"],
    words: ["only", "that", "day", "dawns", "to", "which", "we", "are", "awake"],
    id: "Only that day dawns to which we are awake.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["means", "as", "god", "intended"],
    words: ["to", "love", "someone", "means", "see", "him", "as", "god", "intended"],
    id: "To love someone means to see him as God intended him.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["quality", "research", "absolutely", "best"],
    words: ["the", "quality", "of", "research", "in", "us", "is", "absolutely", "best"],
    id: "The quality of research in the U.S. is absolutely the best.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["enjoy", "constitutes", "our", "abundance"],
    words: ["not", "what", "we", "have", "but", "enjoy", "constitutes", "our", "abundance"],
    id: "Not what we have But what we enjoy, constitutes our abundance.",
    author: "Epicurus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["true", "fear", "forever", "affixed"],
    words: ["to", "good", "and", "true", "love", "fear", "is", "forever", "affixed"],
    id: "To good and true love fear is forever affixed.",
    author: "Francois Rabelais",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["improve", "all", "your", "tomorrows"],
    words: ["what", "you", "do", "today", "can", "improve", "all", "your", "tomorrows"],
    id: "What you do today can improve all your tomorrows.",
    author: "Ralph Marston",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "half", "spent", "before"],
    words: ["life", "is", "half", "spent", "before", "we", "know", "what", "it"],
    id: "Life is half spent before we know what it is.",
    author: "George Herbert",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "wife", "everything", "i"],
    words: ["my", "wife", "has", "about", "everything", "i", "can", "think", "of"],
    id: "My wife has about everything I can think of.",
    author: "Billy Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "conquer", "their", "souls"],
    words: ["i", "just", "want", "to", "conquer", "people", "and", "their", "souls"],
    id: "I just want to conquer people and their souls.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "how", "talent", "object"],
    words: ["happiness", "is", "a", "how", "not", "what", "talent", "an", "object"],
    id: "Happiness is a how; not a what. A talent, not an object.",
    author: "Hermann Hesse",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["poor", "likely", "live", "long"],
    words: ["if", "you", "are", "poor", "not", "likely", "to", "live", "long"],
    id: "If you are poor, you are not likely to live long.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["nobody", "minds", "having", "too"],
    words: ["nobody", "minds", "having", "what", "is", "too", "good", "for", "them"],
    id: "Nobody minds having what is too good for them.",
    author: "Jane Austen",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "constantly", "aspires", "elevated"],
    words: ["if", "a", "man", "constantly", "aspires", "is", "he", "not", "elevated"],
    id: "If a man constantly aspires is he not elevated?",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["ideas", "why", "would", "guns"],
    words: ["we", "don't", "let", "them", "have", "ideas", "why", "would", "guns"],
    id: "We don't let them have ideas. Why would we let them have guns?",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["extremely", "important", "write", "children"],
    words: ["it", "is", "extremely", "important", "to", "me", "write", "for", "children"],
    id: "It is extremely important to me to write for children.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["capacity", "take", "protect", "nourish"],
    words: ["love", "is", "the", "capacity", "to", "take", "care", "protect", "nourish"],
    id: "Love is the capacity to take care, to protect, to nourish.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["cook", "sorceress", "dispenses", "happiness"],
    words: ["a", "good", "cook", "is", "like", "sorceress", "who", "dispenses", "happiness"],
    id: "A good cook is like a sorceress who dispenses happiness.",
    author: "Elsa Schiaparelli",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["business", "stock", "eventually", "follows"],
    words: ["if", "a", "business", "does", "well", "the", "stock", "eventually", "follows"],
    id: "If a business does well, the stock eventually follows.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["which", "death", "preferably", "unexpected"],
    words: ["which", "death", "is", "preferably", "to", "every", "other", "the", "unexpected"],
    id: "Which death is preferably to every other? 'The unexpected'.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "nothing", "impossible", "will"],
    words: ["there", "is", "nothing", "impossible", "to", "him", "who", "will", "try"],
    id: "There is nothing impossible to him who will try.",
    author: "Alexander the Great",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "ideal", "reason", "imagination"],
    words: ["happiness", "is", "not", "an", "ideal", "of", "reason", "but", "imagination"],
    id: "Happiness is not an ideal of reason, but of imagination.",
    author: "Immanuel Kant",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "believe", "forgiveness", "trying"],
    words: ["i", "believe", "in", "forgiveness", "trying", "to", "work", "with", "people"],
    id: "I believe in forgiveness; I believe in trying to work with people.",
    author: "John Lewis",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["child", "illusion", "parent", "disillusion"],
    words: ["love", "is", "the", "child", "of", "illusion", "and", "parent", "disillusion"],
    id: "Love is the child of illusion and the parent of disillusion.",
    author: "Miguel de Unamuno",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["drowning", "information", "starved", "knowledge"],
    words: ["we", "are", "drowning", "in", "information", "but", "starved", "for", "knowledge"],
    id: "We are drowning in information but starved for knowledge.",
    author: "John Naisbitt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["offers", "breath", "mint", "accept"],
    words: ["if", "someone", "offers", "you", "a", "breath", "mint", "accept", "it"],
    id: "If someone offers you a breath mint, accept it.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["spirit", "envy", "destroy", "never"],
    words: ["the", "spirit", "of", "envy", "can", "destroy", "it", "never", "build"],
    id: "The spirit of envy can destroy; it can never build.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "sword", "man", "can't"],
    words: ["never", "give", "a", "sword", "to", "man", "who", "can't", "dance"],
    id: "Never give a sword to a man who can't dance.",
    author: "Confucius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["justice", "surrender", "law", "punishment"],
    words: ["justice", "that", "love", "gives", "is", "a", "surrender", "law", "punishment"],
    id: "Justice that love gives is a surrender, justice that law gives is a punishment.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["criticism", "prejudice", "made", "plausible"],
    words: ["criticism", "is", "prejudice", "made", "plausible"],
    id: "Criticism is prejudice made plausible.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "never", "been", "free"],
    words: ["freedom", "has", "never", "been", "free"],
    id: "Freedom has never been free.",
    author: "Medgar Evers",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["even", "paranoids", "real", "enemies"],
    words: ["even", "paranoids", "have", "real", "enemies"],
    id: "Even paranoids have real enemies.",
    author: "Golda Meir",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["you'll", "free", "or", "die"],
    words: ["you'll", "be", "free", "or", "die"],
    id: "You'll be free or die!",
    author: "Harriet Tubman",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "endures", "personal", "qualities"],
    words: ["nothing", "endures", "but", "personal", "qualities"],
    id: "Nothing endures but personal qualities.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "done", "something", "unethical"],
    words: ["we", "all", "have", "done", "something", "unethical"],
    id: "We all have done something unethical.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["men's", "vows", "women's", "traitors"],
    words: ["men's", "vows", "are", "women's", "traitors"],
    id: "Men's vows are women's traitors!",
    author: "William Shakespeare",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "as", "thick", "plank"],
    words: ["i'm", "as", "thick", "a", "plank"],
    id: "I'm as thick as a plank.",
    author: "Princess Diana",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["health", "food", "makes", "sick"],
    words: ["health", "food", "makes", "me", "sick"],
    id: "Health food makes me sick.",
    author: "Calvin Trillin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "comes", "wisdom", "lingers"],
    words: ["knowledge", "comes", "but", "wisdom", "lingers"],
    id: "Knowledge comes, but wisdom lingers.",
    author: "Alfred Lord Tennyson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["as", "grow", "older", "change"],
    words: ["as", "you", "grow", "older", "change"],
    id: "As you grow older, you change.",
    author: "Martin Scorsese",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["fate", "will", "find", "way"],
    words: ["fate", "will", "find", "a", "way"],
    id: "Fate will find a way.",
    author: "Virgil",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "mind", "own", "church"],
    words: ["my", "mind", "is", "own", "church"],
    id: "My mind is my own church.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["scientific", "knowledge", "belongs", "humanity"],
    words: ["scientific", "knowledge", "belongs", "to", "humanity"],
    id: "Scientific knowledge belongs to humanity.",
    author: "Alexandra Elbakyan",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["difficulties", "mastered", "opportunities", "won"],
    words: ["difficulties", "mastered", "are", "opportunities", "won"],
    id: "Difficulties mastered are opportunities won.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "shudder", "when", "relating"],
    words: ["i", "shudder", "when", "relating", "it"],
    id: "I shudder when relating it.",
    author: "Virgil",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "rings", "opinions", "clash"],
    words: ["freedom", "rings", "where", "opinions", "clash"],
    id: "Freedom rings where opinions clash.",
    author: "Adlai Stevenson I",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "never", "too", "dear"],
    words: ["knowledge", "is", "never", "too", "dear"],
    id: "Knowledge is never too dear.",
    author: "Francis Walsingham",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "inhaled", "exhaled", "everything"],
    words: ["i", "have", "inhaled", "exhaled", "everything"],
    id: "I have inhaled, exhaled everything.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "committed", "my", "family"],
    words: ["i'm", "committed", "to", "my", "family"],
    id: "I'm committed to my family.",
    author: "YoungBoy Never Broke Again",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["your", "descendants", "shall", "gather"],
    words: ["your", "descendants", "shall", "gather", "fruits"],
    id: "Your descendants shall gather your fruits.",
    author: "Virgil",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "lies", "being", "bold"],
    words: ["freedom", "lies", "in", "being", "bold"],
    id: "Freedom lies in being bold.",
    author: "Robert Frost",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "family", "always", "priority"],
    words: ["my", "family", "is", "always", "priority"],
    id: "My family is always my priority.",
    author: "J. R. Smith",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "celebrate", "myself", "sing"],
    words: ["i", "celebrate", "myself", "and", "sing"],
    id: "I celebrate myself, and sing myself.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["comedy", "acting", "out", "optimism"],
    words: ["comedy", "is", "acting", "out", "optimism"],
    id: "Comedy is acting out optimism.",
    author: "Robin Williams",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["everything", "matches", "black", "especially"],
    words: ["everything", "matches", "black", "especially"],
    id: "Everything matches black, especially black.",
    author: "Nora Ephron",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "things", "deteriorate", "time"],
    words: ["all", "things", "deteriorate", "in", "time"],
    id: "All things deteriorate in time.",
    author: "Virgil",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["often", "costs", "too", "much"],
    words: ["money", "often", "costs", "too", "much"],
    id: "Money often costs too much.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "came", "saw", "conquered"],
    words: ["i", "came", "saw", "conquered"],
    id: "I came, I saw, I conquered.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["breed", "stronger", "than", "pasture"],
    words: ["breed", "is", "stronger", "than", "pasture"],
    id: "Breed is stronger than pasture.",
    author: "George Eliot",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["wealth", "accumulates", "men", "decay"],
    words: ["where", "wealth", "accumulates", "men", "decay"],
    id: "Where wealth accumulates, men decay.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "survivor's", "curiosity", "guess"],
    words: ["i", "have", "survivor's", "curiosity", "guess"],
    id: "I have survivor's curiosity, I guess.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["foot", "feels", "when", "ground"],
    words: ["the", "foot", "feels", "when", "it", "ground"],
    id: "The foot feels the foot when it feels the ground.",
    author: "Buddha",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "quite", "chauvinistic", "person"],
    words: ["i'm", "quite", "a", "chauvinistic", "person"],
    id: "I'm quite a chauvinistic person.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["convince", "by", "our", "presence"],
    words: ["we", "convince", "by", "our", "presence"],
    id: "We convince by our presence.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["youth", "knew", "age", "could"],
    words: ["if", "youth", "knew", "age", "could"],
    id: "If youth knew; if age could.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "writing", "unauthorized", "autobiography"],
    words: ["i'm", "writing", "an", "unauthorized", "autobiography"],
    id: "I'm writing an unauthorized autobiography.",
    author: "Steven Wright",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["beware", "geeks", "bearing", "formulas"],
    words: ["beware", "of", "geeks", "bearing", "formulas"],
    id: "Beware of geeks bearing formulas.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["what's", "another", "word", "thesaurus"],
    words: ["what's", "another", "word", "for", "thesaurus"],
    id: "What's another word for Thesaurus?",
    author: "Steven Wright",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["make", "yourself", "necessary", "somebody"],
    words: ["make", "yourself", "necessary", "to", "somebody"],
    id: "Make yourself necessary to somebody.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "unloved", "hate", "unnatural"],
    words: ["only", "the", "unloved", "hate", "and", "unnatural"],
    id: "Only the unloved hate; the unloved and the unnatural.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "depends", "upon", "ourselves"],
    words: ["happiness", "depends", "upon", "ourselves"],
    id: "Happiness depends upon ourselves.",
    author: "Aristotle",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["obscenity", "comes", "from", "grime"],
    words: ["obscenity", "comes", "from", "grime"],
    id: "Obscenity comes from grime.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["fear", "clogs", "faith", "liberates"],
    words: ["fear", "clogs", "faith", "liberates"],
    id: "Fear clogs; faith liberates.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["i've", "always", "been", "different"],
    words: ["i've", "always", "been", "different"],
    id: "I've always been different.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["applause", "waits", "on", "success"],
    words: ["applause", "waits", "on", "success"],
    id: "Applause waits on success.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["i've", "been", "really", "fortunate"],
    words: ["i've", "been", "really", "fortunate"],
    id: "I've been really, really fortunate.",
    author: "Joe Biden",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["from", "caring", "comes", "courage"],
    words: ["from", "caring", "comes", "courage"],
    id: "From caring comes courage.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "god", "make", "majority"],
    words: ["one", "and", "god", "make", "a", "majority"],
    id: "One and God make a majority.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "hate", "attention", "man"],
    words: ["i", "hate", "attention", "man"],
    id: "I hate attention, man.",
    author: "Rod Wave",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["commit", "yourself", "mighty", "purpose"],
    words: ["commit", "yourself", "to", "a", "mighty", "purpose"],
    id: "Commit yourself to a mighty purpose.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["universe", "transformation", "life", "opinion"],
    words: ["the", "universe", "is", "transformation", "life", "opinion"],
    id: "The universe is transformation: life is opinion.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["big", "results", "require", "ambitions"],
    words: ["big", "results", "require", "ambitions"],
    id: "Big results require big ambitions.",
    author: "Heraclitus",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "had", "flops", "success"],
    words: ["i", "had", "flops", "success"],
    id: "I had flops, I had success.",
    author: "Agnes Varda",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "take", "friendship", "seriously"],
    words: ["i", "take", "friendship", "very", "seriously"],
    id: "I take friendship very seriously.",
    author: "Wole Soyinka",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["sowing", "as", "difficult", "reaping"],
    words: ["sowing", "is", "not", "as", "difficult", "reaping"],
    id: "Sowing is not as difficult as reaping.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "family", "strength", "weakness"],
    words: ["my", "family", "is", "strength", "and", "weakness"],
    id: "My family is my strength and my weakness.",
    author: "Aishwarya Rai Bachchan",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "watch", "times", "change"],
    words: ["i", "love", "to", "watch", "times", "change"],
    id: "I love to watch times change!",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["mastery", "passes", "often", "egotism"],
    words: ["mastery", "passes", "often", "for", "egotism"],
    id: "Mastery passes often for egotism.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["man's", "character", "his", "fate"],
    words: ["a", "man's", "character", "is", "his", "fate"],
    id: "A man's character is his fate.",
    author: "Heraclitus",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["what's", "done", "can't", "undone"],
    words: ["what's", "done", "can't", "be", "undone"],
    id: "What's done can't be undone.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["at", "my", "age", "scare"],
    words: ["at", "my", "age", "flowers", "scare", "me"],
    id: "At my age flowers scare me.",
    author: "George Burns",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["always", "desire", "something", "useful"],
    words: ["always", "desire", "to", "learn", "something", "useful"],
    id: "Always desire to learn something useful.",
    author: "Sophocles",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "my", "job", "breathe"],
    words: ["i", "do", "my", "job", "like", "breathe"],
    id: "I do my job like I breathe.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "99", "percent", "failure"],
    words: ["success", "is", "99", "percent", "failure"],
    id: "Success is 99 percent failure.",
    author: "Soichiro Honda",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "must", "steer", "talk"],
    words: ["one", "must", "steer", "not", "talk"],
    id: "One must steer, not talk.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["watch", "your", "finances", "hawk"],
    words: ["watch", "your", "finances", "like", "a", "hawk"],
    id: "Watch your finances like a hawk.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["either", "make", "dust", "or"],
    words: ["you", "either", "make", "dust", "or", "eat"],
    id: "You either make dust or eat dust.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "shook", "up", "world"],
    words: ["i", "shook", "up", "the", "world"],
    id: "I shook up the world, I shook up the world.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["lost", "because", "told", "ourselves"],
    words: ["we", "lost", "because", "told", "ourselves"],
    id: "We lost because we told ourselves we lost.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["history", "written", "by", "victors"],
    words: ["history", "is", "written", "by", "the", "victors"],
    id: "History is written by the victors.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "can't", "die", "once"],
    words: ["i", "can't", "die", "but", "once"],
    id: "I can't die but once.",
    author: "Harriet Tubman",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["beauty", "folly", "old", "companions"],
    words: ["beauty", "and", "folly", "are", "old", "companions"],
    id: "Beauty and folly are old companions.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "was", "becoming", "post-ideological"],
    words: ["i", "was", "becoming", "post-ideological"],
    id: "I was becoming post-ideological.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["without", "brains", "always", "dangerous"],
    words: ["money", "without", "brains", "is", "always", "dangerous"],
    id: "Money without brains is always dangerous.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["by", "sin", "fell", "angels"],
    words: ["by", "that", "sin", "fell", "the", "angels"],
    id: "By that sin fell the angels.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["men", "willingly", "believe", "wish"],
    words: ["men", "willingly", "believe", "what", "they", "wish"],
    id: "Men willingly believe what they wish.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["weep", "wax", "indignant", "understand"],
    words: ["do", "not", "weep", "wax", "indignant", "understand"],
    id: "Do not weep; do not wax indignant. Understand.",
    author: "Baruch Spinoza",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["world", "remains", "ever", "same"],
    words: ["the", "world", "remains", "ever", "same"],
    id: "The world remains ever the same.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["poor", "content", "rich", "enough"],
    words: ["poor", "and", "content", "is", "rich", "enough"],
    id: "Poor and content is rich, and rich enough.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["questions", "outrank", "easy", "answers"],
    words: ["good", "questions", "outrank", "easy", "answers"],
    id: "Good questions outrank easy answers.",
    author: "Paul Samuelson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["jug", "fills", "drop", "by"],
    words: ["a", "jug", "fills", "drop", "by"],
    id: "A jug fills drop by drop.",
    author: "Buddha",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["belief", "creates", "actual", "fact"],
    words: ["belief", "creates", "the", "actual", "fact"],
    id: "Belief creates the actual fact.",
    author: "William James",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["nature", "wont", "hide", "herself"],
    words: ["nature", "is", "wont", "to", "hide", "herself"],
    id: "Nature is wont to hide herself.",
    author: "Heraclitus",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["leap", "net", "will", "appear"],
    words: ["leap", "and", "the", "net", "will", "appear"],
    id: "Leap, and the net will appear.",
    author: "John Burroughs",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["float", "butterfly", "sting", "bee"],
    words: ["float", "like", "a", "butterfly", "sting", "bee"],
    id: "Float like a butterfly, sting like a bee.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["as", "iron", "sharpens", "friend"],
    words: ["as", "iron", "sharpens", "so", "a", "friend"],
    id: "As iron sharpens iron, so a friend sharpens a friend.",
    author: "King Solomon",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "idealist", "without", "illusions"],
    words: ["i'm", "an", "idealist", "without", "illusions"],
    id: "I'm an idealist without illusions.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["hard", "beginning", "maketh", "ending"],
    words: ["a", "hard", "beginning", "maketh", "good", "ending"],
    id: "A hard beginning maketh a good ending.",
    author: "John Heywood",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["white", "man", "destroying", "world"],
    words: ["the", "white", "man", "is", "destroying", "world"],
    id: "The white man is destroying the world.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["our", "intention", "creates", "reality"],
    words: ["our", "intention", "creates", "reality"],
    id: "Our intention creates our reality.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "anti-tax", "pro-carbon", "tax"],
    words: ["i'm", "anti-tax", "but", "pro-carbon", "tax"],
    id: "I'm anti-tax, but I'm pro-carbon tax.",
    author: "Elon Musk",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["rule", "easy", "govern", "difficult"],
    words: ["to", "rule", "is", "easy", "govern", "difficult"],
    id: "To rule is easy, to govern difficult.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["praise", "undeserved", "satire", "disguise"],
    words: ["praise", "undeserved", "is", "satire", "in", "disguise"],
    id: "Praise undeserved, is satire in disguise.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["live", "life", "you've", "dreamed"],
    words: ["live", "the", "life", "you've", "dreamed"],
    id: "Live the life you've dreamed.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["before", "all", "else", "armed"],
    words: ["before", "all", "else", "be", "armed"],
    id: "Before all else, be armed.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["sows", "virtue", "reaps", "honor"],
    words: ["who", "sows", "virtue", "reaps", "honor"],
    id: "Who sows virtue reaps honor.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "got", "boss", "mentality"],
    words: ["i", "got", "a", "boss", "mentality"],
    id: "I got a boss mentality.",
    author: "Lil Baby",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "son", "died", "aids"],
    words: ["my", "son", "has", "died", "of", "aids"],
    id: "My son has died of AIDS.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "only", "you've", "got"],
    words: ["i'm", "the", "only", "president", "you've", "got"],
    id: "I'm the only president you've got.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["effective", "action", "always", "unjust"],
    words: ["effective", "action", "is", "always", "unjust"],
    id: "Effective action is always unjust.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "seems", "made", "shared"],
    words: ["happiness", "seems", "made", "to", "be", "shared"],
    id: "Happiness seems made to be shared.",
    author: "Pierre Corneille",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["deed", "everything", "glory", "naught"],
    words: ["the", "deed", "is", "everything", "glory", "naught"],
    id: "The deed is everything, the glory is naught.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "admire", "destroy", "themselves"],
    words: ["i", "admire", "people", "who", "destroy", "themselves"],
    id: "I admire people who destroy themselves.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["unused", "life", "early", "death"],
    words: ["an", "unused", "life", "is", "early", "death"],
    id: "An unused life is an early death.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "always", "extreme", "ways"],
    words: ["i", "always", "go", "extreme", "ways"],
    id: "I always go extreme ways.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "itself", "proper", "binge"],
    words: ["life", "itself", "is", "the", "proper", "binge"],
    id: "Life itself is the proper binge.",
    author: "Julia Child",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "itself", "comes", "drops"],
    words: ["time", "itself", "comes", "in", "drops"],
    id: "Time itself comes in drops.",
    author: "William James",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "pride", "fell", "fortunes"],
    words: ["my", "pride", "fell", "with", "fortunes"],
    id: "My pride fell with my fortunes.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["must", "use", "time", "creatively"],
    words: ["we", "must", "use", "time", "creatively"],
    id: "We must use time creatively.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "bear", "charmed", "life"],
    words: ["i", "bear", "a", "charmed", "life"],
    id: "I bear a charmed life.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "train", "sleep", "repeat"],
    words: ["i", "train", "eat", "sleep", "and", "repeat"],
    id: "I train, eat, sleep, and repeat.",
    author: "Khabib Nurmagomedov",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["hope", "will", "never", "silent"],
    words: ["hope", "will", "never", "be", "silent"],
    id: "Hope will never be silent.",
    author: "Harvey Milk",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["personally", "i", "wear", "fur"],
    words: ["personally", "i", "don't", "wear", "fur"],
    id: "Personally, I don't wear fur.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["fewer", "words", "better", "prayer"],
    words: ["the", "fewer", "words", "better", "prayer"],
    id: "The fewer the words, the better the prayer.",
    author: "Martin Luther",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["bad", "company", "corrupts", "character"],
    words: ["bad", "company", "corrupts", "good", "character"],
    id: "Bad company corrupts good character.",
    author: "Menander",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["whatever", "cannot", "understand", "possess"],
    words: ["whatever", "you", "cannot", "understand", "possess"],
    id: "Whatever you cannot understand, you cannot possess.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "ever", "comes", "violence"],
    words: ["nothing", "good", "ever", "comes", "of", "violence"],
    id: "Nothing good ever comes of violence.",
    author: "Martin Luther",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "composed", "misfortunes", "avoided"],
    words: ["happiness", "is", "composed", "of", "misfortunes", "avoided"],
    id: "Happiness is composed of misfortunes avoided.",
    author: "Alphonse Karr",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["faith", "never", "makes", "confession"],
    words: ["faith", "never", "makes", "a", "confession"],
    id: "Faith never makes a confession.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["either", "move", "or", "moved"],
    words: ["either", "move", "or", "be", "moved"],
    id: "Either move or be moved.",
    author: "Ezra Pound",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["always", "your", "next", "move"],
    words: ["it", "is", "always", "your", "next", "move"],
    id: "It is always your next move.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "wasted", "my", "hours"],
    words: ["i", "have", "wasted", "my", "hours"],
    id: "I have wasted my hours.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "absolutely", "normal", "guy"],
    words: ["i'm", "an", "absolutely", "normal", "guy"],
    id: "I'm an absolutely normal guy.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["avoids", "complaint", "invites", "happiness"],
    words: ["he", "who", "avoids", "complaint", "invites", "happiness"],
    id: "He who avoids complaint invites happiness.",
    author: "Abu Bakr",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["precaution", "better", "than", "cure"],
    words: ["precaution", "is", "better", "than", "cure"],
    id: "Precaution is better than cure.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["beauty", "everywhere", "welcome", "guest"],
    words: ["beauty", "is", "everywhere", "a", "welcome", "guest"],
    id: "Beauty is everywhere a welcome guest.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "mean", "families", "weird"],
    words: ["i", "mean", "families", "are", "weird"],
    id: "I mean, families are weird.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["mine", "better", "than", "ours"],
    words: ["mine", "is", "better", "than", "ours"],
    id: "Mine is better than ours.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["order", "heaven's", "first", "law"],
    words: ["order", "is", "heaven's", "first", "law"],
    id: "Order is heaven's first law.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["courage", "found", "unlikely", "places"],
    words: ["courage", "is", "found", "in", "unlikely", "places"],
    id: "Courage is found in unlikely places.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "without", "vote", "protection"],
    words: ["a", "man", "without", "vote", "is", "protection"],
    id: "A man without a vote is man without protection.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["fifth", "freedom", "from", "ignorance"],
    words: ["the", "fifth", "freedom", "is", "from", "ignorance"],
    id: "The fifth freedom is freedom from ignorance.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "comes", "from", "reflection"],
    words: ["change", "comes", "from", "reflection"],
    id: "Change comes from reflection.",
    author: "Genesis P-Orridge",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "didn't", "get", "depressed"],
    words: ["i", "didn't", "get", "depressed", "don't"],
    id: "I didn't get depressed, I don't get depressed.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["fury", "itself", "supplies", "arms"],
    words: ["fury", "itself", "supplies", "arms"],
    id: "Fury itself supplies arms.",
    author: "Virgil",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "saw", "recession", "coming"],
    words: ["no", "one", "saw", "the", "recession", "coming"],
    id: "No one saw the recession coming.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "religion", "simple", "kindness"],
    words: ["my", "religion", "is", "very", "simple", "kindness"],
    id: "My religion is very simple. My religion is kindness.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["health", "consists", "temperance", "alone"],
    words: ["health", "consists", "with", "temperance", "alone"],
    id: "Health consists with temperance alone.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["young", "century", "will", "liberty's"],
    words: ["this", "young", "century", "will", "be", "liberty's"],
    id: "This young century will be liberty's century.",
    author: "George W. Bush",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'd", "rather", "lucky", "than"],
    words: ["i'd", "rather", "be", "lucky", "than", "good"],
    id: "I'd rather be lucky than good.",
    author: "Lefty Gomez",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["two", "evils", "choose", "neither"],
    words: ["of", "two", "evils", "choose", "neither"],
    id: "Of two evils, choose neither.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["our", "necessities", "never", "equal"],
    words: ["our", "necessities", "never", "equal", "wants"],
    id: "Our necessities never equal our wants.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "confuse", "motion", "action"],
    words: ["never", "confuse", "motion", "with", "action"],
    id: "Never confuse motion with action.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "never", "granted", "won"],
    words: ["freedom", "is", "never", "granted", "it", "won"],
    id: "Freedom is never granted; it is won.",
    author: "A. Philip Randolph",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "cannot", "exist", "forever"],
    words: ["nothing", "cannot", "exist", "forever"],
    id: "Nothing cannot exist forever.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["rules", "necessarily", "sacred", "principles"],
    words: ["rules", "are", "not", "necessarily", "sacred", "principles"],
    id: "Rules are not necessarily sacred, principles are.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["excuses", "destroy", "success", "time"],
    words: ["excuses", "destroy", "success", "every", "time"],
    id: "Excuses destroy success every time.",
    author: "Jon Taffer",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["religions", "get", "lost", "as"],
    words: ["religions", "get", "lost", "as", "people", "do"],
    id: "Religions get lost as people do.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["nietzsche", "was", "stupid", "abnormal"],
    words: ["nietzsche", "was", "stupid", "and", "abnormal"],
    id: "Nietzsche was stupid and abnormal.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "wife", "already", "heaven"],
    words: ["my", "wife", "is", "already", "in", "heaven"],
    id: "My wife is already in Heaven.",
    author: "Billy Graham",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "thing", "created", "suddenly"],
    words: ["no", "great", "thing", "is", "created", "suddenly"],
    id: "No great thing is created suddenly.",
    author: "Epictetus",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "trust", "liberals", "conservatives"],
    words: ["i", "don't", "trust", "liberals", "conservatives"],
    id: "I don't trust liberals, I trust conservatives.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["there's", "happiness", "having", "less"],
    words: ["there's", "happiness", "in", "having", "less"],
    id: "There's happiness in having less.",
    author: "Fumio Sasaki",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["would", "make", "lovely", "corpse"],
    words: ["he", "would", "make", "a", "lovely", "corpse"],
    id: "He would make a lovely corpse.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "movements", "too", "far"],
    words: ["all", "movements", "go", "too", "far"],
    id: "All movements go too far.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["ambition", "creep", "as", "soar"],
    words: ["ambition", "can", "creep", "as", "well", "soar"],
    id: "Ambition can creep as well as soar.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "one", "human", "being"],
    words: ["i", "am", "just", "one", "human", "being"],
    id: "I am just one human being.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["peace", "its", "own", "reward"],
    words: ["peace", "is", "its", "own", "reward"],
    id: "Peace is its own reward.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["live", "truth", "instead", "professing"],
    words: ["live", "truth", "instead", "of", "professing", "it"],
    id: "Live truth instead of professing it.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "mind", "becomes", "fortune"],
    words: ["a", "great", "mind", "becomes", "fortune"],
    id: "A great mind becomes a great fortune.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["democracy", "passes", "into", "despotism"],
    words: ["democracy", "passes", "into", "despotism"],
    id: "Democracy passes into despotism.",
    author: "Plato",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "spirits", "desire", "attain"],
    words: ["all", "that", "spirits", "desire", "attain"],
    id: "All that spirits desire, spirits attain.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["film", "spectators", "quiet", "vampires"],
    words: ["film", "spectators", "are", "quiet", "vampires"],
    id: "Film spectators are quiet vampires.",
    author: "Jim Morrison",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "won't", "anything", "green"],
    words: ["i", "won't", "eat", "anything", "green"],
    id: "I won't eat anything green.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "rather", "inclined", "silence"],
    words: ["i", "am", "rather", "inclined", "to", "silence"],
    id: "I am rather inclined to silence.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "things", "only", "transitory"],
    words: ["all", "things", "are", "only", "transitory"],
    id: "All things are only transitory.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["count", "your", "years", "make"],
    words: ["don't", "just", "count", "your", "years", "make"],
    id: "Don't just count your years, make your years count.",
    author: "George Meredith",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["mainstream", "always", "under", "attack"],
    words: ["the", "mainstream", "is", "always", "under", "attack"],
    id: "The mainstream is always under attack.",
    author: "Bill Gates",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "your", "thoughts", "world"],
    words: ["change", "your", "thoughts", "and", "you", "world"],
    id: "Change your thoughts and you change your world.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "comes", "by", "disillusionment"],
    words: ["wisdom", "comes", "by", "disillusionment"],
    id: "Wisdom comes by disillusionment.",
    author: "George Santayana",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["progress", "takes", "time", "discipline"],
    words: ["progress", "takes", "time", "and", "discipline"],
    id: "Progress takes time and discipline.",
    author: "Ronnie Coleman",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["look", "twice", "before", "leap"],
    words: ["look", "twice", "before", "you", "leap"],
    id: "Look twice before you leap.",
    author: "Charlotte Bronte",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["slow", "down", "enjoy", "life"],
    words: ["slow", "down", "and", "enjoy", "life"],
    id: "Slow down and enjoy life.",
    author: "Skip Marley",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["games", "lubricate", "body", "mind"],
    words: ["games", "lubricate", "the", "body", "and", "mind"],
    id: "Games lubricate the body and the mind.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["always", "too", "early", "quit"],
    words: ["it's", "always", "too", "early", "to", "quit"],
    id: "It's always too early to quit.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["necessity", "never", "made", "bargain"],
    words: ["necessity", "never", "made", "a", "good", "bargain"],
    id: "Necessity never made a good bargain.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["liberty", "there", "my", "country"],
    words: ["where", "liberty", "is", "there", "my", "country"],
    id: "Where liberty is, there is my country.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["knavery", "flattery", "blood", "relations"],
    words: ["knavery", "and", "flattery", "are", "blood", "relations"],
    id: "Knavery and flattery are blood relations.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "begins", "at", "end"],
    words: ["wisdom", "begins", "at", "the", "end"],
    id: "Wisdom begins at the end.",
    author: "Daniel Webster",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["imagination", "true", "magic", "carpet"],
    words: ["imagination", "is", "the", "true", "magic", "carpet"],
    id: "Imagination is the true magic carpet.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["wounded", "deer", "leaps", "highest"],
    words: ["a", "wounded", "deer", "leaps", "the", "highest"],
    id: "A wounded deer leaps the highest.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["finite", "fail", "infinite", "venture"],
    words: ["finite", "to", "fail", "but", "infinite", "venture"],
    id: "Finite to fail, but infinite to venture.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["less", "routine", "more", "life"],
    words: ["the", "less", "routine", "more", "life"],
    id: "The less routine the more life.",
    author: "Amos Bronson Alcott",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["done", "better", "than", "said"],
    words: ["well", "done", "is", "better", "than", "said"],
    id: "Well done is better than well said.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "worth", "two", "tomorrows"],
    words: ["one", "today", "is", "worth", "two", "tomorrows"],
    id: "One today is worth two tomorrows.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["will", "never", "win", "begin"],
    words: ["you", "will", "never", "win", "if", "begin"],
    id: "You will never win if you never begin.",
    author: "Helen Rowland",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["god", "helps", "those", "themselves"],
    words: ["god", "helps", "those", "who", "help", "themselves"],
    id: "God helps those who help themselves.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["man's", "extremity", "god's", "opportunity"],
    words: ["man's", "extremity", "is", "god's", "opportunity"],
    id: "Man's extremity is God's opportunity.",
    author: "John Flavel",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "really", "revenge", "movies"],
    words: ["i", "really", "like", "revenge", "movies"],
    id: "I really like revenge movies.",
    author: "Joe Flacco",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "outweighs", "any", "wealth"],
    words: ["wisdom", "outweighs", "any", "wealth"],
    id: "Wisdom outweighs any wealth.",
    author: "Sophocles",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["music", "passions", "enjoy", "themselves"],
    words: ["in", "music", "the", "passions", "enjoy", "themselves"],
    id: "In music the passions enjoy themselves.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "governments", "can't", "much"],
    words: ["i", "think", "governments", "can't", "do", "much"],
    id: "I think governments can't do much.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["lost", "time", "never", "found"],
    words: ["lost", "time", "is", "never", "found", "again"],
    id: "Lost time is never found again.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["abused", "patience", "turns", "fury"],
    words: ["abused", "patience", "turns", "to", "fury"],
    id: "Abused patience turns to fury.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "too", "must", "endured"],
    words: ["great", "loves", "too", "must", "be", "endured"],
    id: "Great loves too must be endured.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "deceived", "deceive", "ourselves"],
    words: ["we", "are", "never", "deceived", "deceive", "ourselves"],
    id: "We are never deceived; we deceive ourselves.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "didn't", "play", "children"],
    words: ["i", "didn't", "play", "with", "other", "children"],
    id: "I didn't play with other children.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["fashion", "made", "become", "unfashionable"],
    words: ["fashion", "is", "made", "to", "become", "unfashionable"],
    id: "Fashion is made to become unfashionable.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["heaven", "dumb", "echoing", "only"],
    words: ["heaven", "is", "dumb", "echoing", "only", "the"],
    id: "Heaven is dumb, echoing only the dumb.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "demands", "singleness", "purpose"],
    words: ["success", "demands", "singleness", "of", "purpose"],
    id: "Success demands singleness of purpose.",
    author: "Vince Lombardi",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["giver", "more", "than", "gift"],
    words: ["love", "the", "giver", "more", "than", "gift"],
    id: "Love the giver more than the gift.",
    author: "Brigham Young",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["talent", "without", "hard", "nothing"],
    words: ["talent", "without", "working", "hard", "is", "nothing"],
    id: "Talent without working hard is nothing.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "win", "nothing", "hurts"],
    words: ["when", "you", "win", "nothing", "hurts"],
    id: "When you win, nothing hurts.",
    author: "Joe Namath",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["paris", "ain't", "much", "town"],
    words: ["paris", "ain't", "much", "of", "a", "town"],
    id: "Paris ain't much of a town.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["brain", "wider", "than", "sky"],
    words: ["the", "brain", "is", "wider", "than", "sky"],
    id: "The brain is wider than the sky.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "friendship", "circle", "minuscule"],
    words: ["my", "friendship", "circle", "is", "minuscule"],
    id: "My friendship circle is minuscule.",
    author: "Fearne Cotton",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["sweat", "makes", "friendship", "cement"],
    words: ["sweat", "makes", "good", "friendship", "cement"],
    id: "Sweat makes good friendship cement.",
    author: "Tyne Daly",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "vote", "jury", "duty"],
    words: ["i", "vote", "and", "do", "jury", "duty"],
    id: "I vote and I do jury duty.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["way", "i", "talk", "bizarre"],
    words: ["the", "way", "i", "talk", "is", "bizarre"],
    id: "The way I talk is bizarre.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["age", "considers", "youth", "ventures"],
    words: ["age", "considers", "youth", "ventures"],
    id: "Age considers; youth ventures.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "many", "sad", "days"],
    words: ["i", "don't", "have", "many", "sad", "days"],
    id: "I don't have many sad days.",
    author: "Billy Graham",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "mother", "did", "children"],
    words: ["my", "mother", "did", "not", "like", "children"],
    id: "My mother did not like children.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "dependent", "on", "effort"],
    words: ["success", "is", "dependent", "on", "effort"],
    id: "Success is dependent on effort.",
    author: "Sophocles",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "find", "fault", "absent"],
    words: ["never", "find", "fault", "with", "the", "absent"],
    id: "Never find fault with the absent.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["use", "plants", "bring", "life"],
    words: ["use", "plants", "to", "bring", "life"],
    id: "Use plants to bring life.",
    author: "Douglas Wilson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["without", "failure", "there", "achievement"],
    words: ["without", "failure", "there", "is", "no", "achievement"],
    id: "Without failure there is no achievement.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["bad", "men", "full", "repentance"],
    words: ["bad", "men", "are", "full", "of", "repentance"],
    id: "Bad men are full of repentance.",
    author: "Aristotle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["err", "human", "forgive", "divine"],
    words: ["to", "err", "is", "human", "forgive", "divine"],
    id: "To err is human; to forgive, divine.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "facts", "only", "interpretations"],
    words: ["there", "are", "no", "facts", "only", "interpretations"],
    id: "There are no facts, only interpretations.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["gentle", "dullness", "ever", "joke"],
    words: ["gentle", "dullness", "ever", "loves", "a", "joke"],
    id: "Gentle dullness ever loves a joke.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["most", "positive", "men", "credulous"],
    words: ["the", "most", "positive", "men", "are", "credulous"],
    id: "The most positive men are the most credulous.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["live", "without", "hope", "cease"],
    words: ["to", "live", "without", "hope", "is", "cease"],
    id: "To live without Hope is to Cease to live.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["silence", "safer", "than", "speech"],
    words: ["silence", "is", "safer", "than", "speech"],
    id: "Silence is safer than speech.",
    author: "Epictetus",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "contemptible", "fear", "contempt"],
    words: ["only", "the", "contemptible", "fear", "contempt"],
    id: "Only the contemptible fear contempt.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["stolen", "kisses", "always", "sweetest"],
    words: ["stolen", "kisses", "are", "always", "sweetest"],
    id: "Stolen kisses are always sweetest.",
    author: "Leigh Hunt",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["eventually", "all", "companies", "replaced"],
    words: ["eventually", "all", "companies", "are", "replaced"],
    id: "Eventually, all companies are replaced.",
    author: "Bill Gates",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "shoot", "from", "hip"],
    words: ["i", "shoot", "from", "the", "hip"],
    id: "I shoot from the hip.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "never", "hold", "grudge"],
    words: ["i", "never", "hold", "a", "grudge"],
    id: "I never hold a grudge.",
    author: "Billy Graham",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["value", "your", "friendship", "relationships"],
    words: ["value", "your", "friendship", "relationships"],
    id: "Value your friendship. Value your relationships.",
    author: "Barbara Bush",
    grammar: null,
    word_count: 4,
    all_intersection_count: 0,
    new_words_count: 4,
  },
  {
    row_new_words: ["old", "authentic", "genuine", "valuable"],
    words: ["old", "is", "authentic", "genuine", "valuable"],
    id: "Old is authentic. Old is genuine. Old is valuable.",
    author: "Billy Graham",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "plus", "my", "circumstances"],
    words: ["i", "am", "plus", "my", "circumstances"],
    id: "I am I plus my circumstances.",
    author: "Jose Ortega y Gasset",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["men", "would", "angels", "gods"],
    words: ["men", "would", "be", "angels", "gods"],
    id: "Men would be angels, angels would be gods.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["law", "mind", "without", "reason"],
    words: ["law", "is", "mind", "without", "reason"],
    id: "Law is mind without reason.",
    author: "Aristotle",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "my", "greatest", "enemy"],
    words: ["time", "is", "my", "greatest", "enemy"],
    id: "Time is my greatest enemy.",
    author: "Evita Peron",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["after", "storm", "comes", "calm"],
    words: ["after", "a", "storm", "comes", "calm"],
    id: "After a storm comes a calm.",
    author: "Matthew Henry",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "christian", "by", "choice"],
    words: ["i'm", "a", "christian", "by", "choice"],
    id: "I'm a Christian by choice.",
    author: "Barack Obama",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i've", "got", "nothing", "hide"],
    words: ["i've", "got", "nothing", "to", "hide"],
    id: "I've got nothing to hide.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "write", "letters", "anymore"],
    words: ["i", "don't", "write", "letters", "anymore"],
    id: "I don't write letters anymore.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "hid", "myself", "food"],
    words: ["i", "hid", "myself", "in", "food"],
    id: "I hid myself in food.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["world", "forgetting", "by", "forgot"],
    words: ["the", "world", "forgetting", "by", "forgot"],
    id: "The world forgetting, by the world forgot.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["without", "forgiveness", "there's", "future"],
    words: ["without", "forgiveness", "there's", "no", "future"],
    id: "Without forgiveness, there's no future.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 5,
    all_intersection_count: 1,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "dies", "really", "lives"],
    words: ["every", "man", "dies", "not", "really", "lives"],
    id: "Every man dies. Not every man really lives.",
    author: "William Wallace",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "peer", "mediator", "chance"],
    words: ["am", "i", "a", "peer", "mediator", "not", "chance"],
    id: "Am I a peer mediator? Not a chance.",
    author: "Travis Kelce",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["music", "way", "opening", "up"],
    words: ["music", "is", "a", "way", "of", "opening", "up"],
    id: "Music is a way of opening up.",
    author: "Rod Wave",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["merry", "heart", "doeth", "medicine"],
    words: ["a", "merry", "heart", "doeth", "good", "like", "medicine"],
    id: "A merry heart doeth good like medicine.",
    author: "King Solomon",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "environment", "pretty", "refreshing"],
    words: ["a", "change", "of", "environment", "is", "pretty", "refreshing"],
    id: "A change of environment is pretty refreshing.",
    author: "Owen Farrell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["tis", "healthy", "sick", "sometimes"],
    words: ["tis", "healthy", "to", "be", "sick", "sometimes"],
    id: "'Tis healthy to be sick sometimes.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["men", "best", "sometimes", "forget"],
    words: ["but", "men", "are", "the", "best", "sometimes", "forget"],
    id: "But men are men; the best sometimes forget.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["america", "greatest", "country", "world"],
    words: ["america", "is", "the", "greatest", "country", "in", "world"],
    id: "America is the greatest country in the world.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["clash", "ideas", "sound", "freedom"],
    words: ["the", "clash", "of", "ideas", "is", "sound", "freedom"],
    id: "The clash of ideas is the sound of freedom.",
    author: "Lady Bird Johnson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "got", "quarrel", "vietcong"],
    words: ["i", "got", "no", "quarrel", "with", "them", "vietcong"],
    id: "I got no quarrel with them Vietcong.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["none", "himself", "his", "parallel"],
    words: ["none", "but", "himself", "can", "be", "his", "parallel"],
    id: "None but himself can be his parallel.",
    author: "Virgil",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["words", "meaning", "their", "change"],
    words: ["words", "have", "meaning", "and", "their", "doesn't", "change"],
    id: "Words have meaning. And their meaning doesn't change.",
    author: "Antonin Scalia",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "dreamer", "football", "romantic"],
    words: ["i", "am", "not", "a", "dreamer", "football", "romantic"],
    id: "I am not a dreamer. I am a football romantic.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "feel", "my", "momentum"],
    words: ["i", "do", "feel", "my", "work", "has", "momentum"],
    id: "I do feel my work has momentum.",
    author: "Ken Stott",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["personality", "everything", "art", "poetry"],
    words: ["personality", "is", "everything", "in", "art", "and", "poetry"],
    id: "Personality is everything in art and poetry.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "rapper", "more", "hustler"],
    words: ["i'm", "a", "rapper", "but", "more", "of", "hustler"],
    id: "I'm a rapper but I'm more of a hustler.",
    author: "Lil Baby",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "normal", "means", "anyway"],
    words: ["i", "don't", "know", "what", "normal", "means", "anyway"],
    id: "I don't know what 'normal' means, anyway.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["things", "happen", "those", "hustle"],
    words: ["good", "things", "happen", "to", "those", "who", "hustle"],
    id: "Good things happen to those who hustle.",
    author: "Chuck Noll",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "i", "kill", "killing"],
    words: ["the", "time", "i", "kill", "is", "killing", "me"],
    id: "The time I kill is killing me.",
    author: "Mason Cooley",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "i'm", "unlucky", "person"],
    words: ["i", "don't", "think", "i'm", "an", "unlucky", "person"],
    id: "I don't think I'm an unlucky person.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["experience", "teacher", "all", "things"],
    words: ["experience", "is", "the", "teacher", "of", "all", "things"],
    id: "Experience is the teacher of all things.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["greatest", "remedy", "anger", "delay"],
    words: ["the", "greatest", "remedy", "for", "anger", "is", "delay"],
    id: "The greatest remedy for anger is delay.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["men", "become", "tools", "their"],
    words: ["men", "have", "become", "the", "tools", "of", "their"],
    id: "Men have become the tools of their tools.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["aren't", "anything", "when", "talking"],
    words: ["you", "aren't", "learning", "anything", "when", "you're", "talking"],
    id: "You aren't learning anything when you're talking.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["its", "essence", "spiritual", "fire"],
    words: ["love", "in", "its", "essence", "is", "spiritual", "fire"],
    id: "Love in its essence is spiritual fire.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["gratitude", "sickness", "suffered", "by"],
    words: ["gratitude", "is", "a", "sickness", "suffered", "by", "dogs"],
    id: "Gratitude is a sickness suffered by dogs.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "lived", "long", "enough"],
    words: ["life", "if", "well", "lived", "is", "long", "enough"],
    id: "Life, if well lived, is long enough.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["desire", "having", "sin", "covetousness"],
    words: ["desire", "of", "having", "is", "the", "sin", "covetousness"],
    id: "Desire of having is the sin of covetousness.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["friend", "nothing", "known", "enemy"],
    words: ["a", "friend", "is", "nothing", "but", "known", "enemy"],
    id: "A friend is nothing but a known enemy.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["power", "alluring", "pure", "minds"],
    words: ["power", "is", "not", "alluring", "to", "pure", "minds"],
    id: "Power is not alluring to pure minds.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["reign", "must", "submit", "greater"],
    words: ["every", "reign", "must", "submit", "to", "a", "greater"],
    id: "Every reign must submit to a greater reign.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["men", "born", "succeed", "fail"],
    words: ["men", "are", "born", "to", "succeed", "not", "fail"],
    id: "Men are born to succeed, not to fail.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "succumb", "temptation", "bitterness"],
    words: ["never", "succumb", "to", "the", "temptation", "of", "bitterness"],
    id: "Never succumb to the temptation of bitterness.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["more", "things", "change", "same"],
    words: ["the", "more", "things", "change", "they", "are", "same"],
    id: "The more things change, the more they are the same.",
    author: "Alphonse Karr",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["you've", "got", "talent", "protect"],
    words: ["if", "you've", "got", "a", "talent", "protect", "it"],
    id: "If you've got a talent, protect it.",
    author: "Jim Carrey",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["heaven", "makes", "one", "heavenly"],
    words: ["the", "love", "of", "heaven", "makes", "one", "heavenly"],
    id: "The love of heaven makes one heavenly.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["overestimate", "decency", "human", "race"],
    words: ["don't", "overestimate", "the", "decency", "of", "human", "race"],
    id: "Don't overestimate the decency of the human race.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["platitudes", "there", "because", "true"],
    words: ["platitudes", "yes", "there", "are", "because", "they", "true"],
    id: "Platitudes? Yes, there are platitudes. Platitudes are there because they are true.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["something", "wonderful", "may", "imitate"],
    words: ["do", "something", "wonderful", "people", "may", "imitate", "it"],
    id: "Do something wonderful, people may imitate it.",
    author: "Albert Schweitzer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["swap", "horses", "crossing", "stream"],
    words: ["don't", "swap", "horses", "in", "crossing", "a", "stream"],
    id: "Don't swap horses in crossing a stream.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["statesman", "cannot", "afford", "moralist"],
    words: ["a", "statesman", "cannot", "afford", "to", "be", "moralist"],
    id: "A statesman cannot afford to be a moralist.",
    author: "Will Durant",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["commonsense", "realised", "sense", "proportion"],
    words: ["commonsense", "is", "the", "realised", "sense", "of", "proportion"],
    id: "Commonsense is the realised sense of proportion.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["throw", "kisses", "hopelessly", "lazy"],
    words: ["people", "who", "throw", "kisses", "are", "hopelessly", "lazy"],
    id: "People who throw kisses are hopelessly lazy.",
    author: "Bob Hope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "right", "little", "wrong"],
    words: ["to", "do", "a", "great", "right", "little", "wrong"],
    id: "To do a great right do a little wrong.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["near", "sun", "center", "universe"],
    words: ["near", "the", "sun", "is", "center", "of", "universe"],
    id: "Near the sun is the center of the universe.",
    author: "Nicolaus Copernicus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["legacy", "rich", "as", "honesty"],
    words: ["no", "legacy", "is", "so", "rich", "as", "honesty"],
    id: "No legacy is so rich as honesty.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["our", "life", "thoughts", "make"],
    words: ["our", "life", "is", "what", "thoughts", "make", "it"],
    id: "Our life is what our thoughts make it.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["family", "one", "nature's", "masterpieces"],
    words: ["the", "family", "is", "one", "of", "nature's", "masterpieces"],
    id: "The family is one of nature's masterpieces.",
    author: "George Santayana",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["dreams", "touchstones", "our", "character"],
    words: ["dreams", "are", "the", "touchstones", "of", "our", "character"],
    id: "Dreams are the touchstones of our character.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "wasted", "on", "living"],
    words: ["life", "is", "wasted", "on", "the", "living"],
    id: "Life is wasted on the living.",
    author: "Douglas Adams",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["pleasure", "job", "puts", "perfection"],
    words: ["pleasure", "in", "the", "job", "puts", "perfection", "work"],
    id: "Pleasure in the job puts perfection in the work.",
    author: "Aristotle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "should", "upright", "kept"],
    words: ["a", "man", "should", "be", "upright", "not", "kept"],
    id: "A man should be upright, not be kept upright.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wise", "resolve", "patient", "perform"],
    words: ["wise", "to", "resolve", "and", "patient", "perform"],
    id: "Wise to resolve, and patient to perform.",
    author: "Homer",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["friendship", "set", "on", "fire"],
    words: ["love", "is", "friendship", "set", "on", "fire"],
    id: "Love is friendship set on fire.",
    author: "Jeremy Taylor",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["predicting", "rain", "count", "arks"],
    words: ["predicting", "rain", "doesn't", "count", "building", "arks", "does"],
    id: "Predicting rain doesn't count. Building arks does.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["intelligence", "ability", "adapt", "change"],
    words: ["intelligence", "is", "the", "ability", "to", "adapt", "change"],
    id: "Intelligence is the ability to adapt to change.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "make", "movies", "oscars"],
    words: ["you", "never", "make", "movies", "for", "oscars"],
    id: "You never make movies for Oscars.",
    author: "Peter Jackson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "never", "given", "won"],
    words: ["freedom", "is", "never", "given", "it", "won"],
    id: "Freedom is never given; it is won.",
    author: "A. Philip Randolph",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["gain", "strength", "temptation", "resist"],
    words: ["we", "gain", "the", "strength", "of", "temptation", "resist"],
    id: "We gain the strength of the temptation we resist.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["jests", "rich", "ever", "successful"],
    words: ["the", "jests", "of", "rich", "are", "ever", "successful"],
    id: "The jests of the rich are ever successful.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["earth", "belongs", "living", "dead"],
    words: ["the", "earth", "belongs", "to", "living", "not", "dead"],
    id: "The earth belongs to the living, not to the dead.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "without", "their", "consent"],
    words: ["cats", "don't", "like", "change", "without", "their", "consent"],
    id: "Cats don't like change without their consent.",
    author: "Roger Caras",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "patience", "at", "all"],
    words: ["i", "have", "no", "patience", "at", "all"],
    id: "I have no patience at all.",
    author: "Alan Sugar",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "illusion", "lunchtime", "doubly"],
    words: ["time", "is", "an", "illusion", "lunchtime", "doubly", "so"],
    id: "Time is an illusion. Lunchtime doubly so.",
    author: "Douglas Adams",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "society", "end", "government"],
    words: ["the", "happiness", "of", "society", "is", "end", "government"],
    id: "The happiness of society is the end of government.",
    author: "John Adams",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["film", "petrified", "fountain", "thought"],
    words: ["a", "film", "is", "petrified", "fountain", "of", "thought"],
    id: "A film is a petrified fountain of thought.",
    author: "Jean Cocteau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["beware", "person", "one", "book"],
    words: ["beware", "of", "the", "person", "one", "book"],
    id: "Beware of the person of one book.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["healthy", "discontent", "prelude", "progress"],
    words: ["healthy", "discontent", "is", "the", "prelude", "to", "progress"],
    id: "Healthy discontent is the prelude to progress.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["poverty", "worst", "form", "violence"],
    words: ["poverty", "is", "the", "worst", "form", "of", "violence"],
    id: "Poverty is the worst form of violence.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["impractical", "jokers", "all", "friendship"],
    words: ["impractical", "jokers", "is", "all", "about", "friendship"],
    id: "Impractical Jokers' is all about friendship.",
    author: "James Murray",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["love's", "malady", "without", "cure"],
    words: ["but", "love's", "a", "malady", "without", "cure"],
    id: "But love's a malady without a cure.",
    author: "John Dryden",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["possession", "anything", "begins", "mind"],
    words: ["the", "possession", "of", "anything", "begins", "in", "mind"],
    id: "The possession of anything begins in the mind.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["vienna", "gate", "eastern", "europe"],
    words: ["vienna", "is", "the", "gate", "to", "eastern", "europe"],
    id: "Vienna is the gate to Eastern Europe.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "those", "wander", "lost"],
    words: ["not", "all", "those", "who", "wander", "are", "lost"],
    id: "Not all those who wander are lost.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "success", "rate", "itself"],
    words: ["my", "success", "rate", "speaks", "for", "itself"],
    id: "My success rate speaks for itself.",
    author: "Abby Lee Miller",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["even", "wisdom", "yield", "self-interest"],
    words: ["even", "wisdom", "has", "to", "yield", "self-interest"],
    id: "Even wisdom has to yield to self-interest.",
    author: "Pindar",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "dna", "software", "system"],
    words: ["life", "is", "a", "dna", "software", "system"],
    id: "Life is a DNA software system.",
    author: "Craig Venter",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["soul's", "joy", "lies", "doing"],
    words: ["the", "soul's", "joy", "lies", "in", "doing"],
    id: "The soul's joy lies in doing.",
    author: "Percy Bysshe Shelley",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["keeping", "score", "games", "friendships"],
    words: ["keeping", "score", "is", "for", "games", "not", "friendships"],
    id: "Keeping score is for games, not friendships.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["lipton", "employees", "take", "breaks"],
    words: ["do", "lipton", "employees", "take", "coffee", "breaks"],
    id: "Do Lipton employees take coffee breaks?",
    author: "Steven Wright",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["reactionary", "somnambulist", "walking", "backwards"],
    words: ["a", "reactionary", "is", "somnambulist", "walking", "backwards"],
    id: "A reactionary is a somnambulist walking backwards.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["trust", "too", "much", "appearances"],
    words: ["trust", "not", "too", "much", "to", "appearances"],
    id: "Trust not too much to appearances.",
    author: "Virgil",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "short", "art", "long"],
    words: ["life", "is", "short", "the", "art", "long"],
    id: "Life is short, the art long.",
    author: "Hippocrates",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["promise", "made", "debt", "unpaid"],
    words: ["a", "promise", "made", "is", "debt", "unpaid"],
    id: "A promise made is a debt unpaid.",
    author: "Robert W. Service",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "itself", "kind", "gratitude"],
    words: ["happiness", "is", "itself", "a", "kind", "of", "gratitude"],
    id: "Happiness is itself a kind of gratitude.",
    author: "Joseph Wood Krutch",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "ideas", "originate", "muscles"],
    words: ["great", "ideas", "originate", "in", "the", "muscles"],
    id: "Great ideas originate in the muscles.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "worth", "more", "than"],
    words: ["nothing", "is", "worth", "more", "than", "this", "day"],
    id: "Nothing is worth more than this day.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["useless", "life", "early", "death"],
    words: ["a", "useless", "life", "is", "an", "early", "death"],
    id: "A useless life is an early death.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "best", "when", "shared"],
    words: ["success", "is", "best", "when", "it's", "shared"],
    id: "Success is best when it's shared.",
    author: "Howard Schultz",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["road", "success", "runs", "uphill"],
    words: ["the", "road", "to", "success", "runs", "uphill"],
    id: "The road to success runs uphill.",
    author: "Willie Davis",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "held", "seed", "shared"],
    words: ["happiness", "held", "is", "the", "seed", "shared", "flower"],
    id: "Happiness held is the seed; Happiness shared is the flower.",
    author: "John Harrigan",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "your", "heart", "tells"],
    words: ["only", "do", "what", "your", "heart", "tells", "you"],
    id: "Only do what your heart tells you.",
    author: "Princess Diana",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["exploration", "by", "real", "inspires"],
    words: ["exploration", "by", "real", "people", "inspires", "us"],
    id: "Exploration by real people inspires us.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["slow", "steady", "wins", "race"],
    words: ["slow", "and", "steady", "wins", "the", "race"],
    id: "Slow and steady wins the race.",
    author: "Robert Lloyd",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["your", "ideology", "i", "mine"],
    words: ["you", "have", "your", "ideology", "and", "i", "mine"],
    id: "You have your ideology and I have mine.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "found", "only", "truth"],
    words: ["wisdom", "is", "found", "only", "in", "truth"],
    id: "Wisdom is found only in truth.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["science", "captain", "practice", "soldiers"],
    words: ["science", "is", "the", "captain", "and", "practice", "soldiers"],
    id: "Science is the captain, and practice the soldiers.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "success", "without", "hardship"],
    words: ["there", "is", "no", "success", "without", "hardship"],
    id: "There is no success without hardship.",
    author: "Sophocles",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "live", "crazy", "time"],
    words: ["i", "live", "in", "a", "crazy", "time"],
    id: "I live in a crazy time.",
    author: "Anne Frank",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["neither", "borrower", "nor", "lender"],
    words: ["neither", "a", "borrower", "nor", "lender", "be"],
    id: "Neither a borrower nor a lender be.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["decline", "literature", "indicates", "nation"],
    words: ["the", "decline", "of", "literature", "indicates", "a", "nation"],
    id: "The decline of literature indicates the decline of a nation.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "sails", "wind", "time"],
    words: ["wisdom", "sails", "with", "wind", "and", "time"],
    id: "Wisdom sails with wind and time.",
    author: "John Florio",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["correction", "much", "encouragement", "more"],
    words: ["correction", "does", "much", "but", "encouragement", "more"],
    id: "Correction does much, but encouragement does more.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["whenever", "speech", "corrupted", "mind"],
    words: ["whenever", "the", "speech", "is", "corrupted", "so", "mind"],
    id: "Whenever the speech is corrupted so is the mind.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["closed", "mouth", "catches", "flies"],
    words: ["a", "closed", "mouth", "catches", "no", "flies"],
    id: "A closed mouth catches no flies.",
    author: "Miguel de Cervantes",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["man's", "as", "miserable", "thinks"],
    words: ["a", "man's", "as", "miserable", "he", "thinks", "is"],
    id: "A man's as miserable as he thinks he is.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "chaos", "better", "lighting"],
    words: ["freedom", "is", "just", "chaos", "with", "better", "lighting"],
    id: "Freedom is just Chaos, with better lighting.",
    author: "Alan Dean Foster",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["love's", "tongue", "his", "eyes"],
    words: ["love's", "tongue", "is", "in", "his", "eyes"],
    id: "Love's tongue is in his eyes.",
    author: "John Fletcher",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "failure", "equally", "disastrous"],
    words: ["success", "and", "failure", "are", "equally", "disastrous"],
    id: "Success and failure are equally disastrous.",
    author: "Tennessee Williams",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "consists", "anticipation", "consequences"],
    words: ["wisdom", "consists", "of", "the", "anticipation", "consequences"],
    id: "Wisdom consists of the anticipation of consequences.",
    author: "Norman Cousins",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "virtue", "its", "reward"],
    words: ["happiness", "is", "a", "virtue", "not", "its", "reward"],
    id: "Happiness is a virtue, not its reward.",
    author: "Baruch Spinoza",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["bitterness", "imprisons", "life", "releases"],
    words: ["bitterness", "imprisons", "life", "love", "releases", "it"],
    id: "Bitterness imprisons life; love releases it.",
    author: "Harry Emerson Fosdick",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["true", "stories", "never", "endings"],
    words: ["true", "love", "stories", "never", "have", "endings"],
    id: "True love stories never have endings.",
    author: "Richard Bach",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "never", "enjoyed", "film"],
    words: ["i", "never", "enjoyed", "working", "in", "a", "film"],
    id: "I never enjoyed working in a film.",
    author: "Marlene Dietrich",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "exist", "as", "enough"],
    words: ["i", "exist", "as", "am", "that", "is", "enough"],
    id: "I exist as I am, that is enough.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["fool", "wise", "his", "eyes"],
    words: ["a", "fool", "is", "wise", "in", "his", "eyes"],
    id: "A fool is wise in his eyes.",
    author: "King Solomon",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["attitude", "determines", "altitude", "life"],
    words: ["attitude", "determines", "the", "altitude", "of", "life"],
    id: "Attitude determines the altitude of life.",
    author: "Edwin Louis Cole",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "his", "own", "hell"],
    words: ["every", "man", "is", "his", "own", "hell"],
    id: "Every man is his own hell.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["absence", "sharpens", "presence", "strengthens"],
    words: ["absence", "sharpens", "love", "presence", "strengthens", "it"],
    id: "Absence sharpens love, presence strengthens it.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "make", "movies", "exclusively"],
    words: ["i", "make", "movies", "for", "money", "exclusively"],
    id: "I make movies for money, exclusively for money.",
    author: "Klaus Kinski",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["groundwork", "all", "happiness", "health"],
    words: ["the", "groundwork", "of", "all", "happiness", "is", "health"],
    id: "The groundwork of all happiness is health.",
    author: "Leigh Hunt",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["common", "sense", "genius", "humanity"],
    words: ["common", "sense", "is", "the", "genius", "of", "humanity"],
    id: "Common sense is the genius of humanity.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["patience", "time", "heals", "all"],
    words: ["patience", "time", "love", "it", "heals", "all"],
    id: "Patience, time, love, it heals all.",
    author: "Ray J",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["action", "real", "measure", "intelligence"],
    words: ["action", "is", "the", "real", "measure", "of", "intelligence"],
    id: "Action is the real measure of intelligence.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["television", "chewing", "gum", "eyes"],
    words: ["television", "is", "chewing", "gum", "for", "the", "eyes"],
    id: "Television is chewing gum for the eyes.",
    author: "Frank Lloyd Wright",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "little", "success", "laughter"],
    words: ["there", "is", "little", "success", "where", "laughter"],
    id: "There is little success where there is little laughter.",
    author: "Andrew Carnegie",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["cosmology", "rapidly", "advancing", "field"],
    words: ["cosmology", "is", "a", "rapidly", "advancing", "field"],
    id: "Cosmology is a rapidly advancing field.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "insecure", "strive", "security"],
    words: ["only", "the", "insecure", "strive", "for", "security"],
    id: "Only the insecure strive for security.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["real", "minimum", "wage", "zero"],
    words: ["the", "real", "minimum", "wage", "is", "zero"],
    id: "The real minimum wage is zero.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "flying", "never", "return"],
    words: ["time", "is", "flying", "never", "to", "return"],
    id: "Time is flying never to return.",
    author: "Virgil",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["march", "human", "mind", "slow"],
    words: ["the", "march", "of", "human", "mind", "is", "slow"],
    id: "The march of the human mind is slow.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["hitch", "your", "wagon", "star"],
    words: ["hitch", "your", "wagon", "to", "a", "star"],
    id: "Hitch your wagon to a star.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "monster", "white", "rabbit"],
    words: ["i'm", "not", "a", "monster", "white", "rabbit"],
    id: "I'm not a monster; I'm a white rabbit.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["fear", "those", "argue", "dodge"],
    words: ["fear", "not", "those", "who", "argue", "but", "dodge"],
    id: "Fear not those who argue but those who dodge.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "never", "grow", "up"],
    words: ["i", "never", "want", "to", "grow", "up"],
    id: "I never want to grow up.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "fool", "understand", "another"],
    words: ["what", "one", "fool", "can", "understand", "another"],
    id: "What one fool can understand, another can.",
    author: "Richard P. Feynman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["thing", "fear", "bring", "pass"],
    words: ["the", "thing", "we", "fear", "bring", "to", "pass"],
    id: "The thing we fear we bring to pass.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wheel", "come", "full", "circle"],
    words: ["the", "wheel", "is", "come", "full", "circle"],
    id: "The wheel is come full circle.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["which", "inhuman", "cannot", "divine"],
    words: ["that", "which", "is", "inhuman", "cannot", "be", "divine"],
    id: "That which is inhuman cannot be divine.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "as", "skinny", "feels"],
    words: ["nothing", "tastes", "as", "good", "skinny", "feels"],
    id: "Nothing tastes as good as skinny feels.",
    author: "Kate Moss",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["blessing", "ignored", "becomes", "curse"],
    words: ["every", "blessing", "ignored", "becomes", "a", "curse"],
    id: "Every blessing ignored becomes a curse.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["ballot", "stronger", "than", "bullet"],
    words: ["the", "ballot", "is", "stronger", "than", "bullet"],
    id: "The ballot is stronger than the bullet.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["greatest", "evil", "physical", "pain"],
    words: ["the", "greatest", "evil", "is", "physical", "pain"],
    id: "The greatest evil is physical pain.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["price", "freedom", "eternal", "vigilance"],
    words: ["the", "price", "of", "freedom", "is", "eternal", "vigilance"],
    id: "The price of freedom is eternal vigilance.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["hath", "many", "friends", "none"],
    words: ["he", "who", "hath", "many", "friends", "none"],
    id: "He who hath many friends hath none.",
    author: "Aristotle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "eye", "sees", "feels"],
    words: ["one", "eye", "sees", "the", "other", "feels"],
    id: "One eye sees, the other feels.",
    author: "Paul Klee",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["falsehood", "easy", "truth", "difficult"],
    words: ["falsehood", "is", "easy", "truth", "so", "difficult"],
    id: "Falsehood is easy, truth so difficult.",
    author: "George Eliot",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["joy", "serious", "business", "heaven"],
    words: ["joy", "is", "the", "serious", "business", "of", "heaven"],
    id: "Joy is the serious business of Heaven.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["fault", "fostered", "by", "concealment"],
    words: ["a", "fault", "is", "fostered", "by", "concealment"],
    id: "A fault is fostered by concealment.",
    author: "Virgil",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["more", "one", "judges", "less"],
    words: ["the", "more", "one", "judges", "less", "loves"],
    id: "The more one judges, the less one loves.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["power", "speed", "hands", "feet"],
    words: ["power", "and", "speed", "be", "hands", "feet"],
    id: "Power and speed be hands and feet.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["their", "rage", "supplies", "weapons"],
    words: ["their", "rage", "supplies", "them", "with", "weapons"],
    id: "Their rage supplies them with weapons.",
    author: "Virgil",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["read", "my", "lips", "taxes"],
    words: ["read", "my", "lips", "no", "new", "taxes"],
    id: "Read my lips: no new taxes.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["was", "best", "times", "worst"],
    words: ["it", "was", "the", "best", "of", "times", "worst"],
    id: "It was the best of times, it was the worst of times.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "flies", "never", "recalled"],
    words: ["time", "flies", "never", "to", "be", "recalled"],
    id: "Time flies never to be recalled.",
    author: "Virgil",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "scriptural", "basis", "segregation"],
    words: ["there", "is", "no", "scriptural", "basis", "for", "segregation"],
    id: "There is no scriptural basis for segregation.",
    author: "Billy Graham",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["delights", "self-discovery", "always", "available"],
    words: ["the", "delights", "of", "self-discovery", "are", "always", "available"],
    id: "The delights of self-discovery are always available.",
    author: "Gail Sheehy",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["speech", "small", "change", "silence"],
    words: ["speech", "is", "the", "small", "change", "of", "silence"],
    id: "Speech is the small change of silence.",
    author: "George Meredith",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "hurt", "by", "himself"],
    words: ["no", "man", "is", "hurt", "but", "by", "himself"],
    id: "No man is hurt but by himself.",
    author: "Diogenes",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "believe", "being", "innovator"],
    words: ["i", "believe", "in", "being", "an", "innovator"],
    id: "I believe in being an innovator.",
    author: "Walt Disney",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["takes", "wise", "man", "discover"],
    words: ["it", "takes", "a", "wise", "man", "to", "discover"],
    id: "It takes a wise man to discover a wise man.",
    author: "Diogenes",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["humor", "most", "engaging", "cowardice"],
    words: ["humor", "is", "the", "most", "engaging", "cowardice"],
    id: "Humor is the most engaging cowardice.",
    author: "Robert Frost",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["problems", "stop", "signs", "guidelines"],
    words: ["problems", "are", "not", "stop", "signs", "they", "guidelines"],
    id: "Problems are not stop signs, they are guidelines.",
    author: "Robert H. Schuller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["exit", "entry", "somewhere", "else"],
    words: ["every", "exit", "is", "an", "entry", "somewhere", "else"],
    id: "Every exit is an entry somewhere else.",
    author: "Tom Stoppard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "make", "injustice", "mercy"],
    words: ["nothing", "can", "make", "injustice", "just", "but", "mercy"],
    id: "Nothing can make injustice just but mercy.",
    author: "Robert Frost",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["writing", "better", "cleverness", "patience"],
    words: ["writing", "is", "good", "thinking", "better", "cleverness", "patience"],
    id: "Writing is good, thinking is better. Cleverness is good, patience is better.",
    author: "Hermann Hesse",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wherever", "-", "all", "there"],
    words: ["wherever", "you", "are", "-", "be", "all", "there"],
    id: "Wherever you are - be all there.",
    author: "Jim Elliot",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["zebra", "change", "its", "spots"],
    words: ["a", "zebra", "does", "not", "change", "its", "spots"],
    id: "A zebra does not change its spots.",
    author: "Al Gore",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["ok", "what's", "speed", "dark"],
    words: ["ok", "so", "what's", "the", "speed", "of", "dark"],
    id: "OK, so what's the speed of dark?",
    author: "Steven Wright",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "sing", "because", "i'm"],
    words: ["i", "don't", "sing", "because", "i'm", "happy"],
    id: "I don't sing because I'm happy; I'm happy because I sing.",
    author: "William James",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["wise", "lead", "quiet", "lives"],
    words: ["the", "good", "and", "wise", "lead", "quiet", "lives"],
    id: "The good and the wise lead quiet lives.",
    author: "Euripides",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["calumny", "only", "noise", "madmen"],
    words: ["calumny", "is", "only", "the", "noise", "of", "madmen"],
    id: "Calumny is only the noise of madmen.",
    author: "Diogenes",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "i'm", "bad", "better"],
    words: ["when", "i'm", "good", "very", "but", "bad", "better"],
    id: "When I'm good, I'm very good. But when I'm bad I'm better.",
    author: "Mae West",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["dream", "courtship", "wedlock", "wake"],
    words: ["they", "dream", "in", "courtship", "but", "wedlock", "wake"],
    id: "They dream in courtship, but in wedlock wake.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["whatever", "satisfies", "soul", "truth"],
    words: ["whatever", "satisfies", "the", "soul", "is", "truth"],
    id: "Whatever satisfies the soul is truth.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["forge", "chains", "wear", "life"],
    words: ["we", "forge", "the", "chains", "wear", "in", "life"],
    id: "We forge the chains we wear in life.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["because", "split", "second", "gone"],
    words: ["because", "in", "a", "split", "second", "it's", "gone"],
    id: "Because in a split second, it's gone.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["brain", "feathers", "heart", "lead"],
    words: ["a", "brain", "of", "feathers", "and", "heart", "lead"],
    id: "A brain of feathers, and a heart of lead.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "all", "things", "sweet"],
    words: ["change", "in", "all", "things", "is", "sweet"],
    id: "Change in all things is sweet.",
    author: "Aristotle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "prevents", "happiness", "memory"],
    words: ["nothing", "prevents", "happiness", "like", "the", "memory", "of"],
    id: "Nothing prevents happiness like the memory of happiness.",
    author: "Andre Gide",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["pride", "never-failing", "vice", "fools"],
    words: ["is", "pride", "the", "never-failing", "vice", "of", "fools"],
    id: "Is pride, the never-failing vice of fools.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wit", "lowest", "form", "humor"],
    words: ["wit", "is", "the", "lowest", "form", "of", "humor"],
    id: "Wit is the lowest form of humor.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["everything", "more", "difficult", "meritorious"],
    words: ["not", "everything", "that", "is", "more", "difficult", "meritorious"],
    id: "Not everything that is more difficult is more meritorious.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "geniuses", "shortest", "biographies"],
    words: ["great", "geniuses", "have", "the", "shortest", "biographies"],
    id: "Great geniuses have the shortest biographies.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["asserted", "without", "evidence", "dismissed"],
    words: ["what", "can", "be", "asserted", "without", "evidence", "dismissed"],
    id: "What can be asserted without evidence can be dismissed without evidence.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["too", "much", "feel", "little"],
    words: ["we", "think", "too", "much", "and", "feel", "little"],
    id: "We think too much and feel too little.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["nurture", "education", "implant", "constitutions"],
    words: ["for", "good", "nurture", "and", "education", "implant", "constitutions"],
    id: "For good nurture and education implant good constitutions.",
    author: "Plato",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "always", "safety", "valor"],
    words: ["there", "is", "always", "safety", "in", "valor"],
    id: "There is always safety in valor.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["well-ordered", "self-love", "right", "natural"],
    words: ["well-ordered", "self-love", "is", "right", "and", "natural"],
    id: "Well-ordered self-love is right and natural.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["usually", "praise", "only", "praised"],
    words: ["usually", "we", "praise", "only", "to", "be", "praised"],
    id: "Usually we praise only to be praised.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "way", "round", "through"],
    words: ["the", "only", "way", "round", "is", "through"],
    id: "The only way round is through.",
    author: "Robert Frost",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["burned", "book", "enlightens", "world"],
    words: ["every", "burned", "book", "enlightens", "the", "world"],
    id: "Every burned book enlightens the world.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "owe", "my", "solitude"],
    words: ["i", "owe", "my", "solitude", "to", "other", "people"],
    id: "I owe my solitude to other people.",
    author: "Alan Watts",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["giving", "up", "cannot", "option"],
    words: ["giving", "up", "cannot", "be", "an", "option"],
    id: "Giving up cannot be an option.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["cynicism", "humor", "ill", "health"],
    words: ["cynicism", "is", "humor", "in", "ill", "health"],
    id: "Cynicism is humor in ill health.",
    author: "H. G. Wells",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["healthy", "live", "their", "world"],
    words: ["healthy", "people", "live", "with", "their", "world"],
    id: "Healthy people live with their world.",
    author: "Anne Wilson Schaef",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["heaven", "means", "one", "god"],
    words: ["heaven", "means", "to", "be", "one", "with", "god"],
    id: "Heaven means to be one with God.",
    author: "Confucius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "intelligent", "because", "nothing"],
    words: ["i", "know", "that", "am", "intelligent", "because", "nothing"],
    id: "I know that I am intelligent, because I know that I know nothing.",
    author: "Socrates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "enemies", "devour", "each"],
    words: ["let", "my", "enemies", "devour", "each", "other"],
    id: "Let my enemies devour each other.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "anger", "rises", "consequences"],
    words: ["when", "anger", "rises", "think", "of", "the", "consequences"],
    id: "When anger rises, think of the consequences.",
    author: "Confucius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["ignorance", "always", "afraid", "change"],
    words: ["ignorance", "is", "always", "afraid", "of", "change"],
    id: "Ignorance is always afraid of change.",
    author: "Jawaharlal Nehru",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "being", "alone", "intoxicating"],
    words: ["freedom", "of", "being", "alone", "is", "intoxicating"],
    id: "Freedom of being alone is intoxicating.",
    author: "Kangana Ranaut",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "man", "courage", "majority"],
    words: ["one", "man", "with", "courage", "is", "a", "majority"],
    id: "One man with courage is a majority.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["doors", "wisdom", "never", "shut"],
    words: ["the", "doors", "of", "wisdom", "are", "never", "shut"],
    id: "The doors of wisdom are never shut.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["health", "education", "always", "issues"],
    words: ["health", "and", "education", "are", "always", "issues"],
    id: "Health and education are always issues.",
    author: "Helen Clark",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "trying", "change", "world"],
    words: ["i'm", "trying", "to", "change", "the", "world"],
    id: "I'm trying to change the world.",
    author: "Kid Cudi",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["thoughtful", "soul", "solitude", "retires"],
    words: ["the", "thoughtful", "soul", "to", "solitude", "retires"],
    id: "The thoughtful soul to solitude retires.",
    author: "Omar Khayyam",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "romantic", "i", "movies"],
    words: ["i'm", "a", "romantic", "i", "like", "movies"],
    id: "I'm a romantic. I like romantic movies.",
    author: "Chris Evans",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["folk", "their", "luck", "buttered"],
    words: ["some", "folk", "want", "their", "luck", "buttered"],
    id: "Some folk want their luck buttered.",
    author: "Thomas Hardy",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["won't", "counseled", "can't", "helped"],
    words: ["he", "that", "won't", "be", "counseled", "can't", "helped"],
    id: "He that won't be counseled can't be helped.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["ignorance", "bold", "knowledge", "reserved"],
    words: ["ignorance", "is", "bold", "and", "knowledge", "reserved"],
    id: "Ignorance is bold and knowledge reserved.",
    author: "Thucydides",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["mind", "always", "patsy", "heart"],
    words: ["the", "mind", "is", "always", "patsy", "of", "heart"],
    id: "The mind is always the patsy of the heart.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["at", "war", "against", "islam"],
    words: ["we", "are", "not", "at", "war", "against", "islam"],
    id: "We are not at war against Islam.",
    author: "Barack Obama",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["may", "delay", "time", "will"],
    words: ["you", "may", "delay", "but", "time", "will", "not"],
    id: "You may delay, but time will not.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["energy", "mind", "essence", "life"],
    words: ["the", "energy", "of", "mind", "is", "essence", "life"],
    id: "The energy of the mind is the essence of life.",
    author: "Aristotle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["family", "our", "greatest", "luxury"],
    words: ["the", "family", "is", "our", "greatest", "luxury"],
    id: "The family is our greatest luxury.",
    author: "Granit Xhaka",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "wild", "life", "freedom"],
    words: ["i", "need", "this", "wild", "life", "freedom"],
    id: "I need this wild life, this freedom.",
    author: "Zane Grey",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "excellent", "memory", "most"],
    words: ["i", "have", "an", "excellent", "memory", "a", "most"],
    id: "I have an excellent memory, a most excellent memory.",
    author: "Bill Gates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "certain", "freedom's", "departure"],
    words: ["the", "only", "certain", "freedom's", "in", "departure"],
    id: "The only certain freedom's in departure.",
    author: "Robert Frost",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["pictures", "must", "too", "picturesque"],
    words: ["pictures", "must", "not", "be", "too", "picturesque"],
    id: "Pictures must not be too picturesque.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["adventure", "outside", "man", "within"],
    words: ["adventure", "is", "not", "outside", "man", "it", "within"],
    id: "Adventure is not outside man; it is within.",
    author: "George Eliot",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "achieved", "inner", "freedom"],
    words: ["i", "have", "achieved", "an", "inner", "freedom"],
    id: "I have achieved an inner freedom.",
    author: "Dmitri Mendeleev",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "making", "music", "bruh"],
    words: ["i", "just", "love", "making", "music", "bruh"],
    id: "I just love making music, bruh.",
    author: "Rod Wave",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["self-discipline", "most", "anything", "possible"],
    words: ["with", "self-discipline", "most", "anything", "is", "possible"],
    id: "With self-discipline most anything is possible.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["fear", "change", "-", "embrace"],
    words: ["don't", "fear", "change", "-", "embrace", "it"],
    id: "Don't fear change - embrace it.",
    author: "Anthony J. D'Angelo",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["understand", "forgive", "even", "oneself"],
    words: ["to", "understand", "is", "forgive", "even", "oneself"],
    id: "To understand is to forgive, even oneself.",
    author: "Alexander Chase",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "curvy", "one", "family"],
    words: ["i'm", "the", "curvy", "one", "of", "family"],
    id: "I'm the curvy one of the family.",
    author: "Elizabeth Olsen",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["anything", "at", "contributes", "happiness"],
    words: ["anything", "you're", "good", "at", "contributes", "to", "happiness"],
    id: "Anything you're good at contributes to happiness.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["nobody", "bring", "peace", "yourself"],
    words: ["nobody", "can", "bring", "you", "peace", "but", "yourself"],
    id: "Nobody can bring you peace but yourself.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "cure", "contempt", "counter-contempt"],
    words: ["the", "only", "cure", "for", "contempt", "is", "counter-contempt"],
    id: "The only cure for contempt is counter-contempt.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["energy", "innovation", "nationalistic", "game"],
    words: ["energy", "innovation", "is", "not", "a", "nationalistic", "game"],
    id: "Energy innovation is not a nationalistic game.",
    author: "Bill Gates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "still", "healthy", "as"],
    words: ["i'm", "still", "healthy", "as", "can", "be"],
    id: "I'm still healthy as can be.",
    author: "Darrell Royal",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["without", "courage", "wisdom", "bears"],
    words: ["without", "courage", "wisdom", "bears", "no", "fruit"],
    id: "Without courage, wisdom bears no fruit.",
    author: "Baltasar Gracian",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["self-suggestion", "makes", "master", "yourself"],
    words: ["self-suggestion", "makes", "you", "master", "of", "yourself"],
    id: "Self-suggestion makes you master of yourself.",
    author: "W. Clement Stone",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["discipline", "wisdom", "vice", "versa"],
    words: ["discipline", "is", "wisdom", "and", "vice", "versa"],
    id: "Discipline is wisdom and vice versa.",
    author: "M. Scott Peck",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["price", "pay", "value", "get"],
    words: ["price", "is", "what", "you", "pay", "value", "get"],
    id: "Price is what you pay. Value is what you get.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["giving", "opens", "way", "receiving"],
    words: ["giving", "opens", "the", "way", "for", "receiving"],
    id: "Giving opens the way for receiving.",
    author: "Florence Scovel Shinn",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["meditation", "soul's", "perspective", "glass"],
    words: ["meditation", "is", "the", "soul's", "perspective", "glass"],
    id: "Meditation is the soul's perspective glass.",
    author: "Owen Feltham",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["courage", "always", "surest", "wisdom"],
    words: ["courage", "is", "always", "the", "surest", "wisdom"],
    id: "Courage is always the surest wisdom.",
    author: "Wilfred Grenfell",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["readership", "still", "growing", "india"],
    words: ["newspaper", "readership", "is", "still", "growing", "in", "india"],
    id: "Newspaper readership is still growing in India.",
    author: "Bill Gates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "hate", "nasty", "ugly"],
    words: ["what", "i", "hate", "is", "nasty", "ugly", "people"],
    id: "What I hate is nasty, ugly people.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "by", "nature", "political"],
    words: ["man", "is", "by", "nature", "a", "political", "animal"],
    id: "Man is by nature a political animal.",
    author: "Aristotle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["friendship", "wildly", "underrated", "medication"],
    words: ["friendship", "is", "a", "wildly", "underrated", "medication"],
    id: "Friendship is a wildly underrated medication.",
    author: "Anna Deavere Smith",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["strength", "wisdom", "opposing", "values"],
    words: ["strength", "and", "wisdom", "are", "not", "opposing", "values"],
    id: "Strength and wisdom are not opposing values.",
    author: "William J. Clinton",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "herald", "great", "king"],
    words: ["i", "am", "the", "herald", "of", "great", "king"],
    id: "I am the herald of the Great King.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["heeded", "wisdom", "offering", "guidance"],
    words: ["we", "have", "heeded", "no", "wisdom", "offering", "guidance"],
    id: "We have heeded no wisdom offering guidance.",
    author: "Dora Russell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "lack", "energy", "wisdom"],
    words: ["what", "i", "lack", "in", "energy", "have", "wisdom"],
    id: "What I lack in energy, I have in wisdom.",
    author: "Marcia Cross",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["chinese", "themselves", "really", "change"],
    words: ["chinese", "people", "themselves", "they", "really", "want", "change"],
    id: "Chinese people themselves, they really want change.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "god", "everything", "permitted"],
    words: ["if", "there", "is", "no", "god", "everything", "permitted"],
    id: "If there is no God, everything is permitted.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "important", "man's", "life"],
    words: ["wisdom", "is", "important", "in", "every", "man's", "life"],
    id: "Wisdom is important in every man's life.",
    author: "Rza",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["fear", "present", "wisdom", "cannot"],
    words: ["where", "fear", "is", "present", "wisdom", "cannot", "be"],
    id: "Where fear is present, wisdom cannot be.",
    author: "Lactantius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["live", "around", "here", "often"],
    words: ["so", "do", "you", "live", "around", "here", "often"],
    id: "So, do you live around here often?",
    author: "Steven Wright",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["prosperity", "mortals", "never", "enough"],
    words: ["of", "prosperity", "mortals", "can", "never", "have", "enough"],
    id: "Of prosperity mortals can never have enough.",
    author: "Aeschylus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["full", "wisdom", "ordinations", "fate"],
    words: ["full", "of", "wisdom", "are", "the", "ordinations", "fate"],
    id: "Full of wisdom are the ordinations of fate.",
    author: "Friedrich Schiller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["takes", "three", "make", "child"],
    words: ["it", "takes", "three", "to", "make", "a", "child"],
    id: "It takes three to make a child.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "worked", "great", "directors"],
    words: ["i", "have", "worked", "with", "some", "great", "directors"],
    id: "I have worked with some great directors.",
    author: "Dabney Coleman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["picture", "worth", "thousand", "words"],
    words: ["a", "picture", "is", "worth", "thousand", "words"],
    id: "A picture is worth a thousand words.",
    author: "Unknown",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["kind", "whenever", "possible", "always"],
    words: ["be", "kind", "whenever", "possible", "it", "is", "always"],
    id: "Be kind whenever possible. It is always possible.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["christians", "limited", "any", "church"],
    words: ["christians", "are", "not", "limited", "to", "any", "church"],
    id: "Christians are not limited to any church.",
    author: "Billy Graham",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["memory", "mother", "all", "wisdom"],
    words: ["memory", "is", "the", "mother", "of", "all", "wisdom"],
    id: "Memory is the mother of all wisdom.",
    author: "Aeschylus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["fear", "thought", "admitted", "inferiority"],
    words: ["fear", "is", "the", "thought", "of", "admitted", "inferiority"],
    id: "Fear is the thought of admitted inferiority.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["confidence", "momentum", "huge", "sport"],
    words: ["confidence", "and", "momentum", "are", "huge", "in", "sport"],
    id: "Confidence and momentum are huge in sport.",
    author: "Gareth Barry",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["corruption", "another", "form", "tyranny"],
    words: ["corruption", "is", "just", "another", "form", "of", "tyranny"],
    id: "Corruption is just another form of tyranny.",
    author: "Joe Biden",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["best", "road", "progress", "freedom's"],
    words: ["the", "best", "road", "to", "progress", "is", "freedom's"],
    id: "The best road to progress is freedom's road.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["aim", "above", "mark", "hit"],
    words: ["we", "aim", "above", "the", "mark", "to", "hit"],
    id: "We aim above the mark to hit the mark.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wish", "were", "easier", "better"],
    words: ["don't", "wish", "it", "were", "easier", "you", "better"],
    id: "Don't wish it were easier, wish you were better.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["will", "man", "his", "happiness"],
    words: ["the", "will", "of", "man", "is", "his", "happiness"],
    id: "The will of man is his happiness.",
    author: "Friedrich Schiller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wretched", "those", "vindictive", "spiteful"],
    words: ["wretched", "are", "those", "who", "vindictive", "and", "spiteful"],
    id: "Wretched are those who are vindictive and spiteful.",
    author: "Pope Francis",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["tennis", "game", "family", "forever"],
    words: ["tennis", "just", "a", "game", "family", "is", "forever"],
    id: "Tennis just a game, family is forever.",
    author: "Serena Williams",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["either", "run", "or", "runs"],
    words: ["either", "you", "run", "the", "day", "or", "runs"],
    id: "Either you run the day or the day runs you.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["there's", "electrical", "thing", "movies"],
    words: ["there's", "an", "electrical", "thing", "about", "movies"],
    id: "There's an electrical thing about movies.",
    author: "Oliver Stone",
    grammar: null,
    word_count: 6,
    all_intersection_count: 2,
    new_words_count: 4,
  },
  {
    row_new_words: ["practice", "time", "get", "chance"],
    words: ["practice", "every", "time", "you", "get", "a", "chance"],
    id: "Practice every time you get a chance.",
    author: "Bill Monroe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["why", "all", "push", "around"],
    words: ["why", "do", "you", "all", "push", "us", "around"],
    id: "Why do you all push us around?",
    author: "Rosa Parks",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "only", "likely", "inevitable"],
    words: ["change", "is", "not", "only", "likely", "it's", "inevitable"],
    id: "Change is not only likely, it's inevitable.",
    author: "Barbara Sher",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "weather", "discourse", "fools"],
    words: ["change", "of", "weather", "is", "the", "discourse", "fools"],
    id: "Change of weather is the discourse of fools.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["tears", "writer", "reader", "surprise"],
    words: ["no", "tears", "in", "the", "writer", "reader", "surprise"],
    id: "No tears in the writer, no tears in the reader. No surprise in the writer, no surprise in the reader.",
    author: "Robert Frost",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "never", "thought", "would"],
    words: ["no", "i", "never", "thought", "would", "like", "cats"],
    id: "No, I never thought I would like cats.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["worst", "cynicism", "belief", "luck"],
    words: ["the", "worst", "cynicism", "a", "belief", "in", "luck"],
    id: "The worst cynicism: a belief in luck.",
    author: "Joyce Carol Oates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["gut", "health", "key", "overall"],
    words: ["gut", "health", "is", "the", "key", "to", "overall"],
    id: "Gut health is the key to overall health.",
    author: "Kris Carr",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "shouting", "true", "knowledge"],
    words: ["where", "there", "is", "shouting", "no", "true", "knowledge"],
    id: "Where there is shouting, there is no true knowledge.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "entrepreneurial", "take", "risks"],
    words: ["i", "like", "entrepreneurial", "people", "who", "take", "risks"],
    id: "I like entrepreneurial people; I like people who take risks.",
    author: "Billie Jean King",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["noblest", "pleasure", "joy", "understanding"],
    words: ["the", "noblest", "pleasure", "is", "joy", "of", "understanding"],
    id: "The noblest pleasure is the joy of understanding.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["talks", "more", "sooner", "exhausted"],
    words: ["he", "who", "talks", "more", "is", "sooner", "exhausted"],
    id: "He who talks more is sooner exhausted.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["poetry", "grief", "politics", "grievance"],
    words: ["poetry", "is", "about", "the", "grief", "politics", "grievance"],
    id: "Poetry is about the grief. Politics is about the grievance.",
    author: "Robert Frost",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["poetry", "gets", "lost", "translation"],
    words: ["poetry", "is", "what", "gets", "lost", "in", "translation"],
    id: "Poetry is what gets lost in translation.",
    author: "Robert Frost",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["vanity", "healthiest", "thing", "life"],
    words: ["vanity", "is", "the", "healthiest", "thing", "in", "life"],
    id: "Vanity is the healthiest thing in life.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["step", "by", "thing", "done"],
    words: ["step", "by", "and", "the", "thing", "is", "done"],
    id: "Step by step and the thing is done.",
    author: "Charles Atlas",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "positive", "things", "happen"],
    words: ["when", "you", "think", "positive", "good", "things", "happen"],
    id: "When you think positive, good things happen.",
    author: "Matt Kemp",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["enjoy", "freedom", "control", "ourselves"],
    words: ["to", "enjoy", "freedom", "we", "have", "control", "ourselves"],
    id: "To enjoy freedom we have to control ourselves.",
    author: "Virginia Woolf",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "working-class", "person", "class"],
    words: ["i'm", "a", "working-class", "person", "working", "with", "class"],
    id: "I'm a working-class person, working with class.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["highest", "result", "education", "tolerance"],
    words: ["the", "highest", "result", "of", "education", "is", "tolerance"],
    id: "The highest result of education is tolerance.",
    author: "Helen Keller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["obtains", "little", "scatters", "much"],
    words: ["he", "who", "obtains", "has", "little", "scatters", "much"],
    id: "He who obtains has little. He who scatters has much.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["words", "truth", "always", "paradoxical"],
    words: ["the", "words", "of", "truth", "are", "always", "paradoxical"],
    id: "The words of truth are always paradoxical.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["vaccines", "tugboats", "preventive", "health"],
    words: ["vaccines", "are", "the", "tugboats", "of", "preventive", "health"],
    id: "Vaccines are the tugboats of preventive health.",
    author: "William Foege",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["silence", "source", "great", "strength"],
    words: ["silence", "is", "a", "source", "of", "great", "strength"],
    id: "Silence is a source of great strength.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "clean", "means", "health"],
    words: ["water", "is", "life", "and", "clean", "means", "health"],
    id: "Water is life, and clean water means health.",
    author: "Audrey Hepburn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["person", "hears", "only", "understand"],
    words: ["a", "person", "hears", "only", "what", "they", "understand"],
    id: "A person hears only what they understand.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["chance", "name", "our", "ignorance"],
    words: ["chance", "is", "a", "name", "for", "our", "ignorance"],
    id: "Chance is a name for our ignorance.",
    author: "Leslie Stephen",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "supreme", "part", "happiness"],
    words: ["wisdom", "is", "the", "supreme", "part", "of", "happiness"],
    id: "Wisdom is the supreme part of happiness.",
    author: "Sophocles",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["space", "ails", "moderns", "sick"],
    words: ["space", "ails", "us", "moderns", "we", "are", "sick", "with"],
    id: "Space ails us moderns: we are sick with space.",
    author: "Robert Frost",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["correct", "answer", "affectionate", "kiss"],
    words: ["a", "correct", "answer", "is", "like", "an", "affectionate", "kiss"],
    id: "A correct answer is like an affectionate kiss.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["secret", "happiness", "freedom", "courage"],
    words: ["the", "secret", "to", "happiness", "is", "freedom", "and", "courage"],
    id: "The secret to happiness is freedom... And the secret to freedom is courage.",
    author: "Thucydides",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["you've", "been", "through", "somebody"],
    words: ["love", "is", "what", "you've", "been", "through", "with", "somebody"],
    id: "Love is what you've been through with somebody.",
    author: "James Thurber",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["playing", "golf", "foreign", "language"],
    words: ["playing", "golf", "is", "like", "learning", "a", "foreign", "language"],
    id: "Playing golf is like learning a foreign language.",
    author: "Henry Longhurst",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["luxury", "must", "comfortable", "otherwise"],
    words: ["luxury", "must", "be", "comfortable", "otherwise", "it", "is", "not"],
    id: "Luxury must be comfortable, otherwise it is not luxury.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["college", "isn't", "place", "ideas"],
    words: ["college", "isn't", "the", "place", "to", "go", "for", "ideas"],
    id: "College isn't the place to go for ideas.",
    author: "Helen Keller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["god", "dropped", "acid", "would"],
    words: ["if", "god", "dropped", "acid", "would", "he", "see", "people"],
    id: "If God dropped acid, would he see people?",
    author: "Steven Wright",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "too", "short", "hard"],
    words: ["life", "is", "too", "short", "to", "work", "so", "hard"],
    id: "Life is too short to work so hard.",
    author: "Vivien Leigh",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "change", "babies", "diapers"],
    words: ["no", "one", "likes", "change", "but", "babies", "in", "diapers"],
    id: "No one likes change but babies in diapers.",
    author: "Barbara Johnson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["being", "matter", "personal", "taste"],
    words: ["being", "happy", "is", "a", "matter", "of", "personal", "taste"],
    id: "Being happy is a matter of personal taste.",
    author: "Pierre Teilhard de Chardin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "grow", "old", "something"],
    words: ["i", "grow", "old", "learning", "something", "new", "every", "day"],
    id: "I grow old learning something new every day.",
    author: "Solon",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "enough", "another's", "master"],
    words: ["no", "man", "is", "good", "enough", "to", "be", "another's", "master"],
    id: "No man is good enough to be another's master.",
    author: "William Morris",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["process", "continues", "until", "die"],
    words: ["the", "learning", "process", "continues", "until", "day", "you", "die"],
    id: "The learning process continues until the day you die.",
    author: "Kirk Douglas",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["dictators", "free", "themselves", "enslave"],
    words: ["dictators", "free", "themselves", "but", "they", "enslave", "the", "people"],
    id: "Dictators free themselves, but they enslave the people.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["vatican", "dagger", "heart", "italy"],
    words: ["the", "vatican", "is", "a", "dagger", "in", "heart", "of", "italy"],
    id: "The Vatican is a dagger in the heart of Italy.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "as", "seems", "beforehand"],
    words: ["nothing", "is", "so", "good", "as", "it", "seems", "beforehand"],
    id: "Nothing is so good as it seems beforehand.",
    author: "George Eliot",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "failure", "enjoying", "life"],
    words: ["no", "man", "is", "a", "failure", "who", "enjoying", "life"],
    id: "No man is a failure who is enjoying life.",
    author: "William Feather",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["better", "look", "than", "feel"],
    words: ["it", "is", "better", "to", "look", "good", "than", "feel"],
    id: "It is better to look good than to feel good.",
    author: "Fernando Lamas",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "alone", "science", "sciences"],
    words: ["wisdom", "alone", "is", "the", "science", "of", "other", "sciences"],
    id: "Wisdom alone is the science of other sciences.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["twice", "armed", "fight", "faith"],
    words: ["we", "are", "twice", "armed", "if", "fight", "with", "faith"],
    id: "We are twice armed if we fight with faith.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["those", "move", "easiest", "learn'd"],
    words: ["those", "move", "easiest", "who", "have", "learn'd", "to", "dance"],
    id: "Those move easiest who have learn'd to dance.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["willingness", "great", "difficulties", "cannot"],
    words: ["where", "the", "willingness", "is", "great", "difficulties", "cannot", "be"],
    id: "Where the willingness is great, the difficulties cannot be great.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "mind", "talking", "itself"],
    words: ["when", "the", "mind", "is", "thinking", "it", "talking", "to", "itself"],
    id: "When the mind is thinking it is talking to itself.",
    author: "Plato",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["why", "man", "when", "success"],
    words: ["why", "be", "a", "man", "when", "you", "can", "success"],
    id: "Why be a man when you can be a success?",
    author: "Bertolt Brecht",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "my", "possessions", "time"],
    words: ["all", "my", "possessions", "for", "a", "moment", "of", "time"],
    id: "All my possessions for a moment of time.",
    author: "Elizabeth I",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["cunning", "low", "mimic", "wisdom"],
    words: ["cunning", "is", "but", "the", "low", "mimic", "of", "wisdom"],
    id: "Cunning... is but the low mimic of wisdom.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["underestimating", "force", "angry", "kids"],
    words: ["people", "are", "underestimating", "the", "force", "of", "angry", "kids"],
    id: "People are underestimating the force of angry kids.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'd", "i'm", "great", "teacher"],
    words: ["i'd", "like", "to", "think", "i'm", "a", "great", "teacher"],
    id: "I'd like to think I'm a great teacher.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["accumulation", "knowledge", "powerful", "thing"],
    words: ["the", "accumulation", "of", "knowledge", "is", "a", "powerful", "thing"],
    id: "The accumulation of knowledge is a powerful thing.",
    author: "Kirk Cousins",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["youth", "beauty", "wisdom", "rare"],
    words: ["in", "youth", "and", "beauty", "wisdom", "is", "but", "rare"],
    id: "In youth and beauty, wisdom is but rare!",
    author: "Homer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["reputation", "more", "valuable", "than"],
    words: ["a", "good", "reputation", "is", "more", "valuable", "than", "money"],
    id: "A good reputation is more valuable than money.",
    author: "Publilius Syrus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["war", "there", "prize", "runner-up"],
    words: ["in", "war", "there", "is", "no", "prize", "for", "runner-up"],
    id: "In war there is no prize for runner-up.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["sought", "given", "unsought", "better"],
    words: ["love", "sought", "is", "good", "but", "given", "unsought", "better"],
    id: "Love sought is good, but given unsought, is better.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "time", "incredibly", "lucky"],
    words: ["to", "work", "all", "the", "time", "is", "be", "incredibly", "lucky"],
    id: "To work all the time is to be incredibly lucky.",
    author: "Harvey Fierstein",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["professor", "must", "theory", "as"],
    words: ["a", "professor", "must", "have", "theory", "as", "dog", "fleas"],
    id: "A professor must have a theory as a dog must have fleas.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["logic", "beginning", "wisdom", "end"],
    words: ["logic", "is", "the", "beginning", "of", "wisdom", "not", "end"],
    id: "Logic is the beginning of wisdom, not the end.",
    author: "Leonard Nimoy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "harvest", "quiet", "eye"],
    words: ["happiness", "is", "the", "harvest", "of", "a", "quiet", "eye"],
    id: "Happiness is the harvest of a quiet eye.",
    author: "Austin O'Malley",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["war", "there", "substitute", "victory"],
    words: ["in", "war", "there", "is", "no", "substitute", "for", "victory"],
    id: "In war there is no substitute for victory.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["driving", "force", "all", "nature"],
    words: ["water", "is", "the", "driving", "force", "of", "all", "nature"],
    id: "Water is the driving force of all nature.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["white", "hair", "engenders", "wisdom"],
    words: ["it", "is", "not", "white", "hair", "that", "engenders", "wisdom"],
    id: "It is not white hair that engenders wisdom.",
    author: "Menander",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["take", "much", "make", "angry"],
    words: ["it", "doesn't", "take", "much", "to", "make", "me", "angry"],
    id: "It doesn't take much to make me angry.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "never", "made", "bigot"],
    words: ["wisdom", "has", "never", "made", "a", "bigot", "but", "learning"],
    id: "Wisdom has never made a bigot, but learning has.",
    author: "Josh Billings",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "kids", "tough", "audience"],
    words: ["i", "love", "kids", "but", "they", "are", "a", "tough", "audience"],
    id: "I love kids, but they are a tough audience.",
    author: "Robin Williams",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["deferring", "anger", "best", "antidote"],
    words: ["the", "deferring", "of", "anger", "is", "best", "antidote", "to"],
    id: "The deferring of anger is the best antidote to anger.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["bulk", "universities", "teaching", "kids"],
    words: ["the", "bulk", "of", "universities", "are", "about", "teaching", "kids"],
    id: "The bulk of the universities are about teaching kids.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["wanting", "feel", "synonymous", "god"],
    words: ["wanting", "to", "feel", "good", "is", "synonymous", "with", "god"],
    id: "Wanting to feel good is synonymous with wanting to feel God.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["affront", "treat", "falsehood", "complaisance"],
    words: ["it", "is", "an", "affront", "to", "treat", "falsehood", "with", "complaisance"],
    id: "It is an affront to treat falsehood with complaisance.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "when", "stop", "die"],
    words: ["life", "is", "about", "learning", "when", "you", "stop", "die"],
    id: "Life is about learning; when you stop learning, you die.",
    author: "Tom Clancy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["something", "enough", "stop", "doing"],
    words: ["if", "something", "is", "not", "good", "enough", "stop", "doing", "it"],
    id: "If something is not good enough, stop doing it.",
    author: "Jonathan Ive",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["conquer", "fear", "beginning", "wisdom"],
    words: ["to", "conquer", "fear", "is", "the", "beginning", "of", "wisdom"],
    id: "To conquer fear is the beginning of wisdom.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "harder", "more", "luck"],
    words: ["i", "think", "the", "harder", "you", "work", "more", "luck", "have"],
    id: "I think the harder you work, the more luck you have.",
    author: "Dave Thomas",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["little", "minds", "big", "time"],
    words: ["little", "minds", "have", "worries", "big", "no", "time", "for"],
    id: "Little minds have little worries, big minds have no time for worries.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["fortune", "brings", "boats", "steered"],
    words: ["fortune", "brings", "in", "some", "boats", "that", "are", "not", "steered"],
    id: "Fortune brings in some boats that are not steered.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["less", "talk", "more", "listened"],
    words: ["the", "less", "you", "talk", "more", "you're", "listened", "to"],
    id: "The less you talk, the more you're listened to.",
    author: "Pauline Phillips",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["scars", "price", "pay", "success"],
    words: ["scars", "are", "the", "price", "you", "pay", "for", "success"],
    id: "Scars are the price you pay for success.",
    author: "Melvin Van Peebles",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "exact", "science", "art"],
    words: ["life", "is", "not", "an", "exact", "science", "it", "art"],
    id: "Life is not an exact science, it is an art.",
    author: "Samuel Butler",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["pain", "doorway", "wisdom", "truth"],
    words: ["pain", "is", "the", "doorway", "to", "wisdom", "and", "truth"],
    id: "Pain is the doorway to wisdom and to truth.",
    author: "Keith Miller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "those", "yearn", "impossible"],
    words: ["i", "love", "those", "who", "yearn", "for", "the", "impossible"],
    id: "I love those who yearn for the impossible.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["constitution", "arrangement", "magistracies", "state"],
    words: ["a", "constitution", "is", "the", "arrangement", "of", "magistracies", "in", "state"],
    id: "A constitution is the arrangement of magistracies in a state.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "consequence", "must", "goal"],
    words: ["success", "is", "a", "consequence", "and", "must", "not", "be", "goal"],
    id: "Success is a consequence and must not be a goal.",
    author: "Gustave Flaubert",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "-", "you've", "got"],
    words: ["success", "-", "it's", "what", "you", "do", "with", "you've", "got"],
    id: "Success - it's what you do with what you've got.",
    author: "Woody Hayes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "will", "whitewash", "white"],
    words: ["there", "will", "be", "no", "whitewash", "in", "the", "white", "house"],
    id: "There will be no whitewash in the White House.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["everything", "beauty", "everyone", "sees"],
    words: ["everything", "has", "beauty", "but", "not", "everyone", "sees", "it"],
    id: "Everything has beauty, but not everyone sees it.",
    author: "Confucius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["critics", "you'll", "likely", "success"],
    words: ["if", "you", "have", "no", "critics", "you'll", "likely", "success"],
    id: "If you have no critics you'll likely have no success.",
    author: "Malcolm X",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["commander", "benevolent", "unconcerned", "fame"],
    words: ["a", "good", "commander", "is", "benevolent", "and", "unconcerned", "with", "fame"],
    id: "A good commander is benevolent and unconcerned with fame.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["living", "deeply", "fear", "death"],
    words: ["people", "living", "deeply", "have", "no", "fear", "of", "death"],
    id: "People living deeply have no fear of death.",
    author: "Anais Nin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["gentle", "all", "stern", "yourself"],
    words: ["be", "gentle", "to", "all", "and", "stern", "with", "yourself"],
    id: "Be gentle to all and stern with yourself.",
    author: "Saint Teresa of Avila",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "harm", "repeating", "thing"],
    words: ["there", "is", "no", "harm", "in", "repeating", "a", "good", "thing"],
    id: "There is no harm in repeating a good thing.",
    author: "Plato",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["death", "birth", "secret", "nature"],
    words: ["death", "like", "birth", "is", "a", "secret", "of", "nature"],
    id: "Death, like birth, is a secret of Nature.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["wash", "wear", "bridal", "gown"],
    words: ["she", "has", "a", "wash", "and", "wear", "bridal", "gown"],
    id: "She has a wash and wear bridal gown.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["giving", "up", "something", "lauda"],
    words: ["giving", "up", "is", "something", "a", "lauda", "doesn't", "do"],
    id: "Giving up is something a Lauda doesn't do.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["free", "achieved", "your", "life"],
    words: ["to", "be", "free", "is", "have", "achieved", "your", "life"],
    id: "To be free is to have achieved your life.",
    author: "Tennessee Williams",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "way", "being", "wise"],
    words: ["be", "happy", "it's", "one", "way", "of", "being", "wise"],
    id: "Be happy. It's one way of being wise.",
    author: "Sidonie Gabrielle Colette",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["cinema", "most", "fraud", "world"],
    words: ["cinema", "is", "the", "most", "beautiful", "fraud", "in", "world"],
    id: "Cinema is the most beautiful fraud in the world.",
    author: "Jean-Luc Godard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["makers", "history", "made", "by"],
    words: ["we", "are", "not", "makers", "of", "history", "made", "by"],
    id: "We are not makers of history. We are made by history.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["change", "forget", "tell", "each"],
    words: ["people", "change", "and", "forget", "to", "tell", "each", "other"],
    id: "People change and forget to tell each other.",
    author: "Lillian Hellman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["truth", "never", "damages", "cause"],
    words: ["truth", "never", "damages", "a", "cause", "that", "is", "just"],
    id: "Truth never damages a cause that is just.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "put", "ding", "universe"],
    words: ["i", "want", "to", "put", "a", "ding", "in", "the", "universe"],
    id: "I want to put a ding in the universe.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["punishment", "gift", "many", "favor"],
    words: ["a", "punishment", "to", "some", "gift", "and", "many", "favor"],
    id: "A punishment to some, to some a gift, and to many a favor.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["patience", "best", "remedy", "trouble"],
    words: ["patience", "is", "the", "best", "remedy", "for", "every", "trouble"],
    id: "Patience is the best remedy for every trouble.",
    author: "Plautus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "christian", "democrat", "all"],
    words: ["i", "am", "a", "christian", "and", "democrat", "that's", "all"],
    id: "I am a Christian and a Democrat, that's all.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["beauty", "taste", "creation", "art"],
    words: ["love", "of", "beauty", "is", "taste", "the", "creation", "art"],
    id: "Love of beauty is taste. The creation of beauty is art.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["afraid", "fail", "then", "probably"],
    words: ["if", "you're", "afraid", "to", "fail", "then", "probably", "going"],
    id: "If you're afraid to fail, then you're probably going to fail.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["yield", "calamity", "face", "boldly"],
    words: ["yield", "not", "to", "calamity", "but", "face", "her", "boldly"],
    id: "Yield not to calamity, but face her boldly.",
    author: "Virgil",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["greatest", "healing", "therapy", "friendship"],
    words: ["the", "greatest", "healing", "therapy", "is", "friendship", "and", "love"],
    id: "The greatest healing therapy is friendship and love.",
    author: "Hubert H. Humphrey",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "anyone", "normal", "family"],
    words: ["i", "don't", "think", "anyone", "has", "a", "normal", "family"],
    id: "I don't think anyone has a normal family.",
    author: "Edward Furlong",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["rank", "insider", "as", "outsider"],
    words: ["you", "can", "be", "a", "rank", "insider", "as", "well", "outsider"],
    id: "You can be a rank insider as well as a rank outsider.",
    author: "Robert Frost",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["order", "wisdom", "must", "ignorance"],
    words: ["in", "order", "to", "have", "wisdom", "we", "must", "ignorance"],
    id: "In order to have wisdom we must have ignorance.",
    author: "Theodore Dreiser",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["facts", "many", "truth", "one"],
    words: ["facts", "are", "many", "but", "the", "truth", "is", "one"],
    id: "Facts are many, but the truth is one.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["enters", "port", "full", "sail"],
    words: ["he", "enters", "the", "port", "with", "a", "full", "sail"],
    id: "He enters the port with a full sail.",
    author: "Virgil",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["benefit", "receive", "tax", "levied"],
    words: ["for", "every", "benefit", "you", "receive", "a", "tax", "is", "levied"],
    id: "For every benefit you receive a tax is levied.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "really", "listen", "rap"],
    words: ["i", "don't", "really", "listen", "to", "rap", "just", "like"],
    id: "I don't really listen to rap; I just like to rap.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "regrets", "life", "lessons"],
    words: ["there", "are", "no", "regrets", "in", "life", "just", "lessons"],
    id: "There are no regrets in life, just lessons.",
    author: "Jennifer Aniston",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["publishing", "kind", "jurassic", "age"],
    words: ["publishing", "is", "in", "a", "kind", "of", "jurassic", "age"],
    id: "Publishing is in a kind of Jurassic age.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["rarest", "quality", "epitaph", "truth"],
    words: ["the", "rarest", "quality", "in", "an", "epitaph", "is", "truth"],
    id: "The rarest quality in an epitaph is truth.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["wealth", "known", "great", "comforter"],
    words: ["wealth", "is", "well", "known", "to", "be", "a", "great", "comforter"],
    id: "Wealth is well known to be a great comforter.",
    author: "Plato",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "men's", "gains", "venturing"],
    words: ["all", "men's", "gains", "are", "the", "fruit", "of", "venturing"],
    id: "All men's gains are the fruit of venturing.",
    author: "Herodotus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["true", "selfless", "prepared", "sacrifice"],
    words: ["true", "love", "is", "selfless", "it", "prepared", "to", "sacrifice"],
    id: "True love is selfless. It is prepared to sacrifice.",
    author: "Sadhu Vaswani",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "god's", "novel", "write"],
    words: ["life", "is", "god's", "novel", "let", "him", "write", "it"],
    id: "Life is God's novel. Let him write it.",
    author: "Isaac Bashevis Singer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["rely", "on", "one", "hole"],
    words: ["a", "mouse", "does", "not", "rely", "on", "just", "one", "hole"],
    id: "A mouse does not rely on just one hole.",
    author: "Plautus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "will", "take", "answer"],
    words: ["i", "will", "not", "take", "but", "for", "an", "answer"],
    id: "I will not take 'but' for an answer.",
    author: "Langston Hughes",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["god's", "mercy", "fresh", "morning"],
    words: ["god's", "mercy", "is", "fresh", "and", "new", "every", "morning"],
    id: "God's mercy is fresh and new every morning.",
    author: "Joyce Meyer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["material", "things", "delightful", "important"],
    words: ["material", "things", "are", "delightful", "but", "they're", "not", "important"],
    id: "Material things are delightful, but they're not important.",
    author: "Richard Branson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["beginning", "most", "important", "part"],
    words: ["the", "beginning", "is", "most", "important", "part", "of", "work"],
    id: "The beginning is the most important part of the work.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["reality", "only", "rorschach", "ink-blot"],
    words: ["reality", "is", "only", "a", "rorschach", "ink-blot", "you", "know"],
    id: "Reality is only a Rorschach ink-blot, you know.",
    author: "Alan Watts",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["right", "man", "his", "due"],
    words: ["it", "is", "right", "to", "give", "every", "man", "his", "due"],
    id: "It is right to give every man his due.",
    author: "Plato",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["self-actualized", "independent", "opinion", "others"],
    words: [
      "self-actualized",
      "people",
      "are",
      "independent",
      "of",
      "the",
      "good",
      "opinion",
      "others",
    ],
    id: "Self-actualized people are independent of the good opinion of others.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["alone", "little", "together", "much"],
    words: ["alone", "we", "can", "do", "so", "little", "together", "much"],
    id: "Alone we can do so little; together we can do so much.",
    author: "Helen Keller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["injured", "friend", "bitterest", "foes"],
    words: ["an", "injured", "friend", "is", "the", "bitterest", "of", "foes"],
    id: "An injured friend is the bitterest of foes.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["god", "forgives", "those", "invent"],
    words: ["god", "forgives", "those", "who", "invent", "what", "they", "need"],
    id: "God forgives those who invent what they need.",
    author: "Lillian Hellman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["things", "change", "world", "important"],
    words: ["things", "don't", "have", "to", "change", "the", "world", "be", "important"],
    id: "Things don't have to change the world to be important.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "blessing", "prudent", "friend"],
    words: ["life", "has", "no", "blessing", "like", "a", "prudent", "friend"],
    id: "Life has no blessing like a prudent friend.",
    author: "Euripides",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["behavior", "last", "refuge", "mediocrity"],
    words: ["good", "behavior", "is", "the", "last", "refuge", "of", "mediocrity"],
    id: "Good behavior is the last refuge of mediocrity.",
    author: "Henry S. Haskins",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["struggle", "complexities", "avoid", "simplicities"],
    words: ["we", "struggle", "with", "the", "complexities", "and", "avoid", "simplicities"],
    id: "We struggle with the complexities and avoid the simplicities.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "whole", "encyclopedia", "facts"],
    words: ["a", "man", "is", "the", "whole", "encyclopedia", "of", "facts"],
    id: "A man is the whole encyclopedia of facts.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["deserve", "your", "mother's", "father's"],
    words: ["you", "don't", "have", "to", "deserve", "your", "mother's", "love", "father's"],
    id: "You don't have to deserve your mother's love. You have to deserve your father's.",
    author: "Robert Frost",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["pray", "lord", "prays", "devil"],
    words: ["he", "who", "doesn't", "pray", "to", "the", "lord", "prays", "devil"],
    id: "He who doesn't pray to the Lord prays to the devil.",
    author: "Pope Francis",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["artist", "cries", "out", "design"],
    words: ["the", "artist", "in", "me", "cries", "out", "for", "design"],
    id: "The artist in me cries out for design.",
    author: "Robert Frost",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["everybody", "success", "hate", "successful"],
    words: ["everybody", "loves", "success", "but", "they", "hate", "successful", "people"],
    id: "Everybody loves success, but they hate successful people.",
    author: "John McEnroe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "believe", "true", "endings"],
    words: ["i", "believe", "in", "true", "love", "and", "happy", "endings"],
    id: "I believe in true love, and I believe in happy endings. And I believe.",
    author: "Christie Brinkley",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["loyalty", "you'll", "get", "back"],
    words: ["you", "give", "loyalty", "you'll", "get", "it", "back", "love"],
    id: "You give loyalty, you'll get it back. You give love, you'll get it back.",
    author: "Tommy Lasorda",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["patience", "name", "game", "bollywood"],
    words: ["patience", "is", "the", "name", "of", "game", "in", "bollywood"],
    id: "Patience is the name of the game in Bollywood.",
    author: "Rhea Chakraborty",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["lasts", "longest", "never", "returned"],
    words: ["the", "love", "that", "lasts", "longest", "is", "never", "returned"],
    id: "The love that lasts longest is the love that is never returned.",
    author: "W. Somerset Maugham",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["polite", "all", "intimate", "few"],
    words: ["be", "polite", "to", "all", "but", "intimate", "with", "few"],
    id: "Be polite to all, but intimate with few.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["faith", "taking", "form", "aspiration"],
    words: ["faith", "is", "love", "taking", "the", "form", "of", "aspiration"],
    id: "Faith is love taking the form of aspiration.",
    author: "William Ellery Channing",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "pompous", "pretentious", "movies"],
    words: ["what", "i", "don't", "like", "are", "pompous", "pretentious", "movies"],
    id: "What I don't like are pompous, pretentious movies.",
    author: "Peter Jackson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["full", "things", "empty", "god"],
    words: ["to", "be", "full", "of", "things", "is", "empty", "god"],
    id: "To be full of things is to be empty of God. To be empty of things is to be full of God.",
    author: "Meister Eckhart",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["defend", "absolutely", "freedom", "speech"],
    words: ["we", "need", "to", "defend", "absolutely", "the", "freedom", "of", "speech"],
    id: "We need to defend absolutely the freedom of speech.",
    author: "Patrick Chappatte",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "treasure", "wise", "man"],
    words: ["knowledge", "is", "the", "treasure", "of", "a", "wise", "man"],
    id: "Knowledge is the treasure of a wise man.",
    author: "William Penn",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["calamity", "overcome", "by", "endurance"],
    words: ["every", "calamity", "is", "to", "be", "overcome", "by", "endurance"],
    id: "Every calamity is to be overcome by endurance.",
    author: "Virgil",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["forgiveness", "gift", "central", "faith"],
    words: ["forgiveness", "is", "a", "gift", "and", "central", "to", "faith"],
    id: "Forgiveness is a gift, and central to faith.",
    author: "Kerry Kennedy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["research", "blind", "date", "knowledge"],
    words: ["what", "is", "research", "but", "a", "blind", "date", "with", "knowledge"],
    id: "What is research but a blind date with knowledge?",
    author: "Will Harvey",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["education", "foundation", "better", "future"],
    words: ["a", "good", "education", "is", "foundation", "for", "better", "future"],
    id: "A good education is a foundation for a better future.",
    author: "Elizabeth Warren",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["men", "polite", "liberal", "education"],
    words: ["men", "of", "polite", "learning", "and", "a", "liberal", "education"],
    id: "Men of polite learning and a liberal education.",
    author: "Matthew Henry",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["can't", "regulate", "lab", "world"],
    words: ["you", "can't", "regulate", "every", "lab", "in", "the", "world"],
    id: "You can't regulate every lab in the world.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["more", "liberty", "away", "will"],
    words: ["the", "more", "liberty", "you", "give", "away", "will", "have"],
    id: "The more liberty you give away the more you will have.",
    author: "Robert Green Ingersoll",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["perception", "beauty", "moral", "test"],
    words: ["the", "perception", "of", "beauty", "is", "a", "moral", "test"],
    id: "The perception of beauty is a moral test.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["can't", "command", "our", "actions"],
    words: ["we", "can't", "command", "our", "love", "but", "can", "actions"],
    id: "We can't command our love, but we can our actions.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "way", "happiness", "-"],
    words: ["there", "is", "no", "way", "to", "happiness", "-", "the"],
    id: "There is no way to happiness - happiness is the way.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["drawing", "simply", "line", "walk"],
    words: ["a", "drawing", "is", "simply", "line", "going", "for", "walk"],
    id: "A drawing is simply a line going for a walk.",
    author: "Paul Klee",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["practice", "motivation", "discrimination", "hate"],
    words: ["let's", "practice", "motivation", "and", "love", "not", "discrimination", "hate"],
    id: "Let's practice motivation and love, not discrimination and hate.",
    author: "Zendaya",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["seek", "understand", "may", "believe"],
    words: ["seek", "not", "to", "understand", "that", "you", "may", "believe", "but"],
    id: "Seek not to understand that you may believe, but believe that you may understand.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "part", "everything", "read"],
    words: ["i", "am", "a", "part", "of", "everything", "that", "have", "read"],
    id: "I am a part of everything that I have read.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["irresistible", "desire", "irresistibly", "desired"],
    words: ["love", "is", "an", "irresistible", "desire", "to", "be", "irresistibly", "desired"],
    id: "Love is an irresistible desire to be irresistibly desired.",
    author: "Robert Frost",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["words", "also", "actions", "kind"],
    words: ["words", "are", "also", "actions", "and", "a", "kind", "of"],
    id: "Words are also actions, and actions are a kind of words.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["came", "friendship", "took", "away"],
    words: ["came", "but", "for", "friendship", "and", "took", "away", "love"],
    id: "Came but for friendship, and took away love.",
    author: "Thomas Moore",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["isn't", "something", "find", "finds"],
    words: ["love", "isn't", "something", "you", "find", "is", "that", "finds"],
    id: "Love isn't something you find. Love is something that finds you.",
    author: "Loretta Young",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["compulsively", "pun", "called", "paronomasiac"],
    words: ["if", "you", "compulsively", "pun", "are", "called", "a", "paronomasiac"],
    id: "If you compulsively pun you are called a paronomasiac.",
    author: "Tim Vine",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["everything", "existed", "lingers", "eternity"],
    words: ["everything", "that", "has", "existed", "lingers", "in", "the", "eternity"],
    id: "Everything that has existed, lingers in the Eternity.",
    author: "Agatha Christie",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "great", "always", "wishes"],
    words: ["where", "there", "is", "great", "love", "are", "always", "wishes"],
    id: "Where there is great love, there are always wishes.",
    author: "Willa Cather",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["above", "all", "gift", "oneself"],
    words: ["love", "is", "above", "all", "the", "gift", "of", "oneself"],
    id: "Love is, above all, the gift of oneself.",
    author: "Jean Anouilh",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["overact", "story", "your", "name"],
    words: ["don't", "overact", "the", "story", "of", "your", "name", "work"],
    id: "Don't overact the story of your name. Overact the story of your work.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["mountains", "get", "their", "tidings"],
    words: ["climb", "the", "mountains", "and", "get", "their", "good", "tidings"],
    id: "Climb the mountains and get their good tidings.",
    author: "John Muir",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["dying", "wild", "night", "road"],
    words: ["dying", "is", "a", "wild", "night", "and", "new", "road"],
    id: "Dying is a wild night and a new road.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["woman", "only", "cigar", "smoke"],
    words: ["a", "woman", "is", "only", "but", "good", "cigar", "smoke"],
    id: "A woman is only a woman, but a good cigar is a smoke.",
    author: "Rudyard Kipling",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["careful", "success", "dark", "side"],
    words: ["be", "careful", "of", "success", "it", "has", "a", "dark", "side"],
    id: "Be careful of success; it has a dark side.",
    author: "Robert Redford",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["alter", "beloved", "alters", "itself"],
    words: ["love", "does", "not", "alter", "the", "beloved", "it", "alters", "itself"],
    id: "Love does not alter the beloved, it alters itself.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["world", "were", "perfect", "wouldn't"],
    words: ["if", "the", "world", "were", "perfect", "it", "wouldn't", "be"],
    id: "If the world were perfect, it wouldn't be.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["purpose", "business", "create", "customer"],
    words: ["the", "purpose", "of", "a", "business", "is", "to", "create", "customer"],
    id: "The purpose of a business is to create a customer.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["observe", "lot", "by", "watching"],
    words: ["you", "can", "observe", "a", "lot", "by", "just", "watching"],
    id: "You can observe a lot by just watching.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["always", "better", "slightly", "underdressed"],
    words: ["it", "is", "always", "better", "to", "be", "slightly", "underdressed"],
    id: "It is always better to be slightly underdressed.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["double", "pleasure", "deceive", "deceiver"],
    words: ["it", "is", "double", "pleasure", "to", "deceive", "the", "deceiver"],
    id: "It is double pleasure to deceive the deceiver.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["yesterday", "today's", "memory", "dream"],
    words: ["yesterday", "is", "but", "today's", "memory", "and", "tomorrow", "dream"],
    id: "Yesterday is but today's memory, and tomorrow is today's dream.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "secrets", "time", "reveal"],
    words: ["there", "are", "no", "secrets", "that", "time", "does", "not", "reveal"],
    id: "There are no secrets that time does not reveal.",
    author: "Jean Racine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "forget", "remember", "understand"],
    words: ["i", "hear", "and", "forget", "see", "remember", "do", "understand"],
    id: "I hear and I forget. I see and I remember. I do and I understand.",
    author: "Confucius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["everything", "i", "joke", "myself"],
    words: ["everything", "i", "say", "is", "a", "joke", "am", "myself"],
    id: "Everything I say is a joke. I am a joke myself.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "knowledge", "evil", "ignorance"],
    words: ["the", "only", "good", "is", "knowledge", "and", "evil", "ignorance"],
    id: "The only good is knowledge, and the only evil is ignorance.",
    author: "Herodotus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "i", "had", "then"],
    words: ["the", "knowledge", "i", "have", "now", "is", "not", "had", "then"],
    id: "The knowledge I have now is not the knowledge I had then.",
    author: "Stokely Carmichael",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["gracefulness", "body", "understanding", "mind"],
    words: ["gracefulness", "is", "to", "the", "body", "what", "understanding", "mind"],
    id: "Gracefulness is to the body what understanding is to the mind.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "forgive", "great", "deal"],
    words: ["when", "you", "are", "happy", "can", "forgive", "a", "great", "deal"],
    id: "When you are happy you can forgive a great deal.",
    author: "Princess Diana",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["god", "best", "known", "knowing"],
    words: ["god", "is", "best", "known", "in", "not", "knowing", "him"],
    id: "God is best known in not knowing him.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["ego", "master", "its", "own"],
    words: ["the", "ego", "is", "not", "master", "in", "its", "own", "house"],
    id: "The ego is not master in its own house.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["because", "only", "true", "adventure"],
    words: ["we", "love", "because", "it's", "the", "only", "true", "adventure"],
    id: "We love because it's the only true adventure.",
    author: "Nikki Giovanni",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "clever", "poor", "stupid"],
    words: ["i", "think", "clever", "people", "that", "poor", "are", "stupid"],
    id: "I think clever people think that poor people are stupid.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["shape", "our", "buildings", "thereafter"],
    words: ["we", "shape", "our", "buildings", "thereafter", "they", "us"],
    id: "We shape our buildings; thereafter they shape us.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["look", "talent", "scout", "cemetery"],
    words: ["you", "look", "like", "a", "talent", "scout", "for", "cemetery"],
    id: "You look like a talent scout for a cemetery.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["those", "own", "much", "fear"],
    words: ["those", "who", "own", "much", "have", "to", "fear"],
    id: "Those who own much have much to fear.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "man", "whom", "fears"],
    words: ["no", "one", "loves", "the", "man", "whom", "he", "fears"],
    id: "No one loves the man whom he fears.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["humbleth", "himself", "wishes", "exalted"],
    words: ["he", "that", "humbleth", "himself", "wishes", "to", "be", "exalted"],
    id: "He that humbleth himself wishes to be exalted.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["show", "how", "done", "loser"],
    words: ["let", "me", "show", "you", "how", "it's", "done", "loser"],
    id: "Let me show you how it's done... Loser!",
    author: "Babe Ruth",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["often", "doubt", "most", "believe"],
    words: ["in", "love", "we", "often", "doubt", "what", "most", "believe"],
    id: "In love we often doubt what we most believe.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["mind", "anxious", "future", "miserable"],
    words: ["the", "mind", "that", "is", "anxious", "about", "future", "miserable"],
    id: "The mind that is anxious about the future is miserable.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "enjoy", "wasting", "wasted"],
    words: ["the", "time", "you", "enjoy", "wasting", "is", "not", "wasted"],
    id: "The time you enjoy wasting is not wasted time.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["gods", "too", "fond", "joke"],
    words: ["the", "gods", "too", "are", "fond", "of", "a", "joke"],
    id: "The gods too are fond of a joke.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["which", "kill", "makes", "stronger"],
    words: ["that", "which", "does", "not", "kill", "us", "makes", "stronger"],
    id: "That which does not kill us makes us stronger.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "injury", "sow", "pardon"],
    words: ["where", "there", "is", "injury", "let", "me", "sow", "pardon"],
    id: "Where there is injury let me sow pardon.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["honest", "man's", "noblest", "god"],
    words: ["an", "honest", "man's", "the", "noblest", "work", "of", "god"],
    id: "An honest man's the noblest work of God.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "order", "can't", "obeyed"],
    words: ["never", "give", "an", "order", "that", "can't", "be", "obeyed"],
    id: "Never give an order that can't be obeyed.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["respect", "one", "greatest", "expressions"],
    words: ["respect", "is", "one", "of", "the", "greatest", "expressions", "love"],
    id: "Respect is one of the greatest expressions of love.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["maybe", "stories", "data", "soul"],
    words: ["maybe", "stories", "are", "just", "data", "with", "a", "soul"],
    id: "Maybe stories are just data with a soul.",
    author: "Brene Brown",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["past", "would", "divine", "future"],
    words: ["study", "the", "past", "if", "you", "would", "divine", "future"],
    id: "Study the past, if you would divine the future.",
    author: "Confucius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["distrust", "caution", "parents", "security"],
    words: ["distrust", "and", "caution", "are", "the", "parents", "of", "security"],
    id: "Distrust and caution are the parents of security.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["humanity", "never", "had", "begin"],
    words: ["humanity", "you", "never", "had", "it", "to", "begin", "with"],
    id: "Humanity, you never had it to begin with.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["poets", "only", "interpreters", "gods"],
    words: ["the", "poets", "are", "only", "interpreters", "of", "gods"],
    id: "The poets are only the interpreters of the gods.",
    author: "Socrates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["heaven", "all", "interesting", "missing"],
    words: ["in", "heaven", "all", "the", "interesting", "people", "are", "missing"],
    id: "In heaven, all the interesting people are missing.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["first", "take", "then", "takes"],
    words: ["first", "you", "take", "a", "drink", "then", "the", "takes"],
    id: "First you take a drink, then the drink takes a drink, then the drink takes you.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["glamour", "all", "york", "america"],
    words: ["the", "glamour", "of", "it", "all", "new", "york", "america"],
    id: "The glamour of it all! New York! America!",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["tyrant", "fallen", "iraq", "free"],
    words: ["the", "tyrant", "has", "fallen", "and", "iraq", "is", "free"],
    id: "The tyrant has fallen, and Iraq is free.",
    author: "George W. Bush",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "afraid", "look", "idiot"],
    words: ["i'm", "not", "afraid", "to", "look", "like", "an", "idiot"],
    id: "I'm not afraid to look like an idiot.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "liberty", "unless", "economic"],
    words: ["there", "can", "be", "no", "liberty", "unless", "is", "economic"],
    id: "There can be no liberty unless there is economic liberty.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["man's", "worth", "its", "season"],
    words: ["a", "man's", "worth", "has", "its", "season", "like", "fruit"],
    id: "A man's worth has its season, like fruit.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "heroes", "evil", "as"],
    words: ["there", "are", "heroes", "in", "evil", "as", "well", "good"],
    id: "There are heroes in evil as well as in good.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiest", "women", "nations", "history"],
    words: ["the", "happiest", "women", "like", "nations", "have", "no", "history"],
    id: "The happiest women, like the happiest nations, have no history.",
    author: "George Eliot",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["necessity", "established", "fact", "interpretation"],
    words: ["necessity", "is", "not", "an", "established", "fact", "but", "interpretation"],
    id: "Necessity is not an established fact, but an interpretation.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["toleration", "all", "or", "none"],
    words: ["toleration", "is", "good", "for", "all", "or", "it", "none"],
    id: "Toleration is good for all, or it is good for none.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["end", "labor", "gain", "leisure"],
    words: ["the", "end", "of", "labor", "is", "to", "gain", "leisure"],
    id: "The end of labor is to gain leisure.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["legacy", "stupid", "thing", "i"],
    words: ["legacy", "is", "a", "stupid", "thing", "i", "don't", "want"],
    id: "Legacy is a stupid thing! I don't want a legacy.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["takes", "lot", "energy", "teach"],
    words: ["it", "takes", "a", "lot", "of", "energy", "to", "teach"],
    id: "It takes a lot of energy to teach.",
    author: "Dabney Coleman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "difficulties", "necessary", "health"],
    words: ["man", "needs", "difficulties", "they", "are", "necessary", "for", "health"],
    id: "Man needs difficulties; they are necessary for health.",
    author: "Carl Jung",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["superstition", "religion", "feeble", "minds"],
    words: ["superstition", "is", "the", "religion", "of", "feeble", "minds"],
    id: "Superstition is the religion of feeble minds.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "fairest", "toughest", "judge"],
    words: ["time", "is", "the", "fairest", "and", "toughest", "judge"],
    id: "Time is the fairest and toughest judge.",
    author: "Edgar Quinet",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "dressmaker", "specializing", "alterations"],
    words: ["time", "is", "a", "dressmaker", "specializing", "in", "alterations"],
    id: "Time is a dressmaker specializing in alterations.",
    author: "Faith Baldwin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["mature", "momentarily", "over", "long-term"],
    words: ["we", "don't", "mature", "momentarily", "but", "over", "the", "long-term"],
    id: "We don't mature momentarily, but over the long-term.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["as", "twig", "bent", "inclines"],
    words: ["as", "the", "twig", "is", "bent", "tree", "inclines"],
    id: "As the twig is bent the tree inclines.",
    author: "Virgil",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["education", "cheap", "defense", "nations"],
    words: ["education", "is", "the", "cheap", "defense", "of", "nations"],
    id: "Education is the cheap defense of nations.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "conservative", "any", "kind"],
    words: ["i'm", "not", "a", "conservative", "of", "any", "kind"],
    id: "I'm not a conservative of any kind.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["right", "man", "one", "seizes"],
    words: ["the", "right", "man", "is", "one", "who", "seizes", "moment"],
    id: "The right man is the one who seizes the moment.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["art", "proper", "task", "life"],
    words: ["art", "is", "the", "proper", "task", "of", "life"],
    id: "Art is the proper task of life.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["into", "leader", "before", "vision"],
    words: ["people", "buy", "into", "the", "leader", "before", "they", "vision"],
    id: "People buy into the leader before they buy into the vision.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "play", "soccer", "which"],
    words: ["what", "i", "do", "is", "play", "soccer", "which", "like"],
    id: "What I do is play soccer, which is what I like.",
    author: "Lionel Messi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["was", "patriotism", "communism", "inspired"],
    words: ["it", "was", "patriotism", "not", "communism", "that", "inspired", "me"],
    id: "It was patriotism, not communism, that inspired me.",
    author: "Ho Chi Minh",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["improve", "change", "perfect", "often"],
    words: ["to", "improve", "is", "change", "be", "perfect", "often"],
    id: "To improve is to change; to be perfect is to change often.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["family", "important", "thing", "everything"],
    words: ["family", "is", "not", "an", "important", "thing", "it's", "everything"],
    id: "Family is not an important thing. It's everything.",
    author: "Michael J. Fox",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["natural", "desire", "men", "knowledge"],
    words: ["the", "natural", "desire", "of", "good", "men", "is", "knowledge"],
    id: "The natural desire of good men is knowledge.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["sharing", "digital", "technology", "easy"],
    words: ["sharing", "is", "good", "and", "with", "digital", "technology", "easy"],
    id: "Sharing is good, and with digital technology, sharing is easy.",
    author: "Richard Stallman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "historian", "freaks", "out"],
    words: ["i'm", "a", "historian", "and", "that", "freaks", "me", "out"],
    id: "I'm a historian, and that freaks me out.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["all", "art", "imitation", "nature"],
    words: ["all", "art", "is", "but", "imitation", "of", "nature"],
    id: "All art is but imitation of nature.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["put", "back", "into", "communities"],
    words: ["it's", "good", "to", "put", "money", "back", "into", "communities"],
    id: "It's good to put money back into communities.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["pen", "as", "beak", "hen"],
    words: ["a", "pen", "is", "to", "me", "as", "beak", "hen"],
    id: "A pen is to me as a beak is to a hen.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["morning", "without", "dwindled", "dawn"],
    words: ["morning", "without", "you", "is", "a", "dwindled", "dawn"],
    id: "Morning without you is a dwindled dawn.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wit", "dunces", "dunce", "wits"],
    words: ["a", "wit", "with", "dunces", "and", "dunce", "wits"],
    id: "A wit with dunces, and a dunce with wits.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "trying", "change", "world"],
    words: ["i", "am", "trying", "to", "change", "the", "world"],
    id: "I am trying to change the world.",
    author: "Jean-Luc Godard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["way", "life", "worth", "defending"],
    words: ["this", "way", "of", "life", "is", "worth", "defending"],
    id: "This way of life is worth defending.",
    author: "George W. Bush",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["reform", "movement", "lunatic", "fringe"],
    words: ["every", "reform", "movement", "has", "a", "lunatic", "fringe"],
    id: "Every reform movement has a lunatic fringe.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "confess", "believe", "time"],
    words: ["i", "confess", "do", "not", "believe", "in", "time"],
    id: "I confess, I do not believe in time.",
    author: "Vladimir Nabokov",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["happiness", "health", "bad", "memory"],
    words: ["happiness", "is", "good", "health", "and", "a", "bad", "memory"],
    id: "Happiness is good health and a bad memory.",
    author: "Ingrid Bergman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["bible", "sanctum", "world", "sputum"],
    words: ["the", "bible", "is", "a", "sanctum", "world", "sputum"],
    id: "The Bible is a sanctum; the world, sputum.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["beauty", "greatest", "seducer", "man"],
    words: ["beauty", "is", "the", "greatest", "seducer", "of", "man"],
    id: "Beauty is the greatest seducer of man.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "childhood", "our", "immortality"],
    words: ["life", "is", "the", "childhood", "of", "our", "immortality"],
    id: "Life is the childhood of our immortality.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "lively", "process", "becoming"],
    words: ["life", "is", "a", "lively", "process", "of", "becoming"],
    id: "Life is a lively process of becoming.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["shakespeare", "didn't", "at", "all"],
    words: ["shakespeare", "didn't", "work", "at", "all", "for", "me"],
    id: "Shakespeare didn't work at all for me.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "movies", "answers", "questions"],
    words: ["my", "movies", "are", "not", "of", "answers", "but", "questions"],
    id: "My movies are not movies of answers but of questions.",
    author: "Alejandro Amenabar",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["because", "great", "one", "courageous"],
    words: ["because", "of", "a", "great", "love", "one", "is", "courageous"],
    id: "Because of a great love, one is courageous.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["farther", "backward", "look", "forward"],
    words: ["the", "farther", "backward", "you", "can", "look", "forward", "see"],
    id: "The farther backward you can look, the farther forward you can see.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "lead", "from", "behind"],
    words: ["you", "know", "can", "only", "lead", "them", "from", "behind"],
    id: "You know, you can only lead them from behind.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["object", "superior", "man", "truth"],
    words: ["the", "object", "of", "superior", "man", "is", "truth"],
    id: "The object of the superior man is truth.",
    author: "Confucius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["loving", "heart", "truest", "wisdom"],
    words: ["a", "loving", "heart", "is", "the", "truest", "wisdom"],
    id: "A loving heart is the truest wisdom.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "there", "darkness", "ignorance"],
    words: ["i", "say", "there", "is", "no", "darkness", "but", "ignorance"],
    id: "I say there is no darkness but ignorance.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["traveller", "reached", "end", "journey"],
    words: ["the", "traveller", "has", "reached", "end", "of", "journey"],
    id: "The traveller has reached the end of the journey!",
    author: "Edmund Burke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["better", "travel", "than", "arrive"],
    words: ["it", "is", "better", "to", "travel", "well", "than", "arrive"],
    id: "It is better to travel well than to arrive.",
    author: "Buddha",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["wouldn't", "won", "we'd", "beaten"],
    words: ["you", "wouldn't", "have", "won", "if", "we'd", "beaten"],
    id: "You wouldn't have won if we'd beaten you.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "warrior", "middle", "class"],
    words: ["i'm", "a", "warrior", "for", "the", "middle", "class"],
    id: "I'm a warrior for the middle class.",
    author: "Barack Obama",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["supernatural", "natural", "yet", "understood"],
    words: ["the", "supernatural", "is", "natural", "not", "yet", "understood"],
    id: "The supernatural is the natural not yet understood.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wherever", "all", "your", "heart"],
    words: ["wherever", "you", "go", "with", "all", "your", "heart"],
    id: "Wherever you go, go with all your heart.",
    author: "Confucius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["others", "wise", "himself", "enlightened"],
    words: ["he", "who", "knows", "others", "is", "wise", "himself", "enlightened"],
    id: "He who knows others is wise. He who knows himself is enlightened.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["music", "soundtrack", "your", "life"],
    words: ["music", "is", "the", "soundtrack", "of", "your", "life"],
    id: "Music is the soundtrack of your life.",
    author: "Dick Clark",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["sweetest", "all", "sounds", "praise"],
    words: ["the", "sweetest", "of", "all", "sounds", "is", "praise"],
    id: "The sweetest of all sounds is praise.",
    author: "Xenophon",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "kind", "fascist", "myself"],
    words: ["i'm", "kind", "of", "fascist", "with", "myself", "you", "know"],
    id: "I'm kind of fascist with myself, you know.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "still", "on", "patience"],
    words: ["i", "still", "have", "to", "work", "on", "patience"],
    id: "I still have to work on patience.",
    author: "Robert Mueller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "part", "was", "greek"],
    words: ["for", "my", "part", "it", "was", "greek", "to", "me"],
    id: "For my part, it was Greek to me.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["real", "secret", "success", "enthusiasm"],
    words: ["the", "real", "secret", "of", "success", "is", "enthusiasm"],
    id: "The real secret of success is enthusiasm.",
    author: "Walter Chrysler",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["loyalty", "friendship", "trumps", "politics"],
    words: ["loyalty", "and", "friendship", "trumps", "politics", "for", "me"],
    id: "Loyalty and friendship trumps politics for me.",
    author: "Robert Kraft",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["education", "preparation", "life", "itself"],
    words: ["education", "is", "not", "preparation", "for", "life", "itself"],
    id: "Education is not preparation for life; education is life itself.",
    author: "John Dewey",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "always", "overmatch", "strength"],
    words: ["wisdom", "is", "always", "an", "overmatch", "for", "strength"],
    id: "Wisdom is always an overmatch for strength.",
    author: "Phil Jackson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["always", "win", "side", "god"],
    words: ["they", "always", "win", "who", "side", "with", "god"],
    id: "They always win who side with God.",
    author: "Frederick William Faber",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["inequality", "partly", "marker", "success"],
    words: ["inequality", "is", "partly", "a", "marker", "of", "success"],
    id: "Inequality is partly a marker of success.",
    author: "Angus Deaton",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["eyes", "those", "silent", "tongues"],
    words: ["the", "eyes", "those", "silent", "tongues", "of", "love"],
    id: "The eyes those silent tongues of love.",
    author: "Miguel de Cervantes",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "always", "interested", "something"],
    words: ["i'm", "always", "interested", "in", "learning", "something", "new"],
    id: "I'm always interested in learning something new.",
    author: "Katherine Johnson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wish", "others", "god", "wishes"],
    words: ["what", "you", "wish", "to", "others", "god", "wishes"],
    id: "What you wish to others, God wishes to you.",
    author: "T. B. Joshua",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["observe", "lot", "by", "watching"],
    words: ["you", "can", "observe", "a", "lot", "by", "watching"],
    id: "You can observe a lot by watching.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["charity", "substitute", "justice", "withheld"],
    words: ["charity", "is", "no", "substitute", "for", "justice", "withheld"],
    id: "Charity is no substitute for justice withheld.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["really", "forgive", "can't", "forget"],
    words: ["can", "you", "really", "forgive", "if", "can't", "forget"],
    id: "Can you really forgive if you can't forget?",
    author: "Sarah Jessica Parker",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["patience", "was", "easy", "lesson"],
    words: ["learning", "patience", "was", "not", "an", "easy", "lesson"],
    id: "Learning patience was not an easy lesson.",
    author: "Michael Douglas",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["tell", "send", "everything", "fly"],
    words: ["tell", "them", "to", "send", "everything", "that", "can", "fly"],
    id: "Tell them to send everything that can fly.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["nothing", "god's", "obtainable", "by"],
    words: ["nothing", "that", "is", "god's", "obtainable", "by", "money"],
    id: "Nothing that is God's is obtainable by money.",
    author: "Tertullian",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["education", "another", "name", "happiness"],
    words: ["a", "good", "education", "is", "another", "name", "for", "happiness"],
    id: "A good education is another name for happiness.",
    author: "Ann Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["growth", "only", "evidence", "life"],
    words: ["growth", "is", "the", "only", "evidence", "of", "life"],
    id: "Growth is the only evidence of life.",
    author: "John Henry Newman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["beware", "barrenness", "busy", "life"],
    words: ["beware", "the", "barrenness", "of", "a", "busy", "life"],
    id: "Beware the barrenness of a busy life.",
    author: "Socrates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "long", "lesson", "humility"],
    words: ["life", "is", "a", "long", "lesson", "in", "humility"],
    id: "Life is a long lesson in humility.",
    author: "James M. Barrie",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["being", "only", "transcendent", "experience"],
    words: ["being", "in", "love", "is", "the", "only", "transcendent", "experience"],
    id: "Being in love is the only transcendent experience.",
    author: "Armistead Maupin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["success", "preparation", "opportunity", "meet"],
    words: ["success", "is", "where", "preparation", "and", "opportunity", "meet"],
    id: "Success is where preparation and opportunity meet.",
    author: "Bobby Unser",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["though", "lovers", "lost", "shall"],
    words: ["though", "lovers", "be", "lost", "love", "shall", "not"],
    id: "Though lovers be lost, love shall not.",
    author: "Dylan Thomas",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "family", "definitely", "ordinary"],
    words: ["my", "family", "they", "are", "definitely", "not", "an", "ordinary"],
    id: "My family, they are definitely not an ordinary family.",
    author: "Linda Perry",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["principle", "part", "faith", "patience"],
    words: ["the", "principle", "part", "of", "faith", "is", "patience"],
    id: "The principle part of faith is patience.",
    author: "George MacDonald",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "age", "stop", "walking"],
    words: ["the", "wisdom", "of", "age", "don't", "stop", "walking"],
    id: "The wisdom of age: don't stop walking.",
    author: "Mason Cooley",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["necessary", "change", "survival", "mandatory"],
    words: ["it", "is", "not", "necessary", "to", "change", "survival", "mandatory"],
    id: "It is not necessary to change. Survival is not mandatory.",
    author: "W. Edwards Deming",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["kids", "teach", "lot", "patience"],
    words: ["kids", "teach", "you", "a", "lot", "of", "patience"],
    id: "Kids teach you a lot of patience.",
    author: "Mona Singh",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["forgiveness", "means", "letting", "past"],
    words: ["forgiveness", "means", "letting", "go", "of", "the", "past"],
    id: "Forgiveness means letting go of the past.",
    author: "Gerald Jampolsky",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["movies", "biggest", "entertainer", "india"],
    words: ["movies", "are", "the", "biggest", "entertainer", "in", "india"],
    id: "Movies are the biggest entertainer in India.",
    author: "Ranjeet",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["pound", "worth", "ton", "luck"],
    words: ["a", "pound", "of", "pluck", "is", "worth", "ton", "luck"],
    id: "A pound of pluck is worth a ton of luck.",
    author: "James A. Garfield",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["beware", "fury", "patient", "man"],
    words: ["beware", "the", "fury", "of", "a", "patient", "man"],
    id: "Beware the fury of a patient man.",
    author: "John Dryden",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["god", "forgive", "i", "never"],
    words: ["god", "forgive", "you", "but", "i", "never", "can"],
    id: "God forgive you, but I never can.",
    author: "Elizabeth I",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "peace", "prepare", "war"],
    words: ["in", "time", "of", "peace", "prepare", "for", "war"],
    id: "In time of peace prepare for war.",
    author: "Publius Flavius Vegetius Renatus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["everything", "as", "eye", "another"],
    words: ["do", "everything", "as", "in", "the", "eye", "of", "another"],
    id: "Do everything as in the eye of another.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["fine", "manners", "support", "others"],
    words: ["fine", "manners", "need", "the", "support", "of", "in", "others"],
    id: "Fine manners need the support of fine manners in others.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "all", "essential", "my"],
    words: ["the", "freedom", "of", "all", "is", "essential", "to", "my"],
    id: "The freedom of all is essential to my freedom.",
    author: "Mikhail Bakunin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["certainly", "strange", "names", "diseases"],
    words: ["they", "certainly", "give", "very", "strange", "names", "to", "diseases"],
    id: "They certainly give very strange names to diseases.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["horror", "movies", "real", "escape"],
    words: ["for", "me", "horror", "movies", "are", "a", "real", "escape"],
    id: "For me, horror movies are a real escape.",
    author: "Lena Headey",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["unrest", "spirit", "mark", "life"],
    words: ["unrest", "of", "spirit", "is", "a", "mark", "life"],
    id: "Unrest of spirit is a mark of life.",
    author: "Karl A. Menninger",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["goal", "all", "life", "death"],
    words: ["the", "goal", "of", "all", "life", "is", "death"],
    id: "The goal of all life is death.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["alcohol", "infinite", "patience", "stupidity"],
    words: ["alcohol", "gives", "you", "infinite", "patience", "for", "stupidity"],
    id: "Alcohol gives you infinite patience for stupidity.",
    author: "Sammy Davis, Jr.",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "largely", "matter", "expectation"],
    words: ["life", "is", "largely", "a", "matter", "of", "expectation"],
    id: "Life is largely a matter of expectation.",
    author: "Horace",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "limes", "make", "margaritas"],
    words: ["if", "life", "gives", "you", "limes", "make", "margaritas"],
    id: "If life gives you limes, make margaritas.",
    author: "Jimmy Buffett",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["fear", "proof", "degenerate", "mind"],
    words: ["fear", "is", "proof", "of", "a", "degenerate", "mind"],
    id: "Fear is proof of a degenerate mind.",
    author: "Virgil",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["my", "trade", "art", "live"],
    words: ["my", "trade", "and", "art", "is", "to", "live"],
    id: "My trade and art is to live.",
    author: "Michel de Montaigne",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["friendship", "man", "his", "virtue"],
    words: ["friendship", "with", "a", "man", "is", "his", "virtue"],
    id: "Friendship with a man is friendship with his virtue.",
    author: "Mencius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["god's", "finger", "touched", "slept"],
    words: ["god's", "finger", "touched", "him", "and", "he", "slept"],
    id: "God's finger touched him, and he slept.",
    author: "Alfred Lord Tennyson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["none", "weight", "another's", "burden"],
    words: ["none", "knows", "the", "weight", "of", "another's", "burden"],
    id: "None knows the weight of another's burden.",
    author: "George Herbert",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["hope", "patience", "lamp", "lit"],
    words: ["hope", "is", "patience", "with", "the", "lamp", "lit"],
    id: "Hope is patience with the lamp lit.",
    author: "Tertullian",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["thought", "wind", "knowledge", "sail"],
    words: ["thought", "is", "the", "wind", "and", "knowledge", "sail"],
    id: "Thought is the wind and knowledge the sail.",
    author: "David Hare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "lived", "fear", "half"],
    words: ["a", "life", "lived", "in", "fear", "is", "half"],
    id: "A life lived in fear is a life half lived.",
    author: "Baz Luhrmann",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["find", "patience", "breath", "life"],
    words: ["find", "patience", "in", "the", "breath", "of", "life"],
    id: "Find patience in the breath of life.",
    author: "Ryunosuke Satoro",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["tough", "times", "never", "last"],
    words: ["tough", "times", "never", "last", "but", "people", "do"],
    id: "Tough times never last, but tough people do.",
    author: "Robert H. Schuller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["always", "keep", "keeps", "young"],
    words: ["always", "keep", "learning", "it", "keeps", "you", "young"],
    id: "Always keep learning. It keeps you young.",
    author: "Patty Berg",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["books", "leave", "free", "make"],
    words: ["some", "books", "leave", "us", "free", "and", "make"],
    id: "Some books leave us free and some books make us free.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["as", "entrepreneur", "never", "stop"],
    words: ["as", "an", "entrepreneur", "you", "never", "stop", "learning"],
    id: "As an entrepreneur, you never stop learning.",
    author: "Daymond John",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "wisest", "counselor", "all"],
    words: ["time", "is", "the", "wisest", "counselor", "of", "all"],
    id: "Time is the wisest counselor of all.",
    author: "Pericles",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["lot", "change", "editing", "room"],
    words: ["a", "lot", "can", "change", "in", "the", "editing", "room"],
    id: "A lot can change in the editing room.",
    author: "Diane Lane",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["patience", "bitter", "its", "sweet"],
    words: ["patience", "is", "bitter", "but", "its", "fruit", "sweet"],
    id: "Patience is bitter, but its fruit is sweet.",
    author: "Jean-Jacques Rousseau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["pass", "through", "world", "once"],
    words: ["we", "pass", "through", "this", "world", "but", "once"],
    id: "We pass through this world but once.",
    author: "Stephen Jay Gould",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "which", "popular", "scientific"],
    words: ["that", "knowledge", "which", "is", "popular", "not", "scientific"],
    id: "That knowledge which is popular is not scientific.",
    author: "Maria Mitchell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["angel", "god", "never", "wings"],
    words: ["an", "angel", "of", "god", "never", "has", "wings"],
    id: "An angel of God never has wings.",
    author: "Joseph Smith, Jr.",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "change", "way", "feel"],
    words: ["when", "you", "change", "the", "way", "think", "can", "feel"],
    id: "When you change the way you think, you can change the way you feel.",
    author: "David D. Burns",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["eternity", "standing", "still", "ever"],
    words: ["eternity", "a", "moment", "standing", "still", "for", "ever"],
    id: "Eternity: a moment standing still for ever.",
    author: "James Montgomery",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "patience", "wasting", "time"],
    words: ["i", "have", "no", "patience", "for", "wasting", "time"],
    id: "I have no patience for wasting time.",
    author: "William Clay Ford, Jr.",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["most", "unhappy", "fear", "change"],
    words: ["it's", "the", "most", "unhappy", "people", "who", "fear", "change"],
    id: "It's the most unhappy people who most fear change.",
    author: "Mignon McLaughlin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["chess", "only", "knowledge", "logic"],
    words: ["chess", "is", "not", "only", "knowledge", "and", "logic"],
    id: "Chess is not only knowledge and logic.",
    author: "Alexander Alekhine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["movies", "shadiest", "business", "world"],
    words: ["movies", "are", "the", "shadiest", "business", "in", "world"],
    id: "Movies are the shadiest business in the world.",
    author: "Jessica Alba",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["music", "only", "looking", "words"],
    words: ["music", "is", "only", "love", "looking", "for", "words"],
    id: "Music is only love looking for words.",
    author: "Lawrence Durrell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["force", "more", "potent", "than"],
    words: ["what", "force", "is", "more", "potent", "than", "love"],
    id: "What force is more potent than love?",
    author: "Igor Stravinsky",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["everything", "against", "weakens", "empowers"],
    words: ["everything", "you", "are", "against", "weakens", "for", "empowers"],
    id: "Everything you are against weakens you. Everything you are for empowers you.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "possible", "beginning", "happiness"],
    words: ["knowledge", "of", "what", "is", "possible", "the", "beginning", "happiness"],
    id: "Knowledge of what is possible is the beginning of happiness.",
    author: "George Santayana",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["avoid", "popularity", "would", "peace"],
    words: ["avoid", "popularity", "if", "you", "would", "have", "peace"],
    id: "Avoid popularity if you would have peace.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["sky", "daily", "bread", "eyes"],
    words: ["the", "sky", "is", "daily", "bread", "of", "eyes"],
    id: "The sky is the daily bread of the eyes.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["our", "first", "last", "self-love"],
    words: ["our", "first", "and", "last", "love", "is", "self-love"],
    id: "Our first and last love is self-love.",
    author: "Christian Nestell Bovee",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["everybody", "somebody", "nobody", "grow"],
    words: ["everybody", "wants", "to", "be", "somebody", "nobody", "grow"],
    id: "Everybody wants to be somebody; nobody wants to grow.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["paranoia", "another", "word", "ignorance"],
    words: ["paranoia", "is", "just", "another", "word", "for", "ignorance"],
    id: "Paranoia is just another word for ignorance.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["mother", "really", "never", "free"],
    words: ["a", "mother", "who", "is", "really", "never", "free"],
    id: "A mother who is really a mother is never free.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["discontent", "first", "necessity", "progress"],
    words: ["discontent", "is", "the", "first", "necessity", "of", "progress"],
    id: "Discontent is the first necessity of progress.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["war", "mainly", "catalogue", "blunders"],
    words: ["war", "is", "mainly", "a", "catalogue", "of", "blunders"],
    id: "War is mainly a catalogue of blunders.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "interested", "how", "change"],
    words: ["i'm", "very", "interested", "in", "how", "people", "change"],
    id: "I'm very interested in how people change.",
    author: "Nathan Englander",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["thousands", "lived", "without", "one"],
    words: ["thousands", "have", "lived", "without", "love", "not", "one", "water"],
    id: "Thousands have lived without love, not one without water.",
    author: "W. H. Auden",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "unique", "picture", "reality"],
    words: ["there", "is", "no", "unique", "picture", "of", "reality"],
    id: "There is no unique picture of reality.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["dignity", "negotiable", "honor", "family"],
    words: ["dignity", "is", "not", "negotiable", "the", "honor", "of", "family"],
    id: "Dignity is not negotiable. Dignity is the honor of the family.",
    author: "Vartan Gregorian",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["men", "their", "mothers", "made"],
    words: ["men", "are", "what", "their", "mothers", "made", "them"],
    id: "Men are what their mothers made them.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "always", "second", "chance"],
    words: ["in", "life", "you", "always", "have", "a", "second", "chance"],
    id: "In life, you always have a second chance.",
    author: "Patrice Evra",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["facts", "fit", "theory", "change"],
    words: ["if", "the", "facts", "don't", "fit", "theory", "change"],
    id: "If the facts don't fit the theory, change the facts.",
    author: "Unknown",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["follow", "will", "flee", "thee"],
    words: ["follow", "love", "and", "it", "will", "flee", "thee"],
    id: "Follow love and it will flee, flee love and it will follow thee.",
    author: "John Gay",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wherever", "fates", "lead", "follow"],
    words: ["wherever", "the", "fates", "lead", "us", "let", "follow"],
    id: "Wherever the fates lead us let us follow.",
    author: "Virgil",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["slump", "i", "ain't", "hitting"],
    words: ["slump", "i", "ain't", "in", "no", "just", "hitting"],
    id: "Slump? I ain't in no slump... I just ain't hitting.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["writer", "engineer", "human", "soul"],
    words: ["the", "writer", "is", "engineer", "of", "human", "soul"],
    id: "The writer is the engineer of the human soul.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "its", "root", "goodness"],
    words: ["wisdom", "has", "its", "root", "in", "goodness", "not"],
    id: "Wisdom has its root in goodness, not goodness its root in wisdom.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "spent", "never", "wasted"],
    words: ["time", "spent", "with", "cats", "is", "never", "wasted"],
    id: "Time spent with cats is never wasted.",
    author: "Unknown",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["music", "demands", "full", "concentration"],
    words: ["music", "for", "me", "it", "demands", "full", "concentration"],
    id: "Music for me, it demands full concentration.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["honest", "man", "always", "child"],
    words: ["an", "honest", "man", "is", "always", "a", "child"],
    id: "An honest man is always a child.",
    author: "Socrates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["must", "change", "order", "survive"],
    words: ["you", "must", "change", "in", "order", "to", "survive"],
    id: "You must change in order to survive.",
    author: "Pearl Bailey",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["experience", "making", "mistakes", "from"],
    words: ["experience", "is", "making", "mistakes", "and", "learning", "from", "them"],
    id: "Experience is making mistakes and learning from them.",
    author: "Bill Ackman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["can't", "nature", "run", "wild"],
    words: ["you", "can't", "just", "let", "nature", "run", "wild"],
    id: "You can't just let nature run wild.",
    author: "Walt Disney",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["youth", "hope", "our", "future"],
    words: ["the", "youth", "is", "hope", "of", "our", "future"],
    id: "The youth is the hope of our future.",
    author: "Jose Rizal",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["death", "cure", "all", "diseases"],
    words: ["death", "is", "the", "cure", "for", "all", "diseases"],
    id: "Death is the cure for all diseases.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["best", "revenge", "world", "success"],
    words: ["the", "best", "revenge", "in", "world", "is", "success"],
    id: "The best revenge in the world is success.",
    author: "Suge Knight",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["patriot's", "blood", "seed", "freedom's"],
    words: ["the", "patriot's", "blood", "is", "seed", "of", "freedom's", "tree"],
    id: "The patriot's blood is the seed of Freedom's tree.",
    author: "Thomas Campbell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["industrial", "revolution", "brings", "along"],
    words: ["every", "industrial", "revolution", "brings", "along", "a", "learning"],
    id: "Every industrial revolution brings along a learning revolution.",
    author: "Alexander De Croo",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["human", "nature", "itself", "vicious"],
    words: ["human", "nature", "is", "not", "of", "itself", "vicious"],
    id: "Human nature is not of itself vicious.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "california", "weather", "fantastic"],
    words: ["i", "love", "california", "the", "weather", "is", "fantastic"],
    id: "I love California. The weather is fantastic.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["dirtiest", "book", "all", "expurgated"],
    words: ["the", "dirtiest", "book", "of", "all", "is", "expurgated"],
    id: "The dirtiest book of all is the expurgated book.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["first", "species", "capable", "self-annihilation"],
    words: ["we", "are", "the", "first", "species", "capable", "of", "self-annihilation"],
    id: "We are the first species capable of self-annihilation.",
    author: "Elon Musk",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["discipline", "desire", "background", "character"],
    words: ["the", "discipline", "of", "desire", "is", "background", "character"],
    id: "The discipline of desire is the background of character.",
    author: "John Locke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["take", "i", "drug", "hallucinogenic"],
    words: ["take", "me", "i", "am", "the", "drug", "hallucinogenic"],
    id: "Take me, I am the drug; take me, I am hallucinogenic.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "consider", "myself", "credulous"],
    words: ["i", "don't", "consider", "myself", "to", "be", "that", "credulous"],
    id: "I don't consider myself to be that credulous.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["gods", "suit", "their", "circumstances"],
    words: ["every", "people", "have", "gods", "to", "suit", "their", "circumstances"],
    id: "Every people have gods to suit their circumstances.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["fashion", "architecture", "matter", "proportions"],
    words: ["fashion", "is", "architecture", "it", "a", "matter", "of", "proportions"],
    id: "Fashion is architecture: it is a matter of proportions.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["should", "treat", "muse", "fairy"],
    words: ["you", "should", "treat", "a", "muse", "like", "fairy"],
    id: "You should treat a muse like a fairy.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["purpose", "all", "wars", "peace"],
    words: ["the", "purpose", "of", "all", "wars", "is", "peace"],
    id: "The purpose of all wars, is peace.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'm", "united", "states", "emperor"],
    words: ["i'm", "the", "president", "of", "united", "states", "not", "emperor"],
    id: "I'm the president of the United States. I'm not the emperor of the United States.",
    author: "Barack Obama",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["liberalism", "totalitarianism", "human", "face"],
    words: ["liberalism", "is", "totalitarianism", "with", "a", "human", "face"],
    id: "Liberalism is totalitarianism with a human face.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["neurosis", "inability", "tolerate", "ambiguity"],
    words: ["neurosis", "is", "the", "inability", "to", "tolerate", "ambiguity"],
    id: "Neurosis is the inability to tolerate ambiguity.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "little", "patience", "children"],
    words: ["i", "have", "very", "little", "patience", "with", "children"],
    id: "I have very little patience with children.",
    author: "Mem Fox",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["miss", "100%", "shots", "take"],
    words: ["you", "miss", "100%", "of", "the", "shots", "don't", "take"],
    id: "You miss 100% of the shots you don't take.",
    author: "Wayne Gretzky",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["before", "drop", "album", "momentum"],
    words: ["before", "you", "drop", "an", "album", "need", "some", "momentum"],
    id: "Before you drop an album, you need some momentum.",
    author: "Jadakiss",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["will", "draw", "through", "key-hole"],
    words: ["love", "will", "draw", "an", "elephant", "through", "a", "key-hole"],
    id: "Love will draw an elephant through a key-hole.",
    author: "Samuel Richardson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["you've", "got", "life", "live"],
    words: ["if", "you've", "got", "love", "life", "you", "can", "live"],
    id: "If you've got love you've got life, if you can love you can live.",
    author: "Kim Casali",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["self", "which", "one", "truly"],
    words: ["be", "that", "self", "which", "one", "truly", "is"],
    id: "Be that self which one truly is.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["there", "cosmetic", "beauty", "happiness"],
    words: ["there", "is", "no", "cosmetic", "for", "beauty", "like", "happiness"],
    id: "There is no cosmetic for beauty like happiness.",
    author: "Maria Mitchell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["everybody", "heaven", "nobody", "die"],
    words: ["everybody", "wants", "to", "go", "heaven", "but", "nobody", "die"],
    id: "Everybody wants to go to heaven, but nobody wants to die.",
    author: "Unknown",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["crazy", "players", "i", "why"],
    words: ["crazy", "players", "love", "me", "i", "don't", "know", "why"],
    id: "Crazy players love me. I don't know why.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["first", "requisite", "civilization", "justice"],
    words: ["the", "first", "requisite", "of", "civilization", "is", "that", "justice"],
    id: "The first requisite of civilization is that of justice.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["unexamined", "life", "worth", "living"],
    words: ["the", "unexamined", "life", "is", "not", "worth", "living"],
    id: "The unexamined life is not worth living.",
    author: "Socrates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["forgiveness", "key", "action", "freedom"],
    words: ["forgiveness", "is", "the", "key", "to", "action", "and", "freedom"],
    id: "Forgiveness is the key to action and freedom.",
    author: "Hannah Arendt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["fool", "his", "soon", "elected"],
    words: ["a", "fool", "and", "his", "money", "are", "soon", "elected"],
    id: "A fool and his money are soon elected.",
    author: "Will Rogers",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "born", "atheism", "believe"],
    words: ["man", "is", "not", "born", "to", "atheism", "he", "believe"],
    id: "Man is not born to atheism. He is born to believe.",
    author: "Billy Graham",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["once", "blown", "forever", "dies"],
    words: ["the", "flower", "that", "once", "has", "blown", "forever", "dies"],
    id: "The Flower that once has blown forever dies.",
    author: "Omar Khayyam",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["rather", "than", "fame", "truth"],
    words: ["rather", "than", "love", "money", "fame", "give", "me", "truth"],
    id: "Rather than love, than money, than fame, give me truth.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["on", "hand", "different", "fingers"],
    words: ["on", "the", "other", "hand", "you", "have", "different", "fingers"],
    id: "On the other hand, you have different fingers.",
    author: "Steven Wright",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["can't", "everything", "would", "put"],
    words: ["you", "can't", "have", "everything", "where", "would", "put", "it"],
    id: "You can't have everything. Where would you put it?",
    author: "Steven Wright",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["hard", "believe", "because", "obey"],
    words: ["it", "is", "so", "hard", "to", "believe", "because", "obey"],
    id: "It is so hard to believe because it is so hard to obey.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["hair", "divides", "false", "true"],
    words: ["a", "hair", "divides", "what", "is", "false", "and", "true"],
    id: "A hair divides what is false and true.",
    author: "Omar Khayyam",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["order", "foundation", "all", "things"],
    words: ["good", "order", "is", "the", "foundation", "of", "all", "things"],
    id: "Good order is the foundation of all things.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["boast", "their", "iq", "losers"],
    words: ["people", "who", "boast", "about", "their", "iq", "are", "losers"],
    id: "People who boast about their I.Q. are losers.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["religion", "spirituality", "also", "without"],
    words: ["you", "can", "have", "religion", "with", "spirituality", "also", "without"],
    id: "You can have religion with spirituality. You can also have religion without spirituality.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["line", "dot", "went", "walk"],
    words: ["a", "line", "is", "dot", "that", "went", "for", "walk"],
    id: "A line is a dot that went for a walk.",
    author: "Paul Klee",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "much", "patience", "sick"],
    words: ["i", "don't", "have", "much", "patience", "with", "sick", "people"],
    id: "I don't have much patience with sick people.",
    author: "Maura Tierney",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["liberty", "others", "power", "ourselves"],
    words: ["the", "love", "of", "liberty", "is", "others", "power", "ourselves"],
    id: "The love of liberty is the love of others; the love of power is the love of ourselves.",
    author: "William Hazlitt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "easily", "satisfied", "best"],
    words: ["i", "am", "easily", "satisfied", "with", "the", "very", "best"],
    id: "I am easily satisfied with the very best.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["temperance", "mean", "regard", "pleasures"],
    words: ["temperance", "is", "a", "mean", "with", "regard", "to", "pleasures"],
    id: "Temperance is a mean with regard to pleasures.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "myself", "on", "television"],
    words: ["i", "don't", "like", "seeing", "myself", "on", "television", "it"],
    id: "I don't like seeing myself on television. I don't like it.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["first", "quality", "needed", "audacity"],
    words: ["the", "first", "quality", "that", "is", "needed", "audacity"],
    id: "The first quality that is needed is audacity.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 3,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "seldom", "same", "man"],
    words: ["great", "and", "good", "are", "seldom", "the", "same", "man"],
    id: "Great and good are seldom the same man.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["were", "better", "wouldn't", "as"],
    words: ["if", "it", "were", "better", "wouldn't", "be", "as", "good"],
    id: "If it were better, it wouldn't be as good.",
    author: "Brendan Gill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["fight", "between", "world", "back"],
    words: ["in", "the", "fight", "between", "you", "and", "world", "back"],
    id: "In the fight between you and the world, back the world.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["may", "live", "as", "long"],
    words: ["may", "you", "live", "as", "long", "want", "and", "not"],
    id: "May you live as long as you want and not want as long as you live.",
    author: "Unknown",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["there's", "substitute", "practice", "meditation"],
    words: ["there's", "no", "substitute", "for", "the", "practice", "of", "meditation"],
    id: "There's no substitute for the practice of meditation.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["would", "i", "without", "baseball"],
    words: ["where", "would", "i", "be", "without", "baseball", "who", "am"],
    id: "Where would I be without baseball? Who am I without baseball?",
    author: "Bob Uecker",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["triumph", "imagination", "over", "intelligence"],
    words: ["love", "is", "the", "triumph", "of", "imagination", "over", "intelligence"],
    id: "Love is the triumph of imagination over intelligence.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["future", "always", "dystopia", "movies"],
    words: ["the", "future", "is", "always", "a", "dystopia", "in", "movies"],
    id: "The future is always a dystopia in movies.",
    author: "Alex Cox",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["public-opinion", "poll", "substitute", "thought"],
    words: ["a", "public-opinion", "poll", "is", "no", "substitute", "for", "thought"],
    id: "A public-opinion poll is no substitute for thought.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 8,
    all_intersection_count: 4,
    new_words_count: 4,
  },
  {
    row_new_words: ["ceases", "pleasure", "when", "secret"],
    words: ["love", "ceases", "to", "be", "a", "pleasure", "when", "it", "secret"],
    id: "Love ceases to be a pleasure when it ceases to be a secret.",
    author: "Aphra Behn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "god", "always", "majority"],
    words: ["a", "man", "with", "god", "is", "always", "in", "the", "majority"],
    id: "A man with God is always in the majority.",
    author: "John Knox",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["always", "bathroom", "when", "chance"],
    words: ["always", "go", "to", "the", "bathroom", "when", "you", "have", "a", "chance"],
    id: "Always go to the bathroom when you have a chance.",
    author: "King George V",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["freedom", "what's", "been", "done"],
    words: ["freedom", "is", "what", "you", "do", "with", "what's", "been", "done", "to"],
    id: "Freedom is what you do with what's been done to you.",
    author: "Jean-Paul Sartre",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["easter", "important", "second", "chance"],
    words: ["easter", "is", "very", "important", "to", "me", "it's", "a", "second", "chance"],
    id: "Easter is very important to me, it's a second chance.",
    author: "Reba McEntire",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "free", "at", "wishes"],
    words: ["man", "is", "free", "at", "the", "moment", "he", "wishes", "to", "be"],
    id: "Man is free at the moment he wishes to be.",
    author: "Voltaire",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["physical", "roles", "chance", "something"],
    words: ["physical", "roles", "give", "me", "a", "chance", "to", "learn", "something", "new"],
    id: "Physical roles give me a chance to learn something new.",
    author: "Zoe Saldana",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["always", "being", "given", "required"],
    words: ["love", "is", "always", "being", "given", "where", "it", "not", "required"],
    id: "Love is always being given where it is not required.",
    author: "E. M. Forster",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["conscience", "authentic", "voice", "god"],
    words: ["conscience", "is", "the", "authentic", "voice", "of", "god", "to", "you"],
    id: "Conscience is the authentic voice of God to you.",
    author: "Rutherford B. Hayes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "god", "all", "until"],
    words: ["you", "never", "know", "god", "is", "all", "need", "until", "have"],
    id: "You never know God is all you need until God is all you have.",
    author: "Rick Warren",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["patience", "rebel", "against", "hardship"],
    words: ["to", "learn", "patience", "is", "not", "rebel", "against", "every", "hardship"],
    id: "To learn patience is not to rebel against every hardship.",
    author: "Henri Nouwen",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["soul", "within", "man", "degrade"],
    words: ["the", "soul", "that", "is", "within", "me", "no", "man", "can", "degrade"],
    id: "The soul that is within me no man can degrade.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["opera", "music", "bawdy", "cathedral"],
    words: ["the", "opera", "is", "to", "music", "what", "a", "bawdy", "house", "cathedral"],
    id: "The opera is to music what a bawdy house is to a cathedral.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "more", "gets", "craves"],
    words: ["knowledge", "is", "like", "money", "the", "more", "he", "gets", "craves"],
    id: "Knowledge is like money: the more he gets, the more he craves.",
    author: "Josh Billings",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "stop", "-", "wisdom"],
    words: ["you", "have", "to", "know", "when", "stop", "-", "that's", "wisdom"],
    id: "You have to know when to stop - that's wisdom.",
    author: "Hubert de Givenchy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "confounds", "evil", "enemy"],
    words: ["one", "that", "confounds", "good", "and", "evil", "is", "an", "enemy", "to"],
    id: "One that confounds good and evil is an enemy to good.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "short", "enjoy", "fullest"],
    words: ["life", "is", "short", "so", "enjoy", "it", "to", "the", "fullest"],
    id: "Life is short, so enjoy it to the fullest.",
    author: "John Walters",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "leave", "till", "which"],
    words: ["never", "leave", "that", "till", "tomorrow", "which", "you", "can", "do", "today"],
    id: "Never leave that till tomorrow which you can do today.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["pay", "price", "success", "enjoy"],
    words: ["you", "do", "not", "pay", "the", "price", "of", "success", "enjoy"],
    id: "You do not pay the price of success, you enjoy the price of success.",
    author: "Zig Ziglar",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "spanish", "little", "italian"],
    words: ["i", "don't", "speak", "spanish", "a", "little", "of", "italian", "but", "no"],
    id: "I don't speak Spanish, I speak a little of Italian but no Spanish.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["camera", "tool", "how", "without"],
    words: ["a", "camera", "is", "tool", "for", "learning", "how", "to", "see", "without"],
    id: "A camera is a tool for learning how to see without a camera.",
    author: "Dorothea Lange",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["imagination", "without", "wings", "feet"],
    words: ["he", "who", "has", "imagination", "without", "learning", "wings", "but", "no", "feet"],
    id: "He who has imagination without learning has wings but no feet.",
    author: "Joseph Joubert",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["make", "films", "easy", "hard"],
    words: ["learning", "to", "make", "films", "is", "very", "easy", "what", "about", "hard"],
    id: "Learning to make films is very easy. Learning what to make films about is very hard.",
    author: "George Lucas",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["act", "on", "since", "wish"],
    words: ["let", "us", "act", "on", "what", "we", "have", "since", "not", "wish"],
    id: "Let us act on what we have, since we have not what we wish.",
    author: "John Henry Newman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["forgiveness", "faith", "keep", "reviving"],
    words: ["forgiveness", "is", "like", "faith", "you", "have", "to", "keep", "reviving", "it"],
    id: "Forgiveness is like faith. You have to keep reviving it.",
    author: "Mason Cooley",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "flies", "arrow", "-"],
    words: ["time", "flies", "like", "an", "arrow", "-", "but", "fruit", "a", "banana"],
    id: "Time flies like an arrow - but fruit flies like a banana.",
    author: "Terry Wogan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "found", "truth", "rather"],
    words: ["say", "not", "i", "have", "found", "the", "truth", "but", "rather", "a"],
    id: "Say not, 'I have found the truth,' but rather, 'I have found a truth.'",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["time", "which", "fire", "burn"],
    words: ["time", "is", "the", "school", "in", "which", "we", "learn", "fire", "burn"],
    id: "Time is the school in which we learn, time is the fire in which we burn.",
    author: "Delmore Schwartz",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "will", "thank", "god"],
    words: ["i", "will", "thank", "god", "for", "the", "day", "and", "moment", "have"],
    id: "I will thank God for the day and the moment I have.",
    author: "Jim Valvano",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["successful", "your", "heart", "business"],
    words: ["to", "be", "successful", "you", "have", "your", "heart", "in", "business", "and"],
    id: "To be successful, you have to have your heart in your business and your business in your heart.",
    author: "Thomas J. Watson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["another", "person", "face", "god"],
    words: ["to", "love", "another", "person", "is", "see", "the", "face", "of", "god"],
    id: "To love another person is to see the face of God.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["knowledge", "god", "far", "from"],
    words: ["the", "knowledge", "of", "god", "is", "very", "far", "from", "love", "him"],
    id: "The knowledge of God is very far from the love of Him.",
    author: "Blaise Pascal",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["never", "companion", "casts", "shade"],
    words: ["never", "have", "a", "companion", "that", "casts", "you", "in", "the", "shade"],
    id: "Never have a companion that casts you in the shade.",
    author: "Baltasar Gracian",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["mother's", "peace", "acquired", "deserved"],
    words: ["mother's", "love", "is", "peace", "it", "need", "not", "be", "acquired", "deserved"],
    id: "Mother's love is peace. It need not be acquired, it need not be deserved.",
    author: "Erich Fromm",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["will", "springs", "from", "knowledge"],
    words: ["the", "will", "to", "do", "springs", "from", "knowledge", "that", "we", "can"],
    id: "The will to do springs from the knowledge that we can do.",
    author: "James Allen",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["patience", "takes", "time", "brand"],
    words: ["people", "need", "patience", "it", "takes", "time", "to", "build", "a", "brand"],
    id: "People need patience. It takes time to build a brand.",
    author: "Carmen Busquets",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["able", "how", "much", "little"],
    words: ["to", "be", "able", "say", "how", "much", "love", "is", "but", "little"],
    id: "To be able to say how much love, is love but little.",
    author: "Petrarch",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "cannot", "sleep", "dined"],
    words: ["one", "cannot", "think", "well", "love", "sleep", "if", "has", "not", "dined"],
    id: "One cannot think well, love well, sleep well, if one has not dined well.",
    author: "Virginia Woolf",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "pain", "enjoyment", "anesthetic"],
    words: ["life", "is", "pain", "and", "the", "enjoyment", "of", "love", "an", "anesthetic"],
    id: "Life is pain and the enjoyment of love is an anesthetic.",
    author: "Cesare Pavese",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["must", "first", "lot", "patience"],
    words: ["you", "must", "first", "have", "a", "lot", "of", "patience", "to", "learn"],
    id: "You must first have a lot of patience to learn to have patience.",
    author: "Stanislaw Jerzy Lec",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["life", "journey", "chapter", "interesting"],
    words: ["see", "life", "is", "a", "journey", "and", "every", "chapter", "very", "interesting"],
    id: "See, life is a journey and every chapter is very interesting.",
    author: "Zeenat Aman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["books", "led", "others", "madness"],
    words: ["books", "have", "led", "some", "to", "learning", "and", "others", "madness"],
    id: "Books have led some to learning and others to madness.",
    author: "Petrarch",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["movies", "fun", "cure", "cancer"],
    words: ["movies", "are", "fun", "but", "they're", "not", "a", "cure", "for", "cancer"],
    id: "Movies are fun, but they're not a cure for cancer.",
    author: "Warren Beatty",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["lifestyle", "pay", "life", "pays"],
    words: ["a", "lifestyle", "is", "what", "you", "pay", "for", "life", "pays"],
    id: "A lifestyle is what you pay for; a life is what pays you.",
    author: "Thomas Leonard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["forgiveness", "giving", "receiving", "life"],
    words: ["forgiveness", "is", "the", "giving", "and", "so", "receiving", "of", "life"],
    id: "Forgiveness is the giving, and so the receiving, of life.",
    author: "George MacDonald",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["nobody", "got", "by", "living"],
    words: ["nobody", "got", "where", "they", "are", "today", "by", "living", "for", "tomorrow"],
    id: "Nobody got where they are today by living for tomorrow.",
    author: "Tom Wilson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["prosperity", "joyful", "adversity", "consider"],
    words: ["in", "the", "day", "of", "prosperity", "be", "joyful", "but", "adversity", "consider"],
    id: "In the day of prosperity be joyful, but in the day of adversity consider.",
    author: "King Solomon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "once", "stop", "growing"],
    words: ["i", "think", "once", "you", "stop", "learning", "you're", "going", "to", "growing"],
    id: "I think once you stop learning, you're going to stop growing.",
    author: "Sean McVay",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["way", "life", "many", "things"],
    words: ["the", "way", "to", "know", "life", "is", "love", "many", "things"],
    id: "The way to know life is to love many things.",
    author: "Vincent Van Gogh",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["when", "i", "become", "might"],
    words: ["when", "i", "let", "go", "of", "what", "am", "become", "might", "be"],
    id: "When I let go of what I am, I become what I might be.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["wisdom", "authority", "makes", "law"],
    words: ["it", "is", "not", "wisdom", "but", "authority", "that", "makes", "a", "law"],
    id: "It is not wisdom but Authority that makes a law.",
    author: "Thomas Hobbes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["movies", "expensive", "form", "therapy"],
    words: ["movies", "are", "like", "an", "expensive", "form", "of", "therapy", "for", "me"],
    id: "Movies are like an expensive form of therapy for me.",
    author: "Tim Burton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["i'll", "back", "take", "owe"],
    words: ["i'll", "go", "back", "and", "take", "what", "the", "people", "owe", "me"],
    id: "I'll go back and take what the people owe me.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["foul", "fair", "i", "seen"],
    words: ["so", "foul", "and", "fair", "a", "day", "i", "have", "not", "seen"],
    id: "So foul and fair a day I have not seen.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["trace", "effects", "their", "causes"],
    words: ["happy", "is", "he", "who", "can", "trace", "effects", "to", "their", "causes"],
    id: "Happy is he who can trace effects to their causes.",
    author: "Virgil",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["most", "approval", "get", "least"],
    words: ["people", "who", "want", "the", "most", "approval", "get", "least", "and", "need"],
    id: "People who want the most approval get the least and people who need approval the least get the most.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["what's", "use", "happiness", "can't"],
    words: ["what's", "the", "use", "of", "happiness", "it", "can't", "buy", "you", "money"],
    id: "What's the use of happiness? It can't buy you money.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["begets", "rules", "same", "all"],
    words: ["love", "begets", "knows", "no", "rules", "this", "is", "same", "for", "all"],
    id: "Love begets love, love knows no rules, this is same for all.",
    author: "Virgil",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["worthless", "live", "only", "worth"],
    words: ["worthless", "people", "live", "only", "to", "eat", "and", "drink", "of", "worth"],
    id: "Worthless people live only to eat and drink; people of worth eat and drink only to live.",
    author: "Socrates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["unfortunately", "my", "family", "writer"],
    words: ["unfortunately", "for", "my", "family", "they", "have", "a", "writer", "in", "the"],
    id: "Unfortunately for my family, they have a writer in the family.",
    author: "Isabel Allende",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["affect", "quality", "highest", "arts"],
    words: ["to", "affect", "the", "quality", "of", "day", "that", "is", "highest", "arts"],
    id: "To affect the quality of the day, that is the highest of arts.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["keep", "half", "wisdom", "happiness"],
    words: ["keep", "well", "that", "is", "the", "half", "of", "wisdom", "and", "happiness"],
    id: "Keep well; that is the half of wisdom and of happiness.",
    author: "Emile Zola",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["thing", "fear", "death", "certain"],
    words: ["do", "the", "thing", "we", "fear", "and", "death", "of", "is", "certain"],
    id: "Do the thing we fear, and death of fear is certain.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["only", "way", "friend", "one"],
    words: ["the", "only", "way", "to", "have", "a", "friend", "is", "be", "one"],
    id: "The only way to have a friend is to be one.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["tell", "admires", "i", "will"],
    words: ["tell", "me", "who", "admires", "and", "loves", "you", "i", "will", "are"],
    id: "Tell me who admires and loves you, and I will tell you who you are.",
    author: "Antoine de Saint-Exupery",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "tell", "meaning", "cross"],
    words: ["i", "want", "to", "tell", "people", "about", "the", "meaning", "of", "cross"],
    id: "I want to tell people about the meaning of the cross.",
    author: "Billy Graham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["i", "swim", "amazing", "kick"],
    words: ["i", "swim", "like", "a", "fish", "and", "have", "an", "amazing", "kick"],
    id: "I swim like a fish and I have an amazing kick.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["parting", "all", "heaven", "hell"],
    words: ["parting", "is", "all", "we", "know", "of", "heaven", "and", "need", "hell"],
    id: "Parting is all we know of heaven, and all we need of hell.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["death", "worst", "happen", "men"],
    words: ["death", "is", "not", "the", "worst", "that", "can", "happen", "to", "men"],
    id: "Death is not the worst that can happen to men.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["golf", "game", "shots", "bad"],
    words: ["golf", "is", "not", "a", "game", "of", "good", "shots", "it's", "bad"],
    id: "Golf is not a game of good shots. It's a game of bad shots.",
    author: "Ben Hogan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "great", "qualities", "forgiveness"],
    words: ["one", "of", "the", "great", "qualities", "we", "have", "is", "forgiveness"],
    id: "One of the great qualities we have is forgiveness.",
    author: "Buster Posey",
    grammar: null,
    word_count: 9,
    all_intersection_count: 5,
    new_words_count: 4,
  },
  {
    row_new_words: ["exist", "bad", "black", "white"],
    words: ["good", "can", "exist", "in", "bad", "and", "it's", "not", "black", "white"],
    id: "Good can exist in bad, and bad can exist in good. It's not black and white.",
    author: "Sarah Jeffery",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["city", "because", "our", "citizens"],
    words: ["this", "city", "is", "what", "it", "because", "our", "citizens", "are", "they"],
    id: "This City is what it is because our citizens are what they are.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["reward", "thing", "done", "having"],
    words: ["the", "reward", "of", "a", "thing", "well", "done", "is", "having", "it"],
    id: "The reward of a thing well done is having done it.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["man", "thinks", "all", "long"],
    words: ["a", "man", "is", "what", "he", "thinks", "about", "all", "day", "long"],
    id: "A man is what he thinks about all day long.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["can't", "because", "i'm", "parentheses"],
    words: ["if", "you", "can't", "hear", "me", "it's", "because", "i'm", "in", "parentheses"],
    id: "If you can't hear me, it's because I'm in parentheses.",
    author: "Steven Wright",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["oneself", "action", "another", "person"],
    words: ["to", "know", "oneself", "is", "study", "in", "action", "with", "another", "person"],
    id: "To know oneself is to study oneself in action with another person.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["how", "live", "time", "given"],
    words: ["know", "how", "to", "live", "the", "time", "that", "is", "given", "you"],
    id: "Know how to live the time that is given you.",
    author: "Dario Fo",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["matter", "temperature", "room", "always"],
    words: [
      "it",
      "doesn't",
      "matter",
      "what",
      "temperature",
      "the",
      "room",
      "is",
      "it's",
      "always",
    ],
    id: "It doesn't matter what temperature the room is, it's always room temperature.",
    author: "Steven Wright",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["one", "word", "ideal", "divine"],
    words: ["in", "one", "word", "this", "ideal", "is", "that", "you", "are", "divine"],
    id: "In one word, this ideal is that you are divine.",
    author: "Swami Vivekananda",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["times", "bad", "never", "comprise"],
    words: ["in", "good", "times", "and", "bad", "never", "comprise", "who", "you", "are"],
    id: "In good times and bad times, never comprise who you are.",
    author: "Cynthia Bailey",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["will", "win", "when", "fight"],
    words: ["he", "will", "win", "who", "knows", "when", "to", "fight", "and", "not"],
    id: "He will win who knows when to fight and when not to fight.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["understand", "universe", "control", "way"],
    words: ["if", "you", "understand", "the", "universe", "control", "it", "in", "a", "way"],
    id: "If you understand the universe, you control it, in a way.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["oh", "lord", "chastity", "yet"],
    words: ["oh", "lord", "give", "me", "chastity", "but", "do", "not", "it", "yet"],
    id: "Oh Lord, give me chastity, but do not give it yet.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["punishment", "cause", "makes", "martyr"],
    words: ["it", "is", "not", "the", "punishment", "but", "cause", "that", "makes", "martyr"],
    id: "It is not the punishment but the cause that makes the martyr.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["productivity", "responsibility", "worker", "manager"],
    words: [
      "the",
      "productivity",
      "of",
      "work",
      "is",
      "not",
      "responsibility",
      "worker",
      "but",
      "manager",
    ],
    id: "The productivity of work is not the responsibility of the worker but of the manager.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["titles", "nicknames", "nickname", "title"],
    words: ["titles", "are", "but", "nicknames", "and", "every", "nickname", "is", "a", "title"],
    id: "Titles are but nicknames, and every nickname is a title.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["millionth", "one", "percent", "anything"],
    words: ["we", "don't", "know", "a", "millionth", "of", "one", "percent", "about", "anything"],
    id: "We don't know a millionth of one percent about anything.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["look", "woman", "dress", "there"],
    words: ["look", "for", "the", "woman", "in", "dress", "if", "there", "is", "no"],
    id: "Look for the woman in the dress. If there is no woman, there is no dress.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["anything", "really", "attain", "after"],
    words: ["anything", "you", "really", "want", "can", "attain", "if", "go", "after", "it"],
    id: "Anything you really want, you can attain, if you really go after it.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 6,
    new_words_count: 4,
  },
  {
    row_new_words: ["great", "city", "confounded", "populous", "one"],
    words: ["a", "great", "city", "is", "not", "to", "be", "confounded", "with", "populous", "one"],
    id: "A great city is not to be confounded with a populous one.",
    author: "Aristotle",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["order", "understanding", "forgiveness", "compassion", "empathy"],
    words: [
      "in",
      "order",
      "to",
      "have",
      "understanding",
      "you",
      "need",
      "forgiveness",
      "compassion",
      "and",
      "empathy",
    ],
    id: "In order to have understanding, you need forgiveness, compassion, and empathy.",
    author: "Rooney Mara",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "repeat", "successes", "on", "things"],
    words: ["i", "do", "not", "like", "to", "repeat", "successes", "go", "on", "other", "things"],
    id: "I do not like to repeat successes, I like to go on to other things.",
    author: "Walt Disney",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "anything", "flimsy", "foundation", "friendship"],
    words: [
      "you",
      "can't",
      "build",
      "anything",
      "with",
      "a",
      "flimsy",
      "foundation",
      "friendship",
      "is",
      "the",
    ],
    id: "You can't build anything with a flimsy foundation. Friendship is the foundation.",
    author: "Hill Harper",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "actor", "or", "director", "friendship"],
    words: [
      "no",
      "one",
      "works",
      "with",
      "an",
      "actor",
      "or",
      "a",
      "director",
      "for",
      "friendship",
    ],
    id: "No one works with an actor or a director for friendship.",
    author: "Govinda",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["berlin", "-", "world", "our", "time"],
    words: ["people", "of", "berlin", "-", "the", "world", "this", "is", "our", "moment", "time"],
    id: "People of Berlin - people of the world - this is our moment. This is our time.",
    author: "Barack Obama",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["almost", "monday", "i", "charity", "thing"],
    words: [
      "almost",
      "every",
      "monday",
      "i",
      "have",
      "a",
      "charity",
      "thing",
      "like",
      "that",
      "do",
    ],
    id: "Almost every Monday I have a charity thing. I like that. I do.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["experience", "something", "get", "until", "after"],
    words: [
      "experience",
      "is",
      "something",
      "you",
      "don't",
      "get",
      "until",
      "just",
      "after",
      "need",
      "it",
    ],
    id: "Experience is something you don't get until just after you need it.",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["kids", "deserve", "right", "change", "world"],
    words: [
      "kids",
      "deserve",
      "the",
      "right",
      "to",
      "think",
      "that",
      "they",
      "can",
      "change",
      "world",
    ],
    id: "Kids deserve the right to think that they can change the world.",
    author: "Lois Lowry",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["others", "i", "should", "yourself", "first"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "love",
      "others",
      "i",
      "think",
      "should",
      "yourself",
      "first",
    ],
    id: "If you want to love others, I think you should love yourself first.",
    author: "RM",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "fight", "person", "different"],
    words: [
      "i",
      "never",
      "fight",
      "for",
      "the",
      "money",
      "but",
      "every",
      "person",
      "is",
      "different",
    ],
    id: "I never fight for the money, but every person is different.",
    author: "Khabib Nurmagomedov",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "hail", "chief", "nice", "ring"],
    words: ["i", "think", "hail", "to", "the", "chief", "has", "a", "nice", "ring", "it"],
    id: "I think 'Hail to the Chief' has a nice ring to it.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["secret", "ingredient", "meal", "also", "garlic"],
    words: [
      "the",
      "secret",
      "ingredient",
      "to",
      "every",
      "meal",
      "is",
      "love",
      "and",
      "also",
      "garlic",
    ],
    id: "The secret ingredient to every meal is love. And also garlic.",
    author: "Michael Sorrentino",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["publish", "data", "should", "share", "knowledge"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "publish",
      "data",
      "should",
      "do",
      "it",
      "share",
      "knowledge",
    ],
    id: "If you want to publish data, you should do it to share knowledge.",
    author: "Andrew Ng",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["since", "knowledge", "sorrow's", "spy", "safe"],
    words: [
      "since",
      "knowledge",
      "is",
      "but",
      "sorrow's",
      "spy",
      "it",
      "not",
      "safe",
      "to",
      "know",
    ],
    id: "Since knowledge is but sorrow's spy, It is not safe to know.",
    author: "William Davenant",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "real", "freedom", "without", "fail"],
    words: ["there", "can", "be", "no", "real", "freedom", "without", "the", "to", "fail"],
    id: "There can be no real freedom without the freedom to fail.",
    author: "Erich Fromm",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["chicago", "will", "chance", "sporting", "spirit"],
    words: [
      "chicago",
      "will",
      "give",
      "you",
      "a",
      "chance",
      "the",
      "sporting",
      "spirit",
      "is",
      "of",
    ],
    id: "Chicago will give you a chance. The sporting spirit is the spirit of Chicago.",
    author: "Lincoln Steffens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["richest", "which", "submits", "arbitration", "time"],
    words: [
      "the",
      "richest",
      "love",
      "is",
      "that",
      "which",
      "submits",
      "to",
      "arbitration",
      "of",
      "time",
    ],
    id: "The richest love is that which submits to the arbitration of time.",
    author: "Lawrence Durrell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "great", "changes", "old", "age"],
    words: ["it", "is", "not", "well", "to", "make", "great", "changes", "in", "old", "age"],
    id: "It is not well to make great changes in old age.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "marriage", "soul", "liable", "divorce"],
    words: [
      "friendship",
      "is",
      "the",
      "marriage",
      "of",
      "soul",
      "and",
      "this",
      "liable",
      "to",
      "divorce",
    ],
    id: "Friendship is the marriage of the soul, and this marriage is liable to divorce.",
    author: "Voltaire",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["artist", "cannot", "fail", "success", "one"],
    words: ["an", "artist", "cannot", "fail", "it", "is", "a", "success", "to", "be", "one"],
    id: "An artist cannot fail; it is a success to be one.",
    author: "Charles Horton Cooley",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "mirror", "get", "back", "out"],
    words: ["life", "is", "a", "mirror", "and", "you", "get", "back", "what", "give", "out"],
    id: "Life is a mirror and you get back what you give out.",
    author: "Tess Daly",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["writing", "nothing", "more", "cleverly", "each"],
    words: ["writing", "is", "learning", "to", "say", "nothing", "more", "cleverly", "each", "day"],
    id: "Writing is learning to say nothing, more cleverly each day.",
    author: "William Allingham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "difference", "temperature", "room", "always"],
    words: [
      "it",
      "doesn't",
      "make",
      "a",
      "difference",
      "what",
      "temperature",
      "room",
      "is",
      "it's",
      "always",
    ],
    id: "It doesn't make a difference what temperature a room is, it's always room temperature.",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["had", "million", "shakespeares", "could", "write"],
    words: [
      "if",
      "you",
      "had",
      "a",
      "million",
      "shakespeares",
      "could",
      "they",
      "write",
      "like",
      "monkey",
    ],
    id: "If you had a million Shakespeares, could they write like a monkey?",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["ignorance", "knowledge", "which", "mother", "wonder"],
    words: [
      "it",
      "is",
      "not",
      "ignorance",
      "but",
      "knowledge",
      "which",
      "the",
      "mother",
      "of",
      "wonder",
    ],
    id: "It is not ignorance but knowledge which is the mother of wonder.",
    author: "Joseph Wood Krutch",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["at", "end", "life", "too", "short"],
    words: ["at", "the", "end", "of", "day", "life", "is", "too", "short", "to", "worry"],
    id: "At the end of the day, life is too short to worry.",
    author: "Lauren Ash",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "believe", "marriage", "family", "children"],
    words: [
      "i",
      "don't",
      "know",
      "if",
      "believe",
      "in",
      "marriage",
      "family",
      "love",
      "and",
      "children",
    ],
    id: "I don't know if I believe in marriage. I believe in family, love and children.",
    author: "Penelope Cruz",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "make", "always", "been", "will"],
    words: ["life", "is", "what", "we", "make", "it", "always", "has", "been", "will", "be"],
    id: "Life is what we make it, always has been, always will be.",
    author: "Grandma Moses",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["each", "one", "outlet", "god", "inlet"],
    words: ["each", "one", "of", "us", "is", "an", "outlet", "to", "god", "and", "inlet"],
    id: "Each one of us is an outlet to God and an inlet to God.",
    author: "Ernest Holmes",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["here", "add", "life", "get", "from"],
    words: ["we", "are", "here", "to", "add", "what", "can", "life", "not", "get", "from"],
    id: "We are here to add what we can to life, not to get what we can from life.",
    author: "William Osler",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["might", "as", "die", "on", "living"],
    words: ["we", "might", "as", "well", "die", "to", "go", "on", "living", "like", "this"],
    id: "We might as well die as to go on living like this.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "encourage", "themselves", "express", "however"],
    words: [
      "i",
      "want",
      "to",
      "encourage",
      "people",
      "be",
      "themselves",
      "and",
      "express",
      "however",
      "they",
    ],
    id: "I want to encourage people to be themselves and express themselves however they want.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "concerned", "succeeding", "complete", "freedom"],
    words: [
      "when",
      "you're",
      "not",
      "concerned",
      "with",
      "succeeding",
      "you",
      "can",
      "work",
      "complete",
      "freedom",
    ],
    id: "When you're not concerned with succeeding, you can work with complete freedom.",
    author: "Larry David",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["meaning", "purpose", "life", "empty", "without"],
    words: [
      "work",
      "gives",
      "you",
      "meaning",
      "and",
      "purpose",
      "life",
      "is",
      "empty",
      "without",
      "it",
    ],
    id: "Work gives you meaning and purpose and life is empty without it.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["enemies", "freedom", "argue", "shout", "shoot"],
    words: [
      "the",
      "enemies",
      "of",
      "freedom",
      "do",
      "not",
      "argue",
      "they",
      "shout",
      "and",
      "shoot",
    ],
    id: "The enemies of freedom do not argue; they shout and they shoot.",
    author: "William Inge",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["use", "language", "will", "never", "anything"],
    words: [
      "use",
      "what",
      "language",
      "you",
      "will",
      "can",
      "never",
      "say",
      "anything",
      "but",
      "are",
    ],
    id: "Use what language you will, you can never say anything but what you are.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["we'll", "back", "moon", "by", "anything"],
    words: ["we'll", "go", "back", "to", "the", "moon", "by", "not", "learning", "anything", "new"],
    id: "We'll go back to the moon by not learning anything new.",
    author: "Burt Rutan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "most", "yourself", "all", "there"],
    words: ["make", "the", "most", "of", "yourself", "for", "that", "is", "all", "there", "you"],
    id: "Make the most of yourself, for that is all there is of you.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["music", "by", "reading", "making", "mail"],
    words: [
      "learning",
      "music",
      "by",
      "reading",
      "about",
      "it",
      "is",
      "like",
      "making",
      "love",
      "mail",
    ],
    id: "Learning music by reading about it is like making love by mail.",
    author: "Luciano Pavarotti",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "anyone", "asks", "chance", "pride"],
    words: ["all", "anyone", "asks", "for", "is", "a", "chance", "to", "work", "with", "pride"],
    id: "All anyone asks for is a chance to work with pride.",
    author: "W. Edwards Deming",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["sound", "music", "50%", "entertainment", "movie"],
    words: [
      "the",
      "sound",
      "and",
      "music",
      "are",
      "50%",
      "of",
      "entertainment",
      "in",
      "a",
      "movie",
    ],
    id: "The sound and music are 50% of the entertainment in a movie.",
    author: "George Lucas",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["was", "great", "experience", "james", "stewart"],
    words: [
      "it",
      "was",
      "a",
      "great",
      "learning",
      "experience",
      "to",
      "work",
      "with",
      "james",
      "stewart",
    ],
    id: "It was a great learning experience to work with James Stewart.",
    author: "Julie Adams",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["any", "kind", "crisis", "wakes", "up"],
    words: ["any", "kind", "of", "crisis", "can", "be", "good", "it", "wakes", "you", "up"],
    id: "Any kind of crisis can be good. It wakes you up.",
    author: "Ryan Reynolds",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["may", "there", "always", "peace", "happiness"],
    words: [
      "may",
      "there",
      "always",
      "be",
      "peace",
      "love",
      "and",
      "happiness",
      "in",
      "every",
      "house",
    ],
    id: "May there always be peace, love and happiness in every house.",
    author: "Islom Karimov",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "patience", "skill", "wish", "had"],
    words: ["i", "think", "patience", "is", "a", "skill", "and", "wish", "had", "it"],
    id: "I think patience is a skill and I wish I had it.",
    author: "David Duchovny",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "prefer", "video", "than", "play"],
    words: ["i", "prefer", "to", "be", "in", "a", "video", "than", "play", "with", "it"],
    id: "I prefer to be in a video than to play with it.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "one", "must", "first", "able"],
    words: ["to", "say", "i", "love", "you", "one", "must", "first", "be", "able", "the"],
    id: "To say 'I love you' one must first be able to say the 'I.'",
    author: "Ayn Rand",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["silence", "golden", "when", "can't", "answer"],
    words: [
      "silence",
      "is",
      "golden",
      "when",
      "you",
      "can't",
      "think",
      "of",
      "a",
      "good",
      "answer",
    ],
    id: "Silence is golden when you can't think of a good answer.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "problem", "solved", "reality", "experienced"],
    words: [
      "life",
      "is",
      "not",
      "a",
      "problem",
      "to",
      "be",
      "solved",
      "but",
      "reality",
      "experienced",
    ],
    id: "Life is not a problem to be solved, but a reality to be experienced.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["done", "anything", "been", "truly", "idle"],
    words: [
      "to",
      "have",
      "done",
      "anything",
      "just",
      "for",
      "money",
      "is",
      "been",
      "truly",
      "idle",
    ],
    id: "To have done anything just for money is to have been truly idle.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["product", "quantum", "fluctuations", "early", "universe"],
    words: [
      "we",
      "are",
      "the",
      "product",
      "of",
      "quantum",
      "fluctuations",
      "in",
      "very",
      "early",
      "universe",
    ],
    id: "We are the product of quantum fluctuations in the very early universe.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["business", "up", "big", "enough", "respectable"],
    words: [
      "if",
      "you",
      "can",
      "build",
      "a",
      "business",
      "up",
      "big",
      "enough",
      "it's",
      "respectable",
    ],
    id: "If you can build a business up big enough, it's respectable.",
    author: "Will Rogers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "grateful", "my", "thanksgiving", "perpetual"],
    words: [
      "i",
      "am",
      "grateful",
      "for",
      "what",
      "and",
      "have",
      "my",
      "thanksgiving",
      "is",
      "perpetual",
    ],
    id: "I am grateful for what I am and have. My thanksgiving is perpetual.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["sweetest", "all", "sounds", "voice", "woman"],
    words: ["the", "sweetest", "of", "all", "sounds", "is", "that", "voice", "woman", "we", "love"],
    id: "The sweetest of all sounds is that of the voice of the woman we love.",
    author: "Jean de la Bruyere",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["health", "state", "which", "medicine", "nothing"],
    words: [
      "health",
      "is",
      "the",
      "state",
      "about",
      "which",
      "medicine",
      "has",
      "nothing",
      "to",
      "say",
    ],
    id: "Health is the state about which medicine has nothing to say.",
    author: "W. H. Auden",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["win", "always", "feeling", "whole", "team"],
    words: ["to", "win", "is", "always", "good", "for", "the", "feeling", "of", "whole", "team"],
    id: "To win is always good for the feeling of the whole team.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["tell", "deliberate", "lies", "sometimes", "evasive"],
    words: [
      "you",
      "don't",
      "tell",
      "deliberate",
      "lies",
      "but",
      "sometimes",
      "have",
      "to",
      "be",
      "evasive",
    ],
    id: "You don't tell deliberate lies, but sometimes you have to be evasive.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["education", "filling", "pail", "lighting", "fire"],
    words: [
      "education",
      "is",
      "not",
      "the",
      "filling",
      "of",
      "a",
      "pail",
      "but",
      "lighting",
      "fire",
    ],
    id: "Education is not the filling of a pail, but the lighting of a fire.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["manager", "responsible", "application", "performance", "knowledge"],
    words: [
      "a",
      "manager",
      "is",
      "responsible",
      "for",
      "the",
      "application",
      "and",
      "performance",
      "of",
      "knowledge",
    ],
    id: "A manager is responsible for the application and performance of knowledge.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["lot", "afraid", "heights", "i'm", "widths"],
    words: ["a", "lot", "of", "people", "are", "afraid", "heights", "not", "me", "i'm", "widths"],
    id: "A lot of people are afraid of heights. Not me, I'm afraid of widths.",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["nothing", "stopping", "from", "doing", "i"],
    words: ["nothing", "is", "stopping", "me", "from", "doing", "what", "i", "love", "to", "do"],
    id: "Nothing is stopping me from doing what I love to do.",
    author: "The Weeknd",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["sun", "always", "shine", "west", "virginia"],
    words: [
      "the",
      "sun",
      "doesn't",
      "always",
      "shine",
      "in",
      "west",
      "virginia",
      "but",
      "people",
      "do",
    ],
    id: "The sun doesn't always shine in West Virginia, but the people do.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["many", "alive", "touch", "miracle", "being"],
    words: [
      "many",
      "people",
      "are",
      "alive",
      "but",
      "don't",
      "touch",
      "the",
      "miracle",
      "of",
      "being",
    ],
    id: "Many people are alive but don't touch the miracle of being alive.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["teams", "could", "make", "trouble", "win"],
    words: ["the", "other", "teams", "could", "make", "trouble", "for", "us", "if", "they", "win"],
    id: "The other teams could make trouble for us if they win.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["cast", "votes", "decide", "election", "count"],
    words: [
      "the",
      "people",
      "who",
      "cast",
      "votes",
      "don't",
      "decide",
      "an",
      "election",
      "count",
      "do",
    ],
    id: "The people who cast the votes don't decide an election, the people who count the votes do.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["way", "bring", "change", "proactive", "active"],
    words: [
      "the",
      "way",
      "to",
      "bring",
      "about",
      "change",
      "is",
      "be",
      "proactive",
      "and",
      "active",
    ],
    id: "The way to bring about change is to be proactive and active.",
    author: "Octavia Spencer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["kindness", "language", "which", "deaf", "blind"],
    words: [
      "kindness",
      "is",
      "the",
      "language",
      "which",
      "deaf",
      "can",
      "hear",
      "and",
      "blind",
      "see",
    ],
    id: "Kindness is the language which the deaf can hear and the blind can see.",
    author: "Unknown",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "fight", "cannot", "will", "victorious"],
    words: [
      "he",
      "who",
      "knows",
      "when",
      "can",
      "fight",
      "and",
      "cannot",
      "will",
      "be",
      "victorious",
    ],
    id: "He who knows when he can fight and when he cannot, will be victorious.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["hit", "i", "enjoy", "movie", "personally"],
    words: ["a", "hit", "for", "me", "is", "if", "i", "enjoy", "the", "movie", "personally"],
    id: "A hit for me is if I enjoy the movie, if I personally enjoy the movie.",
    author: "Ridley Scott",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "art", "when", "i'm", "life"],
    words: ["i", "don't", "think", "about", "art", "when", "i'm", "working", "try", "to", "life"],
    id: "I don't think about art when I'm working. I try to think about life.",
    author: "Jean-Michel Basquiat",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["cinema", "matter", "what's", "frame", "out"],
    words: ["cinema", "is", "a", "matter", "of", "what's", "in", "the", "frame", "and", "out"],
    id: "Cinema is a matter of what's in the frame and what's out.",
    author: "Martin Scorsese",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["prayer", "change", "god", "changes", "prays"],
    words: [
      "prayer",
      "does",
      "not",
      "change",
      "god",
      "but",
      "it",
      "changes",
      "him",
      "who",
      "prays",
    ],
    id: "Prayer does not change God, but it changes him who prays.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["fortune", "favors", "elated", "frowns", "despond"],
    words: [
      "if",
      "fortune",
      "favors",
      "you",
      "do",
      "not",
      "be",
      "elated",
      "she",
      "frowns",
      "despond",
    ],
    id: "If fortune favors you do not be elated; if she frowns do not despond.",
    author: "Ausonius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "time", "dislike", "gossip", "when"],
    words: [
      "the",
      "only",
      "time",
      "people",
      "dislike",
      "gossip",
      "is",
      "when",
      "you",
      "about",
      "them",
    ],
    id: "The only time people dislike gossip is when you gossip about them.",
    author: "Will Rogers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "buddha", "enough", "many", "buddhas"],
    words: ["one", "buddha", "is", "not", "enough", "we", "need", "to", "have", "many", "buddhas"],
    id: "One Buddha is not enough; we need to have many Buddhas.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "change", "bad", "sometimes", "done"],
    words: ["all", "change", "is", "bad", "but", "sometimes", "it", "has", "to", "be", "done"],
    id: "All change is bad. But sometimes it has to be done.",
    author: "P. J. O'Rourke",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "much", "hate", "wasting", "time"],
    words: ["i", "have", "so", "much", "that", "want", "to", "do", "hate", "wasting", "time"],
    id: "I have so much that I want to do. I hate wasting time.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["lot", "movies", "life", "mine", "slice"],
    words: ["a", "lot", "of", "movies", "are", "about", "life", "mine", "like", "slice", "cake"],
    id: "A lot of movies are about life, mine are like a slice of cake.",
    author: "Alfred Hitchcock",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["bottom", "line", "fun", "enjoy", "life"],
    words: ["the", "bottom", "line", "is", "to", "have", "fun", "and", "enjoy", "life"],
    id: "The bottom line is to have fun and enjoy life.",
    author: "Rekha",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "come", "fork", "road", "take"],
    words: ["when", "you", "come", "to", "a", "fork", "in", "the", "road", "take", "it"],
    id: "When you come to a fork in the road, take it.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["absence", "justice", "sovereignty", "organized", "robbery"],
    words: [
      "in",
      "the",
      "absence",
      "of",
      "justice",
      "what",
      "is",
      "sovereignty",
      "but",
      "organized",
      "robbery",
    ],
    id: "In the absence of justice, what is sovereignty but organized robbery?",
    author: "Saint Augustine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "absence", "failure", "persistence", "through"],
    words: [
      "success",
      "is",
      "not",
      "the",
      "absence",
      "of",
      "failure",
      "it's",
      "persistence",
      "through",
    ],
    id: "Success is not the absence of failure; it's the persistence through failure.",
    author: "Aisha Tyler",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["sometimes", "your", "joy", "source", "smile"],
    words: ["sometimes", "your", "joy", "is", "the", "source", "of", "smile", "but", "can", "be"],
    id: "Sometimes your joy is the source of your smile, but sometimes your smile can be the source of your joy.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["gravitation", "far", "understandable", "terms", "phenomena"],
    words: [
      "gravitation",
      "is",
      "so",
      "far",
      "not",
      "understandable",
      "in",
      "terms",
      "of",
      "other",
      "phenomena",
    ],
    id: "Gravitation is, so far, not understandable in terms of other phenomena.",
    author: "Richard P. Feynman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["better", "grace", "i", "more", "natural"],
    words: ["he", "does", "it", "with", "better", "grace", "but", "i", "do", "more", "natural"],
    id: "He does it with better grace, but I do it more natural.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "short", "up", "make", "sweet"],
    words: ["life", "is", "short", "and", "it", "up", "to", "you", "make", "sweet"],
    id: "Life is short, and it is up to you to make it sweet.",
    author: "Sarah Louise Delany",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["lack", "realism", "vision", "costs", "credibility"],
    words: [
      "a",
      "lack",
      "of",
      "realism",
      "in",
      "the",
      "vision",
      "today",
      "costs",
      "credibility",
      "tomorrow",
    ],
    id: "A lack of realism in the vision today costs credibility tomorrow.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["more", "agreeable", "power", "than", "receive"],
    words: [
      "it",
      "is",
      "more",
      "agreeable",
      "to",
      "have",
      "the",
      "power",
      "give",
      "than",
      "receive",
    ],
    id: "It is more agreeable to have the power to give than to receive.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["common", "thing", "somewhere", "box", "there"],
    words: ["to", "me", "it's", "a", "common", "thing", "go", "somewhere", "and", "box", "there"],
    id: "To me, it's a common thing to go somewhere and box there.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "most", "glorified", "when", "satisfied"],
    words: ["god", "is", "most", "glorified", "in", "us", "when", "we", "are", "satisfied", "him"],
    id: "God is most glorified in us when we are most satisfied in Him.",
    author: "John Piper",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["advice", "cannot", "wisdom", "profit", "by"],
    words: ["we", "give", "advice", "but", "cannot", "the", "wisdom", "to", "profit", "by", "it"],
    id: "We give advice, but we cannot give the wisdom to profit by it.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["game", "two", "play", "both", "win"],
    words: ["love", "is", "a", "game", "that", "two", "can", "play", "and", "both", "win"],
    id: "Love is a game that two can play and both win.",
    author: "Eva Gabor",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["vulgar", "coming", "from", "i", "talk"],
    words: ["it's", "vulgar", "coming", "from", "where", "i", "do", "to", "talk", "about", "money"],
    id: "It's vulgar, coming from where I do, to talk about money.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "my", "family", "all", "humanity"],
    words: ["i", "don't", "want", "to", "just", "love", "my", "family", "all", "of", "humanity"],
    id: "I don't want to just love my family; I want to love all of humanity.",
    author: "Alejandro Jodorowsky",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "enough", "memory", "successful", "liar"],
    words: ["no", "man", "has", "a", "good", "enough", "memory", "to", "be", "successful", "liar"],
    id: "No man has a good enough memory to be a successful liar.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["more", "one", "mistress", "ready", "hate"],
    words: ["the", "more", "one", "loves", "a", "mistress", "is", "ready", "to", "hate", "her"],
    id: "The more one loves a mistress, the more one is ready to hate her.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["cannot", "escape", "responsibility", "by", "evading"],
    words: [
      "you",
      "cannot",
      "escape",
      "the",
      "responsibility",
      "of",
      "tomorrow",
      "by",
      "evading",
      "it",
      "today",
    ],
    id: "You cannot escape the responsibility of tomorrow by evading it today.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "ridiculous", "through", "as", "pretend"],
    words: [
      "we",
      "are",
      "never",
      "so",
      "ridiculous",
      "through",
      "what",
      "as",
      "pretend",
      "to",
      "be",
    ],
    id: "We are never so ridiculous through what we are as through what we pretend to be.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "only", "loved", "also", "told"],
    words: ["i", "like", "not", "only", "to", "be", "loved", "but", "also", "told", "am"],
    id: "I like not only to be loved, but also to be told I am loved.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "thank", "everyone", "made", "necessary"],
    words: [
      "i",
      "just",
      "want",
      "to",
      "thank",
      "everyone",
      "who",
      "made",
      "this",
      "day",
      "necessary",
    ],
    id: "I just want to thank everyone who made this day necessary.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["reason", "why", "kills", "more", "than"],
    words: [
      "the",
      "reason",
      "why",
      "worry",
      "kills",
      "more",
      "people",
      "than",
      "work",
      "is",
      "that",
    ],
    id: "The reason why worry kills more people than work is that more people worry than work.",
    author: "Robert Frost",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "my", "birthday", "occasion", "fanfare"],
    words: ["i", "don't", "like", "my", "birthday", "to", "be", "an", "occasion", "for", "fanfare"],
    id: "I don't like my birthday to be an occasion for fanfare.",
    author: "Simi Garewal",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["enjoy", "meetings", "should", "charge", "anything"],
    words: [
      "people",
      "who",
      "enjoy",
      "meetings",
      "should",
      "not",
      "be",
      "in",
      "charge",
      "of",
      "anything",
    ],
    id: "People who enjoy meetings should not be in charge of anything.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["imponderable", "world", "greater", "than", "handle"],
    words: [
      "what",
      "is",
      "imponderable",
      "in",
      "the",
      "world",
      "greater",
      "than",
      "we",
      "can",
      "handle",
    ],
    id: "What is imponderable in the world is greater than what we can handle.",
    author: "Pierre Teilhard de Chardin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["enjoy", "failure", "from", "never", "success"],
    words: ["enjoy", "failure", "and", "learn", "from", "it", "you", "can", "never", "success"],
    id: "Enjoy failure and learn from it. You can never learn from success.",
    author: "James Dyson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "found", "life", "will", "back"],
    words: ["i", "have", "found", "that", "if", "you", "love", "life", "will", "back"],
    id: "I have found that if you love life, life will love you back.",
    author: "Arthur Rubinstein",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["starts", "failure", "first", "beginning", "education"],
    words: [
      "learning",
      "starts",
      "with",
      "failure",
      "the",
      "first",
      "is",
      "beginning",
      "of",
      "education",
    ],
    id: "Learning starts with failure; the first failure is the beginning of education.",
    author: "John Hersey",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["sport's", "patience", "keep", "matter", "outcome"],
    words: [
      "sport's",
      "about",
      "patience",
      "and",
      "keep",
      "working",
      "no",
      "matter",
      "the",
      "outcome",
    ],
    id: "Sport's about patience and keep working no matter the outcome.",
    author: "Marc Gasol",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "knowledge", "lives", "by", "acting"],
    words: ["a", "man", "of", "knowledge", "lives", "by", "acting", "not", "thinking", "about"],
    id: "A man of knowledge lives by acting, not by thinking about acting.",
    author: "Carlos Castaneda",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["hollywood", "marriage", "success", "outlasts", "milk"],
    words: ["in", "hollywood", "a", "marriage", "is", "success", "if", "it", "outlasts", "milk"],
    id: "In Hollywood a marriage is a success if it outlasts milk.",
    author: "Rita Rudner",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["height", "folly", "only", "wise", "one"],
    words: ["it's", "the", "height", "of", "folly", "to", "want", "be", "only", "wise", "one"],
    id: "It's the height of folly to want to be the only wise one.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["imagine", "i", "would", "could", "all"],
    words: ["can", "you", "imagine", "what", "i", "would", "do", "if", "could", "all"],
    id: "Can you imagine what I would do if I could do all I can?",
    author: "Jeff Rich",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["society", "men", "born", "rule", "advise"],
    words: ["in", "every", "society", "some", "men", "are", "born", "to", "rule", "and", "advise"],
    id: "In every society some men are born to rule, and some to advise.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["proverb", "wisdom", "many", "wit", "one"],
    words: ["a", "proverb", "is", "the", "wisdom", "of", "many", "and", "wit", "one"],
    id: "A proverb is the wisdom of many and the wit of one.",
    author: "Lord John Russell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["children", "anchors", "hold", "mother", "life"],
    words: ["children", "are", "the", "anchors", "that", "hold", "a", "mother", "to", "life"],
    id: "Children are the anchors that hold a mother to life.",
    author: "Sophocles",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["charming", "woman", "follow", "crowd", "herself"],
    words: ["a", "charming", "woman", "doesn't", "follow", "the", "crowd", "she", "is", "herself"],
    id: "A charming woman... doesn't follow the crowd. She is herself.",
    author: "Loretta Young",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "because", "used", "living", "loving"],
    words: ["we", "love", "life", "not", "because", "are", "used", "to", "living", "but", "loving"],
    id: "We love life, not because we are used to living but because we are used to loving.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "failure", "my", "concern", "his"],
    words: ["the", "success", "and", "failure", "are", "not", "my", "concern", "but", "his"],
    id: "The success and the failure are not my concern, but His.",
    author: "Joseph Barber Lightfoot",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["because", "things", "difficult", "dare", "venture"],
    words: [
      "it's",
      "not",
      "because",
      "things",
      "are",
      "difficult",
      "that",
      "we",
      "dare",
      "venture",
      "they",
    ],
    id: "It's not because things are difficult that we dare not venture. It's because we dare not venture that they are difficult.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["responsibility", "belongs", "student", "regardless", "age"],
    words: [
      "responsibility",
      "for",
      "learning",
      "belongs",
      "to",
      "the",
      "student",
      "regardless",
      "of",
      "age",
    ],
    id: "Responsibility for learning belongs to the student, regardless of age.",
    author: "Robert Martin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "way", "god", "many", "things"],
    words: ["the", "best", "way", "to", "know", "god", "is", "love", "many", "things"],
    id: "The best way to know God is to love many things.",
    author: "Vincent Van Gogh",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "whole", "life", "make", "lives"],
    words: ["dogs", "are", "not", "our", "whole", "life", "but", "they", "make", "lives"],
    id: "Dogs are not our whole life, but they make our lives whole.",
    author: "Roger Caras",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "stop", "teacher", "being", "student"],
    words: ["you", "never", "stop", "learning", "if", "have", "a", "teacher", "being", "student"],
    id: "You never stop learning. If you have a teacher, you never stop being a student.",
    author: "Elisabeth Rohm",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["coward", "incapable", "exhibiting", "prerogative", "brave"],
    words: [
      "a",
      "coward",
      "is",
      "incapable",
      "of",
      "exhibiting",
      "love",
      "it",
      "the",
      "prerogative",
      "brave",
    ],
    id: "A coward is incapable of exhibiting love; it is the prerogative of the brave.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["secret", "our", "success", "never", "up"],
    words: ["the", "secret", "of", "our", "success", "is", "that", "we", "never", "give", "up"],
    id: "The secret of our success is that we never, never give up.",
    author: "Wilma Mankiller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "improves", "your", "bargaining", "position"],
    words: [
      "money",
      "can't",
      "buy",
      "love",
      "but",
      "it",
      "improves",
      "your",
      "bargaining",
      "position",
    ],
    id: "Money can't buy love, but it improves your bargaining position.",
    author: "Christopher Marlowe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["presidency", "many", "problems", "boredom", "least"],
    words: [
      "the",
      "presidency",
      "has",
      "many",
      "problems",
      "but",
      "boredom",
      "is",
      "least",
      "of",
      "them",
    ],
    id: "The presidency has many problems, but boredom is the least of them.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["emancipation", "from", "bondage", "soil", "freedom"],
    words: [
      "emancipation",
      "from",
      "the",
      "bondage",
      "of",
      "soil",
      "is",
      "no",
      "freedom",
      "for",
      "tree",
    ],
    id: "Emancipation from the bondage of the soil is no freedom for the tree.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["takes", "two", "truth", "one", "another"],
    words: ["it", "takes", "two", "to", "speak", "the", "truth", "one", "and", "another", "hear"],
    id: "It takes two to speak the truth: one to speak, and another to hear.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "one", "spent", "earning", "yearning"],
    words: [
      "a",
      "happy",
      "life",
      "is",
      "one",
      "spent",
      "in",
      "learning",
      "earning",
      "and",
      "yearning",
    ],
    id: "A happy life is one spent in learning, earning, and yearning.",
    author: "Lillian Gish",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "patience", "exercise", "substantial", "when"],
    words: ["i", "do", "have", "patience", "can", "exercise", "substantial", "when", "need", "to"],
    id: "I do have patience. I can exercise substantial patience when I need to.",
    author: "Robert Bigelow",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["trouble", "from", "experience", "never", "graduate"],
    words: [
      "the",
      "trouble",
      "with",
      "learning",
      "from",
      "experience",
      "is",
      "that",
      "you",
      "never",
      "graduate",
    ],
    id: "The trouble with learning from experience is that you never graduate.",
    author: "Doug Larson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["invariable", "mark", "wisdom", "miraculous", "common"],
    words: [
      "the",
      "invariable",
      "mark",
      "of",
      "wisdom",
      "is",
      "to",
      "see",
      "miraculous",
      "in",
      "common",
    ],
    id: "The invariable mark of wisdom is to see the miraculous in the common.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["seeks", "find", "seek", "will", "found"],
    words: ["he", "who", "seeks", "does", "not", "find", "but", "seek", "will", "be", "found"],
    id: "He who seeks does not find, but he who does not seek will be found.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["biggest", "motivation", "my", "desire", "best"],
    words: ["the", "biggest", "motivation", "for", "me", "is", "my", "desire", "to", "be", "best"],
    id: "The biggest motivation for me is my desire to be the best.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "sacred", "space", "find", "yourself"],
    words: [
      "your",
      "sacred",
      "space",
      "is",
      "where",
      "you",
      "can",
      "find",
      "yourself",
      "again",
      "and",
    ],
    id: "Your sacred space is where you can find yourself again and again.",
    author: "Joseph Campbell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "addicted", "exercising", "i", "something"],
    words: [
      "i'm",
      "addicted",
      "to",
      "exercising",
      "and",
      "i",
      "have",
      "do",
      "something",
      "every",
      "day",
    ],
    id: "I'm addicted to exercising and I have to do something every day.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "fear", "god", "next", "mostly"],
    words: ["i", "fear", "god", "and", "next", "to", "mostly", "them", "that", "him", "not"],
    id: "I fear God and next to God I mostly fear them that fear him not.",
    author: "Saadi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["everyone", "wills", "inner", "voice", "within"],
    words: [
      "everyone",
      "who",
      "wills",
      "can",
      "hear",
      "the",
      "inner",
      "voice",
      "it",
      "is",
      "within",
    ],
    id: "Everyone who wills can hear the inner voice. It is within everyone.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["beginning", "knowledge", "discovery", "something", "understand"],
    words: [
      "the",
      "beginning",
      "of",
      "knowledge",
      "is",
      "discovery",
      "something",
      "we",
      "do",
      "not",
      "understand",
    ],
    id: "The beginning of knowledge is the discovery of something we do not understand.",
    author: "Frank Herbert",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "dead", "never", "until", "forgotten"],
    words: ["our", "dead", "are", "never", "to", "us", "until", "we", "have", "forgotten", "them"],
    id: "Our dead are never dead to us, until we have forgotten them.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["succeed", "set", "priorities", "decide", "stand"],
    words: [
      "to",
      "succeed",
      "today",
      "you",
      "have",
      "set",
      "priorities",
      "decide",
      "what",
      "stand",
      "for",
    ],
    id: "To succeed today, you have to set priorities, decide what you stand for.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["from", "fountain", "knowledge", "others", "gargle"],
    words: [
      "some",
      "people",
      "drink",
      "from",
      "the",
      "fountain",
      "of",
      "knowledge",
      "others",
      "just",
      "gargle",
    ],
    id: "Some people drink from the fountain of knowledge, others just gargle.",
    author: "Robert Anthony",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["leader", "one", "way", "goes", "shows"],
    words: ["a", "leader", "is", "one", "who", "knows", "the", "way", "goes", "and", "shows"],
    id: "A leader is one who knows the way, goes the way, and shows the way.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["makes", "quick", "use", "genius", "prudence"],
    words: ["who", "makes", "quick", "use", "of", "the", "moment", "is", "a", "genius", "prudence"],
    id: "Who makes quick use of the moment is a genius of prudence.",
    author: "Johann Kaspar Lavater",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["miracles", "come", "moments", "ready", "willing"],
    words: ["miracles", "come", "in", "moments", "be", "ready", "and", "willing"],
    id: "Miracles come in moments. Be ready and willing.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "choose", "your", "family", "friends"],
    words: ["you", "can't", "choose", "your", "family", "but", "can", "friends"],
    id: "You can't choose your family, but you can choose your friends.",
    author: "Scott Hall",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "young", "die", "old", "age"],
    words: ["how", "young", "can", "you", "die", "of", "old", "age"],
    id: "How young can you die of old age?",
    author: "Steven Wright",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "real", "wisdom", "saying", "thing"],
    words: ["there's", "a", "real", "wisdom", "to", "not", "saying", "thing"],
    id: "There's a real wisdom to not saying a thing.",
    author: "Willem Dafoe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["management", "doing", "things", "right", "leadership"],
    words: ["management", "is", "doing", "things", "right", "leadership", "the"],
    id: "Management is doing things right; leadership is doing the right things.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "all", "giving", "up-and-coming", "chance"],
    words: ["i'm", "all", "about", "giving", "up-and-coming", "people", "a", "chance"],
    id: "I'm all about giving up-and-coming people a chance.",
    author: "Lil Baby",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["anger", "begets", "more", "forgiveness", "lead"],
    words: ["anger", "begets", "more", "and", "forgiveness", "love", "lead", "to"],
    id: "Anger begets more anger, and forgiveness and love lead to more forgiveness and love.",
    author: "Mahavira",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["should", "never", "undermine", "friendship", "loyalty"],
    words: ["you", "should", "never", "undermine", "friendship", "and", "loyalty"],
    id: "You should never undermine friendship and loyalty.",
    author: "Toyah Willcox",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["growth", "itself", "contains", "germ", "happiness"],
    words: ["growth", "itself", "contains", "the", "germ", "of", "happiness"],
    id: "Growth itself contains the germ of happiness.",
    author: "Pearl S. Buck",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["order", "without", "liberty", "equally", "destructive"],
    words: ["order", "without", "liberty", "and", "are", "equally", "destructive"],
    id: "Order without liberty and liberty without order are equally destructive.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["exist", "without", "evil", "whereas", "cannot"],
    words: ["good", "can", "exist", "without", "evil", "whereas", "cannot"],
    id: "Good can exist without evil, whereas evil cannot exist without good.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["touch", "seems", "as", "essential", "sunlight"],
    words: ["touch", "seems", "to", "be", "as", "essential", "sunlight"],
    id: "Touch seems to be as essential as sunlight.",
    author: "Diane Ackerman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["wise", "at", "once", "fool", "last"],
    words: ["the", "wise", "does", "at", "once", "what", "fool", "last"],
    id: "The wise does at once what the fool does at last.",
    author: "Baltasar Gracian",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["government", "best", "which", "governs", "least"],
    words: ["that", "government", "is", "best", "which", "governs", "least"],
    id: "That government is best which governs least.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["family", "first", "always", "matter", "situation"],
    words: ["family", "first", "always", "no", "matter", "what", "the", "situation"],
    id: "Family first, always, no matter what the situation.",
    author: "Baker Mayfield",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "pictures", "aren't", "enough", "close"],
    words: ["if", "your", "pictures", "aren't", "good", "enough", "you", "close"],
    id: "If your pictures aren't good enough, you aren't close enough.",
    author: "Robert Capa",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["create", "your", "opportunities", "by", "asking"],
    words: ["you", "create", "your", "opportunities", "by", "asking", "for", "them"],
    id: "You create your opportunities by asking for them.",
    author: "Shakti Gawain",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "way", "out", "always", "through"],
    words: ["the", "best", "way", "out", "is", "always", "through"],
    id: "The best way out is always through.",
    author: "Robert Frost",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["takes", "up", "knowledge", "leaves", "off"],
    words: ["love", "takes", "up", "where", "knowledge", "leaves", "off"],
    id: "Love takes up where knowledge leaves off.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "isn't", "unless", "there's", "violin-playing"],
    words: ["happiness", "isn't", "unless", "there's", "a", "violin-playing", "goat"],
    id: "Happiness isn't happiness unless there's a violin-playing goat.",
    author: "Julia Roberts",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["motherhood", "all", "begins", "ends", "there"],
    words: ["motherhood", "all", "love", "begins", "and", "ends", "there"],
    id: "Motherhood: All love begins and ends there.",
    author: "Robert Browning",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["beauty", "distress", "much", "most", "affecting"],
    words: ["beauty", "in", "distress", "is", "much", "the", "most", "affecting"],
    id: "Beauty in distress is much the most affecting beauty.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["large", "skepticism", "leads", "understanding", "small"],
    words: ["large", "skepticism", "leads", "to", "understanding", "small", "no"],
    id: "Large skepticism leads to large understanding. Small skepticism leads to small understanding. No skepticism leads to no understanding.",
    author: "Unknown",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "without", "liberty", "body", "spirit"],
    words: ["life", "without", "liberty", "is", "like", "a", "body", "spirit"],
    id: "Life without liberty is like a body without spirit.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["gods", "service", "tolerable", "man's", "intolerable"],
    words: ["the", "gods", "service", "is", "tolerable", "man's", "intolerable"],
    id: "The gods' service is tolerable, man's intolerable.",
    author: "Plato",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "crowded", "along", "extra", "mile"],
    words: ["it's", "never", "crowded", "along", "the", "extra", "mile"],
    id: "It's never crowded along the extra mile.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["way", "up", "down", "one", "same"],
    words: ["the", "way", "up", "and", "down", "are", "one", "same"],
    id: "The way up and the way down are one and the same.",
    author: "Heraclitus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["magnetic", "personality", "necessarily", "indicate", "heart"],
    words: ["a", "magnetic", "personality", "doesn't", "necessarily", "indicate", "good", "heart"],
    id: "A magnetic personality doesn't necessarily indicate a good heart.",
    author: "Laura Linney",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["personality", "unbroken", "series", "successful", "gestures"],
    words: ["personality", "is", "an", "unbroken", "series", "of", "successful", "gestures"],
    id: "Personality is an unbroken series of successful gestures.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["uneasy", "lies", "head", "wears", "crown"],
    words: ["uneasy", "lies", "the", "head", "that", "wears", "a", "crown"],
    id: "Uneasy lies the head that wears a crown.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "isn't", "something", "experience", "remember"],
    words: ["happiness", "isn't", "something", "you", "experience", "it's", "remember"],
    id: "Happiness isn't something you experience; it's something you remember.",
    author: "Oscar Levant",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["momentum", "next", "day's", "starting", "pitcher"],
    words: ["momentum", "is", "the", "next", "day's", "starting", "pitcher"],
    id: "Momentum? Momentum is the next day's starting pitcher.",
    author: "Earl Weaver",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "second", "acts", "american", "lives"],
    words: ["there", "are", "no", "second", "acts", "in", "american", "lives"],
    id: "There are no second acts in American lives.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["eating", "words", "never", "given", "indigestion"],
    words: ["eating", "words", "has", "never", "given", "me", "indigestion"],
    id: "Eating words has never given me indigestion.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "path", "through", "life", "highway"],
    words: ["the", "best", "path", "through", "life", "is", "highway"],
    id: "The best path through life is the highway.",
    author: "Henri Frederic Amiel",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "gets", "weird", "turn", "pro"],
    words: ["when", "the", "going", "gets", "weird", "turn", "pro"],
    id: "When the going gets weird, the weird turn pro.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["anger", "begins", "folly", "ends", "repentance"],
    words: ["anger", "begins", "with", "folly", "and", "ends", "repentance"],
    id: "Anger begins with folly, and ends with repentance.",
    author: "Pythagoras",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["tears", "come", "from", "heart", "brain"],
    words: ["tears", "come", "from", "the", "heart", "and", "not", "brain"],
    id: "Tears come from the heart and not from the brain.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "divine", "bestows", "keys", "knowledge"],
    words: ["only", "divine", "love", "bestows", "the", "keys", "of", "knowledge"],
    id: "Only divine love bestows the keys of knowledge.",
    author: "Arthur Rimbaud",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["jealousy", "contains", "more", "self-love", "than"],
    words: ["jealousy", "contains", "more", "of", "self-love", "than", "love"],
    id: "Jealousy contains more of self-love than of love.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["russians", "feared", "ike", "didn't", "fear"],
    words: ["the", "russians", "feared", "ike", "they", "didn't", "fear", "me"],
    id: "The Russians feared Ike. They didn't fear me.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "might", "serious", "fun", "politics"],
    words: ["there", "might", "be", "some", "serious", "fun", "in", "politics"],
    id: "There might be some serious fun in politics.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["looks", "through", "telescope", "envy", "microscope"],
    words: ["love", "looks", "through", "a", "telescope", "envy", "microscope"],
    id: "Love looks through a telescope; envy, through a microscope.",
    author: "Josh Billings",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "never", "easy", "those", "dream"],
    words: ["life", "is", "never", "easy", "for", "those", "who", "dream"],
    id: "Life is never easy for those who dream.",
    author: "Robert James Waller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["cannot", "open", "book", "without", "something"],
    words: ["you", "cannot", "open", "a", "book", "without", "learning", "something"],
    id: "You cannot open a book without learning something.",
    author: "Confucius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["faith", "state", "openness", "or", "trust"],
    words: ["faith", "is", "a", "state", "of", "openness", "or", "trust"],
    id: "Faith is a state of openness or trust.",
    author: "Alan Watts",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["silence", "true", "friend", "never", "betrays"],
    words: ["silence", "is", "a", "true", "friend", "who", "never", "betrays"],
    id: "Silence is a true friend who never betrays.",
    author: "Confucius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["fool", "flatters", "himself", "wise", "man"],
    words: ["a", "fool", "flatters", "himself", "wise", "man", "the"],
    id: "A fool flatters himself, a wise man flatters the fool.",
    author: "Edward G. Bulwer-Lytton",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["movies", "were", "custard", "compared", "politics"],
    words: ["the", "movies", "were", "custard", "compared", "to", "politics"],
    id: "The movies were custard compared to politics.",
    author: "Nancy Reagan",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["maria", "best", "reason", "come", "home"],
    words: ["maria", "is", "the", "best", "reason", "to", "come", "home"],
    id: "Maria is the best reason to come home.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["why", "can't", "i", "my", "waffle"],
    words: ["why", "can't", "i", "just", "eat", "my", "waffle"],
    id: "Why can't I just eat my waffle?",
    author: "Barack Obama",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["humility", "solid", "foundation", "all", "virtues"],
    words: ["humility", "is", "the", "solid", "foundation", "of", "all", "virtues"],
    id: "Humility is the solid foundation of all virtues.",
    author: "Confucius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "family", "man", "at", "heart"],
    words: ["i", "am", "a", "family", "man", "at", "heart"],
    id: "I am a family man at heart.",
    author: "Jeffrey Dean Morgan",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "laughable", "laughs", "at", "himself"],
    words: ["no", "one", "is", "laughable", "who", "laughs", "at", "himself"],
    id: "No one is laughable who laughs at himself.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "lives", "science", "as", "bread"],
    words: ["man", "lives", "for", "science", "as", "well", "bread"],
    id: "Man lives for science as well as bread.",
    author: "William James",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["true", "change", "takes", "place", "imagination"],
    words: ["true", "change", "takes", "place", "in", "the", "imagination"],
    id: "True change takes place in the imagination.",
    author: "Unknown",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "come", "from", "middle-class", "family"],
    words: ["i", "come", "from", "a", "very", "middle-class", "family"],
    id: "I come from a very middle-class family.",
    author: "Sean Murray",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["shiva", "nirvana's", "dedication", "patience", "adorable"],
    words: ["shiva", "nirvana's", "dedication", "and", "patience", "are", "adorable"],
    id: "Shiva Nirvana's dedication and patience are adorable.",
    author: "Gopi Sundar",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "poor", "creature", "compared", "woman"],
    words: ["a", "man", "is", "poor", "creature", "compared", "to", "woman"],
    id: "A man is a poor creature compared to a woman.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["blind", "friendship", "closes", "its", "eyes"],
    words: ["love", "is", "blind", "friendship", "closes", "its", "eyes"],
    id: "Love is blind; friendship closes its eyes.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["family", "nature", "health", "all", "together"],
    words: ["family", "nature", "and", "health", "all", "go", "together"],
    id: "Family, nature and health all go together.",
    author: "Olivia Newton-John",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["human", "beings", "as", "would", "yourself"],
    words: ["love", "other", "human", "beings", "as", "you", "would", "yourself"],
    id: "Love other human beings as you would love yourself.",
    author: "Ho Chi Minh",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["affirmation", "without", "discipline", "beginning", "delusion"],
    words: ["affirmation", "without", "discipline", "is", "the", "beginning", "of", "delusion"],
    id: "Affirmation without discipline is the beginning of delusion.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "tasted", "freedom", "really", "liked"],
    words: ["i", "tasted", "freedom", "and", "really", "liked", "it"],
    id: "I tasted freedom and I really liked it.",
    author: "Phil Lynott",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "were", "alexander", "would", "diogenes"],
    words: ["if", "i", "were", "not", "alexander", "would", "be", "diogenes"],
    id: "If I were not Alexander, I would be Diogenes.",
    author: "Alexander the Great",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["clouds", "symbolize", "veils", "shroud", "god"],
    words: ["clouds", "symbolize", "the", "veils", "that", "shroud", "god"],
    id: "Clouds symbolize the veils that shroud God.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "elation", "others", "mere", "stagnation"],
    words: ["happiness", "to", "some", "elation", "is", "others", "mere", "stagnation"],
    id: "Happiness, to some, elation; Is, to others, mere stagnation.",
    author: "Amy Lowell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["essence", "mathematics", "lies", "its", "freedom"],
    words: ["the", "essence", "of", "mathematics", "lies", "in", "its", "freedom"],
    id: "The essence of mathematics lies in its freedom.",
    author: "Georg Cantor",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "only", "law", "which", "genius"],
    words: ["freedom", "is", "the", "only", "law", "which", "genius", "knows"],
    id: "Freedom is the only law which genius knows.",
    author: "James Russell Lowell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["rise", "by", "sin", "virtue", "fall"],
    words: ["some", "rise", "by", "sin", "and", "virtue", "fall"],
    id: "Some rise by sin, and some by virtue fall.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "thy", "ear", "few", "voice"],
    words: ["give", "every", "man", "thy", "ear", "but", "few", "voice"],
    id: "Give every man thy ear, but few thy voice.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "given", "anybody", "his", "freedom"],
    words: ["no", "man", "can", "given", "anybody", "his", "freedom"],
    id: "No man can given anybody his freedom.",
    author: "Stokely Carmichael",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "drive", "down", "health", "costs"],
    words: ["i", "want", "to", "drive", "down", "health", "care", "costs"],
    id: "I want to drive down health care costs.",
    author: "Chris Gibson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["family", "most", "important", "thing", "world"],
    words: ["family", "is", "the", "most", "important", "thing", "in", "world"],
    id: "Family is the most important thing in the world.",
    author: "Princess Diana",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["may", "never", "live", "crazy", "die"],
    words: ["some", "may", "never", "live", "but", "the", "crazy", "die"],
    id: "Some may never live, but the crazy never die.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["reasons", "which", "reason", "cannot", "understand"],
    words: ["love", "has", "reasons", "which", "reason", "cannot", "understand"],
    id: "Love has reasons which reason cannot understand.",
    author: "Blaise Pascal",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["slow", "choosing", "friend", "slower", "changing"],
    words: ["be", "slow", "in", "choosing", "a", "friend", "slower", "changing"],
    id: "Be slow in choosing a friend, slower in changing.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "first", "priority", "time", "family"],
    words: ["my", "first", "priority", "is", "time", "with", "family"],
    id: "My first priority is time with my family.",
    author: "Tom Selleck",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["luck", "when", "preparation", "opportunity", "meet"],
    words: ["luck", "that's", "when", "preparation", "and", "opportunity", "meet"],
    id: "Luck, that's when preparation and opportunity meet.",
    author: "Pierre Trudeau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "will", "pass", "seasons", "come"],
    words: ["time", "will", "pass", "and", "seasons", "come", "go"],
    id: "Time will pass and seasons will come and go.",
    author: "Roy Bean",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["its", "own", "exceeding", "great", "reward"],
    words: ["learning", "is", "its", "own", "exceeding", "great", "reward"],
    id: "Learning is its own exceeding great reward.",
    author: "William Hazlitt",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "cooking", "myself", "my", "family"],
    words: ["i", "love", "cooking", "for", "myself", "and", "my", "family"],
    id: "I love cooking for myself and cooking for my family.",
    author: "Al Roker",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["rarely", "promise", "lawful", "constantly", "perform"],
    words: ["rarely", "promise", "but", "if", "lawful", "constantly", "perform"],
    id: "Rarely promise, but, if lawful, constantly perform.",
    author: "William Penn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "many", "things", "i", "understand"],
    words: ["there", "are", "many", "things", "that", "i", "don't", "understand"],
    id: "There are many things that I don't understand.",
    author: "Billy Graham",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["college", "refuge", "from", "hasty", "judgment"],
    words: ["college", "is", "a", "refuge", "from", "hasty", "judgment"],
    id: "College is a refuge from hasty judgment.",
    author: "Robert Frost",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["rises", "late", "must", "trot", "all"],
    words: ["he", "that", "rises", "late", "must", "trot", "all", "day"],
    id: "He that rises late must trot all day.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "had", "lovers", "quarrel", "world"],
    words: ["i", "had", "a", "lovers", "quarrel", "with", "the", "world"],
    id: "I had a lovers quarrel with the world.",
    author: "Robert Frost",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "should", "obstacles", "accessing", "knowledge"],
    words: ["there", "should", "be", "no", "obstacles", "to", "accessing", "knowledge"],
    id: "There should be no obstacles to accessing knowledge.",
    author: "Alexandra Elbakyan",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["real", "freedom", "lies", "wildness", "civilization"],
    words: ["real", "freedom", "lies", "in", "wildness", "not", "civilization"],
    id: "Real freedom lies in wildness, not civilization.",
    author: "Charles Lindbergh",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "writer", "teaches", "teacher", "writes"],
    words: ["i'm", "not", "a", "writer", "who", "teaches", "teacher", "writes"],
    id: "I'm not a writer who teaches. I'm a teacher who writes.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["by", "failing", "prepare", "preparing", "fail"],
    words: ["by", "failing", "to", "prepare", "you", "are", "preparing", "fail"],
    id: "By failing to prepare, you are preparing to fail.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["come", "into", "cooking", "get", "rich"],
    words: ["you", "don't", "come", "into", "cooking", "to", "get", "rich"],
    id: "You don't come into cooking to get rich.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "stay", "home", "my", "family"],
    words: ["i", "like", "to", "stay", "home", "with", "my", "family"],
    id: "I like to stay home with my family.",
    author: "Mads Mikkelsen",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["music", "part", "life", "fashion", "too"],
    words: ["music", "is", "part", "of", "the", "life", "fashion", "too"],
    id: "Music is part of the life of fashion, too.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "get", "too", "much", "winter"],
    words: ["you", "can't", "get", "too", "much", "winter", "in", "the"],
    id: "You can't get too much winter in the winter.",
    author: "Robert Frost",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["god's", "dice", "always", "lucky", "roll"],
    words: ["god's", "dice", "always", "have", "a", "lucky", "roll"],
    id: "God's dice always have a lucky roll.",
    author: "Sophocles",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "fine", "fleeting", "significance", "lasting"],
    words: ["success", "is", "fine", "but", "fleeting", "significance", "lasting"],
    id: "Success is fine, but success is fleeting. Significance is lasting.",
    author: "Beth Brooke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "took", "time", "out", "life"],
    words: ["i", "took", "some", "time", "out", "for", "life"],
    id: "I took some time out for life.",
    author: "James L. Brooks",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["blame", "his", "chooses", "god", "blameless"],
    words: ["the", "blame", "is", "his", "who", "chooses", "god", "blameless"],
    id: "The blame is his who chooses: God is blameless.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["will", "grace", "as", "horse", "rider"],
    words: ["will", "is", "to", "grace", "as", "the", "horse", "rider"],
    id: "Will is to grace as the horse is to the rider.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["efficiency", "doing", "things", "right", "effectiveness"],
    words: ["efficiency", "is", "doing", "things", "right", "effectiveness", "the"],
    id: "Efficiency is doing things right; effectiveness is doing the right things.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "keep", "my", "birthday", "relaxed"],
    words: ["i", "like", "to", "keep", "my", "birthday", "very", "relaxed"],
    id: "I like to keep my birthday very relaxed.",
    author: "Barun Sobti",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["politics", "art", "controlling", "your", "environment"],
    words: ["politics", "is", "the", "art", "of", "controlling", "your", "environment"],
    id: "Politics is the art of controlling your environment.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["isn't", "agreeing", "or", "disagreeing", "voting"],
    words: ["thinking", "isn't", "agreeing", "or", "disagreeing", "that's", "voting"],
    id: "Thinking isn't agreeing or disagreeing. That's voting.",
    author: "Robert Frost",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["high-quality", "food", "better", "your", "health"],
    words: ["high-quality", "food", "is", "better", "for", "your", "health"],
    id: "High-quality food is better for your health.",
    author: "Michael Pollan",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "happiest", "my", "family", "around"],
    words: ["i'm", "happiest", "with", "my", "family", "around", "me"],
    id: "I'm happiest with my family around me.",
    author: "Amy Winehouse",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["things", "hardest", "bear", "sweetest", "remember"],
    words: ["the", "things", "hardest", "to", "bear", "are", "sweetest", "remember"],
    id: "The things hardest to bear are sweetest to remember.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "poets", "there", "must", "audiences"],
    words: ["to", "have", "great", "poets", "there", "must", "be", "audiences"],
    id: "To have great poets, there must be great audiences.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["any", "thing", "sacred", "human", "body"],
    words: ["if", "any", "thing", "is", "sacred", "the", "human", "body"],
    id: "If any thing is sacred, the human body is sacred.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["attention", "health", "life's", "greatest", "hindrance"],
    words: ["attention", "to", "health", "is", "life's", "greatest", "hindrance"],
    id: "Attention to health is life's greatest hindrance.",
    author: "Plato",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["innovation", "only", "occur", "breathe", "free"],
    words: ["innovation", "can", "only", "occur", "where", "you", "breathe", "free"],
    id: "Innovation can only occur where you can breathe free.",
    author: "Joe Biden",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["heresy", "another", "word", "freedom", "thought"],
    words: ["heresy", "is", "another", "word", "for", "freedom", "of", "thought"],
    id: "Heresy is another word for freedom of thought.",
    author: "Graham Greene",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "hours", "wings", "absence", "crutches"],
    words: ["our", "hours", "in", "love", "have", "wings", "absence", "crutches"],
    id: "Our hours in love have wings; in absence, crutches.",
    author: "Miguel de Cervantes",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["goodness", "only", "investment", "never", "fails"],
    words: ["goodness", "is", "the", "only", "investment", "that", "never", "fails"],
    id: "Goodness is the only investment that never fails.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["coward", "only", "threatens", "when", "safe"],
    words: ["the", "coward", "only", "threatens", "when", "he", "is", "safe"],
    id: "The coward only threatens when he is safe.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["unclean", "person", "universally", "slothful", "one"],
    words: ["an", "unclean", "person", "is", "universally", "a", "slothful", "one"],
    id: "An unclean person is universally a slothful one.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["n", "kind", "ignorance", "distinguishing", "studious"],
    words: ["learning", "n", "the", "kind", "of", "ignorance", "distinguishing", "studious"],
    id: "Learning, n. The kind of ignorance distinguishing the studious.",
    author: "Ambrose Bierce",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["most", "practical", "kind", "politics", "decency"],
    words: ["the", "most", "practical", "kind", "of", "politics", "is", "decency"],
    id: "The most practical kind of politics is the politics of decency.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "bad", "habits", "leads", "life"],
    words: ["a", "change", "in", "bad", "habits", "leads", "to", "life"],
    id: "A change in bad habits leads to a change in life.",
    author: "Jenny Craig",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "imagination", "without", "wings", "feet"],
    words: ["one", "who", "has", "imagination", "without", "learning", "wings", "feet"],
    id: "One who has imagination without learning has wings without feet.",
    author: "Joseph Joubert",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["healthy", "outside", "starts", "from", "inside"],
    words: ["a", "healthy", "outside", "starts", "from", "the", "inside"],
    id: "A healthy outside starts from the inside.",
    author: "Robert Urich",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "never", "enough", "i", "you's"],
    words: ["there", "are", "never", "enough", "i", "love", "you's"],
    id: "There are never enough I Love You's.",
    author: "Lenny Bruce",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["starting", "point", "all", "achievement", "desire"],
    words: ["the", "starting", "point", "of", "all", "achievement", "is", "desire"],
    id: "The starting point of all achievement is desire.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["men", "exist", "sake", "one", "another"],
    words: ["men", "exist", "for", "the", "sake", "of", "one", "another"],
    id: "Men exist for the sake of one another.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["poem", "begins", "delight", "ends", "wisdom"],
    words: ["a", "poem", "begins", "in", "delight", "and", "ends", "wisdom"],
    id: "A poem begins in delight and ends in wisdom.",
    author: "Robert Frost",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["without", "knowledge", "action", "useless", "futile"],
    words: ["without", "knowledge", "action", "is", "useless", "and", "futile"],
    id: "Without knowledge action is useless and knowledge without action is futile.",
    author: "Abu Bakr",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "gossips", "people's", "secret", "virtues"],
    words: ["no", "one", "gossips", "about", "other", "people's", "secret", "virtues"],
    id: "No one gossips about other people's secret virtues.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["sadness", "wall", "between", "two", "gardens"],
    words: ["sadness", "is", "but", "a", "wall", "between", "two", "gardens"],
    id: "Sadness is but a wall between two gardens.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["sows", "thorns", "should", "never", "barefoot"],
    words: ["he", "that", "sows", "thorns", "should", "never", "go", "barefoot"],
    id: "He that sows thorns should never go barefoot.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["keep", "taking", "chances", "having", "fun"],
    words: ["just", "keep", "taking", "chances", "and", "having", "fun"],
    id: "Just keep taking chances and having fun.",
    author: "Garth Brooks",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "expression", "cannot", "defined", "selectively"],
    words: ["the", "freedom", "of", "expression", "cannot", "be", "defined", "selectively"],
    id: "The freedom of expression cannot be defined selectively.",
    author: "Barkha Dutt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["savage", "man", "never", "quite", "eradicated"],
    words: ["the", "savage", "in", "man", "is", "never", "quite", "eradicated"],
    id: "The savage in man is never quite eradicated.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["praise", "as", "tasted", "allow", "prove"],
    words: ["praise", "us", "as", "we", "are", "tasted", "allow", "prove"],
    id: "Praise us as we are tasted, allow us as we prove.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["gift", "kind", "countenance", "double", "present"],
    words: ["a", "gift", "with", "kind", "countenance", "is", "double", "present"],
    id: "A gift, with a kind countenance, is a double present.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["charitable", "before", "wealth", "makes", "covetous"],
    words: ["be", "charitable", "before", "wealth", "makes", "you", "covetous"],
    id: "Be charitable before wealth makes you covetous.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["spiritual", "worldliness", "kills", "soul", "church"],
    words: ["spiritual", "worldliness", "kills", "it", "the", "soul", "church"],
    id: "Spiritual worldliness kills! It kills the soul! It kills the Church!",
    author: "Pope Francis",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "crime", "concealed", "by", "another"],
    words: ["one", "crime", "has", "to", "be", "concealed", "by", "another"],
    id: "One crime has to be concealed by another.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "will", "praise", "any", "man"],
    words: ["i", "will", "praise", "any", "man", "that", "me"],
    id: "I will praise any man that will praise me.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "hope", "nothing", "fear", "free"],
    words: ["i", "hope", "for", "nothing", "fear", "am", "free"],
    id: "I hope for nothing. I fear nothing. I am free.",
    author: "Nikos Kazantzakis",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["pessimist", "sees", "difficulty", "opportunity", "optimist"],
    words: ["the", "pessimist", "sees", "difficulty", "in", "every", "opportunity", "optimist"],
    id: "The pessimist sees difficulty in every opportunity. The optimist sees the opportunity in every difficulty.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["without", "thought", "labor", "lost", "perilous"],
    words: ["learning", "without", "thought", "is", "labor", "lost", "perilous"],
    id: "Learning without thought is labor lost; thought without learning is perilous.",
    author: "Confucius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["filmmaking", "chance", "live", "many", "lifetimes"],
    words: ["filmmaking", "is", "a", "chance", "to", "live", "many", "lifetimes"],
    id: "Filmmaking is a chance to live many lifetimes.",
    author: "Robert Altman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["common", "core", "big", "win", "education"],
    words: ["common", "core", "is", "a", "big", "win", "for", "education"],
    id: "Common Core is a big win for education.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "sin", "judgment", "without", "knowledge"],
    words: ["the", "greatest", "sin", "is", "judgment", "without", "knowledge"],
    id: "The greatest sin is judgment without knowledge.",
    author: "Kelsey Grammer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["self-interest", "makes", "blind", "others", "sharp-sighted"],
    words: ["self-interest", "makes", "some", "people", "blind", "and", "others", "sharp-sighted"],
    id: "Self-interest makes some people blind, and others sharp-sighted.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "ignorance", "gets", "started", "bounds"],
    words: ["when", "ignorance", "gets", "started", "it", "knows", "no", "bounds"],
    id: "When ignorance gets started it knows no bounds.",
    author: "Will Rogers",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["quality", "rather", "than", "quantity", "matters"],
    words: ["it", "is", "quality", "rather", "than", "quantity", "that", "matters"],
    id: "It is quality rather than quantity that matters.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["most", "my", "family", "fluent", "malayalam"],
    words: ["most", "of", "my", "family", "speaks", "fluent", "malayalam"],
    id: "Most of my family speaks fluent Malayalam.",
    author: "Vidya Vox",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "read", "reason", "against", "reading"],
    words: ["how", "well", "he's", "read", "to", "reason", "against", "reading"],
    id: "How well he's read, to reason against reading!",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["intelligence", "without", "ambition", "bird", "wings"],
    words: ["intelligence", "without", "ambition", "is", "a", "bird", "wings"],
    id: "Intelligence without ambition is a bird without wings.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["conquers", "others", "strong", "himself", "mighty"],
    words: ["he", "who", "conquers", "others", "is", "strong", "himself", "mighty"],
    id: "He who conquers others is strong; He who conquers himself is mighty.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["lost", "possessed", "by", "carnal", "desire"],
    words: ["he", "is", "lost", "who", "possessed", "by", "carnal", "desire"],
    id: "He is lost who is possessed by carnal desire.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["view", "health", "as", "investment", "expense"],
    words: ["view", "health", "as", "an", "investment", "not", "expense"],
    id: "View health as an investment, not an expense.",
    author: "John Quelch",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["pope", "how", "many", "divisions", "got"],
    words: ["the", "pope", "how", "many", "divisions", "has", "he", "got"],
    id: "The Pope? How many divisions has he got?",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["dos", "ugly", "interferes", "users", "experience"],
    words: ["dos", "is", "ugly", "and", "interferes", "with", "users", "experience"],
    id: "DOS is ugly and interferes with users' experience.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "find", "forgiveness", "really", "healthy"],
    words: ["i", "find", "forgiveness", "to", "be", "really", "healthy"],
    id: "I find forgiveness to be really healthy.",
    author: "Ben Affleck",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["music", "must", "made", "easy", "fun"],
    words: ["learning", "music", "must", "be", "made", "easy", "and", "fun"],
    id: "Learning music must be made easy and fun.",
    author: "Shankar Mahadevan",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["cry", "much", "easier", "than", "change"],
    words: ["people", "can", "cry", "much", "easier", "than", "they", "change"],
    id: "People can cry much easier than they can change.",
    author: "James Baldwin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["trust", "god", "-", "will", "provide"],
    words: ["trust", "in", "god", "-", "she", "will", "provide"],
    id: "Trust in God - she will provide.",
    author: "Emmeline Pankhurst",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "often", "ends", "-", "never"],
    words: ["friendship", "often", "ends", "in", "love", "but", "-", "never"],
    id: "Friendship often ends in love; but love in friendship - never.",
    author: "Charles Caleb Colton",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["rare", "as", "true", "friendship", "rarer"],
    words: ["rare", "as", "is", "true", "love", "friendship", "rarer"],
    id: "Rare as is true love, true friendship is rarer.",
    author: "Jean de La Fontaine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["somebody", "called", "homophobe", "i'm", "homophobic"],
    words: ["somebody", "called", "me", "a", "homophobe", "i'm", "not", "homophobic"],
    id: "Somebody called me a homophobe. I'm not homophobic.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["iraqis", "become", "invested", "their", "nationhood"],
    words: ["the", "iraqis", "have", "become", "invested", "in", "their", "nationhood"],
    id: "The Iraqis have become invested in their nationhood.",
    author: "Joe Biden",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["nature", "hurry", "yet", "everything", "accomplished"],
    words: ["nature", "does", "not", "hurry", "yet", "everything", "is", "accomplished"],
    id: "Nature does not hurry, yet everything is accomplished.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["most", "poor", "live", "poorest", "countries"],
    words: ["most", "poor", "people", "live", "in", "the", "poorest", "countries"],
    id: "Most poor people live in the poorest countries.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["education", "best", "provision", "old", "age"],
    words: ["education", "is", "the", "best", "provision", "for", "old", "age"],
    id: "Education is the best provision for old age.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["lot", "jews", "great", "friends", "mine"],
    words: ["a", "lot", "of", "jews", "are", "great", "friends", "mine"],
    id: "A lot of Jews are great friends of mine.",
    author: "Billy Graham",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["subtlest", "folly", "proceeds", "from", "wisdom"],
    words: ["for", "the", "subtlest", "folly", "proceeds", "from", "wisdom"],
    id: "For the subtlest folly proceeds from the subtlest wisdom.",
    author: "John Webster",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["space", "place", "as", "eternity", "time"],
    words: ["space", "is", "to", "place", "as", "eternity", "time"],
    id: "Space is to place as eternity is to time.",
    author: "Joseph Joubert",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["rich", "countries", "afford", "overpay", "things"],
    words: ["rich", "countries", "can", "afford", "to", "overpay", "for", "things"],
    id: "Rich countries can afford to overpay for things.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "different", "because", "everyone's", "personality"],
    words: ["every", "friendship", "is", "different", "because", "everyone's", "personality"],
    id: "Every friendship is different because everyone's personality is different.",
    author: "Nargis Fakhri",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowledge", "makes", "cherish", "innocence", "unattainable"],
    words: ["the", "knowledge", "that", "makes", "us", "cherish", "innocence", "unattainable"],
    id: "The knowledge that makes us cherish innocence makes innocence unattainable.",
    author: "Irving Howe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["data", "information", "knowledge", "understanding", "wisdom"],
    words: ["data", "is", "not", "information", "knowledge", "understanding", "wisdom"],
    id: "Data is not information, information is not knowledge, knowledge is not understanding, understanding is not wisdom.",
    author: "Clifford Stoll",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["men", "freely", "believe", "which", "desire"],
    words: ["men", "freely", "believe", "that", "which", "they", "desire"],
    id: "Men freely believe that which they desire.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["preacheth", "patience", "never", "knew", "pain"],
    words: ["he", "preacheth", "patience", "that", "never", "knew", "pain"],
    id: "He preacheth patience that never knew pain.",
    author: "Henry George Bohn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "power", "often", "simple", "patience"],
    words: ["the", "greatest", "power", "is", "often", "simple", "patience"],
    id: "The greatest power is often simple patience.",
    author: "E. Joseph Cossman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["result", "showed", "wisdom", "your", "orders"],
    words: ["the", "result", "showed", "wisdom", "of", "your", "orders"],
    id: "The result showed the wisdom of your orders.",
    author: "John Bigelow",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["ours", "career", "built", "on", "friendship"],
    words: ["ours", "is", "a", "career", "built", "on", "friendship", "not"],
    id: "Ours is a career built on a friendship, not a friendship built on a career.",
    author: "Declan Donnelly",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["reason", "god's", "crowning", "gift", "man"],
    words: ["reason", "is", "god's", "crowning", "gift", "to", "man"],
    id: "Reason is God's crowning gift to man.",
    author: "Sophocles",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "bank", "account", "cook", "digestion"],
    words: ["happiness", "a", "good", "bank", "account", "cook", "and", "digestion"],
    id: "Happiness: a good bank account, a good cook, and a good digestion.",
    author: "Jean-Jacques Rousseau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["big", "thoughts", "relish", "small", "pleasures"],
    words: ["think", "big", "thoughts", "but", "relish", "small", "pleasures"],
    id: "Think big thoughts but relish small pleasures.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["marriage", "friendship", "recognized", "by", "police"],
    words: ["marriage", "a", "friendship", "recognized", "by", "the", "police"],
    id: "Marriage: A friendship recognized by the police.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["winning", "isn't", "everything", "only", "thing"],
    words: ["winning", "isn't", "everything", "it's", "the", "only", "thing"],
    id: "Winning isn't everything, it's the only thing.",
    author: "Vince Lombardi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["keep", "oneself", "safe", "mean", "bury"],
    words: ["to", "keep", "oneself", "safe", "does", "not", "mean", "bury"],
    id: "To keep oneself safe does not mean to bury oneself.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "soul", "grown", "deep", "rivers"],
    words: ["my", "soul", "has", "grown", "deep", "like", "the", "rivers"],
    id: "My soul has grown deep like the rivers.",
    author: "Langston Hughes",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "flies", "when", "changing", "world"],
    words: ["time", "flies", "when", "you're", "changing", "the", "world"],
    id: "Time flies when you're changing the world.",
    author: "Craig Kielburger",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["woe", "those", "die", "mortal", "sin"],
    words: ["woe", "to", "those", "who", "die", "in", "mortal", "sin"],
    id: "Woe to those who die in mortal sin!",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "yourself", "receive", "more", "than"],
    words: ["when", "you", "give", "yourself", "receive", "more", "than"],
    id: "When you give yourself, you receive more than you give.",
    author: "Antoine de Saint-Exupery",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["age", "comes", "common", "sense", "wisdom"],
    words: ["with", "age", "comes", "common", "sense", "and", "wisdom"],
    id: "With age comes common sense and wisdom.",
    author: "Nas",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "will", "forgive", "his", "job"],
    words: ["god", "will", "forgive", "me", "it's", "his", "job"],
    id: "God will forgive me. It's his job.",
    author: "Heinrich Heine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["words", "without", "thoughts", "never", "heaven"],
    words: ["words", "without", "thoughts", "never", "to", "heaven", "go"],
    id: "Words without thoughts never to heaven go.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["exaggeration", "truth", "lost", "its", "temper"],
    words: ["exaggeration", "is", "truth", "that", "has", "lost", "its", "temper"],
    id: "Exaggeration is truth that has lost its temper.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["history", "shows", "there", "invincible", "armies"],
    words: ["history", "shows", "that", "there", "are", "no", "invincible", "armies"],
    id: "History shows that there are no invincible armies.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["real", "freedom", "lies", "wildness", "civilization"],
    words: ["real", "freedom", "lies", "in", "wildness", "not", "civilization"],
    id: "Real freedom lies in wildness, not in civilization.",
    author: "Charles Lindbergh",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["injustice", "anywhere", "threat", "justice", "everywhere"],
    words: ["injustice", "anywhere", "is", "a", "threat", "to", "justice", "everywhere"],
    id: "Injustice anywhere is a threat to justice everywhere.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "time", "enough", "none", "spare"],
    words: ["there's", "time", "enough", "but", "none", "to", "spare"],
    id: "There's time enough, but none to spare.",
    author: "Charles W. Chesnutt",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "makes", "friendship", "stronger", "weaker"],
    words: ["time", "makes", "friendship", "stronger", "but", "love", "weaker"],
    id: "Time makes friendship stronger, but love weaker.",
    author: "Jean de la Bruyere",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "all", "preaching", "forgiveness", "compassion"],
    words: ["i'm", "all", "about", "preaching", "forgiveness", "and", "compassion"],
    id: "I'm all about preaching forgiveness and compassion.",
    author: "Montaigne",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["act", "dying", "one", "acts", "life"],
    words: ["the", "act", "of", "dying", "is", "one", "acts", "life"],
    id: "The act of dying is one of the acts of life.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "nothing", "funnier", "than", "human"],
    words: ["there's", "nothing", "funnier", "than", "the", "human", "animal"],
    id: "There's nothing funnier than the human animal.",
    author: "Walt Disney",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["win", "without", "risk", "triumph", "glory"],
    words: ["to", "win", "without", "risk", "is", "triumph", "glory"],
    id: "To win without risk is to triumph without glory.",
    author: "Pierre Corneille",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["allow", "geniuses", "around", "our", "studio"],
    words: ["we", "allow", "no", "geniuses", "around", "our", "studio"],
    id: "We allow no geniuses around our Studio.",
    author: "Walt Disney",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "delight", "owning", "anything", "unshared"],
    words: ["there", "is", "no", "delight", "in", "owning", "anything", "unshared"],
    id: "There is no delight in owning anything unshared.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["no-one", "gets", "into", "my", "head"],
    words: ["no-one", "but", "me", "gets", "into", "my", "head"],
    id: "No-one but me gets into my head.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["curiosity", "one", "great", "secrets", "happiness"],
    words: ["curiosity", "is", "one", "of", "the", "great", "secrets", "happiness"],
    id: "Curiosity is one of the great secrets of happiness.",
    author: "Bryant H. McGill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["everything", "one", "thing", "impossible", "rationality"],
    words: ["in", "everything", "one", "thing", "is", "impossible", "rationality"],
    id: "In everything one thing is impossible: rationality.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["flow", "words", "sure", "sign", "duplicity"],
    words: ["a", "flow", "of", "words", "is", "sure", "sign", "duplicity"],
    id: "A flow of words is a sure sign of duplicity.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["well-timed", "silence", "most", "commanding", "expression"],
    words: ["well-timed", "silence", "is", "the", "most", "commanding", "expression"],
    id: "Well-timed silence is the most commanding expression.",
    author: "Mark Helprin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["black", "beauty", "-", "dark", "horse"],
    words: ["black", "beauty", "-", "he's", "a", "dark", "horse"],
    id: "Black beauty - he's a dark horse.",
    author: "Tim Vine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["words", "mere", "matter", "from", "heart"],
    words: ["words", "mere", "no", "matter", "from", "the", "heart"],
    id: "Words, words, mere words, no matter from the heart.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["misfortune", "shows", "those", "really", "friends"],
    words: ["misfortune", "shows", "those", "who", "are", "not", "really", "friends"],
    id: "Misfortune shows those who are not really friends.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["laughs", "best", "will", "also", "last"],
    words: ["he", "who", "laughs", "best", "today", "will", "also", "last"],
    id: "He who laughs best today, will also laughs last.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["nothing", "as", "obnoxious", "people's", "luck"],
    words: ["nothing", "is", "as", "obnoxious", "other", "people's", "luck"],
    id: "Nothing is as obnoxious as other people's luck.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["suffering", "one", "life's", "great", "teachers"],
    words: ["suffering", "is", "one", "of", "life's", "great", "teachers"],
    id: "Suffering is one of life's great teachers.",
    author: "Bryant H. McGill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["hypocrisy", "homage", "vice", "pays", "virtue"],
    words: ["hypocrisy", "is", "the", "homage", "vice", "pays", "to", "virtue"],
    id: "Hypocrisy is the homage vice pays to virtue.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["refrain", "from", "imitation", "best", "revenge"],
    words: ["to", "refrain", "from", "imitation", "is", "the", "best", "revenge"],
    id: "To refrain from imitation is the best revenge.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "well-read", "when", "i", "read"],
    words: ["i'm", "not", "well-read", "but", "when", "i", "read", "well"],
    id: "I'm not well-read, but when I read, I read well.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["luck", "matter", "preparation", "meeting", "opportunity"],
    words: ["luck", "is", "a", "matter", "of", "preparation", "meeting", "opportunity"],
    id: "Luck is a matter of preparation meeting opportunity.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["liberty", "must", "limited", "order", "possessed"],
    words: ["liberty", "must", "be", "limited", "in", "order", "to", "possessed"],
    id: "Liberty must be limited in order to be possessed.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["belief", "cruel", "god", "makes", "man"],
    words: ["belief", "in", "a", "cruel", "god", "makes", "man"],
    id: "Belief in a cruel God makes a cruel man.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "place", "means", "man", "alive"],
    words: ["there's", "place", "and", "means", "for", "every", "man", "alive"],
    id: "There's place and means for every man alive.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["shame", "may", "restrain", "law", "prohibit"],
    words: ["shame", "may", "restrain", "what", "law", "does", "not", "prohibit"],
    id: "Shame may restrain what law does not prohibit.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["ignorance", "root", "stem", "all", "evil"],
    words: ["ignorance", "the", "root", "and", "stem", "of", "all", "evil"],
    id: "Ignorance, the root and stem of all evil.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "problems", "never", "cease", "change"],
    words: ["your", "problems", "never", "cease", "they", "just", "change"],
    id: "Your problems never cease. They just change.",
    author: "Phil Jackson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["rockets", "cool", "there's", "getting", "around"],
    words: ["rockets", "are", "cool", "there's", "no", "getting", "around", "that"],
    id: "Rockets are cool. There's no getting around that.",
    author: "Elon Musk",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["less", "more", "unless", "al", "gore"],
    words: ["less", "is", "more", "unless", "you're", "al", "gore"],
    id: "Less is more, unless you're Al Gore.",
    author: "Unknown",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "will", "box", "passion", "devotion"],
    words: ["i", "will", "box", "with", "passion", "and", "devotion"],
    id: "I will box with passion and devotion.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["habit", "resisted", "soon", "becomes", "necessity"],
    words: ["habit", "if", "not", "resisted", "soon", "becomes", "necessity"],
    id: "Habit, if not resisted, soon becomes necessity.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "quit", "winners", "quitters", "win"],
    words: ["you", "can", "never", "quit", "winners", "and", "quitters", "win"],
    id: "You can never quit. Winners never quit, and quitters never win.",
    author: "Ted Turner",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "pat", "robertson", "terrific", "fellow"],
    words: ["i", "think", "pat", "robertson", "is", "a", "terrific", "fellow"],
    id: "I think Pat Robertson is a terrific fellow.",
    author: "Billy Graham",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["id", "was", "there", "ego", "shall"],
    words: ["where", "id", "was", "there", "ego", "shall", "be"],
    id: "Where id was, there ego shall be.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "weapon", "against", "enemy", "another"],
    words: ["the", "best", "weapon", "against", "an", "enemy", "is", "another"],
    id: "The best weapon against an enemy is another enemy.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["at", "center", "non-violence", "stands", "principle"],
    words: ["at", "the", "center", "of", "non-violence", "stands", "principle", "love"],
    id: "At the center of non-violence stands the principle of love.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "name", "can't", "tough", "pronounce"],
    words: ["my", "name", "can't", "be", "that", "tough", "to", "pronounce"],
    id: "My name can't be that tough to pronounce!",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["may", "these", "vows", "marriage", "blessed"],
    words: ["may", "these", "vows", "and", "this", "marriage", "be", "blessed"],
    id: "May these vows and this marriage be blessed.",
    author: "Rumi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["intolerance", "betrays", "faith", "one's", "cause"],
    words: ["intolerance", "betrays", "want", "of", "faith", "in", "one's", "cause"],
    id: "Intolerance betrays want of faith in one's cause.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "will", "wisdom", "finds", "way"],
    words: ["be", "there", "a", "will", "and", "wisdom", "finds", "way"],
    id: "Be there a will, and wisdom finds a way.",
    author: "George Crabbe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "prepared", "speaker", "deserves", "confident"],
    words: ["only", "the", "prepared", "speaker", "deserves", "to", "be", "confident"],
    id: "Only the prepared speaker deserves to be confident.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["honor", "simply", "morality", "superior", "men"],
    words: ["honor", "is", "simply", "the", "morality", "of", "superior", "men"],
    id: "Honor is simply the morality of superior men.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["neurosis", "always", "substitute", "legitimate", "suffering"],
    words: ["neurosis", "is", "always", "a", "substitute", "for", "legitimate", "suffering"],
    id: "Neurosis is always a substitute for legitimate suffering.",
    author: "Carl Jung",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["temptation", "woman's", "weapon", "man's", "excuse"],
    words: ["temptation", "is", "a", "woman's", "weapon", "and", "man's", "excuse"],
    id: "Temptation is a woman's weapon and man's excuse.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["struggle", "between", "yourself", "world", "second"],
    words: ["in", "the", "struggle", "between", "yourself", "and", "world", "second"],
    id: "In the struggle between yourself and the world second the world.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["more", "violent", "storm", "quicker", "passes"],
    words: ["the", "more", "violent", "storm", "quicker", "it", "passes"],
    id: "The more violent the storm, the quicker it passes.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["wisdom", "attained", "by", "years", "ability"],
    words: ["wisdom", "is", "not", "attained", "by", "years", "but", "ability"],
    id: "Wisdom is not attained by years, but by ability.",
    author: "Plautus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["without", "music", "life", "would", "mistake"],
    words: ["without", "music", "life", "would", "be", "a", "mistake"],
    id: "Without music, life would be a mistake.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["fear", "exist", "anywhere", "except", "mind"],
    words: ["fear", "doesn't", "exist", "anywhere", "except", "in", "the", "mind"],
    id: "Fear doesn't exist anywhere except in the mind.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "constant", "technology", "industry", "change"],
    words: ["the", "only", "constant", "in", "technology", "industry", "is", "change"],
    id: "The only constant in the technology industry is change.",
    author: "Marc Benioff",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "grow", "aren't", "really", "living"],
    words: ["if", "we", "don't", "change", "grow", "aren't", "really", "living"],
    id: "If we don't change, we don't grow. If we don't grow, we aren't really living.",
    author: "Gail Sheehy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "half", "man", "posterity", "entirely"],
    words: ["what", "life", "half", "gives", "a", "man", "posterity", "entirely"],
    id: "What life half gives a man, posterity gives entirely.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "faithfulness", "makes", "trustworthy", "god"],
    words: ["your", "faithfulness", "makes", "you", "trustworthy", "to", "god"],
    id: "Your faithfulness makes you trustworthy to God.",
    author: "Edwin Louis Cole",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["home", "alone", "was", "movie", "alibi"],
    words: ["home", "alone", "was", "a", "movie", "not", "an", "alibi"],
    id: "'Home Alone' was a movie, not an alibi.",
    author: "Jerry Orbach",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["whatever", "makes", "men", "christians", "citizens"],
    words: ["whatever", "makes", "men", "good", "christians", "them", "citizens"],
    id: "Whatever makes men good Christians, makes them good citizens.",
    author: "Daniel Webster",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["along", "success", "comes", "reputation", "wisdom"],
    words: ["along", "with", "success", "comes", "a", "reputation", "for", "wisdom"],
    id: "Along with success comes a reputation for wisdom.",
    author: "Euripides",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["voters", "quickly", "forget", "man", "says"],
    words: ["voters", "quickly", "forget", "what", "a", "man", "says"],
    id: "Voters quickly forget what a man says.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["words", "worth", "much", "cost", "little"],
    words: ["good", "words", "are", "worth", "much", "and", "cost", "little"],
    id: "Good words are worth much, and cost little.",
    author: "George Herbert",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["successful", "one", "must", "take", "chances"],
    words: ["to", "be", "successful", "one", "must", "take", "chances"],
    id: "To be successful, one must take chances.",
    author: "Willie Stargell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["start", "right", "rather", "than", "acceptable"],
    words: ["start", "with", "what", "is", "right", "rather", "than", "acceptable"],
    id: "Start with what is right rather than what is acceptable.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["alas", "i", "woman", "friendless", "hopeless"],
    words: ["alas", "i", "am", "a", "woman", "friendless", "hopeless"],
    id: "Alas, I am a woman friendless, hopeless!",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["rhetoric", "art", "ruling", "minds", "men"],
    words: ["rhetoric", "is", "the", "art", "of", "ruling", "minds", "men"],
    id: "Rhetoric is the art of ruling the minds of men.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "afford", "killing", "one", "another"],
    words: ["we", "can't", "afford", "to", "be", "killing", "one", "another"],
    id: "We can't afford to be killing one another.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "most", "important", "thing", "all"],
    words: ["friendship", "is", "the", "most", "important", "thing", "of", "all"],
    id: "Friendship is the most important thing of all.",
    author: "Kim Philby",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["real", "firmness", "anything", "strut", "nothing"],
    words: ["real", "firmness", "is", "good", "for", "anything", "strut", "nothing"],
    id: "Real firmness is good for anything; strut is good for nothing.",
    author: "Alexander Hamilton",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["alliances", "made", "strong", "capable", "partners"],
    words: ["good", "alliances", "are", "made", "of", "strong", "capable", "partners"],
    id: "Good alliances are made of strong, capable partners.",
    author: "Mark Esper",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "screwed", "up", "real", "didn't"],
    words: ["well", "i", "screwed", "it", "up", "real", "good", "didn't"],
    id: "Well, I screwed it up real good, didn't I?",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["chaotic", "action", "preferable", "orderly", "inaction"],
    words: ["chaotic", "action", "is", "preferable", "to", "orderly", "inaction"],
    id: "Chaotic action is preferable to orderly inaction.",
    author: "Will Rogers",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["must", "show", "world", "abhor", "fighting"],
    words: ["you", "must", "show", "the", "world", "that", "abhor", "fighting"],
    id: "You must show the world that you abhor fighting.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["most", "certain", "sign", "wisdom", "cheerfulness"],
    words: ["the", "most", "certain", "sign", "of", "wisdom", "is", "cheerfulness"],
    id: "The most certain sign of wisdom is cheerfulness.",
    author: "Michel de Montaigne",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["jaw-jaw", "always", "better", "than", "war-war"],
    words: ["to", "jaw-jaw", "is", "always", "better", "than", "war-war"],
    id: "To jaw-jaw is always better than to war-war.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "creative", "reading", "as", "writing"],
    words: ["there", "is", "creative", "reading", "as", "well", "writing"],
    id: "There is creative reading as well as creative writing.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["follow", "by", "faith", "alone", "blindly"],
    words: ["to", "follow", "by", "faith", "alone", "is", "blindly"],
    id: "To Follow by faith alone is to follow blindly.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["soul", "healed", "by", "being", "children"],
    words: ["the", "soul", "is", "healed", "by", "being", "with", "children"],
    id: "The soul is healed by being with children.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["patience", "diligence", "faith", "remove", "mountains"],
    words: ["patience", "and", "diligence", "like", "faith", "remove", "mountains"],
    id: "Patience and Diligence, like faith, remove mountains.",
    author: "William Penn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["fashion", "imposition", "reign", "on", "freedom"],
    words: ["fashion", "is", "an", "imposition", "a", "reign", "on", "freedom"],
    id: "Fashion is an imposition, a reign on freedom.",
    author: "Golda Meir",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["old", "guys", "still", "fun", "things"],
    words: ["old", "guys", "can", "still", "do", "fun", "things"],
    id: "Old guys can still do fun things.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["safe", "fairyland", "untrue", "all", "worlds"],
    words: ["a", "safe", "fairyland", "is", "untrue", "to", "all", "worlds"],
    id: "A safe fairyland is untrue to all worlds.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["nature", "always", "wears", "colors", "spirit"],
    words: ["nature", "always", "wears", "the", "colors", "of", "spirit"],
    id: "Nature always wears the colors of the spirit.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["ideas", "beginning", "points", "all", "fortunes"],
    words: ["ideas", "are", "the", "beginning", "points", "of", "all", "fortunes"],
    id: "Ideas are the beginning points of all fortunes.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["fill", "earth", "your", "songs", "gratitude"],
    words: ["fill", "the", "earth", "with", "your", "songs", "of", "gratitude"],
    id: "Fill the earth with your songs of gratitude.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["judgment", "comes", "from", "experience", "bad"],
    words: ["good", "judgment", "comes", "from", "experience", "and", "bad"],
    id: "Good judgment comes from experience and experience comes from bad judgment.",
    author: "Fred Brooks",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "wisdom", "share", "on", "dating"],
    words: ["i", "have", "no", "wisdom", "to", "share", "on", "dating"],
    id: "I have no wisdom to share on dating.",
    author: "Rachel Dratch",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["light", "troubles", "weighty", "struck", "dumb"],
    words: ["light", "troubles", "speak", "the", "weighty", "are", "struck", "dumb"],
    id: "Light troubles speak; the weighty are struck dumb.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["trendy", "can't", "even", "escape", "ourselves"],
    words: ["we're", "so", "trendy", "we", "can't", "even", "escape", "ourselves"],
    id: "We're so trendy we can't even escape ourselves.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "its", "illusions", "less", "than"],
    words: ["friendship", "has", "its", "illusions", "no", "less", "than", "love"],
    id: "Friendship has its illusions no less than love.",
    author: "Stendhal",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["little", "soul", "carrying", "around", "corpse"],
    words: ["you", "are", "a", "little", "soul", "carrying", "around", "corpse"],
    id: "You are a little soul carrying around a corpse.",
    author: "Epictetus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "advice", "-", "only", "corroboration"],
    words: ["no", "one", "wants", "advice", "-", "only", "corroboration"],
    id: "No one wants advice - only corroboration.",
    author: "John Steinbeck",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["saw", "heat", "wave", "would", "back"],
    words: ["if", "you", "saw", "a", "heat", "wave", "would", "back"],
    id: "If you saw a heat wave, would you wave back?",
    author: "Steven Wright",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["passion", "strife", "bow", "down", "mind"],
    words: ["passion", "and", "strife", "bow", "down", "the", "mind"],
    id: "Passion and strife bow down the mind.",
    author: "Virgil",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["minute's", "success", "pays", "failure", "years"],
    words: ["a", "minute's", "success", "pays", "the", "failure", "of", "years"],
    id: "A minute's success pays the failure of years.",
    author: "Robert Browning",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["road", "success", "always", "under", "construction"],
    words: ["the", "road", "to", "success", "is", "always", "under", "construction"],
    id: "The road to success is always under construction.",
    author: "Lily Tomlin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "gave", "admitted", "god", "was"],
    words: ["i", "gave", "in", "and", "admitted", "that", "god", "was"],
    id: "I gave in, and admitted that God was God.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["thick", "skin", "gift", "from", "god"],
    words: ["a", "thick", "skin", "is", "gift", "from", "god"],
    id: "A thick skin is a gift from God.",
    author: "Konrad Adenauer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowledge", "will", "power", "character", "respect"],
    words: ["knowledge", "will", "give", "you", "power", "but", "character", "respect"],
    id: "Knowledge will give you power, but character respect.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowledge", "most", "democratic", "source", "power"],
    words: ["knowledge", "is", "the", "most", "democratic", "source", "of", "power"],
    id: "Knowledge is the most democratic source of power.",
    author: "Alvin Toffler",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "enemy", "individual", "freedom", "himself"],
    words: ["the", "greatest", "enemy", "of", "individual", "freedom", "is", "himself"],
    id: "The greatest enemy of individual freedom is the individual himself.",
    author: "Saul Alinsky",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["should", "ask", "questions", "without", "knowledge"],
    words: ["you", "should", "not", "ask", "questions", "without", "knowledge"],
    id: "You should not ask questions without knowledge.",
    author: "W. Edwards Deming",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["innovation", "distinguishes", "between", "leader", "follower"],
    words: ["innovation", "distinguishes", "between", "a", "leader", "and", "follower"],
    id: "Innovation distinguishes between a leader and a follower.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["facts", "liberals", "strong", "suit", "rhetoric"],
    words: ["facts", "are", "not", "liberals", "strong", "suit", "rhetoric", "is"],
    id: "Facts are not liberals' strong suit. Rhetoric is.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "wasted", "time", "doth", "waste"],
    words: ["i", "wasted", "time", "and", "now", "doth", "waste", "me"],
    id: "I wasted time, and now doth time waste me.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["cannot", "believe", "god", "until", "yourself"],
    words: ["you", "cannot", "believe", "in", "god", "until", "yourself"],
    id: "You cannot believe in God until you believe in yourself.",
    author: "Swami Vivekananda",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["showing", "off", "fool's", "idea", "glory"],
    words: ["showing", "off", "is", "the", "fool's", "idea", "of", "glory"],
    id: "Showing off is the fool's idea of glory.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "generally", "read", "lot", "fiction"],
    words: ["i", "don't", "generally", "read", "a", "lot", "of", "fiction"],
    id: "I don't generally read a lot of fiction.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "haven't", "got", "lot", "patience"],
    words: ["i", "haven't", "got", "a", "lot", "of", "patience"],
    id: "I haven't got a lot of patience.",
    author: "Jeffrey Katzenberg",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["nothing", "external", "any", "power", "over"],
    words: ["nothing", "external", "to", "you", "has", "any", "power", "over"],
    id: "Nothing external to you has any power over you.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["read", "without", "reflecting", "eating", "digesting"],
    words: ["to", "read", "without", "reflecting", "is", "like", "eating", "digesting"],
    id: "To read without reflecting is like eating without digesting.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["as", "long", "live", "keep", "how"],
    words: ["as", "long", "you", "live", "keep", "learning", "how", "to"],
    id: "As long as you live, keep learning how to live.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["each", "bears", "his", "own", "hell"],
    words: ["each", "of", "us", "bears", "his", "own", "hell"],
    id: "Each of us bears his own Hell.",
    author: "Virgil",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["beauty", "without", "grace", "hook", "bait"],
    words: ["beauty", "without", "grace", "is", "the", "hook", "bait"],
    id: "Beauty without grace is the hook without the bait.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["wear", "smile", "friends", "scowl", "wrinkles"],
    words: ["wear", "a", "smile", "and", "have", "friends", "scowl", "wrinkles"],
    id: "Wear a smile and have friends; wear a scowl and have wrinkles.",
    author: "George Eliot",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "coming", "consciousness", "without", "pain"],
    words: ["there", "is", "no", "coming", "to", "consciousness", "without", "pain"],
    id: "There is no coming to consciousness without pain.",
    author: "Carl Jung",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "nature", "art", "unknown", "thee"],
    words: ["all", "nature", "is", "but", "art", "unknown", "to", "thee"],
    id: "All nature is but art unknown to thee.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["harder", "preserve", "than", "obtain", "liberty"],
    words: ["it", "is", "harder", "to", "preserve", "than", "obtain", "liberty"],
    id: "It is harder to preserve than to obtain liberty.",
    author: "John C. Calhoun",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["important", "principles", "may", "must", "inflexible"],
    words: ["important", "principles", "may", "and", "must", "be", "inflexible"],
    id: "Important principles may, and must, be inflexible.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["fell", "down", "yesterday", "stand", "up"],
    words: ["if", "you", "fell", "down", "yesterday", "stand", "up", "today"],
    id: "If you fell down yesterday, stand up today.",
    author: "H. G. Wells",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["by", "time", "we've", "made", "had"],
    words: ["by", "the", "time", "we've", "made", "it", "had"],
    id: "By the time we've made it, we've had it.",
    author: "Malcolm Forbes",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["management", "nothing", "more", "than", "motivating"],
    words: ["management", "is", "nothing", "more", "than", "motivating", "other", "people"],
    id: "Management is nothing more than motivating other people.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["call", "terrorists", "i", "freedom", "fighters"],
    words: ["they", "call", "them", "terrorists", "i", "freedom", "fighters"],
    id: "They call them terrorists, I call them freedom fighters.",
    author: "Louis Farrakhan",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "suffer", "anything", "i've", "lost"],
    words: ["i", "don't", "suffer", "of", "anything", "that", "i've", "lost"],
    id: "I don't suffer of anything that I've lost.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["sid", "vicious", "cool", "super", "chill"],
    words: ["to", "me", "sid", "vicious", "is", "cool", "super", "chill"],
    id: "To me, Sid Vicious is cool. Super chill.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "matter", "really", "tough", "choices"],
    words: ["life", "is", "a", "matter", "of", "really", "tough", "choices"],
    id: "Life is a matter of really tough choices.",
    author: "Joe Biden",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["first", "always", "occupies", "special", "place"],
    words: ["a", "first", "love", "always", "occupies", "special", "place"],
    id: "A first love always occupies a special place.",
    author: "Lee Konitz",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "plan", "future", "by", "past"],
    words: ["you", "can", "never", "plan", "the", "future", "by", "past"],
    id: "You can never plan the future by the past.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "trying", "on", "my", "patience"],
    words: ["i'm", "trying", "to", "work", "on", "my", "patience"],
    id: "I'm trying to work on my patience.",
    author: "Stassi Schroeder",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["many", "hanging", "prevents", "bad", "marriage"],
    words: ["many", "a", "good", "hanging", "prevents", "bad", "marriage"],
    id: "Many a good hanging prevents a bad marriage.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["fools", "rush", "angels", "fear", "tread"],
    words: ["fools", "rush", "in", "where", "angels", "fear", "to", "tread"],
    id: "Fools rush in where angels fear to tread.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["derivatives", "financial", "weapons", "mass", "destruction"],
    words: ["derivatives", "are", "financial", "weapons", "of", "mass", "destruction"],
    id: "Derivatives are financial weapons of mass destruction.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["spend", "life", "something", "which", "outlasts"],
    words: ["to", "spend", "life", "for", "something", "which", "outlasts", "it"],
    id: "To spend life for something which outlasts it.",
    author: "William James",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["o", "goodly", "outside", "falsehood", "hath"],
    words: ["o", "what", "a", "goodly", "outside", "falsehood", "hath"],
    id: "O, what a goodly outside falsehood hath!",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "man", "ever", "complains", "opportunity"],
    words: ["no", "great", "man", "ever", "complains", "of", "want", "opportunity"],
    id: "No great man ever complains of want of opportunity.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["nothing", "happen", "more", "than", "death"],
    words: ["nothing", "can", "happen", "more", "beautiful", "than", "death"],
    id: "Nothing can happen more beautiful than death.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["evil", "god's", "right", "hand", "left"],
    words: ["evil", "and", "good", "are", "god's", "right", "hand", "left"],
    id: "Evil and good are God's right hand and left.",
    author: "Horace Mann",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["took", "edge", "off", "word", "computer"],
    words: ["apple", "took", "the", "edge", "off", "word", "computer"],
    id: "Apple took the edge off the word 'computer.'",
    author: "Steve Jobs",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "myself", "i'm", "growing", "maturing"],
    words: ["i", "know", "myself", "i'm", "learning", "growing", "maturing"],
    id: "I know myself, I'm learning myself. I'm growing, I'm maturing.",
    author: "PartyNextDoor",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["fall", "dropping", "wears", "away", "stone"],
    words: ["the", "fall", "of", "dropping", "water", "wears", "away", "stone"],
    id: "The fall of dropping water wears away the Stone.",
    author: "Lucretius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["wisdom", "knowing", "when", "can't", "wise"],
    words: ["wisdom", "is", "knowing", "when", "you", "can't", "be", "wise"],
    id: "Wisdom is knowing when you can't be wise.",
    author: "Paul Engle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["sometimes", "victim", "your", "own", "success"],
    words: ["sometimes", "you're", "a", "victim", "of", "your", "own", "success"],
    id: "Sometimes you're a victim of your own success.",
    author: "Jan Vertonghen",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "really", "isn't", "formula", "success"],
    words: ["there", "really", "isn't", "a", "formula", "for", "success"],
    id: "There really isn't a formula for success.",
    author: "Romeo Santos",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "failure", "except", "longer", "trying"],
    words: ["there", "is", "no", "failure", "except", "in", "longer", "trying"],
    id: "There is no failure except in no longer trying.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i've", "said", "my", "patience", "infinite"],
    words: ["i've", "said", "my", "patience", "is", "not", "infinite"],
    id: "I've said my patience is not infinite.",
    author: "Darrell Issa",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "found", "doing", "merely", "possessing"],
    words: ["happiness", "is", "found", "in", "doing", "not", "merely", "possessing"],
    id: "Happiness is found in doing, not merely possessing.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "glad", "i", "was", "navy"],
    words: ["i'm", "glad", "i", "was", "in", "the", "navy"],
    id: "I'm glad I was in the Navy.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["persevere", "preserve", "yourselves", "better", "circumstances"],
    words: ["persevere", "and", "preserve", "yourselves", "for", "better", "circumstances"],
    id: "Persevere and preserve yourselves for better circumstances.",
    author: "Virgil",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["opinion", "medium", "between", "knowledge", "ignorance"],
    words: ["opinion", "is", "the", "medium", "between", "knowledge", "and", "ignorance"],
    id: "Opinion is the medium between knowledge and ignorance.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["discipline", "bridge", "between", "goals", "accomplishment"],
    words: ["discipline", "is", "the", "bridge", "between", "goals", "and", "accomplishment"],
    id: "Discipline is the bridge between goals and accomplishment.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["probable", "impossibilities", "preferred", "improbable", "possibilities"],
    words: [
      "probable",
      "impossibilities",
      "are",
      "to",
      "be",
      "preferred",
      "improbable",
      "possibilities",
    ],
    id: "Probable impossibilities are to be preferred to improbable possibilities.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "really", "milk", "i'm", "dairy"],
    words: ["i", "really", "like", "milk", "i'm", "a", "dairy", "queen"],
    id: "I really like milk. I'm a dairy queen.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["costly", "wisdom", "bought", "by", "experience"],
    words: ["it", "is", "costly", "wisdom", "that", "bought", "by", "experience"],
    id: "It is costly wisdom that is bought by experience.",
    author: "Roger Ascham",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["most", "important", "thing", "world", "family"],
    words: ["the", "most", "important", "thing", "in", "world", "is", "family", "and", "love"],
    id: "The most important thing in the world is family and love.",
    author: "John Wooden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "respect", "everybody", "around", "peace"],
    words: ["when", "we", "respect", "everybody", "around", "us", "are", "in", "peace", "with"],
    id: "When we respect everybody around us, we are in peace with everybody around us.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "believe", "god", "i'm", "interested"],
    words: ["i", "don't", "believe", "in", "god", "but", "i'm", "very", "interested", "her"],
    id: "I don't believe in God but I'm very interested in her.",
    author: "Arthur C. Clarke",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["writing", "boring", "takes", "much", "patience"],
    words: ["writing", "is", "boring", "very", "and", "it", "takes", "so", "much", "patience"],
    id: "Writing is boring, very boring, and it takes so much patience.",
    author: "Ingmar Bergman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["remember", "extra", "everyone", "else's", "play"],
    words: ["remember", "you", "are", "just", "an", "extra", "in", "everyone", "else's", "play"],
    id: "Remember you are just an extra in everyone else's play.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "humblest", "person", "world", "admit"],
    words: ["i", "am", "not", "the", "humblest", "person", "in", "world", "admit", "that"],
    id: "I am not the humblest person in the world. I admit that.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["grab", "chance", "won't", "sorry", "might-have-been"],
    words: ["grab", "a", "chance", "and", "you", "won't", "be", "sorry", "for", "might-have-been"],
    id: "Grab a chance and you won't be sorry for a might-have-been.",
    author: "Arthur Ransome",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["mind", "unlearns", "difficulty", "long", "learned"],
    words: [
      "the",
      "mind",
      "unlearns",
      "with",
      "difficulty",
      "what",
      "it",
      "has",
      "long",
      "learned",
    ],
    id: "The mind unlearns with difficulty what it has long learned.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "film", "should", "seem", "time"],
    words: ["every", "great", "film", "should", "seem", "new", "time", "you", "see", "it"],
    id: "Every great film should seem new every time you see it.",
    author: "Roger Ebert",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["ability", "will", "never", "up", "demand"],
    words: ["ability", "will", "never", "catch", "up", "with", "the", "demand", "for", "it"],
    id: "Ability will never catch up with the demand for it.",
    author: "Confucius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "i'm", "supposed", "muscle", "cars"],
    words: ["i", "know", "i'm", "not", "supposed", "to", "like", "muscle", "cars", "but"],
    id: "I know I'm not supposed to like muscle cars, but I like muscle cars.",
    author: "Joe Biden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["peace", "talk", "your", "friends", "enemies"],
    words: ["if", "you", "want", "peace", "don't", "talk", "to", "your", "friends", "enemies"],
    id: "If you want peace, you don't talk to your friends. You talk to your enemies.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["philosophers", "greatest", "get", "fewest", "rewards"],
    words: [
      "dogs",
      "and",
      "philosophers",
      "do",
      "the",
      "greatest",
      "good",
      "get",
      "fewest",
      "rewards",
    ],
    id: "Dogs and philosophers do the greatest good and get the fewest rewards.",
    author: "Diogenes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["debt", "owe", "play", "imagination", "incalculable"],
    words: ["the", "debt", "we", "owe", "to", "play", "of", "imagination", "is", "incalculable"],
    id: "The debt we owe to the play of imagination is incalculable.",
    author: "Carl Jung",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["selfishness", "one", "qualities", "apt", "inspire"],
    words: ["selfishness", "is", "one", "of", "the", "qualities", "apt", "to", "inspire", "love"],
    id: "Selfishness is one of the qualities apt to inspire love.",
    author: "Nathaniel Hawthorne",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["clearly", "budget", "got", "lot", "numbers"],
    words: ["it's", "clearly", "a", "budget", "got", "lot", "of", "numbers", "in", "it"],
    id: "It's clearly a budget. It's got a lot of numbers in it.",
    author: "George W. Bush",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "one", "stage", "peasant", "actor"],
    words: ["there", "is", "but", "one", "stage", "for", "the", "peasant", "and", "actor"],
    id: "There is but one stage for the peasant and the actor.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["first", "then", "adorn", "yourself", "accordingly"],
    words: [
      "know",
      "first",
      "who",
      "you",
      "are",
      "and",
      "then",
      "adorn",
      "yourself",
      "accordingly",
    ],
    id: "Know, first, who you are, and then adorn yourself accordingly.",
    author: "Epictetus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["come", "nearest", "great", "when", "humility"],
    words: ["we", "come", "nearest", "to", "the", "great", "when", "are", "in", "humility"],
    id: "We come nearest to the great when we are great in humility.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["witness", "two", "lovers", "spectacle", "gods"],
    words: ["to", "witness", "two", "lovers", "is", "a", "spectacle", "for", "the", "gods"],
    id: "To witness two lovers is a spectacle for the gods.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["self-limitation", "master", "first", "shows", "himself"],
    words: [
      "it",
      "is",
      "in",
      "self-limitation",
      "that",
      "a",
      "master",
      "first",
      "shows",
      "himself",
    ],
    id: "It is in self-limitation that a master first shows himself.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["art", "being", "wise", "knowing", "overlook"],
    words: ["the", "art", "of", "being", "wise", "is", "knowing", "what", "to", "overlook"],
    id: "The art of being wise is the art of knowing what to overlook.",
    author: "William James",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["reason", "always", "existed", "reasonable", "form"],
    words: ["reason", "has", "always", "existed", "but", "not", "in", "a", "reasonable", "form"],
    id: "Reason has always existed, but not always in a reasonable form.",
    author: "Karl Marx",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["anyone", "chance", "drive", "ferrari", "will"],
    words: ["anyone", "who", "has", "the", "chance", "to", "drive", "for", "ferrari", "will"],
    id: "Anyone who has the chance to drive for Ferrari will drive for Ferrari.",
    author: "Charles Leclerc",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "must", "poor", "luxury", "giving"],
    words: ["one", "must", "be", "poor", "to", "know", "the", "luxury", "of", "giving"],
    id: "One must be poor to know the luxury of giving!",
    author: "George Eliot",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["wise", "father", "his", "own", "child"],
    words: ["it", "is", "a", "wise", "father", "that", "knows", "his", "own", "child"],
    id: "It is a wise father that knows his own child.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["easier", "wise", "others", "than", "ourselves"],
    words: ["it", "is", "easier", "to", "be", "wise", "for", "others", "than", "ourselves"],
    id: "It is easier to be wise for others than for ourselves.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["health", "hygiene", "basic", "personal", "all"],
    words: ["health", "and", "hygiene", "are", "a", "basic", "personal", "need", "for", "all"],
    id: "Health and hygiene are a basic personal need for all.",
    author: "Manushi Chhillar",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["music", "best", "means", "digesting", "time"],
    words: ["music", "is", "the", "best", "means", "we", "have", "of", "digesting", "time"],
    id: "Music is the best means we have of digesting time.",
    author: "W. H. Auden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["nice", "whites", "rediscover", "their", "humanity"],
    words: ["be", "nice", "to", "whites", "they", "need", "you", "rediscover", "their", "humanity"],
    id: "Be nice to whites, they need you to rediscover their humanity.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["better", "alone", "than", "bad", "company"],
    words: ["it", "is", "better", "to", "be", "alone", "than", "in", "bad", "company"],
    id: "It is better to be alone than in bad company.",
    author: "George Washington",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "art", "contempt", "man", "small"],
    words: ["great", "art", "is", "the", "contempt", "of", "a", "man", "for", "small"],
    id: "Great art is the contempt of a great man for small art.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["whether", "or", "can't", "usually", "right"],
    words: ["whether", "you", "think", "that", "can", "or", "can't", "are", "usually", "right"],
    id: "Whether you think that you can, or that you can't, you are usually right.",
    author: "Henry Ford",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "goes", "ah", "alas", "stays"],
    words: ["time", "goes", "you", "say", "ah", "no", "alas", "stays", "we", "go"],
    id: "Time goes, you say? Ah, no! alas, time stays, we go.",
    author: "Henry Austin Dobson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["refusal", "praise", "desire", "praised", "twice"],
    words: ["a", "refusal", "of", "praise", "is", "desire", "to", "be", "praised", "twice"],
    id: "A refusal of praise is a desire to be praised twice.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["conquer", "self", "will", "conquered", "by"],
    words: ["if", "you", "do", "not", "conquer", "self", "will", "be", "conquered", "by"],
    id: "If you do not conquer self, you will be conquered by self.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["matters", "conscience", "law", "majority", "place"],
    words: ["in", "matters", "of", "conscience", "the", "law", "majority", "has", "no", "place"],
    id: "In matters of conscience, the law of the majority has no place.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["right", "ours", "must", "use", "will"],
    words: ["the", "right", "is", "ours", "have", "it", "we", "must", "use", "will"],
    id: "The right is ours. Have it we must. Use it we will.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["blessed", "merciful", "will", "shown", "mercy"],
    words: ["blessed", "are", "the", "merciful", "for", "they", "will", "be", "shown", "mercy"],
    id: "Blessed are the merciful, for they will be shown mercy.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["little", "knowledge", "dangerous", "thing", "also"],
    words: ["a", "little", "knowledge", "is", "dangerous", "thing", "but", "want", "of", "also"],
    id: "A little knowledge is a dangerous thing, but a little want of knowledge is also a dangerous thing.",
    author: "Samuel Butler",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "continuation", "happenings", "which", "resisted"],
    words: [
      "happiness",
      "is",
      "a",
      "continuation",
      "of",
      "happenings",
      "which",
      "are",
      "not",
      "resisted",
    ],
    id: "Happiness is a continuation of happenings which are not resisted.",
    author: "Deepak Chopra",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["christmas", "season", "only", "rejoicing", "reflection"],
    words: [
      "christmas",
      "is",
      "a",
      "season",
      "not",
      "only",
      "of",
      "rejoicing",
      "but",
      "reflection",
    ],
    id: "Christmas is a season not only of rejoicing but of reflection.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["win", "best", "thing", "lose", "next"],
    words: ["to", "love", "and", "win", "is", "the", "best", "thing", "lose", "next"],
    id: "To love and win is the best thing. To love and lose, the next best.",
    author: "William Makepeace Thackeray",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "level", "snobbery", "fickleness", "la"],
    words: ["there", "is", "a", "level", "of", "snobbery", "and", "fickleness", "in", "la"],
    id: "There is a level of snobbery and fickleness in L.A.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["whoever", "father", "should", "procure", "one"],
    words: ["whoever", "does", "not", "have", "a", "good", "father", "should", "procure", "one"],
    id: "Whoever does not have a good father should procure one.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["things", "ain't", "used", "never", "were"],
    words: ["things", "ain't", "what", "they", "used", "to", "be", "and", "never", "were"],
    id: "Things ain't what they used to be and never were.",
    author: "Will Rogers",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["wronged", "nothing", "unless", "continue", "remember"],
    words: ["to", "be", "wronged", "is", "nothing", "unless", "you", "continue", "remember", "it"],
    id: "To be wronged is nothing unless you continue to remember it.",
    author: "Confucius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["something", "change", "can't", "your", "attitude"],
    words: ["if", "you", "don't", "like", "something", "change", "it", "can't", "your", "attitude"],
    id: "If you don't like something, change it. If you can't change it, change your attitude.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["talk", "god", "praying", "talks", "schizophrenia"],
    words: ["if", "you", "talk", "to", "god", "are", "praying", "talks", "have", "schizophrenia"],
    id: "If you talk to God, you are praying; If God talks to you, you have schizophrenia.",
    author: "Thomas Szasz",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "certain", "little", "sounds", "i"],
    words: ["there's", "certain", "little", "sounds", "that", "i", "just", "like", "to", "hear"],
    id: "There's certain little sounds that I just like to hear.",
    author: "Lil Baby",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["easier", "tutor", "command", "than", "teach"],
    words: ["it", "is", "easier", "for", "a", "tutor", "to", "command", "than", "teach"],
    id: "It is easier for a tutor to command than to teach.",
    author: "John Locke",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["order", "friends", "must", "first", "one"],
    words: ["in", "order", "to", "have", "friends", "you", "must", "first", "be", "one"],
    id: "In order to have friends, you must first be one.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "world", "riches", "pleasure", "pride"],
    words: ["the", "god", "of", "this", "world", "is", "riches", "pleasure", "and", "pride"],
    id: "The God of this world is riches, pleasure and pride.",
    author: "Martin Luther",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["wisdom", "find", "greater", "than", "kindness"],
    words: ["what", "wisdom", "can", "you", "find", "that", "is", "greater", "than", "kindness"],
    id: "What wisdom can you find that is greater than kindness?",
    author: "Jean-Jacques Rousseau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["world", "static", "place", "change", "evolve"],
    words: ["the", "world", "is", "not", "a", "static", "place", "people", "change", "evolve"],
    id: "The world is not a static place. People change, evolve.",
    author: "Mahesh Bhatt",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["cannot", "great", "things", "small", "way"],
    words: ["if", "you", "cannot", "do", "great", "things", "small", "in", "a", "way"],
    id: "If you cannot do great things, do small things in a great way.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["politics", "pulpit", "terms", "little", "agreement"],
    words: [
      "politics",
      "and",
      "the",
      "pulpit",
      "are",
      "terms",
      "that",
      "have",
      "little",
      "agreement",
    ],
    id: "Politics and the pulpit are terms that have little agreement.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["dalai", "lama", "temporal", "leader", "tibet"],
    words: ["the", "dalai", "lama", "is", "just", "a", "temporal", "leader", "of", "tibet"],
    id: "The Dalai Lama is just a temporal leader of Tibet.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["ask", "will", "wish", "had", "got"],
    words: ["do", "not", "ask", "for", "what", "you", "will", "wish", "had", "got"],
    id: "Do not ask for what you will wish you had not got.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "or", "misery", "mind", "lives"],
    words: ["happiness", "or", "misery", "is", "in", "the", "mind", "it", "that", "lives"],
    id: "Happiness, or misery, is in the mind. It is the mind that lives.",
    author: "William Cobbett",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["their", "eyes", "i", "my", "nose"],
    words: ["people", "can", "drink", "with", "their", "eyes", "i", "eat", "my", "nose"],
    id: "People can drink with their eyes; I can eat with my nose.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["science", "knowledge", "only", "philosophy", "wisdom"],
    words: [
      "science",
      "gives",
      "us",
      "knowledge",
      "but",
      "only",
      "philosophy",
      "can",
      "give",
      "wisdom",
    ],
    id: "Science gives us knowledge, but only philosophy can give us wisdom.",
    author: "Will Durant",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["country", "dirtier", "fingernails", "cleaner", "minds"],
    words: [
      "what",
      "the",
      "country",
      "needs",
      "is",
      "dirtier",
      "fingernails",
      "and",
      "cleaner",
      "minds",
    ],
    id: "What the country needs is dirtier fingernails and cleaner minds.",
    author: "Will Rogers",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["less", "deserve", "fortune", "more", "hope"],
    words: ["the", "less", "we", "deserve", "good", "fortune", "more", "hope", "for", "it"],
    id: "The less we deserve good fortune, the more we hope for it.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "true", "wisdom", "knowing", "nothing"],
    words: ["the", "only", "true", "wisdom", "is", "in", "knowing", "you", "know", "nothing"],
    id: "The only true wisdom is in knowing you know nothing.",
    author: "Socrates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "anything", "hot", "strange"],
    words: ["i", "never", "drink", "anything", "hot", "don't", "like", "drinks", "very", "strange"],
    id: "I never drink anything hot; I don't like hot drinks, very strange.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["nothing", "too", "late", "lived", "yesterday"],
    words: [
      "tomorrow",
      "is",
      "nothing",
      "today",
      "too",
      "late",
      "the",
      "good",
      "lived",
      "yesterday",
    ],
    id: "Tomorrow is nothing, today is too late; the good lived yesterday.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["why", "watching", "fill", "one", "happiness"],
    words: ["why", "does", "watching", "a", "dog", "be", "fill", "one", "with", "happiness"],
    id: "Why does watching a dog be a dog fill one with happiness?",
    author: "Jonathan Safran Foer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["substance", "ambitious", "merely", "shadow", "dream"],
    words: ["the", "very", "substance", "of", "ambitious", "is", "merely", "shadow", "a", "dream"],
    id: "The very substance of the ambitious is merely the shadow of a dream.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["victory", "thousand", "fathers", "defeat", "orphan"],
    words: ["victory", "has", "a", "thousand", "fathers", "but", "defeat", "is", "an", "orphan"],
    id: "Victory has a thousand fathers, but defeat is an orphan.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["healing", "process", "starts", "graciousness", "forgiveness"],
    words: [
      "for",
      "me",
      "the",
      "healing",
      "process",
      "starts",
      "with",
      "graciousness",
      "and",
      "forgiveness",
    ],
    id: "For me, the healing process starts with graciousness and forgiveness.",
    author: "India Arie",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["york", "definitely", "ready", "word", "god"],
    words: ["new", "york", "is", "definitely", "ready", "for", "the", "word", "of", "god"],
    id: "New York is definitely ready for the word of God.",
    author: "Billy Graham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["legend", "lie", "attained", "dignity", "age"],
    words: ["legend", "a", "lie", "that", "has", "attained", "the", "dignity", "of", "age"],
    id: "Legend: A lie that has attained the dignity of age.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["accountability", "friendship", "equivalent", "without", "strategy"],
    words: [
      "accountability",
      "in",
      "friendship",
      "is",
      "the",
      "equivalent",
      "of",
      "love",
      "without",
      "strategy",
    ],
    id: "Accountability in friendship is the equivalent of love without strategy.",
    author: "Anita Brookner",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["order", "succeed", "must", "first", "believe"],
    words: ["in", "order", "to", "succeed", "we", "must", "first", "believe", "that", "can"],
    id: "In order to succeed, we must first believe that we can.",
    author: "Nikos Kazantzakis",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["eye", "negotiate", "itself", "trust", "agent"],
    words: ["let", "every", "eye", "negotiate", "for", "itself", "and", "trust", "no", "agent"],
    id: "Let every eye negotiate for itself and trust no agent.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "big", "opportunity", "may", "right"],
    words: ["your", "big", "opportunity", "may", "be", "right", "where", "you", "are", "now"],
    id: "Your big opportunity may be right where you are now.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "short", "bible", "says", "vapor"],
    words: ["life", "is", "so", "short", "bible", "says", "it's", "like", "a", "vapor"],
    id: "Life is so, so short. Bible says it's like a vapor.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "liked", "singing", "took", "chance"],
    words: ["i", "liked", "singing", "and", "just", "took", "a", "chance", "with", "it"],
    id: "I liked singing and just took a chance with it.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "things", "which", "exist", "originality"],
    words: ["all", "good", "things", "which", "exist", "are", "the", "fruits", "of", "originality"],
    id: "All good things which exist are the fruits of originality.",
    author: "John Stuart Mill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["o", "i'm", "from", "short", "block"],
    words: ["the", "o", "is", "where", "i'm", "from", "it's", "short", "for", "block"],
    id: "The O is where I'm from. It's short for O Block.",
    author: "King Von",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "living", "by", "get", "life"],
    words: ["we", "make", "a", "living", "by", "what", "get", "but", "life", "give"],
    id: "We make a living by what we get, but we make a life by what we give.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["opposite", "happiness", "isn't", "sadness", "boredom"],
    words: [
      "for",
      "me",
      "the",
      "opposite",
      "of",
      "happiness",
      "isn't",
      "sadness",
      "but",
      "boredom",
    ],
    id: "For me, the opposite of happiness isn't sadness but boredom.",
    author: "Sushant Singh Rajput",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["universe", "under", "obligation", "make", "sense"],
    words: ["the", "universe", "is", "under", "no", "obligation", "to", "make", "sense", "you"],
    id: "The Universe is under no obligation to make sense to you.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["fighting", "corruption", "governance", "self-defense", "patriotism"],
    words: [
      "fighting",
      "corruption",
      "is",
      "not",
      "just",
      "good",
      "governance",
      "it's",
      "self-defense",
      "patriotism",
    ],
    id: "Fighting corruption is not just good governance. It's self-defense. It's patriotism.",
    author: "Joe Biden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "fight", "my", "legacy", "history"],
    words: ["i", "don't", "fight", "for", "the", "money", "my", "legacy", "history", "people"],
    id: "I don't fight for the money. I fight for my legacy. I fight for history. I fight for my people.",
    author: "Khabib Nurmagomedov",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "sweet", "perception", "natural", "fact"],
    words: ["how", "sweet", "is", "the", "perception", "of", "a", "new", "natural", "fact"],
    id: "How sweet is the perception of a new natural fact!",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "overrated", "there", "conflict", "life"],
    words: ["happiness", "is", "overrated", "there", "has", "to", "be", "conflict", "in", "life"],
    id: "Happiness is overrated. There has to be conflict in life.",
    author: "Brad Pitt",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "keys", "happiness", "bad", "memory"],
    words: ["one", "of", "the", "keys", "to", "happiness", "is", "a", "bad", "memory"],
    id: "One of the keys to happiness is a bad memory.",
    author: "Rita Mae Brown",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["client", "mere", "unit", "factor", "problem"],
    words: ["a", "client", "is", "to", "me", "mere", "unit", "factor", "in", "problem"],
    id: "A client is to me a mere unit, a factor in a problem.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["cost", "living", "up", "chance", "down"],
    words: ["the", "cost", "of", "living", "is", "going", "up", "and", "chance", "down"],
    id: "The cost of living is going up and the chance of living is going down.",
    author: "Flip Wilson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["decisiveness", "one", "word", "makes", "manager"],
    words: ["decisiveness", "is", "the", "one", "word", "that", "makes", "a", "good", "manager"],
    id: "Decisiveness is the one word that makes a good manager.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["thankful", "getting", "all", "government", "paying"],
    words: [
      "be",
      "thankful",
      "we're",
      "not",
      "getting",
      "all",
      "the",
      "government",
      "paying",
      "for",
    ],
    id: "Be thankful we're not getting all the government we're paying for.",
    author: "Will Rogers",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["those", "imitate", "anything", "produce", "nothing"],
    words: ["those", "who", "do", "not", "want", "to", "imitate", "anything", "produce", "nothing"],
    id: "Those who do not want to imitate anything, produce nothing.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["called", "genius", "abundance", "life", "health"],
    words: ["what", "is", "called", "genius", "the", "abundance", "of", "life", "and", "health"],
    id: "What is called genius is the abundance of life and health.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["important", "set", "tone", "get", "momentum"],
    words: ["it's", "important", "to", "set", "a", "tone", "and", "get", "some", "momentum"],
    id: "It's important to set a tone and get some momentum.",
    author: "Ben Zobrist",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "life", "better", "year", "living"],
    words: ["my", "life", "is", "better", "with", "every", "year", "of", "living", "it"],
    id: "My life is better with every year of living it.",
    author: "Rachel Maddow",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["pillars", "truth", "freedom", "-", "society"],
    words: ["the", "pillars", "of", "truth", "and", "freedom", "-", "they", "are", "society"],
    id: "The pillars of truth and the pillars of freedom - they are the pillars of society.",
    author: "Henrik Ibsen",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["name", "rather", "chosen", "than", "riches"],
    words: ["a", "good", "name", "is", "rather", "to", "be", "chosen", "than", "riches"],
    id: "A good name is rather to be chosen than riches.",
    author: "King Solomon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "teacher", "failure", "makes", "humble"],
    words: ["success", "is", "not", "a", "good", "teacher", "failure", "makes", "you", "humble"],
    id: "Success is not a good teacher, failure makes you humble.",
    author: "Shah Rukh Khan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["one's", "self", "wisdom", "neighbors", "genius"],
    words: ["to", "know", "one's", "self", "is", "wisdom", "but", "not", "neighbors", "genius"],
    id: "To know one's self is wisdom, but not to know one's neighbors is genius.",
    author: "Minna Antrim",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["get", "paid", "hour", "value", "bring"],
    words: ["you", "don't", "get", "paid", "for", "the", "hour", "value", "bring", "to"],
    id: "You don't get paid for the hour. You get paid for the value you bring to the hour.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "given", "earn", "by", "giving"],
    words: ["life", "is", "given", "to", "us", "we", "earn", "it", "by", "giving"],
    id: "Life is given to us, we earn it by giving it.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "way", "out", "difficulty", "through"],
    words: ["the", "best", "way", "out", "of", "a", "difficulty", "is", "through", "it"],
    id: "The best way out of a difficulty is through it.",
    author: "Will Rogers",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["theory", "evolution", "gravity", "scientific", "fact"],
    words: ["the", "theory", "of", "evolution", "like", "gravity", "is", "a", "scientific", "fact"],
    id: "The theory of evolution, like the theory of gravity, is a scientific fact.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["ruler", "alien", "defies", "public", "opinion"],
    words: ["for", "me", "every", "ruler", "is", "alien", "that", "defies", "public", "opinion"],
    id: "For me every ruler is alien that defies public opinion.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["at", "first", "succeed", "much", "skydiving"],
    words: ["if", "at", "first", "you", "don't", "succeed", "so", "much", "for", "skydiving"],
    id: "If at first you don't succeed... so much for skydiving.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "note", "mine", "worth", "noting"],
    words: ["there's", "not", "a", "note", "of", "mine", "that's", "worth", "the", "noting"],
    id: "There's not a note of mine that's worth the noting.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["desire", "spirit's", "wings", "great", "deeds"],
    words: ["love", "and", "desire", "are", "the", "spirit's", "wings", "to", "great", "deeds"],
    id: "Love and desire are the spirit's wings to great deeds.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["fool", "thinks", "himself", "wise", "man"],
    words: ["a", "fool", "thinks", "himself", "to", "be", "wise", "but", "man", "knows"],
    id: "A fool thinks himself to be wise, but a wise man knows himself to be a fool.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["hatred", "affair", "heart", "contempt", "head"],
    words: ["hatred", "is", "an", "affair", "of", "the", "heart", "contempt", "that", "head"],
    id: "Hatred is an affair of the heart; contempt that of the head.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "job", "players", "react", "better"],
    words: ["it", "is", "my", "job", "to", "help", "the", "players", "react", "better"],
    id: "It is my job to help the players react better.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["honor", "won", "must", "only", "lost"],
    words: ["honor", "has", "not", "to", "be", "won", "it", "must", "only", "lost"],
    id: "Honor has not to be won; it must only not be lost.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["first", "mistake", "public", "business", "into"],
    words: ["the", "first", "mistake", "in", "public", "business", "is", "going", "into", "it"],
    id: "The first mistake in public business is the going into it.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["puritanism", "haunting", "fear", "somewhere", "may"],
    words: [
      "puritanism",
      "the",
      "haunting",
      "fear",
      "that",
      "someone",
      "somewhere",
      "may",
      "be",
      "happy",
    ],
    id: "Puritanism. The haunting fear that someone, somewhere, may be happy.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["must", "willing", "pay", "price", "freedom"],
    words: ["we", "must", "be", "willing", "to", "pay", "a", "price", "for", "freedom"],
    id: "We must be willing to pay a price for freedom.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "longer", "walk", "street", "over"],
    words: ["i", "can", "no", "longer", "walk", "in", "the", "street", "that's", "over"],
    id: "I can no longer walk in the street. That's over.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "big", "problems", "lot", "little"],
    words: ["there", "are", "no", "big", "problems", "just", "a", "lot", "of", "little"],
    id: "There are no big problems, there are just a lot of little problems.",
    author: "Henry Ford",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["seek", "peace", "knowing", "climate", "freedom"],
    words: ["we", "seek", "peace", "knowing", "that", "is", "the", "climate", "of", "freedom"],
    id: "We seek peace, knowing that peace is the climate of freedom.",
    author: "Dwight D. Eisenhower",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["those", "whom", "hate", "others", "indifferent"],
    words: ["those", "whom", "we", "can", "love", "hate", "to", "others", "are", "indifferent"],
    id: "Those whom we can love, we can hate; to others we are indifferent.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["brain", "muscle", "i'm", "kind", "body-builder"],
    words: ["the", "brain", "is", "a", "muscle", "and", "i'm", "kind", "of", "body-builder"],
    id: "The brain is a muscle, and I'm a kind of body-builder.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["carry", "your", "countenance", "letter", "recommendation"],
    words: [
      "you",
      "don't",
      "carry",
      "in",
      "your",
      "countenance",
      "a",
      "letter",
      "of",
      "recommendation",
    ],
    id: "You don't carry in your countenance a letter of recommendation.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["both", "rebellion", "conformity", "attack", "success"],
    words: [
      "it's",
      "both",
      "rebellion",
      "and",
      "conformity",
      "that",
      "attack",
      "you",
      "with",
      "success",
    ],
    id: "It's both rebellion and conformity that attack you with success.",
    author: "Amy Tan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["live", "change", "perfect", "changed", "often"],
    words: ["to", "live", "is", "change", "and", "be", "perfect", "have", "changed", "often"],
    id: "To live is to change, and to be perfect is to have changed often.",
    author: "John Henry Newman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["first", "requisite", "happiness", "abolition", "religion"],
    words: [
      "the",
      "first",
      "requisite",
      "for",
      "happiness",
      "of",
      "people",
      "is",
      "abolition",
      "religion",
    ],
    id: "The first requisite for the happiness of the people is the abolition of religion.",
    author: "Karl Marx",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["quality", "longevity", "one's", "life", "important"],
    words: ["the", "quality", "not", "longevity", "of", "one's", "life", "is", "what", "important"],
    id: "The quality, not the longevity, of one's life is what is important.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["making", "excuses", "seldom", "anything", "else"],
    words: ["he", "that", "is", "good", "for", "making", "excuses", "seldom", "anything", "else"],
    id: "He that is good for making excuses is seldom good for anything else.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["explanation", "cause", "justification", "by", "reason"],
    words: ["an", "explanation", "of", "cause", "is", "not", "a", "justification", "by", "reason"],
    id: "An explanation of cause is not a justification by reason.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "lucky", "most", "perfect", "conditions"],
    words: ["i'm", "lucky", "to", "work", "in", "the", "most", "perfect", "of", "conditions"],
    id: "I'm lucky to work in the most perfect of conditions.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["inner", "reality", "recognized", "only", "by"],
    words: ["the", "inner", "reality", "of", "love", "can", "be", "recognized", "only", "by"],
    id: "The inner reality of love can be recognized only by love.",
    author: "Hans Urs von Balthasar",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["real", "knowledge", "extent", "one's", "ignorance"],
    words: ["real", "knowledge", "is", "to", "know", "the", "extent", "of", "one's", "ignorance"],
    id: "Real knowledge is to know the extent of one's ignorance.",
    author: "Confucius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "most", "intelligent", "-", "silly"],
    words: ["man", "is", "the", "most", "intelligent", "of", "animals", "-", "and", "silly"],
    id: "Man is the most intelligent of the animals - and the most silly.",
    author: "Diogenes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["miracles", "fact", "break", "laws", "nature"],
    words: ["miracles", "do", "not", "in", "fact", "break", "the", "laws", "of", "nature"],
    id: "Miracles do not, in fact, break the laws of nature.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["secret", "happiness", "find", "congenial", "monotony"],
    words: ["the", "secret", "of", "happiness", "is", "to", "find", "a", "congenial", "monotony"],
    id: "The secret of happiness is to find a congenial monotony.",
    author: "V. S. Pritchett",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "grateful", "part", "sundance", "family"],
    words: ["i'm", "so", "grateful", "to", "be", "part", "of", "the", "sundance", "family"],
    id: "I'm so grateful to be part of the Sundance family.",
    author: "Matthew Heineman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "child", "born", "mother", "also"],
    words: ["for", "when", "a", "child", "is", "born", "the", "mother", "also", "again"],
    id: "For when a child is born the mother also is born again.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["two", "wrongs", "make", "right", "excuse"],
    words: ["two", "wrongs", "don't", "make", "a", "right", "but", "they", "good", "excuse"],
    id: "Two wrongs don't make a right, but they make a good excuse.",
    author: "Thomas Szasz",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["celebrate", "occasion", "wine", "sweet", "words"],
    words: ["let", "us", "celebrate", "the", "occasion", "with", "wine", "and", "sweet", "words"],
    id: "Let us celebrate the occasion with wine and sweet words.",
    author: "Plautus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["first", "last", "demanded", "genius", "truth"],
    words: ["first", "and", "last", "what", "is", "demanded", "of", "genius", "love", "truth"],
    id: "First and last, what is demanded of genius is love of truth.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["coming", "out", "most", "political", "thing"],
    words: ["coming", "out", "is", "the", "most", "political", "thing", "you", "can", "do"],
    id: "Coming out is the most political thing you can do.",
    author: "Harvey Milk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "mutual", "blackmail", "elevated", "level"],
    words: [
      "friendship",
      "is",
      "mutual",
      "blackmail",
      "elevated",
      "to",
      "the",
      "level",
      "of",
      "love",
    ],
    id: "Friendship is mutual blackmail elevated to the level of love.",
    author: "Robin Morgan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["elegance", "consist", "putting", "on", "dress"],
    words: ["elegance", "does", "not", "consist", "in", "putting", "on", "a", "new", "dress"],
    id: "Elegance does not consist in putting on a new dress.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["live", "happily", "inward", "power", "soul"],
    words: ["to", "live", "happily", "is", "an", "inward", "power", "of", "the", "soul"],
    id: "To live happily is an inward power of the soul.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["often", "safer", "chains", "than", "free"],
    words: ["it", "is", "often", "safer", "to", "be", "in", "chains", "than", "free"],
    id: "It is often safer to be in chains than to be free.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["democracy", "election", "our", "daily", "life"],
    words: ["democracy", "is", "not", "just", "an", "election", "it", "our", "daily", "life"],
    id: "Democracy is not just an election, it is our daily life.",
    author: "Tsai Ing-wen",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "dream", "church", "mother", "shepherdess"],
    words: ["i", "dream", "of", "a", "church", "that", "is", "mother", "and", "shepherdess"],
    id: "I dream of a church that is a mother and shepherdess.",
    author: "Pope Francis",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["everyone", "hand", "outcome", "piece", "knowledge"],
    words: ["everyone", "wants", "a", "hand", "in", "the", "outcome", "piece", "of", "knowledge"],
    id: "Everyone wants a hand in the outcome, a piece of the knowledge.",
    author: "Walter Gilbert",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "faith", "one", "admits", "doubt"],
    words: ["you", "know", "my", "faith", "is", "one", "that", "admits", "some", "doubt"],
    id: "You know, my faith is one that admits some doubt.",
    author: "Barack Obama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["quality", "act", "as", "already", "had"],
    words: ["if", "you", "want", "a", "quality", "act", "as", "already", "had", "it"],
    id: "If you want a quality, act as if you already had it.",
    author: "William James",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["more", "one", "seen", "asks", "better"],
    words: ["the", "more", "one", "has", "seen", "of", "good", "asks", "for", "better"],
    id: "The more one has seen of the good, the more one asks for the better.",
    author: "John Mason Brown",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["wishes", "obeyed", "must", "how", "command"],
    words: ["he", "who", "wishes", "to", "be", "obeyed", "must", "know", "how", "command"],
    id: "He who wishes to be obeyed must know how to command.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["tyrant", "lived", "believed", "freedom", "himself"],
    words: [
      "every",
      "tyrant",
      "who",
      "has",
      "lived",
      "believed",
      "in",
      "freedom",
      "for",
      "himself",
    ],
    id: "Every tyrant who has lived has believed in freedom for himself.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["everything", "yourself", "believe", "because", "learned"],
    words: [
      "everything",
      "you",
      "think",
      "about",
      "yourself",
      "believe",
      "is",
      "because",
      "learned",
      "it",
    ],
    id: "Everything you think about yourself, everything you believe about yourself, is because you learned it.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["obedience", "mother", "success", "wedded", "safety"],
    words: ["obedience", "is", "the", "mother", "of", "success", "and", "wedded", "to", "safety"],
    id: "Obedience is the mother of success and is wedded to safety.",
    author: "Aeschylus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["poor", "pupil", "surpass", "his", "master"],
    words: ["poor", "is", "the", "pupil", "who", "does", "not", "surpass", "his", "master"],
    id: "Poor is the pupil who does not surpass his master.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["these", "short", "tracks", "patience", "show"],
    words: ["these", "short", "tracks", "are", "about", "patience", "you", "have", "to", "show"],
    id: "These short tracks are about patience. You have to show patience.",
    author: "Denny Hamlin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["pessimist", "sees", "difficulty", "opportunity", "optimist"],
    words: [
      "a",
      "pessimist",
      "sees",
      "the",
      "difficulty",
      "in",
      "every",
      "opportunity",
      "an",
      "optimist",
    ],
    id: "A pessimist sees the difficulty in every opportunity; an optimist sees the opportunity in every difficulty.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["governed", "appetite", "greater", "part", "liberty"],
    words: ["a", "well", "governed", "appetite", "is", "the", "greater", "part", "of", "liberty"],
    id: "A well governed appetite is the greater part of liberty.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["dignity", "consist", "possessing", "honors", "deserving"],
    words: [
      "dignity",
      "does",
      "not",
      "consist",
      "in",
      "possessing",
      "honors",
      "but",
      "deserving",
      "them",
    ],
    id: "Dignity does not consist in possessing honors, but in deserving them.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "grief", "itself", "put", "end"],
    words: ["great", "grief", "does", "not", "of", "itself", "put", "an", "end", "to"],
    id: "Great grief does not of itself put an end to itself.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["indignation", "submission", "our", "thoughts", "desires"],
    words: [
      "indignation",
      "is",
      "a",
      "submission",
      "of",
      "our",
      "thoughts",
      "but",
      "not",
      "desires",
    ],
    id: "Indignation is a submission of our thoughts, but not of our desires.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["most", "difficult", "thing", "life", "yourself"],
    words: ["the", "most", "difficult", "thing", "in", "life", "is", "to", "know", "yourself"],
    id: "The most difficult thing in life is to know yourself.",
    author: "Thales",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["few", "envy", "many", "only", "watch"],
    words: ["the", "few", "who", "do", "are", "envy", "of", "many", "only", "watch"],
    id: "The few who do are the envy of the many who only watch.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "huge", "value", "instant", "feedback"],
    words: ["there", "is", "a", "huge", "value", "in", "learning", "with", "instant", "feedback"],
    id: "There is a huge value in learning with instant feedback.",
    author: "Anant Agarwal",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["lot", "get", "impatient", "pace", "change"],
    words: ["a", "lot", "of", "people", "get", "impatient", "with", "the", "pace", "change"],
    id: "A lot of people get impatient with the pace of change.",
    author: "James Levine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["pain", "parting", "nothing", "joy", "meeting"],
    words: ["the", "pain", "of", "parting", "is", "nothing", "to", "joy", "meeting", "again"],
    id: "The pain of parting is nothing to the joy of meeting again.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["live", "as", "were", "die", "forever"],
    words: ["live", "as", "if", "you", "were", "to", "die", "tomorrow", "learn", "forever"],
    id: "Live as if you were to die tomorrow. Learn as if you were to live forever.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "bound", "thee", "my", "answer"],
    words: ["i", "am", "not", "bound", "to", "please", "thee", "with", "my", "answer"],
    id: "I am not bound to please thee with my answer.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["movies", "finished", "abandoned", "movie", "never"],
    words: ["movies", "are", "not", "finished", "they", "abandoned", "a", "movie", "is", "never"],
    id: "Movies are not finished. They are abandoned. A movie is never finished.",
    author: "Louis Leterrier",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "myself", "god", "forbid", "should"],
    words: ["i", "do", "not", "know", "myself", "and", "god", "forbid", "that", "should"],
    id: "I do not know myself, and God forbid that I should.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["fighting", "football", "serenity", "-", "i"],
    words: ["fighting", "football", "not", "serenity", "-", "that", "is", "what", "i", "like"],
    id: "Fighting football, not serenity football - that is what I like.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "really", "being", "centre", "attention"],
    words: ["i", "don't", "really", "like", "being", "in", "the", "centre", "of", "attention"],
    id: "I don't really like being in the centre of attention.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "run", "we'll", "all", "ok"],
    words: ["if", "i", "don't", "run", "for", "president", "we'll", "all", "be", "ok"],
    id: "If I don't run for president, we'll all be OK.",
    author: "Joe Biden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["until", "lost", "begin", "understand", "ourselves"],
    words: ["not", "until", "we", "are", "lost", "do", "begin", "to", "understand", "ourselves"],
    id: "Not until we are lost do we begin to understand ourselves.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "were", "bad", "would", "lawyers"],
    words: ["if", "there", "were", "no", "bad", "people", "would", "be", "good", "lawyers"],
    id: "If there were no bad people, there would be no good lawyers.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["patience", "virtue", "i'm", "tough", "lesson"],
    words: ["patience", "is", "a", "virtue", "and", "i'm", "learning", "it's", "tough", "lesson"],
    id: "Patience is a virtue, and I'm learning patience. It's a tough lesson.",
    author: "Elon Musk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["ability", "concentrate", "use", "time", "everything"],
    words: [
      "the",
      "ability",
      "to",
      "concentrate",
      "and",
      "use",
      "time",
      "well",
      "is",
      "everything",
    ],
    id: "The ability to concentrate and to use time well is everything.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "mickey", "who's", "inside", "suit"],
    words: ["i'm", "mickey", "mouse", "they", "don't", "know", "who's", "inside", "the", "suit"],
    id: "I'm Mickey Mouse. They don't know who's inside the suit.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "always", "madness", "also", "reason"],
    words: ["there", "is", "always", "some", "madness", "in", "love", "but", "also", "reason"],
    id: "There is always some madness in love. But there is also always some reason in madness.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["happens", "as", "important", "how", "react"],
    words: ["what", "happens", "is", "not", "as", "important", "how", "you", "react", "to"],
    id: "What happens is not as important as how you react to what happens.",
    author: "Ellen Glasgow",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "his", "best", "else", "there"],
    words: ["if", "a", "man", "does", "his", "best", "what", "else", "is", "there"],
    id: "If a man does his best, what else is there?",
    author: "George S. Patton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "beat", "person", "never", "up"],
    words: ["you", "just", "can't", "beat", "the", "person", "who", "never", "gives", "up"],
    id: "You just can't beat the person who never gives up.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["matter", "i", "carry", "my", "family"],
    words: ["no", "matter", "where", "i", "go", "want", "to", "carry", "my", "family"],
    id: "No matter where I go, I want to carry my family.",
    author: "Auli'i Cravalho",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["once", "only", "perfectly", "equipped", "loving"],
    words: ["we", "love", "but", "once", "for", "only", "are", "perfectly", "equipped", "loving"],
    id: "We love but once, for once only are we perfectly equipped for loving.",
    author: "Cyril Connolly",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "nut", "case", "i", "believe"],
    words: ["i'm", "a", "nut", "case", "but", "that", "is", "what", "i", "believe"],
    id: "I'm a nut case, but that is what I believe.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["show", "gracious", "loser", "i'll", "failure"],
    words: ["show", "me", "a", "good", "and", "gracious", "loser", "i'll", "you", "failure"],
    id: "Show me a good and gracious loser and I'll show you a failure.",
    author: "Knute Rockne",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "wisdom", "realize", "one's", "lack"],
    words: ["the", "greatest", "wisdom", "is", "to", "realize", "one's", "lack", "of", "it"],
    id: "The greatest wisdom is to realize one's lack of it.",
    author: "Constantin Stanislavski",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["life's", "greatest", "happiness", "convinced", "loved"],
    words: ["life's", "greatest", "happiness", "is", "to", "be", "convinced", "we", "are", "loved"],
    id: "Life's greatest happiness is to be convinced we are loved.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["beauty", "shines", "brighter", "than", "heart"],
    words: ["no", "beauty", "shines", "brighter", "than", "that", "of", "a", "good", "heart"],
    id: "No beauty shines brighter than that of a good heart.",
    author: "Shanina Shaik",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["search", "knowledge", "long", "difficult", "task"],
    words: ["the", "search", "for", "knowledge", "is", "a", "long", "and", "difficult", "task"],
    id: "The search for knowledge is a long and difficult task.",
    author: "Fabiola Gianotti",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "those", "everything", "done", "bored"],
    words: ["only", "those", "who", "want", "everything", "done", "for", "them", "are", "bored"],
    id: "Only those who want everything done for them are bored.",
    author: "Billy Graham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["abnormal", "best", "way", "understanding", "normal"],
    words: ["to", "study", "the", "abnormal", "is", "best", "way", "of", "understanding", "normal"],
    id: "To study the abnormal is the best way of understanding the normal.",
    author: "William James",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["were", "born", "fear", "learned", "here"],
    words: ["love", "is", "what", "we", "were", "born", "with", "fear", "learned", "here"],
    id: "Love is what we were born with. Fear is what we learned here.",
    author: "Marianne Williamson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["understand", "how", "obsessed", "i", "winning"],
    words: [
      "people",
      "just",
      "don't",
      "understand",
      "how",
      "obsessed",
      "i",
      "am",
      "with",
      "winning",
    ],
    id: "People just don't understand how obsessed I am with winning.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["fools", "admire", "men", "sense", "approve"],
    words: ["fools", "admire", "but", "men", "of", "sense", "approve"],
    id: "Fools admire, but men of sense approve.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["art", "never", "finished", "only", "abandoned"],
    words: ["art", "is", "never", "finished", "only", "abandoned"],
    id: "Art is never finished, only abandoned.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["from", "each", "according", "his", "abilities"],
    words: ["from", "each", "according", "to", "his", "abilities", "needs"],
    id: "From each according to his abilities, to each according to his needs.",
    author: "Karl Marx",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["whatever", "said", "by", "another", "mine"],
    words: ["whatever", "is", "well", "said", "by", "another", "mine"],
    id: "Whatever is well said by another, is mine.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["treatment", "without", "prevention", "simply", "unsustainable"],
    words: ["treatment", "without", "prevention", "is", "simply", "unsustainable"],
    id: "Treatment without prevention is simply unsustainable.",
    author: "Bill Gates",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["human", "heart", "same", "world", "over"],
    words: ["the", "human", "heart", "is", "same", "world", "over"],
    id: "The human heart is the same the world over.",
    author: "Billy Graham",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "only", "real", "when", "shared"],
    words: ["happiness", "is", "only", "real", "when", "shared"],
    id: "Happiness is only real when shared.",
    author: "Christopher McCandless",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["short", "cuts", "make", "long", "delays"],
    words: ["short", "cuts", "make", "long", "delays"],
    id: "Short cuts make long delays.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["obedience", "brings", "success", "exact", "miracles"],
    words: ["obedience", "brings", "success", "exact", "miracles"],
    id: "Obedience brings success; exact obedience brings miracles.",
    author: "Russell M. Nelson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["whatever", "begun", "anger", "ends", "shame"],
    words: ["whatever", "is", "begun", "in", "anger", "ends", "shame"],
    id: "Whatever is begun in anger ends in shame.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["lengthen", "thy", "life", "lessen", "meals"],
    words: ["to", "lengthen", "thy", "life", "lessen", "meals"],
    id: "To lengthen thy life, lessen thy meals.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "mental", "harmony", "unhappiness", "inharmony"],
    words: ["happiness", "is", "mental", "harmony", "unhappiness", "inharmony"],
    id: "Happiness is mental harmony; unhappiness is mental inharmony.",
    author: "James Allen",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["crime", "when", "succeeds", "called", "virtue"],
    words: ["crime", "when", "it", "succeeds", "is", "called", "virtue"],
    id: "Crime when it succeeds is called virtue.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["as", "knowledge", "increases", "wonder", "deepens"],
    words: ["as", "knowledge", "increases", "wonder", "deepens"],
    id: "As knowledge increases, wonder deepens.",
    author: "Charles Morgan",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "crime", "pay", "become", "lawyer"],
    words: ["make", "crime", "pay", "become", "a", "lawyer"],
    id: "Make crime pay. Become a lawyer.",
    author: "Will Rogers",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["vast", "art", "narrow", "human", "wit"],
    words: ["so", "vast", "is", "art", "narrow", "human", "wit"],
    id: "So vast is art, so narrow human wit.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "take", "elevator", "city", "hall"],
    words: ["never", "take", "an", "elevator", "in", "city", "hall"],
    id: "Never take an elevator in city hall.",
    author: "Harvey Milk",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "knew", "anything", "rapping"],
    words: ["i", "never", "knew", "anything", "about", "rapping"],
    id: "I never knew anything about rapping.",
    author: "Lil Baby",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["husbands", "never", "become", "merely", "proficient"],
    words: ["husbands", "never", "become", "good", "they", "merely", "proficient"],
    id: "Husbands never become good; they merely become proficient.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["yesterday's", "weirdness", "tomorrow's", "reason", "why"],
    words: ["yesterday's", "weirdness", "is", "tomorrow's", "reason", "why"],
    id: "Yesterday's weirdness is tomorrow's reason why.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "forgive", "always", "forget"],
    words: ["i", "never", "forgive", "but", "always", "forget"],
    id: "I never forgive, but I always forget.",
    author: "Arthur Balfour",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["pint", "sweat", "saves", "gallon", "blood"],
    words: ["a", "pint", "of", "sweat", "saves", "gallon", "blood"],
    id: "A pint of sweat, saves a gallon of blood.",
    author: "George S. Patton",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["guard", "against", "impostures", "pretended", "patriotism"],
    words: ["guard", "against", "the", "impostures", "of", "pretended", "patriotism"],
    id: "Guard against the impostures of pretended patriotism.",
    author: "George Washington",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "preparing", "my", "impromptu", "remarks"],
    words: ["i'm", "just", "preparing", "my", "impromptu", "remarks"],
    id: "I'm just preparing my impromptu remarks.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "consecrates", "most", "offensive", "crimes"],
    words: ["success", "consecrates", "the", "most", "offensive", "crimes"],
    id: "Success consecrates the most offensive crimes.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["words", "cool", "more", "than", "cold"],
    words: ["good", "words", "cool", "more", "than", "cold", "water"],
    id: "Good words cool more than cold water.",
    author: "John Ray",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["putin", "sought", "destabilize", "ukraine's", "economy"],
    words: ["putin", "sought", "to", "destabilize", "ukraine's", "economy"],
    id: "Putin sought to destabilize Ukraine's economy.",
    author: "Joe Biden",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "cruelty", "springs", "from", "weakness"],
    words: ["all", "cruelty", "springs", "from", "weakness"],
    id: "All cruelty springs from weakness.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["devotion", "complete", "culminates", "knowledge", "supreme"],
    words: ["devotion", "complete", "culminates", "in", "knowledge", "supreme"],
    id: "Devotion complete culminates in knowledge supreme.",
    author: "Ramana Maharshi",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["successful", "fortunate", "crime", "called", "virtue"],
    words: ["successful", "and", "fortunate", "crime", "is", "called", "virtue"],
    id: "Successful and fortunate crime is called virtue.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["above", "all", "things", "reverence", "yourself"],
    words: ["above", "all", "things", "reverence", "yourself"],
    id: "Above all things, reverence yourself.",
    author: "Pythagoras",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "schools", "flourish", "all", "flourishes"],
    words: ["when", "schools", "flourish", "all", "flourishes"],
    id: "When schools flourish, all flourishes.",
    author: "Martin Luther",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "measurable", "progress", "reasonable", "time"],
    words: ["make", "measurable", "progress", "in", "reasonable", "time"],
    id: "Make measurable progress in reasonable time.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["loneliness", "more", "lonely", "than", "distrust"],
    words: ["what", "loneliness", "is", "more", "lonely", "than", "distrust"],
    id: "What loneliness is more lonely than distrust?",
    author: "George Eliot",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["anticipate", "difficult", "by", "managing", "easy"],
    words: ["anticipate", "the", "difficult", "by", "managing", "easy"],
    id: "Anticipate the difficult by managing the easy.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["nobody", "hurt", "without", "my", "permission"],
    words: ["nobody", "can", "hurt", "me", "without", "my", "permission"],
    id: "Nobody can hurt me without my permission.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowledge", "shrinks", "as", "wisdom", "grows"],
    words: ["knowledge", "shrinks", "as", "wisdom", "grows"],
    id: "Knowledge shrinks as wisdom grows.",
    author: "Alfred North Whitehead",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "impeached", "myself", "by", "resigning"],
    words: ["i", "have", "impeached", "myself", "by", "resigning"],
    id: "I have impeached myself by resigning.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["law", "reason", "free", "from", "passion"],
    words: ["the", "law", "is", "reason", "free", "from", "passion"],
    id: "The law is reason, free from passion.",
    author: "Aristotle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["divided", "against", "itself", "cannot", "stand"],
    words: ["a", "house", "divided", "against", "itself", "cannot", "stand"],
    id: "A house divided against itself cannot stand.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["talent", "perceives", "differences", "genius", "unity"],
    words: ["talent", "perceives", "differences", "genius", "unity"],
    id: "Talent perceives differences; genius, unity.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["merry", "heart", "maketh", "cheerful", "countenance"],
    words: ["a", "merry", "heart", "maketh", "cheerful", "countenance"],
    id: "A merry heart maketh a cheerful countenance.",
    author: "King Solomon",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["from", "wonder", "into", "existence", "opens"],
    words: ["from", "wonder", "into", "existence", "opens"],
    id: "From wonder into wonder existence opens.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "was", "god's", "first", "creation"],
    words: ["time", "was", "god's", "first", "creation"],
    id: "Time was God's first creation.",
    author: "Walter Lang",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["at", "ev'ry", "word", "reputation", "dies"],
    words: ["at", "ev'ry", "word", "a", "reputation", "dies"],
    id: "At ev'ry word a reputation dies.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "moral", "duty", "inseparably", "connected"],
    words: ["happiness", "and", "moral", "duty", "are", "inseparably", "connected"],
    id: "Happiness and moral duty are inseparably connected.",
    author: "George Washington",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["age", "wrinkles", "body", "quitting", "soul"],
    words: ["age", "wrinkles", "the", "body", "quitting", "soul"],
    id: "Age wrinkles the body. Quitting wrinkles the soul.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["dolph", "writing", "movies", "into", "fashion"],
    words: ["dolph", "writing", "movies", "into", "fashion"],
    id: "Dolph writing movies. Dolph into fashion.",
    author: "Young Dolph",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["aids", "itself", "subject", "incredible", "stigma"],
    words: ["aids", "itself", "is", "subject", "to", "incredible", "stigma"],
    id: "AIDS itself is subject to incredible stigma.",
    author: "Bill Gates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["perfect", "behavior", "born", "complete", "indifference"],
    words: ["perfect", "behavior", "is", "born", "of", "complete", "indifference"],
    id: "Perfect behavior is born of complete indifference.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["intellectual", "passion", "drives", "out", "sensuality"],
    words: ["intellectual", "passion", "drives", "out", "sensuality"],
    id: "Intellectual passion drives out sensuality.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "your", "friend", "impulse", "enemy"],
    words: ["time", "is", "your", "friend", "impulse", "enemy"],
    id: "Time is your friend; impulse is your enemy.",
    author: "John C. Bogle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["intellect", "always", "fooled", "by", "heart"],
    words: ["the", "intellect", "is", "always", "fooled", "by", "heart"],
    id: "The intellect is always fooled by the heart.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["ambition", "should", "made", "sterner", "stuff"],
    words: ["ambition", "should", "be", "made", "of", "sterner", "stuff"],
    id: "Ambition should be made of sterner stuff.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "success", "literally", "your", "figuratively"],
    words: ["my", "success", "literally", "is", "your", "figuratively"],
    id: "My success, literally, is your success figuratively.",
    author: "Bo Burnham",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "befriended", "remember", "befriend", "forget"],
    words: ["when", "befriended", "remember", "it", "you", "befriend", "forget"],
    id: "When befriended, remember it; when you befriend, forget it.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "wish", "had", "more", "patience"],
    words: ["i", "wish", "had", "more", "patience"],
    id: "I wish I had more patience.",
    author: "Toni Collette",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "leader", "little", "humble", "follower"],
    words: ["i'm", "no", "leader", "a", "little", "humble", "follower"],
    id: "I'm no leader; I'm a little humble follower.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["excessive", "literary", "production", "social", "offense"],
    words: ["excessive", "literary", "production", "is", "a", "social", "offense"],
    id: "Excessive literary production is a social offense.",
    author: "George Eliot",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["vulgar", "boil", "learned", "roast", "egg"],
    words: ["the", "vulgar", "boil", "learned", "roast", "an", "egg"],
    id: "The vulgar boil, the learned roast, an egg.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "got", "my", "own", "back"],
    words: ["i", "got", "my", "own", "back"],
    id: "I got my own back.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "pitchers", "liars", "or", "crybabies"],
    words: ["all", "pitchers", "are", "liars", "or", "crybabies"],
    id: "All pitchers are liars or crybabies.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["discontented", "man", "finds", "easy", "chair"],
    words: ["the", "discontented", "man", "finds", "no", "easy", "chair"],
    id: "The discontented man finds no easy chair.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["rebellion", "against", "tyrants", "obedience", "god"],
    words: ["rebellion", "against", "tyrants", "is", "obedience", "to", "god"],
    id: "Rebellion against tyrants is obedience to God.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "always", "benefits", "sometimes", "injures"],
    words: ["friendship", "always", "benefits", "love", "sometimes", "injures"],
    id: "Friendship always benefits; love sometimes injures.",
    author: "Seneca",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "trust", "one", "even", "myself"],
    words: ["i", "trust", "no", "one", "not", "even", "myself"],
    id: "I trust no one, not even myself.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "nothing", "permanent", "except", "change"],
    words: ["there", "is", "nothing", "permanent", "except", "change"],
    id: "There is nothing permanent except change.",
    author: "Heraclitus",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "at", "times", "unconscious", "prophets"],
    words: ["we", "are", "all", "at", "times", "unconscious", "prophets"],
    id: "We are all at times unconscious prophets.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "doing", "ordinary", "things", "extraordinarily"],
    words: ["success", "is", "doing", "ordinary", "things", "extraordinarily", "well"],
    id: "Success is doing ordinary things extraordinarily well.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "shall", "grow", "conservative", "age"],
    words: ["i", "shall", "not", "grow", "conservative", "with", "age"],
    id: "I shall not grow conservative with age.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["woman's", "at", "best", "contradiction", "still"],
    words: ["woman's", "at", "best", "a", "contradiction", "still"],
    id: "Woman's at best a contradiction still.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["nickel", "ain't", "worth", "dime", "anymore"],
    words: ["a", "nickel", "ain't", "worth", "dime", "anymore"],
    id: "A nickel ain't worth a dime anymore.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["kisses", "better", "fate", "than", "wisdom"],
    words: ["kisses", "are", "a", "better", "fate", "than", "wisdom"],
    id: "Kisses are a better fate than wisdom.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["fine", "art", "knowledge", "made", "visible"],
    words: ["fine", "art", "is", "knowledge", "made", "visible"],
    id: "Fine art is knowledge made visible.",
    author: "Gustave Courbet",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["cricket", "basically", "baseball", "on", "valium"],
    words: ["cricket", "is", "basically", "baseball", "on", "valium"],
    id: "Cricket is basically baseball on valium.",
    author: "Robin Williams",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["geologists", "saying", "-", "rocks", "remember"],
    words: ["geologists", "have", "a", "saying", "-", "rocks", "remember"],
    id: "Geologists have a saying - rocks remember.",
    author: "Neil Armstrong",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["always", "seems", "impossible", "until", "done"],
    words: ["it", "always", "seems", "impossible", "until", "it's", "done"],
    id: "It always seems impossible until it's done.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["by", "perseverance", "snail", "reached", "ark"],
    words: ["by", "perseverance", "the", "snail", "reached", "ark"],
    id: "By perseverance the snail reached the ark.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["penny", "saved", "two", "pence", "clear"],
    words: ["a", "penny", "saved", "is", "two", "pence", "clear"],
    id: "A penny saved is two pence clear.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["science", "organized", "knowledge", "wisdom", "life"],
    words: ["science", "is", "organized", "knowledge", "wisdom", "life"],
    id: "Science is organized knowledge. Wisdom is organized life.",
    author: "Immanuel Kant",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "thoughts", "come", "from", "heart"],
    words: ["great", "thoughts", "come", "from", "the", "heart"],
    id: "Great thoughts come from the heart.",
    author: "Luc de Clapiers",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "deeds", "determine", "as", "much"],
    words: ["our", "deeds", "determine", "us", "as", "much", "we"],
    id: "Our deeds determine us, as much as we determine our deeds.",
    author: "George Eliot",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "abiding", "success", "without", "commitment"],
    words: ["there's", "no", "abiding", "success", "without", "commitment"],
    id: "There's no abiding success without commitment.",
    author: "Tony Robbins",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["looks", "outside", "dreams", "inside", "awakes"],
    words: ["who", "looks", "outside", "dreams", "inside", "awakes"],
    id: "Who looks outside, dreams; who looks inside, awakes.",
    author: "Carl Jung",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "alone", "eternal", "perpetual", "immortal"],
    words: ["change", "alone", "is", "eternal", "perpetual", "immortal"],
    id: "Change alone is eternal, perpetual, immortal.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["woman", "was", "god's", "second", "mistake"],
    words: ["woman", "was", "god's", "second", "mistake"],
    id: "Woman was God's second mistake.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["will", "minus", "intellect", "constitutes", "vulgarity"],
    words: ["will", "minus", "intellect", "constitutes", "vulgarity"],
    id: "Will minus intellect constitutes vulgarity.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["die", "v", "stop", "sinning", "suddenly"],
    words: ["die", "v", "to", "stop", "sinning", "suddenly"],
    id: "Die, v.: To stop sinning suddenly.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["wrong", "life", "cannot", "lived", "rightly"],
    words: ["wrong", "life", "cannot", "be", "lived", "rightly"],
    id: "Wrong life cannot be lived rightly.",
    author: "Theodor W. Adorno",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "spend", "lot", "time", "reading"],
    words: ["i", "spend", "a", "lot", "of", "time", "reading"],
    id: "I spend a lot of time reading.",
    author: "Bill Gates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["laughter", "america's", "most", "important", "export"],
    words: ["laughter", "is", "america's", "most", "important", "export"],
    id: "Laughter is America's most important export.",
    author: "Walt Disney",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "was", "arrested", "lip-syncing", "karaoke"],
    words: ["i", "was", "arrested", "for", "lip-syncing", "karaoke"],
    id: "I was arrested for lip-syncing karaoke.",
    author: "Steven Wright",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["gets", "late", "early", "out", "there"],
    words: ["it", "gets", "late", "early", "out", "there"],
    id: "It gets late early out there.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["zeal", "without", "knowledge", "fire", "light"],
    words: ["zeal", "without", "knowledge", "is", "fire", "light"],
    id: "Zeal without knowledge is fire without light.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "one", "mind", "two", "bodies"],
    words: ["friendship", "is", "one", "mind", "in", "two", "bodies"],
    id: "Friendship is one mind in two bodies.",
    author: "Mencius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["almost", "mind-boggling", "how", "time", "flies"],
    words: ["it's", "almost", "mind-boggling", "how", "time", "flies"],
    id: "It's almost mind-boggling how time flies.",
    author: "Martin Brodeur",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "warfare", "based", "on", "deception"],
    words: ["all", "warfare", "is", "based", "on", "deception"],
    id: "All warfare is based on deception.",
    author: "Unknown",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["clever", "man", "commits", "minor", "blunders"],
    words: ["a", "clever", "man", "commits", "no", "minor", "blunders"],
    id: "A clever man commits no minor blunders.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["on", "wrongs", "swift", "vengeance", "waits"],
    words: ["on", "wrongs", "swift", "vengeance", "waits"],
    id: "On wrongs swift vengeance waits.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "must", "lived", "as", "play"],
    words: ["life", "must", "be", "lived", "as", "play"],
    id: "Life must be lived as play.",
    author: "Plato",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "comes", "from", "strength", "self-reliance"],
    words: ["freedom", "comes", "from", "strength", "and", "self-reliance"],
    id: "Freedom comes from strength and self-reliance.",
    author: "Lisa Murkowski",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["patience", "means", "restraining", "one's", "inclinations"],
    words: ["patience", "means", "restraining", "one's", "inclinations"],
    id: "Patience means restraining one's inclinations.",
    author: "Tokugawa Ieyasu",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["creditors", "better", "memories", "than", "debtors"],
    words: ["creditors", "have", "better", "memories", "than", "debtors"],
    id: "Creditors have better memories than debtors.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["patience", "was", "my", "strong", "point"],
    words: ["patience", "was", "not", "my", "strong", "point"],
    id: "Patience was not my strong point.",
    author: "Lauren Bacall",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["arafat's", "departure", "created", "awesome", "opportunity"],
    words: ["arafat's", "departure", "has", "created", "an", "awesome", "opportunity"],
    id: "Arafat's departure has created an awesome opportunity.",
    author: "Joe Biden",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["taste", "cannot", "controlled", "by", "law"],
    words: ["taste", "cannot", "be", "controlled", "by", "law"],
    id: "Taste cannot be controlled by law.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["half", "truth", "often", "great", "lie"],
    words: ["half", "a", "truth", "is", "often", "great", "lie"],
    id: "Half a truth is often a great lie.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["observe", "all", "men", "thyself", "most"],
    words: ["observe", "all", "men", "thyself", "most"],
    id: "Observe all men, thyself most.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["ignorance", "never", "better", "than", "knowledge"],
    words: ["ignorance", "is", "never", "better", "than", "knowledge"],
    id: "Ignorance is never better than knowledge.",
    author: "Enrico Fermi",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "came", "saw", "god", "conquered"],
    words: ["i", "came", "saw", "god", "conquered"],
    id: "I came, I saw, God conquered.",
    author: "Charles V",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["sweet", "mercy", "nobility's", "true", "badge"],
    words: ["sweet", "mercy", "is", "nobility's", "true", "badge"],
    id: "Sweet mercy is nobility's true badge.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "grew", "up", "funny", "way"],
    words: ["i", "grew", "up", "in", "a", "funny", "way"],
    id: "I grew up in a funny way.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["as", "soon", "there", "life", "danger"],
    words: ["as", "soon", "there", "is", "life", "danger"],
    id: "As soon as there is life there is danger.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["write", "injuries", "dust", "benefits", "marble"],
    words: ["write", "injuries", "in", "dust", "benefits", "marble"],
    id: "Write injuries in dust, benefits in marble.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["achievement", "brings", "its", "own", "anticlimax"],
    words: ["achievement", "brings", "its", "own", "anticlimax"],
    id: "Achievement brings its own anticlimax.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["spoken", "word", "arouses", "our", "self-will"],
    words: ["every", "spoken", "word", "arouses", "our", "self-will"],
    id: "Every spoken word arouses our self-will.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "makes", "heroes", "dissolves", "celebrities"],
    words: ["time", "makes", "heroes", "but", "dissolves", "celebrities"],
    id: "Time makes heroes but dissolves celebrities.",
    author: "Daniel J. Boorstin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "mother", "died", "on", "birthday"],
    words: ["my", "mother", "died", "on", "birthday"],
    id: "My mother died on my birthday.",
    author: "Shannon Briggs",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["tesla", "becoming", "real", "car", "company"],
    words: ["tesla", "is", "becoming", "a", "real", "car", "company"],
    id: "Tesla is becoming a real car company.",
    author: "Elon Musk",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "terrified", "losing", "my", "voice"],
    words: ["i'm", "terrified", "of", "losing", "my", "voice"],
    id: "I'm terrified of losing my voice.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["produce", "great", "men", "rest", "follows"],
    words: ["produce", "great", "men", "the", "rest", "follows"],
    id: "Produce great men, the rest follows.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["silence", "better", "than", "unmeaning", "words"],
    words: ["silence", "is", "better", "than", "unmeaning", "words"],
    id: "Silence is better than unmeaning words.",
    author: "Pythagoras",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "family", "number", "one", "priority"],
    words: ["my", "family", "is", "number", "one", "priority"],
    id: "My family is my number one priority.",
    author: "Georgina Chapman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["perspective", "worth", "80", "iq", "points"],
    words: ["perspective", "is", "worth", "80", "iq", "points"],
    id: "Perspective is worth 80 IQ points.",
    author: "Alan Kay",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["nobody", "believes", "completely", "unadulterated", "capitalism"],
    words: ["nobody", "believes", "in", "completely", "unadulterated", "capitalism"],
    id: "Nobody believes in completely unadulterated capitalism.",
    author: "Bill Gates",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["computers", "double", "their", "performance", "month"],
    words: ["computers", "double", "their", "performance", "every", "month"],
    id: "Computers double their performance every month.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "dote", "on", "his", "absence"],
    words: ["i", "dote", "on", "his", "very", "absence"],
    id: "I dote on his very absence.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["energy", "persistence", "conquer", "all", "things"],
    words: ["energy", "and", "persistence", "conquer", "all", "things"],
    id: "Energy and persistence conquer all things.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "reject", "most", "conventional", "wisdom"],
    words: ["i", "reject", "most", "conventional", "wisdom"],
    id: "I reject most conventional wisdom.",
    author: "Rush Limbaugh",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["reason", "immortal", "all", "else", "mortal"],
    words: ["reason", "is", "immortal", "all", "else", "mortal"],
    id: "Reason is immortal, all else mortal.",
    author: "Pythagoras",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["soul", "never", "thinks", "without", "picture"],
    words: ["the", "soul", "never", "thinks", "without", "a", "picture"],
    id: "The soul never thinks without a picture.",
    author: "Aristotle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["virtue", "bold", "goodness", "never", "fearful"],
    words: ["virtue", "is", "bold", "and", "goodness", "never", "fearful"],
    id: "Virtue is bold, and goodness never fearful.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["self-knowledge", "comes", "from", "knowing", "men"],
    words: ["self-knowledge", "comes", "from", "knowing", "other", "men"],
    id: "Self-knowledge comes from knowing other men.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["guilty", "person", "his", "own", "hangman"],
    words: ["every", "guilty", "person", "is", "his", "own", "hangman"],
    id: "Every guilty person is his own hangman.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["nothing", "inspires", "forgiveness", "quite", "revenge"],
    words: ["nothing", "inspires", "forgiveness", "quite", "like", "revenge"],
    id: "Nothing inspires forgiveness quite like revenge.",
    author: "Scott Adams",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["empty", "vessel", "makes", "loudest", "sound"],
    words: ["the", "empty", "vessel", "makes", "loudest", "sound"],
    id: "The empty vessel makes the loudest sound.",
    author: "Plato",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["many", "foxes", "grow", "gray", "few"],
    words: ["many", "foxes", "grow", "gray", "but", "few", "good"],
    id: "Many foxes grow gray but few grow good.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["after", "all", "god", "because", "remembers"],
    words: ["after", "all", "god", "is", "because", "he", "remembers"],
    id: "After all, God is God because he remembers.",
    author: "Elie Wiesel",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["health", "valued", "till", "sickness", "comes"],
    words: ["health", "is", "not", "valued", "till", "sickness", "comes"],
    id: "Health is not valued till sickness comes.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "more", "life", "than", "movies"],
    words: ["there's", "more", "to", "life", "than", "movies"],
    id: "There's more to life than movies.",
    author: "Paul Walker",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "stupid", "changed", "by", "success"],
    words: ["only", "stupid", "people", "are", "changed", "by", "success"],
    id: "Only stupid people are changed by success.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["hidden", "harmony", "better", "than", "obvious"],
    words: ["the", "hidden", "harmony", "is", "better", "than", "obvious"],
    id: "The hidden harmony is better than the obvious.",
    author: "Heraclitus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["human", "mind", "our", "fundamental", "resource"],
    words: ["the", "human", "mind", "is", "our", "fundamental", "resource"],
    id: "The human mind is our fundamental resource.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "invented", "cordless", "extension", "cord"],
    words: ["i", "invented", "the", "cordless", "extension", "cord"],
    id: "I invented the cordless extension cord.",
    author: "Steven Wright",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "find", "happiness", "tiniest", "things"],
    words: ["i", "find", "happiness", "in", "the", "tiniest", "things"],
    id: "I find happiness in the tiniest things.",
    author: "Sai Pallavi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "ambition", "ends", "happiness", "begins"],
    words: ["when", "ambition", "ends", "happiness", "begins"],
    id: "When ambition ends, happiness begins.",
    author: "Thomas Merton",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "fails", "forward", "toward", "success"],
    words: ["one", "fails", "forward", "toward", "success"],
    id: "One fails forward toward success.",
    author: "Charles Kettering",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["win", "by", "tenderness", "conquer", "forgiveness"],
    words: ["we", "win", "by", "tenderness", "conquer", "forgiveness"],
    id: "We win by tenderness. We conquer by forgiveness.",
    author: "Frederick William Robertson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowing", "others", "wisdom", "yourself", "enlightenment"],
    words: ["knowing", "others", "is", "wisdom", "yourself", "enlightenment"],
    id: "Knowing others is wisdom, knowing yourself is Enlightenment.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["man's", "character", "his", "guardian", "divinity"],
    words: ["a", "man's", "character", "is", "his", "guardian", "divinity"],
    id: "A man's character is his guardian divinity.",
    author: "Heraclitus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["medicine", "heals", "doubts", "as", "diseases"],
    words: ["medicine", "heals", "doubts", "as", "well", "diseases"],
    id: "Medicine heals doubts as well as diseases.",
    author: "Karl Marx",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "parents", "magazine", "my", "home"],
    words: ["i", "have", "parents", "magazine", "in", "my", "home"],
    id: "I have 'Parents' magazine in my home.",
    author: "Joe Biden",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "looking", "sympathy", "at", "all"],
    words: ["i'm", "not", "looking", "for", "sympathy", "at", "all"],
    id: "I'm not looking for sympathy at all.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["everything", "rises", "falls", "on", "leadership"],
    words: ["everything", "rises", "and", "falls", "on", "leadership"],
    id: "Everything rises and falls on leadership.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["such", "bore", "being", "always", "poor"],
    words: ["it's", "such", "a", "bore", "being", "always", "poor"],
    id: "It's such a Bore Being always Poor.",
    author: "Langston Hughes",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["behind", "great", "fortune", "lies", "crime"],
    words: ["behind", "every", "great", "fortune", "lies", "a", "crime"],
    id: "Behind every great fortune lies a great crime.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "even", "support", "religious", "freedom"],
    words: ["i", "don't", "even", "support", "religious", "freedom"],
    id: "I don't even support religious freedom.",
    author: "John MacArthur",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["tyranny", "naturally", "arises", "out", "democracy"],
    words: ["tyranny", "naturally", "arises", "out", "of", "democracy"],
    id: "Tyranny naturally arises out of democracy.",
    author: "Plato",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "created", "myself", "taught", "much"],
    words: ["i", "created", "myself", "have", "taught", "so", "much"],
    id: "I created myself. I have taught myself so much.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "colorful", "sock", "i'm", "man"],
    words: ["i", "like", "a", "colorful", "sock", "i'm", "man"],
    id: "I like a colorful sock. I'm a sock man.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["history", "more", "or", "less", "bunk"],
    words: ["history", "is", "more", "or", "less", "bunk"],
    id: "History is more or less bunk.",
    author: "Henry Ford",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["suspicion", "always", "haunts", "guilty", "mind"],
    words: ["suspicion", "always", "haunts", "the", "guilty", "mind"],
    id: "Suspicion always haunts the guilty mind.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "always", "room", "at", "top"],
    words: ["there", "is", "always", "room", "at", "the", "top"],
    id: "There is always room at the top.",
    author: "Daniel Webster",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["unbeing", "dead", "isn't", "being", "alive"],
    words: ["unbeing", "dead", "isn't", "being", "alive"],
    id: "Unbeing dead isn't being alive.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["respond", "intelligently", "even", "unintelligent", "treatment"],
    words: ["respond", "intelligently", "even", "to", "unintelligent", "treatment"],
    id: "Respond intelligently even to unintelligent treatment.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "brings", "all", "things", "pass"],
    words: ["time", "brings", "all", "things", "to", "pass"],
    id: "Time brings all things to pass.",
    author: "Aeschylus",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["virtue", "itself", "scapes", "calumnious", "strokes"],
    words: ["virtue", "itself", "scapes", "not", "calumnious", "strokes"],
    id: "Virtue itself scapes not calumnious strokes.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "consists", "giving", "serving", "others"],
    words: ["happiness", "consists", "in", "giving", "and", "serving", "others"],
    id: "Happiness... consists in giving, and in serving others.",
    author: "Henry Drummond",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "intend", "make", "georgia", "howl"],
    words: ["i", "intend", "to", "make", "georgia", "howl"],
    id: "I intend to make Georgia howl.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "call", "architecture", "frozen", "music"],
    words: ["i", "call", "architecture", "frozen", "music"],
    id: "I call architecture frozen music.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["age", "merely", "shows", "children", "remain"],
    words: ["age", "merely", "shows", "what", "children", "we", "remain"],
    id: "Age merely shows what children we remain.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["history", "mostly", "guessing", "rest", "prejudice"],
    words: ["history", "is", "mostly", "guessing", "the", "rest", "prejudice"],
    id: "History is mostly guessing; the rest is prejudice.",
    author: "Will Durant",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["science", "basically", "inoculation", "against", "charlatans"],
    words: ["science", "is", "basically", "an", "inoculation", "against", "charlatans"],
    id: "Science is basically an inoculation against charlatans.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "read", "books", "listen", "music"],
    words: ["i", "like", "to", "read", "books", "listen", "music"],
    id: "I like to read books. I like to listen to music.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["future", "green", "energy", "sustainability", "renewable"],
    words: ["the", "future", "is", "green", "energy", "sustainability", "renewable"],
    id: "The future is green energy, sustainability, renewable energy.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "said", "most", "things"],
    words: ["i", "never", "said", "most", "of", "the", "things"],
    id: "I never said most of the things I said.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["greed", "all", "nature", "too", "little"],
    words: ["for", "greed", "all", "nature", "is", "too", "little"],
    id: "For greed all nature is too little.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["without", "electricity", "air", "would", "rot"],
    words: ["without", "electricity", "the", "air", "would", "rot"],
    id: "Without electricity, the air would rot.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "family", "was", "guide", "reality"],
    words: ["my", "family", "was", "guide", "to", "reality"],
    id: "My family was my guide to my reality.",
    author: "Haywood Nelson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "stand", "anti-bigotry", "anti-semitism", "anti-racism"],
    words: ["i", "stand", "for", "anti-bigotry", "anti-semitism", "and", "anti-racism"],
    id: "I stand for anti-bigotry, anti-Semitism, and anti-racism.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["zeal", "will", "more", "than", "knowledge"],
    words: ["zeal", "will", "do", "more", "than", "knowledge"],
    id: "Zeal will do more than knowledge.",
    author: "William Hazlitt",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["saying", "nothing", "sometimes", "says", "most"],
    words: ["saying", "nothing", "sometimes", "says", "the", "most"],
    id: "Saying nothing... sometimes says the most.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "teachers", "deserve", "better", "feedback"],
    words: ["our", "teachers", "deserve", "better", "feedback"],
    id: "Our teachers deserve better feedback.",
    author: "Bill Gates",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "confuse", "single", "defeat", "final"],
    words: ["never", "confuse", "a", "single", "defeat", "with", "final"],
    id: "Never confuse a single defeat with a final defeat.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["on", "all", "peaks", "lies", "peace"],
    words: ["on", "all", "the", "peaks", "lies", "peace"],
    id: "On all the peaks lies peace.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["many", "called", "few", "get", "up"],
    words: ["many", "are", "called", "but", "few", "get", "up"],
    id: "Many are called but few get up.",
    author: "Oliver Herford",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "panic", "everyone", "else", "panics"],
    words: ["if", "i", "panic", "everyone", "else", "panics"],
    id: "If I panic, everyone else panics.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "played", "anything", "toys"],
    words: ["i", "never", "played", "with", "anything", "like", "toys"],
    id: "I never played with anything like toys.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "ain't", "really", "got", "problems"],
    words: ["i", "ain't", "really", "got", "problems", "with", "people"],
    id: "I ain't really got problems with people.",
    author: "King Von",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["everything", "i", "learned", "from", "movies"],
    words: ["everything", "i", "learned", "from", "the", "movies"],
    id: "Everything I learned I learned from the movies.",
    author: "Audrey Hepburn",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "intentionally", "make", "deep", "movies"],
    words: ["i", "don't", "intentionally", "make", "deep", "movies"],
    id: "I don't intentionally make deep movies.",
    author: "Hayao Miyazaki",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["shared", "joys", "make", "friend", "sufferings"],
    words: ["shared", "joys", "make", "a", "friend", "not", "sufferings"],
    id: "Shared joys make a friend, not shared sufferings.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "happiness", "depends", "on", "leisurely"],
    words: ["all", "happiness", "depends", "on", "a", "leisurely", "breakfast"],
    id: "All happiness depends on a leisurely breakfast.",
    author: "John Gunther",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["many", "tibetans", "sacrifice", "their", "lives"],
    words: ["many", "tibetans", "sacrifice", "their", "lives"],
    id: "Many Tibetans sacrifice their lives.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["nation", "destroys", "its", "soil", "itself"],
    words: ["the", "nation", "that", "destroys", "its", "soil", "itself"],
    id: "The nation that destroys its soil destroys itself.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["violent", "anger", "makes", "physically", "ill"],
    words: ["violent", "anger", "makes", "me", "physically", "ill"],
    id: "Violent anger makes me physically ill.",
    author: "Langston Hughes",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["war", "at", "its", "best", "barbarism"],
    words: ["war", "is", "at", "its", "best", "barbarism"],
    id: "War is at its best barbarism.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "must", "one's", "own", "time"],
    words: ["one", "must", "be", "of", "one's", "own", "time"],
    id: "One must be of one's own time.",
    author: "Honoré Daumier",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["as", "much", "by", "writing", "reading"],
    words: ["learn", "as", "much", "by", "writing", "reading"],
    id: "Learn as much by writing as by reading.",
    author: "John Dalberg-Acton",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["power", "forgiveness", "transcends", "personal", "relationships"],
    words: ["the", "power", "of", "forgiveness", "transcends", "personal", "relationships"],
    id: "The power of forgiveness transcends personal relationships.",
    author: "Eric Metaxas",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "draw", "from", "crowd", "lot"],
    words: ["i", "draw", "from", "the", "crowd", "a", "lot"],
    id: "I draw from the crowd a lot.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "exhausted", "trying", "stay", "healthy"],
    words: ["i'm", "exhausted", "trying", "to", "stay", "healthy"],
    id: "I'm exhausted trying to stay healthy.",
    author: "Steve Yzerman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["silence", "true", "wisdom's", "best", "reply"],
    words: ["silence", "is", "true", "wisdom's", "best", "reply"],
    id: "Silence is true wisdom's best reply.",
    author: "Euripides",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["myspace", "my", "wife", "facebook", "mistress"],
    words: ["myspace", "is", "my", "wife", "facebook", "mistress"],
    id: "MySpace is my wife... Facebook is my mistress.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["kindness", "i've", "discovered", "everything", "life"],
    words: ["kindness", "i've", "discovered", "is", "everything", "in", "life"],
    id: "Kindness, I've discovered, is everything in life.",
    author: "Isaac Bashevis Singer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["oh", "i", "horror", "movies", "yeah"],
    words: ["oh", "i", "love", "horror", "movies", "yeah"],
    id: "Oh, I love horror movies, yeah.",
    author: "Norman Reedus",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["hell", "circumstances", "i", "create", "opportunities"],
    words: ["to", "hell", "with", "circumstances", "i", "create", "opportunities"],
    id: "To hell with circumstances; I create opportunities.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["extreme", "hopes", "born", "from", "misery"],
    words: ["extreme", "hopes", "are", "born", "from", "misery"],
    id: "Extreme hopes are born from extreme misery.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["honour", "sinks", "commerce", "long", "prevails"],
    words: ["honour", "sinks", "where", "commerce", "long", "prevails"],
    id: "Honour sinks where commerce long prevails.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "support", "liberty", "true", "freedom"],
    words: ["i", "support", "liberty", "true", "freedom"],
    id: "I support liberty; I support true freedom.",
    author: "David Draiman",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["most", "simple", "things", "bring", "happiness"],
    words: ["the", "most", "simple", "things", "can", "bring", "happiness"],
    id: "The most simple things can bring the most happiness.",
    author: "Izabella Scorupco",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["power", "means", "happiness", "hard", "sacrifice"],
    words: ["power", "means", "happiness", "hard", "work", "and", "sacrifice"],
    id: "Power means happiness; power means hard work and sacrifice.",
    author: "Beyonce Knowles",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["fear", "its", "use", "cowardice", "none"],
    words: ["fear", "has", "its", "use", "but", "cowardice", "none"],
    id: "Fear has its use but cowardice has none.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["greater", "power", "more", "dangerous", "abuse"],
    words: ["the", "greater", "power", "more", "dangerous", "abuse"],
    id: "The greater the power, the more dangerous the abuse.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "based", "on", "your", "perspective"],
    words: ["success", "is", "based", "on", "your", "perspective"],
    id: "Success is based on your perspective.",
    author: "CM Punk",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "death", "tragedy", "million", "statistic"],
    words: ["one", "death", "is", "a", "tragedy", "million", "statistic"],
    id: "One death is a tragedy; one million is a statistic.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["dies", "only", "when", "growth", "stops"],
    words: ["love", "dies", "only", "when", "growth", "stops"],
    id: "Love dies only when growth stops.",
    author: "Pearl S. Buck",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["o", "had", "i", "followed", "arts"],
    words: ["o", "had", "i", "but", "followed", "the", "arts"],
    id: "O, had I but followed the arts!",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["without", "gambling", "i", "would", "exist"],
    words: ["without", "gambling", "i", "would", "not", "exist"],
    id: "Without gambling, I would not exist.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "was", "adored", "once", "too"],
    words: ["i", "was", "adored", "once", "too"],
    id: "I was adored once too.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "time", "spent", "eternity", "begins"],
    words: ["when", "time", "is", "spent", "eternity", "begins"],
    id: "When Time is spent, Eternity begins.",
    author: "Helen Hunt Jackson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["each", "provides", "its", "own", "gifts"],
    words: ["each", "day", "provides", "its", "own", "gifts"],
    id: "Each day provides its own gifts.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["ordinary", "morality", "innate", "my", "view"],
    words: ["ordinary", "morality", "is", "innate", "in", "my", "view"],
    id: "Ordinary morality is innate in my view.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["life's", "more", "amusing", "than", "thought"],
    words: ["life's", "more", "amusing", "than", "we", "thought"],
    id: "Life's more amusing than we thought.",
    author: "Andrew Lang",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["turn", "your", "wounds", "into", "wisdom"],
    words: ["turn", "your", "wounds", "into", "wisdom"],
    id: "Turn your wounds into wisdom.",
    author: "Oprah Winfrey",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["made", "too", "many", "wrong", "mistakes"],
    words: ["we", "made", "too", "many", "wrong", "mistakes"],
    id: "We made too many wrong mistakes.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["nature", "never", "breaks", "own", "laws"],
    words: ["nature", "never", "breaks", "her", "own", "laws"],
    id: "Nature never breaks her own laws.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "mind", "your", "happiness", "duty"],
    words: ["never", "mind", "your", "happiness", "do", "duty"],
    id: "Never mind your happiness; do your duty.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "was", "reared", "church", "presbyterian"],
    words: ["i", "was", "reared", "in", "the", "church", "presbyterian"],
    id: "I was reared in the church, in the Presbyterian Church.",
    author: "Billy Graham",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "said", "half", "things"],
    words: ["i", "never", "said", "half", "the", "things"],
    id: "I never said half the things I said.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "owe", "nothing", "women's", "lib"],
    words: ["i", "owe", "nothing", "to", "women's", "lib"],
    id: "I owe nothing to Women's Lib.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["softest", "things", "world", "overcome", "hardest"],
    words: ["the", "softest", "things", "in", "world", "overcome", "hardest"],
    id: "The softest things in the world overcome the hardest things in the world.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "believed", "birthday", "parties"],
    words: ["i", "never", "believed", "in", "birthday", "parties"],
    id: "I never believed in birthday parties.",
    author: "Sanjay Leela Bhansali",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["demands", "infinitely", "less", "than", "friendship"],
    words: ["love", "demands", "infinitely", "less", "than", "friendship"],
    id: "Love demands infinitely less than friendship.",
    author: "George Jean Nathan",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["bible", "cradle", "wherein", "christ", "laid"],
    words: ["the", "bible", "is", "cradle", "wherein", "christ", "laid"],
    id: "The Bible is the cradle wherein Christ is laid.",
    author: "Martin Luther",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "miss", "-", "being", "anonymous"],
    words: ["i", "just", "miss", "-", "being", "anonymous"],
    id: "I just miss - I miss being anonymous.",
    author: "Barack Obama",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "finds", "limits", "by", "pushing"],
    words: ["one", "finds", "limits", "by", "pushing", "them"],
    id: "One finds limits by pushing them.",
    author: "Herbert A. Simon",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["blood", "alone", "moves", "wheels", "history"],
    words: ["blood", "alone", "moves", "the", "wheels", "of", "history"],
    id: "Blood alone moves the wheels of history.",
    author: "Martin Luther",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["joan", "arc", "had", "style", "jesus"],
    words: ["joan", "of", "arc", "had", "style", "jesus"],
    id: "Joan of Arc had style. Jesus had style.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["fear", "cannot", "without", "hope", "nor"],
    words: ["fear", "cannot", "be", "without", "hope", "nor"],
    id: "Fear cannot be without hope nor hope without fear.",
    author: "Baruch Spinoza",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["judgment", "comes", "from", "experience", "bad"],
    words: ["good", "judgment", "comes", "from", "experience", "and", "bad"],
    id: "Good judgment comes from experience, and experience comes from bad judgment.",
    author: "Rita Mae Brown",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "government", "course", "against", "liberty"],
    words: ["all", "government", "of", "course", "is", "against", "liberty"],
    id: "All government, of course, is against liberty.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["pretend", "inferiority", "encourage", "his", "arrogance"],
    words: ["pretend", "inferiority", "and", "encourage", "his", "arrogance"],
    id: "Pretend inferiority and encourage his arrogance.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "as", "many", "opinions", "experts"],
    words: ["there", "are", "as", "many", "opinions", "experts"],
    id: "There are as many opinions as there are experts.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "must", "cruel", "only", "kind"],
    words: ["i", "must", "be", "cruel", "only", "to", "kind"],
    id: "I must be cruel, only to be kind.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["thought", "because", "had", "power", "wisdom"],
    words: ["we", "thought", "because", "had", "power", "wisdom"],
    id: "We thought, because we had power, we had wisdom.",
    author: "Stephen Vincent Benet",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["things", "will", "come", "from", "self-expression"],
    words: ["good", "things", "will", "come", "from", "self-expression"],
    id: "Good things will come from self-expression.",
    author: "John Cho",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "cannot", "live", "without", "books"],
    words: ["i", "cannot", "live", "without", "books"],
    id: "I cannot live without books.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["grief", "changes", "shape", "never", "ends"],
    words: ["grief", "changes", "shape", "but", "it", "never", "ends"],
    id: "Grief changes shape, but it never ends.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["up", "lose", "hope", "sell", "out"],
    words: ["don't", "give", "up", "lose", "hope", "sell", "out"],
    id: "Don't give up. Don't lose hope. Don't sell out.",
    author: "Christopher Reeve",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["while", "postponing", "life", "speeds", "by"],
    words: ["while", "we", "are", "postponing", "life", "speeds", "by"],
    id: "While we are postponing, life speeds by.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "lies", "first", "all", "health"],
    words: ["happiness", "lies", "first", "of", "all", "in", "health"],
    id: "Happiness lies first of all in health.",
    author: "George William Curtis",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["men", "only", "as", "great", "kind"],
    words: ["men", "are", "only", "as", "great", "they", "kind"],
    id: "Men are only as great as they are kind.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["self-development", "higher", "duty", "than", "self-sacrifice"],
    words: ["self-development", "is", "a", "higher", "duty", "than", "self-sacrifice"],
    id: "Self-development is a higher duty than self-sacrifice.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["why", "should", "poetry", "make", "sense"],
    words: ["why", "should", "poetry", "have", "to", "make", "sense"],
    id: "Why should poetry have to make sense?",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "companies", "built", "on", "products"],
    words: ["great", "companies", "are", "built", "on", "products"],
    id: "Great companies are built on great products.",
    author: "Elon Musk",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["without", "hard", "nothing", "grows", "weeds"],
    words: ["without", "hard", "work", "nothing", "grows", "but", "weeds"],
    id: "Without hard work, nothing grows but weeds.",
    author: "Gordon B. Hinckley",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["pessimism", "leads", "weakness", "optimism", "power"],
    words: ["pessimism", "leads", "to", "weakness", "optimism", "power"],
    id: "Pessimism leads to weakness, optimism to power.",
    author: "William James",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["flattery", "corrupts", "both", "receiver", "giver"],
    words: ["flattery", "corrupts", "both", "the", "receiver", "and", "giver"],
    id: "Flattery corrupts both the receiver and the giver.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["denis", "law", "could", "on", "eggshells"],
    words: ["denis", "law", "could", "dance", "on", "eggshells"],
    id: "Denis Law could dance on eggshells.",
    author: "Bill Shankly",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["lo", "huge", "heaps", "littleness", "around"],
    words: ["lo", "what", "huge", "heaps", "of", "littleness", "around"],
    id: "Lo, what huge heaps of littleness around!",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["even", "napoleon", "had", "his", "watergate"],
    words: ["even", "napoleon", "had", "his", "watergate"],
    id: "Even Napoleon had his Watergate.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["ruffled", "mind", "makes", "restless", "pillow"],
    words: ["a", "ruffled", "mind", "makes", "restless", "pillow"],
    id: "A ruffled mind makes a restless pillow.",
    author: "Charlotte Bronte",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i've", "always", "enjoyed", "poor", "health"],
    words: ["i've", "always", "enjoyed", "poor", "health"],
    id: "I've always enjoyed poor health.",
    author: "Taylor Caldwell",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "my", "movies", "achingly", "personal"],
    words: ["all", "my", "movies", "are", "achingly", "personal"],
    id: "All my movies are achingly personal.",
    author: "Quentin Tarantino",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["computers", "make", "totally", "blank", "out"],
    words: ["computers", "make", "me", "totally", "blank", "out"],
    id: "Computers make me totally blank out.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["taste", "may", "change", "inclination", "never"],
    words: ["taste", "may", "change", "but", "inclination", "never"],
    id: "Taste may change, but inclination never.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["forgiveness", "indifference", "impossible", "while", "lasts"],
    words: ["forgiveness", "is", "indifference", "impossible", "while", "love", "lasts"],
    id: "Forgiveness is indifference. Forgiveness is impossible while love lasts.",
    author: "Mary Boykin Chesnut",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "always", "been", "great", "liar"],
    words: ["success", "has", "always", "been", "a", "great", "liar"],
    id: "Success has always been a great liar.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["things", "happen", "when", "meet", "strangers"],
    words: ["good", "things", "happen", "when", "you", "meet", "strangers"],
    id: "Good things happen when you meet strangers.",
    author: "Yo-Yo Ma",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "favorite", "holding", "period", "forever"],
    words: ["our", "favorite", "holding", "period", "is", "forever"],
    id: "Our favorite holding period is forever.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["music", "melody", "whose", "text", "world"],
    words: ["music", "is", "the", "melody", "whose", "text", "world"],
    id: "Music is the melody whose text is the world.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["laugh", "much", "or", "often", "unrestrainedly"],
    words: ["do", "not", "laugh", "much", "or", "often", "unrestrainedly"],
    id: "Do not laugh much or often or unrestrainedly.",
    author: "Epictetus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "praise", "loudly", "blame", "softly"],
    words: ["i", "praise", "loudly", "blame", "softly"],
    id: "I praise loudly. I blame softly.",
    author: "Catherine the Great",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["harder", "conflict", "more", "glorious", "triumph"],
    words: ["the", "harder", "conflict", "more", "glorious", "triumph"],
    id: "The harder the conflict, the more glorious the triumph.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "goes", "hand-in-hand", "mutual", "respect"],
    words: ["freedom", "goes", "hand-in-hand", "with", "mutual", "respect"],
    id: "Freedom goes hand-in-hand with mutual respect.",
    author: "Kay Rala Xanana Gusmao",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "age", "nor", "weariness", "defeat"],
    words: ["i", "know", "not", "age", "nor", "weariness", "defeat"],
    id: "I know not age, nor weariness nor defeat.",
    author: "Rose Kennedy",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["sins", "cannot", "undone", "only", "forgiven"],
    words: ["sins", "cannot", "be", "undone", "only", "forgiven"],
    id: "Sins cannot be undone, only forgiven.",
    author: "Igor Stravinsky",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["wisdom", "comes", "alone", "through", "suffering"],
    words: ["wisdom", "comes", "alone", "through", "suffering"],
    id: "Wisdom comes alone through suffering.",
    author: "Aeschylus",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "too", "short", "long-term", "grudges"],
    words: ["life", "is", "too", "short", "for", "long-term", "grudges"],
    id: "Life is too short for long-term grudges.",
    author: "Elon Musk",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["means", "commit", "yourself", "without", "guarantee"],
    words: ["love", "means", "to", "commit", "yourself", "without", "guarantee"],
    id: "Love means to commit yourself without guarantee.",
    author: "Anne Campbell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["question", "everything", "something", "answer", "nothing"],
    words: ["question", "everything", "learn", "something", "answer", "nothing"],
    id: "Question everything. Learn something. Answer nothing.",
    author: "Euripides",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["cherub's", "face", "reptile", "all", "rest"],
    words: ["a", "cherub's", "face", "reptile", "all", "the", "rest"],
    id: "A cherub's face, a reptile all the rest.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "really", "normal", "football", "manager"],
    words: ["i'm", "really", "a", "normal", "football", "manager"],
    id: "I'm really a normal football manager.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "change", "your", "fate", "attitude"],
    words: ["if", "you", "can't", "change", "your", "fate", "attitude"],
    id: "If you can't change your fate, change your attitude.",
    author: "Charles Revson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["evil", "prospers", "when", "men", "nothing"],
    words: ["evil", "prospers", "when", "good", "men", "do", "nothing"],
    id: "Evil prospers when good men do nothing.",
    author: "John Philpot Curran",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "great", "achievements", "require", "time"],
    words: ["all", "great", "achievements", "require", "time"],
    id: "All great achievements require time.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "crime", "thrillers", "heist", "movies"],
    words: ["i", "like", "crime", "thrillers", "heist", "movies"],
    id: "I like crime thrillers. I like heist movies.",
    author: "Jason Statham",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "only", "security", "ability", "change"],
    words: ["our", "only", "security", "is", "ability", "to", "change"],
    id: "Our only security is our ability to change.",
    author: "John Lilly",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["something", "always", "comes", "from", "bad"],
    words: ["something", "good", "always", "comes", "from", "bad"],
    id: "Something good always comes from something bad.",
    author: "Brady Jandreau",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "always", "entertain", "great", "hopes"],
    words: ["i", "always", "entertain", "great", "hopes"],
    id: "I always entertain great hopes.",
    author: "Robert Frost",
    grammar: null,
    word_count: 5,
    all_intersection_count: 0,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "shall", "exist", "eternally", "how"],
    words: ["if", "i", "shall", "exist", "eternally", "how", "tomorrow"],
    id: "If I shall exist eternally, how shall I exist tomorrow?",
    author: "Franz Kafka",
    grammar: null,
    word_count: 7,
    all_intersection_count: 2,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "doubt", "take", "more", "time"],
    words: ["when", "in", "doubt", "take", "more", "time"],
    id: "When in doubt, take more time.",
    author: "John Zimmerman",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["caesar's", "wife", "must", "above", "suspicion"],
    words: ["caesar's", "wife", "must", "be", "above", "suspicion"],
    id: "Caesar's wife must be above suspicion.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 6,
    all_intersection_count: 1,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "play", "hard", "at", "all"],
    words: ["when", "you", "play", "hard", "work", "don't", "at", "all"],
    id: "When you play, play hard; when you work, don't play at all.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "painting", "picture", "doing", "sum"],
    words: ["life", "is", "painting", "a", "picture", "not", "doing", "sum"],
    id: "Life is painting a picture, not doing a sum.",
    author: "Oliver Wendell Holmes, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["failure", "condiment", "success", "its", "flavor"],
    words: ["failure", "is", "the", "condiment", "that", "gives", "success", "its", "flavor"],
    id: "Failure is the condiment that gives success its flavor.",
    author: "Truman Capote",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "threat", "freedom", "absence", "criticism"],
    words: ["the", "greatest", "threat", "to", "freedom", "is", "absence", "of", "criticism"],
    id: "The greatest threat to freedom is the absence of criticism.",
    author: "Wole Soyinka",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["doubt", "never", "been", "on", "terms"],
    words: ["love", "and", "doubt", "have", "never", "been", "on", "speaking", "terms"],
    id: "Love and doubt have never been on speaking terms.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["because", "things", "way", "will", "stay"],
    words: ["because", "things", "are", "the", "way", "they", "will", "not", "stay"],
    id: "Because things are the way they are, things will not stay the way they are.",
    author: "Bertolt Brecht",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["you'll", "time", "rest", "when", "dead"],
    words: ["you'll", "have", "time", "to", "rest", "when", "you're", "dead"],
    id: "You'll have time to rest when you're dead.",
    author: "Robert De Niro",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["will", "punished", "your", "anger", "by"],
    words: ["you", "will", "not", "be", "punished", "for", "your", "anger", "by"],
    id: "You will not be punished for your anger, you will be punished by your anger.",
    author: "Buddha",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["discussion", "exchange", "knowledge", "argument", "ignorance"],
    words: ["discussion", "is", "an", "exchange", "of", "knowledge", "argument", "ignorance"],
    id: "Discussion is an exchange of knowledge; an argument an exchange of ignorance.",
    author: "Robert Quillen",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "as", "art", "moves", "curves"],
    words: ["in", "life", "as", "art", "the", "beautiful", "moves", "curves"],
    id: "In life, as in art, the beautiful moves in curves.",
    author: "Edward G. Bulwer-Lytton",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["was", "there", "ever", "praised", "his"],
    words: ["but", "was", "there", "ever", "dog", "that", "praised", "his", "fleas"],
    id: "But was there ever dog that praised his fleas?",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["impose", "on", "others", "yourself", "desire"],
    words: ["do", "not", "impose", "on", "others", "what", "you", "yourself", "desire"],
    id: "Do not impose on others what you yourself do not desire.",
    author: "Confucius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["everything", "comes", "hustles", "while", "waits"],
    words: ["everything", "comes", "to", "him", "who", "hustles", "while", "he", "waits"],
    id: "Everything comes to him who hustles while he waits.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["believing", "force", "same", "as", "gravitation"],
    words: ["not", "believing", "in", "force", "is", "the", "same", "as", "gravitation"],
    id: "Not believing in force is the same as not believing in gravitation.",
    author: "Thomas Hobbes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["god's", "family", "there", "outsiders", "enemies"],
    words: ["in", "god's", "family", "there", "are", "no", "outsiders", "enemies"],
    id: "In God's family, there are no outsiders, no enemies.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["nine", "men", "ten", "would", "suicides"],
    words: ["nine", "men", "in", "ten", "are", "would", "be", "suicides"],
    id: "Nine men in ten are would be suicides.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["social-networking", "thing", "takes", "crazy", "places"],
    words: ["this", "social-networking", "thing", "takes", "you", "to", "crazy", "places"],
    id: "This social-networking thing takes you to crazy places.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "football", "always", "expression", "emotion"],
    words: ["the", "best", "football", "is", "always", "about", "expression", "of", "emotion"],
    id: "The best football is always about expression of emotion. Always.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "something", "dangerous", "sauces", "body"],
    words: ["if", "there's", "something", "dangerous", "sauces", "are", "for", "the", "body"],
    id: "If there's something dangerous, sauces are dangerous for the body.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["ear", "only", "true", "writer", "reader"],
    words: ["the", "ear", "is", "only", "true", "writer", "and", "reader"],
    id: "The ear is the only true writer and the only true reader.",
    author: "Robert Frost",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "marriage", "without", "there", "will"],
    words: ["where", "there's", "marriage", "without", "love", "there", "will", "be"],
    id: "Where there's marriage without love, there will be love without marriage.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["composes", "himself", "wiser", "than", "book"],
    words: ["he", "that", "composes", "himself", "is", "wiser", "than", "a", "book"],
    id: "He that composes himself is wiser than he that composes a book.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["retreating", "-", "advancing", "another", "direction"],
    words: ["we", "are", "not", "retreating", "-", "advancing", "in", "another", "direction"],
    id: "We are not retreating - we are advancing in another direction.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["self-interest", "enemy", "all", "true", "affection"],
    words: ["self-interest", "is", "the", "enemy", "of", "all", "true", "affection"],
    id: "Self-interest is the enemy of all true affection.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["plato", "dear", "dearer", "still", "truth"],
    words: ["plato", "is", "dear", "to", "me", "but", "dearer", "still", "truth"],
    id: "Plato is dear to me, but dearer still is truth.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "end", "result", "all", "true"],
    words: ["change", "is", "the", "end", "result", "of", "all", "true", "learning"],
    id: "Change is the end result of all true learning.",
    author: "Leo Buscaglia",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["whatever", "received", "according", "nature", "recipient"],
    words: ["whatever", "is", "received", "according", "to", "the", "nature", "of", "recipient"],
    id: "Whatever is received is received according to the nature of the recipient.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["will", "burn", "bridge", "when", "come"],
    words: ["we", "will", "burn", "that", "bridge", "when", "come", "to", "it"],
    id: "We will burn that bridge when we come to it.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "on", "my", "birthday", "always"],
    words: ["i", "like", "working", "on", "my", "birthday", "so", "always", "do"],
    id: "I like working on my birthday, so I always do.",
    author: "Abhishek Bachchan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["unbecoming", "young", "men", "utter", "maxims"],
    words: ["it", "is", "unbecoming", "for", "young", "men", "to", "utter", "maxims"],
    id: "It is unbecoming for young men to utter maxims.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["honest", "disagreement", "often", "sign", "progress"],
    words: ["honest", "disagreement", "is", "often", "a", "good", "sign", "of", "progress"],
    id: "Honest disagreement is often a good sign of progress.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["reason", "or", "she'll", "make", "feel"],
    words: ["hear", "reason", "or", "she'll", "make", "you", "feel", "her"],
    id: "Hear reason, or she'll make you feel her.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "materialist", "conception", "history", "valid"],
    words: ["i", "think", "the", "materialist", "conception", "of", "history", "is", "valid"],
    id: "I think the materialist conception of history is valid.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["movies", "by", "nature", "subjective", "objective"],
    words: ["movies", "by", "nature", "are", "not", "subjective", "they're", "objective"],
    id: "Movies, by nature, are not subjective, they're objective.",
    author: "Stephen Chbosky",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["reason", "man", "rather", "god", "world"],
    words: ["reason", "in", "man", "is", "rather", "like", "god", "the", "world"],
    id: "Reason in man is rather like God in the world.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["certain", "defects", "necessary", "existence", "individuality"],
    words: [
      "certain",
      "defects",
      "are",
      "necessary",
      "for",
      "the",
      "existence",
      "of",
      "individuality",
    ],
    id: "Certain defects are necessary for the existence of individuality.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["memories", "make", "our", "family", "everything"],
    words: ["the", "memories", "we", "make", "with", "our", "family", "is", "everything"],
    id: "The memories we make with our family is everything.",
    author: "Candace Cameron Bure",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "one", "aim", "per", "debate"],
    words: ["you", "can", "only", "have", "one", "aim", "per", "debate"],
    id: "You can only have one aim per debate.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["laughter", "tonic", "relief", "surcease", "pain"],
    words: ["laughter", "is", "the", "tonic", "relief", "surcease", "for", "pain"],
    id: "Laughter is the tonic, the relief, the surcease for pain.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "death", "only", "change", "worlds"],
    words: ["there", "is", "no", "death", "only", "a", "change", "of", "worlds"],
    id: "There is no death, only a change of worlds.",
    author: "Chief Seattle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["regrets", "natural", "property", "grey", "hairs"],
    words: ["regrets", "are", "the", "natural", "property", "of", "grey", "hairs"],
    id: "Regrets are the natural property of grey hairs.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["politeness", "human", "nature", "warmth", "wax"],
    words: ["politeness", "is", "to", "human", "nature", "what", "warmth", "wax"],
    id: "Politeness is to human nature what warmth is to wax.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "war", "may", "live", "peace"],
    words: ["we", "make", "war", "that", "may", "live", "in", "peace"],
    id: "We make war that we may live in peace.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "possible", "source", "evil", "except"],
    words: ["there", "is", "no", "possible", "source", "of", "evil", "except", "good"],
    id: "There is no possible source of evil except good.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "comes", "from", "earth", "returns"],
    words: ["life", "comes", "from", "the", "earth", "and", "returns", "to"],
    id: "Life comes from the earth and life returns to the earth.",
    author: "Zhuangzi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["suffering", "one", "side", "always", "more"],
    words: ["love", "is", "suffering", "one", "side", "always", "loves", "more"],
    id: "Love is suffering. One side always loves more.",
    author: "Catherine Deneuve",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "isn't", "matter", "milestones", "moments"],
    words: ["life", "isn't", "a", "matter", "of", "milestones", "but", "moments"],
    id: "Life isn't a matter of milestones, but of moments.",
    author: "Rose Kennedy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["may", "your", "words", "feel", "attitude"],
    words: ["people", "may", "hear", "your", "words", "but", "they", "feel", "attitude"],
    id: "People may hear your words, but they feel your attitude.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "ever", "learned", "fully", "themselves"],
    words: ["no", "one", "has", "ever", "learned", "fully", "to", "know", "themselves"],
    id: "No one has ever learned fully to know themselves.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["slaves", "generally", "expected", "sing", "as"],
    words: ["slaves", "are", "generally", "expected", "to", "sing", "as", "well", "work"],
    id: "Slaves are generally expected to sing as well as to work.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["risk", "comes", "from", "knowing", "doing"],
    words: ["risk", "comes", "from", "not", "knowing", "what", "you're", "doing"],
    id: "Risk comes from not knowing what you're doing.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["theory", "there", "difference", "between", "practice"],
    words: ["in", "theory", "there", "is", "no", "difference", "between", "and", "practice"],
    id: "In theory there is no difference between theory and practice. In practice there is.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "life", "because", "more", "there"],
    words: ["i", "love", "life", "because", "what", "more", "is", "there"],
    id: "I love life because what more is there?",
    author: "Anthony Hopkins",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "birth", "consciousness", "without", "pain"],
    words: ["there", "is", "no", "birth", "of", "consciousness", "without", "pain"],
    id: "There is no birth of consciousness without pain.",
    author: "Carl Jung",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["power", "positive", "change", "your", "life"],
    words: ["the", "power", "of", "positive", "thinking", "can", "change", "your", "life"],
    id: "The power of positive thinking can change your life.",
    author: "Devin McCourty",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["may", "without", "loving", "cannot", "giving"],
    words: ["we", "may", "give", "without", "loving", "but", "cannot", "love", "giving"],
    id: "We may give without loving, but we cannot love without giving.",
    author: "Bernard Meltzer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "serene", "criticism", "as", "yet"],
    words: ["there", "is", "no", "just", "and", "serene", "criticism", "as", "yet"],
    id: "There is no just and serene criticism as yet.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "man", "grins", "when", "fights"],
    words: ["i", "like", "a", "man", "who", "grins", "when", "he", "fights"],
    id: "I like a man who grins when he fights.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "job", "team", "chance", "win"],
    words: ["my", "job", "is", "to", "give", "team", "a", "chance", "win"],
    id: "My job is to give my team a chance to win.",
    author: "Nolan Ryan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["nothing", "fatal", "religion", "as", "indifference"],
    words: ["nothing", "is", "so", "fatal", "to", "religion", "as", "indifference"],
    id: "Nothing is so fatal to religion as indifference.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowledge", "becomes", "evil", "aim", "virtuous"],
    words: ["knowledge", "becomes", "evil", "if", "the", "aim", "be", "not", "virtuous"],
    id: "Knowledge becomes evil if the aim be not virtuous.",
    author: "Plato",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["poverty", "lack", "knowledge", "must", "challenged"],
    words: ["poverty", "and", "lack", "of", "knowledge", "must", "be", "challenged"],
    id: "Poverty and lack of knowledge must be challenged.",
    author: "Russell Simmons",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "still", "cure", "common", "birthday"],
    words: ["there", "is", "still", "no", "cure", "for", "the", "common", "birthday"],
    id: "There is still no cure for the common birthday.",
    author: "John Glenn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["those", "been", "writing", "literature", "life"],
    words: ["those", "who", "have", "been", "writing", "literature", "not", "life"],
    id: "Those who have been writing literature have not been writing life.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["false", "quarrel", "there", "true", "valor"],
    words: ["in", "a", "false", "quarrel", "there", "is", "no", "true", "valor"],
    id: "In a false quarrel there is no true valor.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "smash", "somebody's", "skull", "enjoy"],
    words: ["when", "you", "see", "me", "smash", "somebody's", "skull", "enjoy", "it"],
    id: "When you see me smash somebody's skull, you enjoy it.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["pride", "wish", "owe", "vanity", "pay"],
    words: ["pride", "does", "not", "wish", "to", "owe", "and", "vanity", "pay"],
    id: "Pride does not wish to owe and vanity does not wish to pay.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["universe", "wider", "than", "our", "views"],
    words: ["the", "universe", "is", "wider", "than", "our", "views", "of", "it"],
    id: "The universe is wider than our views of it.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["better", "rust", "out", "than", "wear"],
    words: ["it", "is", "better", "to", "rust", "out", "than", "wear"],
    id: "It is better to rust out than wear out.",
    author: "Edwin Markham",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["cisterns", "made", "hoarding", "channels", "sharing"],
    words: ["we", "are", "not", "cisterns", "made", "for", "hoarding", "channels", "sharing"],
    id: "We are not cisterns made for hoarding, we are channels made for sharing.",
    author: "Billy Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "hard", "after", "all", "kills"],
    words: ["life", "is", "hard", "after", "all", "it", "kills", "you"],
    id: "Life is hard. After all, it kills you.",
    author: "Katharine Hepburn",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["cannot", "make", "revolution", "silk", "gloves"],
    words: ["you", "cannot", "make", "a", "revolution", "with", "silk", "gloves"],
    id: "You cannot make a revolution with silk gloves.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["man's", "mind", "create", "character", "control"],
    words: ["what", "a", "man's", "mind", "can", "create", "character", "control"],
    id: "What a man's mind can create, man's character can control.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["staying", "healthy", "true", "wealth", "happiness"],
    words: ["just", "staying", "healthy", "that", "is", "true", "wealth", "and", "happiness"],
    id: "Just staying healthy, that is true wealth and true happiness.",
    author: "Jon Jones",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["loaf", "bread", "jug", "wine", "thou"],
    words: ["a", "loaf", "of", "bread", "jug", "wine", "and", "thou"],
    id: "A loaf of bread, a jug of wine, and thou.",
    author: "Omar Khayyam",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["inhabit", "universe", "characterized", "by", "diversity"],
    words: ["we", "inhabit", "a", "universe", "that", "is", "characterized", "by", "diversity"],
    id: "We inhabit a universe that is characterized by diversity.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "i'm", "cool", "all", "matters"],
    words: ["i", "think", "i'm", "cool", "that's", "all", "that", "matters"],
    id: "I think I'm cool. That's all that matters.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["little", "more", "careful", "than", "anything"],
    words: ["be", "of", "love", "a", "little", "more", "careful", "than", "anything"],
    id: "Be of love a little more careful than of anything.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["devil", "cite", "scripture", "his", "purpose"],
    words: ["the", "devil", "can", "cite", "scripture", "for", "his", "purpose"],
    id: "The devil can cite Scripture for his purpose.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "learned", "value", "hard", "by"],
    words: ["i", "learned", "the", "value", "of", "hard", "work", "by", "working"],
    id: "I learned the value of hard work by working hard.",
    author: "Margaret Mead",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["worst", "madmen", "saint", "run", "mad"],
    words: ["the", "worst", "of", "madmen", "is", "a", "saint", "run", "mad"],
    id: "The worst of madmen is a saint run mad.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "make", "film", "usually", "idea"],
    words: ["when", "you", "make", "a", "film", "usually", "about", "an", "idea"],
    id: "When you make a film you usually make a film about an idea.",
    author: "Sydney Pollack",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["half", "lies", "tell", "aren't", "true"],
    words: ["half", "the", "lies", "they", "tell", "about", "me", "aren't", "true"],
    id: "Half the lies they tell about me aren't true.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["lawless", "make", "their", "wills", "law"],
    words: ["lawless", "are", "they", "that", "make", "their", "wills", "law"],
    id: "Lawless are they that make their wills their law.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "desire", "forgiving", "non-judgmental", "person"],
    words: ["my", "desire", "is", "to", "be", "a", "forgiving", "non-judgmental", "person"],
    id: "My desire is to be a forgiving, non-judgmental person.",
    author: "Janine Turner",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "made", "judgments", "past", "future"],
    words: ["i", "have", "made", "good", "judgments", "in", "the", "past", "future"],
    id: "I have made good judgments in the past. I have made good judgments in the future.",
    author: "Dan Quayle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "belong", "organized", "party", "democrat"],
    words: ["i", "belong", "to", "no", "organized", "party", "am", "a", "democrat"],
    id: "I belong to no organized party. I am a Democrat.",
    author: "Will Rogers",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "hard", "master", "particularly", "people's"],
    words: ["happiness", "is", "a", "hard", "master", "particularly", "other", "people's"],
    id: "Happiness is a hard master, particularly other people's happiness.",
    author: "Aldous Huxley",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["adopt", "pace", "nature", "secret", "patience"],
    words: ["adopt", "the", "pace", "of", "nature", "her", "secret", "is", "patience"],
    id: "Adopt the pace of nature: her secret is patience.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "something", "dies", "unless", "used"],
    words: ["freedom", "is", "something", "that", "dies", "unless", "it's", "used"],
    id: "Freedom is something that dies unless it's used.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "hour", "run", "through", "roughest"],
    words: ["time", "and", "the", "hour", "run", "through", "roughest", "day"],
    id: "Time and the hour run through the roughest day.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "accept", "reality", "dare", "question"],
    words: ["i", "accept", "reality", "and", "dare", "not", "question", "it"],
    id: "I accept reality and dare not question it.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["ballots", "rightful", "peaceful", "successors", "bullets"],
    words: ["ballots", "are", "the", "rightful", "and", "peaceful", "successors", "to", "bullets"],
    id: "Ballots are the rightful and peaceful successors to bullets.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["wealth", "ability", "fully", "experience", "life"],
    words: ["wealth", "is", "the", "ability", "to", "fully", "experience", "life"],
    id: "Wealth is the ability to fully experience life.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["doing", "collections", "fashion", "non-stop", "dialogue"],
    words: ["doing", "collections", "fashion", "is", "like", "a", "non-stop", "dialogue"],
    id: "Doing collections, doing fashion is like a non-stop dialogue.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["lennox", "lewis", "hater", "tyson", "fury"],
    words: ["lennox", "lewis", "is", "a", "hater", "of", "tyson", "fury"],
    id: "Lennox Lewis is a hater of Tyson Fury.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["movies", "written", "sand", "applauded", "forgotten"],
    words: [
      "movies",
      "are",
      "written",
      "in",
      "sand",
      "applauded",
      "today",
      "forgotten",
      "tomorrow",
    ],
    id: "Movies are written in sand: applauded today, forgotten tomorrow.",
    author: "D. W. Griffith",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["freeman", "shall", "debarred", "use", "arms"],
    words: ["no", "freeman", "shall", "be", "debarred", "the", "use", "of", "arms"],
    id: "No freeman shall be debarred the use of arms.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "right", "live", "as", "wish"],
    words: ["freedom", "is", "the", "right", "to", "live", "as", "we", "wish"],
    id: "Freedom is the right to live as we wish.",
    author: "Epictetus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["foundation", "state", "education", "its", "youth"],
    words: ["the", "foundation", "of", "every", "state", "is", "education", "its", "youth"],
    id: "The foundation of every state is the education of its youth.",
    author: "Diogenes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["valiant", "never", "taste", "death", "once"],
    words: ["the", "valiant", "never", "taste", "of", "death", "but", "once"],
    id: "The valiant never taste of death but once.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["making", "living", "out", "being", "bored"],
    words: ["work", "is", "making", "a", "living", "out", "of", "being", "bored"],
    id: "Work is making a living out of being bored.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "would", "tragic", "weren't", "funny"],
    words: ["life", "would", "be", "tragic", "if", "it", "weren't", "funny"],
    id: "Life would be tragic if it weren't funny.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["celebrity", "chastisement", "merit", "punishment", "talent"],
    words: ["celebrity", "is", "the", "chastisement", "of", "merit", "and", "punishment", "talent"],
    id: "Celebrity is the chastisement of merit and the punishment of talent.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["henceforth", "i", "ask", "fortune", "myself"],
    words: ["henceforth", "i", "ask", "not", "good", "fortune", "myself", "am"],
    id: "Henceforth I ask not good fortune. I myself am good fortune.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["time's", "subjects", "time", "bids", "gone"],
    words: ["we", "are", "time's", "subjects", "and", "time", "bids", "be", "gone"],
    id: "We are time's subjects, and time bids be gone.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "trust", "few", "wrong", "none"],
    words: ["love", "all", "trust", "a", "few", "do", "wrong", "to", "none"],
    id: "Love all, trust a few, do wrong to none.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["enemy", "generally", "says", "believes", "wishes"],
    words: ["an", "enemy", "generally", "says", "and", "believes", "what", "he", "wishes"],
    id: "An enemy generally says and believes what he wishes.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["meaning", "peace", "absence", "opposition", "socialism"],
    words: ["the", "meaning", "of", "peace", "is", "absence", "opposition", "to", "socialism"],
    id: "The meaning of peace is the absence of opposition to socialism.",
    author: "Karl Marx",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["physician", "treats", "disease", "great", "patient"],
    words: ["the", "good", "physician", "treats", "disease", "great", "patient", "who", "has"],
    id: "The good physician treats the disease; the great physician treats the patient who has the disease.",
    author: "William Osler",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["future", "more", "uncertain", "than", "present"],
    words: ["the", "future", "is", "no", "more", "uncertain", "than", "present"],
    id: "The future is no more uncertain than the present.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["carry", "within", "wonders", "seek", "without"],
    words: ["we", "carry", "within", "us", "the", "wonders", "seek", "without"],
    id: "We carry within us the wonders we seek without us.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "getting", "happiness", "wanting", "get"],
    words: ["success", "is", "getting", "what", "you", "want", "happiness", "wanting", "get"],
    id: "Success is getting what you want. Happiness is wanting what you get.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["enjoyment", "incredible", "energizer", "human", "spirit"],
    words: ["enjoyment", "is", "an", "incredible", "energizer", "to", "the", "human", "spirit"],
    id: "Enjoyment is an incredible energizer to the human spirit.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "opponent", "choleric", "temper", "irritate"],
    words: ["if", "your", "opponent", "is", "of", "choleric", "temper", "irritate", "him"],
    id: "If your opponent is of choleric temper, irritate him.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "health", "family", "core", "being"],
    words: ["my", "health", "and", "family", "are", "the", "core", "of", "being"],
    id: "My health and my family are the core of my being.",
    author: "Jon Bon Jovi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "retain", "lot", "microsoft's", "stock"],
    words: ["i'm", "going", "to", "retain", "a", "lot", "of", "microsoft's", "stock"],
    id: "I'm going to retain a lot of Microsoft's stock.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["forth", "conqueror", "win", "great", "victories"],
    words: ["go", "forth", "a", "conqueror", "and", "win", "great", "victories"],
    id: "Go forth a conqueror and win great victories.",
    author: "Virgil",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["history", "lies", "all", "secrets", "statecraft"],
    words: ["study", "history", "in", "lies", "all", "the", "secrets", "of", "statecraft"],
    id: "Study history, study history. In history lies all the secrets of statecraft.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["artist", "home", "europe", "except", "paris"],
    words: ["an", "artist", "has", "no", "home", "in", "europe", "except", "paris"],
    id: "An artist has no home in Europe except in Paris.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["failure", "exist", "only", "change", "direction"],
    words: ["failure", "doesn't", "exist", "it's", "only", "a", "change", "of", "direction"],
    id: "Failure doesn't exist. It's only a change of direction.",
    author: "Alejandro Jodorowsky",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["game", "which", "one", "always", "cheats"],
    words: ["love", "is", "a", "game", "in", "which", "one", "always", "cheats"],
    id: "Love is a game in which one always cheats.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "democracy", "dreams", "never", "up"],
    words: ["freedom", "and", "democracy", "are", "dreams", "you", "never", "give", "up"],
    id: "Freedom and democracy are dreams you never give up.",
    author: "Aung San Suu Kyi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["communism", "one-third", "practice", "two-thirds", "explanation"],
    words: [
      "communism",
      "to",
      "me",
      "is",
      "one-third",
      "practice",
      "and",
      "two-thirds",
      "explanation",
    ],
    id: "Communism to me is one-third practice and two-thirds explanation.",
    author: "Will Rogers",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "intend", "live", "forever", "far"],
    words: ["i", "intend", "to", "live", "forever", "so", "far", "good"],
    id: "I intend to live forever. So far, so good.",
    author: "Steven Wright",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["any", "time", "spent", "on", "wasted"],
    words: ["any", "time", "not", "spent", "on", "love", "is", "wasted"],
    id: "Any time not spent on love is wasted.",
    author: "Torquato Tasso",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["stars", "above", "govern", "our", "conditions"],
    words: ["it", "is", "the", "stars", "above", "us", "govern", "our", "conditions"],
    id: "It is the stars, The stars above us, govern our conditions.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["loving", "live", "world", "hostile", "same"],
    words: ["loving", "people", "live", "in", "a", "world", "hostile", "same"],
    id: "Loving people live in a loving world. Hostile people live in a hostile world. Same world.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["kids", "big", "part", "my", "schedule"],
    words: ["the", "kids", "are", "a", "big", "part", "of", "my", "schedule"],
    id: "The kids are a big part of my schedule.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "action", "only", "inaction"],
    words: ["i", "never", "worry", "about", "action", "but", "only", "inaction"],
    id: "I never worry about action, but only inaction.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["squirrel", "kill", "jest", "dies", "earnest"],
    words: ["the", "squirrel", "that", "you", "kill", "in", "jest", "dies", "earnest"],
    id: "The squirrel that you kill in jest, dies in earnest.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["ever", "loved", "at", "first", "sight"],
    words: ["who", "ever", "loved", "that", "not", "at", "first", "sight"],
    id: "Who ever loved that loved not at first sight?",
    author: "Christopher Marlowe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["words", "powerful", "change", "your", "life"],
    words: ["words", "are", "powerful", "if", "you", "change", "your", "can", "life"],
    id: "Words are powerful; if you change your words, you can change your life.",
    author: "Joyce Meyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "mind", "hating", "because", "pushes"],
    words: ["i", "don't", "mind", "people", "hating", "me", "because", "it", "pushes"],
    id: "I don't mind people hating me, because it pushes me.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["read", "bible", "hard", "honestly", "complain"],
    words: ["read", "the", "bible", "work", "hard", "and", "honestly", "don't", "complain"],
    id: "Read the Bible. Work hard and honestly. And don't complain.",
    author: "Billy Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "spend", "your", "before", "earned"],
    words: ["never", "spend", "your", "money", "before", "you", "have", "earned", "it"],
    id: "Never spend your money before you have earned it.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "wisdom", "save", "collection", "platitudes"],
    words: ["what", "is", "all", "wisdom", "save", "a", "collection", "of", "platitudes"],
    id: "What is all wisdom save a collection of platitudes?",
    author: "Norman Douglas",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["health", "insurance", "should", "given", "citizen"],
    words: ["health", "insurance", "should", "be", "a", "given", "for", "every", "citizen"],
    id: "Health insurance should be a given for every citizen.",
    author: "Jesse Ventura",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "way", "better", "-", "find"],
    words: ["there's", "a", "way", "to", "do", "it", "better", "-", "find"],
    id: "There's a way to do it better - find it.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["endure", "present", "watch", "better", "things"],
    words: ["endure", "the", "present", "and", "watch", "for", "better", "things"],
    id: "Endure the present, and watch for better things.",
    author: "Virgil",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "mr", "gorbachev", "business", "together"],
    words: ["i", "like", "mr", "gorbachev", "we", "can", "do", "business", "together"],
    id: "I like Mr. Gorbachev, we can do business together.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "credit", "highest", "when", "used"],
    words: ["friendship", "like", "credit", "is", "highest", "when", "it", "not", "used"],
    id: "Friendship, like credit, is highest when it is not used.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["show", "hero", "i'll", "write", "tragedy"],
    words: ["show", "me", "a", "hero", "and", "i'll", "write", "you", "tragedy"],
    id: "Show me a hero and I'll write you a tragedy.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["true", "knowledge", "exists", "knowing", "nothing"],
    words: ["true", "knowledge", "exists", "in", "knowing", "that", "you", "know", "nothing"],
    id: "True knowledge exists in knowing that you know nothing.",
    author: "Socrates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["everyone", "movies", "when", "little", "kid"],
    words: ["well", "everyone", "likes", "movies", "when", "they're", "a", "little", "kid"],
    id: "Well, everyone likes movies when they're a little kid.",
    author: "Anthony Hopkins",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["friends", "siblings", "god", "never", "gave"],
    words: ["friends", "are", "the", "siblings", "god", "never", "gave", "us"],
    id: "Friends are the siblings God never gave us.",
    author: "Mencius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["free", "speech", "carries", "freedom", "listen"],
    words: ["free", "speech", "carries", "with", "it", "some", "freedom", "to", "listen"],
    id: "Free speech carries with it some freedom to listen.",
    author: "Warren E. Burger",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "humility", "more", "success", "lasting"],
    words: ["where", "there", "is", "humility", "more", "success", "and", "lasting"],
    id: "Where there is humility, there is more success, and lasting success.",
    author: "Patrick Lencioni",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["head", "heart", "always", "formidable", "combination"],
    words: ["a", "good", "head", "and", "heart", "are", "always", "formidable", "combination"],
    id: "A good head and a good heart are always a formidable combination.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["nature", "machine", "family", "life", "cycle"],
    words: ["nature", "is", "a", "machine", "the", "family", "life", "cycle", "like"],
    id: "Nature is a machine. The family is a machine. The life cycle is like a machine.",
    author: "Ray Dalio",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "inspiration", "woman", "friendship", "loneliness"],
    words: ["my", "inspiration", "are", "the", "woman", "friendship", "and", "loneliness"],
    id: "My inspiration are the woman, friendship, and loneliness.",
    author: "Enrique Iglesias",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "made", "up", "marble", "mud"],
    words: ["life", "is", "made", "up", "of", "marble", "and", "mud"],
    id: "Life is made up of marble and mud.",
    author: "Nathaniel Hawthorne",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["america", "really", "mythologise", "when", "die"],
    words: ["in", "america", "they", "really", "do", "mythologise", "people", "when", "die"],
    id: "In America they really do mythologise people when they die.",
    author: "Robin Williams",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["spread", "freedom", "best", "security", "free"],
    words: ["the", "spread", "of", "freedom", "is", "best", "security", "for", "free"],
    id: "The spread of freedom is the best security for the free.",
    author: "Tony Blair",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "live", "god's", "peculiar", "light"],
    words: ["i", "live", "and", "love", "in", "god's", "peculiar", "light"],
    id: "I live and love in God's peculiar light.",
    author: "Michelangelo",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["disciplined", "effort", "there", "multiple", "reward"],
    words: ["for", "every", "disciplined", "effort", "there", "is", "a", "multiple", "reward"],
    id: "For every disciplined effort there is a multiple reward.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "i", "angry", "pray", "preach"],
    words: ["when", "i", "am", "angry", "can", "pray", "well", "and", "preach"],
    id: "When I am angry I can pray well and preach well.",
    author: "Martin Luther",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["any", "friendship", "or", "relationship", "language"],
    words: ["any", "friendship", "or", "relationship", "is", "about", "a", "language"],
    id: "Any friendship or relationship is about a language.",
    author: "Gina Bellman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "ever", "lacks", "reason", "suicide"],
    words: ["no", "one", "ever", "lacks", "a", "good", "reason", "for", "suicide"],
    id: "No one ever lacks a good reason for suicide.",
    author: "Cesare Pavese",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "one", "long", "struggle", "dark"],
    words: ["life", "is", "one", "long", "struggle", "in", "the", "dark"],
    id: "Life is one long struggle in the dark.",
    author: "Lucretius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "getting", "happiness", "liking", "get"],
    words: ["success", "is", "getting", "what", "you", "want", "happiness", "liking", "get"],
    id: "Success is getting what you want. Happiness is liking what you get.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["young", "man", "rules", "old", "exceptions"],
    words: ["the", "young", "man", "knows", "rules", "but", "old", "exceptions"],
    id: "The young man knows the rules, but the old man knows the exceptions.",
    author: "Oliver Wendell Holmes, Sr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "inevitable", "better", "full-time", "job"],
    words: ["change", "is", "inevitable", "for", "the", "better", "a", "full-time", "job"],
    id: "Change is inevitable. Change for the better is a full-time job.",
    author: "Adlai Stevenson I",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowledge", "only", "one", "half", "faith"],
    words: ["knowledge", "is", "only", "one", "half", "faith", "the", "other"],
    id: "Knowledge is only one half. Faith is the other.",
    author: "Novalis",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "man", "always", "willing", "little"],
    words: ["a", "great", "man", "is", "always", "willing", "to", "be", "little"],
    id: "A great man is always willing to be little.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["rarer", "than", "genius", "itself", "friendship"],
    words: ["love", "is", "rarer", "than", "genius", "itself", "and", "friendship"],
    id: "Love is rarer than genius itself. And friendship is rarer than love.",
    author: "Charles Peguy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["purity", "heart", "will", "one", "thing"],
    words: ["purity", "of", "heart", "is", "to", "will", "one", "thing"],
    id: "Purity of heart is to will one thing.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["better", "imitate", "ancient", "than", "modern"],
    words: ["it", "is", "better", "to", "imitate", "ancient", "than", "modern", "work"],
    id: "It is better to imitate ancient than modern work.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["create", "god", "command", "king", "slave"],
    words: ["create", "like", "a", "god", "command", "king", "work", "slave"],
    id: "Create like a god, command like a king, work like a slave.",
    author: "Constantin Brancusi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "either", "god", "or", "wolf"],
    words: ["man", "is", "to", "either", "a", "god", "or", "wolf"],
    id: "Man is to man either a god or a wolf.",
    author: "Desiderius Erasmus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["person", "lives", "twice", "first", "life"],
    words: ["the", "person", "lives", "twice", "who", "first", "life", "well"],
    id: "The person lives twice who lives the first life well.",
    author: "Robert Herrick",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "friends", "keep", "enemies", "win"],
    words: ["do", "good", "to", "your", "friends", "keep", "them", "enemies", "win"],
    id: "Do good to your friends to keep them, to your enemies to win them.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i've", "learned", "friendship", "equate", "business"],
    words: ["i've", "learned", "that", "friendship", "does", "not", "equate", "business"],
    id: "I've learned that friendship does not equate business, business does not equate friendship.",
    author: "Jill Scott",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "usually", "culmination", "controlling", "failure"],
    words: ["success", "is", "usually", "the", "culmination", "of", "controlling", "failure"],
    id: "Success is usually the culmination of controlling failure.",
    author: "Sylvester Stallone",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["hell", "empty", "all", "devils", "here"],
    words: ["hell", "is", "empty", "and", "all", "the", "devils", "are", "here"],
    id: "Hell is empty and all the devils are here.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "begins", "on", "side", "despair"],
    words: ["life", "begins", "on", "the", "other", "side", "of", "despair"],
    id: "Life begins on the other side of despair.",
    author: "Jean-Paul Sartre",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["egg", "better", "than", "hen", "to-morrow"],
    words: ["an", "egg", "today", "is", "better", "than", "a", "hen", "to-morrow"],
    id: "An egg today is better than a hen to-morrow.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["individualization", "fundamentally", "redefines", "role", "assessment"],
    words: [
      "the",
      "individualization",
      "of",
      "learning",
      "fundamentally",
      "redefines",
      "role",
      "assessment",
    ],
    id: "The individualization of learning fundamentally redefines the role of assessment.",
    author: "Sebastian Thrun",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "protection", "any", "woman", "courage"],
    words: ["the", "best", "protection", "any", "woman", "can", "have", "is", "courage"],
    id: "The best protection any woman can have... is courage.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "too", "young", "old", "change"],
    words: ["you", "are", "never", "too", "young", "to", "learn", "old", "change"],
    id: "You are never too young to learn, never too old to change.",
    author: "Russell M. Nelson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["blessed", "meek", "will", "inherit", "earth"],
    words: ["blessed", "are", "the", "meek", "for", "they", "will", "inherit", "earth"],
    id: "Blessed are the meek, for they will inherit the earth.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["feature", "films", "director", "god", "documentary"],
    words: ["in", "feature", "films", "the", "director", "is", "god", "documentary"],
    id: "In feature films the director is God; in documentary films God is the director.",
    author: "Alfred Hitchcock",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["by", "age", "capacity", "wisdom", "acquired"],
    words: ["not", "by", "age", "but", "capacity", "is", "wisdom", "acquired"],
    id: "Not by age but by capacity is wisdom acquired.",
    author: "Plautus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["sole", "meaning", "life", "serve", "humanity"],
    words: ["the", "sole", "meaning", "of", "life", "is", "to", "serve", "humanity"],
    id: "The sole meaning of life is to serve humanity.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "easier", "made", "than", "kept"],
    words: ["friendship", "is", "like", "money", "easier", "made", "than", "kept"],
    id: "Friendship is like money, easier made than kept.",
    author: "Samuel Butler",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "something", "great", "terrible", "suicide"],
    words: ["there", "is", "something", "great", "and", "terrible", "about", "suicide"],
    id: "There is something great and terrible about suicide.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["wisely", "slow", "stumble", "run", "fast"],
    words: ["wisely", "and", "slow", "they", "stumble", "that", "run", "fast"],
    id: "Wisely, and slow. They stumble that run fast.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["unless", "bad", "times", "can't", "appreciate"],
    words: ["unless", "you", "have", "bad", "times", "can't", "appreciate", "the", "good"],
    id: "Unless you have bad times, you can't appreciate the good times.",
    author: "Joe Torre",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["beyond", "as", "wrong", "fall", "short"],
    words: ["to", "go", "beyond", "is", "as", "wrong", "fall", "short"],
    id: "To go beyond is as wrong as to fall short.",
    author: "Confucius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["opportunity", "those", "already", "on", "floor"],
    words: ["opportunity", "dances", "with", "those", "already", "on", "the", "dance", "floor"],
    id: "Opportunity dances with those already on the dance floor.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["slowness", "change", "usually", "means", "fear"],
    words: ["slowness", "to", "change", "usually", "means", "fear", "of", "the", "new"],
    id: "Slowness to change usually means fear of the new.",
    author: "Phil Crosby",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "advance", "worrying", "become", "planning"],
    words: ["let", "our", "advance", "worrying", "become", "thinking", "and", "planning"],
    id: "Let our advance worrying become advance thinking and planning.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["divine", "enough", "ask", "important", "receive"],
    words: ["we", "are", "divine", "enough", "to", "ask", "and", "important", "receive"],
    id: "We are Divine enough to ask and we are important enough to receive.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["nation", "ridicules", "nations", "all", "right"],
    words: ["every", "nation", "ridicules", "other", "nations", "and", "all", "are", "right"],
    id: "Every nation ridicules other nations, and all are right.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "our", "country", "ought", "lovely"],
    words: ["to", "make", "us", "love", "our", "country", "ought", "be", "lovely"],
    id: "To make us love our country, our country ought to be lovely.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "merely", "necessary", "life", "-"],
    words: ["change", "is", "not", "merely", "necessary", "to", "life", "-", "it"],
    id: "Change is not merely necessary to life - it is life.",
    author: "Alvin Toffler",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["hard", "distractions", "plentiful", "time", "short"],
    words: ["work", "is", "hard", "distractions", "are", "plentiful", "and", "time", "short"],
    id: "Work is hard. Distractions are plentiful. And time is short.",
    author: "Adam Hochschild",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["sometimes", "heart", "sees", "invisible", "eye"],
    words: ["sometimes", "the", "heart", "sees", "what", "is", "invisible", "to", "eye"],
    id: "Sometimes the heart sees what is invisible to the eye.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "heart", "i", "always", "raider"],
    words: ["in", "my", "heart", "i", "am", "always", "a", "raider"],
    id: "In my heart, I am always a Raider.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["leader", "get", "stuck", "behind", "desk"],
    words: ["a", "good", "leader", "doesn't", "get", "stuck", "behind", "desk"],
    id: "A good leader doesn't get stuck behind a desk.",
    author: "Richard Branson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "forever", "failure", "isn't", "fatal"],
    words: ["success", "is", "not", "forever", "and", "failure", "isn't", "fatal"],
    id: "Success is not forever and failure isn't fatal.",
    author: "Don Shula",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["gulf", "lesson", "one", "value", "airpower"],
    words: ["gulf", "lesson", "one", "is", "the", "value", "of", "airpower"],
    id: "Gulf Lesson One is the value of airpower.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["certainly", "windows", "share", "servers", "strong"],
    words: ["certainly", "the", "windows", "share", "of", "servers", "is", "strong"],
    id: "Certainly, the Windows share of servers is strong.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "birthday", "generally", "quiet", "affair"],
    words: ["my", "birthday", "is", "generally", "a", "very", "quiet", "affair"],
    id: "My birthday is generally a very quiet affair.",
    author: "Jackie Shroff",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["passion", "drives", "reason", "hold", "reins"],
    words: ["if", "passion", "drives", "you", "let", "reason", "hold", "the", "reins"],
    id: "If passion drives you, let reason hold the reins.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["feeling", "then", "nothing", "else", "matters"],
    words: ["if", "you're", "happy", "feeling", "good", "then", "nothing", "else", "matters"],
    id: "If you're happy, if you're feeling good, then nothing else matters.",
    author: "Robin Wright",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["always", "everything", "ask", "those", "command"],
    words: ["always", "do", "everything", "you", "ask", "of", "those", "command"],
    id: "Always do everything you ask of those you command.",
    author: "George S. Patton",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "most", "unselfish", "chef", "britain"],
    words: ["i", "am", "the", "most", "unselfish", "chef", "in", "britain", "today"],
    id: "I am the most unselfish chef in Britain today.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["addition", "true", "knowledge", "human", "power"],
    words: ["every", "addition", "to", "true", "knowledge", "is", "an", "human", "power"],
    id: "Every addition to true knowledge is an addition to human power.",
    author: "Horace Mann",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["america", "spirit", "human", "exploration", "distilled"],
    words: ["america", "is", "the", "spirit", "of", "human", "exploration", "distilled"],
    id: "America is the spirit of human exploration distilled.",
    author: "Elon Musk",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "interval", "between", "periods", "unhappiness"],
    words: ["happiness", "is", "the", "interval", "between", "periods", "of", "unhappiness"],
    id: "Happiness is the interval between periods of unhappiness.",
    author: "Don Marquis",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["immature", "says", "i", "because", "mature"],
    words: ["immature", "love", "says", "i", "you", "because", "need", "mature"],
    id: "Immature love says: 'I love you because I need you.' Mature love says 'I need you because I love you.'",
    author: "Erich Fromm",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "way", "look", "at", "things"],
    words: ["if", "you", "change", "the", "way", "look", "at", "things"],
    id: "If you change the way you look at things, the things you look at change.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["crime", "great", "as", "daring", "excel"],
    words: ["no", "crime", "is", "so", "great", "as", "daring", "to", "excel"],
    id: "No crime is so great as daring to excel.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["patience", "art", "concealing", "your", "impatience"],
    words: ["patience", "is", "the", "art", "of", "concealing", "your", "impatience"],
    id: "Patience is the art of concealing your impatience.",
    author: "Guy Kawasaki",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "bought", "batteries", "weren't", "included"],
    words: ["i", "bought", "some", "batteries", "but", "they", "weren't", "included"],
    id: "I bought some batteries, but they weren't included.",
    author: "Steven Wright",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["honesty", "first", "chapter", "book", "wisdom"],
    words: ["honesty", "is", "the", "first", "chapter", "in", "book", "of", "wisdom"],
    id: "Honesty is the first chapter in the book of wisdom.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "prefers", "belief", "exercise", "judgment"],
    words: ["every", "man", "prefers", "belief", "to", "the", "exercise", "of", "judgment"],
    id: "Every man prefers belief to the exercise of judgment.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["class", "more", "than", "also", "knowledge"],
    words: ["class", "is", "more", "than", "money", "also", "about", "knowledge"],
    id: "Class is more than money. Class is also about knowledge.",
    author: "bell hooks",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "comfort", "afflicted", "afflicting", "comfortable"],
    words: ["you", "can't", "comfort", "the", "afflicted", "with", "afflicting", "comfortable"],
    id: "You can't comfort the afflicted with afflicting the comfortable.",
    author: "Princess Diana",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["thermometer", "success", "merely", "jealousy", "malcontents"],
    words: ["the", "thermometer", "of", "success", "is", "merely", "jealousy", "malcontents"],
    id: "The thermometer of success is merely the jealousy of the malcontents.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["may", "live", "as", "long", "wish"],
    words: ["may", "you", "live", "as", "long", "wish", "and", "love"],
    id: "May you live as long as you wish and love as long as you live.",
    author: "Robert A. Heinlein",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["better", "times", "perhaps", "await", "wretched"],
    words: ["better", "times", "perhaps", "await", "us", "who", "are", "now", "wretched"],
    id: "Better times perhaps await us who are now wretched.",
    author: "Virgil",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["vulnerability", "weakness", "myth", "profoundly", "dangerous"],
    words: [
      "vulnerability",
      "is",
      "not",
      "weakness",
      "and",
      "that",
      "myth",
      "profoundly",
      "dangerous",
    ],
    id: "Vulnerability is not weakness. And that myth is profoundly dangerous.",
    author: "Brene Brown",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "there", "different", "levels", "forgiveness"],
    words: ["i", "think", "there", "are", "different", "levels", "of", "forgiveness"],
    id: "I think there are different levels of forgiveness.",
    author: "Catherine Oxenberg",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "keep", "exploring", "doing", "more"],
    words: ["i", "want", "to", "keep", "learning", "exploring", "doing", "more"],
    id: "I want to keep learning, keep exploring, keep doing more.",
    author: "Jessye Norman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["hard", "separate", "art", "from", "artist"],
    words: ["it", "is", "hard", "to", "separate", "the", "art", "from", "artist"],
    id: "It is hard to separate the art from the artist.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["slavery", "weed", "grows", "on", "soil"],
    words: ["slavery", "is", "a", "weed", "that", "grows", "on", "every", "soil"],
    id: "Slavery is a weed that grows on every soil.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["war", "cruelty", "none", "make", "gentle"],
    words: ["war", "is", "cruelty", "and", "none", "can", "make", "it", "gentle"],
    id: "War is cruelty, and none can make it gentle.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "prone", "doubt", "cautious", "wise"],
    words: ["how", "prone", "to", "doubt", "cautious", "are", "the", "wise"],
    id: "How prone to doubt, how cautious are the wise!",
    author: "Alexander Pope",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["health", "wellness", "mean", "different", "things"],
    words: ["health", "and", "wellness", "does", "mean", "different", "things", "to", "people"],
    id: "Health and wellness does mean different things to different people.",
    author: "Denise Morrison",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["forgive", "son", "men", "must", "err"],
    words: ["forgive", "son", "men", "are", "they", "needs", "must", "err"],
    id: "Forgive, son; men are men; they needs must err.",
    author: "Euripides",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["universe", "take", "quite", "while", "deliver"],
    words: ["the", "universe", "can", "take", "quite", "a", "while", "to", "deliver"],
    id: "The universe can take quite a while to deliver.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["was", "wise", "man", "invented", "beer"],
    words: ["he", "was", "a", "wise", "man", "who", "invented", "beer"],
    id: "He was a wise man who invented beer.",
    author: "Unknown",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["confuse", "ego", "lust", "insecurity", "true"],
    words: ["people", "confuse", "ego", "lust", "insecurity", "with", "true", "love"],
    id: "People confuse ego, lust, insecurity with true love.",
    author: "Simon Cowell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["everyone", "alike", "then", "somebody", "isn't"],
    words: ["if", "everyone", "is", "thinking", "alike", "then", "somebody", "isn't"],
    id: "If everyone is thinking alike, then somebody isn't thinking.",
    author: "George S. Patton",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["world", "will", "end", "fire", "ice"],
    words: ["some", "say", "the", "world", "will", "end", "in", "fire", "ice"],
    id: "Some say the world will end in fire, some say in ice.",
    author: "Robert Frost",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "defeat", "inevitable", "wisest", "yield"],
    words: ["when", "defeat", "is", "inevitable", "it", "wisest", "to", "yield"],
    id: "When defeat is inevitable, it is wisest to yield.",
    author: "Quintilian",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["prayer", "key", "morning", "bolt", "evening"],
    words: ["prayer", "is", "the", "key", "of", "morning", "and", "bolt", "evening"],
    id: "Prayer is the key of the morning and the bolt of the evening.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "greatness", "simplicity", "goodness", "truth"],
    words: ["there", "is", "no", "greatness", "where", "simplicity", "goodness", "and", "truth"],
    id: "There is no greatness where there is no simplicity, goodness and truth.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "write", "fairly", "self-confident", "manner"],
    words: ["i", "think", "write", "in", "a", "fairly", "self-confident", "manner"],
    id: "I think I write in a fairly self-confident manner.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["win", "victory", "when", "yield", "friends"],
    words: ["you", "win", "the", "victory", "when", "yield", "to", "friends"],
    id: "You win the victory when you yield to friends.",
    author: "Sophocles",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["health", "vital", "principle", "bliss", "exercise"],
    words: ["health", "is", "the", "vital", "principle", "of", "bliss", "and", "exercise"],
    id: "Health is the vital principle of bliss, and exercise, of health.",
    author: "James Thomson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "rain", "my", "favorite", "weather"],
    words: ["i", "love", "the", "rain", "it's", "my", "favorite", "weather"],
    id: "I love the rain. It's my favorite weather.",
    author: "Kristen Wiig",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["lack", "pep", "often", "mistaken", "patience"],
    words: ["lack", "of", "pep", "is", "often", "mistaken", "for", "patience"],
    id: "Lack of pep is often mistaken for patience.",
    author: "Kin Hubbard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "revenge", "complete", "as", "forgiveness"],
    words: ["there", "is", "no", "revenge", "so", "complete", "as", "forgiveness"],
    id: "There is no revenge so complete as forgiveness.",
    author: "Josh Billings",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["cannot", "anything", "away", "feel", "either"],
    words: ["he", "who", "cannot", "give", "anything", "away", "feel", "either"],
    id: "He who cannot give anything away cannot feel anything either.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["character", "develops", "itself", "stream", "life"],
    words: ["character", "develops", "itself", "in", "the", "stream", "of", "life"],
    id: "Character develops itself in the stream of life.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["perfecting", "oneself", "as", "much", "unlearning"],
    words: ["perfecting", "oneself", "is", "as", "much", "unlearning", "it", "learning"],
    id: "Perfecting oneself is as much unlearning as it is learning.",
    author: "Edsger Dijkstra",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["joke", "our", "time", "suicide", "intention"],
    words: ["the", "joke", "of", "our", "time", "is", "suicide", "intention"],
    id: "The joke of our time is the suicide of intention.",
    author: "Theodor W. Adorno",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "enemy", "progress", "illusion", "knowledge"],
    words: ["the", "greatest", "enemy", "of", "progress", "is", "illusion", "knowledge"],
    id: "The greatest enemy of progress is the illusion of knowledge.",
    author: "John Young",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "-", "being", "search", "meaning"],
    words: ["man", "-", "a", "being", "in", "search", "of", "meaning"],
    id: "Man - a being in search of meaning.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "as", "your", "last", "show"],
    words: ["every", "day", "you're", "only", "as", "good", "your", "last", "show"],
    id: "Every day, you're only as good as your last show.",
    author: "Oprah Winfrey",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "raise", "by", "vile", "means"],
    words: ["for", "i", "can", "raise", "no", "money", "by", "vile", "means"],
    id: "For I can raise no money by vile means.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "must", "never", "write", "when"],
    words: ["i", "must", "never", "write", "when", "do", "not", "want", "to"],
    id: "I must never write when I do not want to write.",
    author: "Langston Hughes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "could", "talk", "food", "all"],
    words: ["i", "could", "talk", "food", "all", "day", "love", "good"],
    id: "I could talk food all day. I love good food.",
    author: "Tom Brady",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["character", "result", "system", "stereotyped", "principles"],
    words: ["character", "is", "the", "result", "of", "a", "system", "stereotyped", "principles"],
    id: "Character is the result of a system of stereotyped principles.",
    author: "Unknown",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i've", "never", "had", "patience", "teacher"],
    words: ["i've", "never", "had", "the", "patience", "of", "a", "teacher"],
    id: "I've never had the patience of a teacher.",
    author: "Josh Turner",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["having", "hope", "excuse", "doing", "something"],
    words: ["not", "having", "hope", "is", "an", "excuse", "for", "doing", "something"],
    id: "Not having hope is not an excuse for not doing something.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "unhappy", "cannot", "forgive", "himself"],
    words: ["how", "unhappy", "is", "he", "who", "cannot", "forgive", "himself"],
    id: "How unhappy is he who cannot forgive himself.",
    author: "Publilius Syrus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["future", "as", "bright", "promises", "god"],
    words: ["the", "future", "is", "as", "bright", "promises", "of", "god"],
    id: "The future is as bright as the promises of God.",
    author: "William Carey",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["power", "after", "first", "source", "happiness"],
    words: ["power", "after", "love", "is", "the", "first", "source", "of", "happiness"],
    id: "Power, after love, is the first source of happiness.",
    author: "Stendhal",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["bible", "shows", "way", "heaven", "heavens"],
    words: ["the", "bible", "shows", "way", "to", "go", "heaven", "not", "heavens"],
    id: "The Bible shows the way to go to heaven, not the way the heavens go.",
    author: "Galileo Galilei",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["loving", "heart", "beginning", "all", "knowledge"],
    words: ["a", "loving", "heart", "is", "the", "beginning", "of", "all", "knowledge"],
    id: "A loving heart is the beginning of all knowledge.",
    author: "Thomas Carlyle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "used", "mathematics", "creating", "world"],
    words: ["god", "used", "beautiful", "mathematics", "in", "creating", "the", "world"],
    id: "God used beautiful mathematics in creating the world.",
    author: "Paul Dirac",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "doubt", "change", "history", "already"],
    words: ["never", "doubt", "that", "you", "can", "change", "history", "already", "have"],
    id: "Never doubt that you can change history. You already have.",
    author: "Marge Piercy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "hate", "which", "often", "fear"],
    words: ["in", "time", "we", "hate", "that", "which", "often", "fear"],
    id: "In time we hate that which we often fear.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["strangest", "life", "i've", "ever", "known"],
    words: ["this", "is", "the", "strangest", "life", "i've", "ever", "known"],
    id: "This is the strangest life I've ever known.",
    author: "Jim Morrison",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["modest", "doubt", "called", "beacon", "wise"],
    words: ["modest", "doubt", "is", "called", "the", "beacon", "of", "wise"],
    id: "Modest doubt is called the beacon of the wise.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["interesting", "enthusiastic", "talk", "too", "much"],
    words: ["be", "interesting", "enthusiastic", "and", "don't", "talk", "too", "much"],
    id: "Be interesting, be enthusiastic... and don't talk too much.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["ever", "injected", "truth", "into", "politics"],
    words: ["if", "you", "ever", "injected", "truth", "into", "politics", "have", "no"],
    id: "If you ever injected truth into politics you have no politics.",
    author: "Will Rogers",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["idea", "having", "steady", "job", "appealing"],
    words: ["the", "idea", "of", "having", "a", "steady", "job", "is", "appealing"],
    id: "The idea of having a steady job is appealing.",
    author: "Robin Williams",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "firm", "believer", "by", "heart"],
    words: ["i'm", "a", "firm", "believer", "in", "learning", "by", "heart"],
    id: "I'm a firm believer in learning by heart.",
    author: "Seamus Heaney",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "delightful", "find", "friend", "everyone"],
    words: ["how", "delightful", "to", "find", "a", "friend", "in", "everyone"],
    id: "How delightful to find a friend in everyone.",
    author: "Joseph Brodsky",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["god's", "greatest", "gifts", "unanswered", "prayers"],
    words: ["some", "of", "god's", "greatest", "gifts", "are", "unanswered", "prayers"],
    id: "Some of God's greatest gifts are unanswered prayers.",
    author: "Garth Brooks",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["age", "bring", "wisdom", "brings", "wrinkles"],
    words: ["age", "does", "not", "bring", "you", "wisdom", "brings", "wrinkles"],
    id: "Age does not bring you wisdom, age brings you wrinkles.",
    author: "Estelle Getty",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "safe", "thing", "take", "chance"],
    words: ["the", "only", "safe", "thing", "is", "to", "take", "a", "chance"],
    id: "The only safe thing is to take a chance.",
    author: "Mike Nichols",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowledge", "cultivates", "your", "seeds", "sow"],
    words: ["knowledge", "cultivates", "your", "seeds", "and", "does", "not", "sow", "in"],
    id: "Knowledge cultivates your seeds and does not sow in your seeds.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "lost", "upon", "god", "alone"],
    words: ["all", "love", "is", "lost", "but", "upon", "god", "alone"],
    id: "All love is lost but upon God alone.",
    author: "William Dunbar",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["dictator", "enemy", "freedom", "opponent", "law"],
    words: ["every", "dictator", "is", "an", "enemy", "of", "freedom", "opponent", "law"],
    id: "Every dictator is an enemy of freedom, an opponent of law.",
    author: "Demosthenes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["yesterday", "use", "up", "too", "much"],
    words: ["don't", "let", "yesterday", "use", "up", "too", "much", "of", "today"],
    id: "Don't let yesterday use up too much of today.",
    author: "Will Rogers",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["degree", "loving", "measured", "by", "giving"],
    words: ["the", "degree", "of", "loving", "is", "measured", "by", "giving"],
    id: "The degree of loving is measured by the degree of giving.",
    author: "Edwin Louis Cole",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["bad", "laws", "worst", "sort", "tyranny"],
    words: ["bad", "laws", "are", "the", "worst", "sort", "of", "tyranny"],
    id: "Bad laws are the worst sort of tyranny.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["nine-tenths", "wisdom", "being", "wise", "time"],
    words: ["nine-tenths", "of", "wisdom", "is", "being", "wise", "in", "time"],
    id: "Nine-tenths of wisdom is being wise in time.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "consume", "music", "way", "movies"],
    words: ["i", "consume", "music", "the", "way", "other", "people", "movies"],
    id: "I consume music the way other people consume movies.",
    author: "Lena Olin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "much", "difference", "between", "hate"],
    words: ["there's", "not", "much", "difference", "between", "love", "and", "hate"],
    id: "There's not much difference between love and hate.",
    author: "Ichiro Suzuki",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "social", "existence", "health", "body"],
    words: ["money", "is", "to", "my", "social", "existence", "what", "health", "body"],
    id: "Money is to my social existence what health is to my body.",
    author: "Mason Cooley",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "only", "one", "passion", "happiness"],
    words: ["there", "is", "only", "one", "passion", "the", "for", "happiness"],
    id: "There is only one passion, the passion for happiness.",
    author: "Denis Diderot",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["physics", "mathematics", "chemistry", "worshipping", "god"],
    words: ["studying", "physics", "mathematics", "and", "chemistry", "is", "worshipping", "god"],
    id: "Studying physics, mathematics, and chemistry is worshipping God.",
    author: "Fethullah Gulen",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "here", "i'm", "alive", "enough"],
    words: ["i", "am", "here", "and", "i'm", "alive", "that's", "enough"],
    id: "I am here and I'm alive. That's enough.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["unpredictable", "real", "interesting", "tell", "story"],
    words: ["be", "unpredictable", "real", "interesting", "tell", "a", "good", "story"],
    id: "Be unpredictable, be real, be interesting. Tell a good story.",
    author: "James Dashner",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "believe", "giving", "second", "chance"],
    words: ["i", "believe", "in", "giving", "people", "a", "second", "chance"],
    id: "I believe in giving people a second chance.",
    author: "George Zimmer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "responsible", "only", "god", "history"],
    words: ["i", "am", "responsible", "only", "to", "god", "and", "history"],
    id: "I am responsible only to God and history.",
    author: "Francisco Franco",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "really", "capitalizing", "on", "momentum"],
    words: ["i", "don't", "really", "care", "about", "capitalizing", "on", "momentum"],
    id: "I don't really care about capitalizing on momentum.",
    author: "Bo Burnham",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["investment", "knowledge", "pays", "best", "interest"],
    words: ["an", "investment", "in", "knowledge", "pays", "the", "best", "interest"],
    id: "An investment in knowledge pays the best interest.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "small", "accidents", "on", "circuit"],
    words: ["there", "are", "no", "small", "accidents", "on", "this", "circuit"],
    id: "There are no small accidents on this circuit.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["thing", "worse", "than", "rebellion", "causes"],
    words: ["the", "thing", "worse", "than", "rebellion", "is", "that", "causes"],
    id: "The thing worse than rebellion is the thing that causes rebellion.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowledge", "end", "based", "on", "acknowledgement"],
    words: ["knowledge", "is", "in", "the", "end", "based", "on", "acknowledgement"],
    id: "Knowledge is in the end based on acknowledgement.",
    author: "Ludwig Wittgenstein",
    grammar: null,
    word_count: 8,
    all_intersection_count: 3,
    new_words_count: 5,
  },
  {
    row_new_words: ["conformity", "jailer", "freedom", "enemy", "growth"],
    words: ["conformity", "is", "the", "jailer", "of", "freedom", "and", "enemy", "growth"],
    id: "Conformity is the jailer of freedom and the enemy of growth.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["holiness", "happiness", "chief", "end", "man"],
    words: ["holiness", "not", "happiness", "is", "the", "chief", "end", "of", "man"],
    id: "Holiness, not happiness, is the chief end of man.",
    author: "Oswald Chambers",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["gentle", "heart", "tied", "easy", "thread"],
    words: ["a", "gentle", "heart", "is", "tied", "with", "an", "easy", "thread"],
    id: "A gentle heart is tied with an easy thread.",
    author: "George Herbert",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["patient", "patience", "mother", "all", "virtues"],
    words: ["be", "patient", "patience", "is", "the", "mother", "of", "all", "virtues"],
    id: "Be patient. Patience is the mother of all virtues.",
    author: "Hrithik Roshan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "god", "sneezed", "i", "didn't"],
    words: ["when", "god", "sneezed", "i", "didn't", "know", "what", "to", "say"],
    id: "When God sneezed, I didn't know what to say.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "wisdom", "teach", "only", "ignorance"],
    words: ["if", "i", "don't", "have", "wisdom", "can", "teach", "you", "only", "ignorance"],
    id: "If I don't have wisdom, I can teach you only ignorance.",
    author: "Leo Buscaglia",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "always", "matter", "luck", "timing"],
    words: ["success", "is", "always", "a", "matter", "of", "some", "luck", "and", "timing"],
    id: "Success is always a matter of some luck and timing.",
    author: "Kathleen Kennedy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["specter", "haunting", "europe", "-", "communism"],
    words: ["a", "specter", "is", "haunting", "europe", "-", "the", "of", "communism"],
    id: "A specter is haunting Europe - the specter of communism.",
    author: "Karl Marx",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["patience", "key", "as", "long", "purpose"],
    words: ["patience", "is", "key", "as", "long", "you", "have", "a", "purpose"],
    id: "Patience is key, as long as you have a purpose.",
    author: "Rudy Ruettiger",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["memory", "one", "trained", "forget", "trivial"],
    words: ["a", "good", "memory", "is", "one", "trained", "to", "forget", "the", "trivial"],
    id: "A good memory is one trained to forget the trivial.",
    author: "Clifton Fadiman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["sometimes", "even", "live", "act", "courage"],
    words: ["sometimes", "even", "to", "live", "is", "an", "act", "of", "courage"],
    id: "Sometimes even to live is an act of courage.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["poker", "skill", "game", "pretending", "chance"],
    words: ["poker", "is", "a", "skill", "game", "pretending", "to", "be", "chance"],
    id: "Poker is a skill game pretending to be a chance game.",
    author: "James Altucher",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["young", "permanently", "state", "resembling", "intoxication"],
    words: ["the", "young", "are", "permanently", "in", "a", "state", "resembling", "intoxication"],
    id: "The young are permanently in a state resembling intoxication.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["army", "principles", "penetrate", "soldiers", "cannot"],
    words: ["an", "army", "of", "principles", "can", "penetrate", "where", "soldiers", "cannot"],
    id: "An army of principles can penetrate where an army of soldiers cannot.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["conquest", "our", "principles", "inconsistent", "government"],
    words: [
      "conquest",
      "is",
      "not",
      "in",
      "our",
      "principles",
      "it",
      "inconsistent",
      "with",
      "government",
    ],
    id: "Conquest is not in our principles. It is inconsistent with our government.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["faith", "believing", "god", "knowing", "will"],
    words: ["faith", "is", "not", "believing", "that", "god", "can", "it", "knowing", "will"],
    id: "Faith is not believing that God can. It is knowing that God will.",
    author: "Ben Stein",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["must", "live", "another", "wish", "yourself"],
    words: ["you", "must", "live", "for", "another", "if", "wish", "to", "yourself"],
    id: "You must live for another if you wish to live for yourself.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["learned", "men", "cisterns", "knowledge", "fountainheads"],
    words: ["learned", "men", "are", "the", "cisterns", "of", "knowledge", "not", "fountainheads"],
    id: "Learned men are the cisterns of knowledge, not the fountainheads.",
    author: "James Northcote",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["character", "formed", "stormy", "billows", "world"],
    words: ["character", "is", "formed", "in", "the", "stormy", "billows", "of", "world"],
    id: "Character is formed in the stormy billows of the world.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["anger", "intolerance", "enemies", "correct", "understanding"],
    words: [
      "anger",
      "and",
      "intolerance",
      "are",
      "the",
      "enemies",
      "of",
      "correct",
      "understanding",
    ],
    id: "Anger and intolerance are the enemies of correct understanding.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "anything", "politics", "zero", "nothing"],
    words: ["i", "don't", "know", "anything", "about", "politics", "like", "zero", "nothing"],
    id: "I don't know anything about politics. Like, zero. Nothing.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "right", "saturday", "night", "bath"],
    words: ["every", "man", "has", "a", "right", "to", "saturday", "night", "bath"],
    id: "Every man has a right to a Saturday night bath.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["men", "must", "obey", "laws", "too"],
    words: ["good", "men", "must", "not", "obey", "the", "laws", "too", "well"],
    id: "Good men must not obey the laws too well.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "power", "should", "use", "lightly"],
    words: ["he", "who", "has", "great", "power", "should", "use", "it", "lightly"],
    id: "He who has great power should use it lightly.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "way", "predict", "future", "create"],
    words: ["the", "best", "way", "to", "predict", "future", "is", "create", "it"],
    id: "The best way to predict the future is to create it.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["low", "expectations", "key", "happiness", "life"],
    words: ["low", "expectations", "is", "the", "key", "to", "happiness", "in", "life"],
    id: "Low expectations is the key to happiness in life.",
    author: "Christopher Miller",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["dreams", "future", "always", "filled", "gadgets"],
    words: ["dreams", "about", "the", "future", "are", "always", "filled", "with", "gadgets"],
    id: "Dreams about the future are always filled with gadgets.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "failures", "life", "only", "opportunities"],
    words: ["there", "are", "no", "failures", "in", "life", "only", "learning", "opportunities"],
    id: "There are no failures in life: only learning opportunities.",
    author: "Maneet Chauhan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["most", "consciously", "look", "at", "movies"],
    words: ["most", "of", "us", "do", "not", "consciously", "look", "at", "movies"],
    id: "Most of us do not consciously look at movies.",
    author: "Roger Ebert",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["four", "things", "success", "pray", "believe"],
    words: ["four", "things", "for", "success", "work", "and", "pray", "think", "believe"],
    id: "Four things for success: work and pray, think and believe.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "flesh", "shall", "great", "poem"],
    words: ["and", "your", "very", "flesh", "shall", "be", "a", "great", "poem"],
    id: "And your very flesh shall be a great poem.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "isn't", "getting", "wanting", "got"],
    words: ["happiness", "isn't", "getting", "what", "you", "want", "it's", "wanting", "got"],
    id: "Happiness isn't getting what you want, it's wanting what you got.",
    author: "Garth Brooks",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "real", "power", "heels", "dress"],
    words: ["there's", "a", "real", "power", "in", "heels", "and", "good", "dress"],
    id: "There's a real power in heels and a good dress.",
    author: "Talulah Riley",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "marriage", "entirely", "matter", "chance"],
    words: ["happiness", "in", "marriage", "is", "entirely", "a", "matter", "of", "chance"],
    id: "Happiness in marriage is entirely a matter of chance.",
    author: "Jane Austen",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["mortality", "different", "when", "20", "50"],
    words: ["mortality", "is", "very", "different", "when", "you're", "20", "to", "50"],
    id: "Mortality is very different when you're 20 to when you're 50.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "i", "really", "learned", "kindergarten"],
    words: ["all", "i", "really", "need", "to", "know", "learned", "in", "kindergarten"],
    id: "All I really need to know... I learned in kindergarten.",
    author: "Robert Fulghum",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "artist", "interpret", "meaning", "life"],
    words: ["only", "an", "artist", "can", "interpret", "the", "meaning", "of", "life"],
    id: "Only an artist can interpret the meaning of life.",
    author: "Novalis",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "judge", "another", "define", "yourself"],
    words: ["when", "you", "judge", "another", "do", "not", "define", "them", "yourself"],
    id: "When you judge another, you do not define them, you define yourself.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["possess", "only", "happiness", "able", "understand"],
    words: ["we", "possess", "only", "the", "happiness", "are", "able", "to", "understand"],
    id: "We possess only the happiness we are able to understand.",
    author: "Maurice Maeterlinck",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["first", "rule", "lose", "second", "forget"],
    words: ["the", "first", "rule", "is", "not", "to", "lose", "second", "forget"],
    id: "The first rule is not to lose. The second rule is not to forget the first rule.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "fair", "terms", "villain's", "mind"],
    words: ["i", "like", "not", "fair", "terms", "and", "a", "villain's", "mind"],
    id: "I like not fair terms and a villain's mind.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["mind", "still", "whole", "universe", "surrenders"],
    words: ["to", "the", "mind", "that", "is", "still", "whole", "universe", "surrenders"],
    id: "To the mind that is still, the whole universe surrenders.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "made", "56", "movies", "many"],
    words: ["i", "made", "about", "56", "movies", "think", "not", "that", "many"],
    id: "I made about 56 movies, I think. Not that many.",
    author: "Angela Lansbury",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "neither", "without", "yesterday", "nor"],
    words: ["there", "can", "be", "neither", "today", "without", "yesterday", "nor", "tomorrow"],
    id: "There can be neither today without yesterday nor tomorrow without today.",
    author: "Kim Jong-un",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["looking", "at", "middle-class", "guy", "i"],
    words: ["you're", "looking", "at", "a", "middle-class", "guy", "i", "am", "who"],
    id: "You're looking at a middle-class guy. I am who I am.",
    author: "Joe Biden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["desire", "nothing", "up", "all", "desires"],
    words: ["desire", "nothing", "give", "up", "all", "desires", "and", "be", "happy"],
    id: "Desire nothing, give up all desires and be happy.",
    author: "Swami Sivananda",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "sadness", "laughter", "bitter", "sweet"],
    words: ["god", "is", "in", "the", "sadness", "and", "laughter", "bitter", "sweet"],
    id: "God is in the sadness and the laughter, in the bitter and the sweet.",
    author: "Neale Donald Walsch",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "friend", "all", "living", "things"],
    words: ["the", "good", "man", "is", "friend", "of", "all", "living", "things"],
    id: "The good man is the friend of all living things.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["wise", "life", "problem", "fool", "solution"],
    words: ["to", "the", "wise", "life", "is", "a", "problem", "fool", "solution"],
    id: "To the wise, life is a problem; to the fool, a solution.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "disability", "life", "bad", "attitude"],
    words: ["the", "only", "disability", "in", "life", "is", "a", "bad", "attitude"],
    id: "The only disability in life is a bad attitude.",
    author: "Scott Hamilton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["hard", "remember", "it'll", "difficult", "forget"],
    words: ["if", "it's", "hard", "to", "remember", "it'll", "be", "difficult", "forget"],
    id: "If it's hard to remember, it'll be difficult to forget.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["measure", "success", "happiness", "peace", "mind"],
    words: ["the", "measure", "of", "success", "is", "happiness", "and", "peace", "mind"],
    id: "The measure of success is happiness and peace of mind.",
    author: "Bobby Davro",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "first", "recollection", "bugle", "call"],
    words: ["my", "first", "recollection", "is", "that", "of", "a", "bugle", "call"],
    id: "My first recollection is that of a bugle call.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["oh", "will", "make", "howl", "rhyme"],
    words: ["oh", "love", "will", "make", "a", "dog", "howl", "in", "rhyme"],
    id: "Oh, love will make a dog howl in rhyme.",
    author: "Francis Beaumont",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["irony", "gaiety", "reflection", "joy", "wisdom"],
    words: ["irony", "is", "the", "gaiety", "of", "reflection", "and", "joy", "wisdom"],
    id: "Irony is the gaiety of reflection and the joy of wisdom.",
    author: "Anatole France",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["once", "accept", "failure", "fun", "success"],
    words: ["once", "you", "can", "accept", "failure", "have", "fun", "and", "success"],
    id: "Once you can accept failure, you can have fun and success.",
    author: "Rickey Henderson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["being", "entirely", "honest", "oneself", "exercise"],
    words: ["being", "entirely", "honest", "with", "oneself", "is", "a", "good", "exercise"],
    id: "Being entirely honest with oneself is a good exercise.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "truth", "simple", "doubly", "lie"],
    words: ["all", "truth", "is", "simple", "that", "not", "doubly", "a", "lie"],
    id: "All truth is simple... is that not doubly a lie?",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "film", "easy", "war", "miracle"],
    words: ["to", "make", "a", "film", "is", "easy", "good", "war", "very", "miracle"],
    id: "To make a film is easy; to make a good film is war. To make a very good film is a miracle.",
    author: "Alejandro Gonzalez Inarritu",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["secret", "happiness", "admire", "without", "desiring"],
    words: ["the", "secret", "of", "happiness", "is", "to", "admire", "without", "desiring"],
    id: "The secret of happiness is to admire without desiring.",
    author: "Carl Sandburg",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "regret", "one", "my", "life"],
    words: ["i", "do", "not", "regret", "one", "moment", "of", "my", "life"],
    id: "I do not regret one moment of my life.",
    author: "Lillie Langtry",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["transitions", "time", "reflection", "looking", "forward"],
    words: ["transitions", "are", "a", "time", "for", "reflection", "and", "looking", "forward"],
    id: "Transitions are a time for reflection, and a time for looking forward.",
    author: "Roy Cooper",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["method", "nature", "could", "ever", "analyze"],
    words: ["the", "method", "of", "nature", "who", "could", "ever", "analyze", "it"],
    id: "The method of nature: who could ever analyze it?",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["music", "-", "motivational", "makes", "relax"],
    words: ["music", "-", "it's", "motivational", "and", "just", "makes", "you", "relax"],
    id: "Music - it's motivational and just makes you relax.",
    author: "Taika Waititi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["recipes", "tell", "nothing", "techniques", "key"],
    words: ["recipes", "tell", "you", "nothing", "learning", "techniques", "is", "the", "key"],
    id: "Recipes tell you nothing. Learning techniques is the key.",
    author: "Tom Colicchio",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "tendency", "things", "right", "themselves"],
    words: ["there", "is", "a", "tendency", "for", "things", "to", "right", "themselves"],
    id: "There is a tendency for things to right themselves.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "possessions", "friend", "most", "precious"],
    words: ["of", "all", "possessions", "a", "friend", "is", "the", "most", "precious"],
    id: "Of all possessions a friend is the most precious.",
    author: "Herodotus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "cease", "never", "been", "real"],
    words: ["the", "friendship", "that", "can", "cease", "has", "never", "been", "real"],
    id: "The friendship that can cease has never been real.",
    author: "St. Jerome",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "his", "lifetime", "thank", "faults"],
    words: ["every", "man", "in", "his", "lifetime", "needs", "to", "thank", "faults"],
    id: "Every man in his lifetime needs to thank his faults.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["modesty", "should", "typical", "success", "champion"],
    words: ["modesty", "should", "be", "typical", "of", "the", "success", "a", "champion"],
    id: "Modesty should be typical of the success of a champion.",
    author: "Major Taylor",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["stand", "little", "less", "between", "sun"],
    words: ["stand", "a", "little", "less", "between", "me", "and", "the", "sun"],
    id: "Stand a little less between me and the sun.",
    author: "Diogenes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["as", "long", "men", "children", "everything"],
    words: ["as", "long", "you", "know", "men", "are", "like", "children", "everything"],
    id: "As long as you know men are like children, you know everything!",
    author: "Coco Chanel",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["shoe", "fit", "must", "change", "foot"],
    words: ["if", "the", "shoe", "doesn't", "fit", "must", "we", "change", "foot"],
    id: "If the shoe doesn't fit, must we change the foot?",
    author: "Gloria Steinem",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "make", "at", "least", "look"],
    words: ["if", "you", "can't", "make", "it", "good", "at", "least", "look"],
    id: "If you can't make it good, at least make it look good.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "when", "easy", "your", "harness"],
    words: ["you", "have", "freedom", "when", "you're", "easy", "in", "your", "harness"],
    id: "You have freedom when you're easy in your harness.",
    author: "Robert Frost",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["stripped", "bare", "by", "curse", "plenty"],
    words: ["we", "are", "stripped", "bare", "by", "the", "curse", "of", "plenty"],
    id: "We are stripped bare by the curse of plenty.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["lost", "contact", "reality", "simplicity", "life"],
    words: ["we", "have", "lost", "contact", "with", "reality", "the", "simplicity", "of", "life"],
    id: "We have lost contact with reality, the simplicity of life.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "finished", "when", "defeated", "quits"],
    words: ["a", "man", "is", "not", "finished", "when", "he", "defeated", "quits"],
    id: "A man is not finished when he is defeated. He is finished when he quits.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "arrogant", "french", "-", "extraordinary"],
    words: ["you", "know", "how", "arrogant", "the", "french", "are", "-", "extraordinary"],
    id: "You know how arrogant the French are - extraordinary.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "optical", "illusion", "person", "meet"],
    words: ["there", "is", "an", "optical", "illusion", "about", "every", "person", "we", "meet"],
    id: "There is an optical illusion about every person we meet.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "success", "as", "goal", "obedience"],
    words: ["i", "don't", "see", "success", "as", "the", "goal", "obedience", "is"],
    id: "I don't see success as the goal. Obedience is the goal.",
    author: "Jerry B. Jenkins",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["finest", "steel", "through", "hottest", "fire"],
    words: ["the", "finest", "steel", "has", "to", "go", "through", "hottest", "fire"],
    id: "The finest steel has to go through the hottest fire.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["boy's", "story", "best", "ever", "told"],
    words: ["a", "boy's", "story", "is", "the", "best", "that", "ever", "told"],
    id: "A boy's story is the best that is ever told.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["ounce", "action", "worth", "ton", "theory"],
    words: ["an", "ounce", "of", "action", "is", "worth", "a", "ton", "theory"],
    id: "An ounce of action is worth a ton of theory.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "there", "state", "freedom", "will"],
    words: ["when", "there", "is", "state", "can", "be", "no", "freedom", "but", "will"],
    id: "When there is state there can be no freedom, but when there is freedom there will be no state.",
    author: "Vladimir Lenin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["lot", "when", "share", "meal", "together"],
    words: ["you", "learn", "a", "lot", "about", "someone", "when", "share", "meal", "together"],
    id: "You learn a lot about someone when you share a meal together.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["business", "easily", "defined", "-", "people's"],
    words: ["business", "that's", "easily", "defined", "-", "it's", "other", "people's", "money"],
    id: "Business, that's easily defined - it's other people's money.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["first", "step", "forgiveness", "willingness", "forgive"],
    words: ["the", "first", "step", "in", "forgiveness", "is", "willingness", "to", "forgive"],
    id: "The first step in forgiveness is the willingness to forgive.",
    author: "Marianne Williamson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["women", "music", "should", "never", "dated"],
    words: ["they", "say", "women", "and", "music", "should", "never", "be", "dated"],
    id: "They say women and music should never be dated.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["genius", "without", "education", "silver", "mine"],
    words: ["genius", "without", "education", "is", "like", "silver", "in", "the", "mine"],
    id: "Genius without education is like silver in the mine.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["wasted", "on", "others", "one's", "self"],
    words: ["a", "day", "wasted", "on", "others", "is", "not", "one's", "self"],
    id: "A day wasted on others is not wasted on one's self.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["should", "two", "things", "classy", "fabulous"],
    words: ["a", "girl", "should", "be", "two", "things", "classy", "and", "fabulous"],
    id: "A girl should be two things: classy and fabulous.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["always", "answer", "asks", "more", "question"],
    words: ["always", "the", "beautiful", "answer", "who", "asks", "a", "more", "question"],
    id: "Always the beautiful answer who asks a more beautiful question.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["human", "beings", "having", "spiritual", "experience"],
    words: ["we", "are", "not", "human", "beings", "having", "a", "spiritual", "experience"],
    id: "We are not human beings having a spiritual experience. We are spiritual beings having a human experience.",
    author: "Pierre Teilhard de Chardin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "still", "marxist", "many", "ways"],
    words: ["i", "still", "think", "like", "a", "marxist", "in", "many", "ways"],
    id: "I still think like a Marxist in many ways.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["winning", "most", "important", "everything", "consequence"],
    words: ["winning", "is", "the", "most", "important", "everything", "consequence", "of", "that"],
    id: "Winning is the most important. Everything is consequence of that.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "sometimes", "best", "training", "rest"],
    words: ["i", "think", "sometimes", "the", "best", "training", "is", "to", "rest"],
    id: "I think sometimes the best training is to rest.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "such", "thing", "as", "tax"],
    words: ["there", "is", "no", "such", "thing", "as", "a", "good", "tax"],
    id: "There is no such thing as a good tax.",
    author: "Unknown",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "wealth", "live", "content", "little"],
    words: ["the", "greatest", "wealth", "is", "to", "live", "content", "with", "little"],
    id: "The greatest wealth is to live content with little.",
    author: "Plato",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "imagine", "only", "living", "thing"],
    words: ["i", "imagine", "that", "yes", "is", "the", "only", "living", "thing"],
    id: "I imagine that yes is the only living thing.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["senses", "fail", "reason", "must", "step"],
    words: ["where", "the", "senses", "fail", "us", "reason", "must", "step", "in"],
    id: "Where the senses fail us, reason must step in.",
    author: "Galileo Galilei",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["at", "touch", "everyone", "becomes", "poet"],
    words: ["at", "the", "touch", "of", "love", "everyone", "becomes", "a", "poet"],
    id: "At the touch of love everyone becomes a poet.",
    author: "Plato",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "way", "fill", "time", "waste"],
    words: ["the", "best", "way", "to", "fill", "time", "is", "waste", "it"],
    id: "The best way to fill time is to waste it.",
    author: "Marguerite Duras",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "aim", "education", "knowledge", "action"],
    words: ["the", "great", "aim", "of", "education", "is", "not", "knowledge", "but", "action"],
    id: "The great aim of education is not knowledge but action.",
    author: "Herbert Spencer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "achieve", "success", "unless", "doing"],
    words: ["you", "never", "achieve", "success", "unless", "like", "what", "are", "doing"],
    id: "You never achieve success unless you like what you are doing.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["culture", "mind", "must", "subservient", "heart"],
    words: ["culture", "of", "the", "mind", "must", "be", "subservient", "to", "heart"],
    id: "Culture of the mind must be subservient to the heart.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "rollicking", "kindness", "looks", "malice"],
    words: ["there", "is", "a", "rollicking", "kindness", "that", "looks", "like", "malice"],
    id: "There is a rollicking kindness that looks like malice.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["many", "find", "universe", "confusing", "-"],
    words: ["many", "people", "find", "the", "universe", "confusing", "-", "it's", "not"],
    id: "Many people find the universe confusing - it's not.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "hard", "recall", "its", "glow"],
    words: ["happiness", "is", "hard", "to", "recall", "its", "just", "a", "glow"],
    id: "Happiness is hard to recall. Its just a glow.",
    author: "Frank McCourt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "idols", "admire", "dedication", "competence"],
    words: ["i", "have", "no", "idols", "admire", "work", "dedication", "and", "competence"],
    id: "I have no idols. I admire work, dedication and competence.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["courage", "mean", "regard", "fear", "confidence"],
    words: ["courage", "is", "a", "mean", "with", "regard", "to", "fear", "and", "confidence"],
    id: "Courage is a mean with regard to fear and confidence.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["alimony", "-", "ransom", "pay", "devil"],
    words: ["alimony", "-", "the", "ransom", "that", "happy", "pay", "to", "devil"],
    id: "Alimony - the ransom that the happy pay to the devil.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["everything", "done", "world", "by", "hope"],
    words: ["everything", "that", "is", "done", "in", "the", "world", "by", "hope"],
    id: "Everything that is done in the world is done by hope.",
    author: "Martin Luther",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "comes", "success", "there", "shortcuts"],
    words: ["when", "it", "comes", "to", "success", "there", "are", "no", "shortcuts"],
    id: "When it comes to success, there are no shortcuts.",
    author: "Bo Bennett",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["bollywood", "birthday", "celebrations", "usual", "carefree"],
    words: [
      "in",
      "bollywood",
      "birthday",
      "celebrations",
      "are",
      "usual",
      "and",
      "very",
      "carefree",
    ],
    id: "In Bollywood, birthday celebrations are usual and very carefree.",
    author: "Arbaaz Khan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "higher", "end", "than", "amused"],
    words: ["life", "has", "a", "higher", "end", "than", "to", "be", "amused"],
    id: "Life has a higher end, than to be amused.",
    author: "William Ellery Channing",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "wife", "i", "each", "much"],
    words: ["my", "wife", "and", "i", "love", "each", "other", "very", "much"],
    id: "My wife and I love each other very much.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["would", "move", "world", "first", "himself"],
    words: ["let", "him", "that", "would", "move", "the", "world", "first", "himself"],
    id: "Let him that would move the world first move himself.",
    author: "Socrates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["region", "earth", "full", "our", "calamities"],
    words: ["what", "region", "of", "the", "earth", "is", "not", "full", "our", "calamities"],
    id: "What region of the earth is not full of our calamities?",
    author: "Virgil",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["woman", "uniformly", "sacrificed", "wife", "mother"],
    words: ["the", "woman", "is", "uniformly", "sacrificed", "to", "wife", "and", "mother"],
    id: "The woman is uniformly sacrificed to the wife and mother.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["essence", "all", "art", "great", "gratitude"],
    words: ["the", "essence", "of", "all", "beautiful", "art", "great", "is", "gratitude"],
    id: "The essence of all beautiful art, all great art, is gratitude.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["friends", "acquaintances", "surest", "passport", "fortune"],
    words: ["friends", "and", "acquaintances", "are", "the", "surest", "passport", "to", "fortune"],
    id: "Friends and acquaintances are the surest passport to fortune.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "only", "ask", "free", "butterflies"],
    words: ["i", "only", "ask", "to", "be", "free", "the", "butterflies", "are"],
    id: "I only ask to be free. The butterflies are free.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["abundance", "something", "acquire", "tune", "into"],
    words: ["abundance", "is", "not", "something", "we", "acquire", "it", "tune", "into"],
    id: "Abundance is not something we acquire. It is something we tune into.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["nepotism", "will", "never", "success", "talent"],
    words: ["nepotism", "will", "never", "give", "you", "success", "but", "talent", "can"],
    id: "Nepotism will never give you success, but talent can.",
    author: "Ashok Saraf",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["friend", "may", "reckoned", "masterpiece", "nature"],
    words: ["a", "friend", "may", "well", "be", "reckoned", "the", "masterpiece", "of", "nature"],
    id: "A friend may well be reckoned the masterpiece of nature.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["why", "jews", "interferes", "their", "suffering"],
    words: ["why", "don't", "jews", "drink", "it", "interferes", "with", "their", "suffering"],
    id: "Why don't Jews drink? It interferes with their suffering.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["aim", "above", "morality", "simply", "something"],
    words: ["aim", "above", "morality", "be", "not", "simply", "good", "for", "something"],
    id: "Aim above morality. Be not simply good, be good for something.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["make", "clear", "i", "hate", "anybody"],
    words: ["let's", "make", "this", "clear", "i", "do", "not", "hate", "anybody"],
    id: "Let's make this clear: I do not hate anybody.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "coincidence", "stability", "brings", "success"],
    words: ["there", "is", "no", "coincidence", "that", "stability", "brings", "success", "and"],
    id: "There is no coincidence that stability brings success, and success brings stability.",
    author: "Robert Green",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["as", "reason", "rebel", "faith", "passion"],
    words: ["as", "reason", "is", "a", "rebel", "to", "faith", "so", "passion"],
    id: "As reason is a rebel to faith, so passion is a rebel to reason.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["getting", "married", "divorced", "really", "experience"],
    words: ["getting", "married", "and", "divorced", "really", "is", "a", "learning", "experience"],
    id: "Getting married and divorced really is a learning experience.",
    author: "Pollyanna McIntosh",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["short", "words", "best", "old", "all"],
    words: ["the", "short", "words", "are", "best", "and", "old", "of", "all"],
    id: "The short words are best, and the old words are the best of all.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "live", "my", "life", "record"],
    words: ["i", "want", "to", "live", "my", "life", "not", "record", "it"],
    id: "I want to live my life, not record it.",
    author: "Jackie Kennedy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["condition", "man", "war", "everyone", "against"],
    words: ["the", "condition", "of", "man", "is", "a", "war", "everyone", "against"],
    id: "The condition of man... is a condition of war of everyone against everyone.",
    author: "Thomas Hobbes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "hit", "at", "same", "time"],
    words: ["how", "can", "you", "think", "and", "hit", "at", "the", "same", "time"],
    id: "How can you think and hit at the same time?",
    author: "Yogi Berra",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "many", "fathers", "failure", "orphan"],
    words: ["success", "has", "many", "fathers", "and", "failure", "is", "an", "orphan"],
    id: "Success has many fathers and failure is an orphan.",
    author: "Alexander Nix",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["often", "pardon", "those", "annoy", "cannot"],
    words: ["we", "often", "pardon", "those", "that", "annoy", "us", "but", "cannot"],
    id: "We often pardon those that annoy us, but we cannot pardon those we annoy.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["stop", "bad", "guy", "gun", "takes"],
    words: ["to", "stop", "a", "bad", "guy", "with", "gun", "it", "takes", "good"],
    id: "To stop a bad guy with a gun, it takes a good guy with a gun.",
    author: "Wayne LaPierre",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["joy", "wonder", "wise", "amazement", "gods"],
    words: ["love", "is", "the", "joy", "of", "good", "wonder", "wise", "amazement", "gods"],
    id: "Love is the joy of the good, the wonder of the wise, the amazement of the Gods.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["myself", "acquainted", "misfortune", "i", "unfortunate"],
    words: [
      "myself",
      "acquainted",
      "with",
      "misfortune",
      "i",
      "learn",
      "to",
      "help",
      "the",
      "unfortunate",
    ],
    id: "Myself acquainted with misfortune, I learn to help the unfortunate.",
    author: "Virgil",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["wonder", "feeling", "philosopher", "philosophy", "begins"],
    words: [
      "wonder",
      "is",
      "the",
      "feeling",
      "of",
      "philosopher",
      "and",
      "philosophy",
      "begins",
      "in",
    ],
    id: "Wonder is the feeling of the philosopher, and philosophy begins in wonder.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "circumstances", "repair", "defect", "character"],
    words: ["no", "change", "of", "circumstances", "can", "repair", "a", "defect", "character"],
    id: "No change of circumstances can repair a defect of character.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["fortune", "converts", "everything", "advantage", "favorites"],
    words: [
      "fortune",
      "converts",
      "everything",
      "to",
      "the",
      "advantage",
      "of",
      "her",
      "favorites",
    ],
    id: "Fortune converts everything to the advantage of her favorites.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["music", "moonlight", "gloomy", "night", "life"],
    words: ["music", "is", "moonlight", "in", "the", "gloomy", "night", "of", "life"],
    id: "Music is moonlight in the gloomy night of life.",
    author: "Jean Paul",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["state", "nature", "profit", "measure", "right"],
    words: ["in", "the", "state", "of", "nature", "profit", "is", "measure", "right"],
    id: "In the state of nature profit is the measure of right.",
    author: "Thomas Hobbes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["fight", "climate", "change", "actually", "win"],
    words: ["we", "have", "to", "fight", "climate", "change", "like", "actually", "want", "win"],
    id: "We have to fight climate change like we actually want to win.",
    author: "Jagmeet Singh",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["decision", "based", "on", "knowledge", "numbers"],
    words: ["a", "good", "decision", "is", "based", "on", "knowledge", "and", "not", "numbers"],
    id: "A good decision is based on knowledge and not on numbers.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "luck", "all", "make", "yourself"],
    words: ["the", "best", "luck", "of", "all", "is", "you", "make", "for", "yourself"],
    id: "The best luck of all is the luck you make for yourself.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["war", "poor", "chisel", "carve", "out"],
    words: ["war", "is", "a", "poor", "chisel", "to", "carve", "out", "tomorrow"],
    id: "War is a poor chisel to carve out tomorrow.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["wild", "west", "internet", "there", "rules"],
    words: ["it's", "like", "the", "wild", "west", "internet", "there", "are", "no", "rules"],
    id: "It's like the Wild West, the Internet. There are no rules.",
    author: "Steven Wright",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "all", "short", "merry", "life"],
    words: ["i", "am", "all", "for", "the", "short", "and", "merry", "life"],
    id: "I am all for the short and merry life.",
    author: "Edward Fitzgerald",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["unlike", "politicians", "i", "admit", "mistake"],
    words: ["unlike", "some", "politicians", "i", "can", "admit", "to", "a", "mistake"],
    id: "Unlike some politicians, I can admit to a mistake.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["even", "my", "divorce", "everything", "i"],
    words: ["even", "with", "my", "divorce", "and", "everything", "i", "don't", "need", "money"],
    id: "Even with my divorce and with everything, I don't need money.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["last", "suit", "wear", "any", "pockets"],
    words: ["the", "last", "suit", "that", "you", "wear", "don't", "need", "any", "pockets"],
    id: "The last suit that you wear, you don't need any pockets.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["cannot", "positive", "life", "negative", "mind"],
    words: ["you", "cannot", "have", "a", "positive", "life", "and", "negative", "mind"],
    id: "You cannot have a positive life and a negative mind.",
    author: "Joyce Meyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["aim", "education", "knowledge", "facts", "values"],
    words: ["the", "aim", "of", "education", "is", "knowledge", "not", "facts", "but", "values"],
    id: "The aim of education is the knowledge, not of facts, but of values.",
    author: "William Inge",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["luck", "marches", "those", "their", "best"],
    words: ["luck", "marches", "with", "those", "who", "give", "their", "very", "best"],
    id: "Luck marches with those who give their very best.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["sometimes", "lose", "win", "unlucky", "life"],
    words: ["sometimes", "you", "lose", "win", "are", "unlucky", "but", "that's", "life"],
    id: "Sometimes you lose, sometimes you win, sometimes you are unlucky, but that's life.",
    author: "Jerome Boateng",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["those", "i", "fight", "hate", "guard"],
    words: ["those", "that", "i", "fight", "do", "not", "hate", "guard", "love"],
    id: "Those that I fight I do not hate, those that I guard I do not love.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "art", "drawing", "without", "eraser"],
    words: ["life", "is", "the", "art", "of", "drawing", "without", "an", "eraser"],
    id: "Life is the art of drawing without an eraser.",
    author: "John W. Gardner",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "limitations", "except", "ones", "make"],
    words: ["life", "has", "no", "limitations", "except", "the", "ones", "you", "make"],
    id: "Life has no limitations, except the ones you make.",
    author: "Les Brown",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["real", "ornament", "woman", "character", "purity"],
    words: ["the", "real", "ornament", "of", "woman", "is", "her", "character", "purity"],
    id: "The real ornament of woman is her character, her purity.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["moral", "monopoly", "antithesis", "marketplace", "ideas"],
    words: ["a", "moral", "monopoly", "is", "the", "antithesis", "of", "marketplace", "ideas"],
    id: "A moral monopoly is the antithesis of a marketplace of ideas.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "destination", "as", "hope", "strategy"],
    words: ["change", "is", "not", "a", "destination", "just", "as", "hope", "strategy"],
    id: "Change is not a destination, just as hope is not a strategy.",
    author: "Rudy Giuliani",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "i", "tremble", "my", "country"],
    words: ["if", "god", "is", "just", "i", "tremble", "for", "my", "country"],
    id: "If God is just, I tremble for my country.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["discover", "yourself", "through", "research", "your"],
    words: ["you", "discover", "yourself", "through", "the", "research", "of", "your", "work"],
    id: "You discover yourself through the research of your work.",
    author: "Carine Roitfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["mental", "health", "invisible", "real", "issue"],
    words: ["mental", "health", "is", "invisible", "but", "it's", "a", "very", "real", "issue"],
    id: "Mental health is invisible, but it's a very real issue.",
    author: "Coco Gauff",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["laws", "nature", "mathematical", "thoughts", "god"],
    words: ["the", "laws", "of", "nature", "are", "but", "mathematical", "thoughts", "god"],
    id: "The laws of nature are but the mathematical thoughts of God.",
    author: "Euclid",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["effortless", "painless", "process", "something", "naturally"],
    words: [
      "learning",
      "is",
      "effortless",
      "a",
      "painless",
      "process",
      "something",
      "we",
      "do",
      "naturally",
    ],
    id: "Learning is effortless. Learning is a painless process. Learning is something we do naturally!",
    author: "Neil Bush",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "victory", "friendship", "all", "greater"],
    words: ["great", "is", "the", "victory", "but", "friendship", "of", "all", "greater"],
    id: "Great is the victory, but the friendship of all is greater.",
    author: "Emil Zatopek",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["move", "from", "era", "confrontation", "negotiation"],
    words: ["let", "us", "move", "from", "the", "era", "of", "confrontation", "to", "negotiation"],
    id: "Let us move from the era of confrontation to the era of negotiation.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["before", "forgive", "one", "another", "understand"],
    words: ["before", "we", "can", "forgive", "one", "another", "have", "to", "understand"],
    id: "Before we can forgive one another, we have to understand one another.",
    author: "Emma Goldman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["everywhere", "within", "walking", "distance", "time"],
    words: [
      "everywhere",
      "is",
      "within",
      "walking",
      "distance",
      "if",
      "you",
      "have",
      "the",
      "time",
    ],
    id: "Everywhere is within walking distance if you have the time.",
    author: "Steven Wright",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["making", "living", "prevent", "from", "life"],
    words: ["don't", "let", "making", "a", "living", "prevent", "you", "from", "life"],
    id: "Don't let making a living prevent you from making a life.",
    author: "John Wooden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["fear", "makes", "strangers", "would", "friends"],
    words: ["fear", "makes", "strangers", "of", "people", "who", "would", "be", "friends"],
    id: "Fear makes strangers of people who would be friends.",
    author: "Shirley MacLaine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["particulars", "meaning", "there", "must", "universals"],
    words: [
      "if",
      "particulars",
      "are",
      "to",
      "have",
      "meaning",
      "there",
      "must",
      "be",
      "universals",
    ],
    id: "If particulars are to have meaning, there must be universals.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "skill", "together", "expect", "masterpiece"],
    words: ["when", "love", "and", "skill", "work", "together", "expect", "a", "masterpiece"],
    id: "When love and skill work together, expect a masterpiece.",
    author: "John Ruskin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "best", "leave", "rest", "god"],
    words: ["do", "your", "best", "and", "leave", "the", "rest", "to", "god"],
    id: "Do your best and leave the rest to God.",
    author: "Manisha Koirala",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["single", "my", "garden", "friend", "world"],
    words: ["a", "single", "rose", "can", "be", "my", "garden", "friend", "world"],
    id: "A single rose can be my garden... a single friend, my world.",
    author: "Leo Buscaglia",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "honor", "history", "bestow", "peacemaker"],
    words: [
      "the",
      "greatest",
      "honor",
      "history",
      "can",
      "bestow",
      "is",
      "that",
      "of",
      "peacemaker",
    ],
    id: "The greatest honor history can bestow is that of peacemaker.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "cannot", "overlook", "real", "friendship"],
    words: ["what", "i", "cannot", "love", "overlook", "is", "that", "real", "friendship"],
    id: "What I cannot love, I overlook. Is that real friendship?",
    author: "Anais Nin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "being", "city", "great", "weather"],
    words: ["i", "love", "being", "in", "a", "city", "with", "great", "weather"],
    id: "I love being in a city with great weather.",
    author: "Sean McVay",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["wise", "men", "because", "something", "fools"],
    words: ["wise", "men", "speak", "because", "they", "have", "something", "to", "say", "fools"],
    id: "Wise men speak because they have something to say; Fools because they have to say something.",
    author: "Unknown",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["laughter", "closest", "thing", "grace", "god"],
    words: ["laughter", "is", "the", "closest", "thing", "to", "grace", "of", "god"],
    id: "Laughter is the closest thing to the grace of God.",
    author: "Karl Barth",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["creation", "thousand", "forests", "one", "acorn"],
    words: ["the", "creation", "of", "a", "thousand", "forests", "is", "in", "one", "acorn"],
    id: "The creation of a thousand forests is in one acorn.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "multiplies", "life", "divides", "evil"],
    words: ["friendship", "multiplies", "the", "good", "of", "life", "and", "divides", "evil"],
    id: "Friendship multiplies the good of life and divides the evil.",
    author: "Baltasar Gracian",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["would", "been", "difficult", "ugly", "daughter"],
    words: ["it", "would", "have", "been", "difficult", "to", "an", "ugly", "daughter"],
    id: "It would have been difficult to have an ugly daughter.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["morality", "principle", "commerce", "civilized", "nations"],
    words: [
      "money",
      "not",
      "morality",
      "is",
      "the",
      "principle",
      "commerce",
      "of",
      "civilized",
      "nations",
    ],
    id: "Money, not morality, is the principle commerce of civilized nations.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["living", "out", "sense", "obligation", "slave"],
    words: ["if", "you", "are", "living", "out", "of", "a", "sense", "obligation", "slave"],
    id: "If you are living out of a sense of obligation you are slave.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["combine", "extremes", "will", "true", "center"],
    words: ["combine", "the", "extremes", "and", "you", "will", "have", "true", "center"],
    id: "Combine the extremes, and you will have the true center.",
    author: "Karl Wilhelm Friedrich Schlegel",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "resist", "idea", "crippled", "genius"],
    words: ["no", "one", "can", "resist", "the", "idea", "of", "a", "crippled", "genius"],
    id: "No one can resist the idea of a crippled genius.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["investor", "profit", "from", "yesterday's", "growth"],
    words: [
      "the",
      "investor",
      "of",
      "today",
      "does",
      "not",
      "profit",
      "from",
      "yesterday's",
      "growth",
    ],
    id: "The investor of today does not profit from yesterday's growth.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "master", "patience", "everything", "else"],
    words: ["a", "man", "who", "is", "master", "of", "patience", "everything", "else"],
    id: "A man who is a master of patience is master of everything else.",
    author: "George Savile",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["world", "round", "friendship", "may", "encircle"],
    words: ["the", "world", "is", "round", "so", "that", "friendship", "may", "encircle", "it"],
    id: "The world is round so that friendship may encircle it.",
    author: "Pierre Teilhard de Chardin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["mutual", "self-giving", "which", "ends", "self-recovery"],
    words: ["love", "is", "a", "mutual", "self-giving", "which", "ends", "in", "self-recovery"],
    id: "Love is a mutual self-giving which ends in self-recovery.",
    author: "Fulton J. Sheen",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["usman's", "veteran", "comes", "patience", "skill"],
    words: ["usman's", "a", "veteran", "and", "with", "that", "comes", "patience", "skill"],
    id: "Usman's a veteran, and with that comes patience, skill.",
    author: "Sean Strickland",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["momentum", "begets", "best", "way", "start"],
    words: ["momentum", "begets", "and", "the", "best", "way", "to", "start", "is"],
    id: "Momentum begets momentum, and the best way to start is to start.",
    author: "Gil Penchina",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "eye", "ever", "on", "those"],
    words: ["my", "eye", "is", "ever", "on", "those", "who", "love", "me"],
    id: "My eye is ever on those who love me.",
    author: "Sai Baba",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["education", "ornament", "prosperity", "refuge", "adversity"],
    words: [
      "education",
      "is",
      "an",
      "ornament",
      "in",
      "prosperity",
      "and",
      "a",
      "refuge",
      "adversity",
    ],
    id: "Education is an ornament in prosperity and a refuge in adversity.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["always", "make", "war", "when", "peace"],
    words: ["people", "always", "make", "war", "when", "they", "say", "love", "peace"],
    id: "People always make war when they say they love peace.",
    author: "D. H. Lawrence",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["giddy", "thinks", "world", "turns", "round"],
    words: ["he", "that", "is", "giddy", "thinks", "the", "world", "turns", "round"],
    id: "He that is giddy thinks the world turns round.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "birthday", "person", "modest", "birthdays"],
    words: ["i", "am", "a", "birthday", "person", "not", "modest", "about", "birthdays"],
    id: "I am a birthday person. I am not modest about birthdays.",
    author: "Parineeti Chopra",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["stories", "mafia", "godfather", "american", "gangster"],
    words: [
      "people",
      "love",
      "stories",
      "about",
      "the",
      "mafia",
      "godfather",
      "american",
      "gangster",
    ],
    id: "People love stories about the mafia: 'The Godfather,' 'American Gangster.'",
    author: "Jason Statham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["take", "away", "our", "earth", "tomb"],
    words: ["take", "away", "love", "and", "our", "earth", "is", "a", "tomb"],
    id: "Take away love and our earth is a tomb.",
    author: "Robert Browning",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "way", "predict", "future", "invent"],
    words: ["the", "best", "way", "to", "predict", "future", "is", "invent", "it"],
    id: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "life's", "most", "important", "skill"],
    words: ["learning", "how", "to", "learn", "is", "life's", "most", "important", "skill"],
    id: "Learning how to learn is life's most important skill.",
    author: "Tony Buzan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["meanwhile", "time", "flies", "never", "regained"],
    words: ["but", "meanwhile", "time", "flies", "it", "never", "to", "be", "regained"],
    id: "But meanwhile time flies; it flies never to be regained.",
    author: "Virgil",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["enjoy", "glow", "health", "must", "exercise"],
    words: ["to", "enjoy", "the", "glow", "of", "good", "health", "you", "must", "exercise"],
    id: "To enjoy the glow of good health, you must exercise.",
    author: "Gene Tunney",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "always", "song", "puts", "mood"],
    words: ["i", "always", "like", "a", "good", "song", "puts", "me", "in", "mood"],
    id: "I always like a good song: puts me in a good mood.",
    author: "Waris Ahluwalia",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["rich", "only", "through", "poor", "refuse"],
    words: ["we", "are", "rich", "only", "through", "what", "give", "and", "poor", "refuse"],
    id: "We are rich only through what we give, and poor only through what we refuse.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "specter", "haunting", "europe", "communism"],
    words: ["there", "is", "a", "specter", "haunting", "europe", "the", "of", "communism"],
    id: "There is a specter haunting Europe, the specter of Communism.",
    author: "Karl Marx",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["patience", "all", "things", "first", "yourself"],
    words: ["have", "patience", "with", "all", "things", "but", "first", "of", "yourself"],
    id: "Have patience with all things, But, first of all with yourself.",
    author: "Saint Francis de Sales",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["nonviolence", "policy", "when", "conditions", "permit"],
    words: ["nonviolence", "is", "a", "good", "policy", "when", "the", "conditions", "permit"],
    id: "Nonviolence is a good policy when the conditions permit.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "ghosts", "our", "old", "worst"],
    words: ["of", "all", "ghosts", "the", "our", "old", "loves", "are", "worst"],
    id: "Of all ghosts the ghosts of our old loves are the worst.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "forgotten", "all", "i", "done"],
    words: ["has", "god", "forgotten", "all", "i", "have", "done", "for", "him"],
    id: "Has God forgotten all I have done for Him.",
    author: "Louis XIV",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["truth", "rough", "flavours", "bite", "through"],
    words: ["truth", "has", "rough", "flavours", "if", "we", "bite", "it", "through"],
    id: "Truth has rough flavours if we bite it through.",
    author: "George Eliot",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "humble", "at", "all", "times"],
    words: ["i", "just", "want", "to", "be", "humble", "at", "all", "times"],
    id: "I just want to be humble at all times.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["honestly", "i", "change", "after", "meeting"],
    words: [
      "honestly",
      "i",
      "think",
      "people",
      "change",
      "after",
      "meeting",
      "someone",
      "you",
      "love",
    ],
    id: "Honestly, I think people change after meeting someone you love.",
    author: "Taeyang",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["music", "art", "prophets", "gift", "god"],
    words: ["music", "is", "the", "art", "of", "prophets", "and", "gift", "god"],
    id: "Music is the art of the prophets and the gift of God.",
    author: "Martin Luther",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["easier", "men", "general", "than", "particular"],
    words: ["it", "is", "easier", "to", "know", "men", "in", "general", "than", "particular"],
    id: "It is easier to know men in general, than men in particular.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "believe", "person", "born", "talent"],
    words: ["i", "believe", "that", "every", "person", "is", "born", "with", "talent"],
    id: "I believe that every person is born with talent.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "guy", "heart", "i", "intentions"],
    words: ["i'm", "a", "good", "guy", "with", "heart", "and", "i", "have", "intentions"],
    id: "I'm a good guy with a good heart, and I have good intentions.",
    author: "Antonio Brown",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["strong", "enough", "bear", "misfortunes", "others"],
    words: ["we", "are", "strong", "enough", "to", "bear", "the", "misfortunes", "of", "others"],
    id: "We are strong enough to bear the misfortunes of others.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "rich", "whose", "pleasures", "cheapest"],
    words: ["that", "man", "is", "rich", "whose", "pleasures", "are", "the", "cheapest"],
    id: "That man is rich whose pleasures are the cheapest.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "words", "wings", "fly", "would"],
    words: ["our", "words", "have", "wings", "but", "fly", "not", "where", "we", "would"],
    id: "Our words have wings, but fly not where we would.",
    author: "George Eliot",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["space", "time", "measured", "by", "heart"],
    words: ["love", "is", "space", "and", "time", "measured", "by", "the", "heart"],
    id: "Love is space and time measured by the heart.",
    author: "Marcel Proust",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["common", "thing", "uncommonly", "brings", "success"],
    words: ["to", "do", "a", "common", "thing", "uncommonly", "well", "brings", "success"],
    id: "To do a common thing uncommonly well brings success.",
    author: "Henry J. Heinz",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["key", "success", "action", "essential", "perseverance"],
    words: [
      "the",
      "key",
      "to",
      "success",
      "is",
      "action",
      "and",
      "essential",
      "in",
      "perseverance",
    ],
    id: "The key to success is action, and the essential in action is perseverance.",
    author: "Sun Yat-sen",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["knowing", "enough", "must", "apply", "willing"],
    words: ["knowing", "is", "not", "enough", "we", "must", "apply", "willing", "do"],
    id: "Knowing is not enough; we must apply. Willing is not enough; we must do.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["experience", "praises", "most", "one", "made"],
    words: ["experience", "praises", "the", "most", "happy", "one", "who", "made", "people"],
    id: "Experience praises the most happy the one who made the most people happy.",
    author: "Karl Marx",
    grammar: null,
    word_count: 9,
    all_intersection_count: 4,
    new_words_count: 5,
  },
  {
    row_new_words: ["wisdom", "knowing", "next", "virtue", "doing"],
    words: ["wisdom", "is", "knowing", "what", "to", "do", "next", "virtue", "doing", "it"],
    id: "Wisdom is knowing what to do next; virtue is doing it.",
    author: "David Starr Jordan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: ["making", "happiness", "great", "incentive", "super-happiness"],
    words: [
      "making",
      "money",
      "is",
      "a",
      "happiness",
      "and",
      "that's",
      "great",
      "incentive",
      "other",
      "people",
      "happy",
      "super-happiness",
    ],
    id: "Making money is a happiness. And that's a great incentive. Making other people happy is a super-happiness.",
    author: "Muhammad Yunus",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "never", "feels", "occurs", "ask"],
    words: [
      "the",
      "man",
      "never",
      "feels",
      "want",
      "of",
      "what",
      "it",
      "occurs",
      "to",
      "him",
      "ask",
      "for",
    ],
    id: "The man never feels the want of what it never occurs to him to ask for.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["resentment", "drinking", "poison", "person", "die"],
    words: [
      "resentment",
      "is",
      "like",
      "drinking",
      "poison",
      "and",
      "waiting",
      "for",
      "the",
      "other",
      "person",
      "to",
      "die",
    ],
    id: "Resentment is like drinking poison and waiting for the other person to die.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "allowed", "laugh", "heaven", "there"],
    words: [
      "if",
      "i",
      "am",
      "not",
      "allowed",
      "to",
      "laugh",
      "in",
      "heaven",
      "don't",
      "want",
      "go",
      "there",
    ],
    id: "If I am not allowed to laugh in heaven, I don't want to go there.",
    author: "Martin Luther",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["disturbed", "by", "things", "view", "take"],
    words: [
      "people",
      "are",
      "not",
      "disturbed",
      "by",
      "things",
      "but",
      "the",
      "view",
      "they",
      "take",
      "of",
      "them",
    ],
    id: "People are not disturbed by things, but by the view they take of them.",
    author: "Epictetus",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "waste", "time", "made", "up"],
    words: [
      "if",
      "you",
      "love",
      "life",
      "don't",
      "waste",
      "time",
      "for",
      "is",
      "what",
      "made",
      "up",
      "of",
    ],
    id: "If you love life, don't waste time, for time is what life is made up of.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["ruthless", "world", "one", "must", "cope"],
    words: [
      "this",
      "is",
      "a",
      "ruthless",
      "world",
      "and",
      "one",
      "must",
      "be",
      "to",
      "cope",
      "with",
      "it",
    ],
    id: "This is a ruthless world and one must be ruthless to cope with it.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "always", "been", "back", "anything"],
    words: [
      "i",
      "have",
      "always",
      "been",
      "that",
      "girl",
      "in",
      "the",
      "back",
      "who",
      "doesn't",
      "say",
      "anything",
    ],
    id: "I have always been that girl in the back who doesn't say anything.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["hope", "happiness", "world", "from", "god"],
    words: [
      "if",
      "you",
      "hope",
      "for",
      "happiness",
      "in",
      "the",
      "world",
      "it",
      "from",
      "god",
      "and",
      "not",
    ],
    id: "If you hope for happiness in the world, hope for it from God, and not from the world.",
    author: "David Brainerd",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["improv", "sometimes", "when", "open-field", "running"],
    words: [
      "the",
      "improv",
      "sometimes",
      "it",
      "works",
      "doesn't",
      "but",
      "when",
      "does",
      "it's",
      "like",
      "open-field",
      "running",
    ],
    id: "The improv, sometimes it works, sometimes it doesn't, but when it does, it's like open-field running.",
    author: "Robin Williams",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["pebble", "pond", "creates", "ripple", "change"],
    words: [
      "you",
      "want",
      "to",
      "be",
      "the",
      "pebble",
      "in",
      "pond",
      "that",
      "creates",
      "ripple",
      "for",
      "change",
    ],
    id: "You want to be the pebble in the pond that creates the ripple for change.",
    author: "Tim Cook",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["rightly", "orderly", "educated", "disciplined", "way"],
    words: [
      "to",
      "love",
      "rightly",
      "is",
      "what",
      "orderly",
      "and",
      "beautiful",
      "in",
      "an",
      "educated",
      "disciplined",
      "way",
    ],
    id: "To love rightly is to love what is orderly and beautiful in an educated and disciplined way.",
    author: "Plato",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["mark", "action", "appears", "inevitable", "retrospect"],
    words: [
      "it",
      "is",
      "the",
      "mark",
      "of",
      "a",
      "good",
      "action",
      "that",
      "appears",
      "inevitable",
      "in",
      "retrospect",
    ],
    id: "It is the mark of a good action that it appears inevitable in retrospect.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["thing", "caution", "from", "misfortunes", "others"],
    words: [
      "it",
      "is",
      "a",
      "good",
      "thing",
      "to",
      "learn",
      "caution",
      "from",
      "the",
      "misfortunes",
      "of",
      "others",
    ],
    id: "It is a good thing to learn caution from the misfortunes of others.",
    author: "Publilius Syrus",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["ultimately", "deeply", "side", "fear", "freedom"],
    words: [
      "ultimately",
      "we",
      "know",
      "deeply",
      "that",
      "the",
      "other",
      "side",
      "of",
      "every",
      "fear",
      "is",
      "freedom",
    ],
    id: "Ultimately we know deeply that the other side of every fear is freedom.",
    author: "Marilyn Ferguson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "time", "cut-and-dried", "monotony", "leaves"],
    words: [
      "there",
      "is",
      "no",
      "time",
      "for",
      "cut-and-dried",
      "monotony",
      "work",
      "and",
      "love",
      "that",
      "leaves",
      "other",
    ],
    id: "There is no time for cut-and-dried monotony. There is time for work. And time for love. That leaves no other time!",
    author: "Coco Chanel",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["ask", "anything", "i", "i'm", "answer"],
    words: [
      "if",
      "you",
      "ask",
      "me",
      "anything",
      "i",
      "don't",
      "know",
      "i'm",
      "not",
      "going",
      "to",
      "answer",
    ],
    id: "If you ask me anything I don't know, I'm not going to answer.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["hit", "movie", "another", "straight", "away"],
    words: [
      "if",
      "you're",
      "in",
      "a",
      "hit",
      "movie",
      "it's",
      "good",
      "to",
      "be",
      "another",
      "straight",
      "away",
    ],
    id: "If you're in a hit movie, it's good to be in another hit movie straight away.",
    author: "Val Kilmer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["should", "cd", "rip", "legal", "then"],
    words: [
      "people",
      "should",
      "just",
      "buy",
      "a",
      "cd",
      "and",
      "rip",
      "it",
      "you",
      "are",
      "legal",
      "then",
    ],
    id: "People should just buy a CD and rip it. You are legal then.",
    author: "Bill Gates",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "face", "hour", "savor", "nature"],
    words: [
      "we",
      "see",
      "god",
      "face",
      "to",
      "every",
      "hour",
      "and",
      "know",
      "the",
      "savor",
      "of",
      "nature",
    ],
    id: "We see God face to face every hour, and know the savor of Nature.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "within", "deep", "wisdom", "sometimes"],
    words: [
      "we",
      "all",
      "have",
      "within",
      "us",
      "a",
      "deep",
      "wisdom",
      "but",
      "sometimes",
      "don't",
      "know",
      "it",
    ],
    id: "We all have within us a deep wisdom, but sometimes we don't know we have it.",
    author: "Shakti Gawain",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["come", "out", "ballpark", "nobody's", "stop"],
    words: [
      "if",
      "the",
      "people",
      "don't",
      "want",
      "to",
      "come",
      "out",
      "ballpark",
      "nobody's",
      "going",
      "stop",
      "them",
    ],
    id: "If the people don't want to come out to the ballpark, nobody's going to stop them.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["lack", "friendship", "makes", "unhappy", "marriages"],
    words: [
      "it",
      "is",
      "not",
      "a",
      "lack",
      "of",
      "love",
      "but",
      "friendship",
      "that",
      "makes",
      "unhappy",
      "marriages",
    ],
    id: "It is not a lack of love, but a lack of friendship that makes unhappy marriages.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["play", "big", "amplifier", "trying", "control"],
    words: [
      "learning",
      "to",
      "play",
      "with",
      "a",
      "big",
      "amplifier",
      "is",
      "like",
      "trying",
      "control",
      "an",
      "elephant",
    ],
    id: "Learning to play with a big amplifier is like trying to control an elephant.",
    author: "Ritchie Blackmore",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["honour", "thrill", "privilege", "play", "nhl"],
    words: [
      "it's",
      "an",
      "honour",
      "and",
      "a",
      "thrill",
      "privilege",
      "just",
      "to",
      "play",
      "in",
      "the",
      "nhl",
    ],
    id: "It's an honour and a thrill and a privilege just to play in the NHL.",
    author: "Wayne Gretzky",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["or", "world", "evolves", "priorities", "change"],
    words: [
      "like",
      "it",
      "or",
      "not",
      "the",
      "world",
      "evolves",
      "priorities",
      "change",
      "and",
      "so",
      "do",
      "you",
    ],
    id: "Like it or not, the world evolves, priorities change and so do you.",
    author: "Marilu Henner",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "pleasure", "life", "doing", "cannot"],
    words: [
      "the",
      "greatest",
      "pleasure",
      "in",
      "life",
      "is",
      "doing",
      "what",
      "people",
      "say",
      "you",
      "cannot",
      "do",
    ],
    id: "The greatest pleasure in life is doing what people say you cannot do.",
    author: "Walter Bagehot",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "admire", "wisdom", "come", "advice"],
    words: [
      "we",
      "all",
      "admire",
      "the",
      "wisdom",
      "of",
      "people",
      "who",
      "come",
      "to",
      "us",
      "for",
      "advice",
    ],
    id: "We all admire the wisdom of people who come to us for advice.",
    author: "Arthur Helps",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["ruler", "must", "first", "been", "ruled"],
    words: [
      "he",
      "who",
      "is",
      "to",
      "be",
      "a",
      "good",
      "ruler",
      "must",
      "have",
      "first",
      "been",
      "ruled",
    ],
    id: "He who is to be a good ruler must have first been ruled.",
    author: "Aristotle",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "thing", "never", "get", "enough"],
    words: [
      "the",
      "one",
      "thing",
      "we",
      "can",
      "never",
      "get",
      "enough",
      "of",
      "is",
      "love",
      "and",
      "give",
    ],
    id: "The one thing we can never get enough of is love. And the one thing we never give enough is love.",
    author: "Henry Miller",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["because", "something", "planned", "mean", "useless"],
    words: [
      "just",
      "because",
      "something",
      "doesn't",
      "do",
      "what",
      "you",
      "planned",
      "it",
      "to",
      "mean",
      "it's",
      "useless",
    ],
    id: "Just because something doesn't do what you planned it to do doesn't mean it's useless.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["utmost", "extent", "man's", "knowledge", "nothing"],
    words: [
      "the",
      "utmost",
      "extent",
      "of",
      "man's",
      "knowledge",
      "is",
      "to",
      "know",
      "that",
      "he",
      "knows",
      "nothing",
    ],
    id: "The utmost extent of man's knowledge, is to know that he knows nothing.",
    author: "Joseph Addison",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "thing", "future", "will", "different"],
    words: [
      "the",
      "only",
      "thing",
      "we",
      "know",
      "about",
      "future",
      "is",
      "that",
      "it",
      "will",
      "be",
      "different",
    ],
    id: "The only thing we know about the future is that it will be different.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "greatest", "pieces", "economic", "wisdom"],
    words: [
      "one",
      "of",
      "the",
      "greatest",
      "pieces",
      "economic",
      "wisdom",
      "is",
      "to",
      "know",
      "what",
      "you",
      "do",
      "not",
    ],
    id: "One of the greatest pieces of economic wisdom is to know what you do not know.",
    author: "John Kenneth Galbraith",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["set", "yourself", "earnestly", "made", "then"],
    words: [
      "set",
      "yourself",
      "earnestly",
      "to",
      "see",
      "what",
      "you",
      "are",
      "made",
      "do",
      "and",
      "then",
      "it",
    ],
    id: "Set yourself earnestly to see what you are made to do, and then set yourself earnestly to do it.",
    author: "Phillips Brooks",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["trouble", "movies", "as", "business", "art"],
    words: [
      "the",
      "trouble",
      "with",
      "movies",
      "as",
      "a",
      "business",
      "is",
      "that",
      "it's",
      "an",
      "art",
      "and",
    ],
    id: "The trouble with movies as a business is that it's an art, and the trouble with movies as art is that it's a business.",
    author: "Charlton Heston",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "long-term", "companion", "i", "cannot"],
    words: [
      "if",
      "i'm",
      "not",
      "in",
      "love",
      "with",
      "a",
      "long-term",
      "companion",
      "i",
      "cannot",
      "be",
      "happy",
    ],
    id: "If I'm not in love, if I'm not with a long-term companion, I cannot be happy.",
    author: "Elon Musk",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["put", "goals", "front", "move", "towards"],
    words: [
      "we",
      "need",
      "to",
      "put",
      "goals",
      "in",
      "front",
      "of",
      "us",
      "and",
      "move",
      "towards",
      "them",
    ],
    id: "We need to put goals in front of us and move towards them.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["learned", "nature", "explore", "fool", "more"],
    words: [
      "the",
      "learned",
      "is",
      "happy",
      "nature",
      "to",
      "explore",
      "fool",
      "that",
      "he",
      "knows",
      "no",
      "more",
    ],
    id: "The learned is happy, nature to explore; The fool is happy, that he knows no more.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "biggest", "chinese", "star", "world"],
    words: [
      "you",
      "just",
      "wait",
      "i'm",
      "going",
      "to",
      "be",
      "the",
      "biggest",
      "chinese",
      "star",
      "in",
      "world",
    ],
    id: "You just wait. I'm going to be the biggest Chinese Star in the world.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["more", "painful", "tragically", "though", "part"],
    words: [
      "the",
      "more",
      "painful",
      "it",
      "is",
      "tragically",
      "you",
      "do",
      "learn",
      "though",
      "that's",
      "good",
      "part",
    ],
    id: "The more painful it is, tragically, the more you do learn, though, that's the good part.",
    author: "Sylvia Browne",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "sufficiency", "world", "man's", "greed"],
    words: [
      "there",
      "is",
      "a",
      "sufficiency",
      "in",
      "the",
      "world",
      "for",
      "man's",
      "need",
      "but",
      "not",
      "greed",
    ],
    id: "There is a sufficiency in the world for man's need but not for man's greed.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["magic", "come", "from", "i", "magic's"],
    words: [
      "and",
      "where",
      "does",
      "magic",
      "come",
      "from",
      "i",
      "think",
      "that",
      "magic's",
      "in",
      "the",
      "learning",
    ],
    id: "And where does magic come from? I think that magic's in the learning.",
    author: "Dar Williams",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["shall", "never", "all", "simple", "smile"],
    words: [
      "we",
      "shall",
      "never",
      "know",
      "all",
      "the",
      "good",
      "that",
      "a",
      "simple",
      "smile",
      "can",
      "do",
    ],
    id: "We shall never know all the good that a simple smile can do.",
    author: "Mother Teresa",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["marriage", "wonderful", "institution", "would", "live"],
    words: [
      "marriage",
      "is",
      "a",
      "wonderful",
      "institution",
      "but",
      "who",
      "would",
      "want",
      "to",
      "live",
      "in",
      "an",
    ],
    id: "Marriage is a wonderful institution, but who would want to live in an institution?",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "those", "knowledge", "passion", "theatre"],
    words: [
      "there",
      "are",
      "those",
      "who",
      "have",
      "a",
      "knowledge",
      "and",
      "passion",
      "for",
      "the",
      "theatre",
      "don't",
    ],
    id: "There are those who have a knowledge and passion for the theatre, and those who don't.",
    author: "Diana Rigg",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["opinion", "which", "their", "problem", "yours"],
    words: [
      "the",
      "opinion",
      "which",
      "other",
      "people",
      "have",
      "of",
      "you",
      "is",
      "their",
      "problem",
      "not",
      "yours",
    ],
    id: "The opinion which other people have of you is their problem, not yours.",
    author: "Elisabeth Kubler-Ross",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["biggest", "disease", "age", "feeling", "unloved"],
    words: [
      "the",
      "biggest",
      "disease",
      "this",
      "day",
      "and",
      "age",
      "is",
      "that",
      "of",
      "people",
      "feeling",
      "unloved",
    ],
    id: "The biggest disease this day and age is that of people feeling unloved.",
    author: "Princess Diana",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["remembering", "painful", "difficult", "inspiring", "wisdom"],
    words: [
      "remembering",
      "is",
      "painful",
      "it's",
      "difficult",
      "but",
      "it",
      "can",
      "be",
      "inspiring",
      "and",
      "give",
      "wisdom",
    ],
    id: "Remembering is painful, it's difficult, but it can be inspiring and it can give wisdom.",
    author: "Paul Greengrass",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["place", "when", "all", "hope", "expectations"],
    words: [
      "it's",
      "a",
      "good",
      "place",
      "when",
      "all",
      "you",
      "have",
      "is",
      "hope",
      "and",
      "not",
      "expectations",
    ],
    id: "It's a good place when all you have is hope and not expectations.",
    author: "Danny Boyle",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["believe", "then", "chance", "at", "all"],
    words: [
      "if",
      "you",
      "do",
      "not",
      "believe",
      "can",
      "it",
      "then",
      "have",
      "no",
      "chance",
      "at",
      "all",
    ],
    id: "If you do not believe you can do it then you have no chance at all.",
    author: "Arsene Wenger",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["safe", "or", "take", "chance", "great"],
    words: [
      "do",
      "you",
      "want",
      "to",
      "be",
      "safe",
      "and",
      "good",
      "or",
      "take",
      "a",
      "chance",
      "great",
    ],
    id: "Do you want to be safe and good, or do you want to take a chance and be great?",
    author: "Jimmy Johnson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "nature", "done", "creates", "genius"],
    words: [
      "when",
      "nature",
      "has",
      "work",
      "to",
      "be",
      "done",
      "she",
      "creates",
      "a",
      "genius",
      "do",
      "it",
    ],
    id: "When nature has work to be done, she creates a genius to do it.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["falls", "even", "though", "weed", "grows"],
    words: [
      "a",
      "flower",
      "falls",
      "even",
      "though",
      "we",
      "love",
      "it",
      "and",
      "weed",
      "grows",
      "do",
      "not",
    ],
    id: "A flower falls, even though we love it; and a weed grows, even though we do not love it.",
    author: "Dogen",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["jesus", "ideal", "wonderful", "christians", "-"],
    words: [
      "jesus",
      "is",
      "ideal",
      "and",
      "wonderful",
      "but",
      "you",
      "christians",
      "-",
      "are",
      "not",
      "like",
      "him",
    ],
    id: "Jesus is ideal and wonderful, but you Christians - you are not like him.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["hockey", "player", "plays", "puck", "great"],
    words: [
      "a",
      "good",
      "hockey",
      "player",
      "plays",
      "where",
      "the",
      "puck",
      "is",
      "great",
      "going",
      "to",
      "be",
    ],
    id: "A good hockey player plays where the puck is. A great hockey player plays where the puck is going to be.",
    author: "Wayne Gretzky",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["first", "rule", "business", "men", "would"],
    words: [
      "the",
      "first",
      "rule",
      "of",
      "business",
      "is",
      "do",
      "other",
      "men",
      "for",
      "they",
      "would",
      "you",
    ],
    id: "The first rule of business is: Do other men for they would do you.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "here", "spell", "get", "laughs"],
    words: [
      "we",
      "are",
      "all",
      "here",
      "for",
      "a",
      "spell",
      "get",
      "the",
      "good",
      "laughs",
      "you",
      "can",
    ],
    id: "We are all here for a spell, get all the good laughs you can.",
    author: "Will Rogers",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["despise", "all", "those", "vices", "virtue"],
    words: [
      "we",
      "do",
      "not",
      "despise",
      "all",
      "those",
      "who",
      "have",
      "vices",
      "but",
      "that",
      "no",
      "virtue",
    ],
    id: "We do not despise all those who have vices, but we do despise those that have no virtue.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["possesses", "art", "science", "religion", "possess"],
    words: [
      "he",
      "who",
      "possesses",
      "art",
      "and",
      "science",
      "has",
      "religion",
      "does",
      "not",
      "possess",
      "them",
      "needs",
    ],
    id: "He who possesses art and science has religion; he who does not possess them, needs religion.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "believe", "universal", "health", "afraid"],
    words: [
      "i",
      "believe",
      "in",
      "universal",
      "health",
      "care",
      "and",
      "am",
      "not",
      "afraid",
      "to",
      "say",
      "so",
    ],
    id: "I believe in universal health care. And I am not afraid to say so.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "playing", "wouldn't", "coach", "patience"],
    words: [
      "i",
      "like",
      "playing",
      "wouldn't",
      "be",
      "a",
      "good",
      "coach",
      "don't",
      "have",
      "the",
      "patience",
      "to",
    ],
    id: "I like playing. I wouldn't be a good coach. I don't have the patience to be a coach.",
    author: "Tom Brady",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["experience", "change", "wake", "up", "different"],
    words: [
      "people",
      "don't",
      "want",
      "to",
      "experience",
      "change",
      "they",
      "just",
      "wake",
      "up",
      "and",
      "it's",
      "different",
    ],
    id: "People don't want to experience change; they just want to wake up, and it's different.",
    author: "Chadwick Boseman",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "introvert", "privately", "shy", "unless"],
    words: [
      "i",
      "am",
      "an",
      "introvert",
      "privately",
      "very",
      "shy",
      "and",
      "don't",
      "speak",
      "unless",
      "have",
      "to",
    ],
    id: "I am an introvert; privately I am very shy, and I don't speak unless I have to.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["tell", "audience", "then", "you've", "said"],
    words: [
      "tell",
      "the",
      "audience",
      "what",
      "you're",
      "going",
      "to",
      "say",
      "it",
      "then",
      "them",
      "you've",
      "said",
    ],
    id: "Tell the audience what you're going to say, say it; then tell them what you've said.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["war", "horrible", "or", "might", "grow"],
    words: [
      "it",
      "is",
      "good",
      "that",
      "war",
      "so",
      "horrible",
      "or",
      "we",
      "might",
      "grow",
      "to",
      "like",
    ],
    id: "It is good that war is so horrible, or we might grow to like it.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["others", "cannot", "at", "least", "harm"],
    words: [
      "if",
      "you",
      "can",
      "help",
      "others",
      "cannot",
      "do",
      "that",
      "at",
      "least",
      "not",
      "harm",
      "them",
    ],
    id: "If you can, help others; if you cannot do that, at least do not harm them.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["scientist", "answer", "even", "absence", "data"],
    words: [
      "if",
      "you're",
      "a",
      "scientist",
      "and",
      "you",
      "have",
      "to",
      "an",
      "answer",
      "even",
      "in",
      "the",
      "absence",
      "of",
      "data",
      "not",
      "going",
      "be",
      "good",
    ],
    id: "If you're a scientist, and you have to have an answer, even in the absence of data, you're not going to be a good scientist.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 20,
    all_intersection_count: 15,
    new_words_count: 5,
  },
  {
    row_new_words: ["must", "willing", "life", "planned", "as"],
    words: [
      "we",
      "must",
      "be",
      "willing",
      "to",
      "let",
      "go",
      "of",
      "the",
      "life",
      "have",
      "planned",
      "so",
      "as",
      "that",
      "is",
      "waiting",
      "for",
      "us",
    ],
    id: "We must be willing to let go of the life we have planned, so as to have the life that is waiting for us.",
    author: "E. M. Forster",
    grammar: null,
    word_count: 19,
    all_intersection_count: 14,
    new_words_count: 5,
  },
  {
    row_new_words: ["things", "stay", "as", "will", "change"],
    words: [
      "if",
      "we",
      "want",
      "things",
      "to",
      "stay",
      "as",
      "they",
      "are",
      "will",
      "have",
      "change",
    ],
    id: "If we want things to stay as they are, things will have to change.",
    author: "Giuseppe Tomasi di Lampedusa",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "long", "lived", "main", "thing"],
    words: [
      "not",
      "how",
      "long",
      "but",
      "well",
      "you",
      "have",
      "lived",
      "is",
      "the",
      "main",
      "thing",
    ],
    id: "Not how long, but how well you have lived is the main thing.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["friendship", "immortality", "soul", "too", "believed"],
    words: [
      "friendship",
      "like",
      "the",
      "immortality",
      "of",
      "soul",
      "is",
      "too",
      "good",
      "to",
      "be",
      "believed",
    ],
    id: "Friendship, like the immortality of the soul, is too good to be believed.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["everything", "shadow", "cast", "by", "which"],
    words: [
      "everything",
      "that",
      "we",
      "see",
      "is",
      "a",
      "shadow",
      "cast",
      "by",
      "which",
      "do",
      "not",
    ],
    id: "Everything that we see is a shadow cast by that which we do not see.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["test", "manners", "patient", "bad", "ones"],
    words: [
      "the",
      "test",
      "of",
      "good",
      "manners",
      "is",
      "to",
      "be",
      "patient",
      "with",
      "bad",
      "ones",
    ],
    id: "The test of good manners is to be patient with the bad ones.",
    author: "Solomon Ibn Gabirol",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["thing", "get", "all", "government", "pay"],
    words: [
      "it's",
      "a",
      "good",
      "thing",
      "we",
      "don't",
      "get",
      "all",
      "the",
      "government",
      "pay",
      "for",
    ],
    id: "It's a good thing we don't get all the government we pay for.",
    author: "Will Rogers",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "pledge", "myself", "deal", "american"],
    words: [
      "i",
      "pledge",
      "you",
      "myself",
      "to",
      "a",
      "new",
      "deal",
      "for",
      "the",
      "american",
      "people",
    ],
    id: "I pledge you, I pledge myself, to a new deal for the American people.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["way", "anything", "realize", "may", "lost"],
    words: [
      "the",
      "way",
      "to",
      "love",
      "anything",
      "is",
      "realize",
      "that",
      "it",
      "may",
      "be",
      "lost",
    ],
    id: "The way to love anything is to realize that it may be lost.",
    author: "Gilbert K. Chesterton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "shows", "believe", "first", "time"],
    words: [
      "when",
      "someone",
      "shows",
      "you",
      "who",
      "they",
      "are",
      "believe",
      "them",
      "the",
      "first",
      "time",
    ],
    id: "When someone shows you who they are, believe them the first time.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "make", "movies", "really", "involved"],
    words: [
      "i",
      "just",
      "want",
      "to",
      "make",
      "movies",
      "really",
      "love",
      "be",
      "involved",
      "with",
      "them",
    ],
    id: "I just want to make movies. I really love movies. I want to be involved with them.",
    author: "Jodie Foster",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["truth", "one", "free", "until", "everybody"],
    words: [
      "the",
      "truth",
      "is",
      "no",
      "one",
      "of",
      "us",
      "can",
      "be",
      "free",
      "until",
      "everybody",
    ],
    id: "The truth is, no one of us can be free until everybody is free.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["everything", "done", "as", "makes", "difference"],
    words: [
      "let",
      "everything",
      "you",
      "do",
      "be",
      "done",
      "as",
      "if",
      "it",
      "makes",
      "a",
      "difference",
    ],
    id: "Let everything you do be done as if it makes a difference.",
    author: "William James",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["discovery", "ourselves", "others", "delight", "recognition"],
    words: [
      "love",
      "is",
      "but",
      "the",
      "discovery",
      "of",
      "ourselves",
      "in",
      "others",
      "and",
      "delight",
      "recognition",
    ],
    id: "Love is but the discovery of ourselves in others, and the delight in the recognition.",
    author: "Alexander Smith",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["music", "best", "way", "i", "communicate"],
    words: [
      "music",
      "is",
      "the",
      "best",
      "way",
      "that",
      "i",
      "can",
      "communicate",
      "with",
      "other",
      "people",
    ],
    id: "Music is the best way that I can communicate with other people.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "comes", "from", "physical", "survival"],
    words: [
      "life",
      "comes",
      "from",
      "physical",
      "survival",
      "but",
      "the",
      "good",
      "what",
      "we",
      "care",
      "about",
    ],
    id: "Life comes from physical survival; but the good life comes from what we care about.",
    author: "Rollo May",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["cannot", "serve", "lord", "heart", "forgiveness"],
    words: [
      "we",
      "cannot",
      "serve",
      "the",
      "lord",
      "if",
      "don't",
      "have",
      "a",
      "heart",
      "of",
      "forgiveness",
    ],
    id: "We cannot serve the Lord if we don't have a heart of forgiveness.",
    author: "Monty Williams",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "all", "advice", "those", "ill"],
    words: [
      "when",
      "we",
      "are",
      "well",
      "all",
      "have",
      "good",
      "advice",
      "for",
      "those",
      "who",
      "ill",
    ],
    id: "When we are well, we all have good advice for those who are ill.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["stars", "hold", "our", "destiny", "ourselves"],
    words: [
      "it",
      "is",
      "not",
      "in",
      "the",
      "stars",
      "to",
      "hold",
      "our",
      "destiny",
      "but",
      "ourselves",
    ],
    id: "It is not in the stars to hold our destiny but in ourselves.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "inner-life", "slave", "his", "surroundings"],
    words: [
      "the",
      "man",
      "who",
      "has",
      "no",
      "inner-life",
      "is",
      "a",
      "slave",
      "to",
      "his",
      "surroundings",
    ],
    id: "The man who has no inner-life is a slave to his surroundings.",
    author: "Henri Frederic Amiel",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["choose", "attitudes", "right", "continuing", "choice"],
    words: [
      "we",
      "choose",
      "what",
      "attitudes",
      "have",
      "right",
      "now",
      "and",
      "it's",
      "a",
      "continuing",
      "choice",
    ],
    id: "We choose what attitudes we have right now. And it's a continuing choice.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "way", "independent", "sovereign", "state"],
    words: [
      "the",
      "best",
      "way",
      "of",
      "learning",
      "to",
      "be",
      "an",
      "independent",
      "sovereign",
      "state",
      "is",
    ],
    id: "The best way of learning to be an independent sovereign state is to be an independent sovereign state.",
    author: "Kwame Nkrumah",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["on", "our", "pleasures", "last", "forever"],
    words: [
      "we",
      "go",
      "on",
      "in",
      "our",
      "pleasures",
      "thinking",
      "they're",
      "going",
      "to",
      "last",
      "forever",
    ],
    id: "We go on in our pleasures thinking they're going to last forever.",
    author: "Billy Graham",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "rarely", "absent", "its", "presence"],
    words: [
      "happiness",
      "is",
      "rarely",
      "absent",
      "it",
      "we",
      "that",
      "know",
      "not",
      "of",
      "its",
      "presence",
    ],
    id: "Happiness is rarely absent; it is we that know not of its presence.",
    author: "Maurice Maeterlinck",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "forest", "place", "yourself", "better"],
    words: [
      "i",
      "love",
      "the",
      "forest",
      "it",
      "is",
      "place",
      "where",
      "you",
      "hear",
      "yourself",
      "better",
    ],
    id: "I love the forest. It is the place where you hear yourself better.",
    author: "Fahadh Faasil",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "power", "begin", "world", "over"],
    words: [
      "we",
      "have",
      "it",
      "in",
      "our",
      "power",
      "to",
      "begin",
      "the",
      "world",
      "over",
      "again",
    ],
    id: "We have it in our power to begin the world over again.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["after", "your", "dreams", "regret", "hesitate"],
    words: [
      "do",
      "what",
      "you",
      "love",
      "and",
      "go",
      "after",
      "your",
      "dreams",
      "don't",
      "regret",
      "it",
      "hesitate",
    ],
    id: "Do what you love and go after your dreams. Don't regret it. Don't hesitate.",
    author: "Jackson Wang",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["politics", "football", "daylight", "through", "hole"],
    words: [
      "politics",
      "is",
      "like",
      "football",
      "if",
      "you",
      "see",
      "daylight",
      "go",
      "through",
      "the",
      "hole",
    ],
    id: "Politics is like football; if you see daylight, go through the hole.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["usually", "commitment", "promises", "friendship", "grows"],
    words: [
      "usually",
      "you",
      "don't",
      "have",
      "commitment",
      "promises",
      "in",
      "a",
      "friendship",
      "it",
      "just",
      "grows",
    ],
    id: "Usually, you don't have commitment promises in a friendship. Usually, it just grows.",
    author: "Sheila Heti",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "worth", "living", "since", "make"],
    words: [
      "this",
      "life",
      "is",
      "worth",
      "living",
      "we",
      "can",
      "say",
      "since",
      "it",
      "what",
      "make",
    ],
    id: "This life is worth living, we can say, since it is what we make it.",
    author: "William James",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["key", "time", "management", "-", "value"],
    words: [
      "this",
      "is",
      "the",
      "key",
      "to",
      "time",
      "management",
      "-",
      "see",
      "value",
      "of",
      "every",
      "moment",
    ],
    id: "This is the key to time management - to see the value of every moment.",
    author: "Menachem Mendel Schneerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["any", "road", "will", "get", "there"],
    words: [
      "if",
      "you",
      "don't",
      "know",
      "where",
      "are",
      "going",
      "any",
      "road",
      "will",
      "get",
      "there",
    ],
    id: "If you don't know where you are going, any road will get you there.",
    author: "Lewis Carroll",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["buddha", "finds", "freedom", "fortune", "bad"],
    words: [
      "a",
      "buddha",
      "is",
      "someone",
      "who",
      "finds",
      "freedom",
      "in",
      "good",
      "fortune",
      "and",
      "bad",
    ],
    id: "A Buddha is someone who finds freedom in good fortune and bad.",
    author: "Bodhidharma",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "those", "joy", "their", "reward"],
    words: [
      "there",
      "are",
      "those",
      "who",
      "give",
      "with",
      "joy",
      "and",
      "that",
      "is",
      "their",
      "reward",
    ],
    id: "There are those who give with joy, and that joy is their reward.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["find", "job", "add", "five", "days"],
    words: ["find", "a", "job", "you", "like", "and", "add", "five", "days", "to", "every", "week"],
    id: "Find a job you like and you add five days to every week.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "principle", "worth", "name", "wholly"],
    words: [
      "there",
      "is",
      "no",
      "principle",
      "worth",
      "the",
      "name",
      "if",
      "it",
      "not",
      "wholly",
      "good",
    ],
    id: "There is no principle worth the name if it is not wholly good.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["little", "trouble", "world", "due", "friendship"],
    words: [
      "the",
      "little",
      "trouble",
      "in",
      "world",
      "that",
      "is",
      "not",
      "due",
      "to",
      "love",
      "friendship",
    ],
    id: "The little trouble in the world that is not due to love is due to friendship.",
    author: "E. W. Howe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "family", "taste", "family's", "inconsistent"],
    words: [
      "it's",
      "not",
      "that",
      "our",
      "family",
      "has",
      "no",
      "taste",
      "just",
      "family's",
      "is",
      "inconsistent",
    ],
    id: "It's not that our family has no taste, it's just that our family's taste is inconsistent.",
    author: "Dave Eggers",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["hate", "feel", "all", "nothing", "perceive"],
    words: [
      "to",
      "hate",
      "love",
      "think",
      "feel",
      "see",
      "all",
      "this",
      "is",
      "nothing",
      "but",
      "perceive",
    ],
    id: "To hate, to love, to think, to feel, to see; all this is nothing but to perceive.",
    author: "David Hume",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "making", "happiness", "fulfillment", "ability"],
    words: [
      "success",
      "is",
      "not",
      "just",
      "making",
      "money",
      "happiness",
      "fulfillment",
      "it's",
      "the",
      "ability",
      "to",
      "give",
    ],
    id: "Success is not just making money. Success is happiness. Success is fulfillment; it's the ability to give.",
    author: "Adam Neumann",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["drive", "happiness", "vital", "keeps", "motion"],
    words: [
      "the",
      "drive",
      "for",
      "happiness",
      "is",
      "vital",
      "it's",
      "what",
      "keeps",
      "us",
      "in",
      "motion",
    ],
    id: "The drive for happiness is vital; it's what keeps us in motion.",
    author: "Richard Gere",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["friend", "one", "same", "enemies", "as"],
    words: [
      "a",
      "friend",
      "is",
      "one",
      "who",
      "has",
      "the",
      "same",
      "enemies",
      "as",
      "you",
      "have",
    ],
    id: "A friend is one who has the same enemies as you have.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["duty", "propose", "privilege", "congress", "dispose"],
    words: [
      "it",
      "is",
      "the",
      "duty",
      "of",
      "president",
      "to",
      "propose",
      "and",
      "privilege",
      "congress",
      "dispose",
    ],
    id: "It is the duty of the President to propose and it is the privilege of the Congress to dispose.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["year", "sharp", "elbow", "quick", "tongue"],
    words: [
      "it's",
      "going",
      "to",
      "be",
      "the",
      "year",
      "of",
      "sharp",
      "elbow",
      "and",
      "quick",
      "tongue",
    ],
    id: "It's going to be the year of the sharp elbow and the quick tongue.",
    author: "George W. Bush",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["anything", "knowledge", "opportunity", "more", "rational"],
    words: [
      "anything",
      "that",
      "gives",
      "us",
      "new",
      "knowledge",
      "an",
      "opportunity",
      "to",
      "be",
      "more",
      "rational",
    ],
    id: "Anything that gives us new knowledge gives us an opportunity to be more rational.",
    author: "Herbert A. Simon",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["secret", "happiness", "admire", "without", "desiring"],
    words: [
      "the",
      "secret",
      "of",
      "happiness",
      "is",
      "to",
      "admire",
      "without",
      "desiring",
      "and",
      "that",
      "not",
    ],
    id: "The secret of happiness is to admire without desiring. And that is not happiness.",
    author: "F. H. Bradley",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "meet", "tells", "something", "yourself"],
    words: [
      "love",
      "is",
      "when",
      "you",
      "meet",
      "someone",
      "who",
      "tells",
      "something",
      "new",
      "about",
      "yourself",
    ],
    id: "Love is when you meet someone who tells you something new about yourself.",
    author: "Andre Breton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "much", "enjoy", "makes", "happiness"],
    words: [
      "it",
      "is",
      "not",
      "how",
      "much",
      "we",
      "have",
      "but",
      "enjoy",
      "that",
      "makes",
      "happiness",
    ],
    id: "It is not how much we have, but how much we enjoy, that makes happiness.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["understand", "nature", "one", "must", "prince"],
    words: [
      "to",
      "understand",
      "the",
      "nature",
      "of",
      "people",
      "one",
      "must",
      "be",
      "a",
      "prince",
      "and",
    ],
    id: "To understand the nature of the people one must be a prince, and to understand the nature of the prince, one must be of the people.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["since", "grows", "within", "beauty", "soul"],
    words: [
      "since",
      "love",
      "grows",
      "within",
      "you",
      "so",
      "beauty",
      "for",
      "is",
      "the",
      "of",
      "soul",
    ],
    id: "Since love grows within you, so beauty grows. For love is the beauty of the soul.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["province", "knowledge", "privilege", "wisdom", "listen"],
    words: [
      "it",
      "is",
      "the",
      "province",
      "of",
      "knowledge",
      "to",
      "speak",
      "and",
      "privilege",
      "wisdom",
      "listen",
    ],
    id: "It is the province of knowledge to speak, and it is the privilege of wisdom to listen.",
    author: "Oliver Wendell Holmes, Sr.",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["small", "world", "i", "wouldn't", "paint"],
    words: [
      "it's",
      "a",
      "small",
      "world",
      "but",
      "i",
      "wouldn't",
      "want",
      "to",
      "have",
      "paint",
      "it",
    ],
    id: "It's a small world, but I wouldn't want to have to paint it.",
    author: "Steven Wright",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["wise", "man", "sees", "what's", "born"],
    words: ["who", "is", "the", "wise", "man", "he", "sees", "what's", "going", "to", "be", "born"],
    id: "Who is the wise man? He who sees what's going to be born.",
    author: "King Solomon",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["sometimes", "ask", "god", "something", "asking"],
    words: [
      "sometimes",
      "you",
      "ask",
      "god",
      "for",
      "something",
      "and",
      "don't",
      "know",
      "what",
      "you're",
      "asking",
    ],
    id: "Sometimes you ask God for something and you don't know what you're asking.",
    author: "Mahalia Jackson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["rough", "road", "leads", "heights", "greatness"],
    words: [
      "it",
      "is",
      "a",
      "rough",
      "road",
      "that",
      "leads",
      "to",
      "the",
      "heights",
      "of",
      "greatness",
    ],
    id: "It is a rough road that leads to the heights of greatness.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["fashion", "world", "avoid", "cost", "encounter"],
    words: [
      "the",
      "fashion",
      "of",
      "world",
      "is",
      "to",
      "avoid",
      "cost",
      "and",
      "you",
      "encounter",
      "it",
    ],
    id: "The fashion of the world is to avoid cost, and you encounter it.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "too", "short", "things", "doing"],
    words: [
      "life",
      "is",
      "too",
      "short",
      "to",
      "do",
      "the",
      "things",
      "you",
      "don't",
      "love",
      "doing",
    ],
    id: "Life is too short to do the things you don't love doing.",
    author: "Bruce Dickinson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["farmer", "optimist", "or", "wouldn't", "still"],
    words: [
      "the",
      "farmer",
      "has",
      "to",
      "be",
      "an",
      "optimist",
      "or",
      "he",
      "wouldn't",
      "still",
      "a",
    ],
    id: "The farmer has to be an optimist or he wouldn't still be a farmer.",
    author: "Will Rogers",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "family", "visiting", "friends", "as"],
    words: [
      "my",
      "family",
      "is",
      "very",
      "good",
      "about",
      "visiting",
      "me",
      "and",
      "other",
      "friends",
      "as",
      "well",
    ],
    id: "My family is very good about visiting me, and other friends as well.",
    author: "Billy Graham",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "all", "men", "why", "suffer"],
    words: ["god", "is", "in", "all", "men", "but", "are", "not", "that", "why", "we", "suffer"],
    id: "God is in all men, but all men are not in God; that is why we suffer.",
    author: "Ramakrishna",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["wishes", "rich", "will", "hanged", "year"],
    words: ["he", "who", "wishes", "to", "be", "rich", "in", "a", "day", "will", "hanged", "year"],
    id: "He who wishes to be rich in a day will be hanged in a year.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["hardest", "thing", "front", "your", "eyes"],
    words: [
      "the",
      "hardest",
      "thing",
      "to",
      "see",
      "is",
      "what",
      "in",
      "front",
      "of",
      "your",
      "eyes",
    ],
    id: "The hardest thing to see is what is in front of your eyes.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["shall", "little", "way", "require", "understand"],
    words: [
      "we",
      "shall",
      "see",
      "but",
      "a",
      "little",
      "way",
      "if",
      "require",
      "to",
      "understand",
      "what",
    ],
    id: "We shall see but a little way if we require to understand what we see.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["foundation", "family", "-", "all", "begins"],
    words: [
      "the",
      "foundation",
      "of",
      "family",
      "-",
      "that's",
      "where",
      "it",
      "all",
      "begins",
      "for",
      "me",
    ],
    id: "The foundation of family - that's where it all begins for me.",
    author: "Faith Hill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["reality", "crutch", "can't", "cope", "drugs"],
    words: [
      "reality",
      "is",
      "just",
      "a",
      "crutch",
      "for",
      "people",
      "who",
      "can't",
      "cope",
      "with",
      "drugs",
    ],
    id: "Reality is just a crutch for people who can't cope with drugs.",
    author: "Robin Williams",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "feel", "chance", "role", "model"],
    words: ["i", "just", "feel", "like", "have", "a", "chance", "to", "be", "role", "model", "now"],
    id: "I just feel like I have a chance to be a role model now.",
    author: "Kane Brown",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["imperfect", "wired", "struggle", "worthy", "belonging"],
    words: [
      "you're",
      "imperfect",
      "and",
      "wired",
      "for",
      "struggle",
      "but",
      "you",
      "are",
      "worthy",
      "of",
      "love",
      "belonging",
    ],
    id: "You're imperfect, and you're wired for struggle, but you are worthy of love and belonging.",
    author: "Brene Brown",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["proves", "movies", "100", "million", "dollars"],
    words: [
      "it",
      "just",
      "proves",
      "good",
      "movies",
      "don't",
      "need",
      "100",
      "million",
      "dollars",
      "to",
      "be",
    ],
    id: "It just proves good movies don't need 100 million dollars to be good.",
    author: "Emily Blunt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["fixed", "star", "change", "his", "mind"],
    words: ["he", "who", "is", "fixed", "to", "a", "star", "does", "not", "change", "his", "mind"],
    id: "He who is fixed to a star does not change his mind.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "ease", "comfort", "dare", "endure"],
    words: [
      "this",
      "is",
      "no",
      "time",
      "for",
      "ease",
      "and",
      "comfort",
      "it",
      "to",
      "dare",
      "endure",
    ],
    id: "This is no time for ease and comfort. It is time to dare and endure.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["luck", "assisted", "all", "chance", "wise"],
    words: [
      "luck",
      "can",
      "be",
      "assisted",
      "it",
      "is",
      "not",
      "all",
      "chance",
      "with",
      "the",
      "wise",
    ],
    id: "Luck can be assisted. It is not all chance with the wise.",
    author: "Baltasar Gracian",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["hard", "obey", "when", "one", "whom"],
    words: ["it", "is", "not", "hard", "to", "obey", "when", "we", "love", "the", "one", "whom"],
    id: "It is not hard to obey when we love the one whom we obey.",
    author: "Saint Ignatius",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "free", "choice", "extent", "rational"],
    words: [
      "a",
      "man",
      "has",
      "free",
      "choice",
      "to",
      "the",
      "extent",
      "that",
      "he",
      "is",
      "rational",
    ],
    id: "A man has free choice to the extent that he is rational.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "patient", "stupidity", "those", "proud"],
    words: [
      "i",
      "am",
      "patient",
      "with",
      "stupidity",
      "but",
      "not",
      "those",
      "who",
      "are",
      "proud",
      "of",
      "it",
    ],
    id: "I am patient with stupidity but not with those who are proud of it.",
    author: "Edith Sitwell",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "happiness", "gun", "horse", "wife"],
    words: [
      "all",
      "you",
      "need",
      "for",
      "happiness",
      "is",
      "a",
      "good",
      "gun",
      "horse",
      "and",
      "wife",
    ],
    id: "All you need for happiness is a good gun, a good horse, and a good wife.",
    author: "Daniel Boone",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["first", "step", "towards", "knowledge", "ignorant"],
    words: [
      "the",
      "first",
      "step",
      "towards",
      "knowledge",
      "is",
      "to",
      "know",
      "that",
      "we",
      "are",
      "ignorant",
    ],
    id: "The first step towards knowledge is to know that we are ignorant.",
    author: "Richard Cecil",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["longer", "soon", "as", "wish", "happier"],
    words: [
      "we",
      "are",
      "no",
      "longer",
      "happy",
      "so",
      "soon",
      "as",
      "wish",
      "to",
      "be",
      "happier",
    ],
    id: "We are no longer happy so soon as we wish to be happier.",
    author: "Walter Savage Landor",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["movies", "art", "form", "available", "masses"],
    words: [
      "movies",
      "are",
      "an",
      "art",
      "form",
      "that",
      "is",
      "very",
      "available",
      "to",
      "the",
      "masses",
    ],
    id: "Movies are an art form that is very available to the masses.",
    author: "Richard King",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["time", "right", "when", "will", "over"],
    words: [
      "if",
      "you",
      "don't",
      "have",
      "time",
      "to",
      "do",
      "it",
      "right",
      "when",
      "will",
      "over",
    ],
    id: "If you don't have time to do it right, when will you have time to do it over?",
    author: "John Wooden",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["enough", "i", "first", "last", "word"],
    words: [
      "for",
      "me",
      "it's",
      "enough",
      "that",
      "i",
      "have",
      "the",
      "first",
      "and",
      "last",
      "word",
    ],
    id: "For me, it's enough that I have the first and last word.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["acquire", "lot", "knowledge", "without", "ever"],
    words: [
      "you",
      "can",
      "acquire",
      "a",
      "lot",
      "of",
      "knowledge",
      "without",
      "ever",
      "going",
      "to",
      "school",
    ],
    id: "You can acquire a lot of knowledge without ever going to school.",
    author: "William Glasser",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "gave", "memory", "might", "december"],
    words: [
      "god",
      "gave",
      "us",
      "memory",
      "so",
      "that",
      "we",
      "might",
      "have",
      "roses",
      "in",
      "december",
    ],
    id: "God gave us memory so that we might have roses in December.",
    author: "James M. Barrie",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["times", "change", "get", "left", "behind"],
    words: [
      "the",
      "times",
      "change",
      "and",
      "if",
      "you",
      "don't",
      "with",
      "them",
      "get",
      "left",
      "behind",
    ],
    id: "The times change, and if you don't change with them, you get left behind.",
    author: "Bradley Walsh",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["age", "case", "mind", "over", "matter"],
    words: ["age", "is", "a", "case", "of", "mind", "over", "matter", "if", "you", "don't", "it"],
    id: "Age is a case of mind over matter. If you don't mind, it don't matter.",
    author: "Satchel Paige",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["hollywood", "here", "stay", "keep", "momentum"],
    words: [
      "hollywood",
      "is",
      "here",
      "to",
      "stay",
      "and",
      "we",
      "have",
      "keep",
      "the",
      "momentum",
      "going",
    ],
    id: "Hollywood is here to stay, and we have to keep the momentum going.",
    author: "Kevin de Leon",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "will", "feedback", "how", "improve"],
    words: [
      "we",
      "all",
      "need",
      "people",
      "who",
      "will",
      "give",
      "us",
      "feedback",
      "that's",
      "how",
      "improve",
    ],
    id: "We all need people who will give us feedback. That's how we improve.",
    author: "Bill Gates",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["anything", "certain", "i", "myself", "marxist"],
    words: [
      "if",
      "anything",
      "is",
      "certain",
      "it",
      "that",
      "i",
      "myself",
      "am",
      "not",
      "a",
      "marxist",
    ],
    id: "If anything is certain, it is that I myself am not a Marxist.",
    author: "Karl Marx",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "all", "emotions", "everyone", "appears"],
    words: [
      "i",
      "have",
      "all",
      "the",
      "emotions",
      "that",
      "everyone",
      "has",
      "it",
      "just",
      "appears",
      "don't",
    ],
    id: "I have all the emotions that everyone has; it just appears that I don't.",
    author: "Steven Wright",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["from", "my", "example", "despise", "gods"],
    words: [
      "from",
      "my",
      "example",
      "learn",
      "to",
      "be",
      "just",
      "and",
      "not",
      "despise",
      "the",
      "gods",
    ],
    id: "From my example learn to be just, and not to despise the gods.",
    author: "Virgil",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["single", "person", "power", "change", "world"],
    words: [
      "every",
      "single",
      "person",
      "has",
      "the",
      "power",
      "to",
      "change",
      "world",
      "and",
      "help",
      "people",
    ],
    id: "Every single person has the power to change the world and help people.",
    author: "Laura Marano",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["government", "association", "men", "violence", "rest"],
    words: [
      "government",
      "is",
      "an",
      "association",
      "of",
      "men",
      "who",
      "do",
      "violence",
      "to",
      "the",
      "rest",
      "us",
    ],
    id: "Government is an association of men who do violence to the rest of us.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "something", "comes", "active", "process"],
    words: [
      "happiness",
      "is",
      "not",
      "something",
      "that",
      "just",
      "comes",
      "to",
      "you",
      "it's",
      "an",
      "active",
      "process",
    ],
    id: "Happiness is not something that just comes to you. It's an active process.",
    author: "Kate Hudson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["oh", "i", "shoes", "i'm", "fetishist"],
    words: ["oh", "yes", "i", "love", "to", "do", "shoes", "i'm", "not", "a", "fetishist", "but"],
    id: "Oh yes, I love to do shoes. I'm not a fetishist but I love to do shoes.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["show", "up", "world", "anything", "happen"],
    words: [
      "you",
      "have",
      "to",
      "show",
      "up",
      "in",
      "the",
      "world",
      "cup",
      "and",
      "anything",
      "can",
      "happen",
    ],
    id: "You have to show up in the World Cup, and in the World Cup anything can happen.",
    author: "Lionel Messi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["great", "happiness", "praised", "most", "praiseworthy"],
    words: [
      "it",
      "is",
      "great",
      "happiness",
      "to",
      "be",
      "praised",
      "of",
      "them",
      "who",
      "are",
      "most",
      "praiseworthy",
    ],
    id: "It is great happiness to be praised of them who are most praiseworthy.",
    author: "Philip Sidney",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["too", "busy", "doing", "finds", "time"],
    words: ["he", "who", "is", "too", "busy", "doing", "good", "finds", "no", "time", "to", "be"],
    id: "He who is too busy doing good finds no time to be good.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["excited", "welcoming", "member", "family", "daughter"],
    words: [
      "we",
      "are",
      "very",
      "excited",
      "about",
      "welcoming",
      "a",
      "new",
      "member",
      "of",
      "the",
      "family",
      "daughter",
    ],
    id: "We are very excited about welcoming a new member of the family, a daughter!",
    author: "Michael Weatherly",
    grammar: null,
    word_count: 13,
    all_intersection_count: 8,
    new_words_count: 5,
  },
  {
    row_new_words: ["come", "up", "movies", "great", "compliment"],
    words: [
      "and",
      "if",
      "people",
      "come",
      "up",
      "say",
      "they",
      "like",
      "the",
      "movies",
      "you're",
      "in",
      "it's",
      "a",
      "great",
      "compliment",
    ],
    id: "And if people come up and say they like the movies you're in, it's a great compliment.",
    author: "Jason Statham",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["advertising", "art", "convincing", "spend", "something"],
    words: [
      "advertising",
      "is",
      "the",
      "art",
      "of",
      "convincing",
      "people",
      "to",
      "spend",
      "money",
      "they",
      "don't",
      "have",
      "for",
      "something",
      "need",
    ],
    id: "Advertising is the art of convincing people to spend money they don't have for something they don't need.",
    author: "Will Rogers",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "inward", "outward", "depend", "on"],
    words: [
      "happiness",
      "is",
      "inward",
      "and",
      "not",
      "outward",
      "so",
      "it",
      "does",
      "depend",
      "on",
      "what",
      "we",
      "have",
      "but",
      "are",
    ],
    id: "Happiness is inward, and not outward; and so, it does not depend on what we have, but on what we are.",
    author: "Henry Van Dyke",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["may", "freedom", "as", "right", "opportunity"],
    words: [
      "may",
      "we",
      "think",
      "of",
      "freedom",
      "not",
      "as",
      "the",
      "right",
      "to",
      "do",
      "please",
      "but",
      "opportunity",
      "what",
      "is",
    ],
    id: "May we think of freedom, not as the right to do as we please, but as the opportunity to do what is right.",
    author: "Peter Marshall",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["disneyland", "didn't", "into", "idea", "making"],
    words: [
      "disneyland",
      "is",
      "a",
      "work",
      "of",
      "love",
      "we",
      "didn't",
      "go",
      "into",
      "just",
      "with",
      "the",
      "idea",
      "making",
      "money",
    ],
    id: "Disneyland is a work of love. We didn't go into Disneyland just with the idea of making money.",
    author: "Walt Disney",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["world", "take", "up", "makes", "rich"],
    words: [
      "in",
      "this",
      "world",
      "it",
      "is",
      "not",
      "what",
      "we",
      "take",
      "up",
      "but",
      "give",
      "that",
      "makes",
      "us",
      "rich",
    ],
    id: "In this world it is not what we take up, but what we give up, that makes us rich.",
    author: "Henry Ward Beecher",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "happen", "vacuum", "only", "as"],
    words: [
      "success",
      "doesn't",
      "happen",
      "in",
      "a",
      "vacuum",
      "you're",
      "only",
      "as",
      "good",
      "the",
      "people",
      "you",
      "work",
      "with",
      "and",
      "for",
    ],
    id: "Success doesn't happen in a vacuum. You're only as good as the people you work with and the people you work for.",
    author: "Casey Kasem",
    grammar: null,
    word_count: 17,
    all_intersection_count: 12,
    new_words_count: 5,
  },
  {
    row_new_words: ["age", "lot", "patience", "movie", "business"],
    words: [
      "in",
      "this",
      "day",
      "and",
      "age",
      "you",
      "need",
      "a",
      "lot",
      "of",
      "patience",
      "if",
      "are",
      "the",
      "movie",
      "business",
    ],
    id: "In this day and age, you need a lot of patience if you are in the movie business.",
    author: "Brett Ratner",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["successful", "consistency", "you've", "got", "chance"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "be",
      "successful",
      "need",
      "consistency",
      "and",
      "don't",
      "have",
      "it",
      "you've",
      "got",
      "no",
      "chance",
    ],
    id: "If you want to be successful, you need consistency and if you don't have it, you've got no chance.",
    author: "Paul Merson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "husband", "honours", "as", "woman"],
    words: [
      "i",
      "do",
      "not",
      "want",
      "a",
      "husband",
      "who",
      "honours",
      "me",
      "as",
      "queen",
      "if",
      "he",
      "does",
      "love",
      "woman",
    ],
    id: "I do not want a husband who honours me as a queen, if he does not love me as a woman.",
    author: "Elizabeth I",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["thank", "god", "life", "forgive", "enough"],
    words: [
      "thank",
      "you",
      "god",
      "for",
      "this",
      "good",
      "life",
      "and",
      "forgive",
      "us",
      "if",
      "we",
      "do",
      "not",
      "love",
      "it",
      "enough",
    ],
    id: "Thank you, God, for this good life and forgive us if we do not love it enough.",
    author: "Garrison Keillor",
    grammar: null,
    word_count: 17,
    all_intersection_count: 12,
    new_words_count: 5,
  },
  {
    row_new_words: ["react", "fear", "teach", "sunday", "true"],
    words: [
      "people",
      "react",
      "to",
      "fear",
      "not",
      "love",
      "they",
      "don't",
      "teach",
      "that",
      "in",
      "sunday",
      "school",
      "but",
      "it's",
      "true",
    ],
    id: "People react to fear, not love; they don't teach that in Sunday School, but it's true.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["hard", "chance", "until", "prove", "worthy"],
    words: [
      "but",
      "it's",
      "hard",
      "for",
      "people",
      "to",
      "give",
      "you",
      "a",
      "chance",
      "until",
      "prove",
      "them",
      "that",
      "you're",
      "worthy",
    ],
    id: "But it's hard for people to give you a chance until you prove to them that you're worthy.",
    author: "Hailie Deegan",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["character", "reputation", "shadow", "real", "thing"],
    words: [
      "character",
      "is",
      "like",
      "a",
      "tree",
      "and",
      "reputation",
      "shadow",
      "the",
      "what",
      "we",
      "think",
      "of",
      "it",
      "real",
      "thing",
    ],
    id: "Character is like a tree and reputation like a shadow. The shadow is what we think of it; the tree is the real thing.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["believe", "gospels", "reject", "gospel", "yourself"],
    words: [
      "if",
      "you",
      "believe",
      "what",
      "like",
      "in",
      "the",
      "gospels",
      "and",
      "reject",
      "don't",
      "it",
      "is",
      "not",
      "gospel",
      "but",
      "yourself",
    ],
    id: "If you believe what you like in the gospels, and reject what you don't like, it is not the gospel you believe, but yourself.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 17,
    all_intersection_count: 12,
    new_words_count: 5,
  },
  {
    row_new_words: ["world", "coming", "end", "already", "australia"],
    words: [
      "don't",
      "worry",
      "about",
      "the",
      "world",
      "coming",
      "to",
      "an",
      "end",
      "today",
      "it",
      "is",
      "already",
      "tomorrow",
      "in",
      "australia",
    ],
    id: "Don't worry about the world coming to an end today. It is already tomorrow in Australia.",
    author: "Charles M. Schulz",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "haven't", "forgiven", "really", "awaken"],
    words: [
      "if",
      "there",
      "are",
      "people",
      "you",
      "haven't",
      "forgiven",
      "you're",
      "not",
      "going",
      "to",
      "really",
      "awaken",
      "have",
      "let",
      "go",
    ],
    id: "If there are people you haven't forgiven, you're not going to really awaken. You have to let go.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 16,
    all_intersection_count: 11,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "anything", "man", "i", "chance"],
    words: [
      "if",
      "there",
      "is",
      "anything",
      "that",
      "a",
      "man",
      "can",
      "do",
      "well",
      "i",
      "say",
      "let",
      "him",
      "it",
      "give",
      "chance",
    ],
    id: "If there is anything that a man can do well, I say let him do it. Give him a chance.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 17,
    all_intersection_count: 12,
    new_words_count: 5,
  },
  {
    row_new_words: ["once", "settle", "second", "happens", "life"],
    words: [
      "once",
      "you",
      "say",
      "you're",
      "going",
      "to",
      "settle",
      "for",
      "second",
      "that's",
      "what",
      "happens",
      "in",
      "life",
    ],
    id: "Once you say you're going to settle for second, that's what happens to you in life.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "i", "covers", "anymore", "because"],
    words: [
      "i'm",
      "happy",
      "that",
      "i",
      "don't",
      "have",
      "to",
      "do",
      "covers",
      "anymore",
      "because",
      "that's",
      "not",
      "what",
    ],
    id: "I'm happy that I don't have to do covers anymore, because that's not what I do.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "western", "civilization", "would", "idea"],
    words: [
      "what",
      "do",
      "i",
      "think",
      "of",
      "western",
      "civilization",
      "it",
      "would",
      "be",
      "a",
      "very",
      "good",
      "idea",
    ],
    id: "What do I think of Western civilization? I think it would be a very good idea.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["play", "patience", "at", "mercy", "cards"],
    words: [
      "if",
      "you're",
      "going",
      "to",
      "just",
      "play",
      "with",
      "patience",
      "be",
      "at",
      "the",
      "mercy",
      "of",
      "cards",
    ],
    id: "If you're going to just play with patience, you're going to be at the mercy of the cards.",
    author: "Phil Hellmuth",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["satire", "dead", "alive", "living", "white"],
    words: [
      "people",
      "say",
      "satire",
      "is",
      "dead",
      "it's",
      "not",
      "alive",
      "and",
      "living",
      "in",
      "the",
      "white",
      "house",
    ],
    id: "People say satire is dead. It's not dead; it's alive and living in the White House.",
    author: "Robin Williams",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["there's", "something", "boring", "office", "living"],
    words: [
      "there's",
      "something",
      "boring",
      "about",
      "people",
      "who",
      "have",
      "to",
      "go",
      "an",
      "office",
      "for",
      "a",
      "living",
    ],
    id: "There's something boring about people who have to go to an office for a living.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "better", "yesterday", "all", "time"],
    words: [
      "you",
      "can't",
      "have",
      "a",
      "better",
      "tomorrow",
      "if",
      "are",
      "thinking",
      "about",
      "yesterday",
      "all",
      "the",
      "time",
    ],
    id: "You can't have a better tomorrow if you are thinking about yesterday all the time.",
    author: "Charles Kettering",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "never", "because", "disgusting", "things"],
    words: [
      "i",
      "never",
      "drink",
      "water",
      "because",
      "of",
      "the",
      "disgusting",
      "things",
      "that",
      "fish",
      "do",
      "in",
      "it",
    ],
    id: "I never drink water because of the disgusting things that fish do in it.",
    author: "Unknown",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["outspoken", "easy", "when", "complete", "truth"],
    words: [
      "to",
      "be",
      "outspoken",
      "is",
      "easy",
      "when",
      "you",
      "do",
      "not",
      "wait",
      "speak",
      "the",
      "complete",
      "truth",
    ],
    id: "To be outspoken is easy when you do not wait to speak the complete truth.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'm", "change", "world", "-", "all"],
    words: [
      "i'm",
      "not",
      "going",
      "to",
      "change",
      "the",
      "world",
      "you're",
      "but",
      "we",
      "can",
      "help",
      "-",
      "all",
    ],
    id: "I'm not going to change the world. You're not going to change the world. But we can help - we can all help.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "as", "woman", "bad", "dares"],
    words: [
      "a",
      "man",
      "is",
      "as",
      "good",
      "he",
      "has",
      "to",
      "be",
      "and",
      "woman",
      "bad",
      "she",
      "dares",
    ],
    id: "A man is as good as he has to be, and a woman as bad as she dares.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["when", "recognized", "strive", "worthy", "recognition"],
    words: [
      "don't",
      "worry",
      "when",
      "you",
      "are",
      "not",
      "recognized",
      "but",
      "strive",
      "to",
      "be",
      "worthy",
      "of",
      "recognition",
    ],
    id: "Don't worry when you are not recognized, but strive to be worthy of recognition.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "read", "dangerous", "interpret", "reading"],
    words: [
      "it's",
      "good",
      "to",
      "know",
      "how",
      "read",
      "but",
      "dangerous",
      "and",
      "not",
      "interpret",
      "what",
      "you're",
      "reading",
    ],
    id: "It's good to know how to read, but it's dangerous to know how to read and not how to interpret what you're reading.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["something", "because", "stands", "chance", "succeed"],
    words: [
      "work",
      "for",
      "something",
      "because",
      "it",
      "is",
      "good",
      "not",
      "just",
      "stands",
      "a",
      "chance",
      "to",
      "succeed",
    ],
    id: "Work for something because it is good, not just because it stands a chance to succeed.",
    author: "Vaclav Havel",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["scientists", "spite", "system", "typically", "because"],
    words: [
      "people",
      "who",
      "are",
      "scientists",
      "today",
      "in",
      "spite",
      "of",
      "the",
      "system",
      "typically",
      "not",
      "because",
      "it",
    ],
    id: "People who are scientists today are scientists in spite of the system, typically, not because of it.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["starts", "my", "family", "drives", "smiling"],
    words: [
      "it",
      "starts",
      "with",
      "my",
      "family",
      "that",
      "drives",
      "me",
      "every",
      "day",
      "just",
      "seeing",
      "them",
      "smiling",
    ],
    id: "It starts with my family. That drives me every day, just seeing them smiling.",
    author: "Giannis Antetokounmpo",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "measure", "sorrow", "sometimes", "awakens"],
    words: [
      "every",
      "life",
      "has",
      "a",
      "measure",
      "of",
      "sorrow",
      "and",
      "sometimes",
      "this",
      "is",
      "what",
      "awakens",
      "us",
    ],
    id: "Every life has a measure of sorrow, and sometimes this is what awakens us.",
    author: "Steven Tyler",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "defeat", "grope", "things", "hard"],
    words: [
      "i",
      "think",
      "in",
      "defeat",
      "you",
      "grope",
      "for",
      "things",
      "that",
      "are",
      "happy",
      "and",
      "it's",
      "hard",
    ],
    id: "I think in defeat you grope for things that are happy, and it's hard.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "already", "can't", "tell", "em"],
    words: [
      "there",
      "are",
      "some",
      "people",
      "who",
      "if",
      "they",
      "don't",
      "already",
      "know",
      "you",
      "can't",
      "tell",
      "em",
    ],
    id: "There are some people who, if they don't already know, you can't tell 'em.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["proof", "kind", "when", "because", "proven"],
    words: [
      "a",
      "proof",
      "is",
      "what",
      "kind",
      "of",
      "it's",
      "and",
      "when",
      "you",
      "have",
      "good",
      "because",
      "proven",
    ],
    id: "A proof is a proof. What kind of a proof? It's a proof. A proof is a proof. And when you have a good proof, it's because it's proven.",
    author: "Jean Chretien",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["alive", "whether", "sun's", "shining", "or"],
    words: [
      "every",
      "day",
      "is",
      "a",
      "good",
      "to",
      "be",
      "alive",
      "whether",
      "the",
      "sun's",
      "shining",
      "or",
      "not",
    ],
    id: "Every day is a good day to be alive, whether the sun's shining or not.",
    author: "Marty Robbins",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["how", "great", "country", "i", "russia"],
    words: [
      "if",
      "you",
      "don't",
      "know",
      "how",
      "great",
      "this",
      "country",
      "is",
      "i",
      "someone",
      "who",
      "does",
      "russia",
    ],
    id: "If you don't know how great this country is, I know someone who does; Russia.",
    author: "Robert Frost",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["ones", "crazy", "enough", "change", "world"],
    words: [
      "the",
      "ones",
      "who",
      "are",
      "crazy",
      "enough",
      "to",
      "think",
      "that",
      "they",
      "can",
      "change",
      "world",
      "do",
    ],
    id: "The ones who are crazy enough to think that they can change the world are the ones who do.",
    author: "John McAfee",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "succeed", "line", "endeavor", "which"],
    words: [
      "no",
      "man",
      "can",
      "succeed",
      "in",
      "a",
      "line",
      "of",
      "endeavor",
      "which",
      "he",
      "does",
      "not",
      "like",
    ],
    id: "No man can succeed in a line of endeavor which he does not like.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["dalai", "lama", "tell", "life's", "change"],
    words: [
      "you",
      "don't",
      "have",
      "to",
      "be",
      "the",
      "dalai",
      "lama",
      "tell",
      "people",
      "that",
      "life's",
      "about",
      "change",
    ],
    id: "You don't have to be the Dalai Lama to tell people that life's about change.",
    author: "John Cleese",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["allowed", "laugh", "heaven", "i", "there"],
    words: [
      "if",
      "you",
      "are",
      "not",
      "allowed",
      "to",
      "laugh",
      "in",
      "heaven",
      "i",
      "don't",
      "want",
      "go",
      "there",
    ],
    id: "If you are not allowed to laugh in heaven, I don't want to go there.",
    author: "Martin Luther",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["power", "being", "lady", "tell", "aren't"],
    words: [
      "power",
      "is",
      "like",
      "being",
      "a",
      "lady",
      "if",
      "you",
      "have",
      "to",
      "tell",
      "people",
      "are",
      "aren't",
    ],
    id: "Power is like being a lady... if you have to tell people you are, you aren't.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "exists", "only", "lot", "share"],
    words: [
      "happiness",
      "exists",
      "only",
      "if",
      "you",
      "have",
      "a",
      "lot",
      "of",
      "people",
      "to",
      "share",
      "it",
      "with",
    ],
    id: "Happiness exists only if you have a lot of people to share it with.",
    author: "Ranbir Kapoor",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["trouble", "our", "times", "future", "used"],
    words: [
      "the",
      "trouble",
      "with",
      "our",
      "times",
      "is",
      "that",
      "future",
      "not",
      "what",
      "it",
      "used",
      "to",
      "be",
    ],
    id: "The trouble with our times is that the future is not what it used to be.",
    author: "Paul Valery",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["anyone", "able", "perform", "on", "bad"],
    words: [
      "anyone",
      "can",
      "have",
      "a",
      "good",
      "day",
      "but",
      "you",
      "to",
      "be",
      "able",
      "perform",
      "on",
      "bad",
    ],
    id: "Anyone can have a good day, but you have to be able to perform on a bad day.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["enough", "mind", "main", "thing", "use"],
    words: [
      "it",
      "is",
      "not",
      "enough",
      "to",
      "have",
      "a",
      "good",
      "mind",
      "the",
      "main",
      "thing",
      "use",
      "well",
    ],
    id: "It is not enough to have a good mind; the main thing is to use it well.",
    author: "Rene Descartes",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["repeatedly", "excellence", "then", "act", "habit"],
    words: [
      "we",
      "are",
      "what",
      "repeatedly",
      "do",
      "excellence",
      "then",
      "is",
      "not",
      "an",
      "act",
      "but",
      "a",
      "habit",
    ],
    id: "We are what we repeatedly do. Excellence, then, is not an act, but a habit.",
    author: "Will Durant",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "better", "than", "trying", "reform"],
    words: [
      "i",
      "think",
      "that",
      "am",
      "better",
      "than",
      "the",
      "people",
      "who",
      "are",
      "trying",
      "to",
      "reform",
      "me",
    ],
    id: "I think that I am better than the people who are trying to reform me.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["home", "place", "when", "there", "take"],
    words: [
      "home",
      "is",
      "the",
      "place",
      "where",
      "when",
      "you",
      "have",
      "to",
      "go",
      "there",
      "they",
      "take",
      "in",
    ],
    id: "Home is the place where, when you have to go there, they have to take you in.",
    author: "Robert Frost",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["birth", "family", "all", "-", "extended"],
    words: [
      "you",
      "don't",
      "have",
      "to",
      "give",
      "birth",
      "someone",
      "a",
      "family",
      "we're",
      "all",
      "-",
      "an",
      "extended",
    ],
    id: "You don't have to give birth to someone to have a family. We're all family - an extended family.",
    author: "Sandra Bullock",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["always", "meet", "each", "smile", "beginning"],
    words: [
      "let",
      "us",
      "always",
      "meet",
      "each",
      "other",
      "with",
      "smile",
      "for",
      "the",
      "is",
      "beginning",
      "of",
      "love",
    ],
    id: "Let us always meet each other with smile, for the smile is the beginning of love.",
    author: "Mother Teresa",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["hour", "night", "unspeakably", "perfect", "miracle"],
    words: [
      "to",
      "me",
      "every",
      "hour",
      "of",
      "the",
      "day",
      "and",
      "night",
      "is",
      "an",
      "unspeakably",
      "perfect",
      "miracle",
    ],
    id: "To me, every hour of the day and night is an unspeakably perfect miracle.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["unlearn", "learned", "most", "necessary", "kind"],
    words: [
      "not",
      "to",
      "unlearn",
      "what",
      "you",
      "have",
      "learned",
      "is",
      "the",
      "most",
      "necessary",
      "kind",
      "of",
      "learning",
    ],
    id: "Not to unlearn what you have learned is the most necessary kind of learning.",
    author: "Antisthenes",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["highest", "reach", "injustice", "deemed", "when"],
    words: [
      "the",
      "highest",
      "reach",
      "of",
      "injustice",
      "is",
      "to",
      "be",
      "deemed",
      "just",
      "when",
      "you",
      "are",
      "not",
    ],
    id: "The highest reach of injustice is to be deemed just when you are not.",
    author: "Plato",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["scholar", "cherishes", "comfort", "fit", "deemed"],
    words: [
      "a",
      "scholar",
      "who",
      "cherishes",
      "the",
      "love",
      "of",
      "comfort",
      "is",
      "not",
      "fit",
      "to",
      "be",
      "deemed",
    ],
    id: "A scholar who cherishes the love of comfort is not fit to be deemed a scholar.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["fair", "ask", "others", "willing", "yourself"],
    words: [
      "it",
      "is",
      "not",
      "fair",
      "to",
      "ask",
      "of",
      "others",
      "what",
      "you",
      "are",
      "willing",
      "do",
      "yourself",
    ],
    id: "It is not fair to ask of others what you are not willing to do yourself.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "thing", "business", "entertainment", "patience"],
    words: [
      "the",
      "one",
      "thing",
      "about",
      "business",
      "of",
      "entertainment",
      "is",
      "that",
      "you",
      "have",
      "to",
      "learn",
      "patience",
    ],
    id: "The one thing about the business of entertainment is that you have to learn patience.",
    author: "Kevin Hart",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "little", "chocolate", "then", "hurt"],
    words: [
      "all",
      "you",
      "need",
      "is",
      "love",
      "but",
      "a",
      "little",
      "chocolate",
      "now",
      "and",
      "then",
      "doesn't",
      "hurt",
    ],
    id: "All you need is love. But a little chocolate now and then doesn't hurt.",
    author: "Charles M. Schulz",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["ignorant", "person", "one", "found", "out"],
    words: [
      "an",
      "ignorant",
      "person",
      "is",
      "one",
      "who",
      "doesn't",
      "know",
      "what",
      "you",
      "have",
      "just",
      "found",
      "out",
    ],
    id: "An ignorant person is one who doesn't know what you have just found out.",
    author: "Will Rogers",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["death", "or", "pain", "dreaded", "fear"],
    words: [
      "it",
      "is",
      "not",
      "death",
      "or",
      "pain",
      "that",
      "to",
      "be",
      "dreaded",
      "but",
      "the",
      "fear",
      "of",
    ],
    id: "It is not death or pain that is to be dreaded, but the fear of pain or death.",
    author: "Epictetus",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["forgiveness", "person", "peace", "take", "opportunity"],
    words: [
      "forgiveness",
      "is",
      "not",
      "to",
      "give",
      "the",
      "other",
      "person",
      "peace",
      "for",
      "you",
      "take",
      "that",
      "opportunity",
    ],
    id: "Forgiveness is not to give the other person peace. Forgiveness is for you. Take that opportunity.",
    author: "Mackenzie Phillips",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["without", "things", "indispensable", "part", "happiness"],
    words: [
      "to",
      "be",
      "without",
      "some",
      "of",
      "the",
      "things",
      "you",
      "want",
      "is",
      "an",
      "indispensable",
      "part",
      "happiness",
    ],
    id: "To be without some of the things you want is an indispensable part of happiness.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["difficult", "at", "begins", "less", "begun"],
    words: [
      "it",
      "is",
      "difficult",
      "to",
      "know",
      "at",
      "what",
      "moment",
      "love",
      "begins",
      "less",
      "that",
      "has",
      "begun",
    ],
    id: "It is difficult to know at what moment love begins; it is less difficult to know that it has begun.",
    author: "Henry Wadsworth Longfellow",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["purity", "considers", "gift", "lover", "giver"],
    words: [
      "he",
      "who",
      "loves",
      "with",
      "purity",
      "considers",
      "not",
      "the",
      "gift",
      "of",
      "lover",
      "but",
      "love",
      "giver",
    ],
    id: "He who loves with purity considers not the gift of the lover, but the love of the giver.",
    author: "Thomas a Kempis",
    grammar: null,
    word_count: 14,
    all_intersection_count: 9,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "thought", "one", "should", "attitude"],
    words: [
      "i",
      "thought",
      "one",
      "should",
      "have",
      "the",
      "attitude",
      "of",
      "what",
      "do",
      "you",
      "care",
      "other",
      "people",
      "think",
    ],
    id: "I thought one should have the attitude of 'What do you care what other people think!'",
    author: "Richard P. Feynman",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "can't", "change", "their", "minds"],
    words: [
      "people",
      "are",
      "going",
      "to",
      "say",
      "what",
      "they",
      "want",
      "and",
      "think",
      "i",
      "can't",
      "change",
      "their",
      "minds",
    ],
    id: "People are going to say what they want to say and think what they want to think, and I can't change their minds.",
    author: "Hilary Duff",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["then", "pause", "our", "pursuit", "happiness"],
    words: [
      "now",
      "and",
      "then",
      "it's",
      "good",
      "to",
      "pause",
      "in",
      "our",
      "pursuit",
      "of",
      "happiness",
      "just",
      "be",
      "happy",
    ],
    id: "Now and then it's good to pause in our pursuit of happiness and just be happy.",
    author: "Guillaume Apollinaire",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["liberty", "power", "doing", "right", "ought"],
    words: [
      "liberty",
      "is",
      "not",
      "the",
      "power",
      "of",
      "doing",
      "what",
      "we",
      "like",
      "but",
      "right",
      "to",
      "do",
      "ought",
    ],
    id: "Liberty is not the power of doing what we like, but the right to do what we ought.",
    author: "John Dalberg-Acton",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["refusing", "ask", "when", "chance", "helpful"],
    words: [
      "refusing",
      "to",
      "ask",
      "for",
      "help",
      "when",
      "you",
      "need",
      "it",
      "is",
      "someone",
      "the",
      "chance",
      "be",
      "helpful",
    ],
    id: "Refusing to ask for help when you need it is refusing someone the chance to be helpful.",
    author: "Ric Ocasek",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["two-tier", "america", "those", "legal", "citizens"],
    words: [
      "we",
      "don't",
      "want",
      "two-tier",
      "people",
      "in",
      "america",
      "those",
      "who",
      "are",
      "legal",
      "but",
      "not",
      "citizens",
      "and",
    ],
    id: "We don't want two-tier people in America. Those who are legal but not citizens, and citizens.",
    author: "Joe Biden",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["physical", "psychical", "necessarily", "reality", "appears"],
    words: [
      "like",
      "the",
      "physical",
      "psychical",
      "is",
      "not",
      "necessarily",
      "in",
      "reality",
      "what",
      "it",
      "appears",
      "to",
      "us",
      "be",
    ],
    id: "Like the physical, the psychical is not necessarily in reality what it appears to us to be.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["advice", "always", "certain", "ignored", "reason"],
    words: [
      "good",
      "advice",
      "is",
      "always",
      "certain",
      "to",
      "be",
      "ignored",
      "but",
      "that's",
      "no",
      "reason",
      "not",
      "give",
      "it",
    ],
    id: "Good advice is always certain to be ignored, but that's no reason not to give it.",
    author: "Agatha Christie",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "change", "things", "drop", "ocean"],
    words: [
      "i",
      "don't",
      "know",
      "if",
      "you",
      "can",
      "change",
      "things",
      "but",
      "it's",
      "a",
      "drop",
      "in",
      "the",
      "ocean",
    ],
    id: "I don't know if you can change things, but it's a drop in the ocean.",
    author: "Julie Walters",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["beauty", "surrounds", "usually", "walking", "garden"],
    words: [
      "beauty",
      "surrounds",
      "us",
      "but",
      "usually",
      "we",
      "need",
      "to",
      "be",
      "walking",
      "in",
      "a",
      "garden",
      "know",
      "it",
    ],
    id: "Beauty surrounds us, but usually we need to be walking in a garden to know it.",
    author: "Rumi",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["mattresses", "couple", "em", "their", "birthday"],
    words: [
      "mattresses",
      "beautiful",
      "let's",
      "go",
      "buy",
      "a",
      "couple",
      "of",
      "give",
      "em",
      "to",
      "people",
      "for",
      "their",
      "birthday",
    ],
    id: "Mattresses! Beautiful! Let's go buy a couple of mattresses. Give 'em to people for their birthday.",
    author: "Lawrence Tierney",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "chief", "will", "inspire", "could"],
    words: [
      "our",
      "chief",
      "want",
      "is",
      "someone",
      "who",
      "will",
      "inspire",
      "us",
      "to",
      "be",
      "what",
      "we",
      "know",
      "could",
    ],
    id: "Our chief want is someone who will inspire us to be what we know we could be.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["prudent", "lies", "enemy", "will", "victorious"],
    words: [
      "he",
      "who",
      "is",
      "prudent",
      "and",
      "lies",
      "in",
      "wait",
      "for",
      "an",
      "enemy",
      "not",
      "will",
      "be",
      "victorious",
    ],
    id: "He who is prudent and lies in wait for an enemy who is not, will be victorious.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["unknown", "open", "heart", "trust", "hurts"],
    words: [
      "love",
      "is",
      "unknown",
      "to",
      "open",
      "the",
      "heart",
      "in",
      "trust",
      "they",
      "say",
      "hurts",
      "it",
      "doesn't",
      "have",
    ],
    id: "Love is unknown. To open the heart in trust is unknown. They say love hurts. It doesn't have to.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "gift", "others", "unconditional", "acceptance"],
    words: [
      "the",
      "greatest",
      "gift",
      "that",
      "you",
      "can",
      "give",
      "to",
      "others",
      "is",
      "of",
      "unconditional",
      "love",
      "and",
      "acceptance",
    ],
    id: "The greatest gift that you can give to others is the gift of unconditional love and acceptance.",
    author: "Brian Tracy",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "simple", "everyday", "miracle", "aware"],
    words: [
      "happiness",
      "is",
      "a",
      "simple",
      "everyday",
      "miracle",
      "like",
      "water",
      "and",
      "we",
      "are",
      "not",
      "aware",
      "of",
      "it",
    ],
    id: "Happiness is a simple everyday miracle, like water, and we are not aware of it.",
    author: "Nikos Kazantzakis",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["generous", "both", "won't", "hunt", "happiness"],
    words: [
      "be",
      "happy",
      "with",
      "what",
      "you",
      "have",
      "and",
      "are",
      "generous",
      "both",
      "won't",
      "to",
      "hunt",
      "for",
      "happiness",
    ],
    id: "Be happy with what you have and are, be generous with both, and you won't have to hunt for happiness.",
    author: "William E. Gladstone",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["at", "center", "your", "being", "answer"],
    words: [
      "at",
      "the",
      "center",
      "of",
      "your",
      "being",
      "you",
      "have",
      "answer",
      "know",
      "who",
      "are",
      "and",
      "what",
      "want",
    ],
    id: "At the center of your being you have the answer; you know who you are and you know what you want.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["chiefest", "point", "happiness", "man", "willing"],
    words: [
      "it",
      "is",
      "the",
      "chiefest",
      "point",
      "of",
      "happiness",
      "that",
      "a",
      "man",
      "willing",
      "to",
      "be",
      "what",
      "he",
    ],
    id: "It is the chiefest point of happiness that a man is willing to be what he is.",
    author: "Desiderius Erasmus",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["my", "body", "lunch", "dinner", "i"],
    words: [
      "my",
      "body",
      "is",
      "like",
      "breakfast",
      "lunch",
      "and",
      "dinner",
      "i",
      "don't",
      "think",
      "about",
      "it",
      "just",
      "have",
    ],
    id: "My body is like breakfast, lunch, and dinner. I don't think about it, I just have it.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "key", "happiness", "you'll", "successful"],
    words: [
      "success",
      "is",
      "not",
      "the",
      "key",
      "to",
      "happiness",
      "if",
      "you",
      "love",
      "what",
      "do",
      "you'll",
      "be",
      "successful",
    ],
    id: "Success is not the key to happiness. Happiness is the key to success. If you love what you do, you'll be successful.",
    author: "Tyga",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["must", "mad", "things", "world", "god"],
    words: [
      "if",
      "you",
      "must",
      "be",
      "mad",
      "it",
      "not",
      "for",
      "the",
      "things",
      "of",
      "world",
      "with",
      "love",
      "god",
    ],
    id: "If you must be mad, be it not for the things of the world. Be mad with the love of God.",
    author: "Ramakrishna",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["any", "lady", "first", "being", "i"],
    words: [
      "any",
      "lady",
      "who",
      "is",
      "first",
      "likes",
      "being",
      "i",
      "don't",
      "care",
      "what",
      "they",
      "say",
      "like",
      "it",
    ],
    id: "Any lady who is first lady likes being first lady. I don't care what they say, they like it.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["only", "time", "these", "on", "y"],
    words: [
      "the",
      "only",
      "time",
      "to",
      "buy",
      "these",
      "is",
      "on",
      "a",
      "day",
      "with",
      "no",
      "y",
      "in",
      "it",
    ],
    id: "The only time to buy these is on a day with no 'y' in it.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["highest", "reward", "god", "ability", "better"],
    words: [
      "the",
      "highest",
      "reward",
      "that",
      "god",
      "gives",
      "us",
      "for",
      "good",
      "work",
      "is",
      "ability",
      "to",
      "do",
      "better",
    ],
    id: "The highest reward that God gives us for good work is the ability to do better work.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["food", "always", "cooking", "eating", "those"],
    words: [
      "food",
      "to",
      "me",
      "is",
      "always",
      "about",
      "cooking",
      "and",
      "eating",
      "with",
      "those",
      "you",
      "love",
      "care",
      "for",
    ],
    id: "Food, to me, is always about cooking and eating with those you love and care for.",
    author: "David Chang",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "allow", "person", "tell", "power"],
    words: [
      "never",
      "allow",
      "a",
      "person",
      "to",
      "tell",
      "you",
      "no",
      "who",
      "doesn't",
      "have",
      "the",
      "power",
      "say",
      "yes",
    ],
    id: "Never allow a person to tell you no who doesn't have the power to say yes.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["evil", "men", "songs", "how", "russians"],
    words: ["evil", "men", "have", "no", "songs", "how", "is", "it", "that", "the", "russians"],
    id: "'Evil men have no songs.' How is it that the Russians have songs?",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["cures", "-", "both", "ones", "receive"],
    words: [
      "love",
      "cures",
      "people",
      "-",
      "both",
      "the",
      "ones",
      "who",
      "give",
      "it",
      "and",
      "receive",
    ],
    id: "Love cures people - both the ones who give it and the ones who receive it.",
    author: "Karl A. Menninger",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "badge", "christian", "discipleship", "orthodoxy"],
    words: [
      "the",
      "one",
      "badge",
      "of",
      "christian",
      "discipleship",
      "is",
      "not",
      "orthodoxy",
      "but",
      "love",
    ],
    id: "The one badge of Christian discipleship is not orthodoxy but love.",
    author: "Billy Graham",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "time", "make", "friends", "before"],
    words: ["the", "best", "time", "to", "make", "friends", "is", "before", "you", "need", "them"],
    id: "The best time to make friends is before you need them.",
    author: "Ethel Barrymore",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["scream", "always", "-", "noise", "music"],
    words: ["a", "scream", "is", "always", "just", "that", "-", "noise", "and", "not", "music"],
    id: "A 'scream' is always just that - a noise and not music.",
    author: "Carl Jung",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["empty", "its", "sorrow", "empties", "strength"],
    words: [
      "worry",
      "does",
      "not",
      "empty",
      "tomorrow",
      "of",
      "its",
      "sorrow",
      "it",
      "empties",
      "today",
      "strength",
    ],
    id: "Worry does not empty tomorrow of its sorrow. It empties today of its strength.",
    author: "Corrie Ten Boom",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["essence", "all", "art", "pleasure", "giving"],
    words: ["the", "essence", "of", "all", "art", "is", "to", "have", "pleasure", "in", "giving"],
    id: "The essence of all art is to have pleasure in giving pleasure.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "any", "thought", "was", "wouldn't"],
    words: ["i", "don't", "think", "am", "any", "good", "if", "thought", "was", "wouldn't", "be"],
    id: "I don't think I am any good. If I thought I was any good, I wouldn't be.",
    author: "John Betjeman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["accept", "challenges", "feel", "exhilaration", "victory"],
    words: [
      "accept",
      "the",
      "challenges",
      "so",
      "that",
      "you",
      "can",
      "feel",
      "exhilaration",
      "of",
      "victory",
    ],
    id: "Accept the challenges so that you can feel the exhilaration of victory.",
    author: "George S. Patton",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["world", "really", "more", "less", "paper"],
    words: [
      "what",
      "the",
      "world",
      "really",
      "needs",
      "is",
      "more",
      "love",
      "and",
      "less",
      "paper",
      "work",
    ],
    id: "What the world really needs is more love and less paper work.",
    author: "Pearl Bailey",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "deprive", "hope", "might", "all"],
    words: [
      "never",
      "deprive",
      "someone",
      "of",
      "hope",
      "it",
      "might",
      "be",
      "all",
      "they",
      "have",
    ],
    id: "Never deprive someone of hope; it might be all they have.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["fool", "cannot", "keep", "gain", "lose"],
    words: [
      "he",
      "is",
      "no",
      "fool",
      "who",
      "gives",
      "what",
      "cannot",
      "keep",
      "to",
      "gain",
      "lose",
    ],
    id: "He is no fool who gives what he cannot keep to gain what he cannot lose.",
    author: "Jim Elliot",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "believe", "god", "ritualistic", "pray"],
    words: [
      "i",
      "believe",
      "in",
      "god",
      "am",
      "not",
      "ritualistic",
      "but",
      "do",
      "pray",
      "every",
      "day",
    ],
    id: "I believe in God. I am not ritualistic but I do pray every day.",
    author: "Sudha Murty",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "asking", "more", "than", "prepared"],
    words: [
      "love",
      "is",
      "never",
      "asking",
      "for",
      "more",
      "than",
      "you",
      "are",
      "prepared",
      "to",
      "give",
    ],
    id: "Love is... never asking for more than you are prepared to give.",
    author: "Kim Casali",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "most", "wine", "belongs", "others"],
    words: ["what", "i", "like", "to", "drink", "most", "is", "wine", "that", "belongs", "others"],
    id: "What I like to drink most is wine that belongs to others.",
    author: "Diogenes",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "plan", "life", "all", "available"],
    words: [
      "we",
      "can't",
      "plan",
      "life",
      "all",
      "can",
      "do",
      "is",
      "be",
      "available",
      "for",
      "it",
    ],
    id: "We can't plan life. All we can do is be available for it.",
    author: "Lauryn Hill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["everything", "existing", "universe", "chance", "necessity"],
    words: [
      "everything",
      "existing",
      "in",
      "the",
      "universe",
      "is",
      "fruit",
      "of",
      "chance",
      "and",
      "necessity",
    ],
    id: "Everything existing in the universe is the fruit of chance and necessity.",
    author: "Democritus",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["improve", "content", "thought", "foolish", "stupid"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "improve",
      "be",
      "content",
      "thought",
      "foolish",
      "and",
      "stupid",
    ],
    id: "If you want to improve, be content to be thought foolish and stupid.",
    author: "Epictetus",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "world", "black", "white", "compromising"],
    words: [
      "i",
      "see",
      "the",
      "world",
      "in",
      "black",
      "and",
      "white",
      "don't",
      "like",
      "compromising",
    ],
    id: "I see the world in black and white, and I don't like compromising.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["gather", "honey", "kick", "over", "beehive"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "gather",
      "honey",
      "don't",
      "kick",
      "over",
      "the",
      "beehive",
    ],
    id: "If you want to gather honey, don't kick over the beehive.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["nice", "whites", "rediscover", "their", "humanity"],
    words: [
      "be",
      "nice",
      "to",
      "the",
      "whites",
      "they",
      "need",
      "you",
      "rediscover",
      "their",
      "humanity",
    ],
    id: "Be nice to the whites, they need you to rediscover their humanity.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["war", "easy", "begin", "hard", "stop"],
    words: ["love", "is", "like", "war", "easy", "to", "begin", "but", "very", "hard", "stop"],
    id: "Love is like war: easy to begin but very hard to stop.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["your", "culture", "geeks", "real", "trouble"],
    words: [
      "if",
      "your",
      "culture",
      "doesn't",
      "like",
      "geeks",
      "you",
      "are",
      "in",
      "real",
      "trouble",
    ],
    id: "If your culture doesn't like geeks, you are in real trouble.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "may", "thank", "god", "used"],
    words: ["i", "may", "not", "be", "where", "want", "to", "but", "thank", "god", "am", "used"],
    id: "I may not be where I want to be, but thank God I am not where I used to be.",
    author: "Joyce Meyer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "man", "must", "get", "better"],
    words: ["i", "don't", "like", "that", "man", "must", "get", "to", "know", "him", "better"],
    id: "I don't like that man. I must get to know him better.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["three", "keep", "secret", "two", "dead"],
    words: ["three", "can", "keep", "a", "secret", "if", "two", "of", "them", "are", "dead"],
    id: "Three can keep a secret, if two of them are dead.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["process", "something", "incite", "literally", "riot"],
    words: [
      "the",
      "learning",
      "process",
      "is",
      "something",
      "you",
      "can",
      "incite",
      "literally",
      "like",
      "a",
      "riot",
    ],
    id: "The learning process is something you can incite, literally incite, like a riot.",
    author: "Audre Lorde",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "worms", "i", "believe", "glow-worm"],
    words: ["we", "are", "all", "worms", "but", "i", "believe", "that", "am", "a", "glow-worm"],
    id: "We are all worms. But I believe that I am a glow-worm.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["greatest", "gift", "god", "given", "free"],
    words: [
      "love",
      "is",
      "the",
      "greatest",
      "gift",
      "that",
      "god",
      "has",
      "given",
      "us",
      "it's",
      "free",
    ],
    id: "Love is the greatest gift that God has given us. It's free.",
    author: "Taraji P. Henson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "been", "able", "causes", "things"],
    words: [
      "happy",
      "the",
      "man",
      "who",
      "has",
      "been",
      "able",
      "to",
      "learn",
      "causes",
      "of",
      "things",
    ],
    id: "Happy the man who has been able to learn the causes of things.",
    author: "Virgil",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i'd", "thank", "lord", "making", "yankee"],
    words: [
      "i'd",
      "like",
      "to",
      "thank",
      "the",
      "good",
      "lord",
      "for",
      "making",
      "me",
      "a",
      "yankee",
    ],
    id: "I'd like to thank the good Lord for making me a Yankee.",
    author: "Joe DiMaggio",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["maids", "nothing", "husbands", "when", "everything"],
    words: [
      "maids",
      "want",
      "nothing",
      "but",
      "husbands",
      "and",
      "when",
      "they",
      "have",
      "them",
      "everything",
    ],
    id: "Maids want nothing but husbands, and when they have them, they want everything.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["times", "god", "come", "myriad", "disguises"],
    words: [
      "in",
      "times",
      "of",
      "need",
      "god",
      "can",
      "come",
      "to",
      "you",
      "a",
      "myriad",
      "disguises",
    ],
    id: "In times of need, God can come to you in a myriad of disguises.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["change", "our", "lives", "exactly", "wish"],
    words: [
      "we",
      "can",
      "change",
      "our",
      "lives",
      "do",
      "have",
      "and",
      "be",
      "exactly",
      "what",
      "wish",
    ],
    id: "We can change our lives. We can do, have, and be exactly what we wish.",
    author: "Tony Robbins",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["hire", "make", "best", "things", "world"],
    words: [
      "we",
      "hire",
      "people",
      "who",
      "want",
      "to",
      "make",
      "the",
      "best",
      "things",
      "in",
      "world",
    ],
    id: "We hire people who want to make the best things in the world.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["whether", "believe", "thing", "or", "right"],
    words: ["whether", "you", "believe", "can", "do", "a", "thing", "or", "not", "are", "right"],
    id: "Whether you believe you can do a thing or not, you are right.",
    author: "Henry Ford",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["psychic", "network", "why", "phone", "number"],
    words: [
      "if",
      "it's",
      "the",
      "psychic",
      "network",
      "why",
      "do",
      "they",
      "need",
      "a",
      "phone",
      "number",
    ],
    id: "If it's the Psychic Network why do they need a phone number?",
    author: "Robin Williams",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "free", "obtained", "empire", "himself"],
    words: [
      "no",
      "one",
      "is",
      "free",
      "who",
      "has",
      "not",
      "obtained",
      "the",
      "empire",
      "of",
      "himself",
    ],
    id: "No one is free who has not obtained the empire of himself.",
    author: "Pythagoras",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["price", "anything", "amount", "life", "exchange"],
    words: [
      "the",
      "price",
      "of",
      "anything",
      "is",
      "amount",
      "life",
      "you",
      "exchange",
      "for",
      "it",
    ],
    id: "The price of anything is the amount of life you exchange for it.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["blind", "person", "teacher", "another", "self"],
    words: [
      "what",
      "a",
      "blind",
      "person",
      "needs",
      "is",
      "not",
      "teacher",
      "but",
      "another",
      "self",
    ],
    id: "What a blind person needs is not a teacher but another self.",
    author: "Helen Keller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["as", "polite", "custodian", "chairman", "board"],
    words: [
      "be",
      "as",
      "polite",
      "to",
      "the",
      "custodian",
      "you",
      "are",
      "chairman",
      "of",
      "board",
    ],
    id: "Be as polite to the custodian as you are to the chairman of the board.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["men", "value", "world", "rights", "privileges"],
    words: [
      "what",
      "men",
      "value",
      "in",
      "this",
      "world",
      "is",
      "not",
      "rights",
      "but",
      "privileges",
    ],
    id: "What men value in this world is not rights but privileges.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["desire", "gold", "means", "freedom", "benefit"],
    words: [
      "the",
      "desire",
      "of",
      "gold",
      "is",
      "not",
      "for",
      "it",
      "means",
      "freedom",
      "and",
      "benefit",
    ],
    id: "The desire of gold is not for gold. It is for the means of freedom and benefit.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "got", "powdered", "-", "add"],
    words: [
      "i",
      "got",
      "this",
      "powdered",
      "water",
      "-",
      "now",
      "don't",
      "know",
      "what",
      "to",
      "add",
    ],
    id: "I got this powdered water - now I don't know what to add.",
    author: "Steven Wright",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["our", "character", "when", "one", "looking"],
    words: ["our", "character", "is", "what", "we", "do", "when", "think", "no", "one", "looking"],
    id: "Our character is what we do when we think no one is looking.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "take", "life", "too", "serious"],
    words: ["do", "the", "best", "you", "can", "and", "don't", "take", "life", "too", "serious"],
    id: "Do the best you can, and don't take life too serious.",
    author: "Will Rogers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["which", "single", "envy", "thorns", "numerous"],
    words: [
      "the",
      "flower",
      "which",
      "is",
      "single",
      "need",
      "not",
      "envy",
      "thorns",
      "that",
      "are",
      "numerous",
    ],
    id: "The flower which is single need not envy the thorns that are numerous.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["leave", "stadium", "until", "game", "finishes"],
    words: [
      "we",
      "don't",
      "want",
      "people",
      "to",
      "leave",
      "the",
      "stadium",
      "until",
      "game",
      "finishes",
    ],
    id: "We don't want people to leave the stadium until the game finishes.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["lucid", "dreaming", "being", "aware", "doing"],
    words: [
      "lucid",
      "dreaming",
      "is",
      "but",
      "you",
      "being",
      "aware",
      "that",
      "you're",
      "doing",
      "it",
    ],
    id: "Lucid dreaming is dreaming but you being aware that you're doing it.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["sure", "i", "only", "endure", "conquer"],
    words: [
      "sure",
      "i",
      "am",
      "of",
      "this",
      "that",
      "you",
      "have",
      "only",
      "to",
      "endure",
      "conquer",
    ],
    id: "Sure I am of this, that you have only to endure to conquer.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["schools", "ain't", "used", "never", "was"],
    words: ["the", "schools", "ain't", "what", "they", "used", "to", "be", "and", "never", "was"],
    id: "The schools ain't what they used to be and never was.",
    author: "Will Rogers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["nothing", "rated", "higher", "than", "value"],
    words: ["nothing", "is", "to", "be", "rated", "higher", "than", "the", "value", "of", "day"],
    id: "Nothing is to be rated higher than the value of the day.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "clever", "enough", "all", "evil"],
    words: [
      "no",
      "man",
      "is",
      "clever",
      "enough",
      "to",
      "know",
      "all",
      "the",
      "evil",
      "he",
      "does",
    ],
    id: "No man is clever enough to know all the evil he does.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["might", "wind", "up", "someplace", "else"],
    words: [
      "if",
      "you",
      "don't",
      "know",
      "where",
      "are",
      "going",
      "might",
      "wind",
      "up",
      "someplace",
      "else",
    ],
    id: "If you don't know where you are going, you might wind up someplace else.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["can't", "change", "how", "act", "react"],
    words: ["you", "can't", "change", "how", "people", "act", "but", "what", "can", "is", "react"],
    id: "You can't change how people act, but what you can change is how you react.",
    author: "Bonnie Hammer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "wake", "up", "im", "breathing"],
    words: [
      "i",
      "wake",
      "up",
      "every",
      "day",
      "and",
      "think",
      "im",
      "breathing",
      "it's",
      "a",
      "good",
    ],
    id: "I wake up every day and I think, 'I'm breathing! It's a good day.'",
    author: "Eve Ensler",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["seems", "unjust", "law", "at", "all"],
    words: ["it", "seems", "to", "me", "that", "an", "unjust", "law", "is", "no", "at", "all"],
    id: "It seems to me that an unjust law is no law at all.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["major", "value", "life", "get", "become"],
    words: ["the", "major", "value", "in", "life", "is", "not", "what", "you", "get", "become"],
    id: "The major value in life is not what you get. The major value in life is what you become.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["those", "hope", "life", "dead", "even"],
    words: ["those", "who", "hope", "for", "no", "other", "life", "are", "dead", "even", "this"],
    id: "Those who hope for no other life are dead even for this.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "world", "can't", "back", "health"],
    words: ["all", "the", "money", "in", "world", "can't", "buy", "you", "back", "good", "health"],
    id: "All the money in the world can't buy you back good health.",
    author: "Reba McEntire",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "way", "idea", "lot", "ideas"],
    words: ["the", "best", "way", "to", "have", "a", "good", "idea", "is", "lot", "of", "ideas"],
    id: "The best way to have a good idea is to have a lot of ideas.",
    author: "Linus Pauling",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["always", "win", "your", "battles", "fought"],
    words: [
      "you",
      "don't",
      "always",
      "win",
      "your",
      "battles",
      "but",
      "it's",
      "good",
      "to",
      "know",
      "fought",
    ],
    id: "You don't always win your battles, but it's good to know you fought.",
    author: "John Greenleaf Whittier",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["made", "fair", "compact", "poverty", "rich"],
    words: ["he", "who", "has", "made", "a", "fair", "compact", "with", "poverty", "is", "rich"],
    id: "He who has made a fair compact with poverty is rich.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["nicer", "thing", "somebody", "than", "make"],
    words: [
      "what",
      "nicer",
      "thing",
      "can",
      "you",
      "do",
      "for",
      "somebody",
      "than",
      "make",
      "them",
      "breakfast",
    ],
    id: "What nicer thing can you do for somebody than make them breakfast?",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "food", "eating", "deprive", "myself"],
    words: [
      "i",
      "like",
      "food",
      "eating",
      "and",
      "don't",
      "want",
      "to",
      "deprive",
      "myself",
      "of",
      "good",
    ],
    id: "I like food. I like eating. And I don't want to deprive myself of good food.",
    author: "Sarah Michelle Gellar",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "got", "ear", "kind", "things"],
    words: ["i", "just", "got", "an", "ear", "for", "the", "kind", "of", "things", "like"],
    id: "I just got an ear for the kind of things I like.",
    author: "Lil Baby",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["richest", "content", "least", "wealth", "nature"],
    words: [
      "he",
      "is",
      "richest",
      "who",
      "content",
      "with",
      "the",
      "least",
      "for",
      "wealth",
      "of",
      "nature",
    ],
    id: "He is richest who is content with the least, for content is the wealth of nature.",
    author: "Socrates",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["somebody", "your", "time", "biggest", "gift"],
    words: ["to", "give", "somebody", "your", "time", "is", "the", "biggest", "gift", "you", "can"],
    id: "To give somebody your time is the biggest gift you can give.",
    author: "Franka Potente",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "my", "children", "ultimate", "father"],
    words: ["i", "just", "want", "to", "be", "that", "my", "children", "the", "ultimate", "father"],
    id: "I just want to be that to my children. The ultimate father.",
    author: "Lil Baby",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["much", "lost", "lack", "little", "more"],
    words: [
      "much",
      "good",
      "work",
      "is",
      "lost",
      "for",
      "the",
      "lack",
      "of",
      "a",
      "little",
      "more",
    ],
    id: "Much good work is lost for the lack of a little more.",
    author: "Edward H. Harriman",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["ill", "praise", "worse", "censure", "understand"],
    words: [
      "you",
      "do",
      "ill",
      "if",
      "praise",
      "but",
      "worse",
      "censure",
      "what",
      "not",
      "understand",
    ],
    id: "You do ill if you praise, but worse if you censure, what you do not understand.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["god", "always", "given", "strength", "right"],
    words: [
      "god",
      "has",
      "always",
      "given",
      "me",
      "the",
      "strength",
      "to",
      "say",
      "what",
      "is",
      "right",
    ],
    id: "God has always given me the strength to say what is right.",
    author: "Rosa Parks",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["human", "foot", "masterpiece", "engineering", "art"],
    words: [
      "the",
      "human",
      "foot",
      "is",
      "a",
      "masterpiece",
      "of",
      "engineering",
      "and",
      "work",
      "art",
    ],
    id: "The human foot is a masterpiece of engineering and a work of art.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["friends", "manners", "will", "carry", "won't"],
    words: [
      "friends",
      "and",
      "good",
      "manners",
      "will",
      "carry",
      "you",
      "where",
      "money",
      "won't",
      "go",
    ],
    id: "Friends and good manners will carry you where money won't go.",
    author: "Margaret Walker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "pay", "wages", "because", "lot"],
    words: ["i", "don't", "pay", "good", "wages", "because", "have", "a", "lot", "of", "money"],
    id: "I don't pay good wages because I have a lot of money; I have a lot of money because I pay good wages.",
    author: "Robert Bosch",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["will", "resist", "change", "when", "done"],
    words: [
      "people",
      "will",
      "resist",
      "change",
      "when",
      "it's",
      "done",
      "to",
      "them",
      "not",
      "with",
    ],
    id: "People will resist change when it's done to them, not with them.",
    author: "Ken Blanchard",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["endless", "mystery", "nothing", "else", "explain"],
    words: [
      "love",
      "is",
      "an",
      "endless",
      "mystery",
      "for",
      "it",
      "has",
      "nothing",
      "else",
      "to",
      "explain",
    ],
    id: "Love is an endless mystery, for it has nothing else to explain it.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["by", "plucking", "petals", "gather", "beauty"],
    words: [
      "by",
      "plucking",
      "her",
      "petals",
      "you",
      "do",
      "not",
      "gather",
      "the",
      "beauty",
      "of",
      "flower",
    ],
    id: "By plucking her petals, you do not gather the beauty of the flower.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["one", "guy", "get", "billion-dollar", "fight"],
    words: [
      "one",
      "day",
      "some",
      "guy",
      "is",
      "going",
      "to",
      "get",
      "a",
      "billion-dollar",
      "fight",
    ],
    id: "One day some guy is going to get a billion-dollar fight.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["close", "people's", "heart", "merciful", "god"],
    words: [
      "the",
      "just",
      "is",
      "close",
      "to",
      "people's",
      "heart",
      "but",
      "merciful",
      "of",
      "god",
    ],
    id: "The just is close to the people's heart, but the merciful is close to the heart of God.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "lot", "pop", "music", "idea"],
    words: ["i", "don't", "like", "a", "lot", "of", "pop", "music", "but", "the", "idea"],
    id: "I don't like a lot of pop music. But I like the idea of pop music.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["there", "enough", "time", "all", "nothing"],
    words: [
      "there",
      "is",
      "not",
      "enough",
      "time",
      "to",
      "do",
      "all",
      "the",
      "nothing",
      "we",
      "want",
    ],
    id: "There is not enough time to do all the nothing we want to do.",
    author: "Bill Watterson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["trust", "dreams", "hidden", "gate", "eternity"],
    words: [
      "trust",
      "in",
      "dreams",
      "for",
      "them",
      "is",
      "hidden",
      "the",
      "gate",
      "to",
      "eternity",
    ],
    id: "Trust in dreams, for in them is hidden the gate to eternity.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["man", "suffer", "others", "god", "men"],
    words: ["to", "be", "a", "man", "is", "suffer", "for", "others", "god", "help", "us", "men"],
    id: "To be a man is to suffer for others. God help us to be men!",
    author: "Cesar Chavez",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["never", "too", "late", "might", "been"],
    words: ["it", "is", "never", "too", "late", "to", "be", "what", "you", "might", "have", "been"],
    id: "It is never too late to be what you might have been.",
    author: "George Eliot",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["genuine", "leader", "searcher", "consensus", "molder"],
    words: [
      "a",
      "genuine",
      "leader",
      "is",
      "not",
      "searcher",
      "for",
      "consensus",
      "but",
      "molder",
      "of",
    ],
    id: "A genuine leader is not a searcher for consensus but a molder of consensus.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["comment", "splendid", "expression", "i", "using"],
    words: [
      "no",
      "comment",
      "is",
      "a",
      "splendid",
      "expression",
      "i",
      "am",
      "using",
      "it",
      "again",
      "and",
    ],
    id: "'No comment' is a splendid expression. I am using it again and again.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "horror", "movies", "degree", "coziness"],
    words: [
      "i",
      "like",
      "horror",
      "movies",
      "that",
      "have",
      "a",
      "degree",
      "of",
      "coziness",
      "to",
      "them",
    ],
    id: "I like horror movies that have a degree of coziness to them.",
    author: "Tobias Forge",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "revenge", "unlike", "performed", "injury"],
    words: [
      "the",
      "best",
      "revenge",
      "is",
      "to",
      "be",
      "unlike",
      "him",
      "who",
      "performed",
      "injury",
    ],
    id: "The best revenge is to be unlike him who performed the injury.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["hard", "interest", "those", "everything", "nothing"],
    words: [
      "it",
      "is",
      "hard",
      "to",
      "interest",
      "those",
      "who",
      "have",
      "everything",
      "in",
      "nothing",
    ],
    id: "It is hard to interest those who have everything in those who have nothing.",
    author: "Helen Keller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["women's", "natural", "role", "pillar", "family"],
    words: ["women's", "natural", "role", "is", "to", "be", "a", "pillar", "of", "the", "family"],
    id: "Women's natural role is to be a pillar of the family.",
    author: "Grace Kelly",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "worth", "having", "connote", "err"],
    words: ["freedom", "is", "not", "worth", "having", "if", "it", "does", "connote", "to", "err"],
    id: "Freedom is not worth having if it does not connote freedom to err.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "way", "pay", "lovely", "enjoy"],
    words: ["the", "best", "way", "to", "pay", "for", "a", "lovely", "moment", "is", "enjoy", "it"],
    id: "The best way to pay for a lovely moment is to enjoy it.",
    author: "Richard Bach",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["courageous", "fear", "forgiving", "sake", "peace"],
    words: [
      "courageous",
      "people",
      "do",
      "not",
      "fear",
      "forgiving",
      "for",
      "the",
      "sake",
      "of",
      "peace",
    ],
    id: "Courageous people do not fear forgiving, for the sake of peace.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["mind", "man", "conceive", "believe", "achieve"],
    words: [
      "what",
      "the",
      "mind",
      "of",
      "man",
      "can",
      "conceive",
      "and",
      "believe",
      "it",
      "achieve",
    ],
    id: "What the mind of man can conceive and believe, it can achieve.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["freedom", "cannot", "responsibility", "only", "belongs"],
    words: [
      "freedom",
      "cannot",
      "be",
      "a",
      "responsibility",
      "that",
      "only",
      "belongs",
      "to",
      "some",
      "of",
      "us",
    ],
    id: "Freedom cannot be a responsibility that only belongs to some of us.",
    author: "Cori Bush",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["rarely", "succeed", "unless", "fun", "doing"],
    words: [
      "people",
      "rarely",
      "succeed",
      "unless",
      "they",
      "have",
      "fun",
      "in",
      "what",
      "are",
      "doing",
    ],
    id: "People rarely succeed unless they have fun in what they are doing.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["broken", "heart", "kills", "pride", "monseigneur"],
    words: [
      "it",
      "is",
      "not",
      "the",
      "broken",
      "heart",
      "that",
      "kills",
      "but",
      "pride",
      "monseigneur",
    ],
    id: "It is not the broken heart that kills, but broken pride, monseigneur.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["woe", "teaches", "men", "faster", "than"],
    words: ["woe", "to", "him", "who", "teaches", "men", "faster", "than", "they", "can", "learn"],
    id: "Woe to him who teaches men faster than they can learn.",
    author: "Will Durant",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["desire", "begin", "by", "believing", "wicked"],
    words: [
      "if",
      "you",
      "desire",
      "to",
      "be",
      "good",
      "begin",
      "by",
      "believing",
      "that",
      "are",
      "wicked",
    ],
    id: "If you desire to be good, begin by believing that you are wicked.",
    author: "Epictetus",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["got", "wife", "yet", "complete", "man"],
    words: ["he", "that", "has", "not", "got", "a", "wife", "is", "yet", "complete", "man"],
    id: "He that has not got a wife is not yet a complete man.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["use", "all", "advantage", "there", "having"],
    words: ["the", "use", "of", "money", "is", "all", "advantage", "there", "in", "having", "it"],
    id: "The use of money is all the advantage there is in having it.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["success", "only", "motivational", "factor", "character"],
    words: [
      "success",
      "is",
      "the",
      "only",
      "motivational",
      "factor",
      "that",
      "a",
      "boy",
      "with",
      "character",
      "needs",
    ],
    id: "Success is the only motivational factor that a boy with character needs.",
    author: "Woody Hayes",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["listen", "wicked", "already", "beginning", "wickedness"],
    words: [
      "to",
      "see",
      "and",
      "listen",
      "the",
      "wicked",
      "is",
      "already",
      "beginning",
      "of",
      "wickedness",
    ],
    id: "To see and listen to the wicked is already the beginning of wickedness.",
    author: "Confucius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["being", "second", "first", "ones", "lose"],
    words: ["being", "second", "is", "to", "be", "the", "first", "of", "ones", "who", "lose"],
    id: "Being second is to be the first of the ones who lose.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["all", "possibilities", "things", "even", "dream"],
    words: [
      "we",
      "all",
      "have",
      "possibilities",
      "don't",
      "know",
      "about",
      "can",
      "do",
      "things",
      "even",
      "dream",
    ],
    id: "We all have possibilities we don't know about. We can do things we don't even dream we can do.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["real", "man", "child", "hidden", "play"],
    words: [
      "in",
      "every",
      "real",
      "man",
      "a",
      "child",
      "is",
      "hidden",
      "that",
      "wants",
      "to",
      "play",
    ],
    id: "In every real man a child is hidden that wants to play.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["religion", "come", "up", "any", "arguments"],
    words: [
      "religion",
      "is",
      "not",
      "going",
      "to",
      "come",
      "up",
      "with",
      "any",
      "new",
      "arguments",
    ],
    id: "Religion is not going to come up with any new arguments.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["imagination", "at", "root", "much", "passes"],
    words: [
      "imagination",
      "is",
      "at",
      "the",
      "root",
      "of",
      "much",
      "that",
      "passes",
      "for",
      "love",
    ],
    id: "Imagination is at the root of much that passes for love.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["millions", "die", "everyone's", "got", "sometime"],
    words: [
      "millions",
      "of",
      "people",
      "die",
      "every",
      "day",
      "everyone's",
      "got",
      "to",
      "go",
      "sometime",
    ],
    id: "Millions of people die every day. Everyone's got to go sometime.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["happiness", "kiss", "must", "share", "enjoy"],
    words: ["happiness", "is", "like", "a", "kiss", "you", "must", "share", "it", "to", "enjoy"],
    id: "Happiness is like a kiss. You must share it to enjoy it.",
    author: "Bernard Meltzer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["experience", "keeps", "dear", "fools", "will"],
    words: [
      "experience",
      "keeps",
      "a",
      "dear",
      "school",
      "but",
      "fools",
      "will",
      "learn",
      "in",
      "no",
      "other",
    ],
    id: "Experience keeps a dear school, but fools will learn in no other.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["lesson", "still", "make", "mistakes", "forgiven"],
    words: [
      "the",
      "lesson",
      "is",
      "that",
      "you",
      "can",
      "still",
      "make",
      "mistakes",
      "and",
      "be",
      "forgiven",
    ],
    id: "The lesson is that you can still make mistakes and be forgiven.",
    author: "Robert Downey, Jr.",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["best", "thing", "world", "lives", "longest"],
    words: ["love", "is", "the", "best", "thing", "in", "world", "and", "that", "lives", "longest"],
    id: "Love is the best thing in the world, and the thing that lives the longest.",
    author: "Henry Van Dyke",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["use", "philosopher", "hurt", "anybody's", "feelings"],
    words: [
      "of",
      "what",
      "use",
      "is",
      "a",
      "philosopher",
      "who",
      "doesn't",
      "hurt",
      "anybody's",
      "feelings",
    ],
    id: "Of what use is a philosopher who doesn't hurt anybody's feelings?",
    author: "Diogenes",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "whether", "there", "gods", "ought"],
    words: [
      "i",
      "do",
      "not",
      "know",
      "whether",
      "there",
      "are",
      "gods",
      "but",
      "ought",
      "to",
      "be",
    ],
    id: "I do not know whether there are gods, but there ought to be.",
    author: "Diogenes",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["life", "happen", "believe", "right", "always"],
    words: [
      "let",
      "life",
      "happen",
      "to",
      "you",
      "believe",
      "me",
      "is",
      "in",
      "the",
      "right",
      "always",
    ],
    id: "Let life happen to you. Believe me: life is in the right, always.",
    author: "Rainer Maria Rilke",
    grammar: null,
    word_count: 12,
    all_intersection_count: 7,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "year", "chance", "compete", "championship"],
    words: [
      "i",
      "want",
      "every",
      "year",
      "to",
      "have",
      "a",
      "chance",
      "compete",
      "for",
      "championship",
    ],
    id: "I want every year to have a chance to compete for a championship.",
    author: "Paul George",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["i", "idea", "plan", "sell", "company"],
    words: ["i", "don't", "think", "it's", "a", "good", "idea", "to", "plan", "sell", "company"],
    id: "I don't think it's a good idea to plan to sell a company.",
    author: "Elon Musk",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["would", "already", "wise", "order", "wisdom"],
    words: [
      "they",
      "would",
      "need",
      "to",
      "be",
      "already",
      "wise",
      "in",
      "order",
      "love",
      "wisdom",
    ],
    id: "They would need to be already wise, in order to love wisdom.",
    author: "Friedrich Schiller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["classical", "music", "chance", "understand", "experience"],
    words: [
      "it's",
      "not",
      "that",
      "people",
      "don't",
      "like",
      "classical",
      "music",
      "they",
      "have",
      "the",
      "chance",
      "to",
      "understand",
      "and",
      "experience",
      "it",
    ],
    id: "It's not that people don't like classical music. It's that they don't have the chance to understand and to experience it.",
    author: "Gustavo Dudamel",
    grammar: null,
    word_count: 17,
    all_intersection_count: 12,
    new_words_count: 5,
  },
  {
    row_new_words: ["movies", "admire", "extent", "function", "when"],
    words: [
      "the",
      "movies",
      "we",
      "love",
      "and",
      "admire",
      "are",
      "to",
      "some",
      "extent",
      "a",
      "function",
      "of",
      "who",
      "when",
      "see",
      "them",
    ],
    id: "The movies we love and admire are to some extent a function of who we are when we see them.",
    author: "Mary Schmich",
    grammar: null,
    word_count: 17,
    all_intersection_count: 12,
    new_words_count: 5,
  },
  {
    row_new_words: ["those", "credit", "even", "sinners", "same"],
    words: [
      "if",
      "you",
      "love",
      "those",
      "who",
      "what",
      "credit",
      "is",
      "that",
      "to",
      "for",
      "even",
      "sinners",
      "them",
      "and",
      "do",
      "good",
      "the",
      "same",
    ],
    id: "If you love those who love you, what credit is that to you? For even sinners love those who love them. And if you do good to those who do good to you, what credit is that to you? For even sinners do the same.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 19,
    all_intersection_count: 14,
    new_words_count: 5,
  },
  {
    row_new_words: ["isn't", "or", "doing", "makes", "unhappy"],
    words: [
      "it",
      "isn't",
      "what",
      "you",
      "have",
      "or",
      "who",
      "are",
      "where",
      "doing",
      "that",
      "makes",
      "happy",
      "unhappy",
      "is",
      "think",
      "about",
    ],
    id: "It isn't what you have, or who you are, or where you are, or what you are doing that makes you happy or unhappy. It is what you think about.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 17,
    all_intersection_count: 12,
    new_words_count: 5,
  },
  {
    row_new_words: ["by", "run", "running", "same", "way", "loving"],
    words: [
      "you",
      "learn",
      "to",
      "speak",
      "by",
      "speaking",
      "study",
      "studying",
      "run",
      "running",
      "work",
      "working",
      "in",
      "just",
      "the",
      "same",
      "way",
      "love",
      "loving",
    ],
    id: "You learn to speak by speaking, to study by studying, to run by running, to work by working; in just the same way, you learn to love by loving.",
    author: "Anatole France",
    grammar: null,
    word_count: 19,
    all_intersection_count: 13,
    new_words_count: 6,
  },
  {
    row_new_words: ["yourself", "all", "flaws", "much", "better", "makes"],
    words: [
      "if",
      "you",
      "can",
      "learn",
      "to",
      "love",
      "yourself",
      "and",
      "all",
      "the",
      "flaws",
      "other",
      "people",
      "so",
      "much",
      "better",
      "that",
      "makes",
      "happy",
    ],
    id: "If you can learn to love yourself and all the flaws, you can love other people so much better. And that makes you so happy.",
    author: "Kristin Chenoweth",
    grammar: null,
    word_count: 19,
    all_intersection_count: 13,
    new_words_count: 6,
  },
  {
    row_new_words: ["important", "otherwise", "your", "job", "quite", "miserable"],
    words: [
      "it's",
      "very",
      "important",
      "to",
      "like",
      "the",
      "people",
      "you",
      "work",
      "with",
      "otherwise",
      "your",
      "job",
      "is",
      "going",
      "be",
      "quite",
      "miserable",
    ],
    id: "It's very important to like the people you work with. Otherwise, your job is going to be quite miserable.",
    author: "Elon Musk",
    grammar: null,
    word_count: 18,
    all_intersection_count: 12,
    new_words_count: 6,
  },
  {
    row_new_words: ["highest", "compliment", "pay", "i", "hard", "never"],
    words: [
      "the",
      "highest",
      "compliment",
      "that",
      "you",
      "can",
      "pay",
      "me",
      "is",
      "to",
      "say",
      "i",
      "work",
      "hard",
      "every",
      "day",
      "never",
      "dog",
      "it",
    ],
    id: "The highest compliment that you can pay me is to say that I work hard every day, that I never dog it.",
    author: "Wayne Gretzky",
    grammar: null,
    word_count: 19,
    all_intersection_count: 13,
    new_words_count: 6,
  },
  {
    row_new_words: ["i've", "got", "big", "heart", "will", "keep"],
    words: [
      "people",
      "can",
      "say",
      "what",
      "they",
      "want",
      "about",
      "me",
      "but",
      "i've",
      "got",
      "a",
      "big",
      "heart",
      "and",
      "will",
      "keep",
      "going",
    ],
    id: "People can say what they want about me. But I've got a big heart and will keep going.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 18,
    all_intersection_count: 12,
    new_words_count: 6,
  },
  {
    row_new_words: ["joy", "surrender", "into", "flow", "even", "when"],
    words: [
      "joy",
      "is",
      "in",
      "learning",
      "to",
      "say",
      "yes",
      "what",
      "and",
      "surrender",
      "into",
      "flow",
      "with",
      "even",
      "when",
      "it's",
      "you",
      "don't",
      "want",
    ],
    id: "Joy is in learning to say yes to what is and to surrender into flow with what is, even when it's what you don't want.",
    author: "Pete Holmes",
    grammar: null,
    word_count: 19,
    all_intersection_count: 13,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "god", "sports", "fan", "or", "comeback"],
    words: [
      "i",
      "don't",
      "know",
      "if",
      "god",
      "is",
      "a",
      "sports",
      "fan",
      "or",
      "not",
      "but",
      "do",
      "this",
      "he",
      "loves",
      "good",
      "comeback",
    ],
    id: "I don't know if God is a sports fan or not, but I do know this: He loves a good comeback.",
    author: "Lane Kiffin",
    grammar: null,
    word_count: 18,
    all_intersection_count: 12,
    new_words_count: 6,
  },
  {
    row_new_words: ["must", "life", "planned", "as", "accept", "one"],
    words: [
      "we",
      "must",
      "let",
      "go",
      "of",
      "the",
      "life",
      "have",
      "planned",
      "so",
      "as",
      "to",
      "accept",
      "one",
      "that",
      "is",
      "waiting",
      "for",
      "us",
    ],
    id: "We must let go of the life we have planned, so as to accept the one that is waiting for us.",
    author: "Joseph Campbell",
    grammar: null,
    word_count: 19,
    all_intersection_count: 13,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "holds", "none", "got", "god", "planned"],
    words: [
      "i",
      "don't",
      "know",
      "what",
      "tomorrow",
      "holds",
      "none",
      "of",
      "us",
      "do",
      "we",
      "got",
      "to",
      "wait",
      "and",
      "see",
      "god",
      "has",
      "planned",
    ],
    id: "I don't know what tomorrow holds. None of us do. We got to wait and see what God has planned.",
    author: "Roy Jones Jr.",
    grammar: null,
    word_count: 19,
    all_intersection_count: 13,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "many", "things", "world", "mourn", "change"],
    words: [
      "there",
      "are",
      "many",
      "things",
      "we",
      "do",
      "not",
      "want",
      "about",
      "the",
      "world",
      "let",
      "us",
      "just",
      "mourn",
      "them",
      "change",
    ],
    id: "There are many things we do not want about the world. Let us not just mourn them. Let us change them.",
    author: "Ferdinand Marcos",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["democracy", "theory", "common", "deserve", "get", "hard"],
    words: [
      "democracy",
      "is",
      "the",
      "theory",
      "that",
      "common",
      "people",
      "know",
      "what",
      "they",
      "want",
      "and",
      "deserve",
      "to",
      "get",
      "it",
      "good",
      "hard",
    ],
    id: "Democracy is the theory that the common people know what they want, and deserve to get it good and hard.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 18,
    all_intersection_count: 12,
    new_words_count: 6,
  },
  {
    row_new_words: ["best", "reason", "movies", "eating", "popcorn", "being"],
    words: [
      "the",
      "best",
      "reason",
      "to",
      "go",
      "movies",
      "is",
      "be",
      "with",
      "other",
      "people",
      "eating",
      "popcorn",
      "being",
      "you",
      "don't",
      "know",
    ],
    id: "The best reason to go to the movies is to be with other people. Eating the popcorn, being with other people you don't know.",
    author: "Peter Weller",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "thing", "hold", "allow", "-", "knowledge"],
    words: [
      "when",
      "you",
      "know",
      "a",
      "thing",
      "to",
      "hold",
      "that",
      "it",
      "and",
      "do",
      "not",
      "allow",
      "-",
      "this",
      "is",
      "knowledge",
    ],
    id: "When you know a thing, to hold that you know it, and when you do not know a thing, to allow that you do not know it - this is knowledge.",
    author: "Confucius",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["doing", "thing", "liking", "makes", "life", "blessed"],
    words: [
      "it",
      "is",
      "not",
      "doing",
      "the",
      "thing",
      "we",
      "like",
      "to",
      "do",
      "but",
      "liking",
      "have",
      "that",
      "makes",
      "life",
      "blessed",
    ],
    id: "It is not doing the thing we like to do, but liking the thing we have to do, that makes life blessed.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["religion", "takes", "account", "practical", "affairs", "solve"],
    words: [
      "a",
      "religion",
      "that",
      "takes",
      "no",
      "account",
      "of",
      "practical",
      "affairs",
      "and",
      "does",
      "not",
      "help",
      "to",
      "solve",
      "them",
      "is",
    ],
    id: "A religion that takes no account of practical affairs and does not help to solve them is no religion.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatever", "great", "at", "able", "make", "sacrifices"],
    words: [
      "whatever",
      "you",
      "want",
      "to",
      "do",
      "if",
      "be",
      "great",
      "at",
      "it",
      "have",
      "love",
      "and",
      "able",
      "make",
      "sacrifices",
      "for",
    ],
    id: "Whatever you want to do, if you want to be great at it, you have to love it and be able to make sacrifices for it.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["important", "get", "respect", "at", "same", "time"],
    words: [
      "it",
      "is",
      "so",
      "important",
      "to",
      "get",
      "respect",
      "for",
      "what",
      "you",
      "do",
      "and",
      "at",
      "the",
      "same",
      "time",
      "give",
    ],
    id: "It is so important to get respect for what you do and at the same time give it.",
    author: "Estelle Parsons",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["thing", "i", "make", "music", "weird", "says"],
    words: [
      "the",
      "thing",
      "is",
      "i",
      "make",
      "music",
      "like",
      "so",
      "it's",
      "just",
      "weird",
      "if",
      "someone",
      "says",
      "they",
      "don't",
      "it",
    ],
    id: "The thing is, I make music I like. So it's just weird if someone says they don't like it.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["worst", "thing", "happens", "may", "best", "get"],
    words: [
      "the",
      "worst",
      "thing",
      "that",
      "happens",
      "to",
      "you",
      "may",
      "be",
      "best",
      "for",
      "if",
      "don't",
      "let",
      "it",
      "get",
      "of",
    ],
    id: "The worst thing that happens to you may be the best thing for you if you don't let it get the best of you.",
    author: "Will Rogers",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "one", "i", "god", "bless", "health"],
    words: [
      "we",
      "all",
      "have",
      "to",
      "go",
      "one",
      "day",
      "so",
      "i",
      "just",
      "want",
      "god",
      "bless",
      "me",
      "with",
      "good",
      "health",
    ],
    id: "We all have to go one day. So I just want God to bless me with good health.",
    author: "Randhir Kapoor",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "country", "cannot", "walk", "on", "street"],
    words: [
      "i",
      "love",
      "dogs",
      "but",
      "you",
      "have",
      "to",
      "be",
      "in",
      "the",
      "country",
      "with",
      "cannot",
      "walk",
      "a",
      "dog",
      "on",
      "street",
    ],
    id: "I love dogs, but dogs, you have to be in the country with dogs. I cannot walk a dog on the street.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 18,
    all_intersection_count: 12,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "blessings", "old", "friends", "afford", "stupid"],
    words: [
      "it",
      "is",
      "one",
      "of",
      "the",
      "blessings",
      "old",
      "friends",
      "that",
      "you",
      "can",
      "afford",
      "to",
      "be",
      "stupid",
      "with",
      "them",
    ],
    id: "It is one of the blessings of old friends that you can afford to be stupid with them.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["by", "alone", "succeed", "penetrating", "essence", "being"],
    words: [
      "is",
      "it",
      "not",
      "by",
      "love",
      "alone",
      "that",
      "we",
      "succeed",
      "in",
      "penetrating",
      "to",
      "the",
      "very",
      "essence",
      "of",
      "being",
    ],
    id: "Is it not by love alone that we succeed in penetrating to the very essence of being?",
    author: "Igor Stravinsky",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "when", "way", "ever", "become", "interested"],
    words: [
      "i",
      "know",
      "what",
      "like",
      "when",
      "see",
      "it",
      "but",
      "no",
      "way",
      "have",
      "ever",
      "become",
      "interested",
      "in",
      "learning",
      "about",
    ],
    id: "I know what I like when I see it, but no way have I ever become interested in learning about it.",
    author: "Timothy Spall",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["successful", "make", "become", "attract", "bring", "success"],
    words: [
      "successful",
      "people",
      "make",
      "money",
      "it's",
      "not",
      "that",
      "who",
      "become",
      "but",
      "attract",
      "they",
      "bring",
      "success",
      "to",
      "what",
      "do",
    ],
    id: "Successful people make money. It's not that people who make money become successful, but that successful people attract money. They bring success to what they do.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["changing", "sometimes", "situation", "how", "even", "better"],
    words: [
      "it's",
      "not",
      "about",
      "changing",
      "people",
      "sometimes",
      "a",
      "situation",
      "how",
      "can",
      "we",
      "build",
      "an",
      "even",
      "better",
      "for",
      "them",
    ],
    id: "It's not about changing people; it's sometimes about changing a situation. How can we build an even better situation for them?",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["get", "when", "come", "must", "find", "out"],
    words: [
      "we",
      "don't",
      "get",
      "to",
      "know",
      "people",
      "when",
      "they",
      "come",
      "us",
      "must",
      "go",
      "them",
      "find",
      "out",
      "what",
      "are",
      "like",
    ],
    id: "We don't get to know people when they come to us; we must go to them to find out what they are like.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 18,
    all_intersection_count: 12,
    new_words_count: 6,
  },
  {
    row_new_words: ["way", "god", "season", "strive", "near", "each"],
    words: [
      "the",
      "way",
      "to",
      "be",
      "with",
      "god",
      "in",
      "every",
      "season",
      "is",
      "strive",
      "near",
      "him",
      "week",
      "and",
      "each",
      "day",
    ],
    id: "The way to be with God in every season is to strive to be near Him every week and each day.",
    author: "Thomas S. Monson",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "doing", "funny", "show", "act", "fall"],
    words: [
      "i",
      "like",
      "doing",
      "a",
      "funny",
      "show",
      "where",
      "don't",
      "have",
      "to",
      "act",
      "and",
      "fall",
      "in",
      "love",
      "with",
      "girl",
    ],
    id: "I like doing a funny show where I don't have to act and fall in love with a girl.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "devil", "under", "name", "bible", "describes"],
    words: [
      "it",
      "is",
      "not",
      "a",
      "god",
      "just",
      "and",
      "good",
      "but",
      "devil",
      "under",
      "the",
      "name",
      "of",
      "that",
      "bible",
      "describes",
    ],
    id: "It is not a God, just and good, but a devil, under the name of God, that the Bible describes.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["words", "wisdom", "stupid", "understands", "wise", "already"],
    words: [
      "we",
      "have",
      "no",
      "words",
      "for",
      "speaking",
      "of",
      "wisdom",
      "to",
      "the",
      "stupid",
      "he",
      "who",
      "understands",
      "wise",
      "is",
      "already",
    ],
    id: "We have no words for speaking of wisdom to the stupid. He who understands the wise is wise already.",
    author: "Georg C. Lichtenberg",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["play", "hockey", "seasons", "win", "stanley", "objective"],
    words: [
      "you",
      "do",
      "not",
      "play",
      "hockey",
      "for",
      "good",
      "seasons",
      "to",
      "win",
      "the",
      "stanley",
      "cup",
      "it",
      "has",
      "be",
      "objective",
    ],
    id: "You do not play hockey for good seasons. You play to win the Stanley Cup. It has to be the objective.",
    author: "Guy Lafleur",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["older", "sit", "down", "ask", "asks", "i"],
    words: [
      "older",
      "people",
      "sit",
      "down",
      "and",
      "ask",
      "what",
      "is",
      "it",
      "but",
      "the",
      "boy",
      "asks",
      "can",
      "i",
      "do",
      "with",
    ],
    id: "Older people sit down and ask, 'What is it?' but the boy asks, 'What can I do with it?'.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["aim", "success", "believe", "will", "come", "naturally"],
    words: [
      "don't",
      "aim",
      "for",
      "success",
      "if",
      "you",
      "want",
      "it",
      "just",
      "do",
      "what",
      "love",
      "and",
      "believe",
      "in",
      "will",
      "come",
      "naturally",
    ],
    id: "Don't aim for success if you want it; just do what you love and believe in, and it will come naturally.",
    author: "David Frost",
    grammar: null,
    word_count: 18,
    all_intersection_count: 12,
    new_words_count: 6,
  },
  {
    row_new_words: ["throes", "transition", "publication", "their", "digital", "strategy"],
    words: [
      "we",
      "are",
      "in",
      "the",
      "throes",
      "of",
      "a",
      "transition",
      "where",
      "every",
      "publication",
      "has",
      "to",
      "think",
      "their",
      "digital",
      "strategy",
    ],
    id: "We are in the throes of a transition where every publication has to think of their digital strategy.",
    author: "Bill Gates",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "nobody", "truth", "success", "future", "ready"],
    words: [
      "it",
      "needs",
      "time",
      "nobody",
      "wants",
      "to",
      "hear",
      "but",
      "that's",
      "the",
      "truth",
      "if",
      "you",
      "want",
      "have",
      "success",
      "in",
      "future",
      "be",
      "ready",
      "work",
      "now",
    ],
    id: "It needs time. Nobody wants to hear it, but that's the truth: if you want to have success in the future, you have to be ready to work now.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 22,
    all_intersection_count: 16,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "live", "by", "reason", "any", "themselves"],
    words: [
      "and",
      "all",
      "people",
      "live",
      "not",
      "by",
      "reason",
      "of",
      "any",
      "care",
      "they",
      "have",
      "for",
      "themselves",
      "but",
      "the",
      "love",
      "them",
      "that",
      "is",
      "in",
      "other",
    ],
    id: "And all people live, Not by reason of any care they have for themselves, But by the love for them that is in other people.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 22,
    all_intersection_count: 16,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "important", "thing", "inspire", "great", "whatever"],
    words: [
      "the",
      "most",
      "important",
      "thing",
      "is",
      "to",
      "try",
      "and",
      "inspire",
      "people",
      "so",
      "that",
      "they",
      "can",
      "be",
      "great",
      "in",
      "whatever",
      "want",
      "do",
    ],
    id: "The most important thing is to try and inspire people so that they can be great in whatever they want to do.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 20,
    all_intersection_count: 14,
    new_words_count: 6,
  },
  {
    row_new_words: ["steroids", "guarantee", "success", "i", "lot", "will"],
    words: [
      "steroids",
      "do",
      "not",
      "guarantee",
      "you're",
      "going",
      "to",
      "have",
      "success",
      "i",
      "think",
      "a",
      "lot",
      "of",
      "people",
      "that",
      "they",
      "will",
      "but",
      "don't",
    ],
    id: "Steroids do not guarantee you're going to have success. I think a lot of people think that they will, but they don't.",
    author: "Tony Gwynn",
    grammar: null,
    word_count: 20,
    all_intersection_count: 14,
    new_words_count: 6,
  },
  {
    row_new_words: ["friendship", "inexplicable", "should", "explained", "one", "kill"],
    words: [
      "friendship",
      "is",
      "inexplicable",
      "it",
      "should",
      "not",
      "be",
      "explained",
      "if",
      "one",
      "doesn't",
      "want",
      "to",
      "kill",
    ],
    id: "Friendship is inexplicable, it should not be explained if one doesn't want to kill it.",
    author: "Max Jacob",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["might", "find", "happiness", "quit", "struggling", "desperately"],
    words: [
      "some",
      "of",
      "us",
      "might",
      "find",
      "happiness",
      "if",
      "we",
      "quit",
      "struggling",
      "so",
      "desperately",
      "for",
      "it",
    ],
    id: "Some of us might find happiness if we quit struggling so desperately for it.",
    author: "William Feather",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "adults", "will", "living", "21st", "century"],
    words: [
      "learning",
      "is",
      "what",
      "most",
      "adults",
      "will",
      "do",
      "for",
      "a",
      "living",
      "in",
      "the",
      "21st",
      "century",
    ],
    id: "Learning is what most adults will do for a living in the 21st century.",
    author: "S. J. Perelman",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["confidence", "self", "twice", "defeated", "race", "life"],
    words: [
      "if",
      "you",
      "have",
      "no",
      "confidence",
      "in",
      "self",
      "are",
      "twice",
      "defeated",
      "the",
      "race",
      "of",
      "life",
    ],
    id: "If you have no confidence in self, you are twice defeated in the race of life.",
    author: "Marcus Garvey",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "which", "changes", "alter", "image", "retained"],
    words: [
      "time",
      "which",
      "changes",
      "people",
      "does",
      "not",
      "alter",
      "the",
      "image",
      "we",
      "have",
      "retained",
      "of",
      "them",
    ],
    id: "Time, which changes people, does not alter the image we have retained of them.",
    author: "Marcel Proust",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["distance", "hath", "continent", "its", "eyes", "stars"],
    words: [
      "love",
      "knows",
      "not",
      "distance",
      "it",
      "hath",
      "no",
      "continent",
      "its",
      "eyes",
      "are",
      "for",
      "the",
      "stars",
    ],
    id: "Love knows not distance; it hath no continent; its eyes are for the stars.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["penalty", "success", "bored", "by", "used", "snub"],
    words: [
      "the",
      "penalty",
      "of",
      "success",
      "is",
      "to",
      "be",
      "bored",
      "by",
      "people",
      "who",
      "used",
      "snub",
      "you",
    ],
    id: "The penalty of success is to be bored by people who used to snub you.",
    author: "Nancy Astor",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyday", "conquering", "fear", "learned", "secret", "life"],
    words: [
      "he",
      "who",
      "is",
      "not",
      "everyday",
      "conquering",
      "some",
      "fear",
      "has",
      "learned",
      "the",
      "secret",
      "of",
      "life",
    ],
    id: "He who is not everyday conquering some fear has not learned the secret of life.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["mickey", "symbol", "independence", "was", "means", "end"],
    words: [
      "mickey",
      "mouse",
      "is",
      "to",
      "me",
      "a",
      "symbol",
      "of",
      "independence",
      "he",
      "was",
      "means",
      "an",
      "end",
    ],
    id: "Mickey Mouse is, to me, a symbol of independence. He was a means to an end.",
    author: "Walt Disney",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["too", "beauty", "one", "nature's", "greatest", "healers"],
    words: [
      "we",
      "are",
      "learning",
      "too",
      "that",
      "the",
      "love",
      "of",
      "beauty",
      "is",
      "one",
      "nature's",
      "greatest",
      "healers",
    ],
    id: "We are learning, too, that the love of beauty is one of Nature's greatest healers.",
    author: "Ellsworth Huntington",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["honors", "world", "puff", "emptiness", "peril", "falling"],
    words: [
      "the",
      "honors",
      "of",
      "this",
      "world",
      "what",
      "are",
      "they",
      "but",
      "puff",
      "and",
      "emptiness",
      "peril",
      "falling",
    ],
    id: "The honors of this world, what are they but puff, and emptiness, and peril of falling?",
    author: "Saint Augustine",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["twice", "thrice", "over", "as", "repeat", "review"],
    words: [
      "twice",
      "and",
      "thrice",
      "over",
      "as",
      "they",
      "say",
      "good",
      "is",
      "it",
      "to",
      "repeat",
      "review",
      "what",
    ],
    id: "Twice and thrice over, as they say, good is it to repeat and review what is good.",
    author: "Plato",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["constitution", "only", "right", "pursue", "happiness", "yourself"],
    words: [
      "the",
      "constitution",
      "only",
      "gives",
      "people",
      "right",
      "to",
      "pursue",
      "happiness",
      "you",
      "have",
      "catch",
      "it",
      "yourself",
    ],
    id: "The Constitution only gives people the right to pursue happiness. You have to catch it yourself.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "your", "best", "all", "isn't", "enough"],
    words: [
      "you",
      "can",
      "only",
      "do",
      "your",
      "best",
      "that's",
      "all",
      "and",
      "if",
      "it",
      "isn't",
      "good",
      "enough",
    ],
    id: "You can only do your best. That's all you can do. And if it isn't good enough, it isn't good enough.",
    author: "Imelda Staunton",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "grateful", "always", "matter", "form", "takes"],
    words: [
      "i'm",
      "grateful",
      "for",
      "always",
      "this",
      "moment",
      "the",
      "now",
      "no",
      "matter",
      "what",
      "form",
      "it",
      "takes",
    ],
    id: "I'm grateful for always this moment, the now, no matter what form it takes.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["straying", "away", "from", "church", "back", "god"],
    words: [
      "every",
      "day",
      "people",
      "are",
      "straying",
      "away",
      "from",
      "the",
      "church",
      "and",
      "going",
      "back",
      "to",
      "god",
    ],
    id: "Every day people are straying away from the church and going back to God.",
    author: "Lenny Bruce",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "beats", "rug", "blows", "against", "dust"],
    words: [
      "when",
      "someone",
      "beats",
      "a",
      "rug",
      "the",
      "blows",
      "are",
      "not",
      "against",
      "but",
      "dust",
      "in",
      "it",
    ],
    id: "When someone beats a rug, the blows are not against the rug, but against the dust in it.",
    author: "Rumi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["worst", "thing", "men", "when", "drunk", "sober"],
    words: [
      "the",
      "worst",
      "thing",
      "about",
      "some",
      "men",
      "is",
      "that",
      "when",
      "they",
      "are",
      "not",
      "drunk",
      "sober",
    ],
    id: "The worst thing about some men is that when they are not drunk they are sober.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["life's", "play", "length", "excellence", "acting", "matters"],
    words: [
      "life's",
      "like",
      "a",
      "play",
      "it's",
      "not",
      "the",
      "length",
      "but",
      "excellence",
      "of",
      "acting",
      "that",
      "matters",
    ],
    id: "Life's like a play: it's not the length, but the excellence of the acting that matters.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["faith", "things", "seen", "hope", "at", "hand"],
    words: [
      "faith",
      "has",
      "to",
      "do",
      "with",
      "things",
      "that",
      "are",
      "not",
      "seen",
      "and",
      "hope",
      "at",
      "hand",
    ],
    id: "Faith has to do with things that are not seen and hope with things that are not at hand.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["audience", "chance", "will", "half", "your", "acting"],
    words: [
      "if",
      "you",
      "give",
      "an",
      "audience",
      "a",
      "chance",
      "they",
      "will",
      "do",
      "half",
      "your",
      "acting",
      "for",
    ],
    id: "If you give an audience a chance they will do half your acting for you.",
    author: "William Hazlitt",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "make", "life", "less", "difficult", "each"],
    words: [
      "what",
      "do",
      "we",
      "live",
      "for",
      "if",
      "not",
      "to",
      "make",
      "life",
      "less",
      "difficult",
      "each",
      "other",
    ],
    id: "What do we live for, if not to make life less difficult for each other?",
    author: "George Eliot",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "wife", "sex", "back", "car", "drive"],
    words: [
      "my",
      "wife",
      "wants",
      "sex",
      "in",
      "the",
      "back",
      "of",
      "car",
      "and",
      "she",
      "me",
      "to",
      "drive",
    ],
    id: "My wife wants sex in the back of the car and she wants me to drive.",
    author: "Rodney Dangerfield",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "pathetic", "person", "world", "sight", "vision"],
    words: [
      "the",
      "most",
      "pathetic",
      "person",
      "in",
      "world",
      "is",
      "someone",
      "who",
      "has",
      "sight",
      "but",
      "no",
      "vision",
    ],
    id: "The most pathetic person in the world is someone who has sight, but has no vision.",
    author: "Helen Keller",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["identify", "our", "experience", "differentiation", "between", "happens"],
    words: [
      "we",
      "identify",
      "in",
      "our",
      "experience",
      "a",
      "differentiation",
      "between",
      "what",
      "do",
      "and",
      "happens",
      "to",
      "us",
    ],
    id: "We identify in our experience a differentiation between what we do and what happens to us.",
    author: "Alan Watts",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "obscenity", "matter", "taste", "eye", "beholder"],
    words: [
      "it's",
      "true",
      "that",
      "obscenity",
      "is",
      "a",
      "matter",
      "of",
      "taste",
      "and",
      "in",
      "the",
      "eye",
      "beholder",
    ],
    id: "It's true that obscenity is a matter of taste and in the eye of the beholder.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["teacher", "inspire", "hope", "ignite", "imagination", "instill"],
    words: [
      "a",
      "good",
      "teacher",
      "can",
      "inspire",
      "hope",
      "ignite",
      "the",
      "imagination",
      "and",
      "instill",
      "love",
      "of",
      "learning",
    ],
    id: "A good teacher can inspire hope, ignite the imagination, and instill a love of learning.",
    author: "Brad Henry",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "knowing", "tomato", "wisdom", "put", "salad"],
    words: [
      "knowledge",
      "is",
      "knowing",
      "that",
      "a",
      "tomato",
      "fruit",
      "wisdom",
      "not",
      "to",
      "put",
      "it",
      "in",
      "salad",
    ],
    id: "Knowledge is knowing that a tomato is a fruit. Wisdom is knowing not to put it in a fruit salad.",
    author: "Brian O'Driscoll",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["fear", "stating", "obvious", "freedom", "belongs", "politicians"],
    words: [
      "with",
      "fear",
      "of",
      "stating",
      "the",
      "obvious",
      "freedom",
      "belongs",
      "to",
      "we",
      "people",
      "not",
      "they",
      "politicians",
    ],
    id: "With fear of stating the obvious: Freedom belongs to 'We the People,' not 'They the Politicians.'",
    author: "John Ridley",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["anytime", "come", "team", "player", "there", "curve"],
    words: [
      "anytime",
      "you",
      "come",
      "to",
      "a",
      "new",
      "team",
      "and",
      "with",
      "player",
      "there",
      "is",
      "learning",
      "curve",
    ],
    id: "Anytime you come to a new team and with a new player there is a learning curve.",
    author: "Robin Lopez",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["exercise", "bunk", "healthy", "sick", "should", "take"],
    words: [
      "exercise",
      "is",
      "bunk",
      "if",
      "you",
      "are",
      "healthy",
      "don't",
      "need",
      "it",
      "sick",
      "should",
      "not",
      "take",
    ],
    id: "Exercise is bunk. If you are healthy, you don't need it: if you are sick you should not take it.",
    author: "Henry Ford",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["measure", "health", "adjusted", "profoundly", "sick", "society"],
    words: [
      "it",
      "is",
      "no",
      "measure",
      "of",
      "health",
      "to",
      "be",
      "well",
      "adjusted",
      "a",
      "profoundly",
      "sick",
      "society",
    ],
    id: "It is no measure of health to be well adjusted to a profoundly sick society.",
    author: "Jiddu Krishnamurti",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "part", "wisdom", "knowing", "anymore", "letting"],
    words: [
      "one",
      "part",
      "of",
      "wisdom",
      "is",
      "knowing",
      "what",
      "you",
      "don't",
      "need",
      "anymore",
      "and",
      "letting",
      "it",
      "go",
    ],
    id: "One part of wisdom is knowing what you don't need anymore and letting it go.",
    author: "Jane Fonda",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["greatest", "number", "which", "measure", "right", "wrong"],
    words: [
      "it",
      "is",
      "the",
      "greatest",
      "good",
      "to",
      "number",
      "of",
      "people",
      "which",
      "measure",
      "right",
      "and",
      "wrong",
    ],
    id: "It is the greatest good to the greatest number of people which is the measure of right and wrong.",
    author: "Jeremy Bentham",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["thing", "uneducated", "man", "read", "books", "quotations"],
    words: [
      "it",
      "is",
      "a",
      "good",
      "thing",
      "for",
      "an",
      "uneducated",
      "man",
      "to",
      "read",
      "books",
      "of",
      "quotations",
    ],
    id: "It is a good thing for an uneducated man to read books of quotations.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "create", "problems", "through", "ignorance", "solve"],
    words: [
      "if",
      "knowledge",
      "can",
      "create",
      "problems",
      "it",
      "is",
      "not",
      "through",
      "ignorance",
      "that",
      "we",
      "solve",
      "them",
    ],
    id: "If knowledge can create problems, it is not through ignorance that we can solve them.",
    author: "Isaac Asimov",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "given", "second", "chance", "i'm", "waste"],
    words: [
      "god",
      "has",
      "given",
      "me",
      "a",
      "second",
      "chance",
      "and",
      "i'm",
      "not",
      "going",
      "to",
      "waste",
      "it",
    ],
    id: "God has given me a second chance and I'm not going to waste it.",
    author: "Manny Ramirez",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["mental", "health", "your", "name", "or", "living"],
    words: [
      "mental",
      "health",
      "doesn't",
      "care",
      "what",
      "your",
      "name",
      "is",
      "or",
      "you",
      "do",
      "for",
      "a",
      "living",
    ],
    id: "Mental health doesn't care what your name is or what you do for a living.",
    author: "Mardy Fish",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "ever", "steps", "same", "river", "twice"],
    words: [
      "no",
      "man",
      "ever",
      "steps",
      "in",
      "the",
      "same",
      "river",
      "twice",
      "for",
      "it's",
      "not",
      "and",
      "he's",
    ],
    id: "No man ever steps in the same river twice, for it's not the same river and he's not the same man.",
    author: "Heraclitus",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "human", "race", "future", "into", "space"],
    words: [
      "i",
      "think",
      "the",
      "human",
      "race",
      "doesn't",
      "have",
      "a",
      "future",
      "if",
      "it",
      "go",
      "into",
      "space",
    ],
    id: "I think the human race doesn't have a future if it doesn't go into space.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'd", "play", "guitar", "would", "confusing", "sighted"],
    words: [
      "i'd",
      "think",
      "learning",
      "to",
      "play",
      "the",
      "guitar",
      "would",
      "be",
      "very",
      "confusing",
      "for",
      "sighted",
      "people",
    ],
    id: "I'd think learning to play the guitar would be very confusing for sighted people.",
    author: "Doc Watson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["tax", "more", "than", "wise", "given", "men"],
    words: [
      "to",
      "tax",
      "and",
      "please",
      "no",
      "more",
      "than",
      "love",
      "be",
      "wise",
      "is",
      "not",
      "given",
      "men",
    ],
    id: "To tax and to please, no more than to love and to be wise, is not given to men.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "enjoy", "time", "passing", "privilege", "friendship"],
    words: [
      "i",
      "enjoy",
      "the",
      "time",
      "passing",
      "think",
      "it's",
      "a",
      "privilege",
      "to",
      "be",
      "in",
      "friendship",
      "with",
    ],
    id: "I enjoy the time passing. I think it's a privilege to be in friendship with time.",
    author: "Agnes Varda",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "fragile", "guaranteed", "everything", "you've", "got"],
    words: [
      "life",
      "is",
      "fragile",
      "we're",
      "not",
      "guaranteed",
      "a",
      "tomorrow",
      "so",
      "give",
      "it",
      "everything",
      "you've",
      "got",
    ],
    id: "Life is fragile. We're not guaranteed a tomorrow so give it everything you've got.",
    author: "Tim Cook",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "dishes", "night", "-", "volunteer", "way"],
    words: [
      "i",
      "do",
      "the",
      "dishes",
      "every",
      "night",
      "-",
      "other",
      "people",
      "volunteer",
      "but",
      "like",
      "way",
      "it",
    ],
    id: "I do the dishes every night - other people volunteer, but I like the way I do it.",
    author: "Bill Gates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["things", "prosperity", "wished", "belong", "adversity", "admired"],
    words: [
      "the",
      "good",
      "things",
      "of",
      "prosperity",
      "are",
      "to",
      "be",
      "wished",
      "but",
      "that",
      "belong",
      "adversity",
      "admired",
    ],
    id: "The good things of prosperity are to be wished; but the good things that belong to adversity are to be admired.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "honored", "part", "sony", "atv", "family"],
    words: [
      "i",
      "am",
      "very",
      "happy",
      "and",
      "honored",
      "to",
      "be",
      "part",
      "of",
      "the",
      "sony",
      "atv",
      "family",
    ],
    id: "I am very happy and honored to be part of the Sony ATV family.",
    author: "Maluma",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "cancer", "whole", "family", "everyone", "too"],
    words: [
      "when",
      "someone",
      "has",
      "cancer",
      "the",
      "whole",
      "family",
      "and",
      "everyone",
      "who",
      "loves",
      "them",
      "does",
      "too",
    ],
    id: "When someone has cancer, the whole family and everyone who loves them does, too.",
    author: "Terri Clark",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["pfft", "i", "hate", "christmas", "children", "families"],
    words: [
      "pfft",
      "i",
      "hate",
      "christmas",
      "day",
      "it's",
      "for",
      "children",
      "and",
      "families",
      "not",
      "people",
      "like",
      "me",
    ],
    id: "Pfft, I hate Christmas Day. It's for children and families. Not for people like me.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["up", "airplane", "high", "enough", "even", "exist"],
    words: [
      "go",
      "up",
      "in",
      "an",
      "airplane",
      "high",
      "enough",
      "and",
      "it's",
      "like",
      "we",
      "don't",
      "even",
      "exist",
    ],
    id: "Go up in an airplane. Go high enough, and it's like we don't even exist.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["research", "shown", "best", "way", "make", "each"],
    words: [
      "research",
      "has",
      "shown",
      "that",
      "the",
      "best",
      "way",
      "to",
      "be",
      "happy",
      "is",
      "make",
      "each",
      "day",
    ],
    id: "Research has shown that the best way to be happy is to make each day happy.",
    author: "Deepak Chopra",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["quality", "our", "which", "will", "god", "quantity"],
    words: [
      "it",
      "is",
      "the",
      "quality",
      "of",
      "our",
      "work",
      "which",
      "will",
      "please",
      "god",
      "and",
      "not",
      "quantity",
    ],
    id: "It is the quality of our work which will please God and not the quantity.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["without", "architecture", "olives", "nor", "wines", "cellar"],
    words: [
      "they",
      "can",
      "do",
      "without",
      "architecture",
      "who",
      "have",
      "no",
      "olives",
      "nor",
      "wines",
      "in",
      "the",
      "cellar",
    ],
    id: "They can do without architecture who have no olives nor wines in the cellar.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "enthusiastic", "feel", "positive", "energy", "simple"],
    words: [
      "when",
      "you",
      "are",
      "enthusiastic",
      "about",
      "what",
      "do",
      "feel",
      "this",
      "positive",
      "energy",
      "it's",
      "very",
      "simple",
    ],
    id: "When you are enthusiastic about what you do, you feel this positive energy. It's very simple.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["opposition", "disarms", "refuses", "disarm", "shall", "ourselves"],
    words: [
      "if",
      "the",
      "opposition",
      "disarms",
      "well",
      "and",
      "good",
      "it",
      "refuses",
      "to",
      "disarm",
      "we",
      "shall",
      "ourselves",
    ],
    id: "If the opposition disarms, well and good. If it refuses to disarm, we shall disarm it ourselves.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["fear", "eccentric", "opinion", "accepted", "was", "once"],
    words: [
      "do",
      "not",
      "fear",
      "to",
      "be",
      "eccentric",
      "in",
      "opinion",
      "for",
      "every",
      "now",
      "accepted",
      "was",
      "once",
    ],
    id: "Do not fear to be eccentric in opinion, for every opinion now accepted was once eccentric.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "evil", "prove", "its", "purity", "above"],
    words: [
      "there",
      "has",
      "to",
      "be",
      "evil",
      "so",
      "that",
      "good",
      "can",
      "prove",
      "its",
      "purity",
      "above",
      "it",
    ],
    id: "There has to be evil so that good can prove its purity above it.",
    author: "Unknown",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["end", "life", "god", "soul", "following", "will"],
    words: [
      "the",
      "end",
      "of",
      "life",
      "is",
      "to",
      "be",
      "like",
      "god",
      "and",
      "soul",
      "following",
      "will",
      "him",
    ],
    id: "The end of life is to be like God, and the soul following God will be like Him.",
    author: "Socrates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "feel", "inspire", "more", "than", "remember"],
    words: [
      "i",
      "feel",
      "like",
      "inspire",
      "more",
      "people",
      "than",
      "think",
      "do",
      "just",
      "need",
      "to",
      "remember",
      "that",
    ],
    id: "I feel like I inspire more people than I think I do. I just need to remember that.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["remarkable", "how", "closely", "history", "connected", "man"],
    words: [
      "it",
      "is",
      "remarkable",
      "how",
      "closely",
      "the",
      "history",
      "of",
      "apple",
      "tree",
      "connected",
      "with",
      "that",
      "man",
    ],
    id: "It is remarkable how closely the history of the apple tree is connected with that of man.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "idea", "vision", "my", "own", "success"],
    words: [
      "i",
      "have",
      "an",
      "idea",
      "of",
      "who",
      "want",
      "to",
      "be",
      "a",
      "vision",
      "my",
      "own",
      "success",
    ],
    id: "I have an idea of who I want to be, I have a vision of my own success.",
    author: "Wiz Khalifa",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["willing", "risk", "unusual", "will", "settle", "ordinary"],
    words: [
      "if",
      "you",
      "are",
      "not",
      "willing",
      "to",
      "risk",
      "the",
      "unusual",
      "will",
      "have",
      "settle",
      "for",
      "ordinary",
    ],
    id: "If you are not willing to risk the unusual, you will have to settle for the ordinary.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["way", "gain", "reputation", "endeavor", "desire", "appear"],
    words: [
      "the",
      "way",
      "to",
      "gain",
      "a",
      "good",
      "reputation",
      "is",
      "endeavor",
      "be",
      "what",
      "you",
      "desire",
      "appear",
    ],
    id: "The way to gain a good reputation is to endeavor to be what you desire to appear.",
    author: "Socrates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "found", "irish", "bit", "odd", "refuse"],
    words: [
      "we",
      "have",
      "always",
      "found",
      "the",
      "irish",
      "a",
      "bit",
      "odd",
      "they",
      "refuse",
      "to",
      "be",
      "english",
    ],
    id: "We have always found the Irish a bit odd. They refuse to be English.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["much", "easier", "pray", "bore", "than", "one"],
    words: [
      "it's",
      "so",
      "much",
      "easier",
      "to",
      "pray",
      "for",
      "a",
      "bore",
      "than",
      "go",
      "and",
      "see",
      "one",
    ],
    id: "It's so much easier to pray for a bore than to go and see one.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["lot", "legends", "come", "before", "my", "time"],
    words: [
      "a",
      "lot",
      "of",
      "legends",
      "people",
      "have",
      "come",
      "before",
      "me",
      "but",
      "this",
      "is",
      "my",
      "time",
    ],
    id: "A lot of legends, a lot of people, have come before me. But this is my time.",
    author: "Usain Bolt",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "god", "judge", "goes", "heaven", "hell"],
    words: [
      "i'm",
      "going",
      "to",
      "let",
      "god",
      "be",
      "the",
      "judge",
      "of",
      "who",
      "goes",
      "heaven",
      "and",
      "hell",
    ],
    id: "I'm going to let God be the judge of who goes to heaven and hell.",
    author: "Joel Osteen",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["chance", "both", "artistically", "appreciated", "commercially", "hope"],
    words: [
      "the",
      "chance",
      "to",
      "be",
      "both",
      "artistically",
      "appreciated",
      "and",
      "commercially",
      "that's",
      "what",
      "you",
      "hope",
      "for",
    ],
    id: "The chance to be both artistically appreciated and commercially appreciated... That's what you hope for.",
    author: "Gerard Butler",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["difference", "make", "how", "much", "amounts", "more"],
    words: [
      "what",
      "difference",
      "does",
      "it",
      "make",
      "how",
      "much",
      "you",
      "have",
      "do",
      "not",
      "amounts",
      "to",
      "more",
    ],
    id: "What difference does it make how much you have? What you do not have amounts to much more.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["dislike", "same", "things", "makes", "solid", "friendship"],
    words: [
      "to",
      "like",
      "and",
      "dislike",
      "the",
      "same",
      "things",
      "this",
      "is",
      "what",
      "makes",
      "a",
      "solid",
      "friendship",
    ],
    id: "To like and dislike the same things, this is what makes a solid friendship.",
    author: "Sallust",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["cut", "your", "own", "throat", "come", "bandage"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "cut",
      "your",
      "own",
      "throat",
      "don't",
      "come",
      "me",
      "for",
      "a",
      "bandage",
    ],
    id: "If you want to cut your own throat, don't come to me for a bandage.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "defensive", "player", "year", "chance", "mvp"],
    words: [
      "i",
      "want",
      "to",
      "be",
      "the",
      "defensive",
      "player",
      "of",
      "year",
      "and",
      "if",
      "have",
      "chance",
      "mvp",
    ],
    id: "I want to be the Defensive Player of the Year and, if I have the chance, be MVP.",
    author: "Joel Embiid",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["can't", "civilization", "advance", "war", "kill", "way"],
    words: [
      "you",
      "can't",
      "say",
      "civilization",
      "don't",
      "advance",
      "in",
      "every",
      "war",
      "they",
      "kill",
      "a",
      "new",
      "way",
    ],
    id: "You can't say civilization don't advance... in every war they kill you in a new way.",
    author: "Will Rogers",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "being", "anchor", "as", "chance", "yourself"],
    words: [
      "i",
      "love",
      "being",
      "an",
      "anchor",
      "as",
      "it",
      "gives",
      "you",
      "a",
      "chance",
      "to",
      "be",
      "yourself",
    ],
    id: "I love being an anchor, as it gives you a chance to be yourself.",
    author: "Rithvik Dhanjani",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["really", "solid", "foundation", "friends", "family", "keep"],
    words: [
      "you",
      "need",
      "a",
      "really",
      "solid",
      "foundation",
      "of",
      "friends",
      "and",
      "family",
      "to",
      "keep",
      "where",
      "be",
    ],
    id: "You need a really solid foundation of friends and family to keep you where you need to be.",
    author: "Lilly Singh",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "natural-born", "actor", "been", "slow", "curve"],
    words: [
      "i'm",
      "not",
      "a",
      "natural-born",
      "actor",
      "so",
      "it's",
      "been",
      "very",
      "slow",
      "learning",
      "curve",
      "for",
      "me",
    ],
    id: "I'm not a natural-born actor. So it's been a very slow learning curve for me.",
    author: "John Cho",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["world", "hastening", "toward", "i", "viewing", "contempt"],
    words: [
      "what",
      "is",
      "this",
      "world",
      "that",
      "hastening",
      "me",
      "toward",
      "i",
      "know",
      "not",
      "viewing",
      "with",
      "contempt",
    ],
    id: "What is this world that is hastening me toward I know not what, viewing me with contempt?",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["round", "ring", "suppose", "secret", "sits", "middle"],
    words: [
      "we",
      "dance",
      "round",
      "in",
      "a",
      "ring",
      "and",
      "suppose",
      "but",
      "the",
      "secret",
      "sits",
      "middle",
      "knows",
    ],
    id: "We dance round in a ring and suppose, but the secret sits in the middle and knows.",
    author: "Robert Frost",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["make", "movies", "extraordinary", "those", "huge", "journey"],
    words: [
      "you",
      "want",
      "to",
      "make",
      "movies",
      "about",
      "extraordinary",
      "people",
      "but",
      "those",
      "have",
      "a",
      "huge",
      "journey",
    ],
    id: "You want to make movies about extraordinary people, but those extraordinary people have to have a huge journey.",
    author: "Marielle Heller",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["become", "familiar", "nature", "through", "will", "never"],
    words: [
      "he",
      "who",
      "does",
      "not",
      "become",
      "familiar",
      "with",
      "nature",
      "through",
      "love",
      "will",
      "never",
      "know",
      "her",
    ],
    id: "He who does not become familiar with nature through love will never know her.",
    author: "Karl Wilhelm Friedrich Schlegel",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["nobody", "guaranteed", "why", "goals", "cool", "motivational"],
    words: [
      "nobody",
      "is",
      "guaranteed",
      "tomorrow",
      "but",
      "why",
      "not",
      "have",
      "goals",
      "that",
      "are",
      "cool",
      "and",
      "motivational",
    ],
    id: "Nobody is guaranteed tomorrow, but why not have goals that are cool and motivational?",
    author: "Morten Andersen",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "three", "classes", "those", "when", "shown"],
    words: [
      "there",
      "are",
      "three",
      "classes",
      "of",
      "people",
      "those",
      "who",
      "see",
      "when",
      "they",
      "shown",
      "do",
      "not",
    ],
    id: "There are three classes of people: those who see, those who see when they are shown, those who do not see.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "chess", "match", "decision", "make", "consequence"],
    words: [
      "life",
      "is",
      "a",
      "chess",
      "match",
      "every",
      "decision",
      "that",
      "you",
      "make",
      "has",
      "consequence",
      "to",
      "it",
    ],
    id: "Life is a chess match. Every decision that you make has a consequence to it.",
    author: "P.K. Subban",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "wisdom", "cannot", "measured", "garden", "there"],
    words: [
      "the",
      "time",
      "of",
      "wisdom",
      "cannot",
      "be",
      "measured",
      "and",
      "for",
      "me",
      "is",
      "garden",
      "there",
      "no",
      "in",
    ],
    id: "The time of wisdom cannot be measured, and for me, wisdom is the garden. There is no time in the garden.",
    author: "W. S. Merwin",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["mr", "attlee", "modest", "man", "indeed", "lot"],
    words: [
      "mr",
      "attlee",
      "is",
      "a",
      "very",
      "modest",
      "man",
      "indeed",
      "he",
      "has",
      "lot",
      "to",
      "be",
      "about",
    ],
    id: "Mr. Attlee is a very modest man. Indeed he has a lot to be modest about.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["might", "get", "all", "world", "must", "certainly"],
    words: [
      "people",
      "might",
      "not",
      "get",
      "all",
      "they",
      "work",
      "for",
      "in",
      "this",
      "world",
      "but",
      "must",
      "certainly",
    ],
    id: "People might not get all they work for in this world, but they must certainly work for all they get.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "somehow", "really", "then", "live", "decision"],
    words: [
      "i",
      "think",
      "that",
      "somehow",
      "we",
      "learn",
      "who",
      "really",
      "are",
      "and",
      "then",
      "live",
      "with",
      "decision",
    ],
    id: "I think that somehow, we learn who we really are and then live with that decision.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["everybody", "comfortable", "idea", "politics", "guilty", "addiction"],
    words: [
      "not",
      "everybody",
      "is",
      "comfortable",
      "with",
      "the",
      "idea",
      "that",
      "politics",
      "a",
      "guilty",
      "addiction",
      "but",
      "it",
    ],
    id: "Not everybody is comfortable with the idea that politics is a guilty addiction. But it is.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["aren't", "respected", "by", "how", "friendship", "valued"],
    words: [
      "if",
      "you",
      "aren't",
      "respected",
      "by",
      "someone",
      "how",
      "can",
      "the",
      "friendship",
      "with",
      "them",
      "be",
      "valued",
    ],
    id: "If you aren't respected by someone, how can the friendship with them be valued?",
    author: "Sudeep",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["jail", "zone", "out", "thing", "cheat", "code"],
    words: [
      "in",
      "jail",
      "you",
      "just",
      "zone",
      "out",
      "so",
      "that's",
      "the",
      "thing",
      "like",
      "a",
      "cheat",
      "code",
    ],
    id: "In jail, you just zone out, so that's the thing. That's like a cheat code.",
    author: "King Von",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "things", "bad", "there's", "come", "from"],
    words: [
      "when",
      "things",
      "are",
      "going",
      "bad",
      "there's",
      "to",
      "be",
      "some",
      "good",
      "that's",
      "come",
      "from",
      "it",
    ],
    id: "When things are going bad, there's going to be some good that's going to come from it.",
    author: "Jocko Willink",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "feel", "freedom", "make", "mistakes", "things"],
    words: [
      "i",
      "need",
      "to",
      "feel",
      "like",
      "have",
      "that",
      "freedom",
      "make",
      "mistakes",
      "and",
      "just",
      "try",
      "things",
    ],
    id: "I need to feel like I have that freedom to make mistakes and to just try things.",
    author: "Megan Rapinoe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["problem", "socialism", "eventually", "run", "out", "peoples"],
    words: [
      "the",
      "problem",
      "with",
      "socialism",
      "is",
      "that",
      "you",
      "eventually",
      "run",
      "out",
      "of",
      "other",
      "peoples",
      "money",
    ],
    id: "The problem with socialism is that you eventually run out of other peoples' money.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "friends", "get", "yourself", "jail", "sentence"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "know",
      "who",
      "your",
      "friends",
      "are",
      "get",
      "yourself",
      "a",
      "jail",
      "sentence",
    ],
    id: "If you want to know who your friends are, get yourself a jail sentence.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "through", "listening", "i", "never", "stop"],
    words: [
      "it's",
      "only",
      "through",
      "listening",
      "that",
      "you",
      "learn",
      "and",
      "i",
      "never",
      "want",
      "to",
      "stop",
      "learning",
    ],
    id: "It's only through listening that you learn, and I never want to stop learning.",
    author: "Drew Barrymore",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "subject", "old", "something", "cannot", "said"],
    words: [
      "there",
      "is",
      "no",
      "subject",
      "so",
      "old",
      "that",
      "something",
      "new",
      "cannot",
      "be",
      "said",
      "about",
      "it",
    ],
    id: "There is no subject so old that something new cannot be said about it.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "10000", "explanations", "failure", "explanation", "success"],
    words: [
      "i",
      "think",
      "you",
      "can",
      "have",
      "10000",
      "explanations",
      "for",
      "failure",
      "but",
      "no",
      "good",
      "explanation",
      "success",
    ],
    id: "I think you can have 10,000 explanations for failure, but no good explanation for success.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "lot", "movies", "i", "especially", "remakes"],
    words: [
      "there",
      "are",
      "a",
      "lot",
      "of",
      "movies",
      "that",
      "i",
      "don't",
      "care",
      "about",
      "especially",
      "not",
      "remakes",
    ],
    id: "There are a lot of movies that I don't care about, especially not remakes.",
    author: "John Carpenter",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["universe", "as", "joint", "product", "observer", "observed"],
    words: [
      "the",
      "universe",
      "as",
      "we",
      "know",
      "it",
      "is",
      "a",
      "joint",
      "product",
      "of",
      "observer",
      "and",
      "observed",
    ],
    id: "The universe as we know it is a joint product of the observer and the observed.",
    author: "Pierre Teilhard de Chardin",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["courage", "knowledge", "how", "fear", "ought", "feared"],
    words: [
      "courage",
      "is",
      "the",
      "knowledge",
      "of",
      "how",
      "to",
      "fear",
      "what",
      "ought",
      "be",
      "feared",
      "and",
      "not",
    ],
    id: "Courage is... the knowledge of how to fear what ought to be feared and how not to fear what ought not to be feared.",
    author: "David Ben-Gurion",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["after", "all", "lie", "tis", "truth", "masquerade"],
    words: [
      "and",
      "after",
      "all",
      "what",
      "is",
      "a",
      "lie",
      "tis",
      "but",
      "the",
      "truth",
      "in",
      "masquerade",
    ],
    id: "And, after all, what is a lie? 'Tis but the truth in a masquerade.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["hard", "life", "i", "could", "would", "all"],
    words: [
      "it's",
      "a",
      "hard",
      "life",
      "but",
      "if",
      "i",
      "could",
      "would",
      "do",
      "it",
      "all",
      "again",
    ],
    id: "It's a hard life... but if I could, I would do it all again.",
    author: "Natalia Makarova",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["expecting", "greatest", "impediment", "living", "anticipation", "loses"],
    words: [
      "expecting",
      "is",
      "the",
      "greatest",
      "impediment",
      "to",
      "living",
      "in",
      "anticipation",
      "of",
      "tomorrow",
      "it",
      "loses",
      "today",
    ],
    id: "Expecting is the greatest impediment to living. In anticipation of tomorrow, it loses today.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["believes", "freedom", "will", "never", "loved", "hated"],
    words: [
      "he",
      "who",
      "believes",
      "in",
      "freedom",
      "of",
      "the",
      "will",
      "has",
      "never",
      "loved",
      "and",
      "hated",
    ],
    id: "He who believes in freedom of the will has never loved and never hated.",
    author: "Marie von Ebner-Eschenbach",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "tale", "life", "how", "long", "matters"],
    words: [
      "as",
      "is",
      "a",
      "tale",
      "so",
      "life",
      "not",
      "how",
      "long",
      "it",
      "but",
      "good",
      "what",
      "matters",
    ],
    id: "As is a tale, so is life: not how long it is, but how good it is, is what matters.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["there's", "american", "country", "free", "until", "one"],
    words: [
      "there's",
      "not",
      "an",
      "american",
      "in",
      "this",
      "country",
      "free",
      "until",
      "every",
      "one",
      "of",
      "us",
      "is",
    ],
    id: "There's not an American in this country free until every one of us is free.",
    author: "Jackie Robinson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["tax", "collector", "must", "poor", "creating", "many"],
    words: [
      "the",
      "tax",
      "collector",
      "must",
      "love",
      "poor",
      "people",
      "he's",
      "creating",
      "so",
      "many",
      "of",
      "them",
    ],
    id: "The tax collector must love poor people, he's creating so many of them.",
    author: "Bill Vaughan",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "his", "own", "heart", "dares", "mind"],
    words: [
      "every",
      "one",
      "speaks",
      "well",
      "of",
      "his",
      "own",
      "heart",
      "but",
      "no",
      "dares",
      "speak",
      "mind",
    ],
    id: "Every one speaks well of his own heart, but no one dares speak well of his own mind.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "failure", "thinks", "success", "winner", "then"],
    words: [
      "no",
      "man",
      "can",
      "be",
      "a",
      "failure",
      "if",
      "he",
      "thinks",
      "he's",
      "success",
      "is",
      "winner",
      "then",
    ],
    id: "No man can be a failure if he thinks he's a success; If he thinks he is a winner, then he is.",
    author: "Robert W. Service",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["business", "scared", "then", "better", "something", "else"],
    words: [
      "if",
      "you're",
      "in",
      "this",
      "business",
      "and",
      "you",
      "are",
      "scared",
      "then",
      "better",
      "do",
      "something",
      "else",
    ],
    id: "If you're in this business and you are scared, then you better do something else.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["gaffe", "washington", "telling", "truth", "never", "hurt"],
    words: [
      "a",
      "gaffe",
      "in",
      "washington",
      "is",
      "someone",
      "telling",
      "the",
      "truth",
      "and",
      "has",
      "never",
      "hurt",
      "me",
    ],
    id: "A gaffe in Washington is someone telling the truth, and telling the truth has never hurt me.",
    author: "Joe Biden",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "important", "except", "impact", "on", "lives"],
    words: [
      "a",
      "life",
      "is",
      "not",
      "important",
      "except",
      "in",
      "the",
      "impact",
      "it",
      "has",
      "on",
      "other",
      "lives",
    ],
    id: "A life is not important except in the impact it has on other lives.",
    author: "Jackie Robinson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["richer", "man", "seen", "cannot", "or", "being"],
    words: [
      "who",
      "is",
      "richer",
      "the",
      "man",
      "seen",
      "but",
      "cannot",
      "see",
      "or",
      "not",
      "being",
      "can",
    ],
    id: "Who is richer? The man who is seen, but cannot see? Or the man who is not being seen, but can see?",
    author: "Babe Ruth",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["before", "your", "example", "laborious", "their", "affairs"],
    words: [
      "go",
      "before",
      "the",
      "people",
      "with",
      "your",
      "example",
      "and",
      "be",
      "laborious",
      "in",
      "their",
      "affairs",
    ],
    id: "Go before the people with your example, and be laborious in their affairs.",
    author: "Confucius",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["telling", "american", "patience", "courage", "resolve", "determination"],
    words: [
      "we",
      "are",
      "telling",
      "the",
      "american",
      "people",
      "to",
      "have",
      "patience",
      "courage",
      "resolve",
      "and",
      "determination",
    ],
    id: "We are telling the American people to have patience, courage, resolve and determination.",
    author: "Muammar al-Gaddafi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "common", "among", "each", "ideal", "happiness"],
    words: [
      "life",
      "in",
      "common",
      "among",
      "people",
      "who",
      "love",
      "each",
      "other",
      "is",
      "the",
      "ideal",
      "of",
      "happiness",
    ],
    id: "Life in common among people who love each other is the ideal of happiness.",
    author: "George Sand",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["philly", "i", "had", "scorer", "chance", "win"],
    words: [
      "in",
      "philly",
      "i",
      "had",
      "to",
      "be",
      "a",
      "scorer",
      "for",
      "us",
      "have",
      "chance",
      "win",
    ],
    id: "In Philly, I had to be a scorer for us to have a chance to win.",
    author: "Jrue Holiday",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["make", "lots", "birdies", "your", "opponent", "chance"],
    words: [
      "you",
      "have",
      "to",
      "make",
      "lots",
      "of",
      "birdies",
      "and",
      "give",
      "your",
      "opponent",
      "no",
      "chance",
    ],
    id: "You have to make lots of birdies and give your opponent no chance.",
    author: "Michelle Wie",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "man", "living", "more", "than", "thinks"],
    words: [
      "there",
      "is",
      "no",
      "man",
      "living",
      "that",
      "can",
      "not",
      "do",
      "more",
      "than",
      "he",
      "thinks",
    ],
    id: "There is no man living that can not do more than he thinks he can.",
    author: "Henry Ford",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["develop", "passion", "will", "never", "cease", "grow"],
    words: [
      "develop",
      "a",
      "passion",
      "for",
      "learning",
      "if",
      "you",
      "do",
      "will",
      "never",
      "cease",
      "to",
      "grow",
    ],
    id: "Develop a passion for learning. If you do, you will never cease to grow.",
    author: "Anthony J. D'Angelo",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "at", "home", "gone", "out", "walk"],
    words: [
      "god",
      "is",
      "at",
      "home",
      "it's",
      "we",
      "who",
      "have",
      "gone",
      "out",
      "for",
      "a",
      "walk",
    ],
    id: "God is at home, it's we who have gone out for a walk.",
    author: "Meister Eckhart",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "rule", "men", "more", "can't", "than"],
    words: [
      "as",
      "a",
      "rule",
      "men",
      "worry",
      "more",
      "about",
      "what",
      "they",
      "can't",
      "see",
      "than",
      "can",
    ],
    id: "As a rule, men worry more about what they can't see than about what they can.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["on", "forgiveness", "includes", "your", "own", "self"],
    words: [
      "you",
      "have",
      "to",
      "work",
      "on",
      "forgiveness",
      "and",
      "love",
      "that",
      "includes",
      "your",
      "own",
      "self",
    ],
    id: "You have to work on forgiveness and love, and that includes your own self.",
    author: "Brian Welch",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["big", "or", "home", "because", "true", "lose"],
    words: [
      "go",
      "big",
      "or",
      "home",
      "because",
      "it's",
      "true",
      "what",
      "do",
      "you",
      "have",
      "to",
      "lose",
    ],
    id: "Go big or go home. Because it's true. What do you have to lose?",
    author: "Eliza Dushku",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["hatred", "cease", "by", "only", "eternal", "rule"],
    words: [
      "hatred",
      "does",
      "not",
      "cease",
      "by",
      "but",
      "only",
      "love",
      "this",
      "is",
      "the",
      "eternal",
      "rule",
    ],
    id: "Hatred does not cease by hatred, but only by love; this is the eternal rule.",
    author: "Buddha",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["small", "degree", "hope", "sufficient", "cause", "birth"],
    words: [
      "a",
      "very",
      "small",
      "degree",
      "of",
      "hope",
      "is",
      "sufficient",
      "to",
      "cause",
      "the",
      "birth",
      "love",
    ],
    id: "A very small degree of hope is sufficient to cause the birth of love.",
    author: "Stendhal",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["magic", "believing", "yourself", "make", "anything", "happen"],
    words: [
      "magic",
      "is",
      "believing",
      "in",
      "yourself",
      "if",
      "you",
      "can",
      "do",
      "that",
      "make",
      "anything",
      "happen",
    ],
    id: "Magic is believing in yourself, if you can do that, you can make anything happen.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "there's", "always", "chance", "things", "future"],
    words: [
      "you",
      "never",
      "say",
      "there's",
      "always",
      "a",
      "chance",
      "to",
      "do",
      "things",
      "in",
      "the",
      "future",
    ],
    id: "You never say never. There's always a chance to do things in the future.",
    author: "Nick Cummins",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "experience", "having", "time", "connecting", "fans"],
    words: [
      "it's",
      "all",
      "about",
      "the",
      "experience",
      "and",
      "having",
      "a",
      "good",
      "time",
      "connecting",
      "with",
      "fans",
    ],
    id: "It's all about the experience and having a good time and connecting with the fans.",
    author: "Robert Trujillo",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i've", "seen", "firsthand", "being", "change", "reveals"],
    words: [
      "i've",
      "seen",
      "firsthand",
      "that",
      "being",
      "president",
      "doesn't",
      "change",
      "who",
      "you",
      "are",
      "it",
      "reveals",
    ],
    id: "I've seen firsthand that being president doesn't change who you are. It reveals who you are.",
    author: "Michelle Obama",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["make", "movies", "critics", "since", "pay", "anyhow"],
    words: [
      "we",
      "don't",
      "make",
      "movies",
      "for",
      "critics",
      "since",
      "they",
      "pay",
      "to",
      "see",
      "them",
      "anyhow",
    ],
    id: "We don't make movies for critics, since they don't pay to see them anyhow.",
    author: "Charles Bronson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["ever", "anything", "hesitate", "ask", "else", "first"],
    words: [
      "if",
      "you",
      "ever",
      "need",
      "anything",
      "please",
      "don't",
      "hesitate",
      "to",
      "ask",
      "someone",
      "else",
      "first",
    ],
    id: "If you ever need anything please don't hesitate to ask someone else first.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "child", "only", "one", "gift", "enthusiasm"],
    words: [
      "if",
      "you",
      "can",
      "give",
      "your",
      "child",
      "only",
      "one",
      "gift",
      "let",
      "it",
      "be",
      "enthusiasm",
    ],
    id: "If you can give your child only one gift, let it be enthusiasm.",
    author: "Bruce Barton",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatever", "may", "seem", "insignificant", "most", "important"],
    words: [
      "whatever",
      "you",
      "do",
      "may",
      "seem",
      "insignificant",
      "to",
      "but",
      "it",
      "is",
      "most",
      "important",
      "that",
    ],
    id: "Whatever you do may seem insignificant to you, but it is most important that you do it.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["pursuit", "happiness", "driving", "force", "romantic", "novel"],
    words: [
      "it's",
      "the",
      "pursuit",
      "of",
      "love",
      "and",
      "happiness",
      "that",
      "is",
      "driving",
      "force",
      "romantic",
      "novel",
    ],
    id: "It's the pursuit of love and happiness that is the driving force of the romantic novel.",
    author: "Susanna Kearsley",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["nuclear", "family", "destructive", "grew", "out", "selfishness"],
    words: [
      "the",
      "nuclear",
      "family",
      "doesn't",
      "work",
      "it's",
      "very",
      "destructive",
      "it",
      "grew",
      "out",
      "of",
      "selfishness",
    ],
    id: "The nuclear family doesn't work. It's very destructive; it grew out of selfishness.",
    author: "Greg Wise",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["rebuke", "mockers", "or", "will", "hate", "wise"],
    words: [
      "do",
      "not",
      "rebuke",
      "mockers",
      "or",
      "they",
      "will",
      "hate",
      "you",
      "the",
      "wise",
      "and",
      "love",
    ],
    id: "Do not rebuke mockers, or they will hate you; rebuke the wise, and they will love you.",
    author: "King Solomon",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "best", "leader's", "done", "did", "ourselves"],
    words: [
      "when",
      "the",
      "best",
      "leader's",
      "work",
      "is",
      "done",
      "people",
      "say",
      "we",
      "did",
      "it",
      "ourselves",
    ],
    id: "When the best leader's work is done the people say, 'We did it ourselves.'",
    author: "Lao Tzu",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["much", "himself", "more", "esteemed", "than", "imagines"],
    words: [
      "he",
      "who",
      "does",
      "not",
      "think",
      "much",
      "of",
      "himself",
      "is",
      "more",
      "esteemed",
      "than",
      "imagines",
    ],
    id: "He who does not think much of himself is much more esteemed than he imagines.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatever", "doing", "whoever", "gift", "your", "attention"],
    words: [
      "give",
      "whatever",
      "you",
      "are",
      "doing",
      "and",
      "whoever",
      "with",
      "the",
      "gift",
      "of",
      "your",
      "attention",
    ],
    id: "Give whatever you are doing and whoever you are with the gift of your attention.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "movies", "man", "spectacle", "i'm", "playing"],
    words: [
      "i",
      "want",
      "people",
      "to",
      "go",
      "the",
      "movies",
      "am",
      "man",
      "of",
      "spectacle",
      "i'm",
      "playing",
    ],
    id: "I want people to go to the movies. I am the man of the spectacle. I'm playing.",
    author: "Roman Polanski",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "great", "unknown", "i", "believe", "find"],
    words: [
      "god",
      "is",
      "to",
      "me",
      "the",
      "great",
      "unknown",
      "i",
      "believe",
      "in",
      "him",
      "but",
      "find",
      "not",
    ],
    id: "God is to me the Great Unknown. I believe in Him, but I find Him not.",
    author: "Adoniram Judson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["trick", "make", "sure", "die", "prosperity", "come"],
    words: [
      "the",
      "trick",
      "is",
      "to",
      "make",
      "sure",
      "you",
      "don't",
      "die",
      "waiting",
      "for",
      "prosperity",
      "come",
    ],
    id: "The trick is to make sure you don't die waiting for prosperity to come.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["traveler", "fixed", "plans", "intent", "on", "arriving"],
    words: [
      "a",
      "good",
      "traveler",
      "has",
      "no",
      "fixed",
      "plans",
      "and",
      "is",
      "not",
      "intent",
      "on",
      "arriving",
    ],
    id: "A good traveler has no fixed plans, and is not intent on arriving.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["something", "acceptance", "kindness", "create", "true", "friendship"],
    words: [
      "if",
      "you",
      "do",
      "something",
      "with",
      "acceptance",
      "and",
      "kindness",
      "can",
      "create",
      "a",
      "true",
      "friendship",
    ],
    id: "If you do something with acceptance and kindness, you can create a true friendship.",
    author: "Dustin Lance Black",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["zeal", "volcano", "peak", "which", "indecisiveness", "grow"],
    words: [
      "zeal",
      "is",
      "a",
      "volcano",
      "the",
      "peak",
      "of",
      "which",
      "grass",
      "indecisiveness",
      "does",
      "not",
      "grow",
    ],
    id: "Zeal is a volcano, the peak of which the grass of indecisiveness does not grow.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["eyes", "ears", "poor", "witnesses", "uncultured", "souls"],
    words: [
      "eyes",
      "and",
      "ears",
      "are",
      "poor",
      "witnesses",
      "to",
      "people",
      "if",
      "they",
      "have",
      "uncultured",
      "souls",
    ],
    id: "Eyes and ears are poor witnesses to people if they have uncultured souls.",
    author: "Heraclitus",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "power", "only", "man", "facts", "bother"],
    words: [
      "knowledge",
      "is",
      "power",
      "only",
      "if",
      "man",
      "knows",
      "what",
      "facts",
      "not",
      "to",
      "bother",
      "with",
    ],
    id: "Knowledge is power only if man knows what facts not to bother with.",
    author: "Robert Staughton Lynd",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["movies", "must", "gambler", "produce", "films", "gamble"],
    words: [
      "and",
      "in",
      "movies",
      "you",
      "must",
      "be",
      "a",
      "gambler",
      "to",
      "produce",
      "films",
      "is",
      "gamble",
    ],
    id: "And in movies you must be a gambler. To produce films is to gamble.",
    author: "Douglas Sirk",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "skiing", "god", "than", "church", "sport"],
    words: [
      "it",
      "is",
      "better",
      "to",
      "go",
      "skiing",
      "and",
      "think",
      "of",
      "god",
      "than",
      "church",
      "sport",
    ],
    id: "It is better to go skiing and think of God, than go to church and think of sport.",
    author: "Fridtjof Nansen",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["until", "meet", "may", "lord", "take", "liking"],
    words: [
      "until",
      "we",
      "meet",
      "again",
      "may",
      "the",
      "good",
      "lord",
      "take",
      "a",
      "liking",
      "to",
      "you",
    ],
    id: "Until we meet again, may the good Lord take a liking to you.",
    author: "Roy Rogers",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["bureaucrat", "world", "mere", "object", "manipulated", "by"],
    words: [
      "for",
      "the",
      "bureaucrat",
      "world",
      "is",
      "a",
      "mere",
      "object",
      "to",
      "be",
      "manipulated",
      "by",
      "him",
    ],
    id: "For the bureaucrat, the world is a mere object to be manipulated by him.",
    author: "Karl Marx",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["should", "make", "up", "their", "own", "mind"],
    words: [
      "people",
      "should",
      "make",
      "up",
      "their",
      "own",
      "mind",
      "about",
      "what",
      "they",
      "think",
      "of",
      "me",
    ],
    id: "People should make up their own mind about what they think of me.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "single", "doing", "more", "or", "something"],
    words: [
      "i",
      "think",
      "every",
      "single",
      "day",
      "can",
      "be",
      "doing",
      "more",
      "or",
      "learning",
      "something",
      "new",
    ],
    id: "I think every single day, I can be doing more or learning something new.",
    author: "Lindsey Horan",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "arises", "our", "thoughts", "make", "world"],
    words: [
      "we",
      "are",
      "what",
      "think",
      "all",
      "that",
      "arises",
      "with",
      "our",
      "thoughts",
      "make",
      "the",
      "world",
    ],
    id: "We are what we think. All that we are arises with our thoughts. With our thoughts, we make the world.",
    author: "Buddha",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["fact", "man", "reporter", "evidence", "flaw", "character"],
    words: [
      "the",
      "fact",
      "that",
      "a",
      "man",
      "is",
      "newspaper",
      "reporter",
      "evidence",
      "of",
      "some",
      "flaw",
      "character",
    ],
    id: "The fact that a man is a newspaper reporter is evidence of some flaw of character.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["process", "turning", "motherhood", "start", "many", "things"],
    words: [
      "in",
      "the",
      "process",
      "of",
      "turning",
      "to",
      "motherhood",
      "you",
      "start",
      "learning",
      "so",
      "many",
      "things",
    ],
    id: "In the process of turning to motherhood, you start learning so many things.",
    author: "Esha Deol",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["today's", "yesterday", "was", "means", "great", "life"],
    words: [
      "today's",
      "a",
      "beautiful",
      "day",
      "and",
      "yesterday",
      "was",
      "so",
      "that",
      "means",
      "it's",
      "great",
      "life",
    ],
    id: "Today's a beautiful day, and yesterday was a beautiful day, so that means it's a great life.",
    author: "Gnash",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["poverty", "must", "bar", "offer", "escape", "from"],
    words: [
      "poverty",
      "must",
      "not",
      "be",
      "a",
      "bar",
      "to",
      "learning",
      "and",
      "offer",
      "an",
      "escape",
      "from",
    ],
    id: "Poverty must not be a bar to learning and learning must offer an escape from poverty.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["faith", "permitting", "ourselves", "seized", "by", "things"],
    words: [
      "faith",
      "is",
      "permitting",
      "ourselves",
      "to",
      "be",
      "seized",
      "by",
      "the",
      "things",
      "we",
      "do",
      "not",
      "see",
    ],
    id: "Faith is permitting ourselves to be seized by the things we do not see.",
    author: "Martin Luther",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "would", "most", "surprised", "scary", "movies"],
    words: [
      "i",
      "think",
      "people",
      "would",
      "be",
      "most",
      "surprised",
      "to",
      "know",
      "that",
      "love",
      "scary",
      "movies",
    ],
    id: "I think people would be most surprised to know that I love scary movies.",
    author: "Olivia Holt",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["sacred", "reserve", "energy", "blood", "spiritual", "evolution"],
    words: [
      "love",
      "is",
      "a",
      "sacred",
      "reserve",
      "of",
      "energy",
      "it",
      "like",
      "the",
      "blood",
      "spiritual",
      "evolution",
    ],
    id: "Love is a sacred reserve of energy; it is like the blood of spiritual evolution.",
    author: "Pierre Teilhard de Chardin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "as", "sportsman", "put", "up", "fight"],
    words: [
      "that's",
      "what",
      "you",
      "live",
      "for",
      "as",
      "a",
      "sportsman",
      "have",
      "to",
      "put",
      "up",
      "fight",
    ],
    id: "That's what you live for as a sportsman. You have to put up a fight.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["gift", "from", "god", "matter", "how", "old"],
    words: [
      "every",
      "day",
      "is",
      "a",
      "gift",
      "from",
      "god",
      "no",
      "matter",
      "how",
      "old",
      "we",
      "are",
    ],
    id: "Every day is a gift from God, no matter how old we are.",
    author: "Billy Graham",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["apart", "from", "education", "health", "play", "sports"],
    words: [
      "apart",
      "from",
      "education",
      "you",
      "need",
      "good",
      "health",
      "and",
      "for",
      "that",
      "to",
      "play",
      "sports",
    ],
    id: "Apart from education, you need good health, and for that, you need to play sports.",
    author: "Kapil Dev",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "panic", "feel", "fear", "then", "act"],
    words: [
      "i",
      "want",
      "you",
      "to",
      "panic",
      "feel",
      "the",
      "fear",
      "every",
      "day",
      "and",
      "then",
      "act",
    ],
    id: "I want you to panic. I want you to feel the fear I feel every day. And then I want you to act.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["reason", "there", "lie", "better", "tell", "truth"],
    words: [
      "for",
      "every",
      "good",
      "reason",
      "there",
      "is",
      "to",
      "lie",
      "a",
      "better",
      "tell",
      "the",
      "truth",
    ],
    id: "For every good reason there is to lie, there is a better reason to tell the truth.",
    author: "Bo Bennett",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "discipline", "well-cut", "impeccable", "clothes", "healthy"],
    words: [
      "i",
      "like",
      "the",
      "discipline",
      "of",
      "well-cut",
      "impeccable",
      "clothes",
      "think",
      "it's",
      "a",
      "very",
      "healthy",
    ],
    id: "I like the discipline of well-cut, impeccable clothes. I think it's a very healthy discipline.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["young", "as", "attracted", "truth", "convenience", "expediency"],
    words: [
      "young",
      "people",
      "are",
      "just",
      "as",
      "attracted",
      "to",
      "the",
      "truth",
      "they",
      "convenience",
      "and",
      "expediency",
    ],
    id: "Young people are just as attracted to the truth as they are convenience and expediency.",
    author: "Pope Francis",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "their", "partners", "changing", "clothes", "common"],
    words: [
      "people",
      "change",
      "their",
      "partners",
      "like",
      "they",
      "are",
      "changing",
      "clothes",
      "it's",
      "very",
      "common",
      "now",
    ],
    id: "People change their partners like they are changing clothes. It's very common now.",
    author: "Sooraj Pancholi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["wounds", "only", "healed", "by", "one", "made"],
    words: [
      "the",
      "wounds",
      "of",
      "love",
      "can",
      "only",
      "be",
      "healed",
      "by",
      "one",
      "who",
      "made",
      "them",
    ],
    id: "The wounds of love can only be healed by the one who made them.",
    author: "Publilius Syrus",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "being", "put", "box", "make", "music"],
    words: [
      "i",
      "don't",
      "like",
      "being",
      "put",
      "in",
      "a",
      "box",
      "just",
      "make",
      "music",
      "you",
      "know",
    ],
    id: "I don't like being put in a box. I just make music, you know?",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["reason", "men", "oppose", "progress", "hate", "inertia"],
    words: [
      "the",
      "reason",
      "men",
      "oppose",
      "progress",
      "is",
      "not",
      "that",
      "they",
      "hate",
      "but",
      "love",
      "inertia",
    ],
    id: "The reason men oppose progress is not that they hate progress, but that they love inertia.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["therefore", "medicine", "ought", "causes", "sickness", "health"],
    words: [
      "therefore",
      "in",
      "medicine",
      "we",
      "ought",
      "to",
      "know",
      "the",
      "causes",
      "of",
      "sickness",
      "and",
      "health",
    ],
    id: "Therefore in medicine we ought to know the causes of sickness and health.",
    author: "Avicenna",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["little", "when", "your", "possessions", "yourself", "truly"],
    words: [
      "you",
      "give",
      "but",
      "little",
      "when",
      "of",
      "your",
      "possessions",
      "it",
      "is",
      "yourself",
      "that",
      "truly",
    ],
    id: "You give but little when you give of your possessions. It is when you give of yourself that you truly give.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["direction", "magnitude", "which", "taken", "into", "consideration"],
    words: [
      "it",
      "is",
      "the",
      "direction",
      "and",
      "not",
      "magnitude",
      "which",
      "to",
      "be",
      "taken",
      "into",
      "consideration",
    ],
    id: "It is the direction and not the magnitude which is to be taken into consideration.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["god's", "will", "merely", "should", "make", "ourselves"],
    words: [
      "it",
      "is",
      "not",
      "god's",
      "will",
      "merely",
      "that",
      "we",
      "should",
      "be",
      "happy",
      "but",
      "make",
      "ourselves",
    ],
    id: "It is not God's will merely that we should be happy, but that we should make ourselves happy.",
    author: "Immanuel Kant",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "athenian", "or", "greek", "citizen", "world"],
    words: [
      "i",
      "am",
      "not",
      "an",
      "athenian",
      "or",
      "a",
      "greek",
      "but",
      "citizen",
      "of",
      "the",
      "world",
    ],
    id: "I am not an Athenian or a Greek, but a citizen of the world.",
    author: "Diogenes",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["reputation", "men", "women", "character", "god", "angels"],
    words: [
      "reputation",
      "is",
      "what",
      "men",
      "and",
      "women",
      "think",
      "of",
      "us",
      "character",
      "god",
      "angels",
      "know",
    ],
    id: "Reputation is what men and women think of us; character is what God and angels know of us.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "was", "always", "matter", "right", "act"],
    words: [
      "life",
      "was",
      "always",
      "a",
      "matter",
      "of",
      "waiting",
      "for",
      "the",
      "right",
      "moment",
      "to",
      "act",
    ],
    id: "Life was always a matter of waiting for the right moment to act.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "process", "state", "being", "direction", "destination"],
    words: [
      "the",
      "good",
      "life",
      "is",
      "a",
      "process",
      "not",
      "state",
      "of",
      "being",
      "it",
      "direction",
      "destination",
    ],
    id: "The good life is a process, not a state of being. It is a direction not a destination.",
    author: "Carl Rogers",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["art", "handicraft", "transmission", "feeling", "artist", "experienced"],
    words: [
      "art",
      "is",
      "not",
      "a",
      "handicraft",
      "it",
      "the",
      "transmission",
      "of",
      "feeling",
      "artist",
      "has",
      "experienced",
    ],
    id: "Art is not a handicraft, it is the transmission of feeling the artist has experienced.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["everything", "exists", "manner", "seed", "which", "will"],
    words: [
      "everything",
      "that",
      "exists",
      "is",
      "in",
      "a",
      "manner",
      "the",
      "seed",
      "of",
      "which",
      "will",
      "be",
    ],
    id: "Everything that exists is in a manner the seed of that which will be.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "which", "port", "sailing", "wind", "favorable"],
    words: [
      "if",
      "one",
      "does",
      "not",
      "know",
      "to",
      "which",
      "port",
      "is",
      "sailing",
      "no",
      "wind",
      "favorable",
    ],
    id: "If one does not know to which port one is sailing, no wind is favorable.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["zest", "enthusiasm", "attract", "life", "back", "kind"],
    words: [
      "if",
      "you",
      "have",
      "zest",
      "and",
      "enthusiasm",
      "attract",
      "life",
      "does",
      "give",
      "back",
      "in",
      "kind",
    ],
    id: "If you have zest and enthusiasm you attract zest and enthusiasm. Life does give back in kind.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["wish", "mind", "man", "listen", "his", "words"],
    words: [
      "if",
      "you",
      "wish",
      "to",
      "know",
      "the",
      "mind",
      "of",
      "a",
      "man",
      "listen",
      "his",
      "words",
    ],
    id: "If you wish to know the mind of a man, listen to his words.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["fight", "reach", "your", "dream", "sacrifice", "hard"],
    words: [
      "you",
      "have",
      "to",
      "fight",
      "reach",
      "your",
      "dream",
      "sacrifice",
      "and",
      "work",
      "hard",
      "for",
      "it",
    ],
    id: "You have to fight to reach your dream. You have to sacrifice and work hard for it.",
    author: "Lionel Messi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["expect", "translated", "from", "despotism", "liberty", "featherbed"],
    words: [
      "we",
      "are",
      "not",
      "to",
      "expect",
      "be",
      "translated",
      "from",
      "despotism",
      "liberty",
      "in",
      "a",
      "featherbed",
    ],
    id: "We are not to expect to be translated from despotism to liberty in a featherbed.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["would", "take", "must", "first", "beginning", "intelligence"],
    words: [
      "if",
      "you",
      "would",
      "take",
      "must",
      "first",
      "give",
      "this",
      "is",
      "the",
      "beginning",
      "of",
      "intelligence",
    ],
    id: "If you would take, you must first give, this is the beginning of intelligence.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["privilege", "gods", "nothing", "godlike", "men", "little"],
    words: [
      "it",
      "is",
      "the",
      "privilege",
      "of",
      "gods",
      "to",
      "want",
      "nothing",
      "and",
      "godlike",
      "men",
      "little",
    ],
    id: "It is the privilege of the gods to want nothing, and of godlike men to want little.",
    author: "Diogenes",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["cheers", "year", "another", "chance", "get", "right"],
    words: [
      "cheers",
      "to",
      "a",
      "new",
      "year",
      "and",
      "another",
      "chance",
      "for",
      "us",
      "get",
      "it",
      "right",
    ],
    id: "Cheers to a new year and another chance for us to get it right.",
    author: "Oprah Winfrey",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "pursued", "attracted", "by", "person", "become"],
    words: [
      "success",
      "is",
      "not",
      "to",
      "be",
      "pursued",
      "it",
      "attracted",
      "by",
      "the",
      "person",
      "you",
      "become",
    ],
    id: "Success is not to be pursued; it is to be attracted by the person you become.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "friendship", "out", "when", "race", "fight"],
    words: [
      "there",
      "is",
      "no",
      "friendship",
      "out",
      "when",
      "you",
      "race",
      "have",
      "to",
      "fight",
      "that's",
      "it",
    ],
    id: "There is no friendship out there. When you race, you have to fight. That's it.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["homophobic", "something", "gay", "bad", "all", "movies"],
    words: [
      "you're",
      "not",
      "homophobic",
      "for",
      "thinking",
      "that",
      "something",
      "gay",
      "is",
      "bad",
      "all",
      "movies",
      "are",
    ],
    id: "You're not homophobic for thinking that something gay is bad. All gay movies are bad.",
    author: "Katya Zamolodchikova",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["chance", "pseudonym", "god", "when", "did", "sign"],
    words: [
      "chance",
      "is",
      "the",
      "pseudonym",
      "of",
      "god",
      "when",
      "he",
      "did",
      "not",
      "want",
      "to",
      "sign",
    ],
    id: "Chance is the pseudonym of God when he did not want to sign.",
    author: "Theophile Gautier",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["yet", "one", "best", "will", "lead", "difficulty"],
    words: [
      "to",
      "know",
      "yet",
      "think",
      "that",
      "one",
      "does",
      "not",
      "is",
      "best",
      "knows",
      "will",
      "lead",
      "difficulty",
    ],
    id: "To know yet to think that one does not know is best; Not to know yet to think that one knows will lead to difficulty.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "men", "as", "god", "saw", "heard"],
    words: [
      "so",
      "live",
      "with",
      "men",
      "as",
      "if",
      "god",
      "saw",
      "you",
      "and",
      "speak",
      "to",
      "heard",
    ],
    id: "So live with men as if God saw you and speak to God, as if men heard you.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["any", "man", "may", "easily", "harm", "another"],
    words: [
      "any",
      "man",
      "may",
      "easily",
      "do",
      "harm",
      "but",
      "not",
      "every",
      "can",
      "good",
      "to",
      "another",
    ],
    id: "Any man may easily do harm, but not every man can do good to another.",
    author: "Plato",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["white", "their", "slaves", "free", "whole", "thing"],
    words: [
      "white",
      "people",
      "just",
      "don't",
      "want",
      "their",
      "slaves",
      "to",
      "be",
      "free",
      "that's",
      "the",
      "whole",
      "thing",
    ],
    id: "White people just don't want their slaves to be free. That's the whole thing.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["large", "part", "mankind", "angry", "sins", "sinners"],
    words: [
      "a",
      "large",
      "part",
      "of",
      "mankind",
      "is",
      "angry",
      "not",
      "with",
      "the",
      "sins",
      "but",
      "sinners",
    ],
    id: "A large part of mankind is angry not with the sins, but with the sinners.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["embarrassed", "by", "your", "failures", "from", "start"],
    words: [
      "do",
      "not",
      "be",
      "embarrassed",
      "by",
      "your",
      "failures",
      "learn",
      "from",
      "them",
      "and",
      "start",
      "again",
    ],
    id: "Do not be embarrassed by your failures, learn from them and start again.",
    author: "Richard Branson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["real", "beauty", "true", "oneself", "makes", "feel"],
    words: [
      "real",
      "beauty",
      "is",
      "to",
      "be",
      "true",
      "oneself",
      "that's",
      "what",
      "makes",
      "me",
      "feel",
      "good",
    ],
    id: "Real beauty is to be true to oneself. That's what makes me feel good.",
    author: "Laetitia Casta",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "more", "than", "paid", "will", "soon"],
    words: [
      "the",
      "man",
      "who",
      "does",
      "more",
      "than",
      "he",
      "is",
      "paid",
      "for",
      "will",
      "soon",
      "be",
    ],
    id: "The man who does more than he is paid for will soon be paid for more than he does.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "product", "his", "thoughts", "thinks", "becomes"],
    words: [
      "a",
      "man",
      "is",
      "but",
      "the",
      "product",
      "of",
      "his",
      "thoughts",
      "what",
      "he",
      "thinks",
      "becomes",
    ],
    id: "A man is but the product of his thoughts, what he thinks he becomes.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["praise", "others", "ordinarily", "order", "praised", "ourselves"],
    words: [
      "we",
      "do",
      "not",
      "praise",
      "others",
      "ordinarily",
      "but",
      "in",
      "order",
      "to",
      "be",
      "praised",
      "ourselves",
    ],
    id: "We do not praise others, ordinarily, but in order to be praised ourselves.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["everything", "irritates", "others", "lead", "understanding", "ourselves"],
    words: [
      "everything",
      "that",
      "irritates",
      "us",
      "about",
      "others",
      "can",
      "lead",
      "to",
      "an",
      "understanding",
      "of",
      "ourselves",
    ],
    id: "Everything that irritates us about others can lead us to an understanding of ourselves.",
    author: "Carl Jung",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["take", "mickey", "out", "mental", "health", "delicate"],
    words: [
      "people",
      "take",
      "the",
      "mickey",
      "out",
      "of",
      "mental",
      "health",
      "but",
      "it",
      "is",
      "very",
      "delicate",
    ],
    id: "People take the mickey out of mental health, but it is very delicate.",
    author: "Frank Bruno",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["virus", "happen", "anybody", "at", "any", "time"],
    words: [
      "love",
      "is",
      "like",
      "a",
      "virus",
      "it",
      "can",
      "happen",
      "to",
      "anybody",
      "at",
      "any",
      "time",
    ],
    id: "Love is like a virus. It can happen to anybody at any time.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["death", "two", "wings", "bear", "man", "heaven"],
    words: [
      "death",
      "and",
      "love",
      "are",
      "the",
      "two",
      "wings",
      "that",
      "bear",
      "good",
      "man",
      "to",
      "heaven",
    ],
    id: "Death and love are the two wings that bear the good man to heaven.",
    author: "Michelangelo",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["suffer", "thank", "god", "sure", "sign", "alive"],
    words: [
      "if",
      "you",
      "suffer",
      "thank",
      "god",
      "it",
      "is",
      "a",
      "sure",
      "sign",
      "that",
      "are",
      "alive",
    ],
    id: "If you suffer, thank God! It is a sure sign that you are alive.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["fine", "thing", "honest", "also", "important", "right"],
    words: [
      "it",
      "is",
      "a",
      "fine",
      "thing",
      "to",
      "be",
      "honest",
      "but",
      "also",
      "very",
      "important",
      "right",
    ],
    id: "It is a fine thing to be honest, but it is also very important to be right.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "as", "live", "forever", "afterlife", "die"],
    words: [
      "do",
      "for",
      "this",
      "life",
      "as",
      "if",
      "you",
      "live",
      "forever",
      "the",
      "afterlife",
      "die",
      "tomorrow",
    ],
    id: "Do for this life as if you live forever, do for the afterlife as if you die tomorrow.",
    author: "Ali ibn Abi Talib",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["right", "express", "opinion", "until", "all", "answers"],
    words: [
      "we",
      "have",
      "no",
      "right",
      "to",
      "express",
      "an",
      "opinion",
      "until",
      "know",
      "all",
      "of",
      "the",
      "answers",
    ],
    id: "We have no right to express an opinion until we know all of the answers.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["comes", "from", "friendship", "underlying", "facet", "life"],
    words: [
      "the",
      "love",
      "that",
      "comes",
      "from",
      "friendship",
      "is",
      "underlying",
      "facet",
      "of",
      "a",
      "happy",
      "life",
    ],
    id: "The love that comes from friendship is the underlying facet of a happy life.",
    author: "Chelsea Handler",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "already", "halfway", "any", "woman", "listens"],
    words: [
      "a",
      "man",
      "is",
      "already",
      "halfway",
      "in",
      "love",
      "with",
      "any",
      "woman",
      "who",
      "listens",
      "to",
      "him",
    ],
    id: "A man is already halfway in love with any woman who listens to him.",
    author: "Brendan Behan",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "given", "two", "hands", "one", "receive"],
    words: [
      "god",
      "has",
      "given",
      "us",
      "two",
      "hands",
      "one",
      "to",
      "receive",
      "with",
      "and",
      "the",
      "other",
      "give",
    ],
    id: "God has given us two hands, one to receive with and the other to give with.",
    author: "Billy Graham",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "bit", "i", "carry", "much", "cash"],
    words: [
      "i'm",
      "a",
      "bit",
      "like",
      "the",
      "queen",
      "in",
      "that",
      "i",
      "don't",
      "carry",
      "much",
      "cash",
    ],
    id: "I'm a bit like the Queen in that I don't carry much cash.",
    author: "Richard Branson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "too", "little", "craves", "more", "poor"],
    words: [
      "it",
      "is",
      "not",
      "the",
      "man",
      "who",
      "has",
      "too",
      "little",
      "but",
      "craves",
      "more",
      "that",
      "poor",
    ],
    id: "It is not the man who has too little, but the man who craves more, that is poor.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "when", "forget", "all", "our", "begin"],
    words: [
      "it",
      "is",
      "only",
      "when",
      "we",
      "forget",
      "all",
      "our",
      "learning",
      "that",
      "begin",
      "to",
      "know",
    ],
    id: "It is only when we forget all our learning that we begin to know.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "given", "my", "life", "art", "prefer"],
    words: [
      "i",
      "don't",
      "like",
      "to",
      "say",
      "have",
      "given",
      "my",
      "life",
      "art",
      "prefer",
      "has",
      "me",
    ],
    id: "I don't like to say I have given my life to art. I prefer to say art has given me my life.",
    author: "Frank Stella",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "his", "youth", "cannot", "endure", "age"],
    words: [
      "a",
      "man",
      "loves",
      "the",
      "meat",
      "in",
      "his",
      "youth",
      "that",
      "he",
      "cannot",
      "endure",
      "age",
    ],
    id: "A man loves the meat in his youth that he cannot endure in his age.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "greatest", "gifts", "yourself", "forgive", "everybody"],
    words: [
      "it's",
      "one",
      "of",
      "the",
      "greatest",
      "gifts",
      "you",
      "can",
      "give",
      "yourself",
      "to",
      "forgive",
      "everybody",
    ],
    id: "It's one of the greatest gifts you can give yourself, to forgive. Forgive everybody.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["take", "your", "body", "only", "place", "live"],
    words: [
      "take",
      "care",
      "of",
      "your",
      "body",
      "it's",
      "the",
      "only",
      "place",
      "you",
      "have",
      "to",
      "live",
    ],
    id: "Take care of your body. It's the only place you have to live.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "harbor", "seeks", "any", "wind", "right"],
    words: [
      "if",
      "a",
      "man",
      "knows",
      "not",
      "what",
      "harbor",
      "he",
      "seeks",
      "any",
      "wind",
      "is",
      "the",
      "right",
    ],
    id: "If a man knows not what harbor he seeks, any wind is the right wind.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 8,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "unhappy", "even", "unreturned", "its", "rainbow"],
    words: [
      "let",
      "no",
      "one",
      "who",
      "loves",
      "be",
      "unhappy",
      "even",
      "love",
      "unreturned",
      "has",
      "its",
      "rainbow",
    ],
    id: "Let no one who loves be unhappy, even love unreturned has its rainbow.",
    author: "James M. Barrie",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["friendship", "will", "continue", "end", "which", "begun"],
    words: [
      "that",
      "friendship",
      "will",
      "not",
      "continue",
      "to",
      "the",
      "end",
      "which",
      "is",
      "begun",
      "for",
      "an",
    ],
    id: "That friendship will not continue to the end which is begun for an end.",
    author: "Francis Quarles",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["watch", "cynical", "one", "often", "discover", "lack"],
    words: [
      "watch",
      "what",
      "people",
      "are",
      "cynical",
      "about",
      "and",
      "one",
      "can",
      "often",
      "discover",
      "they",
      "lack",
    ],
    id: "Watch what people are cynical about, and one can often discover what they lack.",
    author: "George S. Patton",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["would", "faithful", "servant", "one", "serve", "yourself"],
    words: [
      "if",
      "you",
      "would",
      "have",
      "a",
      "faithful",
      "servant",
      "and",
      "one",
      "that",
      "like",
      "serve",
      "yourself",
    ],
    id: "If you would have a faithful servant, and one that you like, serve yourself.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "often", "opposite", "depression", "happiness", "vitality"],
    words: [
      "i",
      "often",
      "say",
      "that",
      "the",
      "opposite",
      "of",
      "depression",
      "is",
      "not",
      "happiness",
      "but",
      "vitality",
    ],
    id: "I often say that the opposite of depression is not happiness but vitality.",
    author: "Andrew Solomon",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["device", "making", "ignorant", "more", "crazy", "crazier"],
    words: [
      "a",
      "newspaper",
      "is",
      "device",
      "for",
      "making",
      "the",
      "ignorant",
      "more",
      "and",
      "crazy",
      "crazier",
    ],
    id: "A newspaper is a device for making the ignorant more ignorant and the crazy crazier.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "right", "time", "place", "happen", "any"],
    words: [
      "there",
      "is",
      "no",
      "right",
      "time",
      "and",
      "place",
      "for",
      "love",
      "it",
      "can",
      "happen",
      "any",
    ],
    id: "There is no right time and right place for love... it can happen any time.",
    author: "Shah Rukh Khan",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["enough", "result", "will", "most", "certainly", "attain"],
    words: [
      "if",
      "you",
      "care",
      "enough",
      "for",
      "a",
      "result",
      "will",
      "most",
      "certainly",
      "attain",
      "it",
    ],
    id: "If you care enough for a result, you will most certainly attain it.",
    author: "William James",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "consensus-building", "politics", "i'm", "meant", "doing"],
    words: [
      "i",
      "don't",
      "think",
      "consensus-building",
      "politics",
      "is",
      "what",
      "i'm",
      "meant",
      "to",
      "be",
      "doing",
    ],
    id: "I don't think consensus-building politics is what I'm meant to be doing.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["more", "praise", "celebrate", "your", "life", "there"],
    words: [
      "the",
      "more",
      "you",
      "praise",
      "and",
      "celebrate",
      "your",
      "life",
      "there",
      "is",
      "in",
      "to",
    ],
    id: "The more you praise and celebrate your life, the more there is in life to celebrate.",
    author: "Oprah Winfrey",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "over", "years", "changes", "situations", "bad"],
    words: [
      "people",
      "change",
      "over",
      "the",
      "years",
      "and",
      "that",
      "changes",
      "situations",
      "for",
      "good",
      "bad",
    ],
    id: "People change over the years, and that changes situations for good and for bad.",
    author: "Bobby Knight",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["doing", "cornerstone", "having", "abundance", "your", "life"],
    words: [
      "doing",
      "what",
      "you",
      "love",
      "is",
      "the",
      "cornerstone",
      "of",
      "having",
      "abundance",
      "in",
      "your",
      "life",
    ],
    id: "Doing what you love is the cornerstone of having abundance in your life.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["startling", "leaves", "little", "time", "anything", "else"],
    words: [
      "to",
      "love",
      "is",
      "so",
      "startling",
      "it",
      "leaves",
      "little",
      "time",
      "for",
      "anything",
      "else",
    ],
    id: "To love is so startling it leaves little time for anything else.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "fellow", "americans", "ask", "your", "country"],
    words: [
      "my",
      "fellow",
      "americans",
      "ask",
      "not",
      "what",
      "your",
      "country",
      "can",
      "do",
      "for",
      "you",
    ],
    id: "My fellow Americans, ask not what your country can do for you, ask what you can do for your country.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["last", "thing", "i", "wanted", "was", "wartime"],
    words: [
      "the",
      "last",
      "thing",
      "i",
      "wanted",
      "to",
      "do",
      "was",
      "be",
      "a",
      "wartime",
      "president",
    ],
    id: "The last thing I wanted to do was to be a wartime President.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "than", "human", "beings", "because", "tell"],
    words: [
      "dogs",
      "are",
      "better",
      "than",
      "human",
      "beings",
      "because",
      "they",
      "know",
      "but",
      "do",
      "not",
      "tell",
    ],
    id: "Dogs are better than human beings because they know but do not tell.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "state", "mind", "start", "yourself", "as"],
    words: [
      "success",
      "is",
      "a",
      "state",
      "of",
      "mind",
      "if",
      "you",
      "want",
      "start",
      "thinking",
      "yourself",
      "as",
    ],
    id: "Success is a state of mind. If you want success, start thinking of yourself as a success.",
    author: "Joyce Brothers",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["prayer", "better", "heart", "without", "words", "than"],
    words: [
      "in",
      "prayer",
      "it",
      "is",
      "better",
      "to",
      "have",
      "a",
      "heart",
      "without",
      "words",
      "than",
    ],
    id: "In prayer it is better to have a heart without words than words without a heart.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "something", "postpone", "future", "design", "present"],
    words: [
      "happiness",
      "is",
      "not",
      "something",
      "you",
      "postpone",
      "for",
      "the",
      "future",
      "it",
      "design",
      "present",
    ],
    id: "Happiness is not something you postpone for the future; it is something you design for the present.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["lives", "by", "crystal", "soon", "ground", "glass"],
    words: [
      "he",
      "who",
      "lives",
      "by",
      "the",
      "crystal",
      "ball",
      "soon",
      "learns",
      "to",
      "eat",
      "ground",
      "glass",
    ],
    id: "He who lives by the crystal ball soon learns to eat ground glass.",
    author: "Edgar Fiedler",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["truth", "all", "men", "only", "those", "seek"],
    words: [
      "the",
      "truth",
      "is",
      "not",
      "for",
      "all",
      "men",
      "but",
      "only",
      "those",
      "who",
      "seek",
      "it",
    ],
    id: "The truth is not for all men, but only for those who seek it.",
    author: "Ayn Rand",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["sit", "as", "superlegislature", "weigh", "wisdom", "legislation"],
    words: [
      "we",
      "do",
      "not",
      "sit",
      "as",
      "a",
      "superlegislature",
      "to",
      "weigh",
      "the",
      "wisdom",
      "of",
      "legislation",
    ],
    id: "We do not sit as a superlegislature to weigh the wisdom of legislation.",
    author: "William O. Douglas",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["must", "hard", "nut", "crack", "sweet", "kernel"],
    words: ["must", "is", "a", "hard", "nut", "to", "crack", "but", "it", "has", "sweet", "kernel"],
    id: "Must is a hard nut to crack, but it has a sweet kernel.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "did", "exist", "would", "necessary", "invent"],
    words: [
      "if",
      "god",
      "did",
      "not",
      "exist",
      "it",
      "would",
      "be",
      "necessary",
      "to",
      "invent",
      "him",
    ],
    id: "If God did not exist, it would be necessary to invent Him.",
    author: "Voltaire",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["maybe", "life", "wink", "eye", "winking", "stars"],
    words: [
      "maybe",
      "that's",
      "what",
      "life",
      "is",
      "a",
      "wink",
      "of",
      "the",
      "eye",
      "and",
      "winking",
      "stars",
    ],
    id: "Maybe that's what life is... a wink of the eye and winking stars.",
    author: "Jack Kerouac",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["constitutions", "maryland", "york", "founded", "higher", "wisdom"],
    words: [
      "the",
      "constitutions",
      "of",
      "maryland",
      "and",
      "new",
      "york",
      "are",
      "founded",
      "in",
      "higher",
      "wisdom",
    ],
    id: "The constitutions of Maryland and New York are founded in higher wisdom.",
    author: "Ezra Stiles",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "three", "ingredients", "life", "earning", "yearning"],
    words: [
      "there",
      "are",
      "three",
      "ingredients",
      "in",
      "the",
      "good",
      "life",
      "learning",
      "earning",
      "and",
      "yearning",
    ],
    id: "There are three ingredients in the good life: learning, earning and yearning.",
    author: "Christopher Morley",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["everybody", "interested", "success", "nobody", "process", "behind"],
    words: [
      "everybody",
      "is",
      "interested",
      "in",
      "success",
      "but",
      "nobody",
      "cares",
      "about",
      "the",
      "process",
      "behind",
      "it",
    ],
    id: "Everybody is interested in success, but nobody cares about the process behind it.",
    author: "Pratik Gandhi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["whenever", "well-informed", "trusted", "their", "own", "government"],
    words: [
      "whenever",
      "the",
      "people",
      "are",
      "well-informed",
      "they",
      "can",
      "be",
      "trusted",
      "with",
      "their",
      "own",
      "government",
    ],
    id: "Whenever the people are well-informed, they can be trusted with their own government.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["little", "child", "get", "great", "deal", "back"],
    words: [
      "give",
      "a",
      "little",
      "love",
      "to",
      "child",
      "and",
      "you",
      "get",
      "great",
      "deal",
      "back",
    ],
    id: "Give a little love to a child, and you get a great deal back.",
    author: "John Ruskin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "life", "i", "longer", "useful", "others"],
    words: ["what", "is", "my", "life", "if", "i", "am", "no", "longer", "useful", "to", "others"],
    id: "What is my life if I am no longer useful to others.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "i", "my", "friend", "simply", "his"],
    words: ["the", "most", "i", "can", "do", "for", "my", "friend", "is", "simply", "be", "his"],
    id: "The most I can do for my friend is simply be his friend.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["show", "sane", "man", "i", "will", "cure"],
    words: ["show", "me", "a", "sane", "man", "and", "i", "will", "cure", "him", "for", "you"],
    id: "Show me a sane man and I will cure him for you.",
    author: "Carl Jung",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["any", "landing", "walk", "away", "from", "one"],
    words: [
      "they",
      "say",
      "any",
      "landing",
      "you",
      "can",
      "walk",
      "away",
      "from",
      "is",
      "a",
      "good",
      "one",
    ],
    id: "They say any landing you can walk away from is a good one.",
    author: "Alan Shepard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["secret", "marriage", "friendship", "laughter", "there's", "alternative"],
    words: [
      "the",
      "secret",
      "to",
      "a",
      "happy",
      "marriage",
      "is",
      "friendship",
      "and",
      "laughter",
      "there's",
      "no",
      "alternative",
    ],
    id: "The secret to a happy marriage is friendship and laughter. There's no alternative.",
    author: "Geraldine James",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["expectations", "form", "first-class", "truth", "believe", "true"],
    words: [
      "expectations",
      "are",
      "a",
      "form",
      "of",
      "first-class",
      "truth",
      "if",
      "people",
      "believe",
      "it",
      "it's",
      "true",
    ],
    id: "Expectations are a form of first-class truth: If people believe it, it's true.",
    author: "Bill Gates",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["impossible", "begin", "which", "one", "thinks", "already"],
    words: [
      "it",
      "is",
      "impossible",
      "to",
      "begin",
      "learn",
      "that",
      "which",
      "one",
      "thinks",
      "already",
      "knows",
    ],
    id: "It is impossible to begin to learn that which one thinks one already knows.",
    author: "Epictetus",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "than", "sheep", "all", "your", "life"],
    words: [
      "it's",
      "better",
      "to",
      "be",
      "a",
      "lion",
      "for",
      "day",
      "than",
      "sheep",
      "all",
      "your",
      "life",
    ],
    id: "It's better to be a lion for a day than a sheep all your life.",
    author: "Elizabeth Kenny",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["lure", "distant", "difficult", "deceptive", "great", "opportunity"],
    words: [
      "the",
      "lure",
      "of",
      "distant",
      "and",
      "difficult",
      "is",
      "deceptive",
      "great",
      "opportunity",
      "where",
      "you",
      "are",
    ],
    id: "The lure of the distant and the difficult is deceptive. The great opportunity is where you are.",
    author: "John Burroughs",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["profound", "pessimist", "life", "being", "danger", "depressing"],
    words: [
      "to",
      "a",
      "profound",
      "pessimist",
      "about",
      "life",
      "being",
      "in",
      "danger",
      "is",
      "not",
      "depressing",
    ],
    id: "To a profound pessimist about life, being in danger is not depressing.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["because", "cannot", "god", "only", "consider", "how"],
    words: [
      "because",
      "we",
      "cannot",
      "know",
      "what",
      "god",
      "is",
      "but",
      "only",
      "he",
      "not",
      "consider",
      "how",
    ],
    id: "Because we cannot know what God is, but only what He is not, we cannot consider how He is but only how He is not.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["spring", "root", "honesty", "virtue", "lie", "education"],
    words: [
      "the",
      "very",
      "spring",
      "and",
      "root",
      "of",
      "honesty",
      "virtue",
      "lie",
      "in",
      "good",
      "education",
    ],
    id: "The very spring and root of honesty and virtue lie in good education.",
    author: "Plutarch",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "expand", "my", "become", "better", "person"],
    words: [
      "i",
      "just",
      "want",
      "to",
      "expand",
      "my",
      "learning",
      "and",
      "become",
      "a",
      "better",
      "person",
    ],
    id: "I just want to expand my learning and become a better person.",
    author: "Caleb McLaughlin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["family", "come", "from", "isn't", "as", "important"],
    words: [
      "the",
      "family",
      "you",
      "come",
      "from",
      "isn't",
      "as",
      "important",
      "you're",
      "going",
      "to",
      "have",
    ],
    id: "The family you come from isn't as important as the family you're going to have.",
    author: "Ring Lardner",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["fear", "failure", "greatest", "motivational", "tool", "drives"],
    words: [
      "fear",
      "of",
      "failure",
      "it's",
      "the",
      "greatest",
      "motivational",
      "tool",
      "it",
      "drives",
      "me",
      "and",
    ],
    id: "Fear of failure, it's the greatest motivational tool. It drives me and drives me and drives me.",
    author: "Jerry West",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["justice", "kind", "compact", "harm", "or", "harmed"],
    words: [
      "justice",
      "is",
      "a",
      "kind",
      "of",
      "compact",
      "not",
      "to",
      "harm",
      "or",
      "be",
      "harmed",
    ],
    id: "Justice... is a kind of compact not to harm or be harmed.",
    author: "Epicurus",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["cannot", "done", "should", "interrupt", "those", "doing"],
    words: [
      "people",
      "who",
      "say",
      "it",
      "cannot",
      "be",
      "done",
      "should",
      "not",
      "interrupt",
      "those",
      "are",
      "doing",
    ],
    id: "People who say it cannot be done should not interrupt those who are doing it.",
    author: "Unknown",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["punch", "hard", "knockouts", "beat", "knock", "out"],
    words: [
      "you",
      "have",
      "to",
      "punch",
      "hard",
      "for",
      "knockouts",
      "and",
      "beat",
      "me",
      "knock",
      "out",
    ],
    id: "You have to punch hard for knockouts, and to beat me, you have to knock me out.",
    author: "Khabib Nurmagomedov",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "isn't", "end", "result", "along", "way"],
    words: [
      "success",
      "isn't",
      "about",
      "the",
      "end",
      "result",
      "it's",
      "what",
      "you",
      "learn",
      "along",
      "way",
    ],
    id: "Success isn't about the end result, it's about what you learn along the way.",
    author: "Vera Wang",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "what's", "idea", "or", "bad", "until"],
    words: [
      "no",
      "one",
      "knows",
      "what's",
      "a",
      "good",
      "idea",
      "or",
      "bad",
      "until",
      "you",
      "try",
      "it",
    ],
    id: "No one knows what's a good idea or a bad idea until you try it.",
    author: "Marc Randolph",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["progress", "lies", "enhancing", "advancing", "toward", "will"],
    words: [
      "progress",
      "lies",
      "not",
      "in",
      "enhancing",
      "what",
      "is",
      "but",
      "advancing",
      "toward",
      "will",
      "be",
    ],
    id: "Progress lies not in enhancing what is, but in advancing toward what will be.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["patriotism", "willingness", "kill", "killed", "trivial", "reasons"],
    words: [
      "patriotism",
      "is",
      "the",
      "willingness",
      "to",
      "kill",
      "and",
      "be",
      "killed",
      "for",
      "trivial",
      "reasons",
    ],
    id: "Patriotism is the willingness to kill and be killed for trivial reasons.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["student", "something", "offer", "deserves", "nurturing", "environment"],
    words: [
      "every",
      "student",
      "has",
      "something",
      "to",
      "offer",
      "and",
      "deserves",
      "a",
      "nurturing",
      "learning",
      "environment",
    ],
    id: "Every student has something to offer, and every student deserves a nurturing learning environment.",
    author: "Ilhan Omar",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "hope", "communion", "most", "important", "thing"],
    words: [
      "i",
      "hope",
      "to",
      "have",
      "communion",
      "with",
      "the",
      "people",
      "that",
      "is",
      "most",
      "important",
      "thing",
    ],
    id: "I hope to have communion with the people, that is the most important thing.",
    author: "Pope John Paul II",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyone", "praise", "blame", "human", "virtue", "madness"],
    words: [
      "what",
      "can",
      "everyone",
      "do",
      "praise",
      "and",
      "blame",
      "this",
      "is",
      "human",
      "virtue",
      "madness",
    ],
    id: "What can everyone do? Praise and blame. This is human virtue, this is human madness.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "nothing", "either", "or", "bad", "makes"],
    words: [
      "there",
      "is",
      "nothing",
      "either",
      "good",
      "or",
      "bad",
      "but",
      "thinking",
      "makes",
      "it",
      "so",
    ],
    id: "There is nothing either good or bad but thinking makes it so.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["anything", "else", "under", "heaven", "really", "matters"],
    words: [
      "love",
      "him",
      "and",
      "let",
      "you",
      "do",
      "think",
      "anything",
      "else",
      "under",
      "heaven",
      "really",
      "matters",
    ],
    id: "Love him and let him love you. Do you think anything else under heaven really matters?",
    author: "James Baldwin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "purpose", "friendship", "save", "deepening", "spirit"],
    words: [
      "let",
      "there",
      "be",
      "no",
      "purpose",
      "in",
      "friendship",
      "save",
      "the",
      "deepening",
      "of",
      "spirit",
    ],
    id: "Let there be no purpose in friendship save the deepening of the spirit.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "dealing", "remember", "creatures", "logic", "emotion"],
    words: [
      "when",
      "dealing",
      "with",
      "people",
      "remember",
      "you",
      "are",
      "not",
      "creatures",
      "of",
      "logic",
      "but",
      "emotion",
    ],
    id: "When dealing with people, remember you are not dealing with creatures of logic, but creatures of emotion.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "limit", "our", "realization", "will", "doubts"],
    words: [
      "the",
      "only",
      "limit",
      "to",
      "our",
      "realization",
      "of",
      "tomorrow",
      "will",
      "be",
      "doubts",
      "today",
    ],
    id: "The only limit to our realization of tomorrow will be our doubts of today.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "how", "hard", "woman", "especially", "black"],
    words: [
      "i",
      "know",
      "how",
      "hard",
      "it",
      "is",
      "to",
      "be",
      "a",
      "woman",
      "especially",
      "black",
    ],
    id: "I know how hard it is to be a woman, especially a black woman.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["turbulence", "life", "force", "opportunity", "use", "change"],
    words: [
      "turbulence",
      "is",
      "life",
      "force",
      "it",
      "opportunity",
      "let's",
      "love",
      "and",
      "use",
      "for",
      "change",
    ],
    id: "Turbulence is life force. It is opportunity. Let's love turbulence and use it for change.",
    author: "Ramsey Clark",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["actions", "ennoble", "sons", "our", "own", "deeds"],
    words: [
      "good",
      "actions",
      "ennoble",
      "us",
      "we",
      "are",
      "the",
      "sons",
      "of",
      "our",
      "own",
      "deeds",
    ],
    id: "Good actions ennoble us, we are the sons of our own deeds.",
    author: "Jean Paul",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["woman", "plan", "when", "family", "how", "support"],
    words: [
      "a",
      "woman",
      "can",
      "plan",
      "when",
      "to",
      "have",
      "her",
      "family",
      "and",
      "how",
      "support",
    ],
    id: "A woman can plan when to have her family and how to support a family.",
    author: "Kathleen Turner",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["possesses", "nor", "will", "possessed", "sufficient", "unto"],
    words: [
      "love",
      "possesses",
      "not",
      "nor",
      "will",
      "it",
      "be",
      "possessed",
      "for",
      "is",
      "sufficient",
      "unto",
    ],
    id: "Love possesses not nor will it be possessed, for love is sufficient unto love.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["free", "person", "from", "error", "take", "away"],
    words: [
      "to",
      "free",
      "a",
      "person",
      "from",
      "error",
      "is",
      "give",
      "and",
      "not",
      "take",
      "away",
    ],
    id: "To free a person from error is to give, and not to take away.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "lives", "words", "religion", "must", "read"],
    words: [
      "it",
      "is",
      "in",
      "our",
      "lives",
      "and",
      "not",
      "words",
      "that",
      "religion",
      "must",
      "be",
      "read",
    ],
    id: "It is in our lives and not our words that our religion must be read.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "only", "one", "kind", "thousand", "imitations"],
    words: [
      "there",
      "is",
      "only",
      "one",
      "kind",
      "of",
      "love",
      "but",
      "are",
      "a",
      "thousand",
      "imitations",
    ],
    id: "There is only one kind of love, but there are a thousand imitations.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["things", "come", "easy", "road", "lined", "pitfalls"],
    words: [
      "good",
      "things",
      "do",
      "not",
      "come",
      "easy",
      "the",
      "road",
      "is",
      "lined",
      "with",
      "pitfalls",
    ],
    id: "Good things do not come easy. The road is lined with pitfalls.",
    author: "Desi Arnaz",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["horror", "movies", "exist", "unless", "always", "will"],
    words: [
      "horror",
      "movies",
      "don't",
      "exist",
      "unless",
      "you",
      "go",
      "and",
      "see",
      "them",
      "people",
      "always",
      "will",
    ],
    id: "Horror movies don't exist unless you go and see them, and people always will.",
    author: "Joss Whedon",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["days", "bad", "main", "thing", "grow", "mentally"],
    words: [
      "you",
      "have",
      "good",
      "days",
      "bad",
      "but",
      "the",
      "main",
      "thing",
      "is",
      "to",
      "grow",
      "mentally",
    ],
    id: "You have good days, you have bad days. But the main thing is to grow mentally.",
    author: "Floyd Mayweather, Jr.",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "may", "god", "put", "there", "keep"],
    words: ["if", "i", "am", "not", "may", "god", "put", "me", "there", "and", "so", "keep"],
    id: "If I am not, may God put me there; and if I am, may God so keep me.",
    author: "Joan of Arc",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "only", "one", "happiness", "life", "loved"],
    words: [
      "there",
      "is",
      "only",
      "one",
      "happiness",
      "in",
      "this",
      "life",
      "to",
      "love",
      "and",
      "be",
      "loved",
    ],
    id: "There is only one happiness in this life, to love and be loved.",
    author: "George Sand",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["tell", "joke", "forest", "nobody", "laughs", "was"],
    words: [
      "if",
      "you",
      "tell",
      "a",
      "joke",
      "in",
      "the",
      "forest",
      "but",
      "nobody",
      "laughs",
      "was",
      "it",
    ],
    id: "If you tell a joke in the forest, but nobody laughs, was it a joke?",
    author: "Steven Wright",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["children", "educated", "by", "grown-up", "his", "talk"],
    words: [
      "children",
      "are",
      "educated",
      "by",
      "what",
      "the",
      "grown-up",
      "is",
      "and",
      "not",
      "his",
      "talk",
    ],
    id: "Children are educated by what the grown-up is and not by his talk.",
    author: "Carl Jung",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["majority", "husbands", "remind", "orangutan", "trying", "play"],
    words: [
      "the",
      "majority",
      "of",
      "husbands",
      "remind",
      "me",
      "an",
      "orangutan",
      "trying",
      "to",
      "play",
      "violin",
    ],
    id: "The majority of husbands remind me of an orangutan trying to play the violin.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["may", "as", "never", "occasion", "repent", "our"],
    words: [
      "may",
      "we",
      "so",
      "love",
      "as",
      "never",
      "to",
      "have",
      "occasion",
      "repent",
      "of",
      "our",
    ],
    id: "May we so love as never to have occasion to repent of our love!",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["economy", "will", "pay", "any", "price", "get"],
    words: [
      "people",
      "want",
      "economy",
      "and",
      "they",
      "will",
      "pay",
      "any",
      "price",
      "to",
      "get",
      "it",
    ],
    id: "People want economy and they will pay any price to get it.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "chef", "happens", "appear", "on", "telly"],
    words: [
      "i",
      "am",
      "a",
      "chef",
      "who",
      "happens",
      "to",
      "appear",
      "on",
      "the",
      "telly",
      "that's",
      "it",
    ],
    id: "I am a chef who happens to appear on the telly, that's it.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["dull", "thing", "style-now", "i", "call", "art"],
    words: [
      "to",
      "do",
      "a",
      "dull",
      "thing",
      "with",
      "style-now",
      "that's",
      "what",
      "i",
      "call",
      "art",
    ],
    id: "To do a dull thing with style-now that's what I call art.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "without", "hope", "faith", "agonizing", "death"],
    words: [
      "love",
      "we",
      "say",
      "is",
      "life",
      "but",
      "without",
      "hope",
      "and",
      "faith",
      "agonizing",
      "death",
    ],
    id: "Love, we say, is life; but love without hope and faith is agonizing death.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "direction", "may", "end", "up", "heading"],
    words: [
      "if",
      "you",
      "do",
      "not",
      "change",
      "direction",
      "may",
      "end",
      "up",
      "where",
      "are",
      "heading",
    ],
    id: "If you do not change direction, you may end up where you are heading.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["can't", "rigid", "view", "all", "taxes", "evil"],
    words: [
      "you",
      "can't",
      "have",
      "a",
      "rigid",
      "view",
      "that",
      "all",
      "new",
      "taxes",
      "are",
      "evil",
    ],
    id: "You can't have a rigid view that all new taxes are evil.",
    author: "Bill Gates",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "talent", "god's", "gift", "back", "god"],
    words: [
      "your",
      "talent",
      "is",
      "god's",
      "gift",
      "to",
      "you",
      "what",
      "do",
      "with",
      "it",
      "back",
      "god",
    ],
    id: "Your talent is God's gift to you. What you do with it is your gift back to God.",
    author: "Leo Buscaglia",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["pessimist", "person", "been", "intimately", "acquainted", "optimist"],
    words: [
      "a",
      "pessimist",
      "that's",
      "person",
      "who",
      "has",
      "been",
      "intimately",
      "acquainted",
      "with",
      "an",
      "optimist",
    ],
    id: "A pessimist? That's a person who has been intimately acquainted with an optimist.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["why", "live", "bear", "almost", "any", "how"],
    words: ["he", "who", "has", "a", "why", "to", "live", "can", "bear", "almost", "any", "how"],
    id: "He who has a why to live can bear almost any how.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["keep", "your", "face", "sunshine", "cannot", "shadow"],
    words: [
      "keep",
      "your",
      "face",
      "to",
      "the",
      "sunshine",
      "and",
      "you",
      "cannot",
      "see",
      "a",
      "shadow",
    ],
    id: "Keep your face to the sunshine and you cannot see a shadow.",
    author: "Helen Keller",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["seek", "wisdom", "understanding", "only", "length", "days"],
    words: [
      "we",
      "are",
      "to",
      "seek",
      "wisdom",
      "and",
      "understanding",
      "only",
      "in",
      "the",
      "length",
      "of",
      "days",
    ],
    id: "We are to seek wisdom and understanding only in the length of days.",
    author: "Robert Hall",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["sake", "being", "loved", "human", "loving", "angelic"],
    words: [
      "to",
      "love",
      "for",
      "the",
      "sake",
      "of",
      "being",
      "loved",
      "is",
      "human",
      "but",
      "loving",
      "angelic",
    ],
    id: "To love for the sake of being loved is human, but to love for the sake of loving is angelic.",
    author: "Alphonse de Lamartine",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "would", "believe", "only", "god", "how"],
    words: [
      "i",
      "would",
      "believe",
      "only",
      "in",
      "a",
      "god",
      "that",
      "knows",
      "how",
      "to",
      "dance",
    ],
    id: "I would believe only in a God that knows how to Dance.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["way", "silence", "religious", "disputes", "take", "notice"],
    words: [
      "the",
      "way",
      "to",
      "silence",
      "religious",
      "disputes",
      "is",
      "take",
      "no",
      "notice",
      "of",
      "them",
    ],
    id: "The way to silence religious disputes is to take no notice of them.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["seldom", "improve", "when", "model", "themselves", "copy"],
    words: [
      "people",
      "seldom",
      "improve",
      "when",
      "they",
      "have",
      "no",
      "other",
      "model",
      "but",
      "themselves",
      "to",
      "copy",
    ],
    id: "People seldom improve when they have no other model but themselves to copy.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["hardest", "close", "open", "hand", "because", "one"],
    words: [
      "this",
      "is",
      "what",
      "hardest",
      "to",
      "close",
      "the",
      "open",
      "hand",
      "because",
      "one",
      "loves",
    ],
    id: "This is what is hardest: to close the open hand because one loves.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "i'm", "trying", "always", "open", "how"],
    words: [
      "i",
      "know",
      "what",
      "i'm",
      "trying",
      "to",
      "say",
      "so",
      "always",
      "open",
      "learning",
      "how",
      "it",
    ],
    id: "I know what I'm trying to say, so I'm always open to learning how to say it.",
    author: "Lynn Nottage",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["choose", "your", "family", "god's", "gift", "as"],
    words: [
      "you",
      "don't",
      "choose",
      "your",
      "family",
      "they",
      "are",
      "god's",
      "gift",
      "to",
      "as",
      "them",
    ],
    id: "You don't choose your family. They are God's gift to you, as you are to them.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["succeeded", "life", "when", "all", "really", "only"],
    words: [
      "you",
      "have",
      "succeeded",
      "in",
      "life",
      "when",
      "all",
      "really",
      "want",
      "is",
      "only",
      "what",
      "need",
    ],
    id: "You have succeeded in life when all you really want is only what you really need.",
    author: "Vernon Howard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["impossible", "one", "class", "appreciate", "wrongs", "another"],
    words: [
      "it",
      "is",
      "impossible",
      "for",
      "one",
      "class",
      "to",
      "appreciate",
      "the",
      "wrongs",
      "of",
      "another",
    ],
    id: "It is impossible for one class to appreciate the wrongs of another.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["general", "benevolence", "friendship", "made", "man", "ought"],
    words: [
      "general",
      "benevolence",
      "but",
      "not",
      "friendship",
      "made",
      "a",
      "man",
      "what",
      "he",
      "ought",
      "to",
      "be",
    ],
    id: "General benevolence, but not general friendship, made a man what he ought to be.",
    author: "Jane Austen",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyone", "chance", "improve", "up", "their", "skills"],
    words: [
      "everyone",
      "has",
      "a",
      "chance",
      "to",
      "learn",
      "improve",
      "and",
      "build",
      "up",
      "their",
      "skills",
    ],
    id: "Everyone has a chance to learn, improve, and build up their skills.",
    author: "Tom Peters",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "paid", "having", "head", "hands", "using"],
    words: [
      "a",
      "man",
      "is",
      "not",
      "paid",
      "for",
      "having",
      "head",
      "and",
      "hands",
      "but",
      "using",
      "them",
    ],
    id: "A man is not paid for having a head and hands, but for using them.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["behavior", "man", "thinks", "feels", "or", "believes"],
    words: [
      "behavior",
      "is",
      "what",
      "a",
      "man",
      "does",
      "not",
      "he",
      "thinks",
      "feels",
      "or",
      "believes",
    ],
    id: "Behavior is what a man does, not what he thinks, feels, or believes.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "action", "never", "too", "late", "something"],
    words: [
      "the",
      "time",
      "for",
      "action",
      "is",
      "now",
      "it's",
      "never",
      "too",
      "late",
      "to",
      "do",
      "something",
    ],
    id: "The time for action is now. It's never too late to do something.",
    author: "Antoine de Saint-Exupery",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "heat", "planet", "up", "boiling", "pot"],
    words: [
      "as",
      "you",
      "heat",
      "the",
      "planet",
      "up",
      "it's",
      "just",
      "like",
      "boiling",
      "a",
      "pot",
    ],
    id: "As you heat the planet up, it's just like boiling a pot.",
    author: "Elon Musk",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["common", "saying", "everybody's", "mouth", "life", "sojourn"],
    words: [
      "it",
      "is",
      "a",
      "common",
      "saying",
      "and",
      "in",
      "everybody's",
      "mouth",
      "that",
      "life",
      "but",
      "sojourn",
    ],
    id: "It is a common saying, and in everybody's mouth, that life is but a sojourn.",
    author: "Plato",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "support", "i'll", "fight", "because", "i"],
    words: [
      "give",
      "me",
      "your",
      "support",
      "and",
      "i'll",
      "fight",
      "for",
      "you",
      "because",
      "i",
      "am",
    ],
    id: "Give me your support, and I'll fight for you... I'll fight for you because I am you.",
    author: "Harvey Milk",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["each", "perfect", "heaven-sent", "holds", "seeds", "growth"],
    words: [
      "each",
      "moment",
      "is",
      "perfect",
      "and",
      "heaven-sent",
      "in",
      "that",
      "holds",
      "the",
      "seeds",
      "for",
      "growth",
    ],
    id: "Each moment is perfect and heaven-sent, in that each moment holds the seeds for growth.",
    author: "Suzan-Lori Parks",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["matches", "called", "illusion", "their", "father", "mother"],
    words: [
      "love",
      "matches",
      "so",
      "called",
      "have",
      "illusion",
      "for",
      "their",
      "father",
      "and",
      "need",
      "mother",
    ],
    id: "Love matches, so called, have illusion for their father and need for their mother.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["many", "would", "sooner", "die", "than", "fact"],
    words: [
      "many",
      "people",
      "would",
      "sooner",
      "die",
      "than",
      "think",
      "in",
      "fact",
      "they",
      "do",
      "so",
    ],
    id: "Many people would sooner die than think; in fact, they do so.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["easy", "find", "happiness", "ourselves", "possible", "elsewhere"],
    words: [
      "it",
      "is",
      "not",
      "easy",
      "to",
      "find",
      "happiness",
      "in",
      "ourselves",
      "and",
      "possible",
      "elsewhere",
    ],
    id: "It is not easy to find happiness in ourselves, and it is not possible to find it elsewhere.",
    author: "Agnes Repplier",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "living", "daily", "i", "thank", "god"],
    words: [
      "i'm",
      "just",
      "living",
      "daily",
      "and",
      "i",
      "thank",
      "god",
      "for",
      "every",
      "day",
      "that",
      "have",
    ],
    id: "I'm just living daily and I thank God for every day that I have.",
    author: "Daniel Jacobs",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "agony", "parting", "look", "into", "depths"],
    words: [
      "only",
      "in",
      "the",
      "agony",
      "of",
      "parting",
      "do",
      "we",
      "look",
      "into",
      "depths",
      "love",
    ],
    id: "Only in the agony of parting do we look into the depths of love.",
    author: "George Eliot",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["displease", "merit", "others", "faults", "defects", "pleasing"],
    words: [
      "some",
      "people",
      "displease",
      "with",
      "merit",
      "and",
      "others",
      "very",
      "faults",
      "defects",
      "are",
      "pleasing",
    ],
    id: "Some people displease with merit, and others' very faults and defects are pleasing.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["wedded", "freedom", "expression", "shall", "nothing", "diminish"],
    words: [
      "we",
      "are",
      "wedded",
      "to",
      "freedom",
      "of",
      "expression",
      "and",
      "shall",
      "do",
      "nothing",
      "diminish",
      "that",
    ],
    id: "We are wedded to freedom of expression and shall do nothing to diminish that freedom.",
    author: "Kapil Sibal",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["conscience", "inner", "voice", "warns", "might", "looking"],
    words: [
      "conscience",
      "is",
      "the",
      "inner",
      "voice",
      "that",
      "warns",
      "us",
      "someone",
      "might",
      "be",
      "looking",
    ],
    id: "Conscience is the inner voice that warns us that someone might be looking.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["on", "tv", "radio", "you've", "got", "create"],
    words: [
      "on",
      "tv",
      "the",
      "people",
      "can",
      "see",
      "it",
      "radio",
      "you've",
      "got",
      "to",
      "create",
    ],
    id: "On TV the people can see it. On radio you've got to create it.",
    author: "Bob Uecker",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "difference", "between", "madman", "i'm", "mad"],
    words: [
      "the",
      "only",
      "difference",
      "between",
      "me",
      "and",
      "a",
      "madman",
      "is",
      "that",
      "i'm",
      "not",
      "mad",
    ],
    id: "The only difference between me and a madman is that I'm not mad.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["belief", "knowledge", "considered", "two", "different", "things"],
    words: [
      "belief",
      "and",
      "knowledge",
      "are",
      "considered",
      "to",
      "be",
      "two",
      "different",
      "things",
      "but",
      "they",
      "not",
    ],
    id: "Belief and knowledge are considered to be two different things. But they are not.",
    author: "Stanley Fish",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "all", "squads", "i'm", "power", "friendship"],
    words: [
      "i",
      "love",
      "all",
      "girl",
      "squads",
      "i'm",
      "for",
      "like",
      "power",
      "and",
      "friendship",
      "about",
      "that",
    ],
    id: "I love all girl squads. I'm all for, like, girl power and friendship, and I'm all about that.",
    author: "Poppy Delevingne",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "when", "spread", "wealth", "around", "everybody"],
    words: [
      "i",
      "think",
      "when",
      "you",
      "spread",
      "the",
      "wealth",
      "around",
      "it's",
      "good",
      "for",
      "everybody",
    ],
    id: "I think when you spread the wealth around it's good for everybody.",
    author: "Barack Obama",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["whole", "phenomenon", "computer", "library", "amazing", "thing"],
    words: [
      "this",
      "whole",
      "phenomenon",
      "of",
      "the",
      "computer",
      "in",
      "a",
      "library",
      "is",
      "an",
      "amazing",
      "thing",
    ],
    id: "This whole phenomenon of the computer in a library is an amazing thing.",
    author: "Bill Gates",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["hard", "ape", "believe", "descended", "from", "man"],
    words: [
      "it",
      "is",
      "hard",
      "for",
      "the",
      "ape",
      "to",
      "believe",
      "he",
      "descended",
      "from",
      "man",
    ],
    id: "It is hard for the ape to believe he descended from man.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["matter", "how", "slowly", "as", "long", "stop"],
    words: [
      "it",
      "does",
      "not",
      "matter",
      "how",
      "slowly",
      "you",
      "go",
      "as",
      "long",
      "do",
      "stop",
    ],
    id: "It does not matter how slowly you go as long as you do not stop.",
    author: "Confucius",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["wishing", "friends", "quick", "friendship", "slow", "ripening"],
    words: [
      "wishing",
      "to",
      "be",
      "friends",
      "is",
      "quick",
      "work",
      "but",
      "friendship",
      "a",
      "slow",
      "ripening",
      "fruit",
    ],
    id: "Wishing to be friends is quick work, but friendship is a slow ripening fruit.",
    author: "Aristotle",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["takes", "courage", "grow", "up", "become", "really"],
    words: [
      "it",
      "takes",
      "courage",
      "to",
      "grow",
      "up",
      "and",
      "become",
      "who",
      "you",
      "really",
      "are",
    ],
    id: "It takes courage to grow up and become who you really are.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "simple", "formula", "your", "best", "may"],
    words: [
      "success",
      "has",
      "a",
      "simple",
      "formula",
      "do",
      "your",
      "best",
      "and",
      "people",
      "may",
      "like",
      "it",
    ],
    id: "Success has a simple formula: do your best, and people may like it.",
    author: "Sam Ewing",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["remember", "happiest", "those", "getting", "more", "giving"],
    words: [
      "remember",
      "that",
      "the",
      "happiest",
      "people",
      "are",
      "not",
      "those",
      "getting",
      "more",
      "but",
      "giving",
    ],
    id: "Remember that the happiest people are not those getting more, but those giving more.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatever", "will", "first", "such", "as", "able"],
    words: [
      "do",
      "whatever",
      "you",
      "will",
      "but",
      "first",
      "be",
      "such",
      "as",
      "are",
      "able",
      "to",
    ],
    id: "Do whatever you will, but first be such as are able to will.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["leadership", "consists", "showing", "average", "how", "superior"],
    words: [
      "good",
      "leadership",
      "consists",
      "of",
      "showing",
      "average",
      "people",
      "how",
      "to",
      "do",
      "the",
      "work",
      "superior",
    ],
    id: "Good leadership consists of showing average people how to do the work of superior people.",
    author: "John D. Rockefeller",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "would", "sooner", "die", "than", "fact"],
    words: [
      "most",
      "people",
      "would",
      "sooner",
      "die",
      "than",
      "think",
      "in",
      "fact",
      "they",
      "do",
      "so",
    ],
    id: "Most people would sooner die than think; in fact, they do so.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "discriminate", "between", "when", "comes", "friendship"],
    words: [
      "i",
      "don't",
      "discriminate",
      "between",
      "a",
      "boy",
      "and",
      "girl",
      "when",
      "it",
      "comes",
      "to",
      "friendship",
    ],
    id: "I don't discriminate between a boy and girl when it comes to friendship.",
    author: "Pooja Bedi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["how", "easy", "amiable", "midst", "happiness", "success"],
    words: [
      "how",
      "easy",
      "to",
      "be",
      "amiable",
      "in",
      "the",
      "midst",
      "of",
      "happiness",
      "and",
      "success",
    ],
    id: "How easy to be amiable in the midst of happiness and success.",
    author: "William Ellery Channing",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["grow", "thick", "skin", "only", "comes", "time"],
    words: [
      "you",
      "have",
      "to",
      "grow",
      "thick",
      "skin",
      "and",
      "that",
      "only",
      "comes",
      "with",
      "time",
      "learning",
    ],
    id: "You have to grow thick skin and that only comes with time and learning.",
    author: "Karlie Kloss",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "always", "ready", "although", "being", "taught"],
    words: [
      "i",
      "am",
      "always",
      "ready",
      "to",
      "learn",
      "although",
      "do",
      "not",
      "like",
      "being",
      "taught",
    ],
    id: "I am always ready to learn although I do not always like being taught.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "cannot", "found", "exist", "nor", "denied"],
    words: [
      "true",
      "love",
      "cannot",
      "be",
      "found",
      "where",
      "it",
      "does",
      "not",
      "exist",
      "nor",
      "can",
      "denied",
    ],
    id: "True love cannot be found where it does not exist, nor can it be denied where it does.",
    author: "Torquato Tasso",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["speech", "arrangement", "notes", "will", "never", "played"],
    words: [
      "speech",
      "is",
      "an",
      "arrangement",
      "of",
      "notes",
      "that",
      "will",
      "never",
      "be",
      "played",
      "again",
    ],
    id: "Speech is an arrangement of notes that will never be played again.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "way", "get", "best", "argument", "avoid"],
    words: ["the", "only", "way", "to", "get", "best", "of", "an", "argument", "is", "avoid", "it"],
    id: "The only way to get the best of an argument is to avoid it.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "worse", "sorrow", "than", "remembering", "happiness"],
    words: [
      "there",
      "is",
      "no",
      "worse",
      "sorrow",
      "than",
      "remembering",
      "happiness",
      "in",
      "the",
      "day",
      "of",
    ],
    id: "There is no worse sorrow than remembering happiness in the day of sorrow.",
    author: "Alfred de Musset",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "only", "count", "his", "troubles", "joys"],
    words: [
      "man",
      "only",
      "likes",
      "to",
      "count",
      "his",
      "troubles",
      "but",
      "he",
      "does",
      "not",
      "joys",
    ],
    id: "Man only likes to count his troubles, but he does not count his joys.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["aids", "god's", "punishment", "homosexuals", "society", "tolerates"],
    words: [
      "aids",
      "is",
      "not",
      "just",
      "god's",
      "punishment",
      "for",
      "homosexuals",
      "it",
      "the",
      "society",
      "that",
      "tolerates",
    ],
    id: "AIDS is not just God's punishment for homosexuals; it is God's punishment for the society that tolerates homosexuals.",
    author: "Jerry Falwell",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "place", "net", "keeper", "cannot", "reach"],
    words: [
      "there",
      "is",
      "a",
      "place",
      "in",
      "the",
      "net",
      "where",
      "keeper",
      "cannot",
      "reach",
      "ball",
    ],
    id: "There is a place in the net where the keeper cannot reach the ball.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "did", "become", "my", "health", "chickens"],
    words: [
      "i",
      "did",
      "not",
      "become",
      "a",
      "vegetarian",
      "for",
      "my",
      "health",
      "it",
      "the",
      "of",
      "chickens",
    ],
    id: "I did not become a vegetarian for my health, I did it for the health of the chickens.",
    author: "Isaac Bashevis Singer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["being", "ignorant", "much", "shame", "as", "unwilling"],
    words: [
      "being",
      "ignorant",
      "is",
      "not",
      "so",
      "much",
      "a",
      "shame",
      "as",
      "unwilling",
      "to",
      "learn",
    ],
    id: "Being ignorant is not so much a shame, as being unwilling to learn.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["women", "naturally", "secretive", "their", "own", "secreting"],
    words: [
      "women",
      "are",
      "naturally",
      "secretive",
      "and",
      "they",
      "like",
      "to",
      "do",
      "their",
      "own",
      "secreting",
    ],
    id: "Women are naturally secretive, and they like to do their own secreting.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "way", "beat", "lawyers", "die", "nothing"],
    words: [
      "the",
      "only",
      "way",
      "you",
      "can",
      "beat",
      "lawyers",
      "is",
      "to",
      "die",
      "with",
      "nothing",
    ],
    id: "The only way you can beat the lawyers is to die with nothing.",
    author: "Will Rogers",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["mistakes", "always", "forgivable", "one", "courage", "admit"],
    words: [
      "mistakes",
      "are",
      "always",
      "forgivable",
      "if",
      "one",
      "has",
      "the",
      "courage",
      "to",
      "admit",
      "them",
    ],
    id: "Mistakes are always forgivable, if one has the courage to admit them.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "especially", "when", "half", "art", "diplomacy"],
    words: [
      "to",
      "say",
      "nothing",
      "especially",
      "when",
      "speaking",
      "is",
      "half",
      "the",
      "art",
      "of",
      "diplomacy",
    ],
    id: "To say nothing, especially when speaking, is half the art of diplomacy.",
    author: "Will Durant",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["may", "god", "save", "country", "evident", "will"],
    words: [
      "may",
      "god",
      "save",
      "the",
      "country",
      "for",
      "it",
      "is",
      "evident",
      "that",
      "people",
      "will",
      "not",
    ],
    id: "May God save the country, for it is evident that the people will not.",
    author: "Millard Fillmore",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["gardening", "meditation", "helps", "remember", "process", "patience"],
    words: [
      "gardening",
      "is",
      "a",
      "working",
      "meditation",
      "for",
      "me",
      "it",
      "helps",
      "remember",
      "process",
      "and",
      "patience",
    ],
    id: "Gardening is a working meditation for me. It helps me remember process, and it helps me remember patience.",
    author: "Lizz Wright",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["above", "make", "world", "serve", "your", "purpose"],
    words: [
      "be",
      "above",
      "it",
      "make",
      "the",
      "world",
      "serve",
      "your",
      "purpose",
      "but",
      "do",
      "not",
    ],
    id: "Be above it! Make the world serve your purpose, but do not serve it.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["forgive", "set", "prisoner", "free", "discover", "was"],
    words: [
      "to",
      "forgive",
      "is",
      "set",
      "a",
      "prisoner",
      "free",
      "and",
      "discover",
      "that",
      "the",
      "was",
      "you",
    ],
    id: "To forgive is to set a prisoner free and discover that the prisoner was you.",
    author: "Lewis B. Smedes",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["different", "being", "makes", "stand", "out", "world"],
    words: [
      "different",
      "is",
      "good",
      "and",
      "being",
      "what",
      "makes",
      "us",
      "stand",
      "out",
      "in",
      "the",
      "world",
    ],
    id: "Different is good, and being different is what makes us stand out in the world.",
    author: "Natalya Neidhart",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "future", "holds", "all", "i'm", "real"],
    words: [
      "i",
      "don't",
      "know",
      "what",
      "the",
      "future",
      "holds",
      "all",
      "is",
      "i'm",
      "good",
      "today",
      "real",
    ],
    id: "I don't know what the future holds. All I know is, I'm good today. Real good.",
    author: "Chris Farley",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "hammer", "tend", "problem", "as", "nail"],
    words: [
      "if",
      "you",
      "only",
      "have",
      "a",
      "hammer",
      "tend",
      "to",
      "see",
      "every",
      "problem",
      "as",
      "nail",
    ],
    id: "If you only have a hammer, you tend to see every problem as a nail.",
    author: "Abraham Maslow",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["matter", "tell", "words", "ideas", "change", "world"],
    words: [
      "no",
      "matter",
      "what",
      "people",
      "tell",
      "you",
      "words",
      "and",
      "ideas",
      "can",
      "change",
      "the",
      "world",
    ],
    id: "No matter what people tell you, words and ideas can change the world.",
    author: "Robin Williams",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["americans", "rising", "tasks", "history", "expect", "same"],
    words: [
      "americans",
      "are",
      "rising",
      "to",
      "the",
      "tasks",
      "of",
      "history",
      "and",
      "they",
      "expect",
      "same",
      "us",
    ],
    id: "Americans are rising to the tasks of history, and they expect the same of us.",
    author: "George W. Bush",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "could", "live", "didn't", "intellectual", "friendship"],
    words: [
      "i",
      "don't",
      "think",
      "could",
      "live",
      "with",
      "someone",
      "that",
      "didn't",
      "have",
      "an",
      "intellectual",
      "friendship",
    ],
    id: "I don't think I could live with someone that I didn't have an intellectual friendship with.",
    author: "Jonathan Franzen",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["aim", "wise", "secure", "pleasure", "avoid", "pain"],
    words: [
      "the",
      "aim",
      "of",
      "wise",
      "is",
      "not",
      "to",
      "secure",
      "pleasure",
      "but",
      "avoid",
      "pain",
    ],
    id: "The aim of the wise is not to secure pleasure, but to avoid pain.",
    author: "Aristotle",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["barbecue", "may", "road", "world", "peace", "start"],
    words: [
      "barbecue",
      "may",
      "not",
      "be",
      "the",
      "road",
      "to",
      "world",
      "peace",
      "but",
      "it's",
      "a",
      "start",
    ],
    id: "Barbecue may not be the road to world peace, but it's a start.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["find", "your", "life", "then", "hang", "on"],
    words: [
      "if",
      "you",
      "find",
      "someone",
      "love",
      "in",
      "your",
      "life",
      "then",
      "hang",
      "on",
      "to",
      "that",
    ],
    id: "If you find someone you love in your life, then hang on to that love.",
    author: "Princess Diana",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["everything", "comes", "belongs", "create", "capacity", "receive"],
    words: [
      "everything",
      "comes",
      "to",
      "us",
      "that",
      "belongs",
      "if",
      "we",
      "create",
      "the",
      "capacity",
      "receive",
      "it",
    ],
    id: "Everything comes to us that belongs to us if we create the capacity to receive it.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["greatest", "all", "advantages", "enjoy", "advantage", "at"],
    words: [
      "it",
      "is",
      "the",
      "greatest",
      "of",
      "all",
      "advantages",
      "to",
      "enjoy",
      "no",
      "advantage",
      "at",
    ],
    id: "It is the greatest of all advantages to enjoy no advantage at all.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "can't", "done", "counts", "risk", "reward"],
    words: [
      "the",
      "man",
      "who",
      "knows",
      "it",
      "can't",
      "be",
      "done",
      "counts",
      "risk",
      "not",
      "reward",
    ],
    id: "The man who knows it can't be done counts the risk, not the reward.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["longer", "question", "staying", "healthy", "finding", "sickness"],
    words: [
      "it's",
      "no",
      "longer",
      "a",
      "question",
      "of",
      "staying",
      "healthy",
      "finding",
      "sickness",
      "you",
      "like",
    ],
    id: "It's no longer a question of staying healthy. It's a question of finding a sickness you like.",
    author: "Jackie Mason",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["cease", "decrees", "gods", "changed", "by", "prayers"],
    words: [
      "cease",
      "to",
      "think",
      "that",
      "the",
      "decrees",
      "of",
      "gods",
      "can",
      "be",
      "changed",
      "by",
      "prayers",
    ],
    id: "Cease to think that the decrees of the gods can be changed by prayers.",
    author: "Virgil",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["how", "dreadful", "knowledge", "truth", "when", "there's"],
    words: [
      "how",
      "dreadful",
      "knowledge",
      "of",
      "the",
      "truth",
      "can",
      "be",
      "when",
      "there's",
      "no",
      "help",
      "in",
    ],
    id: "How dreadful knowledge of the truth can be when there's no help in the truth.",
    author: "Sophocles",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "different", "vision", "leadership", "brings", "together"],
    words: [
      "i",
      "have",
      "a",
      "different",
      "vision",
      "of",
      "leadership",
      "is",
      "someone",
      "who",
      "brings",
      "people",
      "together",
    ],
    id: "I have a different vision of leadership. A leadership is someone who brings people together.",
    author: "George W. Bush",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["write", "on", "your", "heart", "best", "year"],
    words: [
      "write",
      "it",
      "on",
      "your",
      "heart",
      "that",
      "every",
      "day",
      "is",
      "the",
      "best",
      "in",
      "year",
    ],
    id: "Write it on your heart that every day is the best day in the year.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "country", "prison", "first", "then", "become"],
    words: [
      "in",
      "my",
      "country",
      "we",
      "go",
      "to",
      "prison",
      "first",
      "and",
      "then",
      "become",
      "president",
    ],
    id: "In my country we go to prison first and then become President.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["may", "cock", "crows", "hen", "lays", "eggs"],
    words: ["it", "may", "be", "the", "cock", "that", "crows", "but", "is", "hen", "lays", "eggs"],
    id: "It may be the cock that crows, but it is the hen that lays the eggs.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "something", "applaud", "will", "make", "two"],
    words: [
      "when",
      "someone",
      "does",
      "something",
      "good",
      "applaud",
      "you",
      "will",
      "make",
      "two",
      "people",
      "happy",
    ],
    id: "When someone does something good, applaud! You will make two people happy.",
    author: "Samuel Goldwyn",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["stuff", "i", "my", "chances", "liking", "up"],
    words: [
      "if",
      "you",
      "like",
      "the",
      "stuff",
      "i",
      "do",
      "my",
      "chances",
      "of",
      "liking",
      "go",
      "up",
    ],
    id: "If you like the stuff I do, my chances of liking you go up.",
    author: "Penn Jillette",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "feared", "than", "loved", "cannot", "both"],
    words: [
      "it",
      "is",
      "better",
      "to",
      "be",
      "feared",
      "than",
      "loved",
      "if",
      "you",
      "cannot",
      "both",
    ],
    id: "It is better to be feared than loved, if you cannot be both.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["nation's", "culture", "resides", "hearts", "soul", "its"],
    words: [
      "a",
      "nation's",
      "culture",
      "resides",
      "in",
      "the",
      "hearts",
      "and",
      "soul",
      "of",
      "its",
      "people",
    ],
    id: "A nation's culture resides in the hearts and in the soul of its people.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "weather", "sufficient", "recreate", "world", "ourselves"],
    words: [
      "a",
      "change",
      "in",
      "the",
      "weather",
      "is",
      "sufficient",
      "to",
      "recreate",
      "world",
      "and",
      "ourselves",
    ],
    id: "A change in the weather is sufficient to recreate the world and ourselves.",
    author: "Marcel Proust",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["pity", "beyond", "all", "telling", "hid", "heart"],
    words: [
      "a",
      "pity",
      "beyond",
      "all",
      "telling",
      "is",
      "hid",
      "in",
      "the",
      "heart",
      "of",
      "love",
    ],
    id: "A pity beyond all telling is hid in the heart of love.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "said", "almond", "friend", "god", "blossomed"],
    words: [
      "i",
      "said",
      "to",
      "the",
      "almond",
      "tree",
      "friend",
      "speak",
      "me",
      "of",
      "god",
      "and",
      "blossomed",
    ],
    id: "I said to the almond tree, 'Friend, speak to me of God,' and the almond tree blossomed.",
    author: "Nikos Kazantzakis",
    grammar: null,
    word_count: 13,
    all_intersection_count: 7,
    new_words_count: 6,
  },
  {
    row_new_words: ["wholly", "devoted", "intellectual", "exercise", "succeeded", "life"],
    words: [
      "to",
      "be",
      "wholly",
      "devoted",
      "some",
      "intellectual",
      "exercise",
      "is",
      "have",
      "succeeded",
      "in",
      "life",
    ],
    id: "To be wholly devoted to some intellectual exercise is to have succeeded in life.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["thing", "being", "hero", "main", "when", "die"],
    words: [
      "this",
      "thing",
      "of",
      "being",
      "a",
      "hero",
      "about",
      "the",
      "main",
      "to",
      "it",
      "is",
      "know",
      "when",
      "die",
    ],
    id: "This thing of being a hero, about the main thing to it is to know when to die.",
    author: "Will Rogers",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["healthy", "attitude", "contagious", "from", "others", "carrier"],
    words: [
      "a",
      "healthy",
      "attitude",
      "is",
      "contagious",
      "but",
      "don't",
      "wait",
      "to",
      "catch",
      "it",
      "from",
      "others",
      "be",
      "carrier",
    ],
    id: "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier.",
    author: "Tom Stoppard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "happiness", "things", "believe", "different", "from"],
    words: [
      "there",
      "can",
      "be",
      "no",
      "happiness",
      "if",
      "the",
      "things",
      "we",
      "believe",
      "in",
      "are",
      "different",
      "from",
      "do",
    ],
    id: "There can be no happiness if the things we believe in are different from the things we do.",
    author: "Freya Stark",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["affairs", "world", "men", "saved", "by", "faith"],
    words: [
      "in",
      "the",
      "affairs",
      "of",
      "this",
      "world",
      "men",
      "are",
      "saved",
      "not",
      "by",
      "faith",
      "but",
      "want",
      "it",
    ],
    id: "In the affairs of this world, men are saved not by faith, but by the want of it.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["reason", "on", "because", "live", "impoverished", "present"],
    words: [
      "the",
      "reason",
      "we",
      "want",
      "to",
      "go",
      "on",
      "and",
      "is",
      "because",
      "live",
      "in",
      "an",
      "impoverished",
      "present",
    ],
    id: "The reason we want to go on and on is because we live in an impoverished present.",
    author: "Alan Watts",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["big", "man", "time", "really", "anything", "sit"],
    words: [
      "a",
      "big",
      "man",
      "has",
      "no",
      "time",
      "really",
      "to",
      "do",
      "anything",
      "but",
      "just",
      "sit",
      "and",
      "be",
    ],
    id: "A big man has no time really to do anything but just sit and be big.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["generosity", "giving", "which", "i", "more", "than"],
    words: [
      "generosity",
      "is",
      "not",
      "giving",
      "me",
      "that",
      "which",
      "i",
      "need",
      "more",
      "than",
      "you",
      "do",
      "but",
      "it",
    ],
    id: "Generosity is not giving me that which I need more than you do, but it is giving me that which you need more than I do.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["fast", "process", "successful", "at", "highest", "level"],
    words: [
      "it's",
      "a",
      "fast",
      "learning",
      "process",
      "if",
      "you",
      "want",
      "to",
      "be",
      "successful",
      "at",
      "the",
      "highest",
      "level",
    ],
    id: "It's a fast learning process if you want to be successful at the highest level.",
    author: "Christian Pulisic",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "feel", "whatever", "your", "path", "heart"],
    words: [
      "i",
      "feel",
      "like",
      "this",
      "whatever",
      "is",
      "in",
      "your",
      "path",
      "and",
      "heart",
      "you",
      "need",
      "to",
      "do",
    ],
    id: "I feel like this: Whatever is in your path and in your heart, you need to do.",
    author: "J. Cole",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "faith", "will", "important", "part", "being"],
    words: [
      "do",
      "i",
      "think",
      "faith",
      "will",
      "be",
      "an",
      "important",
      "part",
      "of",
      "being",
      "a",
      "good",
      "president",
      "yes",
    ],
    id: "Do I think faith will be an important part of being a good president? Yes, I do.",
    author: "George W. Bush",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["conflict", "between", "evil", "real", "truth", "lies"],
    words: [
      "people",
      "like",
      "to",
      "say",
      "that",
      "the",
      "conflict",
      "is",
      "between",
      "good",
      "and",
      "evil",
      "real",
      "truth",
      "lies",
    ],
    id: "People like to say that the conflict is between good and evil. The real conflict is between truth and lies.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["fine", "only", "make", "wag", "his", "tail"],
    words: [
      "money",
      "can",
      "buy",
      "you",
      "a",
      "fine",
      "dog",
      "but",
      "only",
      "love",
      "make",
      "him",
      "wag",
      "his",
      "tail",
    ],
    id: "Money can buy you a fine dog, but only love can make him wag his tail.",
    author: "Kinky Friedman",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["millionaire", "start", "billion", "dollars", "launch", "airline"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "be",
      "a",
      "millionaire",
      "start",
      "with",
      "billion",
      "dollars",
      "and",
      "launch",
      "new",
      "airline",
    ],
    id: "If you want to be a Millionaire, start with a billion dollars and launch a new airline.",
    author: "Richard Branson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["at", "end", "i", "had", "fun", "was"],
    words: [
      "at",
      "the",
      "end",
      "of",
      "day",
      "if",
      "i",
      "can",
      "say",
      "had",
      "fun",
      "it",
      "was",
      "a",
      "good",
    ],
    id: "At the end of the day, if I can say I had fun, it was a good day.",
    author: "Simone Biles",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "spend", "club", "hold", "player", "stay"],
    words: [
      "i",
      "don't",
      "want",
      "to",
      "spend",
      "money",
      "the",
      "club",
      "doesn't",
      "have",
      "hold",
      "a",
      "player",
      "that",
      "stay",
    ],
    id: "I don't want to spend money the club doesn't have; I don't want to hold a player that doesn't want to stay.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["simple", "-", "america", "as", "its", "promise"],
    words: [
      "what",
      "the",
      "people",
      "want",
      "is",
      "very",
      "simple",
      "-",
      "they",
      "an",
      "america",
      "as",
      "good",
      "its",
      "promise",
    ],
    id: "What the people want is very simple - they want an America as good as its promise.",
    author: "Barbara Jordan",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "strength", "true", "show", "himself", "as"],
    words: [
      "where",
      "is",
      "the",
      "man",
      "who",
      "has",
      "strength",
      "to",
      "be",
      "true",
      "and",
      "show",
      "himself",
      "as",
      "he",
    ],
    id: "Where is the man who has the strength to be true, and to show himself as he is?",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "playing", "purple", "gold", "finish", "up"],
    words: [
      "i",
      "like",
      "playing",
      "for",
      "the",
      "purple",
      "and",
      "gold",
      "this",
      "is",
      "where",
      "want",
      "to",
      "finish",
      "up",
    ],
    id: "I like playing for the purple and gold. This is where I want to finish up.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["really", "how", "able", "enjoy", "life", "general"],
    words: [
      "it's",
      "really",
      "not",
      "about",
      "what",
      "you",
      "have",
      "how",
      "you're",
      "able",
      "to",
      "enjoy",
      "life",
      "in",
      "general",
    ],
    id: "It's really not about what you have. It's about how you're able to enjoy life in general.",
    author: "Kevin Harvick",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["negative", "impression", "york", "i", "quite", "fair"],
    words: [
      "people",
      "have",
      "a",
      "negative",
      "impression",
      "of",
      "new",
      "york",
      "that",
      "i",
      "don't",
      "think",
      "is",
      "quite",
      "fair",
    ],
    id: "People have a negative impression of New York that I don't think is quite fair.",
    author: "Billy Graham",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["understand", "poorly", "even", "my", "complaint", "understanding"],
    words: [
      "people",
      "understand",
      "me",
      "so",
      "poorly",
      "that",
      "they",
      "don't",
      "even",
      "my",
      "complaint",
      "about",
      "them",
      "not",
      "understanding",
    ],
    id: "People understand me so poorly that they don't even understand my complaint about them not understanding me.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["root", "possibility", "doing", "-", "all", "creation"],
    words: [
      "the",
      "root",
      "of",
      "this",
      "possibility",
      "doing",
      "good",
      "-",
      "that",
      "we",
      "all",
      "have",
      "is",
      "in",
      "creation",
    ],
    id: "The root of this possibility of doing good - that we all have - is in creation.",
    author: "Pope Francis",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["failure", "man", "blundered", "able", "cash", "experience"],
    words: [
      "a",
      "failure",
      "is",
      "man",
      "who",
      "has",
      "blundered",
      "but",
      "not",
      "able",
      "to",
      "cash",
      "in",
      "the",
      "experience",
    ],
    id: "A failure is a man who has blundered, but is not able to cash in the experience.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["understand", "name", "must", "acquainted", "particular", "which"],
    words: [
      "to",
      "understand",
      "a",
      "name",
      "you",
      "must",
      "be",
      "acquainted",
      "with",
      "the",
      "particular",
      "of",
      "which",
      "it",
      "is",
    ],
    id: "To understand a name you must be acquainted with the particular of which it is a name.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "politicians", "politics", "definitely", "associated", "any"],
    words: [
      "i",
      "don't",
      "like",
      "politicians",
      "and",
      "politics",
      "definitely",
      "want",
      "to",
      "be",
      "associated",
      "with",
      "any",
      "of",
      "them",
    ],
    id: "I don't like politicians, and I don't like politics. I definitely don't want to be associated with any of them.",
    author: "Steven Wright",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["enough", "great", "qualities", "should", "also", "management"],
    words: [
      "it",
      "is",
      "not",
      "enough",
      "to",
      "have",
      "great",
      "qualities",
      "we",
      "should",
      "also",
      "the",
      "management",
      "of",
      "them",
    ],
    id: "It is not enough to have great qualities; We should also have the management of them.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["will", "pretty", "won't", "wag", "his", "tail"],
    words: [
      "money",
      "will",
      "buy",
      "a",
      "pretty",
      "good",
      "dog",
      "but",
      "it",
      "won't",
      "the",
      "wag",
      "of",
      "his",
      "tail",
    ],
    id: "Money will buy a pretty good dog, but it won't buy the wag of his tail.",
    author: "Josh Billings",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "our", "vices", "leave", "imagine", "leaving"],
    words: [
      "when",
      "our",
      "vices",
      "leave",
      "us",
      "we",
      "like",
      "to",
      "imagine",
      "it",
      "is",
      "who",
      "are",
      "leaving",
      "them",
    ],
    id: "When our vices leave us, we like to imagine it is we who are leaving them.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["greatest", "way", "live", "honor", "world", "pretend"],
    words: [
      "the",
      "greatest",
      "way",
      "to",
      "live",
      "with",
      "honor",
      "in",
      "this",
      "world",
      "is",
      "be",
      "what",
      "we",
      "pretend",
    ],
    id: "The greatest way to live with honor in this world is to be what we pretend to be.",
    author: "Socrates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["lie", "yourself", "surely", "themselves", "will", "also"],
    words: [
      "you",
      "know",
      "that",
      "if",
      "lie",
      "to",
      "yourself",
      "surely",
      "other",
      "people",
      "themselves",
      "and",
      "they",
      "will",
      "also",
    ],
    id: "You know that if you lie to yourself, surely other people lie to themselves. And if they lie to themselves, they will lie to you also.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "their", "behavior", "unless", "makes", "difference"],
    words: [
      "people",
      "don't",
      "change",
      "their",
      "behavior",
      "unless",
      "it",
      "makes",
      "a",
      "difference",
      "for",
      "them",
      "to",
      "do",
      "so",
    ],
    id: "People don't change their behavior unless it makes a difference for them to do so.",
    author: "Sharon Stone",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["enemy", "yourself", "fear", "results", "hundred", "battles"],
    words: [
      "if",
      "you",
      "know",
      "the",
      "enemy",
      "and",
      "yourself",
      "need",
      "not",
      "fear",
      "results",
      "of",
      "a",
      "hundred",
      "battles",
    ],
    id: "If you know the enemy and know yourself you need not fear the results of a hundred battles.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "get", "hit", "record", "horror", "movies"],
    words: [
      "i",
      "want",
      "to",
      "get",
      "a",
      "hit",
      "record",
      "and",
      "know",
      "can",
      "be",
      "good",
      "in",
      "horror",
      "movies",
    ],
    id: "I want to get a hit record, and I know I can be good in horror movies.",
    author: "Tiny Tim",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "bound", "by", "my", "wounds", "able"],
    words: [
      "freedom",
      "is",
      "not",
      "to",
      "be",
      "bound",
      "by",
      "my",
      "wounds",
      "and",
      "able",
      "eat",
      "cake",
      "every",
      "day",
    ],
    id: "Freedom is... not to be bound by my wounds. And to be able to eat cake every day.",
    author: "Amanda de Cadenet",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "humans", "constantly", "changing", "evolving", "i"],
    words: [
      "as",
      "humans",
      "we",
      "are",
      "constantly",
      "learning",
      "and",
      "changing",
      "evolving",
      "i",
      "think",
      "that",
      "is",
      "so",
      "beautiful",
    ],
    id: "As humans, we are constantly learning and changing and evolving, and I think that is so beautiful.",
    author: "Mija",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["business", "meeting", "getting", "knowledgeable", "our", "craft"],
    words: [
      "we're",
      "learning",
      "the",
      "business",
      "meeting",
      "people",
      "we",
      "need",
      "to",
      "know",
      "getting",
      "knowledgeable",
      "about",
      "our",
      "craft",
    ],
    id: "We're learning the business, meeting people we need to know, getting knowledgeable about our craft.",
    author: "Lauren Jauregui",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["willing", "war", "yourself", "create", "whole", "identity"],
    words: [
      "you",
      "have",
      "to",
      "be",
      "willing",
      "go",
      "war",
      "with",
      "yourself",
      "and",
      "create",
      "a",
      "whole",
      "new",
      "identity",
    ],
    id: "You have to be willing to go to war with yourself and create a whole new identity.",
    author: "David Goggins",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["stimulus", "spending", "-", "whole", "point", "seriously"],
    words: [
      "what",
      "do",
      "you",
      "think",
      "a",
      "stimulus",
      "is",
      "it's",
      "spending",
      "-",
      "that's",
      "the",
      "whole",
      "point",
      "seriously",
    ],
    id: "What do you think a stimulus is? It's spending - that's the whole point! Seriously.",
    author: "Barack Obama",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["surround", "yourself", "positivity", "challenge", "make", "better"],
    words: [
      "surround",
      "yourself",
      "with",
      "good",
      "people",
      "positivity",
      "and",
      "who",
      "are",
      "going",
      "to",
      "challenge",
      "you",
      "make",
      "better",
    ],
    id: "Surround yourself with good people; surround yourself with positivity and people who are going to challenge you to make you better.",
    author: "Ali Krieger",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "was", "born", "enormous", "affection", "terrible"],
    words: [
      "i",
      "was",
      "born",
      "with",
      "an",
      "enormous",
      "need",
      "for",
      "affection",
      "and",
      "a",
      "terrible",
      "to",
      "give",
      "it",
    ],
    id: "I was born with an enormous need for affection, and a terrible need to give it.",
    author: "Audrey Hepburn",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["intersection", "law", "politics", "technology", "force", "lot"],
    words: [
      "the",
      "intersection",
      "of",
      "law",
      "politics",
      "and",
      "technology",
      "is",
      "going",
      "to",
      "force",
      "a",
      "lot",
      "good",
      "thinking",
    ],
    id: "The intersection of law, politics, and technology is going to force a lot of good thinking.",
    author: "Bill Gates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "number", "languages", "none", "more", "than"],
    words: [
      "i",
      "speak",
      "a",
      "number",
      "of",
      "languages",
      "but",
      "none",
      "are",
      "more",
      "beautiful",
      "to",
      "me",
      "than",
      "english",
    ],
    id: "I speak a number of languages, but none are more beautiful to me than English.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "still", "best", "thing", "life", "kid"],
    words: [
      "i",
      "still",
      "learn",
      "but",
      "think",
      "it's",
      "the",
      "best",
      "thing",
      "in",
      "life",
      "to",
      "have",
      "a",
      "kid",
    ],
    id: "I still learn, but I think it's the best thing in life to have a kid.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["scottish", "weather", "30", "minutes", "likely", "change"],
    words: [
      "if",
      "you",
      "don't",
      "like",
      "scottish",
      "weather",
      "wait",
      "30",
      "minutes",
      "and",
      "it",
      "is",
      "likely",
      "to",
      "change",
    ],
    id: "If you don't like Scottish weather, wait 30 minutes, and it is likely to change.",
    author: "Raymond Bonner",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["world", "turns", "aside", "any", "man", "pass"],
    words: [
      "the",
      "world",
      "turns",
      "aside",
      "to",
      "let",
      "any",
      "man",
      "pass",
      "who",
      "knows",
      "where",
      "he",
      "is",
      "going",
    ],
    id: "The world turns aside to let any man pass who knows where he is going.",
    author: "Epictetus",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["you've", "never", "been", "hurt", "nobody's", "watching"],
    words: [
      "work",
      "like",
      "you",
      "don't",
      "need",
      "the",
      "money",
      "love",
      "you've",
      "never",
      "been",
      "hurt",
      "dance",
      "nobody's",
      "watching",
    ],
    id: "Work like you don't need the money. Love like you've never been hurt. Dance like nobody's watching.",
    author: "Satchel Paige",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["choose", "job", "will", "never", "your", "life"],
    words: [
      "choose",
      "a",
      "job",
      "you",
      "love",
      "and",
      "will",
      "never",
      "have",
      "to",
      "work",
      "day",
      "in",
      "your",
      "life",
    ],
    id: "Choose a job you love, and you will never have to work a day in your life.",
    author: "Unknown",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["foreigners", "invest", "any", "more", "france", "-"],
    words: [
      "foreigners",
      "don't",
      "want",
      "to",
      "invest",
      "any",
      "more",
      "in",
      "france",
      "-",
      "and",
      "this",
      "is",
      "not",
      "working",
    ],
    id: "Foreigners don't want to invest any more in France - and this is not working.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["even", "close", "finishing", "basic", "dream", "pc"],
    words: [
      "we",
      "are",
      "not",
      "even",
      "close",
      "to",
      "finishing",
      "the",
      "basic",
      "dream",
      "of",
      "what",
      "pc",
      "can",
      "be",
    ],
    id: "We are not even close to finishing the basic dream of what the PC can be.",
    author: "Bill Gates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["comedy", "funny", "small", "rooms", "young", "audiences"],
    words: [
      "comedy",
      "is",
      "learning",
      "to",
      "be",
      "funny",
      "and",
      "you",
      "learn",
      "in",
      "small",
      "rooms",
      "with",
      "young",
      "audiences",
    ],
    id: "Comedy is learning to be funny, and you learn to be funny in small rooms with young audiences.",
    author: "Joan Rivers",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["nature", "wise", "resist", "pleasures", "foolish", "slave"],
    words: [
      "it",
      "is",
      "the",
      "nature",
      "of",
      "wise",
      "to",
      "resist",
      "pleasures",
      "but",
      "foolish",
      "be",
      "a",
      "slave",
      "them",
    ],
    id: "It is the nature of the wise to resist pleasures, but the foolish to be a slave to them.",
    author: "Epictetus",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["sexy", "feel", "your", "skin", "matter", "size"],
    words: [
      "you",
      "can",
      "be",
      "sexy",
      "and",
      "feel",
      "good",
      "in",
      "your",
      "skin",
      "no",
      "matter",
      "what",
      "size",
      "are",
    ],
    id: "You can be sexy and feel good in your skin, no matter what size you are.",
    author: "Ashley Graham",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "mama", "great", "role", "having", "chance"],
    words: [
      "i",
      "know",
      "mama",
      "rose",
      "is",
      "a",
      "great",
      "role",
      "and",
      "love",
      "having",
      "chance",
      "to",
      "do",
      "it",
    ],
    id: "I know Mama Rose is a great role and I love having a chance to do it.",
    author: "Bernadette Peters",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "best", "longer", "enough", "seemingly", "impossible"],
    words: [
      "to",
      "do",
      "your",
      "best",
      "is",
      "no",
      "longer",
      "good",
      "enough",
      "we",
      "now",
      "have",
      "the",
      "seemingly",
      "impossible",
    ],
    id: "To do your best is no longer good enough. We now have to do the seemingly impossible.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["generally", "howling", "wilderness", "howl", "imagination", "traveler"],
    words: [
      "generally",
      "speaking",
      "a",
      "howling",
      "wilderness",
      "does",
      "not",
      "howl",
      "it",
      "is",
      "the",
      "imagination",
      "of",
      "traveler",
      "that",
    ],
    id: "Generally speaking, a howling wilderness does not howl: it is the imagination of the traveler that does the howling.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["bad", "man", "sort", "weeps", "time", "woman"],
    words: [
      "a",
      "bad",
      "man",
      "is",
      "the",
      "sort",
      "who",
      "weeps",
      "every",
      "time",
      "he",
      "speaks",
      "of",
      "good",
      "woman",
    ],
    id: "A bad man is the sort who weeps every time he speaks of a good woman.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "take", "movies", "seriously", "anyone", "headache"],
    words: [
      "i",
      "don't",
      "take",
      "the",
      "movies",
      "seriously",
      "and",
      "anyone",
      "who",
      "does",
      "is",
      "in",
      "for",
      "a",
      "headache",
    ],
    id: "I don't take the movies seriously, and anyone who does is in for a headache.",
    author: "Bette Davis",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["become", "capable", "becoming", "only", "end", "life"],
    words: [
      "to",
      "be",
      "what",
      "we",
      "are",
      "and",
      "become",
      "capable",
      "of",
      "becoming",
      "is",
      "the",
      "only",
      "end",
      "life",
    ],
    id: "To be what we are, and to become what we are capable of becoming, is the only end of life.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["important", "as", "family", "sit", "woods", "sometimes"],
    words: [
      "it's",
      "important",
      "for",
      "us",
      "as",
      "a",
      "family",
      "to",
      "go",
      "and",
      "sit",
      "in",
      "the",
      "woods",
      "sometimes",
    ],
    id: "It's important for us as a family to go and sit in the woods sometimes.",
    author: "Stella McCartney",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["much", "our", "friends", "as", "confidence", "their"],
    words: [
      "we",
      "do",
      "not",
      "so",
      "much",
      "need",
      "the",
      "help",
      "of",
      "our",
      "friends",
      "as",
      "confidence",
      "their",
      "in",
    ],
    id: "We do not so much need the help of our friends as the confidence of their help in need.",
    author: "Epicurus",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "brings", "difficulties", "true", "side", "energy"],
    words: [
      "love",
      "always",
      "brings",
      "difficulties",
      "that",
      "is",
      "true",
      "but",
      "the",
      "good",
      "side",
      "of",
      "it",
      "gives",
      "energy",
    ],
    id: "Love always brings difficulties, that is true, but the good side of it is that it gives energy.",
    author: "Vincent Van Gogh",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "consists", "doing", "having", "right", "ought"],
    words: [
      "freedom",
      "consists",
      "not",
      "in",
      "doing",
      "what",
      "we",
      "like",
      "but",
      "having",
      "the",
      "right",
      "to",
      "do",
      "ought",
    ],
    id: "Freedom consists not in doing what we like, but in having the right to do what we ought.",
    author: "Pope John Paul II",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "worth", "nothing", "i", "can't", "share"],
    words: [
      "the",
      "success",
      "is",
      "worth",
      "nothing",
      "to",
      "me",
      "if",
      "i",
      "can't",
      "share",
      "it",
      "with",
      "people",
      "love",
    ],
    id: "The success is worth nothing to me if I can't share it with the people I love.",
    author: "Michael Buble",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["duty", "shepherd", "shear", "his", "sheep", "skin"],
    words: [
      "it",
      "is",
      "the",
      "duty",
      "of",
      "a",
      "good",
      "shepherd",
      "to",
      "shear",
      "his",
      "sheep",
      "not",
      "skin",
      "them",
    ],
    id: "It is the duty of a good shepherd to shear his sheep, not to skin them.",
    author: "Tiberius",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["longer", "posses", "freedom", "everything", "merely", "illusion"],
    words: [
      "for",
      "you",
      "who",
      "no",
      "longer",
      "posses",
      "it",
      "freedom",
      "is",
      "everything",
      "us",
      "do",
      "merely",
      "an",
      "illusion",
    ],
    id: "For you who no longer posses it, freedom is everything, for us who do, it is merely an illusion.",
    author: "Emil Cioran",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["talk", "something", "make", "up", "kind", "language"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "talk",
      "about",
      "something",
      "new",
      "have",
      "make",
      "up",
      "a",
      "kind",
      "of",
      "language",
    ],
    id: "If you want to talk about something new, you have to make up a new kind of language.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "adopt", "lot", "days", "one", "bad"],
    words: [
      "when",
      "you",
      "adopt",
      "a",
      "dog",
      "have",
      "lot",
      "of",
      "very",
      "good",
      "days",
      "and",
      "one",
      "bad",
      "day",
    ],
    id: "When you adopt a dog, you have a lot of very good days and one very bad day.",
    author: "W. Bruce Cameron",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["ask", "act", "action", "will", "delineate", "define"],
    words: [
      "do",
      "you",
      "want",
      "to",
      "know",
      "who",
      "are",
      "don't",
      "ask",
      "act",
      "action",
      "will",
      "delineate",
      "and",
      "define",
    ],
    id: "Do you want to know who you are? Don't ask. Act! Action will delineate and define you.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "talking", "women", "because", "constant", "always"],
    words: [
      "i",
      "love",
      "talking",
      "about",
      "women",
      "because",
      "they",
      "are",
      "a",
      "constant",
      "study",
      "and",
      "you're",
      "always",
      "learning",
    ],
    id: "I love talking about women because they are a constant study and you're always learning.",
    author: "Mario Lopez",
    grammar: null,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["writers", "lampposts", "critics", "ask", "hurt", "lamppost"],
    words: [
      "writers",
      "are",
      "lampposts",
      "and",
      "critics",
      "dogs",
      "ask",
      "what",
      "they",
      "think",
      "about",
      "does",
      "the",
      "dog",
      "hurt",
      "lamppost",
    ],
    id: "Writers are lampposts and critics are dogs. Ask lampposts what they think about dogs. Does the dog hurt the lamppost?",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["even", "knowledge", "fashion", "wise", "affect", "ignorance"],
    words: [
      "even",
      "knowledge",
      "has",
      "to",
      "be",
      "in",
      "the",
      "fashion",
      "and",
      "where",
      "it",
      "is",
      "not",
      "wise",
      "affect",
      "ignorance",
    ],
    id: "Even knowledge has to be in the fashion, and where it is not, it is wise to affect ignorance.",
    author: "Baltasar Gracian",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["his", "own", "language", "worse", "than", "smelly"],
    words: [
      "he",
      "who",
      "does",
      "not",
      "love",
      "his",
      "own",
      "language",
      "is",
      "worse",
      "than",
      "an",
      "animal",
      "and",
      "smelly",
      "fish",
    ],
    id: "He who does not love his own language is worse than an animal and smelly fish.",
    author: "Jose Rizal",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "impact", "look", "up", "more", "than"],
    words: [
      "i",
      "have",
      "an",
      "impact",
      "and",
      "people",
      "look",
      "up",
      "to",
      "me",
      "now",
      "it's",
      "more",
      "than",
      "the",
      "money",
    ],
    id: "I have an impact and people look up to me now. It's more than the money.",
    author: "Lil Baby",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "looks", "at", "kind", "person", "judging"],
    words: [
      "when",
      "the",
      "dog",
      "looks",
      "at",
      "you",
      "is",
      "not",
      "thinking",
      "what",
      "kind",
      "of",
      "a",
      "person",
      "are",
      "judging",
    ],
    id: "When the dog looks at you, the dog is not thinking what kind of a person you are. The dog is not judging you.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["extraordinary", "when", "acquainted", "whole", "family", "forget"],
    words: [
      "it",
      "is",
      "extraordinary",
      "that",
      "when",
      "you",
      "are",
      "acquainted",
      "with",
      "a",
      "whole",
      "family",
      "can",
      "forget",
      "about",
      "them",
    ],
    id: "It is extraordinary that when you are acquainted with a whole family you can forget about them.",
    author: "Gertrude Stein",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "romans", "general", "should", "soldier", "necessary"],
    words: [
      "i",
      "think",
      "with",
      "the",
      "romans",
      "that",
      "general",
      "of",
      "today",
      "should",
      "be",
      "a",
      "soldier",
      "tomorrow",
      "if",
      "necessary",
    ],
    id: "I think with the Romans, that the general of today should be a soldier tomorrow if necessary.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "plenty", "time", "whatever", "stay", "present"],
    words: [
      "life",
      "gives",
      "you",
      "plenty",
      "of",
      "time",
      "to",
      "do",
      "whatever",
      "want",
      "if",
      "stay",
      "in",
      "the",
      "present",
      "moment",
    ],
    id: "Life gives you plenty of time to do whatever you want to do if you stay in the present moment.",
    author: "Deepak Chopra",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["friend", "easy", "your", "time", "always", "opportune"],
    words: [
      "to",
      "help",
      "a",
      "friend",
      "in",
      "need",
      "is",
      "easy",
      "but",
      "give",
      "him",
      "your",
      "time",
      "not",
      "always",
      "opportune",
    ],
    id: "To help a friend in need is easy, but to give him your time is not always opportune.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["realize", "privilege", "gift", "power", "blessing", "success"],
    words: [
      "let",
      "us",
      "realize",
      "that",
      "the",
      "privilege",
      "to",
      "work",
      "is",
      "a",
      "gift",
      "power",
      "blessing",
      "love",
      "of",
      "success",
    ],
    id: "Let us realize that: the privilege to work is a gift, the power to work is a blessing, the love of work is success!",
    author: "David O. McKay",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["tell", "how", "things", "surprise", "their", "results"],
    words: [
      "don't",
      "tell",
      "people",
      "how",
      "to",
      "do",
      "things",
      "them",
      "what",
      "and",
      "let",
      "surprise",
      "you",
      "with",
      "their",
      "results",
    ],
    id: "Don't tell people how to do things, tell them what to do and let them surprise you with their results.",
    author: "George S. Patton",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["dares", "fool", "first", "step", "direction", "wisdom"],
    words: [
      "he",
      "dares",
      "to",
      "be",
      "a",
      "fool",
      "and",
      "that",
      "is",
      "the",
      "first",
      "step",
      "in",
      "direction",
      "of",
      "wisdom",
    ],
    id: "He dares to be a fool, and that is the first step in the direction of wisdom.",
    author: "James Huneker",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["first", "problem", "all", "men", "women", "unlearn"],
    words: [
      "the",
      "first",
      "problem",
      "for",
      "all",
      "of",
      "us",
      "men",
      "and",
      "women",
      "is",
      "not",
      "to",
      "learn",
      "but",
      "unlearn",
    ],
    id: "The first problem for all of us, men and women, is not to learn, but to unlearn.",
    author: "Gloria Steinem",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["party", "as", "much", "experience", "sitting", "lecture"],
    words: [
      "going",
      "to",
      "a",
      "party",
      "for",
      "me",
      "is",
      "as",
      "much",
      "learning",
      "experience",
      "you",
      "know",
      "sitting",
      "in",
      "lecture",
    ],
    id: "Going to a party, for me, is as much a learning experience as, you know, sitting in a lecture.",
    author: "Natalie Portman",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "important", "thing", "mater", "how", "or"],
    words: [
      "learning",
      "is",
      "the",
      "most",
      "important",
      "thing",
      "no",
      "mater",
      "how",
      "you",
      "do",
      "it",
      "or",
      "where",
      "who",
      "with",
    ],
    id: "Learning is the most important thing, no mater how you do it, or where you do it, or who you do it with.",
    author: "Saoirse Ronan",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["tough", "lesson", "life", "one", "everybody", "wishes"],
    words: [
      "a",
      "tough",
      "lesson",
      "in",
      "life",
      "that",
      "one",
      "has",
      "to",
      "learn",
      "is",
      "not",
      "everybody",
      "wishes",
      "you",
      "well",
    ],
    id: "A tough lesson in life that one has to learn is that not everybody wishes you well.",
    author: "Dan Rather",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "thank", "god", "blessing", "tools", "blessed"],
    words: [
      "i",
      "thank",
      "god",
      "for",
      "blessing",
      "me",
      "with",
      "the",
      "tools",
      "that",
      "he",
      "blessed",
      "to",
      "be",
      "where",
      "am",
    ],
    id: "I thank God for blessing me with the tools that he blessed me with to be where I am.",
    author: "Terence Crawford",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "gave", "breath", "breathe", "mad", "nothin"],
    words: [
      "be",
      "happy",
      "god",
      "gave",
      "you",
      "a",
      "breath",
      "to",
      "breathe",
      "every",
      "day",
      "don't",
      "need",
      "mad",
      "about",
      "nothin",
    ],
    id: "Be happy. God gave you a breath to breathe every day. You don't need to be mad about nothin'.",
    author: "Lamar Jackson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["order", "succeed", "must", "fail", "next", "time"],
    words: [
      "in",
      "order",
      "to",
      "succeed",
      "you",
      "must",
      "fail",
      "so",
      "that",
      "know",
      "what",
      "not",
      "do",
      "the",
      "next",
      "time",
    ],
    id: "In order to succeed you must fail, so that you know what not to do the next time.",
    author: "Anthony J. D'Angelo",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["present", "only", "time", "there", "matter", "always"],
    words: [
      "the",
      "present",
      "moment",
      "if",
      "you",
      "think",
      "about",
      "it",
      "is",
      "only",
      "time",
      "there",
      "no",
      "matter",
      "what",
      "always",
      "now",
    ],
    id: "The present moment, if you think about it, is the only time there is. No matter what time it is, it is always now.",
    author: "Marianne Williamson",
    grammar: null,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "much", "man's", "religion", "whose", "better"],
    words: [
      "i",
      "care",
      "not",
      "much",
      "for",
      "a",
      "man's",
      "religion",
      "whose",
      "dog",
      "and",
      "cat",
      "are",
      "the",
      "better",
      "it",
    ],
    id: "I care not much for a man's religion whose dog and cat are not the better for it.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["virtue", "relative", "actions", "ages", "each", "all"],
    words: [
      "virtue",
      "is",
      "relative",
      "to",
      "the",
      "actions",
      "and",
      "ages",
      "of",
      "each",
      "us",
      "in",
      "all",
      "that",
      "we",
      "do",
    ],
    id: "Virtue is relative to the actions and ages of each of us in all that we do.",
    author: "Plato",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "sure", "saves", "here", "save", "all"],
    words: [
      "i",
      "know",
      "for",
      "sure",
      "that",
      "loves",
      "saves",
      "me",
      "and",
      "it",
      "is",
      "here",
      "to",
      "save",
      "us",
      "all",
    ],
    id: "I know for sure that loves saves me and that it is here to save us all.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["nonsense", "happiness", "exaggerate", "extent", "which", "more"],
    words: [
      "it's",
      "nonsense",
      "to",
      "say",
      "money",
      "doesn't",
      "buy",
      "happiness",
      "but",
      "people",
      "exaggerate",
      "the",
      "extent",
      "which",
      "more",
      "can",
    ],
    id: "It's nonsense to say money doesn't buy happiness, but people exaggerate the extent to which more money can buy more happiness.",
    author: "Daniel Kahneman",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["particular", "faith", "or", "religion", "survive", "without"],
    words: [
      "if",
      "you",
      "have",
      "a",
      "particular",
      "faith",
      "or",
      "religion",
      "that",
      "is",
      "good",
      "but",
      "can",
      "survive",
      "without",
      "it",
    ],
    id: "If you have a particular faith or religion, that is good. But you can survive without it.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["out", "port", "coming", "determines", "success", "voyage"],
    words: [
      "it",
      "is",
      "not",
      "the",
      "going",
      "out",
      "of",
      "port",
      "but",
      "coming",
      "in",
      "that",
      "determines",
      "success",
      "a",
      "voyage",
    ],
    id: "It is not the going out of port, but the coming in, that determines the success of a voyage.",
    author: "Henry Ward Beecher",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["mean", "on", "occasion", "take", "granted", "right"],
    words: [
      "say",
      "just",
      "what",
      "you",
      "mean",
      "to",
      "do",
      "on",
      "every",
      "occasion",
      "and",
      "take",
      "it",
      "for",
      "granted",
      "right",
    ],
    id: "Say just what you mean to do on every occasion, and take it for granted you mean to do right.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "long", "discipline", "success", "makes", "everything"],
    words: [
      "as",
      "long",
      "you",
      "have",
      "discipline",
      "can",
      "be",
      "a",
      "success",
      "is",
      "what",
      "makes",
      "do",
      "everything",
      "need",
      "to",
    ],
    id: "As long as you have discipline, you can be a success. Discipline is what makes you do everything you need to do.",
    author: "Anthony Joshua",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["much", "call", "management", "consists", "making", "difficult"],
    words: [
      "so",
      "much",
      "of",
      "what",
      "we",
      "call",
      "management",
      "consists",
      "in",
      "making",
      "it",
      "difficult",
      "for",
      "people",
      "to",
      "work",
    ],
    id: "So much of what we call management consists in making it difficult for people to work.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["opinion", "will", "everything", "may", "suspected", "doing"],
    words: [
      "he",
      "that",
      "is",
      "of",
      "the",
      "opinion",
      "money",
      "will",
      "do",
      "everything",
      "may",
      "well",
      "be",
      "suspected",
      "doing",
      "for",
    ],
    id: "He that is of the opinion money will do everything may well be suspected of doing everything for money.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "supposed", "way", "cope", "makes", "difference"],
    words: [
      "life",
      "is",
      "not",
      "what",
      "it's",
      "supposed",
      "to",
      "be",
      "it",
      "the",
      "way",
      "you",
      "cope",
      "with",
      "makes",
      "difference",
    ],
    id: "Life is not what it's supposed to be. It's what it is. The way you cope with it is what makes the difference.",
    author: "Virginia Satir",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["creation", "wealth", "wrong", "its", "own", "sake"],
    words: [
      "it",
      "is",
      "not",
      "the",
      "creation",
      "of",
      "wealth",
      "that",
      "wrong",
      "but",
      "love",
      "money",
      "for",
      "its",
      "own",
      "sake",
    ],
    id: "It is not the creation of wealth that is wrong, but the love of money for its own sake.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "live", "himself", "should", "always", "company"],
    words: [
      "a",
      "man",
      "has",
      "to",
      "live",
      "with",
      "himself",
      "and",
      "he",
      "should",
      "see",
      "it",
      "that",
      "always",
      "good",
      "company",
    ],
    id: "A man has to live with himself, and he should see to it that he always has good company.",
    author: "Charles Evans Hughes",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["those", "reward", "even", "tax", "collectors", "same"],
    words: [
      "for",
      "if",
      "you",
      "love",
      "those",
      "who",
      "what",
      "reward",
      "have",
      "do",
      "not",
      "even",
      "the",
      "tax",
      "collectors",
      "same",
    ],
    id: "For if you love those who love you, what reward have you? Do not even the tax collectors do the same?",
    author: "Jesus Christ",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["meddle", "affairs", "wizards", "subtle", "quick", "anger"],
    words: [
      "do",
      "not",
      "meddle",
      "in",
      "the",
      "affairs",
      "of",
      "wizards",
      "for",
      "they",
      "are",
      "subtle",
      "and",
      "quick",
      "to",
      "anger",
    ],
    id: "Do not meddle in the affairs of Wizards, for they are subtle and quick to anger.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["decide", "willing", "exchange", "establish", "your", "priorities"],
    words: [
      "decide",
      "what",
      "you",
      "want",
      "are",
      "willing",
      "to",
      "exchange",
      "for",
      "it",
      "establish",
      "your",
      "priorities",
      "and",
      "go",
      "work",
    ],
    id: "Decide what you want, decide what you are willing to exchange for it. Establish your priorities and go to work.",
    author: "H. L. Hunt",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["small", "things", "power", "disturb", "then", "exactly"],
    words: [
      "if",
      "small",
      "things",
      "have",
      "the",
      "power",
      "to",
      "disturb",
      "you",
      "then",
      "who",
      "think",
      "are",
      "is",
      "exactly",
      "that",
    ],
    id: "If small things have the power to disturb you, then who you think you are is exactly that: small.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["bar", "mitzvah", "candle-lighting", "ceremony", "birthday", "big"],
    words: [
      "in",
      "a",
      "bar",
      "mitzvah",
      "you",
      "do",
      "the",
      "candle-lighting",
      "ceremony",
      "with",
      "cake",
      "every",
      "birthday",
      "is",
      "big",
      "moment",
    ],
    id: "In a bar mitzvah, you do the candle-lighting ceremony with the cake. Every birthday, the cake is the big moment.",
    author: "Ron Ben-Israel",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "key", "happiness", "doing", "will", "successful"],
    words: [
      "success",
      "is",
      "not",
      "the",
      "key",
      "to",
      "happiness",
      "if",
      "you",
      "love",
      "what",
      "are",
      "doing",
      "will",
      "be",
      "successful",
    ],
    id: "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.",
    author: "Albert Schweitzer",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "wrong", "first", "criminals", "nerve", "knowledge"],
    words: [
      "when",
      "a",
      "doctor",
      "does",
      "go",
      "wrong",
      "he",
      "is",
      "the",
      "first",
      "of",
      "criminals",
      "has",
      "nerve",
      "and",
      "knowledge",
    ],
    id: "When a doctor does go wrong he is the first of criminals. He has nerve and he has knowledge.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["address", "accomplishments", "mastery", "palaces", "fortunes", "goes"],
    words: [
      "give",
      "a",
      "boy",
      "address",
      "and",
      "accomplishments",
      "you",
      "him",
      "the",
      "mastery",
      "of",
      "palaces",
      "fortunes",
      "where",
      "he",
      "goes",
    ],
    id: "Give a boy address and accomplishments and you give him the mastery of palaces and fortunes where he goes.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 10,
    new_words_count: 6,
  },
  {
    row_new_words: ["making", "decisions", "crucial", "skill", "at", "level"],
    words: ["making", "good", "decisions", "is", "a", "crucial", "skill", "at", "every", "level"],
    id: "Making good decisions is a crucial skill at every level.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "britain", "because", "boxing", "was", "born"],
    words: ["i", "like", "britain", "because", "that", "is", "where", "boxing", "was", "born"],
    id: "I like Britain because that is where boxing was born.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["what's", "right", "left", "everything", "else", "wrong"],
    words: ["what's", "right", "is", "left", "if", "you", "do", "everything", "else", "wrong"],
    id: "What's right is what's left if you do everything else wrong.",
    author: "Robin Williams",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "things", "nature", "there", "something", "marvelous"],
    words: ["in", "all", "things", "of", "nature", "there", "is", "something", "the", "marvelous"],
    id: "In all things of nature there is something of the marvelous.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "getting", "ready", "live", "never", "living"],
    words: ["we", "are", "always", "getting", "ready", "to", "live", "but", "never", "living"],
    id: "We are always getting ready to live but never living.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "one", "will", "take", "chances", "backlash"],
    words: [
      "i'm",
      "the",
      "one",
      "who",
      "will",
      "take",
      "chances",
      "not",
      "worry",
      "about",
      "backlash",
    ],
    id: "I'm the one who will take chances, not worry about the backlash.",
    author: "Magic Johnson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "cure", "worrying", "better", "than", "whiskey"],
    words: ["as", "a", "cure", "for", "worrying", "work", "is", "better", "than", "whiskey"],
    id: "As a cure for worrying, work is better than whiskey.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "lost", "my", "mental", "faculties", "perfectly"],
    words: ["i", "have", "lost", "my", "mental", "faculties", "but", "am", "perfectly", "well"],
    id: "I have lost my mental faculties but am perfectly well.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["short", "words", "best", "old", "when", "all"],
    words: ["short", "words", "are", "best", "and", "the", "old", "when", "of", "all"],
    id: "Short words are best and the old words when short are best of all.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["healthy", "citizens", "greatest", "asset", "any", "country"],
    words: [
      "healthy",
      "citizens",
      "are",
      "the",
      "greatest",
      "asset",
      "any",
      "country",
      "can",
      "have",
    ],
    id: "Healthy citizens are the greatest asset any country can have.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["record", "i", "worship", "devil", "hate", "religion"],
    words: ["for", "the", "record", "i", "don't", "worship", "devil", "just", "hate", "religion"],
    id: "For the record, I don't worship the devil. I just hate religion.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "alone", "fate", "all", "great", "souls"],
    words: ["to", "live", "alone", "is", "the", "fate", "of", "all", "great", "souls"],
    id: "To live alone is the fate of all great souls.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["force", "vital", "principle", "immediate", "parent", "despotism"],
    words: [
      "force",
      "is",
      "the",
      "vital",
      "principle",
      "and",
      "immediate",
      "parent",
      "of",
      "despotism",
    ],
    id: "Force is the vital principle and immediate parent of despotism.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "been", "pregnant", "many", "movies", "ridiculous"],
    words: ["i", "have", "been", "pregnant", "in", "so", "many", "movies", "it's", "ridiculous"],
    id: "I have been pregnant in so many movies it's ridiculous.",
    author: "Jennifer Aniston",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "suffers", "before", "necessary", "more", "than"],
    words: ["a", "man", "who", "suffers", "before", "it", "is", "necessary", "more", "than"],
    id: "A man who suffers before it is necessary, suffers more than is necessary.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["often", "forgive", "those", "bore", "cannot", "whom"],
    words: ["we", "often", "forgive", "those", "who", "bore", "us", "but", "cannot", "whom"],
    id: "We often forgive those who bore us, but we cannot forgive those whom we bore.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["promise", "proportion", "our", "hopes", "deliver", "fears"],
    words: ["we", "promise", "in", "proportion", "to", "our", "hopes", "and", "deliver", "fears"],
    id: "We promise in proportion to our hopes, and we deliver in proportion to our fears.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["hits", "from", "both", "sides", "plate", "amphibious"],
    words: ["he", "hits", "from", "both", "sides", "of", "the", "plate", "he's", "amphibious"],
    id: "He hits from both sides of the plate. He's amphibious.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "should", "strife", "vanquished", "or", "dead"],
    words: ["there", "should", "be", "no", "strife", "with", "the", "vanquished", "or", "dead"],
    id: "There should be no strife with the vanquished or the dead.",
    author: "Virgil",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["speeches", "measured", "by", "hour", "will", "die"],
    words: ["speeches", "that", "are", "measured", "by", "the", "hour", "will", "die", "with"],
    id: "Speeches that are measured by the hour will die with the hour.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "there", "take", "my", "soul", "i"],
    words: ["god", "if", "there", "is", "a", "take", "my", "soul", "i", "have"],
    id: "God, if there is a God, take my soul, if I have a soul.",
    author: "Ernest Renan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["excellent", "soul", "exempt", "from", "mixture", "madness"],
    words: ["no", "excellent", "soul", "is", "exempt", "from", "a", "mixture", "of", "madness"],
    id: "No excellent soul is exempt from a mixture of madness.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["interest", "paid", "by", "those", "borrow", "trouble"],
    words: ["worry", "is", "the", "interest", "paid", "by", "those", "who", "borrow", "trouble"],
    id: "Worry is the interest paid by those who borrow trouble.",
    author: "George Washington",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["will", "save", "their", "government", "itself", "allow"],
    words: [
      "the",
      "people",
      "will",
      "save",
      "their",
      "government",
      "if",
      "itself",
      "allow",
      "them",
    ],
    id: "The people will save their government, if the government itself will allow them.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["movie", "too", "long", "bad", "short", "enough"],
    words: ["no", "good", "movie", "is", "too", "long", "and", "bad", "short", "enough"],
    id: "No good movie is too long and no bad movie is short enough.",
    author: "Roger Ebert",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["any", "detour", "as", "opportunity", "experience", "things"],
    words: ["see", "any", "detour", "as", "an", "opportunity", "to", "experience", "new", "things"],
    id: "See any detour as an opportunity to experience new things.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "mom", "father's", "birthday", "on", "same"],
    words: ["my", "mom", "and", "father's", "birthday", "are", "on", "the", "same", "day"],
    id: "My mom and my father's birthday are on the same day.",
    author: "Victor Cruz",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["great", "folly", "wish", "wise", "all", "alone"],
    words: ["it", "is", "great", "folly", "to", "wish", "be", "wise", "all", "alone"],
    id: "It is great folly to wish to be wise all alone.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["once", "dialogue", "starting", "break", "down", "prejudice"],
    words: [
      "once",
      "you",
      "have",
      "dialogue",
      "starting",
      "know",
      "can",
      "break",
      "down",
      "prejudice",
    ],
    id: "Once you have dialogue starting, you know you can break down prejudice.",
    author: "Harvey Milk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["boisterous", "sea", "liberty", "never", "without", "wave"],
    words: ["the", "boisterous", "sea", "of", "liberty", "is", "never", "without", "a", "wave"],
    id: "The boisterous sea of liberty is never without a wave.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "i", "feel", "bad", "my", "religion"],
    words: ["when", "i", "do", "good", "feel", "bad", "and", "that's", "my", "religion"],
    id: "When I do good I feel good, when I do bad I feel bad, and that's my religion.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "those", "their", "minds", "hearts", "as"],
    words: ["not", "all", "those", "who", "know", "their", "minds", "hearts", "as", "well"],
    id: "Not all those who know their minds know their hearts as well.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "expensive", "suits", "look", "cheap", "on"],
    words: ["i", "buy", "expensive", "suits", "they", "just", "look", "cheap", "on", "me"],
    id: "I buy expensive suits. They just look cheap on me.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["important", "enough", "ask", "blessed", "receive", "back"],
    words: ["you", "are", "important", "enough", "to", "ask", "and", "blessed", "receive", "back"],
    id: "You are important enough to ask and you are blessed enough to receive back.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "tasted", "freedom", "will", "up", "which"],
    words: ["i", "have", "tasted", "freedom", "will", "not", "give", "up", "that", "which"],
    id: "I have tasted freedom. I will not give up that which I have tasted.",
    author: "Harvey Milk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["best", "been", "done", "solitude", "worst", "turmoil"],
    words: ["the", "best", "thinking", "has", "been", "done", "in", "solitude", "worst", "turmoil"],
    id: "The best thinking has been done in solitude. The worst has been done in turmoil.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["more", "possibilities", "available", "each", "than", "realize"],
    words: [
      "we",
      "have",
      "more",
      "possibilities",
      "available",
      "in",
      "each",
      "moment",
      "than",
      "realize",
    ],
    id: "We have more possibilities available in each moment than we realize.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["much", "more", "secure", "feared", "than", "loved"],
    words: ["it", "is", "much", "more", "secure", "to", "be", "feared", "than", "loved"],
    id: "It is much more secure to be feared than to be loved.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["weak", "never", "forgive", "forgiveness", "attribute", "strong"],
    words: [
      "the",
      "weak",
      "can",
      "never",
      "forgive",
      "forgiveness",
      "is",
      "attribute",
      "of",
      "strong",
    ],
    id: "The weak can never forgive. Forgiveness is the attribute of the strong.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["pop", "culture", "reflection", "social", "change", "cause"],
    words: ["pop", "culture", "is", "a", "reflection", "of", "social", "change", "not", "cause"],
    id: "Pop culture is a reflection of social change, not a cause of social change.",
    author: "John Podhoretz",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "take", "my", "chances", "any", "composer"],
    words: ["i", "just", "have", "to", "take", "my", "chances", "like", "any", "other", "composer"],
    id: "I just have to take my chances like any other composer.",
    author: "Gordon Getty",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["chance", "never", "yet", "satisfied", "hope", "suffering"],
    words: [
      "chance",
      "has",
      "never",
      "yet",
      "satisfied",
      "the",
      "hope",
      "of",
      "a",
      "suffering",
      "people",
    ],
    id: "Chance has never yet satisfied the hope of a suffering people.",
    author: "Marcus Garvey",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "on", "whole", "different", "level", "rapping"],
    words: ["to", "me", "i'm", "on", "a", "whole", "different", "level", "of", "rapping"],
    id: "To me, I'm on a whole different level of rapping.",
    author: "Lil Baby",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["often", "wears", "mask", "order", "test", "loyalty"],
    words: ["love", "often", "wears", "a", "mask", "in", "order", "to", "test", "loyalty"],
    id: "Love often wears a mask in order to test loyalty.",
    author: "Minna Antrim",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["spring", "becomes", "hidden", "soon", "filled", "up"],
    words: ["the", "spring", "of", "love", "becomes", "hidden", "and", "soon", "filled", "up"],
    id: "The spring of love becomes hidden and soon filled up.",
    author: "Max Muller",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "desire", "more", "natural", "than", "knowledge"],
    words: ["there", "is", "no", "desire", "more", "natural", "than", "the", "for", "knowledge"],
    id: "There is no desire more natural than the desire for knowledge.",
    author: "Michel de Montaigne",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["born", "believing", "man", "bears", "beliefs", "as"],
    words: [
      "we",
      "are",
      "born",
      "believing",
      "a",
      "man",
      "bears",
      "beliefs",
      "as",
      "tree",
      "apples",
    ],
    id: "We are born believing. A man bears beliefs as a tree bears apples.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["wisdom", "wise", "uncommon", "degree", "common", "sense"],
    words: ["the", "wisdom", "of", "wise", "is", "an", "uncommon", "degree", "common", "sense"],
    id: "The wisdom of the wise is an uncommon degree of common sense.",
    author: "William Inge",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["patience", "key", "success", "i", "rely", "on"],
    words: ["patience", "is", "a", "key", "to", "success", "i", "rely", "on", "that"],
    id: "Patience is a key to success. I rely on that.",
    author: "Cordarrelle Patterson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["sustainability", "longer", "doing", "less", "harm", "more"],
    words: [
      "sustainability",
      "is",
      "no",
      "longer",
      "about",
      "doing",
      "less",
      "harm",
      "it's",
      "more",
      "good",
    ],
    id: "Sustainability is no longer about doing less harm. It's about doing more good.",
    author: "Jochen Zeitz",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["single", "player", "matters", "change", "course", "game"],
    words: ["every", "single", "player", "matters", "can", "change", "the", "course", "of", "game"],
    id: "Every single player matters. Every single player can change the course of the game.",
    author: "Bill Belichick",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["best", "thing", "hold", "onto", "life", "each"],
    words: ["the", "best", "thing", "to", "hold", "onto", "in", "life", "is", "each", "other"],
    id: "The best thing to hold onto in life is each other.",
    author: "Audrey Hepburn",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "religion", "-", "i", "could", "die"],
    words: ["love", "is", "my", "religion", "-", "i", "could", "die", "for", "it"],
    id: "Love is my religion - I could die for it.",
    author: "John Keats",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "wingless", "two", "feet", "flat", "nails"],
    words: ["man", "is", "a", "wingless", "animal", "with", "two", "feet", "and", "flat", "nails"],
    id: "Man is a wingless animal with two feet and flat nails.",
    author: "Plato",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["football", "demands", "patience", "keep", "will", "rewarded"],
    words: [
      "football",
      "demands",
      "patience",
      "keep",
      "working",
      "and",
      "you",
      "will",
      "be",
      "rewarded",
    ],
    id: "Football demands patience. Keep working and you will be rewarded.",
    author: "Marcelo",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["matters", "heart", "nothing", "true", "except", "improbable"],
    words: ["in", "matters", "of", "the", "heart", "nothing", "is", "true", "except", "improbable"],
    id: "In matters of the heart, nothing is true except the improbable.",
    author: "Madame de Stael",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "problem", "age", "patience", "begins", "ebb"],
    words: ["one", "problem", "with", "age", "is", "that", "patience", "begins", "to", "ebb"],
    id: "One problem with age is that patience begins to ebb.",
    author: "Carl Hiaasen",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "too", "late", "up", "our", "prejudices"],
    words: ["it", "is", "never", "too", "late", "to", "give", "up", "our", "prejudices"],
    id: "It is never too late to give up our prejudices.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["cannot", "blossom", "without", "sunshine", "man", "live"],
    words: [
      "a",
      "flower",
      "cannot",
      "blossom",
      "without",
      "sunshine",
      "and",
      "man",
      "live",
      "love",
    ],
    id: "A flower cannot blossom without sunshine, and man cannot live without love.",
    author: "Max Muller",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["kiss", "rosy", "dot", "over", "i", "loving"],
    words: ["a", "kiss", "is", "rosy", "dot", "over", "the", "i", "of", "loving"],
    id: "A kiss is a rosy dot over the 'i' of loving.",
    author: "Cyrano de Bergerac",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "forgiveness", "sin", "redemption", "ignorance"],
    words: [
      "i",
      "believe",
      "in",
      "the",
      "forgiveness",
      "of",
      "sin",
      "and",
      "redemption",
      "ignorance",
    ],
    id: "I believe in the forgiveness of sin and the redemption of ignorance.",
    author: "Adlai Stevenson II",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["omnipotence", "knowing", "how", "everything", "done", "doing"],
    words: [
      "omnipotence",
      "is",
      "not",
      "knowing",
      "how",
      "everything",
      "done",
      "it's",
      "just",
      "doing",
      "it",
    ],
    id: "Omnipotence is not knowing how everything is done; it's just doing it.",
    author: "Alan Watts",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["sometimes", "patience", "most", "important", "club", "bag"],
    words: ["sometimes", "patience", "can", "be", "the", "most", "important", "club", "in", "bag"],
    id: "Sometimes patience can be the most important club in the bag.",
    author: "Paul Casey",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "human", "race", "enter", "solar", "system"],
    words: ["it's", "time", "for", "the", "human", "race", "to", "enter", "solar", "system"],
    id: "It's time for the human race to enter the solar system.",
    author: "Dan Quayle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["can't", "forward", "backwards", "at", "same", "time"],
    words: ["you", "can't", "go", "forward", "and", "backwards", "at", "the", "same", "time"],
    id: "You can't go forward and backwards at the same time.",
    author: "Steve Harvey",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "indebted", "my", "father", "living", "teacher"],
    words: ["i", "am", "indebted", "to", "my", "father", "for", "living", "but", "teacher", "well"],
    id: "I am indebted to my father for living, but to my teacher for living well.",
    author: "Alexander the Great",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["ability", "forgive", "one", "man's", "greatest", "achievements"],
    words: [
      "the",
      "ability",
      "to",
      "forgive",
      "is",
      "one",
      "of",
      "man's",
      "greatest",
      "achievements",
    ],
    id: "The ability to forgive is one of man's greatest achievements.",
    author: "Bryant H. McGill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["humility", "gateway", "into", "grace", "favor", "god"],
    words: ["humility", "is", "the", "gateway", "into", "grace", "and", "favor", "of", "god"],
    id: "Humility is the gateway into the grace and the favor of God.",
    author: "Harold Warner",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "time", "many", "words", "also", "sleep"],
    words: ["there", "is", "a", "time", "for", "many", "words", "and", "also", "sleep"],
    id: "There is a time for many words, and there is also a time for sleep.",
    author: "Homer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "spend", "lot", "time", "bird", "watching"],
    words: ["i", "spend", "a", "lot", "of", "time", "learning", "about", "bird", "watching"],
    id: "I spend a lot of time learning about bird watching.",
    author: "Nikki Giovanni",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "david", "haye", "his", "antics", "earn"],
    words: ["i", "don't", "need", "david", "haye", "and", "his", "antics", "to", "earn", "money"],
    id: "I don't need David Haye and his antics to earn money.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["clear", "intelligence", "any", "long-term", "survival", "value"],
    words: [
      "it",
      "is",
      "not",
      "clear",
      "that",
      "intelligence",
      "has",
      "any",
      "long-term",
      "survival",
      "value",
    ],
    id: "It is not clear that intelligence has any long-term survival value.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["education", "man", "never", "completed", "until", "dies"],
    words: [
      "the",
      "education",
      "of",
      "a",
      "man",
      "is",
      "never",
      "completed",
      "until",
      "he",
      "dies",
    ],
    id: "The education of a man is never completed until he dies.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["surprise", "greatest", "gift", "which", "life", "grant"],
    words: ["surprise", "is", "the", "greatest", "gift", "which", "life", "can", "grant", "us"],
    id: "Surprise is the greatest gift which life can grant us.",
    author: "Boris Pasternak",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "unchangeable", "inherent", "unalienable", "rights", "man"],
    words: [
      "nothing",
      "is",
      "unchangeable",
      "but",
      "the",
      "inherent",
      "and",
      "unalienable",
      "rights",
      "of",
      "man",
    ],
    id: "Nothing is unchangeable but the inherent and unalienable rights of man.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["didn't", "lose", "game", "ran", "out", "time"],
    words: ["we", "didn't", "lose", "the", "game", "just", "ran", "out", "of", "time"],
    id: "We didn't lose the game; we just ran out of time.",
    author: "Vince Lombardi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["error", "only", "truth", "shrinks", "from", "inquiry"],
    words: [
      "it",
      "is",
      "error",
      "only",
      "and",
      "not",
      "truth",
      "that",
      "shrinks",
      "from",
      "inquiry",
    ],
    id: "It is error only, and not truth, that shrinks from inquiry.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["poetic", "knowledge", "born", "great", "silence", "scientific"],
    words: [
      "poetic",
      "knowledge",
      "is",
      "born",
      "in",
      "the",
      "great",
      "silence",
      "of",
      "scientific",
    ],
    id: "Poetic knowledge is born in the great silence of scientific knowledge.",
    author: "Aime Cesaire",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "will", "pattern", "all", "patience", "nothing"],
    words: ["no", "i", "will", "be", "the", "pattern", "of", "all", "patience", "say", "nothing"],
    id: "No, I will be the pattern of all patience; I will say nothing.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["often", "easier", "ask", "forgiveness", "than", "permission"],
    words: ["it", "is", "often", "easier", "to", "ask", "for", "forgiveness", "than", "permission"],
    id: "It is often easier to ask for forgiveness than to ask for permission.",
    author: "Grace Hopper",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["die", "only", "once", "such", "long", "time"],
    words: ["we", "die", "only", "once", "and", "for", "such", "a", "long", "time"],
    id: "We die only once, and for such a long time.",
    author: "Moliere",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["take", "your", "life", "lord", "will", "death"],
    words: ["take", "care", "of", "your", "life", "and", "the", "lord", "will", "death"],
    id: "Take care of your life and the Lord will take care of your death.",
    author: "George Whitefield",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["opportunity", "i", "got", "took", "as", "experience"],
    words: ["every", "opportunity", "i", "got", "took", "it", "as", "a", "learning", "experience"],
    id: "Every opportunity I got, I took it as a learning experience.",
    author: "Satya Nadella",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "means", "opportunity", "never", "thought", "would"],
    words: [
      "freedom",
      "means",
      "the",
      "opportunity",
      "to",
      "be",
      "what",
      "we",
      "never",
      "thought",
      "would",
    ],
    id: "Freedom means the opportunity to be what we never thought we would be.",
    author: "Daniel J. Boorstin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["kind", "free", "though", "slave", "evil", "king"],
    words: ["he", "that", "is", "kind", "free", "though", "a", "slave", "evil", "be", "king"],
    id: "He that is kind is free, though he is a slave; he that is evil is a slave, though he be a king.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "greater", "harm", "than", "time", "wasted"],
    words: ["there", "is", "no", "greater", "harm", "than", "that", "of", "time", "wasted"],
    id: "There is no greater harm than that of time wasted.",
    author: "Michelangelo",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "most", "valuable", "thing", "man", "spend"],
    words: ["time", "is", "the", "most", "valuable", "thing", "a", "man", "can", "spend"],
    id: "Time is the most valuable thing a man can spend.",
    author: "Theophrastus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "offer", "excuse", "than", "bad", "one"],
    words: ["it", "is", "better", "to", "offer", "no", "excuse", "than", "a", "bad", "one"],
    id: "It is better to offer no excuse than a bad one.",
    author: "George Washington",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["honestly", "i", "make", "movies", "funnier", "better"],
    words: ["honestly", "i", "just", "like", "to", "make", "movies", "the", "funnier", "better"],
    id: "Honestly, I just like to make movies. The funnier the better.",
    author: "Allen Covert",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "lives", "times", "also", "as", "such"],
    words: ["if", "we", "live", "good", "lives", "the", "times", "are", "also", "as", "such"],
    id: "If we live good lives, the times are also good. As we are, such are the times.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["there's", "art", "find", "mind's", "construction", "face"],
    words: ["there's", "no", "art", "to", "find", "the", "mind's", "construction", "in", "face"],
    id: "There's no art to find the mind's construction in the face.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "anything", "dies", "when", "stomp", "on"],
    words: ["life", "is", "anything", "that", "dies", "when", "you", "stomp", "on", "it"],
    id: "Life is anything that dies when you stomp on it.",
    author: "Dave Barry",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["aim", "life", "only", "fortune", "worth", "finding"],
    words: ["an", "aim", "in", "life", "is", "the", "only", "fortune", "worth", "finding"],
    id: "An aim in life is the only fortune worth finding.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "could", "everywhere", "therefore", "made", "mothers"],
    words: ["god", "could", "not", "be", "everywhere", "and", "therefore", "he", "made", "mothers"],
    id: "God could not be everywhere, and therefore he made mothers.",
    author: "Rudyard Kipling",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["ignorance", "talk", "as", "will", "its", "value"],
    words: ["let", "ignorance", "talk", "as", "it", "will", "learning", "has", "its", "value"],
    id: "Let ignorance talk as it will, learning has its value.",
    author: "Jean de La Fontaine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["before", "even", "as", "behind", "god", "all"],
    words: ["before", "me", "even", "as", "behind", "god", "is", "and", "all", "well"],
    id: "Before me, even as behind, God is, and all is well.",
    author: "John Greenleaf Whittier",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["oh", "how", "i", "would", "poor", "church"],
    words: ["oh", "how", "i", "would", "like", "a", "poor", "church", "and", "for", "the"],
    id: "Oh, how I would like a poor Church, and for the poor.",
    author: "Pope Francis",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["softly", "carry", "big", "stick", "will", "far"],
    words: ["speak", "softly", "and", "carry", "a", "big", "stick", "you", "will", "go", "far"],
    id: "Speak softly and carry a big stick; you will go far.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["blessed", "those", "without", "remembering", "take", "forgetting"],
    words: [
      "blessed",
      "are",
      "those",
      "who",
      "give",
      "without",
      "remembering",
      "and",
      "take",
      "forgetting",
    ],
    id: "Blessed are those who give without remembering and take without forgetting.",
    author: "Elizabeth Bibesco",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "man", "never", "makes", "mistake", "anything"],
    words: [
      "the",
      "only",
      "man",
      "who",
      "never",
      "makes",
      "a",
      "mistake",
      "is",
      "does",
      "anything",
    ],
    id: "The only man who never makes a mistake is the man who never does anything.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["things", "done", "exempt", "themselves", "from", "fear"],
    words: [
      "things",
      "done",
      "well",
      "and",
      "with",
      "a",
      "care",
      "exempt",
      "themselves",
      "from",
      "fear",
    ],
    id: "Things done well and with a care, exempt themselves from fear.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "couldn't", "success", "went", "ahead", "without"],
    words: ["i", "couldn't", "wait", "for", "success", "so", "went", "ahead", "without", "it"],
    id: "I couldn't wait for success, so I went ahead without it.",
    author: "Jonathan Winters",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "pleasant", "death", "peaceful", "transition", "troublesome"],
    words: [
      "life",
      "is",
      "pleasant",
      "death",
      "peaceful",
      "it's",
      "the",
      "transition",
      "that's",
      "troublesome",
    ],
    id: "Life is pleasant. Death is peaceful. It's the transition that's troublesome.",
    author: "Isaac Asimov",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "something", "when", "can't", "get", "sleep"],
    words: ["life", "is", "something", "to", "do", "when", "you", "can't", "get", "sleep"],
    id: "Life is something to do when you can't get to sleep.",
    author: "Fran Lebowitz",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["were", "shoot", "mime", "would", "use", "silencer"],
    words: ["if", "you", "were", "going", "to", "shoot", "a", "mime", "would", "use", "silencer"],
    id: "If you were going to shoot a mime, would you use a silencer?",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["increase", "your", "success", "rate", "double", "failure"],
    words: ["if", "you", "want", "to", "increase", "your", "success", "rate", "double", "failure"],
    id: "If you want to increase your success rate, double your failure rate.",
    author: "Thomas J. Watson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["justice", "turns", "scale", "bringing", "through", "suffering"],
    words: [
      "justice",
      "turns",
      "the",
      "scale",
      "bringing",
      "to",
      "some",
      "learning",
      "through",
      "suffering",
    ],
    id: "Justice turns the scale, bringing to some learning through suffering.",
    author: "Aeschylus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["secret", "success", "own", "nothing", "control", "everything"],
    words: [
      "the",
      "secret",
      "to",
      "success",
      "is",
      "own",
      "nothing",
      "but",
      "control",
      "everything",
    ],
    id: "The secret to success is to own nothing, but control everything.",
    author: "Nelson Rockefeller",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["trust", "one", "life's", "most", "difficult", "tasks"],
    words: ["learning", "to", "trust", "is", "one", "of", "life's", "most", "difficult", "tasks"],
    id: "Learning to trust is one of life's most difficult tasks.",
    author: "Isaac Watts",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "word", "makes", "manager", "-", "decisiveness"],
    words: ["the", "one", "word", "that", "makes", "a", "good", "manager", "-", "decisiveness"],
    id: "The one word that makes a good manager - decisiveness.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["sequestered", "nooks", "all", "sweet", "serenity", "books"],
    words: [
      "the",
      "love",
      "of",
      "learning",
      "sequestered",
      "nooks",
      "and",
      "all",
      "sweet",
      "serenity",
      "books",
    ],
    id: "The love of learning, the sequestered nooks, And all the sweet serenity of books.",
    author: "Henry Wadsworth Longfellow",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["curiosity", "killed", "while", "i", "was", "suspect"],
    words: ["curiosity", "killed", "the", "cat", "but", "for", "a", "while", "i", "was", "suspect"],
    id: "Curiosity killed the cat, but for a while I was a suspect.",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "gift", "given", "trust", "-", "child"],
    words: ["life", "is", "a", "gift", "given", "in", "trust", "-", "like", "child"],
    id: "Life is a gift, given in trust - like a child.",
    author: "Anne Morrow Lindbergh",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["although", "world", "full", "suffering", "also", "overcoming"],
    words: [
      "although",
      "the",
      "world",
      "is",
      "full",
      "of",
      "suffering",
      "it",
      "also",
      "overcoming",
    ],
    id: "Although the world is full of suffering, it is also full of the overcoming of it.",
    author: "Helen Keller",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["judgements", "prevent", "from", "lies", "beyond", "appearances"],
    words: [
      "judgements",
      "prevent",
      "us",
      "from",
      "seeing",
      "the",
      "good",
      "that",
      "lies",
      "beyond",
      "appearances",
    ],
    id: "Judgements prevent us from seeing the good that lies beyond appearances.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "curious", "find", "lots", "interesting", "things"],
    words: [
      "when",
      "you're",
      "curious",
      "you",
      "find",
      "lots",
      "of",
      "interesting",
      "things",
      "to",
      "do",
    ],
    id: "When you're curious, you find lots of interesting things to do.",
    author: "Walt Disney",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "thin", "line", "between", "relationship", "friendship"],
    words: [
      "there",
      "is",
      "a",
      "very",
      "thin",
      "line",
      "between",
      "relationship",
      "and",
      "friendship",
    ],
    id: "There is a very thin line between relationship and friendship.",
    author: "Divya Agarwal",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["comedy", "cathartic", "way", "deal", "personal", "trauma"],
    words: [
      "comedy",
      "can",
      "be",
      "a",
      "cathartic",
      "way",
      "to",
      "deal",
      "with",
      "personal",
      "trauma",
    ],
    id: "Comedy can be a cathartic way to deal with personal trauma.",
    author: "Robin Williams",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "failure", "both", "part", "life", "permanent"],
    words: ["success", "and", "failure", "are", "both", "part", "of", "life", "not", "permanent"],
    id: "Success and failure are both part of life. Both are not permanent.",
    author: "Shah Rukh Khan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["elegant", "hotel", "room", "service", "unlisted", "number"],
    words: ["this", "is", "an", "elegant", "hotel", "room", "service", "has", "unlisted", "number"],
    id: "This is an elegant hotel! Room service has an unlisted number.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "man's", "willing", "eager", "god's", "join"],
    words: ["when", "a", "man's", "willing", "and", "eager", "the", "god's", "join", "in"],
    id: "When a man's willing and eager the god's join in.",
    author: "Aeschylus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["tact", "ability", "describe", "others", "as", "themselves"],
    words: [
      "tact",
      "is",
      "the",
      "ability",
      "to",
      "describe",
      "others",
      "as",
      "they",
      "see",
      "themselves",
    ],
    id: "Tact is the ability to describe others as they see themselves.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "our", "relatives", "thank", "choose", "friends"],
    words: ["god", "gives", "us", "our", "relatives", "thank", "we", "can", "choose", "friends"],
    id: "God gives us our relatives, thank God we can choose our friends.",
    author: "Ethel Watts Mumford",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["friendship", "loyalty", "shouldn't", "come", "way", "casting"],
    words: [
      "friendship",
      "and",
      "loyalty",
      "shouldn't",
      "come",
      "in",
      "the",
      "way",
      "of",
      "casting",
    ],
    id: "Friendship and loyalty shouldn't come in the way of casting.",
    author: "Sanjay Leela Bhansali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["compliment", "eloquent", "except", "as", "expression", "indifference"],
    words: [
      "no",
      "compliment",
      "can",
      "be",
      "eloquent",
      "except",
      "as",
      "an",
      "expression",
      "of",
      "indifference",
    ],
    id: "No compliment can be eloquent, except as an expression of indifference.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "men", "fear", "god", "themselves", "evil"],
    words: ["when", "men", "don't", "fear", "god", "they", "give", "themselves", "to", "evil"],
    id: "When men don't fear God, they give themselves to evil.",
    author: "Ray Comfort",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "nothing", "mortal", "fear", "galvanize", "friendship"],
    words: [
      "there",
      "is",
      "nothing",
      "like",
      "mortal",
      "fear",
      "to",
      "galvanize",
      "a",
      "friendship",
    ],
    id: "There is nothing like mortal fear to galvanize a friendship.",
    author: "Andrew Lincoln",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "comes", "down", "friendship", "treating", "right"],
    words: ["but", "it", "all", "comes", "down", "to", "friendship", "treating", "people", "right"],
    id: "But it all comes down to friendship, treating people right.",
    author: "Ernie Banks",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["difficult", "realization", "something", "than", "oneself", "real"],
    words: [
      "love",
      "is",
      "the",
      "difficult",
      "realization",
      "that",
      "something",
      "other",
      "than",
      "oneself",
      "real",
    ],
    id: "Love is the difficult realization that something other than oneself is real.",
    author: "Iris Murdoch",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["power", "choosing", "evil", "within", "reach", "all"],
    words: [
      "the",
      "power",
      "of",
      "choosing",
      "good",
      "and",
      "evil",
      "is",
      "within",
      "reach",
      "all",
    ],
    id: "The power of choosing good and evil is within the reach of all.",
    author: "Origen",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["value", "principle", "number", "things", "will", "explain"],
    words: [
      "the",
      "value",
      "of",
      "a",
      "principle",
      "is",
      "number",
      "things",
      "it",
      "will",
      "explain",
    ],
    id: "The value of a principle is the number of things it will explain.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["movies", "tend", "dislocated", "non-linear", "their", "process"],
    words: [
      "movies",
      "tend",
      "to",
      "be",
      "dislocated",
      "and",
      "non-linear",
      "in",
      "their",
      "process",
    ],
    id: "Movies tend to be dislocated and non-linear in their process.",
    author: "David MacKenzie",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["real", "life", "messy", "drama", "shaped", "version"],
    words: ["real", "life", "is", "messy", "and", "drama", "a", "shaped", "version", "of"],
    id: "Real life is messy, and drama is a shaped version of real life.",
    author: "Simon Beaufoy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["history", "will", "kind", "i", "intend", "write"],
    words: ["history", "will", "be", "kind", "to", "me", "for", "i", "intend", "write", "it"],
    id: "History will be kind to me for I intend to write it.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["win", "justice", "quickest", "by", "rendering", "party"],
    words: ["we", "win", "justice", "quickest", "by", "rendering", "to", "the", "other", "party"],
    id: "We win justice quickest by rendering justice to the other party.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "truest", "life", "when", "dreams", "awake"],
    words: ["our", "truest", "life", "is", "when", "we", "are", "in", "dreams", "awake"],
    id: "Our truest life is when we are in dreams awake.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["cross", "country", "skiing", "great", "live", "small"],
    words: ["cross", "country", "skiing", "is", "great", "if", "you", "live", "in", "a", "small"],
    id: "Cross country skiing is great if you live in a small country.",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "space", "one", "occupies", "day's", "insolence"],
    words: [
      "success",
      "is",
      "the",
      "space",
      "one",
      "occupies",
      "in",
      "newspaper",
      "day's",
      "insolence",
    ],
    id: "Success is the space one occupies in the newspaper. Success is one day's insolence.",
    author: "Elias Canetti",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "place", "success", "comes", "before", "dictionary"],
    words: ["the", "only", "place", "success", "comes", "before", "work", "is", "in", "dictionary"],
    id: "The only place success comes before work is in the dictionary.",
    author: "Vince Lombardi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["truth", "property", "individual", "treasure", "all", "men"],
    words: [
      "truth",
      "is",
      "the",
      "property",
      "of",
      "no",
      "individual",
      "but",
      "treasure",
      "all",
      "men",
    ],
    id: "Truth is the property of no individual but is the treasure of all men.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["baby", "god's", "opinion", "life", "should", "on"],
    words: ["a", "baby", "is", "god's", "opinion", "that", "life", "should", "go", "on"],
    id: "A baby is God's opinion that life should go on.",
    author: "Carl Sandburg",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["takes", "long", "time", "grow", "old", "friend"],
    words: ["it", "takes", "a", "long", "time", "to", "grow", "an", "old", "friend"],
    id: "It takes a long time to grow an old friend.",
    author: "John Leonard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["priests", "others", "poetry", "i", "my", "friends"],
    words: ["some", "people", "go", "to", "priests", "others", "poetry", "i", "my", "friends"],
    id: "Some people go to priests; others to poetry; I to my friends.",
    author: "Virginia Woolf",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "comes", "time", "when", "exercise", "patience"],
    words: ["there", "comes", "a", "time", "when", "you", "have", "to", "exercise", "patience"],
    id: "There comes a time when you have to exercise patience.",
    author: "Columbus Short",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "systems", "predictability", "order", "honors", "planning"],
    words: [
      "god",
      "is",
      "a",
      "of",
      "systems",
      "and",
      "predictability",
      "order",
      "honors",
      "planning",
    ],
    id: "God is a God of systems and predictability and order, and God honors planning.",
    author: "Andy Stanley",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "seem", "smell", "stench", "appeasement", "air"],
    words: ["i", "seem", "to", "smell", "the", "stench", "of", "appeasement", "in", "air"],
    id: "I seem to smell the stench of appeasement in the air.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["teachers", "how", "bring", "out", "best", "students"],
    words: [
      "good",
      "teachers",
      "know",
      "how",
      "to",
      "bring",
      "out",
      "the",
      "best",
      "in",
      "students",
    ],
    id: "Good teachers know how to bring out the best in students.",
    author: "Charles Kuralt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["soul", "everlasting", "its", "experience", "lifetime", "after"],
    words: [
      "the",
      "soul",
      "is",
      "everlasting",
      "and",
      "its",
      "learning",
      "experience",
      "lifetime",
      "after",
    ],
    id: "The soul is everlasting, and its learning experience is lifetime after lifetime.",
    author: "Shirley MacLaine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: [
      "monk's",
      "extraordinary",
      "patience",
      "hindrance",
      "desperate",
      "decision-making",
    ],
    words: [
      "a",
      "monk's",
      "extraordinary",
      "patience",
      "can",
      "be",
      "hindrance",
      "to",
      "desperate",
      "decision-making",
    ],
    id: "A monk's extraordinary patience can be a hindrance to desperate decision-making.",
    author: "Harsha Bhogle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["separates", "players", "from", "greats", "remember", "consistency"],
    words: [
      "what",
      "separates",
      "the",
      "good",
      "players",
      "from",
      "greats",
      "people",
      "remember",
      "is",
      "consistency",
    ],
    id: "What separates the good players from the greats people remember is consistency.",
    author: "Ryan Mason",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["hard", "fail", "worse", "never", "tried", "succeed"],
    words: ["it", "is", "hard", "to", "fail", "but", "worse", "never", "have", "tried", "succeed"],
    id: "It is hard to fail, but it is worse never to have tried to succeed.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["wisdom", "oft", "times", "consists", "knowing", "next"],
    words: ["wisdom", "oft", "times", "consists", "of", "knowing", "what", "to", "do", "next"],
    id: "Wisdom oft times consists of knowing what to do next.",
    author: "Herbert Hoover",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["separates", "old", "from", "young", "experience", "patience"],
    words: [
      "what",
      "separates",
      "old",
      "from",
      "the",
      "young",
      "is",
      "experience",
      "and",
      "patience",
    ],
    id: "What separates old from the young is experience and patience.",
    author: "Sudha Murty",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["forgiven", "because", "christ", "bore", "our", "sins"],
    words: ["we", "are", "not", "forgiven", "because", "good", "christ", "bore", "our", "sins"],
    id: "We are not forgiven because we are good. We are forgiven because Christ bore our sins.",
    author: "Joseph Prince",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["satisfaction", "lies", "effort", "attainment", "full", "victory"],
    words: [
      "satisfaction",
      "lies",
      "in",
      "the",
      "effort",
      "not",
      "attainment",
      "full",
      "is",
      "victory",
    ],
    id: "Satisfaction lies in the effort, not in the attainment, full effort is full victory.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["isn't", "always", "hanging", "on", "sometimes", "letting"],
    words: [
      "love",
      "isn't",
      "always",
      "about",
      "hanging",
      "on",
      "sometimes",
      "it's",
      "letting",
      "go",
    ],
    id: "Love isn't always about hanging on. Sometimes it's about letting go.",
    author: "Reham Khan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["convert", "somebody", "take", "by", "hand", "guide"],
    words: ["to", "convert", "somebody", "go", "and", "take", "them", "by", "the", "hand", "guide"],
    id: "To convert somebody go and take them by the hand and guide them.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["enlightened", "ruler", "heedful", "general", "full", "caution"],
    words: [
      "the",
      "enlightened",
      "ruler",
      "is",
      "heedful",
      "and",
      "good",
      "general",
      "full",
      "of",
      "caution",
    ],
    id: "The enlightened ruler is heedful, and the good general full of caution.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "isn't", "at", "somebody", "else's", "expense"],
    words: [
      "freedom",
      "isn't",
      "to",
      "do",
      "what",
      "you",
      "want",
      "at",
      "somebody",
      "else's",
      "expense",
    ],
    id: "Freedom isn't to do what you want at somebody else's expense.",
    author: "John Lydon",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["without", "freedom", "press", "there", "representative", "government"],
    words: [
      "without",
      "freedom",
      "of",
      "the",
      "press",
      "there",
      "can",
      "be",
      "no",
      "representative",
      "government",
    ],
    id: "Without freedom of the press, there can be no representative government.",
    author: "Charles Maurice de Talleyrand",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["each", "time", "as", "deeply", "were", "forever"],
    words: ["each", "time", "you", "love", "as", "deeply", "if", "it", "were", "forever"],
    id: "Each time you love, love as deeply as if it were forever.",
    author: "Audre Lorde",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["something", "said", "ask", "man", "done", "woman"],
    words: ["if", "you", "want", "something", "said", "ask", "a", "man", "done", "woman"],
    id: "If you want something said, ask a man; if you want something done, ask a woman.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "value", "without", "being", "object", "utility"],
    words: ["nothing", "can", "have", "value", "without", "being", "an", "object", "of", "utility"],
    id: "Nothing can have value without being an object of utility.",
    author: "Karl Marx",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["real", "business", "life", "trying", "understand", "each"],
    words: [
      "the",
      "real",
      "business",
      "of",
      "life",
      "is",
      "trying",
      "to",
      "understand",
      "each",
      "other",
    ],
    id: "The real business of life is trying to understand each other.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "experience", "time", "i'm", "on", "set"],
    words: ["it's", "always", "a", "learning", "experience", "every", "time", "i'm", "on", "set"],
    id: "It's always a learning experience every time I'm on set.",
    author: "Reece Thompson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["listened", "too", "long", "courtly", "muses", "europe"],
    words: [
      "we",
      "have",
      "listened",
      "too",
      "long",
      "to",
      "the",
      "courtly",
      "muses",
      "of",
      "europe",
    ],
    id: "We have listened too long to the courtly Muses of Europe.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["art", "living", "more", "wrestling", "than", "dancing"],
    words: ["the", "art", "of", "living", "is", "more", "like", "wrestling", "than", "dancing"],
    id: "The art of living is more like wrestling than dancing.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "journey", "when", "stop", "things", "right"],
    words: ["life", "is", "a", "journey", "when", "we", "stop", "things", "don't", "go", "right"],
    id: "Life is a journey. When we stop, things don't go right.",
    author: "Pope Francis",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "men's", "souls", "immortal", "righteous", "divine"],
    words: [
      "all",
      "men's",
      "souls",
      "are",
      "immortal",
      "but",
      "the",
      "of",
      "righteous",
      "and",
      "divine",
    ],
    id: "All men's souls are immortal, but the souls of the righteous are immortal and divine.",
    author: "Socrates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["may", "encounter", "many", "defeats", "must", "defeated"],
    words: ["we", "may", "encounter", "many", "defeats", "but", "must", "not", "be", "defeated"],
    id: "We may encounter many defeats but we must not be defeated.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "-", "negative", "empty", "exceedingly", "powerful"],
    words: [
      "but",
      "to",
      "me",
      "nothing",
      "-",
      "the",
      "negative",
      "empty",
      "is",
      "exceedingly",
      "powerful",
    ],
    id: "But to me nothing - the negative, the empty - is exceedingly powerful.",
    author: "Alan Watts",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["take", "away", "paradox", "from", "thinker", "professor"],
    words: [
      "take",
      "away",
      "paradox",
      "from",
      "the",
      "thinker",
      "and",
      "you",
      "have",
      "a",
      "professor",
    ],
    id: "Take away paradox from the thinker and you have a professor.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["difference", "between", "stupidity", "genius", "its", "limits"],
    words: [
      "the",
      "difference",
      "between",
      "stupidity",
      "and",
      "genius",
      "is",
      "that",
      "has",
      "its",
      "limits",
    ],
    id: "The difference between stupidity and genius is that genius has its limits.",
    author: "Unknown",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "desires", "live", "long", "would", "old"],
    words: ["every", "one", "desires", "to", "live", "long", "but", "no", "would", "be", "old"],
    id: "Every one desires to live long, but no one would be old.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["doing", "deeds", "man's", "most", "glorious", "task"],
    words: ["to", "be", "doing", "good", "deeds", "is", "man's", "most", "glorious", "task"],
    id: "To be doing good deeds is man's most glorious task.",
    author: "Sophocles",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["will", "find", "peace", "happiness", "live", "gospel"],
    words: ["you", "will", "find", "peace", "and", "happiness", "if", "live", "the", "gospel"],
    id: "You will find peace and happiness if you will live the gospel.",
    author: "Gordon B. Hinckley",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["must", "rekindle", "fire", "idealism", "our", "society"],
    words: ["we", "must", "rekindle", "the", "fire", "of", "idealism", "in", "our", "society"],
    id: "We must rekindle the fire of idealism in our society.",
    author: "Joe Biden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "always", "time", "enough", "will", "apply"],
    words: ["one", "always", "has", "time", "enough", "if", "will", "apply", "it", "well"],
    id: "One always has time enough, if one will apply it well.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["bashfulness", "ornament", "youth", "reproach", "old", "age"],
    words: [
      "bashfulness",
      "is",
      "an",
      "ornament",
      "to",
      "youth",
      "but",
      "a",
      "reproach",
      "old",
      "age",
    ],
    id: "Bashfulness is an ornament to youth, but a reproach to old age.",
    author: "Aristotle",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["lovers", "truly", "write", "down", "their", "happiness"],
    words: ["lovers", "who", "love", "truly", "do", "not", "write", "down", "their", "happiness"],
    id: "Lovers who love truly do not write down their happiness.",
    author: "Anatole France",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["ninety", "percent", "cases", "polio", "security-vulnerable", "areas"],
    words: [
      "ninety",
      "percent",
      "of",
      "the",
      "cases",
      "polio",
      "are",
      "in",
      "security-vulnerable",
      "areas",
    ],
    id: "Ninety percent of the cases of polio are in security-vulnerable areas.",
    author: "Bill Gates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "more", "terrible", "than", "ignorance", "action"],
    words: ["nothing", "is", "more", "terrible", "than", "to", "see", "ignorance", "in", "action"],
    id: "Nothing is more terrible than to see ignorance in action.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "much", "shorter", "than", "i", "imagined"],
    words: ["life", "is", "much", "shorter", "than", "i", "imagined", "it", "to", "be"],
    id: "Life is much shorter than I imagined it to be.",
    author: "Abraham Cahan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["movies", "scripts", "-", "films", "books", "theatre"],
    words: ["movies", "are", "not", "scripts", "-", "films", "they're", "books", "the", "theatre"],
    id: "Movies are not scripts - movies are films; they're not books, they're not the theatre.",
    author: "Nicolas Roeg",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["future", "already", "arrived", "evenly", "distributed", "yet"],
    words: [
      "the",
      "future",
      "has",
      "already",
      "arrived",
      "it's",
      "just",
      "not",
      "evenly",
      "distributed",
      "yet",
    ],
    id: "The future has already arrived. It's just not evenly distributed yet.",
    author: "William Gibson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["health", "education", "spirituality", "should", "never", "commercialised"],
    words: [
      "for",
      "me",
      "health",
      "education",
      "and",
      "spirituality",
      "should",
      "never",
      "be",
      "commercialised",
    ],
    id: "For me, health, education, and spirituality should never be commercialised.",
    author: "Jaggi Vasudev",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["deny", "their", "human", "rights", "challenge", "humanity"],
    words: [
      "to",
      "deny",
      "people",
      "their",
      "human",
      "rights",
      "is",
      "challenge",
      "very",
      "humanity",
    ],
    id: "To deny people their human rights is to challenge their very humanity.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["non-cooperation", "evil", "as", "much", "duty", "cooperation"],
    words: [
      "non-cooperation",
      "with",
      "evil",
      "is",
      "as",
      "much",
      "a",
      "duty",
      "cooperation",
      "good",
    ],
    id: "Non-cooperation with evil is as much a duty as is cooperation with good.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["at", "rest", "however", "middle", "everything", "sun"],
    words: ["at", "rest", "however", "in", "the", "middle", "of", "everything", "is", "sun"],
    id: "At rest, however, in the middle of everything is the sun.",
    author: "Nicolaus Copernicus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["friends", "show", "their", "times", "trouble", "happiness"],
    words: ["friends", "show", "their", "love", "in", "times", "of", "trouble", "not", "happiness"],
    id: "Friends show their love in times of trouble, not in happiness.",
    author: "Euripides",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["sometimes", "accept", "can't", "win", "all", "time"],
    words: ["sometimes", "you", "have", "to", "accept", "can't", "win", "all", "the", "time"],
    id: "Sometimes you have to accept you can't win all the time.",
    author: "Lionel Messi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'd", "rather", "my", "life", "than", "afraid"],
    words: ["i'd", "rather", "give", "my", "life", "than", "be", "afraid", "to", "it"],
    id: "I'd rather give my life than be afraid to give it.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "humble", "earnest", "seeker", "after", "truth"],
    words: ["i", "am", "a", "humble", "but", "very", "earnest", "seeker", "after", "truth"],
    id: "I am a humble but very earnest seeker after truth.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["wish", "healing", "always", "been", "half", "health"],
    words: ["the", "wish", "for", "healing", "has", "always", "been", "half", "of", "health"],
    id: "The wish for healing has always been half of health.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["amazing", "how", "complete", "delusion", "beauty", "goodness"],
    words: [
      "it",
      "is",
      "amazing",
      "how",
      "complete",
      "the",
      "delusion",
      "that",
      "beauty",
      "goodness",
    ],
    id: "It is amazing how complete is the delusion that beauty is goodness.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["action", "best", "secures", "greatest", "happiness", "number"],
    words: [
      "the",
      "action",
      "is",
      "best",
      "that",
      "secures",
      "greatest",
      "happiness",
      "for",
      "number",
    ],
    id: "The action is best that secures the greatest happiness for the greatest number.",
    author: "William Dean Howells",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["enemy", "conventional", "wisdom", "ideas", "march", "events"],
    words: [
      "the",
      "enemy",
      "of",
      "conventional",
      "wisdom",
      "is",
      "not",
      "ideas",
      "but",
      "march",
      "events",
    ],
    id: "The enemy of the conventional wisdom is not ideas but the march of events.",
    author: "John Kenneth Galbraith",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["course", "same", "old", "story", "truth", "usually"],
    words: ["of", "course", "it's", "the", "same", "old", "story", "truth", "usually", "is"],
    id: "Of course it's the same old story. Truth usually is the same old story.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["universe", "change", "our", "life", "thoughts", "make"],
    words: ["the", "universe", "is", "change", "our", "life", "what", "thoughts", "make", "it"],
    id: "The universe is change; our life is what our thoughts make it.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["secret", "ugliness", "consists", "irregularity", "being", "uninteresting"],
    words: [
      "the",
      "secret",
      "of",
      "ugliness",
      "consists",
      "not",
      "in",
      "irregularity",
      "but",
      "being",
      "uninteresting",
    ],
    id: "The secret of ugliness consists not in irregularity, but in being uninteresting.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "married", "theater", "my", "mistress", "films"],
    words: ["i'm", "married", "to", "the", "theater", "but", "my", "mistress", "is", "films"],
    id: "I'm married to the theater but my mistress is the films.",
    author: "Oskar Werner",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "darkest", "defeat", "victory", "may", "nearest"],
    words: ["in", "the", "time", "of", "darkest", "defeat", "victory", "may", "be", "nearest"],
    id: "In the time of darkest defeat, victory may be nearest.",
    author: "William McKinley",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "really", "miss", "being", "able", "blend"],
    words: ["i", "really", "miss", "being", "able", "to", "blend", "in", "with", "people"],
    id: "I really miss being able to blend in with people.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["single", "art", "form", "involved", "film", "way"],
    words: ["every", "single", "art", "form", "is", "involved", "in", "film", "a", "way"],
    id: "Every single art form is involved in film, in a way.",
    author: "Sydney Pollack",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "resides", "possessions", "gold", "dwells", "soul"],
    words: [
      "happiness",
      "resides",
      "not",
      "in",
      "possessions",
      "and",
      "gold",
      "dwells",
      "the",
      "soul",
    ],
    id: "Happiness resides not in possessions, and not in gold, happiness dwells in the soul.",
    author: "Democritus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["hubble", "isn't", "satellite", "humanity's", "quest", "knowledge"],
    words: [
      "hubble",
      "isn't",
      "just",
      "a",
      "satellite",
      "it's",
      "about",
      "humanity's",
      "quest",
      "for",
      "knowledge",
    ],
    id: "Hubble isn't just a satellite; it's about humanity's quest for knowledge.",
    author: "John M. Grunsfeld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["cinema", "should", "make", "forget", "sitting", "theater"],
    words: ["cinema", "should", "make", "you", "forget", "are", "sitting", "in", "a", "theater"],
    id: "Cinema should make you forget you are sitting in a theater.",
    author: "Roman Polanski",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "i", "slip", "into", "ocean", "home"],
    words: ["every", "time", "i", "slip", "into", "the", "ocean", "it's", "like", "going", "home"],
    id: "Every time I slip into the ocean, it's like going home.",
    author: "Sylvia Earle",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "mixed", "my", "great-grandfather", "was", "white"],
    words: ["all", "of", "us", "are", "so", "mixed", "my", "great-grandfather", "was", "white"],
    id: "All of us are so mixed. My great-grandfather was white.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "complain", "nothing", "make", "things", "better"],
    words: ["i", "like", "to", "complain", "and", "do", "nothing", "make", "things", "better"],
    id: "I like to complain and do nothing to make things better.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["gift", "from", "friend", "wish", "your", "happiness"],
    words: ["every", "gift", "from", "a", "friend", "is", "wish", "for", "your", "happiness"],
    id: "Every gift from a friend is a wish for your happiness.",
    author: "Richard Bach",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "religion", "islam", "allah", "peace"],
    words: ["i", "believe", "in", "the", "religion", "of", "islam", "allah", "and", "peace"],
    id: "I believe in the religion of Islam. I believe in Allah and peace.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["journey", "thousand", "miles", "begins", "one", "step"],
    words: ["the", "journey", "of", "a", "thousand", "miles", "begins", "with", "one", "step"],
    id: "The journey of a thousand miles begins with one step.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["first", "thing", "i", "when", "get", "up"],
    words: ["the", "first", "thing", "i", "do", "when", "get", "up", "have", "breakfast"],
    id: "The first thing I do when I get up, I have breakfast.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["search", "happiness", "one", "chief", "sources", "unhappiness"],
    words: [
      "the",
      "search",
      "for",
      "happiness",
      "is",
      "one",
      "of",
      "chief",
      "sources",
      "unhappiness",
    ],
    id: "The search for happiness is one of the chief sources of unhappiness.",
    author: "Eric Hoffer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["being", "service", "others", "brings", "true", "happiness"],
    words: ["being", "of", "service", "to", "others", "is", "what", "brings", "true", "happiness"],
    id: "Being of service to others is what brings true happiness.",
    author: "Marie Osmond",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["man's", "enemies", "demons", "human", "beings", "himself"],
    words: [
      "man's",
      "enemies",
      "are",
      "not",
      "demons",
      "but",
      "human",
      "beings",
      "like",
      "himself",
    ],
    id: "Man's enemies are not demons, but human beings like himself.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["forest", "restoration", "campaign", "war", "ameliorate", "nature"],
    words: [
      "the",
      "forest",
      "restoration",
      "campaign",
      "is",
      "a",
      "war",
      "to",
      "ameliorate",
      "nature",
    ],
    id: "The forest restoration campaign is a war to ameliorate nature.",
    author: "Kim Jong-un",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["greatest", "happiness", "number", "foundation", "morals", "legislation"],
    words: [
      "the",
      "greatest",
      "happiness",
      "of",
      "number",
      "is",
      "foundation",
      "morals",
      "and",
      "legislation",
    ],
    id: "The greatest happiness of the greatest number is the foundation of morals and legislation.",
    author: "Jeremy Bentham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "long", "persevere", "endure", "get", "anything"],
    words: ["as", "long", "we", "persevere", "and", "endure", "can", "get", "anything", "want"],
    id: "As long as we persevere and endure, we can get anything we want.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["prayer", "simply", "two-way", "conversation", "between", "god"],
    words: [
      "prayer",
      "is",
      "simply",
      "a",
      "two-way",
      "conversation",
      "between",
      "you",
      "and",
      "god",
    ],
    id: "Prayer is simply a two-way conversation between you and God.",
    author: "Billy Graham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "by-product", "cannot", "pursue", "by", "itself"],
    words: ["happiness", "is", "a", "by-product", "you", "cannot", "pursue", "it", "by", "itself"],
    id: "Happiness is a by-product. You cannot pursue it by itself.",
    author: "Sam Levenson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["jonathan", "demme", "sharp", "editor", "his", "movies"],
    words: ["jonathan", "demme", "is", "a", "very", "sharp", "editor", "of", "his", "movies"],
    id: "Jonathan Demme is a very sharp editor of his movies.",
    author: "Anthony Hopkins",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["must", "fast", "because", "race", "against", "time"],
    words: ["we", "must", "go", "fast", "because", "the", "race", "is", "against", "time"],
    id: "We must go fast, because the race is against time.",
    author: "Anna Held",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["wholesome", "oblivion", "one's", "neighbours", "beginning", "wisdom"],
    words: [
      "a",
      "wholesome",
      "oblivion",
      "of",
      "one's",
      "neighbours",
      "is",
      "the",
      "beginning",
      "wisdom",
    ],
    id: "A wholesome oblivion of one's neighbours is the beginning of wisdom.",
    author: "Richard Le Gallienne",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["silence", "reserve", "will", "anyone", "reputation", "wisdom"],
    words: [
      "silence",
      "and",
      "reserve",
      "will",
      "give",
      "anyone",
      "a",
      "reputation",
      "for",
      "wisdom",
    ],
    id: "Silence and reserve will give anyone a reputation for wisdom.",
    author: "Myrtle Reed",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "guiding", "principle", "guilt", "never", "doubted"],
    words: ["my", "guiding", "principle", "is", "this", "guilt", "never", "to", "be", "doubted"],
    id: "My guiding principle is this: Guilt is never to be doubted.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["wisdom", "acquired", "save", "as", "result", "investigation"],
    words: [
      "wisdom",
      "is",
      "not",
      "acquired",
      "save",
      "as",
      "the",
      "result",
      "of",
      "investigation",
    ],
    id: "Wisdom is not acquired save as the result of investigation.",
    author: "Sara Teasdale",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "thought", "makes", "crooked", "all", "straight"],
    words: ["god", "is", "a", "thought", "who", "makes", "crooked", "all", "that", "straight"],
    id: "God is a thought who makes crooked all that is straight.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "seek", "out", "seed", "triumph", "adversity"],
    words: ["always", "seek", "out", "the", "seed", "of", "triumph", "in", "every", "adversity"],
    id: "Always seek out the seed of triumph in every adversity.",
    author: "Og Mandino",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["extreme", "limit", "wisdom", "public", "calls", "madness"],
    words: [
      "the",
      "extreme",
      "limit",
      "of",
      "wisdom",
      "that's",
      "what",
      "public",
      "calls",
      "madness",
    ],
    id: "The extreme limit of wisdom, that's what the public calls madness.",
    author: "Jean Cocteau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["russia", "riddle", "wrapped", "mystery", "inside", "enigma"],
    words: ["russia", "is", "a", "riddle", "wrapped", "in", "mystery", "inside", "an", "enigma"],
    id: "Russia is a riddle wrapped in a mystery inside an enigma.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "something", "pardon", "everything", "also", "condemn"],
    words: [
      "if",
      "there",
      "is",
      "something",
      "to",
      "pardon",
      "in",
      "everything",
      "also",
      "condemn",
    ],
    id: "If there is something to pardon in everything, there is also something to condemn.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "human", "wisdom", "grief", "as", "reward"],
    words: ["all", "human", "wisdom", "works", "and", "has", "worries", "grief", "as", "reward"],
    id: "All human wisdom works and has worries and grief as reward.",
    author: "Johann Georg Hamann",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["practice", "tolerance", "one's", "enemy", "best", "teacher"],
    words: ["in", "the", "practice", "of", "tolerance", "one's", "enemy", "is", "best", "teacher"],
    id: "In the practice of tolerance, one's enemy is the best teacher.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "make", "movies", "as", "painters", "paint"],
    words: ["i", "make", "movies", "just", "as", "painters", "paint", "work", "where", "can"],
    id: "I make movies just as painters paint: I work where I can.",
    author: "Jean-Jacques Annaud",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["aim", "at", "nothing", "you'll", "hit", "time"],
    words: ["if", "you", "aim", "at", "nothing", "you'll", "hit", "it", "every", "time"],
    id: "If you aim at nothing, you'll hit it every time.",
    author: "Zig Ziglar",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["tis", "makes", "world", "round", "my", "baby"],
    words: ["tis", "love", "that", "makes", "the", "world", "go", "round", "my", "baby"],
    id: "'Tis love that makes the world go round, my baby.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "had", "all", "disadvantages", "required", "success"],
    words: ["i", "have", "had", "all", "of", "the", "disadvantages", "required", "for", "success"],
    id: "I have had all of the disadvantages required for success.",
    author: "Larry Ellison",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["road", "success", "failure", "almost", "exactly", "same"],
    words: ["the", "road", "to", "success", "and", "failure", "are", "almost", "exactly", "same"],
    id: "The road to success and the road to failure are almost exactly the same.",
    author: "Colin R. Davis",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["concentration", "one", "happiest", "things", "my", "life"],
    words: ["concentration", "is", "one", "of", "the", "happiest", "things", "in", "my", "life"],
    id: "Concentration is one of the happiest things in my life.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["stance", "posture", "reflect", "proper", "state", "mind"],
    words: ["a", "good", "stance", "and", "posture", "reflect", "proper", "state", "of", "mind"],
    id: "A good stance and posture reflect a proper state of mind.",
    author: "Morihei Ueshiba",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["untutored", "courage", "useless", "face", "educated", "bullets"],
    words: [
      "untutored",
      "courage",
      "is",
      "useless",
      "in",
      "the",
      "face",
      "of",
      "educated",
      "bullets",
    ],
    id: "Untutored courage is useless in the face of educated bullets.",
    author: "George S. Patton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "true", "success", "intrinsic", "kindness", "community"],
    words: [
      "i",
      "think",
      "true",
      "success",
      "is",
      "intrinsic",
      "it's",
      "love",
      "kindness",
      "community",
    ],
    id: "I think true success is intrinsic... It's love. It's kindness. It's community.",
    author: "Tom Shadyac",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["understand", "your", "silence", "will", "probably", "words"],
    words: [
      "he",
      "who",
      "does",
      "not",
      "understand",
      "your",
      "silence",
      "will",
      "probably",
      "words",
    ],
    id: "He who does not understand your silence will probably not understand your words.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["danger", "sensation", "exciting", "challenge", "find", "dangers"],
    words: [
      "the",
      "danger",
      "sensation",
      "is",
      "exciting",
      "challenge",
      "to",
      "find",
      "new",
      "dangers",
    ],
    id: "The danger sensation is exciting. The challenge is to find new dangers.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["fool", "must", "then", "right", "by", "chance"],
    words: ["a", "fool", "must", "now", "and", "then", "be", "right", "by", "chance"],
    id: "A fool must now and then be right, by chance.",
    author: "William Cowper",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["failure", "nature's", "plan", "prepare", "great", "responsibilities"],
    words: [
      "failure",
      "is",
      "nature's",
      "plan",
      "to",
      "prepare",
      "you",
      "for",
      "great",
      "responsibilities",
    ],
    id: "Failure is nature's plan to prepare you for great responsibilities.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["sometimes", "i", "being", "success", "mediocre", "world"],
    words: ["sometimes", "i", "worry", "about", "being", "a", "success", "in", "mediocre", "world"],
    id: "Sometimes I worry about being a success in a mediocre world.",
    author: "Lily Tomlin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "get", "haters", "actually", "feel", "success"],
    words: ["when", "you", "get", "haters", "actually", "feel", "like", "you're", "a", "success"],
    id: "When you get haters you actually feel like you're a success.",
    author: "Sir Mix-a-Lot",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "nothing", "or", "evil", "save", "will"],
    words: ["there", "is", "nothing", "good", "or", "evil", "save", "in", "the", "will"],
    id: "There is nothing good or evil save in the will.",
    author: "Epictetus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "never", "felt", "didn't", "chance", "win"],
    words: ["i", "never", "felt", "that", "didn't", "have", "a", "chance", "to", "win"],
    id: "I never felt that I didn't have a chance to win.",
    author: "Arnold Palmer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "extra", "attention", "from", "nobody", "i'm"],
    words: ["i", "don't", "need", "no", "extra", "attention", "from", "nobody", "i'm", "good"],
    id: "I don't need no extra attention from nobody. I'm good.",
    author: "Rod Wave",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["poetry", "way", "taking", "life", "by", "throat"],
    words: ["poetry", "is", "a", "way", "of", "taking", "life", "by", "the", "throat"],
    id: "Poetry is a way of taking life by the throat.",
    author: "Robert Frost",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "power", "controls", "access", "opportunity", "advancement"],
    words: [
      "today",
      "knowledge",
      "has",
      "power",
      "it",
      "controls",
      "access",
      "to",
      "opportunity",
      "and",
      "advancement",
    ],
    id: "Today knowledge has power. It controls access to opportunity and advancement.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "make", "solid", "kung", "fu", "movie"],
    words: ["i", "want", "to", "make", "a", "good", "solid", "kung", "fu", "movie"],
    id: "I want to make a good, solid kung fu movie.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["poet", "never", "takes", "notes", "take", "affair"],
    words: ["a", "poet", "never", "takes", "notes", "you", "take", "in", "love", "affair"],
    id: "A poet never takes notes. You never take notes in a love affair.",
    author: "Robert Frost",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["obedience", "lawful", "authority", "foundation", "manly", "character"],
    words: [
      "obedience",
      "to",
      "lawful",
      "authority",
      "is",
      "the",
      "foundation",
      "of",
      "manly",
      "character",
    ],
    id: "Obedience to lawful authority is the foundation of manly character.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "enough", "man", "whom", "too", "little"],
    words: ["nothing", "is", "enough", "for", "the", "man", "to", "whom", "too", "little"],
    id: "Nothing is enough for the man to whom enough is too little.",
    author: "Epicurus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "gemini", "i", "change", "my", "mind"],
    words: ["i'm", "a", "gemini", "so", "i", "change", "my", "mind", "every", "day"],
    id: "I'm a Gemini, so I change my mind every day.",
    author: "Natalie Portman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["strike", "brings", "closer", "next", "home", "run"],
    words: ["every", "strike", "brings", "me", "closer", "to", "the", "next", "home", "run"],
    id: "Every strike brings me closer to the next home run.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["above", "all", "heroine", "your", "life", "victim"],
    words: ["above", "all", "be", "the", "heroine", "of", "your", "life", "not", "victim"],
    id: "Above all, be the heroine of your life, not the victim.",
    author: "Nora Ephron",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["fight", "muhammad", "ali", "trophy", "best", "fighting"],
    words: ["the", "fight", "for", "muhammad", "ali", "trophy", "is", "about", "best", "fighting"],
    id: "The fight for the Muhammad Ali trophy is about the best fighting the best.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["delayed", "game", "eventually", "rushed", "forever", "bad"],
    words: ["a", "delayed", "game", "is", "eventually", "good", "but", "rushed", "forever", "bad"],
    id: "A delayed game is eventually good, but a rushed game is forever bad.",
    author: "Shigeru Miyamoto",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "excited", "having", "internet", "my", "den"],
    words: ["i'm", "very", "excited", "about", "having", "the", "internet", "in", "my", "den"],
    id: "I'm very excited about having the Internet in my den.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["two", "enemies", "human", "happiness", "pain", "boredom"],
    words: ["the", "two", "enemies", "of", "human", "happiness", "are", "pain", "and", "boredom"],
    id: "The two enemies of human happiness are pain and boredom.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["dead", "end", "street", "place", "turn", "around"],
    words: ["a", "dead", "end", "street", "is", "good", "place", "to", "turn", "around"],
    id: "A dead end street is a good place to turn around.",
    author: "Naomi Judd",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["hard", "times", "oppression", "develop", "psychic", "muscles"],
    words: [
      "people",
      "need",
      "hard",
      "times",
      "and",
      "oppression",
      "to",
      "develop",
      "psychic",
      "muscles",
    ],
    id: "People need hard times and oppression to develop psychic muscles.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "brings", "opportunities", "on", "hand", "confusing"],
    words: [
      "change",
      "brings",
      "opportunities",
      "on",
      "the",
      "other",
      "hand",
      "can",
      "be",
      "confusing",
    ],
    id: "Change brings opportunities. On the other hand, change can be confusing.",
    author: "Michael Porter",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["high", "moral", "character", "precondition", "great", "accomplishments"],
    words: [
      "high",
      "moral",
      "character",
      "is",
      "not",
      "a",
      "precondition",
      "for",
      "great",
      "accomplishments",
    ],
    id: "High moral character is not a precondition for great moral accomplishments.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["clear", "conscience", "usually", "sign", "bad", "memory"],
    words: ["a", "clear", "conscience", "is", "usually", "the", "sign", "of", "bad", "memory"],
    id: "A clear conscience is usually the sign of a bad memory.",
    author: "Steven Wright",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["despair", "narcotic", "lulls", "mind", "into", "indifference"],
    words: ["despair", "is", "a", "narcotic", "it", "lulls", "the", "mind", "into", "indifference"],
    id: "Despair is a narcotic. It lulls the mind into indifference.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["violence", "arms", "never", "resolve", "problems", "men"],
    words: ["violence", "and", "arms", "can", "never", "resolve", "the", "problems", "of", "men"],
    id: "Violence and arms can never resolve the problems of men.",
    author: "Pope John Paul II",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["arise", "awake", "stop", "until", "goal", "reached"],
    words: ["arise", "awake", "and", "stop", "not", "until", "the", "goal", "is", "reached"],
    id: "Arise! Awake! and stop not until the goal is reached.",
    author: "Swami Vivekananda",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "nothing", "i", "as", "much", "fight"],
    words: ["there", "is", "nothing", "i", "love", "as", "much", "a", "good", "fight"],
    id: "There is nothing I love as much as a good fight.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "must", "always", "remain", "something", "antagonistic"],
    words: [
      "there",
      "must",
      "always",
      "remain",
      "something",
      "that",
      "is",
      "antagonistic",
      "to",
      "good",
    ],
    id: "There must always remain something that is antagonistic to good.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["pity", "friendship", "two", "passions", "incompatible", "each"],
    words: [
      "pity",
      "and",
      "friendship",
      "are",
      "two",
      "passions",
      "incompatible",
      "with",
      "each",
      "other",
    ],
    id: "Pity and friendship are two passions incompatible with each other.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["risk", "wrong", "decision", "preferable", "terror", "indecision"],
    words: [
      "the",
      "risk",
      "of",
      "a",
      "wrong",
      "decision",
      "is",
      "preferable",
      "to",
      "terror",
      "indecision",
    ],
    id: "The risk of a wrong decision is preferable to the terror of indecision.",
    author: "Maimonides",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "tango", "used", "when", "was", "young"],
    words: ["i", "love", "tango", "and", "used", "to", "dance", "when", "was", "young"],
    id: "I love tango, and I used to dance when I was young.",
    author: "Pope Francis",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["canvas", "furnished", "by", "nature", "embroidered", "imagination"],
    words: [
      "love",
      "is",
      "a",
      "canvas",
      "furnished",
      "by",
      "nature",
      "and",
      "embroidered",
      "imagination",
    ],
    id: "Love is a canvas furnished by nature and embroidered by imagination.",
    author: "Voltaire",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "relax", "spend", "time", "my", "family"],
    words: ["i", "want", "to", "relax", "and", "spend", "time", "with", "my", "family"],
    id: "I want to relax and spend time with my family.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["paralympic", "games", "transforming", "our", "perception", "world"],
    words: [
      "the",
      "paralympic",
      "games",
      "is",
      "about",
      "transforming",
      "our",
      "perception",
      "of",
      "world",
    ],
    id: "The Paralympic Games is about transforming our perception of the world.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["bring", "bottled", "lightning", "clean", "tumbler", "corkscrew"],
    words: [
      "bring",
      "in",
      "the",
      "bottled",
      "lightning",
      "a",
      "clean",
      "tumbler",
      "and",
      "corkscrew",
    ],
    id: "Bring in the bottled lightning, a clean tumbler, and a corkscrew.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "easily", "fall", "lies", "through", "things"],
    words: ["i", "don't", "easily", "fall", "for", "lies", "can", "see", "through", "things"],
    id: "I don't easily fall for lies, I can see through things.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "currently", "interesting", "correspondence", "nun", "forgiveness"],
    words: [
      "i'm",
      "currently",
      "in",
      "an",
      "interesting",
      "correspondence",
      "with",
      "a",
      "nun",
      "about",
      "forgiveness",
    ],
    id: "I'm currently in an interesting correspondence with a nun about forgiveness.",
    author: "Julian Clary",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["few", "men", "great", "many", "coats", "breeches"],
    words: ["we", "know", "but", "a", "few", "men", "great", "many", "coats", "and", "breeches"],
    id: "We know but a few men, a great many coats and breeches.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["deepest", "principle", "human", "nature", "craving", "appreciated"],
    words: [
      "the",
      "deepest",
      "principle",
      "in",
      "human",
      "nature",
      "is",
      "craving",
      "to",
      "be",
      "appreciated",
    ],
    id: "The deepest principle in human nature is the craving to be appreciated.",
    author: "William James",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "white", "man's", "brother", "his", "brother-in-law"],
    words: [
      "i",
      "want",
      "to",
      "be",
      "the",
      "white",
      "man's",
      "brother",
      "not",
      "his",
      "brother-in-law",
    ],
    id: "I want to be the white man's brother, not his brother-in-law.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["way", "by", "faith", "shut", "eye", "reason"],
    words: ["the", "way", "to", "see", "by", "faith", "is", "shut", "eye", "of", "reason"],
    id: "The way to see by Faith is to shut the Eye of Reason.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "gets", "lot", "anxiety", "ok", "process"],
    words: [
      "your",
      "girl",
      "gets",
      "a",
      "lot",
      "of",
      "anxiety",
      "it's",
      "ok",
      "learning",
      "process",
    ],
    id: "Your girl gets a lot of anxiety. It's OK. It's a learning process.",
    author: "Marsai Martin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "ask", "judge", "by", "enemies", "made"],
    words: ["i", "ask", "you", "to", "judge", "me", "by", "the", "enemies", "have", "made"],
    id: "I ask you to judge me by the enemies I have made.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "blessed", "family", "supportive", "my", "choices"],
    words: [
      "i'm",
      "so",
      "blessed",
      "to",
      "have",
      "family",
      "that's",
      "supportive",
      "of",
      "my",
      "choices",
    ],
    id: "I'm so blessed to have family that's so supportive of my choices.",
    author: "Aquaria",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "proposal", "disregarded", "on", "score", "youth"],
    words: [
      "let",
      "not",
      "our",
      "proposal",
      "be",
      "disregarded",
      "on",
      "the",
      "score",
      "of",
      "youth",
    ],
    id: "Let not our proposal be disregarded on the score of our youth.",
    author: "Virgil",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["state", "without", "means", "change", "its", "conservation"],
    words: [
      "a",
      "state",
      "without",
      "the",
      "means",
      "of",
      "some",
      "change",
      "is",
      "its",
      "conservation",
    ],
    id: "A State without the means of some change is without the means of its conservation.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["hardest", "habit", "break", "most", "difficult", "satisfy"],
    words: [
      "love",
      "is",
      "the",
      "hardest",
      "habit",
      "to",
      "break",
      "and",
      "most",
      "difficult",
      "satisfy",
    ],
    id: "Love is the hardest habit to break, and the most difficult to satisfy.",
    author: "Drew Barrymore",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["hugs", "great", "amounts", "-", "especially", "children"],
    words: [
      "hugs",
      "can",
      "do",
      "great",
      "amounts",
      "of",
      "good",
      "-",
      "especially",
      "for",
      "children",
    ],
    id: "Hugs can do great amounts of good - especially for children.",
    author: "Princess Diana",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["demand", "loved", "greatest", "all", "arrogant", "presumptions"],
    words: [
      "the",
      "demand",
      "to",
      "be",
      "loved",
      "is",
      "greatest",
      "of",
      "all",
      "arrogant",
      "presumptions",
    ],
    id: "The demand to be loved is the greatest of all arrogant presumptions.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["those", "two", "fastidious", "couple", "fast", "hideous"],
    words: [
      "those",
      "two",
      "are",
      "a",
      "fastidious",
      "couple",
      "she's",
      "fast",
      "and",
      "he's",
      "hideous",
    ],
    id: "Those two are a fastidious couple. She's fast and he's hideous.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "advance", "life", "limits", "our", "abilities"],
    words: [
      "as",
      "we",
      "advance",
      "in",
      "life",
      "learn",
      "the",
      "limits",
      "of",
      "our",
      "abilities",
    ],
    id: "As we advance in life we learn the limits of our abilities.",
    author: "Henry Ford",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["look", "out", "there", "god", "something", "sky"],
    words: ["you", "don't", "look", "out", "there", "for", "god", "something", "in", "the", "sky"],
    id: "You don't look out there for God, something in the sky, you look in you.",
    author: "Alan Watts",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["vast", "thing", "far", "off", "great", "telescopes"],
    words: [
      "you",
      "are",
      "that",
      "vast",
      "thing",
      "see",
      "far",
      "off",
      "with",
      "great",
      "telescopes",
    ],
    id: "You are that vast thing that you see far, far off with great telescopes.",
    author: "Alan Watts",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["airplane", "stays", "up", "because", "time", "fall"],
    words: [
      "the",
      "airplane",
      "stays",
      "up",
      "because",
      "it",
      "doesn't",
      "have",
      "time",
      "to",
      "fall",
    ],
    id: "The airplane stays up because it doesn't have the time to fall.",
    author: "Orville Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["action", "great", "heart", "chief", "qualification", "head"],
    words: [
      "in",
      "action",
      "a",
      "great",
      "heart",
      "is",
      "the",
      "chief",
      "qualification",
      "work",
      "head",
    ],
    id: "In action a great heart is the chief qualification. In work, a great head.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["anger", "never", "without", "reason", "seldom", "one"],
    words: [
      "anger",
      "is",
      "never",
      "without",
      "a",
      "reason",
      "but",
      "seldom",
      "with",
      "good",
      "one",
    ],
    id: "Anger is never without a reason, but seldom with a good one.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["actors", "search", "rejection", "get", "reject", "themselves"],
    words: [
      "actors",
      "search",
      "for",
      "rejection",
      "if",
      "they",
      "don't",
      "get",
      "it",
      "reject",
      "themselves",
    ],
    id: "Actors search for rejection. If they don't get it they reject themselves.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "up", "their", "liberties", "under", "delusion"],
    words: [
      "the",
      "people",
      "never",
      "give",
      "up",
      "their",
      "liberties",
      "but",
      "under",
      "some",
      "delusion",
    ],
    id: "The people never give up their liberties but under some delusion.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["citizens", "tumortown", "forever", "assailed", "cures", "rumors"],
    words: [
      "the",
      "citizens",
      "of",
      "tumortown",
      "are",
      "forever",
      "assailed",
      "with",
      "cures",
      "and",
      "rumors",
    ],
    id: "The citizens of Tumortown are forever assailed with cures and rumors of cures.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "faults", "may", "forgiven", "perfect", "candor"],
    words: [
      "all",
      "faults",
      "may",
      "be",
      "forgiven",
      "of",
      "him",
      "who",
      "has",
      "perfect",
      "candor",
    ],
    id: "All faults may be forgiven of him who has perfect candor.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["scab", "traitor", "his", "god", "mother", "class"],
    words: ["the", "scab", "is", "a", "traitor", "to", "his", "god", "mother", "and", "class"],
    id: "The scab is a traitor to his God, his mother, and his class.",
    author: "Jack London",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "defy", "devil", "consider", "enemy", "mankind"],
    words: [
      "what",
      "man",
      "defy",
      "the",
      "devil",
      "consider",
      "he's",
      "an",
      "enemy",
      "to",
      "mankind",
    ],
    id: "What, man, defy the devil. Consider, he's an enemy to mankind.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["how", "little", "done", "under", "spirit", "fear"],
    words: ["how", "very", "little", "can", "be", "done", "under", "the", "spirit", "of", "fear"],
    id: "How very little can be done under the spirit of fear.",
    author: "Florence Nightingale",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["biggest", "challenge", "i", "any", "artist", "patience"],
    words: [
      "the",
      "biggest",
      "challenge",
      "i",
      "think",
      "for",
      "any",
      "new",
      "artist",
      "is",
      "patience",
    ],
    id: "The biggest challenge, I think for any new artist, is patience.",
    author: "Brett Eldredge",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["momentum", "amazing", "thing", "when", "your", "favour"],
    words: [
      "momentum",
      "is",
      "an",
      "amazing",
      "thing",
      "when",
      "it",
      "working",
      "in",
      "your",
      "favour",
    ],
    id: "Momentum is an amazing thing when it is working in your favour.",
    author: "Simon Mignolet",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["age", "chivalry", "past", "bores", "succeeded", "dragons"],
    words: [
      "the",
      "age",
      "of",
      "chivalry",
      "is",
      "past",
      "bores",
      "have",
      "succeeded",
      "to",
      "dragons",
    ],
    id: "The age of chivalry is past. Bores have succeeded to dragons.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatever", "done", "always", "occurs", "beyond", "evil"],
    words: [
      "whatever",
      "is",
      "done",
      "for",
      "love",
      "always",
      "occurs",
      "beyond",
      "good",
      "and",
      "evil",
    ],
    id: "Whatever is done for love always occurs beyond good and evil.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["internet", "becoming", "town", "square", "global", "village"],
    words: [
      "the",
      "internet",
      "is",
      "becoming",
      "town",
      "square",
      "for",
      "global",
      "village",
      "of",
      "tomorrow",
    ],
    id: "The Internet is becoming the town square for the global village of tomorrow.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "look", "back", "unless", "planning", "way"],
    words: ["never", "look", "back", "unless", "you", "are", "planning", "to", "go", "that", "way"],
    id: "Never look back unless you are planning to go that way.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["men", "endurance", "call", "whole", "world", "brother"],
    words: [
      "the",
      "men",
      "who",
      "learn",
      "endurance",
      "are",
      "they",
      "call",
      "whole",
      "world",
      "brother",
    ],
    id: "The men who learn endurance, are they who call the whole world, brother.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "lot", "patience", "boring", "arthouse", "movies"],
    words: [
      "i",
      "don't",
      "have",
      "a",
      "lot",
      "of",
      "patience",
      "for",
      "boring",
      "arthouse",
      "movies",
    ],
    id: "I don't have a lot of patience for boring arthouse movies.",
    author: "Zoe Kazan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["ultimate", "ground", "faith", "knowledge", "confidence", "god"],
    words: [
      "the",
      "ultimate",
      "ground",
      "of",
      "faith",
      "and",
      "knowledge",
      "is",
      "confidence",
      "in",
      "god",
    ],
    id: "The ultimate ground of faith and knowledge is confidence in God.",
    author: "Charles Hodge",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["research", "formalized", "curiosity", "poking", "prying", "purpose"],
    words: [
      "research",
      "is",
      "formalized",
      "curiosity",
      "it",
      "poking",
      "and",
      "prying",
      "with",
      "a",
      "purpose",
    ],
    id: "Research is formalized curiosity. It is poking and prying with a purpose.",
    author: "Zora Neale Hurston",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["knew", "lie", "silence", "as", "evil", "speech"],
    words: ["he", "knew", "the", "lie", "of", "silence", "to", "be", "as", "evil", "speech"],
    id: "He knew the lie of silence to be as evil as the lie of speech.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["book", "must", "ax", "frozen", "sea", "within"],
    words: ["a", "book", "must", "be", "the", "ax", "for", "frozen", "sea", "within", "us"],
    id: "A book must be the ax for the frozen sea within us.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["how", "little", "noise", "expect", "man", "putt"],
    words: ["how", "about", "a", "little", "noise", "do", "you", "expect", "man", "to", "putt"],
    id: "How about a little noise. How do you expect a man to putt?",
    author: "Babe Ruth",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "togetherness", "important", "ingredient", "family", "life"],
    words: [
      "i",
      "think",
      "togetherness",
      "is",
      "a",
      "very",
      "important",
      "ingredient",
      "to",
      "family",
      "life",
    ],
    id: "I think togetherness is a very important ingredient to family life.",
    author: "Barbara Bush",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "only", "smile", "because", "coming", "next"],
    words: [
      "but",
      "god",
      "can",
      "only",
      "smile",
      "because",
      "know",
      "what",
      "is",
      "coming",
      "next",
    ],
    id: "But God can only smile because only God can know what is coming next.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["friend", "emotional", "bond", "friendship", "human", "experience"],
    words: [
      "a",
      "friend",
      "is",
      "an",
      "emotional",
      "bond",
      "just",
      "like",
      "friendship",
      "human",
      "experience",
    ],
    id: "A friend is an emotional bond, just like friendship is a human experience.",
    author: "Simon Sinek",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "fear", "substance", "probably", "best", "part"],
    words: ["my", "fear", "is", "substance", "and", "probably", "the", "best", "part", "of", "me"],
    id: "My 'fear' is my substance, and probably the best part of me.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["find", "something", "passionate", "keep", "tremendously", "interested"],
    words: [
      "find",
      "something",
      "you're",
      "passionate",
      "about",
      "and",
      "keep",
      "tremendously",
      "interested",
      "in",
      "it",
    ],
    id: "Find something you're passionate about and keep tremendously interested in it.",
    author: "Julia Child",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "courageous", "act", "still", "yourself", "aloud"],
    words: [
      "the",
      "most",
      "courageous",
      "act",
      "is",
      "still",
      "to",
      "think",
      "for",
      "yourself",
      "aloud",
    ],
    id: "The most courageous act is still to think for yourself. Aloud.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["far", "better", "alone", "than", "bad", "company"],
    words: ["it", "is", "far", "better", "to", "be", "alone", "than", "in", "bad", "company"],
    id: "It is far better to be alone, than to be in bad company.",
    author: "George Washington",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["simply", "enjoy", "life", "great", "pleasures", "come"],
    words: [
      "simply",
      "enjoy",
      "life",
      "and",
      "the",
      "great",
      "pleasures",
      "that",
      "come",
      "with",
      "it",
    ],
    id: "Simply enjoy life and the great pleasures that come with it.",
    author: "Karolina Kurkova",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "ever", "told", "grief", "felt", "fear"],
    words: ["no", "one", "ever", "told", "me", "that", "grief", "felt", "so", "like", "fear"],
    id: "No one ever told me that grief felt so like fear.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["blessed", "expects", "nothing", "shall", "never", "disappointed"],
    words: [
      "blessed",
      "is",
      "he",
      "that",
      "expects",
      "nothing",
      "for",
      "shall",
      "never",
      "be",
      "disappointed",
    ],
    id: "Blessed is he that expects nothing, for he shall never be disappointed.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "often", "doubt", "which", "most", "believe"],
    words: [
      "when",
      "we",
      "are",
      "in",
      "love",
      "often",
      "doubt",
      "that",
      "which",
      "most",
      "believe",
    ],
    id: "When we are in love we often doubt that which we most believe.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["dont", "up", "obstacles", "overcome", "through", "strategy"],
    words: [
      "dont",
      "give",
      "up",
      "obstacles",
      "can",
      "be",
      "overcome",
      "through",
      "strategy",
      "and",
      "learning",
    ],
    id: "Dont give up. Obstacles can be overcome through strategy and learning.",
    author: "Hidetaka Miyazaki",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "discourse", "men", "business", "short", "comprehensive"],
    words: [
      "let",
      "your",
      "discourse",
      "with",
      "men",
      "of",
      "business",
      "be",
      "short",
      "and",
      "comprehensive",
    ],
    id: "Let your Discourse with Men of Business be Short and Comprehensive.",
    author: "George Washington",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["talking", "seemed", "was", "point", "adult", "existence"],
    words: [
      "talking",
      "it",
      "seemed",
      "to",
      "me",
      "was",
      "the",
      "point",
      "of",
      "adult",
      "existence",
    ],
    id: "Talking, it seemed to me, was the point of adult existence.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "mystery", "religion", "should", "never", "rationalised"],
    words: [
      "happiness",
      "is",
      "a",
      "mystery",
      "like",
      "religion",
      "and",
      "should",
      "never",
      "be",
      "rationalised",
    ],
    id: "Happiness is a mystery, like religion, and should never be rationalised.",
    author: "Gilbert K. Chesterton",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "suffer", "survive", "find", "meaning", "suffering"],
    words: [
      "to",
      "live",
      "is",
      "suffer",
      "survive",
      "find",
      "some",
      "meaning",
      "in",
      "the",
      "suffering",
    ],
    id: "To live is to suffer, to survive is to find some meaning in the suffering.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "magic", "formula", "prioritizing", "world's", "problems"],
    words: [
      "i",
      "don't",
      "have",
      "a",
      "magic",
      "formula",
      "for",
      "prioritizing",
      "the",
      "world's",
      "problems",
    ],
    id: "I don't have a magic formula for prioritizing the world's problems.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["triangles", "had", "god", "would", "three", "sides"],
    words: ["if", "triangles", "had", "a", "god", "they", "would", "give", "him", "three", "sides"],
    id: "If triangles had a god, they would give him three sides.",
    author: "Montesquieu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "collective", "wisdom", "individual", "ignorance"],
    words: [
      "i",
      "do",
      "not",
      "believe",
      "in",
      "the",
      "collective",
      "wisdom",
      "of",
      "individual",
      "ignorance",
    ],
    id: "I do not believe in the collective wisdom of individual ignorance.",
    author: "Thomas Carlyle",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["friendship", "taxing", "arduous", "form", "leisure", "activity"],
    words: [
      "friendship",
      "is",
      "a",
      "very",
      "taxing",
      "and",
      "arduous",
      "form",
      "of",
      "leisure",
      "activity",
    ],
    id: "Friendship is a very taxing and arduous form of leisure activity.",
    author: "Mortimer Adler",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["america", "forgiveness", "story", "land", "second", "chances"],
    words: [
      "america",
      "loves",
      "a",
      "forgiveness",
      "story",
      "is",
      "the",
      "land",
      "of",
      "second",
      "chances",
    ],
    id: "America loves a forgiveness story. America is the land of second chances.",
    author: "Michael Caputo",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["expanding", "field", "knowledge", "increase", "horizon", "ignorance"],
    words: [
      "in",
      "expanding",
      "the",
      "field",
      "of",
      "knowledge",
      "we",
      "but",
      "increase",
      "horizon",
      "ignorance",
    ],
    id: "In expanding the field of knowledge we but increase the horizon of ignorance.",
    author: "Henry Miller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["make", "your", "habit", "critical", "small", "things"],
    words: [
      "make",
      "it",
      "your",
      "habit",
      "not",
      "to",
      "be",
      "critical",
      "about",
      "small",
      "things",
    ],
    id: "Make it your habit not to be critical about small things.",
    author: "Edward Everett Hale",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "lot", "jazz", "best", "when", "improvise"],
    words: ["life", "is", "a", "lot", "like", "jazz", "it's", "best", "when", "you", "improvise"],
    id: "Life is a lot like jazz... it's best when you improvise.",
    author: "George Gershwin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["cannot", "ladder", "success", "dressed", "costume", "failure"],
    words: [
      "you",
      "cannot",
      "climb",
      "the",
      "ladder",
      "of",
      "success",
      "dressed",
      "in",
      "costume",
      "failure",
    ],
    id: "You cannot climb the ladder of success dressed in the costume of failure.",
    author: "Zig Ziglar",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["winning", "title", "england", "biggest", "challenge", "all"],
    words: [
      "winning",
      "the",
      "title",
      "in",
      "england",
      "is",
      "biggest",
      "challenge",
      "of",
      "them",
      "all",
    ],
    id: "Winning the title in England is the biggest challenge of them all.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["holy", "poverty", "confounds", "cupidity", "avarice", "world"],
    words: [
      "holy",
      "poverty",
      "confounds",
      "cupidity",
      "and",
      "avarice",
      "the",
      "cares",
      "of",
      "this",
      "world",
    ],
    id: "Holy poverty confounds cupidity and avarice and the cares of this world.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["take", "things", "as", "punch", "when", "kick"],
    words: ["take", "things", "as", "they", "are", "punch", "when", "you", "have", "to", "kick"],
    id: "Take things as they are. Punch when you have to punch. Kick when you have to kick.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "lot", "myself", "being", "alone", "doing"],
    words: [
      "i'm",
      "learning",
      "a",
      "lot",
      "about",
      "myself",
      "being",
      "alone",
      "and",
      "doing",
      "what",
    ],
    id: "I'm learning a lot about myself being alone, and doing what I'm doing.",
    author: "Chantal Kreviazuk",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["government", "by", "shall", "perish", "from", "earth"],
    words: [
      "government",
      "of",
      "the",
      "people",
      "by",
      "for",
      "shall",
      "not",
      "perish",
      "from",
      "earth",
    ],
    id: "Government of the people, by the people, for the people, shall not perish from the Earth.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "aid", "zimbabwe", "through", "mugabe", "government"],
    words: [
      "well",
      "no",
      "one",
      "gives",
      "aid",
      "to",
      "zimbabwe",
      "through",
      "the",
      "mugabe",
      "government",
    ],
    id: "Well, no one gives aid to Zimbabwe through the Mugabe government.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "enough", "religion", "world", "even", "destroy"],
    words: [
      "there",
      "is",
      "not",
      "enough",
      "religion",
      "in",
      "the",
      "world",
      "even",
      "to",
      "destroy",
    ],
    id: "There is not enough religion in the world even to destroy religion.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "must", "always", "balanced", "degree", "consistency"],
    words: [
      "but",
      "change",
      "must",
      "always",
      "be",
      "balanced",
      "with",
      "some",
      "degree",
      "of",
      "consistency",
    ],
    id: "But change must always be balanced with some degree of consistency.",
    author: "Ron D. Burton",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "i'd", "better", "because", "was", "combat"],
    words: [
      "i",
      "think",
      "i'd",
      "be",
      "a",
      "better",
      "president",
      "because",
      "was",
      "in",
      "combat",
    ],
    id: "I think I'd be a better president because I was in combat.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["angry", "revenge", "faults", "others", "on", "ourselves"],
    words: [
      "to",
      "be",
      "angry",
      "is",
      "revenge",
      "the",
      "faults",
      "of",
      "others",
      "on",
      "ourselves",
    ],
    id: "To be angry is to revenge the faults of others on ourselves.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "certainly", "taken", "terms", "food", "clothes"],
    words: [
      "i'm",
      "certainly",
      "well",
      "taken",
      "care",
      "of",
      "in",
      "terms",
      "food",
      "and",
      "clothes",
    ],
    id: "I'm certainly well taken care of in terms of food and clothes.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["yesterday", "ours", "recover", "win", "or", "lose"],
    words: [
      "yesterday",
      "is",
      "not",
      "ours",
      "to",
      "recover",
      "but",
      "tomorrow",
      "win",
      "or",
      "lose",
    ],
    id: "Yesterday is not ours to recover, but tomorrow is ours to win or lose.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "person", "change", "world", "add", "up"],
    words: ["no", "one", "person", "can", "change", "the", "world", "but", "and", "add", "up"],
    id: "No one person can change the world, but one and one and one add up.",
    author: "Sylvie Guillem",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "gay", "i", "much", "broadway", "musicals"],
    words: [
      "i'm",
      "not",
      "gay",
      "so",
      "i",
      "don't",
      "know",
      "much",
      "about",
      "broadway",
      "musicals",
    ],
    id: "I'm not gay, so I don't know much about Broadway musicals.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "enough", "strength", "endure", "misfortunes", "others"],
    words: [
      "we",
      "all",
      "have",
      "enough",
      "strength",
      "to",
      "endure",
      "the",
      "misfortunes",
      "of",
      "others",
    ],
    id: "We all have enough strength to endure the misfortunes of others.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "thing", "most", "liberal", "their", "advice"],
    words: [
      "the",
      "one",
      "thing",
      "people",
      "are",
      "most",
      "liberal",
      "with",
      "is",
      "their",
      "advice",
    ],
    id: "The one thing people are the most liberal with, is their advice.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "happiness", "choice", "days", "difficult"],
    words: [
      "i",
      "believe",
      "happiness",
      "is",
      "a",
      "choice",
      "some",
      "days",
      "it",
      "very",
      "difficult",
    ],
    id: "I believe happiness is a choice. Some days it is a very difficult choice.",
    author: "Steve Gleason",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["confidence", "goodness", "another", "proof", "one's", "own"],
    words: [
      "confidence",
      "in",
      "the",
      "goodness",
      "of",
      "another",
      "is",
      "good",
      "proof",
      "one's",
      "own",
    ],
    id: "Confidence in the goodness of another is good proof of one's own goodness.",
    author: "Michel de Montaigne",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyone", "plan", "till", "get", "punched", "mouth"],
    words: ["everyone", "has", "a", "plan", "till", "they", "get", "punched", "in", "the", "mouth"],
    id: "Everyone has a plan 'till they get punched in the mouth.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "movies", "subject", "million", "interpretations"],
    words: [
      "i",
      "do",
      "believe",
      "that",
      "movies",
      "are",
      "subject",
      "to",
      "a",
      "million",
      "interpretations",
    ],
    id: "I do believe that movies are subject to a million interpretations.",
    author: "Oliver Stone",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["low-income", "countries", "main", "problems", "infectious", "diseases"],
    words: [
      "in",
      "low-income",
      "countries",
      "the",
      "main",
      "problems",
      "you",
      "have",
      "is",
      "infectious",
      "diseases",
    ],
    id: "In low-income countries, the main problems you have is infectious diseases.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["talking", "much", "oneself", "also", "means", "conceal"],
    words: [
      "talking",
      "much",
      "about",
      "oneself",
      "can",
      "also",
      "be",
      "a",
      "means",
      "to",
      "conceal",
    ],
    id: "Talking much about oneself can also be a means to conceal oneself.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["evil", "flourish", "only", "requires", "men", "nothing"],
    words: [
      "for",
      "evil",
      "to",
      "flourish",
      "it",
      "only",
      "requires",
      "good",
      "men",
      "do",
      "nothing",
    ],
    id: "For evil to flourish, it only requires good men to do nothing.",
    author: "Simon Wiesenthal",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["ain't", "fun", "watch", "i'll", "get", "done"],
    words: ["this", "ain't", "fun", "but", "you", "watch", "me", "i'll", "get", "it", "done"],
    id: "This ain't fun. But you watch me, I'll get it done.",
    author: "Jackie Robinson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "sure", "thing", "luck", "will", "change"],
    words: ["the", "only", "sure", "thing", "about", "luck", "is", "that", "it", "will", "change"],
    id: "The only sure thing about luck is that it will change.",
    author: "Bret Harte",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatever", "mind", "man", "conceive", "believe", "achieve"],
    words: [
      "whatever",
      "the",
      "mind",
      "of",
      "man",
      "can",
      "conceive",
      "and",
      "believe",
      "it",
      "achieve",
    ],
    id: "Whatever the mind of man can conceive and believe, it can achieve.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["capital", "mistake", "theorize", "before", "one", "data"],
    words: [
      "it",
      "is",
      "a",
      "capital",
      "mistake",
      "to",
      "theorize",
      "before",
      "one",
      "has",
      "data",
    ],
    id: "It is a capital mistake to theorize before one has data.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "more", "patience", "than", "did", "past"],
    words: ["i", "think", "have", "more", "patience", "now", "than", "did", "in", "the", "past"],
    id: "I think I have more patience now than I did in the past.",
    author: "T. Boone Pickens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["once", "make", "decision", "universe", "conspires", "happen"],
    words: [
      "once",
      "you",
      "make",
      "a",
      "decision",
      "the",
      "universe",
      "conspires",
      "to",
      "it",
      "happen",
    ],
    id: "Once you make a decision, the universe conspires to make it happen.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "one", "life", "each", "our", "own"],
    words: ["there", "is", "just", "one", "life", "for", "each", "of", "us", "our", "own"],
    id: "There is just one life for each of us: our own.",
    author: "Euripides",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["important", "all", "up", "on", "mental", "health"],
    words: [
      "it's",
      "so",
      "important",
      "that",
      "we",
      "all",
      "speak",
      "up",
      "on",
      "mental",
      "health",
    ],
    id: "It's so important that we all speak up on mental health.",
    author: "Anne-Marie",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["difference", "between", "something", "great", "attention", "detail"],
    words: [
      "the",
      "difference",
      "between",
      "something",
      "good",
      "and",
      "great",
      "is",
      "attention",
      "to",
      "detail",
    ],
    id: "The difference between something good and something great is attention to detail.",
    author: "Charles R. Swindoll",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["pressure", "always", "on", "perform", "there", "question"],
    words: [
      "pressure",
      "is",
      "always",
      "on",
      "if",
      "you",
      "don't",
      "perform",
      "there",
      "no",
      "question",
    ],
    id: "Pressure is always on if you don't perform. There is no question.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyone", "resilience", "virtue", "essential", "growth", "happiness"],
    words: [
      "everyone",
      "needs",
      "resilience",
      "it's",
      "a",
      "virtue",
      "essential",
      "to",
      "growth",
      "and",
      "happiness",
    ],
    id: "Everyone needs resilience. It's a virtue essential to growth and essential to happiness.",
    author: "Eric Greitens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["difficult", "free", "fools", "from", "chains", "revere"],
    words: [
      "it",
      "is",
      "difficult",
      "to",
      "free",
      "fools",
      "from",
      "the",
      "chains",
      "they",
      "revere",
    ],
    id: "It is difficult to free fools from the chains they revere.",
    author: "Voltaire",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatever", "still", "betray", "betrayal", "only", "happen"],
    words: [
      "love",
      "is",
      "whatever",
      "you",
      "can",
      "still",
      "betray",
      "betrayal",
      "only",
      "happen",
      "if",
    ],
    id: "Love is whatever you can still betray. Betrayal can only happen if you love.",
    author: "John le Carre",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["since", "cannot", "change", "reality", "eyes", "which"],
    words: [
      "since",
      "we",
      "cannot",
      "change",
      "reality",
      "let",
      "us",
      "the",
      "eyes",
      "which",
      "see",
    ],
    id: "Since we cannot change reality, let us change the eyes which see reality.",
    author: "Nikos Kazantzakis",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["truth", "change", "according", "our", "ability", "stomach"],
    words: [
      "the",
      "truth",
      "does",
      "not",
      "change",
      "according",
      "to",
      "our",
      "ability",
      "stomach",
      "it",
    ],
    id: "The truth does not change according to our ability to stomach it.",
    author: "Flannery O'Connor",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "boring", "unless", "put", "effort", "into"],
    words: ["life", "can", "be", "boring", "unless", "you", "put", "some", "effort", "into", "it"],
    id: "Life can be boring unless you put some effort into it.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "should", "lived", "long", "as", "enough"],
    words: ["our", "care", "should", "not", "be", "to", "have", "lived", "long", "as", "enough"],
    id: "Our care should not be to have lived long as to have lived enough.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["might", "remember", "nothing", "bring", "success", "yourself"],
    words: [
      "you",
      "might",
      "well",
      "remember",
      "that",
      "nothing",
      "can",
      "bring",
      "success",
      "but",
      "yourself",
    ],
    id: "You might well remember that nothing can bring you success but yourself.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["necessary", "change", "person", "his", "awareness", "himself"],
    words: [
      "what",
      "is",
      "necessary",
      "to",
      "change",
      "a",
      "person",
      "his",
      "awareness",
      "of",
      "himself",
    ],
    id: "What is necessary to change a person is to change his awareness of himself.",
    author: "Abraham Maslow",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "our", "case", "must", "act", "anew"],
    words: ["as", "our", "case", "is", "new", "we", "must", "think", "and", "act", "anew"],
    id: "As our case is new, we must think and act anew.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["harness", "mules", "oxen", "horse", "chance", "run"],
    words: ["harness", "mules", "and", "oxen", "but", "give", "a", "horse", "chance", "to", "run"],
    id: "Harness mules and oxen, but give a horse a chance to run.",
    author: "Wild Bill Hickok",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "full", "happiness", "tears", "strong", "faith"],
    words: [
      "life",
      "is",
      "full",
      "of",
      "happiness",
      "and",
      "tears",
      "be",
      "strong",
      "have",
      "faith",
    ],
    id: "Life is full of happiness and tears; be strong and have faith.",
    author: "Kareena Kapoor Khan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["first", "sign", "beginning", "understanding", "wish", "die"],
    words: [
      "a",
      "first",
      "sign",
      "of",
      "the",
      "beginning",
      "understanding",
      "is",
      "wish",
      "to",
      "die",
    ],
    id: "A first sign of the beginning of understanding is the wish to die.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["paris", "by", "night", "nightmare", "cliche", "anymore"],
    words: [
      "paris",
      "by",
      "night",
      "is",
      "a",
      "nightmare",
      "now",
      "it",
      "not",
      "cliche",
      "anymore",
    ],
    id: "Paris by night is a nightmare now. It is not a cliche anymore.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["economist's", "guess", "liable", "as", "anybody", "else's"],
    words: [
      "an",
      "economist's",
      "guess",
      "is",
      "liable",
      "to",
      "be",
      "as",
      "good",
      "anybody",
      "else's",
    ],
    id: "An economist's guess is liable to be as good as anybody else's.",
    author: "Will Rogers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "healthy", "alone", "than", "sick", "else"],
    words: [
      "it's",
      "better",
      "to",
      "be",
      "healthy",
      "alone",
      "than",
      "sick",
      "with",
      "someone",
      "else",
    ],
    id: "It's better to be healthy alone than sick with someone else.",
    author: "Phil McGraw",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["strike", "against", "war", "without", "battles", "fought"],
    words: [
      "strike",
      "against",
      "war",
      "for",
      "without",
      "you",
      "no",
      "battles",
      "can",
      "be",
      "fought",
    ],
    id: "Strike against war, for without you no battles can be fought!",
    author: "Helen Keller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["imagination", "nature", "far", "greater", "than", "man"],
    words: [
      "see",
      "that",
      "the",
      "imagination",
      "of",
      "nature",
      "is",
      "far",
      "greater",
      "than",
      "man",
    ],
    id: "See that the imagination of nature is far, far greater than the imagination of man.",
    author: "Richard P. Feynman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "live", "together", "might", "as", "happily"],
    words: ["we", "all", "have", "to", "live", "together", "so", "might", "as", "well", "happily"],
    id: "We all have to live together, so we might as well live together happily.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["can't", "witty", "exert", "themselves", "devout", "affectionate"],
    words: [
      "people",
      "who",
      "can't",
      "be",
      "witty",
      "exert",
      "themselves",
      "to",
      "devout",
      "and",
      "affectionate",
    ],
    id: "People who can't be witty exert themselves to be devout and affectionate.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["nature", "stay", "close", "will", "never", "fail"],
    words: ["study", "nature", "love", "stay", "close", "to", "it", "will", "never", "fail", "you"],
    id: "Study nature, love nature, stay close to nature. It will never fail you.",
    author: "Frank Lloyd Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["truth", "found", "when", "men", "free", "pursue"],
    words: ["the", "truth", "is", "found", "when", "men", "are", "free", "to", "pursue", "it"],
    id: "The truth is found when men are free to pursue it.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["acting", "being", "famous", "exploring", "human", "soul"],
    words: [
      "acting",
      "is",
      "not",
      "about",
      "being",
      "famous",
      "it's",
      "exploring",
      "the",
      "human",
      "soul",
    ],
    id: "Acting is not about being famous, it's about exploring the human soul.",
    author: "Annette Bening",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "limitations", "mind", "except", "those", "acknowledge"],
    words: [
      "there",
      "are",
      "no",
      "limitations",
      "to",
      "the",
      "mind",
      "except",
      "those",
      "we",
      "acknowledge",
    ],
    id: "There are no limitations to the mind except those we acknowledge.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["successful", "man", "one", "had", "chance", "took"],
    words: ["the", "successful", "man", "is", "one", "who", "had", "chance", "and", "took", "it"],
    id: "The successful man is the one who had the chance and took it.",
    author: "Roger Babson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "whole", "story", "having", "second", "chance"],
    words: ["my", "whole", "story", "is", "just", "about", "me", "having", "a", "second", "chance"],
    id: "My whole story is just about me having a second chance.",
    author: "Two Chainz",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["making", "movies", "way", "understanding", "myself", "world"],
    words: [
      "making",
      "movies",
      "is",
      "a",
      "way",
      "of",
      "understanding",
      "myself",
      "and",
      "the",
      "world",
    ],
    id: "Making movies is a way of understanding myself and the world.",
    author: "Ang Lee",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "feel", "sometimes", "was", "meant", "society"],
    words: [
      "i",
      "feel",
      "like",
      "sometimes",
      "that",
      "was",
      "not",
      "meant",
      "for",
      "this",
      "society",
    ],
    id: "I feel like sometimes that I was not meant for this society.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["friendship", "certainly", "finest", "balm", "pangs", "disappointed"],
    words: [
      "friendship",
      "is",
      "certainly",
      "the",
      "finest",
      "balm",
      "for",
      "pangs",
      "of",
      "disappointed",
      "love",
    ],
    id: "Friendship is certainly the finest balm for the pangs of disappointed love.",
    author: "Jane Austen",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["faith", "something", "grasp", "state", "grow", "into"],
    words: ["faith", "is", "not", "something", "to", "grasp", "it", "a", "state", "grow", "into"],
    id: "Faith is not something to grasp, it is a state to grow into.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["blockchain", "change", "everything", "more", "than", "internet"],
    words: [
      "the",
      "blockchain",
      "is",
      "going",
      "to",
      "change",
      "everything",
      "more",
      "than",
      "internet",
      "has",
    ],
    id: "The blockchain is going to change everything more than the Internet has.",
    author: "Brock Pierce",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["healing", "matter", "time", "sometimes", "also", "opportunity"],
    words: [
      "healing",
      "is",
      "a",
      "matter",
      "of",
      "time",
      "but",
      "it",
      "sometimes",
      "also",
      "opportunity",
    ],
    id: "Healing is a matter of time, but it is sometimes also a matter of opportunity.",
    author: "Hippocrates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["nuclear", "option", "ultimately", "example", "arrogance", "power"],
    words: [
      "this",
      "nuclear",
      "option",
      "is",
      "ultimately",
      "an",
      "example",
      "of",
      "the",
      "arrogance",
      "power",
    ],
    id: "This nuclear option is ultimately an example of the arrogance of power.",
    author: "Joe Biden",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["will", "never", "come", "makes", "life", "sweet"],
    words: ["that", "it", "will", "never", "come", "again", "is", "what", "makes", "life", "sweet"],
    id: "That it will never come again is what makes life sweet.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "thing", "matters", "submitting", "will", "god"],
    words: [
      "the",
      "only",
      "thing",
      "that",
      "matters",
      "is",
      "submitting",
      "to",
      "will",
      "of",
      "god",
    ],
    id: "The only thing that matters is submitting to the will of God.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "happiness", "duty", "although", "may", "seem"],
    words: ["there", "is", "happiness", "in", "duty", "although", "it", "may", "not", "seem", "so"],
    id: "There is happiness in duty, although it may not seem so.",
    author: "Jose Marti",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "dissolved", "into", "something", "complete", "great"],
    words: [
      "that",
      "is",
      "happiness",
      "to",
      "be",
      "dissolved",
      "into",
      "something",
      "complete",
      "and",
      "great",
    ],
    id: "That is happiness; to be dissolved into something complete and great.",
    author: "Willa Cather",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["having", "been", "poor", "shame", "being", "ashamed"],
    words: ["having", "been", "poor", "is", "no", "shame", "but", "being", "ashamed", "of", "it"],
    id: "Having been poor is no shame, but being ashamed of it, is.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["far", "from", "enemy", "make", "believe", "near"],
    words: ["if", "you", "are", "far", "from", "the", "enemy", "make", "him", "believe", "near"],
    id: "If you are far from the enemy, make him believe you are near.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["count", "e-mail", "i'm", "on", "internet", "all"],
    words: ["if", "you", "count", "e-mail", "i'm", "on", "the", "internet", "all", "day", "every"],
    id: "If you count E-mail, I'm on the Internet all day, every day.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["train", "our", "minds", "desire", "situation", "demands"],
    words: [
      "let",
      "us",
      "train",
      "our",
      "minds",
      "to",
      "desire",
      "what",
      "the",
      "situation",
      "demands",
    ],
    id: "Let us train our minds to desire what the situation demands.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["whole", "family", "tight", "all", "really", "each"],
    words: [
      "the",
      "whole",
      "family",
      "is",
      "tight",
      "we",
      "all",
      "really",
      "love",
      "each",
      "other",
    ],
    id: "The whole family is tight. We all really love each other.",
    author: "Kieran Culkin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["waits", "upon", "fortune", "never", "sure", "dinner"],
    words: ["he", "that", "waits", "upon", "fortune", "is", "never", "sure", "of", "a", "dinner"],
    id: "He that waits upon fortune, is never sure of a dinner.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["hard", "humble", "when", "as", "great", "i"],
    words: ["it's", "hard", "to", "be", "humble", "when", "you're", "as", "great", "i", "am"],
    id: "It's hard to be humble, when you're as great as I am.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["bible", "man's", "word", "god", "god's", "man"],
    words: ["the", "bible", "is", "not", "man's", "word", "about", "god", "but", "god's", "man"],
    id: "The Bible is not man's word about God, but God's word about man.",
    author: "John Barth",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "one", "forced", "into", "belief", "unbelief"],
    words: ["just", "as", "no", "one", "can", "be", "forced", "into", "belief", "so", "unbelief"],
    id: "Just as no one can be forced into belief, so no one can be forced into unbelief.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["oppressive", "government", "more", "feared", "than", "tiger"],
    words: [
      "an",
      "oppressive",
      "government",
      "is",
      "more",
      "to",
      "be",
      "feared",
      "than",
      "a",
      "tiger",
    ],
    id: "An oppressive government is more to be feared than a tiger.",
    author: "Confucius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["close", "forgets", "my", "birthday", "i", "heartbroken"],
    words: [
      "if",
      "someone",
      "close",
      "to",
      "me",
      "forgets",
      "my",
      "birthday",
      "i",
      "am",
      "heartbroken",
    ],
    id: "If someone close to me forgets my birthday, I am heartbroken.",
    author: "Parineeti Chopra",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["generous", "kindly", "words", "especially", "those", "absent"],
    words: [
      "be",
      "generous",
      "with",
      "kindly",
      "words",
      "especially",
      "about",
      "those",
      "who",
      "are",
      "absent",
    ],
    id: "Be generous with kindly words, especially about those who are absent.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["immorality", "morality", "those", "having", "better", "time"],
    words: [
      "immorality",
      "the",
      "morality",
      "of",
      "those",
      "who",
      "are",
      "having",
      "a",
      "better",
      "time",
    ],
    id: "Immorality: the morality of those who are having a better time.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["even", "virtue", "fairer", "when", "appears", "person"],
    words: [
      "even",
      "virtue",
      "is",
      "fairer",
      "when",
      "it",
      "appears",
      "in",
      "a",
      "beautiful",
      "person",
    ],
    id: "Even virtue is fairer when it appears in a beautiful person.",
    author: "Virgil",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["many", "when", "merely", "rearranging", "their", "prejudices"],
    words: [
      "many",
      "people",
      "think",
      "they",
      "are",
      "thinking",
      "when",
      "merely",
      "rearranging",
      "their",
      "prejudices",
    ],
    id: "Many people think they are thinking when they are merely rearranging their prejudices.",
    author: "William James",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["out", "6", "billion", "humans", "troublemakers", "handful"],
    words: [
      "out",
      "of",
      "6",
      "billion",
      "humans",
      "the",
      "troublemakers",
      "are",
      "just",
      "a",
      "handful",
    ],
    id: "Out of 6 billion humans, the troublemakers are just a handful.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["family", "fight", "talk", "then", "make", "amends"],
    words: [
      "in",
      "every",
      "family",
      "people",
      "fight",
      "don't",
      "talk",
      "and",
      "then",
      "make",
      "amends",
    ],
    id: "In every family, people fight, don't talk and then make amends.",
    author: "Upasana Singh",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "handsome", "or", "sexy", "course", "hopeless"],
    words: ["i", "am", "not", "handsome", "or", "sexy", "of", "course", "it's", "like", "hopeless"],
    id: "I am not handsome or sexy. Of course, it's not like I am hopeless.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["absolutely", "normal", "different", "human", "beings", "ways"],
    words: [
      "it's",
      "absolutely",
      "normal",
      "that",
      "different",
      "human",
      "beings",
      "want",
      "to",
      "go",
      "ways",
    ],
    id: "It's absolutely normal that different human beings want to go different ways.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["born", "great", "achieve", "greatness", "thrust", "upon"],
    words: [
      "some",
      "are",
      "born",
      "great",
      "achieve",
      "greatness",
      "and",
      "have",
      "thrust",
      "upon",
      "them",
    ],
    id: "Some are born great, some achieve greatness, and some have greatness thrust upon them.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["wars", "paid", "wartime", "bill", "comes", "later"],
    words: ["wars", "are", "not", "paid", "for", "in", "wartime", "the", "bill", "comes", "later"],
    id: "Wars are not paid for in wartime, the bill comes later.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "right", "consume", "happiness", "without", "producing"],
    words: [
      "no",
      "one",
      "has",
      "a",
      "right",
      "to",
      "consume",
      "happiness",
      "without",
      "producing",
      "it",
    ],
    id: "No one has a right to consume happiness without producing it.",
    author: "Helen Keller",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["doomed", "make", "choices", "life's", "greatest", "paradox"],
    words: [
      "you",
      "are",
      "doomed",
      "to",
      "make",
      "choices",
      "this",
      "is",
      "life's",
      "greatest",
      "paradox",
    ],
    id: "You are doomed to make choices. This is life's greatest paradox.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["created", "without", "our", "will", "save", "consent"],
    words: [
      "he",
      "who",
      "created",
      "us",
      "without",
      "our",
      "help",
      "will",
      "not",
      "save",
      "consent",
    ],
    id: "He who created us without our help will not save us without our consent.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["difference", "opinion", "makes", "horse", "racing", "missionaries"],
    words: [
      "a",
      "difference",
      "of",
      "opinion",
      "is",
      "what",
      "makes",
      "horse",
      "racing",
      "and",
      "missionaries",
    ],
    id: "A difference of opinion is what makes horse racing and missionaries.",
    author: "Will Rogers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["regard", "as", "most", "humane", "spare", "shame"],
    words: [
      "what",
      "do",
      "you",
      "regard",
      "as",
      "most",
      "humane",
      "to",
      "spare",
      "someone",
      "shame",
    ],
    id: "What do you regard as most humane? To spare someone shame.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "hang", "capitalists", "will", "sell", "use"],
    words: [
      "when",
      "we",
      "hang",
      "the",
      "capitalists",
      "they",
      "will",
      "sell",
      "us",
      "rope",
      "use",
    ],
    id: "When we hang the capitalists they will sell us the rope we use.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "one", "inspired", "by", "guided", "knowledge"],
    words: [
      "the",
      "good",
      "life",
      "is",
      "one",
      "inspired",
      "by",
      "love",
      "and",
      "guided",
      "knowledge",
    ],
    id: "The good life is one inspired by love and guided by knowledge.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "mother", "still", "sends", "office", "birthday"],
    words: [
      "my",
      "mother",
      "still",
      "sends",
      "a",
      "cake",
      "to",
      "the",
      "office",
      "for",
      "birthday",
    ],
    id: "My mother still sends a cake to the office for my birthday.",
    author: "David Ulevitch",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["neglect", "timely", "repair", "makes", "rebuilding", "necessary"],
    words: [
      "it",
      "is",
      "the",
      "neglect",
      "of",
      "timely",
      "repair",
      "that",
      "makes",
      "rebuilding",
      "necessary",
    ],
    id: "It is the neglect of timely repair that makes rebuilding necessary.",
    author: "Richard Whately",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["notice", "whether", "winter", "or", "summer", "when"],
    words: [
      "people",
      "don't",
      "notice",
      "whether",
      "it's",
      "winter",
      "or",
      "summer",
      "when",
      "they're",
      "happy",
    ],
    id: "People don't notice whether it's winter or summer when they're happy.",
    author: "Anton Chekhov",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["secret", "my", "influence", "always", "been", "remained"],
    words: [
      "the",
      "secret",
      "of",
      "my",
      "influence",
      "has",
      "always",
      "been",
      "that",
      "it",
      "remained",
    ],
    id: "The secret of my influence has always been that it remained secret.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "polite", "word", "dead", "buried", "imagination"],
    words: [
      "knowledge",
      "is",
      "a",
      "polite",
      "word",
      "for",
      "dead",
      "but",
      "not",
      "buried",
      "imagination",
    ],
    id: "Knowledge is a polite word for dead but not buried imagination.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["listen", "there's", "hell", "universe", "next", "door"],
    words: [
      "listen",
      "there's",
      "a",
      "hell",
      "of",
      "good",
      "universe",
      "next",
      "door",
      "let's",
      "go",
    ],
    id: "Listen; there's a hell of a good universe next door: let's go.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "best", "friend", "one", "brings", "out"],
    words: ["my", "best", "friend", "is", "the", "one", "who", "brings", "out", "in", "me"],
    id: "My best friend is the one who brings out the best in me.",
    author: "Henry Ford",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["pets", "more", "compassion", "than", "most", "humans"],
    words: [
      "pets",
      "have",
      "more",
      "love",
      "and",
      "compassion",
      "in",
      "them",
      "than",
      "most",
      "humans",
    ],
    id: "Pets have more love and compassion in them than most humans.",
    author: "Robert Wagner",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "wasn't", "great", "chef", "i'm", "writer"],
    words: ["i", "wasn't", "that", "great", "a", "chef", "and", "don't", "think", "i'm", "writer"],
    id: "I wasn't that great a chef, and I don't think I'm that great a writer.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["fashion", "most", "part", "nothing", "ostentation", "riches"],
    words: [
      "fashion",
      "for",
      "the",
      "most",
      "part",
      "is",
      "nothing",
      "but",
      "ostentation",
      "of",
      "riches",
    ],
    id: "Fashion for the most part is nothing but the ostentation of riches.",
    author: "John Locke",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["falling", "having", "relationship", "two", "different", "things"],
    words: [
      "falling",
      "in",
      "love",
      "and",
      "having",
      "a",
      "relationship",
      "are",
      "two",
      "different",
      "things",
    ],
    id: "Falling in love and having a relationship are two different things.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "all", "four", "cruiserweight", "titles", "future"],
    words: [
      "i",
      "want",
      "to",
      "have",
      "all",
      "four",
      "cruiserweight",
      "titles",
      "in",
      "the",
      "future",
    ],
    id: "I want to have all four cruiserweight titles in the future.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["responsibility", "tolerance", "lies", "those", "wider", "vision"],
    words: [
      "the",
      "responsibility",
      "of",
      "tolerance",
      "lies",
      "with",
      "those",
      "who",
      "have",
      "wider",
      "vision",
    ],
    id: "The responsibility of tolerance lies with those who have the wider vision.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["while", "preserves", "its", "language", "marks", "liberty"],
    words: [
      "while",
      "a",
      "people",
      "preserves",
      "its",
      "language",
      "it",
      "the",
      "marks",
      "of",
      "liberty",
    ],
    id: "While a people preserves its language; it preserves the marks of liberty.",
    author: "Jose Rizal",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["decent", "man", "ashamed", "government", "lives", "under"],
    words: [
      "every",
      "decent",
      "man",
      "is",
      "ashamed",
      "of",
      "the",
      "government",
      "he",
      "lives",
      "under",
    ],
    id: "Every decent man is ashamed of the government he lives under.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["art", "being", "slave", "rule", "one's", "master"],
    words: ["the", "art", "of", "being", "a", "slave", "is", "to", "rule", "one's", "master"],
    id: "The art of being a slave is to rule one's master.",
    author: "Diogenes",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "fence", "against", "world", "thorough", "knowledge"],
    words: [
      "the",
      "only",
      "fence",
      "against",
      "world",
      "is",
      "a",
      "thorough",
      "knowledge",
      "of",
      "it",
    ],
    id: "The only fence against the world is a thorough knowledge of it.",
    author: "John Locke",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["blessing", "south", "africa", "man", "nelson", "mandela"],
    words: [
      "it's",
      "a",
      "blessing",
      "that",
      "south",
      "africa",
      "has",
      "man",
      "like",
      "nelson",
      "mandela",
    ],
    id: "It's a blessing that South Africa has a man like Nelson Mandela.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["lawyer's", "truth", "consistency", "or", "consistent", "expediency"],
    words: [
      "the",
      "lawyer's",
      "truth",
      "is",
      "not",
      "but",
      "consistency",
      "or",
      "a",
      "consistent",
      "expediency",
    ],
    id: "The lawyer's truth is not Truth, but consistency or a consistent expediency.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "motivation", "one", "at", "time", "right"],
    words: ["my", "motivation", "is", "tomorrow", "just", "one", "day", "at", "a", "time", "right"],
    id: "My motivation is tomorrow, just one day at a time, right?",
    author: "Rafael Nadal",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "never", "impressions", "probably", "should", "stuff"],
    words: [
      "i",
      "never",
      "do",
      "impressions",
      "but",
      "probably",
      "should",
      "people",
      "like",
      "that",
      "stuff",
    ],
    id: "I never do impressions, but I probably should. People like that stuff.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["more", "live", "present", "fear", "death", "disappears"],
    words: [
      "the",
      "more",
      "you",
      "live",
      "in",
      "present",
      "moment",
      "fear",
      "of",
      "death",
      "disappears",
    ],
    id: "The more you live in the present moment, the more the fear of death disappears.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "things", "artificial", "nature", "art", "god"],
    words: ["all", "things", "are", "artificial", "for", "nature", "is", "the", "art", "of", "god"],
    id: "All things are artificial, for nature is the art of God.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "price", "most", "aren't", "willing", "pay"],
    words: [
      "freedom",
      "has",
      "a",
      "price",
      "most",
      "people",
      "aren't",
      "willing",
      "to",
      "pay",
      "it",
    ],
    id: "Freedom has a price. Most people aren't willing to pay it.",
    author: "Jack Kevorkian",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyone", "deserves", "second", "chance", "way", "i"],
    words: [
      "everyone",
      "deserves",
      "a",
      "second",
      "chance",
      "that's",
      "just",
      "the",
      "way",
      "i",
      "am",
    ],
    id: "Everyone deserves a second chance. That's just the way I am.",
    author: "Booker T",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["parting", "foretaste", "death", "reunion", "hint", "resurrection"],
    words: [
      "every",
      "parting",
      "gives",
      "a",
      "foretaste",
      "of",
      "death",
      "reunion",
      "hint",
      "the",
      "resurrection",
    ],
    id: "Every parting gives a foretaste of death, every reunion a hint of the resurrection.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "never", "been", "done", "only", "remains"],
    words: ["i", "never", "see", "what", "has", "been", "done", "only", "remains", "to", "be"],
    id: "I never see what has been done; I only see what remains to be done.",
    author: "Buddha",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "avoid", "pain", "discipline", "usually", "painful"],
    words: [
      "most",
      "people",
      "want",
      "to",
      "avoid",
      "pain",
      "and",
      "discipline",
      "is",
      "usually",
      "painful",
    ],
    id: "Most people want to avoid pain, and discipline is usually painful.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["each", "one", "make", "difference", "together", "change"],
    words: [
      "each",
      "one",
      "of",
      "us",
      "can",
      "make",
      "a",
      "difference",
      "together",
      "we",
      "change",
    ],
    id: "Each one of us can make a difference. Together we make change.",
    author: "Barbara Mikulski",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "more", "value", "than", "get", "cannot"],
    words: ["time", "is", "more", "value", "than", "money", "you", "can", "get", "but", "cannot"],
    id: "Time is more value than money. You can get more money, but you cannot get more time.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["act", "your", "life", "as", "were", "last"],
    words: ["do", "every", "act", "of", "your", "life", "as", "if", "it", "were", "last"],
    id: "Do every act of your life as if it were your last.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["slave", "his", "stomach", "seldom", "worships", "god"],
    words: ["he", "who", "is", "a", "slave", "to", "his", "stomach", "seldom", "worships", "god"],
    id: "He who is a slave to his stomach seldom worships God.",
    author: "Saadi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "time", "enough", "will", "use", "aright"],
    words: ["we", "always", "have", "time", "enough", "if", "will", "but", "use", "it", "aright"],
    id: "We always have time enough, if we will but use it aright.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "lies", "boldly", "as", "man", "indignant"],
    words: ["no", "one", "lies", "so", "boldly", "as", "the", "man", "who", "is", "indignant"],
    id: "No one lies so boldly as the man who is indignant.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "politics", "much", "i'm", "professional", "athlete"],
    words: [
      "i",
      "don't",
      "know",
      "about",
      "politics",
      "so",
      "much",
      "i'm",
      "a",
      "professional",
      "athlete",
    ],
    id: "I don't know about politics so much. I'm a professional athlete.",
    author: "Khabib Nurmagomedov",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "patience", "strength", "hold", "on", "grudges"],
    words: [
      "i",
      "don't",
      "have",
      "the",
      "patience",
      "and",
      "strength",
      "to",
      "hold",
      "on",
      "grudges",
    ],
    id: "I don't have the patience and the strength to hold on to the grudges.",
    author: "Rahul Vaidya",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["ninety", "percent", "hockey", "mental", "half", "physical"],
    words: [
      "ninety",
      "percent",
      "of",
      "hockey",
      "is",
      "mental",
      "and",
      "the",
      "other",
      "half",
      "physical",
    ],
    id: "Ninety percent of hockey is mental and the other half is physical.",
    author: "Wayne Gretzky",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["sound", "laughter", "vaulted", "dome", "temple", "happiness"],
    words: [
      "the",
      "sound",
      "of",
      "laughter",
      "is",
      "like",
      "vaulted",
      "dome",
      "a",
      "temple",
      "happiness",
    ],
    id: "The sound of laughter is like the vaulted dome of a temple of happiness.",
    author: "Milan Kundera",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "eye", "desire", "become", "pilot", "soul"],
    words: [
      "knowledge",
      "is",
      "the",
      "eye",
      "of",
      "desire",
      "and",
      "can",
      "become",
      "pilot",
      "soul",
    ],
    id: "Knowledge is the eye of desire and can become the pilot of the soul.",
    author: "Will Durant",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["heart", "better", "than", "all", "heads", "world"],
    words: ["a", "good", "heart", "is", "better", "than", "all", "the", "heads", "in", "world"],
    id: "A good heart is better than all the heads in the world.",
    author: "Edward G. Bulwer-Lytton",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["sometimes", "i'm", "bad", "mood", "everyone", "else"],
    words: [
      "sometimes",
      "i'm",
      "in",
      "a",
      "bad",
      "mood",
      "good",
      "it's",
      "like",
      "everyone",
      "else",
    ],
    id: "Sometimes I'm in a bad mood, sometimes I'm in a good mood. It's like everyone else.",
    author: "Tricky",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "unutterable", "sigh", "planted", "depths", "soul"],
    words: [
      "god",
      "is",
      "an",
      "unutterable",
      "sigh",
      "planted",
      "in",
      "the",
      "depths",
      "of",
      "soul",
    ],
    id: "God is an unutterable sigh, planted in the depths of the soul.",
    author: "Jean Paul",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "make", "movies", "kids", "adults", "either"],
    words: ["i", "don't", "want", "to", "make", "movies", "for", "kids", "and", "adults", "either"],
    id: "I don't want to make movies for kids, and I don't want to make movies for adults either.",
    author: "Kristen Stewart",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "course", "useless", "things", "than", "nothing"],
    words: [
      "it",
      "is",
      "better",
      "of",
      "course",
      "to",
      "know",
      "useless",
      "things",
      "than",
      "nothing",
    ],
    id: "It is better, of course, to know useless things than to know nothing.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["critical", "part", "our", "mission", "organizational", "culture"],
    words: [
      "learning",
      "is",
      "a",
      "critical",
      "part",
      "of",
      "our",
      "mission",
      "and",
      "organizational",
      "culture",
    ],
    id: "Learning is a critical part of our mission and organizational culture.",
    author: "Charles Best",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["obedience", "law", "demanded", "asked", "as", "favor"],
    words: ["obedience", "of", "the", "law", "is", "demanded", "not", "asked", "as", "a", "favor"],
    id: "Obedience of the law is demanded; not asked as a favor.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["butterfly", "counts", "months", "moments", "time", "enough"],
    words: [
      "the",
      "butterfly",
      "counts",
      "not",
      "months",
      "but",
      "moments",
      "and",
      "has",
      "time",
      "enough",
    ],
    id: "The butterfly counts not months but moments, and has time enough.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "best", "never", "rest", "til", "your"],
    words: ["good", "better", "best", "never", "let", "it", "rest", "til", "your", "is", "and"],
    id: "Good, better, best. Never let it rest. 'Til your good is better and your better is best.",
    author: "St. Jerome",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["judgment", "comes", "from", "experience", "lot", "bad"],
    words: [
      "good",
      "judgment",
      "comes",
      "from",
      "experience",
      "and",
      "a",
      "lot",
      "of",
      "that",
      "bad",
    ],
    id: "Good judgment comes from experience, and a lot of that comes from bad judgment.",
    author: "Will Rogers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "telugu", "kannada", "was", "all", "tough"],
    words: [
      "i",
      "speak",
      "telugu",
      "so",
      "learning",
      "kannada",
      "was",
      "not",
      "all",
      "that",
      "tough",
    ],
    id: "I speak Telugu, so learning Kannada was not all that tough.",
    author: "Johnny Lever",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["rock", "sea", "unshaken", "stands", "his", "ground"],
    words: ["he", "like", "a", "rock", "in", "the", "sea", "unshaken", "stands", "his", "ground"],
    id: "He like a rock in the sea unshaken stands his ground.",
    author: "Virgil",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["ambition", "path", "success", "persistence", "vehicle", "arrive"],
    words: [
      "ambition",
      "is",
      "the",
      "path",
      "to",
      "success",
      "persistence",
      "vehicle",
      "you",
      "arrive",
      "in",
    ],
    id: "Ambition is the path to success. Persistence is the vehicle you arrive in.",
    author: "Bill Bradley",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "fantasy", "imagination", "-", "inner", "child"],
    words: [
      "i",
      "love",
      "fantasy",
      "imagination",
      "-",
      "that's",
      "the",
      "inner",
      "child",
      "in",
      "me",
    ],
    id: "I love fantasy; I love imagination - that's the inner child in me.",
    author: "Hannah John-Kamen",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "faith", "explanation", "necessary", "without", "possible"],
    words: [
      "to",
      "one",
      "who",
      "has",
      "faith",
      "no",
      "explanation",
      "is",
      "necessary",
      "without",
      "possible",
    ],
    id: "To one who has faith, no explanation is necessary. To one without faith, no explanation is possible.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "powerful", "himself", "his", "own", "power"],
    words: ["most", "powerful", "is", "he", "who", "has", "himself", "in", "his", "own", "power"],
    id: "Most powerful is he who has himself in his own power.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["remember", "then", "i", "everyone", "else", "forgets"],
    words: [
      "if",
      "you",
      "remember",
      "me",
      "then",
      "i",
      "don't",
      "care",
      "everyone",
      "else",
      "forgets",
    ],
    id: "If you remember me, then I don't care if everyone else forgets.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["terrorism", "tactic", "demanding", "impossible", "at", "gunpoint"],
    words: [
      "terrorism",
      "is",
      "the",
      "tactic",
      "of",
      "demanding",
      "impossible",
      "and",
      "it",
      "at",
      "gunpoint",
    ],
    id: "Terrorism is the tactic of demanding the impossible, and demanding it at gunpoint.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["death", "wish", "relief", "many", "end", "all"],
    words: ["death", "is", "the", "wish", "of", "some", "relief", "many", "and", "end", "all"],
    id: "Death is the wish of some, the relief of many, and the end of all.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "rows", "boat", "seldom", "time", "rock"],
    words: ["the", "man", "who", "rows", "boat", "seldom", "has", "time", "to", "rock", "it"],
    id: "The man who rows the boat seldom has time to rock it.",
    author: "Bill Copeland",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'd", "rather", "hated", "i", "than", "loved"],
    words: ["i'd", "rather", "be", "hated", "for", "who", "i", "am", "than", "loved", "not"],
    id: "I'd rather be hated for who I am, than loved for who I am not.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "come", "from", "middle-class", "family", "open-minded"],
    words: [
      "i",
      "come",
      "from",
      "a",
      "middle-class",
      "family",
      "but",
      "we",
      "are",
      "open-minded",
      "people",
    ],
    id: "I come from a middle-class family, but we are open-minded people.",
    author: "Kriti Sanon",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["son", "god", "became", "man", "might", "become"],
    words: ["the", "son", "of", "god", "became", "man", "so", "that", "we", "might", "become"],
    id: "The Son of God became man so that we might become God.",
    author: "Athanasius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["aim", "moon", "miss", "may", "hit", "star"],
    words: ["aim", "for", "the", "moon", "if", "you", "miss", "may", "hit", "a", "star"],
    id: "Aim for the moon. If you miss, you may hit a star.",
    author: "W. Clement Stone",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "dream", "africa", "which", "peace", "itself"],
    words: ["i", "dream", "of", "an", "africa", "which", "is", "in", "peace", "with", "itself"],
    id: "I dream of an Africa which is in peace with itself.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["business", "product", "risks", "can't", "get", "around"],
    words: [
      "every",
      "business",
      "and",
      "product",
      "has",
      "risks",
      "you",
      "can't",
      "get",
      "around",
      "it",
    ],
    id: "Every business and every product has risks. You can't get around it.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatsoever", "god", "without", "nothing", "or", "conceived"],
    words: [
      "whatsoever",
      "is",
      "in",
      "god",
      "and",
      "without",
      "nothing",
      "can",
      "be",
      "or",
      "conceived",
    ],
    id: "Whatsoever is, is in God, and without God nothing can be, or be conceived.",
    author: "Baruch Spinoza",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["broadly", "short", "words", "best", "old", "all"],
    words: [
      "broadly",
      "speaking",
      "the",
      "short",
      "words",
      "are",
      "best",
      "and",
      "old",
      "of",
      "all",
    ],
    id: "Broadly speaking, the short words are the best, and the old words best of all.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["omission", "less", "reprehensible", "than", "commission", "evil"],
    words: [
      "the",
      "omission",
      "of",
      "good",
      "is",
      "no",
      "less",
      "reprehensible",
      "than",
      "commission",
      "evil",
    ],
    id: "The omission of good is no less reprehensible than the commission of evil.",
    author: "Plutarch",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["best", "government", "which", "teaches", "govern", "ourselves"],
    words: [
      "the",
      "best",
      "government",
      "is",
      "that",
      "which",
      "teaches",
      "us",
      "to",
      "govern",
      "ourselves",
    ],
    id: "The best government is that which teaches us to govern ourselves.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["many", "men", "contemptuous", "riches", "few", "away"],
    words: [
      "many",
      "men",
      "are",
      "contemptuous",
      "of",
      "riches",
      "few",
      "can",
      "give",
      "them",
      "away",
    ],
    id: "Many men are contemptuous of riches; few can give them away.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["out", "silence", "born", "concentration", "from", "comes"],
    words: [
      "out",
      "of",
      "silence",
      "is",
      "born",
      "concentration",
      "and",
      "from",
      "that",
      "comes",
      "learning",
    ],
    id: "Out of silence is born concentration, and from that comes learning.",
    author: "Stephen Hough",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["at", "peace", "others", "because", "ourselves", "god"],
    words: [
      "we",
      "are",
      "not",
      "at",
      "peace",
      "with",
      "others",
      "because",
      "ourselves",
      "and",
      "god",
    ],
    id: "We are not at peace with others because we are not at peace with ourselves, and we are not at peace with ourselves because we are not at peace with God.",
    author: "Thomas Merton",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["arm", "or", "leg", "-", "use", "lose"],
    words: ["money", "is", "like", "an", "arm", "or", "leg", "-", "use", "it", "lose"],
    id: "Money is like an arm or leg - use it or lose it.",
    author: "Henry Ford",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "guarantee", "success", "war", "only", "deserve"],
    words: [
      "no",
      "one",
      "can",
      "guarantee",
      "success",
      "in",
      "war",
      "but",
      "only",
      "deserve",
      "it",
    ],
    id: "No one can guarantee success in war, but only deserve it.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["history", "general", "only", "informs", "bad", "government"],
    words: [
      "history",
      "in",
      "general",
      "only",
      "informs",
      "us",
      "of",
      "what",
      "bad",
      "government",
      "is",
    ],
    id: "History, in general, only informs us of what bad government is.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["boldly", "hundreds", "gone", "before", "make", "headlines"],
    words: [
      "boldly",
      "going",
      "where",
      "hundreds",
      "have",
      "gone",
      "before",
      "does",
      "not",
      "make",
      "headlines",
    ],
    id: "'Boldly going where hundreds have gone before' does not make headlines.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["business", "may", "bring", "friendship", "hardly", "ever"],
    words: [
      "business",
      "you",
      "know",
      "may",
      "bring",
      "money",
      "but",
      "friendship",
      "hardly",
      "ever",
      "does",
    ],
    id: "Business, you know, may bring you money, but friendship hardly ever does.",
    author: "Jane Austen",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["failure", "simply", "few", "errors", "judgment", "repeated"],
    words: [
      "failure",
      "is",
      "simply",
      "a",
      "few",
      "errors",
      "in",
      "judgment",
      "repeated",
      "every",
      "day",
    ],
    id: "Failure is simply a few errors in judgment, repeated every day.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["emotion", "experienced", "by", "many", "enjoyed", "few"],
    words: [
      "love",
      "is",
      "an",
      "emotion",
      "experienced",
      "by",
      "the",
      "many",
      "and",
      "enjoyed",
      "few",
    ],
    id: "Love is an emotion experienced by the many and enjoyed by the few.",
    author: "George Jean Nathan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["having", "second", "chance", "makes", "even", "harder"],
    words: [
      "having",
      "a",
      "second",
      "chance",
      "makes",
      "you",
      "want",
      "to",
      "work",
      "even",
      "harder",
    ],
    id: "Having a second chance makes you want to work even harder.",
    author: "Tia Mowry",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["ambition", "path", "success", "persistence", "vehicle", "arrive"],
    words: [
      "ambition",
      "is",
      "the",
      "path",
      "to",
      "success",
      "persistence",
      "vehicle",
      "you",
      "arrive",
      "in",
    ],
    id: "Ambition is the path to success, persistence is the vehicle you arrive in.",
    author: "William Eardley IV",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["first", "all", "must", "note", "universe", "spherical"],
    words: [
      "first",
      "of",
      "all",
      "we",
      "must",
      "note",
      "that",
      "the",
      "universe",
      "is",
      "spherical",
    ],
    id: "First of all, we must note that the universe is spherical.",
    author: "Nicolaus Copernicus",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["successful", "results", "row", "can't", "win", "lose"],
    words: [
      "to",
      "be",
      "successful",
      "you",
      "need",
      "results",
      "in",
      "a",
      "row",
      "can't",
      "win",
      "lose",
    ],
    id: "To be successful, you need results in a row. You can't win, lose, win, lose.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "feel", "lonely", "most", "by", "yourself"],
    words: [
      "the",
      "time",
      "you",
      "feel",
      "lonely",
      "is",
      "most",
      "need",
      "to",
      "be",
      "by",
      "yourself",
    ],
    id: "The time you feel lonely is the time you most need to be by yourself.",
    author: "Douglas Coupland",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["may", "marriage", "full", "laughter", "our", "paradise"],
    words: [
      "may",
      "this",
      "marriage",
      "be",
      "full",
      "of",
      "laughter",
      "our",
      "every",
      "day",
      "in",
      "paradise",
    ],
    id: "May this marriage be full of laughter, our every day in paradise.",
    author: "Rumi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["educate", "man", "mind", "morals", "menace", "society"],
    words: [
      "to",
      "educate",
      "a",
      "man",
      "in",
      "mind",
      "and",
      "not",
      "morals",
      "is",
      "menace",
      "society",
    ],
    id: "To educate a man in mind and not in morals is to educate a menace to society.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "direction", "soon", "we'll", "end", "up"],
    words: [
      "if",
      "we",
      "don't",
      "change",
      "direction",
      "soon",
      "we'll",
      "end",
      "up",
      "where",
      "we're",
      "going",
    ],
    id: "If we don't change direction soon, we'll end up where we're going.",
    author: "Irwin Corey",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["without", "open-minded", "mind", "never", "great", "success"],
    words: [
      "without",
      "an",
      "open-minded",
      "mind",
      "you",
      "can",
      "never",
      "be",
      "a",
      "great",
      "success",
    ],
    id: "Without an open-minded mind, you can never be a great success.",
    author: "Martha Stewart",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["kind", "climbed", "ladder", "success", "wrong", "by"],
    words: [
      "she's",
      "the",
      "kind",
      "of",
      "girl",
      "who",
      "climbed",
      "ladder",
      "success",
      "wrong",
      "by",
    ],
    id: "She's the kind of girl who climbed the ladder of success wrong by wrong.",
    author: "Mae West",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "movies", "tend", "play", "badminton", "once"],
    words: [
      "i",
      "don't",
      "care",
      "about",
      "movies",
      "tend",
      "to",
      "play",
      "badminton",
      "once",
      "a",
      "week",
    ],
    id: "I don't care about movies. I tend to play badminton once a week.",
    author: "Edward Witten",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["opera", "main", "as", "sensible", "baseball", "italian"],
    words: [
      "opera",
      "in",
      "english",
      "is",
      "the",
      "main",
      "just",
      "about",
      "as",
      "sensible",
      "baseball",
      "italian",
    ],
    id: "Opera in English is, in the main, just about as sensible as baseball in Italian.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["pains", "sweeter", "far", "than", "all", "pleasures"],
    words: [
      "pains",
      "of",
      "love",
      "be",
      "sweeter",
      "far",
      "than",
      "all",
      "other",
      "pleasures",
      "are",
    ],
    id: "Pains of love be sweeter far than all other pleasures are.",
    author: "John Dryden",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["fight", "experience", "whether", "win", "or", "loss"],
    words: [
      "every",
      "fight",
      "is",
      "a",
      "learning",
      "experience",
      "whether",
      "it's",
      "win",
      "or",
      "loss",
    ],
    id: "Every fight is a learning experience, whether it's a win or a loss.",
    author: "Alexander Gustafsson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "i", "seen", "teaches", "trust", "creator"],
    words: [
      "all",
      "i",
      "have",
      "seen",
      "teaches",
      "me",
      "to",
      "trust",
      "the",
      "creator",
      "for",
      "not",
    ],
    id: "All I have seen teaches me to trust the creator for all I have not seen.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["getting", "old", "fascination", "thing", "older", "get"],
    words: [
      "getting",
      "old",
      "is",
      "a",
      "fascination",
      "thing",
      "the",
      "older",
      "you",
      "get",
      "want",
      "to",
    ],
    id: "Getting old is a fascination thing. The older you get, the older you want to get.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["once", "satisfied", "your", "goal", "real", "happiness"],
    words: [
      "once",
      "you",
      "are",
      "satisfied",
      "with",
      "your",
      "goal",
      "it",
      "is",
      "the",
      "real",
      "happiness",
    ],
    id: "Once you are satisfied with your goal, it is the real happiness.",
    author: "Saina Nehwal",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "interest", "one", "my", "films", "gun"],
    words: ["i", "have", "a", "love", "interest", "in", "every", "one", "of", "my", "films", "gun"],
    id: "I have a love interest in every one of my films: a gun.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["ethics", "equity", "principles", "justice", "change", "calendar"],
    words: [
      "ethics",
      "and",
      "equity",
      "the",
      "principles",
      "of",
      "justice",
      "do",
      "not",
      "change",
      "with",
      "calendar",
    ],
    id: "Ethics and equity and the principles of justice do not change with the calendar.",
    author: "D. H. Lawrence",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["should", "too", "big", "take", "offense", "noble"],
    words: [
      "we",
      "should",
      "be",
      "too",
      "big",
      "to",
      "take",
      "offense",
      "and",
      "noble",
      "give",
      "it",
    ],
    id: "We should be too big to take offense and too noble to give it.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["eagles", "flock", "find", "one", "at", "time"],
    words: [
      "eagles",
      "don't",
      "flock",
      "you",
      "have",
      "to",
      "find",
      "them",
      "one",
      "at",
      "a",
      "time",
    ],
    id: "Eagles don't flock, you have to find them one at a time.",
    author: "Ross Perot",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "lost", "living", "wisdom", "knowledge", "information"],
    words: [
      "where",
      "is",
      "the",
      "life",
      "we",
      "have",
      "lost",
      "in",
      "living",
      "wisdom",
      "knowledge",
      "information",
    ],
    id: "Where is the Life we have lost in living? Where is the wisdom we have lost in knowledge? Where is the knowledge we have lost in information?",
    author: "T. S. Eliot",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["united", "states", "i", "great", "success", "celebrity"],
    words: [
      "in",
      "the",
      "united",
      "states",
      "i",
      "am",
      "a",
      "great",
      "success",
      "but",
      "not",
      "celebrity",
    ],
    id: "In the United States, I am a great success, but I am not a celebrity.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["pray", "our", "eyes", "on", "god", "difficulties"],
    words: [
      "we",
      "have",
      "to",
      "pray",
      "with",
      "our",
      "eyes",
      "on",
      "god",
      "not",
      "the",
      "difficulties",
    ],
    id: "We have to pray with our eyes on God, not on the difficulties.",
    author: "Oswald Chambers",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "neither", "or", "evil", "only", "place"],
    words: [
      "life",
      "is",
      "neither",
      "good",
      "or",
      "evil",
      "but",
      "only",
      "a",
      "place",
      "for",
      "and",
    ],
    id: "Life is neither good or evil, but only a place for good and evil.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "friendship", "football", "massive", "part", "team"],
    words: [
      "i",
      "think",
      "friendship",
      "in",
      "football",
      "is",
      "a",
      "massive",
      "part",
      "of",
      "the",
      "team",
    ],
    id: "I think friendship in football is a massive part of the team.",
    author: "Alex Oxlade-Chamberlain",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["was", "wise", "man", "originated", "idea", "god"],
    words: ["he", "was", "a", "wise", "man", "who", "originated", "the", "idea", "of", "god"],
    id: "He was a wise man who originated the idea of God.",
    author: "Euripides",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["secret", "my", "success", "two", "word", "answer"],
    words: [
      "the",
      "secret",
      "of",
      "my",
      "success",
      "is",
      "a",
      "two",
      "word",
      "answer",
      "know",
      "people",
    ],
    id: "The secret of my success is a two word answer: Know people.",
    author: "Harvey S. Firestone",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["loved", "feel", "sun", "from", "both", "sides"],
    words: [
      "to",
      "love",
      "and",
      "be",
      "loved",
      "is",
      "feel",
      "the",
      "sun",
      "from",
      "both",
      "sides",
    ],
    id: "To love and be loved is to feel the sun from both sides.",
    author: "David Viscott",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["treat", "right", "will", "ninety", "percent", "time"],
    words: [
      "if",
      "you",
      "treat",
      "people",
      "right",
      "they",
      "will",
      "ninety",
      "percent",
      "of",
      "the",
      "time",
    ],
    id: "If you treat people right they will treat you right... ninety percent of the time.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["can't", "one", "bad", "spoil", "bunch", "ones"],
    words: [
      "you",
      "can't",
      "let",
      "one",
      "bad",
      "moment",
      "spoil",
      "a",
      "bunch",
      "of",
      "good",
      "ones",
    ],
    id: "You can't let one bad moment spoil a bunch of good ones.",
    author: "Dale Earnhardt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "big", "family", "guy", "i", "more"],
    words: ["i'm", "a", "big", "family", "guy", "i", "want", "to", "do", "more", "with", "them"],
    id: "I'm a big family guy. I want to do more with them.",
    author: "Rey Mysterio",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "cars", "clothes", "really", "too", "crazy"],
    words: [
      "i",
      "love",
      "cars",
      "and",
      "clothes",
      "but",
      "don't",
      "really",
      "be",
      "going",
      "too",
      "crazy",
    ],
    id: "I love cars and I love clothes, but I don't really be going too crazy.",
    author: "King Von",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["firmest", "friendship", "based", "on", "identity", "dislikes"],
    words: [
      "the",
      "firmest",
      "friendship",
      "is",
      "based",
      "on",
      "an",
      "identity",
      "of",
      "likes",
      "and",
      "dislikes",
    ],
    id: "The firmest friendship is based on an identity of likes and dislikes.",
    author: "Sallust",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["seldom", "refuse", "one", "offers", "right", "way"],
    words: [
      "people",
      "seldom",
      "refuse",
      "help",
      "if",
      "one",
      "offers",
      "it",
      "in",
      "the",
      "right",
      "way",
    ],
    id: "People seldom refuse help, if one offers it in the right way.",
    author: "A. C. Benson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["defeat", "worst", "failures", "tried", "true", "failure"],
    words: [
      "defeat",
      "is",
      "not",
      "the",
      "worst",
      "of",
      "failures",
      "to",
      "have",
      "tried",
      "true",
      "failure",
    ],
    id: "Defeat is not the worst of failures. Not to have tried is the true failure.",
    author: "George Edward Woodberry",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "looking", "fame", "prefer", "normal", "life"],
    words: [
      "i",
      "am",
      "not",
      "looking",
      "for",
      "fame",
      "prefer",
      "to",
      "have",
      "a",
      "normal",
      "life",
    ],
    id: "I am not looking for fame. I prefer to have a normal life.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["tis", "privilege", "friendship", "talk", "nonsense", "respected"],
    words: [
      "tis",
      "the",
      "privilege",
      "of",
      "friendship",
      "to",
      "talk",
      "nonsense",
      "and",
      "have",
      "her",
      "respected",
    ],
    id: "Tis the privilege of friendship to talk nonsense, and have her nonsense respected.",
    author: "Charles Lamb",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["friends", "call", "up", "at", "4", "matter"],
    words: [
      "it's",
      "the",
      "friends",
      "you",
      "can",
      "call",
      "up",
      "at",
      "4",
      "am",
      "that",
      "matter",
    ],
    id: "It's the friends you can call up at 4 a.m. that matter.",
    author: "Marlene Dietrich",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["matter", "how", "dark", "hope", "always", "possible"],
    words: [
      "no",
      "matter",
      "how",
      "dark",
      "the",
      "moment",
      "love",
      "and",
      "hope",
      "are",
      "always",
      "possible",
    ],
    id: "No matter how dark the moment, love and hope are always possible.",
    author: "George Chakiris",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "family", "everything", "come", "first", "i"],
    words: [
      "my",
      "family",
      "are",
      "everything",
      "to",
      "me",
      "they",
      "come",
      "first",
      "in",
      "i",
      "do",
    ],
    id: "My family are everything to me. They come first in everything I do.",
    author: "Nigel Barker",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["family", "moral", "values", "central", "everything", "i"],
    words: [
      "family",
      "and",
      "moral",
      "values",
      "are",
      "so",
      "central",
      "to",
      "everything",
      "that",
      "i",
      "am",
    ],
    id: "Family and moral values are so central to everything that I am.",
    author: "Marian Wright Edelman",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["welfare", "state", "really", "masses", "egos", "elites"],
    words: [
      "the",
      "welfare",
      "state",
      "is",
      "not",
      "really",
      "about",
      "of",
      "masses",
      "it",
      "egos",
      "elites",
    ],
    id: "The welfare state is not really about the welfare of the masses. It is about the egos of the elites.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'd", "stopped", "writing", "years", "ago", "were"],
    words: [
      "i'd",
      "have",
      "stopped",
      "writing",
      "years",
      "ago",
      "if",
      "it",
      "were",
      "for",
      "the",
      "money",
    ],
    id: "I'd have stopped writing years ago if it were for the money.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "financial", "success", "as", "measurement", "my"],
    words: [
      "i",
      "don't",
      "think",
      "about",
      "financial",
      "success",
      "as",
      "the",
      "measurement",
      "of",
      "my",
    ],
    id: "I don't think about financial success as the measurement of my success.",
    author: "Christie Hefner",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["call", "progress", "exchange", "one", "nuisance", "another"],
    words: [
      "what",
      "we",
      "call",
      "progress",
      "is",
      "the",
      "exchange",
      "of",
      "one",
      "nuisance",
      "for",
      "another",
    ],
    id: "What we call progress is the exchange of one nuisance for another nuisance.",
    author: "Havelock Ellis",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["painter", "should", "paint", "sees", "will", "seen"],
    words: [
      "the",
      "painter",
      "should",
      "not",
      "paint",
      "what",
      "he",
      "sees",
      "but",
      "will",
      "be",
      "seen",
    ],
    id: "The painter should not paint what he sees, but what will be seen.",
    author: "Paul Klee",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "i", "change", "my", "life", "one"],
    words: ["only", "i", "can", "change", "my", "life", "no", "one", "do", "it", "for", "me"],
    id: "Only I can change my life. No one can do it for me.",
    author: "Carol Burnett",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "relationship", "my", "imagination", "friend", "almost"],
    words: [
      "i",
      "just",
      "have",
      "a",
      "relationship",
      "with",
      "my",
      "imagination",
      "it's",
      "like",
      "friend",
      "almost",
    ],
    id: "I just have a relationship with my imagination. It's like my friend, almost.",
    author: "Steven Wright",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "state", "arrive", "at", "manner", "traveling"],
    words: [
      "happiness",
      "is",
      "not",
      "a",
      "state",
      "to",
      "arrive",
      "at",
      "but",
      "manner",
      "of",
      "traveling",
    ],
    id: "Happiness is not a state to arrive at, but a manner of traveling.",
    author: "Margaret Lee Runbeck",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["being", "chef", "then", "all", "you've", "got"],
    words: [
      "if",
      "you",
      "love",
      "being",
      "a",
      "chef",
      "then",
      "give",
      "it",
      "all",
      "you've",
      "got",
    ],
    id: "If you love being a chef then give it all you've got!",
    author: "Monica Galetti",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "waste", "time", "use", "experience", "wisely"],
    words: [
      "nothing",
      "is",
      "a",
      "waste",
      "of",
      "time",
      "if",
      "you",
      "use",
      "the",
      "experience",
      "wisely",
    ],
    id: "Nothing is a waste of time if you use the experience wisely.",
    author: "Auguste Rodin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["energy", "will", "spread", "beyond", "all", "boundaries"],
    words: [
      "love",
      "the",
      "moment",
      "and",
      "energy",
      "of",
      "that",
      "will",
      "spread",
      "beyond",
      "all",
      "boundaries",
    ],
    id: "Love the moment and the energy of that moment will spread beyond all boundaries.",
    author: "Corita Kent",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["world", "too", "dangerous", "anything", "truth", "small"],
    words: [
      "the",
      "world",
      "is",
      "too",
      "dangerous",
      "for",
      "anything",
      "but",
      "truth",
      "and",
      "small",
      "love",
    ],
    id: "The world is too dangerous for anything but truth and too small for anything but love.",
    author: "William Sloane Coffin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "wanted", "fly", "would", "given", "tickets"],
    words: ["if", "god", "wanted", "us", "to", "fly", "he", "would", "have", "given", "tickets"],
    id: "If God wanted us to fly, He would have given us tickets.",
    author: "Mel Brooks",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["first", "time", "marry", "second", "third", "companionship"],
    words: [
      "the",
      "first",
      "time",
      "you",
      "marry",
      "for",
      "love",
      "second",
      "money",
      "and",
      "third",
      "companionship",
    ],
    id: "The first time you marry for love, the second for money, and the third for companionship.",
    author: "Jackie Kennedy",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "comes", "wildlife", "photography", "luck", "patience"],
    words: [
      "when",
      "it",
      "comes",
      "to",
      "wildlife",
      "photography",
      "you",
      "need",
      "have",
      "luck",
      "and",
      "patience",
    ],
    id: "When it comes to wildlife photography, you need to have luck and patience.",
    author: "Waheeda Rehman",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["did", "rational", "souls", "would", "able", "believe"],
    words: [
      "if",
      "we",
      "did",
      "not",
      "have",
      "rational",
      "souls",
      "would",
      "be",
      "able",
      "to",
      "believe",
    ],
    id: "If we did not have rational souls, we would not be able to believe.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "whiskey", "always", "did", "why", "never"],
    words: [
      "i",
      "like",
      "whiskey",
      "always",
      "did",
      "and",
      "that",
      "is",
      "why",
      "never",
      "drink",
      "it",
    ],
    id: "I like whiskey. I always did, and that is why I never drink it.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["would", "law", "lovers", "unto", "itself", "higher"],
    words: [
      "who",
      "would",
      "give",
      "a",
      "law",
      "to",
      "lovers",
      "love",
      "is",
      "unto",
      "itself",
      "higher",
    ],
    id: "Who would give a law to lovers? Love is unto itself a higher law.",
    author: "Boethius",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["bring", "forth", "within", "will", "save", "destroy"],
    words: [
      "if",
      "you",
      "bring",
      "forth",
      "what",
      "is",
      "within",
      "will",
      "save",
      "do",
      "not",
      "destroy",
    ],
    id: "If you bring forth what is within you, what you bring forth will save you. If you do not bring forth what is within you, what you do not bring forth will destroy you.",
    author: "Unknown",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "ever", "loved", "anyone", "way", "everyone"],
    words: [
      "no",
      "one",
      "has",
      "ever",
      "loved",
      "anyone",
      "the",
      "way",
      "everyone",
      "wants",
      "to",
      "be",
    ],
    id: "No one has ever loved anyone the way everyone wants to be loved.",
    author: "Mignon McLaughlin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "really", "god", "then", "looks", "after"],
    words: ["if", "there", "really", "is", "a", "god", "then", "he", "looks", "after", "me"],
    id: "If there really is a god, then he really looks after me.",
    author: "Jackie Chan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["looking", "after", "my", "health", "better", "hope"],
    words: [
      "looking",
      "after",
      "my",
      "health",
      "today",
      "gives",
      "me",
      "a",
      "better",
      "hope",
      "for",
      "tomorrow",
    ],
    id: "Looking after my health today gives me a better hope for tomorrow.",
    author: "Anne Wilson Schaef",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "room", "smallest", "cottage", "loving", "pair"],
    words: [
      "there",
      "is",
      "room",
      "in",
      "the",
      "smallest",
      "cottage",
      "for",
      "a",
      "happy",
      "loving",
      "pair",
    ],
    id: "There is room in the smallest cottage for a happy loving pair.",
    author: "Friedrich Schiller",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["make", "world", "round", "makes", "ride", "worthwhile"],
    words: [
      "love",
      "doesn't",
      "make",
      "the",
      "world",
      "go",
      "round",
      "is",
      "what",
      "makes",
      "ride",
      "worthwhile",
    ],
    id: "Love doesn't make the world go 'round. Love is what makes the ride worthwhile.",
    author: "Franklin P. Jones",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["wealth", "tool", "freedom", "pursuit", "way", "slavery"],
    words: [
      "wealth",
      "is",
      "a",
      "tool",
      "of",
      "freedom",
      "but",
      "the",
      "pursuit",
      "way",
      "to",
      "slavery",
    ],
    id: "Wealth is a tool of freedom, but the pursuit of wealth is the way to slavery.",
    author: "Frank Herbert",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["happens", "minds", "souls", "databases", "multiple-choice", "tests"],
    words: [
      "learning",
      "happens",
      "in",
      "the",
      "minds",
      "and",
      "souls",
      "not",
      "databases",
      "of",
      "multiple-choice",
      "tests",
    ],
    id: "Learning happens in the minds and souls, not in the databases of multiple-choice tests.",
    author: "Ken Robinson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "right", "live", "my", "life", "way"],
    words: ["i", "think", "have", "a", "right", "to", "live", "my", "life", "the", "way", "like"],
    id: "I think I have a right to live my life the way I like.",
    author: "Malala Yousafzai",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "dress", "up", "picture", "my", "face"],
    words: [
      "i",
      "don't",
      "want",
      "to",
      "dress",
      "up",
      "a",
      "picture",
      "with",
      "just",
      "my",
      "face",
    ],
    id: "I don't want to dress up a picture with just my face.",
    author: "Grace Kelly",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["planned", "parenthood", "organization", "provide", "quality", "health"],
    words: [
      "planned",
      "parenthood",
      "is",
      "an",
      "organization",
      "that",
      "does",
      "not",
      "provide",
      "quality",
      "health",
      "care",
    ],
    id: "Planned Parenthood is an organization that does not provide quality health care.",
    author: "Abby Johnson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "hilt", "situation", "believe", "will", "god"],
    words: [
      "live",
      "to",
      "the",
      "hilt",
      "every",
      "situation",
      "you",
      "believe",
      "be",
      "will",
      "of",
      "god",
    ],
    id: "Live to the hilt every situation you believe to be the will of God.",
    author: "Jim Elliot",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["believe", "god", "then", "anything", "gang", "mentality"],
    words: [
      "if",
      "you",
      "can",
      "believe",
      "in",
      "god",
      "then",
      "anything",
      "it's",
      "a",
      "gang",
      "mentality",
    ],
    id: "If you can believe in God, then you can believe in anything. It's a gang mentality.",
    author: "Greg Graffin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "always", "exclusively", "one", "thinks", "differently"],
    words: [
      "freedom",
      "is",
      "always",
      "and",
      "exclusively",
      "for",
      "the",
      "one",
      "who",
      "thinks",
      "differently",
    ],
    id: "Freedom is always and exclusively freedom for the one who thinks differently.",
    author: "Rosa Luxemburg",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["would", "strength", "patience", "welcome", "company", "trees"],
    words: [
      "if",
      "you",
      "would",
      "know",
      "strength",
      "and",
      "patience",
      "welcome",
      "the",
      "company",
      "of",
      "trees",
    ],
    id: "If you would know strength and patience, welcome the company of trees.",
    author: "Hal Borland",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["great", "city", "which", "greatest", "men", "women"],
    words: [
      "a",
      "great",
      "city",
      "is",
      "that",
      "which",
      "has",
      "the",
      "greatest",
      "men",
      "and",
      "women",
    ],
    id: "A great city is that which has the greatest men and women.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["take", "man", "out", "ireland", "can't", "irishness"],
    words: [
      "you",
      "can",
      "take",
      "a",
      "man",
      "out",
      "of",
      "ireland",
      "but",
      "can't",
      "the",
      "irishness",
    ],
    id: "You can take a man out of Ireland, but you can't take the Irishness out of the man.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "way", "demonstrating", "most", "stubborn", "intelligent"],
    words: [
      "time",
      "has",
      "a",
      "way",
      "of",
      "demonstrating",
      "that",
      "the",
      "most",
      "stubborn",
      "are",
      "intelligent",
    ],
    id: "Time has a way of demonstrating that the most stubborn are the most intelligent.",
    author: "Yevgeny Yevtushenko",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["parties", "organised", "happiness", "accidental", "can't", "legislate"],
    words: [
      "parties",
      "are",
      "organised",
      "happiness",
      "but",
      "is",
      "accidental",
      "you",
      "can't",
      "legislate",
      "for",
      "it",
    ],
    id: "Parties are organised happiness but happiness is accidental. You can't legislate for it.",
    author: "Dennis Skinner",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["effective", "communication", "20%", "80%", "how", "feel"],
    words: [
      "effective",
      "communication",
      "is",
      "20%",
      "what",
      "you",
      "know",
      "and",
      "80%",
      "how",
      "feel",
      "about",
    ],
    id: "Effective communication is 20% what you know and 80% how you feel about what you know.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "never", "failing", "rising", "everytime", "fall"],
    words: [
      "success",
      "is",
      "not",
      "in",
      "never",
      "failing",
      "but",
      "rising",
      "everytime",
      "you",
      "fall",
    ],
    id: "Success is not in never failing, but rising everytime you fall!",
    author: "Jonathan Taylor Thomas",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["would", "life", "had", "courage", "attempt", "anything"],
    words: [
      "what",
      "would",
      "life",
      "be",
      "if",
      "we",
      "had",
      "no",
      "courage",
      "to",
      "attempt",
      "anything",
    ],
    id: "What would life be if we had no courage to attempt anything?",
    author: "Vincent Van Gogh",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["greatest", "gift", "life", "friendship", "i", "received"],
    words: [
      "the",
      "greatest",
      "gift",
      "of",
      "life",
      "is",
      "friendship",
      "and",
      "i",
      "have",
      "received",
      "it",
    ],
    id: "The greatest gift of life is friendship, and I have received it.",
    author: "Hubert H. Humphrey",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "depend", "on", "how", "much", "enjoy"],
    words: [
      "happiness",
      "doesn't",
      "depend",
      "on",
      "how",
      "much",
      "you",
      "have",
      "to",
      "enjoy",
      "but",
      "what",
    ],
    id: "Happiness doesn't depend on how much you have to enjoy, but how much you enjoy what you have.",
    author: "Tom Wilson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["won't", "time", "always", "angry", "or", "complaining"],
    words: [
      "people",
      "won't",
      "have",
      "time",
      "for",
      "you",
      "if",
      "are",
      "always",
      "angry",
      "or",
      "complaining",
    ],
    id: "People won't have time for you if you are always angry or complaining.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["days", "get", "your", "head", "bad", "heart"],
    words: [
      "don't",
      "let",
      "the",
      "good",
      "days",
      "get",
      "to",
      "your",
      "head",
      "and",
      "bad",
      "heart",
    ],
    id: "Don't let the good days get to your head, and don't let the bad days get to your heart.",
    author: "Caroline Ghosn",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["variety's", "spice", "life", "all", "its", "flavor"],
    words: [
      "variety's",
      "the",
      "very",
      "spice",
      "of",
      "life",
      "that",
      "gives",
      "it",
      "all",
      "its",
      "flavor",
    ],
    id: "Variety's the very spice of life, That gives it all its flavor.",
    author: "William Cowper",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "live", "each", "fullest", "enjoy", "life"],
    words: [
      "i",
      "just",
      "want",
      "to",
      "live",
      "each",
      "moment",
      "the",
      "fullest",
      "and",
      "enjoy",
      "life",
    ],
    id: "I just want to live each moment to the fullest. And enjoy life.",
    author: "Kadeena Cox",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["health", "real", "wealth", "pieces", "gold", "silver"],
    words: [
      "it",
      "is",
      "health",
      "that",
      "real",
      "wealth",
      "and",
      "not",
      "pieces",
      "of",
      "gold",
      "silver",
    ],
    id: "It is health that is real wealth and not pieces of gold and silver.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["past", "i", "nothing", "nor", "future", "live"],
    words: [
      "with",
      "the",
      "past",
      "i",
      "have",
      "nothing",
      "to",
      "do",
      "nor",
      "future",
      "live",
      "now",
    ],
    id: "With the past, I have nothing to do; nor with the future. I live now.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "brave", "disturbed", "by", "something", "unexpected"],
    words: [
      "no",
      "one",
      "is",
      "so",
      "brave",
      "that",
      "he",
      "not",
      "disturbed",
      "by",
      "something",
      "unexpected",
    ],
    id: "No one is so brave that he is not disturbed by something unexpected.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["render", "god", "owe", "recognize", "obligations", "under"],
    words: [
      "render",
      "to",
      "god",
      "what",
      "you",
      "owe",
      "him",
      "recognize",
      "the",
      "obligations",
      "are",
      "under",
    ],
    id: "Render to God what you owe him; recognize the obligations you are under to him.",
    author: "Louis XIV",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["call", "global", "warming", "climate", "change", "difference"],
    words: [
      "some",
      "people",
      "call",
      "it",
      "global",
      "warming",
      "climate",
      "change",
      "what",
      "is",
      "the",
      "difference",
    ],
    id: "Some people call it global warming; some people call it climate change. What is the difference?",
    author: "Frank Luntz",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "hearts", "troubled", "trust", "god", "also"],
    words: [
      "do",
      "not",
      "let",
      "your",
      "hearts",
      "be",
      "troubled",
      "trust",
      "in",
      "god",
      "also",
      "me",
    ],
    id: "Do not let your hearts be troubled. Trust in God; trust also in me.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledgable", "men's", "knowledge", "cannot", "wise", "wisdom"],
    words: [
      "we",
      "can",
      "be",
      "knowledgable",
      "with",
      "other",
      "men's",
      "knowledge",
      "but",
      "cannot",
      "wise",
      "wisdom",
    ],
    id: "We can be knowledgable with other men's knowledge but we cannot be wise with other men's wisdom.",
    author: "Michel de Montaigne",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "regret", "yesterday", "life", "make", "your"],
    words: [
      "never",
      "regret",
      "yesterday",
      "life",
      "is",
      "in",
      "you",
      "today",
      "and",
      "make",
      "your",
      "tomorrow",
    ],
    id: "Never regret yesterday. Life is in you today and you make your tomorrow.",
    author: "L. Ron Hubbard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["none", "freedom", "heartily", "men", "rest", "licence"],
    words: [
      "none",
      "can",
      "love",
      "freedom",
      "heartily",
      "but",
      "good",
      "men",
      "the",
      "rest",
      "not",
      "licence",
    ],
    id: "None can love freedom heartily, but good men; the rest love not freedom, but licence.",
    author: "John Milton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "take", "my", "enjoy", "food", "living"],
    words: [
      "i",
      "take",
      "care",
      "of",
      "my",
      "flowers",
      "and",
      "cats",
      "enjoy",
      "food",
      "that's",
      "living",
    ],
    id: "I take care of my flowers and my cats. And enjoy food. And that's living.",
    author: "Ursula Andress",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["lies", "behind", "front", "pales", "comparison", "inside"],
    words: [
      "what",
      "lies",
      "behind",
      "you",
      "and",
      "in",
      "front",
      "of",
      "pales",
      "comparison",
      "to",
      "inside",
    ],
    id: "What lies behind you and what lies in front of you, pales in comparison to what lies inside of you.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "reward", "get", "living", "highest", "right"],
    words: [
      "happiness",
      "is",
      "the",
      "reward",
      "we",
      "get",
      "for",
      "living",
      "to",
      "highest",
      "right",
      "know",
    ],
    id: "Happiness is the reward we get for living to the highest right we know.",
    author: "Richard Bach",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["pain", "death", "part", "life", "reject", "itself"],
    words: [
      "pain",
      "and",
      "death",
      "are",
      "part",
      "of",
      "life",
      "to",
      "reject",
      "them",
      "is",
      "itself",
    ],
    id: "Pain and death are part of life. To reject them is to reject life itself.",
    author: "Havelock Ellis",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "10%", "happens", "90%", "how", "react"],
    words: [
      "life",
      "is",
      "10%",
      "what",
      "happens",
      "to",
      "you",
      "and",
      "90%",
      "how",
      "react",
      "it",
    ],
    id: "Life is 10% what happens to you and 90% how you react to it.",
    author: "Charles R. Swindoll",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["sum", "wisdom", "time", "never", "lost", "devoted"],
    words: [
      "the",
      "sum",
      "of",
      "wisdom",
      "is",
      "that",
      "time",
      "never",
      "lost",
      "devoted",
      "to",
      "work",
    ],
    id: "The sum of wisdom is that time is never lost that is devoted to work.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "fear", "boredom", "comes", "taking", "chances"],
    words: [
      "i",
      "fear",
      "the",
      "boredom",
      "that",
      "comes",
      "with",
      "not",
      "learning",
      "and",
      "taking",
      "chances",
    ],
    id: "I fear the boredom that comes with not learning and not taking chances.",
    author: "Robert Fulghum",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "important", "keep", "your", "brain", "active"],
    words: [
      "i",
      "think",
      "it's",
      "so",
      "important",
      "to",
      "keep",
      "learning",
      "and",
      "your",
      "brain",
      "active",
    ],
    id: "I think it's so important to keep learning and keep your brain active.",
    author: "Beverley Mitchell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["great", "able", "express", "friendship", "between", "men"],
    words: [
      "it's",
      "so",
      "great",
      "to",
      "be",
      "able",
      "express",
      "the",
      "friendship",
      "between",
      "men",
    ],
    id: "It's so great to be able to express the friendship between men.",
    author: "Park Hyung-sik",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["may", "part", "friend", "rebuke", "friend's", "folly"],
    words: [
      "it",
      "may",
      "be",
      "the",
      "part",
      "of",
      "a",
      "friend",
      "to",
      "rebuke",
      "friend's",
      "folly",
    ],
    id: "It may be the part of a friend to rebuke a friend's folly.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["milk", "babies", "when", "grow", "up", "beer"],
    words: [
      "milk",
      "is",
      "for",
      "babies",
      "when",
      "you",
      "grow",
      "up",
      "have",
      "to",
      "drink",
      "beer",
    ],
    id: "Milk is for babies. When you grow up you have to drink beer.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["sometimes", "play", "long", "time", "able", "yourself"],
    words: [
      "sometimes",
      "you",
      "have",
      "to",
      "play",
      "a",
      "long",
      "time",
      "be",
      "able",
      "like",
      "yourself",
    ],
    id: "Sometimes you have to play a long time to be able to play like yourself.",
    author: "Miles Davis",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["wise", "only", "because", "fool", "fools", "understand"],
    words: [
      "the",
      "wise",
      "are",
      "only",
      "because",
      "they",
      "love",
      "fool",
      "fools",
      "think",
      "can",
      "understand",
    ],
    id: "The wise are wise only because they love. The fool are fools only because they think they can understand love.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "i", "enjoy", "forbidden", "life", "too"],
    words: [
      "i'm",
      "just",
      "like",
      "you",
      "i",
      "enjoy",
      "the",
      "forbidden",
      "fruits",
      "in",
      "life",
      "too",
    ],
    id: "I'm just like you. I enjoy the forbidden fruits in life, too.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "can't", "get", "at", "least", "more"],
    words: ["if", "i", "can't", "get", "the", "girl", "at", "least", "give", "me", "more", "money"],
    id: "If I can't get the girl, at least give me more money.",
    author: "Alan Alda",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "create", "than", "creating", "essence", "life"],
    words: [
      "it",
      "is",
      "better",
      "to",
      "create",
      "than",
      "learn",
      "creating",
      "the",
      "essence",
      "of",
      "life",
    ],
    id: "It is better to create than to learn! Creating is the essence of life.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["matter", "how", "much", "cricket", "played", "always"],
    words: [
      "no",
      "matter",
      "how",
      "much",
      "cricket",
      "you",
      "have",
      "played",
      "are",
      "always",
      "learning",
    ],
    id: "No matter how much cricket you have played you are always learning.",
    author: "Alastair Cook",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "quiescent", "except", "nascent", "moments", "humility"],
    words: [
      "true",
      "love",
      "is",
      "quiescent",
      "except",
      "in",
      "the",
      "nascent",
      "moments",
      "of",
      "humility",
    ],
    id: "True love is quiescent, except in the nascent moments of true humility.",
    author: "Bryant H. McGill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["something", "true", "amount", "wishful", "will", "change"],
    words: [
      "if",
      "something",
      "is",
      "true",
      "no",
      "amount",
      "of",
      "wishful",
      "thinking",
      "will",
      "change",
      "it",
    ],
    id: "If something is true, no amount of wishful thinking will change it.",
    author: "Richard Dawkins",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "parent", "till", "become", "parents", "ourselves"],
    words: [
      "we",
      "never",
      "know",
      "the",
      "love",
      "of",
      "a",
      "parent",
      "till",
      "become",
      "parents",
      "ourselves",
    ],
    id: "We never know the love of a parent till we become parents ourselves.",
    author: "Henry Ward Beecher",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "best", "keep", "-", "i", "believe"],
    words: [
      "do",
      "your",
      "best",
      "and",
      "keep",
      "learning",
      "-",
      "that's",
      "what",
      "i",
      "believe",
      "in",
    ],
    id: "Do your best and keep learning - that's what I believe in.",
    author: "Suriya",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["luck", "one", "thing", "out", "sort", "happenstance"],
    words: [
      "it's",
      "luck",
      "that",
      "one",
      "thing",
      "works",
      "out",
      "and",
      "doesn't",
      "sort",
      "of",
      "happenstance",
    ],
    id: "It's luck that one thing works out and one doesn't, it's sort of happenstance.",
    author: "Richard Linklater",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "value", "unless", "put", "into", "practice"],
    words: [
      "knowledge",
      "is",
      "of",
      "no",
      "value",
      "unless",
      "you",
      "put",
      "it",
      "into",
      "practice",
    ],
    id: "Knowledge is of no value unless you put it into practice.",
    author: "Anton Chekhov",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["rich", "without", "wisdom", "sheep", "golden", "fleeces"],
    words: [
      "rich",
      "people",
      "without",
      "wisdom",
      "and",
      "learning",
      "are",
      "but",
      "sheep",
      "with",
      "golden",
      "fleeces",
    ],
    id: "Rich people without wisdom and learning are but sheep with golden fleeces.",
    author: "Solon",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["took", "20", "years", "making", "movies", "how"],
    words: [
      "it",
      "took",
      "me",
      "20",
      "years",
      "of",
      "making",
      "movies",
      "to",
      "learn",
      "how",
      "do",
    ],
    id: "It took me 20 years of making movies to learn how to do it.",
    author: "Chevy Chase",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["easy", "self-contentment", "might", "cheap", "source", "happiness"],
    words: [
      "if",
      "you",
      "have",
      "easy",
      "self-contentment",
      "might",
      "a",
      "very",
      "cheap",
      "source",
      "of",
      "happiness",
    ],
    id: "If you have easy self-contentment, you might have a very, very cheap source of happiness.",
    author: "Leon Kass",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["possible", "fly", "without", "motors", "knowledge", "skill"],
    words: [
      "it",
      "is",
      "possible",
      "to",
      "fly",
      "without",
      "motors",
      "but",
      "not",
      "knowledge",
      "and",
      "skill",
    ],
    id: "It is possible to fly without motors, but not without knowledge and skill.",
    author: "Wilbur Wright",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "talk", "trash", "often", "when", "jugular"],
    words: [
      "i",
      "don't",
      "talk",
      "trash",
      "often",
      "but",
      "when",
      "do",
      "go",
      "for",
      "the",
      "jugular",
    ],
    id: "I don't talk trash often, but when I do, I go for the jugular.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["failure", "from", "failures", "itself", "cannot", "celebrated"],
    words: [
      "it's",
      "not",
      "about",
      "the",
      "failure",
      "learning",
      "from",
      "failures",
      "itself",
      "cannot",
      "be",
      "celebrated",
    ],
    id: "It's not about the failure, it's about learning from the failures. Failure itself cannot be celebrated.",
    author: "Satya Nadella",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["friendship", "sentiment", "giving", "one's", "words", "important"],
    words: [
      "friendship",
      "and",
      "sentiment",
      "the",
      "giving",
      "of",
      "one's",
      "words",
      "are",
      "very",
      "important",
    ],
    id: "Friendship and sentiment and the giving of one's words are very important.",
    author: "Michael Cimino",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["right", "alone", "indeed", "beginning", "all", "freedoms"],
    words: [
      "the",
      "right",
      "to",
      "be",
      "let",
      "alone",
      "is",
      "indeed",
      "beginning",
      "of",
      "all",
      "freedoms",
    ],
    id: "The right to be let alone is indeed the beginning of all freedoms.",
    author: "William O. Douglas",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["by", "doing", "only", "way", "i", "how"],
    words: [
      "learning",
      "by",
      "doing",
      "is",
      "the",
      "only",
      "way",
      "i",
      "know",
      "how",
      "to",
      "learn",
    ],
    id: "Learning by doing is the only way I know how to learn.",
    author: "Tony Fadell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "escapism", "essentially", "movies", "vicarious", "thrill"],
    words: [
      "it's",
      "all",
      "about",
      "escapism",
      "that's",
      "essentially",
      "what",
      "movies",
      "are",
      "a",
      "vicarious",
      "thrill",
    ],
    id: "It's all about escapism. That's essentially what all movies are about. It's a vicarious thrill.",
    author: "Todd Phillips",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["noble", "person", "attracts", "how", "hold", "on"],
    words: [
      "a",
      "noble",
      "person",
      "attracts",
      "people",
      "and",
      "knows",
      "how",
      "to",
      "hold",
      "on",
      "them",
    ],
    id: "A noble person attracts noble people, and knows how to hold on to them.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "from", "game", "trying", "make", "difference"],
    words: [
      "i'm",
      "learning",
      "from",
      "every",
      "game",
      "and",
      "trying",
      "to",
      "make",
      "a",
      "difference",
    ],
    id: "I'm learning from every game and trying to make a difference.",
    author: "Rishabh Pant",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["word", "dictionary", "were", "misspelled", "how", "would"],
    words: [
      "if",
      "a",
      "word",
      "in",
      "the",
      "dictionary",
      "were",
      "misspelled",
      "how",
      "would",
      "we",
      "know",
    ],
    id: "If a word in the dictionary were misspelled, how would we know?",
    author: "Steven Wright",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "kill", "man", "costs", "nothing", "polite"],
    words: [
      "when",
      "you",
      "have",
      "to",
      "kill",
      "a",
      "man",
      "it",
      "costs",
      "nothing",
      "be",
      "polite",
    ],
    id: "When you have to kill a man, it costs nothing to be polite.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["landlords", "all", "men", "reap", "never", "sowed"],
    words: [
      "landlords",
      "like",
      "all",
      "other",
      "men",
      "love",
      "to",
      "reap",
      "where",
      "they",
      "never",
      "sowed",
    ],
    id: "Landlords, like all other men, love to reap where they never sowed.",
    author: "Karl Marx",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "noble", "splendid", "ashes", "pompous", "grave"],
    words: [
      "man",
      "is",
      "a",
      "noble",
      "animal",
      "splendid",
      "in",
      "ashes",
      "and",
      "pompous",
      "the",
      "grave",
    ],
    id: "Man is a noble animal, splendid in ashes, and pompous in the grave.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["advertisements", "contain", "only", "truths", "relied", "on"],
    words: [
      "advertisements",
      "contain",
      "the",
      "only",
      "truths",
      "to",
      "be",
      "relied",
      "on",
      "in",
      "a",
      "newspaper",
    ],
    id: "Advertisements contain the only truths to be relied on in a newspaper.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "found", "by", "deeply", "experiencing", "exact"],
    words: [
      "happiness",
      "is",
      "found",
      "by",
      "deeply",
      "experiencing",
      "the",
      "exact",
      "moment",
      "we",
      "are",
      "in",
    ],
    id: "Happiness is found by deeply experiencing the exact moment we are in.",
    author: "Tim Ryan",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["girls", "played", "rumpled", "little", "too", "sometimes"],
    words: [
      "girls",
      "like",
      "to",
      "be",
      "played",
      "with",
      "and",
      "rumpled",
      "a",
      "little",
      "too",
      "sometimes",
    ],
    id: "Girls like to be played with, and rumpled a little too, sometimes.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i've", "made", "over", "20", "movies", "5"],
    words: ["i've", "made", "over", "20", "movies", "and", "5", "of", "them", "are", "good"],
    id: "I've made over 20 movies, and 5 of them are good.",
    author: "Tom Hanks",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["things", "right", "once", "while", "all", "time"],
    words: [
      "you",
      "don't",
      "do",
      "things",
      "right",
      "once",
      "in",
      "a",
      "while",
      "all",
      "the",
      "time",
    ],
    id: "You don't do things right once in a while. You do things right all the time.",
    author: "Vince Lombardi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["jesus", "was", "here", "would", "show", "any"],
    words: [
      "if",
      "jesus",
      "was",
      "here",
      "do",
      "you",
      "think",
      "would",
      "show",
      "me",
      "any",
      "love",
    ],
    id: "If Jesus was here, do you think Jesus would show me any love? Do you think Jesus would love me?",
    author: "Mike Tyson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["democracy", "when", "indigent", "men", "property", "rulers"],
    words: [
      "democracy",
      "is",
      "when",
      "the",
      "indigent",
      "and",
      "not",
      "men",
      "of",
      "property",
      "are",
      "rulers",
    ],
    id: "Democracy is when the indigent, and not the men of property, are the rulers.",
    author: "Aristotle",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "proud", "much", "wisdom", "humble", "more"],
    words: [
      "knowledge",
      "is",
      "proud",
      "that",
      "it",
      "knows",
      "so",
      "much",
      "wisdom",
      "humble",
      "no",
      "more",
    ],
    id: "Knowledge is proud that it knows so much; wisdom is humble that it knows no more.",
    author: "William Cowper",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["whenever", "i", "theaters", "em", "right", "stand-up"],
    words: [
      "whenever",
      "i",
      "do",
      "theaters",
      "don't",
      "like",
      "em",
      "think",
      "they're",
      "right",
      "for",
      "stand-up",
    ],
    id: "Whenever I do theaters, I don't like 'em. I don't think they're right for stand-up.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["friends", "confront", "each", "sometimes", "friendship", "lasts"],
    words: [
      "friends",
      "confront",
      "each",
      "other",
      "sometimes",
      "and",
      "the",
      "friendship",
      "lasts",
      "it",
      "doesn't",
    ],
    id: "Friends confront each other sometimes, and sometimes the friendship lasts, and sometimes it doesn't.",
    author: "Brooke Elliott",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "one", "many", "thousands", "called", "evangelist"],
    words: [
      "i",
      "am",
      "just",
      "one",
      "of",
      "many",
      "thousands",
      "called",
      "to",
      "be",
      "an",
      "evangelist",
    ],
    id: "I am just one of many thousands called to be an evangelist.",
    author: "Billy Graham",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["had", "your", "life", "live", "over", "overseas"],
    words: [
      "if",
      "you",
      "had",
      "your",
      "life",
      "to",
      "live",
      "over",
      "again",
      "do",
      "it",
      "overseas",
    ],
    id: "If you had your life to live over again, do it overseas.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "basic", "question", "best", "shall", "decide"],
    words: [
      "the",
      "most",
      "basic",
      "question",
      "is",
      "not",
      "what",
      "best",
      "but",
      "who",
      "shall",
      "decide",
    ],
    id: "The most basic question is not what is best, but who shall decide what is best.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["friendship", "personal", "issue", "should", "remain", "way"],
    words: [
      "friendship",
      "is",
      "a",
      "personal",
      "issue",
      "and",
      "it",
      "should",
      "remain",
      "that",
      "way",
    ],
    id: "Friendship is a personal issue, and it should remain that way.",
    author: "Prabhas",
    grammar: null,
    word_count: 11,
    all_intersection_count: 5,
    new_words_count: 6,
  },
  {
    row_new_words: ["nowadays", "matter", "chance", "matrimony", "divorce", "course"],
    words: [
      "nowadays",
      "love",
      "is",
      "a",
      "matter",
      "of",
      "chance",
      "matrimony",
      "money",
      "and",
      "divorce",
      "course",
    ],
    id: "Nowadays love is a matter of chance, matrimony a matter of money and divorce a matter of course.",
    author: "Helen Rowland",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "recovering", "perfectionist", "one", "at", "time"],
    words: [
      "i'm",
      "like",
      "a",
      "recovering",
      "perfectionist",
      "for",
      "me",
      "it's",
      "one",
      "day",
      "at",
      "time",
    ],
    id: "I'm like a recovering perfectionist. For me it's one day at a time.",
    author: "Brene Brown",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["little", "many", "words", "great", "deal", "few"],
    words: [
      "do",
      "not",
      "say",
      "a",
      "little",
      "in",
      "many",
      "words",
      "but",
      "great",
      "deal",
      "few",
    ],
    id: "Do not say a little in many words but a great deal in a few.",
    author: "Pythagoras",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "reason", "from", "on", "actualities", "possibilities"],
    words: [
      "we",
      "can",
      "only",
      "reason",
      "from",
      "what",
      "is",
      "on",
      "actualities",
      "but",
      "not",
      "possibilities",
    ],
    id: "We can only reason from what is; we can reason on actualities, but not on possibilities.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["tis", "enough", "feeble", "up", "support", "after"],
    words: [
      "tis",
      "not",
      "enough",
      "to",
      "help",
      "the",
      "feeble",
      "up",
      "but",
      "support",
      "them",
      "after",
    ],
    id: "'Tis not enough to help the feeble up, but to support them after.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "lives", "sum", "total", "choices", "made"],
    words: [
      "our",
      "lives",
      "are",
      "a",
      "sum",
      "total",
      "of",
      "the",
      "choices",
      "we",
      "have",
      "made",
    ],
    id: "Our lives are a sum total of the choices we have made.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "means", "nothing", "i", "meaning", "purpose"],
    words: [
      "happiness",
      "means",
      "nothing",
      "to",
      "me",
      "i",
      "just",
      "want",
      "have",
      "meaning",
      "and",
      "purpose",
    ],
    id: "Happiness means nothing to me. I just want to have meaning and purpose.",
    author: "Jon Krakauer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "expression", "without", "offend", "ceases", "exist"],
    words: [
      "what",
      "is",
      "freedom",
      "of",
      "expression",
      "without",
      "the",
      "to",
      "offend",
      "it",
      "ceases",
      "exist",
    ],
    id: "What is freedom of expression? Without the freedom to offend, it ceases to exist.",
    author: "Salman Rushdie",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["then", "stagnant", "evolving", "business", "isn't", "progressing"],
    words: [
      "if",
      "you're",
      "not",
      "learning",
      "then",
      "stagnant",
      "evolving",
      "and",
      "the",
      "business",
      "isn't",
      "progressing",
    ],
    id: "If you're not learning, then you're stagnant. If you're stagnant, then you're not evolving and the business isn't progressing.",
    author: "Seth Rollins",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["winning", "-", "most", "important", "as", "simple"],
    words: [
      "winning",
      "-",
      "that's",
      "the",
      "most",
      "important",
      "to",
      "me",
      "it's",
      "as",
      "simple",
      "that",
    ],
    id: "Winning - that's the most important to me. It's as simple as that.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "hoping", "understand", "islam", "peace", "violence"],
    words: [
      "i'm",
      "just",
      "hoping",
      "that",
      "people",
      "understand",
      "islam",
      "is",
      "peace",
      "and",
      "not",
      "violence",
    ],
    id: "I'm just hoping that people understand that Islam is peace and not violence.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "making", "should", "greater", "than", "spending"],
    words: [
      "the",
      "time",
      "making",
      "money",
      "should",
      "be",
      "greater",
      "than",
      "that",
      "you",
      "are",
      "spending",
    ],
    id: "The time making money should be greater than the time that you are spending money.",
    author: "Sophia Amoruso",
    grammar: null,
    word_count: 12,
    all_intersection_count: 6,
    new_words_count: 6,
  },
  {
    row_new_words: ["fame", "fickle", "food", "upon", "shifting", "plate"],
    words: ["fame", "is", "a", "fickle", "food", "upon", "shifting", "plate"],
    id: "Fame is a fickle food upon a shifting plate.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["should", "seek", "greatest", "value", "our", "action"],
    words: ["we", "should", "seek", "the", "greatest", "value", "of", "our", "action"],
    id: "We should seek the greatest value of our action.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["words", "my", "book", "nothing", "drift", "everything"],
    words: ["the", "words", "of", "my", "book", "nothing", "drift", "it", "everything"],
    id: "The words of my book nothing, the drift of it everything.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "such", "thing", "as", "lovers", "oath"],
    words: ["there", "is", "no", "such", "thing", "as", "a", "lovers", "oath"],
    id: "There is no such thing as a lovers' oath.",
    author: "Plato",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["nature", "incarnation", "thought", "world", "mind", "precipitated"],
    words: ["nature", "is", "the", "incarnation", "of", "thought", "world", "mind", "precipitated"],
    id: "Nature is the incarnation of thought. The world is the mind precipitated.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "body", "space", "within", "cannot", "die"],
    words: ["your", "body", "has", "space", "within", "you", "that", "cannot", "die"],
    id: "Your body has space within you that cannot die.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "make", "more", "generals", "horses", "cost"],
    words: ["i", "can", "make", "more", "generals", "but", "horses", "cost", "money"],
    id: "I can make more generals, but horses cost money.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["balk", "enemy's", "power", "force", "reveal", "himself"],
    words: ["balk", "the", "enemy's", "power", "force", "him", "to", "reveal", "himself"],
    id: "Balk the enemy's power; force him to reveal himself.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "theory", "evolution", "darwin", "was", "adopted"],
    words: ["my", "theory", "of", "evolution", "is", "that", "darwin", "was", "adopted"],
    id: "My theory of evolution is that Darwin was adopted.",
    author: "Steven Wright",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["disneyland", "star", "everything", "else", "supporting", "role"],
    words: ["disneyland", "is", "the", "star", "everything", "else", "in", "supporting", "role"],
    id: "Disneyland is the star, everything else is in the supporting role.",
    author: "Walt Disney",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["cause", "effect", "two", "sides", "one", "fact"],
    words: ["cause", "and", "effect", "are", "two", "sides", "of", "one", "fact"],
    id: "Cause and effect are two sides of one fact.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["shoot", "at", "mimes", "should", "use", "silencer"],
    words: ["if", "you", "shoot", "at", "mimes", "should", "use", "a", "silencer"],
    id: "If you shoot at mimes, should you use a silencer?",
    author: "Steven Wright",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["how", "treat", "their", "karma", "react", "yours"],
    words: ["how", "people", "treat", "you", "is", "their", "karma", "react", "yours"],
    id: "How people treat you is their karma; how you react is yours.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "tired", "being", "treated", "second-class", "citizen"],
    words: ["i'm", "tired", "of", "being", "treated", "like", "a", "second-class", "citizen"],
    id: "I'm tired of being treated like a second-class citizen.",
    author: "Rosa Parks",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["hope", "such", "bait", "covers", "any", "hook"],
    words: ["hope", "is", "such", "a", "bait", "it", "covers", "any", "hook"],
    id: "Hope is such a bait, it covers any hook.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["successful", "lawsuit", "one", "worn", "by", "policeman"],
    words: ["a", "successful", "lawsuit", "is", "the", "one", "worn", "by", "policeman"],
    id: "A successful lawsuit is the one worn by a policeman.",
    author: "Robert Frost",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["society", "fits", "comfortably", "enough", "call", "freedom"],
    words: ["if", "society", "fits", "you", "comfortably", "enough", "call", "it", "freedom"],
    id: "If society fits you comfortably enough, you call it freedom.",
    author: "Robert Frost",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "friend", "wonderful", "company", "enemy", "mediocre"],
    words: ["time", "is", "the", "friend", "of", "wonderful", "company", "enemy", "mediocre"],
    id: "Time is the friend of the wonderful company, the enemy of the mediocre.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "varieties", "virtues", "liberalism", "most", "beloved"],
    words: ["of", "all", "the", "varieties", "virtues", "liberalism", "is", "most", "beloved"],
    id: "Of all the varieties of virtues, liberalism is the most beloved.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "-", "walk", "free", "own", "superior"],
    words: ["freedom", "-", "to", "walk", "free", "and", "own", "no", "superior"],
    id: "Freedom - to walk free and own no superior.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["reject", "your", "sense", "injury", "itself", "disappears"],
    words: ["reject", "your", "sense", "of", "injury", "and", "the", "itself", "disappears"],
    id: "Reject your sense of injury and the injury itself disappears.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["peace", "comes", "from", "within", "seek", "without"],
    words: ["peace", "comes", "from", "within", "do", "not", "seek", "it", "without"],
    id: "Peace comes from within. Do not seek it without.",
    author: "Buddha",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["battle", "women's", "rights", "been", "largely", "won"],
    words: ["the", "battle", "for", "women's", "rights", "has", "been", "largely", "won"],
    id: "The battle for women's rights has been largely won.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["lot", "songs", "i'm", "kind", "singing", "rapping"],
    words: ["a", "lot", "of", "songs", "i'm", "kind", "singing", "and", "rapping"],
    id: "A lot of songs I'm kind of singing and rapping.",
    author: "Lil Baby",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "really", "close", "relationship", "mike", "bloomberg"],
    words: ["i", "have", "a", "really", "close", "relationship", "with", "mike", "bloomberg"],
    id: "I have a really close relationship with Mike Bloomberg.",
    author: "Joe Biden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "still", "most", "extraordinary", "computer", "all"],
    words: ["man", "is", "still", "the", "most", "extraordinary", "computer", "of", "all"],
    id: "Man is still the most extraordinary computer of all.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["human", "salvation", "lies", "hands", "creatively", "maladjusted"],
    words: ["human", "salvation", "lies", "in", "the", "hands", "of", "creatively", "maladjusted"],
    id: "Human salvation lies in the hands of the creatively maladjusted.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["dancing", "running", "shake", "up", "chemistry", "happiness"],
    words: ["dancing", "and", "running", "shake", "up", "the", "chemistry", "of", "happiness"],
    id: "Dancing and running shake up the chemistry of happiness.",
    author: "Mason Cooley",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "such", "thing", "as", "something", "nothing"],
    words: ["there", "is", "no", "such", "thing", "as", "something", "for", "nothing"],
    id: "There is no such thing as Something for nothing.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "pc", "was", "launched", "knew", "important"],
    words: ["when", "the", "pc", "was", "launched", "people", "knew", "it", "important"],
    id: "When the PC was launched, people knew it was important.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "stay", "tune", "my", "family", "god"],
    words: ["i", "stay", "in", "tune", "with", "my", "family", "and", "god"],
    id: "I stay in tune with my family and God.",
    author: "Regina King",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["life's", "too", "short", "deal", "people's", "insecurities"],
    words: ["life's", "too", "short", "to", "deal", "with", "other", "people's", "insecurities"],
    id: "Life's too short to deal with other people's insecurities.",
    author: "Anthony Hopkins",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyone", "should", "their", "mind", "blown", "once"],
    words: ["everyone", "should", "have", "their", "mind", "blown", "once", "a", "day"],
    id: "Everyone should have their mind blown once a day.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["friendship", "always", "sweet", "responsibility", "never", "opportunity"],
    words: [
      "friendship",
      "is",
      "always",
      "a",
      "sweet",
      "responsibility",
      "never",
      "an",
      "opportunity",
    ],
    id: "Friendship is always a sweet responsibility, never an opportunity.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["come", "live", "my", "heart", "pay", "rent"],
    words: ["come", "live", "in", "my", "heart", "and", "pay", "no", "rent"],
    id: "Come live in my heart, and pay no rent.",
    author: "Samuel Lover",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["man's", "worth", "greater", "than", "his", "ambitions"],
    words: ["a", "man's", "worth", "is", "no", "greater", "than", "his", "ambitions"],
    id: "A man's worth is no greater than his ambitions.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["loss", "nothing", "else", "change", "nature's", "delight"],
    words: ["loss", "is", "nothing", "else", "but", "change", "and", "nature's", "delight"],
    id: "Loss is nothing else but change, and change is Nature's delight.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["beauty", "eternity", "gazing", "at", "itself", "mirror"],
    words: ["beauty", "is", "eternity", "gazing", "at", "itself", "in", "a", "mirror"],
    id: "Beauty is eternity gazing at itself in a mirror.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "regret", "drive", "when", "was", "younger"],
    words: ["i", "regret", "not", "learning", "to", "drive", "when", "was", "younger"],
    id: "I regret not learning to drive when I was younger.",
    author: "Michelle Dockery",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["doing", "what's", "right", "isn't", "problem", "knowing"],
    words: ["doing", "what's", "right", "isn't", "the", "problem", "it", "is", "knowing"],
    id: "Doing what's right isn't the problem. It is knowing what's right.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["isolation", "one", "sure", "way", "human", "happiness"],
    words: ["isolation", "is", "the", "one", "sure", "way", "to", "human", "happiness"],
    id: "Isolation is the one sure way to human happiness.",
    author: "Glenn Gould",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["music", "refuge", "souls", "ulcerated", "by", "happiness"],
    words: ["music", "is", "the", "refuge", "of", "souls", "ulcerated", "by", "happiness"],
    id: "Music is the refuge of souls ulcerated by happiness.",
    author: "Emil Cioran",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["two", "most", "powerful", "warriors", "patience", "time"],
    words: ["the", "two", "most", "powerful", "warriors", "are", "patience", "and", "time"],
    id: "The two most powerful warriors are patience and time.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "choice", "requires", "effort", "at", "times"],
    words: ["happiness", "is", "a", "choice", "that", "requires", "effort", "at", "times"],
    id: "Happiness is a choice that requires effort at times.",
    author: "Aeschylus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["more", "interesting", "than", "knowing", "less", "looking"],
    words: ["thinking", "is", "more", "interesting", "than", "knowing", "but", "less", "looking"],
    id: "Thinking is more interesting than knowing, but less interesting than looking.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["born", "suffer", "die", "fortunately", "there's", "loophole"],
    words: ["you're", "born", "you", "suffer", "die", "fortunately", "there's", "a", "loophole"],
    id: "You're born. You suffer. You die. Fortunately, there's a loophole.",
    author: "Billy Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "there", "were", "vet", "video", "games"],
    words: ["in", "my", "day", "there", "were", "no", "vet", "video", "games"],
    id: "In my day, there were no vet video games.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "great", "family", "live", "amazing", "life"],
    words: ["i", "have", "a", "great", "family", "live", "an", "amazing", "life"],
    id: "I have a great family, I live an amazing life.",
    author: "John Oates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "antidote", "mental", "suffering", "physical", "pain"],
    words: ["the", "only", "antidote", "to", "mental", "suffering", "is", "physical", "pain"],
    id: "The only antidote to mental suffering is physical pain.",
    author: "Karl Marx",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["whole", "more", "than", "sum", "its", "parts"],
    words: ["the", "whole", "is", "more", "than", "sum", "of", "its", "parts"],
    id: "The whole is more than the sum of its parts.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["heyday", "woman's", "life", "shady", "side", "fifty"],
    words: ["the", "heyday", "of", "woman's", "life", "is", "shady", "side", "fifty"],
    id: "The heyday of woman's life is the shady side of fifty.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["free", "trade", "based", "on", "utility", "justice"],
    words: ["free", "trade", "is", "not", "based", "on", "utility", "but", "justice"],
    id: "Free trade is not based on utility but on justice.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "more", "independence", "politics", "than", "jail"],
    words: ["there", "is", "no", "more", "independence", "in", "politics", "than", "jail"],
    id: "There is no more independence in politics than there is in jail.",
    author: "Will Rogers",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["cynics", "right", "nine", "times", "out", "ten"],
    words: ["the", "cynics", "are", "right", "nine", "times", "out", "of", "ten"],
    id: "The cynics are right nine times out of ten.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["idleness", "beginning", "all", "vice", "crown", "virtues"],
    words: ["idleness", "is", "the", "beginning", "of", "all", "vice", "crown", "virtues"],
    id: "Idleness is the beginning of all vice, the crown of all virtues.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["virtues", "lost", "self-interest", "as", "rivers", "sea"],
    words: ["the", "virtues", "are", "lost", "in", "self-interest", "as", "rivers", "sea"],
    id: "The virtues are lost in self-interest as rivers are lost in the sea.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["word", "virtue", "as", "useful", "self-interest", "vices"],
    words: ["the", "word", "virtue", "is", "as", "useful", "to", "self-interest", "vices"],
    id: "The word virtue is as useful to self-interest as the vices.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "gordon", "ramsay", "goodness", "sake", "volatile"],
    words: ["i'm", "gordon", "ramsay", "for", "goodness", "sake", "people", "know", "volatile"],
    id: "I'm Gordon Ramsay, for goodness sake: people know I'm volatile.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["fear", "life", "favorite", "disease", "20th", "century"],
    words: ["the", "fear", "of", "life", "is", "favorite", "disease", "20th", "century"],
    id: "The fear of life is the favorite disease of the 20th century.",
    author: "William Lyon Phelps",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["best", "way", "sell", "yourself", "others", "first"],
    words: ["the", "best", "way", "to", "sell", "yourself", "others", "is", "first"],
    id: "The best way to sell yourself to others is first to sell the others to yourself.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["person", "because", "recognizes", "others", "as", "persons"],
    words: ["a", "person", "is", "because", "he", "recognizes", "others", "as", "persons"],
    id: "A person is a person because he recognizes others as persons.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "personally", "stopped", "flying", "eating", "dairy"],
    words: ["i", "personally", "have", "stopped", "flying", "eating", "meat", "and", "dairy"],
    id: "I personally have stopped flying. I have stopped eating meat and dairy.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "heart", "aches", "america", "its", "deceived"],
    words: ["my", "heart", "aches", "for", "america", "and", "its", "deceived", "people"],
    id: "My heart aches for America and its deceived people.",
    author: "Billy Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "nothing", "except", "fact", "my", "ignorance"],
    words: ["i", "know", "nothing", "except", "the", "fact", "of", "my", "ignorance"],
    id: "I know nothing, except the fact of my ignorance.",
    author: "Diogenes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["ardor", "n", "quality", "distinguishes", "without", "knowledge"],
    words: [
      "ardor",
      "n",
      "the",
      "quality",
      "that",
      "distinguishes",
      "love",
      "without",
      "knowledge",
    ],
    id: "Ardor, n. The quality that distinguishes love without knowledge.",
    author: "Ambrose Bierce",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["fear", "pain", "arising", "from", "anticipation", "evil"],
    words: ["fear", "is", "pain", "arising", "from", "the", "anticipation", "of", "evil"],
    id: "Fear is pain arising from the anticipation of evil.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["old", "things", "always", "repute", "present", "disfavor"],
    words: ["old", "things", "are", "always", "in", "good", "repute", "present", "disfavor"],
    id: "Old things are always in good repute, present things in disfavor.",
    author: "Tacitus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "security", "on", "earth", "only", "opportunity"],
    words: ["there", "is", "no", "security", "on", "this", "earth", "only", "opportunity"],
    id: "There is no security on this earth; there is only opportunity.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["bad", "system", "will", "beat", "person", "time"],
    words: ["a", "bad", "system", "will", "beat", "good", "person", "every", "time"],
    id: "A bad system will beat a good person every time.",
    author: "W. Edwards Deming",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "innovation", "creativity", "without", "failure", "period"],
    words: ["there", "is", "no", "innovation", "and", "creativity", "without", "failure", "period"],
    id: "There is no innovation and creativity without failure. Period.",
    author: "Brene Brown",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["misfortune", "wise", "better", "than", "prosperity", "fool"],
    words: ["the", "misfortune", "of", "wise", "is", "better", "than", "prosperity", "fool"],
    id: "The misfortune of the wise is better than the prosperity of the fool.",
    author: "Epicurus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["friend", "one", "soul", "abiding", "two", "bodies"],
    words: ["a", "friend", "is", "one", "soul", "abiding", "in", "two", "bodies"],
    id: "A friend is one soul abiding in two bodies.",
    author: "Diogenes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "marriage", "between", "man", "woman"],
    words: ["i", "believe", "a", "marriage", "is", "between", "man", "and", "woman"],
    id: "I believe a marriage is between a man and a woman.",
    author: "George W. Bush",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "ones", "life", "start", "immediately", "flamboyantly"],
    words: ["to", "change", "ones", "life", "start", "immediately", "do", "it", "flamboyantly"],
    id: "To change ones life: Start immediately. Do it flamboyantly.",
    author: "William James",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["word", "man", "most", "durable", "all", "material"],
    words: ["the", "word", "of", "man", "is", "most", "durable", "all", "material"],
    id: "The word of man is the most durable of all material.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "only", "pursued", "pursuing", "busy", "tired"],
    words: ["there", "are", "only", "the", "pursued", "pursuing", "busy", "and", "tired"],
    id: "There are only the pursued, the pursuing, the busy and the tired.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "too", "short", "celebrate", "nice", "moments"],
    words: ["life", "is", "too", "short", "not", "to", "celebrate", "nice", "moments"],
    id: "Life is too short not to celebrate nice moments!",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "talk", "mortality", "talking", "our", "children"],
    words: ["when", "we", "talk", "about", "mortality", "are", "talking", "our", "children"],
    id: "When we talk about mortality, we are talking about our children.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["remember", "always", "stoop", "pick", "up", "nothing"],
    words: ["remember", "you", "can", "always", "stoop", "and", "pick", "up", "nothing"],
    id: "Remember, you can always stoop and pick up nothing.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "words", "scarce", "seldom", "spent", "vain"],
    words: ["when", "words", "are", "scarce", "they", "seldom", "spent", "in", "vain"],
    id: "When words are scarce they are seldom spent in vain.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["ever", "tried", "failed", "matter", "fail", "better"],
    words: ["ever", "tried", "failed", "no", "matter", "try", "again", "fail", "better"],
    id: "Ever tried. Ever failed. No matter. Try Again. Fail again. Fail better.",
    author: "Samuel Beckett",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["marrying", "old", "bachelor", "buying", "second-hand", "furniture"],
    words: [
      "marrying",
      "an",
      "old",
      "bachelor",
      "is",
      "like",
      "buying",
      "second-hand",
      "furniture",
    ],
    id: "Marrying an old bachelor is like buying second-hand furniture.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "wisest", "stupidest", "men", "never", "change"],
    words: ["only", "the", "wisest", "and", "stupidest", "of", "men", "never", "change"],
    id: "Only the wisest and stupidest of men never change.",
    author: "Confucius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["what's", "wrong", "being", "boring", "kind", "guy"],
    words: ["what's", "wrong", "with", "being", "a", "boring", "kind", "of", "guy"],
    id: "What's wrong with being a boring kind of guy?",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["order", "irreplaceable", "one", "must", "always", "different"],
    words: ["in", "order", "to", "be", "irreplaceable", "one", "must", "always", "different"],
    id: "In order to be irreplaceable one must always be different.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "act", "on", "impulse", "my", "instincts"],
    words: ["i", "act", "on", "impulse", "and", "go", "with", "my", "instincts"],
    id: "I act on impulse and I go with my instincts.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "wars", "follies", "expensive", "mischievous", "ones"],
    words: ["all", "wars", "are", "follies", "very", "expensive", "and", "mischievous", "ones"],
    id: "All wars are follies, very expensive and very mischievous ones.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["wise", "men", "advice", "fools", "won't", "take"],
    words: ["wise", "men", "don't", "need", "advice", "fools", "won't", "take", "it"],
    id: "Wise men don't need advice. Fools won't take it.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "there's", "anything", "unique", "human", "intelligence"],
    words: [
      "i",
      "don't",
      "think",
      "there's",
      "anything",
      "unique",
      "about",
      "human",
      "intelligence",
    ],
    id: "I don't think there's anything unique about human intelligence.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["gratitude", "merely", "secret", "hope", "further", "favors"],
    words: ["gratitude", "is", "merely", "the", "secret", "hope", "of", "further", "favors"],
    id: "Gratitude is merely the secret hope of further favors.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["promise", "according", "our", "hopes", "perform", "fears"],
    words: ["we", "promise", "according", "to", "our", "hopes", "and", "perform", "fears"],
    id: "We promise according to our hopes and perform according to our fears.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "were", "two-faced", "would", "wearing", "one"],
    words: ["if", "i", "were", "two-faced", "would", "be", "wearing", "this", "one"],
    id: "If I were two-faced, would I be wearing this one?",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["hard", "improve", "public", "education", "-", "clear"],
    words: ["it's", "hard", "to", "improve", "public", "education", "-", "that's", "clear"],
    id: "It's hard to improve public education - that's clear.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["software", "great", "combination", "between", "artistry", "engineering"],
    words: [
      "software",
      "is",
      "a",
      "great",
      "combination",
      "between",
      "artistry",
      "and",
      "engineering",
    ],
    id: "Software is a great combination between artistry and engineering.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "will", "prepare", "my", "chance", "come"],
    words: ["i", "will", "prepare", "and", "some", "day", "my", "chance", "come"],
    id: "I will prepare and some day my chance will come.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "slow", "walker", "never", "walk", "back"],
    words: ["i", "am", "a", "slow", "walker", "but", "never", "walk", "back"],
    id: "I am a slow walker, but I never walk back.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["windows", "8", "key", "future", "surface", "computer"],
    words: ["windows", "8", "is", "key", "to", "the", "future", "surface", "computer"],
    id: "Windows 8 is key to the future, the Surface computer.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "most", "unhappy", "customers", "greatest", "source"],
    words: ["your", "most", "unhappy", "customers", "are", "greatest", "source", "of", "learning"],
    id: "Your most unhappy customers are your greatest source of learning.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "my", "best", "avoid", "shark", "fin"],
    words: ["i", "do", "my", "very", "best", "to", "avoid", "shark", "fin"],
    id: "I do my very best to avoid shark fin.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["skype", "actually", "get", "fair", "bit", "revenue"],
    words: ["skype", "actually", "does", "get", "a", "fair", "bit", "of", "revenue"],
    id: "Skype actually does get a fair bit of revenue.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["possible", "exception", "equator", "everything", "begins", "somewhere"],
    words: [
      "with",
      "the",
      "possible",
      "exception",
      "of",
      "equator",
      "everything",
      "begins",
      "somewhere",
    ],
    id: "With the possible exception of the equator, everything begins somewhere.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["career", "get", "any", "easier", "stays", "tough"],
    words: ["the", "career", "doesn't", "get", "any", "easier", "a", "stays", "tough"],
    id: "The career doesn't get any easier. A career stays tough.",
    author: "Dabney Coleman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "uncreated", "being", "except", "god", "opposite"],
    words: ["there", "is", "no", "uncreated", "being", "except", "god", "has", "opposite"],
    id: "There is no uncreated being except God. God has no opposite.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["little", "minds", "interested", "extraordinary", "great", "commonplace"],
    words: [
      "little",
      "minds",
      "are",
      "interested",
      "in",
      "the",
      "extraordinary",
      "great",
      "commonplace",
    ],
    id: "Little minds are interested in the extraordinary; great minds in the commonplace.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["pretty", "hard", "efficient", "without", "being", "obnoxious"],
    words: ["it's", "pretty", "hard", "to", "be", "efficient", "without", "being", "obnoxious"],
    id: "It's pretty hard to be efficient without being obnoxious.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["people's", "minds", "changed", "through", "observation", "argument"],
    words: [
      "people's",
      "minds",
      "are",
      "changed",
      "through",
      "observation",
      "and",
      "not",
      "argument",
    ],
    id: "People's minds are changed through observation and not through argument.",
    author: "Will Rogers",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["cannot", "change", "your", "destination", "overnight", "direction"],
    words: [
      "you",
      "cannot",
      "change",
      "your",
      "destination",
      "overnight",
      "but",
      "can",
      "direction",
    ],
    id: "You cannot change your destination overnight, but you can change your direction overnight.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "change", "your", "mind", "why", "one"],
    words: ["if", "you", "never", "change", "your", "mind", "why", "have", "one"],
    id: "If you never change your mind, why have one?",
    author: "Edward de Bono",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["easier", "resist", "at", "beginning", "than", "end"],
    words: ["it's", "easier", "to", "resist", "at", "the", "beginning", "than", "end"],
    id: "It's easier to resist at the beginning than at the end.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["truth", "things", "chief", "nutriment", "superior", "intellects"],
    words: ["the", "truth", "of", "things", "is", "chief", "nutriment", "superior", "intellects"],
    id: "The truth of things is the chief nutriment of superior intellects.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "life", "made", "by", "death", "others"],
    words: ["our", "life", "is", "made", "by", "the", "death", "of", "others"],
    id: "Our life is made by the death of others.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "opinion", "completely", "different", "after", "show"],
    words: ["my", "opinion", "can", "be", "completely", "different", "after", "a", "show"],
    id: "My opinion can be completely different after a show.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "more", "friends", "york", "than", "paris"],
    words: ["i", "have", "more", "friends", "in", "new", "york", "than", "paris"],
    id: "I have more friends in New York than Paris.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "look", "sloppy", "because", "then", "feel"],
    words: ["i", "don't", "want", "to", "look", "sloppy", "because", "then", "feel"],
    id: "I don't want to look sloppy, because then I feel sloppy.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "wanted", "become", "illustrator", "as", "child"],
    words: ["i", "wanted", "to", "become", "an", "illustrator", "as", "a", "child"],
    id: "I wanted to become an illustrator as a child.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["before", "anything", "else", "preparation", "key", "success"],
    words: ["before", "anything", "else", "preparation", "is", "the", "key", "to", "success"],
    id: "Before anything else, preparation is the key to success.",
    author: "Alexander Graham Bell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["housework", "can't", "kill", "why", "take", "chance"],
    words: ["housework", "can't", "kill", "you", "but", "why", "take", "a", "chance"],
    id: "Housework can't kill you, but why take a chance?",
    author: "Phyllis Diller",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["kindness", "words", "creates", "confidence", "profoundness", "giving"],
    words: [
      "kindness",
      "in",
      "words",
      "creates",
      "confidence",
      "thinking",
      "profoundness",
      "giving",
      "love",
    ],
    id: "Kindness in words creates confidence. Kindness in thinking creates profoundness. Kindness in giving creates love.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["fresh", "activity", "only", "means", "overcoming", "adversity"],
    words: ["fresh", "activity", "is", "the", "only", "means", "of", "overcoming", "adversity"],
    id: "Fresh activity is the only means of overcoming adversity.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["must", "use", "time", "as", "tool", "couch"],
    words: ["we", "must", "use", "time", "as", "a", "tool", "not", "couch"],
    id: "We must use time as a tool, not as a couch.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "i'll", "take", "bath", "his", "blood"],
    words: ["i", "think", "i'll", "take", "a", "bath", "in", "his", "blood"],
    id: "I think I'll take a bath in his blood.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["man's", "knowledge", "here", "beyond", "his", "experience"],
    words: ["no", "man's", "knowledge", "here", "can", "go", "beyond", "his", "experience"],
    id: "No man's knowledge here can go beyond his experience.",
    author: "John Locke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["civilization", "order", "freedom", "promoting", "cultural", "activity"],
    words: [
      "civilization",
      "is",
      "the",
      "order",
      "and",
      "freedom",
      "promoting",
      "cultural",
      "activity",
    ],
    id: "Civilization is the order and freedom is promoting cultural activity.",
    author: "Will Durant",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["remark", "generally", "hurts", "proportion", "its", "truth"],
    words: ["a", "remark", "generally", "hurts", "in", "proportion", "to", "its", "truth"],
    id: "A remark generally hurts in proportion to its truth.",
    author: "Will Rogers",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["family", "first", "essential", "cell", "human", "society"],
    words: ["the", "family", "is", "first", "essential", "cell", "of", "human", "society"],
    id: "The family is the first essential cell of human society.",
    author: "Pope John XXIII",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["course", "true", "never", "did", "run", "smooth"],
    words: ["the", "course", "of", "true", "love", "never", "did", "run", "smooth"],
    id: "The course of true love never did run smooth.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["gaiety", "most", "outstanding", "feature", "soviet", "union"],
    words: ["gaiety", "is", "the", "most", "outstanding", "feature", "of", "soviet", "union"],
    id: "Gaiety is the most outstanding feature of the Soviet Union.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "was", "fighting", "machine", "will", "iron"],
    words: ["i", "was", "a", "fighting", "machine", "with", "will", "of", "iron"],
    id: "I was a fighting machine with a will of iron.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["habits", "life", "form", "soul", "forms", "countenance"],
    words: ["the", "habits", "of", "life", "form", "soul", "and", "forms", "countenance"],
    id: "The habits of life form the soul, and the soul forms the countenance.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["we'll", "take", "red", "cherry", "on", "top"],
    words: ["we'll", "take", "the", "cake", "with", "red", "cherry", "on", "top"],
    id: "We'll take the cake with the red cherry on top.",
    author: "Navjot Singh Sidhu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["while", "may", "lose", "heart", "never", "hope"],
    words: ["while", "we", "may", "lose", "heart", "never", "have", "to", "hope"],
    id: "While we may lose heart, we never have to lose hope.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["above", "all", "thine", "own", "self", "true"],
    words: ["this", "above", "all", "to", "thine", "own", "self", "be", "true"],
    id: "This above all; to thine own self be true.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "as", "converses", "lover", "silent", "husband"],
    words: ["the", "man", "as", "he", "converses", "is", "lover", "silent", "husband"],
    id: "The man as he converses is the lover; silent, he is the husband.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["injustice", "relatively", "easy", "bear", "stings", "justice"],
    words: ["injustice", "is", "relatively", "easy", "to", "bear", "what", "stings", "justice"],
    id: "Injustice is relatively easy to bear; what stings is justice.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "marry", "hope", "they'll", "change", "later"],
    words: ["never", "marry", "someone", "in", "hope", "that", "they'll", "change", "later"],
    id: "Never marry someone in hope that they'll change later.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["ladder", "success", "never", "crowded", "at", "top"],
    words: ["the", "ladder", "of", "success", "is", "never", "crowded", "at", "top"],
    id: "The ladder of success is never crowded at the top.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "was", "motivated", "different", "part", "because"],
    words: ["i", "was", "motivated", "to", "be", "different", "in", "part", "because"],
    id: "I was motivated to be different in part because I was different.",
    author: "Donna Brazile",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "trouble", "comes", "your", "family", "supports"],
    words: ["when", "trouble", "comes", "it's", "your", "family", "that", "supports", "you"],
    id: "When trouble comes, it's your family that supports you.",
    author: "Guy Lafleur",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "senses", "sight", "must", "most", "delightful"],
    words: ["of", "all", "the", "senses", "sight", "must", "be", "most", "delightful"],
    id: "Of all the senses, sight must be the most delightful.",
    author: "Helen Keller",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["pilots", "take", "special", "joy", "walking", "flying"],
    words: ["pilots", "take", "no", "special", "joy", "in", "walking", "like", "flying"],
    id: "Pilots take no special joy in walking. Pilots like flying.",
    author: "Neil Armstrong",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["justice", "sweet", "musical", "injustice", "harsh", "discordant"],
    words: ["justice", "is", "sweet", "and", "musical", "but", "injustice", "harsh", "discordant"],
    id: "Justice is sweet and musical; but injustice is harsh and discordant.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["soul", "becomes", "dyed", "color", "its", "thoughts"],
    words: ["the", "soul", "becomes", "dyed", "with", "color", "of", "its", "thoughts"],
    id: "The soul becomes dyed with the color of its thoughts.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "longest", "distance", "between", "two", "places"],
    words: ["for", "time", "is", "the", "longest", "distance", "between", "two", "places"],
    id: "For time is the longest distance between two places.",
    author: "Tennessee Williams",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["fears", "nothing", "more", "than", "state", "mind"],
    words: ["fears", "are", "nothing", "more", "than", "a", "state", "of", "mind"],
    id: "Fears are nothing more than a state of mind.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "minister", "preach", "forgiveness", "all", "time"],
    words: ["i", "am", "a", "minister", "and", "preach", "forgiveness", "all", "time"],
    id: "I am a minister and I preach forgiveness all time.",
    author: "Ted DiBiase Sr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["battery", "field", "artillery", "worth", "thousand", "muskets"],
    words: ["a", "battery", "of", "field", "artillery", "is", "worth", "thousand", "muskets"],
    id: "A battery of field artillery is worth a thousand muskets.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["running", "airline", "normal", "job", "racing", "more"],
    words: ["running", "an", "airline", "is", "a", "normal", "job", "racing", "more"],
    id: "Running an airline is a normal job. Racing is more.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "greater", "education", "than", "one", "self-driven"],
    words: ["there", "is", "no", "greater", "education", "than", "one", "that", "self-driven"],
    id: "There is no greater education than one that is self-driven.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["maybe", "your", "weird", "my", "normal", "who's"],
    words: ["maybe", "your", "weird", "is", "my", "normal", "who's", "to", "say"],
    id: "Maybe your weird is my normal. Who's to say?",
    author: "Nicki Minaj",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["wise", "man", "at", "once", "fool", "finally"],
    words: ["the", "wise", "man", "does", "at", "once", "what", "fool", "finally"],
    id: "The wise man does at once what the fool does finally.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["hatred", "gained", "as", "much", "by", "evil"],
    words: ["hatred", "is", "gained", "as", "much", "by", "good", "works", "evil"],
    id: "Hatred is gained as much by good works as by evil.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["war", "too", "serious", "matter", "leave", "soldiers"],
    words: ["war", "is", "too", "serious", "a", "matter", "to", "leave", "soldiers"],
    id: "War is too serious a matter to leave to soldiers.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["exceeds", "man's", "might", "dwells", "gods", "above"],
    words: ["exceeds", "man's", "might", "that", "dwells", "with", "the", "gods", "above"],
    id: "Exceeds man's might: that dwells with the gods above.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["great", "affairs", "start", "champagne", "end", "tisane"],
    words: ["great", "love", "affairs", "start", "with", "champagne", "and", "end", "tisane"],
    id: "Great love affairs start with Champagne and end with tisane.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["efforts", "courage", "enough", "without", "purpose", "direction"],
    words: ["efforts", "and", "courage", "are", "not", "enough", "without", "purpose", "direction"],
    id: "Efforts and courage are not enough without purpose and direction.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["poverty", "luxury", "many", "avarice", "all", "things"],
    words: ["poverty", "wants", "some", "luxury", "many", "and", "avarice", "all", "things"],
    id: "Poverty wants some, luxury many, and avarice all things.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["constant", "exposure", "dangers", "will", "breed", "contempt"],
    words: ["constant", "exposure", "to", "dangers", "will", "breed", "contempt", "for", "them"],
    id: "Constant exposure to dangers will breed contempt for them.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "focused", "on", "gay", "lesbian", "movement"],
    words: ["i'm", "not", "focused", "on", "the", "gay", "and", "lesbian", "movement"],
    id: "I'm not focused on the gay and lesbian movement.",
    author: "Billy Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["those", "dare", "fail", "miserably", "achieve", "greatly"],
    words: ["those", "who", "dare", "to", "fail", "miserably", "can", "achieve", "greatly"],
    id: "Those who dare to fail miserably can achieve greatly.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "body", "dependant", "on", "heaven", "spirit"],
    words: ["our", "body", "is", "dependant", "on", "heaven", "and", "the", "spirit"],
    id: "Our body is dependant on Heaven and Heaven on the Spirit.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "feel", "whatever", "meant", "already", "written"],
    words: ["i", "feel", "like", "whatever", "is", "meant", "this", "already", "written"],
    id: "I feel like whatever is meant is meant. I feel like this is already written.",
    author: "Lil Baby",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["step", "life", "shows", "much", "caution", "required"],
    words: ["every", "step", "of", "life", "shows", "much", "caution", "is", "required"],
    id: "Every step of life shows much caution is required.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["harder", "on", "yourself", "than", "your", "job"],
    words: ["work", "harder", "on", "yourself", "than", "you", "do", "your", "job"],
    id: "Work harder on yourself than you do on your job.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["movies", "big", "part", "our", "indian", "culture"],
    words: ["movies", "are", "a", "big", "part", "of", "our", "indian", "culture"],
    id: "Movies are a big part of our Indian culture.",
    author: "MS Dhoni",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "mom's", "side", "family", "from", "arkansas"],
    words: ["my", "mom's", "side", "of", "the", "family", "is", "from", "arkansas"],
    id: "My mom's side of the family is from Arkansas!",
    author: "Kelli O'Hara",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["choose", "rather", "strong", "soul", "than", "body"],
    words: ["choose", "rather", "to", "be", "strong", "of", "soul", "than", "body"],
    id: "Choose rather to be strong of soul than strong of body.",
    author: "Pythagoras",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["harder", "conceal", "ignorance", "than", "acquire", "knowledge"],
    words: ["it", "is", "harder", "to", "conceal", "ignorance", "than", "acquire", "knowledge"],
    id: "It is harder to conceal ignorance than to acquire knowledge.",
    author: "Arnold H. Glasow",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "being", "deaf", "my", "life", "family"],
    words: ["i'm", "happy", "being", "deaf", "with", "my", "life", "and", "family"],
    id: "I'm happy being deaf. I'm happy with my life and my family.",
    author: "Millicent Simmonds",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "chance", "my", "marrying", "princess", "margaret"],
    words: ["there", "is", "no", "chance", "of", "my", "marrying", "princess", "margaret"],
    id: "There is no chance of my marrying Princess Margaret.",
    author: "Roddy Llewellyn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["everything", "change", "at", "any", "suddenly", "forever"],
    words: ["everything", "can", "change", "at", "any", "moment", "suddenly", "and", "forever"],
    id: "Everything can change at any moment, suddenly and forever.",
    author: "Paul Auster",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "depression", "connected", "insolence", "refusal", "praise"],
    words: ["your", "depression", "is", "connected", "to", "insolence", "and", "refusal", "praise"],
    id: "Your depression is connected to your insolence and refusal to praise.",
    author: "Rumi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["may", "my", "soul", "bloom", "all", "existence"],
    words: ["may", "my", "soul", "bloom", "in", "love", "for", "all", "existence"],
    id: "May my soul bloom in love for all existence.",
    author: "Rudolf Steiner",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["seek", "shall", "find", "flee", "from", "flees"],
    words: ["what", "we", "seek", "shall", "find", "flee", "from", "flees", "us"],
    id: "What we seek we shall find; what we flee from flees from us.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["rip", "george", "floyd", "condolences", "whole", "family"],
    words: ["rip", "to", "george", "floyd", "and", "condolences", "the", "whole", "family"],
    id: "RIP to George Floyd and condolences to the whole family.",
    author: "Young Dolph",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom's", "another", "word", "nothing", "left", "lose"],
    words: ["freedom's", "just", "another", "word", "for", "nothing", "left", "to", "lose"],
    id: "Freedom's just another word for nothing left to lose.",
    author: "Kris Kristofferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["humanity", "won", "its", "battle", "liberty", "country"],
    words: ["humanity", "has", "won", "its", "battle", "liberty", "now", "a", "country"],
    id: "Humanity has won its battle. Liberty now has a country.",
    author: "Marquis de Lafayette",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["by", "luck", "i", "picked", "heroes", "worship"],
    words: ["just", "by", "luck", "i", "picked", "good", "heroes", "to", "worship"],
    id: "Just by luck, I picked good heroes to worship.",
    author: "Charles Kuralt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["grace", "beauty", "form", "under", "influence", "freedom"],
    words: ["grace", "is", "the", "beauty", "of", "form", "under", "influence", "freedom"],
    id: "Grace is the beauty of form under the influence of freedom.",
    author: "Friedrich Schiller",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["hate", "speech", "freedom", "two", "different", "things"],
    words: ["hate", "speech", "and", "freedom", "of", "are", "two", "different", "things"],
    id: "Hate speech and freedom of speech are two different things.",
    author: "Leslie Jones",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "another's", "health", "spoil", "our", "own"],
    words: ["we", "drink", "one", "another's", "health", "and", "spoil", "our", "own"],
    id: "We drink one another's health and spoil our own.",
    author: "Jerome K. Jerome",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "comes", "luck", "make", "your", "own"],
    words: ["when", "it", "comes", "to", "luck", "you", "make", "your", "own"],
    id: "When it comes to luck, you make your own.",
    author: "Bruce Springsteen",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["hit", "home", "runs", "by", "chance", "preparation"],
    words: ["you", "hit", "home", "runs", "not", "by", "chance", "but", "preparation"],
    id: "You hit home runs not by chance but by preparation.",
    author: "Roger Maris",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "became", "fanatic", "healthy", "food", "1944"],
    words: ["i", "became", "a", "fanatic", "about", "healthy", "food", "in", "1944"],
    id: "I became a fanatic about healthy food in 1944.",
    author: "Gloria Swanson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["choice", "-", "chance", "determines", "your", "destiny"],
    words: ["it's", "choice", "-", "not", "chance", "that", "determines", "your", "destiny"],
    id: "It's choice - not chance - that determines your destiny.",
    author: "Jean Nidetch",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "discovered", "freedom", "first", "time", "england"],
    words: ["i", "discovered", "freedom", "for", "the", "first", "time", "in", "england"],
    id: "I discovered freedom for the first time in England.",
    author: "Hirohito",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["illness", "strikes", "men", "when", "exposed", "change"],
    words: ["illness", "strikes", "men", "when", "they", "are", "exposed", "to", "change"],
    id: "Illness strikes men when they are exposed to change.",
    author: "Herodotus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "regular", "person", "makes", "nice", "music"],
    words: ["i'm", "a", "regular", "person", "who", "just", "makes", "nice", "music"],
    id: "I'm a regular person who just makes nice music.",
    author: "Rod Wave",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "sorry", "were", "right", "i'd", "agree"],
    words: ["i'm", "sorry", "if", "you", "were", "right", "i'd", "agree", "with"],
    id: "I'm sorry, if you were right, I'd agree with you.",
    author: "Robin Williams",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["great", "ballplayer", "player", "will", "take", "chance"],
    words: ["a", "great", "ballplayer", "is", "player", "who", "will", "take", "chance"],
    id: "A great ballplayer is a player who will take a chance.",
    author: "Branch Rickey",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["indeed", "most", "important", "product", "knowledge", "ignorance"],
    words: ["indeed", "the", "most", "important", "product", "of", "knowledge", "is", "ignorance"],
    id: "Indeed, the most important product of knowledge is ignorance.",
    author: "David Gross",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "mean", "all", "second", "chance", "sometimes"],
    words: ["i", "mean", "we", "all", "need", "a", "second", "chance", "sometimes"],
    id: "I mean we all need a second chance sometimes.",
    author: "Joel Osteen",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "juices", "antioxidants", "i'm", "health", "nut"],
    words: ["i", "like", "juices", "and", "antioxidants", "i'm", "a", "health", "nut"],
    id: "I like juices and antioxidants. I'm a health nut.",
    author: "Trudie Styler",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["depth", "friendship", "depend", "on", "length", "acquaintance"],
    words: ["depth", "of", "friendship", "does", "not", "depend", "on", "length", "acquaintance"],
    id: "Depth of friendship does not depend on length of acquaintance.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["acquire", "knowledge", "one", "must", "wisdom", "observe"],
    words: ["to", "acquire", "knowledge", "one", "must", "study", "but", "wisdom", "observe"],
    id: "To acquire knowledge, one must study; but to acquire wisdom, one must observe.",
    author: "Marilyn vos Savant",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["quit", "worrying", "your", "health", "will", "away"],
    words: ["quit", "worrying", "about", "your", "health", "it", "will", "go", "away"],
    id: "Quit worrying about your health. It will go away.",
    author: "Robert Orben",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "feeling", "positivity", "really", "foundation", "productivity"],
    words: [
      "happiness",
      "the",
      "feeling",
      "of",
      "positivity",
      "really",
      "is",
      "foundation",
      "productivity",
    ],
    id: "Happiness, the feeling of positivity, really is the foundation of productivity.",
    author: "Miguel McKelvey",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["asking", "questions", "first", "way", "begin", "change"],
    words: ["asking", "questions", "is", "the", "first", "way", "to", "begin", "change"],
    id: "Asking questions is the first way to begin change.",
    author: "Kubra Sait",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "11th", "birthday", "i", "asked", "adopted"],
    words: ["for", "my", "11th", "birthday", "i", "asked", "to", "be", "adopted"],
    id: "For my 11th birthday, I asked to be adopted.",
    author: "Kaui Hart Hemmings",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "was", "raised", "catholic", "religious", "family"],
    words: ["i", "was", "raised", "catholic", "in", "a", "very", "religious", "family"],
    id: "I was raised Catholic in a very religious family.",
    author: "Italia Ricci",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "yourself", "will", "preserve", "from", "vanity"],
    words: ["the", "knowledge", "of", "yourself", "will", "preserve", "you", "from", "vanity"],
    id: "The knowledge of yourself will preserve you from vanity.",
    author: "Miguel de Cervantes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["thought", "wind", "knowledge", "sail", "mankind", "vessel"],
    words: ["thought", "is", "the", "wind", "knowledge", "sail", "and", "mankind", "vessel"],
    id: "Thought is the wind, knowledge the sail, and mankind the vessel.",
    author: "Augustus Hare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["mistakes", "usual", "bridge", "between", "inexperience", "wisdom"],
    words: [
      "mistakes",
      "are",
      "the",
      "usual",
      "bridge",
      "between",
      "inexperience",
      "and",
      "wisdom",
    ],
    id: "Mistakes are the usual bridge between inexperience and wisdom.",
    author: "Phyllis Theroux",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "keep", "open", "mind", "compassionate", "heart"],
    words: ["always", "keep", "an", "open", "mind", "and", "a", "compassionate", "heart"],
    id: "Always keep an open mind and a compassionate heart.",
    author: "Phil Jackson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "nature", "one", "thing", "wisdom", "another"],
    words: ["never", "does", "nature", "say", "one", "thing", "and", "wisdom", "another"],
    id: "Never does nature say one thing and wisdom another.",
    author: "Juvenal",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["much", "wisdom", "one", "age", "folly", "next"],
    words: ["much", "of", "the", "wisdom", "one", "age", "is", "folly", "next"],
    id: "Much of the wisdom of one age, is the folly of the next.",
    author: "Charles Simmons",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "friendship", "ought", "never", "conceal", "thinks"],
    words: ["true", "friendship", "ought", "never", "to", "conceal", "what", "it", "thinks"],
    id: "True friendship ought never to conceal what it thinks.",
    author: "St. Jerome",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "seen", "times", "bad", "struggle", "success"],
    words: ["i", "have", "seen", "good", "times", "and", "bad", "struggle", "success"],
    id: "I have seen good times and bad times, struggle and success.",
    author: "Himani Shivpuri",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["sciences", "may", "learned", "by", "rote", "wisdom"],
    words: ["sciences", "may", "be", "learned", "by", "rote", "but", "wisdom", "not"],
    id: "Sciences may be learned by rote, but wisdom not.",
    author: "Laurence Sterne",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["takes", "20", "years", "make", "overnight", "success"],
    words: ["it", "takes", "20", "years", "to", "make", "an", "overnight", "success"],
    id: "It takes 20 years to make an overnight success.",
    author: "Eddie Cantor",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["wisdom", "must", "from", "one", "who's", "wise"],
    words: ["some", "wisdom", "you", "must", "learn", "from", "one", "who's", "wise"],
    id: "Some wisdom you must learn from one who's wise.",
    author: "Euripides",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "girls", "keep", "diaries", "bad", "time"],
    words: ["only", "good", "girls", "keep", "diaries", "bad", "don't", "have", "time"],
    id: "Only good girls keep diaries. Bad girls don't have time.",
    author: "Tallulah Bankhead",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["wheel", "squeaks", "loudest", "one", "gets", "grease"],
    words: ["the", "wheel", "that", "squeaks", "loudest", "is", "one", "gets", "grease"],
    id: "The wheel that squeaks the loudest is the one that gets the grease.",
    author: "Josh Billings",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["pine", "stays", "green", "winter", "wisdom", "hardship"],
    words: ["the", "pine", "stays", "green", "in", "winter", "wisdom", "hardship"],
    id: "The pine stays green in winter... wisdom in hardship.",
    author: "Norman Douglas",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["keep", "your", "faith", "god", "powder", "dry"],
    words: ["keep", "your", "faith", "in", "god", "but", "powder", "dry"],
    id: "Keep your faith in God, but keep your powder dry.",
    author: "Oliver Cromwell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["sometimes", "patience", "pays", "i", "always", "thought"],
    words: ["sometimes", "patience", "pays", "that's", "what", "i", "always", "thought"],
    id: "Sometimes patience pays. That's what I always thought.",
    author: "Wissam Ben Yedder",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["everything", "subject", "change", "except", "god", "himself"],
    words: ["everything", "is", "subject", "to", "change", "except", "god", "himself"],
    id: "Everything is subject to change except God Himself.",
    author: "Joyce Meyer",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["eccentricity", "never", "been", "discouraged", "our", "family"],
    words: ["eccentricity", "has", "never", "been", "discouraged", "in", "our", "family"],
    id: "Eccentricity has never been discouraged in our family.",
    author: "Carlene Carter",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "absolutely", "spending", "time", "my", "family"],
    words: ["i", "absolutely", "love", "spending", "time", "with", "my", "family"],
    id: "I absolutely love spending time with my family.",
    author: "Kevin Alejandro",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatever", "concerns", "health", "real", "public", "interest"],
    words: ["whatever", "concerns", "health", "is", "of", "real", "public", "interest"],
    id: "Whatever concerns health is of real public interest.",
    author: "Elie Metchnikoff",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "stop", "knowledge", "doubles", "fourteen", "months"],
    words: ["never", "stop", "learning", "knowledge", "doubles", "every", "fourteen", "months"],
    id: "Never stop learning; knowledge doubles every fourteen months.",
    author: "Anthony J. D'Angelo",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "grew", "up", "big", "italian", "family"],
    words: ["i", "grew", "up", "in", "a", "big", "italian", "family"],
    id: "I grew up in a big Italian family.",
    author: "Jonathan Bailey",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "only", "constant", "hanging", "on", "sin"],
    words: ["change", "is", "the", "only", "constant", "hanging", "on", "sin"],
    id: "Change is the only constant. Hanging on is the only sin.",
    author: "Denise McCluggage",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "things", "must", "change", "something", "strange"],
    words: ["all", "things", "must", "change", "to", "something", "new", "strange"],
    id: "All things must change to something new, to something strange.",
    author: "Henry Wadsworth Longfellow",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "change", "growth", "as", "movement", "forward"],
    words: ["all", "change", "is", "not", "growth", "as", "movement", "forward"],
    id: "All change is not growth, as all movement is not forward.",
    author: "Ellen Glasgow",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["culture", "change", "means", "will", "things", "differently"],
    words: ["culture", "change", "means", "we", "will", "do", "things", "differently"],
    id: "Culture change means we will do things differently.",
    author: "Satya Nadella",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "live", "without", "bread", "can't", "freedom"],
    words: ["i", "can", "live", "without", "bread", "but", "can't", "freedom"],
    id: "I can live without bread, but I can't live without freedom.",
    author: "Said Nursi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["larger", "island", "knowledge", "longer", "shoreline", "wonder"],
    words: ["the", "larger", "island", "of", "knowledge", "longer", "shoreline", "wonder"],
    id: "The larger the island of knowledge, the longer the shoreline of wonder.",
    author: "Ralph W. Sockman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["achieve", "inwardly", "will", "change", "outer", "reality"],
    words: ["what", "we", "achieve", "inwardly", "will", "change", "outer", "reality"],
    id: "What we achieve inwardly will change outer reality.",
    author: "Plutarch",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "always", "leave", "room", "serendipity", "chance"],
    words: ["i", "always", "leave", "room", "for", "serendipity", "and", "chance"],
    id: "I always leave room for serendipity and chance.",
    author: "Ken Stott",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["chance", "fights", "ever", "on", "side", "prudent"],
    words: ["chance", "fights", "ever", "on", "the", "side", "of", "prudent"],
    id: "Chance fights ever on the side of the prudent.",
    author: "Euripides",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "grow", "old", "beauty", "steals", "inward"],
    words: ["as", "we", "grow", "old", "the", "beauty", "steals", "inward"],
    id: "As we grow old, the beauty steals inward.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "ever", "prayed", "heartily", "without", "something"],
    words: ["no", "man", "ever", "prayed", "heartily", "without", "learning", "something"],
    id: "No man ever prayed heartily without learning something.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["improved", "maternal", "health", "benefits", "whole", "society"],
    words: ["improved", "maternal", "health", "benefits", "the", "whole", "of", "society"],
    id: "Improved maternal health benefits the whole of society.",
    author: "Isabella Lovin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "success", "happiness", "lies", "freedom", "fulfillment"],
    words: ["true", "success", "happiness", "lies", "in", "freedom", "and", "fulfillment"],
    id: "True success, true happiness lies in freedom and fulfillment.",
    author: "Dada Vaswani",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["why", "i", "volumes", "one", "word", "suffice"],
    words: ["why", "need", "i", "volumes", "if", "one", "word", "suffice"],
    id: "Why need I volumes, if one word suffice?",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["insanity", "runs", "my", "family", "practically", "gallops"],
    words: ["insanity", "runs", "in", "my", "family", "it", "practically", "gallops"],
    id: "Insanity runs in my family. It practically gallops.",
    author: "Cary Grant",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["wise", "man", "turns", "chance", "into", "fortune"],
    words: ["a", "wise", "man", "turns", "chance", "into", "good", "fortune"],
    id: "A wise man turns chance into good fortune.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "really", "known", "my", "health", "approach"],
    words: ["i'm", "not", "really", "known", "for", "my", "health", "approach"],
    id: "I'm not really known for my health approach.",
    author: "Jock Zonfrillo",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["indignation", "brings", "out", "all", "one's", "powers"],
    words: ["a", "good", "indignation", "brings", "out", "all", "one's", "powers"],
    id: "A good indignation brings out all one's powers.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["sometimes", "too", "much", "destroy", "your", "momentum"],
    words: ["sometimes", "thinking", "too", "much", "can", "destroy", "your", "momentum"],
    id: "Sometimes thinking too much can destroy your momentum.",
    author: "Tom Watson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "repent", "having", "eaten", "too", "little"],
    words: ["we", "never", "repent", "of", "having", "eaten", "too", "little"],
    id: "We never repent of having eaten too little.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["law", "or", "ordinance", "mightier", "than", "understanding"],
    words: ["no", "law", "or", "ordinance", "is", "mightier", "than", "understanding"],
    id: "No law or ordinance is mightier than understanding.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "god", "only", "spell", "nature"],
    words: ["i", "believe", "in", "god", "only", "spell", "it", "nature"],
    id: "I believe in God, only I spell it Nature.",
    author: "Frank Lloyd Wright",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "wish", "could", "write", "as", "mysterious"],
    words: ["i", "wish", "could", "write", "as", "mysterious", "a", "cat"],
    id: "I wish I could write as mysterious as a cat.",
    author: "Unknown",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "cannot", "really", "argue", "mathematical", "theorem"],
    words: ["one", "cannot", "really", "argue", "with", "a", "mathematical", "theorem"],
    id: "One cannot really argue with a mathematical theorem.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "many", "victories", "worse", "than", "defeat"],
    words: ["there", "are", "many", "victories", "worse", "than", "a", "defeat"],
    id: "There are many victories worse than a defeat.",
    author: "George Eliot",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["perhaps", "one", "i", "will", "into", "space"],
    words: ["perhaps", "one", "day", "i", "will", "go", "into", "space"],
    id: "Perhaps one day I will go into space.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["united", "states", "themselves", "essentially", "greatest", "poem"],
    words: ["the", "united", "states", "themselves", "are", "essentially", "greatest", "poem"],
    id: "The United States themselves are essentially the greatest poem.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["poetry", "nearer", "vital", "truth", "than", "history"],
    words: ["poetry", "is", "nearer", "to", "vital", "truth", "than", "history"],
    id: "Poetry is nearer to vital truth than history.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "faith", "comes", "moments", "vice", "habitual"],
    words: ["our", "faith", "comes", "in", "moments", "vice", "is", "habitual"],
    id: "Our faith comes in moments; our vice is habitual.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["thy", "self", "enemy", "thousand", "battles", "victories"],
    words: ["know", "thy", "self", "enemy", "a", "thousand", "battles", "victories"],
    id: "Know thy self, know thy enemy. A thousand battles, a thousand victories.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["real", "war", "will", "never", "get", "books"],
    words: ["the", "real", "war", "will", "never", "get", "in", "books"],
    id: "The real war will never get in the books.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "aged", "man", "-", "can't", "fill"],
    words: ["one", "aged", "man", "-", "can't", "fill", "a", "house"],
    id: "One aged man - one man - can't fill a house.",
    author: "Robert Frost",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["spring", "nature's", "way", "saying", "lets", "party"],
    words: ["spring", "is", "nature's", "way", "of", "saying", "lets", "party"],
    id: "Spring is nature's way of saying, 'Let's party!'",
    author: "Robin Williams",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "went", "from", "being", "terminator", "governator"],
    words: ["i", "went", "from", "being", "the", "terminator", "to", "governator"],
    id: "I went from being the Terminator to being the governator.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["brave", "take", "risks", "nothing", "substitute", "experience"],
    words: ["be", "brave", "take", "risks", "nothing", "can", "substitute", "experience"],
    id: "Be brave. Take risks. Nothing can substitute experience.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyone", "complains", "his", "memory", "nobody", "judgment"],
    words: ["everyone", "complains", "of", "his", "memory", "and", "nobody", "judgment"],
    id: "Everyone complains of his memory, and nobody complains of his judgment.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "priest", "scholar", "statesman", "producer", "soldier"],
    words: ["man", "is", "priest", "and", "scholar", "statesman", "producer", "soldier"],
    id: "Man is priest, and scholar, and statesman, and producer, and soldier.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "lyrics", "aren't", "offensive", "find", "everything"],
    words: ["my", "lyrics", "aren't", "offensive", "some", "people", "find", "everything"],
    id: "My lyrics aren't offensive. Some people find everything offensive.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "wanted", "use", "sports", "social", "change"],
    words: ["i", "wanted", "to", "use", "sports", "for", "social", "change"],
    id: "I wanted to use sports for social change.",
    author: "Billie Jean King",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["grant", "thou", "commandest", "then", "command", "wilt"],
    words: ["grant", "what", "thou", "commandest", "and", "then", "command", "wilt"],
    id: "Grant what thou commandest and then command what thou wilt.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "virtue", "summed", "up", "dealing", "justly"],
    words: ["all", "virtue", "is", "summed", "up", "in", "dealing", "justly"],
    id: "All virtue is summed up in dealing justly.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "three", "children", "brought", "great", "joy"],
    words: ["my", "three", "children", "have", "brought", "me", "great", "joy"],
    id: "My three children have brought me great joy.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["was", "god", "doing", "before", "divine", "creation"],
    words: ["what", "was", "god", "doing", "before", "the", "divine", "creation"],
    id: "What was God doing before the divine creation?",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["since", "god", "created", "world", "also", "reality"],
    words: ["since", "god", "created", "the", "world", "he", "also", "reality"],
    id: "Since God created the world, He also created reality.",
    author: "Pope Francis",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "itself", "most", "wonderful", "fairy", "tale"],
    words: ["life", "itself", "is", "the", "most", "wonderful", "fairy", "tale"],
    id: "Life itself is the most wonderful fairy tale.",
    author: "Hans Christian Andersen",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "get", "bored", "those", "whom", "bore"],
    words: ["we", "always", "get", "bored", "with", "those", "whom", "bore"],
    id: "We always get bored with those whom we bore.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["solitary", "trees", "grow", "at", "all", "strong"],
    words: ["solitary", "trees", "if", "they", "grow", "at", "all", "strong"],
    id: "Solitary trees, if they grow at all, grow strong.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["war", "god's", "way", "teaching", "americans", "geography"],
    words: ["war", "is", "god's", "way", "of", "teaching", "americans", "geography"],
    id: "War is God's way of teaching Americans geography.",
    author: "Ambrose Bierce",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["adoration", "caring", "god", "above", "all", "else"],
    words: ["adoration", "is", "caring", "for", "god", "above", "all", "else"],
    id: "Adoration is caring for God above all else.",
    author: "Evelyn Underhill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["bad", "faith", "discourse", "on", "friendship", "loyalty"],
    words: ["bad", "faith", "likes", "discourse", "on", "friendship", "and", "loyalty"],
    id: "Bad faith likes discourse on friendship and loyalty.",
    author: "Mason Cooley",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "dependent", "upon", "glands", "-", "sweat"],
    words: ["success", "is", "dependent", "upon", "the", "glands", "-", "sweat"],
    id: "Success is dependent upon the glands - sweat glands.",
    author: "Zig Ziglar",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["why", "waste", "time", "when", "ignorance", "instantaneous"],
    words: ["why", "waste", "time", "learning", "when", "ignorance", "is", "instantaneous"],
    id: "Why waste time learning, when ignorance is instantaneous?",
    author: "Bill Watterson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "cannot", "alter", "past", "though", "historians"],
    words: ["god", "cannot", "alter", "the", "past", "though", "historians", "can"],
    id: "God cannot alter the past, though historians can.",
    author: "Samuel Butler",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["deliberately", "seek", "opportunities", "kindness", "sympathy", "patience"],
    words: [
      "deliberately",
      "seek",
      "opportunities",
      "for",
      "kindness",
      "sympathy",
      "and",
      "patience",
    ],
    id: "Deliberately seek opportunities for kindness, sympathy, and patience.",
    author: "Evelyn Underhill",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "his", "hardest", "battles", "strongest", "soldiers"],
    words: ["god", "gives", "his", "hardest", "battles", "to", "strongest", "soldiers"],
    id: "God gives his hardest battles to his strongest soldiers.",
    author: "DeMarcus Cousins",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "public", "affair", "failure", "private", "funeral"],
    words: ["success", "is", "a", "public", "affair", "failure", "private", "funeral"],
    id: "Success is a public affair. Failure is a private funeral.",
    author: "Rosalind Russell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["wisdom", "only", "gets", "once", "got", "retains"],
    words: ["wisdom", "not", "only", "gets", "but", "once", "got", "retains"],
    id: "Wisdom not only gets, but once got, retains.",
    author: "Francis Quarles",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["even", "god", "lends", "hand", "honest", "boldness"],
    words: ["even", "god", "lends", "a", "hand", "to", "honest", "boldness"],
    id: "Even God lends a hand to honest boldness.",
    author: "Menander",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "knowledge", "lies", "knowing", "how", "live"],
    words: ["true", "knowledge", "lies", "in", "knowing", "how", "to", "live"],
    id: "True knowledge lies in knowing how to live.",
    author: "Baltasar Gracian",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "itself", "still", "remains", "effective", "therapist"],
    words: ["life", "itself", "still", "remains", "a", "very", "effective", "therapist"],
    id: "Life itself still remains a very effective therapist.",
    author: "Karen Horney",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["endurance", "nobler", "than", "strength", "patience", "beauty"],
    words: ["endurance", "is", "nobler", "than", "strength", "and", "patience", "beauty"],
    id: "Endurance is nobler than strength, and patience than beauty.",
    author: "John Ruskin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["politics", "friendship", "enmity", "intrigue", "even", "humour"],
    words: ["politics", "has", "friendship", "enmity", "intrigue", "and", "even", "humour"],
    id: "Politics has friendship, enmity, intrigue and even humour.",
    author: "Akshaye Khanna",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "human", "knowledge", "takes", "form", "interpretation"],
    words: ["all", "human", "knowledge", "takes", "the", "form", "of", "interpretation"],
    id: "All human knowledge takes the form of interpretation.",
    author: "Walter Benjamin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "attitude", "equally", "as", "important", "ability"],
    words: ["for", "success", "attitude", "is", "equally", "as", "important", "ability"],
    id: "For success, attitude is equally as important as ability.",
    author: "Walter Scott",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "buries", "his", "workmen", "carries", "on"],
    words: ["god", "buries", "his", "workmen", "but", "carries", "on", "work"],
    id: "God buries His workmen but carries on His work.",
    author: "Charles Wesley",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "skill", "plus", "ten", "thousand", "times"],
    words: ["knowledge", "is", "not", "skill", "plus", "ten", "thousand", "times"],
    id: "Knowledge is not skill. Knowledge plus ten thousand times is skill.",
    author: "Shinichi Suzuki",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["genius", "never", "understood", "its", "own", "time"],
    words: ["genius", "is", "never", "understood", "in", "its", "own", "time"],
    id: "Genius is never understood in its own time.",
    author: "Bill Watterson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["smallest", "deed", "better", "than", "greatest", "intention"],
    words: ["the", "smallest", "deed", "is", "better", "than", "greatest", "intention"],
    id: "The smallest deed is better than the greatest intention.",
    author: "John Burroughs",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["failure", "teaches", "lot", "more", "than", "success"],
    words: ["failure", "teaches", "you", "a", "lot", "more", "than", "success"],
    id: "Failure teaches you a lot more than success.",
    author: "Smriti Mandhana",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["oh", "my", "god", "nicole", "killed", "dead"],
    words: ["oh", "my", "god", "nicole", "is", "killed", "she", "dead"],
    id: "Oh my God, Nicole is killed? Oh my God, she is dead?",
    author: "O. J. Simpson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["friendship", "founded", "on", "business", "better", "than"],
    words: ["a", "friendship", "founded", "on", "business", "is", "better", "than"],
    id: "A friendship founded on business is better than a business founded on friendship.",
    author: "John D. Rockefeller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["separating", "your", "profession", "from", "friendship", "difficult"],
    words: ["separating", "your", "profession", "from", "friendship", "is", "very", "difficult"],
    id: "Separating your profession from friendship is very difficult.",
    author: "Lyoto Machida",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["success", "more", "difficult", "handle", "than", "failure"],
    words: ["success", "is", "more", "difficult", "to", "handle", "than", "failure"],
    id: "Success is more difficult to handle than failure.",
    author: "Ravi Zacharias",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["acceptance", "tolerance", "forgiveness", "those", "life-altering", "lessons"],
    words: [
      "acceptance",
      "and",
      "tolerance",
      "forgiveness",
      "those",
      "are",
      "life-altering",
      "lessons",
    ],
    id: "Acceptance and tolerance and forgiveness, those are life-altering lessons.",
    author: "Jessica Lange",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "always", "strives", "together", "those", "strive"],
    words: ["god", "always", "strives", "together", "with", "those", "who", "strive"],
    id: "God always strives together with those who strive.",
    author: "Aeschylus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["dissect", "failure", "lot", "more", "than", "success"],
    words: ["we", "dissect", "failure", "a", "lot", "more", "than", "success"],
    id: "We dissect failure a lot more than we dissect success.",
    author: "Matthew McConaughey",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["cannot", "put", "off", "living", "until", "ready"],
    words: ["we", "cannot", "put", "off", "living", "until", "are", "ready"],
    id: "We cannot put off living until we are ready.",
    author: "Jose Ortega y Gasset",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "had", "blood", "relatives", "till", "made"],
    words: ["i", "had", "no", "blood", "relatives", "till", "made", "some"],
    id: "I had no blood relatives till I made some.",
    author: "Andy Dick",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "i", "paint", "portrait", "lose", "friend"],
    words: ["every", "time", "i", "paint", "a", "portrait", "lose", "friend"],
    id: "Every time I paint a portrait I lose a friend.",
    author: "John Singer Sargent",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "may", "wisdom", "even", "from", "foe"],
    words: ["a", "man", "may", "learn", "wisdom", "even", "from", "foe"],
    id: "A man may learn wisdom even from a foe.",
    author: "Aristophanes",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["cast", "your", "on", "god", "anchor", "holds"],
    words: ["cast", "your", "cares", "on", "god", "that", "anchor", "holds"],
    id: "Cast your cares on God; that anchor holds.",
    author: "Frank Moore Colby",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "either", "great", "adventure", "or", "nothing"],
    words: ["life", "is", "either", "a", "great", "adventure", "or", "nothing"],
    id: "Life is either a great adventure or nothing.",
    author: "Helen Keller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "unique", "fighter", "-", "one", "kind"],
    words: ["i'm", "a", "unique", "fighter", "-", "one", "of", "kind"],
    id: "I'm a unique fighter - one of a kind.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["eyes", "more", "exact", "witnesses", "than", "ears"],
    words: ["the", "eyes", "are", "more", "exact", "witnesses", "than", "ears"],
    id: "The eyes are more exact witnesses than the ears.",
    author: "Heraclitus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["repentant", "tears", "wash", "out", "stain", "guilt"],
    words: ["repentant", "tears", "wash", "out", "the", "stain", "of", "guilt"],
    id: "Repentant tears wash out the stain of guilt.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["which", "obtain", "too", "easily", "esteem", "lightly"],
    words: ["that", "which", "we", "obtain", "too", "easily", "esteem", "lightly"],
    id: "That which we obtain too easily, we esteem too lightly.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["tis", "one", "thing", "tempted", "another", "fall"],
    words: ["tis", "one", "thing", "to", "be", "tempted", "another", "fall"],
    id: "'Tis one thing to be tempted, another thing to fall.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["genius", "one", "percent", "inspiration", "ninety-nine", "perspiration"],
    words: ["genius", "is", "one", "percent", "inspiration", "and", "ninety-nine", "perspiration"],
    id: "Genius is one percent inspiration and ninety-nine percent perspiration.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["ipod", "completely", "changed", "way", "approach", "music"],
    words: ["the", "ipod", "completely", "changed", "way", "people", "approach", "music"],
    id: "The iPod completely changed the way people approach music.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "start", "last", "man", "left", "off"],
    words: ["i", "start", "where", "the", "last", "man", "left", "off"],
    id: "I start where the last man left off.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["enjoy", "process", "far", "more", "than", "proceeds"],
    words: ["we", "enjoy", "the", "process", "far", "more", "than", "proceeds"],
    id: "We enjoy the process far more than the proceeds.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "always", "wonder", "when", "was", "embraced"],
    words: ["i", "always", "wonder", "when", "it", "was", "that", "embraced"],
    id: "I always wonder when it was that I was embraced.",
    author: "Joe Biden",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["law", "grinds", "poor", "rich", "men", "rule"],
    words: ["law", "grinds", "the", "poor", "and", "rich", "men", "rule"],
    id: "Law grinds the poor, and rich men rule the law.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["should", "favor", "innovation", "freedom", "over", "regulation"],
    words: ["we", "should", "favor", "innovation", "and", "freedom", "over", "regulation"],
    id: "We should favor innovation and freedom over regulation.",
    author: "George Allen",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "wondered", "time", "all", "my", "life"],
    words: ["i", "have", "wondered", "about", "time", "all", "my", "life"],
    id: "I have wondered about time all my life.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "constitution", "protects", "aliens", "drunks", "senators"],
    words: ["our", "constitution", "protects", "aliens", "drunks", "and", "us", "senators"],
    id: "Our constitution protects aliens, drunks and U.S. Senators.",
    author: "Will Rogers",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["nominated", "i", "will", "run", "elected", "serve"],
    words: ["if", "nominated", "i", "will", "not", "run", "elected", "serve"],
    id: "If nominated, I will not run; if elected, I will not serve.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["oh", "difference", "between", "nearly", "right", "exactly"],
    words: ["oh", "the", "difference", "between", "nearly", "right", "and", "exactly"],
    id: "Oh, the difference between nearly right and exactly right.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "advise", "comfortably", "from", "safe", "port"],
    words: ["one", "can", "advise", "comfortably", "from", "a", "safe", "port"],
    id: "One can advise comfortably from a safe port.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "abundance", "comes", "only", "through", "great"],
    words: ["life", "in", "abundance", "comes", "only", "through", "great", "love"],
    id: "Life in abundance comes only through great love.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["desire", "many", "things", "will", "seem", "few"],
    words: ["if", "you", "desire", "many", "things", "will", "seem", "few"],
    id: "If you desire many things, many things will seem few.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["charms", "strike", "sight", "merit", "wins", "soul"],
    words: ["charms", "strike", "the", "sight", "but", "merit", "wins", "soul"],
    id: "Charms strike the sight, but merit wins the soul.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["masses", "always", "breeding", "grounds", "psychic", "epidemics"],
    words: ["masses", "are", "always", "breeding", "grounds", "of", "psychic", "epidemics"],
    id: "Masses are always breeding grounds of psychic epidemics.",
    author: "Carl Jung",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "your", "beliefs", "turn", "world", "around"],
    words: ["live", "your", "beliefs", "and", "you", "can", "turn", "the", "world", "around"],
    id: "Live your beliefs and you can turn the world around.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["many", "men", "minds", "certainly", "hearts", "kinds"],
    words: ["if", "so", "many", "men", "minds", "certainly", "hearts", "kinds", "of", "love"],
    id: "If so many men, so many minds, certainly so many hearts, so many kinds of love.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["less", "effort", "faster", "more", "powerful", "will"],
    words: ["the", "less", "effort", "faster", "and", "more", "powerful", "you", "will", "be"],
    id: "The less effort, the faster and more powerful you will be.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["prayer", "confession", "one's", "own", "unworthiness", "weakness"],
    words: [
      "prayer",
      "is",
      "a",
      "confession",
      "of",
      "one's",
      "own",
      "unworthiness",
      "and",
      "weakness",
    ],
    id: "Prayer is a confession of one's own unworthiness and weakness.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["how", "played", "yesterday's", "game", "all", "counts"],
    words: ["how", "you", "played", "in", "yesterday's", "game", "is", "all", "that", "counts"],
    id: "How you played in yesterday's game is all that counts.",
    author: "Jackie Robinson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["morality", "basis", "things", "truth", "substance", "all"],
    words: ["morality", "is", "the", "basis", "of", "things", "and", "truth", "substance", "all"],
    id: "Morality is the basis of things and truth is the substance of all morality.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["health", "sense", "two", "life's", "greatest", "blessings"],
    words: [
      "good",
      "health",
      "and",
      "sense",
      "are",
      "two",
      "of",
      "life's",
      "greatest",
      "blessings",
    ],
    id: "Good health and good sense are two of life's greatest blessings.",
    author: "Publilius Syrus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i've", "always", "found", "speed", "boss", "team"],
    words: ["i've", "always", "found", "that", "the", "speed", "of", "boss", "is", "team"],
    id: "I've always found that the speed of the boss is the speed of the team.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["faithless", "says", "farewell", "when", "road", "darkens"],
    words: ["faithless", "is", "he", "that", "says", "farewell", "when", "the", "road", "darkens"],
    id: "Faithless is he that says farewell when the road darkens.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["first", "greatest", "punishment", "sinner", "conscience", "sin"],
    words: [
      "the",
      "first",
      "and",
      "greatest",
      "punishment",
      "of",
      "sinner",
      "is",
      "conscience",
      "sin",
    ],
    id: "The first and greatest punishment of the sinner is the conscience of sin.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["humor", "health", "soul", "sadness", "its", "poison"],
    words: ["good", "humor", "is", "the", "health", "of", "soul", "sadness", "its", "poison"],
    id: "Good humor is the health of the soul, sadness is its poison.",
    author: "Philip Stanhope, 4th Earl of Chesterfield",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["get", "paid", "bringing", "value", "market", "place"],
    words: ["we", "get", "paid", "for", "bringing", "value", "to", "the", "market", "place"],
    id: "We get paid for bringing value to the market place.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["won't", "create", "success", "freedom", "make", "will"],
    words: ["money", "won't", "create", "success", "the", "freedom", "to", "make", "it", "will"],
    id: "Money won't create success, the freedom to make it will.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["wherever", "there", "human", "being", "opportunity", "kindness"],
    words: [
      "wherever",
      "there",
      "is",
      "a",
      "human",
      "being",
      "an",
      "opportunity",
      "for",
      "kindness",
    ],
    id: "Wherever there is a human being, there is an opportunity for a kindness.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["dreads", "hostility", "too", "much", "unfit", "rule"],
    words: ["he", "who", "dreads", "hostility", "too", "much", "is", "unfit", "to", "rule"],
    id: "He who dreads hostility too much is unfit to rule.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "instructed", "society", "inspired", "only", "solitude"],
    words: [
      "one",
      "can",
      "be",
      "instructed",
      "in",
      "society",
      "is",
      "inspired",
      "only",
      "solitude",
    ],
    id: "One can be instructed in society, one is inspired only in solitude.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["human", "mind", "will", "confined", "any", "limits"],
    words: ["the", "human", "mind", "will", "not", "be", "confined", "to", "any", "limits"],
    id: "The human mind will not be confined to any limits.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["wisdom", "abstract", "past", "beauty", "promise", "future"],
    words: ["wisdom", "is", "the", "abstract", "of", "past", "but", "beauty", "promise", "future"],
    id: "Wisdom is the abstract of the past, but beauty is the promise of the future.",
    author: "Oliver Wendell Holmes, Sr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "had", "wanted", "otherwise", "would", "created"],
    words: ["if", "god", "had", "wanted", "me", "otherwise", "he", "would", "have", "created"],
    id: "If God had wanted me otherwise, He would have created me otherwise.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["goal", "education", "advancement", "knowledge", "dissemination", "truth"],
    words: [
      "the",
      "goal",
      "of",
      "education",
      "is",
      "advancement",
      "knowledge",
      "and",
      "dissemination",
      "truth",
    ],
    id: "The goal of education is the advancement of knowledge and the dissemination of truth.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["minute", "thought", "greater", "than", "hour", "talk"],
    words: ["a", "minute", "of", "thought", "is", "greater", "than", "an", "hour", "talk"],
    id: "A minute of thought is greater than an hour of talk.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["natural", "forces", "within", "true", "healers", "disease"],
    words: ["natural", "forces", "within", "us", "are", "the", "true", "healers", "of", "disease"],
    id: "Natural forces within us are the true healers of disease.",
    author: "Hippocrates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["old", "age", "record", "one's", "whole", "life"],
    words: ["old", "age", "is", "just", "a", "record", "of", "one's", "whole", "life"],
    id: "Old age is just a record of one's whole life.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["being", "hero", "shortest-lived", "profession", "on", "earth"],
    words: [
      "being",
      "a",
      "hero",
      "is",
      "about",
      "the",
      "shortest-lived",
      "profession",
      "on",
      "earth",
    ],
    id: "Being a hero is about the shortest-lived profession on earth.",
    author: "Will Rogers",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyone", "thinks", "changing", "world", "one", "himself"],
    words: ["everyone", "thinks", "of", "changing", "the", "world", "but", "no", "one", "himself"],
    id: "Everyone thinks of changing the world, but no one thinks of changing himself.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "long", "preparation", "something", "never", "happens"],
    words: [
      "life",
      "is",
      "a",
      "long",
      "preparation",
      "for",
      "something",
      "that",
      "never",
      "happens",
    ],
    id: "Life is a long preparation for something that never happens.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["deliberate", "violence", "more", "quenched", "than", "fire"],
    words: ["deliberate", "violence", "is", "more", "to", "be", "quenched", "than", "a", "fire"],
    id: "Deliberate violence is more to be quenched than a fire.",
    author: "Heraclitus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["why", "jewish", "divorces", "cost", "much", "worth"],
    words: ["why", "do", "jewish", "divorces", "cost", "so", "much", "they're", "worth", "it"],
    id: "Why do Jewish divorces cost so much? They're worth it.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["lends", "kind", "enchantment", "all", "our", "surroundings"],
    words: ["study", "lends", "a", "kind", "of", "enchantment", "to", "all", "our", "surroundings"],
    id: "Study lends a kind of enchantment to all our surroundings.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "policy", "on", "pro", "having", "eating"],
    words: ["my", "policy", "on", "cake", "is", "pro", "having", "it", "and", "eating"],
    id: "My policy on cake is pro having it and pro eating it.",
    author: "Boris Johnson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "sorrows", "come", "single", "spies", "battalions"],
    words: ["when", "sorrows", "come", "they", "not", "single", "spies", "but", "in", "battalions"],
    id: "When sorrows come, they come not single spies, but in battalions.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "pain", "breaking", "shell", "encloses", "understanding"],
    words: [
      "your",
      "pain",
      "is",
      "the",
      "breaking",
      "of",
      "shell",
      "that",
      "encloses",
      "understanding",
    ],
    id: "Your pain is the breaking of the shell that encloses your understanding.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "scares", "more", "than", "doll", "collection"],
    words: [
      "nothing",
      "scares",
      "me",
      "more",
      "than",
      "people",
      "with",
      "some",
      "doll",
      "collection",
    ],
    id: "Nothing scares me more than people with some doll collection.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "music", "one", "hero/sheroes", "african-american", "existence"],
    words: [
      "i",
      "think",
      "music",
      "is",
      "one",
      "of",
      "the",
      "hero/sheroes",
      "african-american",
      "existence",
    ],
    id: "I think music is one of the hero/sheroes of the African-American existence.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "retreat", "explain", "get", "done", "howl"],
    words: ["never", "retreat", "explain", "get", "it", "done", "and", "let", "them", "howl"],
    id: "Never retreat. Never explain. Get it done and let them howl.",
    author: "Benjamin Jowett",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "another", "things", "can't", "gift", "wrapped"],
    words: ["people", "give", "one", "another", "things", "that", "can't", "be", "gift", "wrapped"],
    id: "People give one another things that can't be gift wrapped.",
    author: "Nadine Gordimer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "i", "over", "said", "envy", "dumb"],
    words: ["when", "i", "think", "over", "what", "have", "said", "envy", "dumb", "people"],
    id: "When I think over what I have said, I envy dumb people.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["writing", "cookery", "two", "different", "means", "communication"],
    words: [
      "writing",
      "and",
      "cookery",
      "are",
      "just",
      "two",
      "different",
      "means",
      "of",
      "communication",
    ],
    id: "Writing and cookery are just two different means of communication.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["sincere", "diplomat", "dry", "or", "wooden", "iron"],
    words: ["a", "sincere", "diplomat", "is", "like", "dry", "water", "or", "wooden", "iron"],
    id: "A sincere diplomat is like dry water or wooden iron.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "peace", "which", "passeth", "understanding", "bringeth"],
    words: [
      "i",
      "do",
      "not",
      "want",
      "the",
      "peace",
      "which",
      "passeth",
      "understanding",
      "bringeth",
    ],
    id: "I do not want the peace which passeth understanding, I want the understanding which bringeth peace.",
    author: "Helen Keller",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["many", "ways", "segregation", "shaped", "education", "liberated"],
    words: [
      "in",
      "so",
      "many",
      "ways",
      "segregation",
      "shaped",
      "me",
      "and",
      "education",
      "liberated",
    ],
    id: "In so many ways, segregation shaped me, and education liberated me.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["land", "ain't", "making", "any", "more", "stuff"],
    words: ["buy", "land", "they", "ain't", "making", "any", "more", "of", "the", "stuff"],
    id: "Buy land. They ain't making any more of the stuff.",
    author: "Will Rogers",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["any", "fool", "make", "rule", "will", "mind"],
    words: ["any", "fool", "can", "make", "a", "rule", "and", "will", "mind", "it"],
    id: "Any fool can make a rule, and any fool will mind it.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "always", "hated", "bowling", "mind", "admitting"],
    words: ["i", "have", "always", "hated", "bowling", "and", "don't", "mind", "admitting", "it"],
    id: "I have always hated bowling, and I don't mind admitting it.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "style", "anyone's", "i'm", "typical", "wrestler"],
    words: ["my", "style", "is", "not", "like", "anyone's", "i'm", "a", "typical", "wrestler"],
    id: "My style is not like anyone's. I'm not a typical wrestler.",
    author: "Khabib Nurmagomedov",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["delusion", "one", "woman", "differs", "from", "another"],
    words: ["love", "is", "the", "delusion", "that", "one", "woman", "differs", "from", "another"],
    id: "Love is the delusion that one woman differs from another.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["politician", "quite", "as", "unthinkable", "honest", "burglar"],
    words: [
      "a",
      "good",
      "politician",
      "is",
      "quite",
      "as",
      "unthinkable",
      "an",
      "honest",
      "burglar",
    ],
    id: "A good politician is quite as unthinkable as an honest burglar.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "birthdays", "feathers", "broad", "wing", "time"],
    words: ["our", "birthdays", "are", "feathers", "in", "the", "broad", "wing", "of", "time"],
    id: "Our birthdays are feathers in the broad wing of time.",
    author: "Jean Paul",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["completely", "sane", "world", "madness", "only", "freedom"],
    words: ["in", "a", "completely", "sane", "world", "madness", "is", "the", "only", "freedom"],
    id: "In a completely sane world, madness is the only freedom.",
    author: "J. G. Ballard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "had", "second", "chance", "how", "incredible"],
    words: ["i", "had", "a", "second", "chance", "know", "how", "incredible", "that", "is"],
    id: "I had a second chance. I know how incredible that is.",
    author: "Susan Rosenberg",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "characters", "change", "from", "their", "mistakes"],
    words: [
      "i",
      "like",
      "characters",
      "who",
      "don't",
      "change",
      "learn",
      "from",
      "their",
      "mistakes",
    ],
    id: "I like characters who don't change, who don't learn from their mistakes.",
    author: "David Fincher",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "makes", "up", "height", "lacks", "length"],
    words: ["happiness", "makes", "up", "in", "height", "for", "what", "it", "lacks", "length"],
    id: "Happiness makes up in height for what it lacks in length.",
    author: "Robert Frost",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "contagious", "why", "despots", "fear", "much"],
    words: ["freedom", "is", "contagious", "that's", "why", "despots", "fear", "it", "so", "much"],
    id: "Freedom is contagious. That's why despots fear it so much.",
    author: "Bill Owens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["because", "everything", "different", "mean", "anything", "changed"],
    words: [
      "just",
      "because",
      "everything",
      "is",
      "different",
      "doesn't",
      "mean",
      "anything",
      "has",
      "changed",
    ],
    id: "Just because everything is different doesn't mean anything has changed.",
    author: "Irene Peter",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["education", "key", "unlock", "golden", "door", "freedom"],
    words: ["education", "is", "the", "key", "to", "unlock", "golden", "door", "of", "freedom"],
    id: "Education is the key to unlock the golden door of freedom.",
    author: "George Washington Carver",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["more", "practice", "better", "get", "freedom", "create"],
    words: ["the", "more", "you", "practice", "better", "get", "freedom", "have", "to", "create"],
    id: "The more you practice, the better you get, the more freedom you have to create.",
    author: "Jocko Willink",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["economic", "freedom", "cannot", "sacrificed", "political", "preserved"],
    words: [
      "economic",
      "freedom",
      "cannot",
      "be",
      "sacrificed",
      "if",
      "political",
      "is",
      "to",
      "preserved",
    ],
    id: "Economic freedom cannot be sacrificed if political freedom is to be preserved.",
    author: "Herbert Hoover",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["can't", "change", "happened", "still", "will", "happen"],
    words: ["you", "can't", "change", "what", "happened", "but", "can", "still", "will", "happen"],
    id: "You can't change what happened. But you can still change what will happen.",
    author: "Sebastian Vettel",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["end", "all", "knowledge", "should", "service", "others"],
    words: ["the", "end", "of", "all", "knowledge", "should", "be", "service", "to", "others"],
    id: "The end of all knowledge should be service to others.",
    author: "Cesar Chavez",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["christmas", "joy", "religious", "inner", "light", "peace"],
    words: ["christmas", "is", "joy", "religious", "an", "inner", "of", "light", "and", "peace"],
    id: "Christmas is joy, religious joy, an inner joy of light and peace.",
    author: "Pope Francis",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["luck", "always", "last", "refuge", "laziness", "incompetence"],
    words: [
      "luck",
      "is",
      "always",
      "the",
      "last",
      "refuge",
      "of",
      "laziness",
      "and",
      "incompetence",
    ],
    id: "Luck is always the last refuge of laziness and incompetence.",
    author: "James Cash Penney",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["freedom", "right", "one's", "dignity", "as", "man"],
    words: ["freedom", "is", "the", "right", "to", "one's", "dignity", "as", "a", "man"],
    id: "Freedom is the right to one's dignity as a man.",
    author: "Archibald MacLeish",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "always", "tell", "health", "first", "wealth"],
    words: ["i", "always", "tell", "people", "that", "health", "is", "the", "first", "wealth"],
    id: "I always tell people that health is the first wealth.",
    author: "Rev. Run",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "weapon", "i", "intend", "formidably", "armed"],
    words: ["knowledge", "is", "a", "weapon", "i", "intend", "to", "be", "formidably", "armed"],
    id: "Knowledge is a weapon. I intend to be formidably armed.",
    author: "Terry Goodkind",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "never", "learned", "from", "man", "agreed"],
    words: ["i", "never", "learned", "from", "a", "man", "who", "agreed", "with", "me"],
    id: "I never learned from a man who agreed with me.",
    author: "Robert A. Heinlein",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "statesman", "one", "willing", "take", "risks"],
    words: ["the", "true", "statesman", "is", "one", "who", "willing", "to", "take", "risks"],
    id: "The true statesman is the one who is willing to take risks.",
    author: "Charles de Gaulle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["science", "father", "knowledge", "opinion", "breeds", "ignorance"],
    words: [
      "science",
      "is",
      "the",
      "father",
      "of",
      "knowledge",
      "but",
      "opinion",
      "breeds",
      "ignorance",
    ],
    id: "Science is the father of knowledge, but opinion breeds ignorance.",
    author: "Hippocrates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["genuine", "forgiveness", "deny", "anger", "faces", "head-on"],
    words: [
      "genuine",
      "forgiveness",
      "does",
      "not",
      "deny",
      "anger",
      "but",
      "faces",
      "it",
      "head-on",
    ],
    id: "Genuine forgiveness does not deny anger but faces it head-on.",
    author: "Alice Miller",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["extent", "our", "progress", "cultivation", "knowledge", "unlimited"],
    words: [
      "the",
      "extent",
      "of",
      "our",
      "progress",
      "in",
      "cultivation",
      "knowledge",
      "is",
      "unlimited",
    ],
    id: "The extent of our progress in the cultivation of knowledge is unlimited.",
    author: "William Godwin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "affairs", "men", "worthy", "great", "anxiety"],
    words: ["nothing", "in", "the", "affairs", "of", "men", "is", "worthy", "great", "anxiety"],
    id: "Nothing in the affairs of men is worthy of great anxiety.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["everything", "life", "really", "accept", "undergoes", "change"],
    words: [
      "everything",
      "in",
      "life",
      "that",
      "we",
      "really",
      "accept",
      "undergoes",
      "a",
      "change",
    ],
    id: "Everything in life that we really accept undergoes a change.",
    author: "Katherine Mansfield",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["power", "social", "media", "forces", "necessary", "change"],
    words: ["the", "power", "of", "social", "media", "is", "it", "forces", "necessary", "change"],
    id: "The power of social media is it forces necessary change.",
    author: "Erik Qualman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["being", "princess", "isn't", "all", "cracked", "up"],
    words: ["being", "a", "princess", "isn't", "all", "it's", "cracked", "up", "to", "be"],
    id: "Being a princess isn't all it's cracked up to be.",
    author: "Princess Diana",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["knowledge", "unending", "adventure", "at", "edge", "uncertainty"],
    words: [
      "knowledge",
      "is",
      "an",
      "unending",
      "adventure",
      "at",
      "the",
      "edge",
      "of",
      "uncertainty",
    ],
    id: "Knowledge is an unending adventure at the edge of uncertainty.",
    author: "Jacob Bronowski",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["maintaining", "health", "should", "primary", "focus", "everyone"],
    words: [
      "maintaining",
      "good",
      "health",
      "should",
      "be",
      "the",
      "primary",
      "focus",
      "of",
      "everyone",
    ],
    id: "Maintaining good health should be the primary focus of everyone.",
    author: "Sangram Singh",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "person", "cannot", "heal", "soul", "save"],
    words: ["there", "is", "no", "person", "that", "love", "cannot", "heal", "soul", "save"],
    id: "There is no person that love cannot heal; there is no soul that love cannot save.",
    author: "Carlos Santana",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["almost", "everybody", "born", "genius", "buried", "idiot"],
    words: ["almost", "everybody", "is", "born", "a", "genius", "and", "buried", "an", "idiot"],
    id: "Almost everybody is born a genius and buried an idiot.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "strong", "enough", "bear", "men's", "misfortunes"],
    words: ["we", "are", "all", "strong", "enough", "to", "bear", "other", "men's", "misfortunes"],
    id: "We are all strong enough to bear other men's misfortunes.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["must", "there", "as", "many", "squares", "numbers"],
    words: ["we", "must", "say", "that", "there", "are", "as", "many", "squares", "numbers"],
    id: "We must say that there are as many squares as there are numbers.",
    author: "Galileo Galilei",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["firm", "enduring", "simple", "modest", "near", "virtue"],
    words: ["the", "firm", "enduring", "simple", "and", "modest", "are", "near", "to", "virtue"],
    id: "The firm, the enduring, the simple, and the modest are near to virtue.",
    author: "Confucius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "keen", "on", "idea", "being", "unconscious"],
    words: ["i'm", "not", "that", "keen", "on", "the", "idea", "of", "being", "unconscious"],
    id: "I'm not that keen on the idea of being unconscious.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["long", "as", "lose", "any", "happiness", "possess"],
    words: ["so", "long", "as", "we", "can", "lose", "any", "happiness", "possess", "some"],
    id: "So long as we can lose any happiness, we possess some.",
    author: "Booth Tarkington",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "many", "virtuous", "woman", "weary", "trade"],
    words: ["there", "is", "many", "a", "virtuous", "woman", "weary", "of", "her", "trade"],
    id: "There is many a virtuous woman weary of her trade.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["wealth", "consists", "having", "great", "possessions", "few"],
    words: [
      "wealth",
      "consists",
      "not",
      "in",
      "having",
      "great",
      "possessions",
      "but",
      "few",
      "wants",
    ],
    id: "Wealth consists not in having great possessions, but in having few wants.",
    author: "Epictetus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["ill", "back", "always", "sounded", "little", "girly"],
    words: ["ill", "be", "back", "always", "sounded", "a", "little", "girly", "to", "me"],
    id: "'I'll be back' always sounded a little girly to me.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["how", "strange", "nature", "knock", "yet", "intrude"],
    words: ["how", "strange", "that", "nature", "does", "not", "knock", "and", "yet", "intrude"],
    id: "How strange that nature does not knock, and yet does not intrude!",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["longer", "save", "world", "by", "playing", "rules"],
    words: ["we", "can", "no", "longer", "save", "the", "world", "by", "playing", "rules"],
    id: "We can no longer save the world by playing by the rules.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["ego", "nothing", "than", "focus", "conscious", "attention"],
    words: [
      "the",
      "ego",
      "is",
      "nothing",
      "other",
      "than",
      "focus",
      "of",
      "conscious",
      "attention",
    ],
    id: "The ego is nothing other than the focus of conscious attention.",
    author: "Alan Watts",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["nature", "our", "eldest", "mother", "will", "harm"],
    words: ["nature", "is", "our", "eldest", "mother", "she", "will", "do", "no", "harm"],
    id: "Nature is our eldest mother; she will do no harm.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["destroy", "always", "first", "step", "any", "creation"],
    words: ["to", "destroy", "is", "always", "the", "first", "step", "in", "any", "creation"],
    id: "To destroy is always the first step in any creation.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["action", "may", "bring", "happiness", "there", "without"],
    words: ["action", "may", "not", "bring", "happiness", "but", "there", "is", "no", "without"],
    id: "Action may not bring happiness but there is no happiness without action.",
    author: "William James",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["somebody", "was", "once", "nobody", "wanted", "did"],
    words: ["a", "somebody", "was", "once", "nobody", "who", "wanted", "to", "and", "did"],
    id: "A somebody was once a nobody who wanted to and did.",
    author: "John Burroughs",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["humility", "make", "right", "estimate", "one's", "self"],
    words: ["humility", "is", "to", "make", "a", "right", "estimate", "of", "one's", "self"],
    id: "Humility is to make a right estimate of one's self.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["roots", "all", "goodness", "lie", "soil", "appreciation"],
    words: ["the", "roots", "of", "all", "goodness", "lie", "in", "soil", "appreciation", "for"],
    id: "The roots of all goodness lie in the soil of appreciation for goodness.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "human", "beings", "commingled", "out", "evil"],
    words: ["all", "human", "beings", "are", "commingled", "out", "of", "good", "and", "evil"],
    id: "All human beings are commingled out of good and evil.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["wisest", "use", "american", "strength", "advance", "freedom"],
    words: ["the", "wisest", "use", "of", "american", "strength", "is", "to", "advance", "freedom"],
    id: "The wisest use of American strength is to advance freedom.",
    author: "George W. Bush",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["scare", "worth", "more", "man", "than", "advice"],
    words: ["a", "good", "scare", "is", "worth", "more", "to", "man", "than", "advice"],
    id: "A good scare is worth more to a man than good advice.",
    author: "E. W. Howe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["therefore", "man", "must", "end", "science", "politics"],
    words: ["therefore", "the", "good", "of", "man", "must", "be", "end", "science", "politics"],
    id: "Therefore, the good of man must be the end of the science of politics.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["forget", "one's", "purpose", "commonest", "form", "stupidity"],
    words: [
      "to",
      "forget",
      "one's",
      "purpose",
      "is",
      "the",
      "commonest",
      "form",
      "of",
      "stupidity",
    ],
    id: "To forget one's purpose is the commonest form of stupidity.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["easier", "prevent", "bad", "habits", "than", "break"],
    words: ["it", "is", "easier", "to", "prevent", "bad", "habits", "than", "break", "them"],
    id: "It is easier to prevent bad habits than to break them.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "could", "wonderful", "would", "leave", "alone"],
    words: ["life", "could", "be", "wonderful", "if", "people", "would", "leave", "you", "alone"],
    id: "Life could be wonderful if people would leave you alone.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "consider", "myself", "as", "free", "spokesman"],
    words: ["i", "consider", "myself", "as", "a", "free", "spokesman", "for", "the", "people"],
    id: "I consider myself as a free spokesman for the people.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "damnedest", "ostentatious", "manner", "all", "time"],
    words: ["do", "your", "damnedest", "in", "an", "ostentatious", "manner", "all", "the", "time"],
    id: "Do your damnedest in an ostentatious manner all the time.",
    author: "George S. Patton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["reward", "one", "duty", "power", "fulfill", "another"],
    words: ["the", "reward", "of", "one", "duty", "is", "power", "to", "fulfill", "another"],
    id: "The reward of one duty is the power to fulfill another.",
    author: "George Eliot",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["pessimism", "luxury", "jew", "never", "allow", "himself"],
    words: ["pessimism", "is", "a", "luxury", "that", "jew", "can", "never", "allow", "himself"],
    id: "Pessimism is a luxury that a Jew can never allow himself.",
    author: "Golda Meir",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["sun", "too", "shines", "into", "cesspools", "polluted"],
    words: ["the", "sun", "too", "shines", "into", "cesspools", "and", "is", "not", "polluted"],
    id: "The sun, too, shines into cesspools and is not polluted.",
    author: "Diogenes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["woman", "man", "means", "end", "always", "child"],
    words: ["for", "the", "woman", "man", "is", "a", "means", "end", "always", "child"],
    id: "For the woman, the man is a means: the end is always the child.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "must", "govern", "clock", "governed", "by"],
    words: ["i", "must", "govern", "the", "clock", "not", "be", "governed", "by", "it"],
    id: "I must govern the clock, not be governed by it.",
    author: "Golda Meir",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["company", "journey", "makes", "way", "seem", "shorter"],
    words: ["good", "company", "in", "a", "journey", "makes", "the", "way", "seem", "shorter"],
    id: "Good company in a journey makes the way seem shorter.",
    author: "Izaak Walton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["americans", "fight", "all", "real", "sting", "battle"],
    words: ["americans", "love", "to", "fight", "all", "real", "the", "sting", "of", "battle"],
    id: "Americans love to fight. All real Americans love the sting of battle.",
    author: "George S. Patton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["india", "more", "aid", "recipient", "than", "provider"],
    words: ["india", "is", "more", "of", "an", "aid", "recipient", "than", "a", "provider"],
    id: "India is more of an aid recipient than a provider of aid.",
    author: "Bill Gates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "evangelical", "on", "subject", "chefs", "writers"],
    words: ["i'm", "evangelical", "on", "the", "subject", "of", "some", "chefs", "and", "writers"],
    id: "I'm evangelical on the subject of some chefs and writers.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["will", "succeed", "important", "what's", "more", "prepare"],
    words: ["the", "will", "to", "succeed", "is", "important", "but", "what's", "more", "prepare"],
    id: "The will to succeed is important, but what's more important is the will to prepare.",
    author: "Bobby Knight",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["moral", "systems", "religion", "i", "super", "important"],
    words: ["the", "moral", "systems", "of", "religion", "i", "think", "are", "super", "important"],
    id: "The moral systems of religion, I think, are super important.",
    author: "Bill Gates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "those", "fit", "live", "afraid", "die"],
    words: ["only", "those", "are", "fit", "to", "live", "who", "not", "afraid", "die"],
    id: "Only those are fit to live who are not afraid to die.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["art", "hidden", "doorway", "change", "people's", "emotions"],
    words: ["art", "is", "a", "hidden", "doorway", "that", "can", "change", "people's", "emotions"],
    id: "Art is a hidden doorway that can change people's emotions.",
    author: "Akiane Kramarik",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "sex", "fantasy", "nobody", "ever", "mind"],
    words: ["in", "my", "sex", "fantasy", "nobody", "ever", "loves", "me", "for", "mind"],
    id: "In my sex fantasy, nobody ever loves me for my mind.",
    author: "Nora Ephron",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "taken", "more", "out", "alcohol", "than"],
    words: ["i", "have", "taken", "more", "out", "of", "alcohol", "than", "has", "me"],
    id: "I have taken more out of alcohol than alcohol has taken out of me.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["whenever", "i", "followed", "by", "called", "ego"],
    words: ["whenever", "i", "climb", "am", "followed", "by", "a", "dog", "called", "ego"],
    id: "Whenever I climb I am followed by a dog called 'Ego'.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["art", "acting", "consists", "keeping", "from", "coughing"],
    words: [
      "the",
      "art",
      "of",
      "acting",
      "consists",
      "in",
      "keeping",
      "people",
      "from",
      "coughing",
    ],
    id: "The art of acting consists in keeping people from coughing.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["rumours", "demise", "manufacturing", "industry", "greatly", "exaggerated"],
    words: [
      "the",
      "rumours",
      "of",
      "demise",
      "us",
      "manufacturing",
      "industry",
      "are",
      "greatly",
      "exaggerated",
    ],
    id: "The rumours of the demise of the U.S. manufacturing industry are greatly exaggerated.",
    author: "Elon Musk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "great", "genius", "without", "touch", "madness"],
    words: ["there", "is", "no", "great", "genius", "without", "some", "touch", "of", "madness"],
    id: "There is no great genius without some touch of madness.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "there", "should", "regulations", "on", "ai"],
    words: ["i", "do", "think", "there", "should", "be", "some", "regulations", "on", "ai"],
    id: "I do think there should be some regulations on AI.",
    author: "Elon Musk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["composed", "single", "soul", "inhabiting", "two", "bodies"],
    words: ["love", "is", "composed", "of", "a", "single", "soul", "inhabiting", "two", "bodies"],
    id: "Love is composed of a single soul inhabiting two bodies.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "meanings", "depend", "on", "key", "interpretation"],
    words: ["all", "meanings", "we", "know", "depend", "on", "the", "key", "of", "interpretation"],
    id: "All meanings, we know, depend on the key of interpretation.",
    author: "George Eliot",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["ruling", "passion", "will", "conquers", "reason", "still"],
    words: ["the", "ruling", "passion", "be", "it", "what", "will", "conquers", "reason", "still"],
    id: "The ruling passion, be it what it will. The ruling passion conquers reason still.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["war", "events", "importance", "result", "trivial", "causes"],
    words: ["in", "war", "events", "of", "importance", "are", "the", "result", "trivial", "causes"],
    id: "In war, events of importance are the result of trivial causes.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "one", "had", "father", "must", "create"],
    words: ["when", "one", "has", "not", "had", "a", "good", "father", "must", "create"],
    id: "When one has not had a good father, one must create one.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "man's", "character", "real", "criterion", "worth"],
    words: ["only", "a", "man's", "character", "is", "the", "real", "criterion", "of", "worth"],
    id: "Only a man's character is the real criterion of worth.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "must", "account", "idle", "word", "silence"],
    words: ["as", "we", "must", "account", "for", "every", "idle", "word", "so", "silence"],
    id: "As we must account for every idle word, so must we account for every idle silence.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["secret", "happiness", "face", "fact", "world", "horrible"],
    words: ["the", "secret", "to", "happiness", "is", "face", "fact", "that", "world", "horrible"],
    id: "The secret to happiness is to face the fact that the world is horrible.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "nothing", "men", "generous", "as", "advice"],
    words: ["there", "is", "nothing", "men", "are", "so", "generous", "of", "as", "advice"],
    id: "There is nothing men are so generous of as advice.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "argue", "thee", "life", "hath", "immortality"],
    words: ["i", "argue", "thee", "that", "love", "is", "life", "and", "hath", "immortality"],
    id: "I argue thee that love is life. And life hath immortality.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "would", "die", "on", "mars", "impact"],
    words: ["i", "would", "like", "to", "die", "on", "mars", "just", "not", "impact"],
    id: "I would like to die on Mars. Just not on impact.",
    author: "Elon Musk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["persistence", "character", "man", "as", "carbon", "steel"],
    words: ["persistence", "is", "to", "the", "character", "of", "man", "as", "carbon", "steel"],
    id: "Persistence is to the character of man as carbon is to steel.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["protestor", "i", "will", "up", "world's", "poorest"],
    words: ["the", "protestor", "i", "think", "will", "speak", "up", "for", "world's", "poorest"],
    id: "The protestor I think will speak up for the world's poorest.",
    author: "Bill Gates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "make", "ends", "meet", "somebody", "moves"],
    words: ["about", "the", "time", "we", "can", "make", "ends", "meet", "somebody", "moves"],
    id: "About the time we can make the ends meet, somebody moves the ends.",
    author: "Herbert Hoover",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["war", "determine", "right", "-", "only", "left"],
    words: ["war", "does", "not", "determine", "who", "is", "right", "-", "only", "left"],
    id: "War does not determine who is right - only who is left.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["force", "fraud", "war", "two", "cardinal", "virtues"],
    words: ["force", "and", "fraud", "are", "in", "war", "the", "two", "cardinal", "virtues"],
    id: "Force and fraud are in war the two cardinal virtues.",
    author: "Thomas Hobbes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["believe", "only", "possibilities", "faith", "mere", "philosophy"],
    words: [
      "to",
      "believe",
      "only",
      "possibilities",
      "is",
      "not",
      "faith",
      "but",
      "mere",
      "philosophy",
    ],
    id: "To believe only possibilities is not faith, but mere philosophy.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i've", "sparred", "lots", "played", "around", "everybody"],
    words: [
      "i've",
      "sparred",
      "with",
      "lots",
      "of",
      "people",
      "and",
      "played",
      "around",
      "everybody",
    ],
    id: "I've sparred with lots of people and played around with everybody.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["being", "brilliant", "great", "feat", "respect", "nothing"],
    words: ["being", "brilliant", "is", "no", "great", "feat", "if", "you", "respect", "nothing"],
    id: "Being brilliant is no great feat if you respect nothing.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["wars", "never", "hurt", "anybody", "except", "die"],
    words: ["wars", "have", "never", "hurt", "anybody", "except", "the", "people", "who", "die"],
    id: "Wars have never hurt anybody except the people who die.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["liberty", "individual", "necessary", "postulate", "human", "progress"],
    words: [
      "the",
      "liberty",
      "of",
      "individual",
      "is",
      "a",
      "necessary",
      "postulate",
      "human",
      "progress",
    ],
    id: "The liberty of the individual is a necessary postulate of human progress.",
    author: "Ernest Renan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "mankind", "far", "more", "than", "name"],
    words: ["to", "live", "in", "mankind", "is", "far", "more", "than", "a", "name"],
    id: "To live in mankind is far more than to live in a name.",
    author: "Vachel Lindsay",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["although", "prepared", "martyrdom", "i", "preferred", "postponed"],
    words: [
      "although",
      "prepared",
      "for",
      "martyrdom",
      "i",
      "preferred",
      "that",
      "it",
      "be",
      "postponed",
    ],
    id: "Although prepared for martyrdom, I preferred that it be postponed.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["kites", "rise", "highest", "against", "wind", "-"],
    words: ["kites", "rise", "highest", "against", "the", "wind", "-", "not", "with", "it"],
    id: "Kites rise highest against the wind - not with it.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["moderation", "temper", "always", "virtue", "principle", "vice"],
    words: [
      "moderation",
      "in",
      "temper",
      "is",
      "always",
      "a",
      "virtue",
      "but",
      "principle",
      "vice",
    ],
    id: "Moderation in temper is always a virtue; but moderation in principle is always a vice.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["fear", "perfection", "-", "you'll", "never", "reach"],
    words: ["have", "no", "fear", "of", "perfection", "-", "you'll", "never", "reach", "it"],
    id: "Have no fear of perfection - you'll never reach it.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["men", "nearly", "always", "willing", "believe", "wish"],
    words: ["men", "are", "nearly", "always", "willing", "to", "believe", "what", "they", "wish"],
    id: "Men are nearly always willing to believe what they wish.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'd", "fight", "any", "titleholders", "time", "place"],
    words: ["i'd", "love", "to", "fight", "any", "of", "the", "titleholders", "time", "place"],
    id: "I'd love to fight any of the titleholders, any time, any place.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["difficult", "read", "book", "on", "your", "computer"],
    words: ["it's", "very", "difficult", "to", "read", "a", "book", "on", "your", "computer"],
    id: "It's very difficult to read a book on your computer.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "my", "friend", "miss", "as", "long"],
    words: ["i", "want", "my", "friend", "to", "miss", "me", "as", "long", "him"],
    id: "I want my friend to miss me as long as I miss him.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["bride", "woman", "fine", "prospect", "happiness", "behind"],
    words: ["bride", "a", "woman", "with", "fine", "prospect", "of", "happiness", "behind", "her"],
    id: "Bride: A woman with a fine prospect of happiness behind her.",
    author: "Ambrose Bierce",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["trees", "earth's", "endless", "effort", "listening", "heaven"],
    words: [
      "trees",
      "are",
      "earth's",
      "endless",
      "effort",
      "to",
      "speak",
      "the",
      "listening",
      "heaven",
    ],
    id: "Trees are Earth's endless effort to speak to the listening heaven.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "encounters", "prosperity", "also", "encounter", "danger"],
    words: [
      "no",
      "one",
      "that",
      "encounters",
      "prosperity",
      "does",
      "not",
      "also",
      "encounter",
      "danger",
    ],
    id: "No one that encounters prosperity does not also encounter danger.",
    author: "Heraclitus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["lunatic", "lover", "poet", "imagination", "all", "compact"],
    words: ["the", "lunatic", "lover", "and", "poet", "are", "of", "imagination", "all", "compact"],
    id: "The lunatic, the lover, and the poet, are of imagination all compact.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["really", "something", "i", "sir", "arsene", "wenger"],
    words: ["he", "is", "really", "something", "i", "love", "him", "sir", "arsene", "wenger"],
    id: "He is really something. I love him. He is Sir Arsene Wenger.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["trouble", "common", "denominator", "living", "great", "equalizer"],
    words: [
      "trouble",
      "is",
      "the",
      "common",
      "denominator",
      "of",
      "living",
      "it",
      "great",
      "equalizer",
    ],
    id: "Trouble is the common denominator of living. It is the great equalizer.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "lose", "right", "different", "privilege", "free"],
    words: ["when", "we", "lose", "the", "right", "to", "be", "different", "privilege", "free"],
    id: "When we lose the right to be different, we lose the privilege to be free.",
    author: "Charles Evans Hughes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["duration", "passion", "proportionate", "original", "resistance", "woman"],
    words: [
      "the",
      "duration",
      "of",
      "passion",
      "is",
      "proportionate",
      "with",
      "original",
      "resistance",
      "woman",
    ],
    id: "The duration of passion is proportionate with the original resistance of the woman.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["wish", "reach", "highest", "begin", "at", "lowest"],
    words: ["if", "you", "wish", "to", "reach", "the", "highest", "begin", "at", "lowest"],
    id: "If you wish to reach the highest, begin at the lowest.",
    author: "Publilius Syrus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["nixon", "was", "more", "saint", "than", "great"],
    words: ["nixon", "was", "no", "more", "a", "saint", "than", "he", "great", "president"],
    id: "Nixon was no more a saint than he was a great president.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "defensless", "against", "suffering", "as", "when"],
    words: ["we", "are", "never", "so", "defensless", "against", "suffering", "as", "when", "love"],
    id: "We are never so defensless against suffering as when we love.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["health", "there", "freedom", "first", "all", "liberties"],
    words: ["in", "health", "there", "is", "freedom", "the", "first", "of", "all", "liberties"],
    id: "In health there is freedom. Health is the first of all liberties.",
    author: "Henri Frederic Amiel",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["none", "old", "as", "those", "outlived", "enthusiasm"],
    words: ["none", "are", "so", "old", "as", "those", "who", "have", "outlived", "enthusiasm"],
    id: "None are so old as those who have outlived enthusiasm.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "would", "one-man", "multinational", "fashion", "phenomenon"],
    words: [
      "i",
      "would",
      "like",
      "to",
      "be",
      "a",
      "one-man",
      "multinational",
      "fashion",
      "phenomenon",
    ],
    id: "I would like to be a one-man multinational fashion phenomenon.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "world", "full", "suffering", "also", "overcoming"],
    words: ["all", "the", "world", "is", "full", "of", "suffering", "it", "also", "overcoming"],
    id: "All the world is full of suffering. It is also full of overcoming.",
    author: "Helen Keller",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["washington", "city", "southern", "efficiency", "northern", "charm"],
    words: [
      "washington",
      "is",
      "a",
      "city",
      "of",
      "southern",
      "efficiency",
      "and",
      "northern",
      "charm",
    ],
    id: "Washington is a city of Southern efficiency and Northern charm.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["family", "friendships", "two", "greatest", "facilitators", "happiness"],
    words: [
      "family",
      "and",
      "friendships",
      "are",
      "two",
      "of",
      "the",
      "greatest",
      "facilitators",
      "happiness",
    ],
    id: "Family and friendships are two of the greatest facilitators of happiness.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["death", "one", "man", "tragedy", "millions", "statistic"],
    words: ["the", "death", "of", "one", "man", "is", "a", "tragedy", "millions", "statistic"],
    id: "The death of one man is a tragedy. The death of millions is a statistic.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["trees", "earth's", "endless", "effort", "listening", "heaven"],
    words: [
      "trees",
      "are",
      "the",
      "earth's",
      "endless",
      "effort",
      "to",
      "speak",
      "listening",
      "heaven",
    ],
    id: "Trees are the earth's endless effort to speak to the listening heaven.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["print", "sharpest", "strongest", "weapon", "our", "party"],
    words: ["print", "is", "the", "sharpest", "and", "strongest", "weapon", "of", "our", "party"],
    id: "Print is the sharpest and the strongest weapon of our party.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["chief", "function", "body", "carry", "brain", "around"],
    words: ["the", "chief", "function", "of", "body", "is", "to", "carry", "brain", "around"],
    id: "The chief function of the body is to carry the brain around.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 10,
    all_intersection_count: 4,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "drive", "way", "too", "fast", "cholesterol"],
    words: ["i", "drive", "way", "too", "fast", "to", "worry", "about", "cholesterol"],
    id: "I drive way too fast to worry about cholesterol.",
    author: "Steven Wright",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "trying", "change", "how", "women", "themselves"],
    words: ["i'm", "trying", "to", "change", "how", "women", "think", "about", "themselves"],
    id: "I'm trying to change how women think about themselves.",
    author: "Ashley Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["improvising", "things", "always", "changing", "lot", "momentum"],
    words: ["improvising", "things", "is", "always", "changing", "a", "lot", "of", "momentum"],
    id: "Improvising things is always changing. A lot of momentum.",
    author: "Ikue Mori",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["chance", "my", "dear", "sovereign", "deity", "child-bearing"],
    words: ["chance", "my", "dear", "is", "the", "sovereign", "deity", "in", "child-bearing"],
    id: "Chance, my dear, is the sovereign deity in child-bearing.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["evil", "being", "root", "mystery", "pain", "knowledge"],
    words: ["evil", "being", "the", "root", "of", "mystery", "pain", "is", "knowledge"],
    id: "Evil being the root of mystery, pain is the root of knowledge.",
    author: "Simone Weil",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["education", "progressive", "discovery", "our", "own", "ignorance"],
    words: ["education", "is", "a", "progressive", "discovery", "of", "our", "own", "ignorance"],
    id: "Education is a progressive discovery of our own ignorance.",
    author: "Will Durant",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["did", "raise", "armies", "glory", "or", "conquest"],
    words: ["we", "did", "not", "raise", "armies", "for", "glory", "or", "conquest"],
    id: "We did not raise armies for glory or for conquest.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "actually", "boxers", "talk", "too", "much"],
    words: ["i", "actually", "don't", "like", "boxers", "who", "talk", "too", "much"],
    id: "I actually don't like boxers who talk too much.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["getting", "into", "bleeding", "competition", "blood", "bank"],
    words: ["like", "getting", "into", "a", "bleeding", "competition", "with", "blood", "bank"],
    id: "Like getting into a bleeding competition with a blood bank.",
    author: "Richard Branson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["gain", "freedom", "when", "paid", "full", "price"],
    words: ["we", "gain", "freedom", "when", "have", "paid", "the", "full", "price"],
    id: "We gain freedom when we have paid the full price.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["lightning", "something", "which", "would", "rather", "avoid"],
    words: ["lightning", "is", "something", "which", "again", "we", "would", "rather", "avoid"],
    id: "Lightning is something which, again, we would rather avoid.",
    author: "Richard Branson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "soon", "liberty", "complete", "dies", "anarchy"],
    words: ["as", "soon", "liberty", "is", "complete", "it", "dies", "in", "anarchy"],
    id: "As soon as liberty is complete it dies in anarchy.",
    author: "Will Durant",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "my", "adult", "life", "been", "helping"],
    words: ["all", "my", "adult", "life", "people", "have", "been", "helping", "me"],
    id: "All my adult life people have been helping me.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "repair", "roof", "when", "sun", "shining"],
    words: ["the", "time", "to", "repair", "roof", "is", "when", "sun", "shining"],
    id: "The time to repair the roof is when the sun is shining.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "was", "invasion", "normandy", "southern", "france"],
    words: ["i", "was", "in", "the", "invasion", "of", "normandy", "southern", "france"],
    id: "I was in the invasion of Normandy in southern France.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["baseball", "ninety", "percent", "mental", "half", "physical"],
    words: ["baseball", "is", "ninety", "percent", "mental", "the", "other", "half", "physical"],
    id: "Baseball is ninety percent mental. The other half is physical.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "thought", "would", "guy", "on", "radio"],
    words: ["i", "thought", "would", "be", "a", "guy", "on", "the", "radio"],
    id: "I thought I would be a guy on the radio.",
    author: "Steven Wright",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["invincibility", "lies", "defence", "possibility", "victory", "attack"],
    words: [
      "invincibility",
      "lies",
      "in",
      "the",
      "defence",
      "possibility",
      "of",
      "victory",
      "attack",
    ],
    id: "Invincibility lies in the defence; the possibility of victory in the attack.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "free", "voice", "mind", "freedom", "expression"],
    words: ["i", "have", "a", "free", "voice", "mind", "freedom", "of", "expression"],
    id: "I have a free voice. I have a free mind. I have freedom of expression.",
    author: "Larry Wilmore",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "never", "thy", "face", "upon", "hell-fire"],
    words: ["i", "never", "see", "thy", "face", "but", "think", "upon", "hell-fire"],
    id: "I never see thy face but I think upon hell-fire.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "exhausting", "thing", "life", "being", "insincere"],
    words: ["the", "most", "exhausting", "thing", "in", "life", "is", "being", "insincere"],
    id: "The most exhausting thing in life is being insincere.",
    author: "Anne Morrow Lindbergh",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "makes", "god", "his", "own", "desire"],
    words: ["every", "man", "makes", "a", "god", "of", "his", "own", "desire"],
    id: "Every man makes a god of his own desire.",
    author: "Virgil",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["false", "face", "must", "hide", "heart", "doth"],
    words: ["false", "face", "must", "hide", "what", "the", "heart", "doth", "know"],
    id: "False face must hide what the false heart doth know.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "little", "here", "below", "nor", "long"],
    words: ["man", "wants", "but", "little", "here", "below", "nor", "that", "long"],
    id: "Man wants but little here below, nor wants that little long.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["dare", "lose", "one's", "footing", "momentarily", "oneself"],
    words: ["to", "dare", "is", "lose", "one's", "footing", "momentarily", "not", "oneself"],
    id: "To dare is to lose one's footing momentarily. Not to dare is to lose oneself.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "nothing", "except", "fact", "my", "ignorance"],
    words: ["i", "know", "nothing", "except", "the", "fact", "of", "my", "ignorance"],
    id: "I know nothing except the fact of my ignorance.",
    author: "Socrates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["painting", "infinitely", "minute", "part", "my", "personality"],
    words: ["painting", "is", "an", "infinitely", "minute", "part", "of", "my", "personality"],
    id: "Painting is an infinitely minute part of my personality.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["preconceived", "notions", "locks", "on", "door", "wisdom"],
    words: ["preconceived", "notions", "are", "the", "locks", "on", "door", "to", "wisdom"],
    id: "Preconceived notions are the locks on the door to wisdom.",
    author: "Mary Browne",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["mistake", "always", "forgivable", "rarely", "excusable", "unacceptable"],
    words: [
      "a",
      "mistake",
      "is",
      "always",
      "forgivable",
      "rarely",
      "excusable",
      "and",
      "unacceptable",
    ],
    id: "A mistake is always forgivable, rarely excusable and always unacceptable.",
    author: "Robert Fripp",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["too", "soon", "our", "limitations", "never", "powers"],
    words: ["learning", "too", "soon", "our", "limitations", "we", "never", "learn", "powers"],
    id: "Learning too soon our limitations, we never learn our powers.",
    author: "Mignon McLaughlin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["n", "period", "twenty-four", "hours", "mostly", "misspent"],
    words: ["day", "n", "a", "period", "of", "twenty-four", "hours", "mostly", "misspent"],
    id: "Day, n. A period of twenty-four hours, mostly misspent.",
    author: "Ambrose Bierce",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["discipline", "daily", "devotion", "god", "undergirds", "decisions"],
    words: ["the", "discipline", "of", "daily", "devotion", "to", "god", "undergirds", "decisions"],
    id: "The discipline of daily devotion to God undergirds decisions.",
    author: "Edwin Louis Cole",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["pretty", "scary", "how", "quickly", "time", "flies"],
    words: ["it's", "pretty", "scary", "to", "know", "how", "quickly", "time", "flies"],
    id: "It's pretty scary to know how quickly time flies.",
    author: "James Wan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["courage", "compromise", "brings", "smile", "god's", "approval"],
    words: ["courage", "not", "compromise", "brings", "the", "smile", "of", "god's", "approval"],
    id: "Courage, not compromise, brings the smile of God's approval.",
    author: "Thomas S. Monson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "i", "i'm", "trying", "prove", "myself"],
    words: ["every", "time", "i", "dance", "i'm", "trying", "to", "prove", "myself"],
    id: "Every time I dance, I'm trying to prove myself to myself.",
    author: "Misty Copeland",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["sin", "offers", "repentance", "forgiveness", "only", "punishment"],
    words: ["to", "sin", "offers", "repentance", "and", "forgiveness", "not", "only", "punishment"],
    id: "To sin offers repentance and forgiveness; not to sin offers only punishment.",
    author: "Jose Bergamin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["books", "ever", "burning", "lamps", "accumulated", "wisdom"],
    words: ["books", "are", "the", "ever", "burning", "lamps", "of", "accumulated", "wisdom"],
    id: "Books are the ever burning lamps of accumulated wisdom.",
    author: "George William Curtis",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["mistake", "simply", "another", "way", "doing", "things"],
    words: ["a", "mistake", "is", "simply", "another", "way", "of", "doing", "things"],
    id: "A mistake is simply another way of doing things.",
    author: "Katharine Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there's", "wrong", "time", "make", "right", "decision"],
    words: ["there's", "no", "wrong", "time", "to", "make", "the", "right", "decision"],
    id: "There's no wrong time to make the right decision.",
    author: "Dalton McGuinty",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["may", "live", "all", "days", "your", "life"],
    words: ["may", "you", "live", "all", "the", "days", "of", "your", "life"],
    id: "May you live all the days of your life.",
    author: "Jonathan Swift",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["course", "god", "will", "forgive", "his", "job"],
    words: ["of", "course", "god", "will", "forgive", "me", "that's", "his", "job"],
    id: "Of course God will forgive me; that's His job.",
    author: "Heinrich Heine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["patience", "blending", "moral", "courage", "physical", "timidity"],
    words: [
      "patience",
      "that",
      "blending",
      "of",
      "moral",
      "courage",
      "with",
      "physical",
      "timidity",
    ],
    id: "Patience, that blending of moral courage with physical timidity.",
    author: "Thomas Hardy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["fellowship", "friendship", "lasting", "rational", "manly", "pleasures"],
    words: [
      "good",
      "fellowship",
      "and",
      "friendship",
      "are",
      "lasting",
      "rational",
      "manly",
      "pleasures",
    ],
    id: "Good fellowship and friendship are lasting, rational and manly pleasures.",
    author: "William Wycherley",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["swim", "upstream", "way", "ignore", "conventional", "wisdom"],
    words: ["swim", "upstream", "go", "the", "other", "way", "ignore", "conventional", "wisdom"],
    id: "Swim upstream. Go the other way. Ignore the conventional wisdom.",
    author: "Sam Walton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "own", "perchance", "may", "never", "come"],
    words: ["today", "is", "your", "own", "tomorrow", "perchance", "may", "never", "come"],
    id: "Today is your own. Tomorrow perchance may never come.",
    author: "Swami Sivananda",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["music", "mediator", "between", "spiritual", "sensual", "life"],
    words: ["music", "is", "the", "mediator", "between", "spiritual", "and", "sensual", "life"],
    id: "Music is the mediator between the spiritual and the sensual life.",
    author: "Ludwig van Beethoven",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "doing", "movies", "relate", "people's", "experience"],
    words: ["i", "like", "doing", "movies", "that", "relate", "to", "people's", "experience"],
    id: "I like doing movies that relate to people's experience.",
    author: "Illeana Douglas",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["education", "must", "simply", "teach", "-", "life"],
    words: ["education", "must", "not", "simply", "teach", "work", "-", "it", "life"],
    id: "Education must not simply teach work - it must teach Life.",
    author: "W. E. B. Du Bois",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["how", "operate", "soul", "figures", "take", "time"],
    words: ["learning", "how", "to", "operate", "a", "soul", "figures", "take", "time"],
    id: "Learning how to operate a soul figures to take time.",
    author: "Timothy Leary",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "unshakeable", "faith", "believe", "myself", "god"],
    words: ["i", "have", "this", "unshakeable", "faith", "believe", "in", "myself", "god"],
    id: "I have this unshakeable faith. I believe in myself; I believe in God.",
    author: "Laurence Fishburne",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["look", "around", "place", "sow", "few", "seeds"],
    words: ["look", "around", "for", "a", "place", "to", "sow", "few", "seeds"],
    id: "Look around for a place to sow a few seeds.",
    author: "Henry Van Dyke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "success", "overcoming", "fear", "being", "unsuccessful"],
    words: ["true", "success", "is", "overcoming", "the", "fear", "of", "being", "unsuccessful"],
    id: "True success is overcoming the fear of being unsuccessful.",
    author: "Paul Sweeney",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["skill", "acquire", "doubles", "your", "odds", "success"],
    words: ["every", "skill", "you", "acquire", "doubles", "your", "odds", "of", "success"],
    id: "Every skill you acquire doubles your odds of success.",
    author: "Scott Adams",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["self-belief", "hard", "will", "always", "earn", "success"],
    words: ["self-belief", "and", "hard", "work", "will", "always", "earn", "you", "success"],
    id: "Self-belief and hard work will always earn you success.",
    author: "Virat Kohli",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "positive", "action", "combined", "results", "success"],
    words: [
      "your",
      "positive",
      "action",
      "combined",
      "with",
      "thinking",
      "results",
      "in",
      "success",
    ],
    id: "Your positive action combined with positive thinking results in success.",
    author: "Shiv Khera",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["wisdom", "when", "derived", "from", "books", "alone"],
    words: ["wisdom", "is", "not", "when", "it", "derived", "from", "books", "alone"],
    id: "Wisdom is not wisdom when it is derived from books alone.",
    author: "Horace",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i've", "said", "all", "along", "god", "control"],
    words: ["i've", "said", "all", "along", "that", "god", "is", "in", "control"],
    id: "I've said all along that God is in control.",
    author: "Tony Dungy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["path", "success", "take", "massive", "determined", "action"],
    words: ["the", "path", "to", "success", "is", "take", "massive", "determined", "action"],
    id: "The path to success is to take massive, determined action.",
    author: "Tony Robbins",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["by", "all", "means", "use", "time", "alone"],
    words: ["by", "all", "means", "use", "some", "time", "to", "be", "alone"],
    id: "By all means use some time to be alone.",
    author: "Edward Young",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "make", "lord", "only", "god", "gentleman"],
    words: ["i", "can", "make", "a", "lord", "but", "only", "god", "gentleman"],
    id: "I can make a lord, but only God can make a gentleman.",
    author: "King James I",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["patience", "support", "weakness", "impatience", "ruin", "strength"],
    words: ["patience", "is", "the", "support", "of", "weakness", "impatience", "ruin", "strength"],
    id: "Patience is the support of weakness; impatience the ruin of strength.",
    author: "Charles Caleb Colton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "important", "key", "success", "self-confidence", "preparation"],
    words: [
      "one",
      "important",
      "key",
      "to",
      "success",
      "is",
      "self-confidence",
      "an",
      "preparation",
    ],
    id: "One important key to success is self-confidence. An important key to self-confidence is preparation.",
    author: "Arthur Ashe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "tangential", "point", "between", "zero", "infinity"],
    words: ["god", "is", "the", "tangential", "point", "between", "zero", "and", "infinity"],
    id: "God is the tangential point between zero and infinity.",
    author: "Alfred Jarry",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["definiteness", "purpose", "starting", "point", "all", "achievement"],
    words: [
      "definiteness",
      "of",
      "purpose",
      "is",
      "the",
      "starting",
      "point",
      "all",
      "achievement",
    ],
    id: "Definiteness of purpose is the starting point of all achievement.",
    author: "W. Clement Stone",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "prefer", "folly", "enthusiasm", "indifference", "wisdom"],
    words: ["i", "prefer", "the", "folly", "of", "enthusiasm", "to", "indifference", "wisdom"],
    id: "I prefer the folly of enthusiasm to the indifference of wisdom.",
    author: "Anatole France",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["coming", "together", "beginning", "keeping", "progress", "success"],
    words: [
      "coming",
      "together",
      "is",
      "a",
      "beginning",
      "keeping",
      "progress",
      "working",
      "success",
    ],
    id: "Coming together is a beginning; keeping together is progress; working together is success.",
    author: "Edward Everett Hale",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["even", "movies", "superbad", "all", "lovable", "kids"],
    words: ["even", "in", "movies", "like", "superbad", "they're", "all", "lovable", "kids"],
    id: "Even in movies like 'Superbad,' they're all lovable kids.",
    author: "Bo Burnham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["champion", "afraid", "losing", "everyone", "else", "winning"],
    words: ["a", "champion", "is", "afraid", "of", "losing", "everyone", "else", "winning"],
    id: "A champion is afraid of losing. Everyone else is afraid of winning.",
    author: "Billie Jean King",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "commend", "patience", "none", "endure", "suffer"],
    words: ["all", "commend", "patience", "but", "none", "can", "endure", "to", "suffer"],
    id: "All commend patience, but none can endure to suffer.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "nothing", "except", "response", "believing", "prayer"],
    words: ["god", "does", "nothing", "except", "in", "response", "to", "believing", "prayer"],
    id: "God does nothing except in response to believing prayer.",
    author: "John Wesley",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "artist", "here", "live", "out", "loud"],
    words: ["i", "am", "an", "artist", "here", "to", "live", "out", "loud"],
    id: "I am an artist... I am here to live out loud.",
    author: "Emile Zola",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["patience", "may", "toughest", "thing", "when", "injured"],
    words: ["patience", "may", "be", "the", "toughest", "thing", "when", "you're", "injured"],
    id: "Patience may be the toughest thing when you're injured.",
    author: "Danilo Gallinari",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "life", "fullest", "focus", "on", "positive"],
    words: ["live", "life", "to", "the", "fullest", "and", "focus", "on", "positive"],
    id: "Live life to the fullest, and focus on the positive.",
    author: "Matt Cameron",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "name", "linus", "i", "your", "god"],
    words: ["my", "name", "is", "linus", "and", "i", "am", "your", "god"],
    id: "My name is Linus, and I am your God.",
    author: "Linus Torvalds",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["feeling", "remains", "god", "on", "journey", "too"],
    words: ["the", "feeling", "remains", "that", "god", "is", "on", "journey", "too"],
    id: "The feeling remains that God is on the journey, too.",
    author: "Saint Teresa of Avila",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "son", "gave", "permission", "accept", "success"],
    words: ["my", "son", "gave", "me", "the", "permission", "to", "accept", "success"],
    id: "My son gave me the permission to accept my success.",
    author: "Gary Burghoff",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["takes", "as", "much", "energy", "wish", "plan"],
    words: ["it", "takes", "as", "much", "energy", "to", "wish", "does", "plan"],
    id: "It takes as much energy to wish as it does to plan.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "one", "person", "make", "difference"],
    words: ["i", "believe", "that", "one", "person", "can", "make", "a", "difference"],
    id: "I believe that one person can make a difference.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["woman's", "discontent", "increases", "exact", "proportion", "development"],
    words: [
      "woman's",
      "discontent",
      "increases",
      "in",
      "exact",
      "proportion",
      "to",
      "her",
      "development",
    ],
    id: "Woman's discontent increases in exact proportion to her development.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["tell", "i", "forget", "teach", "remember", "involve"],
    words: ["tell", "me", "and", "i", "forget", "teach", "remember", "involve", "learn"],
    id: "Tell me and I forget. Teach me and I remember. Involve me and I learn.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "people's", "man", "-", "only", "matter"],
    words: ["i'm", "a", "people's", "man", "-", "only", "the", "people", "matter"],
    id: "I'm a people's man - only the people matter.",
    author: "Bill Shankly",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "look", "into", "abyss", "also", "looks"],
    words: ["when", "you", "look", "into", "an", "abyss", "the", "also", "looks"],
    id: "When you look into an abyss, the abyss also looks into you.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["unless", "nothing", "else", "makes", "any", "sense"],
    words: ["unless", "you", "love", "someone", "nothing", "else", "makes", "any", "sense"],
    id: "Unless you love someone, nothing else makes any sense.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["guests", "begin", "smell", "after", "three", "days"],
    words: ["guests", "like", "fish", "begin", "to", "smell", "after", "three", "days"],
    id: "Guests, like fish, begin to smell after three days.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "yesterday", "use", "up", "too", "much"],
    words: ["never", "let", "yesterday", "use", "up", "too", "much", "of", "today"],
    id: "Never let yesterday use up too much of today.",
    author: "Will Rogers",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "cease", "make", "contribution", "begin", "die"],
    words: ["when", "you", "cease", "to", "make", "a", "contribution", "begin", "die"],
    id: "When you cease to make a contribution, you begin to die.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "problems", "must", "confess", "old", "age"],
    words: ["i", "have", "the", "problems", "of", "must", "confess", "old", "age"],
    id: "I have the problems of, I must confess, old age.",
    author: "Billy Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["everybody's", "youth", "dream", "form", "chemical", "madness"],
    words: ["everybody's", "youth", "is", "a", "dream", "form", "of", "chemical", "madness"],
    id: "Everybody's youth is a dream, a form of chemical madness.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["learned", "blockhead", "greater", "than", "ignorant", "one"],
    words: ["a", "learned", "blockhead", "is", "greater", "than", "an", "ignorant", "one"],
    id: "A learned blockhead is a greater blockhead than an ignorant one.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["guilt", "perhaps", "most", "painful", "companion", "death"],
    words: ["guilt", "is", "perhaps", "the", "most", "painful", "companion", "of", "death"],
    id: "Guilt is perhaps the most painful companion of death.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["faith", "crosses", "border", "touches", "heart", "nation"],
    words: ["faith", "crosses", "every", "border", "and", "touches", "heart", "in", "nation"],
    id: "Faith crosses every border and touches every heart in every nation.",
    author: "George W. Bush",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["strength", "nation", "derives", "from", "integrity", "home"],
    words: ["the", "strength", "of", "a", "nation", "derives", "from", "integrity", "home"],
    id: "The strength of a nation derives from the integrity of the home.",
    author: "Confucius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["wellness", "prevention", "market", "will", "outgrow", "health"],
    words: ["the", "wellness", "and", "prevention", "market", "will", "outgrow", "health", "care"],
    id: "The wellness and prevention market will outgrow the health care market.",
    author: "Leroy Hood",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "thing", "will", "redeem", "mankind", "cooperation"],
    words: ["the", "only", "thing", "that", "will", "redeem", "mankind", "is", "cooperation"],
    id: "The only thing that will redeem mankind is cooperation.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "biggest", "error", "something", "happen", "yet"],
    words: ["my", "biggest", "error", "something", "that", "is", "to", "happen", "yet"],
    id: "My biggest error? Something that is to happen yet.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "benevolent", "dictatorship", "provided", "dictator"],
    words: [
      "i",
      "believe",
      "in",
      "benevolent",
      "dictatorship",
      "provided",
      "am",
      "the",
      "dictator",
    ],
    id: "I believe in benevolent dictatorship provided I am the dictator.",
    author: "Richard Branson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["youth", "easily", "deceived", "because", "quick", "hope"],
    words: ["youth", "is", "easily", "deceived", "because", "it", "quick", "to", "hope"],
    id: "Youth is easily deceived because it is quick to hope.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "stands", "nothing", "will", "fall", "anything"],
    words: ["a", "man", "who", "stands", "for", "nothing", "will", "fall", "anything"],
    id: "A man who stands for nothing will fall for anything.",
    author: "Unknown",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["public", "sentiment", "nothing", "fail", "without", "succeed"],
    words: ["with", "public", "sentiment", "nothing", "can", "fail", "without", "it", "succeed"],
    id: "With public sentiment, nothing can fail. Without it, nothing can succeed.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["courtesy", "as", "much", "mark", "gentleman", "courage"],
    words: ["courtesy", "is", "as", "much", "a", "mark", "of", "gentleman", "courage"],
    id: "Courtesy is as much a mark of a gentleman as courage.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["attitude", "little", "thing", "makes", "big", "difference"],
    words: ["attitude", "is", "a", "little", "thing", "that", "makes", "big", "difference"],
    id: "Attitude is a little thing that makes a big difference.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["death", "may", "greatest", "all", "human", "blessings"],
    words: ["death", "may", "be", "the", "greatest", "of", "all", "human", "blessings"],
    id: "Death may be the greatest of all human blessings.",
    author: "Socrates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["art", "man", "reveals", "himself", "his", "objects"],
    words: ["in", "art", "man", "reveals", "himself", "and", "not", "his", "objects"],
    id: "In Art, man reveals himself and not his objects.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["because", "your", "smile", "make", "life", "more"],
    words: ["because", "of", "your", "smile", "you", "make", "life", "more", "beautiful"],
    id: "Because of your smile, you make life more beautiful.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "had", "child", "destroyed", "my", "family"],
    words: ["i", "had", "this", "child", "and", "it", "destroyed", "my", "family"],
    id: "I had this child, and it destroyed my family.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["common", "sense", "genius", "dressed", "its", "clothes"],
    words: ["common", "sense", "is", "genius", "dressed", "in", "its", "working", "clothes"],
    id: "Common sense is genius dressed in its working clothes.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["few", "men", "virtue", "withstand", "highest", "bidder"],
    words: ["few", "men", "have", "virtue", "to", "withstand", "the", "highest", "bidder"],
    id: "Few men have virtue to withstand the highest bidder.",
    author: "George Washington",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "due", "respect", "mona", "lisa", "overrated"],
    words: ["with", "all", "due", "respect", "the", "mona", "lisa", "is", "overrated"],
    id: "With all due respect, the Mona Lisa is overrated.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["strongest", "principle", "growth", "lies", "human", "choice"],
    words: ["the", "strongest", "principle", "of", "growth", "lies", "in", "human", "choice"],
    id: "The strongest principle of growth lies in the human choice.",
    author: "George Eliot",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["terrifying", "edible", "beauty", "art", "nouveau", "architecture"],
    words: ["the", "terrifying", "and", "edible", "beauty", "of", "art", "nouveau", "architecture"],
    id: "The terrifying and edible beauty of Art Nouveau architecture.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["nonsense", "only", "because", "common", "sense", "limited"],
    words: ["nonsense", "is", "so", "good", "only", "because", "common", "sense", "limited"],
    id: "Nonsense is so good only because common sense is so limited.",
    author: "George Santayana",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["how", "hide", "one's", "ability", "great", "skill"],
    words: ["to", "know", "how", "hide", "one's", "ability", "is", "great", "skill"],
    id: "To know how to hide one's ability is great skill.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "dialogue", "both", "sides", "willing", "change"],
    words: ["in", "true", "dialogue", "both", "sides", "are", "willing", "to", "change"],
    id: "In true dialogue, both sides are willing to change.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "could", "shadow", "galaxies", "stars", "even"],
    words: ["there", "could", "be", "shadow", "galaxies", "stars", "and", "even", "people"],
    id: "There could be shadow galaxies, shadow stars, and even shadow people.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "loved", "because", "reason", "needed", "loving"],
    words: ["one", "is", "loved", "because", "no", "reason", "needed", "for", "loving"],
    id: "One is loved because one is loved. No reason is needed for loving.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "take", "tougher", "gets", "cooler", "get"],
    words: ["i", "can", "take", "it", "the", "tougher", "gets", "cooler", "get"],
    id: "I can take it. The tougher it gets, the cooler I get.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["found", "his", "style", "when", "cannot", "otherwise"],
    words: ["he", "has", "found", "his", "style", "when", "cannot", "do", "otherwise"],
    id: "He has found his style, when he cannot do otherwise.",
    author: "Paul Klee",
    grammar: null,
    word_count: 9,
    all_intersection_count: 3,
    new_words_count: 6,
  },
  {
    row_new_words: ["add", "value", "others", "one", "must", "first"],
    words: ["to", "add", "value", "others", "one", "must", "first"],
    id: "To add value to others, one must first value others.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["old", "soldiers", "never", "die", "fade", "away"],
    words: ["old", "soldiers", "never", "die", "they", "just", "fade", "away"],
    id: "Old soldiers never die; they just fade away.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["why", "whip", "teacher", "when", "pupil", "misbehaves"],
    words: ["why", "not", "whip", "the", "teacher", "when", "pupil", "misbehaves"],
    id: "Why not whip the teacher when the pupil misbehaves?",
    author: "Diogenes",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["courage", "fear", "holding", "on", "minute", "longer"],
    words: ["courage", "is", "fear", "holding", "on", "a", "minute", "longer"],
    id: "Courage is fear holding on a minute longer.",
    author: "George S. Patton",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["sadness", "flies", "away", "on", "wings", "time"],
    words: ["sadness", "flies", "away", "on", "the", "wings", "of", "time"],
    id: "Sadness flies away on the wings of time.",
    author: "Jean de La Fontaine",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["perhaps", "nature", "our", "best", "assurance", "immortality"],
    words: ["perhaps", "nature", "is", "our", "best", "assurance", "of", "immortality"],
    id: "Perhaps nature is our best assurance of immortality.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["hard", "times", "arouse", "instinctive", "desire", "authenticity"],
    words: ["hard", "times", "arouse", "an", "instinctive", "desire", "for", "authenticity"],
    id: "Hard times arouse an instinctive desire for authenticity.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "make", "goals", "motivational", "specific", "realistic"],
    words: ["i", "make", "goals", "that", "are", "motivational", "specific", "realistic"],
    id: "I make goals that are motivational, that are specific, that are realistic.",
    author: "Morten Andersen",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["middle", "east", "hopeful", "there's", "hope", "there"],
    words: ["the", "middle", "east", "is", "hopeful", "there's", "hope", "there"],
    id: "The Middle East is hopeful. There's hope there.",
    author: "Joe Biden",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["protect", "your", "enthusiasm", "from", "negativity", "others"],
    words: ["protect", "your", "enthusiasm", "from", "the", "negativity", "of", "others"],
    id: "Protect your enthusiasm from the negativity of others.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "favorite", "kitchen", "was", "japanese", "italian"],
    words: ["my", "favorite", "kitchen", "was", "the", "japanese", "and", "italian"],
    id: "My favorite kitchen was the Japanese and the Italian kitchen.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["as", "courage", "imperils", "life", "fear", "protects"],
    words: ["just", "as", "courage", "imperils", "life", "fear", "protects", "it"],
    id: "Just as courage imperils life, fear protects it.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["great", "growling", "engine", "change", "-", "technology"],
    words: ["the", "great", "growling", "engine", "of", "change", "-", "technology"],
    id: "The great growling engine of change - technology.",
    author: "Alvin Toffler",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "more", "valuable", "than", "because", "irreplaceable"],
    words: ["time", "is", "more", "valuable", "than", "money", "because", "irreplaceable"],
    id: "Time is more valuable than money, because time is irreplaceable.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "didn't", "much", "golf", "growing", "up"],
    words: ["i", "didn't", "know", "much", "about", "golf", "growing", "up"],
    id: "I didn't know much about golf growing up.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "our", "knowledge", "its", "origins", "perceptions"],
    words: ["all", "our", "knowledge", "has", "its", "origins", "in", "perceptions"],
    id: "All our knowledge has its origins in our perceptions.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "everything", "i", "understand", "only", "because"],
    words: ["all", "everything", "that", "i", "understand", "only", "because", "love"],
    id: "All, everything that I understand, I understand only because I love.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["difficulties", "strengthen", "mind", "as", "labor", "body"],
    words: ["difficulties", "strengthen", "the", "mind", "as", "labor", "does", "body"],
    id: "Difficulties strengthen the mind, as labor does the body.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["non-violence", "truth", "inseparable", "presuppose", "one", "another"],
    words: ["non-violence", "and", "truth", "are", "inseparable", "presuppose", "one", "another"],
    id: "Non-violence and truth are inseparable and presuppose one another.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["heresy", "one", "age", "becomes", "orthodoxy", "next"],
    words: ["the", "heresy", "of", "one", "age", "becomes", "orthodoxy", "next"],
    id: "The heresy of one age becomes the orthodoxy of the next.",
    author: "Helen Keller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "did", "my", "best", "god", "rest"],
    words: ["i", "did", "my", "best", "and", "god", "the", "rest"],
    id: "I did my best, and God did the rest.",
    author: "Hattie McDaniel",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["whatever", "must", "either", "use", "or", "lose"],
    words: ["whatever", "you", "have", "must", "either", "use", "or", "lose"],
    id: "Whatever you have, you must either use or lose.",
    author: "Henry Ford",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["live", "your", "life", "then", "take", "hat"],
    words: ["live", "your", "life", "do", "work", "then", "take", "hat"],
    id: "Live your life, do your work, then take your hat.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["robbed", "smiles", "steals", "something", "from", "thief"],
    words: ["the", "robbed", "that", "smiles", "steals", "something", "from", "thief"],
    id: "The robbed that smiles, steals something from the thief.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["big", "shots", "only", "little", "keep", "shooting"],
    words: ["big", "shots", "are", "only", "little", "who", "keep", "shooting"],
    id: "Big shots are only little shots who keep shooting.",
    author: "Christopher Morley",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["nature", "hath", "framed", "strange", "fellows", "time"],
    words: ["nature", "hath", "framed", "strange", "fellows", "in", "her", "time"],
    id: "Nature hath framed strange fellows in her time.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["any", "colour", "-", "long", "as", "black"],
    words: ["any", "colour", "-", "so", "long", "as", "it's", "black"],
    id: "Any colour - so long as it's black.",
    author: "Henry Ford",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["cannot", "anything", "from", "success", "only", "failure"],
    words: ["you", "cannot", "learn", "anything", "from", "success", "only", "failure"],
    id: "You cannot learn anything from success, you only learn from failure.",
    author: "Jim Dale",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "brother", "was", "lifeguard", "car", "wash"],
    words: ["my", "brother", "was", "a", "lifeguard", "in", "car", "wash"],
    id: "My brother was a lifeguard in a car wash.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["conscience", "mother-in-law", "whose", "visit", "never", "ends"],
    words: ["conscience", "is", "a", "mother-in-law", "whose", "visit", "never", "ends"],
    id: "Conscience is a mother-in-law whose visit never ends.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "more", "expensive", "than", "missed", "opportunity"],
    words: ["nothing", "is", "more", "expensive", "than", "a", "missed", "opportunity"],
    id: "Nothing is more expensive than a missed opportunity.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "alter", "his", "life", "by", "altering"],
    words: ["man", "can", "alter", "his", "life", "by", "altering", "thinking"],
    id: "Man can alter his life by altering his thinking.",
    author: "William James",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "yourself", "your", "will", "seem", "different"],
    words: ["change", "yourself", "and", "your", "work", "will", "seem", "different"],
    id: "Change yourself and your work will seem different.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "such", "thing", "as", "part", "freedom"],
    words: ["there", "is", "no", "such", "thing", "as", "part", "freedom"],
    id: "There is no such thing as part freedom.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["science", "begins", "as", "philosophy", "ends", "art"],
    words: ["every", "science", "begins", "as", "philosophy", "and", "ends", "art"],
    id: "Every science begins as philosophy and ends as art.",
    author: "Will Durant",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["great", "acts", "made", "up", "small", "deeds"],
    words: ["great", "acts", "are", "made", "up", "of", "small", "deeds"],
    id: "Great acts are made up of small deeds.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["prohibition", "better", "than", "liquor", "at", "all"],
    words: ["prohibition", "is", "better", "than", "no", "liquor", "at", "all"],
    id: "Prohibition is better than no liquor at all.",
    author: "Will Rogers",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["crazy", "story", "biggest", "song", "sure", "everybody"],
    words: ["crazy", "story", "biggest", "song", "for", "sure", "everybody", "love"],
    id: "'Crazy Story,' biggest song for sure. Everybody love 'Crazy Story.'",
    author: "King Von",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["bureaucracy", "giant", "mechanism", "operated", "by", "pygmies"],
    words: ["bureaucracy", "is", "a", "giant", "mechanism", "operated", "by", "pygmies"],
    id: "Bureaucracy is a giant mechanism operated by pygmies.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["cannot", "step", "into", "same", "river", "twice"],
    words: ["you", "cannot", "step", "into", "the", "same", "river", "twice"],
    id: "You cannot step into the same river twice.",
    author: "Heraclitus",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["wickedness", "never", "did", "will", "bring", "happiness"],
    words: ["wickedness", "never", "did", "does", "will", "bring", "us", "happiness"],
    id: "Wickedness never did, never does, never will bring us happiness.",
    author: "Ezra Taft Benson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "bought", "gun", "chose", "drugs", "instead"],
    words: ["i", "bought", "a", "gun", "and", "chose", "drugs", "instead"],
    id: "I bought a gun and chose drugs instead.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["obey", "principles", "without", "being", "bound", "by"],
    words: ["obey", "the", "principles", "without", "being", "bound", "by", "them"],
    id: "Obey the principles without being bound by them.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "ain't", "never", "had", "nothing", "life"],
    words: ["i", "ain't", "never", "had", "nothing", "good", "in", "life"],
    id: "I ain't never had nothing good in life.",
    author: "Lil Baby",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "mother", "teresa", "charles", "manson", "either"],
    words: ["i'm", "not", "mother", "teresa", "but", "charles", "manson", "either"],
    id: "I'm not Mother Teresa, but I'm not Charles Manson, either.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "life", "worth", "more", "than", "title"],
    words: ["my", "life", "is", "worth", "more", "than", "a", "title"],
    id: "My life is worth more than a title.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["everyone", "their", "own", "way", "expressing", "happiness"],
    words: ["everyone", "has", "their", "own", "way", "of", "expressing", "happiness"],
    id: "Everyone has their own way of expressing happiness.",
    author: "Shah Rukh Khan",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["slavery", "takes", "hold", "few", "many", "take"],
    words: ["slavery", "takes", "hold", "of", "few", "but", "many", "take"],
    id: "Slavery takes hold of few, but many take hold of slavery.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["domestic", "policy", "only", "defeat", "foreign", "kill"],
    words: ["domestic", "policy", "can", "only", "defeat", "us", "foreign", "kill"],
    id: "Domestic policy can only defeat us; foreign policy can kill us.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "ideas", "fail", "words", "come", "handy"],
    words: ["when", "ideas", "fail", "words", "come", "in", "very", "handy"],
    id: "When ideas fail, words come in very handy.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "refreshing", "step", "into", "another", "time"],
    words: ["it's", "always", "refreshing", "to", "step", "into", "another", "time"],
    id: "It's always refreshing to step into another time.",
    author: "Diane Lane",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "nation", "must", "defend", "sanctity", "marriage"],
    words: ["our", "nation", "must", "defend", "the", "sanctity", "of", "marriage"],
    id: "Our Nation must defend the sanctity of marriage.",
    author: "George W. Bush",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "fate", "hands", "lemon", "make", "lemonade"],
    words: ["when", "fate", "hands", "you", "a", "lemon", "make", "lemonade"],
    id: "When fate hands you a lemon, make lemonade.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "paid", "jobs", "absorb", "degrade", "mind"],
    words: ["all", "paid", "jobs", "absorb", "and", "degrade", "the", "mind"],
    id: "All paid jobs absorb and degrade the mind.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["life", "one", "damned", "thing", "after", "another"],
    words: ["life", "is", "just", "one", "damned", "thing", "after", "another"],
    id: "Life is just one damned thing after another.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["sometimes", "as", "different", "from", "ourselves", "others"],
    words: ["we", "are", "sometimes", "as", "different", "from", "ourselves", "others"],
    id: "We are sometimes as different from ourselves as we are from others.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["heart", "forever", "making", "head", "its", "fool"],
    words: ["the", "heart", "is", "forever", "making", "head", "its", "fool"],
    id: "The heart is forever making the head its fool.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["vices", "sometimes", "only", "virtues", "carried", "excess"],
    words: ["vices", "are", "sometimes", "only", "virtues", "carried", "to", "excess"],
    id: "Vices are sometimes only virtues carried to excess!",
    author: "Charles Dickens",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["memory", "man's", "greatest", "friend", "worst", "enemy"],
    words: ["memory", "is", "man's", "greatest", "friend", "and", "worst", "enemy"],
    id: "Memory is man's greatest friend and worst enemy.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["innovation", "moving", "at", "scarily", "fast", "pace"],
    words: ["innovation", "is", "moving", "at", "a", "scarily", "fast", "pace"],
    id: "Innovation is moving at a scarily fast pace.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["houston", "tranquillity", "base", "here", "eagle", "landed"],
    words: ["houston", "tranquillity", "base", "here", "the", "eagle", "has", "landed"],
    id: "Houston, Tranquillity Base here. The Eagle has landed.",
    author: "Neil Armstrong",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["had", "deceived", "thee", "often", "as", "thyself"],
    words: ["who", "had", "deceived", "thee", "so", "often", "as", "thyself"],
    id: "Who had deceived thee so often as thyself?",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["might", "could", "would", "-", "contemptible", "auxiliaries"],
    words: ["might", "could", "would", "-", "they", "are", "contemptible", "auxiliaries"],
    id: "Might, could, would - they are contemptible auxiliaries.",
    author: "George Eliot",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["always", "complain", "their", "memories", "never", "minds"],
    words: ["people", "always", "complain", "about", "their", "memories", "never", "minds"],
    id: "People always complain about their memories, never about their minds.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["can't", "your", "team", "without", "hating", "another"],
    words: ["you", "can't", "love", "your", "team", "without", "hating", "another"],
    id: "You can't love your team without hating another team.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["lives", "upon", "hope", "will", "die", "fasting"],
    words: ["he", "that", "lives", "upon", "hope", "will", "die", "fasting"],
    id: "He that lives upon hope will die fasting.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["passions", "only", "orators", "which", "always", "persuade"],
    words: ["the", "passions", "are", "only", "orators", "which", "always", "persuade"],
    id: "The passions are the only orators which always persuade.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["worst", "wheel", "cart", "makes", "most", "noise"],
    words: ["the", "worst", "wheel", "of", "cart", "makes", "most", "noise"],
    id: "The worst wheel of the cart makes the most noise.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "more", "obstinate", "than", "fashionable", "consensus"],
    words: ["nothing", "is", "more", "obstinate", "than", "a", "fashionable", "consensus"],
    id: "Nothing is more obstinate than a fashionable consensus.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["future", "influences", "present", "as", "much", "past"],
    words: ["the", "future", "influences", "present", "just", "as", "much", "past"],
    id: "The future influences the present just as much as the past.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["most", "would", "rather", "than", "get", "affection"],
    words: ["most", "people", "would", "rather", "give", "than", "get", "affection"],
    id: "Most people would rather give than get affection.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["baseball", "changes", "through", "years", "gets", "milder"],
    words: ["baseball", "changes", "through", "the", "years", "it", "gets", "milder"],
    id: "Baseball changes through the years. It gets milder.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "diamond", "flaw", "than", "pebble", "without"],
    words: ["better", "a", "diamond", "with", "flaw", "than", "pebble", "without"],
    id: "Better a diamond with a flaw than a pebble without.",
    author: "Confucius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["discernment", "god's", "call", "intercession", "never", "faultfinding"],
    words: ["discernment", "is", "god's", "call", "to", "intercession", "never", "faultfinding"],
    id: "Discernment is God's call to intercession, never to faultfinding.",
    author: "Corrie Ten Boom",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["ridicule", "dishonors", "man", "more", "than", "dishonor"],
    words: ["ridicule", "dishonors", "a", "man", "more", "than", "dishonor", "does"],
    id: "Ridicule dishonors a man more than dishonor does.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["being", "vegan", "first-world", "phenomenon", "completely", "self-indulgent"],
    words: [
      "being",
      "a",
      "vegan",
      "is",
      "first-world",
      "phenomenon",
      "completely",
      "self-indulgent",
    ],
    id: "Being a vegan is a first-world phenomenon, completely self-indulgent.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["praise", "there", "more", "obtrusiveness", "than", "blame"],
    words: ["in", "praise", "there", "is", "more", "obtrusiveness", "than", "blame"],
    id: "In praise there is more obtrusiveness than in blame.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["consider", "each", "soil", "will", "bear", "refuses"],
    words: ["consider", "what", "each", "soil", "will", "bear", "and", "refuses"],
    id: "Consider what each soil will bear, and what each refuses.",
    author: "Virgil",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["actors", "one", "family", "over", "entire", "world"],
    words: ["actors", "are", "one", "family", "over", "the", "entire", "world"],
    id: "Actors are one family over the entire world.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "nothing", "more", "unaesthetic", "than", "policeman"],
    words: ["there", "is", "nothing", "more", "unaesthetic", "than", "a", "policeman"],
    id: "There is nothing more unaesthetic than a policeman.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "eternal", "facts", "as", "absolute", "truths"],
    words: ["there", "are", "no", "eternal", "facts", "as", "absolute", "truths"],
    id: "There are no eternal facts, as there are no absolute truths.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "had", "slumps", "lasted", "into", "winter"],
    words: ["i", "had", "slumps", "that", "lasted", "into", "the", "winter"],
    id: "I had slumps that lasted into the winter.",
    author: "Bob Uecker",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "felt", "clinton", "represented", "worst", "1960s"],
    words: ["i", "felt", "clinton", "represented", "the", "worst", "of", "1960s"],
    id: "I felt Clinton represented the worst of the 1960s.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["vulnerability", "basically", "uncertainty", "risk", "emotional", "exposure"],
    words: [
      "vulnerability",
      "is",
      "basically",
      "uncertainty",
      "risk",
      "and",
      "emotional",
      "exposure",
    ],
    id: "Vulnerability is basically uncertainty, risk, and emotional exposure.",
    author: "Brene Brown",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "more", "precious", "than", "independence", "liberty"],
    words: ["nothing", "is", "more", "precious", "than", "independence", "and", "liberty"],
    id: "Nothing is more precious than independence and liberty.",
    author: "Ho Chi Minh",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "only", "one", "sort", "discipline", "perfect"],
    words: ["there", "is", "only", "one", "sort", "of", "discipline", "perfect"],
    id: "There is only one sort of discipline, perfect discipline.",
    author: "George S. Patton",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["pleasure", "action", "make", "hours", "seem", "short"],
    words: ["pleasure", "and", "action", "make", "the", "hours", "seem", "short"],
    id: "Pleasure and action make the hours seem short.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "fight", "something", "than", "live", "nothing"],
    words: ["better", "to", "fight", "for", "something", "than", "live", "nothing"],
    id: "Better to fight for something than live for nothing.",
    author: "George S. Patton",
    grammar: null,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["great", "thieves", "lead", "away", "little", "thief"],
    words: ["the", "great", "thieves", "lead", "away", "little", "thief"],
    id: "The great thieves lead away the little thief.",
    author: "Diogenes",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["mind", "cannot", "long", "play", "heart's", "role"],
    words: ["the", "mind", "cannot", "long", "play", "heart's", "role"],
    id: "The mind cannot long play the heart's role.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "get", "more", "spam", "than", "anyone"],
    words: ["i", "get", "more", "spam", "than", "anyone", "know"],
    id: "I get more spam than anyone I know.",
    author: "Bill Gates",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["republics", "decline", "into", "democracies", "degenerate", "despotisms"],
    words: ["republics", "decline", "into", "democracies", "and", "degenerate", "despotisms"],
    id: "Republics decline into democracies and democracies degenerate into despotisms.",
    author: "Aristotle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["any", "truth", "better", "than", "indefinite", "doubt"],
    words: ["any", "truth", "is", "better", "than", "indefinite", "doubt"],
    id: "Any truth is better than indefinite doubt.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["human", "beings", "lose", "their", "logic", "vindictiveness"],
    words: ["human", "beings", "lose", "their", "logic", "in", "vindictiveness"],
    id: "Human beings lose their logic in their vindictiveness.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["confidence", "contributes", "more", "conversation", "than", "wit"],
    words: ["confidence", "contributes", "more", "to", "conversation", "than", "wit"],
    id: "Confidence contributes more to conversation than wit.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["laws", "houses", "lean", "on", "one", "another"],
    words: ["laws", "like", "houses", "lean", "on", "one", "another"],
    id: "Laws, like houses, lean on one another.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["ever", "disunites", "man", "from", "god", "also"],
    words: ["what", "ever", "disunites", "man", "from", "god", "also"],
    id: "What ever disunites man from God, also disunites man from man.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["moral", "codes", "adjust", "themselves", "environmental", "conditions"],
    words: ["moral", "codes", "adjust", "themselves", "to", "environmental", "conditions"],
    id: "Moral codes adjust themselves to environmental conditions.",
    author: "Will Durant",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["everybody", "ignorant", "only", "on", "different", "subjects"],
    words: ["everybody", "is", "ignorant", "only", "on", "different", "subjects"],
    id: "Everybody is ignorant, only on different subjects.",
    author: "Will Rogers",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["fashion", "fades", "only", "style", "remains", "same"],
    words: ["fashion", "fades", "only", "style", "remains", "the", "same"],
    id: "Fashion fades, only style remains the same.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["without", "football", "my", "life", "worth", "nothing"],
    words: ["without", "football", "my", "life", "is", "worth", "nothing"],
    id: "Without football, my life is worth nothing.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "write", "all", "my", "own", "speeches"],
    words: ["i", "write", "all", "of", "my", "own", "speeches"],
    id: "I write all of my own speeches.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["strictest", "law", "sometimes", "becomes", "severest", "injustice"],
    words: ["the", "strictest", "law", "sometimes", "becomes", "severest", "injustice"],
    id: "The strictest law sometimes becomes the severest injustice.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["god's", "promises", "shine", "on", "your", "problems"],
    words: ["let", "god's", "promises", "shine", "on", "your", "problems"],
    id: "Let God's promises shine on your problems.",
    author: "Corrie Ten Boom",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "came", "up", "from", "difficult", "background"],
    words: ["i", "came", "up", "from", "a", "difficult", "background"],
    id: "I came up from a difficult background.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["nobody", "goes", "there", "anymore", "too", "crowded"],
    words: ["nobody", "goes", "there", "anymore", "it's", "too", "crowded"],
    id: "Nobody goes there anymore. It's too crowded.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["tyranny", "always", "better", "organized", "than", "freedom"],
    words: ["tyranny", "is", "always", "better", "organized", "than", "freedom"],
    id: "Tyranny is always better organized than freedom.",
    author: "Charles Peguy",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["finance", "time", "devours", "its", "own", "children"],
    words: ["finance", "like", "time", "devours", "its", "own", "children"],
    id: "Finance, like time, devours its own children.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["transformation", "literally", "means", "beyond", "your", "form"],
    words: ["transformation", "literally", "means", "going", "beyond", "your", "form"],
    id: "Transformation literally means going beyond your form.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "growing", "old", "teaches", "all", "things"],
    words: ["but", "time", "growing", "old", "teaches", "all", "things"],
    id: "But time growing old teaches all things.",
    author: "Aeschylus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["god", "screens", "evermore", "from", "premature", "ideas"],
    words: ["god", "screens", "us", "evermore", "from", "premature", "ideas"],
    id: "God screens us evermore from premature ideas.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["without", "discipline", "there's", "life", "at", "all"],
    words: ["without", "discipline", "there's", "no", "life", "at", "all"],
    id: "Without discipline, there's no life at all.",
    author: "Katharine Hepburn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "made", "my", "fair", "share", "mistakes"],
    words: ["i", "made", "my", "fair", "share", "of", "mistakes"],
    id: "I made my fair share of mistakes.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["one", "man", "excels", "eloquence", "another", "arms"],
    words: ["one", "man", "excels", "in", "eloquence", "another", "arms"],
    id: "One man excels in eloquence, another in arms.",
    author: "Virgil",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["music", "fills", "infinite", "between", "two", "souls"],
    words: ["music", "fills", "the", "infinite", "between", "two", "souls"],
    id: "Music fills the infinite between two souls.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["there", "far", "more", "opportunity", "than", "ability"],
    words: ["there", "is", "far", "more", "opportunity", "than", "ability"],
    id: "There is far more opportunity than there is ability.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["external", "nature", "only", "internal", "writ", "large"],
    words: ["external", "nature", "is", "only", "internal", "writ", "large"],
    id: "External nature is only internal nature writ large.",
    author: "Swami Vivekananda",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'm", "most", "comfortable", "my", "birthday", "suit"],
    words: ["i'm", "most", "comfortable", "in", "my", "birthday", "suit"],
    id: "I'm most comfortable in my birthday suit.",
    author: "Amanda Seyfried",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["rule", "no1", "never", "lose", "no2", "forget"],
    words: ["rule", "no1", "never", "lose", "money", "no2", "forget"],
    id: "Rule No.1: Never lose money. Rule No.2: Never forget rule No.1.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "left", "on", "my", "15th", "birthday"],
    words: ["i", "left", "school", "on", "my", "15th", "birthday"],
    id: "I left school on my 15th birthday.",
    author: "David Bailey",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["complete", "abstinence", "easier", "than", "perfect", "moderation"],
    words: ["complete", "abstinence", "is", "easier", "than", "perfect", "moderation"],
    id: "Complete abstinence is easier than perfect moderation.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["more", "one", "pleases", "everybody", "less", "profoundly"],
    words: ["the", "more", "one", "pleases", "everybody", "less", "profoundly"],
    id: "The more one pleases everybody, the less one pleases profoundly.",
    author: "Stendhal",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["at", "one", "point", "decided", "enough", "was"],
    words: ["at", "one", "point", "he", "decided", "enough", "was"],
    id: "At one point he decided enough was enough.",
    author: "Steven Wright",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["character", "much", "easier", "kept", "than", "recovered"],
    words: ["character", "is", "much", "easier", "kept", "than", "recovered"],
    id: "Character is much easier kept than recovered.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["investing", "health", "will", "produce", "enormous", "benefits"],
    words: ["investing", "in", "health", "will", "produce", "enormous", "benefits"],
    id: "Investing in health will produce enormous benefits.",
    author: "Gro Harlem Brundtland",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "making", "your", "dreams", "come", "true"],
    words: ["happiness", "is", "making", "your", "dreams", "come", "true"],
    id: "Happiness is making your dreams come true.",
    author: "Jourdan Dunn",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["forgive", "many", "things", "others", "nothing", "yourself"],
    words: ["forgive", "many", "things", "in", "others", "nothing", "yourself"],
    id: "Forgive many things in others; nothing in yourself.",
    author: "Ausonius",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["chance", "makes", "our", "parents", "choice", "friends"],
    words: ["chance", "makes", "our", "parents", "but", "choice", "friends"],
    id: "Chance makes our parents, but choice makes our friends.",
    author: "Jacques Delille",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "your", "life", "by", "changing", "heart"],
    words: ["you", "change", "your", "life", "by", "changing", "heart"],
    id: "You change your life by changing your heart.",
    author: "Max Lucado",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["great", "deeds", "usually", "wrought", "at", "risks"],
    words: ["great", "deeds", "are", "usually", "wrought", "at", "risks"],
    id: "Great deeds are usually wrought at great risks.",
    author: "Herodotus",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "idiots", "refuse", "change", "their", "minds"],
    words: ["only", "idiots", "refuse", "to", "change", "their", "minds"],
    id: "Only idiots refuse to change their minds.",
    author: "Brigitte Bardot",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["pressed", "into", "service", "means", "out", "shape"],
    words: ["pressed", "into", "service", "means", "out", "of", "shape"],
    id: "Pressed into service means pressed out of shape.",
    author: "Robert Frost",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "family", "life", "was", "certainly", "intellectual"],
    words: ["our", "family", "life", "was", "certainly", "not", "intellectual"],
    id: "Our family life was certainly not intellectual.",
    author: "Douglass North",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "lasting", "business", "built", "on", "friendship"],
    words: ["all", "lasting", "business", "is", "built", "on", "friendship"],
    id: "All lasting business is built on friendship.",
    author: "Alfred A. Montapert",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "greatest", "success", "story", "been", "family"],
    words: ["my", "greatest", "success", "story", "has", "been", "family"],
    id: "My greatest success story has been my family.",
    author: "Alex Spanos",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "family", "more", "important", "than", "party"],
    words: ["my", "family", "is", "more", "important", "than", "party"],
    id: "My family is more important than my party.",
    author: "Zell Miller",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["carpe", "per", "diem", "-", "seize", "check"],
    words: ["carpe", "per", "diem", "-", "seize", "the", "check"],
    id: "Carpe per diem - seize the check.",
    author: "Robin Williams",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "means", "resistance", "transformation", "igniting", "energies"],
    words: ["change", "means", "resistance", "and", "transformation", "igniting", "energies"],
    id: "Change means resistance, and resistance means transformation and igniting energies.",
    author: "Yasmine Hamdan",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "betray", "somebody", "else", "also", "yourself"],
    words: ["when", "you", "betray", "somebody", "else", "also", "yourself"],
    id: "When you betray somebody else, you also betray yourself.",
    author: "Isaac Bashevis Singer",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["sometimes", "god", "writes", "straightforward", "twisted", "lines"],
    words: ["sometimes", "god", "writes", "straightforward", "in", "twisted", "lines"],
    id: "Sometimes God writes straightforward in twisted lines.",
    author: "Gloria Trevi",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["expect", "great", "things", "from", "god", "attempt"],
    words: ["expect", "great", "things", "from", "god", "attempt", "for"],
    id: "Expect great things from God, attempt great things for God.",
    author: "William Carey",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "wisdom", "comes", "from", "experience", "foolishness"],
    words: ["our", "wisdom", "comes", "from", "experience", "and", "foolishness"],
    id: "Our wisdom comes from our experience, and our experience comes from our foolishness.",
    author: "Sacha Guitry",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["face-to-face", "conversation", "unfolds", "slowly", "teaches", "patience"],
    words: ["face-to-face", "conversation", "unfolds", "slowly", "it", "teaches", "patience"],
    id: "Face-to-face conversation unfolds slowly. It teaches patience.",
    author: "Sherry Turkle",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["into", "each", "life", "rain", "must", "fall"],
    words: ["into", "each", "life", "some", "rain", "must", "fall"],
    id: "Into each life some rain must fall.",
    author: "Henry Wadsworth Longfellow",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "believe", "grow", "life", "through", "movies"],
    words: ["i", "believe", "grow", "in", "life", "through", "movies"],
    id: "I believe I grow in life through movies.",
    author: "Lee Je-hoon",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["academic", "success", "depends", "on", "research", "publications"],
    words: ["academic", "success", "depends", "on", "research", "and", "publications"],
    id: "Academic success depends on research and publications.",
    author: "Philip Zimbardo",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["cannot", "find", "peace", "by", "avoiding", "life"],
    words: ["you", "cannot", "find", "peace", "by", "avoiding", "life"],
    id: "You cannot find peace by avoiding life.",
    author: "Virginia Woolf",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["may", "god", "protect", "from", "gloomy", "saints"],
    words: ["may", "god", "protect", "me", "from", "gloomy", "saints"],
    id: "May God protect me from gloomy saints.",
    author: "Saint Teresa of Avila",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["patience", "never", "been", "my", "strong", "suit"],
    words: ["patience", "has", "never", "been", "my", "strong", "suit"],
    id: "Patience has never been my strong suit.",
    author: "Michael Chiklis",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["only", "god", "look", "at", "somebody's", "heart"],
    words: ["only", "god", "can", "look", "at", "somebody's", "heart"],
    id: "Only God can look at somebody's heart.",
    author: "Joel Osteen",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["superior", "man", "blames", "himself", "inferior", "others"],
    words: ["the", "superior", "man", "blames", "himself", "inferior", "others"],
    id: "The superior man blames himself. The inferior man blames others.",
    author: "Don Shula",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["sad", "patience", "too", "near", "neighbour", "despair"],
    words: ["sad", "patience", "too", "near", "neighbour", "to", "despair"],
    id: "Sad Patience, too near neighbour to despair.",
    author: "Matthew Arnold",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["difficult", "years", "lie", "ahead", "patience", "required"],
    words: ["difficult", "years", "lie", "ahead", "patience", "is", "required"],
    id: "Difficult years lie ahead, patience is required.",
    author: "Yiannis Stournaras",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "moves", "one", "direction", "memory", "another"],
    words: ["time", "moves", "in", "one", "direction", "memory", "another"],
    id: "Time moves in one direction, memory in another.",
    author: "William Gibson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["concision", "style", "precision", "thought", "decision", "life"],
    words: ["concision", "in", "style", "precision", "thought", "decision", "life"],
    id: "Concision in style, precision in thought, decision in life.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "unhappy", "one", "doubts", "everything", "nothing"],
    words: ["when", "unhappy", "one", "doubts", "everything", "happy", "nothing"],
    id: "When unhappy, one doubts everything; when happy, one doubts nothing.",
    author: "Joseph Roux",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["peace", "possible", "truth", "at", "all", "costs"],
    words: ["peace", "if", "possible", "truth", "at", "all", "costs"],
    id: "Peace if possible, truth at all costs.",
    author: "Martin Luther",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["faith", "makes", "all", "things", "possible", "easy"],
    words: ["faith", "makes", "all", "things", "possible", "love", "easy"],
    id: "Faith makes all things possible... love makes all things easy.",
    author: "Dwight L. Moody",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "said", "was", "greatest", "never", "smartest"],
    words: ["i", "said", "was", "the", "greatest", "never", "smartest"],
    id: "I said I was 'The Greatest,' I never said I was the smartest!",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["nation", "was", "ever", "ruined", "by", "trade"],
    words: ["no", "nation", "was", "ever", "ruined", "by", "trade"],
    id: "No nation was ever ruined by trade.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["never", "get", "out", "bed", "before", "noon"],
    words: ["never", "get", "out", "of", "bed", "before", "noon"],
    id: "Never get out of bed before noon.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["renunciation", "remains", "sorrow", "though", "borne", "willingly"],
    words: ["renunciation", "remains", "sorrow", "though", "a", "borne", "willingly"],
    id: "Renunciation remains sorrow, though a sorrow borne willingly.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["authority", "poisons", "everybody", "takes", "on", "himself"],
    words: ["authority", "poisons", "everybody", "who", "takes", "on", "himself"],
    id: "Authority poisons everybody who takes authority on himself.",
    author: "Golda Meir",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "cook", "under", "pressure", "trade", "perfection"],
    words: ["when", "you", "cook", "under", "pressure", "trade", "perfection"],
    id: "When you cook under pressure you trade perfection.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["art", "raises", "its", "head", "creeds", "relax"],
    words: ["art", "raises", "its", "head", "where", "creeds", "relax"],
    id: "Art raises its head where creeds relax.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["yesterday's", "home", "runs", "win", "today's", "games"],
    words: ["yesterday's", "home", "runs", "don't", "win", "today's", "games"],
    id: "Yesterday's home runs don't win today's games.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["happiness", "will", "come", "from", "materialism", "meaning"],
    words: ["happiness", "will", "come", "from", "materialism", "not", "meaning"],
    id: "Happiness will come from materialism, not from meaning.",
    author: "Andrei Platonov",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["eros", "will", "naked", "bodies", "friendship", "personalities"],
    words: ["eros", "will", "have", "naked", "bodies", "friendship", "personalities"],
    id: "Eros will have naked bodies; Friendship naked personalities.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "nation", "must", "come", "together", "unite"],
    words: ["our", "nation", "must", "come", "together", "to", "unite"],
    id: "Our nation must come together to unite.",
    author: "George W. Bush",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["positive", "anything", "better", "than", "negative", "nothing"],
    words: ["positive", "anything", "is", "better", "than", "negative", "nothing"],
    id: "Positive anything is better than negative nothing.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "feel", "exhausted", "teach", "too", "long"],
    words: ["i", "feel", "exhausted", "if", "teach", "too", "long"],
    id: "I feel exhausted if I teach too long.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "only", "follow", "one", "party", "vietnamese"],
    words: ["i", "only", "follow", "one", "party", "the", "vietnamese"],
    id: "I only follow one party: the Vietnamese party.",
    author: "Ho Chi Minh",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["kingdom", "founded", "on", "injustice", "never", "lasts"],
    words: ["a", "kingdom", "founded", "on", "injustice", "never", "lasts"],
    id: "A kingdom founded on injustice never lasts.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["lady", "doth", "protest", "too", "much", "methinks"],
    words: ["the", "lady", "doth", "protest", "too", "much", "methinks"],
    id: "The lady doth protest too much, methinks.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["man", "was", "ever", "wise", "by", "chance"],
    words: ["no", "man", "was", "ever", "wise", "by", "chance"],
    id: "No man was ever wise by chance.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["everybody's", "got", "plans", "until", "get", "hit"],
    words: ["everybody's", "got", "plans", "until", "they", "get", "hit"],
    id: "Everybody's got plans... until they get hit.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "children", "presence", "more", "than", "presents"],
    words: ["your", "children", "need", "presence", "more", "than", "presents"],
    id: "Your children need your presence more than your presents.",
    author: "Jesse Jackson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["tread", "softly", "because", "on", "my", "dreams"],
    words: ["tread", "softly", "because", "you", "on", "my", "dreams"],
    id: "Tread softly because you tread on my dreams.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["by", "ancestry", "i", "was", "born", "rule"],
    words: ["by", "ancestry", "i", "was", "born", "to", "rule"],
    id: "By ancestry, I was born to rule.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["better", "witty", "fool", "than", "foolish", "wit"],
    words: ["better", "a", "witty", "fool", "than", "foolish", "wit"],
    id: "Better a witty fool than a foolish wit.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "always", "seems", "impossible", "until", "inevitable"],
    words: ["change", "always", "seems", "impossible", "until", "it's", "inevitable"],
    id: "Change always seems impossible until it's inevitable.",
    author: "Sarah McBride",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["nothing", "strengthens", "authority", "much", "as", "silence"],
    words: ["nothing", "strengthens", "authority", "so", "much", "as", "silence"],
    id: "Nothing strengthens authority so much as silence.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "guess", "i've", "got", "smart", "wife"],
    words: ["i", "guess", "i've", "got", "a", "smart", "wife"],
    id: "I guess I've got a smart wife.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["confer", "dignity", "forgive", "express", "contempt", "forget"],
    words: ["to", "confer", "dignity", "forgive", "express", "contempt", "forget"],
    id: "To confer dignity, forgive. To express contempt, forget.",
    author: "Mason Cooley",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["when", "liberty", "becomes", "license", "dictatorship", "near"],
    words: ["when", "liberty", "becomes", "license", "dictatorship", "is", "near"],
    id: "When liberty becomes license, dictatorship is near.",
    author: "Will Durant",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["while", "saving", "your", "face", "losing", "ass"],
    words: ["while", "you're", "saving", "your", "face", "losing", "ass"],
    id: "While you're saving your face, you're losing your ass.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["men", "die", "despair", "while", "spirits", "ecstasy"],
    words: ["men", "die", "in", "despair", "while", "spirits", "ecstasy"],
    id: "Men die in despair, while spirits die in ecstasy.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["my", "toughest", "fight", "was", "first", "wife"],
    words: ["my", "toughest", "fight", "was", "with", "first", "wife"],
    id: "My toughest fight was with my first wife.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["mastering", "others", "strength", "yourself", "true", "power"],
    words: ["mastering", "others", "is", "strength", "yourself", "true", "power"],
    id: "Mastering others is strength. Mastering yourself is true power.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["above", "anything", "else", "i", "hate", "lose"],
    words: ["above", "anything", "else", "i", "hate", "to", "lose"],
    id: "Above anything else, I hate to lose.",
    author: "Jackie Robinson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["bluebird", "carries", "sky", "on", "his", "back"],
    words: ["the", "bluebird", "carries", "sky", "on", "his", "back"],
    id: "The bluebird carries the sky on his back.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["spending", "time", "outdoors", "makes", "feel", "great"],
    words: ["spending", "time", "outdoors", "makes", "you", "feel", "great"],
    id: "Spending time outdoors makes you feel great.",
    author: "Elizabeth Hurley",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["short", "saying", "often", "contains", "much", "wisdom"],
    words: ["a", "short", "saying", "often", "contains", "much", "wisdom"],
    id: "A short saying often contains much wisdom.",
    author: "Sophocles",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["much", "wisdom", "often", "goes", "fewest", "words"],
    words: ["much", "wisdom", "often", "goes", "with", "fewest", "words"],
    id: "Much wisdom often goes with fewest words.",
    author: "Sophocles",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["wisdom", "finds", "its", "literary", "expression", "literature"],
    words: ["wisdom", "finds", "its", "literary", "expression", "in", "literature"],
    id: "Wisdom finds its literary expression in wisdom literature.",
    author: "Paul Ricoeur",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["best", "wisdom", "comes", "from", "hardest", "struggle"],
    words: ["the", "best", "wisdom", "comes", "from", "hardest", "struggle"],
    id: "The best wisdom comes from the hardest struggle.",
    author: "Xavier Rudd",
    grammar: null,
    word_count: 7,
    all_intersection_count: 1,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "can't", "really", "describe", "my", "sound"],
    words: ["i", "can't", "really", "describe", "my", "sound"],
    id: "I can't really describe my sound.",
    author: "Lil Baby",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "was", "acting", "when", "playing", "baseball"],
    words: ["i", "was", "acting", "when", "playing", "baseball"],
    id: "I was acting when I was playing baseball.",
    author: "Bob Uecker",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["his", "ignorance", "was", "as", "remarkable", "knowledge"],
    words: ["his", "ignorance", "was", "as", "remarkable", "knowledge"],
    id: "His ignorance was as remarkable as his knowledge.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["conceit", "causes", "more", "conversation", "than", "wit"],
    words: ["conceit", "causes", "more", "conversation", "than", "wit"],
    id: "Conceit causes more conversation than wit.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "men", "by", "nature", "desire", "knowledge"],
    words: ["all", "men", "by", "nature", "desire", "knowledge"],
    id: "All men by nature desire knowledge.",
    author: "Aristotle",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["nobody", "makes", "movies", "bad", "on", "purpose"],
    words: ["nobody", "makes", "movies", "bad", "on", "purpose"],
    id: "Nobody makes movies bad on purpose.",
    author: "Roland Emmerich",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["i've", "learnt", "from", "my", "life", "experience"],
    words: ["i've", "learnt", "from", "my", "life", "experience"],
    id: "I've learnt from my life experience.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["there's", "nothing", "more", "american", "than", "movies"],
    words: ["there's", "nothing", "more", "american", "than", "movies"],
    id: "There's nothing more American than movies.",
    author: "Adam McKay",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["more", "than", "kisses", "letters", "mingle", "souls"],
    words: ["more", "than", "kisses", "letters", "mingle", "souls"],
    id: "More than kisses, letters mingle souls.",
    author: "John Donne",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["conflict", "cannot", "survive", "without", "your", "participation"],
    words: ["conflict", "cannot", "survive", "without", "your", "participation"],
    id: "Conflict cannot survive without your participation.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["our", "best", "thoughts", "come", "from", "others"],
    words: ["our", "best", "thoughts", "come", "from", "others"],
    id: "Our best thoughts come from others.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["among", "physicists", "i'm", "respected", "i", "hope"],
    words: ["among", "physicists", "i'm", "respected", "i", "hope"],
    id: "Among physicists, I'm respected I hope.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["i", "wear", "my", "heart", "on", "sleeve"],
    words: ["i", "wear", "my", "heart", "on", "sleeve"],
    id: "I wear my heart on my sleeve.",
    author: "Princess Diana",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["all", "our", "sweetest", "hours", "fly", "fastest"],
    words: ["all", "our", "sweetest", "hours", "fly", "fastest"],
    id: "All our sweetest hours fly fastest.",
    author: "Virgil",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["time", "makes", "more", "converts", "than", "reason"],
    words: ["time", "makes", "more", "converts", "than", "reason"],
    id: "Time makes more converts than reason.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["relationships", "based", "on", "obligation", "lack", "dignity"],
    words: ["relationships", "based", "on", "obligation", "lack", "dignity"],
    id: "Relationships based on obligation lack dignity.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["content", "makes", "poor", "men", "rich", "discontent"],
    words: ["content", "makes", "poor", "men", "rich", "discontent"],
    id: "Content makes poor men rich; discontent makes rich men poor.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["true", "detective", "did", "change", "my", "career"],
    words: ["true", "detective", "did", "change", "my", "career"],
    id: "'True Detective' did change my career.",
    author: "Alexandra Daddario",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["your", "body", "hears", "everything", "mind", "says"],
    words: ["your", "body", "hears", "everything", "mind", "says"],
    id: "Your body hears everything your mind says.",
    author: "Naomi Judd",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["change", "begets", "as", "much", "repetition", "reinforces"],
    words: ["change", "begets", "as", "much", "repetition", "reinforces"],
    id: "Change begets change as much as repetition reinforces repetition.",
    author: "Bill Drayton",
    grammar: null,
    word_count: 6,
    all_intersection_count: 0,
    new_words_count: 6,
  },
  {
    row_new_words: ["i'd", "rather", "optimistic", "wrong", "than", "pessimistic", "right"],
    words: ["i'd", "rather", "be", "optimistic", "and", "wrong", "than", "pessimistic", "right"],
    id: "I'd rather be optimistic and wrong than pessimistic and right.",
    author: "Elon Musk",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "was", "never", "genius", "without", "tincture", "madness"],
    words: ["there", "was", "never", "a", "genius", "without", "tincture", "of", "madness"],
    id: "There was never a genius without a tincture of madness.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["giving", "better", "than", "receiving", "because", "starts", "process"],
    words: ["giving", "is", "better", "than", "receiving", "because", "starts", "the", "process"],
    id: "Giving is better than receiving because giving starts the receiving process.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "swayed", "more", "by", "fear", "than", "reverence"],
    words: ["men", "are", "swayed", "more", "by", "fear", "than", "reverence"],
    id: "Men are swayed more by fear than by reverence.",
    author: "Aristotle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgive", "your", "enemies", "never", "forget", "their", "names"],
    words: ["forgive", "your", "enemies", "but", "never", "forget", "their", "names"],
    id: "Forgive your enemies, but never forget their names.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "did", "two", "or", "three", "plays", "summer"],
    words: ["i", "did", "two", "or", "three", "plays", "every", "summer"],
    id: "I did two or three plays every summer.",
    author: "Dabney Coleman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "never", "smoked", "cigarette", "until", "was", "nine"],
    words: ["i", "never", "smoked", "a", "cigarette", "until", "was", "nine"],
    id: "I never smoked a cigarette until I was nine.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["black-and-white", "always", "looks", "modern", "whatever", "word", "means"],
    words: ["black-and-white", "always", "looks", "modern", "whatever", "that", "word", "means"],
    id: "Black-and-white always looks modern, whatever that word means.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["association", "human", "beings", "lures", "one", "into", "self-observation"],
    words: ["association", "with", "human", "beings", "lures", "one", "into", "self-observation"],
    id: "Association with human beings lures one into self-observation.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["laws", "too", "gentle", "seldom", "obeyed", "severe", "executed"],
    words: ["laws", "too", "gentle", "are", "seldom", "obeyed", "severe", "executed"],
    id: "Laws too gentle are seldom obeyed; too severe, seldom executed.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "quarrel", "how", "wish", "had", "been", "blameless"],
    words: ["when", "we", "quarrel", "how", "wish", "had", "been", "blameless"],
    id: "When we quarrel, how we wish we had been blameless.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["made", "modest", "by", "great", "praise", "others", "insolent"],
    words: ["some", "are", "made", "modest", "by", "great", "praise", "others", "insolent"],
    id: "Some are made modest by great praise, others insolent.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "shut", "their", "doors", "against", "setting", "sun"],
    words: ["men", "shut", "their", "doors", "against", "a", "setting", "sun"],
    id: "Men shut their doors against a setting sun.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "always", "been", "fan", "telling", "honest", "stories"],
    words: ["i've", "always", "been", "a", "fan", "of", "telling", "honest", "stories"],
    id: "I've always been a fan of telling honest stories.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "truly", "great", "thoughts", "conceived", "by", "walking"],
    words: ["all", "truly", "great", "thoughts", "are", "conceived", "by", "walking"],
    id: "All truly great thoughts are conceived by walking.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["bad", "taste", "creates", "many", "more", "millionaires", "than"],
    words: ["bad", "taste", "creates", "many", "more", "millionaires", "than", "good"],
    id: "Bad taste creates many more millionaires than good taste.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "something", "curiously", "boring", "somebody", "else's", "happiness"],
    words: [
      "there",
      "is",
      "something",
      "curiously",
      "boring",
      "about",
      "somebody",
      "else's",
      "happiness",
    ],
    id: "There is something curiously boring about somebody else's happiness.",
    author: "Aldous Huxley",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "life", "frittered", "away", "by", "detail", "simplify"],
    words: ["our", "life", "is", "frittered", "away", "by", "detail", "simplify"],
    id: "Our life is frittered away by detail... simplify, simplify.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["everyone", "said", "i", "got", "my", "own", "sound"],
    words: ["everyone", "has", "said", "i", "got", "my", "own", "sound"],
    id: "Everyone has said I got my own sound.",
    author: "Lil Baby",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["formal", "education", "will", "make", "living", "self-education", "fortune"],
    words: [
      "formal",
      "education",
      "will",
      "make",
      "you",
      "a",
      "living",
      "self-education",
      "fortune",
    ],
    id: "Formal education will make you a living; self-education will make you a fortune.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "things", "cannot", "make", "themselves", "impossible"],
    words: ["i", "believe", "things", "cannot", "make", "themselves", "impossible"],
    id: "I believe things cannot make themselves impossible.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["greater", "our", "knowledge", "increases", "more", "ignorance", "unfolds"],
    words: ["the", "greater", "our", "knowledge", "increases", "more", "ignorance", "unfolds"],
    id: "The greater our knowledge increases the more our ignorance unfolds.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "can't", "explain", "9/11", "except", "evil", "man"],
    words: ["i", "can't", "explain", "9/11", "except", "the", "evil", "of", "man"],
    id: "I can't explain 9/11, except the evil of man.",
    author: "Billy Graham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "pass", "failure", "on", "your", "way", "success"],
    words: ["you", "always", "pass", "failure", "on", "your", "way", "to", "success"],
    id: "You always pass failure on your way to success.",
    author: "Mickey Rooney",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["either", "write", "something", "worth", "reading", "or", "writing"],
    words: ["either", "write", "something", "worth", "reading", "or", "do", "writing"],
    id: "Either write something worth reading or do something worth writing.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "spectacular",
      "achievement",
      "always",
      "preceded",
      "by",
      "unspectacular",
      "preparation",
    ],
    words: [
      "spectacular",
      "achievement",
      "is",
      "always",
      "preceded",
      "by",
      "unspectacular",
      "preparation",
    ],
    id: "Spectacular achievement is always preceded by unspectacular preparation.",
    author: "Robert H. Schuller",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["charity", "begins", "at", "home", "justice", "next", "door"],
    words: ["charity", "begins", "at", "home", "and", "justice", "next", "door"],
    id: "Charity begins at home, and justice begins next door.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "seen", "george", "foreman", "shadow", "boxing", "won"],
    words: ["i've", "seen", "george", "foreman", "shadow", "boxing", "and", "the", "won"],
    id: "I've seen George Foreman shadow boxing, and the shadow won.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "happiness", "depends", "on", "wisdom", "all", "way"],
    words: ["our", "happiness", "depends", "on", "wisdom", "all", "the", "way"],
    id: "Our happiness depends on wisdom all the way.",
    author: "Sophocles",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["small", "deeds", "done", "better", "than", "great", "planned"],
    words: ["small", "deeds", "done", "are", "better", "than", "great", "planned"],
    id: "Small deeds done are better than great deeds planned.",
    author: "Peter Marshall",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "always", "felt", "was", "six", "years", "old"],
    words: ["i", "always", "have", "felt", "that", "was", "six", "years", "old"],
    id: "I always have felt that I was six years old.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["age", "envy", "draw", "wrinkles", "on", "thy", "cheeks"],
    words: ["let", "age", "not", "envy", "draw", "wrinkles", "on", "thy", "cheeks"],
    id: "Let age, not envy, draw wrinkles on thy cheeks.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "many", "man", "more", "hair", "than", "wit"],
    words: ["there's", "many", "a", "man", "has", "more", "hair", "than", "wit"],
    id: "There's many a man has more hair than wit.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["true", "life", "lived", "when", "tiny", "changes", "occur"],
    words: ["true", "life", "is", "lived", "when", "tiny", "changes", "occur"],
    id: "True life is lived when tiny changes occur.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "get", "little", "bit", "emotional", "during", "game"],
    words: ["i", "get", "a", "little", "bit", "emotional", "during", "game"],
    id: "I get a little bit emotional during a game!",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["great", "minds", "discuss", "ideas", "average", "events", "small"],
    words: ["great", "minds", "discuss", "ideas", "average", "events", "small", "people"],
    id: "Great minds discuss ideas; average minds discuss events; small minds discuss people.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["without", "sense", "urgency", "desire", "loses", "its", "value"],
    words: ["without", "a", "sense", "of", "urgency", "desire", "loses", "its", "value"],
    id: "Without a sense of urgency, desire loses its value.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "family", "face", "flesh", "perishes", "live", "on"],
    words: ["i", "am", "the", "family", "face", "flesh", "perishes", "live", "on"],
    id: "I am the family face; flesh perishes, I live on.",
    author: "Thomas Hardy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["tell", "your", "wife", "often", "how", "terrific", "looks"],
    words: ["tell", "your", "wife", "often", "how", "terrific", "she", "looks"],
    id: "Tell your wife often how terrific she looks.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "brought", "myself", "down", "impeached", "by", "resigning"],
    words: ["i", "brought", "myself", "down", "impeached", "by", "resigning"],
    id: "I brought myself down. I impeached myself by resigning.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["hard", "necessity", "ones", "will", "fancy", "must", "conform"],
    words: ["to", "hard", "necessity", "ones", "will", "and", "fancy", "must", "conform"],
    id: "To hard necessity ones will and fancy must conform.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["truth", "weirder", "than", "any", "fiction", "i've", "seen"],
    words: ["truth", "is", "weirder", "than", "any", "fiction", "i've", "seen"],
    id: "Truth is weirder than any fiction I've seen.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["whoever", "despises", "himself", "nonetheless", "respects", "as", "one"],
    words: ["whoever", "despises", "himself", "nonetheless", "respects", "as", "one", "who"],
    id: "Whoever despises himself nonetheless respects himself as one who despises.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["age", "steals", "away", "all", "things", "even", "mind"],
    words: ["age", "steals", "away", "all", "things", "even", "the", "mind"],
    id: "Age steals away all things, even the mind.",
    author: "Virgil",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "was", "anything", "great", "achieved", "without", "danger"],
    words: ["never", "was", "anything", "great", "achieved", "without", "danger"],
    id: "Never was anything great achieved without danger.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["judge", "law", "student", "marks", "his", "own", "examination-papers"],
    words: ["judge", "a", "law", "student", "who", "marks", "his", "own", "examination-papers"],
    id: "Judge: a law student who marks his own examination-papers.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["pursue", "one", "great", "decisive", "aim", "force", "determination"],
    words: ["pursue", "one", "great", "decisive", "aim", "with", "force", "and", "determination"],
    id: "Pursue one great decisive aim with force and determination.",
    author: "Carl von Clausewitz",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["exuberant", "health", "always", "as", "such", "sickness", "also"],
    words: ["exuberant", "health", "is", "always", "as", "such", "sickness", "also"],
    id: "Exuberant health is always, as such, sickness also.",
    author: "Theodor W. Adorno",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "few", "dork", "movies", "made", "by", "dorks"],
    words: ["there's", "very", "few", "dork", "movies", "made", "by", "dorks"],
    id: "There's very few dork movies made by dorks.",
    author: "Zack Snyder",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "joined", "small", "growing", "post-trotskyite", "luxembourgist", "sect"],
    words: [
      "i",
      "joined",
      "a",
      "small",
      "but",
      "growing",
      "post-trotskyite",
      "luxembourgist",
      "sect",
    ],
    id: "I joined a small but growing post-Trotskyite Luxembourgist sect.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "how", "high", "bounce", "when", "hit", "bottom"],
    words: ["success", "is", "how", "high", "you", "bounce", "when", "hit", "bottom"],
    id: "Success is how high you bounce when you hit bottom.",
    author: "George S. Patton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["decent", "career", "was", "ever", "founded", "on", "public"],
    words: ["no", "decent", "career", "was", "ever", "founded", "on", "a", "public"],
    id: "No decent career was ever founded on a public.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "nothing", "more", "deceptive", "than", "obvious", "fact"],
    words: ["there", "is", "nothing", "more", "deceptive", "than", "an", "obvious", "fact"],
    id: "There is nothing more deceptive than an obvious fact.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["europe", "was", "created", "by", "history", "america", "philosophy"],
    words: ["europe", "was", "created", "by", "history", "america", "philosophy"],
    id: "Europe was created by history. America was created by philosophy.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["nation", "should", "stoke", "instability", "its", "neighbor's", "country"],
    words: ["no", "nation", "should", "stoke", "instability", "in", "its", "neighbor's", "country"],
    id: "No nation should stoke instability in its neighbor's country.",
    author: "Joe Biden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["truth", "uttered", "before", "its", "time", "always", "dangerous"],
    words: ["truth", "uttered", "before", "its", "time", "is", "always", "dangerous"],
    id: "Truth uttered before its time is always dangerous.",
    author: "Mencius",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "3rd", "birthday", "party", "was", "funny", "themed"],
    words: ["my", "3rd", "birthday", "party", "was", "funny", "girl", "themed"],
    id: "My 3rd birthday party was 'Funny Girl' themed.",
    author: "Beanie Feldstein",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgiveness", "empowering", "yourself", "rather", "than", "your", "past"],
    words: [
      "forgiveness",
      "is",
      "about",
      "empowering",
      "yourself",
      "rather",
      "than",
      "your",
      "past",
    ],
    id: "Forgiveness is about empowering yourself, rather than empowering your past.",
    author: "T. D. Jakes",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "nothing", "terrible", "as", "activity", "without", "insight"],
    words: ["there", "is", "nothing", "so", "terrible", "as", "activity", "without", "insight"],
    id: "There is nothing so terrible as activity without insight.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "greatest", "asset", "earning", "ability", "resource", "time"],
    words: ["your", "greatest", "asset", "is", "earning", "ability", "resource", "time"],
    id: "Your greatest asset is your earning ability. Your greatest resource is your time.",
    author: "Brian Tracy",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["really", "great", "talent", "finds", "its", "happiness", "execution"],
    words: ["a", "really", "great", "talent", "finds", "its", "happiness", "in", "execution"],
    id: "A really great talent finds its happiness in execution.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "change", "winning", "game", "always", "losing", "one"],
    words: ["never", "change", "a", "winning", "game", "always", "losing", "one"],
    id: "Never change a winning game; always change a losing one.",
    author: "Bill Tilden",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["genius", "always", "finds", "itself", "century", "too", "early"],
    words: ["genius", "always", "finds", "itself", "a", "century", "too", "early"],
    id: "Genius always finds itself a century too early.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["excuses", "change", "nothing", "make", "everyone", "feel", "better"],
    words: ["excuses", "change", "nothing", "but", "make", "everyone", "feel", "better"],
    id: "Excuses change nothing, but make everyone feel better.",
    author: "Mason Cooley",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["waste", "time", "on", "jealousy", "sometimes", "ahead", "behind"],
    words: ["don't", "waste", "time", "on", "jealousy", "sometimes", "you're", "ahead", "behind"],
    id: "Don't waste time on jealousy. Sometimes you're ahead, sometimes you're behind.",
    author: "Mary Schmich",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "too", "often", "repeated", "which", "sufficiently", "learned"],
    words: ["that", "is", "never", "too", "often", "repeated", "which", "sufficiently", "learned"],
    id: "That is never too often repeated, which is never sufficiently learned.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "only", "understand", "friendship", "or", "scorched", "earth"],
    words: ["i", "only", "understand", "friendship", "or", "scorched", "earth"],
    id: "I only understand friendship or scorched earth.",
    author: "Roger Ailes",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["education", "hanging", "around", "until", "you've", "caught", "on"],
    words: ["education", "is", "hanging", "around", "until", "you've", "caught", "on"],
    id: "Education is hanging around until you've caught on.",
    author: "Robert Frost",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "would", "fain", "grow", "old", "many", "things"],
    words: ["i", "would", "fain", "grow", "old", "learning", "many", "things"],
    id: "I would fain grow old learning many things.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["age", "carries", "all", "things", "away", "even", "mind"],
    words: ["age", "carries", "all", "things", "away", "even", "the", "mind"],
    id: "Age carries all things away, even the mind.",
    author: "Virgil",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["will", "never", "fulfill", "your", "destiny", "doing", "despise"],
    words: ["you", "will", "never", "fulfill", "your", "destiny", "doing", "work", "despise"],
    id: "You will never fulfill your destiny doing work you despise.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["really", "advanced", "civilization", "based", "on", "advances", "energy"],
    words: ["really", "advanced", "civilization", "is", "based", "on", "advances", "in", "energy"],
    id: "Really advanced civilization is based on advances in energy.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "always", "been", "old-line", "henry", "clay", "whig"],
    words: ["i", "have", "always", "been", "an", "old-line", "henry", "clay", "whig"],
    id: "I have always been an old-line Henry Clay Whig.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["from", "deepest", "desires", "often", "come", "deadliest", "hate"],
    words: ["from", "the", "deepest", "desires", "often", "come", "deadliest", "hate"],
    id: "From the deepest desires often come the deadliest hate.",
    author: "Socrates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["thyself", "i", "knew", "myself", "would", "run", "away"],
    words: ["know", "thyself", "if", "i", "knew", "myself", "would", "run", "away"],
    id: "Know thyself? If I knew myself I would run away.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["human", "beings", "crave", "freedom", "at", "their", "core"],
    words: ["human", "beings", "crave", "freedom", "at", "their", "core"],
    id: "Human beings crave freedom at their core.",
    author: "John Ensign",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["once", "i", "get", "on", "puzzle", "can't", "off"],
    words: ["once", "i", "get", "on", "a", "puzzle", "can't", "off"],
    id: "Once I get on a puzzle, I can't get off.",
    author: "Richard P. Feynman",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "was", "arrested", "three", "times", "tortured", "once"],
    words: ["i", "was", "arrested", "three", "times", "and", "tortured", "once"],
    id: "I was arrested three times and tortured once.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "educators",
      "take",
      "something",
      "simple",
      "make",
      "complicated",
      "communicators",
    ],
    words: [
      "educators",
      "take",
      "something",
      "simple",
      "and",
      "make",
      "it",
      "complicated",
      "communicators",
    ],
    id: "Educators take something simple and make it complicated. Communicators take something complicated and make it simple.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["trayvon", "martin", "could", "been", "35", "years", "ago"],
    words: ["trayvon", "martin", "could", "have", "been", "me", "35", "years", "ago"],
    id: "Trayvon Martin could have been me 35 years ago.",
    author: "Barack Obama",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "was", "never", "war", "or", "bad", "peace"],
    words: ["there", "was", "never", "a", "good", "war", "or", "bad", "peace"],
    id: "There was never a good war, or a bad peace.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["growing", "up", "i", "was", "always", "small", "guy"],
    words: ["growing", "up", "i", "was", "always", "the", "small", "guy"],
    id: "Growing up, I was always the small guy.",
    author: "Wayne Gretzky",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["soul", "sees", "beauty", "may", "sometimes", "walk", "alone"],
    words: ["the", "soul", "that", "sees", "beauty", "may", "sometimes", "walk", "alone"],
    id: "The soul that sees beauty may sometimes walk alone.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["convictions", "more", "dangerous", "foes", "truth", "than", "lies"],
    words: ["convictions", "are", "more", "dangerous", "foes", "of", "truth", "than", "lies"],
    id: "Convictions are more dangerous foes of truth than lies.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["government's", "first", "duty", "highest", "obligation", "public", "safety"],
    words: [
      "government's",
      "first",
      "duty",
      "and",
      "highest",
      "obligation",
      "is",
      "public",
      "safety",
    ],
    id: "Government's first duty and highest obligation is public safety.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "my", "books", "sold", "on", "airport", "bookstalls"],
    words: ["i", "want", "my", "books", "sold", "on", "airport", "bookstalls"],
    id: "I want my books sold on airport bookstalls.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "man", "cannot", "practice", "many", "arts", "success"],
    words: ["one", "man", "cannot", "practice", "many", "arts", "with", "success"],
    id: "One man cannot practice many arts with success.",
    author: "Plato",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "counted", "sweetest", "by", "those", "never", "succeed"],
    words: ["success", "is", "counted", "sweetest", "by", "those", "who", "never", "succeed"],
    id: "Success is counted sweetest by those who never succeed.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["eternal", "principles", "govern", "happiness", "apply", "equally", "all"],
    words: [
      "eternal",
      "principles",
      "that",
      "govern",
      "happiness",
      "apply",
      "equally",
      "to",
      "all",
    ],
    id: "Eternal principles that govern happiness apply equally to all.",
    author: "Russell M. Nelson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["after", "first", "blush", "sin", "comes", "its", "indifference"],
    words: ["after", "the", "first", "blush", "of", "sin", "comes", "its", "indifference"],
    id: "After the first blush of sin comes its indifference.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "breeds", "complacency", "failure", "only", "paranoid", "survive"],
    words: ["success", "breeds", "complacency", "failure", "only", "the", "paranoid", "survive"],
    id: "Success breeds complacency. Complacency breeds failure. Only the paranoid survive.",
    author: "Andy Grove",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["absent", "never", "without", "fault", "nor", "present", "excuse"],
    words: ["the", "absent", "are", "never", "without", "fault", "nor", "present", "excuse"],
    id: "The absent are never without fault, nor the present without excuse.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["habits", "formed", "at", "youth", "make", "all", "difference"],
    words: ["good", "habits", "formed", "at", "youth", "make", "all", "the", "difference"],
    id: "Good habits formed at youth make all the difference.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "patience", "will", "achieve", "more", "than", "force"],
    words: ["our", "patience", "will", "achieve", "more", "than", "force"],
    id: "Our patience will achieve more than our force.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "left", "canada", "come", "hollywood", "make", "movies"],
    words: ["i", "left", "canada", "to", "come", "hollywood", "make", "movies"],
    id: "I left Canada to come to Hollywood to make movies.",
    author: "Ryan Gosling",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["god's", "gifts", "put", "man's", "best", "dreams", "shame"],
    words: ["god's", "gifts", "put", "man's", "best", "dreams", "to", "shame"],
    id: "God's gifts put man's best dreams to shame.",
    author: "Elizabeth Barrett Browning",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["remember", "upon", "conduct", "each", "depends", "fate", "all"],
    words: ["remember", "upon", "the", "conduct", "of", "each", "depends", "fate", "all"],
    id: "Remember upon the conduct of each depends the fate of all.",
    author: "Alexander the Great",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "make", "friends", "faster", "easier", "than", "journalists"],
    words: ["i", "make", "friends", "faster", "and", "easier", "than", "journalists"],
    id: "I make friends faster and easier than journalists.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "been", "through", "all", "bad", "parts", "streets"],
    words: ["i've", "been", "through", "all", "the", "bad", "parts", "of", "streets"],
    id: "I've been through all the bad parts of the streets.",
    author: "Lil Baby",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["thine", "own", "palace", "or", "world's", "thy", "jail"],
    words: ["be", "thine", "own", "palace", "or", "the", "world's", "thy", "jail"],
    id: "Be thine own palace, or the world's thy jail.",
    author: "John Donne",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "loved", "movies", "ever", "since", "was", "kid"],
    words: ["i", "loved", "movies", "ever", "since", "was", "a", "kid"],
    id: "I loved movies ever since I was a kid.",
    author: "Roger Deakins",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "attitude", "toward", "life", "determines", "life's", "towards"],
    words: ["our", "attitude", "toward", "life", "determines", "life's", "towards", "us"],
    id: "Our attitude toward life determines life's attitude towards us.",
    author: "John N. Mitchell",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["constitution", "guide", "which", "i", "never", "will", "abandon"],
    words: ["the", "constitution", "is", "guide", "which", "i", "never", "will", "abandon"],
    id: "The Constitution is the guide which I never will abandon.",
    author: "George Washington",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["heaven", "under", "our", "feet", "as", "over", "heads"],
    words: ["heaven", "is", "under", "our", "feet", "as", "well", "over", "heads"],
    id: "Heaven is under our feet as well as over our heads.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "had", "great", "success", "being", "total", "idiot"],
    words: ["i've", "had", "great", "success", "being", "a", "total", "idiot"],
    id: "I've had great success being a total idiot.",
    author: "Jerry Lewis",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["power", "over", "others", "weakness", "disguised", "as", "strength"],
    words: ["power", "over", "others", "is", "weakness", "disguised", "as", "strength"],
    id: "Power over others is weakness disguised as strength.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["become", "by", "performing", "action", "temperate", "actions", "brave"],
    words: ["we", "become", "just", "by", "performing", "action", "temperate", "actions", "brave"],
    id: "We become just by performing just action, temperate by performing temperate actions, brave by performing brave action.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "attitude", "towards", "failure", "determines", "altitude", "after"],
    words: ["your", "attitude", "towards", "failure", "determines", "altitude", "after"],
    id: "Your attitude towards failure determines your altitude after failure.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["motivation", "will", "almost", "always", "beat", "mere", "talent"],
    words: ["motivation", "will", "almost", "always", "beat", "mere", "talent"],
    id: "Motivation will almost always beat mere talent.",
    author: "Norman Ralph Augustine",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "empty", "souls", "tend", "toward", "extreme", "opinions"],
    words: ["all", "empty", "souls", "tend", "toward", "extreme", "opinions"],
    id: "All empty souls tend toward extreme opinions.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "away", "nothing", "liberally", "as", "their", "advice"],
    words: ["men", "give", "away", "nothing", "so", "liberally", "as", "their", "advice"],
    id: "Men give away nothing so liberally as their advice.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "never", "reliable", "narrator", "unbiased", "or", "objective"],
    words: ["i'm", "never", "a", "reliable", "narrator", "unbiased", "or", "objective"],
    id: "I'm never a reliable narrator, unbiased or objective.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["poverty", "was", "greatest", "motivating", "factor", "my", "life"],
    words: ["poverty", "was", "the", "greatest", "motivating", "factor", "in", "my", "life"],
    id: "Poverty was the greatest motivating factor in my life.",
    author: "Jimmy Dean",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["violence", "even", "intentioned", "always", "rebounds", "upon", "oneself"],
    words: ["violence", "even", "well", "intentioned", "always", "rebounds", "upon", "oneself"],
    id: "Violence, even well intentioned, always rebounds upon oneself.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["putin", "sought", "keep", "ukraine", "weak", "through", "corruption"],
    words: ["putin", "sought", "to", "keep", "ukraine", "weak", "through", "corruption"],
    id: "Putin sought to keep Ukraine weak through corruption.",
    author: "Joe Biden",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "makes", "nervous", "-", "except", "making", "films"],
    words: ["everything", "makes", "me", "nervous", "-", "except", "making", "films"],
    id: "Everything makes me nervous - except making films.",
    author: "Elizabeth Taylor",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["holy", "wisdom", "confounds", "satan", "all", "his", "wickednesses"],
    words: ["holy", "wisdom", "confounds", "satan", "and", "all", "his", "wickednesses"],
    id: "Holy wisdom confounds Satan and all his wickednesses.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "lived", "places", "these", "guys", "can't", "defecate"],
    words: ["i've", "lived", "places", "these", "guys", "can't", "defecate", "in"],
    id: "I've lived places these guys can't defecate in.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["start", "wide", "expand", "further", "never", "look", "back"],
    words: ["start", "wide", "expand", "further", "and", "never", "look", "back"],
    id: "Start wide, expand further, and never look back.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "more", "life", "than", "increasing", "its", "speed"],
    words: ["there", "is", "more", "to", "life", "than", "increasing", "its", "speed"],
    id: "There is more to life than increasing its speed.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "investing",
      "poor",
      "requires",
      "participation",
      "from",
      "entire",
      "community",
    ],
    words: [
      "investing",
      "for",
      "the",
      "poor",
      "requires",
      "participation",
      "from",
      "entire",
      "community",
    ],
    id: "Investing for the poor requires participation from the entire community.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "accept", "ultimatums", "conventional", "wisdom", "or", "absolutes"],
    words: ["never", "accept", "ultimatums", "conventional", "wisdom", "or", "absolutes"],
    id: "Never accept ultimatums, conventional wisdom, or absolutes.",
    author: "Christopher Reeve",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "make", "audience", "suffer", "as", "much", "possible"],
    words: ["always", "make", "the", "audience", "suffer", "as", "much", "possible"],
    id: "Always make the audience suffer as much as possible.",
    author: "Alfred Hitchcock",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["wars", "poor", "chisels", "carving", "out", "peaceful", "tomorrows"],
    words: ["wars", "are", "poor", "chisels", "for", "carving", "out", "peaceful", "tomorrows"],
    id: "Wars are poor chisels for carving out peaceful tomorrows.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["god's", "promises", "all", "on", "condition", "humble", "obedience"],
    words: ["god's", "promises", "are", "all", "on", "condition", "of", "humble", "obedience"],
    id: "God's promises are all on condition of humble obedience.",
    author: "Ellen G. White",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "connectivity",
      "enables",
      "transparency",
      "better",
      "government",
      "education",
      "health",
    ],
    words: [
      "connectivity",
      "enables",
      "transparency",
      "for",
      "better",
      "government",
      "education",
      "and",
      "health",
    ],
    id: "Connectivity enables transparency for better government, education, and health.",
    author: "Bill Gates",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "never", "fully", "satisfied", "any", "microsoft", "product"],
    words: ["i'm", "never", "fully", "satisfied", "with", "any", "microsoft", "product"],
    id: "I'm never fully satisfied with any Microsoft product.",
    author: "Bill Gates",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["things", "happen", "when", "get", "your", "priorities", "straight"],
    words: ["good", "things", "happen", "when", "you", "get", "your", "priorities", "straight"],
    id: "Good things happen when you get your priorities straight.",
    author: "Scott Caan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "always", "been", "product", "placement", "bond", "movies"],
    words: ["there's", "always", "been", "product", "placement", "in", "bond", "movies"],
    id: "There's always been product placement in Bond movies.",
    author: "Pierce Brosnan",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["knowledge", "rests", "upon", "truth", "alone", "error", "also"],
    words: ["knowledge", "rests", "not", "upon", "truth", "alone", "but", "error", "also"],
    id: "Knowledge rests not upon truth alone, but upon error also.",
    author: "Carl Jung",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["piety", "requires", "honor", "truth", "above", "our", "friends"],
    words: ["piety", "requires", "us", "to", "honor", "truth", "above", "our", "friends"],
    id: "Piety requires us to honor truth above our friends.",
    author: "Aristotle",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["moral", "arc", "universe", "bends", "at", "elbow", "justice"],
    words: ["the", "moral", "arc", "of", "universe", "bends", "at", "elbow", "justice"],
    id: "The moral arc of the universe bends at the elbow of justice.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "never", "lays", "its", "finger", "on", "pulse"],
    words: ["happiness", "never", "lays", "its", "finger", "on", "pulse"],
    id: "Happiness never lays its finger on its pulse.",
    author: "Adam Smith",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "loyal", "friend", "worth", "ten", "thousand", "relatives"],
    words: ["one", "loyal", "friend", "is", "worth", "ten", "thousand", "relatives"],
    id: "One loyal friend is worth ten thousand relatives.",
    author: "Euripides",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["focus", "on", "being", "balanced", "-", "success", "balance"],
    words: ["focus", "on", "being", "balanced", "-", "success", "is", "balance"],
    id: "Focus on being balanced - success is balance.",
    author: "Laila Ali",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "talk", "killing", "time", "while", "quietly", "kills"],
    words: ["men", "talk", "of", "killing", "time", "while", "quietly", "kills", "them"],
    id: "Men talk of killing time, while time quietly kills them.",
    author: "Dion Boucicault",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["formula", "success", "rise", "early", "hard", "strike", "oil"],
    words: ["formula", "for", "success", "rise", "early", "work", "hard", "strike", "oil"],
    id: "Formula for success: rise early, work hard, strike oil.",
    author: "J. Paul Getty",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "was", "naturally", "aggressive", "left-back", "cut-throat", "tackler"],
    words: ["i", "was", "a", "naturally", "aggressive", "left-back", "cut-throat", "tackler"],
    id: "I was a naturally aggressive left-back, a cut-throat tackler.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["nothing", "great", "was", "ever", "achieved", "without", "enthusiasm"],
    words: ["nothing", "great", "was", "ever", "achieved", "without", "enthusiasm"],
    id: "Nothing great was ever achieved without enthusiasm.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "wish", "could", "shut", "up", "can't", "won't"],
    words: ["i", "wish", "could", "shut", "up", "but", "can't", "and", "won't"],
    id: "I wish I could shut up, but I can't, and I won't.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["remember", "life's", "big", "changes", "rarely", "advance", "warning"],
    words: ["remember", "that", "life's", "big", "changes", "rarely", "give", "advance", "warning"],
    id: "Remember that life's big changes rarely give advance warning.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["civilization", "begins", "order", "grows", "liberty", "dies", "chaos"],
    words: ["civilization", "begins", "with", "order", "grows", "liberty", "and", "dies", "chaos"],
    id: "Civilization begins with order, grows with liberty and dies with chaos.",
    author: "Will Durant",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["among", "generally", "corrupt", "liberty", "cannot", "long", "exist"],
    words: ["among", "a", "people", "generally", "corrupt", "liberty", "cannot", "long", "exist"],
    id: "Among a people generally corrupt liberty cannot long exist.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "steady", "progress", "toward", "one's", "personal", "goals"],
    words: ["success", "is", "steady", "progress", "toward", "one's", "personal", "goals"],
    id: "Success is steady progress toward one's personal goals.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["those", "spend", "too", "fast", "never", "grow", "rich"],
    words: ["those", "who", "spend", "too", "fast", "never", "grow", "rich"],
    id: "Those who spend too fast never grow rich.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "diseases", "run", "into", "one", "old", "age"],
    words: ["all", "diseases", "run", "into", "one", "old", "age"],
    id: "All diseases run into one, old age.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["superior", "man", "thinks", "always", "virtue", "common", "comfort"],
    words: ["the", "superior", "man", "thinks", "always", "of", "virtue", "common", "comfort"],
    id: "The superior man thinks always of virtue; the common man thinks of comfort.",
    author: "Confucius",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["undiscovered", "country", "from", "whose", "bourn", "traveler", "returns"],
    words: [
      "the",
      "undiscovered",
      "country",
      "from",
      "whose",
      "bourn",
      "no",
      "traveler",
      "returns",
    ],
    id: "The undiscovered country from whose bourn no traveler returns.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["pace", "progress", "on", "mars", "depends", "upon", "spacex"],
    words: ["the", "pace", "of", "progress", "on", "mars", "depends", "upon", "spacex"],
    id: "The pace of progress on Mars depends upon the pace of progress of SpaceX.",
    author: "Elon Musk",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "first", "win", "feels", "better", "than", "draw"],
    words: ["my", "first", "win", "feels", "better", "than", "a", "draw"],
    id: "My first win feels better than a draw!",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["justice", "will", "overtake", "fabricators", "lies", "false", "witnesses"],
    words: [
      "justice",
      "will",
      "overtake",
      "fabricators",
      "of",
      "lies",
      "and",
      "false",
      "witnesses",
    ],
    id: "Justice will overtake fabricators of lies and false witnesses.",
    author: "Heraclitus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "grew", "up", "fantastic", "southern", "food", "california"],
    words: ["i", "grew", "up", "with", "fantastic", "southern", "food", "in", "california"],
    id: "I grew up with fantastic Southern food. In Southern California.",
    author: "Nora Ephron",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["mankind", "must", "put", "end", "war", "before", "puts"],
    words: ["mankind", "must", "put", "an", "end", "to", "war", "before", "puts"],
    id: "Mankind must put an end to war before war puts an end to mankind.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "one", "god's", "blunders", "or", "god", "man's"],
    words: ["is", "man", "one", "of", "god's", "blunders", "or", "god", "man's"],
    id: "Is man one of God's blunders? Or is God one of man's blunders?",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["fools", "act", "on", "imagination", "without", "knowledge", "pedants"],
    words: ["fools", "act", "on", "imagination", "without", "knowledge", "pedants"],
    id: "Fools act on imagination without knowledge, pedants act on knowledge without imagination.",
    author: "Alfred North Whitehead",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "arteries", "grow", "hard", "heart", "grows", "soft"],
    words: ["as", "the", "arteries", "grow", "hard", "heart", "grows", "soft"],
    id: "As the arteries grow hard, the heart grows soft.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "lady", "mama", "grandma", "everybody", "i'm", "crazy"],
    words: ["my", "lady", "mama", "grandma", "everybody", "think", "i'm", "crazy"],
    id: "My lady, my mama, my grandma, everybody think I'm crazy.",
    author: "Rod Wave",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "sins", "more", "easily", "remembered", "than", "deeds"],
    words: ["our", "sins", "are", "more", "easily", "remembered", "than", "good", "deeds"],
    id: "Our sins are more easily remembered than our good deeds.",
    author: "Democritus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "never", "fortunate", "or", "as", "unfortunate", "imagines"],
    words: ["one", "is", "never", "fortunate", "or", "as", "unfortunate", "imagines"],
    id: "One is never fortunate or as unfortunate as one imagines.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["women", "may", "fall", "when", "there's", "strength", "men"],
    words: ["women", "may", "fall", "when", "there's", "no", "strength", "in", "men"],
    id: "Women may fall when there's no strength in men.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "important", "always", "begins", "from", "something", "trivial"],
    words: ["everything", "important", "always", "begins", "from", "something", "trivial"],
    id: "Everything important always begins from something trivial.",
    author: "Donald Hall",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "mother", "always", "bought", "our", "birthday", "gifts"],
    words: ["my", "mother", "always", "bought", "our", "birthday", "gifts"],
    id: "My mother always bought our birthday gifts.",
    author: "Bruce Eric Kaplan",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "political", "point", "worth", "my", "son's", "life"],
    words: ["there's", "no", "political", "point", "worth", "my", "son's", "life"],
    id: "There's no political point worth my son's life.",
    author: "Joe Biden",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["survival", "was", "my", "only", "hope", "success", "revenge"],
    words: ["survival", "was", "my", "only", "hope", "success", "revenge"],
    id: "Survival was my only hope, success my only revenge.",
    author: "Patricia Cornwell",
    grammar: null,
    word_count: 7,
    all_intersection_count: 0,
    new_words_count: 7,
  },
  {
    row_new_words: ["policies", "many", "principles", "few", "will", "change", "never"],
    words: ["policies", "are", "many", "principles", "few", "will", "change", "never", "do"],
    id: "Policies are many, Principles are few, Policies will change, Principles never do.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["nothing", "more", "fearful", "than", "imagination", "without", "taste"],
    words: ["nothing", "is", "more", "fearful", "than", "imagination", "without", "taste"],
    id: "Nothing is more fearful than imagination without taste.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["first", "ignore", "then", "laugh", "at", "fight", "win"],
    words: ["first", "they", "ignore", "you", "then", "laugh", "at", "fight", "win"],
    id: "First they ignore you, then they laugh at you, then they fight you, then you win.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "compare", "my", "crazy", "story", "meek's", "tony"],
    words: ["they", "always", "compare", "my", "crazy", "story", "to", "meek's", "tony"],
    id: "They always compare my 'Crazy Story' to Meek's 'Tony Story.'",
    author: "King Von",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["even", "after", "bad", "harvest", "there", "must", "sowing"],
    words: ["even", "after", "a", "bad", "harvest", "there", "must", "be", "sowing"],
    id: "Even after a bad harvest there must be sowing.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "hate", "past", "-", "especially", "my", "own"],
    words: ["i", "hate", "the", "past", "-", "especially", "my", "own"],
    id: "I hate the past - especially my own past.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 8,
    all_intersection_count: 1,
    new_words_count: 7,
  },
  {
    row_new_words: ["real", "world", "means", "saying", "sorry", "10", "times"],
    words: [
      "love",
      "in",
      "the",
      "real",
      "world",
      "means",
      "saying",
      "you're",
      "sorry",
      "10",
      "times",
      "a",
      "day",
    ],
    id: "Love in the real world means saying you're sorry 10 times a day.",
    author: "Kathie Lee Gifford",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["attitude", "faith", "opposite", "clinging", "belief", "holding", "on"],
    words: [
      "and",
      "the",
      "attitude",
      "of",
      "faith",
      "is",
      "very",
      "opposite",
      "clinging",
      "to",
      "belief",
      "holding",
      "on",
    ],
    id: "And the attitude of faith is the very opposite of clinging to belief, of holding on.",
    author: "Alan Watts",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "consists", "holding", "cards", "playing", "those", "hold"],
    words: [
      "life",
      "consists",
      "not",
      "in",
      "holding",
      "good",
      "cards",
      "but",
      "playing",
      "those",
      "you",
      "hold",
      "well",
    ],
    id: "Life consists not in holding good cards but in playing those you hold well.",
    author: "Josh Billings",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["united", "states", "never", "will", "at", "war", "islam"],
    words: [
      "the",
      "united",
      "states",
      "is",
      "not",
      "and",
      "never",
      "will",
      "be",
      "at",
      "war",
      "with",
      "islam",
    ],
    id: "The United States is not, and never will be, at war with Islam.",
    author: "Barack Obama",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["cobb", "prick", "sure", "hit", "god", "almighty", "man"],
    words: [
      "cobb",
      "is",
      "a",
      "prick",
      "but",
      "he",
      "sure",
      "can",
      "hit",
      "god",
      "almighty",
      "that",
      "man",
    ],
    id: "Cobb is a prick. But he sure can hit. God Almighty, that man can hit.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "honestly", "cheech", "chong", "movies", "i've", "got"],
    words: [
      "i",
      "honestly",
      "don't",
      "love",
      "the",
      "cheech",
      "and",
      "chong",
      "movies",
      "i've",
      "got",
      "to",
      "say",
    ],
    id: "I honestly don't love the Cheech and Chong movies, I've got to say.",
    author: "Seth Rogen",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["low-income", "countries", "getting", "health", "post", "hard", "expensive"],
    words: [
      "in",
      "low-income",
      "countries",
      "getting",
      "to",
      "a",
      "health",
      "post",
      "is",
      "hard",
      "it's",
      "very",
      "expensive",
    ],
    id: "In low-income countries, getting to a health post is hard. It's very expensive.",
    author: "Bill Gates",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["measure", "life", "after", "all", "its", "duration", "donation"],
    words: [
      "the",
      "measure",
      "of",
      "a",
      "life",
      "after",
      "all",
      "is",
      "not",
      "its",
      "duration",
      "but",
      "donation",
    ],
    id: "The measure of a life, after all, is not its duration, but its donation.",
    author: "Corrie Ten Boom",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "would", "rather", "able", "appreciate", "things", "than"],
    words: [
      "i",
      "would",
      "rather",
      "be",
      "able",
      "to",
      "appreciate",
      "things",
      "can",
      "not",
      "have",
      "than",
      "am",
    ],
    id: "I would rather be able to appreciate things I can not have than to have things I am not able to appreciate.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["world", "nothing", "said", "certain", "except", "death", "taxes"],
    words: [
      "in",
      "this",
      "world",
      "nothing",
      "can",
      "be",
      "said",
      "to",
      "certain",
      "except",
      "death",
      "and",
      "taxes",
    ],
    id: "In this world nothing can be said to be certain, except death and taxes.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["middle", "east", "obviously", "issue", "plagued", "region", "centuries"],
    words: [
      "the",
      "middle",
      "east",
      "is",
      "obviously",
      "an",
      "issue",
      "that",
      "has",
      "plagued",
      "region",
      "for",
      "centuries",
    ],
    id: "The Middle East is obviously an issue that has plagued the region for centuries.",
    author: "Barack Obama",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["yourself", "enjoy", "blessings", "god's", "borne", "out", "on"],
    words: [
      "just",
      "be",
      "yourself",
      "and",
      "enjoy",
      "the",
      "blessings",
      "that",
      "god's",
      "borne",
      "out",
      "on",
      "you",
    ],
    id: "Just be yourself, and enjoy the blessings that God's borne out on you.",
    author: "Delilah",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "prepared", "die", "there", "cause", "which", "kill"],
    words: [
      "i",
      "am",
      "prepared",
      "to",
      "die",
      "but",
      "there",
      "is",
      "no",
      "cause",
      "for",
      "which",
      "kill",
    ],
    id: "I am prepared to die, but there is no cause for which I am prepared to kill.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["dad", "kept", "out", "comes", "goes", "family", "forever"],
    words: [
      "dad",
      "kept",
      "us",
      "out",
      "of",
      "school",
      "but",
      "comes",
      "and",
      "goes",
      "family",
      "is",
      "forever",
    ],
    id: "Dad kept us out of school, but school comes and goes. Family is forever.",
    author: "Charlie Sheen",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "honest", "form", "filmmaking", "make", "film", "yourself"],
    words: [
      "the",
      "most",
      "honest",
      "form",
      "of",
      "filmmaking",
      "is",
      "to",
      "make",
      "a",
      "film",
      "for",
      "yourself",
    ],
    id: "The most honest form of filmmaking is to make a film for yourself.",
    author: "Peter Jackson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "function", "size", "your", "title", "richness", "contribution"],
    words: [
      "success",
      "is",
      "not",
      "a",
      "function",
      "of",
      "the",
      "size",
      "your",
      "title",
      "but",
      "richness",
      "contribution",
    ],
    id: "Success is not a function of the size of your title but the richness of your contribution.",
    author: "Robin S. Sharma",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["waste", "more", "time", "arguing", "man", "should", "one"],
    words: [
      "waste",
      "no",
      "more",
      "time",
      "arguing",
      "about",
      "what",
      "a",
      "good",
      "man",
      "should",
      "be",
      "one",
    ],
    id: "Waste no more time arguing about what a good man should be. Be one.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["none", "coward", "dares", "boast", "never", "known", "fear"],
    words: [
      "none",
      "but",
      "a",
      "coward",
      "dares",
      "to",
      "boast",
      "that",
      "he",
      "has",
      "never",
      "known",
      "fear",
    ],
    id: "None but a coward dares to boast that he has never known fear.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "sane", "satisfactory", "answer", "problem", "human", "existence"],
    words: [
      "love",
      "is",
      "the",
      "only",
      "sane",
      "and",
      "satisfactory",
      "answer",
      "to",
      "problem",
      "of",
      "human",
      "existence",
    ],
    id: "Love is the only sane and satisfactory answer to the problem of human existence.",
    author: "Erich Fromm",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "matter", "events", "depends", "upon", "tides", "mind"],
    words: [
      "happiness",
      "is",
      "not",
      "a",
      "matter",
      "of",
      "events",
      "it",
      "depends",
      "upon",
      "the",
      "tides",
      "mind",
    ],
    id: "Happiness is not a matter of events; it depends upon the tides of the mind.",
    author: "Alice Meynell",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "black", "experience", "always", "talking", "human", "condition"],
    words: [
      "i",
      "speak",
      "to",
      "the",
      "black",
      "experience",
      "but",
      "am",
      "always",
      "talking",
      "about",
      "human",
      "condition",
    ],
    id: "I speak to the black experience, but I am always talking about the human condition.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["foreign", "policy", "human", "relations", "only", "less", "each"],
    words: [
      "foreign",
      "policy",
      "is",
      "like",
      "human",
      "relations",
      "only",
      "people",
      "know",
      "less",
      "about",
      "each",
      "other",
    ],
    id: "Foreign policy is like human relations, only people know less about each other.",
    author: "Joe Biden",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["contented", "mind", "greatest", "blessing", "man", "enjoy", "world"],
    words: [
      "a",
      "contented",
      "mind",
      "is",
      "the",
      "greatest",
      "blessing",
      "man",
      "can",
      "enjoy",
      "in",
      "this",
      "world",
    ],
    id: "A contented mind is the greatest blessing a man can enjoy in this world.",
    author: "Joseph Addison",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["life's", "most", "persistent", "urgent", "question", "doing", "others"],
    words: [
      "life's",
      "most",
      "persistent",
      "and",
      "urgent",
      "question",
      "is",
      "what",
      "are",
      "you",
      "doing",
      "for",
      "others",
    ],
    id: "Life's most persistent and urgent question is, 'What are you doing for others?'",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["whoever", "said", "can't", "happiness", "simply", "didn't", "shopping"],
    words: [
      "whoever",
      "said",
      "money",
      "can't",
      "buy",
      "happiness",
      "simply",
      "didn't",
      "know",
      "where",
      "to",
      "go",
      "shopping",
    ],
    id: "Whoever said money can't buy happiness simply didn't know where to go shopping.",
    author: "Gertrude Stein",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "interested", "preserving", "status", "quo", "i", "overthrow"],
    words: [
      "i'm",
      "not",
      "interested",
      "in",
      "preserving",
      "the",
      "status",
      "quo",
      "i",
      "want",
      "to",
      "overthrow",
      "it",
    ],
    id: "I'm not interested in preserving the status quo; I want to overthrow it.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["takes", "different", "value", "system", "wish", "change", "world"],
    words: [
      "it",
      "takes",
      "a",
      "different",
      "value",
      "system",
      "if",
      "you",
      "wish",
      "to",
      "change",
      "the",
      "world",
    ],
    id: "It takes a different value system if you wish to change the world.",
    author: "Jacque Fresco",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "sources", "moral", "values", "pursuit", "understanding", "happiness"],
    words: [
      "for",
      "me",
      "the",
      "only",
      "sources",
      "of",
      "moral",
      "values",
      "are",
      "pursuit",
      "understanding",
      "and",
      "happiness",
    ],
    id: "For me, the only sources of moral values are the pursuit of understanding and the pursuit of happiness.",
    author: "Matthew Stewart",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "extremely", "satisfied", "content", "happiness", "enough", "everything"],
    words: [
      "i",
      "am",
      "extremely",
      "satisfied",
      "and",
      "content",
      "happy",
      "think",
      "happiness",
      "is",
      "enough",
      "for",
      "everything",
    ],
    id: "I am extremely satisfied and content. I am happy and I think happiness is enough for everything.",
    author: "Waheeda Rehman",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["takes", "lot", "put", "together", "marriage", "family", "home"],
    words: [
      "it",
      "takes",
      "a",
      "lot",
      "of",
      "work",
      "to",
      "put",
      "together",
      "marriage",
      "family",
      "and",
      "home",
    ],
    id: "It takes a lot of work to put together a marriage, to put together a family and a home.",
    author: "Elizabeth Edwards",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "all", "empathy", "may", "enough", "courage", "display"],
    words: [
      "i",
      "think",
      "we",
      "all",
      "have",
      "empathy",
      "may",
      "not",
      "enough",
      "courage",
      "to",
      "display",
      "it",
    ],
    id: "I think we all have empathy. We may not have enough courage to display it.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "heart", "volcano", "how", "shall", "expect", "bloom"],
    words: [
      "if",
      "your",
      "heart",
      "is",
      "a",
      "volcano",
      "how",
      "shall",
      "you",
      "expect",
      "flowers",
      "to",
      "bloom",
    ],
    id: "If your heart is a volcano, how shall you expect flowers to bloom?",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "force", "capable", "transforming", "enemy", "into", "friend"],
    words: [
      "love",
      "is",
      "the",
      "only",
      "force",
      "capable",
      "of",
      "transforming",
      "an",
      "enemy",
      "into",
      "a",
      "friend",
    ],
    id: "Love is the only force capable of transforming an enemy into a friend.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["workers", "world", "unite", "nothing", "lose", "your", "chains"],
    words: [
      "workers",
      "of",
      "the",
      "world",
      "unite",
      "you",
      "have",
      "nothing",
      "to",
      "lose",
      "but",
      "your",
      "chains",
    ],
    id: "Workers of the world unite; you have nothing to lose but your chains.",
    author: "Karl Marx",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "something", "achieve", "or", "someplace", "get", "inhabit"],
    words: [
      "happiness",
      "is",
      "not",
      "something",
      "you",
      "achieve",
      "it's",
      "do",
      "or",
      "someplace",
      "get",
      "to",
      "inhabit",
    ],
    id: "Happiness is not something you achieve. It's not something you do or someplace you get to. Happiness is something you inhabit.",
    author: "Mark Manson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["misled", "by", "those", "claim", "god", "exist", "because"],
    words: [
      "don't",
      "be",
      "misled",
      "by",
      "those",
      "who",
      "claim",
      "god",
      "doesn't",
      "exist",
      "because",
      "he",
      "does",
    ],
    id: "Don't be misled by those who claim God doesn't exist, because He does.",
    author: "Billy Graham",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "less", "more", "when", "comes", "kissing", "movies"],
    words: [
      "i",
      "think",
      "less",
      "is",
      "more",
      "when",
      "it",
      "comes",
      "to",
      "kissing",
      "in",
      "the",
      "movies",
    ],
    id: "I think less is more when it comes to kissing in the movies.",
    author: "Julia Roberts",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "always", "put", "my", "family", "first", "way"],
    words: [
      "i've",
      "always",
      "put",
      "my",
      "family",
      "first",
      "and",
      "that's",
      "just",
      "the",
      "way",
      "it",
      "is",
    ],
    id: "I've always put my family first and that's just the way it is.",
    author: "Jamie Lee Curtis",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["hardest", "thing", "life", "which", "bridge", "cross", "burn"],
    words: [
      "the",
      "hardest",
      "thing",
      "in",
      "life",
      "to",
      "learn",
      "is",
      "which",
      "bridge",
      "cross",
      "and",
      "burn",
    ],
    id: "The hardest thing in life to learn is which bridge to cross and which to burn.",
    author: "David Russell",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "learned", "over", "years", "freedom", "side", "discipline"],
    words: [
      "i've",
      "learned",
      "over",
      "the",
      "years",
      "that",
      "freedom",
      "is",
      "just",
      "other",
      "side",
      "of",
      "discipline",
    ],
    id: "I've learned over the years that freedom is just the other side of discipline.",
    author: "Jake Gyllenhaal",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "matter", "intensity", "balance", "order", "rhythm", "harmony"],
    words: [
      "happiness",
      "is",
      "not",
      "a",
      "matter",
      "of",
      "intensity",
      "but",
      "balance",
      "order",
      "rhythm",
      "and",
      "harmony",
    ],
    id: "Happiness is not a matter of intensity but of balance, order, rhythm and harmony.",
    author: "Thomas Merton",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "chooses", "opinion", "wears", "whatever", "happens", "style"],
    words: [
      "he",
      "never",
      "chooses",
      "an",
      "opinion",
      "just",
      "wears",
      "whatever",
      "happens",
      "to",
      "be",
      "in",
      "style",
    ],
    id: "He never chooses an opinion; he just wears whatever happens to be in style.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "my",
      "favorite",
      "ability",
      "player",
      "combination",
      "aggressiveness",
      "patience",
    ],
    words: [
      "my",
      "favorite",
      "ability",
      "in",
      "a",
      "player",
      "is",
      "the",
      "combination",
      "of",
      "aggressiveness",
      "and",
      "patience",
    ],
    id: "My favorite ability in a player is the combination of aggressiveness and patience.",
    author: "Joey Votto",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "big", "on", "flak", "jackets", "tie-dyed", "shirts"],
    words: [
      "i'm",
      "not",
      "big",
      "on",
      "flak",
      "jackets",
      "and",
      "tie-dyed",
      "shirts",
      "you",
      "know",
      "that's",
      "me",
    ],
    id: "I'm not big on flak jackets and tie-dyed shirts. You know, that's not me.",
    author: "Joe Biden",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["believe", "was", "age", "before", "days", "architects", "builders"],
    words: [
      "believe",
      "me",
      "that",
      "was",
      "a",
      "happy",
      "age",
      "before",
      "the",
      "days",
      "of",
      "architects",
      "builders",
    ],
    id: "Believe me, that was a happy age, before the days of architects, before the days of builders.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "almost", "certain", "more", "radiation", "better", "health"],
    words: [
      "i",
      "am",
      "now",
      "almost",
      "certain",
      "that",
      "we",
      "need",
      "more",
      "radiation",
      "for",
      "better",
      "health",
    ],
    id: "I am now almost certain that we need more radiation for better health.",
    author: "John Cameron",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["health", "thing", "makes", "feel", "best", "time", "year"],
    words: [
      "health",
      "is",
      "the",
      "thing",
      "that",
      "makes",
      "you",
      "feel",
      "now",
      "best",
      "time",
      "of",
      "year",
    ],
    id: "Health is the thing that makes you feel that now is the best time of the year.",
    author: "Franklin Pierce Adams",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["heaven", "exists", "there's", "laughter", "would", "great", "thing"],
    words: [
      "if",
      "heaven",
      "exists",
      "to",
      "know",
      "that",
      "there's",
      "laughter",
      "would",
      "be",
      "a",
      "great",
      "thing",
    ],
    id: "If Heaven exists, to know that there's laughter, that would be a great thing.",
    author: "Robin Williams",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "lots", "clowns", "our", "family", "-", "fun"],
    words: [
      "there",
      "are",
      "a",
      "lots",
      "of",
      "clowns",
      "in",
      "our",
      "family",
      "-",
      "house",
      "is",
      "fun",
    ],
    id: "There are a lots of clowns in our family - our house is a fun house.",
    author: "Sophie Ellis-Bextor",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["end", "up", "freedom", "when", "vote", "struggle", "continues"],
    words: [
      "you",
      "don't",
      "just",
      "end",
      "up",
      "with",
      "freedom",
      "when",
      "have",
      "the",
      "vote",
      "struggle",
      "continues",
    ],
    id: "You don't just end up with freedom when you have the vote. The struggle continues.",
    author: "Jacob Zuma",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["joke", "our", "family", "cry", "reading", "phone", "book"],
    words: [
      "the",
      "joke",
      "in",
      "our",
      "family",
      "is",
      "that",
      "we",
      "can",
      "cry",
      "reading",
      "phone",
      "book",
    ],
    id: "The joke in our family is that we can cry reading the phone book.",
    author: "Ron Reagan",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["family", "everything", "health", "two", "most", "important", "things"],
    words: [
      "family",
      "is",
      "everything",
      "to",
      "me",
      "and",
      "health",
      "are",
      "the",
      "two",
      "most",
      "important",
      "things",
    ],
    id: "Family is everything to me. Family and health are the two most important things to me.",
    author: "Mehcad Brooks",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["older", "shouldn't", "health", "food", "all", "preservatives", "get"],
    words: [
      "older",
      "people",
      "shouldn't",
      "eat",
      "health",
      "food",
      "they",
      "need",
      "all",
      "the",
      "preservatives",
      "can",
      "get",
    ],
    id: "Older people shouldn't eat health food, they need all the preservatives they can get.",
    author: "Robert Orben",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["perfection", "immutable", "things", "imperfect", "change", "way", "perfect"],
    words: [
      "perfection",
      "is",
      "immutable",
      "but",
      "for",
      "things",
      "imperfect",
      "change",
      "the",
      "way",
      "to",
      "perfect",
      "them",
    ],
    id: "Perfection is immutable. But for things imperfect, change is the way to perfect them.",
    author: "Owen Feltham",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["quantity", "quality", "knowledge", "which", "determines", "mind's", "dignity"],
    words: [
      "it",
      "is",
      "not",
      "the",
      "quantity",
      "but",
      "quality",
      "of",
      "knowledge",
      "which",
      "determines",
      "mind's",
      "dignity",
    ],
    id: "It is not the quantity but the quality of knowledge which determines the mind's dignity.",
    author: "William Ellery Channing",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["protracted", "war", "fail", "endanger", "freedom", "democratic", "country"],
    words: [
      "no",
      "protracted",
      "war",
      "can",
      "fail",
      "to",
      "endanger",
      "the",
      "freedom",
      "of",
      "a",
      "democratic",
      "country",
    ],
    id: "No protracted war can fail to endanger the freedom of a democratic country.",
    author: "Alexis de Tocqueville",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["freedom", "right", "choose", "create", "oneself", "alternatives", "choice"],
    words: [
      "what",
      "is",
      "freedom",
      "the",
      "right",
      "to",
      "choose",
      "create",
      "for",
      "oneself",
      "alternatives",
      "of",
      "choice",
    ],
    id: "What is freedom? Freedom is the right to choose: the right to create for oneself the alternatives of choice.",
    author: "Archibald MacLeish",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["had", "gay", "burglars", "night", "broke", "rearranged", "furniture"],
    words: [
      "we",
      "had",
      "gay",
      "burglars",
      "the",
      "other",
      "night",
      "they",
      "broke",
      "in",
      "and",
      "rearranged",
      "furniture",
    ],
    id: "We had gay burglars the other night. They broke in and rearranged the furniture.",
    author: "Robin Williams",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "god", "second", "chance", "something", "take", "granted"],
    words: [
      "when",
      "god",
      "gives",
      "you",
      "a",
      "second",
      "chance",
      "it's",
      "not",
      "something",
      "take",
      "for",
      "granted",
    ],
    id: "When God gives you a second chance, it's not something you take for granted.",
    author: "Lane Kiffin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["leaveth", "nothing", "chance", "will", "few", "things", "ill"],
    words: [
      "he",
      "that",
      "leaveth",
      "nothing",
      "to",
      "chance",
      "will",
      "do",
      "few",
      "things",
      "ill",
      "but",
      "very",
    ],
    id: "He that leaveth nothing to chance will do few things ill, but he will do very few things.",
    author: "George Savile",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["fox", "many", "tricks", "hedgehog", "one", "best", "all"],
    words: [
      "the",
      "fox",
      "has",
      "many",
      "tricks",
      "hedgehog",
      "but",
      "one",
      "that",
      "is",
      "best",
      "of",
      "all",
    ],
    id: "The fox has many tricks. The hedgehog has but one. But that is the best of all.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["catholic", "only", "way", "being", "fully", "utterly", "christian"],
    words: [
      "to",
      "be",
      "catholic",
      "is",
      "the",
      "only",
      "way",
      "of",
      "being",
      "fully",
      "and",
      "utterly",
      "christian",
    ],
    id: "To be Catholic is the only way of being fully and utterly Christian.",
    author: "Pierre Teilhard de Chardin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["woman", "ray", "god", "earthly", "beloved", "creative", "created"],
    words: [
      "woman",
      "is",
      "a",
      "ray",
      "of",
      "god",
      "she",
      "not",
      "that",
      "earthly",
      "beloved",
      "creative",
      "created",
    ],
    id: "Woman is a ray of God. She is not that earthly beloved: she is creative, not created.",
    author: "Rumi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "thought", "sufficient", "measure", "civilization", "influence", "women"],
    words: [
      "i",
      "have",
      "thought",
      "a",
      "sufficient",
      "measure",
      "of",
      "civilization",
      "is",
      "the",
      "influence",
      "good",
      "women",
    ],
    id: "I have thought a sufficient measure of civilization is the influence of good women.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["liberty", "means", "higher", "political", "end", "itself", "highest"],
    words: [
      "liberty",
      "is",
      "not",
      "a",
      "means",
      "to",
      "higher",
      "political",
      "end",
      "it",
      "itself",
      "the",
      "highest",
    ],
    id: "Liberty is not a means to a higher political end. It is itself the highest political end.",
    author: "John Dalberg-Acton",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["right", "time", "any", "one", "still", "lucky", "as"],
    words: [
      "the",
      "right",
      "time",
      "is",
      "any",
      "that",
      "one",
      "still",
      "so",
      "lucky",
      "as",
      "to",
      "have",
    ],
    id: "The right time is any time that one is still so lucky as to have.",
    author: "Henry James",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["attempt", "change", "piece", "dream", "world", "into", "reality"],
    words: [
      "love",
      "is",
      "an",
      "attempt",
      "to",
      "change",
      "a",
      "piece",
      "of",
      "dream",
      "world",
      "into",
      "reality",
    ],
    id: "Love is an attempt to change a piece of a dream world into reality.",
    author: "Theodor Reik",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "body", "eighteen", "year", "old", "keep", "fridge"],
    words: [
      "i",
      "have",
      "the",
      "body",
      "of",
      "an",
      "eighteen",
      "year",
      "old",
      "keep",
      "it",
      "in",
      "fridge",
    ],
    id: "I have the body of an eighteen year old. I keep it in the fridge.",
    author: "Spike Milligan",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["history", "long", "entrust", "freedom", "weak", "or", "timid"],
    words: [
      "history",
      "does",
      "not",
      "long",
      "entrust",
      "the",
      "care",
      "of",
      "freedom",
      "to",
      "weak",
      "or",
      "timid",
    ],
    id: "History does not long entrust the care of freedom to the weak or the timid.",
    author: "Dwight D. Eisenhower",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["change", "season", "calls", "scent", "both", "energizing", "refreshing"],
    words: [
      "a",
      "change",
      "of",
      "season",
      "calls",
      "for",
      "scent",
      "that",
      "is",
      "both",
      "energizing",
      "and",
      "refreshing",
    ],
    id: "A change of season calls for a change of scent that is both energizing and refreshing.",
    author: "Hannah Bronfman",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["takes", "one", "person", "conviction", "start", "ripple", "change"],
    words: [
      "it",
      "takes",
      "but",
      "one",
      "person",
      "moment",
      "conviction",
      "to",
      "start",
      "a",
      "ripple",
      "of",
      "change",
    ],
    id: "It takes but one person, one moment, one conviction, to start a ripple of change.",
    author: "Donna Brazile",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "characteristics",
      "authentically",
      "empowered",
      "personality",
      "humbleness",
      "clarity",
      "forgiveness",
    ],
    words: [
      "the",
      "characteristics",
      "of",
      "an",
      "authentically",
      "empowered",
      "personality",
      "are",
      "humbleness",
      "clarity",
      "forgiveness",
      "and",
      "love",
    ],
    id: "The characteristics of an authentically empowered personality are humbleness, clarity, forgiveness and love.",
    author: "Gary Zukav",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "long", "there", "mathematical", "chance", "keep", "on"],
    words: [
      "as",
      "long",
      "there",
      "is",
      "a",
      "mathematical",
      "chance",
      "we",
      "have",
      "to",
      "keep",
      "on",
      "going",
    ],
    id: "As long as there is a mathematical chance, we have to keep on going.",
    author: "Ottmar Hitzfeld",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["teachers", "change", "lives", "right", "mix", "chalk", "challenges"],
    words: [
      "teachers",
      "can",
      "change",
      "lives",
      "with",
      "just",
      "the",
      "right",
      "mix",
      "of",
      "chalk",
      "and",
      "challenges",
    ],
    id: "Teachers can change lives with just the right mix of chalk and challenges.",
    author: "Joyce Meyer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "momentum", "huge", "sport", "especially", "series", "ashes"],
    words: [
      "i",
      "think",
      "momentum",
      "is",
      "huge",
      "in",
      "sport",
      "especially",
      "a",
      "series",
      "like",
      "the",
      "ashes",
    ],
    id: "I think momentum is huge in sport, especially in a series like the Ashes.",
    author: "Ben Stokes",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["women's", "health", "front", "center", "-", "often", "isn't"],
    words: [
      "women's",
      "health",
      "needs",
      "to",
      "be",
      "front",
      "and",
      "center",
      "-",
      "it",
      "often",
      "isn't",
      "but",
    ],
    id: "Women's health needs to be front and center - it often isn't, but it needs to be.",
    author: "Cynthia Nixon",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["musicians", "always", "gigging", "never", "chance", "stop", "minute"],
    words: [
      "musicians",
      "are",
      "always",
      "gigging",
      "and",
      "never",
      "have",
      "a",
      "chance",
      "to",
      "stop",
      "for",
      "minute",
    ],
    id: "Musicians are always gigging and never have a chance to stop for a minute.",
    author: "Keith Jarrett",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["masters", "unsaid", "words", "slaves", "those", "slip", "out"],
    words: [
      "we",
      "are",
      "masters",
      "of",
      "the",
      "unsaid",
      "words",
      "but",
      "slaves",
      "those",
      "let",
      "slip",
      "out",
    ],
    id: "We are masters of the unsaid words, but slaves of those we let slip out.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "self-defined", "choose", "always", "change", "your", "mind"],
    words: [
      "success",
      "is",
      "self-defined",
      "you",
      "can",
      "choose",
      "what",
      "think",
      "and",
      "always",
      "change",
      "your",
      "mind",
    ],
    id: "Success is self-defined. You can choose what you think success is, and you can always change your mind.",
    author: "Mark Manson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["modesty", "seldom", "resides", "breast", "enriched", "nobler", "virtues"],
    words: [
      "modesty",
      "seldom",
      "resides",
      "in",
      "a",
      "breast",
      "that",
      "is",
      "not",
      "enriched",
      "with",
      "nobler",
      "virtues",
    ],
    id: "Modesty seldom resides in a breast that is not enriched with nobler virtues.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["fortune", "could", "make", "fools", "wise", "made", "lucky"],
    words: [
      "fortune",
      "seeing",
      "that",
      "she",
      "could",
      "not",
      "make",
      "fools",
      "wise",
      "has",
      "made",
      "them",
      "lucky",
    ],
    id: "Fortune, seeing that she could not make fools wise, has made them lucky.",
    author: "Michel de Montaigne",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "greatest", "simplest", "tools", "more", "growing", "doing"],
    words: [
      "one",
      "of",
      "the",
      "greatest",
      "and",
      "simplest",
      "tools",
      "for",
      "learning",
      "more",
      "growing",
      "is",
      "doing",
    ],
    id: "One of the greatest and simplest tools for learning more and growing is doing more.",
    author: "Washington Irving",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "believe", "thing", "all", "way", "implicitly", "unquestionable"],
    words: [
      "when",
      "you",
      "believe",
      "in",
      "a",
      "thing",
      "it",
      "all",
      "the",
      "way",
      "implicitly",
      "and",
      "unquestionable",
    ],
    id: "When you believe in a thing, believe in it all the way, implicitly and unquestionable.",
    author: "Walt Disney",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "live", "joyful", "life", "lot", "laughter", "times"],
    words: [
      "i",
      "live",
      "a",
      "very",
      "joyful",
      "life",
      "with",
      "lot",
      "of",
      "laughter",
      "and",
      "good",
      "times",
    ],
    id: "I live a very joyful life, with a lot of laughter and good times.",
    author: "Kimberly Elise",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["sorrow", "alleviated", "by", "sleep", "bath", "glass", "wine"],
    words: [
      "sorrow",
      "can",
      "be",
      "alleviated",
      "by",
      "good",
      "sleep",
      "a",
      "bath",
      "and",
      "glass",
      "of",
      "wine",
    ],
    id: "Sorrow can be alleviated by good sleep, a bath and a glass of wine.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["photographed", "known", "then", "out", "there", "peddling", "movies"],
    words: [
      "you",
      "don't",
      "want",
      "to",
      "be",
      "photographed",
      "known",
      "then",
      "need",
      "out",
      "there",
      "peddling",
      "movies",
    ],
    id: "You don't want to be photographed? You don't want to be known? Then you don't need to be out there peddling movies.",
    author: "Cameron Diaz",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "met", "man", "as", "tall", "quick", "ever"],
    words: [
      "i",
      "have",
      "not",
      "met",
      "a",
      "man",
      "as",
      "tall",
      "me",
      "who",
      "is",
      "quick",
      "ever",
    ],
    id: "I have not met a man as tall as me who is as quick as me, ever.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "possess", "arms", "though", "hope", "never", "occasion"],
    words: [
      "one",
      "loves",
      "to",
      "possess",
      "arms",
      "though",
      "they",
      "hope",
      "never",
      "have",
      "occasion",
      "for",
      "them",
    ],
    id: "One loves to possess arms, though they hope never to have occasion for them.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["doing", "ought", "deserve", "praise", "because", "our", "duty"],
    words: [
      "in",
      "doing",
      "what",
      "we",
      "ought",
      "deserve",
      "no",
      "praise",
      "because",
      "it",
      "is",
      "our",
      "duty",
    ],
    id: "In doing what we ought we deserve no praise, because it is our duty.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "something", "all", "universe", "conspires", "helping", "achieve"],
    words: [
      "when",
      "you",
      "want",
      "something",
      "all",
      "the",
      "universe",
      "conspires",
      "in",
      "helping",
      "to",
      "achieve",
      "it",
    ],
    id: "When you want something, all the universe conspires in helping you to achieve it.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["consign", "hell", "or", "paradise", "always", "takes", "somewhere"],
    words: [
      "love",
      "can",
      "consign",
      "us",
      "to",
      "hell",
      "or",
      "paradise",
      "but",
      "it",
      "always",
      "takes",
      "somewhere",
    ],
    id: "Love can consign us to hell or to paradise, but it always takes us somewhere.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["hardest", "job", "kids", "face", "manners", "without", "any"],
    words: [
      "the",
      "hardest",
      "job",
      "kids",
      "face",
      "today",
      "is",
      "learning",
      "good",
      "manners",
      "without",
      "seeing",
      "any",
    ],
    id: "The hardest job kids face today is learning good manners without seeing any.",
    author: "Fred Astaire",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "goal", "make", "best", "devices", "world", "biggest"],
    words: [
      "our",
      "goal",
      "is",
      "to",
      "make",
      "the",
      "best",
      "devices",
      "in",
      "world",
      "not",
      "be",
      "biggest",
    ],
    id: "Our goal is to make the best devices in the world, not to be the biggest.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["thus", "supreme", "importance", "war", "attack", "enemy's", "strategy"],
    words: [
      "thus",
      "what",
      "is",
      "of",
      "supreme",
      "importance",
      "in",
      "war",
      "to",
      "attack",
      "the",
      "enemy's",
      "strategy",
    ],
    id: "Thus, what is of supreme importance in war is to attack the enemy's strategy.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["communication", "as", "stimulating", "black", "hard", "sleep", "after"],
    words: [
      "good",
      "communication",
      "is",
      "just",
      "as",
      "stimulating",
      "black",
      "coffee",
      "and",
      "hard",
      "to",
      "sleep",
      "after",
    ],
    id: "Good communication is just as stimulating as black coffee, and just as hard to sleep after.",
    author: "Anne Morrow Lindbergh",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["director", "creates", "environment", "which", "actor", "encouragement", "fly"],
    words: [
      "a",
      "good",
      "director",
      "creates",
      "an",
      "environment",
      "which",
      "gives",
      "the",
      "actor",
      "encouragement",
      "to",
      "fly",
    ],
    id: "A good director creates an environment, which gives the actor the encouragement to fly.",
    author: "Kevin Bacon",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["art", "response", "man's", "creative", "soul", "call", "real"],
    words: [
      "what",
      "is",
      "art",
      "it",
      "the",
      "response",
      "of",
      "man's",
      "creative",
      "soul",
      "to",
      "call",
      "real",
    ],
    id: "What is Art? It is the response of man's creative soul to the call of the Real.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "my", "children", "tell", "shouldn't", "can't", "why"],
    words: [
      "i",
      "love",
      "to",
      "dance",
      "but",
      "my",
      "children",
      "tell",
      "me",
      "shouldn't",
      "can't",
      "see",
      "why",
    ],
    id: "I love to dance, but my children tell me I shouldn't. Can't see why.",
    author: "Richard Branson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["fate", "nothing", "deeds", "committed", "prior", "state", "existence"],
    words: [
      "fate",
      "is",
      "nothing",
      "but",
      "the",
      "deeds",
      "committed",
      "in",
      "a",
      "prior",
      "state",
      "of",
      "existence",
    ],
    id: "Fate is nothing but the deeds committed in a prior state of existence.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["accept", "fact", "treat", "almost", "anybody", "as", "volunteer"],
    words: [
      "accept",
      "the",
      "fact",
      "that",
      "we",
      "have",
      "to",
      "treat",
      "almost",
      "anybody",
      "as",
      "a",
      "volunteer",
    ],
    id: "Accept the fact that we have to treat almost anybody as a volunteer.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "scarcest", "resource", "unless", "managed", "nothing", "else"],
    words: [
      "time",
      "is",
      "the",
      "scarcest",
      "resource",
      "and",
      "unless",
      "it",
      "managed",
      "nothing",
      "else",
      "can",
      "be",
    ],
    id: "Time is the scarcest resource and unless it is managed nothing else can be managed.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["win", "as", "were", "used", "lose", "enjoyed", "change"],
    words: [
      "win",
      "as",
      "if",
      "you",
      "were",
      "used",
      "to",
      "it",
      "lose",
      "enjoyed",
      "for",
      "a",
      "change",
    ],
    id: "Win as if you were used to it, lose as if you enjoyed it for a change.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["parents", "bequeath", "their", "children", "riches", "spirit", "reverence"],
    words: [
      "let",
      "parents",
      "bequeath",
      "to",
      "their",
      "children",
      "not",
      "riches",
      "but",
      "the",
      "spirit",
      "of",
      "reverence",
    ],
    id: "Let parents bequeath to their children not riches, but the spirit of reverence.",
    author: "Plato",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "year", "asked", "poster", "-", "birth", "control"],
    words: [
      "one",
      "year",
      "they",
      "asked",
      "me",
      "to",
      "be",
      "poster",
      "boy",
      "-",
      "for",
      "birth",
      "control",
    ],
    id: "One year they asked me to be poster boy - for birth control.",
    author: "Rodney Dangerfield",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "reason", "fabrics", "cost", "done", "quality", "last"],
    words: [
      "there's",
      "a",
      "reason",
      "good",
      "fabrics",
      "have",
      "cost",
      "they're",
      "done",
      "with",
      "quality",
      "to",
      "last",
    ],
    id: "There's a reason good fabrics have a cost. They're done with good quality to last.",
    author: "Zac Posen",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["true", "ghosts", "which", "everyone", "talks", "few", "seen"],
    words: [
      "true",
      "love",
      "is",
      "like",
      "ghosts",
      "which",
      "everyone",
      "talks",
      "about",
      "and",
      "few",
      "have",
      "seen",
    ],
    id: "True love is like ghosts, which everyone talks about and few have seen.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["confess", "our", "little", "faults", "persuade", "large", "ones"],
    words: [
      "we",
      "confess",
      "our",
      "little",
      "faults",
      "to",
      "persuade",
      "people",
      "that",
      "have",
      "no",
      "large",
      "ones",
    ],
    id: "We confess our little faults to persuade people that we have no large ones.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "afraid", "army", "lions", "led", "by", "sheep"],
    words: [
      "i",
      "am",
      "not",
      "afraid",
      "of",
      "an",
      "army",
      "lions",
      "led",
      "by",
      "a",
      "sheep",
      "lion",
    ],
    id: "I am not afraid of an army of lions led by a sheep; I am afraid of an army of sheep led by a lion.",
    author: "Alexander the Great",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["being", "at", "ease", "knowing", "crucial", "answers", "come"],
    words: [
      "being",
      "at",
      "ease",
      "with",
      "not",
      "knowing",
      "is",
      "crucial",
      "for",
      "answers",
      "to",
      "come",
      "you",
    ],
    id: "Being at ease with not knowing is crucial for answers to come to you.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["glow", "one", "warm", "thought", "worth", "more", "than"],
    words: [
      "the",
      "glow",
      "of",
      "one",
      "warm",
      "thought",
      "is",
      "to",
      "me",
      "worth",
      "more",
      "than",
      "money",
    ],
    id: "The glow of one warm thought is to me worth more than money.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["truth", "certainly", "branch", "morality", "important", "one", "society"],
    words: [
      "truth",
      "is",
      "certainly",
      "a",
      "branch",
      "of",
      "morality",
      "and",
      "very",
      "important",
      "one",
      "to",
      "society",
    ],
    id: "Truth is certainly a branch of morality and a very important one to society.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["seem", "glorious", "all", "show", "underneath", "everyone", "else"],
    words: [
      "people",
      "that",
      "seem",
      "so",
      "glorious",
      "are",
      "all",
      "show",
      "underneath",
      "they",
      "like",
      "everyone",
      "else",
    ],
    id: "People that seem so glorious are all show; underneath they are like everyone else.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["flies", "runs", "rejoices", "free", "nothing", "holds", "back"],
    words: [
      "he",
      "who",
      "loves",
      "flies",
      "runs",
      "and",
      "rejoices",
      "is",
      "free",
      "nothing",
      "holds",
      "him",
      "back",
    ],
    id: "He who loves, flies, runs, and rejoices; he is free and nothing holds him back.",
    author: "Henri Matisse",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["idea", "suggestive", "must", "come", "individual", "force", "revelation"],
    words: [
      "an",
      "idea",
      "to",
      "be",
      "suggestive",
      "must",
      "come",
      "the",
      "individual",
      "with",
      "force",
      "of",
      "revelation",
    ],
    id: "An idea, to be suggestive, must come to the individual with the force of revelation.",
    author: "William James",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["nature", "garrulous", "point", "confusion", "artist", "truly", "taciturn"],
    words: [
      "nature",
      "is",
      "garrulous",
      "to",
      "the",
      "point",
      "of",
      "confusion",
      "let",
      "artist",
      "be",
      "truly",
      "taciturn",
    ],
    id: "Nature is garrulous to the point of confusion, let the artist be truly taciturn.",
    author: "Paul Klee",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "general", "quick", "believe", "which", "wish", "true"],
    words: [
      "men",
      "in",
      "general",
      "are",
      "quick",
      "to",
      "believe",
      "that",
      "which",
      "they",
      "wish",
      "be",
      "true",
    ],
    id: "Men in general are quick to believe that which they wish to be true.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["maxim", "life", "get", "treated", "way", "teach", "treat"],
    words: [
      "maxim",
      "for",
      "life",
      "you",
      "get",
      "treated",
      "in",
      "the",
      "way",
      "teach",
      "people",
      "to",
      "treat",
    ],
    id: "Maxim for life: You get treated in life the way you teach people to treat you.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "ceremonies",
      "different",
      "country",
      "true",
      "politeness",
      "everywhere",
      "same",
    ],
    words: [
      "ceremonies",
      "are",
      "different",
      "in",
      "every",
      "country",
      "but",
      "true",
      "politeness",
      "is",
      "everywhere",
      "the",
      "same",
    ],
    id: "Ceremonies are different in every country, but true politeness is everywhere the same.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["opposition", "necessarily", "enmity", "merely", "misused", "made", "occasion"],
    words: [
      "opposition",
      "is",
      "not",
      "necessarily",
      "enmity",
      "it",
      "merely",
      "misused",
      "and",
      "made",
      "an",
      "occasion",
      "for",
    ],
    id: "Opposition is not necessarily enmity; it is merely misused and made an occasion for enmity.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "homophobic", "racist", "against", "any", "all", "i"],
    words: [
      "i'm",
      "not",
      "homophobic",
      "racist",
      "against",
      "any",
      "people",
      "all",
      "i",
      "have",
      "for",
      "is",
      "love",
    ],
    id: "I'm not homophobic, I'm not racist. I'm not against any people. All I have for people is love.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "little", "league", "wonderful", "keeps", "kids", "out"],
    words: [
      "i",
      "think",
      "little",
      "league",
      "is",
      "wonderful",
      "it",
      "keeps",
      "the",
      "kids",
      "out",
      "of",
      "house",
    ],
    id: "I think Little League is wonderful. It keeps the kids out of the house.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "important", "thing", "world", "baseball", "pretty", "too"],
    words: [
      "love",
      "is",
      "the",
      "most",
      "important",
      "thing",
      "in",
      "world",
      "but",
      "baseball",
      "pretty",
      "good",
      "too",
    ],
    id: "Love is the most important thing in the world, but baseball is pretty good, too.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["person", "firm", "purpose", "all", "men", "things", "servants"],
    words: [
      "to",
      "the",
      "person",
      "with",
      "a",
      "firm",
      "purpose",
      "all",
      "men",
      "and",
      "things",
      "are",
      "servants",
    ],
    id: "To the person with a firm purpose all men and things are servants.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "ideas", "fail", "word", "comes", "save", "situation"],
    words: [
      "for",
      "just",
      "when",
      "ideas",
      "fail",
      "a",
      "word",
      "comes",
      "in",
      "to",
      "save",
      "the",
      "situation",
    ],
    id: "For just when ideas fail, a word comes in to save the situation.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["meanest", "all", "materials", "manhood", "only", "rightly", "disposed"],
    words: [
      "in",
      "the",
      "meanest",
      "are",
      "all",
      "materials",
      "of",
      "manhood",
      "only",
      "they",
      "not",
      "rightly",
      "disposed",
    ],
    id: "In the meanest are all the materials of manhood, only they are not rightly disposed.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["drown", "by", "falling", "into", "river", "staying", "submerged"],
    words: [
      "you",
      "drown",
      "not",
      "by",
      "falling",
      "into",
      "a",
      "river",
      "but",
      "staying",
      "submerged",
      "in",
      "it",
    ],
    id: "You drown not by falling into a river, but by staying submerged in it.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "science",
      "increasingly",
      "answering",
      "questions",
      "used",
      "province",
      "religion",
    ],
    words: [
      "science",
      "is",
      "increasingly",
      "answering",
      "questions",
      "that",
      "used",
      "to",
      "be",
      "the",
      "province",
      "of",
      "religion",
    ],
    id: "Science is increasingly answering questions that used to be the province of religion.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["government", "strongest", "which", "man", "feels", "himself", "part"],
    words: [
      "that",
      "government",
      "is",
      "the",
      "strongest",
      "of",
      "which",
      "every",
      "man",
      "feels",
      "himself",
      "a",
      "part",
    ],
    id: "That government is the strongest of which every man feels himself a part.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["press", "free", "man", "able", "read", "all", "safe"],
    words: [
      "where",
      "the",
      "press",
      "is",
      "free",
      "and",
      "every",
      "man",
      "able",
      "to",
      "read",
      "all",
      "safe",
    ],
    id: "Where the press is free and every man able to read, all is safe.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["luxuries", "life", "i", "will", "willingly", "without", "necessities"],
    words: [
      "give",
      "me",
      "the",
      "luxuries",
      "of",
      "life",
      "and",
      "i",
      "will",
      "willingly",
      "do",
      "without",
      "necessities",
    ],
    id: "Give me the luxuries of life and I will willingly do without the necessities.",
    author: "Frank Lloyd Wright",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "lose", "opportunity", "anything", "beauty", "god's", "handwriting"],
    words: [
      "never",
      "lose",
      "an",
      "opportunity",
      "of",
      "seeing",
      "anything",
      "beautiful",
      "for",
      "beauty",
      "is",
      "god's",
      "handwriting",
    ],
    id: "Never lose an opportunity of seeing anything beautiful, for beauty is God's handwriting.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["business", "involving", "fun", "exercise", "your", "creative", "instincts"],
    words: [
      "a",
      "business",
      "has",
      "to",
      "be",
      "involving",
      "it",
      "fun",
      "and",
      "exercise",
      "your",
      "creative",
      "instincts",
    ],
    id: "A business has to be involving, it has to be fun, and it has to exercise your creative instincts.",
    author: "Richard Branson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["sometimes", "life", "hits", "head", "brick", "lose", "faith"],
    words: [
      "sometimes",
      "life",
      "hits",
      "you",
      "in",
      "the",
      "head",
      "with",
      "a",
      "brick",
      "don't",
      "lose",
      "faith",
    ],
    id: "Sometimes life hits you in the head with a brick. Don't lose faith.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "mysticism",
      "mistake",
      "accidental",
      "individual",
      "symbol",
      "universal",
      "one",
    ],
    words: [
      "mysticism",
      "is",
      "the",
      "mistake",
      "of",
      "an",
      "accidental",
      "and",
      "individual",
      "symbol",
      "for",
      "universal",
      "one",
    ],
    id: "Mysticism is the mistake of an accidental and individual symbol for an universal one.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["able", "alone", "lose", "advantage", "solitude", "society", "thyself"],
    words: [
      "be",
      "able",
      "to",
      "alone",
      "lose",
      "not",
      "the",
      "advantage",
      "of",
      "solitude",
      "and",
      "society",
      "thyself",
    ],
    id: "Be able to be alone. Lose not the advantage of solitude, and the society of thyself.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["been", "married", "many", "times", "marks", "on", "face"],
    words: [
      "she's",
      "been",
      "married",
      "so",
      "many",
      "times",
      "she",
      "has",
      "rice",
      "marks",
      "on",
      "her",
      "face",
    ],
    id: "She's been married so many times she has rice marks on her face.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["absent", "ideal", "those", "present", "seem", "quite", "commonplace"],
    words: [
      "the",
      "people",
      "who",
      "are",
      "absent",
      "ideal",
      "those",
      "present",
      "seem",
      "to",
      "be",
      "quite",
      "commonplace",
    ],
    id: "The people who are absent are the ideal; those who are present seem to be quite commonplace.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["greatest", "obstacle", "discovery", "ignorance", "-", "illusion", "knowledge"],
    words: [
      "the",
      "greatest",
      "obstacle",
      "to",
      "discovery",
      "is",
      "not",
      "ignorance",
      "-",
      "it",
      "illusion",
      "of",
      "knowledge",
    ],
    id: "The greatest obstacle to discovery is not ignorance - it is the illusion of knowledge.",
    author: "Daniel J. Boorstin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["full", "heart", "there", "room", "everything", "empty", "nothing"],
    words: [
      "in",
      "a",
      "full",
      "heart",
      "there",
      "is",
      "room",
      "for",
      "everything",
      "and",
      "an",
      "empty",
      "nothing",
    ],
    id: "In a full heart there is room for everything, and in an empty heart there is room for nothing.",
    author: "Antonio Porchia",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["matters", "style", "swim", "current", "principle", "stand", "rock"],
    words: [
      "in",
      "matters",
      "of",
      "style",
      "swim",
      "with",
      "the",
      "current",
      "principle",
      "stand",
      "like",
      "a",
      "rock",
    ],
    id: "In matters of style, swim with the current; in matters of principle, stand like a rock.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "business", "life", "succeed", "continue", "fail", "spirits"],
    words: [
      "our",
      "business",
      "in",
      "life",
      "is",
      "not",
      "to",
      "succeed",
      "but",
      "continue",
      "fail",
      "good",
      "spirits",
    ],
    id: "Our business in life is not to succeed, but to continue to fail in good spirits.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "take", "my", "own", "time", "rush", "patience"],
    words: [
      "i",
      "like",
      "to",
      "take",
      "my",
      "own",
      "time",
      "and",
      "don't",
      "rush",
      "do",
      "have",
      "patience",
    ],
    id: "I like to take my own time, and I don't like to rush. I do take time, and I have patience.",
    author: "Nargis Fakhri",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "honors", "my", "style", "under", "destroy", "teacher"],
    words: [
      "he",
      "most",
      "honors",
      "my",
      "style",
      "who",
      "learns",
      "under",
      "it",
      "to",
      "destroy",
      "the",
      "teacher",
    ],
    id: "He most honors my style who learns under it to destroy the teacher.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "went", "general", "store", "wouldn't", "anything", "specifically"],
    words: [
      "i",
      "went",
      "to",
      "a",
      "general",
      "store",
      "they",
      "wouldn't",
      "let",
      "me",
      "buy",
      "anything",
      "specifically",
    ],
    id: "I went to a general store. They wouldn't let me buy anything specifically.",
    author: "Steven Wright",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["poet", "before", "anything", "else", "person", "passionately", "language"],
    words: [
      "a",
      "poet",
      "is",
      "before",
      "anything",
      "else",
      "person",
      "who",
      "passionately",
      "in",
      "love",
      "with",
      "language",
    ],
    id: "A poet is, before anything else, a person who is passionately in love with language.",
    author: "W. H. Auden",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "things", "i've", "never", "been", "accused", "caring"],
    words: [
      "one",
      "of",
      "the",
      "things",
      "i've",
      "never",
      "been",
      "accused",
      "is",
      "not",
      "caring",
      "about",
      "people",
    ],
    id: "One of the things I've never been accused of is not caring about people.",
    author: "Joe Biden",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["spirit", "country", "totally", "adverse", "large", "military", "force"],
    words: [
      "the",
      "spirit",
      "of",
      "this",
      "country",
      "is",
      "totally",
      "adverse",
      "to",
      "a",
      "large",
      "military",
      "force",
    ],
    id: "The spirit of this country is totally adverse to a large military force.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "existential", "map", "here", "written", "all", "over"],
    words: [
      "i",
      "have",
      "an",
      "existential",
      "map",
      "it",
      "has",
      "you",
      "are",
      "here",
      "written",
      "all",
      "over",
    ],
    id: "I have an existential map. It has 'You are here' written all over it.",
    author: "Steven Wright",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "leaf", "less", "than", "journey-work", "stars"],
    words: [
      "i",
      "believe",
      "a",
      "leaf",
      "of",
      "grass",
      "is",
      "no",
      "less",
      "than",
      "the",
      "journey-work",
      "stars",
    ],
    id: "I believe a leaf of grass is no less than the journey-work of the stars.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["believe", "rheumatism", "true", "until", "after", "first", "attack"],
    words: [
      "we",
      "don't",
      "believe",
      "in",
      "rheumatism",
      "and",
      "true",
      "love",
      "until",
      "after",
      "the",
      "first",
      "attack",
    ],
    id: "We don't believe in rheumatism and true love until after the first attack.",
    author: "Marie von Ebner-Eschenbach",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "life", "monday", "dip", "glitter", "sparkle", "all"],
    words: [
      "when",
      "life",
      "gives",
      "you",
      "monday",
      "dip",
      "it",
      "in",
      "glitter",
      "and",
      "sparkle",
      "all",
      "day",
    ],
    id: "When life gives you Monday, dip it in glitter and sparkle all day.",
    author: "Ella Woodward",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "enjoy", "how", "cook", "because", "eating", "fun"],
    words: [
      "i",
      "enjoy",
      "learning",
      "how",
      "to",
      "cook",
      "because",
      "like",
      "eat",
      "eating",
      "is",
      "good",
      "fun",
    ],
    id: "I enjoy learning how to cook because I like to eat. Eating is good. Eating is fun.",
    author: "Dale Earnhardt Jr.",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["little", "dangerous", "thing", "lot", "ignorance", "as", "bad"],
    words: [
      "a",
      "little",
      "learning",
      "is",
      "dangerous",
      "thing",
      "but",
      "lot",
      "of",
      "ignorance",
      "just",
      "as",
      "bad",
    ],
    id: "A little learning is a dangerous thing, but a lot of ignorance is just as bad.",
    author: "Bob Edwards",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "power", "away", "weekend", "keeping", "myself", "company"],
    words: [
      "i'm",
      "learning",
      "the",
      "power",
      "of",
      "going",
      "away",
      "for",
      "weekend",
      "and",
      "keeping",
      "myself",
      "company",
    ],
    id: "I'm learning the power of going away for the weekend and keeping myself company.",
    author: "Zoe Saldana",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "thirst", "knowledge", "sure", "drown", "all", "information"],
    words: [
      "in",
      "your",
      "thirst",
      "for",
      "knowledge",
      "be",
      "sure",
      "not",
      "to",
      "drown",
      "all",
      "the",
      "information",
    ],
    id: "In your thirst for knowledge, be sure not to drown in all the information.",
    author: "Anthony J. D'Angelo",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "challenge", "skills", "because", "didn't", "much", "at"],
    words: [
      "i",
      "like",
      "a",
      "challenge",
      "learning",
      "new",
      "skills",
      "because",
      "didn't",
      "learn",
      "much",
      "at",
      "school",
    ],
    id: "I like a challenge. I like learning new skills because I didn't learn much at school.",
    author: "Bernard Sumner",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["live", "given", "great", "humbling", "educational", "process", "life"],
    words: [
      "learning",
      "to",
      "live",
      "with",
      "the",
      "given",
      "is",
      "great",
      "humbling",
      "educational",
      "process",
      "of",
      "life",
    ],
    id: "Learning to live with the given is the great humbling educational process of life.",
    author: "David Milch",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "complacent",
      "satisfaction",
      "present",
      "knowledge",
      "chief",
      "bar",
      "pursuit",
    ],
    words: [
      "a",
      "complacent",
      "satisfaction",
      "with",
      "present",
      "knowledge",
      "is",
      "the",
      "chief",
      "bar",
      "to",
      "pursuit",
      "of",
    ],
    id: "A complacent satisfaction with present knowledge is the chief bar to the pursuit of knowledge.",
    author: "B. H. Liddell Hart",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "will", "always", "large", "extent", "ourselves", "make"],
    words: [
      "life",
      "will",
      "always",
      "be",
      "to",
      "a",
      "large",
      "extent",
      "what",
      "we",
      "ourselves",
      "make",
      "it",
    ],
    id: "Life will always be to a large extent what we ourselves make it.",
    author: "Samuel Smiles",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "studied", "many", "philosophers", "wisdom", "infinitely", "superior"],
    words: [
      "i",
      "have",
      "studied",
      "many",
      "philosophers",
      "and",
      "cats",
      "the",
      "wisdom",
      "of",
      "is",
      "infinitely",
      "superior",
    ],
    id: "I have studied many philosophers and many cats. The wisdom of cats is infinitely superior.",
    author: "Hippolyte Taine",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["major", "advantage", "age", "accept", "without", "passing", "judgment"],
    words: [
      "a",
      "major",
      "advantage",
      "of",
      "age",
      "is",
      "learning",
      "to",
      "accept",
      "people",
      "without",
      "passing",
      "judgment",
    ],
    id: "A major advantage of age is learning to accept people without passing judgment.",
    author: "Liz Carpenter",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["healthful", "hunger", "great", "idea", "beauty", "blessedness", "life"],
    words: [
      "a",
      "healthful",
      "hunger",
      "for",
      "great",
      "idea",
      "is",
      "the",
      "beauty",
      "and",
      "blessedness",
      "of",
      "life",
    ],
    id: "A healthful hunger for a great idea is the beauty and blessedness of life.",
    author: "Jean Ingelow",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "constructed", "event", "cannot", "will", "match", "expectation"],
    words: [
      "life",
      "is",
      "so",
      "constructed",
      "that",
      "the",
      "event",
      "does",
      "not",
      "cannot",
      "will",
      "match",
      "expectation",
    ],
    id: "Life is so constructed, that the event does not, cannot, will not, match the expectation.",
    author: "Charlotte Bronte",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["would", "knowledge", "must", "get", "whole", "world", "questions"],
    words: [
      "if",
      "we",
      "would",
      "have",
      "new",
      "knowledge",
      "must",
      "get",
      "a",
      "whole",
      "world",
      "of",
      "questions",
    ],
    id: "If we would have new knowledge, we must get a whole world of new questions.",
    author: "Susanne Langer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "partly", "make", "made", "by", "friends", "choose"],
    words: [
      "life",
      "is",
      "partly",
      "what",
      "we",
      "make",
      "it",
      "and",
      "made",
      "by",
      "the",
      "friends",
      "choose",
    ],
    id: "Life is partly what we make it, and partly what it is made by the friends we choose.",
    author: "Tennessee Williams",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "hard", "preserve", "order", "follow", "your", "dreams"],
    words: [
      "you",
      "have",
      "to",
      "patience",
      "work",
      "hard",
      "and",
      "preserve",
      "in",
      "order",
      "follow",
      "your",
      "dreams",
    ],
    id: "You have to have patience, work hard and preserve in order to follow your dreams.",
    author: "Whitney Port",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["consists", "two", "solitudes", "protect", "touch", "greet", "each"],
    words: [
      "love",
      "consists",
      "in",
      "this",
      "that",
      "two",
      "solitudes",
      "protect",
      "and",
      "touch",
      "greet",
      "each",
      "other",
    ],
    id: "Love consists in this, that two solitudes protect and touch and greet each other.",
    author: "Rainer Maria Rilke",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["hourglass", "heart", "filling", "up", "as", "brain", "empties"],
    words: [
      "love",
      "is",
      "like",
      "an",
      "hourglass",
      "with",
      "the",
      "heart",
      "filling",
      "up",
      "as",
      "brain",
      "empties",
    ],
    id: "Love is like an hourglass, with the heart filling up as the brain empties.",
    author: "Jules Renard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "thankful", "all", "received", "from", "my", "fans"],
    words: [
      "i",
      "am",
      "thankful",
      "to",
      "all",
      "the",
      "love",
      "that",
      "have",
      "received",
      "from",
      "my",
      "fans",
    ],
    id: "I am thankful to all the love that I have received from my fans.",
    author: "Shaheer Sheikh",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["fate", "always", "seems", "too", "little", "or", "much"],
    words: [
      "the",
      "fate",
      "of",
      "love",
      "is",
      "that",
      "it",
      "always",
      "seems",
      "too",
      "little",
      "or",
      "much",
    ],
    id: "The fate of love is that it always seems too little or too much.",
    author: "Amelia Barr",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "lot", "patience", "movies", "aren't", "cleanly", "told"],
    words: [
      "i",
      "don't",
      "have",
      "a",
      "lot",
      "of",
      "patience",
      "for",
      "movies",
      "that",
      "aren't",
      "cleanly",
      "told",
    ],
    id: "I don't have a lot of patience for movies that aren't cleanly told.",
    author: "Jon Favreau",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["small", "part", "ignorance", "arrange", "classify", "name", "knowledge"],
    words: [
      "the",
      "small",
      "part",
      "of",
      "ignorance",
      "that",
      "we",
      "arrange",
      "and",
      "classify",
      "give",
      "name",
      "knowledge",
    ],
    id: "The small part of ignorance that we arrange and classify we give the name of knowledge.",
    author: "Ambrose Bierce",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["absence", "wind", "fire", "extinguishes", "small", "enkindles", "great"],
    words: [
      "absence",
      "is",
      "to",
      "love",
      "what",
      "wind",
      "fire",
      "it",
      "extinguishes",
      "the",
      "small",
      "enkindles",
      "great",
    ],
    id: "Absence is to love what wind is to fire; it extinguishes the small, it enkindles the great.",
    author: "Roger de Rabutin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["little", "more", "patience", "taped", "piece", "than", "live"],
    words: [
      "people",
      "have",
      "a",
      "little",
      "more",
      "patience",
      "for",
      "taped",
      "piece",
      "than",
      "they",
      "do",
      "live",
    ],
    id: "People have a little more patience for a taped piece than they do a live piece.",
    author: "Tim Robinson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["friendship", "at", "first", "sight", "said", "only", "truth"],
    words: [
      "friendship",
      "at",
      "first",
      "sight",
      "like",
      "love",
      "is",
      "said",
      "to",
      "be",
      "the",
      "only",
      "truth",
    ],
    id: "Friendship at first sight, like love at first sight, is said to be the only truth.",
    author: "Herman Melville",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "way", "keep", "friendship", "respect", "everybody", "different"],
    words: [
      "i",
      "think",
      "the",
      "way",
      "to",
      "keep",
      "a",
      "friendship",
      "is",
      "respect",
      "that",
      "everybody",
      "different",
    ],
    id: "I think the way to keep a friendship is to respect that everybody is different.",
    author: "Emma Bunton",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["doubt", "conventional", "wisdom", "unless", "verify", "reason", "experiment"],
    words: [
      "doubt",
      "the",
      "conventional",
      "wisdom",
      "unless",
      "you",
      "can",
      "verify",
      "it",
      "with",
      "reason",
      "and",
      "experiment",
    ],
    id: "Doubt the conventional wisdom unless you can verify it with reason and experiment.",
    author: "Steve Albini",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["really", "romantic", "put", "lot", "effort", "into", "friendship"],
    words: [
      "you",
      "can",
      "be",
      "really",
      "romantic",
      "and",
      "put",
      "a",
      "lot",
      "of",
      "effort",
      "into",
      "friendship",
    ],
    id: "You can be really romantic and put a lot of effort into friendship.",
    author: "Beanie Feldstein",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "place", "my", "soul", "corner", "character", "god"],
    words: [
      "there",
      "is",
      "no",
      "place",
      "in",
      "my",
      "soul",
      "corner",
      "of",
      "character",
      "where",
      "god",
      "not",
    ],
    id: "There is no place in my soul, no corner of my character, where God is not.",
    author: "Evelyn Underhill",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["design", "future", "effectively", "must", "first", "your", "past"],
    words: [
      "to",
      "design",
      "the",
      "future",
      "effectively",
      "you",
      "must",
      "first",
      "let",
      "go",
      "of",
      "your",
      "past",
    ],
    id: "To design the future effectively, you must first let go of your past.",
    author: "Charles J. Givens",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["genius", "unrefined", "resembles", "flash", "lightning", "wisdom", "sun"],
    words: [
      "genius",
      "unrefined",
      "resembles",
      "a",
      "flash",
      "of",
      "lightning",
      "but",
      "wisdom",
      "is",
      "like",
      "the",
      "sun",
    ],
    id: "Genius unrefined resembles a flash of lightning, but wisdom is like the sun.",
    author: "Franz Grillparzer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "empowered",
      "enriched",
      "workforce",
      "backbone",
      "company's",
      "success",
      "framework",
    ],
    words: [
      "an",
      "empowered",
      "and",
      "enriched",
      "workforce",
      "is",
      "the",
      "backbone",
      "of",
      "a",
      "company's",
      "success",
      "framework",
    ],
    id: "An empowered and enriched workforce is the backbone of a company's success framework.",
    author: "Ananya Birla",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["danger", "success", "makes", "forget", "world's", "dreadful", "injustice"],
    words: [
      "the",
      "danger",
      "of",
      "success",
      "is",
      "that",
      "it",
      "makes",
      "us",
      "forget",
      "world's",
      "dreadful",
      "injustice",
    ],
    id: "The danger of success is that it makes us forget the world's dreadful injustice.",
    author: "Jules Renard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["would", "enjoy", "future", "waste", "none", "his", "present"],
    words: [
      "let",
      "him",
      "who",
      "would",
      "enjoy",
      "a",
      "good",
      "future",
      "waste",
      "none",
      "of",
      "his",
      "present",
    ],
    id: "Let him who would enjoy a good future waste none of his present.",
    author: "Roger Babson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["times", "these", "helps", "recall", "there", "always", "been"],
    words: [
      "in",
      "times",
      "like",
      "these",
      "it",
      "helps",
      "to",
      "recall",
      "that",
      "there",
      "have",
      "always",
      "been",
    ],
    id: "In times like these, it helps to recall that there have always been times like these.",
    author: "Paul Harvey",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "100%", "camp", "says", "forgiveness", "mostly", "forgiver"],
    words: [
      "i",
      "am",
      "100%",
      "in",
      "the",
      "camp",
      "that",
      "says",
      "forgiveness",
      "is",
      "mostly",
      "about",
      "forgiver",
    ],
    id: "I am 100% in the camp that says forgiveness is mostly about the forgiver.",
    author: "Hillary Clinton",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "scarcity", "commodity", "can't", "create", "any", "more"],
    words: [
      "time",
      "is",
      "the",
      "scarcity",
      "and",
      "it's",
      "commodity",
      "we",
      "can't",
      "create",
      "any",
      "more",
      "of",
    ],
    id: "Time is the scarcity, and it's the commodity we can't create any more of.",
    author: "Jim Mitchell",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["spectacles", "experience", "through", "will", "clearly", "second", "time"],
    words: [
      "the",
      "spectacles",
      "of",
      "experience",
      "through",
      "them",
      "you",
      "will",
      "see",
      "clearly",
      "a",
      "second",
      "time",
    ],
    id: "The spectacles of experience; through them you will see clearly a second time.",
    author: "Henrik Ibsen",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "will", "ever", "understand", "freedom", "means", "enjoying"],
    words: [
      "i",
      "don't",
      "think",
      "will",
      "ever",
      "understand",
      "what",
      "freedom",
      "means",
      "but",
      "am",
      "enjoying",
      "learning",
    ],
    id: "I don't think I will ever understand what freedom means, but I am enjoying learning.",
    author: "Park Yeon-mi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["take", "time", "first", "aid", "cpr", "saves", "lives"],
    words: [
      "take",
      "some",
      "time",
      "to",
      "learn",
      "first",
      "aid",
      "and",
      "cpr",
      "it",
      "saves",
      "lives",
      "works",
    ],
    id: "Take some time to learn first aid and CPR. It saves lives, and it works.",
    author: "Bobby Sherman",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["true", "freedom", "until", "allow", "diversity", "opinion", "voices"],
    words: [
      "you",
      "don't",
      "have",
      "true",
      "freedom",
      "until",
      "allow",
      "a",
      "diversity",
      "of",
      "opinion",
      "and",
      "voices",
    ],
    id: "You don't have true freedom until you allow a diversity of opinion and a diversity of voices.",
    author: "Don Lemon",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["sees", "humanity", "more", "victorious", "struggle", "space", "time"],
    words: [
      "every",
      "day",
      "sees",
      "humanity",
      "more",
      "victorious",
      "in",
      "the",
      "struggle",
      "with",
      "space",
      "and",
      "time",
    ],
    id: "Every day sees humanity more victorious in the struggle with space and time.",
    author: "Guglielmo Marconi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "knowledge",
      "guarantee",
      "political",
      "behavior",
      "ignorance",
      "virtual",
      "bad",
    ],
    words: [
      "knowledge",
      "is",
      "not",
      "a",
      "guarantee",
      "of",
      "good",
      "political",
      "behavior",
      "but",
      "ignorance",
      "virtual",
      "bad",
    ],
    id: "Knowledge is not a guarantee of good political behavior, but ignorance is a virtual guarantee of bad behavior.",
    author: "Martha Nussbaum",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["mind", "maketh", "or", "ill", "wretch", "rich", "poor"],
    words: [
      "it",
      "is",
      "the",
      "mind",
      "that",
      "maketh",
      "good",
      "or",
      "ill",
      "wretch",
      "happy",
      "rich",
      "poor",
    ],
    id: "It is the mind that maketh good or ill, That maketh wretch or happy, rich or poor.",
    author: "Michel de Montaigne",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["anyone", "will", "basic", "knowledge", "create", "something", "substantial"],
    words: [
      "anyone",
      "if",
      "he",
      "has",
      "a",
      "will",
      "and",
      "basic",
      "knowledge",
      "can",
      "create",
      "something",
      "substantial",
    ],
    id: "Anyone, if he has a will and basic knowledge, can create something substantial.",
    author: "Ammy Virk",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["thing", "uk", "really", "make", "many", "great", "movies"],
    words: [
      "the",
      "thing",
      "about",
      "uk",
      "is",
      "we",
      "don't",
      "really",
      "make",
      "that",
      "many",
      "great",
      "movies",
    ],
    id: "The thing about the UK is we don't really make that many great movies.",
    author: "Jason Statham",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "making",
      "wrong",
      "decision",
      "understandable",
      "refusing",
      "search",
      "continually",
    ],
    words: [
      "making",
      "a",
      "wrong",
      "decision",
      "is",
      "understandable",
      "refusing",
      "to",
      "search",
      "continually",
      "for",
      "learning",
      "not",
    ],
    id: "Making a wrong decision is understandable. Refusing to search continually for learning is not.",
    author: "Phil Crosby",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["fine", "also", "win", "-", "only", "one", "career"],
    words: [
      "learning",
      "is",
      "fine",
      "but",
      "you",
      "also",
      "have",
      "to",
      "win",
      "-",
      "only",
      "one",
      "career",
    ],
    id: "Learning is fine, but you also have to win - you only have one career.",
    author: "Kylian Mbappe",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["mind", "ought", "sometimes", "diverted", "may", "return", "better"],
    words: [
      "the",
      "mind",
      "ought",
      "sometimes",
      "to",
      "be",
      "diverted",
      "that",
      "it",
      "may",
      "return",
      "better",
      "thinking",
    ],
    id: "The mind ought sometimes to be diverted that it may return to better thinking.",
    author: "Phaedrus",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "i", "wake", "up", "morning", "thank", "god"],
    words: [
      "when",
      "i",
      "wake",
      "up",
      "every",
      "morning",
      "thank",
      "god",
      "for",
      "the",
      "new",
      "day",
    ],
    id: "When I wake up every morning, I thank God for the new day.",
    author: "F. Sionil Jose",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "i", "required", "was", "friendship", "could", "admire"],
    words: [
      "all",
      "i",
      "required",
      "to",
      "be",
      "happy",
      "was",
      "friendship",
      "and",
      "people",
      "could",
      "admire",
    ],
    id: "All I required to be happy was friendship and people I could admire.",
    author: "Christian Dior",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["rides", "sea", "nile", "must", "sails", "woven", "patience"],
    words: [
      "he",
      "who",
      "rides",
      "the",
      "sea",
      "of",
      "nile",
      "must",
      "have",
      "sails",
      "woven",
      "patience",
    ],
    id: "He who rides the sea of the Nile must have sails woven of patience.",
    author: "William Golding",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "learned", "mistakes", "often", "as", "teacher", "success"],
    words: [
      "i've",
      "learned",
      "that",
      "mistakes",
      "can",
      "often",
      "be",
      "as",
      "good",
      "a",
      "teacher",
      "success",
    ],
    id: "I've learned that mistakes can often be as good a teacher as success.",
    author: "Jack Welch",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["deductive", "method", "mode", "using", "knowledge", "inductive", "acquiring"],
    words: [
      "the",
      "deductive",
      "method",
      "is",
      "mode",
      "of",
      "using",
      "knowledge",
      "and",
      "inductive",
      "acquiring",
      "it",
    ],
    id: "The deductive method is the mode of using knowledge, and the inductive method the mode of acquiring it.",
    author: "Henry Mayhew",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["waste", "time", "looking", "perfect", "lover", "instead", "creating"],
    words: [
      "we",
      "waste",
      "time",
      "looking",
      "for",
      "the",
      "perfect",
      "lover",
      "instead",
      "of",
      "creating",
      "love",
    ],
    id: "We waste time looking for the perfect lover, instead of creating the perfect love.",
    author: "Tom Robbins",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["friendship", "without", "self-interest", "one", "rare", "things", "life"],
    words: [
      "friendship",
      "without",
      "self-interest",
      "is",
      "one",
      "of",
      "the",
      "rare",
      "and",
      "beautiful",
      "things",
      "life",
    ],
    id: "Friendship without self-interest is one of the rare and beautiful things of life.",
    author: "James F. Byrnes",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "you've", "known", "long", "never", "lose", "friendship"],
    words: [
      "when",
      "you've",
      "known",
      "someone",
      "for",
      "so",
      "long",
      "you",
      "never",
      "lose",
      "that",
      "friendship",
    ],
    id: "When you've known someone for so long you never lose that friendship.",
    author: "Keren Woodward",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "often", "result", "taking", "misstep", "right", "direction"],
    words: [
      "success",
      "is",
      "often",
      "the",
      "result",
      "of",
      "taking",
      "a",
      "misstep",
      "in",
      "right",
      "direction",
    ],
    id: "Success is often the result of taking a misstep in the right direction.",
    author: "Al Bernstein",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["easier", "humanity", "as", "whole", "than", "one's", "neighbor"],
    words: [
      "it",
      "is",
      "easier",
      "to",
      "love",
      "humanity",
      "as",
      "a",
      "whole",
      "than",
      "one's",
      "neighbor",
    ],
    id: "It is easier to love humanity as a whole than to love one's neighbor.",
    author: "Eric Hoffer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["flying", "how", "throw", "yourself", "at", "ground", "miss"],
    words: [
      "flying",
      "is",
      "learning",
      "how",
      "to",
      "throw",
      "yourself",
      "at",
      "the",
      "ground",
      "and",
      "miss",
    ],
    id: "Flying is learning how to throw yourself at the ground and miss.",
    author: "Douglas Adams",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "friendship", "over", "how", "lost", "lot"],
    words: [
      "i",
      "believe",
      "in",
      "friendship",
      "over",
      "money",
      "and",
      "that's",
      "how",
      "lost",
      "a",
      "lot",
    ],
    id: "I believe in friendship over money and that's how I lost a lot.",
    author: "Jagapathi Babu",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["while", "state", "exists", "there", "freedom", "when", "will"],
    words: [
      "while",
      "the",
      "state",
      "exists",
      "there",
      "can",
      "be",
      "no",
      "freedom",
      "when",
      "is",
      "will",
    ],
    id: "While the State exists there can be no freedom; when there is freedom there will be no State.",
    author: "Vladimir Lenin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "nature", "human", "beings", "success", "hate", "successful"],
    words: [
      "it's",
      "our",
      "nature",
      "human",
      "beings",
      "like",
      "success",
      "but",
      "they",
      "hate",
      "successful",
      "people",
    ],
    id: "It's our nature: Human beings like success but they hate successful people.",
    author: "Carrot Top",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "never", "made", "promise", "was", "too", "true"],
    words: [
      "god",
      "never",
      "made",
      "a",
      "promise",
      "that",
      "was",
      "too",
      "good",
      "to",
      "be",
      "true",
    ],
    id: "God never made a promise that was too good to be true.",
    author: "Dwight L. Moody",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["desire", "knowledge", "thirst", "riches", "increases", "ever", "acquisition"],
    words: [
      "the",
      "desire",
      "of",
      "knowledge",
      "like",
      "thirst",
      "riches",
      "increases",
      "ever",
      "with",
      "acquisition",
      "it",
    ],
    id: "The desire of knowledge, like the thirst of riches, increases ever with the acquisition of it.",
    author: "Laurence Sterne",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["generation", "laughs", "at", "old", "fashions", "follows", "religiously"],
    words: [
      "every",
      "generation",
      "laughs",
      "at",
      "the",
      "old",
      "fashions",
      "but",
      "follows",
      "religiously",
      "new",
    ],
    id: "Every generation laughs at the old fashions, but follows religiously the new.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["once", "lot", "success", "become", "target", "many", "ways"],
    words: [
      "once",
      "you",
      "have",
      "a",
      "lot",
      "of",
      "success",
      "become",
      "target",
      "in",
      "many",
      "ways",
    ],
    id: "Once you have a lot of success, you become a target in many ways.",
    author: "Jennifer Lopez",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["excuse", "worse", "more", "terrible", "than", "lie", "guarded"],
    words: [
      "an",
      "excuse",
      "is",
      "worse",
      "and",
      "more",
      "terrible",
      "than",
      "a",
      "lie",
      "for",
      "guarded",
    ],
    id: "An excuse is worse and more terrible than a lie, for an excuse is a lie guarded.",
    author: "Pope John Paul II",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "ever", "teaches", "teach", "or", "governs", "govern"],
    words: [
      "no",
      "one",
      "ever",
      "teaches",
      "well",
      "who",
      "wants",
      "to",
      "teach",
      "or",
      "governs",
      "govern",
    ],
    id: "No one ever teaches well who wants to teach, or governs well who wants to govern.",
    author: "Plato",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["stupidity", "also", "gift", "god", "one", "mustn't", "misuse"],
    words: [
      "stupidity",
      "is",
      "also",
      "a",
      "gift",
      "of",
      "god",
      "but",
      "one",
      "mustn't",
      "misuse",
      "it",
    ],
    id: "Stupidity is also a gift of God, but one mustn't misuse it.",
    author: "Pope John Paul II",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["freedom", "absolutely", "necessary", "progress", "science", "liberal", "arts"],
    words: [
      "freedom",
      "is",
      "absolutely",
      "necessary",
      "for",
      "the",
      "progress",
      "in",
      "science",
      "and",
      "liberal",
      "arts",
    ],
    id: "Freedom is absolutely necessary for the progress in science and the liberal arts.",
    author: "Baruch Spinoza",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "freedom",
      "expression",
      "matrix",
      "indispensable",
      "condition",
      "nearly",
      "form",
    ],
    words: [
      "freedom",
      "of",
      "expression",
      "is",
      "the",
      "matrix",
      "indispensable",
      "condition",
      "nearly",
      "every",
      "other",
      "form",
    ],
    id: "Freedom of expression is the matrix, the indispensable condition, of nearly every other form of freedom.",
    author: "Benjamin N. Cardozo",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["put", "priority", "on", "environment", "health", "safety", "protections"],
    words: [
      "we",
      "need",
      "to",
      "put",
      "a",
      "priority",
      "on",
      "environment",
      "health",
      "and",
      "safety",
      "protections",
    ],
    id: "We need to put a priority on environment, health, and safety protections.",
    author: "Katie McGinty",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "got", "his", "favorite", "bone", "my", "arm"],
    words: ["what", "a", "dog", "i", "got", "his", "favorite", "bone", "is", "in", "my", "arm"],
    id: "What a dog I got, his favorite bone is in my arm.",
    author: "Rodney Dangerfield",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["once", "enjoy", "taste", "freedom", "there", "turning", "back"],
    words: [
      "once",
      "people",
      "enjoy",
      "the",
      "taste",
      "of",
      "freedom",
      "there",
      "is",
      "no",
      "turning",
      "back",
    ],
    id: "Once people enjoy the taste of freedom, there is no turning back.",
    author: "Neil Bush",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["road", "freedom", "always", "been", "stalked", "by", "death"],
    words: [
      "we",
      "know",
      "the",
      "road",
      "to",
      "freedom",
      "has",
      "always",
      "been",
      "stalked",
      "by",
      "death",
    ],
    id: "We know the road to freedom has always been stalked by death.",
    author: "Angela Davis",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["second", "amendment", "says", "right", "bear", "arms", "artillery"],
    words: [
      "the",
      "second",
      "amendment",
      "says",
      "we",
      "have",
      "right",
      "to",
      "bear",
      "arms",
      "not",
      "artillery",
    ],
    id: "The Second Amendment says we have the right to bear arms, not to bear artillery.",
    author: "Robin Williams",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["christian", "half", "brain", "would", "support", "religious", "freedom"],
    words: [
      "no",
      "christian",
      "with",
      "half",
      "a",
      "brain",
      "would",
      "say",
      "we",
      "support",
      "religious",
      "freedom",
    ],
    id: "No Christian with half a brain would say 'we support religious freedom.'",
    author: "John MacArthur",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["capitalism", "offers", "freedom", "far", "from", "giving", "enslaves"],
    words: [
      "capitalism",
      "offers",
      "you",
      "freedom",
      "but",
      "far",
      "from",
      "giving",
      "people",
      "it",
      "enslaves",
      "them",
    ],
    id: "Capitalism offers you freedom, but far from giving people freedom, it enslaves them.",
    author: "Ian Mckellen",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["beyond", "doubt", "all", "our", "knowledge", "begins", "experience"],
    words: [
      "it",
      "is",
      "beyond",
      "a",
      "doubt",
      "that",
      "all",
      "our",
      "knowledge",
      "begins",
      "with",
      "experience",
    ],
    id: "It is beyond a doubt that all our knowledge begins with experience.",
    author: "Immanuel Kant",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "preaching",
      "expression",
      "moral",
      "sentiment",
      "application",
      "duties",
      "life",
    ],
    words: [
      "preaching",
      "is",
      "the",
      "expression",
      "of",
      "moral",
      "sentiment",
      "in",
      "application",
      "to",
      "duties",
      "life",
    ],
    id: "Preaching is the expression of the moral sentiment in application to the duties of life.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["single", "one", "deserves", "access", "quality", "affordable", "health"],
    words: [
      "every",
      "single",
      "one",
      "of",
      "us",
      "deserves",
      "access",
      "to",
      "quality",
      "affordable",
      "health",
      "care",
    ],
    id: "Every single one of us deserves access to quality, affordable health care.",
    author: "Sara Gideon",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["better", "lose", "health", "spendthrift", "than", "waste", "miser"],
    words: [
      "it",
      "is",
      "better",
      "to",
      "lose",
      "health",
      "like",
      "a",
      "spendthrift",
      "than",
      "waste",
      "miser",
    ],
    id: "It is better to lose health like a spendthrift than to waste it like a miser.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["accidents", "change", "-", "impossible", "accidental", "reveals", "man"],
    words: [
      "accidents",
      "try",
      "to",
      "change",
      "them",
      "-",
      "it's",
      "impossible",
      "the",
      "accidental",
      "reveals",
      "man",
    ],
    id: "Accidents, try to change them - it's impossible. The accidental reveals man.",
    author: "J. B. Priestley",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["lot", "momentum", "comes", "from", "fact", "linux", "free"],
    words: [
      "a",
      "lot",
      "of",
      "that",
      "momentum",
      "comes",
      "from",
      "the",
      "fact",
      "linux",
      "is",
      "free",
    ],
    id: "A lot of that momentum comes from the fact that Linux is free.",
    author: "Nat Friedman",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "people's",
      "personalities",
      "buildings",
      "various",
      "facades",
      "pleasant",
      "view",
    ],
    words: [
      "people's",
      "personalities",
      "like",
      "buildings",
      "have",
      "various",
      "facades",
      "some",
      "pleasant",
      "to",
      "view",
      "not",
    ],
    id: "People's personalities, like buildings, have various facades, some pleasant to view, some not.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["risk", "part", "god's", "game", "alike", "men", "nations"],
    words: [
      "risk",
      "is",
      "a",
      "part",
      "of",
      "god's",
      "game",
      "alike",
      "for",
      "men",
      "and",
      "nations",
    ],
    id: "Risk is a part of God's game, alike for men and nations.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "roots", "paris", "i", "will", "pull", "up"],
    words: ["my", "roots", "are", "in", "paris", "and", "i", "will", "not", "pull", "them", "up"],
    id: "My roots are in Paris, and I will not pull them up.",
    author: "Pierre Teilhard de Chardin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "went", "out", "on", "date", "simile", "metaphor"],
    words: [
      "i",
      "went",
      "out",
      "on",
      "a",
      "date",
      "with",
      "simile",
      "don't",
      "know",
      "what",
      "metaphor",
    ],
    id: "I went out on a date with Simile. I don't know what I metaphor.",
    author: "Tim Vine",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["art", "collaboration", "between", "god", "artist", "less", "better"],
    words: [
      "art",
      "is",
      "a",
      "collaboration",
      "between",
      "god",
      "and",
      "the",
      "artist",
      "less",
      "does",
      "better",
    ],
    id: "Art is a collaboration between God and the artist, and the less the artist does the better.",
    author: "Andre Gide",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["those", "hate", "win", "unless", "then", "destroy", "yourself"],
    words: [
      "those",
      "who",
      "hate",
      "you",
      "don't",
      "win",
      "unless",
      "them",
      "and",
      "then",
      "destroy",
      "yourself",
    ],
    id: "Those who hate you don't win unless you hate them, and then you destroy yourself.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "more", "moral", "than", "far", "immoral", "imagine"],
    words: [
      "men",
      "are",
      "more",
      "moral",
      "than",
      "they",
      "think",
      "and",
      "far",
      "immoral",
      "can",
      "imagine",
    ],
    id: "Men are more moral than they think and far more immoral than they can imagine.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["suspicion", "companion", "mean", "souls", "bane", "all", "society"],
    words: [
      "suspicion",
      "is",
      "the",
      "companion",
      "of",
      "mean",
      "souls",
      "and",
      "bane",
      "all",
      "good",
      "society",
    ],
    id: "Suspicion is the companion of mean souls, and the bane of all good society.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "enough", "govern", "any", "woman", "without", "consent"],
    words: [
      "no",
      "man",
      "is",
      "good",
      "enough",
      "to",
      "govern",
      "any",
      "woman",
      "without",
      "her",
      "consent",
    ],
    id: "No man is good enough to govern any woman without her consent.",
    author: "Susan B. Anthony",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["liking", "i", "nothing", "less", "than", "mysticism", "glory"],
    words: [
      "liking",
      "money",
      "like",
      "i",
      "it",
      "is",
      "nothing",
      "less",
      "than",
      "mysticism",
      "a",
      "glory",
    ],
    id: "Liking money like I like it, is nothing less than mysticism. Money is a glory.",
    author: "Salvador Dali",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["girls", "lunch", "unless", "birthday", "or", "special", "occasion"],
    words: [
      "we're",
      "not",
      "girls",
      "who",
      "lunch",
      "unless",
      "it's",
      "a",
      "birthday",
      "or",
      "special",
      "occasion",
    ],
    id: "We're not girls who lunch unless it's a birthday or special occasion.",
    author: "Kathy Hilton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "trying", "get", "pregnant", "i'm", "sure", "childbirth"],
    words: [
      "i",
      "like",
      "trying",
      "to",
      "get",
      "pregnant",
      "i'm",
      "not",
      "so",
      "sure",
      "about",
      "childbirth",
    ],
    id: "I like trying to get pregnant. I'm not so sure about childbirth.",
    author: "George Eliot",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["trust", "your", "instinct", "end", "though", "render", "reason"],
    words: [
      "trust",
      "your",
      "instinct",
      "to",
      "the",
      "end",
      "though",
      "you",
      "can",
      "render",
      "no",
      "reason",
    ],
    id: "Trust your instinct to the end, though you can render no reason.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["ultimately", "when", "deal", "whole", "country", "priorities", "change"],
    words: [
      "ultimately",
      "when",
      "you",
      "have",
      "to",
      "deal",
      "with",
      "a",
      "whole",
      "country",
      "priorities",
      "change",
    ],
    id: "Ultimately, when you have to deal with a whole country, priorities change.",
    author: "Toby Regbo",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["movie", "makes", "audience", "feel", "they've", "journeyed", "characters"],
    words: [
      "a",
      "good",
      "movie",
      "makes",
      "the",
      "audience",
      "feel",
      "like",
      "they've",
      "journeyed",
      "with",
      "characters",
    ],
    id: "A good movie makes the audience feel like they've journeyed with the characters.",
    author: "Rich Moore",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["value", "dollar", "social", "as", "created", "by", "society"],
    words: [
      "the",
      "value",
      "of",
      "a",
      "dollar",
      "is",
      "social",
      "as",
      "it",
      "created",
      "by",
      "society",
    ],
    id: "The value of a dollar is social, as it is created by society.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "way", "take", "sorrow", "out", "death", "life"],
    words: [
      "the",
      "only",
      "way",
      "to",
      "take",
      "sorrow",
      "out",
      "of",
      "death",
      "is",
      "love",
      "life",
    ],
    id: "The only way to take sorrow out of death is to take love out of life.",
    author: "Russell M. Nelson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "neutral", "change", "things", "courage", "initiative", "leaders"],
    words: [
      "time",
      "is",
      "neutral",
      "and",
      "does",
      "not",
      "change",
      "things",
      "with",
      "courage",
      "initiative",
      "leaders",
    ],
    id: "Time is neutral and does not change things. With courage and initiative, leaders change things.",
    author: "Jesse Jackson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "ability", "or", "patience", "teach", "badminton", "others"],
    words: [
      "i",
      "don't",
      "think",
      "have",
      "the",
      "ability",
      "or",
      "patience",
      "to",
      "teach",
      "badminton",
      "others",
    ],
    id: "I don't think I have the ability or patience to teach badminton to others.",
    author: "Saina Nehwal",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "change", "keep", "grinding", "all", "i", "win"],
    words: [
      "i'm",
      "not",
      "going",
      "to",
      "change",
      "keep",
      "grinding",
      "do",
      "all",
      "i",
      "can",
      "win",
    ],
    id: "I'm not going to change; I'm going to keep grinding. I'm going to do all I can to win.",
    author: "Ezekiel Elliott",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["walk", "by", "following", "rules", "doing", "falling", "over"],
    words: [
      "you",
      "don't",
      "learn",
      "to",
      "walk",
      "by",
      "following",
      "rules",
      "doing",
      "and",
      "falling",
      "over",
    ],
    id: "You don't learn to walk by following rules. You learn by doing, and by falling over.",
    author: "Richard Branson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["wasn't", "pick-pockets", "i'd", "sex", "life", "at", "all"],
    words: [
      "if",
      "it",
      "wasn't",
      "for",
      "pick-pockets",
      "i'd",
      "have",
      "no",
      "sex",
      "life",
      "at",
      "all",
    ],
    id: "If it wasn't for pick-pockets I'd have no sex life at all.",
    author: "Rodney Dangerfield",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "knowledge",
      "improved",
      "challenged",
      "increased",
      "constantly",
      "or",
      "vanishes",
    ],
    words: [
      "knowledge",
      "has",
      "to",
      "be",
      "improved",
      "challenged",
      "and",
      "increased",
      "constantly",
      "or",
      "it",
      "vanishes",
    ],
    id: "Knowledge has to be improved, challenged, and increased constantly, or it vanishes.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["shut", "door", "all", "errors", "truth", "will", "out"],
    words: [
      "if",
      "you",
      "shut",
      "the",
      "door",
      "to",
      "all",
      "errors",
      "truth",
      "will",
      "be",
      "out",
    ],
    id: "If you shut the door to all errors, truth will be shut out.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["whatever", "life", "surround", "yourself", "smart", "who'll", "argue"],
    words: [
      "whatever",
      "you",
      "do",
      "in",
      "life",
      "surround",
      "yourself",
      "with",
      "smart",
      "people",
      "who'll",
      "argue",
    ],
    id: "Whatever you do in life, surround yourself with smart people who'll argue with you.",
    author: "John Wooden",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "conviction", "excessive", "literary", "production", "social", "offence"],
    words: [
      "i",
      "have",
      "the",
      "conviction",
      "that",
      "excessive",
      "literary",
      "production",
      "is",
      "a",
      "social",
      "offence",
    ],
    id: "I have the conviction that excessive literary production is a social offence.",
    author: "George Eliot",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["end", "human", "race", "will", "eventually", "die", "civilization"],
    words: [
      "the",
      "end",
      "of",
      "human",
      "race",
      "will",
      "be",
      "that",
      "it",
      "eventually",
      "die",
      "civilization",
    ],
    id: "The end of the human race will be that it will eventually die of civilization.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["comedy", "twisting", "reality", "commenting", "or", "observing", "life"],
    words: [
      "to",
      "me",
      "comedy",
      "is",
      "just",
      "twisting",
      "reality",
      "it's",
      "commenting",
      "or",
      "observing",
      "life",
    ],
    id: "To me, comedy is just twisting reality. It's commenting or observing or twisting life.",
    author: "Steven Wright",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["were", "make", "little", "fishes", "talk", "would", "whales"],
    words: [
      "if",
      "you",
      "were",
      "to",
      "make",
      "little",
      "fishes",
      "talk",
      "they",
      "would",
      "like",
      "whales",
    ],
    id: "If you were to make little fishes talk, they would talk like whales.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["socrates", "food", "soul", "surely", "i", "said", "knowledge"],
    words: [
      "and",
      "what",
      "socrates",
      "is",
      "the",
      "food",
      "of",
      "soul",
      "surely",
      "i",
      "said",
      "knowledge",
    ],
    id: "And what, Socrates, is the food of the soul? Surely, I said, knowledge is the food of the soul.",
    author: "Plato",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["leadership", "consists", "picking", "men", "helping", "their", "best"],
    words: [
      "leadership",
      "consists",
      "of",
      "picking",
      "good",
      "men",
      "and",
      "helping",
      "them",
      "do",
      "their",
      "best",
    ],
    id: "Leadership consists of picking good men and helping them do their best.",
    author: "Chester W. Nimitz",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "gay", "marriage", "should", "between", "man", "woman"],
    words: [
      "i",
      "think",
      "that",
      "gay",
      "marriage",
      "should",
      "be",
      "between",
      "a",
      "man",
      "and",
      "woman",
    ],
    id: "I think that gay marriage should be between a man and a woman.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "hold", "discussions", "when", "organ", "grinder", "room"],
    words: [
      "never",
      "hold",
      "discussions",
      "with",
      "the",
      "monkey",
      "when",
      "organ",
      "grinder",
      "is",
      "in",
      "room",
    ],
    id: "Never hold discussions with the monkey when the organ grinder is in the room.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "given", "little", "spark", "madness", "mustn't", "lose"],
    words: [
      "you're",
      "only",
      "given",
      "a",
      "little",
      "spark",
      "of",
      "madness",
      "you",
      "mustn't",
      "lose",
      "it",
    ],
    id: "You're only given a little spark of madness. You mustn't lose it.",
    author: "Robin Williams",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "ever", "happened", "came", "out", "helping", "others"],
    words: [
      "everything",
      "good",
      "that's",
      "ever",
      "happened",
      "to",
      "me",
      "came",
      "out",
      "of",
      "helping",
      "others",
    ],
    id: "Everything good that's ever happened to me came out of helping others.",
    author: "Danny Trejo",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["missing", "link", "cosmology", "nature", "dark", "matter", "energy"],
    words: [
      "the",
      "missing",
      "link",
      "in",
      "cosmology",
      "is",
      "nature",
      "of",
      "dark",
      "matter",
      "and",
      "energy",
    ],
    id: "The missing link in cosmology is the nature of dark matter and dark energy.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "justified", "doing", "evil", "on", "ground", "expedience"],
    words: [
      "no",
      "man",
      "is",
      "justified",
      "in",
      "doing",
      "evil",
      "on",
      "the",
      "ground",
      "of",
      "expedience",
    ],
    id: "No man is justified in doing evil on the ground of expedience.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "builders", "larger", "stones", "lie", "without", "lesser"],
    words: [
      "as",
      "the",
      "builders",
      "say",
      "larger",
      "stones",
      "do",
      "not",
      "lie",
      "well",
      "without",
      "lesser",
    ],
    id: "As the builders say, the larger stones do not lie well without the lesser.",
    author: "Plato",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["climate", "change", "really", "abstract", "thing", "most", "world"],
    words: [
      "climate",
      "change",
      "is",
      "a",
      "really",
      "abstract",
      "thing",
      "in",
      "most",
      "of",
      "the",
      "world",
    ],
    id: "Climate change is a really abstract thing in most of the world.",
    author: "James Balog",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["politeness", "desire", "treated", "politely", "esteemed", "polite", "oneself"],
    words: [
      "politeness",
      "is",
      "a",
      "desire",
      "to",
      "be",
      "treated",
      "politely",
      "and",
      "esteemed",
      "polite",
      "oneself",
    ],
    id: "Politeness is a desire to be treated politely, and to be esteemed polite oneself.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["goals", "allow", "control", "direction", "change", "your", "favor"],
    words: [
      "goals",
      "allow",
      "you",
      "to",
      "control",
      "the",
      "direction",
      "of",
      "change",
      "in",
      "your",
      "favor",
    ],
    id: "Goals allow you to control the direction of change in your favor.",
    author: "Brian Tracy",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["ten", "thousand", "regulations", "destroy", "all", "respect", "law"],
    words: [
      "if",
      "you",
      "have",
      "ten",
      "thousand",
      "regulations",
      "destroy",
      "all",
      "respect",
      "for",
      "the",
      "law",
    ],
    id: "If you have ten thousand regulations you destroy all respect for the law.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["distinctions", "drawn", "by", "mind", "necessarily", "equivalent", "reality"],
    words: [
      "distinctions",
      "drawn",
      "by",
      "the",
      "mind",
      "are",
      "not",
      "necessarily",
      "equivalent",
      "to",
      "in",
      "reality",
    ],
    id: "Distinctions drawn by the mind are not necessarily equivalent to distinctions in reality.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "usually", "more", "careful", "his", "than", "principles"],
    words: [
      "a",
      "man",
      "is",
      "usually",
      "more",
      "careful",
      "of",
      "his",
      "money",
      "than",
      "he",
      "principles",
    ],
    id: "A man is usually more careful of his money than he is of his principles.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "angry", "count", "ten", "before", "one", "hundred"],
    words: [
      "when",
      "angry",
      "count",
      "to",
      "ten",
      "before",
      "you",
      "speak",
      "if",
      "very",
      "one",
      "hundred",
    ],
    id: "When angry count to ten before you speak. If very angry, count to one hundred.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["find", "ways", "spread", "word", "god", "corner", "world"],
    words: [
      "find",
      "new",
      "ways",
      "to",
      "spread",
      "the",
      "word",
      "of",
      "god",
      "every",
      "corner",
      "world",
    ],
    id: "Find new ways to spread the word of God to every corner of the world.",
    author: "Pope Francis",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "folks", "as", "make", "up", "their", "minds"],
    words: [
      "most",
      "folks",
      "are",
      "as",
      "happy",
      "they",
      "make",
      "up",
      "their",
      "minds",
      "to",
      "be",
    ],
    id: "Most folks are as happy as they make up their minds to be.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["business", "realize", "you've", "made", "bad", "decision", "change"],
    words: [
      "in",
      "business",
      "if",
      "you",
      "realize",
      "you've",
      "made",
      "a",
      "bad",
      "decision",
      "change",
      "it",
    ],
    id: "In business, if you realize you've made a bad decision, you change it.",
    author: "Richard Branson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "strongly", "believe", "vengeance", "mine", "god", "decide"],
    words: [
      "i",
      "strongly",
      "believe",
      "vengeance",
      "is",
      "not",
      "mine",
      "it",
      "for",
      "god",
      "to",
      "decide",
    ],
    id: "I strongly believe vengeance is not mine; it is for God to decide.",
    author: "Y. S. Jaganmohan Reddy",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["whose", "wisdom", "cannot", "gets", "from", "being", "wise"],
    words: [
      "he",
      "whose",
      "wisdom",
      "cannot",
      "help",
      "him",
      "gets",
      "no",
      "good",
      "from",
      "being",
      "wise",
    ],
    id: "He whose wisdom cannot help him, gets no good from being wise.",
    author: "Quintus Ennius",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["women", "stronger", "than", "men", "-", "die", "wisdom"],
    words: [
      "women",
      "are",
      "stronger",
      "than",
      "men",
      "-",
      "they",
      "do",
      "not",
      "die",
      "of",
      "wisdom",
    ],
    id: "Women are stronger than men - they do not die of wisdom.",
    author: "James Stephens",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["confuse", "fame", "success", "madonna", "one", "helen", "keller"],
    words: [
      "don't",
      "confuse",
      "fame",
      "with",
      "success",
      "madonna",
      "is",
      "one",
      "helen",
      "keller",
      "the",
      "other",
    ],
    id: "Don't confuse fame with success. Madonna is one; Helen Keller is the other.",
    author: "Erma Bombeck",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "only", "meaningful", "enjoyable", "feels", "your", "own"],
    words: [
      "success",
      "is",
      "only",
      "meaningful",
      "and",
      "enjoyable",
      "if",
      "it",
      "feels",
      "like",
      "your",
      "own",
    ],
    id: "Success is only meaningful and enjoyable if it feels like your own.",
    author: "Michelle Obama",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["wisdom", "prepares", "worst", "folly", "leaves", "when", "comes"],
    words: [
      "wisdom",
      "prepares",
      "for",
      "the",
      "worst",
      "but",
      "folly",
      "leaves",
      "day",
      "when",
      "it",
      "comes",
    ],
    id: "Wisdom prepares for the worst, but folly leaves the worst for the day when it comes.",
    author: "Richard Cecil",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["wisdom", "knowledge", "which", "become", "part", "one's", "being"],
    words: [
      "wisdom",
      "is",
      "knowledge",
      "which",
      "has",
      "become",
      "a",
      "part",
      "of",
      "one's",
      "being",
    ],
    id: "Wisdom is knowledge which has become a part of one's being.",
    author: "Orison Swett Marden",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["repeat", "anything", "often", "enough", "will", "start", "become"],
    words: [
      "repeat",
      "anything",
      "often",
      "enough",
      "and",
      "it",
      "will",
      "start",
      "to",
      "become",
      "you",
    ],
    id: "Repeat anything often enough and it will start to become you.",
    author: "Tom Hopkins",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["hunger", "facile", "wisdom", "root", "all", "false", "philosophy"],
    words: [
      "the",
      "hunger",
      "for",
      "facile",
      "wisdom",
      "is",
      "root",
      "of",
      "all",
      "false",
      "philosophy",
    ],
    id: "The hunger for facile wisdom is the root of all false philosophy.",
    author: "George Santayana",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["single", "person", "possess", "wisdom", "chart", "our", "course"],
    words: [
      "no",
      "single",
      "person",
      "can",
      "possess",
      "the",
      "wisdom",
      "to",
      "chart",
      "our",
      "course",
    ],
    id: "No single person can possess the wisdom to chart our course.",
    author: "Jonathan Schell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["fans", "constitutional", "right", "expect", "success", "high", "expectations"],
    words: [
      "fans",
      "have",
      "a",
      "constitutional",
      "right",
      "to",
      "expect",
      "success",
      "and",
      "high",
      "expectations",
    ],
    id: "Fans have a constitutional right to expect success and have high expectations.",
    author: "Jim Harbaugh",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["true", "music", "must", "repeat", "thought", "inspirations", "time"],
    words: [
      "true",
      "music",
      "must",
      "repeat",
      "the",
      "thought",
      "and",
      "inspirations",
      "of",
      "people",
      "time",
    ],
    id: "True music must repeat the thought and inspirations of the people and the time.",
    author: "George Gershwin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["before", "acquire", "great", "power", "must", "wisdom", "use"],
    words: [
      "before",
      "we",
      "acquire",
      "great",
      "power",
      "must",
      "wisdom",
      "to",
      "use",
      "it",
      "well",
    ],
    id: "Before we acquire great power we must acquire wisdom to use it well.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "right", "electric", "cars", "-", "fact", "critical"],
    words: ["the", "time", "is", "right", "for", "electric", "cars", "-", "in", "fact", "critical"],
    id: "The time is right for electric cars - in fact the time is critical.",
    author: "Carlos Ghosn",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["would", "lift", "up", "must", "on", "higher", "ground"],
    words: ["if", "you", "would", "lift", "me", "up", "must", "be", "on", "higher", "ground"],
    id: "If you would lift me up you must be on higher ground.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["we've", "had", "cloning", "south", "years", "called", "cousins"],
    words: [
      "we've",
      "had",
      "cloning",
      "in",
      "the",
      "south",
      "for",
      "years",
      "it's",
      "called",
      "cousins",
    ],
    id: "We've had cloning in the South for years. It's called cousins.",
    author: "Robin Williams",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["burden", "self", "lightened", "i", "laugh", "at", "myself"],
    words: ["the", "burden", "of", "self", "is", "lightened", "with", "i", "laugh", "at", "myself"],
    id: "The burden of the self is lightened with I laugh at myself.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["you'll", "notice", "nancy", "reagan", "never", "when", "ronnie"],
    words: [
      "you'll",
      "notice",
      "that",
      "nancy",
      "reagan",
      "never",
      "drinks",
      "water",
      "when",
      "ronnie",
      "speaks",
    ],
    id: "You'll notice that Nancy Reagan never drinks water when Ronnie speaks.",
    author: "Robin Williams",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "keys",
      "brand",
      "success",
      "self-definition",
      "transparency",
      "authenticity",
      "accountability",
    ],
    words: [
      "the",
      "keys",
      "to",
      "brand",
      "success",
      "are",
      "self-definition",
      "transparency",
      "authenticity",
      "and",
      "accountability",
    ],
    id: "The keys to brand success are self-definition, transparency, authenticity and accountability.",
    author: "Simon Mainwaring",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "important", "thing", "communication", "hearing", "isn't", "said"],
    words: [
      "the",
      "most",
      "important",
      "thing",
      "in",
      "communication",
      "is",
      "hearing",
      "what",
      "isn't",
      "said",
    ],
    id: "The most important thing in communication is hearing what isn't said.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "i", "played", "sandbox", "kept", "covering", "up"],
    words: ["when", "i", "played", "in", "the", "sandbox", "cat", "kept", "covering", "me", "up"],
    id: "When I played in the sandbox, the cat kept covering me up.",
    author: "Rodney Dangerfield",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["look", "at", "walls", "pompeii", "got", "internet", "started"],
    words: [
      "look",
      "at",
      "the",
      "walls",
      "of",
      "pompeii",
      "that's",
      "what",
      "got",
      "internet",
      "started",
    ],
    id: "Look at the walls of Pompeii. That's what got the internet started.",
    author: "Robin Williams",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["supreme", "art", "war", "subdue", "enemy", "without", "fighting"],
    words: [
      "the",
      "supreme",
      "art",
      "of",
      "war",
      "is",
      "to",
      "subdue",
      "enemy",
      "without",
      "fighting",
    ],
    id: "The supreme art of war is to subdue the enemy without fighting.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["eyes", "soul", "multitudes", "unable", "endure", "vision", "divine"],
    words: [
      "the",
      "eyes",
      "of",
      "soul",
      "multitudes",
      "are",
      "unable",
      "to",
      "endure",
      "vision",
      "divine",
    ],
    id: "The eyes of the soul of the multitudes are unable to endure the vision of the divine.",
    author: "Plato",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "met", "surgeon", "general", "-", "offered", "cigarette"],
    words: ["i", "met", "the", "surgeon", "general", "-", "he", "offered", "me", "a", "cigarette"],
    id: "I met the surgeon general - he offered me a cigarette.",
    author: "Rodney Dangerfield",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "important", "part", "education", "proper", "training", "nursery"],
    words: [
      "the",
      "most",
      "important",
      "part",
      "of",
      "education",
      "is",
      "proper",
      "training",
      "in",
      "nursery",
    ],
    id: "The most important part of education is proper training in the nursery.",
    author: "Plato",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "necessary", "one", "cannot", "reap", "immediately", "sown"],
    words: [
      "patience",
      "is",
      "necessary",
      "and",
      "one",
      "cannot",
      "reap",
      "immediately",
      "where",
      "has",
      "sown",
    ],
    id: "Patience is necessary, and one cannot reap immediately where one has sown.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["talk", "little", "on", "many", "subjects", "much", "few"],
    words: ["do", "not", "talk", "a", "little", "on", "many", "subjects", "but", "much", "few"],
    id: "Do not talk a little on many subjects, but much on a few.",
    author: "Pythagoras",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "only", "understood", "backwards", "must", "lived", "forwards"],
    words: [
      "life",
      "can",
      "only",
      "be",
      "understood",
      "backwards",
      "but",
      "it",
      "must",
      "lived",
      "forwards",
    ],
    id: "Life can only be understood backwards; but it must be lived forwards.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "excessive",
      "increase",
      "anything",
      "causes",
      "reaction",
      "opposite",
      "direction",
    ],
    words: [
      "the",
      "excessive",
      "increase",
      "of",
      "anything",
      "causes",
      "a",
      "reaction",
      "in",
      "opposite",
      "direction",
    ],
    id: "The excessive increase of anything causes a reaction in the opposite direction.",
    author: "Plato",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["states", "as", "men", "grow", "out", "human", "characters"],
    words: [
      "states",
      "are",
      "as",
      "the",
      "men",
      "they",
      "grow",
      "out",
      "of",
      "human",
      "characters",
    ],
    id: "States are as the men, they grow out of human characters.",
    author: "Plato",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["words", "printed", "here", "concepts", "must", "through", "experiences"],
    words: [
      "the",
      "words",
      "printed",
      "here",
      "are",
      "concepts",
      "you",
      "must",
      "go",
      "through",
      "experiences",
    ],
    id: "The words printed here are concepts. You must go through the experiences.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["actor", "dreams", "getting", "second", "chance", "reprise", "role"],
    words: [
      "every",
      "actor",
      "dreams",
      "of",
      "getting",
      "a",
      "second",
      "chance",
      "to",
      "reprise",
      "role",
    ],
    id: "Every actor dreams of getting a second chance to reprise a role.",
    author: "Andrew Keenan-Bolger",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "little", "bit", "backward", "i", "didn't", "really"],
    words: ["i'm", "a", "little", "bit", "backward", "i", "didn't", "really", "go", "to", "school"],
    id: "I'm a little bit backward. I didn't really go to school.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["strength", "power", "despotism", "consists", "wholly", "fear", "resistance"],
    words: [
      "the",
      "strength",
      "and",
      "power",
      "of",
      "despotism",
      "consists",
      "wholly",
      "in",
      "fear",
      "resistance",
    ],
    id: "The strength and power of despotism consists wholly in the fear of resistance.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "schoolmaster", "more", "use", "than", "hundred", "priests"],
    words: [
      "one",
      "good",
      "schoolmaster",
      "is",
      "of",
      "more",
      "use",
      "than",
      "a",
      "hundred",
      "priests",
    ],
    id: "One good schoolmaster is of more use than a hundred priests.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["iteration", "friction", "likely", "generate", "heat", "instead", "progress"],
    words: [
      "iteration",
      "like",
      "friction",
      "is",
      "likely",
      "to",
      "generate",
      "heat",
      "instead",
      "of",
      "progress",
    ],
    id: "Iteration, like friction, is likely to generate heat instead of progress.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["die", "different", "from", "any", "one", "supposed", "luckier"],
    words: [
      "to",
      "die",
      "is",
      "different",
      "from",
      "what",
      "any",
      "one",
      "supposed",
      "and",
      "luckier",
    ],
    id: "To die is different from what any one supposed, and luckier.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["frustrating", "still", "more", "movies", "featuring", "strong", "women"],
    words: [
      "it's",
      "frustrating",
      "we",
      "still",
      "don't",
      "see",
      "more",
      "movies",
      "featuring",
      "strong",
      "women",
    ],
    id: "It's frustrating we still don't see more movies featuring strong women.",
    author: "Theo James",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["great", "deed", "done", "by", "falterers", "ask", "certainty"],
    words: [
      "no",
      "great",
      "deed",
      "is",
      "done",
      "by",
      "falterers",
      "who",
      "ask",
      "for",
      "certainty",
    ],
    id: "No great deed is done by falterers who ask for certainty.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["courage", "soldier", "heightened", "by", "his", "knowledge", "profession"],
    words: [
      "the",
      "courage",
      "of",
      "a",
      "soldier",
      "is",
      "heightened",
      "by",
      "his",
      "knowledge",
      "profession",
    ],
    id: "The courage of a soldier is heightened by his knowledge of his profession.",
    author: "Publius Flavius Vegetius Renatus",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "life", "was", "movie", "one", "would", "believe"],
    words: ["if", "my", "life", "was", "a", "movie", "no", "one", "would", "believe", "it"],
    id: "If my life was a movie, no one would believe it.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["temperance", "simply", "disposition", "mind", "which", "binds", "passion"],
    words: [
      "temperance",
      "is",
      "simply",
      "a",
      "disposition",
      "of",
      "the",
      "mind",
      "which",
      "binds",
      "passion",
    ],
    id: "Temperance is simply a disposition of the mind which binds the passion.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["ravi", "always", "put", "lot", "efforts", "my", "birthday"],
    words: ["ravi", "always", "put", "in", "a", "lot", "of", "efforts", "for", "my", "birthday"],
    id: "Ravi always put in a lot of efforts for my birthday.",
    author: "Sargun Mehta",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["defects", "mind", "those", "face", "grow", "worse", "age"],
    words: [
      "the",
      "defects",
      "of",
      "mind",
      "like",
      "those",
      "face",
      "grow",
      "worse",
      "with",
      "age",
    ],
    id: "The defects of the mind, like those of the face, grow worse with age.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["different", "taste", "jokes", "great", "strain", "on", "affections"],
    words: [
      "different",
      "taste",
      "in",
      "jokes",
      "is",
      "a",
      "great",
      "strain",
      "on",
      "the",
      "affections",
    ],
    id: "Different taste in jokes is a great strain on the affections.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "contradict", "myself", "then", "large", "contain", "multitudes"],
    words: [
      "do",
      "i",
      "contradict",
      "myself",
      "very",
      "well",
      "then",
      "am",
      "large",
      "contain",
      "multitudes",
    ],
    id: "Do I contradict myself? Very well, then I contradict myself, I am large, I contain multitudes.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["body", "calculated", "millimeter", "suggest", "bud", "yet", "guarantee"],
    words: [
      "her",
      "body",
      "calculated",
      "to",
      "a",
      "millimeter",
      "suggest",
      "bud",
      "yet",
      "guarantee",
      "flower",
    ],
    id: "Her body calculated to a millimeter to suggest a bud yet guarantee a flower.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["begin", "yourself", "as", "soul", "body", "rather", "than"],
    words: ["begin", "to", "see", "yourself", "as", "a", "soul", "with", "body", "rather", "than"],
    id: "Begin to see yourself as a soul with a body rather than a body with a soul.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["turn", "your", "face", "sun", "shadows", "fall", "behind"],
    words: ["turn", "your", "face", "to", "the", "sun", "and", "shadows", "fall", "behind", "you"],
    id: "Turn your face to the sun and the shadows fall behind you.",
    author: "Unknown",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["cannot", "always", "control", "goes", "on", "outside", "inside"],
    words: [
      "you",
      "cannot",
      "always",
      "control",
      "what",
      "goes",
      "on",
      "outside",
      "but",
      "can",
      "inside",
    ],
    id: "You cannot always control what goes on outside. But you can always control what goes on inside.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["way", "get", "started", "quit", "talking", "begin", "doing"],
    words: ["the", "way", "to", "get", "started", "is", "quit", "talking", "and", "begin", "doing"],
    id: "The way to get started is to quit talking and begin doing.",
    author: "Walt Disney",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "as", "bad", "worst", "thank", "god", "best"],
    words: ["i", "am", "as", "bad", "the", "worst", "but", "thank", "god", "good", "best"],
    id: "I am as bad as the worst, but, thank God, I am as good as the best.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["virtues", "vices", "all", "put", "motion", "by", "interest"],
    words: [
      "the",
      "virtues",
      "and",
      "vices",
      "are",
      "all",
      "put",
      "in",
      "motion",
      "by",
      "interest",
    ],
    id: "The virtues and vices are all put in motion by interest.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "people's", "funerals", "otherwise", "won't", "come", "yours"],
    words: [
      "always",
      "go",
      "to",
      "other",
      "people's",
      "funerals",
      "otherwise",
      "they",
      "won't",
      "come",
      "yours",
    ],
    id: "Always go to other people's funerals, otherwise they won't come to yours.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["ask", "difference", "between", "leader", "boss", "leads", "drives"],
    words: [
      "people",
      "ask",
      "the",
      "difference",
      "between",
      "a",
      "leader",
      "and",
      "boss",
      "leads",
      "drives",
    ],
    id: "People ask the difference between a leader and a boss. The leader leads, and the boss drives.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "supposes", "himself", "fully", "understood", "or", "appreciated"],
    words: [
      "every",
      "man",
      "supposes",
      "himself",
      "not",
      "to",
      "be",
      "fully",
      "understood",
      "or",
      "appreciated",
    ],
    id: "Every man supposes himself not to be fully understood or appreciated.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "life", "experiment", "more", "experiments", "make", "better"],
    words: [
      "all",
      "life",
      "is",
      "an",
      "experiment",
      "the",
      "more",
      "experiments",
      "you",
      "make",
      "better",
    ],
    id: "All life is an experiment. The more experiments you make the better.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "tragedy", "when", "seen", "close-up", "comedy", "long-shot"],
    words: [
      "life",
      "is",
      "a",
      "tragedy",
      "when",
      "seen",
      "in",
      "close-up",
      "but",
      "comedy",
      "long-shot",
    ],
    id: "Life is a tragedy when seen in close-up, but a comedy in long-shot.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "fear", "striking", "out", "get", "your", "way"],
    words: ["never", "let", "the", "fear", "of", "striking", "out", "get", "in", "your", "way"],
    id: "Never let the fear of striking out get in your way.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["existence", "really", "imperfect", "tense", "never", "becomes", "present"],
    words: [
      "existence",
      "really",
      "is",
      "an",
      "imperfect",
      "tense",
      "that",
      "never",
      "becomes",
      "a",
      "present",
    ],
    id: "Existence really is an imperfect tense that never becomes a present.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["superior", "man", "modest", "his", "speech", "exceeds", "actions"],
    words: [
      "the",
      "superior",
      "man",
      "is",
      "modest",
      "in",
      "his",
      "speech",
      "but",
      "exceeds",
      "actions",
    ],
    id: "The superior man is modest in his speech, but exceeds in his actions.",
    author: "Confucius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["easier", "strong", "children", "than", "repair", "broken", "men"],
    words: [
      "it",
      "is",
      "easier",
      "to",
      "build",
      "strong",
      "children",
      "than",
      "repair",
      "broken",
      "men",
    ],
    id: "It is easier to build strong children than to repair broken men.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["motivational", "became", "my", "career", "would", "ever", "thought"],
    words: [
      "motivational",
      "speaking",
      "became",
      "my",
      "career",
      "who",
      "would",
      "ever",
      "have",
      "thought",
      "it",
    ],
    id: "Motivational speaking became my career. Who would ever have thought it?",
    author: "Rudy Ruettiger",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["optimism", "kind", "heart", "stimulant", "-", "digitalis", "failure"],
    words: [
      "optimism",
      "is",
      "a",
      "kind",
      "of",
      "heart",
      "stimulant",
      "-",
      "the",
      "digitalis",
      "failure",
    ],
    id: "Optimism is a kind of heart stimulant - the digitalis of failure.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "home", "heaven", "i'm", "traveling", "through", "world"],
    words: [
      "my",
      "home",
      "is",
      "in",
      "heaven",
      "i'm",
      "just",
      "traveling",
      "through",
      "this",
      "world",
    ],
    id: "My home is in Heaven. I'm just traveling through this world.",
    author: "Billy Graham",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "greatest",
      "achievements",
      "human",
      "mind",
      "generally",
      "received",
      "distrust",
    ],
    words: [
      "the",
      "greatest",
      "achievements",
      "of",
      "human",
      "mind",
      "are",
      "generally",
      "received",
      "with",
      "distrust",
    ],
    id: "The greatest achievements of the human mind are generally received with distrust.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["anyone", "keeps", "ability", "beauty", "never", "grows", "old"],
    words: [
      "anyone",
      "who",
      "keeps",
      "the",
      "ability",
      "to",
      "see",
      "beauty",
      "never",
      "grows",
      "old",
    ],
    id: "Anyone who keeps the ability to see beauty never grows old.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "which", "lacking", "makes", "one", "even", "unbearable"],
    words: [
      "the",
      "happiness",
      "which",
      "is",
      "lacking",
      "makes",
      "one",
      "think",
      "even",
      "has",
      "unbearable",
    ],
    id: "The happiness which is lacking makes one think even the happiness one has unbearable.",
    author: "Joseph Roux",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["great", "men", "seldom", "over-scrupulous", "arrangement", "their", "attire"],
    words: [
      "great",
      "men",
      "are",
      "seldom",
      "over-scrupulous",
      "in",
      "the",
      "arrangement",
      "of",
      "their",
      "attire",
    ],
    id: "Great men are seldom over-scrupulous in the arrangement of their attire.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "listen", "lot", "ted", "talks", "motivational", "speakers"],
    words: [
      "i",
      "listen",
      "to",
      "a",
      "lot",
      "of",
      "ted",
      "talks",
      "and",
      "motivational",
      "speakers",
    ],
    id: "I listen to a lot of TED talks and motivational speakers.",
    author: "Jordan Burroughs",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["women", "-", "always", "trouble", "can't", "live", "without"],
    words: [
      "women",
      "-",
      "always",
      "in",
      "trouble",
      "with",
      "them",
      "but",
      "can't",
      "live",
      "without",
    ],
    id: "Women - always in trouble with them, but can't live without them.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "writing", "swimming", "under", "holding", "your", "breath"],
    words: [
      "all",
      "good",
      "writing",
      "is",
      "swimming",
      "under",
      "water",
      "and",
      "holding",
      "your",
      "breath",
    ],
    id: "All good writing is swimming under water and holding your breath.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["those", "trust", "chance", "must", "abide", "by", "results"],
    words: ["those", "who", "trust", "to", "chance", "must", "abide", "by", "the", "results", "of"],
    id: "Those who trust to chance must abide by the results of chance.",
    author: "Calvin Coolidge",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["histories", "more", "full", "examples", "fidelity", "than", "friends"],
    words: [
      "histories",
      "are",
      "more",
      "full",
      "of",
      "examples",
      "the",
      "fidelity",
      "dogs",
      "than",
      "friends",
    ],
    id: "Histories are more full of examples of the fidelity of dogs than of friends.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["ignorance", "our", "master", "there", "possibility", "real", "peace"],
    words: [
      "where",
      "ignorance",
      "is",
      "our",
      "master",
      "there",
      "no",
      "possibility",
      "of",
      "real",
      "peace",
    ],
    id: "Where ignorance is our master, there is no possibility of real peace.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "your", "best", "plant", "will", "harvest", "later"],
    words: [
      "always",
      "do",
      "your",
      "best",
      "what",
      "you",
      "plant",
      "now",
      "will",
      "harvest",
      "later",
    ],
    id: "Always do your best. What you plant now, you will harvest later.",
    author: "Og Mandino",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["hard", "jobs", "first", "easy", "will", "take", "themselves"],
    words: [
      "do",
      "the",
      "hard",
      "jobs",
      "first",
      "easy",
      "will",
      "take",
      "care",
      "of",
      "themselves",
    ],
    id: "Do the hard jobs first. The easy jobs will take care of themselves.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "without", "trying", "lots", "ideas", "failing", "times"],
    words: [
      "there's",
      "no",
      "learning",
      "without",
      "trying",
      "lots",
      "of",
      "ideas",
      "and",
      "failing",
      "times",
    ],
    id: "There's no learning without trying lots of ideas and failing lots of times.",
    author: "Jonathan Ive",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["prolonged", "slavery", "women", "darkest", "page", "human", "history"],
    words: [
      "the",
      "prolonged",
      "slavery",
      "of",
      "women",
      "is",
      "darkest",
      "page",
      "in",
      "human",
      "history",
    ],
    id: "The prolonged slavery of women is the darkest page in human history.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["laughs", "at", "everything", "why", "because", "fine", "teeth"],
    words: [
      "she",
      "laughs",
      "at",
      "everything",
      "you",
      "say",
      "why",
      "because",
      "has",
      "fine",
      "teeth",
    ],
    id: "She laughs at everything you say. Why? Because she has fine teeth.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["lack", "transparency", "results", "distrust", "deep", "sense", "insecurity"],
    words: [
      "a",
      "lack",
      "of",
      "transparency",
      "results",
      "in",
      "distrust",
      "and",
      "deep",
      "sense",
      "insecurity",
    ],
    id: "A lack of transparency results in distrust and a deep sense of insecurity.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["cycle", "inefficient", "thoughts", "whirling", "around", "center", "fear"],
    words: [
      "worry",
      "is",
      "a",
      "cycle",
      "of",
      "inefficient",
      "thoughts",
      "whirling",
      "around",
      "center",
      "fear",
    ],
    id: "Worry is a cycle of inefficient thoughts whirling around a center of fear.",
    author: "Corrie Ten Boom",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "succession", "moments", "live", "each", "one", "succeed"],
    words: [
      "life",
      "is",
      "a",
      "succession",
      "of",
      "moments",
      "to",
      "live",
      "each",
      "one",
      "succeed",
    ],
    id: "Life is a succession of moments, to live each one is to succeed.",
    author: "Corita Kent",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["portraying", "emotionally", "ill", "characters", "chance", "really", "act"],
    words: [
      "portraying",
      "emotionally",
      "ill",
      "characters",
      "gives",
      "me",
      "the",
      "chance",
      "to",
      "really",
      "act",
    ],
    id: "Portraying emotionally ill characters gives me the chance to really act.",
    author: "Leonardo DiCaprio",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "inclusive",
      "good-quality",
      "education",
      "foundation",
      "dynamic",
      "equitable",
      "societies",
    ],
    words: [
      "inclusive",
      "good-quality",
      "education",
      "is",
      "a",
      "foundation",
      "for",
      "dynamic",
      "and",
      "equitable",
      "societies",
    ],
    id: "Inclusive, good-quality education is a foundation for dynamic and equitable societies.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["saddest", "thing", "i", "imagine", "get", "used", "luxury"],
    words: ["the", "saddest", "thing", "i", "can", "imagine", "is", "to", "get", "used", "luxury"],
    id: "The saddest thing I can imagine is to get used to luxury.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "concept",
      "loneliness",
      "exile",
      "self-sufficiency",
      "continually",
      "bucks",
      "up",
    ],
    words: [
      "the",
      "concept",
      "of",
      "loneliness",
      "and",
      "exile",
      "self-sufficiency",
      "continually",
      "bucks",
      "me",
      "up",
    ],
    id: "The concept of loneliness and exile and self-sufficiency continually bucks me up.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["sometimes", "you've", "got", "when", "time", "leave", "party"],
    words: [
      "sometimes",
      "you've",
      "got",
      "to",
      "know",
      "when",
      "it's",
      "time",
      "leave",
      "the",
      "party",
    ],
    id: "Sometimes you've got to know when it's time to leave the party.",
    author: "Victoria Beckham",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["impossible", "reason", "without", "arriving", "at", "supreme", "being"],
    words: [
      "it",
      "is",
      "impossible",
      "to",
      "reason",
      "without",
      "arriving",
      "at",
      "a",
      "supreme",
      "being",
    ],
    id: "It is impossible to reason without arriving at a Supreme Being.",
    author: "George Washington",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["big", "data", "education", "huge", "potential", "improve", "materials"],
    words: [
      "big",
      "data",
      "in",
      "education",
      "has",
      "huge",
      "potential",
      "to",
      "improve",
      "learning",
      "materials",
    ],
    id: "Big data in education has huge potential to improve learning materials.",
    author: "Jose Ferreira",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["doing", "something", "keep", "gonna", "get", "better", "results"],
    words: [
      "if",
      "you're",
      "doing",
      "something",
      "and",
      "keep",
      "it",
      "gonna",
      "get",
      "better",
      "results",
    ],
    id: "If you're doing something and keep doing it, you're gonna get better results.",
    author: "King Von",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "can't", "prove", "scientifically", "there's", "god", "believe"],
    words: [
      "i",
      "can't",
      "prove",
      "it",
      "scientifically",
      "that",
      "there's",
      "a",
      "god",
      "but",
      "believe",
    ],
    id: "I can't prove it scientifically, that there's a God, but I believe.",
    author: "Billy Graham",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["effort", "made", "happiness", "others", "lifts", "above", "ourselves"],
    words: [
      "an",
      "effort",
      "made",
      "for",
      "the",
      "happiness",
      "of",
      "others",
      "lifts",
      "above",
      "ourselves",
    ],
    id: "An effort made for the happiness of others lifts above ourselves.",
    author: "Lydia M. Child",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "consists", "having", "much", "being", "content", "little"],
    words: [
      "happiness",
      "consists",
      "not",
      "in",
      "having",
      "much",
      "but",
      "being",
      "content",
      "with",
      "little",
    ],
    id: "Happiness consists not in having much, but in being content with little.",
    author: "Marguerite Gardiner",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["will", "soon", "break", "bow", "keep", "always", "stretched"],
    words: [
      "you",
      "will",
      "soon",
      "break",
      "the",
      "bow",
      "if",
      "keep",
      "it",
      "always",
      "stretched",
    ],
    id: "You will soon break the bow if you keep it always stretched.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "reward", "-", "consequence", "suffering", "punishment", "result"],
    words: [
      "happiness",
      "is",
      "not",
      "a",
      "reward",
      "-",
      "it",
      "consequence",
      "suffering",
      "punishment",
      "result",
    ],
    id: "Happiness is not a reward - it is a consequence. Suffering is not a punishment - it is a result.",
    author: "Robert Green Ingersoll",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["using", "windows", "pcs", "more", "than", "watch", "tv"],
    words: [
      "people",
      "are",
      "using",
      "windows",
      "pcs",
      "more",
      "than",
      "they",
      "watch",
      "tv",
      "now",
    ],
    id: "People are using Windows PCs more than they watch TV now.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "present", "only", "shattered", "by", "comparison", "past"],
    words: [
      "happiness",
      "in",
      "the",
      "present",
      "is",
      "only",
      "shattered",
      "by",
      "comparison",
      "with",
      "past",
    ],
    id: "Happiness in the present is only shattered by comparison with the past.",
    author: "Douglas Horton",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "finds", "its", "purpose", "fulfillment", "expansion", "happiness"],
    words: [
      "life",
      "finds",
      "its",
      "purpose",
      "and",
      "fulfillment",
      "in",
      "the",
      "expansion",
      "of",
      "happiness",
    ],
    id: "Life finds its purpose and fulfillment in the expansion of happiness.",
    author: "Maharishi Mahesh Yogi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["better", "than", "worshiping", "gods", "obedience", "laws", "righteousness"],
    words: [
      "better",
      "than",
      "worshiping",
      "gods",
      "is",
      "obedience",
      "to",
      "the",
      "laws",
      "of",
      "righteousness",
    ],
    id: "Better than worshiping gods is obedience to the laws of righteousness.",
    author: "Buddha",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["venus", "transit", "spectacle", "way", "total", "solar", "eclipse"],
    words: [
      "the",
      "venus",
      "transit",
      "is",
      "not",
      "a",
      "spectacle",
      "way",
      "total",
      "solar",
      "eclipse",
    ],
    id: "The Venus transit is not a spectacle the way a total solar eclipse is a spectacle.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "nation", "filled", "strife", "then", "patriots", "flourish"],
    words: [
      "when",
      "a",
      "nation",
      "is",
      "filled",
      "with",
      "strife",
      "then",
      "do",
      "patriots",
      "flourish",
    ],
    id: "When a nation is filled with strife, then do patriots flourish.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["weak", "man", "by", "accident", "strong", "non-violent", "unjust"],
    words: [
      "a",
      "weak",
      "man",
      "is",
      "just",
      "by",
      "accident",
      "strong",
      "but",
      "non-violent",
      "unjust",
    ],
    id: "A weak man is just by accident. A strong but non-violent man is unjust by accident.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["virtue", "persecuted", "more", "by", "wicked", "than", "loved"],
    words: [
      "virtue",
      "is",
      "persecuted",
      "more",
      "by",
      "the",
      "wicked",
      "than",
      "it",
      "loved",
      "good",
    ],
    id: "Virtue is persecuted more by the wicked than it is loved by the good.",
    author: "Buddha",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "seldom", "politics", "more", "than", "eighteen", "hours"],
    words: [
      "i",
      "seldom",
      "think",
      "of",
      "politics",
      "more",
      "than",
      "eighteen",
      "hours",
      "a",
      "day",
    ],
    id: "I seldom think of politics more than eighteen hours a day.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["formation", "one's", "character", "ought", "everyone's", "chief", "aim"],
    words: [
      "the",
      "formation",
      "of",
      "one's",
      "character",
      "ought",
      "to",
      "be",
      "everyone's",
      "chief",
      "aim",
    ],
    id: "The formation of one's character ought to be everyone's chief aim.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["upon", "creatures", "made", "ourselves", "at", "last", "dependent"],
    words: [
      "upon",
      "the",
      "creatures",
      "we",
      "have",
      "made",
      "are",
      "ourselves",
      "at",
      "last",
      "dependent",
    ],
    id: "Upon the creatures we have made, we are, ourselves, at last, dependent.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["importance", "logo", "into", "today's", "fashion", "un", "lievable"],
    words: [
      "the",
      "importance",
      "of",
      "logo",
      "into",
      "today's",
      "fashion",
      "is",
      "un",
      "be",
      "lievable",
    ],
    id: "The importance of logo into today's fashion is un! be! Lievable!",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["middle-income", "countries", "biggest", "users", "gmos", "places", "brazil"],
    words: [
      "middle-income",
      "countries",
      "are",
      "the",
      "biggest",
      "users",
      "of",
      "gmos",
      "places",
      "like",
      "brazil",
    ],
    id: "Middle-income countries are the biggest users of GMOs. Places like Brazil.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["nobody", "owes", "anybody", "living", "everybody", "entitled", "chance"],
    words: [
      "nobody",
      "owes",
      "anybody",
      "a",
      "living",
      "but",
      "everybody",
      "is",
      "entitled",
      "to",
      "chance",
    ],
    id: "Nobody owes anybody a living, but everybody is entitled to a chance.",
    author: "Jack Dempsey",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "eloquent", "prayer", "through", "hands", "heal", "bless"],
    words: [
      "the",
      "most",
      "eloquent",
      "prayer",
      "is",
      "through",
      "hands",
      "that",
      "heal",
      "and",
      "bless",
    ],
    id: "The most eloquent prayer is the prayer through hands that heal and bless.",
    author: "Billy Graham",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["job", "never", "started", "as", "takes", "longest", "finish"],
    words: [
      "it's",
      "the",
      "job",
      "that's",
      "never",
      "started",
      "as",
      "takes",
      "longest",
      "to",
      "finish",
    ],
    id: "It's the job that's never started as takes longest to finish.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["person", "places", "themselves", "on", "level", "ones", "praise"],
    words: [
      "a",
      "person",
      "places",
      "themselves",
      "on",
      "level",
      "with",
      "the",
      "ones",
      "they",
      "praise",
    ],
    id: "A person places themselves on a level with the ones they praise.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["darkness", "cannot", "drive", "out", "only", "light", "hate"],
    words: [
      "darkness",
      "cannot",
      "drive",
      "out",
      "only",
      "light",
      "can",
      "do",
      "that",
      "hate",
      "love",
    ],
    id: "Darkness cannot drive out darkness; only light can do that. Hate cannot drive out hate; only love can do that.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["key", "immortality", "first", "living", "life", "worth", "remembering"],
    words: [
      "the",
      "key",
      "to",
      "immortality",
      "is",
      "first",
      "living",
      "a",
      "life",
      "worth",
      "remembering",
    ],
    id: "The key to immortality is first living a life worth remembering.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "afraid", "anything", "god", "will", "take", "everything"],
    words: [
      "i",
      "am",
      "not",
      "afraid",
      "of",
      "anything",
      "god",
      "will",
      "take",
      "care",
      "everything",
    ],
    id: "I am not afraid of anything. God will take care of everything.",
    author: "Y. S. Jaganmohan Reddy",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["nothing", "heaven", "itself", "better", "than", "friend", "really"],
    words: [
      "nothing",
      "but",
      "heaven",
      "itself",
      "is",
      "better",
      "than",
      "a",
      "friend",
      "who",
      "really",
    ],
    id: "Nothing but heaven itself is better than a friend who is really a friend.",
    author: "Plautus",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["must", "trust", "believe", "or", "life", "becomes", "impossible"],
    words: [
      "you",
      "must",
      "trust",
      "and",
      "believe",
      "in",
      "people",
      "or",
      "life",
      "becomes",
      "impossible",
    ],
    id: "You must trust and believe in people or life becomes impossible.",
    author: "Anton Chekhov",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["little", "details", "vital", "things", "make", "big", "happen"],
    words: [
      "it's",
      "the",
      "little",
      "details",
      "that",
      "are",
      "vital",
      "things",
      "make",
      "big",
      "happen",
    ],
    id: "It's the little details that are vital. Little things make big things happen.",
    author: "John Wooden",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["friendship", "always", "belonged", "core", "my", "spiritual", "journey"],
    words: [
      "friendship",
      "has",
      "always",
      "belonged",
      "to",
      "the",
      "core",
      "of",
      "my",
      "spiritual",
      "journey",
    ],
    id: "Friendship has always belonged to the core of my spiritual journey.",
    author: "Henri Nouwen",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["steady", "quiet", "plodding", "ones", "win", "lifelong", "race"],
    words: [
      "it's",
      "the",
      "steady",
      "quiet",
      "plodding",
      "ones",
      "who",
      "win",
      "in",
      "lifelong",
      "race",
    ],
    id: "It's the steady, quiet, plodding ones who win in the lifelong race.",
    author: "Robert W. Service",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["special", "effects", "movies", "taken", "over", "universe", "scary"],
    words: [
      "special",
      "effects",
      "movies",
      "have",
      "taken",
      "over",
      "the",
      "universe",
      "that",
      "and",
      "scary",
    ],
    id: "Special effects movies have taken over the universe. That and scary movies.",
    author: "Jami Gertz",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "words", "my", "food", "breath", "wine", "everything"],
    words: [
      "your",
      "words",
      "are",
      "my",
      "food",
      "breath",
      "wine",
      "you",
      "everything",
      "to",
      "me",
    ],
    id: "Your words are my food, your breath my wine. You are everything to me.",
    author: "Sarah Bernhardt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "woman", "at", "beginning", "all", "great", "things"],
    words: ["there", "is", "a", "woman", "at", "the", "beginning", "of", "all", "great", "things"],
    id: "There is a woman at the beginning of all great things.",
    author: "Alphonse de Lamartine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "knowledge", "little", "island", "great", "ocean", "nonknowledge"],
    words: [
      "our",
      "knowledge",
      "is",
      "a",
      "little",
      "island",
      "in",
      "great",
      "ocean",
      "of",
      "nonknowledge",
    ],
    id: "Our knowledge is a little island in a great ocean of nonknowledge.",
    author: "Isaac Bashevis Singer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["take", "step", "back", "evaluate", "important", "enjoy", "life"],
    words: [
      "take",
      "a",
      "step",
      "back",
      "evaluate",
      "what",
      "is",
      "important",
      "and",
      "enjoy",
      "life",
    ],
    id: "Take a step back, evaluate what is important, and enjoy life.",
    author: "Teri Garr",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["given", "their", "lives", "industry", "make", "movies", "compelling"],
    words: [
      "people",
      "have",
      "given",
      "their",
      "lives",
      "to",
      "this",
      "industry",
      "make",
      "movies",
      "compelling",
    ],
    id: "People have given their lives to this industry to make movies compelling.",
    author: "David Leitch",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["knowledge", "human", "nature", "beginning", "end", "political", "education"],
    words: [
      "knowledge",
      "of",
      "human",
      "nature",
      "is",
      "the",
      "beginning",
      "and",
      "end",
      "political",
      "education",
    ],
    id: "Knowledge of human nature is the beginning and end of political education.",
    author: "Henry Adams",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "strong",
      "positive",
      "self-image",
      "best",
      "possible",
      "preparation",
      "success",
    ],
    words: [
      "a",
      "strong",
      "positive",
      "self-image",
      "is",
      "the",
      "best",
      "possible",
      "preparation",
      "for",
      "success",
    ],
    id: "A strong, positive self-image is the best possible preparation for success.",
    author: "Joyce Brothers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["were", "bringing", "wham", "close", "weren't", "our", "friendship"],
    words: [
      "we",
      "were",
      "bringing",
      "wham",
      "to",
      "a",
      "close",
      "but",
      "weren't",
      "our",
      "friendship",
    ],
    id: "We were bringing Wham! to a close, but we weren't bringing our friendship to a close.",
    author: "Andrew Ridgeley",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "progressive", "realization", "worthy", "goal", "or", "ideal"],
    words: [
      "success",
      "is",
      "the",
      "progressive",
      "realization",
      "of",
      "a",
      "worthy",
      "goal",
      "or",
      "ideal",
    ],
    id: "Success is the progressive realization of a worthy goal or ideal.",
    author: "Earl Nightingale",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "room", "insecurities", "or", "one", "upmanship", "friendship"],
    words: [
      "there",
      "is",
      "no",
      "room",
      "for",
      "insecurities",
      "or",
      "one",
      "upmanship",
      "in",
      "friendship",
    ],
    id: "There is no room for insecurities or one upmanship in friendship.",
    author: "Ramya Krishnan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "intended", "goes", "far", "beyond", "anything", "imagine"],
    words: [
      "what",
      "god",
      "intended",
      "for",
      "you",
      "goes",
      "far",
      "beyond",
      "anything",
      "can",
      "imagine",
    ],
    id: "What God intended for you goes far beyond anything you can imagine.",
    author: "Oprah Winfrey",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["failure", "key", "success", "each", "mistake", "teaches", "something"],
    words: [
      "failure",
      "is",
      "the",
      "key",
      "to",
      "success",
      "each",
      "mistake",
      "teaches",
      "us",
      "something",
    ],
    id: "Failure is the key to success; each mistake teaches us something.",
    author: "Morihei Ueshiba",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "trouble", "comes", "focus", "on", "god's", "ability"],
    words: [
      "when",
      "trouble",
      "comes",
      "focus",
      "on",
      "god's",
      "ability",
      "to",
      "care",
      "for",
      "you",
    ],
    id: "When trouble comes, focus on God's ability to care for you.",
    author: "Charles Stanley",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "any",
      "businessman",
      "will",
      "tell",
      "transportation",
      "fundamental",
      "success",
    ],
    words: [
      "any",
      "businessman",
      "will",
      "tell",
      "you",
      "that",
      "transportation",
      "is",
      "fundamental",
      "to",
      "success",
    ],
    id: "Any businessman will tell you that transportation is fundamental to success.",
    author: "John Hickenlooper",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "persevering", "persevere", "success", "lies", "ahead"],
    words: [
      "i",
      "believe",
      "in",
      "persevering",
      "if",
      "you",
      "persevere",
      "success",
      "lies",
      "ahead",
      "of",
    ],
    id: "I believe in persevering. If you persevere, success lies ahead of you.",
    author: "Liu Yang",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "must", "never", "measured", "by", "how", "much"],
    words: [
      "success",
      "must",
      "never",
      "be",
      "measured",
      "by",
      "how",
      "much",
      "money",
      "you",
      "have",
    ],
    id: "Success must never be measured by how much money you have.",
    author: "Zig Ziglar",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "sometimes",
      "i",
      "confuse",
      "rote",
      "traditional",
      "conceptual",
      "instruction",
    ],
    words: [
      "sometimes",
      "i",
      "think",
      "people",
      "confuse",
      "rote",
      "learning",
      "with",
      "traditional",
      "conceptual",
      "instruction",
    ],
    id: "Sometimes I think people confuse rote learning with traditional conceptual instruction.",
    author: "Sal Khan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["desire", "most", "important", "factor", "success", "any", "athlete"],
    words: [
      "desire",
      "is",
      "the",
      "most",
      "important",
      "factor",
      "in",
      "success",
      "of",
      "any",
      "athlete",
    ],
    id: "Desire is the most important factor in the success of any athlete.",
    author: "Bill Shoemaker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "wanna", "action", "movies", "tough", "guy", "scare"],
    words: ["i", "wanna", "be", "in", "action", "movies", "the", "tough", "guy", "scare", "people"],
    id: "I wanna be in action movies, I wanna be the tough guy... I wanna scare people.",
    author: "Richard Schiff",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["takes", "discipline", "social", "media", "steal", "your", "time"],
    words: [
      "it",
      "takes",
      "discipline",
      "not",
      "to",
      "let",
      "social",
      "media",
      "steal",
      "your",
      "time",
    ],
    id: "It takes discipline not to let social media steal your time.",
    author: "Alexis Ohanian",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "thank", "lord", "great", "principle", "repentance", "forgiveness"],
    words: [
      "i",
      "thank",
      "the",
      "lord",
      "for",
      "great",
      "principle",
      "of",
      "repentance",
      "and",
      "forgiveness",
    ],
    id: "I thank the Lord for the great principle of repentance and forgiveness.",
    author: "Gordon B. Hinckley",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "passing", "time", "occupy", "our", "chair", "briefly"],
    words: [
      "we're",
      "all",
      "just",
      "passing",
      "time",
      "and",
      "occupy",
      "our",
      "chair",
      "very",
      "briefly",
    ],
    id: "We're all just passing time and occupy our chair very briefly.",
    author: "John Hurt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "drives",
      "success",
      "most",
      "successful",
      "students",
      "internal",
      "motivation",
    ],
    words: [
      "what",
      "drives",
      "success",
      "and",
      "the",
      "most",
      "successful",
      "students",
      "is",
      "internal",
      "motivation",
    ],
    id: "What drives success, and the most successful students, is internal motivation.",
    author: "Vivienne Ming",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["clock", "talked", "loud", "i", "threw", "away", "scared"],
    words: ["the", "clock", "talked", "loud", "i", "threw", "it", "away", "scared", "me", "what"],
    id: "The clock talked loud. I threw it away, it scared me what it talked.",
    author: "Tillie Olsen",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "guy", "isn't", "rowing", "time", "rock", "boat"],
    words: ["only", "the", "guy", "who", "isn't", "rowing", "has", "time", "to", "rock", "boat"],
    id: "Only the guy who isn't rowing has time to rock the boat.",
    author: "Jean-Paul Sartre",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "lends", "helping", "hand", "man", "tries", "hard"],
    words: ["god", "lends", "a", "helping", "hand", "to", "the", "man", "who", "tries", "hard"],
    id: "God lends a helping hand to the man who tries hard.",
    author: "Aeschylus",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["will", "persevere", "often", "difference", "between", "failure", "success"],
    words: [
      "the",
      "will",
      "to",
      "persevere",
      "is",
      "often",
      "difference",
      "between",
      "failure",
      "and",
      "success",
    ],
    id: "The will to persevere is often the difference between failure and success.",
    author: "David Sarnoff",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["hardest", "years", "life", "those", "between", "ten", "seventy"],
    words: [
      "the",
      "hardest",
      "years",
      "in",
      "life",
      "are",
      "those",
      "between",
      "ten",
      "and",
      "seventy",
    ],
    id: "The hardest years in life are those between ten and seventy.",
    author: "Helen Hayes",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "freedom",
      "responsibility",
      "aren't",
      "interconnected",
      "things",
      "same",
      "thing",
    ],
    words: [
      "freedom",
      "and",
      "responsibility",
      "aren't",
      "interconnected",
      "things",
      "they",
      "are",
      "the",
      "same",
      "thing",
    ],
    id: "Freedom and responsibility aren't interconnected things. They are the same thing.",
    author: "Harry Browne",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["profit", "from", "advice", "requires", "more", "wisdom", "than"],
    words: [
      "to",
      "profit",
      "from",
      "good",
      "advice",
      "requires",
      "more",
      "wisdom",
      "than",
      "give",
      "it",
    ],
    id: "To profit from good advice requires more wisdom than to give it.",
    author: "Wilson Mizner",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "made", "up", "sobs", "sniffles", "smiles", "predominating"],
    words: [
      "life",
      "is",
      "made",
      "up",
      "of",
      "sobs",
      "sniffles",
      "and",
      "smiles",
      "with",
      "predominating",
    ],
    id: "Life is made up of sobs, sniffles, and smiles, with sniffles predominating.",
    author: "O. Henry",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "hard", "staying", "happily", "married", "doing", "movies"],
    words: [
      "it's",
      "just",
      "as",
      "hard",
      "staying",
      "happily",
      "married",
      "it",
      "is",
      "doing",
      "movies",
    ],
    id: "It's just as hard... staying happily married as it is doing movies.",
    author: "Tom Hanks",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["first", "time", "i", "jogger", "smiling", "i'll", "consider"],
    words: ["the", "first", "time", "i", "see", "a", "jogger", "smiling", "i'll", "consider", "it"],
    id: "The first time I see a jogger smiling, I'll consider it.",
    author: "Joan Rivers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["being", "human", "lot", "things", "at", "same", "time"],
    words: ["being", "human", "is", "a", "lot", "of", "things", "at", "the", "same", "time"],
    id: "Being human is being a lot of things at the same time.",
    author: "Matthias Schoenaerts",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "management", "requires", "as", "fast", "world", "changing"],
    words: [
      "success",
      "in",
      "management",
      "requires",
      "learning",
      "as",
      "fast",
      "the",
      "world",
      "is",
      "changing",
    ],
    id: "Success in management requires learning as fast as the world is changing.",
    author: "Warren Bennis",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["sometimes", "time", "away", "disconnect", "from", "social", "media"],
    words: [
      "sometimes",
      "you",
      "need",
      "that",
      "time",
      "away",
      "to",
      "disconnect",
      "from",
      "social",
      "media",
    ],
    id: "Sometimes you need that time away to disconnect from social media.",
    author: "Skai Jackson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["truest", "greatness", "lies", "being", "kind", "wisdom", "mind"],
    words: [
      "the",
      "truest",
      "greatness",
      "lies",
      "in",
      "being",
      "kind",
      "wisdom",
      "a",
      "happy",
      "mind",
    ],
    id: "The truest greatness lies in being kind, the truest wisdom in a happy mind.",
    author: "Ella Wheeler Wilcox",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["music", "movies", "all", "dissonance", "consonance", "tension", "release"],
    words: [
      "music",
      "in",
      "movies",
      "is",
      "all",
      "about",
      "dissonance",
      "and",
      "consonance",
      "tension",
      "release",
    ],
    id: "Music in movies is all about dissonance and consonance, tension and release.",
    author: "Quincy Jones",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["tell", "truth", "hard", "come", "dinner", "on", "time"],
    words: ["tell", "the", "truth", "work", "hard", "and", "come", "to", "dinner", "on", "time"],
    id: "Tell the truth, work hard, and come to dinner on time.",
    author: "Gerald R. Ford",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgiveness", "takes", "time", "last", "step", "grieving", "process"],
    words: [
      "forgiveness",
      "takes",
      "time",
      "it",
      "is",
      "the",
      "last",
      "step",
      "of",
      "grieving",
      "process",
    ],
    id: "Forgiveness takes time. It is the last step of the grieving process.",
    author: "Elin Nordegren",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["whatever", "our", "souls", "made", "his", "mine", "same"],
    words: ["whatever", "our", "souls", "are", "made", "of", "his", "and", "mine", "the", "same"],
    id: "Whatever our souls are made of, his and mine are the same.",
    author: "Emily Bronte",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "such", "thing", "as", "freedom", "then", "fate"],
    words: ["if", "there", "is", "such", "a", "thing", "as", "freedom", "then", "no", "fate"],
    id: "If there is such a thing as freedom, then there is no fate.",
    author: "Imre Kertesz",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "long", "raised", "voice", "howl", "rage", "or"],
    words: ["i", "long", "for", "the", "raised", "voice", "howl", "of", "rage", "or", "love"],
    id: "I long for the raised voice, the howl of rage or love.",
    author: "Leslie Fiedler",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "its", "limits", "take", "too", "far", "cowardice"],
    words: [
      "patience",
      "has",
      "its",
      "limits",
      "take",
      "it",
      "too",
      "far",
      "and",
      "it's",
      "cowardice",
    ],
    id: "Patience has its limits, take it too far and it's cowardice.",
    author: "Holbrook Jackson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "balanced",
      "organization",
      "towards",
      "common",
      "objective",
      "there",
      "success",
    ],
    words: [
      "in",
      "a",
      "balanced",
      "organization",
      "working",
      "towards",
      "common",
      "objective",
      "there",
      "is",
      "success",
    ],
    id: "In a balanced organization, working towards a common objective, there is success.",
    author: "Arthur Helps",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "editing", "one", "my", "favorite", "parts", "filmmaking"],
    words: [
      "i",
      "love",
      "editing",
      "it's",
      "one",
      "of",
      "my",
      "favorite",
      "parts",
      "about",
      "filmmaking",
    ],
    id: "I love editing. It's one of my favorite parts about filmmaking.",
    author: "Steven Spielberg",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "its", "limits", "take", "too", "far", "cowardice"],
    words: [
      "patience",
      "has",
      "its",
      "limits",
      "take",
      "it",
      "too",
      "far",
      "and",
      "it's",
      "cowardice",
    ],
    id: "Patience has its limits. Take it too far, and it's cowardice.",
    author: "George Jackson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "affair", "i", "ever", "had", "was", "music"],
    words: ["the", "only", "love", "affair", "i", "have", "ever", "had", "was", "with", "music"],
    id: "The only love affair I have ever had was with music.",
    author: "Maurice Ravel",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["meekness", "uncommon", "patience", "planning", "revenge", "worth", "while"],
    words: [
      "meekness",
      "uncommon",
      "patience",
      "in",
      "planning",
      "a",
      "revenge",
      "that",
      "is",
      "worth",
      "while",
    ],
    id: "Meekness: Uncommon patience in planning a revenge that is worth while.",
    author: "Ambrose Bierce",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["why", "i", "never", "became", "director", "had", "patience"],
    words: [
      "that's",
      "why",
      "i",
      "never",
      "became",
      "a",
      "director",
      "had",
      "patience",
      "with",
      "people",
    ],
    id: "That's why I never became a director. I never had patience with people.",
    author: "Ray Harryhausen",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "when", "underestimate", "then", "become", "pleasantly", "surprised"],
    words: [
      "i",
      "love",
      "when",
      "people",
      "underestimate",
      "me",
      "and",
      "then",
      "become",
      "pleasantly",
      "surprised",
    ],
    id: "I love when people underestimate me and then become pleasantly surprised.",
    author: "Kim Kardashian",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "constant", "oscillation", "between", "sharp", "horns", "dilemmas"],
    words: [
      "life",
      "is",
      "a",
      "constant",
      "oscillation",
      "between",
      "the",
      "sharp",
      "horns",
      "of",
      "dilemmas",
    ],
    id: "Life is a constant oscillation between the sharp horns of dilemmas.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "really", "healthy", "i'm", "tired", "take", "nap"],
    words: ["i", "eat", "really", "healthy", "and", "if", "i'm", "tired", "take", "a", "nap"],
    id: "I eat really healthy, and if I'm tired, I take a nap.",
    author: "Casper Van Dien",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["production", "too", "many", "useful", "things", "results", "useless"],
    words: [
      "the",
      "production",
      "of",
      "too",
      "many",
      "useful",
      "things",
      "results",
      "in",
      "useless",
      "people",
    ],
    id: "The production of too many useful things results in too many useless people.",
    author: "Karl Marx",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["follow", "without", "halt", "one", "aim", "secret", "success"],
    words: [
      "to",
      "follow",
      "without",
      "halt",
      "one",
      "aim",
      "that's",
      "the",
      "secret",
      "of",
      "success",
    ],
    id: "To follow, without halt, one aim: that's the secret of success.",
    author: "Anna Pavlova",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "timid",
      "change",
      "frightening",
      "comfortable",
      "threatening",
      "confident",
      "opportunity",
    ],
    words: [
      "for",
      "the",
      "timid",
      "change",
      "is",
      "frightening",
      "comfortable",
      "threatening",
      "but",
      "confident",
      "opportunity",
    ],
    id: "For the timid, change is frightening; for the comfortable, change is threatening; but for the confident, change is opportunity.",
    author: "Nido Qubein",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "such", "thing", "as", "fun", "whole", "family"],
    words: ["there", "is", "no", "such", "thing", "as", "fun", "for", "the", "whole", "family"],
    id: "There is no such thing as fun for the whole family.",
    author: "Jerry Seinfeld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["disobedience", "true", "foundation", "liberty", "obedient", "must", "slaves"],
    words: [
      "disobedience",
      "is",
      "the",
      "true",
      "foundation",
      "of",
      "liberty",
      "obedient",
      "must",
      "be",
      "slaves",
    ],
    id: "Disobedience is the true foundation of liberty. The obedient must be slaves.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "been", "as", "sincere", "worshipper", "aurora", "greeks"],
    words: [
      "i",
      "have",
      "been",
      "as",
      "sincere",
      "a",
      "worshipper",
      "of",
      "aurora",
      "the",
      "greeks",
    ],
    id: "I have been as sincere a worshipper of Aurora as the Greeks.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["nothing", "particularly", "hard", "divide", "into", "small", "jobs"],
    words: [
      "nothing",
      "is",
      "particularly",
      "hard",
      "if",
      "you",
      "divide",
      "it",
      "into",
      "small",
      "jobs",
    ],
    id: "Nothing is particularly hard if you divide it into small jobs.",
    author: "Henry Ford",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "ambition", "contribute", "quota", "overall", "success", "team"],
    words: [
      "my",
      "ambition",
      "is",
      "to",
      "contribute",
      "quota",
      "the",
      "overall",
      "success",
      "of",
      "team",
    ],
    id: "My ambition is to contribute my quota to the overall success of the team.",
    author: "Victor Moses",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["pursuit", "truth", "permit", "violence", "on", "one's", "opponent"],
    words: [
      "the",
      "pursuit",
      "of",
      "truth",
      "does",
      "not",
      "permit",
      "violence",
      "on",
      "one's",
      "opponent",
    ],
    id: "The pursuit of truth does not permit violence on one's opponent.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["without", "way", "there", "truth", "knowing", "life", "living"],
    words: [
      "without",
      "the",
      "way",
      "there",
      "is",
      "no",
      "going",
      "truth",
      "knowing",
      "life",
      "living",
    ],
    id: "Without the way, there is no going; without the truth, there is no knowing; without the life, there is no living.",
    author: "Thomas a Kempis",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "rock", "singer", "one-night", "stands", "on", "road"],
    words: [
      "i'm",
      "like",
      "a",
      "rock",
      "singer",
      "with",
      "one-night",
      "stands",
      "on",
      "the",
      "road",
    ],
    id: "I'm like a rock singer with one-night stands on the road.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["how", "any", "man", "weak", "dares", "at", "all"],
    words: ["how", "can", "any", "man", "be", "weak", "who", "dares", "to", "at", "all"],
    id: "How can any man be weak who dares to be at all?",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["at", "chrysler", "borrow", "old-fashioned", "way", "pay", "back"],
    words: [
      "we",
      "at",
      "chrysler",
      "borrow",
      "money",
      "the",
      "old-fashioned",
      "way",
      "pay",
      "it",
      "back",
    ],
    id: "We at Chrysler borrow money the old-fashioned way. We pay it back.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "paid", "our", "suspicion", "by", "finding", "suspect"],
    words: [
      "we",
      "are",
      "always",
      "paid",
      "for",
      "our",
      "suspicion",
      "by",
      "finding",
      "what",
      "suspect",
    ],
    id: "We are always paid for our suspicion by finding what we suspect.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "one", "most", "important", "things", "evolving", "forgiveness"],
    words: [
      "i",
      "think",
      "one",
      "of",
      "the",
      "most",
      "important",
      "things",
      "in",
      "evolving",
      "is",
      "forgiveness",
    ],
    id: "I think one of the most important things in evolving is forgiveness.",
    author: "Damon Dash",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "doing",
      "documentary",
      "discovering",
      "being",
      "open",
      "following",
      "curiosity",
    ],
    words: [
      "doing",
      "a",
      "documentary",
      "is",
      "about",
      "discovering",
      "being",
      "open",
      "learning",
      "and",
      "following",
      "curiosity",
    ],
    id: "Doing a documentary is about discovering, being open, learning, and following curiosity.",
    author: "Spike Jonze",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "feel", "much", "freer", "certain", "pope", "antichrist"],
    words: [
      "i",
      "feel",
      "much",
      "freer",
      "now",
      "that",
      "am",
      "certain",
      "the",
      "pope",
      "is",
      "antichrist",
    ],
    id: "I feel much freer now that I am certain the pope is the Antichrist.",
    author: "Martin Luther",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["effects", "climate", "change", "real", "must", "acted", "on"],
    words: [
      "the",
      "effects",
      "of",
      "climate",
      "change",
      "are",
      "real",
      "and",
      "must",
      "be",
      "acted",
      "on",
    ],
    id: "The effects of climate change are real and must be acted on.",
    author: "Joe Biden",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["sleep", "riches", "health", "truly", "enjoyed", "must", "interrupted"],
    words: [
      "for",
      "sleep",
      "riches",
      "and",
      "health",
      "to",
      "be",
      "truly",
      "enjoyed",
      "they",
      "must",
      "interrupted",
    ],
    id: "For sleep, riches and health to be truly enjoyed, they must be interrupted.",
    author: "Jean Paul",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["ordinary", "folk", "chance", "same", "things", "as", "rich"],
    words: [
      "give",
      "ordinary",
      "folk",
      "the",
      "chance",
      "to",
      "buy",
      "same",
      "things",
      "as",
      "rich",
      "people",
    ],
    id: "Give ordinary folk the chance to buy the same things as rich people.",
    author: "Sam Walton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["since", "everything", "our", "heads", "had", "better", "lose"],
    words: [
      "since",
      "everything",
      "is",
      "in",
      "our",
      "heads",
      "we",
      "had",
      "better",
      "not",
      "lose",
      "them",
    ],
    id: "Since everything is in our heads, we had better not lose them.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["power", "man", "grown", "sphere", "except", "over", "himself"],
    words: [
      "the",
      "power",
      "of",
      "man",
      "has",
      "grown",
      "in",
      "every",
      "sphere",
      "except",
      "over",
      "himself",
    ],
    id: "The power of man has grown in every sphere, except over himself.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "happiness", "one", "less", "fortunate", "than", "yourself"],
    words: [
      "do",
      "not",
      "speak",
      "of",
      "your",
      "happiness",
      "to",
      "one",
      "less",
      "fortunate",
      "than",
      "yourself",
    ],
    id: "Do not speak of your happiness to one less fortunate than yourself.",
    author: "Plutarch",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["difference", "taste", "jokes", "great", "strain", "on", "affections"],
    words: [
      "a",
      "difference",
      "of",
      "taste",
      "in",
      "jokes",
      "is",
      "great",
      "strain",
      "on",
      "the",
      "affections",
    ],
    id: "A difference of taste in jokes is a great strain on the affections.",
    author: "George Eliot",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "democracy",
      "pathetic",
      "belief",
      "collective",
      "wisdom",
      "individual",
      "ignorance",
    ],
    words: [
      "democracy",
      "is",
      "a",
      "pathetic",
      "belief",
      "in",
      "the",
      "collective",
      "wisdom",
      "of",
      "individual",
      "ignorance",
    ],
    id: "Democracy is a pathetic belief in the collective wisdom of individual ignorance.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "courage", "danger", "life", "fear", "its", "safeguard"],
    words: [
      "just",
      "as",
      "courage",
      "is",
      "the",
      "danger",
      "of",
      "life",
      "so",
      "fear",
      "its",
      "safeguard",
    ],
    id: "Just as courage is the danger of life, so is fear its safeguard.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "next", "michael", "jordan", "only", "kobe", "bryant"],
    words: [
      "i",
      "don't",
      "want",
      "to",
      "be",
      "the",
      "next",
      "michael",
      "jordan",
      "only",
      "kobe",
      "bryant",
    ],
    id: "I don't want to be the next Michael Jordan, I only want to be Kobe Bryant.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["philosophy", "room", "one", "generation", "will", "government", "next"],
    words: [
      "the",
      "philosophy",
      "of",
      "school",
      "room",
      "in",
      "one",
      "generation",
      "will",
      "be",
      "government",
      "next",
    ],
    id: "The philosophy of the school room in one generation will be the philosophy of government in the next.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "ideas", "must", "as", "broad", "nature", "interpret"],
    words: [
      "our",
      "ideas",
      "must",
      "be",
      "as",
      "broad",
      "nature",
      "if",
      "they",
      "are",
      "to",
      "interpret",
    ],
    id: "Our ideas must be as broad as Nature if they are to interpret Nature.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["live", "startling", "leaves", "little", "time", "anything", "else"],
    words: [
      "to",
      "live",
      "is",
      "so",
      "startling",
      "it",
      "leaves",
      "little",
      "time",
      "for",
      "anything",
      "else",
    ],
    id: "To live is so startling it leaves little time for anything else.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["illegal", "rock", "roll", "throw", "my", "ass", "jail"],
    words: [
      "if",
      "it's",
      "illegal",
      "to",
      "rock",
      "and",
      "roll",
      "throw",
      "my",
      "ass",
      "in",
      "jail",
    ],
    id: "If it's illegal to rock and roll, throw my ass in jail!",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "compassion",
      "necessities",
      "luxuries",
      "without",
      "humanity",
      "cannot",
      "survive",
    ],
    words: [
      "love",
      "and",
      "compassion",
      "are",
      "necessities",
      "not",
      "luxuries",
      "without",
      "them",
      "humanity",
      "cannot",
      "survive",
    ],
    id: "Love and compassion are necessities, not luxuries. Without them humanity cannot survive.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "wrote", "scenarios", "was", "through", "then", "disguised"],
    words: [
      "i",
      "wrote",
      "about",
      "scenarios",
      "that",
      "was",
      "going",
      "through",
      "and",
      "then",
      "disguised",
      "them",
    ],
    id: "I wrote about scenarios that I was going through and then disguised them.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "liberal", "america", "conservative", "-", "united", "states"],
    words: [
      "there's",
      "not",
      "a",
      "liberal",
      "america",
      "and",
      "conservative",
      "-",
      "the",
      "united",
      "states",
      "of",
    ],
    id: "There's not a liberal America and a conservative America - there's the United States of America.",
    author: "Barack Obama",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "shouldn't", "even", "here", "i'm", "better", "something"],
    words: [
      "i",
      "shouldn't",
      "even",
      "be",
      "here",
      "so",
      "if",
      "i'm",
      "better",
      "do",
      "something",
      "good",
    ],
    id: "I shouldn't even be here, so if I'm here, I better do something good.",
    author: "Tammy Duckworth",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["vitality", "shows", "only", "ability", "persist", "start", "over"],
    words: [
      "vitality",
      "shows",
      "in",
      "not",
      "only",
      "the",
      "ability",
      "to",
      "persist",
      "but",
      "start",
      "over",
    ],
    id: "Vitality shows in not only the ability to persist but the ability to start over.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["actually", "refer", "as", "research", "nowadays", "really", "googling"],
    words: [
      "what",
      "people",
      "actually",
      "refer",
      "to",
      "as",
      "research",
      "nowadays",
      "is",
      "really",
      "just",
      "googling",
    ],
    id: "What people actually refer to as research nowadays is really just Googling.",
    author: "Dermot Mulroney",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["cube", "at", "same", "time", "symbol", "simplicity", "complexity"],
    words: [
      "the",
      "cube",
      "is",
      "at",
      "same",
      "time",
      "a",
      "symbol",
      "of",
      "simplicity",
      "and",
      "complexity",
    ],
    id: "The Cube is, at the same time, a symbol of simplicity and complexity.",
    author: "Erno Rubik",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["trusty", "comrade", "always", "use", "chronicler", "still", "more"],
    words: [
      "a",
      "trusty",
      "comrade",
      "is",
      "always",
      "of",
      "use",
      "and",
      "chronicler",
      "still",
      "more",
      "so",
    ],
    id: "A trusty comrade is always of use; and a chronicler still more so.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["common", "sense", "uncommon", "degree", "world", "calls", "wisdom"],
    words: [
      "common",
      "sense",
      "in",
      "an",
      "uncommon",
      "degree",
      "is",
      "what",
      "the",
      "world",
      "calls",
      "wisdom",
    ],
    id: "Common sense in an uncommon degree is what the world calls wisdom.",
    author: "Samuel Taylor Coleridge",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["best", "way", "find", "yourself", "lose", "service", "others"],
    words: [
      "the",
      "best",
      "way",
      "to",
      "find",
      "yourself",
      "is",
      "lose",
      "in",
      "service",
      "of",
      "others",
    ],
    id: "The best way to find yourself is to lose yourself in the service of others.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["generosity", "giving", "more", "than", "pride", "taking", "less"],
    words: [
      "generosity",
      "is",
      "giving",
      "more",
      "than",
      "you",
      "can",
      "and",
      "pride",
      "taking",
      "less",
      "need",
    ],
    id: "Generosity is giving more than you can, and pride is taking less than you need.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["nothing", "given", "away", "will", "ever", "really", "yours"],
    words: [
      "nothing",
      "that",
      "you",
      "have",
      "not",
      "given",
      "away",
      "will",
      "ever",
      "be",
      "really",
      "yours",
    ],
    id: "Nothing that you have not given away will ever be really yours.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["corporation", "there", "only", "one", "guy", "end", "ceo"],
    words: [
      "in",
      "a",
      "corporation",
      "there",
      "can",
      "only",
      "be",
      "one",
      "guy",
      "the",
      "end",
      "ceo",
    ],
    id: "In a corporation, there can only be one guy in the end: the CEO.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "as", "writer", "i", "only", "photograph", "words"],
    words: [
      "in",
      "my",
      "work",
      "as",
      "a",
      "writer",
      "i",
      "only",
      "photograph",
      "words",
      "what",
      "see",
    ],
    id: "In my work, as a writer, I only photograph, in words, what I see.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["leaves", "nothing", "chance", "will", "few", "things", "poorly"],
    words: [
      "he",
      "who",
      "leaves",
      "nothing",
      "to",
      "chance",
      "will",
      "do",
      "few",
      "things",
      "poorly",
      "but",
    ],
    id: "He who leaves nothing to chance will do few things poorly, but he will do few things.",
    author: "Edward Wood, 1st Earl of Halifax",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["nearer", "loving", "those", "hate", "than", "more", "wish"],
    words: [
      "we",
      "are",
      "nearer",
      "loving",
      "those",
      "who",
      "hate",
      "us",
      "than",
      "love",
      "more",
      "wish",
    ],
    id: "We are nearer loving those who hate us than those who love us more than we wish.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["doomed", "cling", "life", "even", "while", "find", "unendurable"],
    words: [
      "we",
      "are",
      "doomed",
      "to",
      "cling",
      "a",
      "life",
      "even",
      "while",
      "find",
      "it",
      "unendurable",
    ],
    id: "We are doomed to cling to a life even while we find it unendurable.",
    author: "William James",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "one", "which", "accordance", "its", "own", "nature"],
    words: [
      "a",
      "happy",
      "life",
      "is",
      "one",
      "which",
      "in",
      "accordance",
      "with",
      "its",
      "own",
      "nature",
    ],
    id: "A happy life is one which is in accordance with its own nature.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "failed", "i've", "found", "10000", "ways", "won't"],
    words: [
      "i",
      "have",
      "not",
      "failed",
      "i've",
      "just",
      "found",
      "10000",
      "ways",
      "that",
      "won't",
      "work",
    ],
    id: "I have not failed. I've just found 10,000 ways that won't work.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["kind", "i", "won", "gold", "medal", "my", "family"],
    words: ["it's", "kind", "of", "like", "i", "won", "a", "gold", "medal", "with", "my", "family"],
    id: "It's kind of like I won a gold medal with my family.",
    author: "Laurie Hernandez",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["universe", "indifferent", "our", "existence", "-", "depends", "on"],
    words: [
      "the",
      "universe",
      "is",
      "not",
      "indifferent",
      "to",
      "our",
      "existence",
      "-",
      "it",
      "depends",
      "on",
    ],
    id: "The universe is not indifferent to our existence - it depends on it.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["architecture", "should", "its", "time", "place", "yearn", "timelessness"],
    words: [
      "architecture",
      "should",
      "speak",
      "of",
      "its",
      "time",
      "and",
      "place",
      "but",
      "yearn",
      "for",
      "timelessness",
    ],
    id: "Architecture should speak of its time and place, but yearn for timelessness.",
    author: "Frank Gehry",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["driving", "up", "value", "advertising", "big", "commitment", "microsoft"],
    words: [
      "driving",
      "up",
      "the",
      "value",
      "of",
      "advertising",
      "is",
      "a",
      "big",
      "commitment",
      "for",
      "microsoft",
    ],
    id: "Driving up the value of the advertising is a big commitment for Microsoft.",
    author: "Bill Gates",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["first", "time", "i", "something", "i'll", "probably", "at"],
    words: [
      "the",
      "first",
      "time",
      "i",
      "do",
      "something",
      "i'll",
      "probably",
      "be",
      "good",
      "at",
      "it",
    ],
    id: "The first time I do something, I'll probably be good at it.",
    author: "King Von",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["more", "unintelligent", "man", "less", "mysterious", "existence", "seems"],
    words: [
      "the",
      "more",
      "unintelligent",
      "a",
      "man",
      "is",
      "less",
      "mysterious",
      "existence",
      "seems",
      "to",
      "him",
    ],
    id: "The more unintelligent a man is, the less mysterious existence seems to him.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["wherever", "art", "medicine", "loved", "there", "also", "humanity"],
    words: [
      "wherever",
      "the",
      "art",
      "of",
      "medicine",
      "is",
      "loved",
      "there",
      "also",
      "a",
      "love",
      "humanity",
    ],
    id: "Wherever the art of medicine is loved, there is also a love of humanity.",
    author: "Hippocrates",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["destiny", "designed", "by", "mere", "mortals", "crafted", "god"],
    words: [
      "destiny",
      "is",
      "not",
      "designed",
      "by",
      "mere",
      "mortals",
      "like",
      "us",
      "it",
      "crafted",
      "god",
    ],
    id: "Destiny is not designed by mere mortals like us. It is crafted by God.",
    author: "Rodrigo Duterte",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "mostly", "by-product", "doing", "makes", "feel", "fulfilled"],
    words: [
      "happiness",
      "is",
      "mostly",
      "a",
      "by-product",
      "of",
      "doing",
      "what",
      "makes",
      "us",
      "feel",
      "fulfilled",
    ],
    id: "Happiness is mostly a by-product of doing what makes us feel fulfilled.",
    author: "Benjamin Spock",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["make", "out", "quarrel", "others", "rhetoric", "ourselves", "poetry"],
    words: [
      "we",
      "make",
      "out",
      "of",
      "the",
      "quarrel",
      "with",
      "others",
      "rhetoric",
      "but",
      "ourselves",
      "poetry",
    ],
    id: "We make out of the quarrel with others, rhetoric, but of the quarrel with ourselves, poetry.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["history", "past", "one", "long", "struggle", "upward", "equality"],
    words: [
      "the",
      "history",
      "of",
      "past",
      "is",
      "but",
      "one",
      "long",
      "struggle",
      "upward",
      "to",
      "equality",
    ],
    id: "The history of the past is but one long struggle upward to equality.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["progress", "nice", "word", "change", "its", "motivator", "enemies"],
    words: [
      "progress",
      "is",
      "a",
      "nice",
      "word",
      "but",
      "change",
      "its",
      "motivator",
      "and",
      "has",
      "enemies",
    ],
    id: "Progress is a nice word. But change is its motivator. And change has its enemies.",
    author: "Robert Kennedy",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["march", "15th", "most", "important", "year", "my", "birthday"],
    words: [
      "march",
      "15th",
      "is",
      "the",
      "most",
      "important",
      "day",
      "of",
      "year",
      "it's",
      "my",
      "birthday",
    ],
    id: "March 15th is the most important day of the year. It's my birthday.",
    author: "Maxwell Jacob Friedman",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "thing", "modeling", "taught", "spotlight", "change", "everything"],
    words: [
      "one",
      "thing",
      "modeling",
      "taught",
      "me",
      "is",
      "that",
      "the",
      "spotlight",
      "can",
      "change",
      "everything",
    ],
    id: "One thing modeling taught me is that the spotlight can change everything.",
    author: "Liya Kebede",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["clear", "gain", "sacrifice", "pleasure", "order", "avoid", "pain"],
    words: [
      "it",
      "is",
      "a",
      "clear",
      "gain",
      "to",
      "sacrifice",
      "pleasure",
      "in",
      "order",
      "avoid",
      "pain",
    ],
    id: "It is a clear gain to sacrifice pleasure in order to avoid pain.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "unhappy", "sleep", "badly", "always", "proud", "fact"],
    words: [
      "men",
      "who",
      "are",
      "unhappy",
      "like",
      "sleep",
      "badly",
      "always",
      "proud",
      "of",
      "the",
      "fact",
    ],
    id: "Men who are unhappy, like men who sleep badly, are always proud of the fact.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "journalism",
      "maintains",
      "democracy",
      "force",
      "progressive",
      "social",
      "change",
    ],
    words: [
      "journalism",
      "is",
      "what",
      "maintains",
      "democracy",
      "it's",
      "the",
      "force",
      "for",
      "progressive",
      "social",
      "change",
    ],
    id: "Journalism is what maintains democracy. It's the force for progressive social change.",
    author: "Andrew Vachss",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["neither", "comprehension", "nor", "take", "place", "atmosphere", "anxiety"],
    words: [
      "neither",
      "comprehension",
      "nor",
      "learning",
      "can",
      "take",
      "place",
      "in",
      "an",
      "atmosphere",
      "of",
      "anxiety",
    ],
    id: "Neither comprehension nor learning can take place in an atmosphere of anxiety.",
    author: "Rose Kennedy",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["stupid", "neither", "forgive", "nor", "forget", "naive", "wise"],
    words: [
      "the",
      "stupid",
      "neither",
      "forgive",
      "nor",
      "forget",
      "naive",
      "and",
      "wise",
      "but",
      "do",
      "not",
    ],
    id: "The stupid neither forgive nor forget; the naive forgive and forget; the wise forgive but do not forget.",
    author: "Thomas Szasz",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["truth", "stands", "even", "there", "public", "support", "self-sustained"],
    words: [
      "truth",
      "stands",
      "even",
      "if",
      "there",
      "be",
      "no",
      "public",
      "support",
      "it",
      "is",
      "self-sustained",
    ],
    id: "Truth stands, even if there be no public support. It is self-sustained.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["asking", "forgiveness", "one", "most", "painful", "kind", "experiences"],
    words: [
      "asking",
      "for",
      "forgiveness",
      "is",
      "just",
      "one",
      "of",
      "the",
      "most",
      "painful",
      "kind",
      "experiences",
    ],
    id: "Asking for forgiveness is just one of the most painful kind of experiences.",
    author: "Nick Nolte",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["chance", "happens", "all", "turn", "account", "gift", "few"],
    words: [
      "chance",
      "happens",
      "to",
      "all",
      "but",
      "turn",
      "account",
      "is",
      "the",
      "gift",
      "of",
      "few",
    ],
    id: "Chance happens to all, but to turn chance to account is the gift of few.",
    author: "Edward G. Bulwer-Lytton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["sword", "never", "kills", "anybody", "tool", "killer's", "hand"],
    words: [
      "a",
      "sword",
      "never",
      "kills",
      "anybody",
      "it",
      "is",
      "tool",
      "in",
      "the",
      "killer's",
      "hand",
    ],
    id: "A sword never kills anybody; it is a tool in the killer's hand.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "play", "guy", "sometimes", "you've", "got", "villain"],
    words: [
      "if",
      "you",
      "can't",
      "play",
      "the",
      "good",
      "guy",
      "sometimes",
      "you've",
      "got",
      "to",
      "villain",
    ],
    id: "If you can't play the good guy, sometimes you've got to play the villain.",
    author: "Ben Askren",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "most",
      "amazing",
      "philanthropists",
      "actually",
      "making",
      "significant",
      "sacrifice",
    ],
    words: [
      "the",
      "most",
      "amazing",
      "philanthropists",
      "are",
      "people",
      "who",
      "actually",
      "making",
      "a",
      "significant",
      "sacrifice",
    ],
    id: "The most amazing philanthropists are people who are actually making a significant sacrifice.",
    author: "Bill Gates",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "critic-proof", "i", "still", "take", "personally", "less"],
    words: [
      "i'm",
      "not",
      "critic-proof",
      "and",
      "i",
      "still",
      "take",
      "it",
      "personally",
      "but",
      "less",
      "now",
    ],
    id: "I'm not critic-proof, and I still take it personally, but I take it less personally now.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["crest", "crowning", "all", "life's", "final", "star", "brotherhood"],
    words: [
      "the",
      "crest",
      "and",
      "crowning",
      "of",
      "all",
      "good",
      "life's",
      "final",
      "star",
      "is",
      "brotherhood",
    ],
    id: "The crest and crowning of all good, Life's final star, is brotherhood.",
    author: "Edwin Markham",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["better", "deceived", "by", "one's", "friends", "than", "deceive"],
    words: [
      "it",
      "is",
      "better",
      "to",
      "be",
      "deceived",
      "by",
      "one's",
      "friends",
      "than",
      "deceive",
      "them",
    ],
    id: "It is better to be deceived by one's friends than to deceive them.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["cut", "all", "cannot", "keep", "spring", "from", "coming"],
    words: [
      "you",
      "can",
      "cut",
      "all",
      "the",
      "flowers",
      "but",
      "cannot",
      "keep",
      "spring",
      "from",
      "coming",
    ],
    id: "You can cut all the flowers but you cannot keep spring from coming.",
    author: "Pablo Neruda",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["plan", "violently", "executed", "better", "than", "perfect", "next"],
    words: [
      "a",
      "good",
      "plan",
      "violently",
      "executed",
      "now",
      "is",
      "better",
      "than",
      "perfect",
      "next",
      "week",
    ],
    id: "A good plan violently executed now is better than a perfect plan executed next week.",
    author: "George S. Patton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "parents", "examples", "christians", "big", "on", "forgiveness"],
    words: [
      "my",
      "parents",
      "are",
      "very",
      "good",
      "examples",
      "of",
      "christians",
      "they",
      "big",
      "on",
      "forgiveness",
    ],
    id: "My parents are very good examples of Christians. They are big on forgiveness.",
    author: "James Acaster",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["whatever", "your", "heart", "clings", "confides", "really", "god"],
    words: [
      "whatever",
      "your",
      "heart",
      "clings",
      "to",
      "and",
      "confides",
      "in",
      "that",
      "is",
      "really",
      "god",
    ],
    id: "Whatever your heart clings to and confides in, that is really your God.",
    author: "Martin Luther",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["strange", "desire", "should", "many", "years", "outlive", "performance"],
    words: [
      "is",
      "it",
      "not",
      "strange",
      "that",
      "desire",
      "should",
      "so",
      "many",
      "years",
      "outlive",
      "performance",
    ],
    id: "Is it not strange that desire should so many years outlive performance?",
    author: "William Shakespeare",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["believing", "progress", "mean", "any", "yet", "been", "made"],
    words: [
      "believing",
      "in",
      "progress",
      "does",
      "not",
      "mean",
      "that",
      "any",
      "has",
      "yet",
      "been",
      "made",
    ],
    id: "Believing in progress does not mean believing that any progress has yet been made.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["length", "man's", "outspread", "arms", "equal", "his", "height"],
    words: [
      "the",
      "length",
      "of",
      "a",
      "man's",
      "outspread",
      "arms",
      "is",
      "equal",
      "to",
      "his",
      "height",
    ],
    id: "The length of a man's outspread arms is equal to his height.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["requires", "wisdom", "understand", "music", "nothing", "audience", "deaf"],
    words: [
      "it",
      "requires",
      "wisdom",
      "to",
      "understand",
      "the",
      "music",
      "is",
      "nothing",
      "if",
      "audience",
      "deaf",
    ],
    id: "It requires wisdom to understand wisdom: the music is nothing if the audience is deaf.",
    author: "Walter Lippmann",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "talk", "sec", "never", "get", "chance", "rest"],
    words: [
      "when",
      "you",
      "talk",
      "about",
      "the",
      "sec",
      "never",
      "get",
      "a",
      "chance",
      "to",
      "rest",
    ],
    id: "When you talk about the SEC, you never get a chance to rest.",
    author: "Kirby Smart",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["whenever", "i", "past", "brings", "back", "many", "memories"],
    words: [
      "whenever",
      "i",
      "think",
      "of",
      "the",
      "past",
      "it",
      "brings",
      "back",
      "so",
      "many",
      "memories",
    ],
    id: "Whenever I think of the past, it brings back so many memories.",
    author: "Steven Wright",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["triumph", "instance", "beauty", "many", "souls", "must", "trampled"],
    words: [
      "for",
      "every",
      "moment",
      "of",
      "triumph",
      "instance",
      "beauty",
      "many",
      "souls",
      "must",
      "be",
      "trampled",
    ],
    id: "For every moment of triumph, for every instance of beauty, many souls must be trampled.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["dominion", "greater", "or", "less", "than", "over", "yourself"],
    words: [
      "you",
      "can",
      "have",
      "no",
      "dominion",
      "greater",
      "or",
      "less",
      "than",
      "that",
      "over",
      "yourself",
    ],
    id: "You can have no dominion greater or less than that over yourself.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "starts", "boiling", "foolish", "turn", "off", "heat"],
    words: [
      "when",
      "the",
      "water",
      "starts",
      "boiling",
      "it",
      "is",
      "foolish",
      "to",
      "turn",
      "off",
      "heat",
    ],
    id: "When the water starts boiling it is foolish to turn off the heat.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["led", "monasteries", "which", "became", "cradle", "academic", "guilds"],
    words: [
      "love",
      "of",
      "learning",
      "led",
      "to",
      "monasteries",
      "which",
      "became",
      "the",
      "cradle",
      "academic",
      "guilds",
    ],
    id: "Love of learning led to monasteries, which became the cradle of academic guilds.",
    author: "John Ortberg",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["battleline", "between", "evil", "runs", "through", "heart", "man"],
    words: [
      "the",
      "battleline",
      "between",
      "good",
      "and",
      "evil",
      "runs",
      "through",
      "heart",
      "of",
      "every",
      "man",
    ],
    id: "The battleline between good and evil runs through the heart of every man.",
    author: "Aleksandr Solzhenitsyn",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["despise", "death", "welcome", "nature", "wills", "all", "else"],
    words: [
      "despise",
      "not",
      "death",
      "but",
      "welcome",
      "it",
      "for",
      "nature",
      "wills",
      "like",
      "all",
      "else",
    ],
    id: "Despise not death, but welcome it, for nature wills it like all else.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgiveness", "any", "aspect", "something", "complex", "greatest", "tool"],
    words: [
      "forgiveness",
      "in",
      "any",
      "aspect",
      "of",
      "something",
      "that",
      "is",
      "complex",
      "the",
      "greatest",
      "tool",
    ],
    id: "Forgiveness in any aspect of something that is complex is the greatest tool.",
    author: "Kate Hudson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "afraid", "storms", "how", "sail", "my", "ship"],
    words: [
      "i'm",
      "not",
      "afraid",
      "of",
      "storms",
      "for",
      "learning",
      "how",
      "to",
      "sail",
      "my",
      "ship",
    ],
    id: "I'm not afraid of storms, for I'm learning how to sail my ship.",
    author: "Louisa May Alcott",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["kindness", "more", "important", "than", "wisdom", "recognition", "beginning"],
    words: [
      "kindness",
      "is",
      "more",
      "important",
      "than",
      "wisdom",
      "and",
      "the",
      "recognition",
      "of",
      "this",
      "beginning",
    ],
    id: "Kindness is more important than wisdom, and the recognition of this is the beginning of wisdom.",
    author: "Theodore Isaac Rubin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "chance", "i'd", "fight", "at", "185", "pounds"],
    words: ["if", "i", "have", "a", "chance", "i'd", "like", "to", "fight", "at", "185", "pounds"],
    id: "If I have a chance, I'd like to fight at 185 pounds.",
    author: "Lyoto Machida",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "experience", "maturity", "face", "things", "different", "ways"],
    words: [
      "time",
      "gives",
      "you",
      "experience",
      "and",
      "maturity",
      "to",
      "face",
      "things",
      "in",
      "different",
      "ways",
    ],
    id: "Time gives you experience and maturity to face things in different ways.",
    author: "Deco",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["physics", "only", "profession", "which", "prophecy", "accurate", "routine"],
    words: [
      "physics",
      "is",
      "the",
      "only",
      "profession",
      "in",
      "which",
      "prophecy",
      "not",
      "accurate",
      "but",
      "routine",
    ],
    id: "Physics is the only profession in which prophecy is not only accurate but routine.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "must",
      "accept",
      "responsibility",
      "your",
      "actions",
      "credit",
      "achievements",
    ],
    words: [
      "you",
      "must",
      "accept",
      "responsibility",
      "for",
      "your",
      "actions",
      "but",
      "not",
      "the",
      "credit",
      "achievements",
    ],
    id: "You must accept responsibility for your actions, but not the credit for your achievements.",
    author: "Denis Waitley",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["by", "nature", "all", "men", "equal", "liberty", "endowments"],
    words: [
      "by",
      "nature",
      "all",
      "men",
      "are",
      "equal",
      "in",
      "liberty",
      "but",
      "not",
      "other",
      "endowments",
    ],
    id: "By nature all men are equal in liberty, but not in other endowments.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["democracy", "also", "form", "worship", "jackals", "by", "jackasses"],
    words: [
      "democracy",
      "is",
      "also",
      "a",
      "form",
      "of",
      "worship",
      "it",
      "the",
      "jackals",
      "by",
      "jackasses",
    ],
    id: "Democracy is also a form of worship. It is the worship of Jackals by Jackasses.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["how", "sharper", "than", "serpent's", "tooth", "thankless", "child"],
    words: [
      "how",
      "sharper",
      "than",
      "a",
      "serpent's",
      "tooth",
      "it",
      "is",
      "to",
      "have",
      "thankless",
      "child",
    ],
    id: "How sharper than a serpent's tooth it is to have a thankless child!",
    author: "William Shakespeare",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["will", "never", "find", "time", "anything", "must", "make"],
    words: [
      "you",
      "will",
      "never",
      "find",
      "time",
      "for",
      "anything",
      "if",
      "want",
      "must",
      "make",
      "it",
    ],
    id: "You will never find time for anything. If you want time you must make it.",
    author: "Charles Buxton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["switzerland", "country", "few", "things", "begin", "many", "end"],
    words: [
      "switzerland",
      "is",
      "a",
      "country",
      "where",
      "very",
      "few",
      "things",
      "begin",
      "but",
      "many",
      "end",
    ],
    id: "Switzerland is a country where very few things begin, but many things end.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "mature",
      "composed",
      "sustaining",
      "celebration",
      "commitment",
      "companionship",
      "trust",
    ],
    words: [
      "mature",
      "love",
      "is",
      "composed",
      "and",
      "sustaining",
      "a",
      "celebration",
      "of",
      "commitment",
      "companionship",
      "trust",
    ],
    id: "Mature love is composed and sustaining; a celebration of commitment, companionship, and trust.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "whip", "any", "man", "world", "never", "peace"],
    words: [
      "when",
      "you",
      "can",
      "whip",
      "any",
      "man",
      "in",
      "the",
      "world",
      "never",
      "know",
      "peace",
    ],
    id: "When you can whip any man in the world, you never know peace.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["sun", "too", "penetrates", "into", "privies", "polluted", "by"],
    words: [
      "the",
      "sun",
      "too",
      "penetrates",
      "into",
      "privies",
      "but",
      "is",
      "not",
      "polluted",
      "by",
      "them",
    ],
    id: "The sun too penetrates into privies, but is not polluted by them.",
    author: "Diogenes",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["acting", "magical", "change", "your", "look", "attitude", "anyone"],
    words: [
      "acting",
      "is",
      "magical",
      "change",
      "your",
      "look",
      "and",
      "attitude",
      "you",
      "can",
      "be",
      "anyone",
    ],
    id: "Acting is magical. Change your look and your attitude, and you can be anyone.",
    author: "Alicia Witt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["had", "more", "hell", "pulpit", "would", "less", "pew"],
    words: [
      "if",
      "we",
      "had",
      "more",
      "hell",
      "in",
      "the",
      "pulpit",
      "would",
      "have",
      "less",
      "pew",
    ],
    id: "If we had more hell in the pulpit, we would have less hell in the pew.",
    author: "Billy Graham",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["simple", "act", "paying", "attention", "take", "long", "way"],
    words: [
      "the",
      "simple",
      "act",
      "of",
      "paying",
      "attention",
      "can",
      "take",
      "you",
      "a",
      "long",
      "way",
    ],
    id: "The simple act of paying attention can take you a long way.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "rock", "i", "stays", "steady", "institution", "family"],
    words: [
      "the",
      "only",
      "rock",
      "i",
      "know",
      "that",
      "stays",
      "steady",
      "institution",
      "works",
      "is",
      "family",
    ],
    id: "The only rock I know that stays steady, the only institution I know that works, is the family.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["whatever", "fortune", "raised", "height", "only", "cast", "down"],
    words: [
      "whatever",
      "fortune",
      "has",
      "raised",
      "to",
      "a",
      "height",
      "she",
      "only",
      "cast",
      "it",
      "down",
    ],
    id: "Whatever fortune has raised to a height, she has raised only to cast it down.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["rights", "flow", "from", "duty", "performed", "worth", "having"],
    words: [
      "rights",
      "that",
      "do",
      "not",
      "flow",
      "from",
      "duty",
      "well",
      "performed",
      "are",
      "worth",
      "having",
    ],
    id: "Rights that do not flow from duty well performed are not worth having.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "centuries",
      "theologians",
      "been",
      "explaining",
      "unknowable",
      "terms",
      "the-not-worth-knowing",
    ],
    words: [
      "for",
      "centuries",
      "theologians",
      "have",
      "been",
      "explaining",
      "the",
      "unknowable",
      "in",
      "terms",
      "of",
      "the-not-worth-knowing",
    ],
    id: "For centuries, theologians have been explaining the unknowable in terms of the-not-worth-knowing.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["after", "all", "greatest", "art", "limit", "isolate", "oneself"],
    words: [
      "it",
      "is",
      "after",
      "all",
      "the",
      "greatest",
      "art",
      "to",
      "limit",
      "and",
      "isolate",
      "oneself",
    ],
    id: "It is after all the greatest art to limit and isolate oneself.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["first", "step", "person's", "salvation", "knowledge", "their", "sin"],
    words: [
      "the",
      "first",
      "step",
      "in",
      "a",
      "person's",
      "salvation",
      "is",
      "knowledge",
      "of",
      "their",
      "sin",
    ],
    id: "The first step in a person's salvation is knowledge of their sin.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["energy", "which", "exists", "itself", "its", "own", "value"],
    words: [
      "love",
      "is",
      "an",
      "energy",
      "which",
      "exists",
      "of",
      "itself",
      "it",
      "its",
      "own",
      "value",
    ],
    id: "Love is an energy which exists of itself. It is its own value.",
    author: "Thornton Wilder",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["since", "thou", "sure", "minute", "throw", "away", "hour"],
    words: [
      "since",
      "thou",
      "are",
      "not",
      "sure",
      "of",
      "a",
      "minute",
      "throw",
      "away",
      "an",
      "hour",
    ],
    id: "Since thou are not sure of a minute, throw not away an hour.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["hunger", "much", "more", "difficult", "remove", "than", "bread"],
    words: [
      "the",
      "hunger",
      "for",
      "love",
      "is",
      "much",
      "more",
      "difficult",
      "to",
      "remove",
      "than",
      "bread",
    ],
    id: "The hunger for love is much more difficult to remove than the hunger for bread.",
    author: "Mother Teresa",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "i", "learned", "way", "live", "life", "changes"],
    words: [
      "just",
      "when",
      "i",
      "think",
      "have",
      "learned",
      "the",
      "way",
      "to",
      "live",
      "life",
      "changes",
    ],
    id: "Just when I think I have learned the way to live, life changes.",
    author: "Hugh Prather",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["boredom", "feeling", "everything", "waste", "time", "serenity", "nothing"],
    words: [
      "boredom",
      "is",
      "the",
      "feeling",
      "that",
      "everything",
      "a",
      "waste",
      "of",
      "time",
      "serenity",
      "nothing",
    ],
    id: "Boredom is the feeling that everything is a waste of time; serenity, that nothing is.",
    author: "Thomas Szasz",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["europe", "became", "rich", "because", "exploited", "africa", "africans"],
    words: [
      "europe",
      "became",
      "rich",
      "because",
      "it",
      "exploited",
      "africa",
      "and",
      "the",
      "africans",
      "know",
      "that",
    ],
    id: "Europe became rich because it exploited Africa; and the Africans know that.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["wise", "apply", "oil", "refined", "politeness", "mechanism", "friendship"],
    words: [
      "it",
      "is",
      "wise",
      "to",
      "apply",
      "the",
      "oil",
      "of",
      "refined",
      "politeness",
      "mechanism",
      "friendship",
    ],
    id: "It is wise to apply the oil of refined politeness to the mechanism of friendship.",
    author: "Sidonie Gabrielle Colette",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "sinners", "everybody", "meet", "over", "world", "sinner"],
    words: [
      "we're",
      "all",
      "sinners",
      "everybody",
      "you",
      "meet",
      "over",
      "the",
      "world",
      "is",
      "a",
      "sinner",
    ],
    id: "We're all sinners. Everybody you meet all over the world is a sinner.",
    author: "Billy Graham",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["walking", "friend", "dark", "better", "than", "alone", "light"],
    words: [
      "walking",
      "with",
      "a",
      "friend",
      "in",
      "the",
      "dark",
      "is",
      "better",
      "than",
      "alone",
      "light",
    ],
    id: "Walking with a friend in the dark is better than walking alone in the light.",
    author: "Helen Keller",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "life", "israel", "sometimes", "bigger", "than", "movies"],
    words: [
      "i",
      "think",
      "that",
      "life",
      "in",
      "israel",
      "is",
      "sometimes",
      "bigger",
      "than",
      "the",
      "movies",
    ],
    id: "I think that life in Israel is sometimes bigger than the movies.",
    author: "Yitzhak Rabin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["hardships", "i", "encountered", "past", "will", "succeed", "future"],
    words: [
      "the",
      "hardships",
      "that",
      "i",
      "encountered",
      "in",
      "past",
      "will",
      "help",
      "me",
      "succeed",
      "future",
    ],
    id: "The hardships that I encountered in the past will help me succeed in the future.",
    author: "Philip Emeagwali",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["empowering", "i", "doing", "something", "taking", "stand", "disrupting"],
    words: [
      "it's",
      "empowering",
      "to",
      "know",
      "i",
      "am",
      "doing",
      "something",
      "taking",
      "a",
      "stand",
      "disrupting",
    ],
    id: "It's empowering to know I am doing something, I am taking a stand, I am disrupting.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "difficult", "first", "family", "bring", "out", "world"],
    words: [
      "the",
      "most",
      "difficult",
      "is",
      "first",
      "family",
      "to",
      "bring",
      "someone",
      "out",
      "of",
      "world",
    ],
    id: "The most difficult is the first family, to bring someone out of the world.",
    author: "Richard G. Scott",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "your", "children", "measure", "success", "regardless", "career"],
    words: [
      "i",
      "think",
      "your",
      "children",
      "are",
      "measure",
      "of",
      "success",
      "regardless",
      "work",
      "and",
      "career",
    ],
    id: "I think your children are your measure of success, regardless of work and career.",
    author: "John Rhys-Davies",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgiveness", "funny", "thing", "warms", "heart", "cools", "sting"],
    words: [
      "forgiveness",
      "is",
      "a",
      "funny",
      "thing",
      "it",
      "warms",
      "the",
      "heart",
      "and",
      "cools",
      "sting",
    ],
    id: "Forgiveness is a funny thing. It warms the heart and cools the sting.",
    author: "William Arthur Ward",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["democracy", "art", "science", "running", "circus", "from", "cage"],
    words: [
      "democracy",
      "is",
      "the",
      "art",
      "and",
      "science",
      "of",
      "running",
      "circus",
      "from",
      "monkey",
      "cage",
    ],
    id: "Democracy is the art and science of running the circus from the monkey cage.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["change", "bulldozed", "road", "down", "center", "my", "mind"],
    words: [
      "the",
      "need",
      "for",
      "change",
      "bulldozed",
      "a",
      "road",
      "down",
      "center",
      "of",
      "my",
      "mind",
    ],
    id: "The need for change bulldozed a road down the center of my mind.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["from", "get", "make", "living", "however", "makes", "life"],
    words: [
      "from",
      "what",
      "we",
      "get",
      "can",
      "make",
      "a",
      "living",
      "give",
      "however",
      "makes",
      "life",
    ],
    id: "From what we get, we can make a living; what we give, however, makes a life.",
    author: "Arthur Ashe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["country", "age", "priest", "had", "been", "hostile", "liberty"],
    words: [
      "in",
      "every",
      "country",
      "and",
      "age",
      "the",
      "priest",
      "had",
      "been",
      "hostile",
      "to",
      "liberty",
    ],
    id: "In every country and every age, the priest had been hostile to Liberty.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["world", "great", "gymnasium", "come", "make", "ourselves", "strong"],
    words: [
      "the",
      "world",
      "is",
      "great",
      "gymnasium",
      "where",
      "we",
      "come",
      "to",
      "make",
      "ourselves",
      "strong",
    ],
    id: "The world is the great gymnasium where we come to make ourselves strong.",
    author: "Swami Vivekananda",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "enough", "govern", "another", "without", "other's", "consent"],
    words: [
      "no",
      "man",
      "is",
      "good",
      "enough",
      "to",
      "govern",
      "another",
      "without",
      "the",
      "other's",
      "consent",
    ],
    id: "No man is good enough to govern another man without the other's consent.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "contract", "friendship", "man", "better", "than", "thyself"],
    words: [
      "never",
      "contract",
      "friendship",
      "with",
      "a",
      "man",
      "that",
      "is",
      "not",
      "better",
      "than",
      "thyself",
    ],
    id: "Never contract friendship with a man that is not better than thyself.",
    author: "Confucius",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["4", "hugs", "survival", "8", "maintenance", "12", "growth"],
    words: [
      "we",
      "need",
      "4",
      "hugs",
      "a",
      "day",
      "for",
      "survival",
      "8",
      "maintenance",
      "12",
      "growth",
    ],
    id: "We need 4 hugs a day for survival. We need 8 hugs a day for maintenance. We need 12 hugs a day for growth.",
    author: "Virginia Satir",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["best", "time", "home", "always", "five", "years", "ago"],
    words: [
      "the",
      "best",
      "time",
      "to",
      "buy",
      "a",
      "home",
      "is",
      "always",
      "five",
      "years",
      "ago",
    ],
    id: "The best time to buy a home is always five years ago.",
    author: "Ray Brown",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["future", "belongs", "those", "believe", "beauty", "their", "dreams"],
    words: [
      "the",
      "future",
      "belongs",
      "to",
      "those",
      "who",
      "believe",
      "in",
      "beauty",
      "of",
      "their",
      "dreams",
    ],
    id: "The future belongs to those who believe in the beauty of their dreams.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "poetry",
      "art",
      "substantiating",
      "shadows",
      "lending",
      "existence",
      "nothing",
    ],
    words: [
      "poetry",
      "is",
      "the",
      "art",
      "of",
      "substantiating",
      "shadows",
      "and",
      "lending",
      "existence",
      "to",
      "nothing",
    ],
    id: "Poetry is the art of substantiating shadows, and of lending existence to nothing.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["make", "penicillin", "out", "mouldy", "bread", "sure", "something"],
    words: [
      "if",
      "they",
      "can",
      "make",
      "penicillin",
      "out",
      "of",
      "mouldy",
      "bread",
      "sure",
      "something",
      "you",
    ],
    id: "If they can make penicillin out of mouldy bread, they can sure make something out of you.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "often", "achieved", "by", "those", "failure", "inevitable"],
    words: [
      "success",
      "is",
      "often",
      "achieved",
      "by",
      "those",
      "who",
      "don't",
      "know",
      "that",
      "failure",
      "inevitable",
    ],
    id: "Success is often achieved by those who don't know that failure is inevitable.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["year", "on", "your", "birthday", "get", "chance", "start"],
    words: [
      "every",
      "year",
      "on",
      "your",
      "birthday",
      "you",
      "get",
      "a",
      "chance",
      "to",
      "start",
      "new",
    ],
    id: "Every year on your birthday, you get a chance to start new.",
    author: "Sammy Hagar",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["great", "empire", "most", "easily", "diminished", "at", "edges"],
    words: [
      "a",
      "great",
      "empire",
      "like",
      "cake",
      "is",
      "most",
      "easily",
      "diminished",
      "at",
      "the",
      "edges",
    ],
    id: "A great empire, like a great cake, is most easily diminished at the edges.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "successful", "commanders", "prima", "donnas", "must", "treated"],
    words: [
      "all",
      "very",
      "successful",
      "commanders",
      "are",
      "prima",
      "donnas",
      "and",
      "must",
      "be",
      "so",
      "treated",
    ],
    id: "All very successful commanders are prima donnas and must be so treated.",
    author: "George S. Patton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "moral", "phenomena", "at", "all", "only", "interpretation"],
    words: [
      "there",
      "are",
      "no",
      "moral",
      "phenomena",
      "at",
      "all",
      "but",
      "only",
      "a",
      "interpretation",
      "of",
    ],
    id: "There are no moral phenomena at all, but only a moral interpretation of phenomena.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "oversteps", "bounds", "moderation", "greatest", "pleasures", "cease"],
    words: [
      "if",
      "one",
      "oversteps",
      "the",
      "bounds",
      "of",
      "moderation",
      "greatest",
      "pleasures",
      "cease",
      "to",
      "please",
    ],
    id: "If one oversteps the bounds of moderation, the greatest pleasures cease to please.",
    author: "Epictetus",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["tears", "shed", "self", "weakness", "others", "sign", "strength"],
    words: [
      "tears",
      "shed",
      "for",
      "self",
      "are",
      "of",
      "weakness",
      "but",
      "others",
      "a",
      "sign",
      "strength",
    ],
    id: "Tears shed for self are tears of weakness, but tears shed for others are a sign of strength.",
    author: "Billy Graham",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["failure", "simply", "opportunity", "begin", "time", "more", "intelligently"],
    words: [
      "failure",
      "is",
      "simply",
      "the",
      "opportunity",
      "to",
      "begin",
      "again",
      "this",
      "time",
      "more",
      "intelligently",
    ],
    id: "Failure is simply the opportunity to begin again, this time more intelligently.",
    author: "Henry Ford",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["surely", "harmful", "souls", "make", "heresy", "believe", "proved"],
    words: [
      "it",
      "is",
      "surely",
      "harmful",
      "to",
      "souls",
      "make",
      "a",
      "heresy",
      "believe",
      "what",
      "proved",
    ],
    id: "It is surely harmful to souls to make it a heresy to believe what is proved.",
    author: "Galileo Galilei",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["family", "always", "gonna", "there", "material", "things", "come"],
    words: [
      "family",
      "always",
      "gonna",
      "be",
      "there",
      "the",
      "material",
      "things",
      "they",
      "come",
      "and",
      "go",
    ],
    id: "Family always gonna be there. The material things, they come and go.",
    author: "Romeo Miller",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["hardest", "part", "travel", "away", "from", "your", "family"],
    words: [
      "the",
      "hardest",
      "part",
      "is",
      "to",
      "travel",
      "and",
      "be",
      "away",
      "from",
      "your",
      "family",
    ],
    id: "The hardest part is to travel, and to be away from your family.",
    author: "Glenn Tipton",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "travelling", "edge", "knowledge", "then", "leap", "taken"],
    words: [
      "life",
      "is",
      "a",
      "travelling",
      "to",
      "the",
      "edge",
      "of",
      "knowledge",
      "then",
      "leap",
      "taken",
    ],
    id: "Life is a travelling to the edge of knowledge, then a leap taken.",
    author: "D. H. Lawrence",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["man's", "growth", "seen", "successive", "choirs", "his", "friends"],
    words: [
      "a",
      "man's",
      "growth",
      "is",
      "seen",
      "in",
      "the",
      "successive",
      "choirs",
      "of",
      "his",
      "friends",
    ],
    id: "A man's growth is seen in the successive choirs of his friends.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["awaken", "others", "same", "attitude", "mind", "hold", "toward"],
    words: [
      "we",
      "awaken",
      "in",
      "others",
      "the",
      "same",
      "attitude",
      "of",
      "mind",
      "hold",
      "toward",
      "them",
    ],
    id: "We awaken in others the same attitude of mind we hold toward them.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["getting", "old", "when", "candles", "cost", "more", "than"],
    words: [
      "you",
      "know",
      "you're",
      "getting",
      "old",
      "when",
      "the",
      "candles",
      "cost",
      "more",
      "than",
      "cake",
    ],
    id: "You know you're getting old when the candles cost more than the cake.",
    author: "Bob Hope",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["achieved", "success", "worked", "laughed", "often", "loved", "much"],
    words: [
      "he",
      "has",
      "achieved",
      "success",
      "who",
      "worked",
      "well",
      "laughed",
      "often",
      "and",
      "loved",
      "much",
    ],
    id: "He has achieved success who has worked well, laughed often, and loved much.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "get", "older", "always", "maturing", "something", "yourself"],
    words: [
      "as",
      "you",
      "get",
      "older",
      "you're",
      "always",
      "maturing",
      "learning",
      "something",
      "new",
      "about",
      "yourself",
    ],
    id: "As you get older, you're always maturing, you're always learning something new about yourself.",
    author: "Troy Vincent",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["simply", "attempt", "fearful", "when", "others", "greedy", "only"],
    words: [
      "we",
      "simply",
      "attempt",
      "to",
      "be",
      "fearful",
      "when",
      "others",
      "are",
      "greedy",
      "and",
      "only",
    ],
    id: "We simply attempt to be fearful when others are greedy and to be greedy only when others are fearful.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["productivity", "being", "able", "things", "were", "never", "before"],
    words: [
      "productivity",
      "is",
      "being",
      "able",
      "to",
      "do",
      "things",
      "that",
      "you",
      "were",
      "never",
      "before",
    ],
    id: "Productivity is being able to do things that you were never able to do before.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "patience", "level", "weakness", "i", "deal", "anyone"],
    words: [
      "my",
      "patience",
      "level",
      "can",
      "be",
      "weakness",
      "but",
      "i",
      "know",
      "deal",
      "with",
      "anyone",
    ],
    id: "My patience level can be my weakness, but I know I can deal with anyone.",
    author: "Urfi Javed",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "were", "predictable", "would", "cease", "without", "flavor"],
    words: [
      "if",
      "life",
      "were",
      "predictable",
      "it",
      "would",
      "cease",
      "to",
      "be",
      "and",
      "without",
      "flavor",
    ],
    id: "If life were predictable it would cease to be life, and be without flavor.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["spirit", "becomes", "free", "only", "when", "ceases", "support"],
    words: [
      "the",
      "spirit",
      "becomes",
      "free",
      "only",
      "when",
      "it",
      "ceases",
      "to",
      "be",
      "a",
      "support",
    ],
    id: "The spirit becomes free only when it ceases to be a support.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["got", "tedious", "saying", "same", "jokes", "way", "attitude"],
    words: [
      "it",
      "got",
      "very",
      "tedious",
      "saying",
      "the",
      "same",
      "jokes",
      "in",
      "way",
      "with",
      "attitude",
    ],
    id: "It got very tedious saying the same jokes in the same way with the same attitude.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["conservative", "man", "too", "cowardly", "fight", "fat", "run"],
    words: [
      "a",
      "conservative",
      "is",
      "man",
      "who",
      "too",
      "cowardly",
      "to",
      "fight",
      "and",
      "fat",
      "run",
    ],
    id: "A conservative is a man who is too cowardly to fight and too fat to run.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "life", "somewhere", "else", "get", "there", "car"],
    words: [
      "everything",
      "in",
      "life",
      "is",
      "somewhere",
      "else",
      "and",
      "you",
      "get",
      "there",
      "a",
      "car",
    ],
    id: "Everything in life is somewhere else, and you get there in a car.",
    author: "E. B. White",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["which", "government", "best", "one", "teaches", "govern", "ourselves"],
    words: [
      "which",
      "government",
      "is",
      "the",
      "best",
      "one",
      "that",
      "teaches",
      "us",
      "to",
      "govern",
      "ourselves",
    ],
    id: "Which government is the best? The one that teaches us to govern ourselves.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["something", "being", "blent", "together", "turns", "wild", "nothing"],
    words: [
      "where",
      "every",
      "something",
      "being",
      "blent",
      "together",
      "turns",
      "to",
      "a",
      "wild",
      "of",
      "nothing",
    ],
    id: "Where every something, being blent together turns to a wild of nothing.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["henceforth", "leaves", "knowledge", "were", "women", "healing", "nations"],
    words: [
      "henceforth",
      "the",
      "leaves",
      "of",
      "tree",
      "knowledge",
      "were",
      "for",
      "women",
      "and",
      "healing",
      "nations",
    ],
    id: "Henceforth the leaves of the tree of knowledge were for women, and for the healing of the nations.",
    author: "Lucy Stone",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "only", "one", "thing", "kill", "movies", "education"],
    words: [
      "there's",
      "only",
      "one",
      "thing",
      "that",
      "can",
      "kill",
      "the",
      "movies",
      "and",
      "that's",
      "education",
    ],
    id: "There's only one thing that can kill the movies, and that's education.",
    author: "Will Rogers",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "thought", "sometimes", "i", "football", "playing", "together"],
    words: [
      "i've",
      "thought",
      "sometimes",
      "about",
      "what",
      "i",
      "like",
      "football",
      "and",
      "it's",
      "playing",
      "together",
    ],
    id: "I've thought sometimes about what I like about football, and it's playing together.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "virtuous", "women", "something", "within", "never", "chaste"],
    words: [
      "the",
      "most",
      "virtuous",
      "women",
      "have",
      "something",
      "within",
      "them",
      "that",
      "is",
      "never",
      "chaste",
    ],
    id: "The most virtuous women have something within them, something that is never chaste.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "all", "woman", "look", "dresses", "crime"],
    words: [
      "i",
      "believe",
      "all",
      "woman",
      "look",
      "good",
      "in",
      "dresses",
      "is",
      "that",
      "a",
      "crime",
    ],
    id: "I believe all woman look good in dresses; is that a crime?",
    author: "Tyson Fury",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "land", "gone", "spent", "then", "most", "excellent"],
    words: [
      "when",
      "house",
      "and",
      "land",
      "are",
      "gone",
      "spent",
      "then",
      "learning",
      "is",
      "most",
      "excellent",
    ],
    id: "When house and land are gone and spent, then learning is most excellent.",
    author: "Samuel Foote",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["one-third", "united", "states", "promote", "while", "two-thirds", "provide"],
    words: [
      "one-third",
      "of",
      "the",
      "people",
      "in",
      "united",
      "states",
      "promote",
      "while",
      "other",
      "two-thirds",
      "provide",
    ],
    id: "One-third of the people in the United States promote, while the other two-thirds provide.",
    author: "Will Rogers",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["solidarity", "attitude", "resistance", "i", "suppose", "or", "should"],
    words: [
      "solidarity",
      "is",
      "an",
      "attitude",
      "of",
      "resistance",
      "i",
      "suppose",
      "or",
      "it",
      "should",
      "be",
    ],
    id: "Solidarity is an attitude of resistance, I suppose, or it should be.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "virtues", "i", "dislike", "none", "vices", "admire"],
    words: [
      "he",
      "has",
      "all",
      "of",
      "the",
      "virtues",
      "i",
      "dislike",
      "and",
      "none",
      "vices",
      "admire",
    ],
    id: "He has all of the virtues I dislike and none of the vices I admire.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["mere", "impulse", "must", "contain", "truth", "which", "law"],
    words: [
      "love",
      "is",
      "not",
      "a",
      "mere",
      "impulse",
      "it",
      "must",
      "contain",
      "truth",
      "which",
      "law",
    ],
    id: "Love is not a mere impulse, it must contain truth, which is law.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["victory", "always", "possible", "person", "refuses", "stop", "fighting"],
    words: [
      "victory",
      "is",
      "always",
      "possible",
      "for",
      "the",
      "person",
      "who",
      "refuses",
      "to",
      "stop",
      "fighting",
    ],
    id: "Victory is always possible for the person who refuses to stop fighting.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "your", "picture", "taken", "lose", "part", "soul"],
    words: [
      "every",
      "time",
      "your",
      "picture",
      "is",
      "taken",
      "you",
      "lose",
      "a",
      "part",
      "of",
      "soul",
    ],
    id: "Every time your picture is taken, you lose a part of your soul.",
    author: "Anna May Wong",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["lord", "how", "subject", "old", "men", "vice", "lying"],
    words: [
      "lord",
      "how",
      "subject",
      "we",
      "old",
      "men",
      "are",
      "to",
      "this",
      "vice",
      "of",
      "lying",
    ],
    id: "Lord, Lord, how subject we old men are to this vice of lying!",
    author: "William Shakespeare",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["cinema", "fill", "empty", "spaces", "your", "life", "loneliness"],
    words: [
      "cinema",
      "can",
      "fill",
      "in",
      "the",
      "empty",
      "spaces",
      "of",
      "your",
      "life",
      "and",
      "loneliness",
    ],
    id: "Cinema can fill in the empty spaces of your life and your loneliness.",
    author: "Pedro Almodovar",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["obvious", "which", "never", "seen", "until", "expresses", "simply"],
    words: [
      "the",
      "obvious",
      "is",
      "that",
      "which",
      "never",
      "seen",
      "until",
      "someone",
      "expresses",
      "it",
      "simply",
    ],
    id: "The obvious is that which is never seen until someone expresses it simply.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["failure", "unimportant", "takes", "courage", "make", "fool", "yourself"],
    words: [
      "failure",
      "is",
      "unimportant",
      "it",
      "takes",
      "courage",
      "to",
      "make",
      "a",
      "fool",
      "of",
      "yourself",
    ],
    id: "Failure is unimportant. It takes courage to make a fool of yourself.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["part", "kindness", "consists", "loving", "more", "than", "deserve"],
    words: [
      "a",
      "part",
      "of",
      "kindness",
      "consists",
      "in",
      "loving",
      "people",
      "more",
      "than",
      "they",
      "deserve",
    ],
    id: "A part of kindness consists in loving people more than they deserve.",
    author: "Joseph Joubert",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "knowledge", "receding", "mirage", "expanding", "desert", "ignorance"],
    words: [
      "our",
      "knowledge",
      "is",
      "a",
      "receding",
      "mirage",
      "in",
      "an",
      "expanding",
      "desert",
      "of",
      "ignorance",
    ],
    id: "Our knowledge is a receding mirage in an expanding desert of ignorance.",
    author: "Will Durant",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "wonders", "then", "behold", "lawyer", "honest", "man"],
    words: [
      "god",
      "works",
      "wonders",
      "now",
      "and",
      "then",
      "behold",
      "a",
      "lawyer",
      "an",
      "honest",
      "man",
    ],
    id: "God works wonders now and then; Behold a lawyer, an honest man.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["discovery", "forward", "lookers", "one", "born", "great", "knowledge"],
    words: [
      "discovery",
      "is",
      "for",
      "forward",
      "lookers",
      "so",
      "no",
      "one",
      "born",
      "with",
      "great",
      "knowledge",
    ],
    id: "Discovery is for forward lookers. So, no one is born with great knowledge.",
    author: "T. B. Joshua",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgiveness", "says", "given", "another", "chance", "make", "beginning"],
    words: [
      "forgiveness",
      "says",
      "you",
      "are",
      "given",
      "another",
      "chance",
      "to",
      "make",
      "a",
      "new",
      "beginning",
    ],
    id: "Forgiveness says you are given another chance to make a new beginning.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["restore", "trust", "must", "reform", "way", "government", "operates"],
    words: [
      "to",
      "restore",
      "the",
      "trust",
      "of",
      "people",
      "we",
      "must",
      "reform",
      "way",
      "government",
      "operates",
    ],
    id: "To restore the trust of the people, we must reform the way the government operates.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["poetry", "when", "emotion", "found", "its", "thought", "words"],
    words: [
      "poetry",
      "is",
      "when",
      "an",
      "emotion",
      "has",
      "found",
      "its",
      "thought",
      "and",
      "the",
      "words",
    ],
    id: "Poetry is when an emotion has found its thought and the thought has found words.",
    author: "Robert Frost",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "had", "wanted", "vote", "would", "given", "candidates"],
    words: [
      "if",
      "god",
      "had",
      "wanted",
      "us",
      "to",
      "vote",
      "he",
      "would",
      "have",
      "given",
      "candidates",
    ],
    id: "If God had wanted us to vote, he would have given us candidates.",
    author: "Jay Leno",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["even", "sleepers", "workers", "collaborators", "goes", "on", "universe"],
    words: [
      "even",
      "sleepers",
      "are",
      "workers",
      "and",
      "collaborators",
      "in",
      "what",
      "goes",
      "on",
      "the",
      "universe",
    ],
    id: "Even sleepers are workers and collaborators in what goes on in the Universe.",
    author: "Heraclitus",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["however", "rare", "true", "may", "less", "than", "friendship"],
    words: [
      "however",
      "rare",
      "true",
      "love",
      "may",
      "be",
      "it",
      "is",
      "less",
      "so",
      "than",
      "friendship",
    ],
    id: "However rare true love may be, it is less so than true friendship.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "regard", "broker", "as", "member", "human", "race"],
    words: [
      "i",
      "do",
      "not",
      "regard",
      "a",
      "broker",
      "as",
      "member",
      "of",
      "the",
      "human",
      "race",
    ],
    id: "I do not regard a broker as a member of the human race.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["nah", "i", "chicago", "often", "out", "there", "miss"],
    words: [
      "nah",
      "i",
      "see",
      "chicago",
      "often",
      "go",
      "out",
      "there",
      "so",
      "don't",
      "miss",
      "it",
    ],
    id: "Nah, I see Chicago often. I go out there, so nah, I don't miss it.",
    author: "King Von",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["leading", "rule", "lawyer", "as", "man", "calling", "diligence"],
    words: [
      "the",
      "leading",
      "rule",
      "for",
      "lawyer",
      "as",
      "man",
      "of",
      "every",
      "calling",
      "is",
      "diligence",
    ],
    id: "The leading rule for the lawyer, as for the man of every calling, is diligence.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "reigns", "when", "take", "liberal", "view", "presented"],
    words: [
      "god",
      "reigns",
      "when",
      "we",
      "take",
      "a",
      "liberal",
      "view",
      "is",
      "presented",
      "to",
      "us",
    ],
    id: "God reigns when we take a liberal view, when a liberal view is presented to us.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "greatest", "form", "prayer", "praise", "god"],
    words: [
      "i",
      "believe",
      "that",
      "the",
      "greatest",
      "form",
      "of",
      "prayer",
      "is",
      "praise",
      "to",
      "god",
    ],
    id: "I believe that the greatest form of prayer is praise to God.",
    author: "Billy Graham",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["must", "dikes", "courage", "hold", "back", "flood", "fear"],
    words: [
      "we",
      "must",
      "build",
      "dikes",
      "of",
      "courage",
      "to",
      "hold",
      "back",
      "the",
      "flood",
      "fear",
    ],
    id: "We must build dikes of courage to hold back the flood of fear.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["too", "great", "haste", "repay", "obligation", "kind", "ingratitude"],
    words: [
      "too",
      "great",
      "haste",
      "to",
      "repay",
      "an",
      "obligation",
      "is",
      "a",
      "kind",
      "of",
      "ingratitude",
    ],
    id: "Too great haste to repay an obligation is a kind of ingratitude.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["physician", "without", "knowledge", "astrology", "right", "call", "himself"],
    words: [
      "a",
      "physician",
      "without",
      "knowledge",
      "of",
      "astrology",
      "has",
      "no",
      "right",
      "to",
      "call",
      "himself",
    ],
    id: "A physician without a knowledge of Astrology has no right to call himself a physician.",
    author: "Hippocrates",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "more", "interested", "your", "future", "relationships", "than"],
    words: [
      "god",
      "is",
      "more",
      "interested",
      "in",
      "your",
      "future",
      "and",
      "relationships",
      "than",
      "you",
      "are",
    ],
    id: "God is more interested in your future and your relationships than you are.",
    author: "Billy Graham",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["lord", "commonly", "riches", "foolish", "whom", "nothing", "else"],
    words: [
      "the",
      "lord",
      "commonly",
      "gives",
      "riches",
      "to",
      "foolish",
      "people",
      "whom",
      "he",
      "nothing",
      "else",
    ],
    id: "The Lord commonly gives riches to foolish people, to whom he gives nothing else.",
    author: "Martin Luther",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["failure", "trying", "something", "risking", "attaining", "your", "goal"],
    words: [
      "it's",
      "not",
      "about",
      "failure",
      "trying",
      "something",
      "and",
      "risking",
      "for",
      "attaining",
      "your",
      "goal",
    ],
    id: "It's not about failure; it's about trying something and risking something for attaining your goal.",
    author: "Carol Alt",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["temperamentally", "i'm", "natural", "producer", "because", "i", "patience"],
    words: [
      "temperamentally",
      "i'm",
      "not",
      "a",
      "natural",
      "producer",
      "because",
      "i",
      "don't",
      "have",
      "the",
      "patience",
    ],
    id: "Temperamentally I'm not a natural producer, because I don't have the patience.",
    author: "Damian Lewis",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "exciting", "business", "most", "when", "lived", "others"],
    words: [
      "life",
      "is",
      "an",
      "exciting",
      "business",
      "and",
      "most",
      "when",
      "it",
      "lived",
      "for",
      "others",
    ],
    id: "Life is an exciting business, and most exciting when it is lived for others.",
    author: "Helen Keller",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "guitars", "fender", "style", "because", "skinny", "necks"],
    words: [
      "i",
      "like",
      "guitars",
      "in",
      "the",
      "fender",
      "style",
      "because",
      "they",
      "have",
      "skinny",
      "necks",
    ],
    id: "I like guitars in the Fender style because they have skinny necks.",
    author: "Kurt Cobain",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "coach", "becomes", "right", "until", "wins", "championship"],
    words: [
      "i",
      "don't",
      "think",
      "a",
      "coach",
      "becomes",
      "the",
      "right",
      "until",
      "he",
      "wins",
      "championship",
    ],
    id: "I don't think a coach becomes the right coach until he wins a championship.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["poetry", "language", "often", "at", "heartbeat", "movements", "change"],
    words: [
      "poetry",
      "and",
      "language",
      "are",
      "often",
      "at",
      "the",
      "heartbeat",
      "of",
      "movements",
      "for",
      "change",
    ],
    id: "Poetry and language are often at the heartbeat of movements for change.",
    author: "Amanda Gorman",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["doorstep", "temple", "wisdom", "knowledge", "our", "own", "ignorance"],
    words: [
      "the",
      "doorstep",
      "to",
      "temple",
      "of",
      "wisdom",
      "is",
      "a",
      "knowledge",
      "our",
      "own",
      "ignorance",
    ],
    id: "The doorstep to the temple of wisdom is a knowledge of our own ignorance.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "fleeting", "-", "i", "main", "lesson", "learned"],
    words: [
      "happiness",
      "is",
      "fleeting",
      "-",
      "i",
      "think",
      "that's",
      "the",
      "main",
      "lesson",
      "have",
      "learned",
    ],
    id: "Happiness is fleeting - I think that's the main lesson I have learned.",
    author: "Cate Blanchett",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["experience", "pain", "or", "loss", "formidably", "motivating", "force"],
    words: [
      "the",
      "experience",
      "of",
      "pain",
      "or",
      "loss",
      "can",
      "be",
      "a",
      "formidably",
      "motivating",
      "force",
    ],
    id: "The experience of pain or loss can be a formidably motivating force.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["more", "knowledge", "greater", "will", "your", "fear", "allah"],
    words: [
      "the",
      "more",
      "knowledge",
      "you",
      "have",
      "greater",
      "will",
      "be",
      "your",
      "fear",
      "of",
      "allah",
    ],
    id: "The more knowledge you have, the greater will be your fear of Allah.",
    author: "Abu Bakr",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["journalists", "when", "ever", "anything", "moves", "begin", "bark"],
    words: [
      "journalists",
      "are",
      "like",
      "dogs",
      "when",
      "ever",
      "anything",
      "moves",
      "they",
      "begin",
      "to",
      "bark",
    ],
    id: "Journalists are like dogs, when ever anything moves they begin to bark.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["golf", "taught", "lot", "patience", "keeping", "my", "temper"],
    words: [
      "golf",
      "has",
      "taught",
      "me",
      "a",
      "lot",
      "about",
      "patience",
      "and",
      "keeping",
      "my",
      "temper",
    ],
    id: "Golf has taught me a lot about patience and keeping my temper.",
    author: "Scott Moir",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["everyone", "looking", "hack", "secret", "success", "without", "hard"],
    words: [
      "everyone",
      "is",
      "looking",
      "for",
      "the",
      "hack",
      "secret",
      "to",
      "success",
      "without",
      "hard",
      "work",
    ],
    id: "Everyone is looking for the hack, the secret to success without hard work.",
    author: "Sam Altman",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "world", "difference", "between", "truth", "facts", "obscure"],
    words: [
      "there's",
      "a",
      "world",
      "of",
      "difference",
      "between",
      "truth",
      "and",
      "facts",
      "can",
      "obscure",
      "the",
    ],
    id: "There's a world of difference between truth and facts. Facts can obscure the truth.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "consists", "activity", "running", "steam", "stagnant", "pool"],
    words: [
      "happiness",
      "consists",
      "in",
      "activity",
      "it",
      "is",
      "running",
      "steam",
      "not",
      "a",
      "stagnant",
      "pool",
    ],
    id: "Happiness consists in activity. It is running steam, not a stagnant pool.",
    author: "John Mason Good",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "crazy", "truly", "horrible", "lives", "must", "live"],
    words: [
      "some",
      "people",
      "never",
      "go",
      "crazy",
      "what",
      "truly",
      "horrible",
      "lives",
      "they",
      "must",
      "live",
    ],
    id: "Some people never go crazy, What truly horrible lives they must live.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["mystery", "creates", "wonder", "basis", "man's", "desire", "understand"],
    words: [
      "mystery",
      "creates",
      "wonder",
      "and",
      "is",
      "the",
      "basis",
      "of",
      "man's",
      "desire",
      "to",
      "understand",
    ],
    id: "Mystery creates wonder and wonder is the basis of man's desire to understand.",
    author: "Neil Armstrong",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "10", "percent", "make", "90", "how", "take"],
    words: ["life", "is", "10", "percent", "what", "you", "make", "it", "and", "90", "how", "take"],
    id: "Life is 10 percent what you make it, and 90 percent how you take it.",
    author: "Irving Berlin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["passion", "fame", "which", "instinct", "all", "great", "souls"],
    words: [
      "passion",
      "for",
      "fame",
      "a",
      "which",
      "is",
      "the",
      "instinct",
      "of",
      "all",
      "great",
      "souls",
    ],
    id: "Passion for fame: A passion which is the instinct of all great souls.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "prepared", "resort", "anything", "submit", "sake", "commonwealth"],
    words: [
      "i",
      "am",
      "prepared",
      "to",
      "resort",
      "anything",
      "submit",
      "for",
      "the",
      "sake",
      "of",
      "commonwealth",
    ],
    id: "I am prepared to resort to anything, to submit to anything, for the sake of the commonwealth.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["hardware", "startup", "lot", "funding", "momentum", "less", "risk"],
    words: [
      "a",
      "hardware",
      "startup",
      "with",
      "lot",
      "of",
      "funding",
      "and",
      "momentum",
      "has",
      "less",
      "risk",
    ],
    id: "A hardware startup with a lot of funding and a lot of momentum has a lot less risk.",
    author: "Brendan Iribe",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["sun", "earth", "friends", "our", "breath", "parts", "banquet"],
    words: [
      "the",
      "sun",
      "earth",
      "love",
      "friends",
      "our",
      "very",
      "breath",
      "are",
      "parts",
      "of",
      "banquet",
    ],
    id: "The sun, the earth, love, friends, our very breath are parts of the banquet.",
    author: "Rebecca Harding Davis",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["magician", "pulls", "man", "out", "his", "own", "hat"],
    words: [
      "love",
      "is",
      "the",
      "magician",
      "that",
      "pulls",
      "man",
      "out",
      "of",
      "his",
      "own",
      "hat",
    ],
    id: "Love is the magician that pulls man out of his own hat.",
    author: "Ben Hecht",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["summer", "bachelors", "breezes", "never", "as", "cool", "pretend"],
    words: [
      "summer",
      "bachelors",
      "like",
      "breezes",
      "are",
      "never",
      "as",
      "cool",
      "they",
      "pretend",
      "to",
      "be",
    ],
    id: "Summer bachelors, like summer breezes, are never as cool as they pretend to be.",
    author: "Nora Ephron",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "acquire",
      "immunity",
      "eloquence",
      "utmost",
      "importance",
      "citizens",
      "democracy",
    ],
    words: [
      "to",
      "acquire",
      "immunity",
      "eloquence",
      "is",
      "of",
      "the",
      "utmost",
      "importance",
      "citizens",
      "a",
      "democracy",
    ],
    id: "To acquire immunity to eloquence is of the utmost importance to the citizens of a democracy.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["moral", "disapprobation", "society", "impact", "on", "behavior", "societies"],
    words: [
      "the",
      "moral",
      "disapprobation",
      "of",
      "society",
      "has",
      "an",
      "impact",
      "on",
      "behavior",
      "in",
      "societies",
    ],
    id: "The moral disapprobation of society has an impact on behavior in societies.",
    author: "Joe Biden",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["everywhere", "i", "find", "poet", "been", "there", "before"],
    words: [
      "everywhere",
      "i",
      "go",
      "find",
      "that",
      "a",
      "poet",
      "has",
      "been",
      "there",
      "before",
      "me",
    ],
    id: "Everywhere I go I find that a poet has been there before me.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["idea", "dangerous", "worthy", "being", "called", "at", "all"],
    words: [
      "an",
      "idea",
      "that",
      "is",
      "not",
      "dangerous",
      "worthy",
      "of",
      "being",
      "called",
      "at",
      "all",
    ],
    id: "An idea that is not dangerous is not worthy of being called an idea at all.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "well-spent", "brings", "sleep", "life", "spent", "death"],
    words: [
      "as",
      "a",
      "well-spent",
      "day",
      "brings",
      "happy",
      "sleep",
      "so",
      "life",
      "well",
      "spent",
      "death",
    ],
    id: "As a well-spent day brings happy sleep, so a life well spent brings happy death.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["may", "fight", "battle", "more", "than", "once", "win"],
    words: [
      "you",
      "may",
      "have",
      "to",
      "fight",
      "a",
      "battle",
      "more",
      "than",
      "once",
      "win",
      "it",
    ],
    id: "You may have to fight a battle more than once to win it.",
    author: "Margaret Thatcher",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["tell", "lies", "yet", "easy", "show", "lying", "immoral"],
    words: [
      "we",
      "tell",
      "lies",
      "yet",
      "it",
      "is",
      "easy",
      "to",
      "show",
      "that",
      "lying",
      "immoral",
    ],
    id: "We tell lies, yet it is easy to show that lying is immoral.",
    author: "Epictetus",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "promise", "when", "sacramento", "will", "pump", "up"],
    words: [
      "i",
      "can",
      "promise",
      "you",
      "that",
      "when",
      "go",
      "to",
      "sacramento",
      "will",
      "pump",
      "up",
    ],
    id: "I can promise you that when I go to Sacramento, I will pump up Sacramento.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["senses", "earth", "reason", "stands", "apart", "from", "contemplation"],
    words: [
      "the",
      "senses",
      "are",
      "of",
      "earth",
      "reason",
      "stands",
      "apart",
      "from",
      "them",
      "in",
      "contemplation",
    ],
    id: "The senses are of the earth, the reason stands apart from them in contemplation.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["perhaps", "better", "irresponsible", "right", "than", "responsible", "wrong"],
    words: [
      "perhaps",
      "it",
      "is",
      "better",
      "to",
      "be",
      "irresponsible",
      "and",
      "right",
      "than",
      "responsible",
      "wrong",
    ],
    id: "Perhaps it is better to be irresponsible and right, than to be responsible and wrong.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "said", "million", "times", "always", "wanted", "movies"],
    words: [
      "i've",
      "said",
      "this",
      "a",
      "million",
      "times",
      "but",
      "always",
      "wanted",
      "to",
      "do",
      "movies",
    ],
    id: "I've said this a million times. But I've always wanted to do movies.",
    author: "David Chase",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["blessed", "forgetful", "get", "better", "even", "their", "blunders"],
    words: [
      "blessed",
      "are",
      "the",
      "forgetful",
      "for",
      "they",
      "get",
      "better",
      "even",
      "of",
      "their",
      "blunders",
    ],
    id: "Blessed are the forgetful: for they get the better even of their blunders.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["you've", "got", "survive", "defeat", "when", "develop", "character"],
    words: [
      "you've",
      "got",
      "to",
      "learn",
      "survive",
      "a",
      "defeat",
      "that's",
      "when",
      "you",
      "develop",
      "character",
    ],
    id: "You've got to learn to survive a defeat. That's when you develop character.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["war", "only", "killed", "once", "politics", "many", "times"],
    words: [
      "in",
      "war",
      "you",
      "can",
      "only",
      "be",
      "killed",
      "once",
      "but",
      "politics",
      "many",
      "times",
    ],
    id: "In war, you can only be killed once, but in politics, many times.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "neither", "without", "nor", "within", "god", "both"],
    words: [
      "happiness",
      "is",
      "neither",
      "without",
      "us",
      "nor",
      "within",
      "it",
      "in",
      "god",
      "both",
      "and",
    ],
    id: "Happiness is neither without us nor within us. It is in God, both without us and within us.",
    author: "Blaise Pascal",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["successful", "health", "reform", "must", "make", "insurance", "affordable"],
    words: [
      "successful",
      "health",
      "reform",
      "must",
      "not",
      "just",
      "make",
      "insurance",
      "affordable",
      "has",
      "to",
      "care",
    ],
    id: "Successful health reform must not just make health insurance affordable, affordable health insurance has to make health care affordable.",
    author: "Elizabeth Edwards",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "blood", "around", "while", "i", "was", "training"],
    words: [
      "it's",
      "in",
      "my",
      "blood",
      "to",
      "be",
      "around",
      "people",
      "while",
      "i",
      "was",
      "training",
    ],
    id: "It's in my blood to be around people while I was training.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["restful", "look", "at", "neither", "emotions", "nor", "conflicts"],
    words: [
      "flowers",
      "are",
      "restful",
      "to",
      "look",
      "at",
      "they",
      "have",
      "neither",
      "emotions",
      "nor",
      "conflicts",
    ],
    id: "Flowers are restful to look at. They have neither emotions nor conflicts.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "belief", "one", "chance", "make", "first", "impression"],
    words: [
      "my",
      "belief",
      "is",
      "you",
      "have",
      "one",
      "chance",
      "to",
      "make",
      "a",
      "first",
      "impression",
    ],
    id: "My belief is you have one chance to make a first impression.",
    author: "Kevin McCarthy",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["art", "classroom", "only", "spurs", "creativity", "also", "inspires"],
    words: [
      "art",
      "in",
      "the",
      "classroom",
      "not",
      "only",
      "spurs",
      "creativity",
      "it",
      "also",
      "inspires",
      "learning",
    ],
    id: "Art in the classroom not only spurs creativity, it also inspires learning.",
    author: "Mickey Hart",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "proud", "fact", "never", "invented", "weapons", "kill"],
    words: [
      "i",
      "am",
      "proud",
      "of",
      "the",
      "fact",
      "that",
      "never",
      "invented",
      "weapons",
      "to",
      "kill",
    ],
    id: "I am proud of the fact that I never invented weapons to kill.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["hug", "boomerang", "-", "get", "back", "right", "away"],
    words: [
      "a",
      "hug",
      "is",
      "like",
      "boomerang",
      "-",
      "you",
      "get",
      "it",
      "back",
      "right",
      "away",
    ],
    id: "A hug is like a boomerang - you get it back right away.",
    author: "Bil Keane",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "tyrannies", "affect", "mankind", "tyranny", "religion", "worst"],
    words: [
      "of",
      "all",
      "the",
      "tyrannies",
      "that",
      "affect",
      "mankind",
      "tyranny",
      "in",
      "religion",
      "is",
      "worst",
    ],
    id: "Of all the tyrannies that affect mankind, tyranny in religion is the worst.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["fear", "mistakes", "will", "failure", "continue", "reach", "out"],
    words: [
      "do",
      "not",
      "fear",
      "mistakes",
      "you",
      "will",
      "know",
      "failure",
      "continue",
      "to",
      "reach",
      "out",
    ],
    id: "Do not fear mistakes. You will know failure. Continue to reach out.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: ["at", "time", "scorching", "irony", "convincing", "argument", "needed"],
    words: [
      "at",
      "a",
      "time",
      "like",
      "this",
      "scorching",
      "irony",
      "not",
      "convincing",
      "argument",
      "is",
      "needed",
    ],
    id: "At a time like this, scorching irony, not convincing argument, is needed.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 12,
    all_intersection_count: 5,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "become",
      "appallingly",
      "obvious",
      "our",
      "technology",
      "exceeded",
      "humanity",
    ],
    words: [
      "it",
      "has",
      "become",
      "appallingly",
      "obvious",
      "that",
      "our",
      "technology",
      "exceeded",
      "humanity",
    ],
    id: "It has become appallingly obvious that our technology has exceeded our humanity.",
    author: "Unknown",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["m-theory", "unified", "theory", "einstein", "was", "hoping", "find"],
    words: [
      "m-theory",
      "is",
      "the",
      "unified",
      "theory",
      "einstein",
      "was",
      "hoping",
      "to",
      "find",
    ],
    id: "M-theory is the unified theory Einstein was hoping to find.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["publishers", "free", "downloads", "as", "threatening", "sales", "book"],
    words: [
      "publishers",
      "see",
      "free",
      "downloads",
      "as",
      "threatening",
      "the",
      "sales",
      "of",
      "book",
    ],
    id: "Publishers see free downloads as threatening the sales of the book.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "heard", "lot", "motivational", "speakers", "throughout", "years"],
    words: [
      "i've",
      "heard",
      "a",
      "lot",
      "of",
      "motivational",
      "speakers",
      "throughout",
      "the",
      "years",
    ],
    id: "I've heard a lot of motivational speakers throughout the years.",
    author: "Jason Kelce",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "television", "had", "vast", "unbelievable", "impact", "on"],
    words: [
      "i",
      "think",
      "television",
      "has",
      "had",
      "a",
      "vast",
      "unbelievable",
      "impact",
      "on",
      "us",
    ],
    id: "I think television has had a vast, unbelievable impact on us.",
    author: "Billy Graham",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["before", "everything", "else", "getting", "ready", "secret", "success"],
    words: [
      "before",
      "everything",
      "else",
      "getting",
      "ready",
      "is",
      "the",
      "secret",
      "of",
      "success",
    ],
    id: "Before everything else, getting ready is the secret of success.",
    author: "Henry Ford",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["self-worth", "comes", "from", "one", "thing", "-", "worthy"],
    words: [
      "self-worth",
      "comes",
      "from",
      "one",
      "thing",
      "-",
      "thinking",
      "that",
      "you",
      "are",
      "worthy",
    ],
    id: "Self-worth comes from one thing - thinking that you are worthy.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["conduct", "three-fourths", "our", "life", "its", "largest", "concern"],
    words: [
      "conduct",
      "is",
      "three-fourths",
      "of",
      "our",
      "life",
      "and",
      "its",
      "largest",
      "concern",
    ],
    id: "Conduct is three-fourths of our life and its largest concern.",
    author: "Matthew Arnold",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "saw", "subliminal", "advertising", "executive", "only", "second"],
    words: [
      "i",
      "saw",
      "a",
      "subliminal",
      "advertising",
      "executive",
      "but",
      "only",
      "for",
      "second",
    ],
    id: "I saw a subliminal advertising executive, but only for a second.",
    author: "Steven Wright",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["weakness", "character", "only", "defect", "which", "cannot", "amended"],
    words: [
      "weakness",
      "of",
      "character",
      "is",
      "the",
      "only",
      "defect",
      "which",
      "cannot",
      "be",
      "amended",
    ],
    id: "Weakness of character is the only defect which cannot be amended.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["reason", "natural", "order", "truth", "imagination", "organ", "meaning"],
    words: [
      "reason",
      "is",
      "the",
      "natural",
      "order",
      "of",
      "truth",
      "but",
      "imagination",
      "organ",
      "meaning",
    ],
    id: "Reason is the natural order of truth; but imagination is the organ of meaning.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "geometry", "humming", "strings", "music", "spacing", "spheres"],
    words: [
      "there",
      "is",
      "geometry",
      "in",
      "the",
      "humming",
      "of",
      "strings",
      "music",
      "spacing",
      "spheres",
    ],
    id: "There is geometry in the humming of the strings, there is music in the spacing of the spheres.",
    author: "Pythagoras",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "connected", "one", "thing", "change", "by", "itself"],
    words: ["all", "is", "connected", "no", "one", "thing", "can", "change", "by", "itself"],
    id: "All is connected... no one thing can change by itself.",
    author: "Paul Hawken",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "abides", "long", "enough", "those", "make", "use"],
    words: ["time", "abides", "long", "enough", "for", "those", "who", "make", "use", "of", "it"],
    id: "Time abides long enough for those who make use of it.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "leave", "convivial", "party", "before", "closing", "time"],
    words: ["one", "does", "not", "leave", "a", "convivial", "party", "before", "closing", "time"],
    id: "One does not leave a convivial party before closing time.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "each", "as", "there", "were", "only", "one"],
    words: ["god", "loves", "each", "of", "us", "as", "if", "there", "were", "only", "one"],
    id: "God loves each of us as if there were only one of us.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["often", "change", "self", "needed", "more", "than", "scene"],
    words: ["very", "often", "a", "change", "of", "self", "is", "needed", "more", "than", "scene"],
    id: "Very often a change of self is needed more than a change of scene.",
    author: "A. C. Benson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["virtue", "would", "far", "vanity", "did", "keep", "company"],
    words: ["virtue", "would", "go", "far", "if", "vanity", "did", "not", "keep", "it", "company"],
    id: "Virtue would go far if vanity did not keep it company.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "metaphysics",
      "means",
      "nothing",
      "unusually",
      "obstinate",
      "effort",
      "clearly",
    ],
    words: [
      "metaphysics",
      "means",
      "nothing",
      "but",
      "an",
      "unusually",
      "obstinate",
      "effort",
      "to",
      "think",
      "clearly",
    ],
    id: "Metaphysics means nothing but an unusually obstinate effort to think clearly.",
    author: "William James",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["show", "thoroughly", "satisfied", "man", "i", "will", "failure"],
    words: [
      "show",
      "me",
      "a",
      "thoroughly",
      "satisfied",
      "man",
      "and",
      "i",
      "will",
      "you",
      "failure",
    ],
    id: "Show me a thoroughly satisfied man and I will show you a failure.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["recent", "birthday", "i've", "been", "acting", "twenty", "years"],
    words: [
      "with",
      "a",
      "recent",
      "birthday",
      "i've",
      "been",
      "acting",
      "now",
      "for",
      "twenty",
      "years",
    ],
    id: "With a recent birthday, I've been acting now for twenty years.",
    author: "Thayer David",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["action", "best", "which", "procures", "greatest", "happiness", "numbers"],
    words: [
      "that",
      "action",
      "is",
      "best",
      "which",
      "procures",
      "the",
      "greatest",
      "happiness",
      "for",
      "numbers",
    ],
    id: "That action is best which procures the greatest happiness for the greatest numbers.",
    author: "Francis Hutcheson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "show", "anything", "anyone", "there", "nothing", "prove"],
    words: [
      "i",
      "don't",
      "have",
      "to",
      "show",
      "anything",
      "anyone",
      "there",
      "is",
      "nothing",
      "prove",
    ],
    id: "I don't have to show anything to anyone. There is nothing to prove.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["start", "one", "person", "at", "time", "change", "village"],
    words: ["so", "you", "start", "one", "person", "at", "a", "time", "change", "can", "village"],
    id: "So you start one person at a time. Change one person, you can change a village.",
    author: "Robin Quivers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["those", "free", "resentful", "thoughts", "surely", "find", "peace"],
    words: [
      "those",
      "who",
      "are",
      "free",
      "of",
      "resentful",
      "thoughts",
      "surely",
      "find",
      "peace",
    ],
    id: "Those who are free of resentful thoughts surely find peace.",
    author: "Buddha",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["past", "cannot", "changed", "future", "yet", "your", "power"],
    words: ["the", "past", "cannot", "be", "changed", "future", "is", "yet", "in", "your", "power"],
    id: "The past cannot be changed. The future is yet in your power.",
    author: "Unknown",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["election", "sort", "advance", "auction", "sale", "stolen", "goods"],
    words: [
      "every",
      "election",
      "is",
      "a",
      "sort",
      "of",
      "advance",
      "auction",
      "sale",
      "stolen",
      "goods",
    ],
    id: "Every election is a sort of advance auction sale of stolen goods.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["everyone", "should", "health", "insurance", "i", "i'm", "selling"],
    words: [
      "everyone",
      "should",
      "have",
      "health",
      "insurance",
      "i",
      "say",
      "care",
      "i'm",
      "not",
      "selling",
    ],
    id: "Everyone should have health insurance? I say everyone should have health care. I'm not selling insurance.",
    author: "Dennis Kucinich",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "remembers", "climbed", "mount", "everest", "second", "time"],
    words: [
      "no",
      "one",
      "remembers",
      "who",
      "climbed",
      "mount",
      "everest",
      "the",
      "second",
      "time",
    ],
    id: "No one remembers who climbed Mount Everest the second time.",
    author: "Edmund Hillary",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "must", "get", "rid", "slavery", "or", "freedom"],
    words: ["i", "think", "we", "must", "get", "rid", "of", "slavery", "or", "freedom"],
    id: "I think we must get rid of slavery, or we must get rid of freedom.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["best", "part", "making", "movies", "from", "pros", "themselves"],
    words: [
      "the",
      "best",
      "part",
      "of",
      "making",
      "movies",
      "learning",
      "from",
      "pros",
      "themselves",
    ],
    id: "The best part of making the movies... learning from the pros themselves.",
    author: "Michelle Rodriguez",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["many", "would", "surprised", "fact", "i'm", "quite", "shy"],
    words: [
      "many",
      "people",
      "would",
      "be",
      "surprised",
      "that",
      "in",
      "fact",
      "i'm",
      "quite",
      "shy",
    ],
    id: "Many people would be surprised that, in fact, I'm quite shy.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["truly", "free", "should", "ready", "risk", "everything", "freedom"],
    words: [
      "to",
      "be",
      "truly",
      "free",
      "you",
      "should",
      "ready",
      "risk",
      "everything",
      "for",
      "freedom",
    ],
    id: "To be truly free, you should be ready to risk everything for freedom.",
    author: "Pavel Durov",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "patient", "crossword", "puzzles", "most", "impatient", "golfer"],
    words: [
      "i'm",
      "patient",
      "with",
      "crossword",
      "puzzles",
      "and",
      "the",
      "most",
      "impatient",
      "golfer",
    ],
    id: "I'm patient with crossword puzzles and the most impatient golfer.",
    author: "Brett Hull",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["psychical", "whatever", "its", "nature", "may", "itself", "unconscious"],
    words: [
      "the",
      "psychical",
      "whatever",
      "its",
      "nature",
      "may",
      "be",
      "is",
      "itself",
      "unconscious",
    ],
    id: "The psychical, whatever its nature may be, is itself unconscious.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["genius", "may", "its", "limitations", "stupidity", "thus", "handicapped"],
    words: [
      "genius",
      "may",
      "have",
      "its",
      "limitations",
      "but",
      "stupidity",
      "is",
      "not",
      "thus",
      "handicapped",
    ],
    id: "Genius may have its limitations, but stupidity is not thus handicapped.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "wife", "dresses", "kill", "cooks", "same", "way"],
    words: ["my", "wife", "dresses", "to", "kill", "she", "cooks", "the", "same", "way"],
    id: "My wife dresses to kill. She cooks the same way.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "happiness", "having", "or", "getting", "only", "giving"],
    words: [
      "there",
      "is",
      "no",
      "happiness",
      "in",
      "having",
      "or",
      "getting",
      "but",
      "only",
      "giving",
    ],
    id: "There is no happiness in having or in getting, but only in giving.",
    author: "Henry Drummond",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["christian", "resolution", "find", "world", "ugly", "bad", "made"],
    words: [
      "the",
      "christian",
      "resolution",
      "to",
      "find",
      "world",
      "ugly",
      "and",
      "bad",
      "has",
      "made",
    ],
    id: "The Christian resolution to find the world ugly and bad has made the world ugly and bad.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "proved", "as", "difficult", "convert", "i", "hypnotize"],
    words: ["i've", "proved", "to", "be", "as", "difficult", "convert", "i", "am", "hypnotize"],
    id: "I've proved to be as difficult to convert as I am to hypnotize.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["frankly", "i", "got", "into", "movies", "because", "lot"],
    words: ["frankly", "i", "got", "into", "the", "movies", "because", "like", "a", "lot"],
    id: "Frankly, I got into the movies because I like the movies a lot.",
    author: "Jack Nicholson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["superior", "man", "understands", "right", "inferior", "will", "sell"],
    words: [
      "the",
      "superior",
      "man",
      "understands",
      "what",
      "is",
      "right",
      "inferior",
      "will",
      "sell",
    ],
    id: "The superior man understands what is right; the inferior man understands what will sell.",
    author: "Confucius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["conscience", "our", "unerring", "judge", "until", "finally", "stifle"],
    words: [
      "conscience",
      "is",
      "our",
      "unerring",
      "judge",
      "until",
      "we",
      "finally",
      "stifle",
      "it",
    ],
    id: "Conscience is our unerring judge until we finally stifle it.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["everyone", "his", "days", "last", "longer", "than", "others"],
    words: [
      "everyone",
      "has",
      "his",
      "day",
      "and",
      "some",
      "days",
      "last",
      "longer",
      "than",
      "others",
    ],
    id: "Everyone has his day and some days last longer than others.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["arrogance", "age", "must", "submit", "taught", "by", "youth"],
    words: ["the", "arrogance", "of", "age", "must", "submit", "to", "be", "taught", "by", "youth"],
    id: "The arrogance of age must submit to be taught by youth.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "prefer", "alive", "i'm", "cautious", "taking", "risks"],
    words: [
      "i",
      "prefer",
      "to",
      "be",
      "alive",
      "so",
      "i'm",
      "cautious",
      "about",
      "taking",
      "risks",
    ],
    id: "I prefer to be alive, so I'm cautious about taking risks.",
    author: "Werner Herzog",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["sometimes", "smallest", "decisions", "change", "your", "life", "forever"],
    words: [
      "sometimes",
      "it's",
      "the",
      "smallest",
      "decisions",
      "that",
      "can",
      "change",
      "your",
      "life",
      "forever",
    ],
    id: "Sometimes it's the smallest decisions that can change your life forever.",
    author: "Keri Russell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["how", "many", "better", "off", "than", "consider", "worse"],
    words: [
      "see",
      "how",
      "many",
      "are",
      "better",
      "off",
      "than",
      "you",
      "but",
      "consider",
      "worse",
    ],
    id: "See how many are better off than you are, but consider how many are worse.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "never", "final", "failure", "fatal", "courage", "counts"],
    words: [
      "success",
      "is",
      "never",
      "final",
      "failure",
      "fatal",
      "it's",
      "courage",
      "that",
      "counts",
    ],
    id: "Success is never final, failure is never fatal. It's courage that counts.",
    author: "John Wooden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["actually", "i'm", "overnight", "success", "took", "twenty", "years"],
    words: [
      "actually",
      "i'm",
      "an",
      "overnight",
      "success",
      "but",
      "it",
      "took",
      "twenty",
      "years",
    ],
    id: "Actually, I'm an overnight success, but it took twenty years.",
    author: "Monty Hall",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["succeed", "jump", "as", "quickly", "at", "opportunities", "conclusions"],
    words: [
      "to",
      "succeed",
      "jump",
      "as",
      "quickly",
      "at",
      "opportunities",
      "you",
      "do",
      "conclusions",
    ],
    id: "To succeed, jump as quickly at opportunities as you do at conclusions.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["innovation", "makes", "world", "round", "brings", "prosperity", "freedom"],
    words: [
      "innovation",
      "makes",
      "the",
      "world",
      "go",
      "round",
      "it",
      "brings",
      "prosperity",
      "and",
      "freedom",
    ],
    id: "Innovation makes the world go round. It brings prosperity and freedom.",
    author: "Robert Metcalfe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "death", "one", "even", "as", "river", "sea"],
    words: ["for", "life", "and", "death", "are", "one", "even", "as", "the", "river", "sea"],
    id: "For life and death are one, even as the river and the sea are one.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["season", "failure", "best", "time", "sowing", "seeds", "success"],
    words: [
      "the",
      "season",
      "of",
      "failure",
      "is",
      "best",
      "time",
      "for",
      "sowing",
      "seeds",
      "success",
    ],
    id: "The season of failure is the best time for sowing the seeds of success.",
    author: "Paramahansa Yogananda",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "wrong-doing", "arises", "because", "mind", "transformed", "remain"],
    words: [
      "all",
      "wrong-doing",
      "arises",
      "because",
      "of",
      "mind",
      "if",
      "is",
      "transformed",
      "can",
      "remain",
    ],
    id: "All wrong-doing arises because of mind. If mind is transformed can wrong-doing remain?",
    author: "Buddha",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["smallest", "seed", "faith", "better", "than", "largest", "happiness"],
    words: [
      "the",
      "smallest",
      "seed",
      "of",
      "faith",
      "is",
      "better",
      "than",
      "largest",
      "fruit",
      "happiness",
    ],
    id: "The smallest seed of faith is better than the largest fruit of happiness.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["courtesy", "only", "thin", "veneer", "on", "general", "selfishness"],
    words: ["courtesy", "is", "only", "a", "thin", "veneer", "on", "the", "general", "selfishness"],
    id: "Courtesy is only a thin veneer on the general selfishness.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["far", "from", "always", "knowing", "our", "own", "wishes"],
    words: ["we", "are", "very", "far", "from", "always", "knowing", "our", "own", "wishes"],
    id: "We are very far from always knowing our own wishes.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["art", "glory", "expression", "sunshine", "light", "letters", "simplicity"],
    words: [
      "the",
      "art",
      "of",
      "glory",
      "expression",
      "and",
      "sunshine",
      "light",
      "letters",
      "is",
      "simplicity",
    ],
    id: "The art of art, the glory of expression and the sunshine of the light of letters, is simplicity.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["scenes", "on", "field", "would", "cured", "anybody", "war"],
    words: [
      "the",
      "scenes",
      "on",
      "this",
      "field",
      "would",
      "have",
      "cured",
      "anybody",
      "of",
      "war",
    ],
    id: "The scenes on this field would have cured anybody of war.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "assumed", "soldier", "did", "lay", "aside", "citizen"],
    words: ["when", "we", "assumed", "the", "soldier", "did", "not", "lay", "aside", "citizen"],
    id: "When we assumed the Soldier, we did not lay aside the Citizen.",
    author: "George Washington",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["forms", "government", "fools", "contest", "whatever", "best", "administered"],
    words: [
      "for",
      "forms",
      "of",
      "government",
      "let",
      "fools",
      "contest",
      "whatever",
      "is",
      "best",
      "administered",
    ],
    id: "For Forms of Government let fools contest; whatever is best administered is best.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["frugality", "founded", "on", "principal", "all", "riches", "limits"],
    words: [
      "frugality",
      "is",
      "founded",
      "on",
      "the",
      "principal",
      "that",
      "all",
      "riches",
      "have",
      "limits",
    ],
    id: "Frugality is founded on the principal that all riches have limits.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "suspects", "himself", "at", "least", "cardinal", "virtues"],
    words: [
      "every",
      "one",
      "suspects",
      "himself",
      "of",
      "at",
      "least",
      "the",
      "cardinal",
      "virtues",
    ],
    id: "Every one suspects himself of at least one of the cardinal virtues.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["intellectual", "says", "simple", "thing", "hard", "way", "artist"],
    words: ["an", "intellectual", "says", "a", "simple", "thing", "in", "hard", "way", "artist"],
    id: "An intellectual says a simple thing in a hard way. An artist says a hard thing in a simple way.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["fiction", "based", "on", "reality", "unless", "fairytale", "artist"],
    words: [
      "fiction",
      "is",
      "based",
      "on",
      "reality",
      "unless",
      "you're",
      "a",
      "fairytale",
      "artist",
    ],
    id: "Fiction is based on reality unless you're a fairytale artist.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "cannot",
      "mere",
      "consumers",
      "governance",
      "must",
      "participants",
      "co-creators",
    ],
    words: [
      "we",
      "cannot",
      "be",
      "mere",
      "consumers",
      "of",
      "good",
      "governance",
      "must",
      "participants",
      "co-creators",
    ],
    id: "We cannot be mere consumers of good governance, we must be participants; we must be co-creators.",
    author: "Rohini Nilekani",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["reason", "obeys", "itself", "ignorance", "submits", "whatever", "dictated"],
    words: [
      "reason",
      "obeys",
      "itself",
      "and",
      "ignorance",
      "submits",
      "to",
      "whatever",
      "is",
      "dictated",
      "it",
    ],
    id: "Reason obeys itself; and ignorance submits to whatever is dictated to it.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["fly", "into", "rage", "always", "make", "bad", "landing"],
    words: ["people", "who", "fly", "into", "a", "rage", "always", "make", "bad", "landing"],
    id: "People who fly into a rage always make a bad landing.",
    author: "Will Rogers",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "anyone", "get", "emotional", "around", "their", "birthday"],
    words: [
      "i",
      "don't",
      "know",
      "anyone",
      "that",
      "doesn't",
      "get",
      "emotional",
      "around",
      "their",
      "birthday",
    ],
    id: "I don't know anyone that doesn't get emotional around their birthday.",
    author: "Alana Haim",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "formidable", "weapon", "against", "errors", "kind", "reason"],
    words: [
      "the",
      "most",
      "formidable",
      "weapon",
      "against",
      "errors",
      "of",
      "every",
      "kind",
      "is",
      "reason",
    ],
    id: "The most formidable weapon against errors of every kind is reason.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["hackers", "rarely", "full", "knowledge", "technology", "stack", "target"],
    words: [
      "hackers",
      "rarely",
      "have",
      "full",
      "knowledge",
      "of",
      "the",
      "technology",
      "stack",
      "a",
      "target",
    ],
    id: "Hackers rarely have full knowledge of the technology stack of a target.",
    author: "John McAfee",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["liberty", "cannot", "preserved", "without", "general", "knowledge", "among"],
    words: [
      "liberty",
      "cannot",
      "be",
      "preserved",
      "without",
      "general",
      "knowledge",
      "among",
      "the",
      "people",
    ],
    id: "Liberty cannot be preserved without general knowledge among the people.",
    author: "John Adams",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "something", "humility", "which", "strangely", "exalts", "heart"],
    words: [
      "there",
      "is",
      "something",
      "in",
      "humility",
      "which",
      "strangely",
      "exalts",
      "the",
      "heart",
    ],
    id: "There is something in humility which strangely exalts the heart.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "funny", "as", "long", "happening", "somebody", "else"],
    words: [
      "everything",
      "is",
      "funny",
      "as",
      "long",
      "it's",
      "happening",
      "to",
      "somebody",
      "else",
    ],
    id: "Everything is funny, as long as it's happening to somebody else.",
    author: "Will Rogers",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["those", "exhausted", "politics", "nothing", "remains", "abstract", "thought"],
    words: [
      "to",
      "those",
      "who",
      "have",
      "exhausted",
      "politics",
      "nothing",
      "remains",
      "but",
      "abstract",
      "thought",
    ],
    id: "To those who have exhausted politics, nothing remains but abstract thought.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "changed", "nothing", "more", "than", "world", "fashion"],
    words: [
      "everything",
      "has",
      "changed",
      "and",
      "nothing",
      "more",
      "than",
      "the",
      "world",
      "of",
      "fashion",
    ],
    id: "Everything has changed, and nothing has changed more than the world of fashion.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "became", "kind", "parent", "my", "mother", "was"],
    words: ["i", "became", "the", "kind", "of", "parent", "my", "mother", "was", "to", "me"],
    id: "I became the kind of parent my mother was to me.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["resort", "had", "ridicule", "only", "when", "reason", "against"],
    words: ["resort", "is", "had", "to", "ridicule", "only", "when", "reason", "against", "us"],
    id: "Resort is had to ridicule only when reason is against us.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["make", "world", "live", "shape", "our", "own", "environment"],
    words: [
      "we",
      "make",
      "the",
      "world",
      "live",
      "in",
      "and",
      "shape",
      "our",
      "own",
      "environment",
    ],
    id: "We make the world we live in and shape our own environment.",
    author: "Orison Swett Marden",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["without", "family", "man", "alone", "world", "trembles", "cold"],
    words: [
      "without",
      "a",
      "family",
      "man",
      "alone",
      "in",
      "the",
      "world",
      "trembles",
      "with",
      "cold",
    ],
    id: "Without a family, man, alone in the world, trembles with the cold.",
    author: "Andre Maurois",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "eating", "take", "one", "bite", "at", "time"],
    words: ["when", "eating", "an", "elephant", "take", "one", "bite", "at", "a", "time"],
    id: "When eating an elephant take one bite at a time.",
    author: "Creighton Abrams",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "simple", "what's", "right", "way", "at", "time"],
    words: ["success", "is", "simple", "do", "what's", "right", "the", "way", "at", "time"],
    id: "Success is simple. Do what's right, the right way, at the right time.",
    author: "Arnold H. Glasow",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["counsel", "woven", "into", "fabric", "real", "life", "wisdom"],
    words: ["counsel", "woven", "into", "the", "fabric", "of", "real", "life", "is", "wisdom"],
    id: "Counsel woven into the fabric of real life is wisdom.",
    author: "Walter Benjamin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "crown", "called", "content", "seldom", "kings", "enjoy"],
    words: ["my", "crown", "is", "called", "content", "a", "that", "seldom", "kings", "enjoy"],
    id: "My crown is called content, a crown that seldom kings enjoy.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["man's", "friendships", "one", "best", "measures", "his", "worth"],
    words: [
      "a",
      "man's",
      "friendships",
      "are",
      "one",
      "of",
      "the",
      "best",
      "measures",
      "his",
      "worth",
    ],
    id: "A man's friendships are one of the best measures of his worth.",
    author: "Charles Darwin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "everything",
      "possible",
      "from",
      "angels",
      "demons",
      "economists",
      "politicians",
    ],
    words: [
      "everything",
      "is",
      "possible",
      "from",
      "angels",
      "to",
      "demons",
      "economists",
      "and",
      "politicians",
    ],
    id: "Everything is possible, from angels to demons to economists and politicians.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "hungry", "vmas", "grammys", "my", "main", "goal"],
    words: ["i'm", "hungry", "for", "vmas", "and", "grammys", "that's", "my", "main", "goal"],
    id: "I'm hungry for VMAs and Grammys. That's my main goal.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["corpses", "more", "fit", "thrown", "out", "than", "dung"],
    words: ["corpses", "are", "more", "fit", "to", "be", "thrown", "out", "than", "is", "dung"],
    id: "Corpses are more fit to be thrown out than is dung.",
    author: "Heraclitus",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "talking", "females", "nothing", "serious", "entertaining", "everybody"],
    words: [
      "i",
      "be",
      "talking",
      "to",
      "females",
      "but",
      "nothing",
      "serious",
      "just",
      "entertaining",
      "everybody",
    ],
    id: "I be talking to females but nothing serious, just entertaining everybody.",
    author: "King Von",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["nothing", "succeeds", "success", "get", "little", "then", "more"],
    words: [
      "nothing",
      "succeeds",
      "like",
      "success",
      "get",
      "a",
      "little",
      "and",
      "then",
      "just",
      "more",
    ],
    id: "Nothing succeeds like success. Get a little success, and then just get a little more.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "more", "pleasant", "things", "than", "beat", "up"],
    words: [
      "there",
      "are",
      "more",
      "pleasant",
      "things",
      "to",
      "do",
      "than",
      "beat",
      "up",
      "people",
    ],
    id: "There are more pleasant things to do than beat up people.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["united", "states", "move", "fast", "even", "stand", "still"],
    words: [
      "the",
      "united",
      "states",
      "has",
      "to",
      "move",
      "very",
      "fast",
      "even",
      "stand",
      "still",
    ],
    id: "The United States has to move very fast to even stand still.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["order", "attain", "impossible", "one", "must", "attempt", "absurd"],
    words: ["in", "order", "to", "attain", "the", "impossible", "one", "must", "attempt", "absurd"],
    id: "In order to attain the impossible, one must attempt the absurd.",
    author: "Miguel de Cervantes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["faith", "knowledge", "within", "heart", "beyond", "reach", "proof"],
    words: [
      "faith",
      "is",
      "a",
      "knowledge",
      "within",
      "the",
      "heart",
      "beyond",
      "reach",
      "of",
      "proof",
    ],
    id: "Faith is a knowledge within the heart, beyond the reach of proof.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["unfortunately", "highly", "curious", "student", "small", "percentage", "kids"],
    words: [
      "unfortunately",
      "the",
      "highly",
      "curious",
      "student",
      "is",
      "a",
      "small",
      "percentage",
      "of",
      "kids",
    ],
    id: "Unfortunately, the highly curious student is a small percentage of the kids.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["cannot", "change", "cards", "dealt", "how", "play", "game"],
    words: [
      "we",
      "cannot",
      "change",
      "the",
      "cards",
      "are",
      "dealt",
      "just",
      "how",
      "play",
      "game",
    ],
    id: "We cannot change the cards we are dealt, just how we play the game.",
    author: "Randy Pausch",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["accurately", "only", "when", "little", "knowledge", "doubt", "increases"],
    words: [
      "we",
      "know",
      "accurately",
      "only",
      "when",
      "little",
      "with",
      "knowledge",
      "doubt",
      "increases",
    ],
    id: "We know accurately only when we know little, with knowledge doubt increases.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "lived", "long", "enough", "both", "years", "accomplishments"],
    words: [
      "i",
      "have",
      "lived",
      "long",
      "enough",
      "both",
      "in",
      "years",
      "and",
      "accomplishments",
    ],
    id: "I have lived long enough both in years and in accomplishments.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "will", "never", "anything", "can't", "handle", "stress"],
    words: [
      "god",
      "will",
      "never",
      "give",
      "you",
      "anything",
      "can't",
      "handle",
      "so",
      "don't",
      "stress",
    ],
    id: "God will never give you anything you can't handle, so don't stress.",
    author: "Kelly Clarkson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["greatness", "guarding", "yourself", "from", "being", "accepted", "by"],
    words: [
      "greatness",
      "is",
      "not",
      "guarding",
      "yourself",
      "from",
      "the",
      "people",
      "being",
      "accepted",
      "by",
    ],
    id: "Greatness is not guarding yourself from the people; greatness is being accepted by the people.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["way", "long", "one", "follows", "precepts", "short", "patterns"],
    words: [
      "the",
      "way",
      "is",
      "long",
      "if",
      "one",
      "follows",
      "precepts",
      "but",
      "short",
      "patterns",
    ],
    id: "The way is long if one follows precepts, but short... if one follows patterns.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "possible", "through", "god", "i'm", "living", "proof"],
    words: [
      "everything",
      "is",
      "possible",
      "through",
      "god",
      "and",
      "i'm",
      "living",
      "proof",
      "of",
      "that",
    ],
    id: "Everything is possible through God, and I'm living proof of that.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["man's", "manners", "mirror", "which", "shows", "his", "portrait"],
    words: [
      "a",
      "man's",
      "manners",
      "are",
      "mirror",
      "in",
      "which",
      "he",
      "shows",
      "his",
      "portrait",
    ],
    id: "A man's manners are a mirror in which he shows his portrait.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["press", "freedom", "foundation", "rights", "all", "filipinos", "truth"],
    words: [
      "press",
      "freedom",
      "is",
      "the",
      "foundation",
      "of",
      "rights",
      "all",
      "filipinos",
      "to",
      "truth",
    ],
    id: "Press freedom is the foundation of the rights of all Filipinos to the truth.",
    author: "Maria Ressa",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "priority", "economy", "minister", "mandate", "create", "momentum"],
    words: [
      "my",
      "priority",
      "is",
      "economy",
      "minister",
      "mandate",
      "and",
      "to",
      "create",
      "momentum",
    ],
    id: "My priority is my economy minister mandate and to create momentum.",
    author: "Emmanuel Macron",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["why", "call", "rush", "hour", "when", "nothing", "moves"],
    words: ["why", "do", "they", "call", "it", "rush", "hour", "when", "nothing", "moves"],
    id: "Why do they call it rush hour when nothing moves?",
    author: "Robin Williams",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "long", "i", "my", "health", "reporting", "somewhere"],
    words: [
      "as",
      "long",
      "i",
      "have",
      "my",
      "health",
      "want",
      "to",
      "be",
      "reporting",
      "somewhere",
    ],
    id: "As long as I have my health, I want to be reporting somewhere.",
    author: "Dan Rather",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "writing", "-", "best", "really", "hate", "collaboration"],
    words: [
      "i",
      "love",
      "writing",
      "-",
      "it's",
      "the",
      "best",
      "but",
      "really",
      "hate",
      "collaboration",
    ],
    id: "I love writing - it's the best. But I really hate collaboration.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "troubles", "wolf", "how", "many", "sheep", "may"],
    words: ["it", "never", "troubles", "the", "wolf", "how", "many", "sheep", "may", "be"],
    id: "It never troubles the wolf how many the sheep may be.",
    author: "Virgil",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["human", "race", "may", "only", "intelligent", "beings", "galaxy"],
    words: ["the", "human", "race", "may", "be", "only", "intelligent", "beings", "in", "galaxy"],
    id: "The human race may be the only intelligent beings in the galaxy.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "thing", "i", "pun", "excessively", "real", "life"],
    words: ["for", "one", "thing", "i", "don't", "pun", "excessively", "in", "real", "life"],
    id: "For one thing, I don't pun excessively in real life.",
    author: "Tim Vine",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["really", "only", "enjoy", "life", "when", "extremely", "busy"],
    words: ["you", "can", "really", "only", "enjoy", "life", "when", "you're", "extremely", "busy"],
    id: "You can really only enjoy life when you're extremely busy.",
    author: "Josephine de La Baume",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["however", "strategy", "should", "occasionally", "look", "at", "results"],
    words: [
      "however",
      "beautiful",
      "the",
      "strategy",
      "you",
      "should",
      "occasionally",
      "look",
      "at",
      "results",
    ],
    id: "However beautiful the strategy, you should occasionally look at the results.",
    author: "Unknown",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["knowledge", "slowly", "up", "ignorance", "hour", "pulls", "down"],
    words: [
      "knowledge",
      "slowly",
      "builds",
      "up",
      "what",
      "ignorance",
      "in",
      "an",
      "hour",
      "pulls",
      "down",
    ],
    id: "Knowledge slowly builds up what Ignorance in an hour pulls down.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "gave", "life", "liberty", "at", "same", "time"],
    words: ["the", "god", "who", "gave", "us", "life", "liberty", "at", "same", "time"],
    id: "The God who gave us life, gave us liberty at the same time.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "women's", "never", "done", "definition", "keeps", "changing"],
    words: [
      "not",
      "only",
      "is",
      "women's",
      "work",
      "never",
      "done",
      "the",
      "definition",
      "keeps",
      "changing",
    ],
    id: "Not only is women's work never done, the definition keeps changing.",
    author: "Bill Copeland",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["beauty", "independence", "departure", "actions", "rely", "on", "themselves"],
    words: [
      "the",
      "beauty",
      "of",
      "independence",
      "departure",
      "actions",
      "that",
      "rely",
      "on",
      "themselves",
    ],
    id: "The beauty of independence, departure, actions that rely on themselves.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["greatest", "happiness", "transform", "one's", "feelings", "into", "action"],
    words: [
      "the",
      "greatest",
      "happiness",
      "is",
      "to",
      "transform",
      "one's",
      "feelings",
      "into",
      "action",
    ],
    id: "The greatest happiness is to transform one's feelings into action.",
    author: "Madame de Stael",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "afraid", "trust", "unknown", "future", "known", "god"],
    words: ["never", "be", "afraid", "to", "trust", "an", "unknown", "future", "a", "known", "god"],
    id: "Never be afraid to trust an unknown future to a known God.",
    author: "Corrie Ten Boom",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "member", "any", "organized", "political", "party", "democrat"],
    words: [
      "i",
      "am",
      "not",
      "a",
      "member",
      "of",
      "any",
      "organized",
      "political",
      "party",
      "democrat",
    ],
    id: "I am not a member of any organized political party. I am a Democrat.",
    author: "Will Rogers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["art", "science", "asking", "questions", "source", "all", "knowledge"],
    words: [
      "the",
      "art",
      "and",
      "science",
      "of",
      "asking",
      "questions",
      "is",
      "source",
      "all",
      "knowledge",
    ],
    id: "The art and science of asking questions is the source of all knowledge.",
    author: "Thomas Berger",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["without", "knowing", "i", "why", "here", "life", "impossible"],
    words: [
      "without",
      "knowing",
      "what",
      "i",
      "am",
      "and",
      "why",
      "here",
      "life",
      "is",
      "impossible",
    ],
    id: "Without knowing what I am and why I am here, life is impossible.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "old", "abc", "-", "ability", "breaks", "courage"],
    words: ["success", "is", "that", "old", "abc", "-", "ability", "breaks", "and", "courage"],
    id: "Success is that old ABC - ability, breaks, and courage.",
    author: "Charles Luckman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "always", "time", "grow", "older", "never", "younger"],
    words: ["there's", "always", "time", "to", "grow", "older", "you", "can", "never", "younger"],
    id: "There's always time to grow older. You can never grow younger.",
    author: "JoJo Siwa",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["takes", "long", "time", "bring", "past", "up", "present"],
    words: ["it", "takes", "a", "long", "time", "to", "bring", "the", "past", "up", "present"],
    id: "It takes a long time to bring the past up to the present.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["heart", "never", "hardens", "temper", "tires", "touch", "hurts"],
    words: [
      "have",
      "a",
      "heart",
      "that",
      "never",
      "hardens",
      "and",
      "temper",
      "tires",
      "touch",
      "hurts",
    ],
    id: "Have a heart that never hardens, and a temper that never tires, and a touch that never hurts.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["public", "sentiment", "everything", "nothing", "fail", "without", "succeed"],
    words: [
      "public",
      "sentiment",
      "is",
      "everything",
      "with",
      "nothing",
      "can",
      "fail",
      "without",
      "it",
      "succeed",
    ],
    id: "Public sentiment is everything. With public sentiment, nothing can fail. Without it, nothing can succeed.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "envy", "or", "much", "respect", "completely", "politicised"],
    words: [
      "i",
      "don't",
      "envy",
      "or",
      "much",
      "respect",
      "people",
      "who",
      "are",
      "completely",
      "politicised",
    ],
    id: "I don't envy or much respect people who are completely politicised.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["superior", "man", "modest", "his", "speech", "exceeds", "actions"],
    words: [
      "a",
      "superior",
      "man",
      "is",
      "modest",
      "in",
      "his",
      "speech",
      "but",
      "exceeds",
      "actions",
    ],
    id: "A superior man is modest in his speech, but exceeds in his actions.",
    author: "Confucius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "war", "afghanistan", "was", "ruthlessly", "enough", "waged"],
    words: [
      "i",
      "don't",
      "think",
      "the",
      "war",
      "in",
      "afghanistan",
      "was",
      "ruthlessly",
      "enough",
      "waged",
    ],
    id: "I don't think the war in Afghanistan was ruthlessly enough waged.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "much",
      "more",
      "difficult",
      "measure",
      "nonperformance",
      "than",
      "performance",
    ],
    words: [
      "it",
      "is",
      "much",
      "more",
      "difficult",
      "to",
      "measure",
      "nonperformance",
      "than",
      "performance",
    ],
    id: "It is much more difficult to measure nonperformance than performance.",
    author: "Harold S. Geneen",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["fatal", "enter", "any", "war", "without", "will", "win"],
    words: ["it", "is", "fatal", "to", "enter", "any", "war", "without", "the", "will", "win"],
    id: "It is fatal to enter any war without the will to win it.",
    author: "Douglas MacArthur",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["tardiness", "often", "robs", "opportunity", "dispatch", "our", "forces"],
    words: [
      "tardiness",
      "often",
      "robs",
      "us",
      "opportunity",
      "and",
      "the",
      "dispatch",
      "of",
      "our",
      "forces",
    ],
    id: "Tardiness often robs us opportunity, and the dispatch of our forces.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["old", "age", "passage", "time", "teach", "all", "things"],
    words: ["old", "age", "and", "the", "passage", "of", "time", "teach", "all", "things"],
    id: "Old age and the passage of time teach all things.",
    author: "Sophocles",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "really", "folk", "married", "women", "single", "men"],
    words: [
      "the",
      "only",
      "really",
      "happy",
      "folk",
      "are",
      "married",
      "women",
      "and",
      "single",
      "men",
    ],
    id: "The only really happy folk are married women and single men.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "would", "encourage", "informed", "-", "knowledge", "power"],
    words: ["i", "would", "encourage", "you", "be", "informed", "-", "knowledge", "is", "power"],
    id: "I would encourage you: be informed - knowledge is power.",
    author: "Matt Bevin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["right", "man", "place", "at", "time", "steal", "millions"],
    words: ["the", "right", "man", "in", "place", "at", "time", "can", "steal", "millions"],
    id: "The right man, in the right place, at the right time, can steal millions.",
    author: "Gregory Nunn",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "pray", "my", "enemies", "god", "would", "forgive"],
    words: ["i", "pray", "for", "my", "enemies", "that", "god", "would", "forgive", "them"],
    id: "I pray for my enemies, that God would forgive them.",
    author: "Ruby Bridges",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["social", "progress", "measured", "by", "position", "female", "sex"],
    words: [
      "social",
      "progress",
      "can",
      "be",
      "measured",
      "by",
      "the",
      "position",
      "of",
      "female",
      "sex",
    ],
    id: "Social progress can be measured by the social position of the female sex.",
    author: "Karl Marx",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["greatest", "virtues", "those", "which", "most", "useful", "persons"],
    words: [
      "the",
      "greatest",
      "virtues",
      "are",
      "those",
      "which",
      "most",
      "useful",
      "to",
      "other",
      "persons",
    ],
    id: "The greatest virtues are those which are most useful to other persons.",
    author: "Aristotle",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "make", "jokes", "watch", "government", "report", "facts"],
    words: [
      "i",
      "don't",
      "make",
      "jokes",
      "just",
      "watch",
      "the",
      "government",
      "and",
      "report",
      "facts",
    ],
    id: "I don't make jokes. I just watch the government and report the facts.",
    author: "Will Rogers",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["rank", "confer", "privilege", "or", "power", "imposes", "responsibility"],
    words: [
      "rank",
      "does",
      "not",
      "confer",
      "privilege",
      "or",
      "give",
      "power",
      "it",
      "imposes",
      "responsibility",
    ],
    id: "Rank does not confer privilege or give power. It imposes responsibility.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "simply", "matter", "luck", "ask", "any", "failure"],
    words: ["success", "is", "simply", "a", "matter", "of", "luck", "ask", "any", "failure"],
    id: "Success is simply a matter of luck. Ask any failure.",
    author: "Earl Wilson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "amaravati",
      "will",
      "world-class",
      "capital",
      "health",
      "tourist",
      "destination",
    ],
    words: [
      "amaravati",
      "will",
      "be",
      "a",
      "world-class",
      "capital",
      "it",
      "health",
      "and",
      "tourist",
      "destination",
    ],
    id: "Amaravati will be a world-class capital. It will be a health and tourist destination.",
    author: "N. Chandrababu Naidu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "first", "signs", "beginning", "understanding", "wish", "die"],
    words: [
      "one",
      "of",
      "the",
      "first",
      "signs",
      "beginning",
      "understanding",
      "is",
      "wish",
      "to",
      "die",
    ],
    id: "One of the first signs of the beginning of understanding is the wish to die.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["advancement", "diffusion", "knowledge", "only", "guardian", "true", "liberty"],
    words: [
      "the",
      "advancement",
      "and",
      "diffusion",
      "of",
      "knowledge",
      "is",
      "only",
      "guardian",
      "true",
      "liberty",
    ],
    id: "The advancement and diffusion of knowledge is the only guardian of true liberty.",
    author: "James Madison",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "i", "my", "art", "hold", "hands", "god"],
    words: ["when", "i", "work", "and", "in", "my", "art", "hold", "hands", "with", "god"],
    id: "When I work, and in my art, I hold hands with God.",
    author: "Robert Mapplethorpe",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "diet", "bank", "account", "food", "choices", "investments"],
    words: [
      "your",
      "diet",
      "is",
      "a",
      "bank",
      "account",
      "good",
      "food",
      "choices",
      "are",
      "investments",
    ],
    id: "Your diet is a bank account. Good food choices are good investments.",
    author: "Bethenny Frankel",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["once", "start", "spiral", "company", "down", "lose", "momentum"],
    words: [
      "once",
      "you",
      "start",
      "to",
      "spiral",
      "a",
      "company",
      "down",
      "lose",
      "the",
      "momentum",
    ],
    id: "Once you start to spiral a company down, you lose the momentum.",
    author: "Mike Ashley",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "overwhelmed", "by", "showered", "on", "my", "fans"],
    words: ["i", "am", "overwhelmed", "by", "the", "love", "showered", "on", "me", "my", "fans"],
    id: "I am overwhelmed by the love showered on me by my fans.",
    author: "The Great Khali",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["root", "kingdom", "state", "family", "person", "its", "head"],
    words: ["the", "root", "of", "kingdom", "is", "in", "state", "family", "person", "its", "head"],
    id: "The root of the kingdom is in the state. The root of the state is in the family. The root of the family is in the person of its head.",
    author: "Mencius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "comedian", "playing", "audience", "too", "afraid", "laugh"],
    words: [
      "god",
      "is",
      "a",
      "comedian",
      "playing",
      "to",
      "an",
      "audience",
      "too",
      "afraid",
      "laugh",
    ],
    id: "God is a comedian, playing to an audience too afraid to laugh.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["move", "forward", "through", "knowledge", "prevail", "i", "word"],
    words: [
      "you",
      "move",
      "forward",
      "through",
      "knowledge",
      "prevail",
      "i",
      "love",
      "the",
      "word",
    ],
    id: "You move forward through knowledge. You prevail through knowledge. I love the word 'prevail.' Prevail!",
    author: "James D. Watson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["last", "analysis", "our", "only", "freedom", "discipline", "ourselves"],
    words: [
      "in",
      "the",
      "last",
      "analysis",
      "our",
      "only",
      "freedom",
      "is",
      "to",
      "discipline",
      "ourselves",
    ],
    id: "In the last analysis, our only freedom is the freedom to discipline ourselves.",
    author: "Bernard Baruch",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["general", "idea", "rich", "helping", "poor", "i", "important"],
    words: [
      "the",
      "general",
      "idea",
      "of",
      "rich",
      "helping",
      "poor",
      "i",
      "think",
      "is",
      "important",
    ],
    id: "The general idea of the rich helping the poor, I think, is important.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["true", "index", "man's", "character", "health", "his", "wife"],
    words: ["the", "true", "index", "of", "a", "man's", "character", "is", "health", "his", "wife"],
    id: "The true index of a man's character is the health of his wife.",
    author: "Cyril Connolly",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["legislature's", "job", "write", "law", "executive", "branch's", "interpret"],
    words: [
      "the",
      "legislature's",
      "job",
      "is",
      "to",
      "write",
      "law",
      "it's",
      "executive",
      "branch's",
      "interpret",
    ],
    id: "The legislature's job is to write law. It's the executive branch's job to interpret law.",
    author: "George W. Bush",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["first", "trillionaire", "world", "will", "person", "mines", "asteroids"],
    words: [
      "the",
      "first",
      "trillionaire",
      "in",
      "world",
      "will",
      "be",
      "person",
      "who",
      "mines",
      "asteroids",
    ],
    id: "The first trillionaire in the world will be the person who mines asteroids.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "at", "peace", "god", "my", "conflict", "man"],
    words: ["i", "am", "at", "peace", "with", "god", "my", "conflict", "is", "man"],
    id: "I am at peace with God. My conflict is with Man.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["lord", "jesus", "christ", "our", "perfect", "example", "patience"],
    words: ["the", "lord", "jesus", "christ", "is", "our", "perfect", "example", "of", "patience"],
    id: "The Lord, Jesus Christ, is our perfect example of patience.",
    author: "Joseph B. Wirthlin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "instance", "nation", "benefitting", "from", "prolonged", "warfare"],
    words: [
      "there",
      "is",
      "no",
      "instance",
      "of",
      "a",
      "nation",
      "benefitting",
      "from",
      "prolonged",
      "warfare",
    ],
    id: "There is no instance of a nation benefitting from prolonged warfare.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "prisoners", "fate", "only", "their", "own", "minds"],
    words: ["men", "are", "not", "prisoners", "of", "fate", "but", "only", "their", "own", "minds"],
    id: "Men are not prisoners of fate, but only prisoners of their own minds.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "heart", "i'm", "confident", "i", "could", "make"],
    words: [
      "in",
      "my",
      "heart",
      "i'm",
      "confident",
      "i",
      "could",
      "make",
      "a",
      "good",
      "president",
    ],
    id: "In my heart, I'm confident I could make a good president.",
    author: "Joe Biden",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "come", "from", "family", "farmers", "son", "farmer"],
    words: ["i", "come", "from", "the", "family", "of", "farmers", "am", "a", "son", "farmer"],
    id: "I come from the family of farmers. I am a son of a farmer.",
    author: "Pankaj Tripathi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["joy", "will", "which", "labours", "overcomes", "obstacles", "triumph"],
    words: [
      "joy",
      "is",
      "of",
      "the",
      "will",
      "which",
      "labours",
      "overcomes",
      "obstacles",
      "knows",
      "triumph",
    ],
    id: "Joy is of the will which labours, which overcomes obstacles, which knows triumph.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "will", "continue", "believe", "israel's", "security", "paramount"],
    words: [
      "i",
      "will",
      "continue",
      "to",
      "believe",
      "that",
      "israel's",
      "security",
      "is",
      "paramount",
    ],
    id: "I will continue to believe that Israel's security is paramount.",
    author: "Barack Obama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "reached", "immortality", "disturbed", "by", "nothing", "material"],
    words: [
      "that",
      "man",
      "has",
      "reached",
      "immortality",
      "who",
      "is",
      "disturbed",
      "by",
      "nothing",
      "material",
    ],
    id: "That man has reached immortality who is disturbed by nothing material.",
    author: "Swami Vivekananda",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "lord", "saviour", "jesus", "christ", "hate", "anybody"],
    words: ["my", "lord", "and", "saviour", "jesus", "christ", "does", "not", "hate", "anybody"],
    id: "My Lord and saviour Jesus Christ does not hate anybody.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["self-respect", "secure", "feeling", "one", "as", "yet", "suspicious"],
    words: [
      "self-respect",
      "the",
      "secure",
      "feeling",
      "that",
      "no",
      "one",
      "as",
      "yet",
      "is",
      "suspicious",
    ],
    id: "Self-respect: the secure feeling that no one, as yet, is suspicious.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "hate", "being", "famous", "freedom", "can't", "anything"],
    words: [
      "i",
      "hate",
      "being",
      "famous",
      "you",
      "have",
      "no",
      "freedom",
      "can't",
      "do",
      "anything",
    ],
    id: "I hate being famous. You have no freedom. You can't do anything.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["weak", "remedies", "wise", "joys", "superior", "wisdom", "bliss"],
    words: ["the", "weak", "have", "remedies", "wise", "joys", "superior", "wisdom", "is", "bliss"],
    id: "The weak have remedies, the wise have joys; superior wisdom is superior bliss.",
    author: "Edward Young",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["talent", "hits", "target", "one", "else", "hit", "genius"],
    words: ["talent", "hits", "a", "target", "no", "one", "else", "can", "hit", "genius", "see"],
    id: "Talent hits a target no one else can hit; Genius hits a target no one else can see.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "motto", "movie", "depressing", "all", "bad", "movies"],
    words: [
      "my",
      "motto",
      "no",
      "good",
      "movie",
      "is",
      "depressing",
      "all",
      "bad",
      "movies",
      "are",
    ],
    id: "My motto: 'No good movie is depressing. All bad movies are depressing.'",
    author: "Roger Ebert",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["temptation", "irresistible", "force", "at", "on", "movable", "body"],
    words: [
      "temptation",
      "is",
      "an",
      "irresistible",
      "force",
      "at",
      "work",
      "on",
      "a",
      "movable",
      "body",
    ],
    id: "Temptation is an irresistible force at work on a movable body.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["death", "solution", "all", "problems", "man", "-", "problem"],
    words: ["death", "is", "the", "solution", "to", "all", "problems", "no", "man", "-", "problem"],
    id: "Death is the solution to all problems. No man - no problem.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["formula", "two", "make", "five", "without", "its", "attractions"],
    words: [
      "the",
      "formula",
      "two",
      "and",
      "make",
      "five",
      "is",
      "not",
      "without",
      "its",
      "attractions",
    ],
    id: "The formula 'Two and two make five' is not without its attractions.",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["at", "first", "succeed", "then", "skydiving", "definitely", "isn't"],
    words: [
      "if",
      "at",
      "first",
      "you",
      "don't",
      "succeed",
      "then",
      "skydiving",
      "definitely",
      "isn't",
      "for",
    ],
    id: "If at first you don't succeed, then skydiving definitely isn't for you.",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "mean", "didn't", "huge", "upbringing", "movies", "guess"],
    words: ["i", "mean", "didn't", "have", "a", "huge", "upbringing", "with", "movies", "guess"],
    id: "I mean, I didn't have a huge upbringing with movies, I guess.",
    author: "Peter Jackson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["nature", "makes", "only", "dumb", "owe", "fools", "society"],
    words: [
      "nature",
      "makes",
      "only",
      "dumb",
      "animals",
      "we",
      "owe",
      "the",
      "fools",
      "to",
      "society",
    ],
    id: "Nature makes only dumb animals. We owe the fools to society.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "circus", "always", "packing", "up", "moving", "away"],
    words: ["time", "is", "a", "circus", "always", "packing", "up", "and", "moving", "away"],
    id: "Time is a circus, always packing up and moving away.",
    author: "Ben Hecht",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "was", "reading", "dictionary", "thought", "poem", "everything"],
    words: [
      "i",
      "was",
      "reading",
      "the",
      "dictionary",
      "thought",
      "it",
      "a",
      "poem",
      "about",
      "everything",
    ],
    id: "I was reading the dictionary. I thought it was a poem about everything.",
    author: "Steven Wright",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "few", "virtuous", "women", "bored", "their", "trade"],
    words: [
      "there",
      "are",
      "few",
      "virtuous",
      "women",
      "who",
      "not",
      "bored",
      "with",
      "their",
      "trade",
    ],
    id: "There are few virtuous women who are not bored with their trade.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["confidence", "cannot", "find", "place", "wherein", "rest", "safety"],
    words: ["confidence", "cannot", "find", "a", "place", "wherein", "to", "rest", "in", "safety"],
    id: "Confidence cannot find a place wherein to rest in safety.",
    author: "Virgil",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "belief",
      "consists",
      "accepting",
      "affirmations",
      "soul",
      "unbelief",
      "denying",
    ],
    words: [
      "belief",
      "consists",
      "in",
      "accepting",
      "the",
      "affirmations",
      "of",
      "soul",
      "unbelief",
      "denying",
      "them",
    ],
    id: "Belief consists in accepting the affirmations of the soul; unbelief, in denying them.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "nothing", "more", "than", "health", "bad", "memory"],
    words: [
      "happiness",
      "is",
      "nothing",
      "more",
      "than",
      "good",
      "health",
      "and",
      "a",
      "bad",
      "memory",
    ],
    id: "Happiness is nothing more than good health and a bad memory.",
    author: "Albert Schweitzer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["despite", "everything", "i", "believe", "really", "at", "heart"],
    words: [
      "despite",
      "everything",
      "i",
      "believe",
      "that",
      "people",
      "are",
      "really",
      "good",
      "at",
      "heart",
    ],
    id: "Despite everything, I believe that people are really good at heart.",
    author: "Anne Frank",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["repentance", "means", "change", "your", "mind", "deeply", "changes"],
    words: [
      "repentance",
      "means",
      "you",
      "change",
      "your",
      "mind",
      "so",
      "deeply",
      "that",
      "it",
      "changes",
    ],
    id: "Repentance means you change your mind so deeply that it changes you.",
    author: "Bruce Wilkinson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "health", "depends", "on", "environment", "which", "live"],
    words: [
      "our",
      "health",
      "depends",
      "on",
      "the",
      "of",
      "environment",
      "in",
      "which",
      "we",
      "live",
    ],
    id: "Our health depends on the health of the environment in which we live.",
    author: "Albert II, Prince of Monaco",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "obscenity",
      "whatever",
      "happens",
      "shock",
      "elderly",
      "ignorant",
      "magistrate",
    ],
    words: [
      "obscenity",
      "is",
      "whatever",
      "happens",
      "to",
      "shock",
      "some",
      "elderly",
      "and",
      "ignorant",
      "magistrate",
    ],
    id: "Obscenity is whatever happens to shock some elderly and ignorant magistrate.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["history", "world", "none", "than", "progress", "consciousness", "freedom"],
    words: [
      "the",
      "history",
      "of",
      "world",
      "is",
      "none",
      "other",
      "than",
      "progress",
      "consciousness",
      "freedom",
    ],
    id: "The history of the world is none other than the progress of the consciousness of freedom.",
    author: "Georg Wilhelm Friedrich Hegel",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["stories", "driven", "by", "conflict", "tension", "high", "stakes"],
    words: [
      "good",
      "stories",
      "are",
      "driven",
      "by",
      "conflict",
      "tension",
      "and",
      "high",
      "stakes",
    ],
    id: "Good stories are driven by conflict, tension, and high stakes.",
    author: "William Landay",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["fame", "changes", "lot", "things", "can't", "change", "lightbulb"],
    words: [
      "fame",
      "changes",
      "a",
      "lot",
      "of",
      "things",
      "but",
      "it",
      "can't",
      "change",
      "lightbulb",
    ],
    id: "Fame changes a lot of things, but it can't change a lightbulb.",
    author: "Gilda Radner",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["human", "dignity", "based", "upon", "freedom", "one", "presupposes"],
    words: [
      "human",
      "dignity",
      "is",
      "based",
      "upon",
      "freedom",
      "and",
      "the",
      "one",
      "presupposes",
      "other",
    ],
    id: "Human dignity is based upon freedom, and freedom upon human dignity. The one presupposes the other.",
    author: "Rollo May",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "true", "friend", "more", "than", "your", "share"],
    words: ["if", "you", "have", "one", "true", "friend", "more", "than", "your", "share"],
    id: "If you have one true friend you have more than your share.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["thing", "i", "fear", "discriminating", "against", "humor", "truth"],
    words: [
      "the",
      "thing",
      "that",
      "i",
      "fear",
      "discriminating",
      "against",
      "is",
      "humor",
      "and",
      "truth",
    ],
    id: "The thing that I fear discriminating against is humor and truth.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["family", "farms", "small", "businesses", "backbone", "our", "communities"],
    words: [
      "family",
      "farms",
      "and",
      "small",
      "businesses",
      "are",
      "the",
      "backbone",
      "of",
      "our",
      "communities",
    ],
    id: "Family farms and small businesses are the backbone of our communities.",
    author: "Tom Allen",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["enough", "i", "should", "succeed", "-", "others", "fail"],
    words: ["it's", "not", "enough", "that", "i", "should", "succeed", "-", "others", "fail"],
    id: "It's not enough that I should succeed - others should fail.",
    author: "David Merrick",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "all", "things", "difficult", "before", "become", "easy"],
    words: [
      "have",
      "patience",
      "all",
      "things",
      "are",
      "difficult",
      "before",
      "they",
      "become",
      "easy",
    ],
    id: "Have patience. All things are difficult before they become easy.",
    author: "Saadi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["daily", "increase", "decrease", "hack", "away", "at", "unessential"],
    words: [
      "it's",
      "not",
      "the",
      "daily",
      "increase",
      "but",
      "decrease",
      "hack",
      "away",
      "at",
      "unessential",
    ],
    id: "It's not the daily increase but daily decrease. Hack away at the unessential.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["hears", "understands", "becomes", "mine", "possession", "all", "time"],
    words: [
      "who",
      "hears",
      "me",
      "understands",
      "becomes",
      "mine",
      "a",
      "possession",
      "for",
      "all",
      "time",
    ],
    id: "Who hears me, who understands me, becomes mine, a possession for all time.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["wonder", "rather", "than", "doubt", "root", "all", "knowledge"],
    words: ["wonder", "rather", "than", "doubt", "is", "the", "root", "of", "all", "knowledge"],
    id: "Wonder rather than doubt is the root of all knowledge.",
    author: "Abraham Joshua Heschel",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "chaos", "there", "cosmos", "disorder", "secret", "order"],
    words: ["in", "all", "chaos", "there", "is", "a", "cosmos", "disorder", "secret", "order"],
    id: "In all chaos there is a cosmos, in all disorder a secret order.",
    author: "Carl Jung",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["rebellion", "without", "truth", "spring", "bleak", "arid", "desert"],
    words: [
      "rebellion",
      "without",
      "truth",
      "is",
      "like",
      "spring",
      "in",
      "a",
      "bleak",
      "arid",
      "desert",
    ],
    id: "Rebellion without truth is like spring in a bleak, arid desert.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "lies", "joy", "achievement", "thrill", "creative", "effort"],
    words: [
      "happiness",
      "lies",
      "in",
      "the",
      "joy",
      "of",
      "achievement",
      "and",
      "thrill",
      "creative",
      "effort",
    ],
    id: "Happiness lies in the joy of achievement and the thrill of creative effort.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["ignorance", "one", "voter", "democracy", "impairs", "security", "all"],
    words: [
      "the",
      "ignorance",
      "of",
      "one",
      "voter",
      "in",
      "a",
      "democracy",
      "impairs",
      "security",
      "all",
    ],
    id: "The ignorance of one voter in a democracy impairs the security of all.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "father", "truth", "its", "mother", "our", "mind"],
    words: ["time", "is", "the", "father", "of", "truth", "its", "mother", "our", "mind"],
    id: "Time is the father of truth, its mother is our mind.",
    author: "Giordano Bruno",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["rejoicing", "our", "joy", "suffering", "over", "makes", "friend"],
    words: [
      "rejoicing",
      "in",
      "our",
      "joy",
      "not",
      "suffering",
      "over",
      "makes",
      "someone",
      "a",
      "friend",
    ],
    id: "Rejoicing in our joy, not suffering over our suffering, makes someone a friend.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "became", "journalist", "because", "one", "didn't", "specialise"],
    words: [
      "i",
      "became",
      "a",
      "journalist",
      "because",
      "one",
      "didn't",
      "have",
      "to",
      "specialise",
    ],
    id: "I became a journalist because one didn't have to specialise.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["take", "risks", "will", "suffer", "defeats", "win", "victories"],
    words: [
      "if",
      "you",
      "take",
      "no",
      "risks",
      "will",
      "suffer",
      "defeats",
      "but",
      "win",
      "victories",
    ],
    id: "If you take no risks, you will suffer no defeats. But if you take no risks, you win no victories.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["nobody", "should", "come", "movies", "unless", "believes", "heroes"],
    words: [
      "nobody",
      "should",
      "come",
      "to",
      "the",
      "movies",
      "unless",
      "he",
      "believes",
      "in",
      "heroes",
    ],
    id: "Nobody should come to the movies unless he believes in heroes.",
    author: "John Wayne",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "nothing", "offer", "blood", "toil", "tears", "sweat"],
    words: ["i", "have", "nothing", "to", "offer", "but", "blood", "toil", "tears", "and", "sweat"],
    id: "I have nothing to offer but blood, toil, tears and sweat.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["we've", "got", "put", "lot", "into", "changing", "behavior"],
    words: ["we've", "got", "to", "put", "a", "lot", "of", "money", "into", "changing", "behavior"],
    id: "We've got to put a lot of money into changing behavior.",
    author: "Bill Gates",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["wish", "readily", "believe", "ourselves", "imagine", "others", "also"],
    words: [
      "what",
      "we",
      "wish",
      "readily",
      "believe",
      "and",
      "ourselves",
      "think",
      "imagine",
      "others",
      "also",
    ],
    id: "What we wish, we readily believe, and what we ourselves think, we imagine others think also.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["great", "genius", "ever", "existed", "without", "touch", "madness"],
    words: [
      "no",
      "great",
      "genius",
      "has",
      "ever",
      "existed",
      "without",
      "some",
      "touch",
      "of",
      "madness",
    ],
    id: "No great genius has ever existed without some touch of madness.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["friendship", "composed", "single", "soul", "inhabiting", "two", "bodies"],
    words: [
      "friendship",
      "is",
      "composed",
      "of",
      "a",
      "single",
      "soul",
      "inhabiting",
      "two",
      "bodies",
    ],
    id: "Friendship is composed of a single soul inhabiting two bodies.",
    author: "Plautus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "played", "by", "rules", "politics", "as", "found"],
    words: ["i", "played", "by", "the", "rules", "of", "politics", "as", "found", "them"],
    id: "I played by the rules of politics as I found them.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "crown",
      "life",
      "neither",
      "happiness",
      "nor",
      "annihilation",
      "understanding",
    ],
    words: [
      "the",
      "crown",
      "of",
      "life",
      "is",
      "neither",
      "happiness",
      "nor",
      "annihilation",
      "it",
      "understanding",
    ],
    id: "The crown of life is neither happiness nor annihilation; it is understanding.",
    author: "Winifred Holtby",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["almost", "all", "our", "sorrows", "spring", "out", "relations"],
    words: [
      "almost",
      "all",
      "of",
      "our",
      "sorrows",
      "spring",
      "out",
      "relations",
      "with",
      "other",
      "people",
    ],
    id: "Almost all of our sorrows spring out of our relations with other people.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "way", "keep", "momentum", "constantly", "greater", "goals"],
    words: [
      "one",
      "way",
      "to",
      "keep",
      "momentum",
      "going",
      "is",
      "have",
      "constantly",
      "greater",
      "goals",
    ],
    id: "One way to keep momentum going is to have constantly greater goals.",
    author: "Michael Korda",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["use", "walking", "anywhere", "preach", "unless", "our", "preaching"],
    words: [
      "it",
      "is",
      "no",
      "use",
      "walking",
      "anywhere",
      "to",
      "preach",
      "unless",
      "our",
      "preaching",
    ],
    id: "It is no use walking anywhere to preach unless our walking is our preaching.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "crush", "ideas", "by", "suppressing", "only", "ignoring"],
    words: [
      "you",
      "can't",
      "crush",
      "ideas",
      "by",
      "suppressing",
      "them",
      "can",
      "only",
      "ignoring",
    ],
    id: "You can't crush ideas by suppressing them. You can only crush them by ignoring them.",
    author: "Vachel Lindsay",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["ignorance", "bungling", "better", "than", "wisdom", "skill", "without"],
    words: [
      "ignorance",
      "and",
      "bungling",
      "with",
      "love",
      "are",
      "better",
      "than",
      "wisdom",
      "skill",
      "without",
    ],
    id: "Ignorance and bungling with love are better than wisdom and skill without.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["heart", "great", "which", "shows", "moderation", "midst", "prosperity"],
    words: [
      "the",
      "heart",
      "is",
      "great",
      "which",
      "shows",
      "moderation",
      "in",
      "midst",
      "of",
      "prosperity",
    ],
    id: "The heart is great which shows moderation in the midst of prosperity.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "isn't", "everything", "makes", "man", "stand", "straight"],
    words: [
      "success",
      "isn't",
      "everything",
      "but",
      "it",
      "makes",
      "a",
      "man",
      "stand",
      "straight",
    ],
    id: "Success isn't everything but it makes a man stand straight.",
    author: "Lillian Hellman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["service", "noblest", "which", "rendered", "its", "own", "sake"],
    words: [
      "that",
      "service",
      "is",
      "the",
      "noblest",
      "which",
      "rendered",
      "for",
      "its",
      "own",
      "sake",
    ],
    id: "That service is the noblest which is rendered for its own sake.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "desire", "future", "will", "break", "ties", "past"],
    words: ["i", "desire", "no", "future", "that", "will", "break", "the", "ties", "with", "past"],
    id: "I desire no future that will break the ties with the past.",
    author: "George Eliot",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "really", "simple", "insist", "on", "making", "complicated"],
    words: [
      "life",
      "is",
      "really",
      "simple",
      "but",
      "we",
      "insist",
      "on",
      "making",
      "it",
      "complicated",
    ],
    id: "Life is really simple, but we insist on making it complicated.",
    author: "Confucius",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "poured", "spot", "remover", "on", "my", "gone"],
    words: ["i", "poured", "spot", "remover", "on", "my", "dog", "now", "he's", "gone"],
    id: "I poured spot remover on my dog. Now he's gone.",
    author: "Steven Wright",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["before", "without", "knowledge", "saying", "lies", "present", "evidence"],
    words: [
      "before",
      "speaking",
      "without",
      "knowledge",
      "saying",
      "lies",
      "you",
      "have",
      "to",
      "present",
      "evidence",
    ],
    id: "Before speaking without knowledge, saying lies, you have to present evidence.",
    author: "Neymar",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["bad", "news", "travels", "at", "speed", "light", "molasses"],
    words: [
      "bad",
      "news",
      "travels",
      "at",
      "the",
      "speed",
      "of",
      "light",
      "good",
      "like",
      "molasses",
    ],
    id: "Bad news travels at the speed of light; good news travels like molasses.",
    author: "Tracy Morgan",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["on", "our", "plane", "knowledge", "ignorance", "immemorial", "adversaries"],
    words: [
      "on",
      "our",
      "plane",
      "knowledge",
      "and",
      "ignorance",
      "are",
      "the",
      "immemorial",
      "adversaries",
    ],
    id: "On our plane knowledge and ignorance are the immemorial adversaries.",
    author: "Frederick Soddy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "name", "harvey", "milk", "i'm", "here", "recruit"],
    words: ["my", "name", "is", "harvey", "milk", "and", "i'm", "here", "to", "recruit", "you"],
    id: "My name is Harvey Milk and I'm here to recruit you.",
    author: "Harvey Milk",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["secret", "creativity", "knowing", "how", "hide", "your", "sources"],
    words: ["the", "secret", "to", "creativity", "is", "knowing", "how", "hide", "your", "sources"],
    id: "The secret to creativity is knowing how to hide your sources.",
    author: "Unknown",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["weed", "plant", "whose", "virtues", "never", "been", "discovered"],
    words: [
      "what",
      "is",
      "a",
      "weed",
      "plant",
      "whose",
      "virtues",
      "have",
      "never",
      "been",
      "discovered",
    ],
    id: "What is a weed? A plant whose virtues have never been discovered.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["dreams", "often", "most", "profound", "when", "seem", "crazy"],
    words: ["dreams", "are", "often", "most", "profound", "when", "they", "seem", "the", "crazy"],
    id: "Dreams are often most profound when they seem the most crazy.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["wars", "nations", "fought", "change", "maps", "poverty", "map"],
    words: [
      "wars",
      "of",
      "nations",
      "are",
      "fought",
      "to",
      "change",
      "maps",
      "but",
      "poverty",
      "map",
    ],
    id: "Wars of nations are fought to change maps. But wars of poverty are fought to map change.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["spirit", "truth", "freedom", "-", "these", "pillars", "society"],
    words: [
      "the",
      "spirit",
      "of",
      "truth",
      "and",
      "freedom",
      "-",
      "these",
      "are",
      "pillars",
      "society",
    ],
    id: "The spirit of truth and the spirit of freedom - these are the pillars of society.",
    author: "Henrik Ibsen",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["desire", "seem", "clever", "often", "keeps", "from", "being"],
    words: ["the", "desire", "to", "seem", "clever", "often", "keeps", "us", "from", "being", "so"],
    id: "The desire to seem clever often keeps us from being so.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["memories", "our", "lives", "deeds", "will", "continue", "others"],
    words: [
      "memories",
      "of",
      "our",
      "lives",
      "works",
      "and",
      "deeds",
      "will",
      "continue",
      "in",
      "others",
    ],
    id: "Memories of our lives, of our works and our deeds will continue in others.",
    author: "Rosa Parks",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["children", "our", "second", "chance", "great", "parent-child", "relationship"],
    words: [
      "children",
      "are",
      "our",
      "second",
      "chance",
      "to",
      "have",
      "a",
      "great",
      "parent-child",
      "relationship",
    ],
    id: "Children are our second chance to have a great parent-child relationship.",
    author: "Laura Schlessinger",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "reject", "cynical", "view", "politics", "dirty", "business"],
    words: [
      "i",
      "reject",
      "the",
      "cynical",
      "view",
      "that",
      "politics",
      "is",
      "a",
      "dirty",
      "business",
    ],
    id: "I reject the cynical view that politics is a dirty business.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "moderately", "play", "badly", "written", "third", "act"],
    words: [
      "life",
      "is",
      "a",
      "moderately",
      "good",
      "play",
      "with",
      "badly",
      "written",
      "third",
      "act",
    ],
    id: "Life is a moderately good play with a badly written third act.",
    author: "Truman Capote",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["prayer", "god's", "backstage", "pass", "into", "personal", "audience"],
    words: [
      "prayer",
      "is",
      "god's",
      "backstage",
      "pass",
      "into",
      "a",
      "personal",
      "audience",
      "with",
      "him",
    ],
    id: "Prayer is God's backstage pass into a personal audience with Him.",
    author: "Tony Evans",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["must", "welcome", "change", "as", "rule", "your", "ruler"],
    words: ["you", "must", "welcome", "change", "as", "the", "rule", "but", "not", "your", "ruler"],
    id: "You must welcome change as the rule but not as your ruler.",
    author: "Denis Waitley",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["finishing", "second", "olympics", "gets", "silver", "politics", "oblivion"],
    words: [
      "finishing",
      "second",
      "in",
      "the",
      "olympics",
      "gets",
      "you",
      "silver",
      "politics",
      "oblivion",
    ],
    id: "Finishing second in the Olympics gets you silver. Finishing second in politics gets you oblivion.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'd", "describe", "myself", "as", "christian", "believe", "god"],
    words: [
      "i'd",
      "describe",
      "myself",
      "as",
      "a",
      "christian",
      "who",
      "doesn't",
      "believe",
      "in",
      "god",
    ],
    id: "I'd describe myself as a Christian who doesn't believe in God.",
    author: "Helen Mirren",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["person's", "fears", "lighter", "when", "danger", "at", "hand"],
    words: [
      "a",
      "person's",
      "fears",
      "are",
      "lighter",
      "when",
      "the",
      "danger",
      "is",
      "at",
      "hand",
    ],
    id: "A person's fears are lighter when the danger is at hand.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["luck", "chance", "toil", "fortune's", "expensive", "smile", "earned"],
    words: [
      "luck",
      "is",
      "not",
      "chance",
      "it's",
      "toil",
      "fortune's",
      "expensive",
      "smile",
      "earned",
    ],
    id: "Luck is not chance, it's toil; fortune's expensive smile is earned.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["writing", "free", "verse", "playing", "tennis", "net", "down"],
    words: [
      "writing",
      "free",
      "verse",
      "is",
      "like",
      "playing",
      "tennis",
      "with",
      "the",
      "net",
      "down",
    ],
    id: "Writing free verse is like playing tennis with the net down.",
    author: "Robert Frost",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "write", "books", "god", "writes", "delivers", "speeches"],
    words: ["i", "don't", "write", "the", "books", "god", "writes", "and", "delivers", "speeches"],
    id: "I don't write the books. God writes the books and delivers the speeches.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "even", "happiness", "-", "makes", "heart", "afraid"],
    words: [
      "there",
      "is",
      "even",
      "a",
      "happiness",
      "-",
      "that",
      "makes",
      "the",
      "heart",
      "afraid",
    ],
    id: "There is even a happiness - that makes the heart afraid.",
    author: "Thomas Hood",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["everyone", "exalts", "himself", "will", "humbled", "humbles", "exalted"],
    words: [
      "for",
      "everyone",
      "who",
      "exalts",
      "himself",
      "will",
      "be",
      "humbled",
      "and",
      "humbles",
      "exalted",
    ],
    id: "For everyone who exalts himself will be humbled, and everyone who humbles himself will be exalted.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "necessity",
      "blind",
      "until",
      "becomes",
      "conscious",
      "freedom",
      "consciousness",
    ],
    words: [
      "necessity",
      "is",
      "blind",
      "until",
      "it",
      "becomes",
      "conscious",
      "freedom",
      "the",
      "consciousness",
      "of",
    ],
    id: "Necessity is blind until it becomes conscious. Freedom is the consciousness of necessity.",
    author: "Karl Marx",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["preach", "better", "sermon", "your", "life", "than", "lips"],
    words: [
      "you",
      "can",
      "preach",
      "a",
      "better",
      "sermon",
      "with",
      "your",
      "life",
      "than",
      "lips",
    ],
    id: "You can preach a better sermon with your life than with your lips.",
    author: "Oliver Goldsmith",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "all", "time", "tombstone", "will", "my", "diploma"],
    words: [
      "i",
      "am",
      "learning",
      "all",
      "the",
      "time",
      "tombstone",
      "will",
      "be",
      "my",
      "diploma",
    ],
    id: "I am learning all the time. The tombstone will be my diploma.",
    author: "Eartha Kitt",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["why", "jewish", "men", "die", "before", "their", "wives"],
    words: ["why", "do", "jewish", "men", "die", "before", "their", "wives", "they", "want", "to"],
    id: "Why do Jewish men die before their wives? They want to.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["pride", "deafens", "advice", "or", "warnings", "those", "around"],
    words: [
      "pride",
      "deafens",
      "us",
      "to",
      "the",
      "advice",
      "or",
      "warnings",
      "of",
      "those",
      "around",
    ],
    id: "Pride deafens us to the advice or warnings of those around us.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["ant", "on", "move", "more", "than", "dozing", "ox"],
    words: ["an", "ant", "on", "the", "move", "does", "more", "than", "a", "dozing", "ox"],
    id: "An ant on the move does more than a dozing ox.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "striker", "any", "chance", "i", "get", "score"],
    words: ["i'm", "a", "striker", "and", "any", "chance", "i", "get", "want", "to", "score"],
    id: "I'm a striker and any chance I get I want to score.",
    author: "Rickie Lambert",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["glory", "being", "carrier", "pilot", "certainly", "worn", "off"],
    words: [
      "the",
      "glory",
      "of",
      "being",
      "a",
      "carrier",
      "pilot",
      "has",
      "certainly",
      "worn",
      "off",
    ],
    id: "The glory of being a carrier pilot has certainly worn off.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "nothing", "wrong", "anger", "provided", "use", "constructively"],
    words: [
      "there's",
      "nothing",
      "wrong",
      "with",
      "anger",
      "provided",
      "you",
      "use",
      "it",
      "constructively",
    ],
    id: "There's nothing wrong with anger provided you use it constructively.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "success",
      "progressive",
      "realization",
      "predetermined",
      "worthwhile",
      "personal",
      "goals",
    ],
    words: [
      "success",
      "is",
      "the",
      "progressive",
      "realization",
      "of",
      "predetermined",
      "worthwhile",
      "personal",
      "goals",
    ],
    id: "Success is the progressive realization of predetermined, worthwhile, personal goals.",
    author: "Paul J. Meyer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["freedom", "-", "occupied", "space", "which", "must", "reoccupied"],
    words: [
      "freedom",
      "-",
      "an",
      "occupied",
      "space",
      "which",
      "must",
      "be",
      "reoccupied",
      "every",
      "day",
    ],
    id: "Freedom - an occupied space which must be reoccupied every day.",
    author: "John Ralston Saul",
    grammar: null,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["many", "total", "abstinence", "easier", "than", "perfect", "moderation"],
    words: ["to", "many", "total", "abstinence", "is", "easier", "than", "perfect", "moderation"],
    id: "To many, total abstinence is easier than perfect moderation.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "exclusive", "sign", "thorough", "knowledge", "power", "teaching"],
    words: [
      "the",
      "one",
      "exclusive",
      "sign",
      "of",
      "thorough",
      "knowledge",
      "is",
      "power",
      "teaching",
    ],
    id: "The one exclusive sign of thorough knowledge is the power of teaching.",
    author: "Aristotle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["impatient", "person", "plays", "differently", "than", "more", "patient"],
    words: ["an", "impatient", "person", "plays", "differently", "than", "a", "more", "patient"],
    id: "An impatient person plays differently than a more patient person.",
    author: "Vladimir Kramnik",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["taunt", "alligator", "until", "after", "you've", "crossed", "creek"],
    words: ["don't", "taunt", "the", "alligator", "until", "after", "you've", "crossed", "creek"],
    id: "Don't taunt the alligator until after you've crossed the creek.",
    author: "Dan Rather",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "achievements", "earned", "riches", "their", "beginning", "idea"],
    words: [
      "all",
      "achievements",
      "earned",
      "riches",
      "have",
      "their",
      "beginning",
      "in",
      "an",
      "idea",
    ],
    id: "All achievements, all earned riches, have their beginning in an idea.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["wave", "evil", "washes", "all", "our", "institutions", "alike"],
    words: ["the", "wave", "of", "evil", "washes", "all", "our", "institutions", "alike"],
    id: "The wave of evil washes all our institutions alike.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "nothing",
      "strengthens",
      "judgment",
      "quickens",
      "conscience",
      "individual",
      "responsibility",
    ],
    words: [
      "nothing",
      "strengthens",
      "the",
      "judgment",
      "and",
      "quickens",
      "conscience",
      "like",
      "individual",
      "responsibility",
    ],
    id: "Nothing strengthens the judgment and quickens the conscience like individual responsibility.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "best",
      "wisdom",
      "earned",
      "through",
      "experience",
      "particularly",
      "mistakes",
    ],
    words: [
      "the",
      "best",
      "wisdom",
      "is",
      "earned",
      "through",
      "experience",
      "particularly",
      "mistakes",
    ],
    id: "The best wisdom is earned through experience, particularly mistakes.",
    author: "Sophia Amoruso",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "cannot", "trust", "man", "control", "others", "himself"],
    words: ["i", "cannot", "trust", "a", "man", "to", "control", "others", "who", "himself"],
    id: "I cannot trust a man to control others who cannot control himself.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["unlike", "grown", "ups", "children", "little", "deceive", "themselves"],
    words: [
      "unlike",
      "grown",
      "ups",
      "children",
      "have",
      "little",
      "need",
      "to",
      "deceive",
      "themselves",
    ],
    id: "Unlike grown ups, children have little need to deceive themselves.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "on", "your", "side", "everything", "becomes", "clear"],
    words: ["if", "you", "have", "god", "on", "your", "side", "everything", "becomes", "clear"],
    id: "If you have God on your side, everything becomes clear.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "minister", "i", "preach", "forgiveness", "all", "time"],
    words: ["i'm", "a", "minister", "i", "preach", "forgiveness", "all", "the", "time"],
    id: "I'm a minister, I preach forgiveness all the time.",
    author: "Ted DiBiase Sr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "prevents", "everything", "from", "happening", "at", "once"],
    words: ["time", "is", "what", "prevents", "everything", "from", "happening", "at", "once"],
    id: "Time is what prevents everything from happening at once.",
    author: "John Archibald Wheeler",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["follow", "your", "instincts", "true", "wisdom", "manifests", "itself"],
    words: [
      "follow",
      "your",
      "instincts",
      "that's",
      "where",
      "true",
      "wisdom",
      "manifests",
      "itself",
    ],
    id: "Follow your instincts. That's where true wisdom manifests itself.",
    author: "Oprah Winfrey",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["means", "use", "must", "as", "pure", "ends", "seek"],
    words: ["means", "we", "use", "must", "be", "as", "pure", "the", "ends", "seek"],
    id: "Means we use must be as pure as the ends we seek.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["money's", "cheapest", "thing", "liberty", "freedom", "most", "expensive"],
    words: [
      "money's",
      "the",
      "cheapest",
      "thing",
      "liberty",
      "and",
      "freedom",
      "is",
      "most",
      "expensive",
    ],
    id: "Money's the cheapest thing. Liberty and freedom is the most expensive.",
    author: "Bill Cunningham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["pride", "there", "many", "curses", "humility", "come", "blessings"],
    words: ["with", "pride", "there", "are", "many", "curses", "humility", "come", "blessings"],
    id: "With pride, there are many curses. With humility, there come many blessings.",
    author: "Ezra Taft Benson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "human",
      "self-understanding",
      "changes",
      "time",
      "also",
      "consciousness",
      "deepens",
    ],
    words: [
      "human",
      "self-understanding",
      "changes",
      "with",
      "time",
      "and",
      "so",
      "also",
      "consciousness",
      "deepens",
    ],
    id: "Human self-understanding changes with time, and so also human consciousness deepens.",
    author: "Pope Francis",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "always", "wanted", "make", "movies", "fever", "dreams"],
    words: ["i've", "always", "wanted", "to", "make", "movies", "that", "are", "fever", "dreams"],
    id: "I've always wanted to make movies that are fever dreams.",
    author: "Damien Chazelle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "difficult", "crime", "track", "one", "which", "purposeless"],
    words: [
      "the",
      "most",
      "difficult",
      "crime",
      "to",
      "track",
      "is",
      "one",
      "which",
      "purposeless",
    ],
    id: "The most difficult crime to track is the one which is purposeless.",
    author: "Arthur Conan Doyle",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "patience",
      "persistence",
      "perspiration",
      "make",
      "unbeatable",
      "combination",
      "success",
    ],
    words: [
      "patience",
      "persistence",
      "and",
      "perspiration",
      "make",
      "an",
      "unbeatable",
      "combination",
      "for",
      "success",
    ],
    id: "Patience, persistence and perspiration make an unbeatable combination for success.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["tyrant", "dies", "his", "rule", "over", "martyr", "begins"],
    words: ["the", "tyrant", "dies", "and", "his", "rule", "is", "over", "martyr", "begins"],
    id: "The tyrant dies and his rule is over, the martyr dies and his rule begins.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["martin", "luther", "king", "really", "his", "birthday", "commercialized"],
    words: [
      "does",
      "martin",
      "luther",
      "king",
      "really",
      "want",
      "his",
      "birthday",
      "commercialized",
    ],
    id: "Does Martin Luther King really want his birthday commercialized?",
    author: "Jidenna",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["marriage", "must", "relation", "either", "sympathy", "or", "conquest"],
    words: [
      "marriage",
      "must",
      "be",
      "a",
      "relation",
      "either",
      "of",
      "sympathy",
      "or",
      "conquest",
    ],
    id: "Marriage must be a relation either of sympathy or of conquest.",
    author: "George Eliot",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["quick", "temper", "will", "make", "fool", "soon", "enough"],
    words: ["a", "quick", "temper", "will", "make", "fool", "of", "you", "soon", "enough"],
    id: "A quick temper will make a fool of you soon enough.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "nothing", "bigger", "or", "older", "than", "universe"],
    words: ["there", "is", "nothing", "bigger", "or", "older", "than", "the", "universe"],
    id: "There is nothing bigger or older than the universe.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "victory", "right", "around", "corner", "never", "up"],
    words: ["your", "victory", "is", "right", "around", "the", "corner", "never", "give", "up"],
    id: "Your victory is right around the corner. Never give up.",
    author: "Nicki Minaj",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "things", "visible", "highest", "heaven", "fixed", "stars"],
    words: ["of", "all", "things", "visible", "the", "highest", "is", "heaven", "fixed", "stars"],
    id: "Of all things visible, the highest is the heaven of the fixed stars.",
    author: "Nicolaus Copernicus",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["secret", "your", "success", "determined", "by", "daily", "agenda"],
    words: ["the", "secret", "of", "your", "success", "is", "determined", "by", "daily", "agenda"],
    id: "The secret of your success is determined by your daily agenda.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "cherish",
      "your",
      "human",
      "connections",
      "relationships",
      "friends",
      "family",
    ],
    words: [
      "cherish",
      "your",
      "human",
      "connections",
      "relationships",
      "with",
      "friends",
      "and",
      "family",
    ],
    id: "Cherish your human connections: your relationships with friends and family.",
    author: "Joseph Brodsky",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "doubt", "deserved", "my", "enemies", "believe", "friends"],
    words: ["i", "no", "doubt", "deserved", "my", "enemies", "but", "don't", "believe", "friends"],
    id: "I no doubt deserved my enemies, but I don't believe I deserved my friends.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["degree", "one's", "emotions", "varies", "inversely", "knowledge", "facts"],
    words: [
      "the",
      "degree",
      "of",
      "one's",
      "emotions",
      "varies",
      "inversely",
      "with",
      "knowledge",
      "facts",
    ],
    id: "The degree of one's emotions varies inversely with one's knowledge of the facts.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "test", "correctness", "educational", "procedure", "happiness", "child"],
    words: [
      "one",
      "test",
      "of",
      "the",
      "correctness",
      "educational",
      "procedure",
      "is",
      "happiness",
      "child",
    ],
    id: "One test of the correctness of educational procedure is the happiness of the child.",
    author: "Maria Montessori",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["before", "alamein", "never", "had", "victory", "after", "defeat"],
    words: ["before", "alamein", "we", "never", "had", "a", "victory", "after", "defeat"],
    id: "Before Alamein we never had a victory. After Alamein we never had a defeat.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["little", "goals", "expect", "achievements", "big", "win", "success"],
    words: ["think", "little", "goals", "and", "expect", "achievements", "big", "win", "success"],
    id: "Think little goals and expect little achievements. Think big goals and win big success.",
    author: "David Joseph Schwartz",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["was", "emotional", "when", "chrysler", "sold", "out", "germans"],
    words: ["it", "was", "emotional", "when", "chrysler", "sold", "out", "to", "the", "germans"],
    id: "It was emotional when Chrysler sold out to the Germans.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["justice", "cannot", "one", "side", "alone", "must", "both"],
    words: ["justice", "cannot", "be", "for", "one", "side", "alone", "but", "must", "both"],
    id: "Justice cannot be for one side alone, but must be for both.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["history", "all", "previous", "societies", "been", "class", "struggles"],
    words: [
      "the",
      "history",
      "of",
      "all",
      "previous",
      "societies",
      "has",
      "been",
      "class",
      "struggles",
    ],
    id: "The history of all previous societies has been the history of class struggles.",
    author: "Karl Marx",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["non-violence", "requires", "double", "faith", "god", "also", "man"],
    words: ["non-violence", "requires", "a", "double", "faith", "in", "god", "and", "also", "man"],
    id: "Non-violence requires a double faith, faith in God and also faith in man.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "power", "god's", "gospel", "beyond", "all", "description"],
    words: ["there", "is", "a", "power", "in", "god's", "gospel", "beyond", "all", "description"],
    id: "There is a power in God's gospel beyond all description.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "destroy", "my", "enemies", "when", "make", "friends"],
    words: ["do", "i", "not", "destroy", "my", "enemies", "when", "make", "them", "friends"],
    id: "Do I not destroy my enemies when I make them my friends?",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["cannot", "more", "sensitive", "pleasure", "without", "being", "pain"],
    words: [
      "we",
      "cannot",
      "be",
      "more",
      "sensitive",
      "to",
      "pleasure",
      "without",
      "being",
      "pain",
    ],
    id: "We cannot be more sensitive to pleasure without being more sensitive to pain.",
    author: "Alan Watts",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "born", "ignorant", "stupid", "made", "by", "education"],
    words: ["men", "are", "born", "ignorant", "not", "stupid", "they", "made", "by", "education"],
    id: "Men are born ignorant, not stupid. They are made stupid by education.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["hero-worship", "strongest", "there", "least", "regard", "human", "freedom"],
    words: [
      "hero-worship",
      "is",
      "strongest",
      "where",
      "there",
      "least",
      "regard",
      "for",
      "human",
      "freedom",
    ],
    id: "Hero-worship is strongest where there is least regard for human freedom.",
    author: "Herbert Spencer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "flies", "over", "leaves", "its", "shadow", "behind"],
    words: ["time", "flies", "over", "us", "but", "leaves", "its", "shadow", "behind"],
    id: "Time flies over us, but leaves its shadow behind.",
    author: "Nathaniel Hawthorne",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["at", "bottom", "enmity", "between", "strangers", "lies", "indifference"],
    words: ["at", "the", "bottom", "of", "enmity", "between", "strangers", "lies", "indifference"],
    id: "At the bottom of enmity between strangers lies indifference.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["true", "patriotism", "better", "than", "wrong", "kind", "piety"],
    words: ["true", "patriotism", "is", "better", "than", "the", "wrong", "kind", "of", "piety"],
    id: "True patriotism is better than the wrong kind of piety.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "design",
      "making",
      "something",
      "intelligible",
      "memorable",
      "great",
      "meaningful",
    ],
    words: [
      "good",
      "design",
      "is",
      "making",
      "something",
      "intelligible",
      "and",
      "memorable",
      "great",
      "meaningful",
    ],
    id: "Good design is making something intelligible and memorable. Great design is making something memorable and meaningful.",
    author: "Dieter Rams",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "blame", "others", "up", "your", "power", "change"],
    words: ["when", "you", "blame", "others", "give", "up", "your", "power", "to", "change"],
    id: "When you blame others, you give up your power to change.",
    author: "Robert Anthony",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "powers", "ordinary", "only", "application", "brings", "success"],
    words: ["my", "powers", "are", "ordinary", "only", "application", "brings", "me", "success"],
    id: "My powers are ordinary. Only my application brings me success.",
    author: "Isaac Newton",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "making", "movies", "kids", "or", "family", "audiences"],
    words: ["i'm", "not", "making", "movies", "for", "kids", "or", "family", "audiences"],
    id: "I'm not making movies for kids or family audiences.",
    author: "Thiagarajan Kumararaja",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["man's", "true", "character", "comes", "out", "when", "drunk"],
    words: ["a", "man's", "true", "character", "comes", "out", "when", "he's", "drunk"],
    id: "A man's true character comes out when he's drunk.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["approach", "liberty", "makes", "even", "old", "man", "brave"],
    words: ["the", "approach", "of", "liberty", "makes", "even", "an", "old", "man", "brave"],
    id: "The approach of liberty makes even an old man brave.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "must", "choose", "life", "between", "boredom", "suffering"],
    words: ["one", "must", "choose", "in", "life", "between", "boredom", "and", "suffering"],
    id: "One must choose in life between boredom and suffering.",
    author: "Madame de Stael",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["rough", "diamonds", "may", "sometimes", "mistaken", "worthless", "pebbles"],
    words: [
      "rough",
      "diamonds",
      "may",
      "sometimes",
      "be",
      "mistaken",
      "for",
      "worthless",
      "pebbles",
    ],
    id: "Rough diamonds may sometimes be mistaken for worthless pebbles.",
    author: "Thomas Browne",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "swain", "scorns", "will", "soon", "find", "another"],
    words: ["if", "one", "swain", "scorns", "you", "will", "soon", "find", "another"],
    id: "If one swain scorns you, you will soon find another.",
    author: "Virgil",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "take", "hold", "things", "by", "smooth", "handle"],
    words: ["always", "take", "hold", "of", "things", "by", "the", "smooth", "handle"],
    id: "Always take hold of things by the smooth handle.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["skating", "over", "thin", "ice", "our", "safety", "speed"],
    words: ["in", "skating", "over", "thin", "ice", "our", "safety", "is", "speed"],
    id: "In skating over thin ice our safety is in our speed.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "things", "quickly", "because", "i'm", "easily", "bored"],
    words: ["i", "like", "to", "do", "things", "quickly", "because", "i'm", "easily", "bored"],
    id: "I like to do things quickly because I'm easily bored.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "find", "best", "birthday", "plans", "unplanned", "ones"],
    words: ["i", "find", "the", "best", "birthday", "plans", "are", "unplanned", "ones"],
    id: "I find the best birthday plans are the unplanned ones.",
    author: "Diana Penty",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "always", "been", "democrat", "runs", "my", "family"],
    words: ["i've", "always", "been", "a", "democrat", "it", "runs", "in", "my", "family"],
    id: "I've always been a Democrat; it runs in my family.",
    author: "Sarah Vaughan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["shall", "i", "destroyed", "my", "preservers", "return", "home"],
    words: ["shall", "i", "that", "have", "destroyed", "my", "preservers", "return", "home"],
    id: "Shall I, that have destroyed my Preservers, return home?",
    author: "Alexander the Great",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["innocence", "find", "near", "much", "protection", "as", "guilt"],
    words: ["innocence", "does", "not", "find", "near", "so", "much", "protection", "as", "guilt"],
    id: "Innocence does not find near so much protection as guilt.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["passion", "makes", "idiots", "cleverest", "men", "biggest", "clever"],
    words: [
      "passion",
      "makes",
      "idiots",
      "of",
      "the",
      "cleverest",
      "men",
      "and",
      "biggest",
      "clever",
    ],
    id: "Passion makes idiots of the cleverest men, and makes the biggest idiots clever.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["more", "often", "treacherous", "through", "weakness", "than", "calculation"],
    words: [
      "we",
      "are",
      "more",
      "often",
      "treacherous",
      "through",
      "weakness",
      "than",
      "calculation",
    ],
    id: "We are more often treacherous through weakness than through calculation.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "much", "pleasure", "gained", "from", "useless", "knowledge"],
    words: [
      "there",
      "is",
      "much",
      "pleasure",
      "to",
      "be",
      "gained",
      "from",
      "useless",
      "knowledge",
    ],
    id: "There is much pleasure to be gained from useless knowledge.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["trying", "define", "yourself", "bite", "your", "own", "teeth"],
    words: ["trying", "to", "define", "yourself", "is", "like", "bite", "your", "own", "teeth"],
    id: "Trying to define yourself is like trying to bite your own teeth.",
    author: "Alan Watts",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["brave", "fearless", "god's", "sake", "stand", "up", "yourself"],
    words: ["be", "brave", "and", "fearless", "for", "god's", "sake", "stand", "up", "yourself"],
    id: "Be brave and be fearless, and for God's sake, stand up for yourself.",
    author: "Gretchen Carlson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "being", "pained", "body", "or", "troubled", "mind"],
    words: ["happiness", "is", "not", "being", "pained", "in", "body", "or", "troubled", "mind"],
    id: "Happiness is not being pained in body or troubled in mind.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "there", "will", "pcs", "at", "price", "point"],
    words: ["i", "think", "there", "will", "be", "pcs", "at", "every", "price", "point"],
    id: "I think there will be PCs at every price point.",
    author: "Bill Gates",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "suppose", "my", "ideal", "brain", "food", "languages"],
    words: ["i", "suppose", "my", "ideal", "brain", "food", "is", "learning", "languages"],
    id: "I suppose my ideal brain food is learning languages.",
    author: "John Grant",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "secret", "staying", "young", "having", "sense", "time"],
    words: ["my", "secret", "to", "staying", "young", "having", "no", "sense", "of", "time"],
    id: "My secret to staying young... Having no sense of time.",
    author: "Steven Wright",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["quality", "means", "doing", "right", "when", "one", "looking"],
    words: ["quality", "means", "doing", "it", "right", "when", "no", "one", "is", "looking"],
    id: "Quality means doing it right when no one is looking.",
    author: "Henry Ford",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["marriage", "neither", "heaven", "nor", "hell", "simply", "purgatory"],
    words: ["marriage", "is", "neither", "heaven", "nor", "hell", "it", "simply", "purgatory"],
    id: "Marriage is neither heaven nor hell, it is simply purgatory.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["exist", "change", "mature", "on", "creating", "oneself", "endlessly"],
    words: [
      "to",
      "exist",
      "is",
      "change",
      "mature",
      "go",
      "on",
      "creating",
      "oneself",
      "endlessly",
    ],
    id: "To exist is to change, to change is to mature, to mature is to go on creating oneself endlessly.",
    author: "Henri Bergson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["earth", "might", "one", "soon", "resemble", "planet", "venus"],
    words: ["earth", "might", "one", "day", "soon", "resemble", "the", "planet", "venus"],
    id: "Earth might one day soon resemble the planet Venus.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "kitchens",
      "hard",
      "environments",
      "form",
      "incredibly",
      "strong",
      "characters",
    ],
    words: [
      "kitchens",
      "are",
      "hard",
      "environments",
      "and",
      "they",
      "form",
      "incredibly",
      "strong",
      "characters",
    ],
    id: "Kitchens are hard environments and they form incredibly strong characters.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["through", "our", "own", "recovered", "innocence", "discern", "neighbors"],
    words: [
      "through",
      "our",
      "own",
      "recovered",
      "innocence",
      "we",
      "discern",
      "the",
      "of",
      "neighbors",
    ],
    id: "Through our own recovered innocence we discern the innocence of our neighbors.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["lives", "without", "folly", "isn't", "wise", "as", "thinks"],
    words: ["he", "who", "lives", "without", "folly", "isn't", "so", "wise", "as", "thinks"],
    id: "He who lives without folly isn't so wise as he thinks.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "wrote", "few", "children's", "books", "on", "purpose"],
    words: ["i", "wrote", "a", "few", "children's", "books", "not", "on", "purpose"],
    id: "I wrote a few children's books... not on purpose.",
    author: "Steven Wright",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["wide", "screen", "makes", "bad", "film", "twice", "as"],
    words: ["a", "wide", "screen", "just", "makes", "bad", "film", "twice", "as"],
    id: "A wide screen just makes a bad film twice as bad.",
    author: "Samuel Goldwyn",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["random", "chance", "plays", "huge", "part", "everybody's", "life"],
    words: ["random", "chance", "plays", "a", "huge", "part", "in", "everybody's", "life"],
    id: "Random chance plays a huge part in everybody's life.",
    author: "Gary Gygax",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["amount", "political", "freedom", "will", "satisfy", "hungry", "masses"],
    words: [
      "no",
      "amount",
      "of",
      "political",
      "freedom",
      "will",
      "satisfy",
      "the",
      "hungry",
      "masses",
    ],
    id: "No amount of political freedom will satisfy the hungry masses.",
    author: "Vladimir Lenin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["pray", "as", "though", "everything", "depended", "on", "god"],
    words: ["pray", "as", "though", "everything", "depended", "on", "god", "work", "you"],
    id: "Pray as though everything depended on God. Work as though everything depended on you.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["zero-gravity", "flight", "first", "step", "toward", "space", "travel"],
    words: ["a", "zero-gravity", "flight", "is", "first", "step", "toward", "space", "travel"],
    id: "A zero-gravity flight is a first step toward space travel.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "our", "individual", "faith", "freedom", "keep", "free"],
    words: ["only", "our", "individual", "faith", "in", "freedom", "can", "keep", "us", "free"],
    id: "Only our individual faith in freedom can keep us free.",
    author: "Dwight D. Eisenhower",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["whether", "chains", "or", "laurels", "liberty", "nothing", "victories"],
    words: [
      "whether",
      "in",
      "chains",
      "or",
      "laurels",
      "liberty",
      "knows",
      "nothing",
      "but",
      "victories",
    ],
    id: "Whether in chains or in laurels, liberty knows nothing but victories.",
    author: "Wendell Phillips",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "my", "favorite", "movies", "all", "time", "fargo"],
    words: ["one", "of", "my", "favorite", "movies", "all", "time", "is", "fargo"],
    id: "One of my favorite movies of all time is 'Fargo.'",
    author: "Cameron Monaghan",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "falling", "nine", "times", "getting", "up", "ten"],
    words: ["success", "is", "falling", "nine", "times", "and", "getting", "up", "ten"],
    id: "Success is falling nine times and getting up ten.",
    author: "Jon Bon Jovi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["friends", "cherish", "one", "another's", "hopes", "kind", "dreams"],
    words: [
      "friends",
      "they",
      "cherish",
      "one",
      "another's",
      "hopes",
      "are",
      "kind",
      "to",
      "dreams",
    ],
    id: "Friends... they cherish one another's hopes. They are kind to one another's dreams.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgive", "yourself", "your", "faults", "mistakes", "move", "on"],
    words: ["forgive", "yourself", "for", "your", "faults", "and", "mistakes", "move", "on"],
    id: "Forgive yourself for your faults and your mistakes and move on.",
    author: "Les Brown",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "sorry", "my", "existence", "noble", "or", "sublime"],
    words: ["i'm", "sorry", "my", "existence", "is", "not", "very", "noble", "or", "sublime"],
    id: "I'm sorry my existence is not very noble or sublime.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "name", "honor", "more", "than", "fear", "death"],
    words: ["i", "love", "the", "name", "of", "honor", "more", "than", "fear", "death"],
    id: "I love the name of honor, more than I fear death.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "one", "bag", "change", "your", "outfit", "completely"],
    words: ["with", "only", "one", "bag", "you", "can", "change", "your", "outfit", "completely"],
    id: "With only one bag, you can change your outfit completely.",
    author: "Sonia Rykiel",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["first", "step", "on", "way", "victory", "recognize", "enemy"],
    words: ["the", "first", "step", "on", "way", "to", "victory", "is", "recognize", "enemy"],
    id: "The first step on the way to victory is to recognize the enemy.",
    author: "Corrie Ten Boom",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["first", "step", "toward", "change", "awareness", "second", "acceptance"],
    words: ["the", "first", "step", "toward", "change", "is", "awareness", "second", "acceptance"],
    id: "The first step toward change is awareness. The second step is acceptance.",
    author: "Nathaniel Branden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "knew", "everything", "received", "real", "happiness", "giving"],
    words: [
      "i",
      "knew",
      "everything",
      "and",
      "received",
      "but",
      "real",
      "happiness",
      "is",
      "giving",
    ],
    id: "I knew everything and received everything. But real happiness, is giving.",
    author: "Alain Delon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["reality", "way", "intruding", "eventually", "intrudes", "on", "everything"],
    words: [
      "reality",
      "has",
      "a",
      "way",
      "of",
      "intruding",
      "eventually",
      "intrudes",
      "on",
      "everything",
    ],
    id: "Reality has a way of intruding. Reality eventually intrudes on everything.",
    author: "Joe Biden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "danger", "reckless", "change", "greater", "blind", "conservatism"],
    words: [
      "there",
      "is",
      "danger",
      "in",
      "reckless",
      "change",
      "but",
      "greater",
      "blind",
      "conservatism",
    ],
    id: "There is danger in reckless change, but greater danger in blind conservatism.",
    author: "Henry George",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["self-taught", "man", "usually", "poor", "teacher", "worse", "student"],
    words: [
      "a",
      "self-taught",
      "man",
      "usually",
      "has",
      "poor",
      "teacher",
      "and",
      "worse",
      "student",
    ],
    id: "A self-taught man usually has a poor teacher and a worse student.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["extreme", "positions", "succeeded", "by", "moderate", "ones", "contrary"],
    words: [
      "extreme",
      "positions",
      "are",
      "not",
      "succeeded",
      "by",
      "moderate",
      "ones",
      "but",
      "contrary",
    ],
    id: "Extreme positions are not succeeded by moderate ones, but by contrary extreme positions.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "strong", "person", "family", "man", "husband", "father"],
    words: ["i'm", "a", "strong", "person", "family", "man", "husband", "and", "father"],
    id: "I'm a strong person, I'm a strong family man, I'm a strong husband and a strong father.",
    author: "David Beckham",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "your", "own", "treasure", "because", "lies", "within"],
    words: ["happiness", "is", "your", "own", "treasure", "because", "it", "lies", "within", "you"],
    id: "Happiness is your own treasure because it lies within you.",
    author: "Prem Rawat",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["rather", "bed", "without", "dinner", "than", "rise", "debt"],
    words: ["rather", "go", "to", "bed", "without", "dinner", "than", "rise", "in", "debt"],
    id: "Rather go to bed without dinner than to rise in debt.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "learned", "lot", "morality", "from", "fiction", "movies"],
    words: ["i", "learned", "a", "lot", "about", "morality", "from", "fiction", "movies"],
    id: "I learned a lot about morality from fiction, from movies.",
    author: "Rob Morrow",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "wrapped", "up", "himself", "makes", "small", "bundle"],
    words: ["a", "man", "wrapped", "up", "in", "himself", "makes", "very", "small", "bundle"],
    id: "A man wrapped up in himself makes a very small bundle.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "never", "promise", "anything", "my", "mum", "supporters"],
    words: ["i", "never", "promise", "anything", "don't", "to", "my", "mum", "the", "supporters"],
    id: "I never promise anything. I don't promise anything to my mum. I don't promise anything to the supporters.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "following", "pattern", "life", "one", "enjoys", "most"],
    words: ["success", "is", "following", "the", "pattern", "of", "life", "one", "enjoys", "most"],
    id: "Success is following the pattern of life one enjoys most.",
    author: "Al Capp",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["private", "soldier", "as", "much", "right", "justice", "major-general"],
    words: [
      "a",
      "private",
      "soldier",
      "has",
      "as",
      "much",
      "right",
      "to",
      "justice",
      "major-general",
    ],
    id: "A private soldier has as much right to justice as a major-general.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["grieve", "anything", "lose", "comes", "round", "another", "form"],
    words: [
      "don't",
      "grieve",
      "anything",
      "you",
      "lose",
      "comes",
      "round",
      "in",
      "another",
      "form",
    ],
    id: "Don't grieve. Anything you lose comes round in another form.",
    author: "Rumi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "big", "advocate", "freedom", "speech", "expression", "thought"],
    words: ["i'm", "a", "big", "advocate", "of", "freedom", "speech", "expression", "thought"],
    id: "I'm a big advocate of freedom: freedom of speech, freedom of expression, freedom of thought.",
    author: "Jimmy Wales",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "count", "weeks", "days", "before", "my", "birthday"],
    words: ["i", "count", "the", "weeks", "and", "days", "before", "my", "birthday"],
    id: "I count the weeks and days before my birthday.",
    author: "Parineeti Chopra",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["manners", "will", "open", "doors", "best", "education", "cannot"],
    words: [
      "good",
      "manners",
      "will",
      "open",
      "doors",
      "that",
      "the",
      "best",
      "education",
      "cannot",
    ],
    id: "Good manners will open doors that the best education cannot.",
    author: "Clarence Thomas",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["foolish", "dead", "alone", "never", "change", "their", "opinions"],
    words: ["the", "foolish", "and", "dead", "alone", "never", "change", "their", "opinions"],
    id: "The foolish and the dead alone never change their opinions.",
    author: "James Russell Lowell",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["everybody", "goes", "movies", "get", "their", "life", "changed"],
    words: ["not", "everybody", "goes", "to", "movies", "get", "their", "life", "changed"],
    id: "Not everybody goes to movies to get their life changed.",
    author: "Samuel L. Jackson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "successful", "war", "seldom", "pays", "its", "losses"],
    words: ["the", "most", "successful", "war", "seldom", "pays", "for", "its", "losses"],
    id: "The most successful war seldom pays for its losses.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "impressed", "by", "someone's", "degree", "making", "movies"],
    words: ["i'm", "not", "impressed", "by", "someone's", "degree", "them", "making", "movies"],
    id: "I'm not impressed by someone's degree... I'm impressed by them making movies.",
    author: "Richard King",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "sometimes", "as", "different", "from", "himself", "others"],
    words: ["a", "man", "is", "sometimes", "as", "different", "from", "himself", "he", "others"],
    id: "A man is sometimes as different from himself as he is from others.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "was", "valiant", "i", "honour", "ambitious", "slew"],
    words: ["as", "he", "was", "valiant", "i", "honour", "him", "but", "ambitious", "slew"],
    id: "As he was valiant, I honour him. But as he was ambitious, I slew him.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["employ", "thy", "time", "thou", "meanest", "gain", "leisure"],
    words: ["employ", "thy", "time", "well", "if", "thou", "meanest", "to", "gain", "leisure"],
    id: "Employ thy time well, if thou meanest to gain leisure.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "muslim", "members", "my", "family", "lived", "countries"],
    words: ["i", "have", "muslim", "members", "of", "my", "family", "lived", "in", "countries"],
    id: "I have Muslim members of my family. I have lived in Muslim countries.",
    author: "Barack Obama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["should", "feel", "sorrow", "sink", "under", "its", "oppression"],
    words: ["we", "should", "feel", "sorrow", "but", "not", "sink", "under", "its", "oppression"],
    id: "We should feel sorrow, but not sink under its oppression.",
    author: "Confucius",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["night", "certainly", "more", "novel", "less", "profane", "than"],
    words: ["night", "is", "certainly", "more", "novel", "and", "less", "profane", "than", "day"],
    id: "Night is certainly more novel and less profane than day.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "soon", "trust", "yourself", "will", "how", "live"],
    words: ["as", "soon", "you", "trust", "yourself", "will", "know", "how", "to", "live"],
    id: "As soon as you trust yourself, you will know how to live.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["virtue", "perhaps", "nothing", "more", "than", "politeness", "soul"],
    words: ["virtue", "perhaps", "is", "nothing", "more", "than", "politeness", "of", "soul"],
    id: "Virtue, perhaps, is nothing more than politeness of soul.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["die", "at", "25", "aren't", "buried", "until", "75"],
    words: ["some", "people", "die", "at", "25", "and", "aren't", "buried", "until", "75"],
    id: "Some people die at 25 and aren't buried until 75.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["novel", "never", "anything", "philosophy", "put", "into", "images"],
    words: ["a", "novel", "is", "never", "anything", "but", "philosophy", "put", "into", "images"],
    id: "A novel is never anything, but a philosophy put into images.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["relentless", "repetitive", "self", "talk", "changes", "our", "self-image"],
    words: [
      "relentless",
      "repetitive",
      "self",
      "talk",
      "is",
      "what",
      "changes",
      "our",
      "self-image",
    ],
    id: "Relentless, repetitive self talk is what changes our self-image.",
    author: "Denis Waitley",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["while", "miser", "merely", "capitalist", "gone", "mad", "rational"],
    words: ["while", "the", "miser", "is", "merely", "a", "capitalist", "gone", "mad", "rational"],
    id: "While the miser is merely a capitalist gone mad, the capitalist is a rational miser.",
    author: "Karl Marx",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "certain", "effect", "war", "diminish", "freedom", "expression"],
    words: [
      "one",
      "certain",
      "effect",
      "of",
      "war",
      "is",
      "to",
      "diminish",
      "freedom",
      "expression",
    ],
    id: "One certain effect of war is to diminish freedom of expression.",
    author: "Howard Zinn",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["virtual", "reality", "augmented", "will", "change", "way", "shop"],
    words: ["virtual", "reality", "and", "augmented", "will", "change", "the", "way", "we", "shop"],
    id: "Virtual reality and augmented reality will change the way we shop.",
    author: "Michio Kaku",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "21st", "birthday", "was", "probably", "worst", "life"],
    words: ["my", "21st", "birthday", "was", "probably", "the", "worst", "day", "of", "life"],
    id: "My 21st birthday was probably the worst day of my life.",
    author: "Troy Aikman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "fear", "greeks", "even", "when", "bring", "gifts"],
    words: ["i", "fear", "the", "greeks", "even", "when", "they", "bring", "gifts"],
    id: "I fear the Greeks, even when they bring gifts.",
    author: "Virgil",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["canada", "loft", "apartment", "over", "really", "great", "party"],
    words: ["canada", "is", "like", "a", "loft", "apartment", "over", "really", "great", "party"],
    id: "Canada is like a loft apartment over a really great party.",
    author: "Robin Williams",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "world", "may", "endured", "except", "continual", "prosperity"],
    words: [
      "everything",
      "in",
      "the",
      "world",
      "may",
      "be",
      "endured",
      "except",
      "continual",
      "prosperity",
    ],
    id: "Everything in the world may be endured except continual prosperity.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["honesty", "most", "part", "less", "profitable", "than", "dishonesty"],
    words: [
      "honesty",
      "is",
      "for",
      "the",
      "most",
      "part",
      "less",
      "profitable",
      "than",
      "dishonesty",
    ],
    id: "Honesty is for the most part less profitable than dishonesty.",
    author: "Plato",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["don", "quixote's", "misfortune", "his", "imagination", "sancho", "panza"],
    words: [
      "don",
      "quixote's",
      "misfortune",
      "is",
      "not",
      "his",
      "imagination",
      "but",
      "sancho",
      "panza",
    ],
    id: "Don Quixote's misfortune is not his imagination, but Sancho Panza.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "flies", "when", "on", "emotional", "roller", "coaster"],
    words: ["time", "flies", "when", "you're", "on", "an", "emotional", "roller", "coaster"],
    id: "Time flies when you're on an emotional roller coaster.",
    author: "Kaitlyn Bristowe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["peace", "mind", "comes", "from", "wanting", "change", "others"],
    words: ["peace", "of", "mind", "comes", "from", "not", "wanting", "to", "change", "others"],
    id: "Peace of mind comes from not wanting to change others.",
    author: "Gerald Jampolsky",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["crazy", "how", "fast", "time", "flies", "things", "progress"],
    words: ["it's", "crazy", "how", "fast", "time", "flies", "and", "things", "progress"],
    id: "It's crazy how fast time flies and how things progress.",
    author: "Nathan Chen",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["football", "game", "cannot", "play", "without", "making", "mistakes"],
    words: [
      "football",
      "is",
      "a",
      "game",
      "you",
      "cannot",
      "play",
      "without",
      "making",
      "mistakes",
    ],
    id: "Football is a game you cannot play without making mistakes.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "principles", "more", "important", "than", "or", "title"],
    words: ["my", "principles", "are", "more", "important", "than", "the", "money", "or", "title"],
    id: "My principles are more important than the money or my title.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "sabbath",
      "provides",
      "wonderful",
      "opportunity",
      "strengthen",
      "family",
      "ties",
    ],
    words: [
      "the",
      "sabbath",
      "provides",
      "a",
      "wonderful",
      "opportunity",
      "to",
      "strengthen",
      "family",
      "ties",
    ],
    id: "The Sabbath provides a wonderful opportunity to strengthen family ties.",
    author: "Russell M. Nelson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["shallow", "men", "believe", "luck", "strong", "cause", "effect"],
    words: ["shallow", "men", "believe", "in", "luck", "strong", "cause", "and", "effect"],
    id: "Shallow men believe in luck. Strong men believe in cause and effect.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["out", "your", "own", "salvation", "depend", "on", "others"],
    words: ["work", "out", "your", "own", "salvation", "do", "not", "depend", "on", "others"],
    id: "Work out your own salvation. Do not depend on others.",
    author: "Buddha",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "touch", "nature", "makes", "whole", "world", "kin"],
    words: ["one", "touch", "of", "nature", "makes", "the", "whole", "world", "kin"],
    id: "One touch of nature makes the whole world kin.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["behavior", "mirror", "which", "everyone", "shows", "their", "image"],
    words: [
      "behavior",
      "is",
      "the",
      "mirror",
      "in",
      "which",
      "everyone",
      "shows",
      "their",
      "image",
    ],
    id: "Behavior is the mirror in which everyone shows their image.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "share", "birthday", "max", "beesley", "james", "nesbitt"],
    words: ["i", "share", "a", "birthday", "with", "max", "beesley", "and", "james", "nesbitt"],
    id: "I share a birthday with Max Beesley and James Nesbitt.",
    author: "Claudia Winkleman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["passions", "vices", "or", "virtues", "their", "highest", "powers"],
    words: ["passions", "are", "vices", "or", "virtues", "to", "their", "highest", "powers"],
    id: "Passions are vices or virtues to their highest powers.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["start", "your", "diet", "during", "period", "optimism", "happiness"],
    words: ["start", "your", "diet", "during", "a", "period", "of", "optimism", "and", "happiness"],
    id: "Start your diet during a period of optimism and happiness.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["ever", "achieve", "total", "forgiveness", "after", "screwing", "up"],
    words: ["do", "you", "ever", "achieve", "total", "forgiveness", "after", "screwing", "up"],
    id: "Do you ever achieve total forgiveness after screwing up?",
    author: "Lee Unkrich",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "didn't", "graduate", "from", "any", "institution", "higher"],
    words: ["i", "didn't", "graduate", "from", "any", "institution", "of", "higher", "learning"],
    id: "I didn't graduate from any institution of higher learning.",
    author: "George Santos",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "dangerous", "man", "when", "turned", "loose", "typewriter"],
    words: ["i", "am", "a", "dangerous", "man", "when", "turned", "loose", "with", "typewriter"],
    id: "I am a dangerous man when turned loose with a typewriter.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["either", "change", "times", "or", "get", "rolled", "over"],
    words: ["either", "you", "change", "with", "the", "times", "or", "get", "rolled", "over"],
    id: "Either you change with the times or you get rolled over.",
    author: "B. J. Armstrong",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "change",
      "occurs",
      "direct",
      "proportion",
      "dissatisfaction",
      "never",
      "changes",
    ],
    words: [
      "change",
      "occurs",
      "in",
      "direct",
      "proportion",
      "to",
      "dissatisfaction",
      "but",
      "never",
      "changes",
    ],
    id: "Change occurs in direct proportion to dissatisfaction, but dissatisfaction never changes.",
    author: "Douglas Horton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["wisdom", "denotes", "pursuing", "best", "ends", "by", "means"],
    words: ["wisdom", "denotes", "the", "pursuing", "of", "best", "ends", "by", "means"],
    id: "Wisdom denotes the pursuing of the best ends by the best means.",
    author: "Francis Hutcheson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["america", "tossed", "its", "cap", "over", "wall", "space"],
    words: ["america", "has", "tossed", "its", "cap", "over", "the", "wall", "of", "space"],
    id: "America has tossed its cap over the wall of space.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "come", "from", "family", "gravy", "considered", "beverage"],
    words: ["i", "come", "from", "a", "family", "where", "gravy", "is", "considered", "beverage"],
    id: "I come from a family where gravy is considered a beverage.",
    author: "Erma Bombeck",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["remember", "most", "valuable", "antiques", "dear", "old", "friends"],
    words: [
      "remember",
      "that",
      "the",
      "most",
      "valuable",
      "antiques",
      "are",
      "dear",
      "old",
      "friends",
    ],
    id: "Remember that the most valuable antiques are dear old friends.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["earth", "changes", "thy", "soul", "god", "stand", "sure"],
    words: ["earth", "changes", "but", "thy", "soul", "and", "god", "stand", "sure"],
    id: "Earth changes, but thy soul and God stand sure.",
    author: "Robert Browning",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "given", "one", "face", "make", "yourself", "another"],
    words: ["god", "has", "given", "you", "one", "face", "and", "make", "yourself", "another"],
    id: "God has given you one face, and you make yourself another.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "feel", "too", "much", "pressure", "only", "opportunity"],
    words: ["i", "don't", "feel", "too", "much", "pressure", "only", "see", "an", "opportunity"],
    id: "I don't feel too much pressure. I only see an opportunity.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "god", "i'm", "saying", "highly", "religious"],
    words: ["i", "believe", "in", "god", "i'm", "not", "saying", "highly", "religious"],
    id: "I believe in God; I'm not saying I'm highly religious.",
    author: "Michael Phelps",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["romania", "american", "forces", "found", "devoted", "nato", "ally"],
    words: ["in", "romania", "american", "forces", "have", "found", "a", "devoted", "nato", "ally"],
    id: "In Romania, American forces have found a devoted NATO ally.",
    author: "Joe Biden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["governance", "requires", "toward", "common", "ground", "isn't", "easy"],
    words: [
      "good",
      "governance",
      "requires",
      "working",
      "toward",
      "common",
      "ground",
      "it",
      "isn't",
      "easy",
    ],
    id: "Good governance requires working toward common ground. It isn't easy.",
    author: "Pete Hoekstra",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "die", "king's", "faithful", "servant", "god's", "first"],
    words: ["i", "die", "the", "king's", "faithful", "servant", "but", "god's", "first"],
    id: "I die the king's faithful servant, but God's first.",
    author: "Thomas More",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["america's", "health", "system", "neither", "healthy", "caring", "nor"],
    words: [
      "america's",
      "health",
      "care",
      "system",
      "is",
      "neither",
      "healthy",
      "caring",
      "nor",
      "a",
    ],
    id: "America's health care system is neither healthy, caring, nor a system.",
    author: "Walter Cronkite",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "time", "more", "than", "strength", "or", "passion"],
    words: ["patience", "and", "time", "do", "more", "than", "strength", "or", "passion"],
    id: "Patience and time do more than strength or passion.",
    author: "Jean de La Fontaine",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "been", "figured", "out", "except", "how", "live"],
    words: ["everything", "has", "been", "figured", "out", "except", "how", "to", "live"],
    id: "Everything has been figured out, except how to live.",
    author: "Jean-Paul Sartre",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "success", "isn't", "result", "arrogance", "-", "belief"],
    words: ["my", "success", "isn't", "a", "result", "of", "arrogance", "-", "it's", "belief"],
    id: "My success isn't a result of arrogance - it's a result of belief.",
    author: "Conor McGregor",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "i've", "been", "guided", "by", "god", "obedient"],
    words: ["i", "know", "that", "i've", "been", "guided", "by", "god", "am", "obedient"],
    id: "I know that I've been guided by God. I am obedient.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "useless", "those", "never", "change", "through", "years"],
    words: ["the", "most", "useless", "are", "those", "who", "never", "change", "through", "years"],
    id: "The most useless are those who never change through the years.",
    author: "James M. Barrie",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["little", "knowledge", "was", "dangerous", "thing", "lot", "lethal"],
    words: ["if", "a", "little", "knowledge", "was", "dangerous", "thing", "lot", "lethal"],
    id: "If a little knowledge was a dangerous thing, a lot was lethal.",
    author: "Tom Sharpe",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "life", "always", "expresses", "result", "dominant", "thoughts"],
    words: ["our", "life", "always", "expresses", "the", "result", "of", "dominant", "thoughts"],
    id: "Our life always expresses the result of our dominant thoughts.",
    author: "Soren Kierkegaard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "made", "ever", "many", "partings", "welded", "together"],
    words: ["life", "is", "made", "of", "ever", "so", "many", "partings", "welded", "together"],
    id: "Life is made of ever so many partings welded together.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "actually", "gaming", "amazing", "your", "mental", "health"],
    words: ["i", "actually", "think", "gaming", "is", "amazing", "for", "your", "mental", "health"],
    id: "I actually think gaming is amazing for your mental health.",
    author: "Asim Chaudhry",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgiveness", "allowed", "wash", "my", "burdened", "past", "away"],
    words: ["forgiveness", "allowed", "me", "to", "wash", "my", "burdened", "past", "away"],
    id: "Forgiveness allowed me to wash my burdened past away.",
    author: "Clemantine Wamariya",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "big", "birthday", "guy", "i", "never", "been"],
    words: ["i'm", "not", "a", "big", "birthday", "guy", "i", "never", "have", "been"],
    id: "I'm not a big birthday guy; I never have been.",
    author: "Lewis Black",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["jesus", "birthday", "commercialized", "course", "black", "history", "month"],
    words: [
      "jesus",
      "birthday",
      "is",
      "commercialized",
      "so",
      "of",
      "course",
      "black",
      "history",
      "month",
    ],
    id: "Jesus' birthday is commercialized, so of course, Black History Month is commercialized.",
    author: "Jidenna",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["enjoy", "satisfaction", "comes", "from", "doing", "little", "things"],
    words: [
      "enjoy",
      "the",
      "satisfaction",
      "that",
      "comes",
      "from",
      "doing",
      "little",
      "things",
      "well",
    ],
    id: "Enjoy the satisfaction that comes from doing little things well.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["sporting", "goods", "companies", "pay", "endorse", "their", "products"],
    words: [
      "sporting",
      "goods",
      "companies",
      "pay",
      "me",
      "not",
      "to",
      "endorse",
      "their",
      "products",
    ],
    id: "Sporting goods companies pay me not to endorse their products.",
    author: "Bob Uecker",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["vote", "means", "equality", "change", "what's", "right", "humanity"],
    words: [
      "vote",
      "love",
      "means",
      "equality",
      "it",
      "change",
      "what's",
      "right",
      "for",
      "humanity",
    ],
    id: "'Vote Love' means vote equality. It means vote change. It means vote what's right for humanity.",
    author: "Macklemore",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "dying", "from", "treatment", "too", "many", "physicians"],
    words: ["i", "am", "dying", "from", "the", "treatment", "of", "too", "many", "physicians"],
    id: "I am dying from the treatment of too many physicians.",
    author: "Alexander the Great",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["choose", "our", "joys", "sorrows", "long", "before", "experience"],
    words: [
      "we",
      "choose",
      "our",
      "joys",
      "and",
      "sorrows",
      "long",
      "before",
      "experience",
      "them",
    ],
    id: "We choose our joys and sorrows long before we experience them.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["war", "heroes", "always", "outnumber", "soldiers", "ten", "one"],
    words: ["in", "war", "the", "heroes", "always", "outnumber", "soldiers", "ten", "to", "one"],
    id: "In war the heroes always outnumber the soldiers ten to one.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "there's", "connection", "between", "absolute", "discipline", "freedom"],
    words: [
      "i",
      "think",
      "there's",
      "some",
      "connection",
      "between",
      "absolute",
      "discipline",
      "and",
      "freedom",
    ],
    id: "I think there's some connection between absolute discipline and absolute freedom.",
    author: "Alan Rickman",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["better", "mad", "rest", "world", "than", "wise", "alone"],
    words: ["better", "mad", "with", "the", "rest", "of", "world", "than", "wise", "alone"],
    id: "Better mad with the rest of the world than wise alone.",
    author: "Baltasar Gracian",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["friendship", "language", "look", "nice", "tonight", "desire", "hot"],
    words: ["friendship", "language", "is", "you", "look", "nice", "tonight", "desire", "hot"],
    id: "Friendship language is, 'You look nice tonight.' Desire language is, 'You look hot tonight.'",
    author: "Matthew Hussey",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "consists", "from", "failure", "without", "loss", "enthusiasm"],
    words: [
      "success",
      "consists",
      "of",
      "going",
      "from",
      "failure",
      "to",
      "without",
      "loss",
      "enthusiasm",
    ],
    id: "Success consists of going from failure to failure without loss of enthusiasm.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "greatest", "said", "even", "before", "knew", "was"],
    words: ["i", "am", "the", "greatest", "said", "that", "even", "before", "knew", "was"],
    id: "I am the greatest, I said that even before I knew I was.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "live", "my", "sons", "would", "lost", "without"],
    words: ["i", "live", "for", "my", "sons", "would", "be", "lost", "without", "them"],
    id: "I live for my sons. I would be lost without them.",
    author: "Princess Diana",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "more", "pleasure", "loving", "than", "being", "beloved"],
    words: ["there", "is", "more", "pleasure", "in", "loving", "than", "being", "beloved"],
    id: "There is more pleasure in loving than in being beloved.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["inner", "experience", "only", "one", "source", "human", "knowledge"],
    words: [
      "but",
      "inner",
      "experience",
      "is",
      "only",
      "one",
      "source",
      "of",
      "human",
      "knowledge",
    ],
    id: "But inner experience is only one source of human knowledge.",
    author: "Muhammad Iqbal",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["thank", "god", "my", "parents", "had", "abundance", "patience"],
    words: ["thank", "god", "my", "parents", "had", "an", "abundance", "of", "patience"],
    id: "Thank God my parents had an abundance of patience.",
    author: "Lexa Doig",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "men", "within", "finger's", "breadth", "being", "mad"],
    words: ["most", "men", "are", "within", "a", "finger's", "breadth", "of", "being", "mad"],
    id: "Most men are within a finger's breadth of being mad.",
    author: "Diogenes",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["fda", "serves", "real", "purpose", "protect", "public", "health"],
    words: ["the", "fda", "serves", "a", "real", "purpose", "to", "protect", "public", "health"],
    id: "The FDA serves a real purpose: To protect public health.",
    author: "Anne Wojcicki",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["administration", "here", "declares", "unconditional", "war", "on", "poverty"],
    words: [
      "this",
      "administration",
      "here",
      "and",
      "now",
      "declares",
      "unconditional",
      "war",
      "on",
      "poverty",
    ],
    id: "This administration here and now declares unconditional war on poverty.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "should", "never", "neglect", "his", "family", "business"],
    words: ["a", "man", "should", "never", "neglect", "his", "family", "for", "business"],
    id: "A man should never neglect his family for business.",
    author: "Walt Disney",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["on", "my", "birthday", "i", "was", "milan", "collections"],
    words: ["on", "my", "birthday", "i", "was", "in", "milan", "for", "the", "collections"],
    id: "On my birthday, I was in Milan for the collections.",
    author: "Eva Herzigova",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["doing", "nothing", "happiness", "children", "misery", "old", "men"],
    words: [
      "doing",
      "nothing",
      "is",
      "happiness",
      "for",
      "children",
      "and",
      "misery",
      "old",
      "men",
    ],
    id: "Doing nothing is happiness for children and misery for old men.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["straight", "line", "belongs", "men", "curved", "one", "god"],
    words: ["the", "straight", "line", "belongs", "to", "men", "curved", "one", "god"],
    id: "The straight line belongs to men, the curved one to God.",
    author: "Antoni Gaudi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "stays", "long", "enough", "anyone", "will", "use"],
    words: ["time", "stays", "long", "enough", "for", "anyone", "who", "will", "use", "it"],
    id: "Time stays long enough for anyone who will use it.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "such", "thing", "as", "perfect", "writing", "despair"],
    words: [
      "there's",
      "no",
      "such",
      "thing",
      "as",
      "perfect",
      "writing",
      "just",
      "like",
      "despair",
    ],
    id: "There's no such thing as perfect writing, just like there's no such thing as perfect despair.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["hold", "puppies", "kittens", "babies", "anytime", "get", "chance"],
    words: [
      "hold",
      "puppies",
      "kittens",
      "and",
      "babies",
      "anytime",
      "you",
      "get",
      "the",
      "chance",
    ],
    id: "Hold puppies, kittens, and babies anytime you get the chance.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["hope", "travels", "through", "nor", "quits", "when", "die"],
    words: ["hope", "travels", "through", "nor", "quits", "us", "when", "we", "die"],
    id: "Hope travels through, nor quits us when we die.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["global", "energy", "security", "vital", "part", "america's", "national"],
    words: [
      "global",
      "energy",
      "security",
      "is",
      "a",
      "vital",
      "part",
      "of",
      "america's",
      "national",
    ],
    id: "Global energy security is a vital part of America's national security.",
    author: "Joe Biden",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["real", "magic", "relationships", "means", "absence", "judgment", "others"],
    words: [
      "real",
      "magic",
      "in",
      "relationships",
      "means",
      "an",
      "absence",
      "of",
      "judgment",
      "others",
    ],
    id: "Real magic in relationships means an absence of judgment of others.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "glory", "was", "i", "had", "such", "friends"],
    words: ["and", "say", "my", "glory", "was", "i", "had", "such", "friends"],
    id: "And say my glory was I had such friends.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["shop", "health", "food", "stores", "never", "look", "healthy"],
    words: ["people", "who", "shop", "in", "health", "food", "stores", "never", "look", "healthy"],
    id: "People who shop in health food stores never look healthy.",
    author: "Amy Sedaris",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["puberty", "was", "most", "horrifying", "time", "my", "life"],
    words: ["puberty", "was", "the", "most", "horrifying", "time", "of", "my", "life"],
    id: "Puberty was the most horrifying time of my life.",
    author: "Brigitte Nielsen",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["coming", "generations", "will", "equality", "from", "poverty", "woes"],
    words: [
      "coming",
      "generations",
      "will",
      "learn",
      "equality",
      "from",
      "poverty",
      "and",
      "love",
      "woes",
    ],
    id: "Coming generations will learn equality from poverty, and love from woes.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["whatever", "deceives", "men", "seems", "produce", "magical", "enchantment"],
    words: ["whatever", "deceives", "men", "seems", "to", "produce", "a", "magical", "enchantment"],
    id: "Whatever deceives men seems to produce a magical enchantment.",
    author: "Plato",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "favorite", "thing", "superbad", "values", "male", "friendship"],
    words: [
      "my",
      "favorite",
      "thing",
      "about",
      "superbad",
      "is",
      "it",
      "values",
      "male",
      "friendship",
    ],
    id: "My favorite thing about 'Superbad' is it values male friendship.",
    author: "Beanie Feldstein",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "americans", "should", "access", "quality", "affordable", "health"],
    words: [
      "all",
      "americans",
      "should",
      "have",
      "access",
      "to",
      "quality",
      "affordable",
      "health",
      "care",
    ],
    id: "All Americans should have access to quality, affordable health care.",
    author: "Christopher Dodd",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["initiative", "doing", "right", "things", "without", "being", "told"],
    words: ["initiative", "is", "doing", "the", "right", "things", "without", "being", "told"],
    id: "Initiative is doing the right things without being told.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "begins", "at", "end", "your", "comfort", "zone"],
    words: ["life", "begins", "at", "the", "end", "of", "your", "comfort", "zone"],
    id: "Life begins at the end of your comfort zone.",
    author: "Neale Donald Walsch",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "right", "cannot", "too", "radical", "wrong", "conservative"],
    words: [
      "when",
      "you",
      "are",
      "right",
      "cannot",
      "be",
      "too",
      "radical",
      "wrong",
      "conservative",
    ],
    id: "When you are right you cannot be too radical; when you are wrong, you cannot be too conservative.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "my", "god", "commands", "whose", "power", "resists"],
    words: ["i", "know", "my", "god", "commands", "whose", "power", "no", "resists"],
    id: "I know My God commands, whose power no power resists.",
    author: "Robert Greene",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "should", "believe", "marriage", "as", "immortality", "soul"],
    words: ["one", "should", "believe", "in", "marriage", "as", "the", "immortality", "of", "soul"],
    id: "One should believe in marriage as in the immortality of the soul.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "one", "unpardonable", "sin", "against", "our", "fellows"],
    words: ["success", "is", "the", "one", "unpardonable", "sin", "against", "our", "fellows"],
    id: "Success is the one unpardonable sin against our fellows.",
    author: "Ambrose Bierce",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "father", "his", "son", "both", "laugh", "cry"],
    words: ["when", "a", "father", "gives", "to", "his", "son", "both", "laugh", "cry"],
    id: "When a father gives to his son, both laugh; when a son gives to his father, both cry.",
    author: "Unknown",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["act", "your", "part", "there", "all", "honour", "lies"],
    words: ["act", "well", "your", "part", "there", "all", "the", "honour", "lies"],
    id: "Act well your part, there all the honour lies.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "worth", "living", "all", "depends", "on", "liver"],
    words: ["is", "life", "worth", "living", "it", "all", "depends", "on", "the", "liver"],
    id: "Is life worth living? It all depends on the liver.",
    author: "William James",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["art", "nature", "speeded", "up", "god", "slowed", "down"],
    words: ["art", "is", "nature", "speeded", "up", "and", "god", "slowed", "down"],
    id: "Art is nature speeded up and God slowed down.",
    author: "Malcolm de Chazal",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "feel", "pampered", "special", "on", "my", "birthday"],
    words: ["i", "want", "to", "feel", "pampered", "and", "special", "on", "my", "birthday"],
    id: "I want to feel pampered and special on my birthday.",
    author: "Rashami Desai",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["children's", "finger-painting", "came", "under", "arts", "movies", "didn't"],
    words: [
      "children's",
      "finger-painting",
      "came",
      "under",
      "the",
      "arts",
      "but",
      "movies",
      "didn't",
    ],
    id: "Children's finger-painting came under the arts, but movies didn't.",
    author: "Nicolas Roeg",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'd", "as", "soon", "play", "tennis", "net", "down"],
    words: ["i'd", "just", "as", "soon", "play", "tennis", "with", "the", "net", "down"],
    id: "I'd just as soon play tennis with the net down.",
    author: "Robert Frost",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "given", "myself", "tesla", "my", "60th", "birthday"],
    words: ["i", "have", "given", "myself", "a", "tesla", "for", "my", "60th", "birthday"],
    id: "I have given myself a Tesla for my 60th birthday.",
    author: "Stanley Druckenmiller",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "three", "constants", "life", "change", "choice", "principles"],
    words: [
      "there",
      "are",
      "three",
      "constants",
      "in",
      "life",
      "change",
      "choice",
      "and",
      "principles",
    ],
    id: "There are three constants in life... change, choice and principles.",
    author: "Stephen Covey",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["fortune", "knocks", "once", "misfortune", "much", "more", "patience"],
    words: ["fortune", "knocks", "but", "once", "misfortune", "has", "much", "more", "patience"],
    id: "Fortune knocks but once, but misfortune has much more patience.",
    author: "Laurence J. Peter",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["arriving", "at", "one", "goal", "starting", "point", "another"],
    words: ["arriving", "at", "one", "goal", "is", "the", "starting", "point", "to", "another"],
    id: "Arriving at one goal is the starting point to another.",
    author: "John Dewey",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "really", "secret", "success", "make", "your", "own"],
    words: ["there's", "really", "no", "secret", "to", "success", "you", "make", "your", "own"],
    id: "There's really no secret to success. You make your own success.",
    author: "Alex Morgan",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "small", "step", "man", "giant", "leap", "mankind"],
    words: ["that's", "one", "small", "step", "for", "a", "man", "giant", "leap", "mankind"],
    id: "That's one small step for a man, one giant leap for mankind.",
    author: "Neil Armstrong",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "wasted", "all", "days", "one", "without", "laughter"],
    words: ["the", "most", "wasted", "of", "all", "days", "is", "one", "without", "laughter"],
    id: "The most wasted of all days is one without laughter.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "rivers", "run", "into", "sea", "yet", "full"],
    words: ["all", "the", "rivers", "run", "into", "sea", "yet", "is", "not", "full"],
    id: "All the rivers run into the sea; yet the sea is not full.",
    author: "King Solomon",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "beggar's", "virtue", "shall", "find", "harbor", "here"],
    words: ["patience", "the", "beggar's", "virtue", "shall", "find", "no", "harbor", "here"],
    id: "Patience, the beggar's virtue, shall find no harbor here.",
    author: "Philip Massinger",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "99%", "success", "never", "giving", "up"],
    words: ["i", "believe", "99%", "of", "success", "is", "never", "giving", "up"],
    id: "I believe 99% of success is never giving up.",
    author: "Coy Bowles",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["things", "won", "done", "joy's", "soul", "lies", "doing"],
    words: ["things", "won", "are", "done", "joy's", "soul", "lies", "in", "the", "doing"],
    id: "Things won are done, joy's soul lies in the doing.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["thy", "tools", "ready", "god", "will", "find", "thee"],
    words: ["have", "thy", "tools", "ready", "god", "will", "find", "thee", "work"],
    id: "Have thy tools ready. God will find thee work.",
    author: "Charles Kingsley",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "sure", "lot", "out", "there", "make", "mistakes"],
    words: ["i'm", "sure", "a", "lot", "of", "people", "out", "there", "make", "mistakes"],
    id: "I'm sure a lot of people out there make mistakes.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["best", "theology", "rather", "divine", "life", "than", "knowledge"],
    words: ["the", "best", "theology", "is", "rather", "a", "divine", "life", "than", "knowledge"],
    id: "The best theology is rather a divine life than a divine knowledge.",
    author: "Jeremy Taylor",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "today's",
      "tactical",
      "victory",
      "guarantee",
      "tomorrow's",
      "strategic",
      "success",
    ],
    words: [
      "today's",
      "tactical",
      "victory",
      "does",
      "not",
      "guarantee",
      "tomorrow's",
      "strategic",
      "success",
    ],
    id: "Today's tactical victory does not guarantee tomorrow's strategic success.",
    author: "Peter Pace",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "neighbor", "circular", "driveway", "can't", "get", "out"],
    words: ["my", "neighbor", "has", "a", "circular", "driveway", "he", "can't", "get", "out"],
    id: "My neighbor has a circular driveway... he can't get out.",
    author: "Steven Wright",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "became", "dalai", "lama", "on", "volunteer", "basis"],
    words: ["i", "became", "the", "dalai", "lama", "not", "on", "a", "volunteer", "basis"],
    id: "I became the Dalai Lama not on a volunteer basis.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "real", "power", "comes", "out", "long", "rifle"],
    words: ["the", "only", "real", "power", "comes", "out", "of", "a", "long", "rifle"],
    id: "The only real power comes out of a long rifle.",
    author: "Joseph Stalin",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "put", "democracy", "freedom", "back", "into", "box"],
    words: ["you", "can't", "put", "democracy", "and", "freedom", "back", "into", "a", "box"],
    id: "You can't put democracy and freedom back into a box.",
    author: "George W. Bush",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["country", "really", "develop", "unless", "its", "citizens", "educated"],
    words: [
      "no",
      "country",
      "can",
      "really",
      "develop",
      "unless",
      "its",
      "citizens",
      "are",
      "educated",
    ],
    id: "No country can really develop unless its citizens are educated.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "simple", "buddhist", "monk", "-", "more", "less"],
    words: ["i", "am", "a", "simple", "buddhist", "monk", "-", "no", "more", "less"],
    id: "I am a simple Buddhist monk - no more, no less.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["growing", "old", "been", "greatest", "surprise", "my", "life"],
    words: ["growing", "old", "has", "been", "the", "greatest", "surprise", "of", "my", "life"],
    id: "Growing old has been the greatest surprise of my life.",
    author: "Billy Graham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "got", "second", "chance", "at", "life", "live"],
    words: ["i", "got", "this", "second", "chance", "at", "life", "and", "live", "it"],
    id: "I got this second chance at life, and I live it.",
    author: "Amy Purdy",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["music", "higher", "revelation", "than", "all", "wisdom", "philosophy"],
    words: [
      "music",
      "is",
      "a",
      "higher",
      "revelation",
      "than",
      "all",
      "wisdom",
      "and",
      "philosophy",
    ],
    id: "Music is a higher revelation than all wisdom and philosophy.",
    author: "Ludwig van Beethoven",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "choose", "up", "sides", "on", "round", "world"],
    words: ["you", "can't", "choose", "up", "sides", "on", "a", "round", "world"],
    id: "You can't choose up sides on a round world.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "could", "kill", "time", "without", "injuring", "eternity"],
    words: ["as", "if", "you", "could", "kill", "time", "without", "injuring", "eternity"],
    id: "As if you could kill time without injuring eternity.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["after", "your", "death", "will", "were", "before", "birth"],
    words: ["after", "your", "death", "you", "will", "be", "what", "were", "before", "birth"],
    id: "After your death you will be what you were before your birth.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "could", "less", "statistics", "all", "team", "success"],
    words: ["i", "could", "care", "less", "about", "statistics", "it's", "all", "team", "success"],
    id: "I could care less about statistics. It's all about team success.",
    author: "Karl-Anthony Towns",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "i", "was", "kid", "our", "family", "rich"],
    words: ["when", "i", "was", "a", "kid", "our", "family", "not", "that", "rich"],
    id: "When I was a kid, our family was not that rich.",
    author: "Masayoshi Son",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "get", "anywhere", "life", "without", "taking", "risks"],
    words: ["you", "can't", "get", "anywhere", "in", "life", "without", "taking", "risks"],
    id: "You can't get anywhere in life without taking risks.",
    author: "Esme Bianco",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["single", "sunbeam", "enough", "drive", "away", "many", "shadows"],
    words: ["a", "single", "sunbeam", "is", "enough", "to", "drive", "away", "many", "shadows"],
    id: "A single sunbeam is enough to drive away many shadows.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["ounce", "practice", "worth", "more", "than", "tons", "preaching"],
    words: ["an", "ounce", "of", "practice", "is", "worth", "more", "than", "tons", "preaching"],
    id: "An ounce of practice is worth more than tons of preaching.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["god's", "children", "their", "happiness", "my", "reasons", "being"],
    words: [
      "god's",
      "children",
      "and",
      "their",
      "happiness",
      "are",
      "my",
      "reasons",
      "for",
      "being",
    ],
    id: "God's children and their happiness are my reasons for being.",
    author: "Red Skelton",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["keep", "your", "secret", "wisdom", "expect", "others", "folly"],
    words: ["to", "keep", "your", "secret", "is", "wisdom", "expect", "others", "it", "folly"],
    id: "To keep your secret is wisdom; to expect others to keep it is folly.",
    author: "William Samuel Johnson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "philosophy", "lies", "two", "words", "sustain", "abstain"],
    words: ["all", "philosophy", "lies", "in", "two", "words", "sustain", "and", "abstain"],
    id: "All philosophy lies in two words, sustain and abstain.",
    author: "Epictetus",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "shrinks", "or", "expands", "proportion", "one's", "courage"],
    words: ["life", "shrinks", "or", "expands", "in", "proportion", "to", "one's", "courage"],
    id: "Life shrinks or expands in proportion to one's courage.",
    author: "Anais Nin",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "sort", "gravitate", "towards", "smaller", "indie-type", "movies"],
    words: ["i", "do", "sort", "of", "gravitate", "towards", "smaller", "indie-type", "movies"],
    id: "I do sort of gravitate towards smaller, indie-type movies.",
    author: "Kristen Wiig",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "definitely",
      "motivational",
      "factor",
      "becoming",
      "three-organization",
      "world",
      "champion",
    ],
    words: [
      "it's",
      "definitely",
      "a",
      "motivational",
      "factor",
      "becoming",
      "three-organization",
      "world",
      "champion",
    ],
    id: "It's definitely a motivational factor, becoming a three-organization world champion.",
    author: "Benson Henderson",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "never", "left", "another", "senator", "out", "dry"],
    words: ["i", "have", "never", "left", "another", "senator", "out", "to", "dry"],
    id: "I have never left another senator out to dry. Never.",
    author: "Joe Biden",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "dreams", "future", "better", "than", "history", "past"],
    words: ["i", "like", "the", "dreams", "of", "future", "better", "than", "history", "past"],
    id: "I like the dreams of the future better than the history of the past.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "demand", "price", "step", "forward", "pays", "dividend"],
    words: [
      "success",
      "doesn't",
      "demand",
      "a",
      "price",
      "every",
      "step",
      "forward",
      "pays",
      "dividend",
    ],
    id: "Success doesn't demand a price. Every step forward pays a dividend.",
    author: "David Joseph Schwartz",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["wisdom", "oftentimes", "nearer", "when", "stoop", "than", "soar"],
    words: ["wisdom", "is", "oftentimes", "nearer", "when", "we", "stoop", "than", "soar"],
    id: "Wisdom is oftentimes nearer when we stoop than when we soar.",
    author: "William Wordsworth",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["nothing", "void", "god", "his", "everywhere", "full", "himself"],
    words: ["nothing", "is", "void", "of", "god", "his", "work", "everywhere", "full", "himself"],
    id: "Nothing is void of God, his work is everywhere his full of himself.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["human", "being", "under", "construction", "from", "conception", "death"],
    words: [
      "every",
      "human",
      "being",
      "is",
      "under",
      "construction",
      "from",
      "conception",
      "to",
      "death",
    ],
    id: "Every human being is under construction from conception to death.",
    author: "Billy Graham",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "make", "feel", "inferior", "without", "your", "consent"],
    words: ["no", "one", "can", "make", "you", "feel", "inferior", "without", "your", "consent"],
    id: "No one can make you feel inferior without your consent.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "two", "choices", "your", "commitment", "versus", "fear"],
    words: ["you", "always", "have", "two", "choices", "your", "commitment", "versus", "fear"],
    id: "You always have two choices: your commitment versus your fear.",
    author: "Sammy Davis, Jr.",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["jobs", "mean", "freedom", "workers", "support", "their", "families"],
    words: ["jobs", "mean", "freedom", "for", "workers", "to", "support", "their", "families"],
    id: "Jobs mean freedom for workers to support their families.",
    author: "Nancy Pelosi",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "charity", "wisdom", "neither", "fear", "nor", "ignorance"],
    words: [
      "where",
      "there",
      "is",
      "charity",
      "and",
      "wisdom",
      "neither",
      "fear",
      "nor",
      "ignorance",
    ],
    id: "Where there is charity and wisdom, there is neither fear nor ignorance.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "made", "by", "his", "belief", "as", "believes"],
    words: ["man", "is", "made", "by", "his", "belief", "as", "he", "believes", "so"],
    id: "Man is made by his belief. As he believes, so he is.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "designed", "come", "second", "or", "third", "win"],
    words: ["i", "am", "not", "designed", "to", "come", "second", "or", "third", "win"],
    id: "I am not designed to come second or third. I am designed to win.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "family", "god", "solve", "problems", "our", "time"],
    words: ["only", "the", "family", "of", "god", "can", "solve", "problems", "our", "time"],
    id: "Only the family of God can solve the problems of our time.",
    author: "James Meredith",
    grammar: null,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["keep", "your", "eyes", "on", "stars", "feet", "ground"],
    words: ["keep", "your", "eyes", "on", "the", "stars", "and", "feet", "ground"],
    id: "Keep your eyes on the stars, and your feet on the ground.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["perhaps", "too", "much", "everything", "as", "bad", "little"],
    words: ["perhaps", "too", "much", "of", "everything", "is", "as", "bad", "little"],
    id: "Perhaps too much of everything is as bad as too little.",
    author: "Edna Ferber",
    grammar: null,
    word_count: 9,
    all_intersection_count: 2,
    new_words_count: 7,
  },
  {
    row_new_words: ["internet", "big", "powerful", "pointless", "complete", "substitute", "life"],
    words: [
      "the",
      "internet",
      "is",
      "so",
      "big",
      "powerful",
      "and",
      "pointless",
      "that",
      "for",
      "some",
      "people",
      "it",
      "a",
      "complete",
      "substitute",
      "life",
    ],
    id: "The Internet is so big, so powerful and pointless that for some people it is a complete substitute for life.",
    author: "Andrew Brown",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["protect", "nhs", "health", "ghettoes", "can't", "get", "treated"],
    words: [
      "if",
      "you",
      "don't",
      "protect",
      "the",
      "nhs",
      "you're",
      "going",
      "to",
      "have",
      "health",
      "ghettoes",
      "where",
      "people",
      "can't",
      "get",
      "treated",
    ],
    id: "If you don't protect the NHS, you're going to have health ghettoes where people can't get treated.",
    author: "Toyah Willcox",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "everywhere", "yet", "always", "as", "somewhat", "recluse"],
    words: [
      "they",
      "say",
      "that",
      "god",
      "is",
      "everywhere",
      "and",
      "yet",
      "we",
      "always",
      "think",
      "of",
      "him",
      "as",
      "somewhat",
      "a",
      "recluse",
    ],
    id: "They say that God is everywhere, and yet we always think of Him as somewhat of a recluse.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "improvements", "made", "sad", "one", "any", "player"],
    words: [
      "the",
      "day",
      "you",
      "think",
      "there",
      "is",
      "no",
      "improvements",
      "to",
      "be",
      "made",
      "a",
      "sad",
      "one",
      "for",
      "any",
      "player",
    ],
    id: "The day you think there is no improvements to be made is a sad one for any player.",
    author: "Lionel Messi",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["lands", "their", "vitality", "few", "class", "bulk", "our"],
    words: [
      "other",
      "lands",
      "have",
      "their",
      "vitality",
      "in",
      "a",
      "few",
      "class",
      "but",
      "we",
      "it",
      "the",
      "bulk",
      "of",
      "our",
      "people",
    ],
    id: "Other lands have their vitality in a few, a class, but we have it in the bulk of our people.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "out", "or", "i've", "got", "chance", "pitch"],
    words: [
      "i",
      "don't",
      "care",
      "if",
      "we're",
      "out",
      "of",
      "it",
      "or",
      "not",
      "i've",
      "got",
      "a",
      "chance",
      "to",
      "pitch",
      "want",
    ],
    id: "I don't care if we're out of it or not, if I've got a chance to pitch, I want to.",
    author: "Greg Maddux",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["myth", "image", "terms", "which", "make", "sense", "world"],
    words: [
      "a",
      "myth",
      "is",
      "an",
      "image",
      "in",
      "terms",
      "of",
      "which",
      "we",
      "try",
      "to",
      "make",
      "sense",
      "the",
      "world",
    ],
    id: "A myth is an image in terms of which we try to make sense of the world.",
    author: "Alan Watts",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["still", "recession", "gonna", "out", "while", "will", "get"],
    words: [
      "we're",
      "still",
      "in",
      "a",
      "recession",
      "not",
      "gonna",
      "be",
      "out",
      "of",
      "it",
      "for",
      "while",
      "but",
      "we",
      "will",
      "get",
    ],
    id: "We're still in a recession. We're not gonna be out of it for a while, but we will get out.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["wake", "up", "morning", "happened", "overnight", "safe", "family's"],
    words: [
      "you",
      "wake",
      "up",
      "in",
      "the",
      "morning",
      "what",
      "do",
      "want",
      "to",
      "know",
      "happened",
      "overnight",
      "if",
      "you're",
      "safe",
      "family's",
    ],
    id: "You wake up in the morning; what do you want to know? You want to know what happened overnight. You want to know if you're safe. You want to know if you're family's safe.",
    author: "Chris Cuomo",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["prohibitionist", "sort", "man", "one", "couldn't", "even", "drank"],
    words: [
      "a",
      "prohibitionist",
      "is",
      "the",
      "sort",
      "of",
      "man",
      "one",
      "couldn't",
      "care",
      "to",
      "drink",
      "with",
      "even",
      "if",
      "he",
      "drank",
    ],
    id: "A prohibitionist is the sort of man one couldn't care to drink with, even if he drank.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "friendship",
      "something",
      "haven't",
      "learned",
      "meaning",
      "really",
      "anything",
    ],
    words: [
      "friendship",
      "is",
      "not",
      "something",
      "you",
      "learn",
      "in",
      "school",
      "but",
      "if",
      "haven't",
      "learned",
      "the",
      "meaning",
      "of",
      "really",
      "anything",
    ],
    id: "Friendship... is not something you learn in school. But if you haven't learned the meaning of friendship, you really haven't learned anything.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["intensity", "football", "how", "live", "liverpool", "-", "usual"],
    words: [
      "the",
      "intensity",
      "of",
      "football",
      "and",
      "how",
      "people",
      "live",
      "in",
      "liverpool",
      "-",
      "it",
      "is",
      "not",
      "a",
      "usual",
    ],
    id: "The intensity of the football and how the people live football in Liverpool - it is not a usual.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "rattlesnake", "poised", "strike", "until", "struck", "crush"],
    words: [
      "when",
      "you",
      "see",
      "a",
      "rattlesnake",
      "poised",
      "to",
      "strike",
      "do",
      "not",
      "wait",
      "until",
      "he",
      "has",
      "struck",
      "crush",
      "him",
    ],
    id: "When you see a rattlesnake poised to strike, you do not wait until he has struck to crush him.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["trouble", "energy", "farming", "isn't", "always", "use", "when"],
    words: [
      "the",
      "trouble",
      "with",
      "energy",
      "farming",
      "is",
      "that",
      "isn't",
      "always",
      "where",
      "you",
      "want",
      "to",
      "use",
      "it",
      "and",
      "when",
    ],
    id: "The trouble with energy farming is that the energy isn't always where you want to use it, and it isn't always when you want to use it.",
    author: "Bill Gates",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["commonly", "tax", "diminution", "freedom", "yet", "clearly", "one"],
    words: [
      "we",
      "do",
      "not",
      "commonly",
      "see",
      "in",
      "a",
      "tax",
      "diminution",
      "of",
      "freedom",
      "and",
      "yet",
      "it",
      "clearly",
      "is",
      "one",
    ],
    id: "We do not commonly see in a tax a diminution of freedom, and yet it clearly is one.",
    author: "Herbert Spencer",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["change", "three", "lives", "10", "hundred", "got", "hasn't"],
    words: [
      "if",
      "you",
      "can",
      "change",
      "three",
      "lives",
      "in",
      "10",
      "a",
      "hundred",
      "that's",
      "got",
      "to",
      "be",
      "good",
      "hasn't",
      "it",
    ],
    id: "If you can change three lives in 10, three lives in a hundred, that's got to be good, hasn't it?",
    author: "Ian Botham",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["wise", "man", "grieve", "things", "which", "rejoices", "those"],
    words: [
      "he",
      "is",
      "a",
      "wise",
      "man",
      "who",
      "does",
      "not",
      "grieve",
      "for",
      "the",
      "things",
      "which",
      "has",
      "but",
      "rejoices",
      "those",
    ],
    id: "He is a wise man who does not grieve for the things which he has not, but rejoices for those which he has.",
    author: "Epictetus",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["fool", "all", "time", "those", "ones", "concentrate", "on"],
    words: [
      "you",
      "can",
      "fool",
      "some",
      "of",
      "the",
      "people",
      "all",
      "time",
      "and",
      "those",
      "are",
      "ones",
      "want",
      "to",
      "concentrate",
      "on",
    ],
    id: "You can fool some of the people all the time, and those are the ones you want to concentrate on.",
    author: "George W. Bush",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["sometimes", "i", "chance", "forward", "course", "make", "most"],
    words: [
      "sometimes",
      "i",
      "just",
      "have",
      "a",
      "chance",
      "to",
      "go",
      "forward",
      "so",
      "of",
      "course",
      "try",
      "make",
      "the",
      "most",
      "it",
    ],
    id: "Sometimes I just have a chance to go forward, so of course I try to make the most of it.",
    author: "Julie Ertz",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "pitching", "movies", "sometimes", "idea", "fully", "dimensions"],
    words: [
      "when",
      "you're",
      "pitching",
      "movies",
      "sometimes",
      "you",
      "know",
      "what",
      "the",
      "idea",
      "is",
      "but",
      "fully",
      "don't",
      "dimensions",
      "of",
      "it",
    ],
    id: "When you're pitching movies, sometimes you know what the idea is, but you fully don't know the dimensions of it.",
    author: "Colin Jost",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["religion", "teaches", "man", "i", "none", "instructs", "bad"],
    words: [
      "every",
      "religion",
      "is",
      "good",
      "that",
      "teaches",
      "man",
      "to",
      "be",
      "and",
      "i",
      "know",
      "of",
      "none",
      "instructs",
      "him",
      "bad",
    ],
    id: "Every religion is good that teaches man to be good; and I know of none that instructs him to be bad.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["thornbush", "old", "obstacle", "road", "must", "fire", "further"],
    words: [
      "the",
      "thornbush",
      "is",
      "old",
      "obstacle",
      "in",
      "road",
      "it",
      "must",
      "catch",
      "fire",
      "if",
      "you",
      "want",
      "to",
      "go",
      "further",
    ],
    id: "The thornbush is the old obstacle in the road. It must catch fire if you want to go further.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["wisdom", "gift", "nothing", "age", "was", "probably", "case"],
    words: [
      "wisdom",
      "is",
      "a",
      "gift",
      "but",
      "has",
      "nothing",
      "to",
      "do",
      "with",
      "age",
      "that",
      "was",
      "probably",
      "the",
      "case",
      "me",
    ],
    id: "Wisdom is a gift but has nothing to do with age. That was probably the case with me.",
    author: "Ruby Bridges",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "i", "read", "papers", "alibi", "my", "ignorance"],
    words: [
      "all",
      "i",
      "know",
      "is",
      "just",
      "what",
      "read",
      "in",
      "the",
      "papers",
      "and",
      "that's",
      "an",
      "alibi",
      "for",
      "my",
      "ignorance",
    ],
    id: "All I know is just what I read in the papers, and that's an alibi for my ignorance.",
    author: "Will Rogers",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["many", "churches", "special", "programs", "grieving", "these", "helpful"],
    words: [
      "many",
      "churches",
      "today",
      "have",
      "special",
      "programs",
      "for",
      "people",
      "who",
      "are",
      "grieving",
      "and",
      "these",
      "can",
      "be",
      "very",
      "helpful",
    ],
    id: "Many churches today have special programs for people who are grieving, and these can be very helpful.",
    author: "Billy Graham",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["immigration", "laws", "only", "discussed", "terms", "how", "break"],
    words: [
      "immigration",
      "laws",
      "are",
      "the",
      "only",
      "that",
      "discussed",
      "in",
      "terms",
      "of",
      "how",
      "to",
      "help",
      "people",
      "who",
      "break",
      "them",
    ],
    id: "Immigration laws are the only laws that are discussed in terms of how to help people who break them.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "credit", "doing", "virtue", "falling", "when", "tempted"],
    words: [
      "there's",
      "no",
      "credit",
      "in",
      "not",
      "doing",
      "what",
      "you",
      "don't",
      "want",
      "to",
      "do",
      "virtue",
      "falling",
      "when",
      "you're",
      "tempted",
    ],
    id: "There's no credit in not doing what you don't want to do. There's no virtue in not falling, when you're not tempted.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "being",
      "transgender",
      "isn't",
      "medical",
      "transition",
      "process",
      "yourself",
    ],
    words: [
      "being",
      "transgender",
      "isn't",
      "a",
      "medical",
      "transition",
      "it's",
      "process",
      "of",
      "learning",
      "to",
      "love",
      "yourself",
      "for",
      "who",
      "you",
      "are",
    ],
    id: "Being transgender isn't a medical transition. It's a process of learning to love yourself for who you are.",
    author: "Jazz Jennings",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "something", "true", "because", "tyson", "says", "critical"],
    words: [
      "i",
      "don't",
      "want",
      "people",
      "to",
      "say",
      "something",
      "is",
      "true",
      "because",
      "tyson",
      "says",
      "it",
      "that's",
      "not",
      "critical",
      "thinking",
    ],
    id: "I don't want people to say, 'Something is true because Tyson says it is true.' That's not critical thinking.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "capitalism", "pcs", "there's", "no-one", "pc", "microsoft"],
    words: [
      "there",
      "are",
      "people",
      "who",
      "don't",
      "like",
      "capitalism",
      "and",
      "pcs",
      "but",
      "there's",
      "no-one",
      "likes",
      "the",
      "pc",
      "doesn't",
      "microsoft",
    ],
    id: "There are people who don't like capitalism, and people who don't like PCs. But there's no-one who likes the PC who doesn't like Microsoft.",
    author: "Bill Gates",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["university", "tell", "greater", "part", "law", "tolerate", "fools"],
    words: [
      "in",
      "university",
      "they",
      "don't",
      "tell",
      "you",
      "that",
      "the",
      "greater",
      "part",
      "of",
      "law",
      "is",
      "learning",
      "to",
      "tolerate",
      "fools",
    ],
    id: "In university they don't tell you that the greater part of the law is learning to tolerate fools.",
    author: "Doris Lessing",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["little", "indeed", "may", "dangerous", "thing", "calamity", "any"],
    words: [
      "a",
      "little",
      "learning",
      "indeed",
      "may",
      "be",
      "dangerous",
      "thing",
      "but",
      "the",
      "want",
      "of",
      "is",
      "calamity",
      "to",
      "any",
      "people",
    ],
    id: "A little learning, indeed, may be a dangerous thing, but the want of learning is a calamity to any people.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["i've", "learnt", "thick", "skin", "lots", "patience", "consistently"],
    words: [
      "i've",
      "learnt",
      "that",
      "you",
      "need",
      "to",
      "have",
      "a",
      "thick",
      "skin",
      "lots",
      "of",
      "patience",
      "and",
      "be",
      "consistently",
      "good",
    ],
    id: "I've learnt that you need to have a thick skin, lots of patience and be consistently good.",
    author: "Vaani Kapoor",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["next", "fight", "ufc", "tell", "i'm", "tony", "ferguson"],
    words: [
      "next",
      "fight",
      "if",
      "the",
      "ufc",
      "tell",
      "me",
      "what",
      "do",
      "you",
      "want",
      "i'm",
      "going",
      "to",
      "say",
      "tony",
      "ferguson",
    ],
    id: "Next fight? If the UFC tell me, 'What do you want?' I'm going to say Tony Ferguson.",
    author: "Khabib Nurmagomedov",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["conquer", "fear", "sit", "home", "out", "get", "busy"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "conquer",
      "fear",
      "don't",
      "sit",
      "home",
      "and",
      "think",
      "about",
      "it",
      "go",
      "out",
      "get",
      "busy",
    ],
    id: "If you want to conquer fear, don't sit home and think about it. Go out and get busy.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "orientals",
      "malays",
      "particular",
      "sensitive",
      "delicacy",
      "sentiment",
      "predominant",
    ],
    words: [
      "orientals",
      "and",
      "the",
      "malays",
      "in",
      "particular",
      "are",
      "a",
      "sensitive",
      "people",
      "delicacy",
      "of",
      "sentiment",
      "is",
      "predominant",
      "with",
      "them",
    ],
    id: "Orientals, and the Malays in particular, are a sensitive people: delicacy of sentiment is predominant with them.",
    author: "Jose Rizal",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "god", "big", "stuff", "planned", "ultimately", "anything"],
    words: [
      "but",
      "i",
      "know",
      "god",
      "has",
      "big",
      "stuff",
      "planned",
      "that",
      "ultimately",
      "doesn't",
      "have",
      "anything",
      "to",
      "do",
      "with",
      "me",
    ],
    id: "But I know God has big stuff planned that ultimately doesn't have anything to do with me.",
    author: "Michael W. Smith",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["leave", "live", "dragon", "out", "your", "calculations", "near"],
    words: [
      "it",
      "does",
      "not",
      "do",
      "to",
      "leave",
      "a",
      "live",
      "dragon",
      "out",
      "of",
      "your",
      "calculations",
      "if",
      "you",
      "near",
      "him",
    ],
    id: "It does not do to leave a live dragon out of your calculations, if you live near him.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["disarming", "when", "epidemic", "public", "health", "issue", "safety"],
    words: [
      "we",
      "are",
      "not",
      "for",
      "disarming",
      "people",
      "when",
      "you",
      "have",
      "an",
      "epidemic",
      "it's",
      "a",
      "public",
      "health",
      "issue",
      "safety",
    ],
    id: "We are not for disarming people. When you have an epidemic it's a public health issue, a safety issue.",
    author: "Sarah Brady",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["begins", "at", "home", "how", "much", "put", "action"],
    words: [
      "love",
      "begins",
      "at",
      "home",
      "and",
      "it",
      "is",
      "not",
      "how",
      "much",
      "we",
      "do",
      "but",
      "put",
      "in",
      "that",
      "action",
    ],
    id: "Love begins at home, and it is not how much we do... but how much love we put in that action.",
    author: "Mother Teresa",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["chief", "event", "life", "which", "encountered", "mind", "startled"],
    words: [
      "a",
      "chief",
      "event",
      "of",
      "life",
      "is",
      "the",
      "day",
      "in",
      "which",
      "we",
      "have",
      "encountered",
      "mind",
      "that",
      "startled",
      "us",
    ],
    id: "A chief event of life is the day in which we have encountered a mind that startled us.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["regret", "things", "did", "tempered", "by", "time", "inconsolable"],
    words: [
      "regret",
      "for",
      "the",
      "things",
      "we",
      "did",
      "can",
      "be",
      "tempered",
      "by",
      "time",
      "it",
      "is",
      "not",
      "do",
      "that",
      "inconsolable",
    ],
    id: "Regret for the things we did can be tempered by time; it is regret for the things we did not do that is inconsolable.",
    author: "Sydney J. Harris",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["lucky", "enough", "find", "way", "life", "courage", "live"],
    words: [
      "if",
      "you",
      "are",
      "lucky",
      "enough",
      "to",
      "find",
      "a",
      "way",
      "of",
      "life",
      "love",
      "have",
      "the",
      "courage",
      "live",
      "it",
    ],
    id: "If you are lucky enough to find a way of life you love, you have to find the courage to live it.",
    author: "John Irving",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "family", "support", "i'm", "always", "doing", "exactly"],
    words: [
      "i",
      "have",
      "a",
      "family",
      "to",
      "support",
      "and",
      "i'm",
      "not",
      "always",
      "going",
      "be",
      "doing",
      "exactly",
      "what",
      "want",
      "do",
    ],
    id: "I have a family to support. And I'm not always going to be doing exactly what I want to do.",
    author: "Patrick Warburton",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "more", "chance", "tease", "my", "music", "bit"],
    words: [
      "i",
      "want",
      "more",
      "people",
      "to",
      "know",
      "who",
      "am",
      "and",
      "have",
      "the",
      "chance",
      "tease",
      "my",
      "music",
      "a",
      "bit",
    ],
    id: "I want more people to know who I am and I want to have the chance to tease my music a bit more.",
    author: "MNEK",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["use", "haven't", "got", "tolerable", "planet", "put", "on"],
    words: [
      "what",
      "is",
      "the",
      "use",
      "of",
      "a",
      "house",
      "if",
      "you",
      "haven't",
      "got",
      "tolerable",
      "planet",
      "to",
      "put",
      "it",
      "on",
    ],
    id: "What is the use of a house if you haven't got a tolerable planet to put it on?",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["keep", "saying", "things", "bad", "chance", "being", "prophet"],
    words: [
      "if",
      "you",
      "keep",
      "saying",
      "things",
      "are",
      "going",
      "to",
      "be",
      "bad",
      "have",
      "a",
      "good",
      "chance",
      "of",
      "being",
      "prophet",
    ],
    id: "If you keep saying things are going to be bad, you have a good chance of being a prophet.",
    author: "Isaac Bashevis Singer",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["asks", "something", "time", "or", "inclination", "fraught", "vulnerability"],
    words: [
      "the",
      "moment",
      "someone",
      "asks",
      "you",
      "to",
      "do",
      "something",
      "don't",
      "have",
      "time",
      "or",
      "inclination",
      "is",
      "fraught",
      "with",
      "vulnerability",
    ],
    id: "The moment someone asks you to do something you don't have the time or inclination to do is fraught with vulnerability.",
    author: "Brene Brown",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["accidents", "there", "life", "little", "folly", "necessary", "out"],
    words: [
      "some",
      "accidents",
      "there",
      "are",
      "in",
      "life",
      "that",
      "a",
      "little",
      "folly",
      "is",
      "necessary",
      "to",
      "help",
      "us",
      "out",
      "of",
    ],
    id: "Some accidents there are in life that a little folly is necessary to help us out of.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["may", "able", "change", "situation", "humor", "your", "attitude"],
    words: [
      "you",
      "may",
      "not",
      "be",
      "able",
      "to",
      "change",
      "a",
      "situation",
      "but",
      "with",
      "humor",
      "can",
      "your",
      "attitude",
      "about",
      "it",
    ],
    id: "You may not be able to change a situation, but with humor you can change your attitude about it.",
    author: "Allen Klein",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["human", "artist", "main", "art", "creation", "our", "story"],
    words: [
      "every",
      "human",
      "is",
      "an",
      "artist",
      "and",
      "this",
      "the",
      "main",
      "art",
      "that",
      "we",
      "have",
      "creation",
      "of",
      "our",
      "story",
    ],
    id: "Every human is an artist. And this is the main art that we have: the creation of our story.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "family", "members", "others", "close", "vital", "homes"],
    words: [
      "patience",
      "with",
      "family",
      "members",
      "and",
      "others",
      "who",
      "are",
      "close",
      "to",
      "us",
      "is",
      "vital",
      "for",
      "have",
      "happy",
      "homes",
    ],
    id: "Patience with family members and others who are close to us is vital for us to have happy homes.",
    author: "Joseph B. Wirthlin",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["live", "by", "truth", "get", "ready", "call", "falsehood"],
    words: [
      "we",
      "have",
      "to",
      "live",
      "today",
      "by",
      "what",
      "truth",
      "can",
      "get",
      "and",
      "be",
      "ready",
      "tomorrow",
      "call",
      "it",
      "falsehood",
    ],
    id: "We have to live today by what truth we can get today and be ready tomorrow to call it falsehood.",
    author: "William James",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["appreciate", "until", "gone", "freedom", "air", "when", "notice"],
    words: [
      "we",
      "don't",
      "appreciate",
      "what",
      "have",
      "until",
      "it's",
      "gone",
      "freedom",
      "is",
      "like",
      "that",
      "air",
      "when",
      "you",
      "it",
      "notice",
    ],
    id: "We don't appreciate what we have until it's gone. Freedom is like that. It's like air. When you have it, you don't notice it.",
    author: "Boris Yeltsin",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "important", "health", "cannot", "healthy", "can't", "appreciate"],
    words: [
      "love",
      "is",
      "not",
      "as",
      "important",
      "good",
      "health",
      "you",
      "cannot",
      "be",
      "in",
      "if",
      "you're",
      "healthy",
      "can't",
      "appreciate",
      "it",
    ],
    id: "Love is not as important as good health. You cannot be in love if you're not healthy. You can't appreciate it.",
    author: "Bryan Cranston",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["matter", "cold", "or", "warm", "wade", "through", "anyway"],
    words: [
      "it",
      "doesn't",
      "matter",
      "if",
      "the",
      "water",
      "is",
      "cold",
      "or",
      "warm",
      "you're",
      "going",
      "to",
      "have",
      "wade",
      "through",
      "anyway",
    ],
    id: "It doesn't matter if the water is cold or warm if you're going to have to wade through it anyway.",
    author: "Pierre Teilhard de Chardin",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "never", "satisfied", "myself", "keeps", "-", "post-satisfaction"],
    words: [
      "i",
      "am",
      "never",
      "satisfied",
      "with",
      "myself",
      "and",
      "that",
      "is",
      "what",
      "keeps",
      "me",
      "going",
      "-",
      "have",
      "no",
      "post-satisfaction",
    ],
    id: "I am never satisfied with myself and that is what keeps me going - I have no post-satisfaction.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["miracle", "happens", "time", "those", "really", "more", "possess"],
    words: [
      "this",
      "is",
      "the",
      "miracle",
      "that",
      "happens",
      "every",
      "time",
      "to",
      "those",
      "who",
      "really",
      "love",
      "more",
      "they",
      "give",
      "possess",
    ],
    id: "This is the miracle that happens every time to those who really love: the more they give, the more they possess.",
    author: "Rainer Maria Rilke",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "unsung", "freedoms", "free", "press", "freedom", "read"],
    words: [
      "one",
      "of",
      "the",
      "unsung",
      "freedoms",
      "that",
      "go",
      "with",
      "a",
      "free",
      "press",
      "is",
      "freedom",
      "not",
      "to",
      "read",
      "it",
    ],
    id: "One of the unsung freedoms that go with a free press is the freedom not to read it.",
    author: "Ferdinand Mount",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "important",
      "our",
      "friends",
      "believe",
      "unreservedly",
      "frank",
      "friendship",
    ],
    words: [
      "it",
      "is",
      "important",
      "to",
      "our",
      "friends",
      "believe",
      "that",
      "we",
      "are",
      "unreservedly",
      "frank",
      "with",
      "them",
      "and",
      "friendship",
      "not",
    ],
    id: "It is important to our friends to believe that we are unreservedly frank with them, and important to friendship that we are not.",
    author: "Mignon McLaughlin",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["afraid", "too", "much", "fear", "person", "at", "all"],
    words: [
      "we",
      "are",
      "afraid",
      "to",
      "care",
      "too",
      "much",
      "for",
      "fear",
      "that",
      "the",
      "other",
      "person",
      "does",
      "not",
      "at",
      "all",
    ],
    id: "We are afraid to care too much, for fear that the other person does not care at all.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "legal", "career", "means", "end", "kingdom", "god"],
    words: [
      "your",
      "legal",
      "career",
      "is",
      "but",
      "a",
      "means",
      "to",
      "an",
      "end",
      "and",
      "that",
      "building",
      "the",
      "kingdom",
      "of",
      "god",
    ],
    id: "Your legal career is but a means to an end, and... that end is building the kingdom of God.",
    author: "Amy Coney Barrett",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["also", "my", "mom", "family", "important", "i", "expected"],
    words: [
      "also",
      "my",
      "mom",
      "and",
      "family",
      "are",
      "very",
      "important",
      "to",
      "me",
      "i",
      "know",
      "that",
      "this",
      "is",
      "not",
      "expected",
    ],
    id: "Also, my mom and family are very important to me and I know that this is not expected.",
    author: "Christina Milian",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["grateful", "make", "charming", "gardeners", "our", "souls", "blossom"],
    words: [
      "let",
      "us",
      "be",
      "grateful",
      "to",
      "people",
      "who",
      "make",
      "happy",
      "they",
      "are",
      "the",
      "charming",
      "gardeners",
      "our",
      "souls",
      "blossom",
    ],
    id: "Let us be grateful to people who make us happy, they are the charming gardeners who make our souls blossom.",
    author: "Marcel Proust",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "muslim", "i", "jesus", "would", "cool", "talk"],
    words: [
      "i'm",
      "a",
      "muslim",
      "but",
      "i",
      "think",
      "jesus",
      "would",
      "have",
      "drink",
      "with",
      "me",
      "he",
      "be",
      "cool",
      "talk",
      "to",
    ],
    id: "I'm a Muslim, but I think Jesus would have a drink with me. He would be cool. He would talk to me.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["how", "wonderful", "american", "known", "best", "times", "worst"],
    words: [
      "how",
      "wonderful",
      "it",
      "is",
      "to",
      "be",
      "an",
      "american",
      "we",
      "have",
      "known",
      "the",
      "best",
      "of",
      "times",
      "and",
      "worst",
    ],
    id: "How wonderful it is to be an American. We have known the best of times and the worst of times.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["nothing", "expecting", "get", "-", "only", "which", "everything"],
    words: [
      "love",
      "has",
      "nothing",
      "to",
      "do",
      "with",
      "what",
      "you",
      "are",
      "expecting",
      "get",
      "-",
      "only",
      "give",
      "which",
      "is",
      "everything",
    ],
    id: "Love has nothing to do with what you are expecting to get - only with what you are expecting to give - which is everything.",
    author: "Katharine Hepburn",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["secret", "life", "right", "loyalties", "hold", "scale", "values"],
    words: [
      "the",
      "secret",
      "of",
      "a",
      "good",
      "life",
      "is",
      "to",
      "have",
      "right",
      "loyalties",
      "and",
      "hold",
      "them",
      "in",
      "scale",
      "values",
    ],
    id: "The secret of a good life is to have the right loyalties and hold them in the right scale of values.",
    author: "Norman Thomas",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "thing", "patience", "attitude", "aren't", "ever", "up"],
    words: [
      "the",
      "only",
      "thing",
      "you",
      "have",
      "to",
      "is",
      "patience",
      "and",
      "an",
      "attitude",
      "that",
      "aren't",
      "ever",
      "going",
      "give",
      "up",
    ],
    id: "The only thing you have to have is patience and an attitude that you aren't ever going to give up.",
    author: "Shawn Michaels",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "music", "songs", "remember", "lines", "single", "second"],
    words: [
      "what",
      "i",
      "like",
      "about",
      "music",
      "is",
      "the",
      "songs",
      "you",
      "can",
      "remember",
      "lines",
      "of",
      "in",
      "a",
      "single",
      "second",
    ],
    id: "What I like about music is the songs you can remember the lines of in a single second.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["balkans", "dysfunctional", "family", "may", "fight", "argue", "end"],
    words: [
      "the",
      "people",
      "of",
      "balkans",
      "are",
      "like",
      "a",
      "dysfunctional",
      "family",
      "we",
      "may",
      "fight",
      "and",
      "argue",
      "but",
      "in",
      "end",
    ],
    id: "The people of the Balkans are like a dysfunctional family. We may fight and argue, but in the end we are family.",
    author: "Vlade Divac",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["during", "shoot", "when", "at", "lines", "next", "all-consuming"],
    words: [
      "during",
      "the",
      "shoot",
      "when",
      "you're",
      "not",
      "at",
      "work",
      "learning",
      "lines",
      "for",
      "next",
      "and",
      "that",
      "can",
      "be",
      "all-consuming",
    ],
    id: "During the shoot, when you're not at work, you're learning lines for the next shoot, and that can be all-consuming.",
    author: "Martin Henderson",
    grammar: null,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "made", "fair", "lovely", "eye", "one", "sees"],
    words: [
      "everything",
      "that",
      "is",
      "made",
      "beautiful",
      "and",
      "fair",
      "lovely",
      "for",
      "the",
      "eye",
      "of",
      "one",
      "who",
      "sees",
    ],
    id: "Everything that is made beautiful and fair and lovely is made for the eye of one who sees.",
    author: "Rumi",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "full", "satisfying", "life", "my", "family", "important"],
    words: [
      "i",
      "have",
      "a",
      "full",
      "and",
      "satisfying",
      "life",
      "my",
      "work",
      "family",
      "are",
      "very",
      "important",
      "to",
      "me",
    ],
    id: "I have a full and satisfying life. My work and my family are very important to me.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["thing", "i", "always", "avoid", "failure", "also", "success"],
    words: [
      "the",
      "thing",
      "i",
      "always",
      "say",
      "to",
      "people",
      "is",
      "this",
      "if",
      "you",
      "avoid",
      "failure",
      "also",
      "success",
    ],
    id: "The thing I always say to people is this: 'If you avoid failure, you also avoid success.'",
    author: "Robert Kiyosaki",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "artist", "always", "remember", "things", "weird", "way"],
    words: [
      "it's",
      "good",
      "as",
      "an",
      "artist",
      "to",
      "always",
      "remember",
      "see",
      "things",
      "in",
      "a",
      "new",
      "weird",
      "way",
    ],
    id: "It's good as an artist to always remember to see things in a new, weird way.",
    author: "Tim Burton",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["anything", "elegant", "as", "few", "serve", "one's", "self"],
    words: [
      "can",
      "anything",
      "be",
      "so",
      "elegant",
      "as",
      "to",
      "have",
      "few",
      "wants",
      "and",
      "serve",
      "them",
      "one's",
      "self",
    ],
    id: "Can anything be so elegant as to have few wants, and to serve them one's self?",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["dodgers", "there's", "chance", "may", "get", "into", "heaven"],
    words: [
      "if",
      "you",
      "don't",
      "love",
      "the",
      "dodgers",
      "there's",
      "a",
      "good",
      "chance",
      "may",
      "not",
      "get",
      "into",
      "heaven",
    ],
    id: "If you don't love the Dodgers, there's a good chance you may not get into Heaven.",
    author: "Tommy Lasorda",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["odorous", "at", "sunrise", "garden", "i", "walk", "undisturbed"],
    words: [
      "give",
      "me",
      "odorous",
      "at",
      "sunrise",
      "a",
      "garden",
      "of",
      "beautiful",
      "flowers",
      "where",
      "i",
      "can",
      "walk",
      "undisturbed",
    ],
    id: "Give me odorous at sunrise a garden of beautiful flowers where I can walk undisturbed.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["first", "principle", "must", "fool", "yourself", "easiest", "person"],
    words: [
      "the",
      "first",
      "principle",
      "is",
      "that",
      "you",
      "must",
      "not",
      "fool",
      "yourself",
      "and",
      "are",
      "easiest",
      "person",
      "to",
    ],
    id: "The first principle is that you must not fool yourself and you are the easiest person to fool.",
    author: "Richard P. Feynman",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["easy", "believe", "freedom", "speech", "those", "whom", "agree"],
    words: [
      "it",
      "is",
      "easy",
      "to",
      "believe",
      "in",
      "freedom",
      "of",
      "speech",
      "for",
      "those",
      "with",
      "whom",
      "we",
      "agree",
    ],
    id: "It is easy to believe in freedom of speech for those with whom we agree.",
    author: "Leo McKern",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "object", "soft", "makes", "hub", "wheeled", "universe"],
    words: [
      "there",
      "is",
      "no",
      "object",
      "so",
      "soft",
      "but",
      "it",
      "makes",
      "a",
      "hub",
      "for",
      "the",
      "wheeled",
      "universe",
    ],
    id: "There is no object so soft but it makes a hub for the wheeled universe.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "people's", "vanity", "because", "offensive", "our", "own"],
    words: [
      "we",
      "have",
      "no",
      "patience",
      "with",
      "other",
      "people's",
      "vanity",
      "because",
      "it",
      "is",
      "offensive",
      "to",
      "our",
      "own",
    ],
    id: "We have no patience with other people's vanity because it is offensive to our own.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["ignorant", "both", "your", "enemy", "yourself", "certain", "peril"],
    words: [
      "if",
      "ignorant",
      "both",
      "of",
      "your",
      "enemy",
      "and",
      "yourself",
      "you",
      "are",
      "certain",
      "to",
      "be",
      "in",
      "peril",
    ],
    id: "If ignorant both of your enemy and yourself, you are certain to be in peril.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "idea",
      "conceptualize",
      "static",
      "relationship",
      "long-standing",
      "policies",
      "health",
    ],
    words: [
      "it's",
      "not",
      "a",
      "good",
      "idea",
      "to",
      "conceptualize",
      "static",
      "relationship",
      "with",
      "long-standing",
      "policies",
      "like",
      "health",
      "care",
    ],
    id: "It's not a good idea to conceptualize a static relationship with long-standing policies, like health care.",
    author: "Ezra Klein",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["lovers", "truth", "nothing", "put", "before", "god", "hope"],
    words: [
      "to",
      "lovers",
      "of",
      "the",
      "truth",
      "nothing",
      "can",
      "be",
      "put",
      "before",
      "god",
      "and",
      "hope",
      "in",
      "him",
    ],
    id: "To lovers of the truth, nothing can be put before God and hope in Him.",
    author: "Saint Basil",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "must", "trouble", "my", "child", "may", "peace"],
    words: [
      "if",
      "there",
      "must",
      "be",
      "trouble",
      "let",
      "it",
      "in",
      "my",
      "day",
      "that",
      "child",
      "may",
      "have",
      "peace",
    ],
    id: "If there must be trouble, let it be in my day, that my child may have peace.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "pretend", "all", "answers", "questions", "certainly", "worth"],
    words: [
      "i",
      "don't",
      "pretend",
      "we",
      "have",
      "all",
      "the",
      "answers",
      "but",
      "questions",
      "are",
      "certainly",
      "worth",
      "thinking",
      "about",
    ],
    id: "I don't pretend we have all the answers. But the questions are certainly worth thinking about.",
    author: "Arthur C. Clarke",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["come", "canada", "our", "health", "ways", "enjoying", "ourselves"],
    words: [
      "we",
      "don't",
      "come",
      "to",
      "canada",
      "for",
      "our",
      "health",
      "can",
      "think",
      "of",
      "other",
      "ways",
      "enjoying",
      "ourselves",
    ],
    id: "We don't come to Canada for our health. We can think of other ways of enjoying ourselves.",
    author: "Prince Philip",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["main", "dangers", "life", "change", "everything", "or", "nothing"],
    words: [
      "the",
      "main",
      "dangers",
      "in",
      "this",
      "life",
      "are",
      "people",
      "who",
      "want",
      "to",
      "change",
      "everything",
      "or",
      "nothing",
    ],
    id: "The main dangers in this life are the people who want to change everything... or nothing.",
    author: "Nancy Astor",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["story", "woman's", "patience", "endure", "man's", "resolution", "achieve"],
    words: [
      "this",
      "is",
      "the",
      "story",
      "of",
      "what",
      "a",
      "woman's",
      "patience",
      "can",
      "endure",
      "and",
      "man's",
      "resolution",
      "achieve",
    ],
    id: "This is the story of what a Woman's patience can endure, and what a Man's resolution can achieve.",
    author: "Wilkie Collins",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["tis", "better", "bear", "ills", "than", "fly", "others"],
    words: [
      "tis",
      "better",
      "to",
      "bear",
      "the",
      "ills",
      "we",
      "have",
      "than",
      "fly",
      "others",
      "that",
      "know",
      "not",
      "of",
    ],
    id: "'Tis better to bear the ills we have than fly to others that we know not of.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["what'll", "ourselves", "afternoon", "after", "next", "thirty", "years"],
    words: [
      "what'll",
      "we",
      "do",
      "with",
      "ourselves",
      "this",
      "afternoon",
      "and",
      "the",
      "day",
      "after",
      "that",
      "next",
      "thirty",
      "years",
    ],
    id: "What'll we do with ourselves this afternoon? And the day after that, and the next thirty years?",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "thing", "on", "earth", "more", "than", "yourself"],
    words: [
      "a",
      "dog",
      "is",
      "the",
      "only",
      "thing",
      "on",
      "earth",
      "that",
      "loves",
      "you",
      "more",
      "than",
      "love",
      "yourself",
    ],
    id: "A dog is the only thing on earth that loves you more than you love yourself.",
    author: "Josh Billings",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["fortune", "harvest", "must", "busy", "when", "corn", "ripe"],
    words: [
      "the",
      "day",
      "of",
      "fortune",
      "is",
      "like",
      "a",
      "harvest",
      "we",
      "must",
      "be",
      "busy",
      "when",
      "corn",
      "ripe",
    ],
    id: "The day of fortune is like a harvest day, We must be busy when the corn is ripe.",
    author: "Torquato Tasso",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["put", "our", "minds", "together", "life", "make", "children"],
    words: [
      "let",
      "us",
      "put",
      "our",
      "minds",
      "together",
      "and",
      "see",
      "what",
      "life",
      "we",
      "can",
      "make",
      "for",
      "children",
    ],
    id: "Let us put our minds together and see what life we can make for our children.",
    author: "Sitting Bull",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["knowledge", "wellsprings", "our", "lives", "should", "also", "govern"],
    words: [
      "love",
      "work",
      "and",
      "knowledge",
      "are",
      "the",
      "wellsprings",
      "of",
      "our",
      "lives",
      "they",
      "should",
      "also",
      "govern",
      "it",
    ],
    id: "Love, work, and knowledge are the wellsprings of our lives, they should also govern it.",
    author: "Wilhelm Reich",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["difference", "between", "life", "movies", "script", "make", "sense"],
    words: [
      "the",
      "difference",
      "between",
      "life",
      "and",
      "movies",
      "is",
      "that",
      "a",
      "script",
      "has",
      "to",
      "make",
      "sense",
      "doesn't",
    ],
    id: "The difference between life and the movies is that a script has to make sense, and life doesn't.",
    author: "Joseph L. Mankiewicz",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "understand", "how", "little", "world", "until", "loss"],
    words: [
      "we",
      "never",
      "understand",
      "how",
      "little",
      "need",
      "in",
      "this",
      "world",
      "until",
      "know",
      "the",
      "loss",
      "of",
      "it",
    ],
    id: "We never understand how little we need in this world until we know the loss of it.",
    author: "James M. Barrie",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["should", "make", "out", "jesus", "i", "walk", "on"],
    words: [
      "people",
      "should",
      "not",
      "make",
      "me",
      "out",
      "to",
      "be",
      "like",
      "jesus",
      "i",
      "don't",
      "walk",
      "on",
      "water",
    ],
    id: "People should not make me out to be like Jesus; I don't walk on water.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "few", "men", "clever", "enough", "all", "mischief"],
    words: [
      "there",
      "are",
      "but",
      "very",
      "few",
      "men",
      "clever",
      "enough",
      "to",
      "know",
      "all",
      "the",
      "mischief",
      "they",
      "do",
    ],
    id: "There are but very few men clever enough to know all the mischief they do.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["30", "minutes", "rest", "little", "i", "fight", "anybody"],
    words: [
      "give",
      "me",
      "30",
      "minutes",
      "rest",
      "little",
      "drink",
      "of",
      "water",
      "and",
      "i",
      "can",
      "fight",
      "with",
      "anybody",
    ],
    id: "Give me 30 minutes rest, little drink of water. Give me 30 minutes, and I can fight with anybody.",
    author: "Khabib Nurmagomedov",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["ending", "depends", "course", "on", "stop", "your", "story"],
    words: [
      "if",
      "you",
      "want",
      "a",
      "happy",
      "ending",
      "that",
      "depends",
      "of",
      "course",
      "on",
      "where",
      "stop",
      "your",
      "story",
    ],
    id: "If you want a happy ending, that depends, of course, on where you stop your story.",
    author: "Orson Welles",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["gentle", "into", "night", "rage", "against", "dying", "light"],
    words: [
      "do",
      "not",
      "go",
      "gentle",
      "into",
      "that",
      "good",
      "night",
      "but",
      "rage",
      "against",
      "the",
      "dying",
      "of",
      "light",
    ],
    id: "Do not go gentle into that good night but rage, rage against the dying of the light.",
    author: "Dylan Thomas",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["christianity", "supplies", "hell", "disagree", "heaven", "your", "friends"],
    words: [
      "christianity",
      "supplies",
      "a",
      "hell",
      "for",
      "the",
      "people",
      "who",
      "disagree",
      "with",
      "you",
      "and",
      "heaven",
      "your",
      "friends",
    ],
    id: "Christianity supplies a Hell for the people who disagree with you and a Heaven for your friends.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["public", "expects", "healthy", "individual", "two", "different", "things"],
    words: [
      "what",
      "the",
      "public",
      "expects",
      "and",
      "is",
      "healthy",
      "for",
      "an",
      "individual",
      "are",
      "two",
      "very",
      "different",
      "things",
    ],
    id: "What the public expects and what is healthy for an individual are two very different things.",
    author: "Esther Williams",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "bad", "enough", "will", "find", "way", "get"],
    words: [
      "when",
      "you",
      "know",
      "what",
      "want",
      "and",
      "it",
      "bad",
      "enough",
      "will",
      "find",
      "a",
      "way",
      "to",
      "get",
    ],
    id: "When you know what you want, and want it bad enough, you will find a way to get it.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "word", "frees", "all", "weight", "pain", "life"],
    words: [
      "one",
      "word",
      "frees",
      "us",
      "of",
      "all",
      "the",
      "weight",
      "and",
      "pain",
      "in",
      "life",
      "that",
      "is",
      "love",
    ],
    id: "One word frees us of all the weight and pain in life. That word is love.",
    author: "Sophocles",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "always", "best", "way", "god", "many", "things"],
    words: [
      "but",
      "i",
      "always",
      "think",
      "that",
      "the",
      "best",
      "way",
      "to",
      "know",
      "god",
      "is",
      "love",
      "many",
      "things",
    ],
    id: "But I always think that the best way to know God is to love many things.",
    author: "Vincent Van Gogh",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "job", "won't", "green", "or", "three", "heads"],
    words: [
      "if",
      "i",
      "do",
      "a",
      "good",
      "job",
      "people",
      "won't",
      "care",
      "am",
      "green",
      "or",
      "have",
      "three",
      "heads",
    ],
    id: "If I do a good job, people won't care if I am green or have three heads.",
    author: "Harvey Milk",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["you've", "got", "out", "on", "limb", "sometimes", "because"],
    words: [
      "you've",
      "got",
      "to",
      "go",
      "out",
      "on",
      "a",
      "limb",
      "sometimes",
      "because",
      "that's",
      "where",
      "the",
      "fruit",
      "is",
    ],
    id: "You've got to go out on a limb sometimes because that's where the fruit is.",
    author: "Will Rogers",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["man's", "character", "abused", "there's", "nobody", "relative", "business"],
    words: [
      "if",
      "a",
      "man's",
      "character",
      "is",
      "to",
      "be",
      "abused",
      "there's",
      "nobody",
      "like",
      "relative",
      "do",
      "the",
      "business",
    ],
    id: "If a man's character is to be abused there's nobody like a relative to do the business.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "how", "deal", "women", "strength", "patience", "-"],
    words: [
      "men",
      "need",
      "to",
      "learn",
      "how",
      "deal",
      "with",
      "women",
      "strength",
      "and",
      "patience",
      "-",
      "this",
      "is",
      "love",
    ],
    id: "Men need to learn how to deal with women with strength and patience - this is love.",
    author: "Jesse Lee Peterson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "wish", "world", "must", "its", "particular", "details"],
    words: [
      "men",
      "who",
      "wish",
      "to",
      "know",
      "about",
      "the",
      "world",
      "must",
      "learn",
      "it",
      "in",
      "its",
      "particular",
      "details",
    ],
    id: "Men who wish to know about the world must learn about it in its particular details.",
    author: "Heraclitus",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "tyranny", "gain", "foothold", "conscience", "remain", "silent"],
    words: [
      "all",
      "tyranny",
      "needs",
      "to",
      "gain",
      "a",
      "foothold",
      "is",
      "for",
      "people",
      "of",
      "good",
      "conscience",
      "remain",
      "silent",
    ],
    id: "All tyranny needs to gain a foothold is for people of good conscience to remain silent.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "cannot", "discover", "anyone", "enough", "definitely", "possible"],
    words: [
      "i",
      "cannot",
      "discover",
      "that",
      "anyone",
      "knows",
      "enough",
      "to",
      "say",
      "definitely",
      "what",
      "is",
      "and",
      "not",
      "possible",
    ],
    id: "I cannot discover that anyone knows enough to say definitely what is and what is not possible.",
    author: "Henry Ford",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "among", "without", "sin", "first", "cast", "stone"],
    words: [
      "let",
      "the",
      "one",
      "among",
      "you",
      "who",
      "is",
      "without",
      "sin",
      "be",
      "first",
      "to",
      "cast",
      "a",
      "stone",
    ],
    id: "Let the one among you who is without sin be the first to cast a stone.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "aspirants", "fool", "enough", "believe", "read", "newspapers"],
    words: [
      "only",
      "the",
      "aspirants",
      "for",
      "president",
      "are",
      "fool",
      "enough",
      "to",
      "believe",
      "what",
      "they",
      "read",
      "in",
      "newspapers",
    ],
    id: "Only the aspirants for president are fool enough to believe what they read in the newspapers.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "bird", "his", "worm", "throw", "into", "nest"],
    words: [
      "god",
      "gives",
      "every",
      "bird",
      "his",
      "worm",
      "but",
      "he",
      "does",
      "not",
      "throw",
      "it",
      "into",
      "the",
      "nest",
    ],
    id: "God gives every bird his worm, but He does not throw it into the nest.",
    author: "P. D. James",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["leaders", "look", "around", "everything", "gonna", "everyone", "else"],
    words: [
      "we're",
      "the",
      "leaders",
      "look",
      "around",
      "everything",
      "we",
      "do",
      "gonna",
      "see",
      "everyone",
      "else",
      "try",
      "to",
      "it",
    ],
    id: "We're the leaders. Look around. Everything we do, we're gonna see everyone else try to do it.",
    author: "King Von",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["lot", "action", "movies", "seem", "scenes", "lead", "up"],
    words: [
      "a",
      "lot",
      "of",
      "action",
      "movies",
      "today",
      "seem",
      "to",
      "have",
      "scenes",
      "that",
      "just",
      "lead",
      "up",
      "the",
    ],
    id: "A lot of action movies today seem to have scenes that just lead up to the action.",
    author: "Jason Statham",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["internet", "easiest", "thing", "get", "into", "retailer", "url"],
    words: [
      "the",
      "internet",
      "is",
      "easiest",
      "thing",
      "to",
      "get",
      "into",
      "be",
      "an",
      "retailer",
      "you",
      "just",
      "that",
      "url",
    ],
    id: "The Internet is the easiest thing to get into. To be an Internet retailer, you just get that URL.",
    author: "Bill Gates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["season", "at", "all", "times", "within", "reach", "hand"],
    words: [
      "love",
      "is",
      "a",
      "fruit",
      "in",
      "season",
      "at",
      "all",
      "times",
      "and",
      "within",
      "reach",
      "of",
      "every",
      "hand",
    ],
    id: "Love is a fruit in season at all times, and within reach of every hand.",
    author: "Mother Teresa",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["better", "take", "lord", "gonna", "on", "your", "hands"],
    words: [
      "you",
      "better",
      "take",
      "care",
      "of",
      "me",
      "lord",
      "if",
      "don't",
      "you're",
      "gonna",
      "have",
      "on",
      "your",
      "hands",
    ],
    id: "You better take care of me Lord, if you don't you're gonna have me on your hands.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "god", "miracle", "somebody", "else's", "salvation"],
    words: [
      "i",
      "believe",
      "if",
      "god",
      "doesn't",
      "give",
      "you",
      "a",
      "miracle",
      "are",
      "of",
      "for",
      "somebody",
      "else's",
      "salvation",
    ],
    id: "I believe if God doesn't give you a miracle, you are a miracle of God for somebody else's salvation.",
    author: "Nick Vujicic",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["writing", "travelling", "wonderful", "somewhere", "get", "tired", "staying"],
    words: [
      "writing",
      "is",
      "like",
      "travelling",
      "it's",
      "wonderful",
      "to",
      "go",
      "somewhere",
      "but",
      "you",
      "get",
      "tired",
      "of",
      "staying",
    ],
    id: "Writing is like travelling. It's wonderful to go somewhere, but you get tired of staying.",
    author: "Langston Hughes",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["small", "company", "taking", "on", "big", "sense", "humor"],
    words: [
      "if",
      "you",
      "are",
      "a",
      "small",
      "company",
      "taking",
      "on",
      "big",
      "need",
      "to",
      "have",
      "sense",
      "of",
      "humor",
    ],
    id: "If you are a small company taking on a big company, you need to have a sense of humor.",
    author: "Richard Branson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "learned", "everybody", "talented", "original", "something", "important"],
    words: [
      "this",
      "is",
      "what",
      "i",
      "learned",
      "that",
      "everybody",
      "talented",
      "original",
      "and",
      "has",
      "something",
      "important",
      "to",
      "say",
    ],
    id: "This is what I learned: that everybody is talented, original and has something important to say.",
    author: "Brenda Ueland",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "gave", "gift", "life", "up", "ourselves", "living"],
    words: [
      "god",
      "gave",
      "us",
      "the",
      "gift",
      "of",
      "life",
      "it",
      "is",
      "up",
      "to",
      "give",
      "ourselves",
      "living",
      "well",
    ],
    id: "God gave us the gift of life; it is up to us to give ourselves the gift of living well.",
    author: "Voltaire",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["problem", "making", "our", "power", "credible", "vietnam", "place"],
    words: [
      "now",
      "we",
      "have",
      "a",
      "problem",
      "in",
      "making",
      "our",
      "power",
      "credible",
      "and",
      "vietnam",
      "is",
      "the",
      "place",
    ],
    id: "Now we have a problem in making our power credible, and Vietnam is the place.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["strike", "till", "iron", "hot", "make", "by", "striking"],
    words: [
      "do",
      "not",
      "wait",
      "to",
      "strike",
      "till",
      "the",
      "iron",
      "is",
      "hot",
      "but",
      "make",
      "it",
      "by",
      "striking",
    ],
    id: "Do not wait to strike till the iron is hot; but make it hot by striking.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "movies", "watching", "movie", "actors", "must", "something"],
    words: [
      "i",
      "love",
      "going",
      "to",
      "the",
      "movies",
      "watching",
      "good",
      "movie",
      "actors",
      "they",
      "must",
      "know",
      "something",
      "don't",
    ],
    id: "I love going to the movies; I love watching good movie actors. They must know something I don't.",
    author: "Uta Hagen",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "heightens", "feeling", "power", "will", "itself", "man"],
    words: [
      "what",
      "is",
      "good",
      "all",
      "that",
      "heightens",
      "the",
      "feeling",
      "of",
      "power",
      "will",
      "to",
      "itself",
      "in",
      "man",
    ],
    id: "What is good? All that heightens the feeling of power, the will to power, power itself in man.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["politics", "such", "torment", "i", "advise", "everyone", "mix"],
    words: [
      "politics",
      "is",
      "such",
      "a",
      "torment",
      "that",
      "i",
      "advise",
      "everyone",
      "love",
      "not",
      "to",
      "mix",
      "with",
      "it",
    ],
    id: "Politics is such a torment that I advise everyone I love not to mix with it.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "avoiding", "war", "only", "postponed", "advantage", "others"],
    words: [
      "there",
      "is",
      "no",
      "avoiding",
      "war",
      "it",
      "can",
      "only",
      "be",
      "postponed",
      "to",
      "the",
      "advantage",
      "of",
      "others",
    ],
    id: "There is no avoiding war; it can only be postponed to the advantage of others.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["opposite", "scarcity", "abundance", "enough", "i'm", "my", "kids"],
    words: [
      "for",
      "me",
      "the",
      "opposite",
      "of",
      "scarcity",
      "is",
      "not",
      "abundance",
      "it's",
      "enough",
      "i'm",
      "my",
      "kids",
      "are",
    ],
    id: "For me, the opposite of scarcity is not abundance. It's enough. I'm enough. My kids are enough.",
    author: "Brene Brown",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["too", "many", "spend", "haven't", "earned", "things", "impress"],
    words: [
      "too",
      "many",
      "people",
      "spend",
      "money",
      "they",
      "haven't",
      "earned",
      "to",
      "buy",
      "things",
      "don't",
      "want",
      "impress",
      "like",
    ],
    id: "Too many people spend money they haven't earned to buy things they don't want to impress people they don't like.",
    author: "Will Rogers",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["mystical", "explanations", "thought", "deep", "truth", "even", "shallow"],
    words: [
      "mystical",
      "explanations",
      "are",
      "thought",
      "to",
      "be",
      "deep",
      "the",
      "truth",
      "is",
      "that",
      "they",
      "not",
      "even",
      "shallow",
    ],
    id: "Mystical explanations are thought to be deep; the truth is that they are not even shallow.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["everyone", "fried", "ever", "make", "from", "place", "makes"],
    words: [
      "everyone",
      "loves",
      "fried",
      "chicken",
      "don't",
      "ever",
      "make",
      "it",
      "buy",
      "from",
      "a",
      "place",
      "that",
      "makes",
      "good",
    ],
    id: "Everyone loves fried chicken, Don't ever make it. Ever. Buy it from a place that makes good fried chicken.",
    author: "Nora Ephron",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["time", "on", "their", "hands", "will", "inevitably", "waste"],
    words: [
      "people",
      "who",
      "have",
      "time",
      "on",
      "their",
      "hands",
      "will",
      "inevitably",
      "waste",
      "the",
      "of",
      "work",
      "to",
      "do",
    ],
    id: "People who have time on their hands will inevitably waste the time of people who have work to do.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["die", "order", "rest", "should", "value", "life", "more"],
    words: [
      "someone",
      "has",
      "to",
      "die",
      "in",
      "order",
      "that",
      "the",
      "rest",
      "of",
      "us",
      "should",
      "value",
      "life",
      "more",
    ],
    id: "Someone has to die in order that the rest of us should value life more.",
    author: "Virginia Woolf",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "believe", "world", "one", "big", "family", "each"],
    words: [
      "i",
      "believe",
      "the",
      "world",
      "is",
      "one",
      "big",
      "family",
      "and",
      "we",
      "need",
      "to",
      "help",
      "each",
      "other",
    ],
    id: "I believe the world is one big family, and we need to help each other.",
    author: "Jet Li",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "itself", "representative", "strong", "forces", "life", "won't"],
    words: [
      "a",
      "cat",
      "is",
      "only",
      "itself",
      "representative",
      "of",
      "the",
      "strong",
      "forces",
      "life",
      "that",
      "won't",
      "let",
      "go",
    ],
    id: "A cat is only itself, representative of the strong forces of life that won't let go.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["leaders", "make", "feel", "at", "heart", "things", "periphery"],
    words: [
      "good",
      "leaders",
      "make",
      "people",
      "feel",
      "that",
      "they're",
      "at",
      "the",
      "very",
      "heart",
      "of",
      "things",
      "not",
      "periphery",
    ],
    id: "Good leaders make people feel that they're at the very heart of things, not at the periphery.",
    author: "Warren Bennis",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["desirable", "cultivate", "respect", "law", "much", "as", "right"],
    words: [
      "it",
      "is",
      "not",
      "desirable",
      "to",
      "cultivate",
      "a",
      "respect",
      "for",
      "the",
      "law",
      "so",
      "much",
      "as",
      "right",
    ],
    id: "It is not desirable to cultivate a respect for the law, so much as for the right.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["mind", "iceberg", "floats", "one-seventh", "its", "bulk", "above"],
    words: [
      "the",
      "mind",
      "is",
      "like",
      "an",
      "iceberg",
      "it",
      "floats",
      "with",
      "one-seventh",
      "of",
      "its",
      "bulk",
      "above",
      "water",
    ],
    id: "The mind is like an iceberg, it floats with one-seventh of its bulk above water.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["realize", "father", "bestowed", "on", "perfect", "christ", "bestows"],
    words: [
      "do",
      "you",
      "not",
      "realize",
      "that",
      "the",
      "love",
      "father",
      "bestowed",
      "on",
      "perfect",
      "christ",
      "he",
      "now",
      "bestows",
    ],
    id: "Do you not realize that the love the Father bestowed on the perfect Christ He now bestows on you?",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "feel", "there", "substitute", "out", "movies", "nothing"],
    words: [
      "i",
      "feel",
      "there",
      "is",
      "no",
      "substitute",
      "for",
      "going",
      "out",
      "to",
      "the",
      "movies",
      "nothing",
      "like",
      "it",
    ],
    id: "I feel there is no substitute for going out to the movies. There is nothing like it.",
    author: "Steven Spielberg",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "thinks", "must", "reach", "conclusions", "always", "pleasant"],
    words: [
      "people",
      "do",
      "not",
      "like",
      "to",
      "think",
      "if",
      "one",
      "thinks",
      "must",
      "reach",
      "conclusions",
      "are",
      "always",
      "pleasant",
    ],
    id: "People do not like to think. If one thinks, one must reach conclusions. Conclusions are not always pleasant.",
    author: "Helen Keller",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["long", "time", "all", "our", "strength", "salvation", "god"],
    words: [
      "we",
      "are",
      "a",
      "long",
      "time",
      "in",
      "learning",
      "that",
      "all",
      "our",
      "strength",
      "and",
      "salvation",
      "is",
      "god",
    ],
    id: "We are a long time in learning that all our strength and salvation is in God.",
    author: "David Brainerd",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["polish", "american", "or", "arab", "greek", "can't", "why"],
    words: [
      "you",
      "can",
      "be",
      "a",
      "polish",
      "american",
      "or",
      "an",
      "arab",
      "greek",
      "but",
      "can't",
      "english",
      "why",
      "not",
    ],
    id: "You can be a Polish American, or an Arab American, or a Greek American but you can't be English American. Why not?",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["k", "12", "partly", "babysitting", "kids", "parents", "things"],
    words: [
      "k",
      "to",
      "12",
      "is",
      "partly",
      "about",
      "babysitting",
      "the",
      "kids",
      "so",
      "parents",
      "can",
      "do",
      "other",
      "things",
    ],
    id: "K to 12 is partly about babysitting the kids so the parents can do other things.",
    author: "Bill Gates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "shortage", "days", "lives", "hard", "come", "by"],
    words: [
      "there",
      "is",
      "no",
      "shortage",
      "of",
      "good",
      "days",
      "it",
      "lives",
      "that",
      "are",
      "hard",
      "to",
      "come",
      "by",
    ],
    id: "There is no shortage of good days. It is good lives that are hard to come by.",
    author: "Annie Dillard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["art", "life", "how", "enjoy", "little", "endure", "much"],
    words: [
      "the",
      "art",
      "of",
      "life",
      "is",
      "to",
      "know",
      "how",
      "enjoy",
      "a",
      "little",
      "and",
      "endure",
      "very",
      "much",
    ],
    id: "The art of life is to know how to enjoy a little and to endure very much.",
    author: "William Hazlitt",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["was", "cock", "thought", "sun", "had", "risen", "crow"],
    words: [
      "he",
      "was",
      "like",
      "a",
      "cock",
      "who",
      "thought",
      "the",
      "sun",
      "had",
      "risen",
      "to",
      "hear",
      "him",
      "crow",
    ],
    id: "He was like a cock who thought the sun had risen to hear him crow.",
    author: "George Eliot",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["real", "christian", "person", "his", "pet", "town", "gossip"],
    words: [
      "a",
      "real",
      "christian",
      "is",
      "person",
      "who",
      "can",
      "give",
      "his",
      "pet",
      "parrot",
      "to",
      "the",
      "town",
      "gossip",
    ],
    id: "A real Christian is a person who can give his pet parrot to the town gossip.",
    author: "Billy Graham",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["gift", "from", "god", "focus", "on", "giver", "enjoy"],
    words: [
      "every",
      "day",
      "is",
      "a",
      "gift",
      "from",
      "god",
      "learn",
      "to",
      "focus",
      "on",
      "the",
      "giver",
      "and",
      "enjoy",
    ],
    id: "Every day is a gift from God. Learn to focus on the Giver and enjoy the gift!",
    author: "Joyce Meyer",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "my", "fans", "when", "get", "chance", "interact"],
    words: [
      "i",
      "love",
      "my",
      "fans",
      "so",
      "when",
      "get",
      "the",
      "chance",
      "to",
      "interact",
      "with",
      "them",
      "it's",
      "beautiful",
    ],
    id: "I love my fans, so when I get the chance to interact with them, it's beautiful.",
    author: "Flo Rida",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "own", "friend", "energetic", "government", "always", "oppressive"],
    words: [
      "i",
      "own",
      "that",
      "am",
      "not",
      "a",
      "friend",
      "to",
      "very",
      "energetic",
      "government",
      "it",
      "is",
      "always",
      "oppressive",
    ],
    id: "I own that I am not a friend to a very energetic government. It is always oppressive.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["painful", "forgetting", "knowing", "which", "worse", "kind", "suffering"],
    words: [
      "waiting",
      "is",
      "painful",
      "forgetting",
      "but",
      "not",
      "knowing",
      "which",
      "to",
      "do",
      "the",
      "worse",
      "kind",
      "of",
      "suffering",
    ],
    id: "Waiting is painful. Forgetting is painful. But not knowing which to do is the worse kind of suffering.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "at", "dawn", "era", "commercial", "space", "exploration"],
    words: [
      "i",
      "think",
      "we",
      "are",
      "at",
      "the",
      "dawn",
      "of",
      "a",
      "new",
      "era",
      "in",
      "commercial",
      "space",
      "exploration",
    ],
    id: "I think we are at the dawn of a new era in commercial space exploration.",
    author: "Elon Musk",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "there",
      "joy",
      "happiness",
      "except",
      "realization",
      "accomplished",
      "something",
    ],
    words: [
      "there",
      "is",
      "joy",
      "in",
      "work",
      "no",
      "happiness",
      "except",
      "the",
      "realization",
      "that",
      "we",
      "have",
      "accomplished",
      "something",
    ],
    id: "There is joy in work. There is no happiness except in the realization that we have accomplished something.",
    author: "Henry Ford",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "i", "feel", "free", "can't", "react", "against"],
    words: [
      "when",
      "i",
      "don't",
      "feel",
      "free",
      "and",
      "can't",
      "do",
      "what",
      "want",
      "just",
      "react",
      "go",
      "against",
      "it",
    ],
    id: "When I don't feel free and can't do what I want I just react. I go against it.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["platitude", "idea", "admitted", "true", "by", "everyone", "b"],
    words: [
      "platitude",
      "an",
      "idea",
      "a",
      "that",
      "is",
      "admitted",
      "to",
      "be",
      "true",
      "by",
      "everyone",
      "and",
      "b",
      "not",
    ],
    id: "Platitude: an idea (a) that is admitted to be true by everyone, and (b) that is not true.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "myself", "as", "artist", "i'm", "guy", "write"],
    words: [
      "i",
      "don't",
      "think",
      "of",
      "myself",
      "as",
      "an",
      "artist",
      "i'm",
      "just",
      "a",
      "guy",
      "who",
      "can",
      "write",
    ],
    id: "I don't think of myself as an artist. I'm just a guy who can write.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["person", "above", "ordinary", "certain", "mission", "called", "fulfill"],
    words: [
      "every",
      "person",
      "above",
      "the",
      "ordinary",
      "has",
      "a",
      "certain",
      "mission",
      "that",
      "they",
      "are",
      "called",
      "to",
      "fulfill",
    ],
    id: "Every person above the ordinary has a certain mission that they are called to fulfill.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "share", "may", "limited", "fact", "makes", "precious"],
    words: [
      "my",
      "share",
      "of",
      "the",
      "work",
      "may",
      "be",
      "limited",
      "but",
      "fact",
      "that",
      "it",
      "is",
      "makes",
      "precious",
    ],
    id: "My share of the work may be limited, but the fact that it is work makes it precious.",
    author: "Helen Keller",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "young", "out", "grab", "world", "by", "lapels"],
    words: [
      "i",
      "love",
      "to",
      "see",
      "a",
      "young",
      "girl",
      "go",
      "out",
      "and",
      "grab",
      "the",
      "world",
      "by",
      "lapels",
    ],
    id: "I love to see a young girl go out and grab the world by the lapels.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "pretend", "which", "actually", "feel", "ours", "command"],
    words: [
      "never",
      "pretend",
      "to",
      "a",
      "love",
      "which",
      "you",
      "do",
      "not",
      "actually",
      "feel",
      "for",
      "is",
      "ours",
      "command",
    ],
    id: "Never pretend to a love which you do not actually feel, for love is not ours to command.",
    author: "Alan Watts",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["secret", "was", "cool", "stay", "god's", "graces", "out"],
    words: [
      "the",
      "secret",
      "was",
      "to",
      "just",
      "be",
      "cool",
      "stay",
      "in",
      "god's",
      "graces",
      "and",
      "work",
      "it",
      "out",
    ],
    id: "The secret was to just be cool, stay in God's graces, and work it out.",
    author: "Solomon Burke",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "change", "blink", "eye", "god", "never", "blinks"],
    words: [
      "everything",
      "can",
      "change",
      "in",
      "the",
      "blink",
      "of",
      "an",
      "eye",
      "but",
      "don't",
      "worry",
      "god",
      "never",
      "blinks",
    ],
    id: "Everything can change in the blink of an eye. But don't worry; God never blinks.",
    author: "Regina Brett",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["remember", "always", "only", "right", "individual", "obligation", "one"],
    words: [
      "remember",
      "always",
      "that",
      "you",
      "not",
      "only",
      "have",
      "the",
      "right",
      "to",
      "be",
      "an",
      "individual",
      "obligation",
      "one",
    ],
    id: "Remember always that you not only have the right to be an individual, you have an obligation to be one.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "concern", "length", "person's", "hair", "his", "conduct"],
    words: [
      "my",
      "concern",
      "today",
      "is",
      "not",
      "with",
      "the",
      "length",
      "of",
      "a",
      "person's",
      "hair",
      "but",
      "his",
      "conduct",
    ],
    id: "My concern today is not with the length of a person's hair but with his conduct.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["gold", "being", "appreciative", "grateful", "your", "life", "family"],
    words: [
      "gold",
      "is",
      "about",
      "being",
      "appreciative",
      "and",
      "grateful",
      "of",
      "your",
      "life",
      "family",
      "the",
      "love",
      "in",
      "it",
    ],
    id: "'Gold' is about being appreciative and grateful of your life, your family, and the love in it.",
    author: "Jessie James Decker",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "trick", "being", "humorist", "when", "whole", "government"],
    words: [
      "there's",
      "no",
      "trick",
      "to",
      "being",
      "a",
      "humorist",
      "when",
      "you",
      "have",
      "the",
      "whole",
      "government",
      "working",
      "for",
    ],
    id: "There's no trick to being a humorist when you have the whole government working for you.",
    author: "Will Rogers",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["right", "truth", "as", "life", "liberty", "pursuit", "happiness"],
    words: [
      "the",
      "people",
      "have",
      "a",
      "right",
      "to",
      "truth",
      "as",
      "they",
      "life",
      "liberty",
      "and",
      "pursuit",
      "of",
      "happiness",
    ],
    id: "The People have a right to the Truth as they have a right to life, liberty and the pursuit of happiness.",
    author: "Frank Norris",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "best", "friend", "man", "wishing", "wishes", "sake"],
    words: [
      "my",
      "best",
      "friend",
      "is",
      "the",
      "man",
      "who",
      "in",
      "wishing",
      "me",
      "well",
      "wishes",
      "it",
      "for",
      "sake",
    ],
    id: "My best friend is the man who in wishing me well wishes it for my sake.",
    author: "Aristotle",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["attained", "by", "chance", "must", "sought", "ardor", "diligence"],
    words: [
      "learning",
      "is",
      "not",
      "attained",
      "by",
      "chance",
      "it",
      "must",
      "be",
      "sought",
      "for",
      "with",
      "ardor",
      "and",
      "diligence",
    ],
    id: "Learning is not attained by chance, it must be sought for with ardor and diligence.",
    author: "Abigail Adams",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "color", "green", "wbc", "belt", "can't", "get"],
    words: [
      "i",
      "like",
      "the",
      "color",
      "green",
      "and",
      "wbc",
      "belt",
      "is",
      "so",
      "can't",
      "wait",
      "to",
      "get",
      "that",
    ],
    id: "I like the color green, and the WBC belt is green, so I can't wait to get that belt.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["balance", "provides", "chance", "longevity", "champion", "at", "home"],
    words: [
      "balance",
      "provides",
      "the",
      "chance",
      "for",
      "longevity",
      "you",
      "can",
      "be",
      "a",
      "champion",
      "at",
      "work",
      "and",
      "home",
    ],
    id: "Balance provides the chance for longevity. You can be a champion at work and at home.",
    author: "Tony Dungy",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "kind", "paranoid", "reverse", "suspect", "plotting", "make"],
    words: [
      "i",
      "am",
      "a",
      "kind",
      "of",
      "paranoid",
      "in",
      "reverse",
      "suspect",
      "people",
      "plotting",
      "to",
      "make",
      "me",
      "happy",
    ],
    id: "I am a kind of paranoid in reverse. I suspect people of plotting to make me happy.",
    author: "J. D. Salinger",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["set", "free", "come", "back", "yours", "never", "were"],
    words: [
      "if",
      "you",
      "love",
      "someone",
      "set",
      "them",
      "free",
      "they",
      "come",
      "back",
      "they're",
      "yours",
      "don't",
      "never",
      "were",
    ],
    id: "If you love someone, set them free. If they come back they're yours; if they don't they never were.",
    author: "Richard Bach",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["few", "wisdom", "prefer", "criticism", "would", "praise", "deceives"],
    words: [
      "few",
      "people",
      "have",
      "the",
      "wisdom",
      "to",
      "prefer",
      "criticism",
      "that",
      "would",
      "do",
      "them",
      "good",
      "praise",
      "deceives",
    ],
    id: "Few people have the wisdom to prefer the criticism that would do them good, to the praise that deceives them.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["ill", "mankind", "wish", "perhaps", "highest", "wisdom", "virtue"],
    words: [
      "to",
      "think",
      "ill",
      "of",
      "mankind",
      "and",
      "not",
      "wish",
      "them",
      "is",
      "perhaps",
      "the",
      "highest",
      "wisdom",
      "virtue",
    ],
    id: "To think ill of mankind and not wish ill to them, is perhaps the highest wisdom and virtue.",
    author: "William Hazlitt",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["being", "same", "room", "creating", "something", "together", "thing"],
    words: [
      "being",
      "in",
      "the",
      "same",
      "room",
      "with",
      "people",
      "and",
      "creating",
      "something",
      "together",
      "is",
      "a",
      "good",
      "thing",
    ],
    id: "Being in the same room with people and creating something together is a good thing.",
    author: "Robin Williams",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["finding", "barber", "lawyer", "-", "gotta", "same", "guy"],
    words: [
      "finding",
      "a",
      "good",
      "barber",
      "is",
      "like",
      "lawyer",
      "-",
      "you",
      "gotta",
      "go",
      "to",
      "the",
      "same",
      "guy",
    ],
    id: "Finding a good barber is like finding a good lawyer - you gotta go to the same guy.",
    author: "Ronny Chieng",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "forgiveness",
      "really",
      "misunderstood",
      "as",
      "power",
      "release",
      "individual",
    ],
    words: [
      "forgiveness",
      "really",
      "is",
      "so",
      "misunderstood",
      "as",
      "well",
      "the",
      "power",
      "it",
      "can",
      "release",
      "in",
      "an",
      "individual",
    ],
    id: "Forgiveness really is so misunderstood, as well as the power it can release in an individual.",
    author: "Jennifer O'Neill",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["pleasures", "greatest", "anticipation", "remember", "also", "true", "trouble"],
    words: [
      "if",
      "pleasures",
      "are",
      "greatest",
      "in",
      "anticipation",
      "just",
      "remember",
      "that",
      "this",
      "is",
      "also",
      "true",
      "of",
      "trouble",
    ],
    id: "If pleasures are greatest in anticipation, just remember that this is also true of trouble.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "prayers", "should", "blessings", "general", "god", "best"],
    words: [
      "our",
      "prayers",
      "should",
      "be",
      "for",
      "blessings",
      "in",
      "general",
      "god",
      "knows",
      "best",
      "what",
      "is",
      "good",
      "us",
    ],
    id: "Our prayers should be for blessings in general, for God knows best what is good for us.",
    author: "Socrates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "oppression",
      "poor",
      "justice",
      "righteousness",
      "trampled",
      "country",
      "astounded",
    ],
    words: [
      "if",
      "you",
      "see",
      "oppression",
      "of",
      "the",
      "poor",
      "and",
      "justice",
      "righteousness",
      "trampled",
      "in",
      "a",
      "country",
      "do",
      "not",
      "be",
      "astounded",
    ],
    id: "If you see oppression of the poor, and justice and righteousness trampled in a country, do not be astounded.",
    author: "King Solomon",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "our", "belief", "system", "mirror", "shows", "believe"],
    words: [
      "we",
      "only",
      "see",
      "what",
      "want",
      "to",
      "hear",
      "our",
      "belief",
      "system",
      "is",
      "just",
      "like",
      "a",
      "mirror",
      "that",
      "shows",
      "us",
      "believe",
    ],
    id: "We only see what we want to see; we only hear what we want to hear. Our belief system is just like a mirror that only shows us what we believe.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "journey", "growing", "changing", "coming", "terms", "loving"],
    words: [
      "life",
      "is",
      "a",
      "journey",
      "and",
      "it's",
      "about",
      "growing",
      "changing",
      "coming",
      "to",
      "terms",
      "with",
      "who",
      "what",
      "you",
      "are",
      "loving",
    ],
    id: "Life is a journey and it's about growing and changing and coming to terms with who and what you are and loving who and what you are.",
    author: "Kelly McGillis",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "changes", "time", "i", "experienced", "enough", "analyze"],
    words: [
      "everything",
      "changes",
      "with",
      "time",
      "i",
      "am",
      "not",
      "so",
      "experienced",
      "enough",
      "to",
      "analyze",
      "what",
      "the",
      "people",
      "love",
      "in",
      "me",
    ],
    id: "Everything changes with time. I am not so experienced enough to analyze what the people love in me.",
    author: "Allu Arjun",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "did", "intend", "then", "why", "make", "out"],
    words: [
      "if",
      "god",
      "did",
      "not",
      "intend",
      "for",
      "us",
      "to",
      "eat",
      "animals",
      "then",
      "why",
      "he",
      "make",
      "them",
      "out",
      "of",
      "meat",
    ],
    id: "If God did not intend for us to eat animals, then why did he make them out of meat?",
    author: "John Cleese",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'd", "people's", "hearts", "i", "myself", "being", "country"],
    words: [
      "i'd",
      "like",
      "to",
      "be",
      "a",
      "queen",
      "in",
      "people's",
      "hearts",
      "but",
      "i",
      "don't",
      "see",
      "myself",
      "being",
      "of",
      "this",
      "country",
    ],
    id: "I'd like to be a queen in people's hearts but I don't see myself being queen of this country.",
    author: "Princess Diana",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "three", "times", "yogurt", "something", "rarely", "continues"],
    words: [
      "i",
      "eat",
      "fish",
      "three",
      "times",
      "a",
      "week",
      "meat",
      "and",
      "if",
      "not",
      "yogurt",
      "something",
      "like",
      "this",
      "it",
      "rarely",
      "continues",
    ],
    id: "I eat fish, three times a week meat, and if not yogurt, something like this and it rarely continues.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["knowledge", "at", "most", "little", "compared", "which", "ignorant"],
    words: [
      "the",
      "learning",
      "and",
      "knowledge",
      "that",
      "we",
      "have",
      "is",
      "at",
      "most",
      "but",
      "little",
      "compared",
      "with",
      "of",
      "which",
      "are",
      "ignorant",
    ],
    id: "The learning and knowledge that we have, is, at the most, but little compared with that of which we are ignorant.",
    author: "Plato",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "life", "listen", "your", "heart", "matter", "win"],
    words: [
      "i",
      "don't",
      "care",
      "what",
      "it",
      "is",
      "in",
      "life",
      "listen",
      "to",
      "your",
      "heart",
      "if",
      "you",
      "do",
      "no",
      "matter",
      "win",
    ],
    id: "I don't care what it is in life: listen to your heart. If you do, no matter what, you win.",
    author: "Paul Walker",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "stay", "same", "musician", "singer", "change", "way"],
    words: [
      "you",
      "can't",
      "stay",
      "the",
      "same",
      "if",
      "you're",
      "a",
      "musician",
      "and",
      "singer",
      "have",
      "to",
      "change",
      "that's",
      "way",
      "it",
      "works",
    ],
    id: "You can't stay the same. If you're a musician and a singer, you have to change, that's the way it works.",
    author: "Van Morrison",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["moments", "happiness", "enjoy", "take", "by", "surprise", "seize"],
    words: [
      "the",
      "moments",
      "of",
      "happiness",
      "we",
      "enjoy",
      "take",
      "us",
      "by",
      "surprise",
      "it",
      "is",
      "not",
      "that",
      "seize",
      "them",
      "but",
      "they",
    ],
    id: "The moments of happiness we enjoy take us by surprise. It is not that we seize them, but that they seize us.",
    author: "Ashley Montagu",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["become", "great", "chef", "chefs", "exactly", "i", "did"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "become",
      "a",
      "great",
      "chef",
      "have",
      "work",
      "with",
      "chefs",
      "and",
      "that's",
      "exactly",
      "what",
      "i",
      "did",
    ],
    id: "If you want to become a great chef, you have to work with great chefs. And that's exactly what I did.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["organization", "matter", "how", "designed", "only", "as", "live"],
    words: [
      "an",
      "organization",
      "no",
      "matter",
      "how",
      "well",
      "designed",
      "is",
      "only",
      "as",
      "good",
      "the",
      "people",
      "who",
      "live",
      "and",
      "work",
      "in",
      "it",
    ],
    id: "An organization, no matter how well designed, is only as good as the people who live and work in it.",
    author: "Dee Hock",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "movies", "instill", "passion", "viewer", "teach", "as"],
    words: [
      "i",
      "like",
      "movies",
      "that",
      "instill",
      "passion",
      "in",
      "the",
      "viewer",
      "can",
      "teach",
      "us",
      "about",
      "who",
      "we",
      "are",
      "as",
      "people",
    ],
    id: "I like movies that instill passion in the viewer. I like movies that can teach us about who we are as people.",
    author: "Alexander Ludwig",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "man", "beneath", "canopy", "heaven", "slavery", "wrong"],
    words: [
      "there",
      "is",
      "not",
      "a",
      "man",
      "beneath",
      "the",
      "canopy",
      "of",
      "heaven",
      "who",
      "does",
      "know",
      "that",
      "slavery",
      "wrong",
      "for",
      "him",
    ],
    id: "There is not a man beneath the canopy of Heaven who does not know that slavery is wrong for him.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["then", "time", "one", "asks", "i", "wish", "explain"],
    words: [
      "what",
      "then",
      "is",
      "time",
      "if",
      "no",
      "one",
      "asks",
      "me",
      "i",
      "know",
      "it",
      "wish",
      "to",
      "explain",
      "him",
      "who",
      "do",
      "not",
    ],
    id: "What then is time? If no one asks me, I know what it is. If I wish to explain it to him who asks, I do not know.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "anything", "antiques", "little", "knowledge", "great", "enthusiasm"],
    words: [
      "i",
      "don't",
      "know",
      "anything",
      "about",
      "antiques",
      "do",
      "buy",
      "them",
      "now",
      "but",
      "have",
      "a",
      "little",
      "knowledge",
      "and",
      "great",
      "enthusiasm",
    ],
    id: "I don't know anything about antiques. I do buy them now, but I have a little knowledge, and great enthusiasm.",
    author: "Fiona Bruce",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["on", "road", "success", "would", "your", "job", "paid"],
    words: [
      "you",
      "know",
      "are",
      "on",
      "the",
      "road",
      "to",
      "success",
      "if",
      "would",
      "do",
      "your",
      "job",
      "and",
      "not",
      "be",
      "paid",
      "for",
      "it",
    ],
    id: "You know you are on the road to success if you would do your job, and not be paid for it.",
    author: "Oprah Winfrey",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["been", "taught", "interested", "health", "disease", "public", "cure"],
    words: [
      "the",
      "doctor",
      "has",
      "been",
      "taught",
      "to",
      "be",
      "interested",
      "not",
      "in",
      "health",
      "but",
      "disease",
      "what",
      "public",
      "is",
      "that",
      "cure",
      "for",
    ],
    id: "The doctor has been taught to be interested not in health but in disease. What the public is taught is that health is the cure for disease.",
    author: "Ashley Montagu",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["adamant", "looking", "at", "google", "page", "pages", "wrong"],
    words: [
      "people",
      "are",
      "adamant",
      "learning",
      "is",
      "not",
      "just",
      "looking",
      "at",
      "a",
      "google",
      "page",
      "but",
      "it",
      "pages",
      "what",
      "wrong",
      "with",
      "that",
    ],
    id: "People are adamant learning is not just looking at a Google page. But it is. Learning is looking at Google pages. What is wrong with that?",
    author: "Sugata Mitra",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["objective", "leadership", "those", "doing", "poorly", "even", "better"],
    words: [
      "a",
      "good",
      "objective",
      "of",
      "leadership",
      "is",
      "to",
      "help",
      "those",
      "who",
      "are",
      "doing",
      "poorly",
      "do",
      "well",
      "and",
      "even",
      "better",
    ],
    id: "A good objective of leadership is to help those who are doing poorly to do well and to help those who are doing well to do even better.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "forgiveness",
      "weakness",
      "absolutely",
      "takes",
      "strong",
      "person",
      "forgive",
    ],
    words: [
      "we",
      "think",
      "that",
      "forgiveness",
      "is",
      "weakness",
      "but",
      "it's",
      "absolutely",
      "not",
      "it",
      "takes",
      "a",
      "very",
      "strong",
      "person",
      "to",
      "forgive",
    ],
    id: "We think that forgiveness is weakness, but it's absolutely not; it takes a very strong person to forgive.",
    author: "T. D. Jakes",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["nation", "region", "decision", "make", "either", "or", "terrorists"],
    words: [
      "every",
      "nation",
      "in",
      "region",
      "now",
      "has",
      "a",
      "decision",
      "to",
      "make",
      "either",
      "you",
      "are",
      "with",
      "us",
      "or",
      "the",
      "terrorists",
    ],
    id: "Every nation in every region now has a decision to make. Either you are with us, or you are with the terrorists.",
    author: "George W. Bush",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["end", "election", "participate", "politics", "cynicism", "or", "hope"],
    words: [
      "in",
      "the",
      "end",
      "that's",
      "what",
      "this",
      "election",
      "is",
      "about",
      "do",
      "we",
      "participate",
      "a",
      "politics",
      "of",
      "cynicism",
      "or",
      "hope",
    ],
    id: "In the end, that's what this election is about. Do we participate in a politics of cynicism or a politics of hope?",
    author: "Barack Obama",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "movies", "make", "semi", "fall", "villain", "sympathy"],
    words: [
      "i",
      "like",
      "movies",
      "that",
      "make",
      "you",
      "semi",
      "fall",
      "in",
      "love",
      "with",
      "the",
      "villain",
      "so",
      "have",
      "sympathy",
      "for",
      "him",
    ],
    id: "I like movies that make you semi fall in love with the villain so you have sympathy for him.",
    author: "Megan Thee Stallion",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["you've", "got", "careful", "because", "might", "get", "there"],
    words: [
      "you've",
      "got",
      "to",
      "be",
      "very",
      "careful",
      "if",
      "you",
      "don't",
      "know",
      "where",
      "are",
      "going",
      "because",
      "might",
      "not",
      "get",
      "there",
    ],
    id: "You've got to be very careful if you don't know where you are going, because you might not get there.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["easier", "change", "than", "your", "field", "changes", "around"],
    words: [
      "it's",
      "easier",
      "to",
      "change",
      "what",
      "you",
      "do",
      "than",
      "people",
      "think",
      "it",
      "is",
      "if",
      "don't",
      "your",
      "field",
      "changes",
      "around",
    ],
    id: "It's easier to change what you do than people think it is. If you don't change, your field changes around you.",
    author: "Walter Gilbert",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "common",
      "misconception",
      "entrepreneur's",
      "metric",
      "success",
      "nor",
      "should",
    ],
    words: [
      "it's",
      "a",
      "common",
      "misconception",
      "that",
      "money",
      "is",
      "every",
      "entrepreneur's",
      "metric",
      "for",
      "success",
      "not",
      "and",
      "nor",
      "should",
      "it",
      "be",
    ],
    id: "It's a common misconception that money is every entrepreneur's metric for success. It's not, and nor should it be.",
    author: "Richard Branson",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "engineers", "were", "position", "change", "world", "-"],
    words: [
      "as",
      "engineers",
      "we",
      "were",
      "going",
      "to",
      "be",
      "in",
      "a",
      "position",
      "change",
      "the",
      "world",
      "-",
      "not",
      "just",
      "study",
      "it",
    ],
    id: "As engineers, we were going to be in a position to change the world - not just study it.",
    author: "Henry Petroski",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "problem", "i", "christian", "must", "success", "too"],
    words: [
      "my",
      "problem",
      "is",
      "i",
      "am",
      "christian",
      "so",
      "think",
      "other",
      "people",
      "must",
      "have",
      "success",
      "too",
      "it's",
      "not",
      "about",
      "me",
    ],
    id: "My problem is I am Christian, so I think other people must have success, too; it's not about me.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["bring", "your", "marketplace", "skill", "feel", "tell", "bank"],
    words: [
      "don't",
      "bring",
      "your",
      "need",
      "to",
      "the",
      "marketplace",
      "skill",
      "if",
      "you",
      "feel",
      "well",
      "tell",
      "doctor",
      "but",
      "not",
      "money",
      "go",
      "bank",
    ],
    id: "Don't bring your need to the marketplace, bring your skill. If you don't feel well, tell your doctor, but not the marketplace. If you need money, go to the bank, but not the marketplace.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["hobbies", "apt", "run", "away", "must", "keep", "reins"],
    words: [
      "hobbies",
      "are",
      "apt",
      "to",
      "run",
      "away",
      "with",
      "us",
      "you",
      "know",
      "it",
      "doesn't",
      "do",
      "be",
      "we",
      "must",
      "keep",
      "the",
      "reins",
    ],
    id: "Hobbies are apt to run away with us, you know; it doesn't do to be run away with. We must keep the reins.",
    author: "George Eliot",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["everyone", "show", "how", "much", "each", "process", "ourselves"],
    words: [
      "everyone",
      "of",
      "us",
      "needs",
      "to",
      "show",
      "how",
      "much",
      "we",
      "care",
      "for",
      "each",
      "other",
      "and",
      "in",
      "the",
      "process",
      "ourselves",
    ],
    id: "Everyone of us needs to show how much we care for each other and, in the process, care for ourselves.",
    author: "Princess Diana",
    grammar: null,
    word_count: 18,
    all_intersection_count: 11,
    new_words_count: 7,
  },
  {
    row_new_words: ["war", "remedy", "our", "enemies", "chosen", "i", "all"],
    words: [
      "war",
      "is",
      "the",
      "remedy",
      "that",
      "our",
      "enemies",
      "have",
      "chosen",
      "and",
      "i",
      "say",
      "let",
      "us",
      "give",
      "them",
      "all",
      "they",
      "want",
    ],
    id: "War is the remedy that our enemies have chosen, and I say let us give them all they want.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "way", "kid", "practice", "total", "fun", "was"],
    words: [
      "the",
      "only",
      "way",
      "a",
      "kid",
      "is",
      "going",
      "to",
      "practice",
      "if",
      "it's",
      "total",
      "fun",
      "for",
      "him",
      "and",
      "it",
      "was",
      "me",
    ],
    id: "The only way a kid is going to practice is if it's total fun for him... and it was for me.",
    author: "Wayne Gretzky",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["monk", "time", "practice", "your", "transformation", "healing", "after"],
    words: [
      "to",
      "be",
      "a",
      "monk",
      "is",
      "have",
      "time",
      "practice",
      "for",
      "your",
      "transformation",
      "and",
      "healing",
      "after",
      "that",
      "help",
      "with",
      "the",
      "of",
      "other",
      "people",
    ],
    id: "To be a monk is to have time to practice for your transformation and healing. And after that to help with the transformation and healing of other people.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 21,
    all_intersection_count: 14,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "luckiest", "things", "happen", "life", "i", "childhood"],
    words: [
      "one",
      "of",
      "the",
      "luckiest",
      "things",
      "that",
      "can",
      "happen",
      "to",
      "you",
      "in",
      "life",
      "is",
      "i",
      "think",
      "have",
      "a",
      "happy",
      "childhood",
    ],
    id: "One of the luckiest things that can happen to you in life is, I think, to have a happy childhood.",
    author: "Agatha Christie",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "all", "person", "even", "part", "their", "happiness"],
    words: [
      "you",
      "know",
      "it's",
      "love",
      "when",
      "all",
      "want",
      "is",
      "that",
      "person",
      "to",
      "be",
      "happy",
      "even",
      "if",
      "you're",
      "not",
      "part",
      "of",
      "their",
      "happiness",
    ],
    id: "You know it's love when all you want is that person to be happy, even if you're not part of their happiness.",
    author: "Julia Roberts",
    grammar: null,
    word_count: 21,
    all_intersection_count: 14,
    new_words_count: 7,
  },
  {
    row_new_words: ["devil", "point", "surviving", "on", "living", "when", "drag"],
    words: [
      "what",
      "the",
      "devil",
      "is",
      "point",
      "of",
      "surviving",
      "going",
      "on",
      "living",
      "when",
      "it's",
      "a",
      "drag",
      "but",
      "you",
      "see",
      "that's",
      "people",
      "do",
    ],
    id: "What the devil is the point of surviving, going on living, when it's a drag? But you see, that's what people do.",
    author: "Alan Watts",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["key", "happiness", "i", "always", "figured", "enough", "made"],
    words: [
      "people",
      "say",
      "that",
      "money",
      "is",
      "not",
      "the",
      "key",
      "to",
      "happiness",
      "but",
      "i",
      "always",
      "figured",
      "if",
      "you",
      "have",
      "enough",
      "can",
      "a",
      "made",
    ],
    id: "People say that money is not the key to happiness, but I always figured if you have enough money, you can have a key made.",
    author: "Joan Rivers",
    grammar: null,
    word_count: 21,
    all_intersection_count: 14,
    new_words_count: 7,
  },
  {
    row_new_words: ["lord", "wisdom", "utter", "words", "gentle", "tender", "may"],
    words: [
      "lord",
      "give",
      "us",
      "the",
      "wisdom",
      "to",
      "utter",
      "words",
      "that",
      "are",
      "gentle",
      "and",
      "tender",
      "for",
      "tomorrow",
      "we",
      "may",
      "have",
      "eat",
      "them",
    ],
    id: "Lord, give us the wisdom to utter words that are gentle and tender, for tomorrow we may have to eat them.",
    author: "Mo Udall",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["important", "thing", "your", "teammates", "pulling", "really", "successful"],
    words: [
      "the",
      "important",
      "thing",
      "is",
      "that",
      "your",
      "teammates",
      "have",
      "to",
      "know",
      "you're",
      "pulling",
      "for",
      "them",
      "and",
      "you",
      "really",
      "want",
      "be",
      "successful",
    ],
    id: "The important thing is that your teammates have to know you're pulling for them and you really want them to be successful.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "series", "collisions", "future", "sum", "been", "yearn"],
    words: [
      "life",
      "is",
      "a",
      "series",
      "of",
      "collisions",
      "with",
      "the",
      "future",
      "it",
      "not",
      "sum",
      "what",
      "we",
      "have",
      "been",
      "but",
      "yearn",
      "to",
      "be",
    ],
    id: "Life is a series of collisions with the future; it is not the sum of what we have been, but what we yearn to be.",
    author: "Jose Ortega y Gasset",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "there", "pockets", "change", "same", "after", "all"],
    words: [
      "money",
      "is",
      "always",
      "there",
      "but",
      "the",
      "pockets",
      "change",
      "it",
      "not",
      "in",
      "same",
      "after",
      "a",
      "and",
      "that",
      "all",
      "to",
      "say",
      "about",
    ],
    id: "Money is always there but the pockets change; it is not in the same pockets after a change, and that is all there is to say about money.",
    author: "Gertrude Stein",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "friends", "death", "look", "terrible", "change", "right"],
    words: [
      "i",
      "need",
      "good",
      "friends",
      "who",
      "can",
      "say",
      "love",
      "you",
      "to",
      "death",
      "but",
      "look",
      "terrible",
      "in",
      "that",
      "and",
      "change",
      "right",
      "now",
    ],
    id: "I need good friends who can say, 'I love you to death, but you look terrible in that, and you need to change right now.'",
    author: "Blake Lively",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "can't", "acquired", "overnight", "up", "muscle", "on"],
    words: [
      "patience",
      "can't",
      "be",
      "acquired",
      "overnight",
      "it",
      "is",
      "just",
      "like",
      "building",
      "up",
      "a",
      "muscle",
      "every",
      "day",
      "you",
      "need",
      "to",
      "work",
      "on",
    ],
    id: "Patience can't be acquired overnight. It is just like building up a muscle. Every day you need to work on it.",
    author: "Eknath Easwaran",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "patient", "become", "god's", "children", "really", "weeping"],
    words: [
      "god",
      "is",
      "patient",
      "with",
      "us",
      "to",
      "become",
      "the",
      "god's",
      "children",
      "he",
      "wants",
      "be",
      "but",
      "you",
      "really",
      "can",
      "see",
      "him",
      "weeping",
    ],
    id: "God is patient with us to become the God's children he wants us to be but you really can see him weeping.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["whenever", "man", "his", "country", "sign", "expects", "paid"],
    words: [
      "whenever",
      "you",
      "hear",
      "a",
      "man",
      "speak",
      "of",
      "his",
      "love",
      "for",
      "country",
      "it",
      "is",
      "sign",
      "that",
      "he",
      "expects",
      "to",
      "be",
      "paid",
    ],
    id: "Whenever you hear a man speak of his love for his country, it is a sign that he expects to be paid for it.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "order",
      "future",
      "exciting",
      "inspiring",
      "one",
      "space-bearing",
      "civilization",
    ],
    words: [
      "in",
      "order",
      "for",
      "us",
      "to",
      "have",
      "a",
      "future",
      "that's",
      "exciting",
      "and",
      "inspiring",
      "it",
      "has",
      "be",
      "one",
      "where",
      "we're",
      "space-bearing",
      "civilization",
    ],
    id: "In order for us to have a future that's exciting and inspiring, it has to be one where we're a space-bearing civilization.",
    author: "Elon Musk",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["world", "little", "man", "or", "woman", "able", "counts"],
    words: [
      "the",
      "world",
      "cares",
      "very",
      "little",
      "about",
      "what",
      "a",
      "man",
      "or",
      "woman",
      "knows",
      "it",
      "is",
      "able",
      "to",
      "do",
      "that",
      "counts",
    ],
    id: "The world cares very little about what a man or woman knows; it is what a man or woman is able to do that counts.",
    author: "Virgil",
    grammar: null,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["why", "lie", "i'm", "hypocrite", "opposite", "i", "others"],
    words: [
      "why",
      "lie",
      "i'm",
      "not",
      "going",
      "to",
      "be",
      "a",
      "hypocrite",
      "and",
      "say",
      "the",
      "opposite",
      "of",
      "what",
      "i",
      "think",
      "like",
      "some",
      "others",
      "do",
    ],
    id: "Why lie? I'm not going to be a hypocrite and say the opposite of what I think, like some others do.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 21,
    all_intersection_count: 14,
    new_words_count: 7,
  },
  {
    row_new_words: ["tao", "teaches", "intervene", "interfere", "things", "leave", "alone"],
    words: [
      "the",
      "tao",
      "teaches",
      "us",
      "not",
      "to",
      "intervene",
      "and",
      "interfere",
      "things",
      "we",
      "love",
      "have",
      "learn",
      "leave",
      "alone",
      "people",
      "let",
      "them",
      "be",
    ],
    id: "The Tao teaches us not to intervene and interfere. The things we love we have to learn to leave alone. And the people we love we have to learn to let them be.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiest", "seem", "those", "particular", "cause", "being", "except"],
    words: [
      "the",
      "happiest",
      "people",
      "seem",
      "to",
      "be",
      "those",
      "who",
      "have",
      "no",
      "particular",
      "cause",
      "for",
      "being",
      "happy",
      "except",
      "that",
      "they",
      "are",
      "so",
    ],
    id: "The happiest people seem to be those who have no particular cause for being happy except that they are so.",
    author: "William Inge",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["secret", "marriage", "finding", "right", "person", "all", "time"],
    words: [
      "the",
      "secret",
      "of",
      "a",
      "happy",
      "marriage",
      "is",
      "finding",
      "right",
      "person",
      "you",
      "know",
      "they're",
      "if",
      "love",
      "to",
      "be",
      "with",
      "them",
      "all",
      "time",
    ],
    id: "The secret of a happy marriage is finding the right person. You know they're right if you love to be with them all the time.",
    author: "Julia Child",
    grammar: null,
    word_count: 21,
    all_intersection_count: 14,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "choose", "tell", "facts", "others", "because", "upset"],
    words: [
      "we",
      "can't",
      "just",
      "choose",
      "to",
      "tell",
      "some",
      "facts",
      "and",
      "not",
      "others",
      "because",
      "don't",
      "want",
      "upset",
      "people",
      "have",
      "it",
      "like",
      "is",
    ],
    id: "We can't just choose to tell some facts and not others because we don't want to upset people. We have to tell it like it is.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 20,
    all_intersection_count: 13,
    new_words_count: 7,
  },
  {
    row_new_words: ["sure", "yourself", "fashion", "beauty", "attitude", "believe", "strong"],
    words: [
      "be",
      "sure",
      "what",
      "you",
      "want",
      "and",
      "about",
      "yourself",
      "fashion",
      "is",
      "not",
      "just",
      "beauty",
      "it's",
      "good",
      "attitude",
      "have",
      "to",
      "believe",
      "in",
      "strong",
    ],
    id: "Be sure what you want and be sure about yourself. Fashion is not just beauty, it's about good attitude. You have to believe in yourself and be strong.",
    author: "Adriana Lima",
    grammar: null,
    word_count: 21,
    all_intersection_count: 14,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "tell", "i", "censor", "myself", "protect", "happiness"],
    words: [
      "when",
      "you're",
      "in",
      "love",
      "so",
      "happy",
      "that",
      "you",
      "want",
      "to",
      "tell",
      "people",
      "about",
      "it",
      "but",
      "now",
      "i",
      "have",
      "censor",
      "myself",
      "need",
      "protect",
      "the",
      "happiness",
    ],
    id: "When you're in love, you're so happy that you want to tell people about it. But now I have to censor myself. You need to protect the happiness you have.",
    author: "Emily Blunt",
    grammar: null,
    word_count: 24,
    all_intersection_count: 17,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "abundant", "place", "all", "on", "earth", "take"],
    words: [
      "life",
      "is",
      "abundant",
      "and",
      "beautiful",
      "it's",
      "a",
      "good",
      "place",
      "that",
      "we're",
      "all",
      "in",
      "you",
      "know",
      "on",
      "this",
      "earth",
      "if",
      "we",
      "take",
      "care",
      "of",
      "it",
    ],
    id: "Life is abundant, and life is beautiful. And it's a good place that we're all in, you know, on this earth, if we take care of it.",
    author: "Alice Walker",
    grammar: null,
    word_count: 24,
    all_intersection_count: 17,
    new_words_count: 7,
  },
  {
    row_new_words: ["get", "up", "morning", "future", "better", "bright", "otherwise"],
    words: [
      "if",
      "you",
      "get",
      "up",
      "in",
      "the",
      "morning",
      "and",
      "think",
      "future",
      "is",
      "going",
      "to",
      "be",
      "better",
      "it",
      "a",
      "bright",
      "day",
      "otherwise",
      "it's",
      "not",
    ],
    id: "If you get up in the morning and think the future is going to be better, it is a bright day. Otherwise, it's not.",
    author: "Elon Musk",
    grammar: null,
    word_count: 22,
    all_intersection_count: 15,
    new_words_count: 7,
  },
  {
    row_new_words: ["hardest", "all", "affection", "fountain", "show", "when", "feel"],
    words: [
      "the",
      "hardest",
      "of",
      "all",
      "is",
      "learning",
      "to",
      "be",
      "a",
      "well",
      "affection",
      "and",
      "not",
      "fountain",
      "show",
      "them",
      "we",
      "love",
      "when",
      "feel",
      "like",
      "it",
      "but",
      "they",
      "do",
    ],
    id: "The hardest of all is learning to be a well of affection, and not a fountain; to show them we love them not when we feel like it, but when they do.",
    author: "Nan Fairbrother",
    grammar: null,
    word_count: 25,
    all_intersection_count: 18,
    new_words_count: 7,
  },
  {
    row_new_words: ["past", "only", "as", "make", "useful", "present", "future"],
    words: [
      "we",
      "have",
      "to",
      "do",
      "with",
      "the",
      "past",
      "only",
      "as",
      "can",
      "make",
      "it",
      "useful",
      "present",
      "and",
      "future",
    ],
    id: "We have to do with the past only as we can make it useful to the present and the future.",
    author: "Frederick Douglass",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["crazy", "get", "paid", "or", "else", "locked", "up"],
    words: [
      "if",
      "you're",
      "going",
      "to",
      "be",
      "crazy",
      "you",
      "have",
      "get",
      "paid",
      "for",
      "it",
      "or",
      "else",
      "locked",
      "up",
    ],
    id: "If you're going to be crazy, you have to get paid for it or else you're going to be locked up.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["talk", "as", "father", "-", "constitution", "says", "feel"],
    words: [
      "just",
      "talk",
      "to",
      "me",
      "as",
      "a",
      "father",
      "-",
      "not",
      "what",
      "the",
      "constitution",
      "says",
      "do",
      "you",
      "feel",
    ],
    id: "Just talk to me as a father - not what the Constitution says. What do you feel?",
    author: "Joe Biden",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["was", "jesus", "son", "god", "haven't", "realized", "yet"],
    words: [
      "was",
      "jesus",
      "the",
      "son",
      "of",
      "god",
      "yes",
      "but",
      "so",
      "are",
      "you",
      "just",
      "haven't",
      "realized",
      "it",
      "yet",
    ],
    id: "Was Jesus the son of God? Yes. But so are you. You just haven't realized it yet.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "listening", "nothing", "would", "wish", "put", "newspapers"],
    words: [
      "no",
      "one",
      "knows",
      "who",
      "is",
      "listening",
      "say",
      "nothing",
      "you",
      "would",
      "not",
      "wish",
      "put",
      "in",
      "the",
      "newspapers",
    ],
    id: "No one knows who is listening, say nothing you would not wish put in the newspapers.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["anyone", "vested", "interest", "space", "solar", "power", "would"],
    words: [
      "if",
      "anyone",
      "has",
      "a",
      "vested",
      "interest",
      "in",
      "space",
      "solar",
      "power",
      "it",
      "would",
      "have",
      "to",
      "be",
      "me",
    ],
    id: "If anyone has a vested interest in space solar power, it would have to be me.",
    author: "Elon Musk",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "successful", "politician", "says", "often", "loudest", "voice"],
    words: [
      "the",
      "most",
      "successful",
      "politician",
      "is",
      "he",
      "who",
      "says",
      "what",
      "people",
      "are",
      "thinking",
      "often",
      "in",
      "loudest",
      "voice",
    ],
    id: "The most successful politician is he who says what the people are thinking most often in the loudest voice.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["much", "our", "friends", "helps", "as", "confidence", "their"],
    words: [
      "it",
      "is",
      "not",
      "so",
      "much",
      "our",
      "friends",
      "help",
      "that",
      "helps",
      "us",
      "as",
      "the",
      "confidence",
      "of",
      "their",
    ],
    id: "It is not so much our friends' help that helps us, as the confidence of their help.",
    author: "Epicurus",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["right", "thing", "immediate", "karma", "i", "feel", "myself"],
    words: [
      "just",
      "try",
      "to",
      "do",
      "the",
      "right",
      "thing",
      "and",
      "that's",
      "immediate",
      "karma",
      "i",
      "feel",
      "good",
      "about",
      "myself",
    ],
    id: "Just try to do the right thing, and that's immediate karma: 'I feel good about myself.'",
    author: "Linda Thompson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["seem", "their", "opinion", "world", "also", "confession", "character"],
    words: [
      "people",
      "seem",
      "not",
      "to",
      "see",
      "that",
      "their",
      "opinion",
      "of",
      "the",
      "world",
      "is",
      "also",
      "a",
      "confession",
      "character",
    ],
    id: "People seem not to see that their opinion of the world is also a confession of character.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["measure", "success", "by", "number", "friends", "remained", "loyal"],
    words: [
      "you",
      "can",
      "measure",
      "success",
      "by",
      "the",
      "number",
      "of",
      "friends",
      "who",
      "have",
      "remained",
      "loyal",
      "to",
      "and",
      "them",
    ],
    id: "You can measure success by the number of friends who have remained loyal to you, and you to them.",
    author: "Richard E. Grant",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["allowed", "bossy", "when", "married", "compromise", "compassion", "patience"],
    words: [
      "no",
      "you're",
      "not",
      "allowed",
      "to",
      "be",
      "bossy",
      "when",
      "married",
      "you",
      "have",
      "learn",
      "compromise",
      "and",
      "compassion",
      "patience",
    ],
    id: "No, you're not allowed to be bossy when you're married. You have to learn compromise, and compassion and patience.",
    author: "Star Jones",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "guinea", "pigs", "laboratory", "god", "humanity", "progress"],
    words: [
      "all",
      "of",
      "us",
      "are",
      "guinea",
      "pigs",
      "in",
      "the",
      "laboratory",
      "god",
      "humanity",
      "is",
      "just",
      "a",
      "work",
      "progress",
    ],
    id: "All of us are guinea pigs in the laboratory of God. Humanity is just a work in progress.",
    author: "Tennessee Williams",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["right", "then", "out", "loud", "otherwise", "passes", "by"],
    words: [
      "if",
      "you",
      "love",
      "someone",
      "say",
      "it",
      "right",
      "then",
      "out",
      "loud",
      "otherwise",
      "the",
      "moment",
      "just",
      "passes",
      "by",
    ],
    id: "If you love someone, you say it, right then, out loud. Otherwise, the moment just passes you by.",
    author: "Julia Roberts",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["wisdom", "knowledge", "evil", "strength", "choose", "between", "two"],
    words: [
      "wisdom",
      "we",
      "know",
      "is",
      "the",
      "knowledge",
      "of",
      "good",
      "and",
      "evil",
      "not",
      "strength",
      "to",
      "choose",
      "between",
      "two",
    ],
    id: "Wisdom we know is the knowledge of good and evil, not the strength to choose between the two.",
    author: "John Cheever",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["feel", "matters", "how", "could", "anything", "more", "fun"],
    words: [
      "to",
      "love",
      "what",
      "you",
      "do",
      "and",
      "feel",
      "that",
      "it",
      "matters",
      "how",
      "could",
      "anything",
      "be",
      "more",
      "fun",
    ],
    id: "To love what you do and feel that it matters how could anything be more fun?",
    author: "Katharine Graham",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "life", "own", "doing", "one", "blame", "yourself"],
    words: [
      "your",
      "life",
      "is",
      "the",
      "fruit",
      "of",
      "own",
      "doing",
      "you",
      "have",
      "no",
      "one",
      "to",
      "blame",
      "but",
      "yourself",
    ],
    id: "Your life is the fruit of your own doing. You have no one to blame but yourself.",
    author: "Joseph Campbell",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["within", "all", "divine", "capacity", "manifest", "attract", "desire"],
    words: [
      "within",
      "all",
      "of",
      "us",
      "is",
      "a",
      "divine",
      "capacity",
      "to",
      "manifest",
      "and",
      "attract",
      "that",
      "we",
      "need",
      "desire",
    ],
    id: "Within all of us is a divine capacity to manifest and attract all that we need and desire.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "learnt", "desperate", "bad", "times", "bullish", "when"],
    words: [
      "i",
      "learnt",
      "not",
      "to",
      "be",
      "desperate",
      "in",
      "bad",
      "times",
      "and",
      "am",
      "learning",
      "bullish",
      "when",
      "are",
      "good",
    ],
    id: "I learnt not to be desperate in bad times and am learning not to be bullish when times are good.",
    author: "John Elkann",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["everything", "should", "result", "our", "gratitude", "god", "done"],
    words: [
      "everything",
      "we",
      "do",
      "should",
      "be",
      "a",
      "result",
      "of",
      "our",
      "gratitude",
      "for",
      "what",
      "god",
      "has",
      "done",
      "us",
    ],
    id: "Everything we do should be a result of our gratitude for what God has done for us.",
    author: "Lauryn Hill",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "ice-cream", "cone", "lick", "one", "at", "time"],
    words: [
      "life",
      "is",
      "like",
      "an",
      "ice-cream",
      "cone",
      "you",
      "have",
      "to",
      "lick",
      "it",
      "one",
      "day",
      "at",
      "a",
      "time",
    ],
    id: "Life is like an ice-cream cone, you have to lick it one day at a time.",
    author: "Charles M. Schulz",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["being", "flooded", "information", "mean", "right", "or", "touch"],
    words: [
      "being",
      "flooded",
      "with",
      "information",
      "doesn't",
      "mean",
      "we",
      "have",
      "the",
      "right",
      "or",
      "that",
      "we're",
      "in",
      "touch",
      "people",
    ],
    id: "Being flooded with information doesn't mean we have the right information or that we're in touch with the right people.",
    author: "Bill Gates",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "much", "man", "wiser", "than", "was", "yesterday"],
    words: [
      "i",
      "do",
      "not",
      "think",
      "much",
      "of",
      "a",
      "man",
      "who",
      "is",
      "wiser",
      "today",
      "than",
      "he",
      "was",
      "yesterday",
    ],
    id: "I do not think much of a man who is not wiser today than he was yesterday.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["historians", "deaf", "on", "answering", "questions", "one", "asked"],
    words: [
      "historians",
      "are",
      "like",
      "deaf",
      "people",
      "who",
      "go",
      "on",
      "answering",
      "questions",
      "that",
      "no",
      "one",
      "has",
      "asked",
      "them",
    ],
    id: "Historians are like deaf people who go on answering questions that no one has asked them.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["jews", "secret", "weapon", "our", "struggle", "arabs", "place"],
    words: [
      "we",
      "jews",
      "have",
      "a",
      "secret",
      "weapon",
      "in",
      "our",
      "struggle",
      "with",
      "the",
      "arabs",
      "no",
      "place",
      "to",
      "go",
    ],
    id: "We Jews have a secret weapon in our struggle with the Arabs; we have no place to go.",
    author: "Golda Meir",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["everyone", "been", "made", "particular", "desire", "put", "heart"],
    words: [
      "everyone",
      "has",
      "been",
      "made",
      "for",
      "some",
      "particular",
      "work",
      "and",
      "the",
      "desire",
      "that",
      "put",
      "in",
      "every",
      "heart",
    ],
    id: "Everyone has been made for some particular work, and the desire for that work has been put in every heart.",
    author: "Rumi",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["winning", "oscar", "honor", "between", "makes", "things", "easier"],
    words: [
      "winning",
      "an",
      "oscar",
      "is",
      "honor",
      "but",
      "between",
      "you",
      "and",
      "me",
      "it",
      "does",
      "not",
      "makes",
      "things",
      "easier",
    ],
    id: "Winning an Oscar is an honor, but, between you and me, it does not makes things easier.",
    author: "Robin Williams",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["wicked", "leader", "despise", "revere", "great", "did", "ourselves"],
    words: [
      "the",
      "wicked",
      "leader",
      "is",
      "he",
      "who",
      "people",
      "despise",
      "good",
      "revere",
      "great",
      "say",
      "we",
      "did",
      "it",
      "ourselves",
    ],
    id: "The wicked leader is he who the people despise. The good leader is he who the people revere. The great leader is he who the people say, 'We did it ourselves.'",
    author: "Lao Tzu",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "health",
      "something",
      "however",
      "extremely",
      "valuable",
      "savings",
      "account",
    ],
    words: [
      "good",
      "health",
      "is",
      "not",
      "something",
      "we",
      "can",
      "buy",
      "however",
      "it",
      "be",
      "an",
      "extremely",
      "valuable",
      "savings",
      "account",
    ],
    id: "Good health is not something we can buy. However, it can be an extremely valuable savings account.",
    author: "Anne Wilson Schaef",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "win", "lot", "much", "my", "ups", "downs"],
    words: [
      "when",
      "you",
      "win",
      "a",
      "lot",
      "don't",
      "learn",
      "much",
      "with",
      "my",
      "ups",
      "and",
      "downs",
      "it's",
      "of",
      "learning",
    ],
    id: "When you win a lot, you don't learn much. With my ups and downs, it's a lot of learning.",
    author: "Tina Maze",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["support", "i", "blessed", "family", "around", "wishing", "things"],
    words: [
      "it",
      "is",
      "good",
      "to",
      "have",
      "the",
      "support",
      "i",
      "am",
      "blessed",
      "that",
      "family",
      "around",
      "me",
      "wishing",
      "things",
    ],
    id: "It is good to have the support I have. I am blessed that I have family around me wishing me good things.",
    author: "Jadon Sancho",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "being", "devote", "most", "my", "time", "healthy"],
    words: [
      "i",
      "do",
      "not",
      "think",
      "about",
      "being",
      "beautiful",
      "what",
      "devote",
      "most",
      "of",
      "my",
      "time",
      "to",
      "is",
      "healthy",
    ],
    id: "I do not think about being beautiful. What I devote most of my time to is being healthy.",
    author: "Ann Bancroft",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["sure", "big", "job", "i", "anyone", "better", "than"],
    words: [
      "sure",
      "it's",
      "a",
      "big",
      "job",
      "but",
      "i",
      "don't",
      "know",
      "anyone",
      "who",
      "can",
      "do",
      "it",
      "better",
      "than",
    ],
    id: "Sure it's a big job; but I don't know anyone who can do it better than I can.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "conventional", "magazine", "wisdom", "redesign", "decade", "or"],
    words: [
      "i",
      "think",
      "in",
      "conventional",
      "magazine",
      "wisdom",
      "you",
      "need",
      "to",
      "have",
      "a",
      "redesign",
      "every",
      "decade",
      "or",
      "so",
    ],
    id: "I think in conventional magazine wisdom, you need to have a redesign every decade or so.",
    author: "Timothy White",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["word", "dead", "when", "said", "i", "begins", "live"],
    words: [
      "a",
      "word",
      "is",
      "dead",
      "when",
      "it",
      "said",
      "some",
      "say",
      "i",
      "just",
      "begins",
      "to",
      "live",
      "that",
      "day",
    ],
    id: "A word is dead when it is said, some say. I say it just begins to live that day.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["movies", "abyss", "jaws", "make", "ocean", "threatening", "tranquil"],
    words: [
      "movies",
      "like",
      "the",
      "abyss",
      "and",
      "jaws",
      "make",
      "people",
      "think",
      "ocean",
      "is",
      "threatening",
      "it's",
      "not",
      "very",
      "tranquil",
    ],
    id: "Movies like 'The Abyss' and 'Jaws' make people think the ocean is threatening. It's not. It's very tranquil.",
    author: "Graham Hawkes",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["little", "dangerous", "thing", "one", "mistake", "great", "deal"],
    words: [
      "a",
      "little",
      "learning",
      "is",
      "not",
      "dangerous",
      "thing",
      "to",
      "one",
      "who",
      "does",
      "mistake",
      "it",
      "for",
      "great",
      "deal",
    ],
    id: "A little learning is not a dangerous thing to one who does not mistake it for a great deal.",
    author: "William Allen White",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "taken", "by", "lapel", "told", "im", "kid"],
    words: [
      "life",
      "loves",
      "to",
      "be",
      "taken",
      "by",
      "the",
      "lapel",
      "and",
      "told",
      "im",
      "with",
      "you",
      "kid",
      "let's",
      "go",
    ],
    id: "Life loves to be taken by the lapel and told: 'I'm with you kid. Let's go.'",
    author: "Maya Angelou",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "great", "believer", "luck", "find", "harder", "more"],
    words: [
      "i",
      "am",
      "a",
      "great",
      "believer",
      "in",
      "luck",
      "and",
      "find",
      "the",
      "harder",
      "work",
      "more",
      "have",
      "of",
      "it",
    ],
    id: "I am a great believer in luck, and I find the harder I work the more I have of it.",
    author: "Stephen Leacock",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["cannot", "only", "distaste", "better", "should", "leave", "your"],
    words: [
      "if",
      "you",
      "cannot",
      "work",
      "with",
      "love",
      "but",
      "only",
      "distaste",
      "it",
      "is",
      "better",
      "that",
      "should",
      "leave",
      "your",
    ],
    id: "If you cannot work with love but only with distaste, it is better that you should leave your work.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "were", "tough", "days", "keep", "dreaming", "believing"],
    words: [
      "there",
      "were",
      "some",
      "tough",
      "days",
      "good",
      "but",
      "you",
      "have",
      "to",
      "just",
      "keep",
      "going",
      "dreaming",
      "and",
      "believing",
    ],
    id: "There were some tough days, there were some good days, but you have to just keep going, keep dreaming, and keep believing.",
    author: "Bukayo Saka",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "my", "life", "i", "women", "driving", "was"],
    words: [
      "for",
      "most",
      "of",
      "my",
      "life",
      "i",
      "let",
      "women",
      "do",
      "the",
      "driving",
      "and",
      "was",
      "happy",
      "to",
      "them",
    ],
    id: "For most of my life I let women do the driving and was happy to let them.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["executives", "never", "put", "off", "until", "get", "else"],
    words: [
      "good",
      "executives",
      "never",
      "put",
      "off",
      "until",
      "tomorrow",
      "what",
      "they",
      "can",
      "get",
      "someone",
      "else",
      "to",
      "do",
      "today",
    ],
    id: "Good executives never put off until tomorrow what they can get someone else to do today.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "catholic", "candidate", "democratic", "party's", "happens", "also"],
    words: [
      "i",
      "am",
      "not",
      "the",
      "catholic",
      "candidate",
      "for",
      "president",
      "democratic",
      "party's",
      "who",
      "happens",
      "also",
      "to",
      "be",
      "a",
    ],
    id: "I am not the Catholic candidate for President. I am the Democratic Party's candidate for President, who happens also to be a Catholic.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["freedom", "press", "such", "way", "there", "much", "from"],
    words: [
      "the",
      "freedom",
      "of",
      "press",
      "works",
      "in",
      "such",
      "a",
      "way",
      "that",
      "there",
      "is",
      "not",
      "much",
      "from",
      "it",
    ],
    id: "The freedom of the press works in such a way that there is not much freedom from it.",
    author: "Grace Kelly",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["billionaire", "song", "my", "kids", "tease", "sing", "funny"],
    words: [
      "the",
      "billionaire",
      "song",
      "is",
      "what",
      "my",
      "kids",
      "tease",
      "me",
      "with",
      "they",
      "sing",
      "it",
      "to",
      "it's",
      "funny",
    ],
    id: "The 'Billionaire' song is what my kids tease me with. They sing it to me. It's funny.",
    author: "Bill Gates",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["thing", "fit", "most", "interesting", "part", "according", "expected"],
    words: [
      "the",
      "thing",
      "that",
      "doesn't",
      "fit",
      "is",
      "that's",
      "most",
      "interesting",
      "part",
      "go",
      "according",
      "to",
      "what",
      "you",
      "expected",
    ],
    id: "The thing that doesn't fit is the thing that's the most interesting: the part that doesn't go according to what you expected.",
    author: "Richard P. Feynman",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["should", "position", "their", "children", "access", "weapons", "ammunition"],
    words: [
      "people",
      "should",
      "not",
      "be",
      "in",
      "a",
      "position",
      "where",
      "their",
      "children",
      "have",
      "access",
      "to",
      "weapons",
      "and",
      "ammunition",
    ],
    id: "People should not be in a position where their children have access to weapons and ammunition.",
    author: "Joe Biden",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["longevity", "friendship", "important", "one", "keys", "foundation", "life"],
    words: [
      "longevity",
      "in",
      "friendship",
      "is",
      "very",
      "important",
      "and",
      "one",
      "of",
      "the",
      "keys",
      "to",
      "foundation",
      "a",
      "happy",
      "life",
    ],
    id: "Longevity in friendship is very important and one of the keys to the foundation of a happy life.",
    author: "Karrueche Tran",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["enough", "must", "wage", "war", "necessary", "peace", "sacrifice"],
    words: [
      "it",
      "is",
      "not",
      "enough",
      "to",
      "say",
      "we",
      "must",
      "wage",
      "war",
      "necessary",
      "love",
      "peace",
      "and",
      "sacrifice",
      "for",
    ],
    id: "It is not enough to say we must not wage war. It is necessary to love peace and sacrifice for it.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "impression", "united", "states", "excited", "fight", "here"],
    words: [
      "i",
      "have",
      "a",
      "very",
      "good",
      "impression",
      "of",
      "the",
      "united",
      "states",
      "and",
      "am",
      "excited",
      "to",
      "fight",
      "here",
    ],
    id: "I have a very good impression of the United States, and I am very excited to fight here.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "keep", "things", "change", "should", "recycle", "ourselves"],
    words: [
      "it",
      "is",
      "always",
      "good",
      "to",
      "keep",
      "learning",
      "every",
      "day",
      "things",
      "change",
      "and",
      "we",
      "should",
      "recycle",
      "ourselves",
    ],
    id: "It is always good to keep learning. Every day things change and we should recycle ourselves every day.",
    author: "Zico",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["aim", "college", "education", "teach", "man", "when", "one"],
    words: [
      "the",
      "aim",
      "of",
      "a",
      "college",
      "education",
      "is",
      "to",
      "teach",
      "you",
      "know",
      "good",
      "man",
      "when",
      "see",
      "one",
    ],
    id: "The aim of a college education is to teach you to know a good man when you see one.",
    author: "William James",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["something", "tonight", "you'll", "sorry", "morning", "sleep", "late"],
    words: [
      "if",
      "you're",
      "going",
      "to",
      "do",
      "something",
      "tonight",
      "that",
      "you'll",
      "be",
      "sorry",
      "for",
      "tomorrow",
      "morning",
      "sleep",
      "late",
    ],
    id: "If you're going to do something tonight that you'll be sorry for tomorrow morning, sleep late.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["bring", "happiness", "will", "at", "least", "miserable", "comfort"],
    words: [
      "money",
      "if",
      "it",
      "does",
      "not",
      "bring",
      "you",
      "happiness",
      "will",
      "at",
      "least",
      "help",
      "be",
      "miserable",
      "in",
      "comfort",
    ],
    id: "Money, if it does not bring you happiness, will at least help you be miserable in comfort.",
    author: "Helen Gurley Brown",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "normal", "person", "quality", "time", "my", "family"],
    words: [
      "i",
      "am",
      "just",
      "a",
      "normal",
      "person",
      "and",
      "it",
      "is",
      "to",
      "have",
      "quality",
      "time",
      "with",
      "my",
      "family",
    ],
    id: "I am just a normal person, and it is normal to have quality time with my family.",
    author: "Carlos Tevez",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["spirit", "makes", "its", "shrewd", "guess", "from", "inhabitant"],
    words: [
      "every",
      "spirit",
      "makes",
      "its",
      "house",
      "and",
      "we",
      "can",
      "give",
      "a",
      "shrewd",
      "guess",
      "from",
      "the",
      "to",
      "inhabitant",
    ],
    id: "Every spirit makes its house, and we can give a shrewd guess from the house to the inhabitant.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "continue",
      "certainly",
      "contributing",
      "element",
      "one's",
      "longevity",
      "health",
    ],
    words: [
      "to",
      "continue",
      "work",
      "love",
      "what",
      "you",
      "do",
      "is",
      "certainly",
      "a",
      "contributing",
      "element",
      "one's",
      "longevity",
      "and",
      "health",
    ],
    id: "To continue to work, to continue to love what you do, is certainly a contributing element to one's longevity and health.",
    author: "John Williams",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["highest", "use", "capital", "make", "more", "betterment", "life"],
    words: [
      "the",
      "highest",
      "use",
      "of",
      "capital",
      "is",
      "not",
      "to",
      "make",
      "more",
      "money",
      "but",
      "do",
      "for",
      "betterment",
      "life",
    ],
    id: "The highest use of capital is not to make more money, but to make money do more for the betterment of life.",
    author: "Henry Ford",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["comedy", "surprises", "intending", "make", "somebody", "laugh", "funny"],
    words: [
      "comedy",
      "is",
      "surprises",
      "so",
      "if",
      "you're",
      "intending",
      "to",
      "make",
      "somebody",
      "laugh",
      "and",
      "they",
      "don't",
      "that's",
      "funny",
    ],
    id: "Comedy is surprises, so if you're intending to make somebody laugh and they don't laugh, that's funny.",
    author: "Norm MacDonald",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "cooking", "whom", "sharing", "food", "form", "expression"],
    words: [
      "i'm",
      "just",
      "someone",
      "who",
      "likes",
      "cooking",
      "and",
      "for",
      "whom",
      "sharing",
      "food",
      "is",
      "a",
      "form",
      "of",
      "expression",
    ],
    id: "I'm just someone who likes cooking and for whom sharing food is a form of expression.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "rich", "proportion", "number", "things", "afford", "alone"],
    words: [
      "a",
      "man",
      "is",
      "rich",
      "in",
      "proportion",
      "to",
      "the",
      "number",
      "of",
      "things",
      "he",
      "can",
      "afford",
      "let",
      "alone",
    ],
    id: "A man is rich in proportion to the number of things he can afford to let alone.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["each", "our", "family", "both", "towards", "same", "goal"],
    words: [
      "we",
      "care",
      "for",
      "each",
      "other",
      "and",
      "about",
      "our",
      "family",
      "are",
      "both",
      "working",
      "towards",
      "the",
      "same",
      "goal",
    ],
    id: "We care for each other and care about our family, and we are both working towards the same goal.",
    author: "Angelina Jolie",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["woman", "only", "thing", "i", "afraid", "will", "hurt"],
    words: [
      "a",
      "woman",
      "is",
      "the",
      "only",
      "thing",
      "i",
      "am",
      "afraid",
      "of",
      "that",
      "know",
      "will",
      "not",
      "hurt",
      "me",
    ],
    id: "A woman is the only thing I am afraid of that I know will not hurt me.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["true", "greatness", "one", "frailty", "man", "security", "god"],
    words: [
      "it",
      "is",
      "true",
      "greatness",
      "to",
      "have",
      "in",
      "one",
      "the",
      "frailty",
      "of",
      "a",
      "man",
      "and",
      "security",
      "god",
    ],
    id: "It is true greatness to have in one the frailty of a man and the security of a god.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["come", "by", "finding", "perfect", "person", "imperfect", "perfectly"],
    words: [
      "we",
      "come",
      "to",
      "love",
      "not",
      "by",
      "finding",
      "a",
      "perfect",
      "person",
      "but",
      "learning",
      "see",
      "an",
      "imperfect",
      "perfectly",
    ],
    id: "We come to love not by finding a perfect person but by learning to see an imperfect person perfectly.",
    author: "Sam Keen",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["did", "choose", "guardians", "gate", "there", "one", "else"],
    words: [
      "we",
      "did",
      "not",
      "choose",
      "to",
      "be",
      "the",
      "guardians",
      "of",
      "gate",
      "but",
      "there",
      "is",
      "no",
      "one",
      "else",
    ],
    id: "We did not choose to be the guardians of the gate, but there is no one else.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["advice", "your", "next", "letter", "i", "promise", "follow"],
    words: [
      "please",
      "give",
      "me",
      "some",
      "good",
      "advice",
      "in",
      "your",
      "next",
      "letter",
      "i",
      "promise",
      "not",
      "to",
      "follow",
      "it",
    ],
    id: "Please give me some good advice in your next letter. I promise not to follow it.",
    author: "Edna St. Vincent Millay",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "knew", "my", "job", "was", "out", "meet"],
    words: [
      "i",
      "knew",
      "what",
      "my",
      "job",
      "was",
      "it",
      "to",
      "go",
      "out",
      "and",
      "meet",
      "the",
      "people",
      "love",
      "them",
    ],
    id: "I knew what my job was; it was to go out and meet the people and love them.",
    author: "Princess Diana",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["may", "or", "ought", "reveal", "itself", "its", "immensity"],
    words: [
      "love",
      "may",
      "be",
      "or",
      "it",
      "not",
      "but",
      "where",
      "is",
      "ought",
      "to",
      "reveal",
      "itself",
      "in",
      "its",
      "immensity",
    ],
    id: "Love may be or it may not, but where it is, it ought to reveal itself in its immensity.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "everything", "humans", "however", "things", "unjust", "others"],
    words: [
      "to",
      "god",
      "everything",
      "is",
      "beautiful",
      "good",
      "and",
      "just",
      "humans",
      "however",
      "think",
      "some",
      "things",
      "are",
      "unjust",
      "others",
    ],
    id: "To God everything is beautiful, good, and just; humans, however, think some things are unjust and others just.",
    author: "Heraclitus",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "play", "role", "feel", "being", "myself", "saying"],
    words: [
      "i",
      "do",
      "not",
      "try",
      "to",
      "play",
      "a",
      "role",
      "feel",
      "good",
      "being",
      "myself",
      "and",
      "saying",
      "what",
      "think",
    ],
    id: "I do not try to play a role. I feel good being myself and saying what I think.",
    author: "Volodymyr Zelensky",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["practice", "patience", "crafting", "process", "virtue", "come", "back"],
    words: [
      "the",
      "practice",
      "of",
      "patience",
      "in",
      "crafting",
      "and",
      "process",
      "is",
      "a",
      "virtue",
      "that",
      "needs",
      "to",
      "come",
      "back",
    ],
    id: "The practice of patience in crafting and process is a virtue that needs to come back.",
    author: "Zac Posen",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["change", "your", "beliefs", "life", "will", "forever", "news"],
    words: [
      "if",
      "you",
      "don't",
      "change",
      "your",
      "beliefs",
      "life",
      "will",
      "be",
      "like",
      "this",
      "forever",
      "is",
      "that",
      "good",
      "news",
    ],
    id: "If you don't change your beliefs, your life will be like this forever. Is that good news?",
    author: "W. Somerset Maugham",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["must", "frank", "world", "frankness", "child", "honesty", "courage"],
    words: [
      "you",
      "must",
      "study",
      "to",
      "be",
      "frank",
      "with",
      "the",
      "world",
      "frankness",
      "is",
      "child",
      "of",
      "honesty",
      "and",
      "courage",
    ],
    id: "You must study to be frank with the world: Frankness is the child of honesty and courage.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["patience", "simply", "ability", "-", "how", "behave", "while"],
    words: [
      "patience",
      "is",
      "not",
      "simply",
      "the",
      "ability",
      "to",
      "wait",
      "-",
      "it's",
      "how",
      "we",
      "behave",
      "while",
      "we're",
      "waiting",
    ],
    id: "Patience is not simply the ability to wait - it's how we behave while we're waiting.",
    author: "Joyce Meyer",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "all", "matter", "more", "memory", "stronger", "stranger"],
    words: [
      "i",
      "think",
      "it",
      "is",
      "all",
      "a",
      "matter",
      "of",
      "love",
      "the",
      "more",
      "you",
      "memory",
      "stronger",
      "and",
      "stranger",
    ],
    id: "I think it is all a matter of love: the more you love a memory, the stronger and stranger it is.",
    author: "Vladimir Nabokov",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "riches", "success", "world", "your", "health", "nothing"],
    words: [
      "you",
      "can",
      "have",
      "all",
      "the",
      "riches",
      "and",
      "success",
      "in",
      "world",
      "but",
      "if",
      "don't",
      "your",
      "health",
      "nothing",
    ],
    id: "You can have all the riches and success in the world, but if you don't have your health, you have nothing.",
    author: "Steven Adler",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["occupation", "delightful", "as", "culture", "earth", "comparable", "garden"],
    words: [
      "no",
      "occupation",
      "is",
      "so",
      "delightful",
      "to",
      "me",
      "as",
      "the",
      "culture",
      "of",
      "earth",
      "and",
      "comparable",
      "that",
      "garden",
    ],
    id: "No occupation is so delightful to me as the culture of the earth, and no culture comparable to that of the garden.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["politics", "become", "expensive", "takes", "lot", "even", "defeated"],
    words: [
      "politics",
      "has",
      "become",
      "so",
      "expensive",
      "that",
      "it",
      "takes",
      "a",
      "lot",
      "of",
      "money",
      "even",
      "to",
      "be",
      "defeated",
    ],
    id: "Politics has become so expensive that it takes a lot of money even to be defeated.",
    author: "Will Rogers",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["anxious", "will", "itself", "day's", "own", "trouble", "sufficient"],
    words: [
      "do",
      "not",
      "be",
      "anxious",
      "about",
      "tomorrow",
      "for",
      "will",
      "itself",
      "let",
      "the",
      "day's",
      "own",
      "trouble",
      "sufficient",
      "day",
    ],
    id: "Do not be anxious about tomorrow, for tomorrow will be anxious for itself. Let the day's own trouble be sufficient for the day.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "when", "talk", "war", "really", "talking", "peace"],
    words: [
      "i",
      "just",
      "want",
      "you",
      "to",
      "know",
      "that",
      "when",
      "we",
      "talk",
      "about",
      "war",
      "we're",
      "really",
      "talking",
      "peace",
    ],
    id: "I just want you to know that, when we talk about war, we're really talking about peace.",
    author: "George W. Bush",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "yardstick",
      "quality",
      "aren't",
      "used",
      "environment",
      "excellence",
      "expected",
    ],
    words: [
      "be",
      "a",
      "yardstick",
      "of",
      "quality",
      "some",
      "people",
      "aren't",
      "used",
      "to",
      "an",
      "environment",
      "where",
      "excellence",
      "is",
      "expected",
    ],
    id: "Be a yardstick of quality. Some people aren't used to an environment where excellence is expected.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["anyone", "heroic", "from", "time", "gentleman", "something", "all"],
    words: [
      "anyone",
      "can",
      "be",
      "heroic",
      "from",
      "time",
      "to",
      "but",
      "a",
      "gentleman",
      "is",
      "something",
      "you",
      "have",
      "all",
      "the",
    ],
    id: "Anyone can be heroic from time to time, but a gentleman is something you have to be all the time.",
    author: "Luigi Pirandello",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "my", "kids", "encyclopedia", "walk", "i", "did"],
    words: [
      "i'm",
      "not",
      "going",
      "to",
      "buy",
      "my",
      "kids",
      "an",
      "encyclopedia",
      "let",
      "them",
      "walk",
      "school",
      "like",
      "i",
      "did",
    ],
    id: "I'm not going to buy my kids an encyclopedia. Let them walk to school like I did.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["abandon", "yourselves", "despair", "easter", "hallelujah", "our", "song"],
    words: [
      "do",
      "not",
      "abandon",
      "yourselves",
      "to",
      "despair",
      "we",
      "are",
      "the",
      "easter",
      "people",
      "and",
      "hallelujah",
      "is",
      "our",
      "song",
    ],
    id: "Do not abandon yourselves to despair. We are the Easter people and hallelujah is our song.",
    author: "Pope John Paul II",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "there", "anything", "wrong", "from", "better", "than"],
    words: [
      "i",
      "don't",
      "think",
      "there",
      "is",
      "anything",
      "wrong",
      "with",
      "learning",
      "from",
      "people",
      "who",
      "are",
      "better",
      "than",
      "you",
    ],
    id: "I don't think there is anything wrong with learning from people who are better than you.",
    author: "Asa Larsson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["may", "sooner", "brought", "hate", "than", "more", "would"],
    words: [
      "we",
      "may",
      "sooner",
      "be",
      "brought",
      "to",
      "love",
      "them",
      "that",
      "hate",
      "us",
      "than",
      "more",
      "would",
      "have",
      "do",
    ],
    id: "We may sooner be brought to love them that hate us, than them that love us more than we would have them do.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["must", "little", "read", "daddy", "never", "loved", "mummy"],
    words: [
      "what",
      "must",
      "it",
      "be",
      "like",
      "for",
      "a",
      "little",
      "boy",
      "to",
      "read",
      "that",
      "daddy",
      "never",
      "loved",
      "mummy",
    ],
    id: "What must it be like for a little boy to read that daddy never loved mummy?",
    author: "Princess Diana",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "ability",
      "financial",
      "institution",
      "threatened",
      "criminal",
      "prosecution",
      "negotiate",
    ],
    words: [
      "you",
      "have",
      "no",
      "ability",
      "if",
      "you're",
      "a",
      "financial",
      "institution",
      "and",
      "threatened",
      "with",
      "criminal",
      "prosecution",
      "to",
      "negotiate",
    ],
    id: "You have no ability, if you're a financial institution and you're threatened with criminal prosecution, you have no ability to negotiate.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["tricks", "treachery", "practice", "fools", "brains", "enough", "honest"],
    words: [
      "tricks",
      "and",
      "treachery",
      "are",
      "the",
      "practice",
      "of",
      "fools",
      "that",
      "don't",
      "have",
      "brains",
      "enough",
      "to",
      "be",
      "honest",
    ],
    id: "Tricks and treachery are the practice of fools, that don't have brains enough to be honest.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["books", "would", "thing", "also", "could", "time", "read"],
    words: [
      "to",
      "buy",
      "books",
      "would",
      "be",
      "a",
      "good",
      "thing",
      "if",
      "we",
      "also",
      "could",
      "the",
      "time",
      "read",
      "them",
    ],
    id: "To buy books would be a good thing if we also could buy the time to read them.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["law", "ordinance", "reason", "common", "made", "by", "community"],
    words: [
      "law",
      "an",
      "ordinance",
      "of",
      "reason",
      "for",
      "the",
      "common",
      "good",
      "made",
      "by",
      "him",
      "who",
      "has",
      "care",
      "community",
    ],
    id: "Law; an ordinance of reason for the common good, made by him who has care of the community.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["could", "back", "change", "things", "might", "person", "right"],
    words: [
      "if",
      "you",
      "could",
      "go",
      "back",
      "and",
      "change",
      "things",
      "might",
      "not",
      "be",
      "the",
      "person",
      "are",
      "right",
      "now",
    ],
    id: "If you could go back and change things, you might not be the person you are right now.",
    author: "Walter Payton",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["repentance", "much", "remorse", "done", "as", "fear", "consequences"],
    words: [
      "repentance",
      "is",
      "not",
      "so",
      "much",
      "remorse",
      "for",
      "what",
      "we",
      "have",
      "done",
      "as",
      "the",
      "fear",
      "of",
      "consequences",
    ],
    id: "Repentance is not so much remorse for what we have done as the fear of the consequences.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["destiny", "matter", "chance", "choice", "thing", "waited", "achieved"],
    words: [
      "destiny",
      "is",
      "no",
      "matter",
      "of",
      "chance",
      "it",
      "a",
      "choice",
      "not",
      "thing",
      "to",
      "be",
      "waited",
      "for",
      "achieved",
    ],
    id: "Destiny is no matter of chance. It is a matter of choice. It is not a thing to be waited for, it is a thing to be achieved.",
    author: "William Jennings Bryan",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["briedis", "fighter", "only", "thing", "i", "his", "belt"],
    words: [
      "briedis",
      "is",
      "a",
      "good",
      "fighter",
      "but",
      "the",
      "only",
      "thing",
      "he",
      "has",
      "that",
      "i",
      "want",
      "his",
      "belt",
    ],
    id: "Briedis is a good fighter, but the only thing he has that I want is his belt.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "thing", "future", "comes", "only", "at", "time"],
    words: [
      "the",
      "one",
      "thing",
      "we",
      "know",
      "about",
      "future",
      "is",
      "that",
      "it",
      "comes",
      "only",
      "day",
      "at",
      "a",
      "time",
    ],
    id: "The one thing we know about the future is that it comes only one day at a time.",
    author: "Jeff Rich",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["cannot", "communion", "christ", "till", "union", "church", "vital"],
    words: [
      "we",
      "cannot",
      "have",
      "communion",
      "with",
      "christ",
      "till",
      "are",
      "in",
      "union",
      "him",
      "and",
      "the",
      "church",
      "vital",
      "it",
    ],
    id: "We cannot have communion with Christ till we are in union with Him; and we cannot have communion with the Church till we are in vital union with it.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["anyone", "count", "seeds", "only", "god", "number", "seed"],
    words: [
      "anyone",
      "can",
      "count",
      "the",
      "seeds",
      "in",
      "an",
      "apple",
      "but",
      "only",
      "god",
      "number",
      "of",
      "apples",
      "a",
      "seed",
    ],
    id: "Anyone can count the seeds in an apple, but only God can count the number of apples in a seed.",
    author: "Robert H. Schuller",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "useless", "world", "lightens", "burden", "anyone", "else"],
    words: [
      "no",
      "one",
      "is",
      "useless",
      "in",
      "this",
      "world",
      "who",
      "lightens",
      "the",
      "burden",
      "of",
      "it",
      "to",
      "anyone",
      "else",
    ],
    id: "No one is useless in this world who lightens the burden of it to anyone else.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["determines", "happens", "change", "our", "lives", "stretch", "minds"],
    words: [
      "what",
      "we",
      "think",
      "determines",
      "happens",
      "to",
      "us",
      "so",
      "if",
      "want",
      "change",
      "our",
      "lives",
      "need",
      "stretch",
      "minds",
    ],
    id: "What we think determines what happens to us, so if we want to change our lives, we need to stretch our minds.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "future", "on", "line", "must", "at", "least"],
    words: [
      "it",
      "is",
      "our",
      "future",
      "on",
      "the",
      "line",
      "and",
      "we",
      "must",
      "at",
      "least",
      "have",
      "a",
      "say",
      "in",
    ],
    id: "It is our future on the line, and we must at least have a say in it.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["war", "terrible", "otherwise", "should", "grow", "too", "fond"],
    words: [
      "it",
      "is",
      "well",
      "that",
      "war",
      "so",
      "terrible",
      "otherwise",
      "we",
      "should",
      "grow",
      "too",
      "fond",
      "of",
    ],
    id: "It is well that war is so terrible, otherwise we should grow too fond of it.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["command", "i", "one", "another", "as", "loved", "must"],
    words: [
      "a",
      "new",
      "command",
      "i",
      "give",
      "you",
      "love",
      "one",
      "another",
      "as",
      "have",
      "loved",
      "so",
      "must",
    ],
    id: "A new command I give you: Love one another. As I have loved you, so you must love one another.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "anything",
      "pounding",
      "humility",
      "into",
      "permanently",
      "restaurant",
      "business",
    ],
    words: [
      "if",
      "anything",
      "is",
      "good",
      "for",
      "pounding",
      "humility",
      "into",
      "you",
      "permanently",
      "it's",
      "the",
      "restaurant",
      "business",
    ],
    id: "If anything is good for pounding humility into you permanently, it's the restaurant business.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "get", "lost", "everything", "keep", "same", "ethic"],
    words: [
      "i",
      "don't",
      "want",
      "to",
      "get",
      "lost",
      "in",
      "everything",
      "just",
      "keep",
      "the",
      "same",
      "work",
      "ethic",
    ],
    id: "I don't want to get lost in everything. I just want to keep the same work ethic.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "something", "sight", "even", "daisy", "jelly", "glass"],
    words: [
      "always",
      "have",
      "something",
      "beautiful",
      "in",
      "sight",
      "even",
      "if",
      "it's",
      "just",
      "a",
      "daisy",
      "jelly",
      "glass",
    ],
    id: "Always have something beautiful in sight, even if it's just a daisy in a jelly glass.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "up", "place", "time", "tide", "will", "turn"],
    words: [
      "never",
      "give",
      "up",
      "for",
      "that",
      "is",
      "just",
      "the",
      "place",
      "and",
      "time",
      "tide",
      "will",
      "turn",
    ],
    id: "Never give up, for that is just the place and time that the tide will turn.",
    author: "Harriet Beecher Stowe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["get", "opportunity", "david", "simon", "anybody", "taste", "would"],
    words: [
      "if",
      "you",
      "get",
      "an",
      "opportunity",
      "to",
      "work",
      "with",
      "david",
      "simon",
      "anybody",
      "good",
      "taste",
      "would",
    ],
    id: "If you get an opportunity to work with David Simon, anybody with good taste would.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["seems", "richer", "more", "chance", "paying", "less", "tax"],
    words: [
      "it",
      "seems",
      "like",
      "the",
      "richer",
      "you",
      "are",
      "more",
      "chance",
      "have",
      "of",
      "paying",
      "less",
      "tax",
    ],
    id: "It seems like the richer you are, the more chance you have of paying less tax.",
    author: "John McEnroe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["bosom", "knock", "there", "ask", "your", "heart", "doth"],
    words: [
      "go",
      "to",
      "you",
      "bosom",
      "knock",
      "there",
      "and",
      "ask",
      "your",
      "heart",
      "what",
      "it",
      "doth",
      "know",
    ],
    id: "Go to you bosom: Knock there, and ask your heart what it doth know.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "words", "prayer", "-", "god", "because", "myself"],
    words: [
      "i",
      "know",
      "no",
      "words",
      "of",
      "prayer",
      "-",
      "god",
      "help",
      "me",
      "because",
      "can",
      "not",
      "myself",
    ],
    id: "I know no words of prayer - God help me because I can not help myself.",
    author: "Harold E. Hughes",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["seldom", "find", "ungrateful", "long", "as", "thought", "serve"],
    words: [
      "we",
      "seldom",
      "find",
      "people",
      "ungrateful",
      "so",
      "long",
      "as",
      "it",
      "is",
      "thought",
      "can",
      "serve",
      "them",
    ],
    id: "We seldom find people ungrateful so long as it is thought we can serve them.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "man", "doubts", "often", "most", "firmly", "believes"],
    words: [
      "when",
      "a",
      "man",
      "is",
      "in",
      "love",
      "he",
      "doubts",
      "very",
      "often",
      "what",
      "most",
      "firmly",
      "believes",
    ],
    id: "When a man is in love, he doubts, very often, what he most firmly believes.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "thing", "should", "surprise", "there", "still", "things"],
    words: [
      "the",
      "only",
      "thing",
      "that",
      "should",
      "surprise",
      "us",
      "is",
      "there",
      "are",
      "still",
      "some",
      "things",
      "can",
    ],
    id: "The only thing that should surprise us is that there are still some things that can surprise us.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["republicans", "both", "man", "dollar", "case", "conflict", "before"],
    words: [
      "republicans",
      "are",
      "for",
      "both",
      "the",
      "man",
      "and",
      "dollar",
      "but",
      "in",
      "case",
      "of",
      "conflict",
      "before",
    ],
    id: "Republicans are for both the man and the dollar, but in case of conflict the man before the dollar.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["doing", "your", "best", "won't", "any", "time", "failure"],
    words: [
      "if",
      "you're",
      "doing",
      "your",
      "best",
      "you",
      "won't",
      "have",
      "any",
      "time",
      "to",
      "worry",
      "about",
      "failure",
    ],
    id: "If you're doing your best, you won't have any time to worry about failure.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "philosophy", "life", "hard", "god", "confuse", "two"],
    words: [
      "my",
      "philosophy",
      "is",
      "life",
      "hard",
      "but",
      "god",
      "good",
      "try",
      "not",
      "to",
      "confuse",
      "the",
      "two",
    ],
    id: "My philosophy is: Life is hard, but God is good. Try not to confuse the two.",
    author: "Anne F. Beiler",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["career", "showbiz", "distance", "run", "patience", "pace", "yourself"],
    words: [
      "a",
      "career",
      "in",
      "showbiz",
      "is",
      "like",
      "distance",
      "run",
      "you",
      "have",
      "to",
      "patience",
      "and",
      "pace",
      "yourself",
    ],
    id: "A career in showbiz is like a distance run. You have to have patience and pace yourself.",
    author: "Tate Donovan",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "promised", "myself", "i'm", "everything", "as", "long"],
    words: [
      "i",
      "have",
      "promised",
      "myself",
      "that",
      "i'm",
      "going",
      "to",
      "do",
      "everything",
      "can",
      "for",
      "as",
      "long",
    ],
    id: "I have promised myself that I'm going to do everything I can for as long as I can.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "most", "qualities", "true", "friendship", "understand", "understood"],
    words: [
      "one",
      "of",
      "the",
      "most",
      "beautiful",
      "qualities",
      "true",
      "friendship",
      "is",
      "to",
      "understand",
      "and",
      "be",
      "understood",
    ],
    id: "One of the most beautiful qualities of true friendship is to understand and to be understood.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "continue", "living", "as", "there", "was", "because"],
    words: [
      "we",
      "can't",
      "just",
      "continue",
      "living",
      "as",
      "if",
      "there",
      "was",
      "no",
      "tomorrow",
      "because",
      "is",
      "a",
    ],
    id: "We can't just continue living as if there was no tomorrow, because there is a tomorrow.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["religion", "department", "life", "something", "enters", "into", "whole"],
    words: [
      "religion",
      "is",
      "not",
      "a",
      "department",
      "of",
      "life",
      "it",
      "something",
      "that",
      "enters",
      "into",
      "the",
      "whole",
    ],
    id: "Religion is not a department of life; it is something that enters into the whole of it.",
    author: "Alan Watts",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "silicon",
      "valley",
      "smartest",
      "engineers",
      "technology",
      "business",
      "world",
    ],
    words: [
      "silicon",
      "valley",
      "has",
      "some",
      "of",
      "the",
      "smartest",
      "engineers",
      "and",
      "technology",
      "business",
      "people",
      "in",
      "world",
    ],
    id: "Silicon Valley has some of the smartest engineers and technology business people in the world.",
    author: "Elon Musk",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["slam", "at", "when", "rude", "they've", "met", "before"],
    words: [
      "it's",
      "not",
      "a",
      "slam",
      "at",
      "you",
      "when",
      "people",
      "are",
      "rude",
      "the",
      "they've",
      "met",
      "before",
    ],
    id: "It's not a slam at you when people are rude, it's a slam at the people they've met before.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "success", "lived", "laughed", "often", "loved", "much"],
    words: [
      "that",
      "man",
      "is",
      "a",
      "success",
      "who",
      "has",
      "lived",
      "well",
      "laughed",
      "often",
      "and",
      "loved",
      "much",
    ],
    id: "That man is a success who has lived well, laughed often and loved much.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "miss", "out", "on", "chance", "having", "time"],
    words: [
      "i",
      "don't",
      "want",
      "to",
      "miss",
      "out",
      "on",
      "the",
      "chance",
      "of",
      "having",
      "a",
      "good",
      "time",
    ],
    id: "I don't want to miss out on the chance of having a good time.",
    author: "Victor Webster",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["bad", "things", "form", "as", "change", "makes", "grow"],
    words: [
      "the",
      "good",
      "and",
      "bad",
      "things",
      "are",
      "what",
      "form",
      "us",
      "as",
      "people",
      "change",
      "makes",
      "grow",
    ],
    id: "The good and bad things are what form us as people... change makes us grow.",
    author: "Kate Winslet",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["because", "thing", "seems", "difficult", "impossible", "anyone", "accomplish"],
    words: [
      "because",
      "a",
      "thing",
      "seems",
      "difficult",
      "for",
      "you",
      "do",
      "not",
      "think",
      "it",
      "impossible",
      "anyone",
      "to",
      "accomplish",
    ],
    id: "Because a thing seems difficult for you, do not think it impossible for anyone to accomplish.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "get", "mixture", "charming", "mannerisms", "women", "known"],
    words: [
      "men",
      "get",
      "to",
      "be",
      "a",
      "mixture",
      "of",
      "the",
      "charming",
      "mannerisms",
      "women",
      "they",
      "have",
      "known",
    ],
    id: "Men get to be a mixture of the charming mannerisms of the women they have known.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["child", "asks", "why", "i", "happiness", "thing", "telling"],
    words: [
      "the",
      "child",
      "asks",
      "why",
      "don't",
      "i",
      "have",
      "this",
      "happiness",
      "thing",
      "you're",
      "telling",
      "me",
      "about",
    ],
    id: "The child asks, 'Why don't I have this happiness thing you're telling me about?'",
    author: "Shel Silverstein",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["sin", "covet", "honor", "i", "most", "offending", "soul"],
    words: [
      "if",
      "it",
      "be",
      "a",
      "sin",
      "to",
      "covet",
      "honor",
      "i",
      "am",
      "the",
      "most",
      "offending",
      "soul",
    ],
    id: "If it be a sin to covet honor, I am the most offending soul.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["many", "identify", "their", "sense", "self", "problems", "or"],
    words: [
      "many",
      "people",
      "identify",
      "their",
      "sense",
      "of",
      "self",
      "with",
      "the",
      "problems",
      "they",
      "have",
      "or",
      "think",
    ],
    id: "Many people identify their sense of self with the problems they have, or think they have.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["overcome", "tug", "against", "as", "reach", "high", "goals"],
    words: [
      "you",
      "need",
      "to",
      "overcome",
      "the",
      "tug",
      "of",
      "people",
      "against",
      "as",
      "reach",
      "for",
      "high",
      "goals",
    ],
    id: "You need to overcome the tug of people against you as you reach for high goals.",
    author: "George S. Patton",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["pray", "sheltered", "from", "dangers", "fearless", "when", "facing"],
    words: [
      "let",
      "us",
      "not",
      "pray",
      "to",
      "be",
      "sheltered",
      "from",
      "dangers",
      "but",
      "fearless",
      "when",
      "facing",
      "them",
    ],
    id: "Let us not pray to be sheltered from dangers but to be fearless when facing them.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["paying", "part", "game", "life", "joy", "buying", "crave"],
    words: [
      "but",
      "paying",
      "is",
      "part",
      "of",
      "the",
      "game",
      "life",
      "it",
      "joy",
      "buying",
      "that",
      "we",
      "crave",
    ],
    id: "But paying is part of the game of life: it is the joy of buying that we crave.",
    author: "Gilbert Parker",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "discourage", "everyone", "got", "had", "begin", "was"],
    words: [
      "don't",
      "let",
      "life",
      "discourage",
      "you",
      "everyone",
      "who",
      "got",
      "where",
      "he",
      "is",
      "had",
      "to",
      "begin",
      "was",
    ],
    id: "Don't let life discourage you; everyone who got where he is had to begin where he was.",
    author: "Richard L. Evans",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["year", "i", "earn", "right", "on", "your", "birthday"],
    words: [
      "every",
      "year",
      "i",
      "think",
      "you",
      "earn",
      "the",
      "right",
      "to",
      "eat",
      "cake",
      "on",
      "your",
      "birthday",
    ],
    id: "Every year, I think you earn the right to eat cake on your birthday.",
    author: "Bret Hart",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["here", "further", "than", "all", "human", "knowledge", "moonshine"],
    words: [
      "we",
      "are",
      "here",
      "and",
      "it",
      "is",
      "now",
      "further",
      "than",
      "that",
      "all",
      "human",
      "knowledge",
      "moonshine",
    ],
    id: "We are here and it is now. Further than that, all human knowledge is moonshine.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["power", "forgiveness", "huge", "really", "big", "save", "world"],
    words: [
      "the",
      "power",
      "of",
      "forgiveness",
      "is",
      "huge",
      "it",
      "really",
      "big",
      "and",
      "can",
      "save",
      "this",
      "world",
    ],
    id: "The power of forgiveness is huge; it is really big, and it can save this world.",
    author: "Immaculee Ilibagiza",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["remember", "children", "marriages", "gardens", "reflect", "kind", "get"],
    words: [
      "remember",
      "that",
      "children",
      "marriages",
      "and",
      "flower",
      "gardens",
      "reflect",
      "the",
      "kind",
      "of",
      "care",
      "they",
      "get",
    ],
    id: "Remember that children, marriages, and flower gardens reflect the kind of care they get.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "cannot", "believe", "god", "praised", "all", "time"],
    words: [
      "i",
      "cannot",
      "believe",
      "in",
      "a",
      "god",
      "who",
      "wants",
      "to",
      "be",
      "praised",
      "all",
      "the",
      "time",
    ],
    id: "I cannot believe in a God who wants to be praised all the time.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "king", "had", "slave", "among", "his", "ancestors"],
    words: [
      "there",
      "is",
      "no",
      "king",
      "who",
      "has",
      "not",
      "had",
      "a",
      "slave",
      "among",
      "his",
      "ancestors",
      "and",
    ],
    id: "There is no king who has not had a slave among his ancestors, and no slave who has not had a king among his.",
    author: "Helen Keller",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["there's", "idea", "i'm", "create", "change", "everybody's", "consciousness"],
    words: [
      "there's",
      "not",
      "some",
      "idea",
      "i'm",
      "going",
      "to",
      "create",
      "a",
      "work",
      "that's",
      "change",
      "everybody's",
      "consciousness",
    ],
    id: "There's not some idea I'm going to create a work that's going to change everybody's consciousness.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["cause", "hallows", "even", "war", "i", "unto", "any"],
    words: [
      "you",
      "say",
      "it",
      "is",
      "the",
      "good",
      "cause",
      "that",
      "hallows",
      "even",
      "war",
      "i",
      "unto",
      "any",
    ],
    id: "You say it is the good cause that hallows even war? I say unto you: it is the good war that hallows any cause.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "those", "questions", "which", "position", "find", "answers"],
    words: [
      "we",
      "hear",
      "only",
      "those",
      "questions",
      "for",
      "which",
      "are",
      "in",
      "a",
      "position",
      "to",
      "find",
      "answers",
    ],
    id: "We hear only those questions for which we are in a position to find answers.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["market", "never", "saturated", "product", "quickly", "bad", "one"],
    words: [
      "a",
      "market",
      "is",
      "never",
      "saturated",
      "with",
      "good",
      "product",
      "but",
      "it",
      "very",
      "quickly",
      "bad",
      "one",
    ],
    id: "A market is never saturated with a good product, but it is very quickly saturated with a bad one.",
    author: "Henry Ford",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["tibetan", "will", "determine", "next", "reincarnation", "dalai", "lama"],
    words: [
      "the",
      "tibetan",
      "people",
      "will",
      "need",
      "to",
      "determine",
      "who",
      "next",
      "reincarnation",
      "of",
      "dalai",
      "lama",
      "is",
    ],
    id: "The Tibetan people will need to determine who the next reincarnation of the Dalai Lama is.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["real", "problem", "why", "pious", "humble", "believing", "suffer"],
    words: [
      "the",
      "real",
      "problem",
      "is",
      "not",
      "why",
      "some",
      "pious",
      "humble",
      "believing",
      "people",
      "suffer",
      "but",
      "do",
    ],
    id: "The real problem is not why some pious, humble, believing people suffer, but why some do not.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["body's", "posture", "heart's", "attitude", "counts", "when", "pray"],
    words: [
      "it",
      "is",
      "not",
      "the",
      "body's",
      "posture",
      "but",
      "heart's",
      "attitude",
      "that",
      "counts",
      "when",
      "we",
      "pray",
    ],
    id: "It is not the body's posture, but the heart's attitude that counts when we pray.",
    author: "Billy Graham",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["made", "buttons", "on", "screen", "look", "you'll", "lick"],
    words: [
      "we",
      "made",
      "the",
      "buttons",
      "on",
      "screen",
      "look",
      "so",
      "good",
      "you'll",
      "want",
      "to",
      "lick",
      "them",
    ],
    id: "We made the buttons on the screen look so good you'll want to lick them.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "outrun", "death", "will", "up", "all", "eventually"],
    words: [
      "no",
      "one",
      "can",
      "outrun",
      "death",
      "it",
      "will",
      "catch",
      "up",
      "to",
      "all",
      "of",
      "us",
      "eventually",
    ],
    id: "No one can outrun death. It will catch up to all of us eventually.",
    author: "Billy Graham",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "connected", "by", "internet", "neurons", "giant", "brain"],
    words: [
      "we",
      "are",
      "all",
      "now",
      "connected",
      "by",
      "the",
      "internet",
      "like",
      "neurons",
      "in",
      "a",
      "giant",
      "brain",
    ],
    id: "We are all now connected by the Internet, like neurons in a giant brain.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "always", "something", "left", "ain't", "learned", "nothing"],
    words: [
      "there",
      "is",
      "always",
      "something",
      "left",
      "to",
      "love",
      "and",
      "if",
      "you",
      "ain't",
      "learned",
      "that",
      "nothing",
    ],
    id: "There is always something left to love. And if you ain't learned that, you ain't learned nothing.",
    author: "Lorraine Hansberry",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "went", "general", "store", "wouldn't", "anything", "specific"],
    words: [
      "i",
      "went",
      "to",
      "a",
      "general",
      "store",
      "but",
      "they",
      "wouldn't",
      "let",
      "me",
      "buy",
      "anything",
      "specific",
    ],
    id: "I went to a general store but they wouldn't let me buy anything specific.",
    author: "Steven Wright",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "time", "master", "mathematical", "details", "theoretical", "physics"],
    words: [
      "most",
      "people",
      "don't",
      "have",
      "time",
      "to",
      "master",
      "the",
      "very",
      "mathematical",
      "details",
      "of",
      "theoretical",
      "physics",
    ],
    id: "Most people don't have time to master the very mathematical details of theoretical physics.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["false", "words", "only", "evil", "themselves", "infect", "soul"],
    words: [
      "false",
      "words",
      "are",
      "not",
      "only",
      "evil",
      "in",
      "themselves",
      "but",
      "they",
      "infect",
      "the",
      "soul",
      "with",
    ],
    id: "False words are not only evil in themselves, but they infect the soul with evil.",
    author: "Socrates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["find", "your", "heart", "somebody", "else", "will", "succeeded"],
    words: [
      "if",
      "you",
      "find",
      "it",
      "in",
      "your",
      "heart",
      "to",
      "care",
      "for",
      "somebody",
      "else",
      "will",
      "have",
      "succeeded",
    ],
    id: "If you find it in your heart to care for somebody else, you will have succeeded.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["trap", "when", "appears", "only", "its", "light", "shadows"],
    words: [
      "love",
      "is",
      "a",
      "trap",
      "when",
      "it",
      "appears",
      "we",
      "see",
      "only",
      "its",
      "light",
      "not",
      "shadows",
    ],
    id: "Love is a trap. When it appears, we see only its light, not its shadows.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["eulogy", "due", "simply", "his", "duty", "nothing", "more"],
    words: [
      "no",
      "eulogy",
      "is",
      "due",
      "to",
      "him",
      "who",
      "simply",
      "does",
      "his",
      "duty",
      "and",
      "nothing",
      "more",
    ],
    id: "No eulogy is due to him who simply does his duty and nothing more.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "science", "psychology", "knowledge", "definite", "thing", "mind"],
    words: [
      "like",
      "all",
      "science",
      "psychology",
      "is",
      "knowledge",
      "and",
      "again",
      "it",
      "of",
      "a",
      "definite",
      "thing",
      "the",
      "mind",
    ],
    id: "Like all science, psychology is knowledge; and like science again, it is knowledge of a definite thing, the mind.",
    author: "James Mark Baldwin",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["nature", "science", "discoveries", "best", "those", "ones", "expect"],
    words: [
      "the",
      "very",
      "nature",
      "of",
      "science",
      "is",
      "discoveries",
      "and",
      "best",
      "those",
      "are",
      "ones",
      "you",
      "don't",
      "expect",
    ],
    id: "The very nature of science is discoveries, and the best of those discoveries are the ones you don't expect.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "really", "warm", "light", "pours", "over", "everyone"],
    words: [
      "i",
      "really",
      "just",
      "want",
      "to",
      "be",
      "warm",
      "yellow",
      "light",
      "that",
      "pours",
      "over",
      "everyone",
      "love",
    ],
    id: "I really just want to be warm yellow light that pours over everyone I love.",
    author: "Conor Oberst",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["lose", "self", "respect", "each", "how", "finally", "die"],
    words: [
      "if",
      "we",
      "lose",
      "love",
      "and",
      "self",
      "respect",
      "for",
      "each",
      "other",
      "this",
      "is",
      "how",
      "finally",
      "die",
    ],
    id: "If we lose love and self respect for each other, this is how we finally die.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "most",
      "really",
      "freedom",
      "because",
      "involves",
      "responsibility",
      "frightened",
    ],
    words: [
      "most",
      "people",
      "do",
      "not",
      "really",
      "want",
      "freedom",
      "because",
      "involves",
      "responsibility",
      "and",
      "are",
      "frightened",
      "of",
    ],
    id: "Most people do not really want freedom, because freedom involves responsibility, and most people are frightened of responsibility.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["cannot", "position", "power", "destroy", "life", "another", "person"],
    words: [
      "you",
      "cannot",
      "be",
      "in",
      "a",
      "position",
      "of",
      "power",
      "and",
      "destroy",
      "the",
      "life",
      "another",
      "person",
    ],
    id: "You cannot be in a position of power and destroy the life of another person.",
    author: "Pope Francis",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["world", "my", "country", "all", "mankind", "brethren", "religion"],
    words: [
      "the",
      "world",
      "is",
      "my",
      "country",
      "all",
      "mankind",
      "are",
      "brethren",
      "and",
      "to",
      "do",
      "good",
      "religion",
    ],
    id: "The World is my country, all mankind are my brethren, and to do good is my religion.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "cure", "anti-aging", "actually", "-", "called", "lifelong"],
    words: [
      "there",
      "is",
      "a",
      "cure",
      "for",
      "anti-aging",
      "that",
      "actually",
      "works",
      "-",
      "it's",
      "called",
      "lifelong",
      "learning",
    ],
    id: "There is a cure for anti-aging that actually works - it's called lifelong learning.",
    author: "Robin S. Sharma",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["hearing", "feeling", "miracles", "each", "part", "tag", "miracle"],
    words: [
      "seeing",
      "hearing",
      "feeling",
      "are",
      "miracles",
      "and",
      "each",
      "part",
      "tag",
      "of",
      "me",
      "is",
      "a",
      "miracle",
    ],
    id: "Seeing, hearing, feeling, are miracles, and each part and tag of me is a miracle.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "can't", "relate", "lazy", "same", "language", "understand"],
    words: [
      "i",
      "can't",
      "relate",
      "to",
      "lazy",
      "people",
      "we",
      "don't",
      "speak",
      "the",
      "same",
      "language",
      "understand",
      "you",
      "want",
    ],
    id: "I can't relate to lazy people. We don't speak the same language. I don't understand you. I don't want to understand you.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["wisdom", "knowledge", "evil", "strength", "choose", "between", "two"],
    words: [
      "wisdom",
      "is",
      "the",
      "knowledge",
      "of",
      "good",
      "and",
      "evil",
      "not",
      "strength",
      "to",
      "choose",
      "between",
      "two",
    ],
    id: "Wisdom is the knowledge of good and evil, not the strength to choose between the two.",
    author: "John Cheever",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["stranger", "passing", "meet", "desire", "why", "should", "i"],
    words: [
      "stranger",
      "if",
      "you",
      "passing",
      "meet",
      "me",
      "and",
      "desire",
      "to",
      "speak",
      "why",
      "should",
      "not",
      "i",
    ],
    id: "Stranger, if you passing meet me and desire to speak to me, why should you not speak to me? And why should I not speak to you?",
    author: "Walt Whitman",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["capitalism", "worked", "anyone", "move", "north", "korea", "welcome"],
    words: [
      "capitalism",
      "has",
      "worked",
      "very",
      "well",
      "anyone",
      "who",
      "wants",
      "to",
      "move",
      "north",
      "korea",
      "is",
      "welcome",
    ],
    id: "Capitalism has worked very well. Anyone who wants to move to North Korea is welcome.",
    author: "Bill Gates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["sitting", "shade", "because", "planted", "long", "time", "ago"],
    words: [
      "someone",
      "is",
      "sitting",
      "in",
      "the",
      "shade",
      "today",
      "because",
      "planted",
      "a",
      "tree",
      "long",
      "time",
      "ago",
    ],
    id: "Someone is sitting in the shade today because someone planted a tree a long time ago.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "particularly", "drawn", "form", "meditation", "called", "japa"],
    words: [
      "i",
      "am",
      "particularly",
      "drawn",
      "to",
      "the",
      "form",
      "of",
      "meditation",
      "called",
      "japa",
      "know",
      "it",
      "works",
    ],
    id: "I am particularly drawn to the form of meditation called Japa. I know it works.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["order", "heal", "others", "first", "ourselves", "how", "deal"],
    words: [
      "in",
      "order",
      "to",
      "heal",
      "others",
      "we",
      "first",
      "need",
      "ourselves",
      "and",
      "know",
      "how",
      "deal",
      "with",
    ],
    id: "In order to heal others, we first need to heal ourselves. And to heal ourselves, we need to know how to deal with ourselves.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "when", "doubt", "makes", "harder", "prove", "wrong"],
    words: [
      "i",
      "love",
      "it",
      "when",
      "people",
      "doubt",
      "me",
      "makes",
      "work",
      "harder",
      "to",
      "prove",
      "them",
      "wrong",
    ],
    id: "I love it when people doubt me. It makes me work harder to prove them wrong.",
    author: "Derek Jeter",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["freedom", "sure", "possession", "those", "alone", "courage", "defend"],
    words: [
      "freedom",
      "is",
      "the",
      "sure",
      "possession",
      "of",
      "those",
      "alone",
      "who",
      "have",
      "courage",
      "to",
      "defend",
      "it",
    ],
    id: "Freedom is the sure possession of those alone who have the courage to defend it.",
    author: "Pericles",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "wise", "look", "ahead", "difficult", "further", "than"],
    words: [
      "it",
      "is",
      "always",
      "wise",
      "to",
      "look",
      "ahead",
      "but",
      "difficult",
      "further",
      "than",
      "you",
      "can",
      "see",
    ],
    id: "It is always wise to look ahead, but difficult to look further than you can see.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["relationships", "extremely", "important", "success", "on", "off", "job"],
    words: [
      "the",
      "relationships",
      "we",
      "have",
      "with",
      "people",
      "are",
      "extremely",
      "important",
      "to",
      "success",
      "on",
      "and",
      "off",
      "job",
    ],
    id: "The relationships we have with people are extremely important to success on and off the job.",
    author: "Zig Ziglar",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "nothing", "easy", "becomes", "difficult", "when", "reluctantly"],
    words: [
      "there",
      "is",
      "nothing",
      "so",
      "easy",
      "but",
      "that",
      "it",
      "becomes",
      "difficult",
      "when",
      "you",
      "do",
      "reluctantly",
    ],
    id: "There is nothing so easy but that it becomes difficult when you do it reluctantly.",
    author: "Pythagoras",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "creatures", "evolved", "enough", "convey", "pure", "infants"],
    words: [
      "the",
      "only",
      "creatures",
      "that",
      "are",
      "evolved",
      "enough",
      "to",
      "convey",
      "pure",
      "love",
      "dogs",
      "and",
      "infants",
    ],
    id: "The only creatures that are evolved enough to convey pure love are dogs and infants.",
    author: "Johnny Depp",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["worship", "any", "god", "lord", "whose", "name", "jealous"],
    words: [
      "do",
      "not",
      "worship",
      "any",
      "other",
      "god",
      "for",
      "the",
      "lord",
      "whose",
      "name",
      "is",
      "jealous",
      "a",
    ],
    id: "Do not worship any other god, for the Lord, whose name is Jealous, is a jealous God.",
    author: "Moses",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["blind", "-", "sees", "more", "less", "because", "willing"],
    words: [
      "love",
      "is",
      "not",
      "blind",
      "-",
      "it",
      "sees",
      "more",
      "less",
      "but",
      "because",
      "willing",
      "to",
      "see",
    ],
    id: "Love is not blind - it sees more, not less. But because it sees more, it is willing to see less.",
    author: "Julius Gordon",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["afraid", "failure", "will", "never", "succeed", "take", "chances"],
    words: [
      "if",
      "you're",
      "so",
      "afraid",
      "of",
      "failure",
      "you",
      "will",
      "never",
      "succeed",
      "have",
      "to",
      "take",
      "chances",
    ],
    id: "If you're so afraid of failure, you will never succeed. You have to take chances.",
    author: "Mario Andretti",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["praise", "comes", "from", "make", "vain", "more", "humble"],
    words: [
      "the",
      "praise",
      "that",
      "comes",
      "from",
      "love",
      "does",
      "not",
      "make",
      "us",
      "vain",
      "but",
      "more",
      "humble",
    ],
    id: "The praise that comes from love does not make us vain, but more humble.",
    author: "James M. Barrie",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["shred", "evidence", "exists", "favor", "idea", "life", "serious"],
    words: [
      "not",
      "a",
      "shred",
      "of",
      "evidence",
      "exists",
      "in",
      "favor",
      "the",
      "idea",
      "that",
      "life",
      "is",
      "serious",
    ],
    id: "Not a shred of evidence exists in favor of the idea that life is serious.",
    author: "Brendan Gill",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "success", "i've", "had", "worked", "hard", "at"],
    words: [
      "i'm",
      "happy",
      "for",
      "the",
      "success",
      "that",
      "i've",
      "had",
      "but",
      "worked",
      "so",
      "hard",
      "at",
      "it",
    ],
    id: "I'm happy for the success that I've had, but I've worked so hard at it.",
    author: "Jenni Rivera",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "interests", "i", "contact", "things", "revolve", "around"],
    words: [
      "only",
      "love",
      "interests",
      "me",
      "and",
      "i",
      "am",
      "in",
      "contact",
      "with",
      "things",
      "that",
      "revolve",
      "around",
    ],
    id: "Only love interests me, and I am only in contact with things that revolve around love.",
    author: "Marc Chagall",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "greatest",
      "problem",
      "world",
      "intolerance",
      "everyone",
      "intolerant",
      "each",
    ],
    words: [
      "the",
      "greatest",
      "problem",
      "in",
      "world",
      "today",
      "is",
      "intolerance",
      "everyone",
      "so",
      "intolerant",
      "of",
      "each",
      "other",
    ],
    id: "The greatest problem in the world today is intolerance. Everyone is so intolerant of each other.",
    author: "Princess Diana",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["freedom", "from", "desire", "answer", "essential", "understanding", "problem"],
    words: [
      "freedom",
      "from",
      "the",
      "desire",
      "for",
      "an",
      "answer",
      "is",
      "essential",
      "to",
      "understanding",
      "of",
      "a",
      "problem",
    ],
    id: "Freedom from the desire for an answer is essential to the understanding of a problem.",
    author: "Jiddu Krishnamurti",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["pure", "willingness", "without", "thought", "receiving", "anything", "return"],
    words: [
      "pure",
      "love",
      "is",
      "a",
      "willingness",
      "to",
      "give",
      "without",
      "thought",
      "of",
      "receiving",
      "anything",
      "in",
      "return",
    ],
    id: "Pure love is a willingness to give without a thought of receiving anything in return.",
    author: "Peace Pilgrim",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["home", "-", "our", "feet", "may", "leave", "hearts"],
    words: [
      "where",
      "we",
      "love",
      "is",
      "home",
      "-",
      "that",
      "our",
      "feet",
      "may",
      "leave",
      "but",
      "not",
      "hearts",
    ],
    id: "Where we love is home - home that our feet may leave, but not our hearts.",
    author: "Oliver Wendell Holmes, Sr.",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "biggest", "thing", "as", "i've", "said", "patience"],
    words: [
      "i",
      "am",
      "who",
      "and",
      "the",
      "biggest",
      "thing",
      "can",
      "learn",
      "as",
      "i've",
      "said",
      "is",
      "patience",
    ],
    id: "I am who I am and the biggest thing I can learn, as I've said, is patience.",
    author: "Shane Lowry",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["take", "time", "deliberate", "when", "action", "arrives", "stop"],
    words: [
      "take",
      "time",
      "to",
      "deliberate",
      "but",
      "when",
      "the",
      "for",
      "action",
      "arrives",
      "stop",
      "thinking",
      "and",
      "go",
      "in",
    ],
    id: "Take time to deliberate; but when the time for action arrives, stop thinking and go in.",
    author: "Andrew Jackson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["sounds", "strange", "someone's", "bad", "luck", "sometimes", "happiness"],
    words: [
      "it",
      "sounds",
      "strange",
      "to",
      "say",
      "but",
      "someone's",
      "bad",
      "luck",
      "is",
      "sometimes",
      "the",
      "happiness",
      "of",
      "other",
    ],
    id: "It sounds strange to say, but someone's bad luck is sometimes the happiness of the other.",
    author: "Michy Batshuayi",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["ambition", "focus", "propel", "success", "also", "your", "downfall"],
    words: [
      "the",
      "ambition",
      "and",
      "focus",
      "that",
      "propel",
      "you",
      "to",
      "success",
      "can",
      "also",
      "be",
      "your",
      "downfall",
    ],
    id: "The ambition and focus that propel you to success can also be your downfall.",
    author: "Judy Smith",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["heat", "furnace", "your", "foe", "hot", "singe", "yourself"],
    words: [
      "heat",
      "not",
      "a",
      "furnace",
      "for",
      "your",
      "foe",
      "so",
      "hot",
      "that",
      "it",
      "do",
      "singe",
      "yourself",
    ],
    id: "Heat not a furnace for your foe so hot that it do singe yourself.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "nothing", "important", "or", "interesting", "anyone", "persuade"],
    words: [
      "when",
      "you",
      "have",
      "nothing",
      "important",
      "or",
      "interesting",
      "to",
      "say",
      "don't",
      "let",
      "anyone",
      "persuade",
      "it",
    ],
    id: "When you have nothing important or interesting to say, don't let anyone persuade you to say it.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["baseball", "was", "always", "will", "best", "game", "world"],
    words: [
      "baseball",
      "was",
      "is",
      "and",
      "always",
      "will",
      "be",
      "to",
      "me",
      "the",
      "best",
      "game",
      "in",
      "world",
    ],
    id: "Baseball was, is and always will be to me the best game in the world.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "person", "educated", "one", "learned", "how", "change"],
    words: [
      "the",
      "only",
      "person",
      "who",
      "is",
      "educated",
      "one",
      "has",
      "learned",
      "how",
      "to",
      "learn",
      "and",
      "change",
    ],
    id: "The only person who is educated is the one who has learned how to learn and change.",
    author: "Carl Rogers",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["philosopher", "goes", "truth", "leads", "patience", "mere", "emotion"],
    words: [
      "a",
      "philosopher",
      "goes",
      "where",
      "the",
      "truth",
      "leads",
      "and",
      "has",
      "no",
      "patience",
      "with",
      "mere",
      "emotion",
    ],
    id: "A philosopher goes where the truth leads and has no patience with mere emotion.",
    author: "John C. Wright",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "when",
      "everything",
      "inside",
      "there",
      "corresponding",
      "something",
      "outside",
    ],
    words: [
      "we",
      "are",
      "happy",
      "when",
      "for",
      "everything",
      "inside",
      "us",
      "there",
      "is",
      "a",
      "corresponding",
      "something",
      "outside",
    ],
    id: "We are happy when for everything inside us there is a corresponding something outside us.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "everything", "deep", "at", "center", "your", "being"],
    words: [
      "i",
      "want",
      "you",
      "to",
      "be",
      "everything",
      "that's",
      "deep",
      "at",
      "the",
      "center",
      "of",
      "your",
      "being",
    ],
    id: "I want you to be everything that's you, deep at the center of your being.",
    author: "Confucius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["lions", "taught", "photography", "patience", "sense", "beauty", "penetrates"],
    words: [
      "the",
      "lions",
      "taught",
      "me",
      "photography",
      "they",
      "patience",
      "and",
      "sense",
      "of",
      "beauty",
      "a",
      "that",
      "penetrates",
      "you",
    ],
    id: "The lions taught me photography. They taught me patience and the sense of beauty, a beauty that penetrates you.",
    author: "Yann Arthus-Bertrand",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "experience", "listen", "feel", "-", "then", "change"],
    words: [
      "my",
      "experience",
      "is",
      "listen",
      "see",
      "feel",
      "-",
      "and",
      "then",
      "think",
      "about",
      "what",
      "you",
      "change",
    ],
    id: "My experience is listen, see, feel - and then think about what you change.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["living", "enough", "one", "must", "sunshine", "freedom", "little"],
    words: [
      "just",
      "living",
      "is",
      "not",
      "enough",
      "one",
      "must",
      "have",
      "sunshine",
      "freedom",
      "and",
      "a",
      "little",
      "flower",
    ],
    id: "Just living is not enough... one must have sunshine, freedom, and a little flower.",
    author: "Hans Christian Andersen",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["three", "grand", "essentials", "happiness", "life", "something", "hope"],
    words: [
      "three",
      "grand",
      "essentials",
      "to",
      "happiness",
      "in",
      "this",
      "life",
      "are",
      "something",
      "do",
      "love",
      "and",
      "hope",
      "for",
    ],
    id: "Three grand essentials to happiness in this life are something to do, something to love, and something to hope for.",
    author: "Joseph Addison",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["virtue", "left", "stand", "alone", "practices", "will", "neighbors"],
    words: [
      "virtue",
      "is",
      "not",
      "left",
      "to",
      "stand",
      "alone",
      "he",
      "who",
      "practices",
      "it",
      "will",
      "have",
      "neighbors",
    ],
    id: "Virtue is not left to stand alone. He who practices it will have neighbors.",
    author: "Confucius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "shortcuts", "success", "believe", "yourself", "never", "up"],
    words: [
      "there",
      "are",
      "no",
      "shortcuts",
      "to",
      "success",
      "you",
      "have",
      "believe",
      "in",
      "yourself",
      "and",
      "never",
      "give",
      "up",
    ],
    id: "There are no shortcuts to success. You have to believe in yourself, and never give up.",
    author: "Neha Dhupia",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "subjective", "thing", "-", "comes", "different", "forms"],
    words: [
      "happiness",
      "is",
      "a",
      "very",
      "subjective",
      "thing",
      "-",
      "it",
      "comes",
      "to",
      "people",
      "in",
      "different",
      "forms",
    ],
    id: "Happiness is a very subjective thing - it comes to people in different forms.",
    author: "Bhumika Chawla",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "happiness", "helps", "look", "lot", "more", "places"],
    words: [
      "money",
      "can't",
      "buy",
      "you",
      "happiness",
      "but",
      "it",
      "helps",
      "look",
      "for",
      "in",
      "a",
      "lot",
      "more",
      "places",
    ],
    id: "Money can't buy you happiness, but it helps you look for it in a lot more places.",
    author: "Milton Berle",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["modestly", "enjoy", "your", "fame", "unworthy", "rank", "holy"],
    words: [
      "if",
      "you",
      "modestly",
      "enjoy",
      "your",
      "fame",
      "are",
      "not",
      "unworthy",
      "to",
      "rank",
      "with",
      "the",
      "holy",
    ],
    id: "If you modestly enjoy your fame you are not unworthy to rank with the holy.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["live", "when", "your", "children", "fairness", "caring", "integrity"],
    words: [
      "live",
      "so",
      "that",
      "when",
      "your",
      "children",
      "think",
      "of",
      "fairness",
      "caring",
      "and",
      "integrity",
      "they",
      "you",
    ],
    id: "Live so that when your children think of fairness, caring, and integrity, they think of you.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "limits", "possibilities", "your", "life", "buts", "use"],
    words: [
      "the",
      "only",
      "limits",
      "to",
      "possibilities",
      "in",
      "your",
      "life",
      "tomorrow",
      "are",
      "buts",
      "you",
      "use",
      "today",
    ],
    id: "The only limits to the possibilities in your life tomorrow are the buts you use today.",
    author: "Les Brown",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["country", "governed", "poverty", "something", "ashamed", "badly", "wealth"],
    words: [
      "in",
      "a",
      "country",
      "well",
      "governed",
      "poverty",
      "is",
      "something",
      "to",
      "be",
      "ashamed",
      "of",
      "badly",
      "wealth",
    ],
    id: "In a country well governed, poverty is something to be ashamed of. In a country badly governed, wealth is something to be ashamed of.",
    author: "Confucius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["little", "ungrammatical", "come", "from", "right", "part", "country"],
    words: [
      "you",
      "can",
      "be",
      "a",
      "little",
      "ungrammatical",
      "if",
      "come",
      "from",
      "the",
      "right",
      "part",
      "of",
      "country",
    ],
    id: "You can be a little ungrammatical if you come from the right part of the country.",
    author: "Robert Frost",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "conquer", "himself", "first", "noblest", "all", "victories"],
    words: [
      "for",
      "a",
      "man",
      "to",
      "conquer",
      "himself",
      "is",
      "the",
      "first",
      "and",
      "noblest",
      "of",
      "all",
      "victories",
    ],
    id: "For a man to conquer himself is the first and noblest of all victories.",
    author: "Plato",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["faced", "right", "leave", "undone", "shows", "lack", "courage"],
    words: [
      "faced",
      "with",
      "what",
      "is",
      "right",
      "to",
      "leave",
      "it",
      "undone",
      "shows",
      "a",
      "lack",
      "of",
      "courage",
    ],
    id: "Faced with what is right, to leave it undone shows a lack of courage.",
    author: "Confucius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["spiritual", "things", "god", "performs", "all", "rest", "then"],
    words: [
      "in",
      "spiritual",
      "things",
      "it",
      "is",
      "god",
      "who",
      "performs",
      "all",
      "for",
      "you",
      "rest",
      "him",
      "then",
    ],
    id: "In spiritual things, it is God who performs all things for you. Rest in Him, then.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["past", "source", "knowledge", "future", "hope", "implies", "faith"],
    words: [
      "the",
      "past",
      "is",
      "a",
      "source",
      "of",
      "knowledge",
      "and",
      "future",
      "hope",
      "love",
      "implies",
      "faith",
      "in",
    ],
    id: "The past is a source of knowledge, and the future is a source of hope. Love of the past implies faith in the future.",
    author: "Stephen Ambrose",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "living", "my", "life", "way", "i", "freedom"],
    words: [
      "i'm",
      "just",
      "living",
      "my",
      "life",
      "the",
      "way",
      "i",
      "want",
      "to",
      "have",
      "freedom",
      "do",
      "that",
    ],
    id: "I'm just living my life the way I want to. I have the freedom to do that.",
    author: "Dan Bilzerian",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "thing", "i'm", "afraid", "bees", "i", "allergic"],
    words: [
      "the",
      "only",
      "thing",
      "i'm",
      "afraid",
      "of",
      "is",
      "bees",
      "i",
      "don't",
      "like",
      "allergic",
      "to",
      "them",
    ],
    id: "The only thing I'm afraid of is bees. I don't like bees. I'm allergic to them.",
    author: "Kobe Bryant",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "ask", "i", "went", "film", "tell", "films"],
    words: [
      "when",
      "people",
      "ask",
      "me",
      "if",
      "i",
      "went",
      "to",
      "film",
      "school",
      "tell",
      "them",
      "no",
      "films",
    ],
    id: "When people ask me if I went to film school I tell them, 'no, I went to films.'",
    author: "Quentin Tarantino",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["road", "freedom", "must", "uphill", "even", "arduous", "frustrating"],
    words: [
      "the",
      "road",
      "to",
      "freedom",
      "must",
      "be",
      "uphill",
      "even",
      "if",
      "it",
      "is",
      "arduous",
      "and",
      "frustrating",
    ],
    id: "The road to freedom must be uphill, even if it is arduous and frustrating.",
    author: "Andrew Goodman",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["seek", "lofty", "by", "reading", "hearing", "great", "at"],
    words: [
      "seek",
      "the",
      "lofty",
      "by",
      "reading",
      "hearing",
      "and",
      "seeing",
      "great",
      "work",
      "at",
      "some",
      "moment",
      "every",
      "day",
    ],
    id: "Seek the lofty by reading, hearing and seeing great work at some moment every day.",
    author: "Thornton Wilder",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["can't", "happiness", "bring", "more", "pleasant", "form", "misery"],
    words: [
      "money",
      "can't",
      "buy",
      "you",
      "happiness",
      "but",
      "it",
      "does",
      "bring",
      "a",
      "more",
      "pleasant",
      "form",
      "of",
      "misery",
    ],
    id: "Money can't buy you happiness but it does bring you a more pleasant form of misery.",
    author: "Spike Milligan",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["ai", "neither", "nor", "evil", "tool", "technology", "use"],
    words: [
      "ai",
      "is",
      "neither",
      "good",
      "nor",
      "evil",
      "it's",
      "a",
      "tool",
      "technology",
      "for",
      "us",
      "to",
      "use",
    ],
    id: "AI is neither good nor evil. It's a tool. It's a technology for us to use.",
    author: "Oren Etzioni",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "retain", "what's", "interesting", "lot", "strategic", "depth"],
    words: [
      "i",
      "retain",
      "what's",
      "interesting",
      "to",
      "me",
      "but",
      "don't",
      "have",
      "a",
      "lot",
      "of",
      "strategic",
      "depth",
    ],
    id: "I retain what's interesting to me, but I don't have a lot of strategic depth.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["child", "comes", "message", "god", "yet", "discouraged", "man"],
    words: [
      "every",
      "child",
      "comes",
      "with",
      "the",
      "message",
      "that",
      "god",
      "is",
      "not",
      "yet",
      "discouraged",
      "of",
      "man",
    ],
    id: "Every child comes with the message that God is not yet discouraged of man.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "time", "changes", "things", "actually", "change", "yourself"],
    words: [
      "they",
      "always",
      "say",
      "time",
      "changes",
      "things",
      "but",
      "you",
      "actually",
      "have",
      "to",
      "change",
      "them",
      "yourself",
    ],
    id: "They always say time changes things, but you actually have to change them yourself.",
    author: "Andy Warhol",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["could", "refrain", "had", "heart", "courage", "make", "known"],
    words: [
      "who",
      "could",
      "refrain",
      "that",
      "had",
      "a",
      "heart",
      "to",
      "love",
      "and",
      "in",
      "courage",
      "make",
      "known",
    ],
    id: "Who could refrain that had a heart to love and in that heart courage to make love known?",
    author: "William Shakespeare",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "half", "as", "should", "less", "than", "deserve"],
    words: [
      "i",
      "don't",
      "know",
      "half",
      "of",
      "you",
      "as",
      "well",
      "should",
      "like",
      "and",
      "less",
      "than",
      "deserve",
    ],
    id: "I don't know half of you half as well as I should like; and I like less than half of you half as well as you deserve.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["appreciate", "noble", "gain", "which", "never", "torn", "from"],
    words: [
      "to",
      "appreciate",
      "the",
      "noble",
      "is",
      "a",
      "gain",
      "which",
      "can",
      "never",
      "be",
      "torn",
      "from",
      "us",
    ],
    id: "To appreciate the noble is a gain which can never be torn from us.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["couple", "years", "i'll", "make", "actress", "overnight", "success"],
    words: [
      "give",
      "me",
      "a",
      "couple",
      "of",
      "years",
      "and",
      "i'll",
      "make",
      "that",
      "actress",
      "an",
      "overnight",
      "success",
    ],
    id: "Give me a couple of years, and I'll make that actress an overnight success.",
    author: "Samuel Goldwyn",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgiveness", "injured", "belong", "ne'er", "pardon", "done", "wrong"],
    words: [
      "forgiveness",
      "to",
      "the",
      "injured",
      "does",
      "belong",
      "but",
      "they",
      "ne'er",
      "pardon",
      "who",
      "have",
      "done",
      "wrong",
    ],
    id: "Forgiveness to the injured does belong; but they ne'er pardon who have done wrong.",
    author: "John Dryden",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["anger", "enemy", "non-violence", "pride", "monster", "swallows", "up"],
    words: [
      "anger",
      "is",
      "the",
      "enemy",
      "of",
      "non-violence",
      "and",
      "pride",
      "a",
      "monster",
      "that",
      "swallows",
      "it",
      "up",
    ],
    id: "Anger is the enemy of non-violence and pride is a monster that swallows it up.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["chance", "perhaps", "pseudonym", "god", "when", "did", "sign"],
    words: [
      "chance",
      "is",
      "perhaps",
      "the",
      "pseudonym",
      "of",
      "god",
      "when",
      "he",
      "did",
      "not",
      "want",
      "to",
      "sign",
    ],
    id: "Chance is perhaps the pseudonym of God when he did not want to sign.",
    author: "Anatole France",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "great", "only", "separated", "by", "willingness", "sacrifice"],
    words: [
      "i",
      "think",
      "that",
      "the",
      "good",
      "and",
      "great",
      "are",
      "only",
      "separated",
      "by",
      "willingness",
      "to",
      "sacrifice",
    ],
    id: "I think that the good and the great are only separated by the willingness to sacrifice.",
    author: "Kareem Abdul-Jabbar",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["dream", "small", "dreams", "power", "move", "hearts", "men"],
    words: [
      "dream",
      "no",
      "small",
      "dreams",
      "for",
      "they",
      "have",
      "power",
      "to",
      "move",
      "the",
      "hearts",
      "of",
      "men",
    ],
    id: "Dream no small dreams for they have no power to move the hearts of men.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["story", "should", "beginning", "middle", "end", "necessarily", "order"],
    words: [
      "a",
      "story",
      "should",
      "have",
      "beginning",
      "middle",
      "and",
      "an",
      "end",
      "but",
      "not",
      "necessarily",
      "in",
      "that",
      "order",
    ],
    id: "A story should have a beginning, a middle and an end, but not necessarily in that order.",
    author: "Jean-Luc Godard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "will", "humble", "either", "choose", "or", "compelled"],
    words: [
      "god",
      "will",
      "have",
      "a",
      "humble",
      "people",
      "either",
      "we",
      "can",
      "choose",
      "to",
      "be",
      "or",
      "compelled",
    ],
    id: "God will have a humble people. Either we can choose to be humble, or we can be compelled to be humble.",
    author: "Ezra Taft Benson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["death", "man", "should", "fear", "never", "beginning", "live"],
    words: [
      "it",
      "is",
      "not",
      "death",
      "that",
      "a",
      "man",
      "should",
      "fear",
      "but",
      "he",
      "never",
      "beginning",
      "to",
      "live",
    ],
    id: "It is not death that a man should fear, but he should fear never beginning to live.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["drug", "dealers", "sympathize", "as", "sort", "pathetic", "character"],
    words: [
      "the",
      "drug",
      "dealers",
      "they",
      "sympathize",
      "with",
      "me",
      "see",
      "as",
      "some",
      "sort",
      "of",
      "pathetic",
      "character",
    ],
    id: "The drug dealers, they sympathize with me. They see me as some sort of pathetic character.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "man", "accompanied", "jacqueline", "kennedy", "paris", "enjoyed"],
    words: [
      "i",
      "am",
      "the",
      "man",
      "who",
      "accompanied",
      "jacqueline",
      "kennedy",
      "to",
      "paris",
      "and",
      "have",
      "enjoyed",
      "it",
    ],
    id: "I am the man who accompanied Jacqueline Kennedy to Paris, and I have enjoyed it.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["father", "man", "expects", "his", "son", "as", "meant"],
    words: [
      "a",
      "father",
      "is",
      "man",
      "who",
      "expects",
      "his",
      "son",
      "to",
      "be",
      "as",
      "good",
      "he",
      "meant",
    ],
    id: "A father is a man who expects his son to be as good a man as he meant to be.",
    author: "Frank A. Clark",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["name", "god", "stop", "cease", "your", "look", "around"],
    words: [
      "in",
      "the",
      "name",
      "of",
      "god",
      "stop",
      "a",
      "moment",
      "cease",
      "your",
      "work",
      "look",
      "around",
      "you",
    ],
    id: "In the name of God, stop a moment, cease your work, look around you.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "those", "around", "bridge", "my", "success", "important"],
    words: [
      "all",
      "those",
      "who",
      "are",
      "around",
      "me",
      "the",
      "bridge",
      "to",
      "my",
      "success",
      "so",
      "they",
      "important",
    ],
    id: "All those who are around me are the bridge to my success, so they are all important.",
    author: "Manny Pacquiao",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["will", "never", "write", "read", "done", "rare", "things"],
    words: [
      "if",
      "you",
      "can",
      "speak",
      "what",
      "will",
      "never",
      "hear",
      "write",
      "read",
      "have",
      "done",
      "rare",
      "things",
    ],
    id: "If you can speak what you will never hear, if you can write what you will never read, you have done rare things.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "possession", "prized", "quality", "thought", "state", "mind"],
    words: [
      "happiness",
      "is",
      "not",
      "a",
      "possession",
      "to",
      "be",
      "prized",
      "it",
      "quality",
      "of",
      "thought",
      "state",
      "mind",
    ],
    id: "Happiness is not a possession to be prized, it is a quality of thought, a state of mind.",
    author: "Daphne du Maurier",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "there",
      "suspicion",
      "person's",
      "motives",
      "everything",
      "becomes",
      "tainted",
    ],
    words: [
      "the",
      "moment",
      "there",
      "is",
      "suspicion",
      "about",
      "a",
      "person's",
      "motives",
      "everything",
      "he",
      "does",
      "becomes",
      "tainted",
    ],
    id: "The moment there is suspicion about a person's motives, everything he does becomes tainted.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["mankind", "may", "general", "fickle", "hypocritical", "greedy", "gain"],
    words: [
      "of",
      "mankind",
      "we",
      "may",
      "say",
      "in",
      "general",
      "they",
      "are",
      "fickle",
      "hypocritical",
      "and",
      "greedy",
      "gain",
    ],
    id: "Of mankind we may say in general they are fickle, hypocritical, and greedy of gain.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["greatness", "nation", "judged", "by", "way", "its", "treated"],
    words: [
      "the",
      "greatness",
      "of",
      "a",
      "nation",
      "can",
      "be",
      "judged",
      "by",
      "way",
      "its",
      "animals",
      "are",
      "treated",
    ],
    id: "The greatness of a nation can be judged by the way its animals are treated.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "there's", "any", "focus", "on", "my", "career"],
    words: [
      "well",
      "i",
      "don't",
      "think",
      "there's",
      "any",
      "need",
      "for",
      "people",
      "to",
      "focus",
      "on",
      "my",
      "career",
    ],
    id: "Well, I don't think there's any need for people to focus on my career.",
    author: "Bill Gates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "cause", "might", "korea", "united", "truth", "infinite"],
    words: [
      "our",
      "cause",
      "is",
      "just",
      "and",
      "the",
      "might",
      "of",
      "korea",
      "that",
      "united",
      "with",
      "truth",
      "infinite",
    ],
    id: "Our cause is just, and the might of Korea that is united with truth is infinite.",
    author: "Kim Jong-un",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "beneficial", "body", "grief", "develops", "powers", "mind"],
    words: [
      "happiness",
      "is",
      "beneficial",
      "for",
      "the",
      "body",
      "but",
      "it",
      "grief",
      "that",
      "develops",
      "powers",
      "of",
      "mind",
    ],
    id: "Happiness is beneficial for the body, but it is grief that develops the powers of the mind.",
    author: "Marcel Proust",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "greedy", "as", "insignificant", "why", "satisfies", "nobody"],
    words: [
      "success",
      "is",
      "not",
      "greedy",
      "as",
      "people",
      "think",
      "but",
      "insignificant",
      "that",
      "why",
      "it",
      "satisfies",
      "nobody",
    ],
    id: "Success is not greedy, as people think, but insignificant. That is why it satisfies nobody.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["lose", "yourself", "far", "off", "time", "seize", "thine"],
    words: [
      "lose",
      "not",
      "yourself",
      "in",
      "a",
      "far",
      "off",
      "time",
      "seize",
      "the",
      "moment",
      "that",
      "is",
      "thine",
    ],
    id: "Lose not yourself in a far off time, seize the moment that is thine.",
    author: "Friedrich Schiller",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "bird", "its", "food", "throw", "into", "nest"],
    words: [
      "god",
      "gives",
      "every",
      "bird",
      "its",
      "food",
      "but",
      "he",
      "does",
      "not",
      "throw",
      "it",
      "into",
      "nest",
    ],
    id: "God gives every bird its food, but He does not throw it into its nest.",
    author: "Josiah Gilbert Holland",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["loss", "young", "first", "painful", "borders", "on", "ludicrous"],
    words: [
      "the",
      "loss",
      "of",
      "young",
      "first",
      "love",
      "is",
      "so",
      "painful",
      "that",
      "it",
      "borders",
      "on",
      "ludicrous",
    ],
    id: "The loss of young first love is so painful that it borders on the ludicrous.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["listener", "only", "popular", "everywhere", "after", "while", "something"],
    words: [
      "a",
      "good",
      "listener",
      "is",
      "not",
      "only",
      "popular",
      "everywhere",
      "but",
      "after",
      "while",
      "he",
      "knows",
      "something",
    ],
    id: "A good listener is not only popular everywhere, but after a while, he knows something.",
    author: "Wilson Mizner",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["one's", "friends", "part", "human", "race", "which", "one"],
    words: [
      "one's",
      "friends",
      "are",
      "that",
      "part",
      "of",
      "the",
      "human",
      "race",
      "with",
      "which",
      "one",
      "can",
      "be",
    ],
    id: "One's friends are that part of the human race with which one can be human.",
    author: "George Santayana",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["usually", "lose", "because", "there", "been", "yesterday", "coming"],
    words: [
      "we",
      "usually",
      "lose",
      "today",
      "because",
      "there",
      "has",
      "been",
      "a",
      "yesterday",
      "and",
      "tomorrow",
      "is",
      "coming",
    ],
    id: "We usually lose today, because there has been a yesterday, and tomorrow is coming.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "mind", "will", "answer", "most", "questions", "relax"],
    words: [
      "your",
      "mind",
      "will",
      "answer",
      "most",
      "questions",
      "if",
      "you",
      "learn",
      "to",
      "relax",
      "and",
      "wait",
      "for",
      "the",
    ],
    id: "Your mind will answer most questions if you learn to relax and wait for the answer.",
    author: "William S. Burroughs",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["whatever", "else", "unsure", "stinking", "dunghill", "world", "mother's"],
    words: [
      "whatever",
      "else",
      "is",
      "unsure",
      "in",
      "this",
      "stinking",
      "dunghill",
      "of",
      "a",
      "world",
      "mother's",
      "love",
      "not",
    ],
    id: "Whatever else is unsure in this stinking dunghill of a world a mother's love is not.",
    author: "James Joyce",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "matter", "holding", "cards", "playing", "poor", "hand"],
    words: [
      "life",
      "is",
      "not",
      "a",
      "matter",
      "of",
      "holding",
      "good",
      "cards",
      "but",
      "playing",
      "poor",
      "hand",
      "well",
    ],
    id: "Life is not a matter of holding good cards, but of playing a poor hand well.",
    author: "Robert Louis Stevenson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "may", "there", "when", "always", "on", "time"],
    words: [
      "god",
      "may",
      "not",
      "be",
      "there",
      "when",
      "you",
      "want",
      "him",
      "but",
      "he",
      "is",
      "always",
      "on",
      "time",
    ],
    id: "God may not be there when you want him but he is always on time.",
    author: "Terrell Owens",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["make", "peace", "your", "enemy", "then", "becomes", "partner"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "make",
      "peace",
      "with",
      "your",
      "enemy",
      "have",
      "work",
      "then",
      "he",
      "becomes",
      "partner",
    ],
    id: "If you want to make peace with your enemy, you have to work with your enemy. Then he becomes your partner.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "i", "children", "face", "god", "why", "much"],
    words: [
      "when",
      "i",
      "see",
      "children",
      "the",
      "face",
      "of",
      "god",
      "that's",
      "why",
      "love",
      "them",
      "so",
      "much",
      "what",
    ],
    id: "When I see children, I see the face of God. That's why I love them so much. That's what I see.",
    author: "Michael Jackson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 8,
    new_words_count: 7,
  },
  {
    row_new_words: ["failing", "youth", "able", "restrain", "its", "own", "violence"],
    words: [
      "it",
      "is",
      "the",
      "failing",
      "of",
      "youth",
      "not",
      "to",
      "be",
      "able",
      "restrain",
      "its",
      "own",
      "violence",
    ],
    id: "It is the failing of youth not to be able to restrain its own violence.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["story", "anorexic", "girls", "-", "nobody", "nothing", "fashion"],
    words: [
      "the",
      "story",
      "with",
      "anorexic",
      "girls",
      "-",
      "nobody",
      "works",
      "that",
      "has",
      "nothing",
      "to",
      "do",
      "fashion",
    ],
    id: "The story with anorexic girls - nobody works with anorexic girls. That has nothing to do with fashion.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "feel", "take", "lot", "pride", "patience", "play"],
    words: [
      "i",
      "feel",
      "like",
      "take",
      "a",
      "lot",
      "of",
      "pride",
      "in",
      "the",
      "patience",
      "that",
      "play",
      "with",
    ],
    id: "I feel like I take a lot of pride in the patience that I play with.",
    author: "Joe Harris",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "admire", "criticize", "because", "their", "opinion", "valuable"],
    words: [
      "i",
      "don't",
      "care",
      "if",
      "people",
      "admire",
      "criticize",
      "me",
      "because",
      "their",
      "opinion",
      "is",
      "valuable",
      "to",
    ],
    id: "I don't care if people I admire criticize me because their opinion is valuable to me.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["shame", "most", "powerful", "master", "emotion", "fear", "enough"],
    words: [
      "shame",
      "is",
      "the",
      "most",
      "powerful",
      "master",
      "emotion",
      "it's",
      "fear",
      "that",
      "we're",
      "not",
      "good",
      "enough",
    ],
    id: "Shame is the most powerful, master emotion. It's the fear that we're not good enough.",
    author: "Brene Brown",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "real", "failure", "life", "true", "best", "one"],
    words: [
      "the",
      "only",
      "real",
      "failure",
      "in",
      "life",
      "is",
      "not",
      "to",
      "be",
      "true",
      "best",
      "one",
      "knows",
    ],
    id: "The only real failure in life is not to be true to the best one knows.",
    author: "Buddha",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "found", "parkinson's", "slow", "down", "whether", "or"],
    words: [
      "i",
      "found",
      "that",
      "this",
      "parkinson's",
      "does",
      "slow",
      "you",
      "down",
      "whether",
      "want",
      "to",
      "or",
      "not",
    ],
    id: "I found that this Parkinson's does slow you down, whether you want to slow down or not.",
    author: "Billy Graham",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["your", "time", "will", "track", "down", "cruise", "missile"],
    words: [
      "if",
      "it",
      "is",
      "your",
      "time",
      "love",
      "will",
      "track",
      "you",
      "down",
      "like",
      "a",
      "cruise",
      "missile",
    ],
    id: "If it is your time, love will track you down like a cruise missile.",
    author: "Lynda Barry",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "were", "three", "marriage", "was", "bit", "crowded"],
    words: [
      "there",
      "were",
      "three",
      "of",
      "us",
      "in",
      "this",
      "marriage",
      "so",
      "it",
      "was",
      "a",
      "bit",
      "crowded",
    ],
    id: "There were three of us in this marriage, so it was a bit crowded.",
    author: "Princess Diana",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["surrounds", "being", "extends", "slowly", "embrace", "all", "shall"],
    words: [
      "love",
      "it",
      "surrounds",
      "every",
      "being",
      "and",
      "extends",
      "slowly",
      "to",
      "embrace",
      "all",
      "that",
      "shall",
      "be",
    ],
    id: "Love... it surrounds every being and extends slowly to embrace all that shall be.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "admire", "dare", "take", "language", "understand", "melody"],
    words: [
      "i",
      "admire",
      "people",
      "who",
      "dare",
      "to",
      "take",
      "the",
      "language",
      "english",
      "and",
      "understand",
      "it",
      "melody",
    ],
    id: "I admire people who dare to take the language, English, and understand it and understand the melody.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "recipe", "happiness", "engine", "simply", "having", "desire"],
    words: [
      "i",
      "don't",
      "have",
      "the",
      "recipe",
      "for",
      "happiness",
      "but",
      "think",
      "engine",
      "is",
      "simply",
      "having",
      "desire",
    ],
    id: "I don't have the recipe for happiness, but I think the engine is simply having the desire.",
    author: "Vanessa Paradis",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "i've", "been", "japanese", "years", "really", "hard"],
    words: [
      "i",
      "like",
      "learning",
      "i've",
      "been",
      "studying",
      "japanese",
      "for",
      "years",
      "now",
      "but",
      "it's",
      "really",
      "hard",
    ],
    id: "I like learning. I've been studying Japanese for years now, but it's really hard.",
    author: "RM",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "moral", "obligation", "child", "best", "education", "possible"],
    words: [
      "it",
      "is",
      "our",
      "moral",
      "obligation",
      "to",
      "give",
      "every",
      "child",
      "the",
      "very",
      "best",
      "education",
      "possible",
    ],
    id: "It is our moral obligation to give every child the very best education possible.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "belongs", "living", "lives", "must", "prepared", "changes"],
    words: [
      "life",
      "belongs",
      "to",
      "the",
      "living",
      "and",
      "he",
      "who",
      "lives",
      "must",
      "be",
      "prepared",
      "for",
      "changes",
    ],
    id: "Life belongs to the living, and he who lives must be prepared for changes.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "job", "bring", "out", "wouldn't", "dare", "themselves"],
    words: [
      "my",
      "job",
      "is",
      "to",
      "bring",
      "out",
      "in",
      "people",
      "what",
      "they",
      "wouldn't",
      "dare",
      "do",
      "themselves",
    ],
    id: "My job is to bring out in people what they wouldn't dare do themselves.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["america", "second", "none", "own", "finish", "line", "forget"],
    words: [
      "we",
      "are",
      "america",
      "second",
      "to",
      "none",
      "and",
      "own",
      "the",
      "finish",
      "line",
      "don't",
      "forget",
      "it",
    ],
    id: "We are America, second to none, and we own the finish line. Don't forget it.",
    author: "Joe Biden",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "used", "his", "then", "were", "separated", "graduated"],
    words: [
      "this",
      "man",
      "used",
      "to",
      "go",
      "school",
      "with",
      "his",
      "dog",
      "then",
      "they",
      "were",
      "separated",
      "graduated",
    ],
    id: "This man used to go to school with his dog. Then they were separated. His dog graduated!",
    author: "Henny Youngman",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "thing", "wrong", "immortality", "tends", "on", "forever"],
    words: [
      "the",
      "only",
      "thing",
      "wrong",
      "with",
      "immortality",
      "is",
      "that",
      "it",
      "tends",
      "to",
      "go",
      "on",
      "forever",
    ],
    id: "The only thing wrong with immortality is that it tends to go on forever.",
    author: "Herb Caen",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "look", "around", "i'm", "mess", "why", "things"],
    words: [
      "i",
      "just",
      "look",
      "around",
      "and",
      "say",
      "i'm",
      "a",
      "mess",
      "don't",
      "know",
      "why",
      "do",
      "things",
    ],
    id: "I just look around and say, I'm a mess. I don't know why I do things.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["movies", "only", "business", "out", "front", "applaud", "yourself"],
    words: [
      "the",
      "movies",
      "are",
      "only",
      "business",
      "where",
      "you",
      "can",
      "go",
      "out",
      "front",
      "and",
      "applaud",
      "yourself",
    ],
    id: "The movies are the only business where you can go out front and applaud yourself.",
    author: "Will Rogers",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "saves", "ourselves", "may", "must", "walk", "path"],
    words: [
      "no",
      "one",
      "saves",
      "us",
      "but",
      "ourselves",
      "can",
      "and",
      "may",
      "we",
      "must",
      "walk",
      "the",
      "path",
    ],
    id: "No one saves us but ourselves. No one can and no one may. We ourselves must walk the path.",
    author: "Buddha",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "movies", "great", "stories", "content", "should", "strong"],
    words: [
      "i",
      "want",
      "to",
      "do",
      "movies",
      "with",
      "great",
      "stories",
      "the",
      "content",
      "should",
      "be",
      "strong",
    ],
    id: "I want to do movies with great stories. The content should be strong.",
    author: "Diljit Dosanjh",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["winding", "watch", "his", "wit", "by", "will", "strike"],
    words: [
      "he",
      "is",
      "winding",
      "the",
      "watch",
      "of",
      "his",
      "wit",
      "by",
      "and",
      "it",
      "will",
      "strike",
    ],
    id: "He is winding the watch of his wit; by and by it will strike.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["football", "sacrifice", "dedication", "lot", "friendship", "off", "pitch"],
    words: [
      "football",
      "is",
      "about",
      "sacrifice",
      "dedication",
      "a",
      "lot",
      "of",
      "work",
      "and",
      "friendship",
      "off",
      "the",
      "pitch",
    ],
    id: "Football is about sacrifice, dedication, a lot of work, and friendship off the pitch.",
    author: "Edinson Cavani",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["taught", "lesson", "then", "given", "test", "life", "teaches"],
    words: [
      "in",
      "school",
      "you're",
      "taught",
      "a",
      "lesson",
      "and",
      "then",
      "given",
      "test",
      "life",
      "that",
      "teaches",
      "you",
    ],
    id: "In school, you're taught a lesson and then given a test. In life, you're given a test that teaches you a lesson.",
    author: "Tom Bodett",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["paradox", "man", "compared", "god", "nothing", "yet", "everything"],
    words: [
      "this",
      "is",
      "a",
      "paradox",
      "of",
      "man",
      "compared",
      "to",
      "god",
      "nothing",
      "yet",
      "we",
      "are",
      "everything",
    ],
    id: "This is a paradox of man: compared to God, man is nothing; yet we are everything to God.",
    author: "Dieter F. Uchtdorf",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "always", "his", "best", "those", "leave", "choice"],
    words: [
      "god",
      "always",
      "gives",
      "his",
      "best",
      "to",
      "those",
      "who",
      "leave",
      "the",
      "choice",
      "with",
      "him",
    ],
    id: "God always gives His best to those who leave the choice with him.",
    author: "Jim Elliot",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "always", "experience", "on", "set", "mistake", "make"],
    words: [
      "there",
      "is",
      "always",
      "a",
      "new",
      "experience",
      "on",
      "every",
      "set",
      "mistake",
      "you",
      "make",
      "it",
      "learning",
    ],
    id: "There is always a new experience on every set, a new mistake you make. It is a learning experience.",
    author: "Amyra Dastur",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["celebrate", "success", "key", "component", "how", "win", "market"],
    words: [
      "learning",
      "to",
      "celebrate",
      "success",
      "is",
      "a",
      "key",
      "component",
      "of",
      "how",
      "win",
      "in",
      "the",
      "market",
    ],
    id: "Learning to celebrate success is a key component of learning how to win in the market.",
    author: "Douglas Conant",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["great", "many", "when", "merely", "rearranging", "their", "prejudices"],
    words: [
      "a",
      "great",
      "many",
      "people",
      "think",
      "they",
      "are",
      "thinking",
      "when",
      "merely",
      "rearranging",
      "their",
      "prejudices",
    ],
    id: "A great many people think they are thinking when they are merely rearranging their prejudices.",
    author: "William James",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "faith", "else's", "greatest", "matters", "most", "case"],
    words: [
      "our",
      "faith",
      "is",
      "in",
      "someone",
      "else's",
      "and",
      "the",
      "greatest",
      "matters",
      "this",
      "most",
      "case",
    ],
    id: "Our faith is faith in someone else's faith, and in the greatest matters this is most the case.",
    author: "William James",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["woman", "face", "man", "as", "sailor", "open", "sea"],
    words: [
      "a",
      "woman",
      "knows",
      "the",
      "face",
      "of",
      "man",
      "she",
      "loves",
      "as",
      "sailor",
      "open",
      "sea",
    ],
    id: "A woman knows the face of the man she loves as a sailor knows the open sea.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["idea", "dangerous", "worthy", "being", "called", "at", "all"],
    words: [
      "the",
      "idea",
      "that",
      "is",
      "not",
      "dangerous",
      "worthy",
      "of",
      "being",
      "called",
      "an",
      "at",
      "all",
    ],
    id: "The idea that is not dangerous is not worthy of being called an idea at all.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "born", "cry", "come", "great", "stage", "fools"],
    words: [
      "when",
      "we",
      "are",
      "born",
      "cry",
      "that",
      "come",
      "to",
      "this",
      "great",
      "stage",
      "of",
      "fools",
    ],
    id: "When we are born we cry that we are come to this great stage of fools.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "man", "reason", "trust", "himself", "trusts", "luck"],
    words: [
      "when",
      "a",
      "man",
      "has",
      "no",
      "reason",
      "to",
      "trust",
      "himself",
      "he",
      "trusts",
      "in",
      "luck",
    ],
    id: "When a man has no reason to trust himself, he trusts in luck.",
    author: "E. W. Howe",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["flattery", "kind", "bad", "which", "our", "vanity", "currency"],
    words: [
      "flattery",
      "is",
      "a",
      "kind",
      "of",
      "bad",
      "money",
      "to",
      "which",
      "our",
      "vanity",
      "gives",
      "us",
      "currency",
    ],
    id: "Flattery is a kind of bad money, to which our vanity gives us currency.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["those", "trick", "candles", "blow", "out", "couple", "seconds"],
    words: [
      "now",
      "you",
      "know",
      "those",
      "trick",
      "candles",
      "that",
      "blow",
      "out",
      "and",
      "a",
      "couple",
      "of",
      "seconds",
    ],
    id: "Now you know those trick candles that you blow out and a couple of seconds.",
    author: "Tim Vine",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["accursed", "brings", "light", "writings", "i", "cast", "away"],
    words: [
      "accursed",
      "who",
      "brings",
      "to",
      "light",
      "of",
      "day",
      "the",
      "writings",
      "i",
      "have",
      "cast",
      "away",
    ],
    id: "Accursed who brings to light of day the writings I have cast away.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["invasion", "armies", "resisted", "idea", "whose", "time", "come"],
    words: [
      "an",
      "invasion",
      "of",
      "armies",
      "can",
      "be",
      "resisted",
      "but",
      "not",
      "idea",
      "whose",
      "time",
      "has",
      "come",
    ],
    id: "An invasion of armies can be resisted, but not an idea whose time has come.",
    author: "Victor Hugo",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["wasn't", "baseball", "i'd", "either", "penitentiary", "or", "cemetery"],
    words: [
      "if",
      "it",
      "wasn't",
      "for",
      "baseball",
      "i'd",
      "be",
      "in",
      "either",
      "the",
      "penitentiary",
      "or",
      "cemetery",
    ],
    id: "If it wasn't for baseball, I'd be in either the penitentiary or the cemetery.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["england", "lucky", "country", "fortune", "comes", "only", "hard"],
    words: [
      "england",
      "is",
      "a",
      "lucky",
      "country",
      "for",
      "me",
      "and",
      "fortune",
      "comes",
      "only",
      "with",
      "hard",
      "work",
    ],
    id: "England is a lucky country for me, and fortune comes only with hard work.",
    author: "Oleksandr Usyk",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["absence", "death", "same", "-", "only", "there", "suffering"],
    words: [
      "absence",
      "and",
      "death",
      "are",
      "the",
      "same",
      "-",
      "only",
      "that",
      "in",
      "there",
      "is",
      "no",
      "suffering",
    ],
    id: "Absence and death are the same - only that in death there is no suffering.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["change", "culture", "will", "start", "by", "changing", "organization"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "change",
      "the",
      "culture",
      "will",
      "have",
      "start",
      "by",
      "changing",
      "organization",
    ],
    id: "If you want to change the culture, you will have to start by changing the organization.",
    author: "Mary Douglas",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["revolution", "evaporates", "leaves", "behind", "only", "slime", "bureaucracy"],
    words: [
      "every",
      "revolution",
      "evaporates",
      "and",
      "leaves",
      "behind",
      "only",
      "the",
      "slime",
      "of",
      "a",
      "new",
      "bureaucracy",
    ],
    id: "Every revolution evaporates and leaves behind only the slime of a new bureaucracy.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "reaching", "important", "birthday", "finding", "exactly", "same"],
    words: [
      "success",
      "is",
      "like",
      "reaching",
      "an",
      "important",
      "birthday",
      "and",
      "finding",
      "you're",
      "exactly",
      "the",
      "same",
    ],
    id: "Success is like reaching an important birthday and finding you're exactly the same.",
    author: "Audrey Hepburn",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["employer", "pays", "wages", "employers", "only", "handle", "customer"],
    words: [
      "it",
      "is",
      "not",
      "the",
      "employer",
      "who",
      "pays",
      "wages",
      "employers",
      "only",
      "handle",
      "money",
      "customer",
    ],
    id: "It is not the employer who pays the wages. Employers only handle the money. It is the customer who pays the wages.",
    author: "Henry Ford",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "ambition", "govern", "men", "painful", "thankless", "office"],
    words: [
      "i",
      "have",
      "no",
      "ambition",
      "to",
      "govern",
      "men",
      "it",
      "is",
      "a",
      "painful",
      "and",
      "thankless",
      "office",
    ],
    id: "I have no ambition to govern men; it is a painful and thankless office.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["friendships", "stood", "test", "time", "change", "surely", "best"],
    words: [
      "friendships",
      "that",
      "have",
      "stood",
      "the",
      "test",
      "of",
      "time",
      "and",
      "change",
      "are",
      "surely",
      "best",
    ],
    id: "Friendships that have stood the test of time and change are surely best.",
    author: "Joseph Parry",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["bore", "person", "opens", "his", "mouth", "puts", "feats"],
    words: [
      "a",
      "bore",
      "is",
      "person",
      "who",
      "opens",
      "his",
      "mouth",
      "and",
      "puts",
      "feats",
      "in",
      "it",
    ],
    id: "A bore is a person who opens his mouth and puts his feats in it.",
    author: "Henry Ford",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["sing", "song", "world", "transfigured", "all", "heavens", "rejoicing"],
    words: [
      "sing",
      "me",
      "a",
      "new",
      "song",
      "the",
      "world",
      "is",
      "transfigured",
      "all",
      "heavens",
      "are",
      "rejoicing",
    ],
    id: "Sing me a new song; the world is transfigured; all the Heavens are rejoicing.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["theology", "effort", "explain", "unknowable", "terms", "worth", "knowing"],
    words: [
      "theology",
      "is",
      "the",
      "effort",
      "to",
      "explain",
      "unknowable",
      "in",
      "terms",
      "of",
      "not",
      "worth",
      "knowing",
    ],
    id: "Theology is the effort to explain the unknowable in terms of the not worth knowing.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["perfection", "man", "find", "out", "his", "own", "imperfections"],
    words: [
      "this",
      "is",
      "the",
      "very",
      "perfection",
      "of",
      "a",
      "man",
      "to",
      "find",
      "out",
      "his",
      "own",
      "imperfections",
    ],
    id: "This is the very perfection of a man, to find out his own imperfections.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["unless", "protect", "privacy", "advice", "gets", "cannot", "get"],
    words: [
      "unless",
      "a",
      "president",
      "can",
      "protect",
      "the",
      "privacy",
      "of",
      "advice",
      "he",
      "gets",
      "cannot",
      "get",
      "needs",
    ],
    id: "Unless a president can protect the privacy of the advice he gets, he cannot get the advice he needs.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "frank", "earnest", "women", "fresno", "chicago", "ernest"],
    words: [
      "this",
      "man",
      "is",
      "frank",
      "and",
      "earnest",
      "with",
      "women",
      "in",
      "fresno",
      "he's",
      "chicago",
      "ernest",
    ],
    id: "This man is frank and earnest with women. In Fresno, he's Frank and in Chicago he's Ernest.",
    author: "Henny Youngman",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["complex", "problem", "there", "answer", "clear", "simple", "wrong"],
    words: [
      "for",
      "every",
      "complex",
      "problem",
      "there",
      "is",
      "an",
      "answer",
      "that",
      "clear",
      "simple",
      "and",
      "wrong",
    ],
    id: "For every complex problem there is an answer that is clear, simple, and wrong.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "usually", "comes", "those", "too", "busy", "looking"],
    words: [
      "success",
      "usually",
      "comes",
      "to",
      "those",
      "who",
      "are",
      "too",
      "busy",
      "be",
      "looking",
      "for",
      "it",
    ],
    id: "Success usually comes to those who are too busy to be looking for it.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "saying", "best", "manager", "world", "i'm", "quite"],
    words: [
      "i",
      "am",
      "not",
      "saying",
      "the",
      "best",
      "manager",
      "in",
      "world",
      "but",
      "i'm",
      "quite",
      "good",
    ],
    id: "I am not saying I am the best manager in the world. But I'm quite good.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["takes", "time", "persuade", "men", "even", "their", "own"],
    words: [
      "it",
      "takes",
      "time",
      "to",
      "persuade",
      "men",
      "do",
      "even",
      "what",
      "is",
      "for",
      "their",
      "own",
      "good",
    ],
    id: "It takes time to persuade men to do even what is for their own good.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["accustomed", "disguise", "ourselves", "others", "end", "become", "disguised"],
    words: [
      "we",
      "are",
      "so",
      "accustomed",
      "to",
      "disguise",
      "ourselves",
      "others",
      "that",
      "in",
      "the",
      "end",
      "become",
      "disguised",
    ],
    id: "We are so accustomed to disguise ourselves to others that in the end we become disguised to ourselves.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "psychiatrist", "because", "believe", "mentally", "disturbed", "way"],
    words: [
      "i",
      "think",
      "need",
      "a",
      "psychiatrist",
      "because",
      "do",
      "believe",
      "am",
      "mentally",
      "disturbed",
      "in",
      "some",
      "way",
    ],
    id: "I think I need a psychiatrist because I do believe I am mentally disturbed in some way.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["cynic", "man", "when", "smells", "looks", "around", "coffin"],
    words: [
      "a",
      "cynic",
      "is",
      "man",
      "who",
      "when",
      "he",
      "smells",
      "flowers",
      "looks",
      "around",
      "for",
      "coffin",
    ],
    id: "A cynic is a man who, when he smells flowers, looks around for a coffin.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["may", "everywhere", "as", "opportunity", "results", "will", "wanting"],
    words: [
      "may",
      "we",
      "do",
      "good",
      "everywhere",
      "as",
      "have",
      "opportunity",
      "and",
      "results",
      "will",
      "not",
      "be",
      "wanting",
    ],
    id: "May we do good everywhere as we have opportunity, and results will not be wanting!",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["reading", "equivalent", "else's", "head", "instead", "one's", "own"],
    words: [
      "reading",
      "is",
      "equivalent",
      "to",
      "thinking",
      "with",
      "someone",
      "else's",
      "head",
      "instead",
      "of",
      "one's",
      "own",
    ],
    id: "Reading is equivalent to thinking with someone else's head instead of with one's own.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["many", "stubborn", "pursuit", "path", "chosen", "few", "goal"],
    words: [
      "many",
      "are",
      "stubborn",
      "in",
      "pursuit",
      "of",
      "the",
      "path",
      "they",
      "have",
      "chosen",
      "few",
      "goal",
    ],
    id: "Many are stubborn in pursuit of the path they have chosen, few in pursuit of the goal.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "didn't", "make", "baseball", "won't", "made", "workin"],
    words: [
      "if",
      "i",
      "didn't",
      "make",
      "it",
      "in",
      "baseball",
      "won't",
      "have",
      "made",
      "workin",
      "like",
      "to",
      "work",
    ],
    id: "If I didn't make it in baseball, I won't have made it workin'. I didn't like to work.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "only", "change", "things", "situation", "i'd", "never"],
    words: [
      "i",
      "only",
      "change",
      "things",
      "where",
      "know",
      "about",
      "a",
      "situation",
      "i'd",
      "never",
      "just",
      "for",
    ],
    id: "I only change things where I know about a situation. I'd never change just for change.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["argument", "similes", "songs", "describe", "much", "prove", "nothing"],
    words: [
      "in",
      "argument",
      "similes",
      "are",
      "like",
      "songs",
      "love",
      "they",
      "describe",
      "much",
      "but",
      "prove",
      "nothing",
    ],
    id: "In argument similes are like songs in love; they describe much, but prove nothing.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["filipino", "embraces", "civilization", "lives", "thrives", "clime", "contact"],
    words: [
      "the",
      "filipino",
      "embraces",
      "civilization",
      "and",
      "lives",
      "thrives",
      "in",
      "every",
      "clime",
      "contact",
      "with",
      "people",
    ],
    id: "The Filipino embraces civilization and lives and thrives in every clime, in contact with every people.",
    author: "Jose Rizal",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "possible", "sense", "tragedy", "without", "having", "humor"],
    words: [
      "i",
      "don't",
      "think",
      "it's",
      "possible",
      "to",
      "have",
      "a",
      "sense",
      "of",
      "tragedy",
      "without",
      "having",
      "humor",
    ],
    id: "I don't think it's possible to have a sense of tragedy without having a sense of humor.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["basic", "problems", "facing", "world", "susceptible", "military", "solution"],
    words: [
      "the",
      "basic",
      "problems",
      "facing",
      "world",
      "today",
      "are",
      "not",
      "susceptible",
      "to",
      "a",
      "military",
      "solution",
    ],
    id: "The basic problems facing the world today are not susceptible to a military solution.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["how", "i", "feel", "sometimes", "around", "nobody", "alone"],
    words: [
      "that's",
      "how",
      "i",
      "feel",
      "sometimes",
      "don't",
      "want",
      "to",
      "be",
      "around",
      "nobody",
      "just",
      "alone",
    ],
    id: "That's how I feel sometimes. I don't want to be around nobody. I just want to be alone.",
    author: "Rod Wave",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "ahead", "father", "time", "scythe", "my", "own"],
    words: [
      "i",
      "want",
      "to",
      "go",
      "ahead",
      "of",
      "father",
      "time",
      "with",
      "a",
      "scythe",
      "my",
      "own",
    ],
    id: "I want to go ahead of Father Time with a scythe of my own.",
    author: "H. G. Wells",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["says", "nothing", "against", "ripeness", "spirit", "few", "worms"],
    words: [
      "it",
      "says",
      "nothing",
      "against",
      "the",
      "ripeness",
      "of",
      "a",
      "spirit",
      "that",
      "has",
      "few",
      "worms",
    ],
    id: "It says nothing against the ripeness of a spirit that it has a few worms.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "ambition", "ten", "sentences", "others", "whole", "book"],
    words: [
      "it",
      "is",
      "my",
      "ambition",
      "to",
      "say",
      "in",
      "ten",
      "sentences",
      "what",
      "others",
      "a",
      "whole",
      "book",
    ],
    id: "It is my ambition to say in ten sentences what others say in a whole book.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["come", "out", "park", "nobody's", "gonna", "stop", "em"],
    words: [
      "if",
      "people",
      "don't",
      "want",
      "to",
      "come",
      "out",
      "the",
      "ball",
      "park",
      "nobody's",
      "gonna",
      "stop",
      "em",
    ],
    id: "If people don't want to come out to the ball park, nobody's gonna stop 'em.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["true", "happiness", "consists", "multitude", "friends", "worth", "choice"],
    words: [
      "true",
      "happiness",
      "consists",
      "not",
      "in",
      "the",
      "multitude",
      "of",
      "friends",
      "but",
      "worth",
      "and",
      "choice",
    ],
    id: "True happiness consists not in the multitude of friends, but in the worth and choice.",
    author: "Ben Jonson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "tattoos", "horrible", "living", "pucci", "dress", "full-time"],
    words: [
      "i",
      "think",
      "tattoos",
      "are",
      "horrible",
      "it's",
      "like",
      "living",
      "in",
      "a",
      "pucci",
      "dress",
      "full-time",
    ],
    id: "I think tattoos are horrible. It's like living in a Pucci dress full-time.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "make", "policy", "statement", "unabashedly", "favor", "women"],
    words: [
      "i",
      "want",
      "to",
      "make",
      "a",
      "policy",
      "statement",
      "am",
      "unabashedly",
      "in",
      "favor",
      "of",
      "women",
    ],
    id: "I want to make a policy statement. I am unabashedly in favor of women.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["wearing", "correct", "dress", "any", "occasion", "matter", "manners"],
    words: [
      "wearing",
      "the",
      "correct",
      "dress",
      "for",
      "any",
      "occasion",
      "is",
      "a",
      "matter",
      "of",
      "good",
      "manners",
    ],
    id: "Wearing the correct dress for any occasion is a matter of good manners.",
    author: "Loretta Young",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["through", "hell", "i", "suggest", "come", "back", "something"],
    words: [
      "if",
      "you're",
      "going",
      "to",
      "go",
      "through",
      "hell",
      "i",
      "suggest",
      "you",
      "come",
      "back",
      "learning",
      "something",
    ],
    id: "If you're going to go through hell... I suggest you come back learning something.",
    author: "Drew Barrymore",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["happiness", "only", "found", "free", "yourself", "all", "distractions"],
    words: [
      "happiness",
      "can",
      "only",
      "be",
      "found",
      "if",
      "you",
      "free",
      "yourself",
      "of",
      "all",
      "other",
      "distractions",
    ],
    id: "Happiness can only be found if you can free yourself of all other distractions.",
    author: "Saul Bellow",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "oftener", "wrong", "than", "those", "least", "bear"],
    words: [
      "no",
      "men",
      "are",
      "oftener",
      "wrong",
      "than",
      "those",
      "that",
      "can",
      "least",
      "bear",
      "to",
      "be",
      "so",
    ],
    id: "No men are oftener wrong than those that can least bear to be so.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["always", "welfare", "development", "governance", "as", "long", "delivering"],
    words: [
      "people",
      "always",
      "want",
      "welfare",
      "development",
      "and",
      "good",
      "governance",
      "as",
      "long",
      "you",
      "are",
      "delivering",
      "with",
    ],
    id: "People always want welfare, development, and good governance. As long as you are delivering, people are with you.",
    author: "N. Chandrababu Naidu",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["achieve", "great", "success", "proof", "all", "others", "as"],
    words: [
      "that",
      "some",
      "achieve",
      "great",
      "success",
      "is",
      "proof",
      "to",
      "all",
      "others",
      "can",
      "it",
      "as",
      "well",
    ],
    id: "That some achieve great success, is proof to all that others can achieve it as well.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["which", "fear", "as", "our", "last", "birthday", "eternity"],
    words: [
      "the",
      "day",
      "which",
      "we",
      "fear",
      "as",
      "our",
      "last",
      "is",
      "but",
      "birthday",
      "of",
      "eternity",
    ],
    id: "The day which we fear as our last is but the birthday of eternity.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["most", "useful", "piece", "uses", "life", "unlearn", "untrue"],
    words: [
      "the",
      "most",
      "useful",
      "piece",
      "of",
      "learning",
      "for",
      "uses",
      "life",
      "is",
      "to",
      "unlearn",
      "what",
      "untrue",
    ],
    id: "The most useful piece of learning for the uses of life is to unlearn what is untrue.",
    author: "Antisthenes",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "thousand", "ways", "kneel", "kiss", "ground", "home"],
    words: [
      "there",
      "are",
      "a",
      "thousand",
      "ways",
      "to",
      "kneel",
      "and",
      "kiss",
      "the",
      "ground",
      "go",
      "home",
      "again",
    ],
    id: "There are a thousand ways to kneel and kiss the ground; there are a thousand ways to go home again.",
    author: "Rumi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "glamour", "being", "sexy", "dressing", "up", "confident"],
    words: [
      "i",
      "love",
      "glamour",
      "and",
      "being",
      "sexy",
      "dressing",
      "up",
      "think",
      "it's",
      "good",
      "to",
      "be",
      "confident",
    ],
    id: "I love glamour and being sexy and dressing up, and I think it's good to be confident.",
    author: "Kelly Brook",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["must", "find", "our", "duties", "comes", "might", "been"],
    words: [
      "we",
      "must",
      "find",
      "our",
      "duties",
      "in",
      "what",
      "comes",
      "to",
      "us",
      "not",
      "might",
      "have",
      "been",
    ],
    id: "We must find our duties in what comes to us, not in what might have been.",
    author: "George Eliot",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["even", "valley", "shadow", "death", "two", "make", "six"],
    words: [
      "even",
      "in",
      "the",
      "valley",
      "of",
      "shadow",
      "death",
      "two",
      "and",
      "do",
      "not",
      "make",
      "six",
    ],
    id: "Even in the valley of the shadow of death, two and two do not make six.",
    author: "Leo Tolstoy",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["woman", "will", "doubt", "everything", "except", "compliments", "herself"],
    words: [
      "a",
      "woman",
      "will",
      "doubt",
      "everything",
      "you",
      "say",
      "except",
      "it",
      "be",
      "compliments",
      "to",
      "herself",
    ],
    id: "A woman will doubt everything you say except it be compliments to herself.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["exaggeration", "undecideds", "could", "one", "way", "or", "another"],
    words: [
      "it's",
      "no",
      "exaggeration",
      "to",
      "say",
      "that",
      "the",
      "undecideds",
      "could",
      "go",
      "one",
      "way",
      "or",
      "another",
    ],
    id: "It's no exaggeration to say that the undecideds could go one way or another.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["greatest", "follies", "sacrifice", "health", "any", "kind", "happiness"],
    words: [
      "the",
      "greatest",
      "of",
      "follies",
      "is",
      "to",
      "sacrifice",
      "health",
      "for",
      "any",
      "other",
      "kind",
      "happiness",
    ],
    id: "The greatest of follies is to sacrifice health for any other kind of happiness.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["wisdom", "quality", "keeps", "from", "getting", "into", "situations"],
    words: [
      "wisdom",
      "is",
      "the",
      "quality",
      "that",
      "keeps",
      "you",
      "from",
      "getting",
      "into",
      "situations",
      "where",
      "need",
      "it",
    ],
    id: "Wisdom is the quality that keeps you from getting into situations where you need it.",
    author: "Doug Larson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["goose", "lays", "golden", "eggs", "lay", "there", "already"],
    words: [
      "the",
      "goose",
      "that",
      "lays",
      "golden",
      "eggs",
      "likes",
      "to",
      "lay",
      "where",
      "there",
      "are",
      "already",
    ],
    id: "The goose that lays the golden eggs likes to lay where there are eggs already.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["something", "change", "world", "without", "young", "will", "future"],
    words: [
      "something",
      "has",
      "to",
      "change",
      "in",
      "the",
      "world",
      "without",
      "young",
      "people",
      "will",
      "have",
      "no",
      "future",
    ],
    id: "Something has to change in the world. Without change, young people will have no future.",
    author: "Donatella Versace",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "peoples", "cry", "straighten", "out", "your", "soul"],
    words: [
      "when",
      "peoples",
      "care",
      "for",
      "you",
      "and",
      "cry",
      "they",
      "can",
      "straighten",
      "out",
      "your",
      "soul",
    ],
    id: "When peoples care for you and cry for you, they can straighten out your soul.",
    author: "Langston Hughes",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "jail", "got", "wrong", "kind", "bars", "there"],
    words: [
      "i",
      "don't",
      "like",
      "jail",
      "they",
      "got",
      "the",
      "wrong",
      "kind",
      "of",
      "bars",
      "in",
      "there",
    ],
    id: "I don't like jail, they got the wrong kind of bars in there.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["boot", "camp", "sucks", "-", "seal", "training", "makes"],
    words: [
      "boot",
      "camp",
      "sucks",
      "-",
      "seal",
      "training",
      "but",
      "you",
      "know",
      "what",
      "that's",
      "makes",
      "good",
    ],
    id: "Boot camp sucks - SEAL training sucks - but you know what? That's what makes you good.",
    author: "David Goggins",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["bad", "officials", "ones", "elected", "by", "citizens", "vote"],
    words: [
      "bad",
      "officials",
      "are",
      "the",
      "ones",
      "elected",
      "by",
      "good",
      "citizens",
      "who",
      "do",
      "not",
      "vote",
    ],
    id: "Bad officials are the ones elected by good citizens who do not vote.",
    author: "George Jean Nathan",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "bought", "instant", "one", "time", "didn't", "add"],
    words: [
      "i",
      "bought",
      "some",
      "instant",
      "water",
      "one",
      "time",
      "but",
      "didn't",
      "know",
      "what",
      "to",
      "add",
      "it",
    ],
    id: "I bought some instant water one time but I didn't know what to add to it.",
    author: "Steven Wright",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["humor", "laughing", "at", "haven't", "got", "when", "ought"],
    words: [
      "humor",
      "is",
      "laughing",
      "at",
      "what",
      "you",
      "haven't",
      "got",
      "when",
      "ought",
      "to",
      "have",
      "it",
    ],
    id: "Humor is laughing at what you haven't got when you ought to have it.",
    author: "Langston Hughes",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["find", "all", "around", "showcase", "you'll", "start", "believing"],
    words: [
      "find",
      "the",
      "good",
      "it's",
      "all",
      "around",
      "you",
      "it",
      "showcase",
      "and",
      "you'll",
      "start",
      "believing",
      "in",
    ],
    id: "Find the good. It's all around you. Find it, showcase it and you'll start believing in it.",
    author: "Jesse Owens",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["niceties", "make", "difference", "fate", "hand", "play", "cards"],
    words: [
      "it's",
      "the",
      "niceties",
      "that",
      "make",
      "difference",
      "fate",
      "gives",
      "us",
      "hand",
      "and",
      "we",
      "play",
      "cards",
    ],
    id: "It's the niceties that make the difference fate gives us the hand, and we play the cards.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "most", "would", "prefer", "popular", "than", "unpopular"],
    words: [
      "i",
      "think",
      "that",
      "most",
      "of",
      "us",
      "would",
      "prefer",
      "to",
      "be",
      "popular",
      "than",
      "unpopular",
    ],
    id: "I think that most of us would prefer to be popular than unpopular.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["been", "my", "experience", "folks", "vices", "few", "virtues"],
    words: [
      "it",
      "has",
      "been",
      "my",
      "experience",
      "that",
      "folks",
      "who",
      "have",
      "no",
      "vices",
      "very",
      "few",
      "virtues",
    ],
    id: "It has been my experience that folks who have no vices have very few virtues.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["appeaser", "one", "feeds", "crocodile", "hoping", "will", "last"],
    words: [
      "an",
      "appeaser",
      "is",
      "one",
      "who",
      "feeds",
      "a",
      "crocodile",
      "hoping",
      "it",
      "will",
      "eat",
      "him",
      "last",
    ],
    id: "An appeaser is one who feeds a crocodile, hoping it will eat him last.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["nature", "face", "at", "twenty", "up", "merit", "fifty"],
    words: [
      "nature",
      "gives",
      "you",
      "the",
      "face",
      "have",
      "at",
      "twenty",
      "it",
      "is",
      "up",
      "to",
      "merit",
      "fifty",
    ],
    id: "Nature gives you the face you have at twenty; it is up to you to merit the face you have at fifty.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["carry", "mother", "earth", "within", "outside", "your", "environment"],
    words: [
      "you",
      "carry",
      "mother",
      "earth",
      "within",
      "she",
      "is",
      "not",
      "outside",
      "of",
      "just",
      "your",
      "environment",
    ],
    id: "You carry Mother Earth within you. She is not outside of you. Mother Earth is not just your environment.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "thinks", "himself", "really", "determines", "his", "fate"],
    words: [
      "it",
      "is",
      "what",
      "a",
      "man",
      "thinks",
      "of",
      "himself",
      "that",
      "really",
      "determines",
      "his",
      "fate",
    ],
    id: "It is what a man thinks of himself that really determines his fate.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "wisest", "man", "alive", "one", "thing", "nothing"],
    words: [
      "i",
      "am",
      "the",
      "wisest",
      "man",
      "alive",
      "for",
      "know",
      "one",
      "thing",
      "and",
      "that",
      "is",
      "nothing",
    ],
    id: "I am the wisest man alive, for I know one thing, and that is that I know nothing.",
    author: "Socrates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "our", "dreams", "come", "true", "courage", "pursue"],
    words: [
      "all",
      "our",
      "dreams",
      "can",
      "come",
      "true",
      "if",
      "we",
      "have",
      "the",
      "courage",
      "to",
      "pursue",
      "them",
    ],
    id: "All our dreams can come true, if we have the courage to pursue them.",
    author: "Walt Disney",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["men", "disturbed", "by", "things", "view", "which", "take"],
    words: [
      "men",
      "are",
      "disturbed",
      "not",
      "by",
      "things",
      "but",
      "the",
      "view",
      "which",
      "they",
      "take",
      "of",
      "them",
    ],
    id: "Men are disturbed not by things, but by the view which they take of them.",
    author: "Epictetus",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["may", "thrill", "known", "start", "friendship", "even", "up"],
    words: [
      "to",
      "some",
      "it",
      "may",
      "be",
      "a",
      "thrill",
      "known",
      "me",
      "it's",
      "start",
      "friendship",
      "even",
      "up",
    ],
    id: "To some it may be a thrill to be known, to me it's a thrill to start a friendship even up.",
    author: "Christopher Knight",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["realize", "your", "thoughts", "when", "begin", "awaken", "spiritually"],
    words: [
      "to",
      "realize",
      "that",
      "you",
      "are",
      "not",
      "your",
      "thoughts",
      "is",
      "when",
      "begin",
      "awaken",
      "spiritually",
    ],
    id: "To realize that you are not your thoughts is when you begin to awaken spiritually.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["poetry", "deal", "joy", "pain", "wonder", "dash", "dictionary"],
    words: [
      "poetry",
      "is",
      "a",
      "deal",
      "of",
      "joy",
      "and",
      "pain",
      "wonder",
      "with",
      "dash",
      "the",
      "dictionary",
    ],
    id: "Poetry is a deal of joy and pain and wonder, with a dash of the dictionary.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "always", "cling", "things", "remind", "being", "kid"],
    words: [
      "i",
      "always",
      "cling",
      "to",
      "things",
      "that",
      "remind",
      "me",
      "of",
      "being",
      "a",
      "kid",
      "again",
    ],
    id: "I always cling to things that remind me of being a kid again.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["rich", "man's", "there", "place", "spit", "his", "face"],
    words: [
      "in",
      "a",
      "rich",
      "man's",
      "house",
      "there",
      "is",
      "no",
      "place",
      "to",
      "spit",
      "but",
      "his",
      "face",
    ],
    id: "In a rich man's house there is no place to spit but his face.",
    author: "Diogenes",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["world", "book", "those", "travel", "read", "only", "page"],
    words: [
      "the",
      "world",
      "is",
      "a",
      "book",
      "and",
      "those",
      "who",
      "do",
      "not",
      "travel",
      "read",
      "only",
      "page",
    ],
    id: "The world is a book, and those who do not travel read only a page.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "planning", "posterity", "ought", "remember", "virtue", "hereditary"],
    words: [
      "when",
      "we",
      "are",
      "planning",
      "for",
      "posterity",
      "ought",
      "to",
      "remember",
      "that",
      "virtue",
      "is",
      "not",
      "hereditary",
    ],
    id: "When we are planning for posterity, we ought to remember that virtue is not hereditary.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["how", "important", "recognize", "celebrate", "our", "heroes", "she-roes"],
    words: [
      "how",
      "important",
      "it",
      "is",
      "for",
      "us",
      "to",
      "recognize",
      "and",
      "celebrate",
      "our",
      "heroes",
      "she-roes",
    ],
    id: "How important it is for us to recognize and celebrate our heroes and she-roes!",
    author: "Maya Angelou",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["laughter", "bad", "beginning", "friendship", "best", "ending", "one"],
    words: [
      "laughter",
      "is",
      "not",
      "a",
      "bad",
      "beginning",
      "for",
      "friendship",
      "and",
      "it",
      "the",
      "best",
      "ending",
      "one",
    ],
    id: "Laughter is not a bad beginning for a friendship, and it is the best ending for one.",
    author: "Henry Ward Beecher",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["seek", "because", "-", "there", "reason", "explanation", "solutions"],
    words: [
      "do",
      "not",
      "seek",
      "the",
      "because",
      "-",
      "in",
      "love",
      "there",
      "is",
      "no",
      "reason",
      "explanation",
      "solutions",
    ],
    id: "Do not seek the because - in love there is no because, no reason, no explanation, no solutions.",
    author: "Anais Nin",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["sinner", "sin", "strongly", "more", "faith", "rejoice", "christ"],
    words: [
      "be",
      "a",
      "sinner",
      "and",
      "sin",
      "strongly",
      "but",
      "more",
      "have",
      "faith",
      "rejoice",
      "in",
      "christ",
    ],
    id: "Be a sinner and sin strongly, but more strongly have faith and rejoice in Christ.",
    author: "Martin Luther",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["women", "teabags", "our", "true", "strength", "until", "hot"],
    words: [
      "women",
      "are",
      "like",
      "teabags",
      "we",
      "don't",
      "know",
      "our",
      "true",
      "strength",
      "until",
      "in",
      "hot",
      "water",
    ],
    id: "Women are like teabags. We don't know our true strength until we are in hot water!",
    author: "Unknown",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "healthy", "body", "free", "chemicals", "once", "controlled"],
    words: [
      "i",
      "have",
      "a",
      "healthy",
      "body",
      "free",
      "of",
      "the",
      "chemicals",
      "that",
      "once",
      "controlled",
      "it",
    ],
    id: "I have a healthy body, free of the chemicals that once controlled it.",
    author: "Lorna Luft",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "would", "married", "i'd", "wife", "single", "life"],
    words: [
      "i",
      "would",
      "be",
      "married",
      "but",
      "i'd",
      "have",
      "no",
      "wife",
      "to",
      "a",
      "single",
      "life",
    ],
    id: "I would be married, but I'd have no wife, I would be married to a single life.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["quality", "number", "days", "or", "events", "actors", "imports"],
    words: [
      "it",
      "is",
      "the",
      "quality",
      "of",
      "moment",
      "not",
      "number",
      "days",
      "or",
      "events",
      "actors",
      "that",
      "imports",
    ],
    id: "It is the quality of the moment, not the number of days, or events, or of actors, that imports.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["highest", "form", "ignorance", "when", "reject", "something", "anything"],
    words: [
      "the",
      "highest",
      "form",
      "of",
      "ignorance",
      "is",
      "when",
      "you",
      "reject",
      "something",
      "don't",
      "know",
      "anything",
      "about",
    ],
    id: "The highest form of ignorance is when you reject something you don't know anything about.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["scarcely", "one", "person", "thousand", "capable", "happiness", "others"],
    words: [
      "scarcely",
      "one",
      "person",
      "in",
      "a",
      "thousand",
      "is",
      "capable",
      "of",
      "tasting",
      "the",
      "happiness",
      "others",
    ],
    id: "Scarcely one person in a thousand is capable of tasting the happiness of others.",
    author: "Henry Fielding",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["mother", "name", "god", "lips", "hearts", "little", "children"],
    words: [
      "mother",
      "is",
      "the",
      "name",
      "for",
      "god",
      "in",
      "lips",
      "and",
      "hearts",
      "of",
      "little",
      "children",
    ],
    id: "Mother is the name for God in the lips and hearts of little children.",
    author: "William Makepeace Thackeray",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "i",
      "forgiveness",
      "overrated",
      "personally",
      "because",
      "things",
      "unforgivable",
    ],
    words: [
      "i",
      "think",
      "forgiveness",
      "is",
      "overrated",
      "personally",
      "because",
      "some",
      "things",
      "people",
      "do",
      "are",
      "unforgivable",
    ],
    id: "I think forgiveness is overrated, personally, because some things people do are unforgivable.",
    author: "Charlamagne tha God",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "afraid", "storms", "how", "sail", "my", "ship"],
    words: [
      "i",
      "am",
      "not",
      "afraid",
      "of",
      "storms",
      "for",
      "learning",
      "how",
      "to",
      "sail",
      "my",
      "ship",
    ],
    id: "I am not afraid of storms for I am learning how to sail my ship.",
    author: "Louisa May Alcott",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["longer", "man's", "fame", "likely", "last", "will", "coming"],
    words: [
      "the",
      "longer",
      "a",
      "man's",
      "fame",
      "is",
      "likely",
      "to",
      "last",
      "it",
      "will",
      "be",
      "in",
      "coming",
    ],
    id: "The longer a man's fame is likely to last, the longer it will be in coming.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["partition", "bad", "whatever", "past", "only", "look", "future"],
    words: [
      "partition",
      "is",
      "bad",
      "but",
      "whatever",
      "past",
      "we",
      "have",
      "only",
      "to",
      "look",
      "the",
      "future",
    ],
    id: "Partition is bad. But whatever is past is past. We have only to look to the future.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["glory", "lies", "attempt", "reach", "one's", "goal", "reaching"],
    words: [
      "glory",
      "lies",
      "in",
      "the",
      "attempt",
      "to",
      "reach",
      "one's",
      "goal",
      "and",
      "not",
      "reaching",
      "it",
    ],
    id: "Glory lies in the attempt to reach one's goal and not in reaching it.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "wig", "looks", "one", "could", "pass", "weave"],
    words: [
      "i",
      "don't",
      "want",
      "a",
      "wig",
      "that",
      "looks",
      "like",
      "one",
      "could",
      "pass",
      "for",
      "weave",
    ],
    id: "I don't want a wig that looks like a wig; I want one that could pass for a weave.",
    author: "Nicki Minaj",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["intervention", "only", "when", "concerned", "seem", "keen", "peace"],
    words: [
      "intervention",
      "only",
      "works",
      "when",
      "the",
      "people",
      "concerned",
      "seem",
      "to",
      "be",
      "keen",
      "for",
      "peace",
    ],
    id: "Intervention only works when the people concerned seem to be keen for peace.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["remember", "can't", "ladder", "success", "your", "hands", "pockets"],
    words: [
      "just",
      "remember",
      "you",
      "can't",
      "climb",
      "the",
      "ladder",
      "of",
      "success",
      "with",
      "your",
      "hands",
      "in",
      "pockets",
    ],
    id: "Just remember, you can't climb the ladder of success with your hands in your pockets.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["wisdom", "consists", "much", "knowing", "ultimate", "as", "next"],
    words: [
      "wisdom",
      "consists",
      "not",
      "so",
      "much",
      "in",
      "knowing",
      "what",
      "to",
      "do",
      "the",
      "ultimate",
      "as",
      "next",
    ],
    id: "Wisdom consists not so much in knowing what to do in the ultimate as knowing what to do next.",
    author: "Herbert Hoover",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "i", "ask", "chance", "prove", "can't", "make"],
    words: [
      "all",
      "i",
      "ask",
      "is",
      "the",
      "chance",
      "to",
      "prove",
      "that",
      "money",
      "can't",
      "make",
      "me",
      "happy",
    ],
    id: "All I ask is the chance to prove that money can't make me happy.",
    author: "Spike Milligan",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["sweden", "green", "paradise", "one", "biggest", "carbon", "footprints"],
    words: [
      "sweden",
      "is",
      "not",
      "a",
      "green",
      "paradise",
      "it",
      "has",
      "one",
      "of",
      "the",
      "biggest",
      "carbon",
      "footprints",
    ],
    id: "Sweden is not a green paradise, it has one of the biggest carbon footprints.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["thou", "must", "naught", "except", "love's", "sake", "only"],
    words: [
      "if",
      "thou",
      "must",
      "love",
      "me",
      "let",
      "it",
      "be",
      "for",
      "naught",
      "except",
      "love's",
      "sake",
      "only",
    ],
    id: "If thou must love me, let it be for naught except for love's sake only.",
    author: "Elizabeth Barrett Browning",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["our", "fatigue", "often", "caused", "by", "frustration", "resentment"],
    words: [
      "our",
      "fatigue",
      "is",
      "often",
      "caused",
      "not",
      "by",
      "work",
      "but",
      "worry",
      "frustration",
      "and",
      "resentment",
    ],
    id: "Our fatigue is often caused not by work, but by worry, frustration and resentment.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["scientist", "befuddled", "by", "looking", "at", "then", "research"],
    words: [
      "if",
      "a",
      "scientist",
      "is",
      "not",
      "befuddled",
      "by",
      "what",
      "they're",
      "looking",
      "at",
      "then",
      "research",
    ],
    id: "If a scientist is not befuddled by what they're looking at, then they're not a research scientist.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "learner", "always", "push", "myself", "farthest", "limits"],
    words: [
      "i",
      "am",
      "a",
      "learner",
      "and",
      "always",
      "learning",
      "want",
      "to",
      "push",
      "myself",
      "the",
      "farthest",
      "limits",
    ],
    id: "I am a learner and always learning. I want to push myself to the farthest limits.",
    author: "Payal Rohatgi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["joy", "prayer", "strength", "net", "by", "which", "souls"],
    words: [
      "joy",
      "is",
      "prayer",
      "strength",
      "love",
      "a",
      "net",
      "of",
      "by",
      "which",
      "you",
      "can",
      "catch",
      "souls",
    ],
    id: "Joy is prayer; joy is strength: joy is love; joy is a net of love by which you can catch souls.",
    author: "Mother Teresa",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["even", "death", "feared", "by", "one", "lived", "wisely"],
    words: [
      "even",
      "death",
      "is",
      "not",
      "to",
      "be",
      "feared",
      "by",
      "one",
      "who",
      "has",
      "lived",
      "wisely",
    ],
    id: "Even death is not to be feared by one who has lived wisely.",
    author: "Buddha",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["drive", "down", "cost", "transportation", "space", "great", "things"],
    words: [
      "if",
      "we",
      "drive",
      "down",
      "the",
      "cost",
      "of",
      "transportation",
      "in",
      "space",
      "can",
      "do",
      "great",
      "things",
    ],
    id: "If we drive down the cost of transportation in space, we can do great things.",
    author: "Elon Musk",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["part", "our", "pop", "culture", "human", "personalities", "talents"],
    words: [
      "it's",
      "part",
      "of",
      "our",
      "pop",
      "culture",
      "to",
      "give",
      "animals",
      "human",
      "personalities",
      "and",
      "talents",
    ],
    id: "It's part of our pop culture to give animals human personalities and talents.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["my", "mother", "great", "source", "advice", "wisdom", "consolation"],
    words: [
      "my",
      "mother",
      "is",
      "a",
      "great",
      "source",
      "of",
      "advice",
      "and",
      "wisdom",
      "consolation",
      "for",
      "me",
    ],
    id: "My mother is a great source of advice and wisdom and consolation for me.",
    author: "Katherine Heigl",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["everyone", "enjoys", "doing", "kind", "which", "best", "suited"],
    words: [
      "everyone",
      "enjoys",
      "doing",
      "the",
      "kind",
      "of",
      "work",
      "for",
      "which",
      "he",
      "is",
      "best",
      "suited",
    ],
    id: "Everyone enjoys doing the kind of work for which he is best suited.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "world", "live", "up", "your", "expectations", "mine"],
    words: [
      "i'm",
      "not",
      "in",
      "this",
      "world",
      "to",
      "live",
      "up",
      "your",
      "expectations",
      "and",
      "you're",
      "mine",
    ],
    id: "I'm not in this world to live up to your expectations and you're not in this world to live up to mine.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["success", "sum", "small", "efforts", "-", "repeated", "out"],
    words: [
      "success",
      "is",
      "the",
      "sum",
      "of",
      "small",
      "efforts",
      "-",
      "repeated",
      "day",
      "in",
      "and",
      "out",
    ],
    id: "Success is the sum of small efforts - repeated day in and day out.",
    author: "Robert Collier",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "machine", "fifty", "ordinary", "men", "extraordinary", "man"],
    words: [
      "one",
      "machine",
      "can",
      "do",
      "the",
      "work",
      "of",
      "fifty",
      "ordinary",
      "men",
      "no",
      "extraordinary",
      "man",
    ],
    id: "One machine can do the work of fifty ordinary men. No machine can do the work of one extraordinary man.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["friendship", "will", "stand", "strain", "much", "advice", "long"],
    words: [
      "friendship",
      "will",
      "not",
      "stand",
      "the",
      "strain",
      "of",
      "very",
      "much",
      "good",
      "advice",
      "for",
      "long",
    ],
    id: "Friendship will not stand the strain of very much good advice for very long.",
    author: "Robert Staughton Lynd",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["only", "thing", "necessary", "triumph", "evil", "men", "nothing"],
    words: [
      "the",
      "only",
      "thing",
      "necessary",
      "for",
      "triumph",
      "of",
      "evil",
      "is",
      "good",
      "men",
      "to",
      "do",
      "nothing",
    ],
    id: "The only thing necessary for the triumph of evil is for good men to do nothing.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["share", "your", "smile", "world", "symbol", "friendship", "peace"],
    words: [
      "share",
      "your",
      "smile",
      "with",
      "the",
      "world",
      "it's",
      "a",
      "symbol",
      "of",
      "friendship",
      "and",
      "peace",
    ],
    id: "Share your smile with the world. It's a symbol of friendship and peace.",
    author: "Christie Brinkley",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "understand", "person", "tends", "regard", "as", "fool"],
    words: [
      "if",
      "one",
      "does",
      "not",
      "understand",
      "a",
      "person",
      "tends",
      "to",
      "regard",
      "him",
      "as",
      "fool",
    ],
    id: "If one does not understand a person, one tends to regard him as a fool.",
    author: "Carl Jung",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["human", "artist", "dream", "your", "life", "make", "art"],
    words: [
      "every",
      "human",
      "is",
      "an",
      "artist",
      "the",
      "dream",
      "of",
      "your",
      "life",
      "to",
      "make",
      "beautiful",
      "art",
    ],
    id: "Every human is an artist. The dream of your life is to make beautiful art.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["continually", "share", "sum", "all", "knowledge", "meaning", "art"],
    words: [
      "to",
      "help",
      "continually",
      "and",
      "share",
      "that",
      "is",
      "the",
      "sum",
      "of",
      "all",
      "knowledge",
      "meaning",
      "art",
    ],
    id: "To help, to continually help and share, that is the sum of all knowledge; that is the meaning of art.",
    author: "Eleonora Duse",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "opinions", "my", "own", "strong", "always", "agree"],
    words: [
      "i",
      "have",
      "opinions",
      "of",
      "my",
      "own",
      "strong",
      "but",
      "don't",
      "always",
      "agree",
      "with",
      "them",
    ],
    id: "I have opinions of my own, strong opinions, but I don't always agree with them.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["those", "short", "lent", "owe", "paid", "at", "easter"],
    words: [
      "those",
      "have",
      "a",
      "short",
      "lent",
      "who",
      "owe",
      "money",
      "to",
      "be",
      "paid",
      "at",
      "easter",
    ],
    id: "Those have a short Lent who owe money to be paid at Easter.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["hallucination", "fact", "error", "erroneous", "judgment", "based", "upon"],
    words: [
      "a",
      "hallucination",
      "is",
      "fact",
      "not",
      "an",
      "error",
      "what",
      "erroneous",
      "judgment",
      "based",
      "upon",
      "it",
    ],
    id: "A hallucination is a fact, not an error; what is erroneous is a judgment based upon it.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["biggest", "adventure", "take", "live", "life", "your", "dreams"],
    words: [
      "the",
      "biggest",
      "adventure",
      "you",
      "can",
      "take",
      "is",
      "to",
      "live",
      "life",
      "of",
      "your",
      "dreams",
    ],
    id: "The biggest adventure you can take is to live the life of your dreams.",
    author: "Oprah Winfrey",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "sing", "humanity", "friendship", "what's", "happening", "earth"],
    words: [
      "i",
      "want",
      "to",
      "sing",
      "of",
      "humanity",
      "friendship",
      "what's",
      "happening",
      "the",
      "earth",
      "and",
      "like",
    ],
    id: "I want to sing of humanity, friendship, what's happening to the Earth and the like.",
    author: "Shankar Mahadevan",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["ounce", "cheerfulness", "worth", "pound", "sadness", "serve", "god"],
    words: [
      "an",
      "ounce",
      "of",
      "cheerfulness",
      "is",
      "worth",
      "a",
      "pound",
      "sadness",
      "to",
      "serve",
      "god",
      "with",
    ],
    id: "An ounce of cheerfulness is worth a pound of sadness to serve God with.",
    author: "Thomas Fuller",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["bear", "your", "own", "faults", "why", "fault", "wife"],
    words: [
      "you",
      "can",
      "bear",
      "your",
      "own",
      "faults",
      "and",
      "why",
      "not",
      "a",
      "fault",
      "in",
      "wife",
    ],
    id: "You can bear your own faults, and why not a fault in your wife?",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["testament", "says", "nothing", "apostles", "retired", "took", "easy"],
    words: [
      "the",
      "new",
      "testament",
      "says",
      "nothing",
      "of",
      "apostles",
      "who",
      "retired",
      "and",
      "took",
      "it",
      "easy",
    ],
    id: "The New Testament says nothing of Apostles who retired and took it easy.",
    author: "Billy Graham",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["party-spirit", "at", "best", "madness", "many", "gain", "few"],
    words: [
      "party-spirit",
      "at",
      "best",
      "is",
      "but",
      "the",
      "madness",
      "of",
      "many",
      "for",
      "gain",
      "a",
      "few",
    ],
    id: "Party-spirit at best is but the madness of many for the gain of a few.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["task", "modern", "educator", "cut", "down", "irrigate", "deserts"],
    words: [
      "the",
      "task",
      "of",
      "modern",
      "educator",
      "is",
      "not",
      "to",
      "cut",
      "down",
      "jungles",
      "but",
      "irrigate",
      "deserts",
    ],
    id: "The task of the modern educator is not to cut down jungles, but to irrigate deserts.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["how", "many", "one", "loses", "when", "decides", "something"],
    words: [
      "how",
      "many",
      "cares",
      "one",
      "loses",
      "when",
      "decides",
      "not",
      "to",
      "be",
      "something",
      "but",
      "someone",
    ],
    id: "How many cares one loses when one decides not to be something but to be someone.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: [
      "understanding",
      "science",
      "pushing",
      "boundaries",
      "makes",
      "immensely",
      "satisfied",
    ],
    words: [
      "understanding",
      "science",
      "and",
      "pushing",
      "the",
      "boundaries",
      "of",
      "is",
      "what",
      "makes",
      "me",
      "immensely",
      "satisfied",
    ],
    id: "Understanding science and pushing the boundaries of science is what makes me immensely satisfied.",
    author: "Bill Gates",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["when", "lose", "person", "much", "surviving", "loss", "difficult"],
    words: [
      "when",
      "you",
      "lose",
      "a",
      "person",
      "love",
      "so",
      "much",
      "surviving",
      "the",
      "loss",
      "is",
      "difficult",
    ],
    id: "When you lose a person you love so much, surviving the loss is difficult.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["fear", "life", "those", "already", "three", "parts", "dead"],
    words: [
      "to",
      "fear",
      "love",
      "is",
      "life",
      "and",
      "those",
      "who",
      "are",
      "already",
      "three",
      "parts",
      "dead",
    ],
    id: "To fear love is to fear life, and those who fear life are already three parts dead.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["as", "were", "live", "hundred", "years", "pray", "die"],
    words: [
      "work",
      "as",
      "if",
      "you",
      "were",
      "to",
      "live",
      "a",
      "hundred",
      "years",
      "pray",
      "die",
      "tomorrow",
    ],
    id: "Work as if you were to live a hundred years. Pray as if you were to die tomorrow.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["from", "reason", "justice", "springs", "goodness", "born", "wisdom"],
    words: [
      "it",
      "is",
      "not",
      "from",
      "reason",
      "that",
      "justice",
      "springs",
      "but",
      "goodness",
      "born",
      "of",
      "wisdom",
    ],
    id: "It is not from reason that justice springs, but goodness is born of wisdom.",
    author: "Maurice Maeterlinck",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["dictatorship", "would", "heck", "lot", "easier", "there's", "question"],
    words: [
      "a",
      "dictatorship",
      "would",
      "be",
      "heck",
      "of",
      "lot",
      "easier",
      "there's",
      "no",
      "question",
      "about",
      "it",
    ],
    id: "A dictatorship would be a heck of a lot easier, there's no question about it.",
    author: "George W. Bush",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["cannot", "teach", "anything", "only", "discover", "within", "themselves"],
    words: [
      "we",
      "cannot",
      "teach",
      "people",
      "anything",
      "can",
      "only",
      "help",
      "them",
      "discover",
      "it",
      "within",
      "themselves",
    ],
    id: "We cannot teach people anything; we can only help them discover it within themselves.",
    author: "Galileo Galilei",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["man", "two", "great", "spiritual", "one", "forgiveness", "goodness"],
    words: [
      "man",
      "has",
      "two",
      "great",
      "spiritual",
      "needs",
      "one",
      "is",
      "for",
      "forgiveness",
      "the",
      "other",
      "goodness",
    ],
    id: "Man has two great spiritual needs. One is for forgiveness. The other is for goodness.",
    author: "Billy Graham",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["pessimism", "only", "name", "men", "weak", "nerves", "wisdom"],
    words: [
      "pessimism",
      "is",
      "only",
      "the",
      "name",
      "that",
      "men",
      "of",
      "weak",
      "nerves",
      "give",
      "to",
      "wisdom",
    ],
    id: "Pessimism is only the name that men of weak nerves give to wisdom.",
    author: "Bernard DeVoto",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["means", "which", "unlovable", "or", "virtue", "at", "all"],
    words: [
      "love",
      "means",
      "to",
      "that",
      "which",
      "is",
      "unlovable",
      "or",
      "it",
      "no",
      "virtue",
      "at",
      "all",
    ],
    id: "Love means to love that which is unlovable; or it is no virtue at all.",
    author: "Gilbert K. Chesterton",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["being", "best", "means", "proving", "different", "countries", "championships"],
    words: [
      "to",
      "me",
      "being",
      "the",
      "best",
      "means",
      "proving",
      "it",
      "in",
      "different",
      "countries",
      "and",
      "championships",
    ],
    id: "To me, being the best means proving it in different countries and championships.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["genius", "might", "ability", "profound", "thing", "simple", "way"],
    words: [
      "genius",
      "might",
      "be",
      "the",
      "ability",
      "to",
      "say",
      "a",
      "profound",
      "thing",
      "in",
      "simple",
      "way",
    ],
    id: "Genius might be the ability to say a profound thing in a simple way.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["how", "spend", "less", "than", "get", "philosopher's", "stone"],
    words: [
      "if",
      "you",
      "know",
      "how",
      "to",
      "spend",
      "less",
      "than",
      "get",
      "have",
      "the",
      "philosopher's",
      "stone",
    ],
    id: "If you know how to spend less than you get, you have the philosopher's stone.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["anxiety", "empty", "its", "sorrows", "only", "empties", "strength"],
    words: [
      "anxiety",
      "does",
      "not",
      "empty",
      "tomorrow",
      "of",
      "its",
      "sorrows",
      "but",
      "only",
      "empties",
      "today",
      "strength",
    ],
    id: "Anxiety does not empty tomorrow of its sorrows, but only empties today of its strength.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["humor", "one", "best", "articles", "dress", "wear", "society"],
    words: [
      "good",
      "humor",
      "is",
      "one",
      "of",
      "the",
      "best",
      "articles",
      "dress",
      "can",
      "wear",
      "in",
      "society",
    ],
    id: "Good humor is one of the best articles of dress one can wear in society.",
    author: "William Makepeace Thackeray",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["all", "i", "make", "comedy", "park", "policeman", "pretty"],
    words: [
      "all",
      "i",
      "need",
      "to",
      "make",
      "a",
      "comedy",
      "is",
      "park",
      "policeman",
      "and",
      "pretty",
      "girl",
    ],
    id: "All I need to make a comedy is a park, a policeman and a pretty girl.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["there", "strings", "human", "heart", "had", "better", "vibrated"],
    words: [
      "there",
      "are",
      "strings",
      "in",
      "the",
      "human",
      "heart",
      "that",
      "had",
      "better",
      "not",
      "be",
      "vibrated",
    ],
    id: "There are strings in the human heart that had better not be vibrated.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["truth", "made", "traitor", "as", "often", "time", "scoundrels"],
    words: [
      "truth",
      "made",
      "you",
      "a",
      "traitor",
      "as",
      "it",
      "often",
      "does",
      "in",
      "time",
      "of",
      "scoundrels",
    ],
    id: "Truth made you a traitor as it often does in a time of scoundrels.",
    author: "Lillian Hellman",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "hope", "birds", "too", "economical", "saves", "heaven"],
    words: [
      "i",
      "hope",
      "you",
      "love",
      "birds",
      "too",
      "it",
      "is",
      "economical",
      "saves",
      "going",
      "to",
      "heaven",
    ],
    id: "I hope you love birds too. It is economical. It saves going to heaven.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["why", "should", "i", "future", "soon", "may", "exist"],
    words: [
      "why",
      "should",
      "i",
      "be",
      "studying",
      "for",
      "a",
      "future",
      "that",
      "soon",
      "may",
      "not",
      "exist",
    ],
    id: "Why should I be studying for a future that soon may not exist?",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["one", "great", "principle", "law", "make", "business", "itself"],
    words: [
      "the",
      "one",
      "great",
      "principle",
      "of",
      "english",
      "law",
      "is",
      "to",
      "make",
      "business",
      "for",
      "itself",
    ],
    id: "The one great principle of English law is to make business for itself.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["tell", "big", "enough", "lie", "frequently", "will", "believed"],
    words: [
      "if",
      "you",
      "tell",
      "a",
      "big",
      "enough",
      "lie",
      "and",
      "it",
      "frequently",
      "will",
      "be",
      "believed",
    ],
    id: "If you tell a big enough lie and tell it frequently enough, it will be believed.",
    author: "Unknown",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "thousand", "times", "too", "short", "bore", "ourselves"],
    words: [
      "is",
      "life",
      "not",
      "a",
      "thousand",
      "times",
      "too",
      "short",
      "for",
      "us",
      "to",
      "bore",
      "ourselves",
    ],
    id: "Is life not a thousand times too short for us to bore ourselves?",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i'm", "silicon", "valley", "guy", "i", "from", "anything"],
    words: [
      "i'm",
      "a",
      "silicon",
      "valley",
      "guy",
      "i",
      "just",
      "think",
      "people",
      "from",
      "can",
      "do",
      "anything",
    ],
    id: "I'm a Silicon Valley guy. I just think people from Silicon Valley can do anything.",
    author: "Elon Musk",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["best", "author", "will", "one", "ashamed", "become", "writer"],
    words: [
      "the",
      "best",
      "author",
      "will",
      "be",
      "one",
      "who",
      "is",
      "ashamed",
      "to",
      "become",
      "a",
      "writer",
    ],
    id: "The best author will be the one who is ashamed to become a writer.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["on", "our", "failures", "base", "different", "better", "success"],
    words: [
      "it",
      "is",
      "on",
      "our",
      "failures",
      "that",
      "we",
      "base",
      "a",
      "new",
      "and",
      "different",
      "better",
      "success",
    ],
    id: "It is on our failures that we base a new and different and better success.",
    author: "Havelock Ellis",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["pair", "powerful", "spectacles", "sometimes", "sufficed", "cure", "person"],
    words: [
      "a",
      "pair",
      "of",
      "powerful",
      "spectacles",
      "has",
      "sometimes",
      "sufficed",
      "to",
      "cure",
      "person",
      "in",
      "love",
    ],
    id: "A pair of powerful spectacles has sometimes sufficed to cure a person in love.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "faith", "god", "there", "whether", "or", "why"],
    words: [
      "i",
      "have",
      "faith",
      "in",
      "god",
      "is",
      "there",
      "whether",
      "we",
      "or",
      "not",
      "so",
      "why",
      "him",
    ],
    id: "I have faith in faith. God is there, whether we have faith or not, so why not have faith in him?",
    author: "Valerie Bertinelli",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["then", "last", "resort", "truths", "mankind", "irrefutable", "errors"],
    words: [
      "what",
      "then",
      "in",
      "the",
      "last",
      "resort",
      "are",
      "truths",
      "of",
      "mankind",
      "they",
      "irrefutable",
      "errors",
    ],
    id: "What then in the last resort are the truths of mankind? They are the irrefutable errors of mankind.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["forgiveness", "act", "will", "function", "regardless", "temperature", "heart"],
    words: [
      "forgiveness",
      "is",
      "an",
      "act",
      "of",
      "the",
      "will",
      "and",
      "can",
      "function",
      "regardless",
      "temperature",
      "heart",
    ],
    id: "Forgiveness is an act of the will, and the will can function regardless of the temperature of the heart.",
    author: "Corrie Ten Boom",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["secrets", "success", "wife", "steady", "job", "my", "told"],
    words: [
      "the",
      "secrets",
      "of",
      "success",
      "are",
      "a",
      "good",
      "wife",
      "and",
      "steady",
      "job",
      "my",
      "told",
      "me",
    ],
    id: "The secrets of success are a good wife and a steady job. My wife told me.",
    author: "Howard Nemerov",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["true", "too", "much", "belief", "bad", "your", "health"],
    words: [
      "it",
      "is",
      "true",
      "that",
      "too",
      "much",
      "belief",
      "can",
      "be",
      "bad",
      "for",
      "your",
      "health",
    ],
    id: "It is true that too much belief can be bad for your health.",
    author: "Terry Eagleton",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "freedom", "means", "total", "selfishness", "nobody", "much"],
    words: [
      "i",
      "have",
      "freedom",
      "but",
      "means",
      "total",
      "selfishness",
      "it",
      "nobody",
      "cares",
      "much",
      "what",
      "you",
      "do",
    ],
    id: "I have freedom. But freedom means total selfishness. It means nobody cares much what you do.",
    author: "Lynn Barber",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["never", "maintain", "friendship", "tell", "truth", "even", "hurts"],
    words: [
      "you",
      "can",
      "never",
      "maintain",
      "a",
      "friendship",
      "if",
      "don't",
      "tell",
      "the",
      "truth",
      "even",
      "it",
      "hurts",
    ],
    id: "You can never maintain a friendship if you don't tell the truth, even if it hurts.",
    author: "Peyton List",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "matter", "how", "old", "get", "always", "yourself"],
    words: [
      "i",
      "think",
      "that",
      "no",
      "matter",
      "how",
      "old",
      "you",
      "get",
      "are",
      "always",
      "learning",
      "about",
      "yourself",
    ],
    id: "I think that no matter how old you get, you are always learning about yourself.",
    author: "Joey Jordison",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["faith", "inner", "strength", "sense", "balance", "perspective", "life"],
    words: [
      "faith",
      "gives",
      "you",
      "an",
      "inner",
      "strength",
      "and",
      "a",
      "sense",
      "of",
      "balance",
      "perspective",
      "in",
      "life",
    ],
    id: "Faith gives you an inner strength and a sense of balance and perspective in life.",
    author: "Gregory Peck",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["interest", "commercial", "world", "wealth", "should", "found", "everywhere"],
    words: [
      "it",
      "is",
      "the",
      "interest",
      "of",
      "commercial",
      "world",
      "that",
      "wealth",
      "should",
      "be",
      "found",
      "everywhere",
    ],
    id: "It is the interest of the commercial world that wealth should be found everywhere.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["life", "nothing", "competition", "criminal", "rather", "than", "victim"],
    words: [
      "life",
      "is",
      "nothing",
      "but",
      "a",
      "competition",
      "to",
      "be",
      "the",
      "criminal",
      "rather",
      "than",
      "victim",
    ],
    id: "Life is nothing but a competition to be the criminal rather than the victim.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["god", "his", "wisdom", "decided", "will", "reward", "own"],
    words: [
      "god",
      "in",
      "his",
      "wisdom",
      "has",
      "decided",
      "that",
      "he",
      "will",
      "reward",
      "no",
      "works",
      "but",
      "own",
    ],
    id: "God in His wisdom has decided that He will reward no works but His own.",
    author: "Johannes Tauler",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["live", "-", "all", "reason", "against", "healthy", "instinct"],
    words: [
      "to",
      "live",
      "is",
      "like",
      "love",
      "-",
      "all",
      "reason",
      "against",
      "it",
      "and",
      "healthy",
      "instinct",
      "for",
    ],
    id: "To live is like to love - all reason is against it, and all healthy instinct for it.",
    author: "Samuel Butler",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["regular", "orderly", "your", "life", "may", "violent", "original"],
    words: [
      "be",
      "regular",
      "and",
      "orderly",
      "in",
      "your",
      "life",
      "so",
      "that",
      "you",
      "may",
      "violent",
      "original",
      "work",
    ],
    id: "Be regular and orderly in your life, so that you may be violent and original in your work.",
    author: "Gustave Flaubert",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["spend", "more", "time", "prayer", "secret", "successful", "evangelism"],
    words: [
      "spend",
      "more",
      "time",
      "in",
      "study",
      "and",
      "prayer",
      "that's",
      "the",
      "secret",
      "of",
      "successful",
      "evangelism",
    ],
    id: "Spend more time in study and prayer. That's the secret of successful evangelism.",
    author: "Billy Graham",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["even", "friendship", "concerned", "takes", "long", "time", "trust"],
    words: [
      "even",
      "where",
      "friendship",
      "is",
      "concerned",
      "it",
      "takes",
      "me",
      "a",
      "long",
      "time",
      "to",
      "trust",
      "people",
    ],
    id: "Even where friendship is concerned, it takes me a long time to trust people.",
    author: "Namie Amuro",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["unselfish", "sense", "being", "benevolent", "generous", "without", "selfless"],
    words: [
      "love",
      "can",
      "be",
      "unselfish",
      "in",
      "the",
      "sense",
      "of",
      "being",
      "benevolent",
      "and",
      "generous",
      "without",
      "selfless",
    ],
    id: "Love can be unselfish, in the sense of being benevolent and generous, without being selfless.",
    author: "Mortimer Adler",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["few", "dare", "eccentric", "marks", "chief", "danger", "time"],
    words: [
      "that",
      "so",
      "few",
      "now",
      "dare",
      "to",
      "be",
      "eccentric",
      "marks",
      "the",
      "chief",
      "danger",
      "of",
      "time",
    ],
    id: "That so few now dare to be eccentric, marks the chief danger of the time.",
    author: "John Stuart Mill",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["back", "mistaken", "venture", "defeat", "laughter", "wisdom", "listen"],
    words: [
      "back",
      "of",
      "every",
      "mistaken",
      "venture",
      "and",
      "defeat",
      "is",
      "the",
      "laughter",
      "wisdom",
      "if",
      "you",
      "listen",
    ],
    id: "Back of every mistaken venture and defeat is the laughter of wisdom, if you listen.",
    author: "Carl Sandburg",
    grammar: null,
    word_count: 14,
    all_intersection_count: 7,
    new_words_count: 7,
  },
  {
    row_new_words: ["almost", "wise", "saying", "opposite", "one", "less", "balance"],
    words: [
      "almost",
      "every",
      "wise",
      "saying",
      "has",
      "an",
      "opposite",
      "one",
      "no",
      "less",
      "to",
      "balance",
      "it",
    ],
    id: "Almost every wise saying has an opposite one, no less wise, to balance it.",
    author: "George Santayana",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["tomb", "suffices", "whom", "whole", "world", "was", "sufficient"],
    words: [
      "a",
      "tomb",
      "now",
      "suffices",
      "him",
      "for",
      "whom",
      "the",
      "whole",
      "world",
      "was",
      "not",
      "sufficient",
    ],
    id: "A tomb now suffices him for whom the whole world was not sufficient.",
    author: "Alexander the Great",
    grammar: null,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["i", "really", "sing", "my", "tone", "voice", "too", "deep"],
    words: [
      "i",
      "really",
      "want",
      "to",
      "sing",
      "but",
      "my",
      "tone",
      "of",
      "voice",
      "is",
      "too",
      "deep",
      "do",
      "what",
    ],
    id: "I really want to sing, but my tone of voice is really too deep to do what I want.",
    author: "Tyler, The Creator",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "knowledge",
      "consequence",
      "time",
      "multitude",
      "days",
      "fittest",
      "teach",
      "wisdom",
    ],
    words: [
      "knowledge",
      "is",
      "the",
      "consequence",
      "of",
      "time",
      "and",
      "multitude",
      "days",
      "are",
      "fittest",
      "to",
      "teach",
      "wisdom",
    ],
    id: "Knowledge is the consequence of time, and multitude of days are fittest to teach wisdom.",
    author: "Jeremy Collier",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["god", "gave", "gift", "86400", "seconds", "used", "one", "thank"],
    words: [
      "god",
      "gave",
      "you",
      "a",
      "gift",
      "of",
      "86400",
      "seconds",
      "today",
      "have",
      "used",
      "one",
      "to",
      "say",
      "thank",
    ],
    id: "God gave you a gift of 86,400 seconds today. Have you used one to say 'thank you?'",
    author: "William Arthur Ward",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["dwell", "past", "dream", "future", "concentrate", "mind", "on", "present"],
    words: [
      "do",
      "not",
      "dwell",
      "in",
      "the",
      "past",
      "dream",
      "of",
      "future",
      "concentrate",
      "mind",
      "on",
      "present",
      "moment",
    ],
    id: "Do not dwell in the past, do not dream of the future, concentrate the mind on the present moment.",
    author: "Buddha",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["polygamy", "endeavour", "get", "more", "out", "life", "than", "there"],
    words: [
      "polygamy",
      "an",
      "endeavour",
      "to",
      "get",
      "more",
      "out",
      "of",
      "life",
      "than",
      "there",
      "is",
      "in",
      "it",
    ],
    id: "Polygamy: An endeavour to get more out of life than there is in it.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "most",
      "important",
      "thing",
      "real",
      "friendship",
      "between",
      "egyptians",
      "americans",
    ],
    words: [
      "the",
      "most",
      "important",
      "thing",
      "for",
      "me",
      "is",
      "to",
      "have",
      "real",
      "friendship",
      "between",
      "egyptians",
      "and",
      "americans",
    ],
    id: "The most important thing for me is to have real friendship between Egyptians and Americans.",
    author: "Mohammed Morsi",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "barely", "walk", "privilege", "able", "move", "at", "all"],
    words: [
      "i",
      "can",
      "barely",
      "walk",
      "but",
      "it's",
      "a",
      "privilege",
      "to",
      "be",
      "able",
      "move",
      "at",
      "all",
    ],
    id: "I can barely walk, but it's a privilege to be able to move at all.",
    author: "Billy Graham",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "individuals",
      "insanity",
      "rare",
      "groups",
      "parties",
      "nations",
      "epochs",
      "rule",
    ],
    words: [
      "in",
      "individuals",
      "insanity",
      "is",
      "rare",
      "but",
      "groups",
      "parties",
      "nations",
      "and",
      "epochs",
      "it",
      "the",
      "rule",
    ],
    id: "In individuals, insanity is rare; but in groups, parties, nations and epochs, it is the rule.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["there's", "chance", "iphone", "get", "any", "significant", "market", "share"],
    words: [
      "there's",
      "no",
      "chance",
      "that",
      "the",
      "iphone",
      "is",
      "going",
      "to",
      "get",
      "any",
      "significant",
      "market",
      "share",
    ],
    id: "There's no chance that the iPhone is going to get any significant market share.",
    author: "Steve Ballmer",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["too", "moral", "may", "cheat", "yourself", "out", "much", "life"],
    words: [
      "do",
      "not",
      "be",
      "too",
      "moral",
      "you",
      "may",
      "cheat",
      "yourself",
      "out",
      "of",
      "much",
      "life",
      "so",
    ],
    id: "Do not be too moral. You may cheat yourself out of much life so.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "there", "more", "happiness", "than", "freedom", "my", "homeland"],
    words: [
      "i",
      "say",
      "there",
      "is",
      "not",
      "more",
      "happiness",
      "for",
      "me",
      "than",
      "the",
      "freedom",
      "of",
      "my",
      "homeland",
    ],
    id: "I say there is not more happiness for me than the freedom of my Homeland.",
    author: "Islom Karimov",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["take", "much", "strength", "things", "requires", "great", "decide", "on"],
    words: [
      "it",
      "does",
      "not",
      "take",
      "much",
      "strength",
      "to",
      "do",
      "things",
      "but",
      "requires",
      "great",
      "decide",
      "on",
      "what",
    ],
    id: "It does not take much strength to do things, but it requires great strength to decide on what to do.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["lies", "behind", "ahead", "tiny", "matters", "compared", "lives", "within"],
    words: [
      "what",
      "lies",
      "behind",
      "us",
      "and",
      "ahead",
      "of",
      "are",
      "tiny",
      "matters",
      "compared",
      "to",
      "lives",
      "within",
    ],
    id: "What lies behind us and what lies ahead of us are tiny matters compared to what lives within us.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "nobody",
      "spends",
      "any",
      "on",
      "smallpox",
      "unless",
      "bio-terrorist",
      "recreating",
    ],
    words: [
      "nobody",
      "spends",
      "any",
      "money",
      "on",
      "smallpox",
      "unless",
      "they",
      "worry",
      "about",
      "a",
      "bio-terrorist",
      "recreating",
      "it",
    ],
    id: "Nobody spends any money on smallpox unless they worry about a bio-terrorist recreating it.",
    author: "Bill Gates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["nature", "worth", "more", "than", "knowledge", "honor", "persons", "possess"],
    words: [
      "good",
      "nature",
      "is",
      "worth",
      "more",
      "than",
      "knowledge",
      "money",
      "honor",
      "to",
      "the",
      "persons",
      "who",
      "possess",
      "it",
    ],
    id: "Good nature is worth more than knowledge, more than money, more than honor, to the persons who possess it.",
    author: "Henry Ward Beecher",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["state", "comes", "into", "existence", "sake", "life", "continues", "exist"],
    words: [
      "the",
      "state",
      "comes",
      "into",
      "existence",
      "for",
      "sake",
      "of",
      "life",
      "and",
      "continues",
      "to",
      "exist",
      "good",
    ],
    id: "The state comes into existence for the sake of life and continues to exist for the sake of good life.",
    author: "Aristotle",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "freedom",
      "procured",
      "by",
      "full",
      "enjoyment",
      "desired",
      "controlling",
      "desire",
    ],
    words: [
      "freedom",
      "is",
      "not",
      "procured",
      "by",
      "a",
      "full",
      "enjoyment",
      "of",
      "what",
      "desired",
      "but",
      "controlling",
      "the",
      "desire",
    ],
    id: "Freedom is not procured by a full enjoyment of what is desired, but by controlling the desire.",
    author: "Epictetus",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "acceptance",
      "happened",
      "first",
      "step",
      "overcoming",
      "consequences",
      "any",
      "misfortune",
    ],
    words: [
      "acceptance",
      "of",
      "what",
      "has",
      "happened",
      "is",
      "the",
      "first",
      "step",
      "to",
      "overcoming",
      "consequences",
      "any",
      "misfortune",
    ],
    id: "Acceptance of what has happened is the first step to overcoming the consequences of any misfortune.",
    author: "William James",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "there",
      "natural",
      "aristocracy",
      "among",
      "men",
      "grounds",
      "virtue",
      "talents",
    ],
    words: [
      "there",
      "is",
      "a",
      "natural",
      "aristocracy",
      "among",
      "men",
      "the",
      "grounds",
      "of",
      "this",
      "are",
      "virtue",
      "and",
      "talents",
    ],
    id: "There is a natural aristocracy among men. The grounds of this are virtue and talents.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["drunkard", "takes", "more", "than", "three", "glasses", "though", "drunk"],
    words: [
      "he",
      "is",
      "a",
      "drunkard",
      "who",
      "takes",
      "more",
      "than",
      "three",
      "glasses",
      "though",
      "be",
      "not",
      "drunk",
    ],
    id: "He is a drunkard who takes more than three glasses though he be not drunk.",
    author: "Epictetus",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["never", "take", "wife", "till", "thou", "hast", "fire", "put"],
    words: [
      "never",
      "take",
      "a",
      "wife",
      "till",
      "thou",
      "hast",
      "house",
      "and",
      "fire",
      "to",
      "put",
      "her",
      "in",
    ],
    id: "Never take a wife till thou hast a house (and a fire) to put her in.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "only", "one", "rule", "being", "talker", "-", "listen"],
    words: [
      "there",
      "is",
      "only",
      "one",
      "rule",
      "for",
      "being",
      "a",
      "good",
      "talker",
      "-",
      "learn",
      "to",
      "listen",
    ],
    id: "There is only one rule for being a good talker - learn to listen.",
    author: "Christopher Morley",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "part",
      "your",
      "heritage",
      "society",
      "opportunity",
      "become",
      "financially",
      "independent",
    ],
    words: [
      "part",
      "of",
      "your",
      "heritage",
      "in",
      "this",
      "society",
      "is",
      "the",
      "opportunity",
      "to",
      "become",
      "financially",
      "independent",
    ],
    id: "Part of your heritage in this society is the opportunity to become financially independent.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["friend", "mine", "tells", "i", "talk", "shorthand", "then", "smudge"],
    words: [
      "a",
      "friend",
      "of",
      "mine",
      "tells",
      "that",
      "i",
      "talk",
      "in",
      "shorthand",
      "and",
      "then",
      "smudge",
      "it",
    ],
    id: "A friend of mine tells that I talk in shorthand and then smudge it.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "wisdom",
      "apprehension",
      "heavenly",
      "things",
      "which",
      "spirit",
      "rises",
      "through",
    ],
    words: [
      "wisdom",
      "is",
      "that",
      "apprehension",
      "of",
      "heavenly",
      "things",
      "to",
      "which",
      "the",
      "spirit",
      "rises",
      "through",
      "love",
    ],
    id: "Wisdom is that apprehension of heavenly things to which the spirit rises through love.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["evil", "teacher", "look", "at", "wisdom", "its", "negative", "power"],
    words: [
      "evil",
      "can",
      "be",
      "a",
      "teacher",
      "if",
      "you",
      "look",
      "at",
      "the",
      "wisdom",
      "of",
      "its",
      "negative",
      "power",
    ],
    id: "Evil can be a teacher, if you look at the wisdom of its negative power.",
    author: "Tom Brown, Jr.",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "religion",
      "essentially",
      "art",
      "theory",
      "remaking",
      "man",
      "finished",
      "creation",
    ],
    words: [
      "religion",
      "is",
      "essentially",
      "the",
      "art",
      "and",
      "theory",
      "of",
      "remaking",
      "man",
      "not",
      "a",
      "finished",
      "creation",
    ],
    id: "Religion is essentially the art and the theory of the remaking of man. Man is not a finished creation.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["one", "ever", "doubts", "i", "mean", "problem", "sometimes", "all"],
    words: [
      "no",
      "one",
      "ever",
      "doubts",
      "that",
      "i",
      "mean",
      "what",
      "say",
      "the",
      "problem",
      "is",
      "sometimes",
      "all",
    ],
    id: "No one ever doubts that I mean what I say. The problem is I sometimes say all that I mean.",
    author: "Joe Biden",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "theology",
      "general",
      "seems",
      "substitution",
      "human",
      "ingenuity",
      "divine",
      "wisdom",
    ],
    words: [
      "theology",
      "in",
      "general",
      "seems",
      "to",
      "me",
      "a",
      "substitution",
      "of",
      "human",
      "ingenuity",
      "for",
      "divine",
      "wisdom",
    ],
    id: "Theology in general seems to me a substitution of human ingenuity for divine wisdom.",
    author: "Julia Ward Howe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "will", "never", "without", "having", "sleep", "alone", "kills"],
    words: [
      "i",
      "will",
      "never",
      "be",
      "happy",
      "without",
      "having",
      "someone",
      "going",
      "to",
      "sleep",
      "alone",
      "kills",
      "me",
    ],
    id: "I will never be happy without having someone. Going to sleep alone kills me.",
    author: "Elon Musk",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["only", "earns", "his", "freedom", "life", "takes", "by", "storm"],
    words: [
      "he",
      "only",
      "earns",
      "his",
      "freedom",
      "and",
      "life",
      "who",
      "takes",
      "them",
      "every",
      "day",
      "by",
      "storm",
    ],
    id: "He only earns his freedom and his life Who takes them every day by storm.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["person", "whose", "mind", "quiet", "satisfied", "god", "pathway", "health"],
    words: [
      "a",
      "person",
      "whose",
      "mind",
      "is",
      "quiet",
      "and",
      "satisfied",
      "in",
      "god",
      "the",
      "pathway",
      "to",
      "health",
    ],
    id: "A person whose mind is quiet and satisfied in God is in the pathway to health.",
    author: "Ellen G. White",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["god", "cosmic", "bellboy", "whom", "press", "button", "get", "things"],
    words: [
      "god",
      "is",
      "not",
      "a",
      "cosmic",
      "bellboy",
      "for",
      "whom",
      "we",
      "can",
      "press",
      "button",
      "to",
      "get",
      "things",
    ],
    id: "God is not a cosmic bellboy for whom we can press a button to get things.",
    author: "Harry Emerson Fosdick",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["only", "when", "rich", "sick", "fully", "feel", "impotence", "wealth"],
    words: [
      "it",
      "is",
      "only",
      "when",
      "the",
      "rich",
      "are",
      "sick",
      "that",
      "they",
      "fully",
      "feel",
      "impotence",
      "of",
      "wealth",
    ],
    id: "It is only when the rich are sick that they fully feel the impotence of wealth.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["homer", "chiefly", "taught", "poets", "art", "telling", "lies", "skillfully"],
    words: [
      "it",
      "is",
      "homer",
      "who",
      "has",
      "chiefly",
      "taught",
      "other",
      "poets",
      "the",
      "art",
      "of",
      "telling",
      "lies",
      "skillfully",
    ],
    id: "It is Homer who has chiefly taught other poets the art of telling lies skillfully.",
    author: "Aristotle",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["only", "man", "clogs", "his", "happiness", "destroying", "thoughts", "may"],
    words: [
      "only",
      "man",
      "clogs",
      "his",
      "happiness",
      "with",
      "care",
      "destroying",
      "what",
      "is",
      "thoughts",
      "of",
      "may",
      "be",
    ],
    id: "Only man clogs his happiness with care, destroying what is with thoughts of what may be.",
    author: "John Dryden",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "read", "lot", "obscure", "books", "nice", "open", "book"],
    words: [
      "i",
      "read",
      "a",
      "lot",
      "of",
      "obscure",
      "books",
      "and",
      "it",
      "is",
      "nice",
      "to",
      "open",
      "book",
    ],
    id: "I read a lot of obscure books and it is nice to open a book.",
    author: "Bill Gates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "dogsled", "team", "ain't", "lead", "scenery", "never", "changes"],
    words: [
      "life",
      "is",
      "like",
      "a",
      "dogsled",
      "team",
      "if",
      "you",
      "ain't",
      "the",
      "lead",
      "dog",
      "scenery",
      "never",
      "changes",
    ],
    id: "Life is like a dogsled team. If you ain't the lead dog, the scenery never changes.",
    author: "Lewis Grizzard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "recipe",
      "perpetual",
      "ignorance",
      "satisfied",
      "your",
      "opinions",
      "content",
      "knowledge",
    ],
    words: [
      "the",
      "recipe",
      "for",
      "perpetual",
      "ignorance",
      "is",
      "be",
      "satisfied",
      "with",
      "your",
      "opinions",
      "and",
      "content",
      "knowledge",
    ],
    id: "The recipe for perpetual ignorance is: Be satisfied with your opinions and content with your knowledge.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["isn't", "enough", "talk", "peace", "one", "must", "believe", "at"],
    words: [
      "it",
      "isn't",
      "enough",
      "to",
      "talk",
      "about",
      "peace",
      "one",
      "must",
      "believe",
      "in",
      "and",
      "work",
      "at",
    ],
    id: "It isn't enough to talk about peace. One must believe in it. And it isn't enough to believe in it. One must work at it.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "there",
      "fool",
      "careless",
      "gambler",
      "starts",
      "taking",
      "victory",
      "granted",
    ],
    words: [
      "there",
      "is",
      "no",
      "fool",
      "like",
      "a",
      "careless",
      "gambler",
      "who",
      "starts",
      "taking",
      "victory",
      "for",
      "granted",
    ],
    id: "There is no fool like a careless gambler who starts taking victory for granted.",
    author: "Hunter S. Thompson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "irrationality",
      "thing",
      "argument",
      "against",
      "its",
      "existence",
      "rather",
      "condition",
    ],
    words: [
      "the",
      "irrationality",
      "of",
      "a",
      "thing",
      "is",
      "no",
      "argument",
      "against",
      "its",
      "existence",
      "rather",
      "condition",
      "it",
    ],
    id: "The irrationality of a thing is no argument against its existence, rather a condition of it.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "create", "companies", "sake", "creating", "get", "things", "done"],
    words: [
      "i",
      "don't",
      "create",
      "companies",
      "for",
      "the",
      "sake",
      "of",
      "creating",
      "but",
      "to",
      "get",
      "things",
      "done",
    ],
    id: "I don't create companies for the sake of creating companies, but to get things done.",
    author: "Elon Musk",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "struggles",
      "strengthens",
      "our",
      "nerves",
      "sharpens",
      "skill",
      "antagonist",
      "helper",
    ],
    words: [
      "he",
      "that",
      "struggles",
      "with",
      "us",
      "strengthens",
      "our",
      "nerves",
      "and",
      "sharpens",
      "skill",
      "antagonist",
      "is",
      "helper",
    ],
    id: "He that struggles with us strengthens our nerves, and sharpens our skill. Our antagonist is our helper.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i'm", "glad", "bmw", "bringing", "electric", "car", "market", "cool"],
    words: [
      "i'm",
      "glad",
      "to",
      "see",
      "that",
      "bmw",
      "is",
      "bringing",
      "an",
      "electric",
      "car",
      "market",
      "that's",
      "cool",
    ],
    id: "I'm glad to see that BMW is bringing an electric car to market. That's cool.",
    author: "Elon Musk",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "believe",
      "planners",
      "deciders",
      "making",
      "decisions",
      "on",
      "behalf",
      "americans",
    ],
    words: [
      "we",
      "don't",
      "believe",
      "in",
      "planners",
      "and",
      "deciders",
      "making",
      "the",
      "decisions",
      "on",
      "behalf",
      "of",
      "americans",
    ],
    id: "We don't believe in planners and deciders making the decisions on behalf of Americans.",
    author: "George W. Bush",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["lord", "i'm", "hold", "steady", "on", "you've", "got", "through"],
    words: [
      "lord",
      "i'm",
      "going",
      "to",
      "hold",
      "steady",
      "on",
      "you",
      "and",
      "you've",
      "got",
      "see",
      "me",
      "through",
    ],
    id: "Lord, I'm going to hold steady on to You and You've got to see me through.",
    author: "Harriet Tubman",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "children", "should", "vaccinated", "because", "affects", "health", "all"],
    words: [
      "i",
      "think",
      "children",
      "should",
      "be",
      "vaccinated",
      "because",
      "that",
      "affects",
      "the",
      "health",
      "of",
      "all",
      "other",
    ],
    id: "I think children should be vaccinated because that affects the health of all the other children.",
    author: "Amanda Peet",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "believe", "should", "chance", "show", "how", "at", "arsenal"],
    words: [
      "but",
      "i",
      "believe",
      "should",
      "have",
      "the",
      "chance",
      "to",
      "show",
      "how",
      "good",
      "am",
      "at",
      "arsenal",
    ],
    id: "But I believe I should have the chance to show how good I am at Arsenal.",
    author: "Emiliano Martinez",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["diplomacy", "art", "saying", "nice", "doggie", "until", "find", "rock"],
    words: [
      "diplomacy",
      "is",
      "the",
      "art",
      "of",
      "saying",
      "nice",
      "doggie",
      "until",
      "you",
      "can",
      "find",
      "a",
      "rock",
    ],
    id: "Diplomacy is the art of saying 'Nice doggie' until you can find a rock.",
    author: "Will Rogers",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["sometimes", "i've", "called", "writing", "disease", "i'm", "glad", "caught"],
    words: [
      "sometimes",
      "i've",
      "called",
      "writing",
      "a",
      "disease",
      "if",
      "so",
      "i'm",
      "glad",
      "that",
      "it",
      "caught",
      "me",
    ],
    id: "Sometimes I've called writing a disease. If so, I'm glad that it caught me.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "forms", "caution", "perhaps", "most", "fatal", "true", "happiness"],
    words: [
      "of",
      "all",
      "forms",
      "caution",
      "in",
      "love",
      "is",
      "perhaps",
      "the",
      "most",
      "fatal",
      "to",
      "true",
      "happiness",
    ],
    id: "Of all forms of caution, caution in love is perhaps the most fatal to true happiness.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["perseverance", "long", "race", "many", "short", "races", "one", "after"],
    words: [
      "perseverance",
      "is",
      "not",
      "a",
      "long",
      "race",
      "it",
      "many",
      "short",
      "races",
      "one",
      "after",
      "the",
      "other",
    ],
    id: "Perseverance is not a long race; it is many short races one after the other.",
    author: "Walter Elliot",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["somebody", "gracious", "enough", "second", "chance", "i", "won't", "third"],
    words: [
      "if",
      "somebody",
      "is",
      "gracious",
      "enough",
      "to",
      "give",
      "me",
      "a",
      "second",
      "chance",
      "i",
      "won't",
      "need",
      "third",
    ],
    id: "If somebody is gracious enough to give me a second chance, I won't need a third.",
    author: "Pete Rose",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["little", "dangerous", "thing", "deep", "or", "taste", "pierian", "spring"],
    words: [
      "a",
      "little",
      "learning",
      "is",
      "dangerous",
      "thing",
      "drink",
      "deep",
      "or",
      "taste",
      "not",
      "the",
      "pierian",
      "spring",
    ],
    id: "A little learning is a dangerous thing; Drink deep, or taste not the Pierian spring.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["easy", "being", "humorist", "when", "you've", "got", "whole", "government"],
    words: [
      "it's",
      "easy",
      "being",
      "a",
      "humorist",
      "when",
      "you've",
      "got",
      "the",
      "whole",
      "government",
      "working",
      "for",
      "you",
    ],
    id: "It's easy being a humorist when you've got the whole government working for you.",
    author: "Will Rogers",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["hope", "being", "able", "there", "light", "despite", "all", "darkness"],
    words: [
      "hope",
      "is",
      "being",
      "able",
      "to",
      "see",
      "that",
      "there",
      "light",
      "despite",
      "all",
      "of",
      "the",
      "darkness",
    ],
    id: "Hope is being able to see that there is light despite all of the darkness.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["even", "one", "life", "breathed", "easier", "because", "lived", "succeeded"],
    words: [
      "to",
      "know",
      "even",
      "one",
      "life",
      "has",
      "breathed",
      "easier",
      "because",
      "you",
      "have",
      "lived",
      "this",
      "is",
      "succeeded",
    ],
    id: "To know even one life has breathed easier because you have lived. This is to have succeeded.",
    author: "Bessie Anderson Stanley",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["onion", "make", "cry", "there's", "never", "been", "vegetable", "laugh"],
    words: [
      "an",
      "onion",
      "can",
      "make",
      "people",
      "cry",
      "but",
      "there's",
      "never",
      "been",
      "a",
      "vegetable",
      "that",
      "laugh",
    ],
    id: "An onion can make people cry but there's never been a vegetable that can make people laugh.",
    author: "Will Rogers",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "time", "departure", "even", "when", "there's", "certain", "place"],
    words: [
      "there",
      "is",
      "a",
      "time",
      "for",
      "departure",
      "even",
      "when",
      "there's",
      "no",
      "certain",
      "place",
      "to",
      "go",
    ],
    id: "There is a time for departure even when there's no certain place to go.",
    author: "Tennessee Williams",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["rest", "satisfied", "doing", "leave", "others", "talk", "as", "will"],
    words: [
      "rest",
      "satisfied",
      "with",
      "doing",
      "well",
      "and",
      "leave",
      "others",
      "to",
      "talk",
      "of",
      "you",
      "as",
      "they",
      "will",
    ],
    id: "Rest satisfied with doing well, and leave others to talk of you as they will.",
    author: "Pythagoras",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "more",
      "interested",
      "making",
      "others",
      "believe",
      "than",
      "trying",
      "ourselves",
    ],
    words: [
      "we",
      "are",
      "more",
      "interested",
      "in",
      "making",
      "others",
      "believe",
      "happy",
      "than",
      "trying",
      "to",
      "be",
      "ourselves",
    ],
    id: "We are more interested in making others believe we are happy than in trying to be happy ourselves.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["any", "idiot", "face", "crisis", "-", "living", "wears", "out"],
    words: [
      "any",
      "idiot",
      "can",
      "face",
      "a",
      "crisis",
      "-",
      "it's",
      "day",
      "to",
      "living",
      "that",
      "wears",
      "you",
      "out",
    ],
    id: "Any idiot can face a crisis - it's day to day living that wears you out.",
    author: "Anton Chekhov",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "problems",
      "price",
      "progress",
      "bring",
      "anything",
      "trouble",
      "news",
      "weakens",
    ],
    words: [
      "problems",
      "are",
      "the",
      "price",
      "of",
      "progress",
      "don't",
      "bring",
      "me",
      "anything",
      "but",
      "trouble",
      "good",
      "news",
      "weakens",
    ],
    id: "Problems are the price of progress. Don't bring me anything but trouble. Good news weakens me.",
    author: "Charles Kettering",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "most",
      "creative",
      "motivated",
      "by",
      "grandest",
      "problems",
      "presented",
      "before",
    ],
    words: [
      "the",
      "most",
      "creative",
      "people",
      "are",
      "motivated",
      "by",
      "grandest",
      "of",
      "problems",
      "that",
      "presented",
      "before",
      "them",
    ],
    id: "The most creative people are motivated by the grandest of problems that are presented before them.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "believe", "god", "planted", "heart", "desire", "live", "freedom"],
    words: [
      "i",
      "believe",
      "that",
      "god",
      "has",
      "planted",
      "in",
      "every",
      "heart",
      "the",
      "desire",
      "to",
      "live",
      "freedom",
    ],
    id: "I believe that God has planted in every heart the desire to live in freedom.",
    author: "George W. Bush",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["world", "as", "rule", "live", "on", "beaches", "country", "clubs"],
    words: [
      "the",
      "world",
      "as",
      "a",
      "rule",
      "does",
      "not",
      "live",
      "on",
      "beaches",
      "and",
      "in",
      "country",
      "clubs",
    ],
    id: "The world, as a rule, does not live on beaches and in country clubs.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["writer", "possesses", "only", "his", "own", "spirit", "also", "friends"],
    words: [
      "a",
      "good",
      "writer",
      "possesses",
      "not",
      "only",
      "his",
      "own",
      "spirit",
      "but",
      "also",
      "the",
      "of",
      "friends",
    ],
    id: "A good writer possesses not only his own spirit but also the spirit of his friends.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "man",
      "called",
      "selfish",
      "pursuing",
      "his",
      "own",
      "neglecting",
      "neighbor's",
    ],
    words: [
      "a",
      "man",
      "is",
      "called",
      "selfish",
      "not",
      "for",
      "pursuing",
      "his",
      "own",
      "good",
      "but",
      "neglecting",
      "neighbor's",
    ],
    id: "A man is called selfish not for pursuing his own good, but for neglecting his neighbor's.",
    author: "Richard Whately",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["royal", "road", "man's", "heart", "talk", "things", "treasures", "most"],
    words: [
      "the",
      "royal",
      "road",
      "to",
      "a",
      "man's",
      "heart",
      "is",
      "talk",
      "him",
      "about",
      "things",
      "he",
      "treasures",
      "most",
    ],
    id: "The royal road to a man's heart is to talk to him about the things he treasures most.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["we've", "climbed", "mighty", "mountain", "i", "valley", "below", "peace"],
    words: [
      "we've",
      "climbed",
      "the",
      "mighty",
      "mountain",
      "i",
      "see",
      "valley",
      "below",
      "and",
      "it's",
      "a",
      "of",
      "peace",
    ],
    id: "We've climbed the mighty mountain. I see the valley below, and it's a valley of peace.",
    author: "George W. Bush",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "philosophy", "i'm", "only", "dread", "one", "at", "time"],
    words: [
      "i",
      "have",
      "a",
      "new",
      "philosophy",
      "i'm",
      "only",
      "going",
      "to",
      "dread",
      "one",
      "day",
      "at",
      "time",
    ],
    id: "I have a new philosophy. I'm only going to dread one day at a time.",
    author: "Charles M. Schulz",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["trust", "same", "as", "faith", "friend", "putting", "anyone", "mistake"],
    words: [
      "trust",
      "is",
      "not",
      "the",
      "same",
      "as",
      "faith",
      "a",
      "friend",
      "someone",
      "you",
      "putting",
      "in",
      "anyone",
      "mistake",
    ],
    id: "Trust is not the same as faith. A friend is someone you trust. Putting faith in anyone is a mistake.",
    author: "Christopher Hitchens",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "all",
      "truths",
      "easy",
      "understand",
      "once",
      "discovered",
      "point",
      "discover",
    ],
    words: [
      "all",
      "truths",
      "are",
      "easy",
      "to",
      "understand",
      "once",
      "they",
      "discovered",
      "the",
      "point",
      "is",
      "discover",
      "them",
    ],
    id: "All truths are easy to understand once they are discovered; the point is to discover them.",
    author: "Galileo Galilei",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "oppose", "all", "wars", "opposed", "dumb", "war", "rash"],
    words: [
      "i",
      "don't",
      "oppose",
      "all",
      "wars",
      "what",
      "am",
      "opposed",
      "to",
      "is",
      "a",
      "dumb",
      "war",
      "rash",
    ],
    id: "I don't oppose all wars. What I am opposed to is a dumb war. What I am opposed to is a rash war.",
    author: "Barack Obama",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "i",
      "plenty",
      "unlike",
      "hollywood",
      "celebrities",
      "or",
      "athletes",
      "invested",
    ],
    words: [
      "i",
      "have",
      "plenty",
      "of",
      "money",
      "unlike",
      "other",
      "hollywood",
      "celebrities",
      "or",
      "athletes",
      "that",
      "not",
      "invested",
      "well",
    ],
    id: "I have plenty of money, unlike other Hollywood celebrities or athletes that have not invested well.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "sustaining",
      "true",
      "friendship",
      "lot",
      "more",
      "challenging",
      "than",
      "credit",
    ],
    words: [
      "sustaining",
      "true",
      "friendship",
      "is",
      "a",
      "lot",
      "more",
      "challenging",
      "than",
      "we",
      "give",
      "it",
      "credit",
      "for",
    ],
    id: "Sustaining true friendship is a lot more challenging than we give it credit for.",
    author: "Mariella Frostrup",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "lose", "change", "everything", "correct", "things", "by", "hard"],
    words: [
      "when",
      "we",
      "lose",
      "don't",
      "have",
      "to",
      "change",
      "everything",
      "but",
      "correct",
      "things",
      "by",
      "working",
      "hard",
    ],
    id: "When we lose, we don't have to change everything but correct things by working hard.",
    author: "Neymar",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["truth", "daughter", "time", "i", "feel", "shame", "being", "midwife"],
    words: [
      "truth",
      "is",
      "the",
      "daughter",
      "of",
      "time",
      "and",
      "i",
      "feel",
      "no",
      "shame",
      "in",
      "being",
      "her",
      "midwife",
    ],
    id: "Truth is the daughter of time, and I feel no shame in being her midwife.",
    author: "Johannes Kepler",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["success", "lousy", "teacher", "seduces", "smart", "into", "can't", "lose"],
    words: [
      "success",
      "is",
      "a",
      "lousy",
      "teacher",
      "it",
      "seduces",
      "smart",
      "people",
      "into",
      "thinking",
      "they",
      "can't",
      "lose",
    ],
    id: "Success is a lousy teacher. It seduces smart people into thinking they can't lose.",
    author: "Bill Gates",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["gee", "its", "lonesome", "outfield", "hard", "keep", "awake", "nothing"],
    words: [
      "gee",
      "its",
      "lonesome",
      "in",
      "the",
      "outfield",
      "it's",
      "hard",
      "to",
      "keep",
      "awake",
      "with",
      "nothing",
      "do",
    ],
    id: "Gee, its lonesome in the outfield. It's hard to keep awake with nothing to do.",
    author: "Babe Ruth",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "nothing", "socialism", "little", "age", "or", "will", "cure"],
    words: [
      "there",
      "is",
      "nothing",
      "in",
      "socialism",
      "that",
      "a",
      "little",
      "age",
      "or",
      "money",
      "will",
      "not",
      "cure",
    ],
    id: "There is nothing in socialism that a little age or a little money will not cure.",
    author: "Will Durant",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "forgiveness",
      "hard",
      "man",
      "most",
      "american-western",
      "males",
      "sign",
      "weakness",
    ],
    words: [
      "forgiveness",
      "is",
      "hard",
      "for",
      "me",
      "man",
      "it",
      "most",
      "american-western",
      "males",
      "it's",
      "a",
      "sign",
      "of",
      "weakness",
    ],
    id: "Forgiveness is hard for me, man. It is for most American-Western males. It's a sign of weakness.",
    author: "Art Alexakis",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "selfishness",
      "must",
      "always",
      "forgiven",
      "because",
      "there",
      "hope",
      "cure",
    ],
    words: [
      "selfishness",
      "must",
      "always",
      "be",
      "forgiven",
      "you",
      "know",
      "because",
      "there",
      "is",
      "no",
      "hope",
      "of",
      "a",
      "cure",
    ],
    id: "Selfishness must always be forgiven you know, because there is no hope of a cure.",
    author: "Jane Austen",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["serious", "changing", "your", "life", "you'll", "find", "way", "excuse"],
    words: [
      "if",
      "you're",
      "serious",
      "about",
      "changing",
      "your",
      "life",
      "you'll",
      "find",
      "a",
      "way",
      "not",
      "an",
      "excuse",
    ],
    id: "If you're serious about changing your life, you'll find a way. If you're not, you'll find an excuse.",
    author: "Jen Sincero",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "anterior",
      "life",
      "posterior",
      "death",
      "initial",
      "creation",
      "exponent",
      "breath",
    ],
    words: [
      "love",
      "is",
      "anterior",
      "to",
      "life",
      "posterior",
      "death",
      "initial",
      "of",
      "creation",
      "and",
      "the",
      "exponent",
      "breath",
    ],
    id: "Love is anterior to life, posterior to death, initial of creation, and the exponent of breath.",
    author: "Emily Dickinson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["time", "flies", "especially", "when", "great", "winning", "team", "playing"],
    words: [
      "time",
      "flies",
      "especially",
      "when",
      "you",
      "have",
      "a",
      "great",
      "you're",
      "winning",
      "and",
      "the",
      "team",
      "is",
      "playing",
    ],
    id: "Time flies. Especially when you have a great time, when you're winning and the team is playing great.",
    author: "Bojan Bogdanovic",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "putting",
      "wisdom",
      "-",
      "partly",
      "natural",
      "gift",
      "accumulation",
      "experience",
    ],
    words: [
      "putting",
      "is",
      "like",
      "wisdom",
      "-",
      "partly",
      "a",
      "natural",
      "gift",
      "and",
      "the",
      "accumulation",
      "of",
      "experience",
    ],
    id: "Putting is like wisdom - partly a natural gift and partly the accumulation of experience.",
    author: "Arnold Palmer",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["truly", "laugh", "must", "able", "take", "your", "pain", "play"],
    words: [
      "to",
      "truly",
      "laugh",
      "you",
      "must",
      "be",
      "able",
      "take",
      "your",
      "pain",
      "and",
      "play",
      "with",
      "it",
    ],
    id: "To truly laugh, you must be able to take your pain, and play with it!",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["stop", "knowledge", "from", "forward", "ignorance", "never", "better", "than"],
    words: [
      "it",
      "is",
      "no",
      "good",
      "to",
      "try",
      "stop",
      "knowledge",
      "from",
      "going",
      "forward",
      "ignorance",
      "never",
      "better",
      "than",
    ],
    id: "It is no good to try to stop knowledge from going forward. Ignorance is never better than knowledge.",
    author: "Enrico Fermi",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["trust", "yourself", "your", "defects", "make", "use", "friend", "foe"],
    words: [
      "trust",
      "not",
      "yourself",
      "but",
      "your",
      "defects",
      "to",
      "know",
      "make",
      "use",
      "of",
      "every",
      "friend",
      "and",
      "foe",
    ],
    id: "Trust not yourself, but your defects to know, make use of every friend and every foe.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "history",
      "philosophy",
      "great",
      "extent",
      "certain",
      "clash",
      "human",
      "temperaments",
    ],
    words: [
      "the",
      "history",
      "of",
      "philosophy",
      "is",
      "to",
      "a",
      "great",
      "extent",
      "that",
      "certain",
      "clash",
      "human",
      "temperaments",
    ],
    id: "The history of philosophy is to a great extent that of a certain clash of human temperaments.",
    author: "William James",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["great", "act", "cleverness", "able", "conceal", "one's", "being", "clever"],
    words: [
      "it",
      "is",
      "a",
      "great",
      "act",
      "of",
      "cleverness",
      "to",
      "be",
      "able",
      "conceal",
      "one's",
      "being",
      "clever",
    ],
    id: "It is a great act of cleverness to be able to conceal one's being clever.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["chance", "rise", "thoughts", "removes", "art", "keep", "or", "acquire"],
    words: [
      "chance",
      "gives",
      "rise",
      "to",
      "thoughts",
      "and",
      "removes",
      "them",
      "no",
      "art",
      "can",
      "keep",
      "or",
      "acquire",
    ],
    id: "Chance gives rise to thoughts, and chance removes them; no art can keep or acquire them.",
    author: "Blaise Pascal",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["fortune", "dealt", "bad", "cards", "then", "wisdom", "make", "gamester"],
    words: [
      "has",
      "fortune",
      "dealt",
      "you",
      "some",
      "bad",
      "cards",
      "then",
      "let",
      "wisdom",
      "make",
      "a",
      "good",
      "gamester",
    ],
    id: "Has fortune dealt you some bad cards. Then let wisdom make you a good gamester.",
    author: "Francis Quarles",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["yourself", "chance", "how", "much", "better", "life", "will", "get"],
    words: [
      "give",
      "yourself",
      "a",
      "chance",
      "to",
      "see",
      "how",
      "much",
      "better",
      "life",
      "will",
      "get",
      "and",
      "it",
    ],
    id: "Give yourself a chance to see how much better life will get. And it will get better.",
    author: "Joel Burns",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "reading",
      "all",
      "books",
      "conversation",
      "finest",
      "minds",
      "past",
      "centuries",
    ],
    words: [
      "the",
      "reading",
      "of",
      "all",
      "good",
      "books",
      "is",
      "like",
      "a",
      "conversation",
      "with",
      "finest",
      "minds",
      "past",
      "centuries",
    ],
    id: "The reading of all good books is like a conversation with the finest minds of past centuries.",
    author: "Rene Descartes",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "never", "met", "man", "ignorant", "couldn't", "something", "from"],
    words: [
      "i",
      "have",
      "never",
      "met",
      "a",
      "man",
      "so",
      "ignorant",
      "that",
      "couldn't",
      "learn",
      "something",
      "from",
      "him",
    ],
    id: "I have never met a man so ignorant that I couldn't learn something from him.",
    author: "Galileo Galilei",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["our", "greatest", "glory", "never", "falling", "rising", "time", "fall"],
    words: [
      "our",
      "greatest",
      "glory",
      "is",
      "not",
      "in",
      "never",
      "falling",
      "but",
      "rising",
      "every",
      "time",
      "we",
      "fall",
    ],
    id: "Our greatest glory is not in never falling, but in rising every time we fall.",
    author: "Confucius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["did", "flatter", "ourselves", "flattery", "others", "could", "never", "harm"],
    words: [
      "if",
      "we",
      "did",
      "not",
      "flatter",
      "ourselves",
      "the",
      "flattery",
      "of",
      "others",
      "could",
      "never",
      "harm",
      "us",
    ],
    id: "If we did not flatter ourselves, the flattery of others could never harm us.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "can't",
      "happiness",
      "make",
      "awfully",
      "comfortable",
      "while",
      "being",
      "miserable",
    ],
    words: [
      "money",
      "can't",
      "buy",
      "happiness",
      "but",
      "it",
      "can",
      "make",
      "you",
      "awfully",
      "comfortable",
      "while",
      "you're",
      "being",
      "miserable",
    ],
    id: "Money can't buy happiness, but it can make you awfully comfortable while you're being miserable.",
    author: "Clare Boothe Luce",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "knowing",
      "completely",
      "forgiven",
      "destroys",
      "power",
      "sin",
      "your",
      "life",
    ],
    words: [
      "knowing",
      "that",
      "you",
      "are",
      "completely",
      "forgiven",
      "destroys",
      "the",
      "power",
      "of",
      "sin",
      "in",
      "your",
      "life",
    ],
    id: "Knowing that you are completely forgiven destroys the power of sin in your life.",
    author: "Joseph Prince",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["believe", "science", "i", "there", "certain", "laws", "always", "obeyed"],
    words: [
      "if",
      "you",
      "believe",
      "in",
      "science",
      "like",
      "i",
      "do",
      "that",
      "there",
      "are",
      "certain",
      "laws",
      "always",
      "obeyed",
    ],
    id: "If you believe in science, like I do, you believe that there are certain laws that are always obeyed.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "perhaps",
      "mission",
      "artist",
      "interpret",
      "beauty",
      "-",
      "within",
      "themselves",
    ],
    words: [
      "perhaps",
      "the",
      "mission",
      "of",
      "an",
      "artist",
      "is",
      "to",
      "interpret",
      "beauty",
      "people",
      "-",
      "within",
      "themselves",
    ],
    id: "Perhaps the mission of an artist is to interpret beauty to people - the beauty within themselves.",
    author: "Langston Hughes",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["wanted", "will", "believe", "find", "out", "which", "exact", "opposite"],
    words: [
      "what",
      "is",
      "wanted",
      "not",
      "the",
      "will",
      "to",
      "believe",
      "but",
      "find",
      "out",
      "which",
      "exact",
      "opposite",
    ],
    id: "What is wanted is not the will to believe, but the will to find out, which is the exact opposite.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "impossible",
      "live",
      "pleasant",
      "life",
      "without",
      "living",
      "wisely",
      "justly",
    ],
    words: [
      "it",
      "is",
      "impossible",
      "to",
      "live",
      "a",
      "pleasant",
      "life",
      "without",
      "living",
      "wisely",
      "and",
      "well",
      "justly",
    ],
    id: "It is impossible to live a pleasant life without living wisely and well and justly. And it is impossible to live wisely and well and justly without living a pleasant life.",
    author: "Epicurus",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["never", "put", "your", "against", "cassius", "clay", "will", "lucky"],
    words: [
      "never",
      "put",
      "your",
      "money",
      "against",
      "cassius",
      "clay",
      "for",
      "you",
      "will",
      "have",
      "a",
      "lucky",
      "day",
    ],
    id: "Never put your money against Cassius Clay, for you will never have a lucky day.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["as", "soon", "kindle", "fire", "snow", "seek", "quench", "words"],
    words: [
      "as",
      "soon",
      "go",
      "kindle",
      "fire",
      "with",
      "snow",
      "seek",
      "to",
      "quench",
      "the",
      "of",
      "love",
      "words",
    ],
    id: "As soon go kindle fire with snow, as seek to quench the fire of love with words.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["lord", "grant", "i", "might", "much", "seek", "loved", "as"],
    words: [
      "lord",
      "grant",
      "that",
      "i",
      "might",
      "not",
      "so",
      "much",
      "seek",
      "to",
      "be",
      "loved",
      "as",
      "love",
    ],
    id: "Lord, grant that I might not so much seek to be loved as to love.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "marriage", "civil", "right", "being", "able", "transfer", "property"],
    words: [
      "i",
      "don't",
      "think",
      "marriage",
      "is",
      "a",
      "civil",
      "right",
      "but",
      "that",
      "being",
      "able",
      "to",
      "transfer",
      "property",
    ],
    id: "I don't think marriage is a civil right, but I think that being able to transfer property is a civil right.",
    author: "Barack Obama",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "measure",
      "democracy",
      "by",
      "freedom",
      "its",
      "dissidents",
      "assimilated",
      "conformists",
    ],
    words: [
      "you",
      "measure",
      "a",
      "democracy",
      "by",
      "the",
      "freedom",
      "it",
      "gives",
      "its",
      "dissidents",
      "not",
      "assimilated",
      "conformists",
    ],
    id: "You measure a democracy by the freedom it gives its dissidents, not the freedom it gives its assimilated conformists.",
    author: "Abbie Hoffman",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "all", "things", "come", "hard", "wisdom", "hardest", "by"],
    words: [
      "in",
      "life",
      "all",
      "good",
      "things",
      "come",
      "hard",
      "but",
      "wisdom",
      "is",
      "the",
      "hardest",
      "to",
      "by",
    ],
    id: "In life, all good things come hard, but wisdom is the hardest to come by.",
    author: "Lucille Ball",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["stylus", "get", "em", "put", "away", "lose", "yuck", "nobody"],
    words: [
      "who",
      "wants",
      "a",
      "stylus",
      "you",
      "have",
      "to",
      "get",
      "em",
      "and",
      "put",
      "away",
      "lose",
      "yuck",
      "nobody",
    ],
    id: "Who wants a stylus. You have to get em and put em away, and you lose em. Yuck. Nobody wants a stylus.",
    author: "Steve Jobs",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "through",
      "gratitude",
      "present",
      "spiritual",
      "dimension",
      "life",
      "opens",
      "up",
    ],
    words: [
      "it",
      "is",
      "through",
      "gratitude",
      "for",
      "the",
      "present",
      "moment",
      "that",
      "spiritual",
      "dimension",
      "of",
      "life",
      "opens",
      "up",
    ],
    id: "It is through gratitude for the present moment that the spiritual dimension of life opens up.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "really",
      "raises",
      "one's",
      "indignation",
      "against",
      "suffering",
      "intrinsically",
      "senselessness",
    ],
    words: [
      "what",
      "really",
      "raises",
      "one's",
      "indignation",
      "against",
      "suffering",
      "is",
      "not",
      "intrinsically",
      "but",
      "the",
      "senselessness",
      "of",
    ],
    id: "What really raises one's indignation against suffering is not suffering intrinsically, but the senselessness of suffering.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "way", "joking", "tell", "truth", "funniest", "joke", "world"],
    words: [
      "my",
      "way",
      "of",
      "joking",
      "is",
      "to",
      "tell",
      "the",
      "truth",
      "that's",
      "funniest",
      "joke",
      "in",
      "world",
    ],
    id: "My way of joking is to tell the truth. That's the funniest joke in the world.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "calm",
      "mind",
      "brings",
      "inner",
      "strength",
      "self-confidence",
      "important",
      "health",
    ],
    words: [
      "calm",
      "mind",
      "brings",
      "inner",
      "strength",
      "and",
      "self-confidence",
      "so",
      "that's",
      "very",
      "important",
      "for",
      "good",
      "health",
    ],
    id: "Calm mind brings inner strength and self-confidence, so that's very important for good health.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "necessary",
      "establish",
      "winner",
      "image",
      "therefore",
      "i",
      "beat",
      "somebody",
    ],
    words: [
      "it",
      "is",
      "necessary",
      "for",
      "me",
      "to",
      "establish",
      "a",
      "winner",
      "image",
      "therefore",
      "i",
      "have",
      "beat",
      "somebody",
    ],
    id: "It is necessary for me to establish a winner image. Therefore, I have to beat somebody.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "name",
      "pretense",
      "virtue",
      "as",
      "serviceable",
      "self-interest",
      "real",
      "vices",
    ],
    words: [
      "the",
      "name",
      "and",
      "pretense",
      "of",
      "virtue",
      "is",
      "as",
      "serviceable",
      "to",
      "self-interest",
      "are",
      "real",
      "vices",
    ],
    id: "The name and pretense of virtue is as serviceable to self-interest as are real vices.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "life",
      "growth",
      "stop",
      "growing",
      "technically",
      "spiritually",
      "as",
      "dead",
    ],
    words: [
      "life",
      "is",
      "growth",
      "if",
      "we",
      "stop",
      "growing",
      "technically",
      "and",
      "spiritually",
      "are",
      "as",
      "good",
      "dead",
    ],
    id: "Life is growth. If we stop growing, technically and spiritually, we are as good as dead.",
    author: "Morihei Ueshiba",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["film", "i", "doing", "things", "either", "myself", "or", "filmmaking"],
    words: [
      "with",
      "every",
      "film",
      "that",
      "i",
      "am",
      "doing",
      "learning",
      "new",
      "things",
      "either",
      "about",
      "myself",
      "or",
      "filmmaking",
    ],
    id: "With every film that I am doing I am learning new things either about myself, or about filmmaking.",
    author: "Sanya Malhotra",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "perceive",
      "advantage",
      "defeating",
      "enemy",
      "must",
      "also",
      "their",
      "rewards",
    ],
    words: [
      "for",
      "them",
      "to",
      "perceive",
      "the",
      "advantage",
      "of",
      "defeating",
      "enemy",
      "they",
      "must",
      "also",
      "have",
      "their",
      "rewards",
    ],
    id: "For them to perceive the advantage of defeating the enemy, they must also have their rewards.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "courageous",
      "enough",
      "take",
      "risks",
      "will",
      "accomplish",
      "nothing",
      "life",
    ],
    words: [
      "he",
      "who",
      "is",
      "not",
      "courageous",
      "enough",
      "to",
      "take",
      "risks",
      "will",
      "accomplish",
      "nothing",
      "in",
      "life",
    ],
    id: "He who is not courageous enough to take risks will accomplish nothing in life.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["been", "struggle", "because", "i", "had", "chance", "white", "refused"],
    words: [
      "it's",
      "been",
      "a",
      "struggle",
      "for",
      "me",
      "because",
      "i",
      "had",
      "chance",
      "to",
      "be",
      "white",
      "and",
      "refused",
    ],
    id: "It's been a struggle for me because I had a chance to be white and refused.",
    author: "Richard Pryor",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["takes", "many", "deeds", "reputation", "only", "one", "bad", "lose"],
    words: [
      "it",
      "takes",
      "many",
      "good",
      "deeds",
      "to",
      "build",
      "a",
      "reputation",
      "and",
      "only",
      "one",
      "bad",
      "lose",
    ],
    id: "It takes many good deeds to build a good reputation, and only one bad one to lose it.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["best", "time", "plant", "was", "25", "years", "ago", "second-best"],
    words: [
      "the",
      "best",
      "time",
      "to",
      "plant",
      "a",
      "tree",
      "was",
      "25",
      "years",
      "ago",
      "second-best",
      "is",
      "today",
    ],
    id: "The best time to plant a tree was 25 years ago. The second-best time to plant a tree is today.",
    author: "Eliud Kipchoge",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "success",
      "persistence",
      "only",
      "afford",
      "persistent",
      "something",
      "deeply",
      "enjoy",
    ],
    words: [
      "success",
      "is",
      "about",
      "persistence",
      "you",
      "can",
      "only",
      "afford",
      "to",
      "be",
      "persistent",
      "in",
      "something",
      "deeply",
      "enjoy",
    ],
    id: "Success is about persistence. You can only afford to be persistent in something you deeply enjoy.",
    author: "Cenk Uygur",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["dream", "success", "while", "get", "up", "morning", "make", "happen"],
    words: [
      "some",
      "people",
      "dream",
      "of",
      "success",
      "while",
      "other",
      "get",
      "up",
      "every",
      "morning",
      "and",
      "make",
      "it",
      "happen",
    ],
    id: "Some people dream of success, while other people get up every morning and make it happen.",
    author: "Wayne Huizenga",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "secret",
      "freedom",
      "lies",
      "educating",
      "whereas",
      "tyranny",
      "keeping",
      "ignorant",
    ],
    words: [
      "the",
      "secret",
      "of",
      "freedom",
      "lies",
      "in",
      "educating",
      "people",
      "whereas",
      "tyranny",
      "is",
      "keeping",
      "them",
      "ignorant",
    ],
    id: "The secret of freedom lies in educating people, whereas the secret of tyranny is in keeping them ignorant.",
    author: "Maximilien Robespierre",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["without", "modesty", "will", "find", "difficult", "make", "his", "words"],
    words: [
      "he",
      "who",
      "speaks",
      "without",
      "modesty",
      "will",
      "find",
      "it",
      "difficult",
      "to",
      "make",
      "his",
      "words",
      "good",
    ],
    id: "He who speaks without modesty will find it difficult to make his words good.",
    author: "Confucius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["cannot", "kindness", "too", "soon", "never", "how", "will", "late"],
    words: [
      "you",
      "cannot",
      "do",
      "a",
      "kindness",
      "too",
      "soon",
      "for",
      "never",
      "know",
      "how",
      "it",
      "will",
      "be",
      "late",
    ],
    id: "You cannot do a kindness too soon, for you never know how soon it will be too late.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["brilliant", "ideas", "can't", "get", "across", "your", "won't", "anywhere"],
    words: [
      "you",
      "can",
      "have",
      "brilliant",
      "ideas",
      "but",
      "if",
      "can't",
      "get",
      "them",
      "across",
      "your",
      "won't",
      "anywhere",
    ],
    id: "You can have brilliant ideas, but if you can't get them across, your ideas won't get you anywhere.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "man",
      "creative",
      "cause",
      "happens",
      "primum",
      "mobile",
      "original",
      "movement",
    ],
    words: [
      "every",
      "man",
      "is",
      "a",
      "creative",
      "cause",
      "of",
      "what",
      "happens",
      "primum",
      "mobile",
      "with",
      "an",
      "original",
      "movement",
    ],
    id: "Every man is a creative cause of what happens, a primum mobile with an original movement.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["safeguarding", "rights", "others", "most", "noble", "end", "human", "being"],
    words: [
      "safeguarding",
      "the",
      "rights",
      "of",
      "others",
      "is",
      "most",
      "noble",
      "and",
      "beautiful",
      "end",
      "a",
      "human",
      "being",
    ],
    id: "Safeguarding the rights of others is the most noble and beautiful end of a human being.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["young", "these", "days", "trust", "anything", "at", "all", "free"],
    words: [
      "young",
      "people",
      "these",
      "days",
      "don't",
      "trust",
      "anything",
      "at",
      "all",
      "they",
      "want",
      "to",
      "be",
      "free",
    ],
    id: "Young people these days don't trust anything at all. They want to be free.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "great", "concern", "whether", "failed", "content", "your", "failure"],
    words: [
      "my",
      "great",
      "concern",
      "is",
      "not",
      "whether",
      "you",
      "have",
      "failed",
      "but",
      "are",
      "content",
      "with",
      "your",
      "failure",
    ],
    id: "My great concern is not whether you have failed, but whether you are content with your failure.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["notice", "taken", "little", "evil", "when", "increases", "strikes", "eye"],
    words: [
      "no",
      "notice",
      "is",
      "taken",
      "of",
      "a",
      "little",
      "evil",
      "but",
      "when",
      "it",
      "increases",
      "strikes",
      "the",
      "eye",
    ],
    id: "No notice is taken of a little evil, but when it increases it strikes the eye.",
    author: "Aristotle",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "regardless",
      "wins",
      "election",
      "should",
      "time",
      "optimism",
      "fresh",
      "approaches",
    ],
    words: [
      "regardless",
      "of",
      "who",
      "wins",
      "an",
      "election",
      "should",
      "be",
      "a",
      "time",
      "for",
      "optimism",
      "and",
      "fresh",
      "approaches",
    ],
    id: "Regardless of who wins, an election should be a time for optimism and fresh approaches.",
    author: "Gary Johnson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["make", "music", "must", "play", "black", "white", "notes", "together"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "make",
      "beautiful",
      "music",
      "must",
      "play",
      "the",
      "black",
      "and",
      "white",
      "notes",
      "together",
    ],
    id: "If you want to make beautiful music, you must play the black and the white notes together.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "greatest",
      "mistake",
      "make",
      "life",
      "continually",
      "fearing",
      "you'll",
      "one",
    ],
    words: [
      "the",
      "greatest",
      "mistake",
      "you",
      "can",
      "make",
      "in",
      "life",
      "is",
      "continually",
      "fearing",
      "that",
      "you'll",
      "one",
    ],
    id: "The greatest mistake you can make in life is continually fearing that you'll make one.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "formal", "gardens", "wild", "nature", "wilderness", "instinct", "guess"],
    words: [
      "i",
      "don't",
      "like",
      "formal",
      "gardens",
      "wild",
      "nature",
      "it's",
      "just",
      "the",
      "wilderness",
      "instinct",
      "in",
      "me",
      "guess",
    ],
    id: "I don't like formal gardens. I like wild nature. It's just the wilderness instinct in me, I guess.",
    author: "Walt Disney",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "obvious", "goals", "cannot", "reached", "adjust", "action", "steps"],
    words: [
      "when",
      "it",
      "is",
      "obvious",
      "that",
      "the",
      "goals",
      "cannot",
      "be",
      "reached",
      "don't",
      "adjust",
      "action",
      "steps",
    ],
    id: "When it is obvious that the goals cannot be reached, don't adjust the goals, adjust the action steps.",
    author: "Confucius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["one", "wonderful", "things", "oprah", "teaches", "keep", "on", "stepping"],
    words: [
      "one",
      "of",
      "the",
      "wonderful",
      "things",
      "about",
      "oprah",
      "she",
      "teaches",
      "you",
      "to",
      "keep",
      "on",
      "stepping",
    ],
    id: "One of the wonderful things about Oprah: She teaches you to keep on stepping.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["space", "general", "gave", "gps", "-", "specifically", "nasa", "investments"],
    words: [
      "space",
      "in",
      "general",
      "gave",
      "us",
      "gps",
      "-",
      "that's",
      "not",
      "specifically",
      "nasa",
      "but",
      "it's",
      "investments",
    ],
    id: "Space in general gave us GPS - that's not specifically NASA, but it's investments in space.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["god", "two", "dwellings", "one", "heaven", "meek", "thankful", "heart"],
    words: [
      "god",
      "has",
      "two",
      "dwellings",
      "one",
      "in",
      "heaven",
      "and",
      "the",
      "other",
      "a",
      "meek",
      "thankful",
      "heart",
    ],
    id: "God has two dwellings; one in heaven, and the other in a meek and thankful heart.",
    author: "Izaak Walton",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["friendship", "takes", "much", "time", "many", "years", "ruin", "seconds"],
    words: [
      "to",
      "build",
      "a",
      "friendship",
      "takes",
      "so",
      "much",
      "time",
      "and",
      "many",
      "years",
      "ruin",
      "it",
      "just",
      "seconds",
    ],
    id: "To build a friendship takes so much time and so many years. To ruin it, just seconds.",
    author: "Vlade Divac",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "comes", "time", "man's", "life", "i've", "had", "plenty"],
    words: [
      "there",
      "comes",
      "a",
      "time",
      "in",
      "every",
      "man's",
      "life",
      "and",
      "i've",
      "had",
      "plenty",
      "of",
      "them",
    ],
    id: "There comes a time in every man's life, and I've had plenty of them.",
    author: "Casey Stengel",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "fine", "line", "between", "loving", "life", "being", "greedy"],
    words: [
      "there",
      "is",
      "a",
      "very",
      "fine",
      "line",
      "between",
      "loving",
      "life",
      "and",
      "being",
      "greedy",
      "for",
      "it",
    ],
    id: "There is a very fine line between loving life and being greedy for it.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["world", "itself", "will", "power", "-", "nothing", "else", "yourself"],
    words: [
      "the",
      "world",
      "itself",
      "is",
      "will",
      "to",
      "power",
      "-",
      "and",
      "nothing",
      "else",
      "you",
      "yourself",
      "are",
    ],
    id: "The world itself is the will to power - and nothing else! And you yourself are the will to power - and nothing else!",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "did", "strip", "club", "didn't", "danced", "became", "popular"],
    words: [
      "i",
      "did",
      "work",
      "in",
      "a",
      "strip",
      "club",
      "but",
      "didn't",
      "danced",
      "and",
      "became",
      "very",
      "popular",
    ],
    id: "I did work in a strip club, but I didn't strip. I danced, and I became very popular.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["most", "sensual", "men", "flee", "women", "torment", "their", "bodies"],
    words: [
      "it",
      "is",
      "the",
      "most",
      "sensual",
      "men",
      "who",
      "need",
      "to",
      "flee",
      "women",
      "and",
      "torment",
      "their",
      "bodies",
    ],
    id: "It is the most sensual men who need to flee women and torment their bodies.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["liberty", "must", "refreshed", "from", "time", "blood", "patriots", "tyrants"],
    words: [
      "the",
      "tree",
      "of",
      "liberty",
      "must",
      "be",
      "refreshed",
      "from",
      "time",
      "to",
      "with",
      "blood",
      "patriots",
      "and",
      "tyrants",
    ],
    id: "The tree of liberty must be refreshed from time to time with the blood of patriots and tyrants.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "one",
      "miserable",
      "as",
      "poor",
      "person",
      "maintains",
      "appearance",
      "wealth",
    ],
    words: [
      "no",
      "one",
      "is",
      "so",
      "miserable",
      "as",
      "the",
      "poor",
      "person",
      "who",
      "maintains",
      "appearance",
      "of",
      "wealth",
    ],
    id: "No one is so miserable as the poor person who maintains the appearance of wealth.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["prepared", "war", "one", "most", "effective", "means", "preserving", "peace"],
    words: [
      "to",
      "be",
      "prepared",
      "for",
      "war",
      "is",
      "one",
      "of",
      "the",
      "most",
      "effective",
      "means",
      "preserving",
      "peace",
    ],
    id: "To be prepared for war is one of the most effective means of preserving peace.",
    author: "George Washington",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["often", "hands", "will", "solve", "mystery", "intellect", "struggled", "vain"],
    words: [
      "often",
      "the",
      "hands",
      "will",
      "solve",
      "a",
      "mystery",
      "that",
      "intellect",
      "has",
      "struggled",
      "with",
      "in",
      "vain",
    ],
    id: "Often the hands will solve a mystery that the intellect has struggled with in vain.",
    author: "Carl Jung",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["men", "must", "taught", "as", "things", "unknown", "proposed", "forgot"],
    words: [
      "men",
      "must",
      "be",
      "taught",
      "as",
      "if",
      "you",
      "them",
      "not",
      "and",
      "things",
      "unknown",
      "proposed",
      "forgot",
    ],
    id: "Men must be taught as if you taught them not, and things unknown proposed as things forgot.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["civility", "which", "will", "purchase", "rarely", "extended", "those", "none"],
    words: [
      "the",
      "civility",
      "which",
      "money",
      "will",
      "purchase",
      "is",
      "rarely",
      "extended",
      "to",
      "those",
      "who",
      "have",
      "none",
    ],
    id: "The civility which money will purchase, is rarely extended to those who have none.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["first", "point", "wisdom", "discern", "which", "false", "second", "true"],
    words: [
      "the",
      "first",
      "point",
      "of",
      "wisdom",
      "is",
      "to",
      "discern",
      "that",
      "which",
      "false",
      "second",
      "know",
      "true",
    ],
    id: "The first point of wisdom is to discern that which is false; the second, to know that which is true.",
    author: "Lactantius",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "hate", "threats", "from", "climate", "crisis", "deniers", "ignore"],
    words: [
      "i",
      "don't",
      "care",
      "about",
      "hate",
      "and",
      "threats",
      "from",
      "climate",
      "crisis",
      "deniers",
      "just",
      "ignore",
      "them",
    ],
    id: "I don't care about hate and threats from climate crisis deniers. I just ignore them.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["change", "means", "was", "before", "wasn't", "perfect", "things", "better"],
    words: [
      "change",
      "means",
      "that",
      "what",
      "was",
      "before",
      "wasn't",
      "perfect",
      "people",
      "want",
      "things",
      "to",
      "be",
      "better",
    ],
    id: "Change means that what was before wasn't perfect. People want things to be better.",
    author: "Esther Dyson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "following",
      "example",
      "united",
      "states",
      "america",
      "there",
      "will",
      "europe",
    ],
    words: [
      "some",
      "day",
      "following",
      "the",
      "example",
      "of",
      "united",
      "states",
      "america",
      "there",
      "will",
      "be",
      "a",
      "europe",
    ],
    id: "Some day, following the example of the United States of America, there will be a United States of Europe.",
    author: "George Washington",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "your",
      "success",
      "depends",
      "mainly",
      "upon",
      "yourself",
      "whether",
      "believe",
    ],
    words: [
      "your",
      "success",
      "depends",
      "mainly",
      "upon",
      "what",
      "you",
      "think",
      "of",
      "yourself",
      "and",
      "whether",
      "believe",
      "in",
    ],
    id: "Your success depends mainly upon what you think of yourself and whether you believe in yourself.",
    author: "William J. H. Boetcker",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "prayer",
      "strong",
      "wall",
      "fortress",
      "church",
      "goodly",
      "christian",
      "weapon",
    ],
    words: [
      "prayer",
      "is",
      "a",
      "strong",
      "wall",
      "and",
      "fortress",
      "of",
      "the",
      "church",
      "it",
      "goodly",
      "christian",
      "weapon",
    ],
    id: "Prayer is a strong wall and fortress of the church; it is a goodly Christian weapon.",
    author: "Martin Luther",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "progress",
      "without",
      "change",
      "whether",
      "basketball",
      "or",
      "anything",
      "else",
    ],
    words: [
      "we",
      "can",
      "have",
      "no",
      "progress",
      "without",
      "change",
      "whether",
      "it",
      "be",
      "basketball",
      "or",
      "anything",
      "else",
    ],
    id: "We can have no progress without change, whether it be basketball or anything else.",
    author: "John Wooden",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "here",
      "unlearn",
      "teachings",
      "church",
      "state",
      "our",
      "education",
      "system",
    ],
    words: [
      "we",
      "are",
      "here",
      "to",
      "unlearn",
      "the",
      "teachings",
      "of",
      "church",
      "state",
      "and",
      "our",
      "education",
      "system",
    ],
    id: "We are here to unlearn the teachings of the church, state, and our education system.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["end", "will", "remember", "words", "our", "enemies", "silence", "friends"],
    words: [
      "in",
      "the",
      "end",
      "we",
      "will",
      "remember",
      "not",
      "words",
      "of",
      "our",
      "enemies",
      "but",
      "silence",
      "friends",
    ],
    id: "In the End, we will remember not the words of our enemies, but the silence of our friends.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["papa", "potatoes", "poultry", "prunes", "prism", "all", "words", "lips"],
    words: [
      "papa",
      "potatoes",
      "poultry",
      "prunes",
      "and",
      "prism",
      "are",
      "all",
      "very",
      "good",
      "words",
      "for",
      "the",
      "lips",
    ],
    id: "Papa, potatoes, poultry, prunes and prism, are all very good words for the lips.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["had", "one", "eye", "popular", "prejudice", "runs", "favor", "two"],
    words: [
      "he",
      "had",
      "but",
      "one",
      "eye",
      "and",
      "the",
      "popular",
      "prejudice",
      "runs",
      "in",
      "favor",
      "of",
      "two",
    ],
    id: "He had but one eye, and the popular prejudice runs in favor of two.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "as", "much", "greatness", "mind", "acknowledging", "turn", "doing"],
    words: [
      "there",
      "is",
      "as",
      "much",
      "greatness",
      "of",
      "mind",
      "in",
      "acknowledging",
      "a",
      "good",
      "turn",
      "doing",
      "it",
    ],
    id: "There is as much greatness of mind in acknowledging a good turn, as in doing it.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["forgiveness", "yourself", "because", "frees", "lets", "out", "prison", "put"],
    words: [
      "forgiveness",
      "is",
      "for",
      "yourself",
      "because",
      "it",
      "frees",
      "you",
      "lets",
      "out",
      "of",
      "that",
      "prison",
      "put",
      "in",
    ],
    id: "Forgiveness is for yourself because it frees you. It lets you out of that prison you put yourself in.",
    author: "Louise L. Hay",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "royal", "road", "short", "cut", "acquirement", "any", "art"],
    words: [
      "there",
      "is",
      "no",
      "royal",
      "road",
      "to",
      "learning",
      "short",
      "cut",
      "the",
      "acquirement",
      "of",
      "any",
      "art",
    ],
    id: "There is no royal road to learning; no short cut to the acquirement of any art.",
    author: "Anthony Trollope",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "bravest",
      "sight",
      "world",
      "great",
      "man",
      "struggling",
      "against",
      "adversity",
    ],
    words: [
      "the",
      "bravest",
      "sight",
      "in",
      "world",
      "is",
      "to",
      "see",
      "a",
      "great",
      "man",
      "struggling",
      "against",
      "adversity",
    ],
    id: "The bravest sight in the world is to see a great man struggling against adversity.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "buildings",
      "storytellers",
      "teach",
      "our",
      "history",
      "bad",
      "tell",
      "stories",
    ],
    words: [
      "buildings",
      "are",
      "storytellers",
      "they",
      "teach",
      "us",
      "about",
      "our",
      "history",
      "good",
      "and",
      "bad",
      "tell",
      "stories",
      "people",
    ],
    id: "Buildings are storytellers. They teach us about our history, good and bad. They tell stories about people.",
    author: "George Clarke",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["cooking", "young", "man's", "game", "i", "bollocks", "anyone", "says"],
    words: [
      "cooking",
      "today",
      "is",
      "a",
      "young",
      "man's",
      "game",
      "i",
      "don't",
      "give",
      "bollocks",
      "what",
      "anyone",
      "says",
    ],
    id: "Cooking today is a young man's game, I don't give a bollocks what anyone says.",
    author: "Gordon Ramsay",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "nothing", "can't", "overcome", "never", "too", "late", "happiness"],
    words: [
      "there",
      "is",
      "nothing",
      "that",
      "you",
      "can't",
      "overcome",
      "so",
      "it's",
      "never",
      "too",
      "late",
      "for",
      "happiness",
    ],
    id: "There is nothing that you can't overcome. So it's never too late for happiness.",
    author: "Pepa",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["freedom", "choice", "more", "treasured", "than", "any", "possession", "earth"],
    words: [
      "freedom",
      "of",
      "choice",
      "is",
      "more",
      "to",
      "be",
      "treasured",
      "than",
      "any",
      "possession",
      "earth",
      "can",
      "give",
    ],
    id: "Freedom of choice is more to be treasured than any possession earth can give.",
    author: "David O. McKay",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "men", "will", "change", "there's", "only", "one", "woman"],
    words: [
      "all",
      "men",
      "can",
      "and",
      "will",
      "change",
      "but",
      "there's",
      "only",
      "one",
      "woman",
      "we're",
      "going",
      "to",
      "for",
    ],
    id: "All men can and will change. But there's only one woman we're going to change for.",
    author: "Steve Harvey",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "humanity", "bound", "up", "yours", "only", "human", "together"],
    words: [
      "my",
      "humanity",
      "is",
      "bound",
      "up",
      "in",
      "yours",
      "for",
      "we",
      "can",
      "only",
      "be",
      "human",
      "together",
    ],
    id: "My humanity is bound up in yours, for we can only be human together.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "freeman", "american", "united", "states", "senator", "democrat", "order"],
    words: [
      "i",
      "am",
      "a",
      "freeman",
      "an",
      "american",
      "united",
      "states",
      "senator",
      "and",
      "democrat",
      "in",
      "that",
      "order",
    ],
    id: "I am a freeman, an American, a United States Senator, and a Democrat, in that order.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "suffering",
      "positive",
      "element",
      "world",
      "indeed",
      "only",
      "link",
      "between",
    ],
    words: [
      "suffering",
      "is",
      "the",
      "positive",
      "element",
      "in",
      "this",
      "world",
      "indeed",
      "it",
      "only",
      "link",
      "between",
      "and",
    ],
    id: "Suffering is the positive element in this world, indeed it is the only link between this world and the positive.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["chef", "i", "their", "cholesterol", "through", "roof", "mine's", "great"],
    words: [
      "every",
      "chef",
      "i",
      "know",
      "their",
      "cholesterol",
      "is",
      "through",
      "the",
      "roof",
      "and",
      "mine's",
      "not",
      "so",
      "great",
    ],
    id: "Every chef I know, their cholesterol is through the roof. And mine's not so great.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["men", "stars", "planets", "ages", "wherein", "live", "illustrate", "times"],
    words: [
      "good",
      "men",
      "are",
      "the",
      "stars",
      "planets",
      "of",
      "ages",
      "wherein",
      "they",
      "live",
      "and",
      "illustrate",
      "times",
    ],
    id: "Good men are the stars, the planets of the ages wherein they live, and illustrate the times.",
    author: "Ben Jonson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "wrestles",
      "strengthens",
      "our",
      "nerves",
      "sharpens",
      "skill",
      "antagonist",
      "helper",
    ],
    words: [
      "he",
      "that",
      "wrestles",
      "with",
      "us",
      "strengthens",
      "our",
      "nerves",
      "and",
      "sharpens",
      "skill",
      "antagonist",
      "is",
      "helper",
    ],
    id: "He that wrestles with us strengthens our nerves and sharpens our skill. Our antagonist is our helper.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "nature",
      "never",
      "read",
      "declaration",
      "independence",
      "continues",
      "make",
      "unequal",
    ],
    words: [
      "nature",
      "has",
      "never",
      "read",
      "the",
      "declaration",
      "of",
      "independence",
      "it",
      "continues",
      "to",
      "make",
      "us",
      "unequal",
    ],
    id: "Nature has never read the Declaration of Independence. It continues to make us unequal.",
    author: "Will Durant",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["live", "our", "life", "example", "positive", "influence", "on", "someone's"],
    words: [
      "let's",
      "live",
      "our",
      "life",
      "to",
      "try",
      "be",
      "a",
      "good",
      "example",
      "and",
      "positive",
      "influence",
      "on",
      "someone's",
    ],
    id: "Let's live our life to try to be a good example and a positive influence on someone's life.",
    author: "Porsha Williams",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["best", "thing", "future", "comes", "only", "one", "at", "time"],
    words: [
      "the",
      "best",
      "thing",
      "about",
      "future",
      "is",
      "that",
      "it",
      "comes",
      "only",
      "one",
      "day",
      "at",
      "a",
      "time",
    ],
    id: "The best thing about the future is that it comes only one day at a time.",
    author: "Dean Acheson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["call", "upon", "supply", "american", "boys", "job", "asian", "should"],
    words: [
      "they",
      "call",
      "upon",
      "us",
      "to",
      "supply",
      "american",
      "boys",
      "do",
      "the",
      "job",
      "that",
      "asian",
      "should",
    ],
    id: "They call upon us to supply American boys to do the job that Asian boys should do.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["only", "dirty", "trick", "played", "on", "achieve", "continuation", "species"],
    words: [
      "love",
      "is",
      "only",
      "a",
      "dirty",
      "trick",
      "played",
      "on",
      "us",
      "to",
      "achieve",
      "continuation",
      "of",
      "the",
      "species",
    ],
    id: "Love is only a dirty trick played on us to achieve continuation of the species.",
    author: "W. Somerset Maugham",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["each", "lover's", "hour", "worth", "age", "dull", "common", "life"],
    words: [
      "each",
      "moment",
      "of",
      "a",
      "happy",
      "lover's",
      "hour",
      "is",
      "worth",
      "an",
      "age",
      "dull",
      "and",
      "common",
      "life",
    ],
    id: "Each moment of a happy lover's hour is worth an age of dull and common life.",
    author: "Aphra Behn",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "matter", "holding", "cards", "sometimes", "playing", "poor", "hand"],
    words: [
      "life",
      "is",
      "not",
      "a",
      "matter",
      "of",
      "holding",
      "good",
      "cards",
      "but",
      "sometimes",
      "playing",
      "poor",
      "hand",
      "well",
    ],
    id: "Life is not a matter of holding good cards, but sometimes, playing a poor hand well.",
    author: "Jack London",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "installed", "skylight", "my", "apartment", "live", "above", "furious"],
    words: [
      "i",
      "installed",
      "a",
      "skylight",
      "in",
      "my",
      "apartment",
      "the",
      "people",
      "who",
      "live",
      "above",
      "me",
      "are",
      "furious",
    ],
    id: "I installed a skylight in my apartment... the people who live above me are furious!",
    author: "Steven Wright",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "having",
      "family",
      "responsibilities",
      "concerns",
      "make",
      "more",
      "understanding",
      "person",
    ],
    words: [
      "having",
      "family",
      "responsibilities",
      "and",
      "concerns",
      "just",
      "has",
      "to",
      "make",
      "you",
      "a",
      "more",
      "understanding",
      "person",
    ],
    id: "Having family responsibilities and concerns just has to make you a more understanding person.",
    author: "Sandra Day O'Connor",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "those",
      "incapable",
      "committing",
      "great",
      "crimes",
      "readily",
      "suspect",
      "others",
    ],
    words: [
      "those",
      "who",
      "are",
      "incapable",
      "of",
      "committing",
      "great",
      "crimes",
      "do",
      "not",
      "readily",
      "suspect",
      "them",
      "in",
      "others",
    ],
    id: "Those who are incapable of committing great crimes do not readily suspect them in others.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "will",
      "quiet",
      "ready",
      "enough",
      "shall",
      "find",
      "compensation",
      "disappointment",
    ],
    words: [
      "if",
      "we",
      "will",
      "be",
      "quiet",
      "and",
      "ready",
      "enough",
      "shall",
      "find",
      "compensation",
      "in",
      "every",
      "disappointment",
    ],
    id: "If we will be quiet and ready enough, we shall find compensation in every disappointment.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "patience",
      "american",
      "long",
      "suffering",
      "there",
      "outrage",
      "left",
      "country",
    ],
    words: [
      "is",
      "the",
      "patience",
      "of",
      "american",
      "people",
      "that",
      "long",
      "suffering",
      "there",
      "no",
      "outrage",
      "left",
      "in",
      "country",
    ],
    id: "Is the patience of the American people that long suffering? Is there no outrage left in the country?",
    author: "Andrew Greeley",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["convictions", "friendly", "stick", "your", "beliefs", "as", "theirs", "hard"],
    words: [
      "have",
      "convictions",
      "be",
      "friendly",
      "stick",
      "to",
      "your",
      "beliefs",
      "as",
      "they",
      "theirs",
      "work",
      "hard",
      "do",
    ],
    id: "Have convictions. Be friendly. Stick to your beliefs as they stick to theirs. Work as hard as they do.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["thou", "hast", "ere", "long", "shalt", "sell", "thy", "necessities"],
    words: [
      "buy",
      "what",
      "thou",
      "hast",
      "no",
      "need",
      "of",
      "and",
      "ere",
      "long",
      "shalt",
      "sell",
      "thy",
      "necessities",
    ],
    id: "Buy what thou hast no need of and ere long thou shalt sell thy necessities.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["gentleness", "get", "done", "unless", "happen", "hen", "laying", "eggs"],
    words: [
      "gentleness",
      "doesn't",
      "get",
      "work",
      "done",
      "unless",
      "you",
      "happen",
      "to",
      "be",
      "a",
      "hen",
      "laying",
      "eggs",
    ],
    id: "Gentleness doesn't get work done unless you happen to be a hen laying eggs.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["film", "never", "really", "unless", "camera", "eye", "head", "poet"],
    words: [
      "a",
      "film",
      "is",
      "never",
      "really",
      "good",
      "unless",
      "the",
      "camera",
      "an",
      "eye",
      "in",
      "head",
      "of",
      "poet",
    ],
    id: "A film is never really good unless the camera is an eye in the head of a poet.",
    author: "Orson Welles",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "told", "my", "dentist", "teeth", "wear", "brown", "tie"],
    words: [
      "i",
      "told",
      "my",
      "dentist",
      "teeth",
      "are",
      "going",
      "yellow",
      "he",
      "me",
      "to",
      "wear",
      "a",
      "brown",
      "tie",
    ],
    id: "I told my dentist my teeth are going yellow. he told me to wear a brown tie.",
    author: "Rodney Dangerfield",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["can't", "get", "big", "enough", "or", "book", "long", "suit"],
    words: [
      "you",
      "can't",
      "get",
      "a",
      "cup",
      "of",
      "tea",
      "big",
      "enough",
      "or",
      "book",
      "long",
      "to",
      "suit",
      "me",
    ],
    id: "You can't get a cup of tea big enough or a book long enough to suit me.",
    author: "C. S. Lewis",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "cannot", "any", "childhood", "as", "strong", "father's", "protection"],
    words: [
      "i",
      "cannot",
      "think",
      "of",
      "any",
      "need",
      "in",
      "childhood",
      "as",
      "strong",
      "the",
      "for",
      "a",
      "father's",
      "protection",
    ],
    id: "I cannot think of any need in childhood as strong as the need for a father's protection.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "difference",
      "between",
      "average",
      "achieving",
      "their",
      "perception",
      "response",
      "failure",
    ],
    words: [
      "the",
      "difference",
      "between",
      "average",
      "people",
      "and",
      "achieving",
      "is",
      "their",
      "perception",
      "of",
      "response",
      "to",
      "failure",
    ],
    id: "The difference between average people and achieving people is their perception of and response to failure.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "framers",
      "our",
      "constitution",
      "meant",
      "were",
      "freedom",
      "religion",
      "from",
    ],
    words: [
      "the",
      "framers",
      "of",
      "our",
      "constitution",
      "meant",
      "we",
      "were",
      "to",
      "have",
      "freedom",
      "religion",
      "not",
      "from",
    ],
    id: "The framers of our Constitution meant we were to have freedom of religion, not freedom from religion.",
    author: "Billy Graham",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "believe", "man", "strongest", "soldier", "daring", "die", "unarmed"],
    words: [
      "i",
      "believe",
      "that",
      "a",
      "man",
      "is",
      "the",
      "strongest",
      "soldier",
      "for",
      "daring",
      "to",
      "die",
      "unarmed",
    ],
    id: "I believe that a man is the strongest soldier for daring to die unarmed.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["man", "always", "two", "reasons", "doing", "anything", "reason", "real"],
    words: [
      "a",
      "man",
      "always",
      "has",
      "two",
      "reasons",
      "for",
      "doing",
      "anything",
      "good",
      "reason",
      "and",
      "the",
      "real",
    ],
    id: "A man always has two reasons for doing anything: a good reason and the real reason.",
    author: "J. P. Morgan",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "order",
      "your",
      "voice",
      "heard",
      "washington",
      "make",
      "little",
      "contribution",
    ],
    words: [
      "in",
      "order",
      "to",
      "have",
      "your",
      "voice",
      "be",
      "heard",
      "washington",
      "you",
      "make",
      "some",
      "little",
      "contribution",
    ],
    id: "In order to have your voice be heard in Washington, you have to make some little contribution.",
    author: "Elon Musk",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["evil", "spoken", "true", "correct", "yourself", "lie", "laugh", "at"],
    words: [
      "if",
      "evil",
      "be",
      "spoken",
      "of",
      "you",
      "and",
      "it",
      "true",
      "correct",
      "yourself",
      "a",
      "lie",
      "laugh",
      "at",
    ],
    id: "If evil be spoken of you and it be true, correct yourself, if it be a lie, laugh at it.",
    author: "Epictetus",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["amount", "goes", "waste", "adds", "your", "skill", "set", "intellect"],
    words: [
      "no",
      "amount",
      "of",
      "learning",
      "goes",
      "to",
      "waste",
      "it",
      "adds",
      "building",
      "your",
      "skill",
      "set",
      "and",
      "intellect",
    ],
    id: "No amount of learning goes to waste. It adds to building your skill set and intellect.",
    author: "Shruti Haasan",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["wisdom", "acquired", "passage", "time", "useless", "gift", "unless", "share"],
    words: [
      "the",
      "wisdom",
      "acquired",
      "with",
      "passage",
      "of",
      "time",
      "is",
      "a",
      "useless",
      "gift",
      "unless",
      "you",
      "share",
      "it",
    ],
    id: "The wisdom acquired with the passage of time is a useless gift unless you share it.",
    author: "Esther Williams",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["death", "more", "than", "turning", "over", "from", "time", "eternity"],
    words: [
      "for",
      "death",
      "is",
      "no",
      "more",
      "than",
      "a",
      "turning",
      "of",
      "us",
      "over",
      "from",
      "time",
      "to",
      "eternity",
    ],
    id: "For death is no more than a turning of us over from time to eternity.",
    author: "William Penn",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i'm", "my", "leaves", "might", "change", "color", "roots", "same"],
    words: [
      "i'm",
      "like",
      "a",
      "tree",
      "my",
      "leaves",
      "might",
      "change",
      "color",
      "but",
      "roots",
      "are",
      "the",
      "same",
    ],
    id: "I'm like a tree. My leaves might change color, but my roots are the same.",
    author: "Rose Namajunas",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["courage", "takes", "stand", "up", "also", "sit", "down", "listen"],
    words: [
      "courage",
      "is",
      "what",
      "it",
      "takes",
      "to",
      "stand",
      "up",
      "and",
      "speak",
      "also",
      "sit",
      "down",
      "listen",
    ],
    id: "Courage is what it takes to stand up and speak; courage is also what it takes to sit down and listen.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["how", "i", "confront", "aging", "wonder", "terror", "yeah", "i'll"],
    words: [
      "how",
      "do",
      "i",
      "confront",
      "aging",
      "with",
      "a",
      "wonder",
      "and",
      "terror",
      "yeah",
      "i'll",
      "say",
      "that",
    ],
    id: "How do I confront aging? With a wonder and a terror. Yeah, I'll say that. Wonder and terror.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i'm", "gifted", "hairdos", "i", "gel", "all", "those", "products"],
    words: [
      "i'm",
      "not",
      "very",
      "gifted",
      "for",
      "hairdos",
      "i",
      "don't",
      "like",
      "gel",
      "and",
      "all",
      "those",
      "products",
    ],
    id: "I'm not very gifted for hairdos. I don't like gel and all those products.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "horrible", "told", "smoke", "everybody", "bad", "health", "forbid"],
    words: [
      "i",
      "think",
      "it's",
      "horrible",
      "that",
      "people",
      "have",
      "to",
      "be",
      "told",
      "don't",
      "smoke",
      "everybody",
      "knows",
      "bad",
      "for",
      "the",
      "health",
      "but",
      "they",
      "forbid",
      "it",
    ],
    id: "I think it's horrible that people have to be told. Don't smoke! Everybody knows it's bad for the health. But they have to forbid it.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 22,
    all_intersection_count: 14,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "smartest",
      "most",
      "successful",
      "i",
      "constantly",
      "evolving",
      "always",
      "end",
    ],
    words: [
      "the",
      "smartest",
      "and",
      "most",
      "successful",
      "people",
      "i",
      "know",
      "are",
      "who",
      "constantly",
      "evolving",
      "always",
      "learning",
      "it",
      "does",
      "not",
      "end",
      "with",
      "school",
    ],
    id: "The smartest and most successful people I know are the people who are constantly evolving, always learning. It does not end with school.",
    author: "Stephanie Ruhle",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["studious", "was", "done", "by", "ancients", "how", "live", "moderns"],
    words: [
      "some",
      "are",
      "so",
      "very",
      "studious",
      "of",
      "learning",
      "what",
      "was",
      "done",
      "by",
      "the",
      "ancients",
      "that",
      "they",
      "know",
      "not",
      "how",
      "to",
      "live",
      "with",
      "moderns",
    ],
    id: "Some are so very studious of learning what was done by the ancients that they know not how to live with the moderns.",
    author: "William Penn",
    grammar: null,
    word_count: 22,
    all_intersection_count: 14,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "went", "bank", "asked", "borrow", "said", "im", "sugar"],
    words: [
      "i",
      "went",
      "to",
      "the",
      "bank",
      "and",
      "asked",
      "borrow",
      "a",
      "cup",
      "of",
      "money",
      "they",
      "said",
      "what",
      "for",
      "im",
      "going",
      "buy",
      "some",
      "sugar",
    ],
    id: "I went to the bank and asked to borrow a cup of money. They said, 'What for?' I said, 'I'm going to buy some sugar.'",
    author: "Steven Wright",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["rule", "all", "make", "difference", "more", "still", "win", "what's"],
    words: [
      "it's",
      "a",
      "rule",
      "to",
      "give",
      "all",
      "and",
      "it",
      "can",
      "make",
      "the",
      "difference",
      "if",
      "you",
      "work",
      "more",
      "don't",
      "have",
      "still",
      "win",
      "what's",
      "this",
    ],
    id: "It's a rule to give all, and it can make the difference if you work more. If you don't have to give all, and you still win, what's this?",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 22,
    all_intersection_count: 14,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "long-run",
      "government",
      "exact",
      "symbol",
      "its",
      "their",
      "wisdom",
      "unwisdom",
    ],
    words: [
      "in",
      "the",
      "long-run",
      "every",
      "government",
      "is",
      "exact",
      "symbol",
      "of",
      "its",
      "people",
      "with",
      "their",
      "wisdom",
      "and",
      "unwisdom",
      "we",
      "have",
      "to",
      "say",
      "like",
    ],
    id: "In the long-run every Government is the exact symbol of its People, with their wisdom and unwisdom; we have to say, Like People like Government.",
    author: "Thomas Carlyle",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["ache", "home", "lives", "all", "safe", "place", "as", "questioned"],
    words: [
      "the",
      "ache",
      "for",
      "home",
      "lives",
      "in",
      "all",
      "of",
      "us",
      "safe",
      "place",
      "where",
      "we",
      "can",
      "go",
      "as",
      "are",
      "and",
      "not",
      "be",
      "questioned",
    ],
    id: "The ache for home lives in all of us, the safe place where we can go as we are and not be questioned.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["enough", "evil", "shun", "should", "sincere", "admit", "too", "up"],
    words: [
      "is",
      "it",
      "not",
      "enough",
      "to",
      "know",
      "the",
      "evil",
      "shun",
      "if",
      "we",
      "should",
      "be",
      "sincere",
      "admit",
      "that",
      "love",
      "too",
      "well",
      "give",
      "up",
    ],
    id: "Is it not enough to know the evil to shun it? If not, we should be sincere enough to admit that we love evil too well to give it up.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["way", "world", "change", "obligation", "one", "step", "at", "time"],
    words: [
      "if",
      "you",
      "don't",
      "like",
      "the",
      "way",
      "world",
      "is",
      "change",
      "it",
      "have",
      "an",
      "obligation",
      "to",
      "just",
      "do",
      "one",
      "step",
      "at",
      "a",
      "time",
    ],
    id: "If you don't like the way the world is, you change it. You have an obligation to change it. You just do it one step at a time.",
    author: "Marian Wright Edelman",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["spiritual", "identity", "means", "or", "beloved", "daughters", "sons", "god"],
    words: [
      "spiritual",
      "identity",
      "means",
      "we",
      "are",
      "not",
      "what",
      "do",
      "or",
      "people",
      "say",
      "about",
      "us",
      "and",
      "have",
      "the",
      "beloved",
      "daughters",
      "sons",
      "of",
      "god",
    ],
    id: "Spiritual identity means we are not what we do or what people say about us. And we are not what we have. We are the beloved daughters and sons of God.",
    author: "Henri Nouwen",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["get", "chance", "vote", "on", "ballot", "actually", "i", "believe"],
    words: [
      "you're",
      "not",
      "going",
      "to",
      "get",
      "a",
      "chance",
      "vote",
      "for",
      "me",
      "on",
      "the",
      "ballot",
      "but",
      "you",
      "can",
      "actually",
      "what",
      "i",
      "believe",
      "in",
    ],
    id: "You're not going to get a chance to vote for me on the ballot, but you can actually vote for what I believe in.",
    author: "Carl Lewis",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["grow", "beard", "mine", "only", "thing", "i", "tell", "patience"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "grow",
      "a",
      "beard",
      "like",
      "mine",
      "the",
      "only",
      "thing",
      "i",
      "can",
      "tell",
      "is",
      "that",
      "have",
      "patience",
      "just",
      "let",
      "it",
    ],
    id: "If you want to grow a beard like mine, the only thing I can tell you is that you have to have patience. You just have to let it grow.",
    author: "Daniel Bryan",
    grammar: null,
    word_count: 22,
    all_intersection_count: 14,
    new_words_count: 8,
  },
  {
    row_new_words: ["importance", "using", "as", "voice", "step", "way", "express", "oneself"],
    words: [
      "for",
      "me",
      "the",
      "importance",
      "in",
      "learning",
      "about",
      "dance",
      "is",
      "using",
      "it",
      "as",
      "a",
      "voice",
      "it's",
      "not",
      "step",
      "way",
      "to",
      "express",
      "oneself",
    ],
    id: "For me, the importance in learning about the dance is using it as a voice. It's not about a step, it's about a way to express oneself.",
    author: "Savion Glover",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["heart", "soul", "writing", "research", "should", "write", "find", "out"],
    words: [
      "the",
      "heart",
      "and",
      "soul",
      "of",
      "good",
      "writing",
      "is",
      "research",
      "you",
      "should",
      "write",
      "not",
      "what",
      "know",
      "but",
      "can",
      "find",
      "out",
      "about",
    ],
    id: "The heart and soul of good writing is research; you should write not what you know but what you can find out about.",
    author: "Robert J. Sawyer",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "counterfeits",
      "reproduce",
      "truth",
      "would",
      "flaw",
      "judgment",
      "deceived",
      "by",
    ],
    words: [
      "some",
      "counterfeits",
      "reproduce",
      "so",
      "very",
      "well",
      "the",
      "truth",
      "that",
      "it",
      "would",
      "be",
      "a",
      "flaw",
      "of",
      "judgment",
      "not",
      "to",
      "deceived",
      "by",
      "them",
    ],
    id: "Some counterfeits reproduce so very well the truth that it would be a flaw of judgment not to be deceived by them.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["anything", "certain", "change", "world", "planning", "will", "exist", "form"],
    words: [
      "if",
      "anything",
      "is",
      "certain",
      "it",
      "that",
      "change",
      "the",
      "world",
      "we",
      "are",
      "planning",
      "for",
      "today",
      "will",
      "not",
      "exist",
      "in",
      "this",
      "form",
      "tomorrow",
    ],
    id: "If anything is certain, it is that change is certain. The world we are planning for today will not exist in this form tomorrow.",
    author: "Phil Crosby",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["chasing", "cash", "happiness", "when", "chase", "lose", "even", "get"],
    words: [
      "people",
      "are",
      "chasing",
      "cash",
      "not",
      "happiness",
      "when",
      "you",
      "chase",
      "money",
      "you're",
      "going",
      "to",
      "lose",
      "just",
      "even",
      "if",
      "get",
      "the",
      "be",
      "happy",
    ],
    id: "People are chasing cash, not happiness. When you chase money, you're going to lose. You're just going to. Even if you get the money, you're not going to be happy.",
    author: "Gary Vaynerchuk",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["i'm", "much", "better", "having", "been", "at", "cia", "believe"],
    words: [
      "i'm",
      "going",
      "to",
      "be",
      "so",
      "much",
      "better",
      "a",
      "president",
      "for",
      "having",
      "been",
      "at",
      "the",
      "cia",
      "that",
      "you're",
      "not",
      "believe",
      "it",
    ],
    id: "I'm going to be so much better a president for having been at the CIA that you're not going to believe it.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "friendship",
      "complete",
      "explanation",
      "cheetah",
      "-",
      "true",
      "conquer",
      "anything",
    ],
    words: [
      "friendship",
      "is",
      "the",
      "complete",
      "explanation",
      "of",
      "what",
      "a",
      "cheetah",
      "girl",
      "-",
      "if",
      "you",
      "have",
      "that",
      "true",
      "can",
      "conquer",
      "anything",
      "want",
    ],
    id: "Friendship is the complete explanation of what a Cheetah Girl is - if you have that true friendship, you can conquer anything you want.",
    author: "Sabrina Bryan",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["perfect", "freedom", "reserved", "man", "lives", "by", "his", "own"],
    words: [
      "perfect",
      "freedom",
      "is",
      "reserved",
      "for",
      "the",
      "man",
      "who",
      "lives",
      "by",
      "his",
      "own",
      "work",
      "and",
      "in",
      "that",
      "does",
      "what",
      "he",
      "wants",
      "to",
      "do",
    ],
    id: "Perfect freedom is reserved for the man who lives by his own work and in that work does what he wants to do.",
    author: "Robin G. Collingwood",
    grammar: null,
    word_count: 22,
    all_intersection_count: 14,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "one",
      "compensations",
      "life",
      "sincerely",
      "another",
      "without",
      "helping",
      "himself",
    ],
    words: [
      "it",
      "is",
      "one",
      "of",
      "the",
      "beautiful",
      "compensations",
      "in",
      "this",
      "life",
      "that",
      "no",
      "can",
      "sincerely",
      "try",
      "to",
      "help",
      "another",
      "without",
      "helping",
      "himself",
    ],
    id: "It is one of the beautiful compensations in this life that no one can sincerely try to help another without helping himself.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["position", "cost", "fuel", "actually", "matters", "rocket", "first", "place"],
    words: [
      "you",
      "need",
      "to",
      "be",
      "in",
      "the",
      "position",
      "where",
      "it",
      "is",
      "cost",
      "of",
      "fuel",
      "that",
      "actually",
      "matters",
      "and",
      "not",
      "building",
      "rocket",
      "first",
      "place",
    ],
    id: "You need to be in the position where it is the cost of the fuel that actually matters and not the cost of building the rocket in the first place.",
    author: "Elon Musk",
    grammar: null,
    word_count: 22,
    all_intersection_count: 14,
    new_words_count: 8,
  },
  {
    row_new_words: ["find", "women", "never", "had", "affair", "hard", "woman", "one"],
    words: [
      "you",
      "can",
      "find",
      "women",
      "who",
      "have",
      "never",
      "had",
      "an",
      "affair",
      "but",
      "it",
      "is",
      "hard",
      "to",
      "a",
      "woman",
      "has",
      "just",
      "one",
    ],
    id: "You can find women who have never had an affair, but it is hard to find a woman who has had just one.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "lots", "world", "morecambe", "alone", "quite", "small", "place"],
    words: [
      "there",
      "are",
      "lots",
      "of",
      "people",
      "in",
      "the",
      "world",
      "who",
      "have",
      "money",
      "morecambe",
      "let",
      "alone",
      "and",
      "is",
      "quite",
      "a",
      "small",
      "place",
    ],
    id: "There are lots of people in the world who have money. Lots of people in Morecambe have money, let alone the world. And Morecambe is quite a small place.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "there",
      "knowledge",
      "light",
      "wisdom",
      "possession",
      "received",
      "from",
      "source",
    ],
    words: [
      "there",
      "is",
      "no",
      "knowledge",
      "light",
      "wisdom",
      "that",
      "you",
      "are",
      "in",
      "possession",
      "of",
      "but",
      "what",
      "have",
      "received",
      "it",
      "from",
      "some",
      "source",
    ],
    id: "There is no knowledge, no light, no wisdom that you are in possession of, but what you have received it from some source.",
    author: "Brigham Young",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["added", "happiness", "man", "health", "out", "debt", "clear", "conscience"],
    words: [
      "what",
      "can",
      "be",
      "added",
      "to",
      "the",
      "happiness",
      "of",
      "a",
      "man",
      "who",
      "is",
      "in",
      "health",
      "out",
      "debt",
      "and",
      "has",
      "clear",
      "conscience",
    ],
    id: "What can be added to the happiness of a man who is in health, out of debt, and has a clear conscience?",
    author: "Adam Smith",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "when",
      "sort",
      "things",
      "happen",
      "genocide",
      "happens",
      "intrinsically",
      "evil",
    ],
    words: [
      "when",
      "you",
      "think",
      "of",
      "the",
      "sort",
      "things",
      "that",
      "happen",
      "a",
      "genocide",
      "happens",
      "it's",
      "again",
      "not",
      "people",
      "who",
      "are",
      "intrinsically",
      "evil",
    ],
    id: "When you think of the sort of things that happen when a genocide happens, it's again not people who are intrinsically evil.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["ready", "die", "put", "word", "freedom", "out", "your", "vocabulary"],
    words: [
      "if",
      "you're",
      "not",
      "ready",
      "to",
      "die",
      "for",
      "it",
      "put",
      "the",
      "word",
      "freedom",
      "out",
      "of",
      "your",
      "vocabulary",
    ],
    id: "If you're not ready to die for it, put the word 'freedom' out of your vocabulary.",
    author: "Malcolm X",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["even", "harder", "average", "ape", "believe", "descended", "from", "man"],
    words: [
      "it",
      "is",
      "even",
      "harder",
      "for",
      "the",
      "average",
      "ape",
      "to",
      "believe",
      "that",
      "he",
      "has",
      "descended",
      "from",
      "man",
    ],
    id: "It is even harder for the average ape to believe that he has descended from man.",
    author: "H. L. Mencken",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["business", "always", "changing", "leader", "you've", "got", "take", "chances"],
    words: [
      "this",
      "business",
      "is",
      "always",
      "changing",
      "if",
      "you're",
      "going",
      "to",
      "be",
      "a",
      "leader",
      "you've",
      "got",
      "take",
      "chances",
    ],
    id: "This business is always changing. If you're going to be a leader, you've got to take chances.",
    author: "Ronnie Milsap",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["man", "cannot", "said", "succeed", "life", "satisfy", "one", "friend"],
    words: [
      "a",
      "man",
      "cannot",
      "be",
      "said",
      "to",
      "succeed",
      "in",
      "this",
      "life",
      "who",
      "does",
      "not",
      "satisfy",
      "one",
      "friend",
    ],
    id: "A man cannot be said to succeed in this life who does not satisfy one friend.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "business", "your", "reputation", "-", "important", "keep", "word"],
    words: [
      "all",
      "you",
      "have",
      "in",
      "business",
      "is",
      "your",
      "reputation",
      "-",
      "so",
      "it's",
      "very",
      "important",
      "that",
      "keep",
      "word",
    ],
    id: "All you have in business is your reputation - so it's very important that you keep your word.",
    author: "Richard Branson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["worst", "part", "celebrating", "another", "birthday", "shock", "only", "as"],
    words: [
      "the",
      "worst",
      "part",
      "about",
      "celebrating",
      "another",
      "birthday",
      "is",
      "shock",
      "that",
      "you're",
      "only",
      "as",
      "well",
      "you",
      "are",
    ],
    id: "The worst part about celebrating another birthday is the shock that you're only as well as you are.",
    author: "Anne Lamott",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["only", "god", "play", "dice", "sometimes", "throws", "cannot", "seen"],
    words: [
      "not",
      "only",
      "does",
      "god",
      "play",
      "dice",
      "but",
      "he",
      "sometimes",
      "throws",
      "them",
      "where",
      "they",
      "cannot",
      "be",
      "seen",
    ],
    id: "Not only does God play dice, but... he sometimes throws them where they cannot be seen.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "anger",
      "restrained",
      "frequently",
      "more",
      "hurtful",
      "than",
      "injury",
      "provokes",
    ],
    words: [
      "anger",
      "if",
      "not",
      "restrained",
      "is",
      "frequently",
      "more",
      "hurtful",
      "to",
      "us",
      "than",
      "the",
      "injury",
      "that",
      "provokes",
      "it",
    ],
    id: "Anger, if not restrained, is frequently more hurtful to us than the injury that provokes it.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "such",
      "irresistable",
      "nature",
      "truth",
      "all",
      "asks",
      "liberty",
      "appearing",
    ],
    words: [
      "but",
      "such",
      "is",
      "the",
      "irresistable",
      "nature",
      "of",
      "truth",
      "that",
      "all",
      "it",
      "asks",
      "and",
      "wants",
      "liberty",
      "appearing",
    ],
    id: "But such is the irresistable nature of truth, that all it asks, and all it wants is the liberty of appearing.",
    author: "Thomas Paine",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["yesterday", "history", "mystery", "god's", "gift", "why", "call", "present"],
    words: [
      "yesterday",
      "is",
      "history",
      "tomorrow",
      "a",
      "mystery",
      "today",
      "god's",
      "gift",
      "that's",
      "why",
      "we",
      "call",
      "it",
      "the",
      "present",
    ],
    id: "Yesterday is history, tomorrow is a mystery, today is God's gift, that's why we call it the present.",
    author: "Joan Rivers",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "painting",
      "always",
      "been",
      "friend",
      "keeps",
      "company",
      "comforts",
      "inspires",
    ],
    words: [
      "a",
      "good",
      "painting",
      "to",
      "me",
      "has",
      "always",
      "been",
      "like",
      "friend",
      "it",
      "keeps",
      "company",
      "comforts",
      "and",
      "inspires",
    ],
    id: "A good painting to me has always been like a friend. It keeps me company, comforts and inspires.",
    author: "Hedy Lamarr",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["one", "whether", "death", "which", "fear", "greatest", "evil", "may"],
    words: [
      "no",
      "one",
      "knows",
      "whether",
      "death",
      "which",
      "people",
      "fear",
      "to",
      "be",
      "the",
      "greatest",
      "evil",
      "may",
      "not",
      "good",
    ],
    id: "No one knows whether death, which people fear to be the greatest evil, may not be the greatest good.",
    author: "Plato",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "true",
      "spacex",
      "partially",
      "government",
      "contractor",
      "would",
      "unfair",
      "entirely",
    ],
    words: [
      "it",
      "is",
      "true",
      "that",
      "spacex",
      "partially",
      "a",
      "government",
      "contractor",
      "but",
      "would",
      "be",
      "unfair",
      "to",
      "say",
      "entirely",
    ],
    id: "It is true that SpaceX is partially a government contractor, but it would be unfair to say that SpaceX is entirely a government contractor.",
    author: "Elon Musk",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "whatever", "believe", "should", "regardless", "risks", "my", "life"],
    words: [
      "i",
      "just",
      "do",
      "whatever",
      "it",
      "is",
      "that",
      "believe",
      "should",
      "regardless",
      "of",
      "the",
      "risks",
      "to",
      "my",
      "life",
    ],
    id: "I just do whatever it is that I believe I should do, regardless of the risks to my life.",
    author: "Corazon Aquino",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["fear", "defeat", "believe", "may", "reveal", "powers", "didn't", "possessed"],
    words: [
      "you",
      "don't",
      "have",
      "to",
      "fear",
      "defeat",
      "if",
      "believe",
      "it",
      "may",
      "reveal",
      "powers",
      "that",
      "didn't",
      "know",
      "possessed",
    ],
    id: "You don't have to fear defeat if you believe it may reveal powers that you didn't know you possessed.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["only", "thing", "relaxes", "archery", "why", "i", "apartments", "gardens"],
    words: [
      "the",
      "only",
      "thing",
      "that",
      "relaxes",
      "me",
      "is",
      "archery",
      "that's",
      "why",
      "i",
      "have",
      "to",
      "apartments",
      "with",
      "gardens",
    ],
    id: "The only thing that relaxes me is archery. That's why I have to have apartments with gardens.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["more", "players", "i", "difficult", "choices", "there", "better", "lfc"],
    words: [
      "the",
      "more",
      "players",
      "i",
      "have",
      "difficult",
      "choices",
      "there",
      "are",
      "for",
      "me",
      "but",
      "better",
      "it",
      "is",
      "lfc",
    ],
    id: "The more players I have, the more difficult choices there are for me, but the better it is for LFC.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["anyone", "great", "time", "san", "francisco", "pretty", "much", "dead"],
    words: [
      "anyone",
      "who",
      "doesn't",
      "have",
      "a",
      "great",
      "time",
      "in",
      "san",
      "francisco",
      "is",
      "pretty",
      "much",
      "dead",
      "to",
      "me",
    ],
    id: "Anyone who doesn't have a great time in San Francisco is pretty much dead to me.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "piece", "your", "soul", "never", "knew", "was", "missing"],
    words: [
      "love",
      "is",
      "when",
      "he",
      "gives",
      "you",
      "a",
      "piece",
      "of",
      "your",
      "soul",
      "that",
      "never",
      "knew",
      "was",
      "missing",
    ],
    id: "Love is when he gives you a piece of your soul, that you never knew was missing.",
    author: "Torquato Tasso",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "i",
      "believe",
      "communication",
      "key",
      "successful",
      "family",
      "our",
      "society",
    ],
    words: [
      "i",
      "believe",
      "that",
      "communication",
      "is",
      "the",
      "key",
      "to",
      "a",
      "happy",
      "and",
      "successful",
      "family",
      "in",
      "our",
      "society",
    ],
    id: "I believe that communication is the key to a happy and successful family in our society.",
    author: "Shweta Menon",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["peace", "within", "ourselves", "vain", "seek", "from", "outward", "sources"],
    words: [
      "if",
      "we",
      "have",
      "not",
      "peace",
      "within",
      "ourselves",
      "it",
      "is",
      "in",
      "vain",
      "to",
      "seek",
      "from",
      "outward",
      "sources",
    ],
    id: "If we have not peace within ourselves, it is in vain to seek it from outward sources.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["sin", "covet", "honour", "i", "most", "offending", "soul", "alive"],
    words: [
      "but",
      "if",
      "it",
      "be",
      "a",
      "sin",
      "to",
      "covet",
      "honour",
      "i",
      "am",
      "the",
      "most",
      "offending",
      "soul",
      "alive",
    ],
    id: "But if it be a sin to covet honour, I am the most offending soul alive.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["though", "travel", "world", "over", "find", "must", "carry", "or"],
    words: [
      "though",
      "we",
      "travel",
      "the",
      "world",
      "over",
      "to",
      "find",
      "beautiful",
      "must",
      "carry",
      "it",
      "with",
      "us",
      "or",
      "not",
    ],
    id: "Though we travel the world over to find the beautiful, we must carry it with us or we find it not.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "man",
      "consumer",
      "ought",
      "producer",
      "by",
      "constitution",
      "expensive",
      "rich",
    ],
    words: [
      "every",
      "man",
      "is",
      "a",
      "consumer",
      "and",
      "ought",
      "to",
      "be",
      "producer",
      "he",
      "by",
      "constitution",
      "expensive",
      "needs",
      "rich",
    ],
    id: "Every man is a consumer, and ought to be a producer. He is by constitution expensive, and needs to be rich.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "my", "flaws", "too", "professional", "miss", "or", "lose"],
    words: [
      "i",
      "have",
      "my",
      "flaws",
      "too",
      "but",
      "am",
      "a",
      "professional",
      "who",
      "doesn't",
      "like",
      "to",
      "miss",
      "or",
      "lose",
    ],
    id: "I have my flaws too, but I am a professional who doesn't like to miss or lose.",
    author: "Cristiano Ronaldo",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["way", "develop", "decisiveness", "start", "right", "next", "question", "face"],
    words: [
      "the",
      "way",
      "to",
      "develop",
      "decisiveness",
      "is",
      "start",
      "right",
      "where",
      "you",
      "are",
      "with",
      "very",
      "next",
      "question",
      "face",
    ],
    id: "The way to develop decisiveness is to start right where you are, with the very next question you face.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "clear",
      "my",
      "friendship",
      "friends",
      "ends",
      "interest",
      "country",
      "begins",
    ],
    words: [
      "let",
      "me",
      "be",
      "very",
      "clear",
      "my",
      "friendship",
      "with",
      "friends",
      "ends",
      "where",
      "the",
      "interest",
      "of",
      "country",
      "begins",
    ],
    id: "Let me be very clear: my friendship with my friends ends where the interest of the country begins.",
    author: "Rodrigo Duterte",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "announce",
      "there",
      "must",
      "criticism",
      "morally",
      "treasonable",
      "american",
      "public",
    ],
    words: [
      "to",
      "announce",
      "that",
      "there",
      "must",
      "be",
      "no",
      "criticism",
      "of",
      "the",
      "president",
      "is",
      "morally",
      "treasonable",
      "american",
      "public",
    ],
    id: "To announce that there must be no criticism of the president... is morally treasonable to the American public.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "take", "yourself", "better", "person", "others", "feel", "treat"],
    words: [
      "when",
      "you",
      "take",
      "care",
      "of",
      "yourself",
      "you're",
      "a",
      "better",
      "person",
      "for",
      "others",
      "feel",
      "good",
      "about",
      "treat",
    ],
    id: "When you take care of yourself, you're a better person for others. When you feel good about yourself, you treat others better.",
    author: "Solange Knowles",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["defense", "definite", "part", "game", "great", "play", "without", "fouling"],
    words: [
      "defense",
      "is",
      "a",
      "definite",
      "part",
      "of",
      "the",
      "game",
      "and",
      "great",
      "learning",
      "to",
      "play",
      "it",
      "without",
      "fouling",
    ],
    id: "Defense is a definite part of the game, and a great part of defense is learning to play it without fouling.",
    author: "John Wooden",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "learned", "patience", "perseverance", "faith", "reach", "your", "goals"],
    words: [
      "i",
      "have",
      "learned",
      "that",
      "you",
      "need",
      "to",
      "patience",
      "perseverance",
      "and",
      "faith",
      "in",
      "people",
      "reach",
      "your",
      "goals",
    ],
    id: "I have learned that you need to have patience, perseverance, and faith in people to reach your goals.",
    author: "Richarlison",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["must", "break", "law", "seize", "power", "all", "cases", "observe"],
    words: [
      "if",
      "you",
      "must",
      "break",
      "the",
      "law",
      "do",
      "it",
      "to",
      "seize",
      "power",
      "in",
      "all",
      "other",
      "cases",
      "observe",
    ],
    id: "If you must break the law, do it to seize power: in all other cases observe it.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["time", "measured", "by", "passing", "years", "one", "feels", "achieves"],
    words: [
      "time",
      "is",
      "not",
      "measured",
      "by",
      "the",
      "passing",
      "of",
      "years",
      "but",
      "what",
      "one",
      "does",
      "feels",
      "and",
      "achieves",
    ],
    id: "Time is not measured by the passing of years but by what one does, what one feels, and what one achieves.",
    author: "Jawaharlal Nehru",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "resistance",
      "fight",
      "physically",
      "gym",
      "life",
      "only",
      "strong",
      "character",
    ],
    words: [
      "the",
      "resistance",
      "that",
      "you",
      "fight",
      "physically",
      "in",
      "gym",
      "and",
      "life",
      "can",
      "only",
      "build",
      "a",
      "strong",
      "character",
    ],
    id: "The resistance that you fight physically in the gym and the resistance that you fight in life can only build a strong character.",
    author: "Arnold Schwarzenegger",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i'm", "saying", "should", "stop", "flying", "easier", "climate", "neutral"],
    words: [
      "i'm",
      "not",
      "saying",
      "that",
      "people",
      "should",
      "stop",
      "flying",
      "just",
      "it",
      "needs",
      "to",
      "be",
      "easier",
      "climate",
      "neutral",
    ],
    id: "I'm not saying that people should stop flying. I'm just saying it needs to be easier to be climate neutral.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "coaching",
      "different",
      "skill",
      "patience",
      "lot",
      "organisation",
      "i",
      "any",
    ],
    words: [
      "coaching",
      "is",
      "a",
      "very",
      "different",
      "skill",
      "you",
      "need",
      "patience",
      "lot",
      "of",
      "organisation",
      "i",
      "don't",
      "have",
      "any",
    ],
    id: "Coaching is a very different skill. You need patience, you need a lot of organisation. I don't have any.",
    author: "Wasim Akram",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["talk", "man", "language", "understands", "goes", "his", "head", "heart"],
    words: [
      "if",
      "you",
      "talk",
      "to",
      "a",
      "man",
      "in",
      "language",
      "he",
      "understands",
      "that",
      "goes",
      "his",
      "head",
      "him",
      "heart",
    ],
    id: "If you talk to a man in a language he understands, that goes to his head. If you talk to him in his language, that goes to his heart.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["always", "read", "something", "will", "make", "look", "die", "middle"],
    words: [
      "always",
      "read",
      "something",
      "that",
      "will",
      "make",
      "you",
      "look",
      "good",
      "if",
      "die",
      "in",
      "the",
      "middle",
      "of",
      "it",
    ],
    id: "Always read something that will make you look good if you die in the middle of it.",
    author: "P. J. O'Rourke",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "there",
      "innate",
      "circuitry",
      "creates",
      "culture",
      "acquires",
      "responds",
      "socialization",
    ],
    words: [
      "there",
      "has",
      "to",
      "be",
      "innate",
      "circuitry",
      "that",
      "does",
      "the",
      "learning",
      "creates",
      "culture",
      "acquires",
      "and",
      "responds",
      "socialization",
    ],
    id: "There has to be innate circuitry that does the learning, that creates the culture, that acquires the culture, and that responds to socialization.",
    author: "Steven Pinker",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "believe", "god", "put", "jolly", "world", "enjoy", "life"],
    words: [
      "i",
      "believe",
      "that",
      "god",
      "put",
      "us",
      "in",
      "this",
      "jolly",
      "world",
      "to",
      "be",
      "happy",
      "and",
      "enjoy",
      "life",
    ],
    id: "I believe that God put us in this jolly world to be happy and enjoy life.",
    author: "Robert Baden-Powell",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "full", "ups", "downs", "one", "stay", "positive", "matter"],
    words: [
      "life",
      "is",
      "full",
      "of",
      "ups",
      "and",
      "downs",
      "one",
      "needs",
      "to",
      "stay",
      "positive",
      "in",
      "no",
      "matter",
      "what",
    ],
    id: "Life is full of ups and downs. One needs to stay positive in life, no matter what.",
    author: "Shilpa Shinde",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "doubt", "life", "given", "enjoyed", "overcome", "got", "over"],
    words: [
      "there",
      "is",
      "no",
      "doubt",
      "that",
      "life",
      "given",
      "us",
      "not",
      "to",
      "be",
      "enjoyed",
      "but",
      "overcome",
      "got",
      "over",
    ],
    id: "There is no doubt that life is given us, not to be enjoyed, but to be overcome; to be got over.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "therefore",
      "when",
      "mind",
      "itself",
      "there",
      "remains",
      "trinity",
      "knowledge",
    ],
    words: [
      "therefore",
      "when",
      "the",
      "mind",
      "knows",
      "itself",
      "and",
      "loves",
      "there",
      "remains",
      "a",
      "trinity",
      "that",
      "is",
      "love",
      "knowledge",
    ],
    id: "Therefore when the mind knows itself and loves itself, there remains a trinity, that is the mind, love and knowledge.",
    author: "Peter Lombard",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "most",
      "part",
      "hollywood",
      "transactional",
      "make",
      "movies",
      "television",
      "shows",
    ],
    words: [
      "for",
      "the",
      "most",
      "part",
      "hollywood",
      "is",
      "very",
      "transactional",
      "people",
      "want",
      "to",
      "make",
      "movies",
      "and",
      "television",
      "shows",
    ],
    id: "For the most part, Hollywood is very transactional. People want to make movies and television shows.",
    author: "Michael Lynton",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "write", "machine", "pen", "paper", "which", "most", "comfortable"],
    words: [
      "i",
      "don't",
      "write",
      "with",
      "a",
      "machine",
      "pen",
      "and",
      "paper",
      "which",
      "is",
      "what",
      "most",
      "comfortable",
      "for",
      "me",
    ],
    id: "I don't write with a machine. I write with a pen and a paper, which is what is most comfortable for me.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "open", "sponge", "i'd", "soak", "up", "things", "skills"],
    words: [
      "i",
      "am",
      "very",
      "open",
      "to",
      "learning",
      "like",
      "a",
      "sponge",
      "i'd",
      "soak",
      "up",
      "in",
      "new",
      "things",
      "skills",
    ],
    id: "I am very open to learning. I am like a sponge: I'd like to soak up in new things, new skills.",
    author: "Catriona Gray",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["library", "temple", "liberated", "more", "than", "all", "wars", "history"],
    words: [
      "the",
      "library",
      "is",
      "temple",
      "of",
      "learning",
      "and",
      "has",
      "liberated",
      "more",
      "people",
      "than",
      "all",
      "wars",
      "in",
      "history",
    ],
    id: "The library is the temple of learning, and learning has liberated more people than all the wars in history.",
    author: "Carl T. Rowan",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["real", "philosopher", "all", "necessary", "hate", "one", "else's", "type"],
    words: [
      "to",
      "be",
      "a",
      "real",
      "philosopher",
      "all",
      "that",
      "is",
      "necessary",
      "hate",
      "some",
      "one",
      "else's",
      "type",
      "of",
      "thinking",
    ],
    id: "To be a real philosopher all that is necessary is to hate some one else's type of thinking.",
    author: "William James",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "mark",
      "educated",
      "mind",
      "able",
      "entertain",
      "thought",
      "without",
      "accepting",
    ],
    words: [
      "it",
      "is",
      "the",
      "mark",
      "of",
      "an",
      "educated",
      "mind",
      "to",
      "be",
      "able",
      "entertain",
      "a",
      "thought",
      "without",
      "accepting",
    ],
    id: "It is the mark of an educated mind to be able to entertain a thought without accepting it.",
    author: "Aristotle",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "willing",
      "betray",
      "years",
      "friendship",
      "get",
      "little",
      "bit",
      "spotlight",
    ],
    words: [
      "some",
      "people",
      "are",
      "willing",
      "to",
      "betray",
      "years",
      "of",
      "friendship",
      "just",
      "get",
      "a",
      "little",
      "bit",
      "the",
      "spotlight",
    ],
    id: "Some people are willing to betray years of friendship just to get a little bit of the spotlight.",
    author: "Lauren Conrad",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "power",
      "creating",
      "better",
      "future",
      "contained",
      "present",
      "create",
      "by",
    ],
    words: [
      "the",
      "power",
      "for",
      "creating",
      "a",
      "better",
      "future",
      "is",
      "contained",
      "in",
      "present",
      "moment",
      "you",
      "create",
      "good",
      "by",
    ],
    id: "The power for creating a better future is contained in the present moment: You create a good future by creating a good present.",
    author: "Eckhart Tolle",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["ask", "my", "shirt", "off", "back", "i'll", "tell", "chance"],
    words: [
      "ask",
      "me",
      "for",
      "my",
      "shirt",
      "off",
      "back",
      "i'll",
      "give",
      "it",
      "to",
      "you",
      "tell",
      "not",
      "a",
      "chance",
    ],
    id: "Ask me for my shirt off my back, I'll give it to you. Tell me? Not a chance.",
    author: "Roddy Piper",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["must", "believe", "luck", "how", "else", "explain", "success", "those"],
    words: [
      "we",
      "must",
      "believe",
      "in",
      "luck",
      "for",
      "how",
      "else",
      "can",
      "explain",
      "the",
      "success",
      "of",
      "those",
      "don't",
      "like",
    ],
    id: "We must believe in luck. For how else can we explain the success of those we don't like?",
    author: "Jean Cocteau",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "mirror", "will", "reflect", "back", "thinker", "thinks", "into"],
    words: [
      "life",
      "is",
      "a",
      "mirror",
      "and",
      "will",
      "reflect",
      "back",
      "to",
      "the",
      "thinker",
      "what",
      "he",
      "thinks",
      "into",
      "it",
    ],
    id: "Life is a mirror and will reflect back to the thinker what he thinks into it.",
    author: "Ernest Holmes",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["their", "rights", "never", "slow", "assert", "maintain", "when", "invaded"],
    words: [
      "the",
      "people",
      "know",
      "their",
      "rights",
      "and",
      "they",
      "are",
      "never",
      "slow",
      "to",
      "assert",
      "maintain",
      "them",
      "when",
      "invaded",
    ],
    id: "The people know their rights, and they are never slow to assert and maintain them when they are invaded.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "everybody",
      "creative",
      "potential",
      "from",
      "express",
      "start",
      "changing",
      "world",
    ],
    words: [
      "everybody",
      "has",
      "a",
      "creative",
      "potential",
      "and",
      "from",
      "the",
      "moment",
      "you",
      "can",
      "express",
      "this",
      "start",
      "changing",
      "world",
    ],
    id: "Everybody has a creative potential and from the moment you can express this creative potential, you can start changing the world.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["credit", "system", "whereby", "person", "pay", "gets", "another", "guarantee"],
    words: [
      "credit",
      "is",
      "a",
      "system",
      "whereby",
      "person",
      "who",
      "can",
      "not",
      "pay",
      "gets",
      "another",
      "to",
      "guarantee",
      "that",
      "he",
    ],
    id: "Credit is a system whereby a person who can not pay gets another person who can not pay to guarantee that he can pay.",
    author: "Charles Dickens",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["way", "knuckleball", "until", "stops", "rolling", "then", "pick", "up"],
    words: [
      "the",
      "way",
      "to",
      "catch",
      "a",
      "knuckleball",
      "is",
      "wait",
      "until",
      "it",
      "stops",
      "rolling",
      "and",
      "then",
      "pick",
      "up",
    ],
    id: "The way to catch a knuckleball is to wait until it stops rolling and then pick it up.",
    author: "Bob Uecker",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "venture", "war", "long", "carried", "on", "against", "will"],
    words: [
      "i",
      "venture",
      "to",
      "say",
      "no",
      "war",
      "can",
      "be",
      "long",
      "carried",
      "on",
      "against",
      "the",
      "will",
      "of",
      "people",
    ],
    id: "I venture to say no war can be long carried on against the will of the people.",
    author: "Edmund Burke",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "easy",
      "get",
      "everything",
      "provided",
      "first",
      "without",
      "things",
      "cannot",
    ],
    words: [
      "it",
      "is",
      "easy",
      "to",
      "get",
      "everything",
      "you",
      "want",
      "provided",
      "first",
      "learn",
      "do",
      "without",
      "the",
      "things",
      "cannot",
    ],
    id: "It is easy to get everything you want, provided you first learn to do without the things you cannot get.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "empathise", "victims", "islamophobia", "my", "own", "friends", "family"],
    words: [
      "i",
      "don't",
      "just",
      "empathise",
      "with",
      "the",
      "victims",
      "of",
      "islamophobia",
      "worry",
      "about",
      "my",
      "own",
      "friends",
      "and",
      "family",
    ],
    id: "I don't just empathise with the victims of Islamophobia; I worry about my own friends and family.",
    author: "Sadiq Khan",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["negro", "country", "relatively", "conscious", "rage", "almost", "all", "time"],
    words: [
      "to",
      "be",
      "a",
      "negro",
      "in",
      "this",
      "country",
      "and",
      "relatively",
      "conscious",
      "is",
      "rage",
      "almost",
      "all",
      "the",
      "time",
    ],
    id: "To be a Negro in this country and to be relatively conscious is to be in a rage almost all the time.",
    author: "James Baldwin",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["world", "flat", "pcs", "hierarchy", "human", "first", "five", "rungs"],
    words: [
      "the",
      "world",
      "is",
      "not",
      "flat",
      "and",
      "pcs",
      "are",
      "in",
      "hierarchy",
      "of",
      "human",
      "needs",
      "first",
      "five",
      "rungs",
    ],
    id: "The world is not flat, and PCs are not, in the hierarchy of human needs, in the first five rungs.",
    author: "Bill Gates",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i'm", "one-man", "show", "i", "was", "never", "my", "life"],
    words: [
      "i'm",
      "not",
      "a",
      "one-man",
      "show",
      "i",
      "was",
      "never",
      "that",
      "in",
      "my",
      "life",
      "and",
      "want",
      "to",
      "be",
    ],
    id: "I'm not a one-man show. I was never that in my life, and I never want to be that.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["johnson's", "war", "america's", "i", "drop", "dead", "will", "still"],
    words: [
      "this",
      "is",
      "not",
      "johnson's",
      "war",
      "america's",
      "if",
      "i",
      "drop",
      "dead",
      "tomorrow",
      "will",
      "still",
      "be",
      "with",
      "you",
    ],
    id: "This is not Johnson's war. This is America's war. If I drop dead tomorrow, this war will still be with you.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["too", "late", "hebrew", "more", "important", "understand", "even", "slang"],
    words: [
      "it",
      "is",
      "too",
      "late",
      "to",
      "be",
      "studying",
      "hebrew",
      "more",
      "important",
      "understand",
      "even",
      "the",
      "slang",
      "of",
      "today",
    ],
    id: "It is too late to be studying Hebrew; it is more important to understand even the slang of today.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["thrive", "on", "military", "acts", "because", "thank", "god", "efficient"],
    words: [
      "we",
      "don't",
      "thrive",
      "on",
      "military",
      "acts",
      "do",
      "them",
      "because",
      "have",
      "to",
      "and",
      "thank",
      "god",
      "are",
      "efficient",
    ],
    id: "We don't thrive on military acts. We do them because we have to, and thank God we are efficient.",
    author: "Golda Meir",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["man's", "ultimate", "direction", "life", "look", "truth", "virtue", "beauty"],
    words: [
      "what",
      "is",
      "man's",
      "ultimate",
      "direction",
      "in",
      "life",
      "it",
      "to",
      "look",
      "for",
      "love",
      "truth",
      "virtue",
      "and",
      "beauty",
    ],
    id: "What is man's ultimate direction in life? It is to look for love, truth, virtue, and beauty.",
    author: "Shinichi Suzuki",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["almost", "always", "there", "change", "characters", "journey", "-", "story"],
    words: [
      "and",
      "almost",
      "always",
      "there",
      "has",
      "to",
      "be",
      "change",
      "in",
      "the",
      "characters",
      "is",
      "journey",
      "-",
      "it's",
      "story",
    ],
    id: "And almost always there has to be change, change in the characters is the journey - it's the story.",
    author: "Graham Brown",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "fun", "movies", "also", "something", "tend", "stay", "always"],
    words: [
      "i",
      "love",
      "fun",
      "movies",
      "that",
      "also",
      "have",
      "something",
      "to",
      "say",
      "they",
      "tend",
      "stay",
      "with",
      "me",
      "always",
    ],
    id: "I love fun movies that also have something to say. They tend to stay with me, always.",
    author: "John Travolta",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["yet", "possess", "ourselves", "at", "same", "time", "much", "more"],
    words: [
      "we",
      "do",
      "not",
      "yet",
      "possess",
      "ourselves",
      "and",
      "know",
      "at",
      "the",
      "same",
      "time",
      "that",
      "are",
      "much",
      "more",
    ],
    id: "We do not yet possess ourselves, and we know at the same time that we are much more.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "why", "women", "any", "things", "men", "when", "one"],
    words: [
      "i",
      "don't",
      "know",
      "why",
      "women",
      "want",
      "any",
      "of",
      "the",
      "things",
      "men",
      "have",
      "when",
      "one",
      "that",
      "is",
    ],
    id: "I don't know why women want any of the things men have when one of the things that women have is men.",
    author: "Coco Chanel",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "interests",
      "life",
      "curiosity",
      "challenges",
      "fight",
      "its",
      "victories",
      "defeats",
    ],
    words: [
      "what",
      "interests",
      "me",
      "in",
      "life",
      "is",
      "curiosity",
      "challenges",
      "the",
      "good",
      "fight",
      "with",
      "its",
      "victories",
      "and",
      "defeats",
    ],
    id: "What interests me in life is curiosity, challenges, the good fight with its victories and defeats.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "thankfully",
      "forgiveness",
      "healing",
      "brings",
      "its",
      "wake",
      "nothing",
      "deserve",
    ],
    words: [
      "thankfully",
      "forgiveness",
      "and",
      "the",
      "healing",
      "it",
      "brings",
      "in",
      "its",
      "wake",
      "has",
      "nothing",
      "to",
      "do",
      "with",
      "deserve",
    ],
    id: "Thankfully, forgiveness, and the healing it brings in its wake, has nothing to do with 'deserve.'",
    author: "Eric Metaxas",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["indispensable", "first", "step", "getting", "things", "out", "life", "decide"],
    words: [
      "the",
      "indispensable",
      "first",
      "step",
      "to",
      "getting",
      "things",
      "you",
      "want",
      "out",
      "of",
      "life",
      "is",
      "this",
      "decide",
      "what",
    ],
    id: "The indispensable first step to getting the things you want out of life is this: decide what you want.",
    author: "Ben Stein",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "highest",
      "function",
      "makes",
      "loved",
      "one",
      "unique",
      "irreplacable",
      "being",
    ],
    words: [
      "the",
      "highest",
      "function",
      "of",
      "love",
      "is",
      "that",
      "it",
      "makes",
      "loved",
      "one",
      "a",
      "unique",
      "and",
      "irreplacable",
      "being",
    ],
    id: "The highest function of love is that it makes the loved one a unique and irreplacable being.",
    author: "Tom Robbins",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "without",
      "knowledge",
      "their",
      "past",
      "history",
      "origin",
      "culture",
      "roots",
    ],
    words: [
      "a",
      "people",
      "without",
      "the",
      "knowledge",
      "of",
      "their",
      "past",
      "history",
      "origin",
      "and",
      "culture",
      "is",
      "like",
      "tree",
      "roots",
    ],
    id: "A people without the knowledge of their past history, origin and culture is like a tree without roots.",
    author: "Marcus Garvey",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["night", "death", "hope", "sees", "star", "listening", "rustle", "wing"],
    words: [
      "in",
      "the",
      "night",
      "of",
      "death",
      "hope",
      "sees",
      "a",
      "star",
      "and",
      "listening",
      "love",
      "can",
      "hear",
      "rustle",
      "wing",
    ],
    id: "In the night of death, hope sees a star, and listening love can hear the rustle of a wing.",
    author: "Robert Green Ingersoll",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "chance", "surviving", "long", "enough", "colonize", "solar", "system"],
    words: [
      "i",
      "think",
      "we",
      "have",
      "a",
      "good",
      "chance",
      "of",
      "surviving",
      "long",
      "enough",
      "to",
      "colonize",
      "the",
      "solar",
      "system",
    ],
    id: "I think we have a good chance of surviving long enough to colonize the solar system.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "always", "knew", "was", "rich", "ever", "doubted", "minute"],
    words: [
      "i",
      "always",
      "knew",
      "was",
      "going",
      "to",
      "be",
      "rich",
      "don't",
      "think",
      "ever",
      "doubted",
      "it",
      "for",
      "a",
      "minute",
    ],
    id: "I always knew I was going to be rich. I don't think I ever doubted it for a minute.",
    author: "Warren Buffett",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["on", "atonement", "jews", "commanded", "seek", "forgiveness", "from", "hurt"],
    words: [
      "on",
      "the",
      "day",
      "of",
      "atonement",
      "jews",
      "are",
      "commanded",
      "to",
      "seek",
      "forgiveness",
      "from",
      "people",
      "we",
      "have",
      "hurt",
    ],
    id: "On the Day of Atonement, Jews are commanded to seek forgiveness from the people we have hurt.",
    author: "Laura Schlessinger",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["happiness", "only", "time", "place", "here", "way", "make", "others"],
    words: [
      "happiness",
      "is",
      "the",
      "only",
      "good",
      "time",
      "to",
      "be",
      "happy",
      "now",
      "place",
      "here",
      "way",
      "make",
      "others",
      "so",
    ],
    id: "Happiness is the only good. The time to be happy is now. The place to be happy is here. The way to be happy is to make others so.",
    author: "Robert Green Ingersoll",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["important", "moving", "world", "forward", "done", "by", "perfect", "men"],
    words: [
      "the",
      "important",
      "work",
      "of",
      "moving",
      "world",
      "forward",
      "does",
      "not",
      "wait",
      "to",
      "be",
      "done",
      "by",
      "perfect",
      "men",
    ],
    id: "The important work of moving the world forward does not wait to be done by perfect men.",
    author: "George Eliot",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "power",
      "cosmetics",
      "effect",
      "on",
      "one's",
      "happiness",
      "confidence",
      "real",
    ],
    words: [
      "the",
      "power",
      "of",
      "cosmetics",
      "and",
      "effect",
      "they",
      "can",
      "have",
      "on",
      "one's",
      "happiness",
      "confidence",
      "is",
      "very",
      "real",
    ],
    id: "The power of cosmetics and the effect they can have on one's happiness and confidence is very real.",
    author: "Huda Kattan",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["valid", "plans", "future", "made", "by", "those", "capacity", "living"],
    words: [
      "no",
      "valid",
      "plans",
      "for",
      "the",
      "future",
      "can",
      "be",
      "made",
      "by",
      "those",
      "who",
      "have",
      "capacity",
      "living",
      "now",
    ],
    id: "No valid plans for the future can be made by those who have no capacity for living now.",
    author: "Alan Watts",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["said", "world", "state", "bankruptcy", "owes", "more", "than", "pay"],
    words: [
      "it",
      "is",
      "said",
      "that",
      "the",
      "world",
      "in",
      "a",
      "state",
      "of",
      "bankruptcy",
      "owes",
      "more",
      "than",
      "can",
      "pay",
    ],
    id: "It is said that the world is in a state of bankruptcy, that the world owes the world more than the world can pay.",
    author: "Ralph Waldo Emerson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "used",
      "dissembling",
      "others",
      "time",
      "come",
      "deceive",
      "dissemble",
      "ourselves",
    ],
    words: [
      "we",
      "are",
      "so",
      "used",
      "to",
      "dissembling",
      "with",
      "others",
      "that",
      "in",
      "time",
      "come",
      "deceive",
      "and",
      "dissemble",
      "ourselves",
    ],
    id: "We are so used to dissembling with others that in time we come to deceive and dissemble with ourselves.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["18", "years", "i", "resisted", "talking", "current", "events", "start"],
    words: [
      "for",
      "18",
      "years",
      "i",
      "have",
      "resisted",
      "talking",
      "about",
      "current",
      "events",
      "am",
      "not",
      "going",
      "to",
      "start",
      "now",
    ],
    id: "For 18 years I have resisted talking about current events. I am not going to start now.",
    author: "George H. W. Bush",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "should", "blissful", "mean", "small", "happiness", "huge", "profound"],
    words: [
      "life",
      "should",
      "be",
      "blissful",
      "and",
      "doesn't",
      "mean",
      "just",
      "a",
      "small",
      "happiness",
      "it's",
      "huge",
      "it",
      "is",
      "profound",
    ],
    id: "Life should be blissful, and blissful doesn't mean just a small happiness. It's huge. It is profound.",
    author: "David Lynch",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "truly", "blessed", "been", "part", "mtv", "family", "long"],
    words: [
      "i",
      "am",
      "truly",
      "blessed",
      "to",
      "have",
      "been",
      "a",
      "part",
      "of",
      "the",
      "mtv",
      "family",
      "for",
      "so",
      "long",
    ],
    id: "I am truly blessed to have been a part of the MTV family for so long.",
    author: "Rob Dyrdek",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "assume",
      "everything",
      "public",
      "knowledge",
      "because",
      "everyone",
      "reporter",
      "photographer",
    ],
    words: [
      "you",
      "have",
      "to",
      "assume",
      "that",
      "everything",
      "do",
      "is",
      "public",
      "knowledge",
      "because",
      "now",
      "everyone",
      "a",
      "reporter",
      "photographer",
    ],
    id: "You have to assume that everything you do is public knowledge. Everything. Because now everyone is a reporter. Everyone is a photographer.",
    author: "Derek Jeter",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["woman", "supposed", "same", "set", "motives", "or", "else", "monster"],
    words: [
      "every",
      "woman",
      "is",
      "supposed",
      "to",
      "have",
      "the",
      "same",
      "set",
      "of",
      "motives",
      "or",
      "else",
      "be",
      "a",
      "monster",
    ],
    id: "Every woman is supposed to have the same set of motives, or else to be a monster.",
    author: "George Eliot",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "individual",
      "because",
      "black",
      "as",
      "insulting",
      "dislike",
      "isn't",
      "white",
    ],
    words: [
      "to",
      "like",
      "an",
      "individual",
      "because",
      "he's",
      "black",
      "is",
      "just",
      "as",
      "insulting",
      "dislike",
      "him",
      "he",
      "isn't",
      "white",
    ],
    id: "To like an individual because he's black is just as insulting as to dislike him because he isn't white.",
    author: "e. e. cummings",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "another",
      "language",
      "allows",
      "your",
      "own",
      "culture",
      "better",
      "viewpoint",
    ],
    words: [
      "just",
      "learning",
      "to",
      "think",
      "in",
      "another",
      "language",
      "allows",
      "you",
      "see",
      "your",
      "own",
      "culture",
      "a",
      "better",
      "viewpoint",
    ],
    id: "Just learning to think in another language allows you to see your own culture in a better viewpoint.",
    author: "Gates McFadden",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["weekends", "sacred", "perfect", "time", "relax", "spend", "family", "friends"],
    words: [
      "weekends",
      "are",
      "sacred",
      "for",
      "me",
      "they're",
      "the",
      "perfect",
      "time",
      "to",
      "relax",
      "and",
      "spend",
      "with",
      "family",
      "friends",
    ],
    id: "Weekends are sacred for me. They're the perfect time to relax and spend time with family and friends.",
    author: "Marcus Samuelsson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["most", "important", "thing", "father", "his", "children", "their", "mother"],
    words: [
      "the",
      "most",
      "important",
      "thing",
      "a",
      "father",
      "can",
      "do",
      "for",
      "his",
      "children",
      "is",
      "to",
      "love",
      "their",
      "mother",
    ],
    id: "The most important thing a father can do for his children is to love their mother.",
    author: "Theodore Hesburgh",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["dawn", "as", "beginning", "life", "setting", "sun", "its", "close"],
    words: [
      "let",
      "every",
      "dawn",
      "be",
      "to",
      "you",
      "as",
      "the",
      "beginning",
      "of",
      "life",
      "and",
      "setting",
      "sun",
      "its",
      "close",
    ],
    id: "Let every dawn be to you as the beginning of life, and every setting sun be to you as its close.",
    author: "John Ruskin",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "there", "always", "room", "improvement", "chance", "get", "better"],
    words: [
      "i",
      "think",
      "there",
      "is",
      "always",
      "room",
      "for",
      "improvement",
      "and",
      "every",
      "day",
      "a",
      "chance",
      "to",
      "get",
      "better",
    ],
    id: "I think there is always room for improvement, and every day is a chance to get better.",
    author: "Jennifer Brady",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "start",
      "any",
      "monty",
      "python",
      "routine",
      "finish",
      "everyone",
      "shorthand",
    ],
    words: [
      "you",
      "can",
      "start",
      "any",
      "monty",
      "python",
      "routine",
      "and",
      "people",
      "finish",
      "it",
      "for",
      "everyone",
      "knows",
      "like",
      "shorthand",
    ],
    id: "You can start any 'Monty Python' routine and people finish it for you. Everyone knows it like shorthand.",
    author: "Robin Williams",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "living",
      "beginning",
      "mass",
      "extinction",
      "our",
      "climate",
      "breaking",
      "down",
    ],
    words: [
      "we",
      "are",
      "living",
      "in",
      "the",
      "beginning",
      "of",
      "a",
      "mass",
      "extinction",
      "and",
      "our",
      "climate",
      "is",
      "breaking",
      "down",
    ],
    id: "We are living in the beginning of a mass extinction and our climate is breaking down.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["anytime", "turtle", "up", "on", "top", "fence", "post", "had"],
    words: [
      "anytime",
      "you",
      "see",
      "a",
      "turtle",
      "up",
      "on",
      "top",
      "of",
      "fence",
      "post",
      "know",
      "he",
      "had",
      "some",
      "help",
    ],
    id: "Anytime you see a turtle up on top of a fence post, you know he had some help.",
    author: "Unknown",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "full", "change", "uncertainty", "experience", "on", "daily", "basis"],
    words: [
      "life",
      "is",
      "full",
      "of",
      "change",
      "and",
      "uncertainty",
      "we",
      "know",
      "this",
      "experience",
      "it",
      "on",
      "a",
      "daily",
      "basis",
    ],
    id: "Life is full of change and uncertainty. We know this. We experience it on a daily basis.",
    author: "Carre Otis",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["one", "joys", "movies", "was", "trashy", "should", "never", "lose"],
    words: [
      "one",
      "of",
      "the",
      "joys",
      "going",
      "to",
      "movies",
      "was",
      "that",
      "it",
      "trashy",
      "and",
      "we",
      "should",
      "never",
      "lose",
    ],
    id: "One of the joys of going to the movies was that it was trashy, and we should never lose that.",
    author: "Oliver Stone",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "would", "remembered", "as", "person", "wanted", "free", "also"],
    words: [
      "i",
      "would",
      "like",
      "to",
      "be",
      "remembered",
      "as",
      "a",
      "person",
      "who",
      "wanted",
      "free",
      "so",
      "other",
      "people",
      "also",
    ],
    id: "I would like to be remembered as a person who wanted to be free... so other people would be also free.",
    author: "Rosa Parks",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["possible", "without", "insisting", "i", "sing", "praises", "my", "devourer"],
    words: [
      "it",
      "is",
      "not",
      "possible",
      "to",
      "eat",
      "me",
      "without",
      "insisting",
      "that",
      "i",
      "sing",
      "praises",
      "of",
      "my",
      "devourer",
    ],
    id: "It is not possible to eat me without insisting that I sing praises of my devourer?",
    author: "Fyodor Dostoevsky",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "still", "far", "from", "being", "god's", "shall", "succeed"],
    words: [
      "i",
      "am",
      "still",
      "far",
      "from",
      "being",
      "what",
      "want",
      "to",
      "be",
      "but",
      "with",
      "god's",
      "help",
      "shall",
      "succeed",
    ],
    id: "I am still far from being what I want to be, but with God's help I shall succeed.",
    author: "Vincent Van Gogh",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "thousand", "ideas", "only", "one", "turns", "out", "satisfied"],
    words: [
      "if",
      "i",
      "have",
      "a",
      "thousand",
      "ideas",
      "and",
      "only",
      "one",
      "turns",
      "out",
      "to",
      "be",
      "good",
      "am",
      "satisfied",
    ],
    id: "If I have a thousand ideas and only one turns out to be good, I am satisfied.",
    author: "Alfred Nobel",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["start", "your", "physical", "moral", "condition", "usually", "find", "sick"],
    words: [
      "if",
      "you",
      "start",
      "to",
      "think",
      "of",
      "your",
      "physical",
      "and",
      "moral",
      "condition",
      "usually",
      "find",
      "that",
      "are",
      "sick",
    ],
    id: "If you start to think of your physical and moral condition, you usually find that you are sick.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "expect", "made", "up", "most", "unexpected", "twists", "turns"],
    words: [
      "life",
      "is",
      "not",
      "what",
      "you",
      "expect",
      "it",
      "made",
      "up",
      "of",
      "the",
      "most",
      "unexpected",
      "twists",
      "and",
      "turns",
    ],
    id: "Life is not what you expect: it is made up of the most unexpected twists and turns.",
    author: "Ilaiyaraaja",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "went", "chinese", "restaurant", "duck", "came", "up", "red"],
    words: [
      "so",
      "i",
      "went",
      "to",
      "the",
      "chinese",
      "restaurant",
      "and",
      "this",
      "duck",
      "came",
      "up",
      "me",
      "with",
      "a",
      "red",
    ],
    id: "So I went to the Chinese restaurant and this duck came up to me with a red.",
    author: "Tim Vine",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["many", "doctrine", "window", "pane", "truth", "through", "divides", "from"],
    words: [
      "many",
      "a",
      "doctrine",
      "is",
      "like",
      "window",
      "pane",
      "we",
      "see",
      "truth",
      "through",
      "it",
      "but",
      "divides",
      "us",
      "from",
    ],
    id: "Many a doctrine is like a window pane. We see truth through it but it divides us from truth.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["wood", "burns", "because", "proper", "stuff", "man", "becomes", "famous"],
    words: [
      "wood",
      "burns",
      "because",
      "it",
      "has",
      "the",
      "proper",
      "stuff",
      "in",
      "and",
      "a",
      "man",
      "becomes",
      "famous",
      "he",
      "him",
    ],
    id: "Wood burns because it has the proper stuff in it; and a man becomes famous because he has the proper stuff in him.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "there",
      "movies",
      "interested",
      "people's",
      "lives",
      "without",
      "agreeing",
      "doing",
    ],
    words: [
      "there",
      "are",
      "movies",
      "where",
      "we",
      "interested",
      "in",
      "seeing",
      "people's",
      "lives",
      "without",
      "agreeing",
      "with",
      "what",
      "they're",
      "doing",
    ],
    id: "There are movies where we are interested in seeing people's lives without agreeing with what they're doing.",
    author: "Natalie Portman",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["injury", "my", "neighbor", "there", "twenty", "gods", "or", "god"],
    words: [
      "it",
      "does",
      "me",
      "no",
      "injury",
      "for",
      "my",
      "neighbor",
      "to",
      "say",
      "there",
      "are",
      "twenty",
      "gods",
      "or",
      "god",
    ],
    id: "It does me no injury for my neighbor to say there are twenty gods or no God.",
    author: "Thomas Jefferson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["object", "war", "die", "your", "country", "make", "bastard", "his"],
    words: [
      "the",
      "object",
      "of",
      "war",
      "is",
      "not",
      "to",
      "die",
      "for",
      "your",
      "country",
      "but",
      "make",
      "other",
      "bastard",
      "his",
    ],
    id: "The object of war is not to die for your country but to make the other bastard die for his.",
    author: "George S. Patton",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["had", "been", "possible", "tower", "babel", "without", "would", "permitted"],
    words: [
      "if",
      "it",
      "had",
      "been",
      "possible",
      "to",
      "build",
      "the",
      "tower",
      "of",
      "babel",
      "without",
      "climbing",
      "would",
      "have",
      "permitted",
    ],
    id: "If it had been possible to build the Tower of Babel without climbing it, it would have been permitted.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["happiness", "man", "life", "consist", "absence", "mastery", "his", "passions"],
    words: [
      "the",
      "happiness",
      "of",
      "a",
      "man",
      "in",
      "this",
      "life",
      "does",
      "not",
      "consist",
      "absence",
      "but",
      "mastery",
      "his",
      "passions",
    ],
    id: "The happiness of a man in this life does not consist in the absence but in the mastery of his passions.",
    author: "Alfred Lord Tennyson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["conscientious", "apt", "their", "duty", "which", "most", "painful", "course"],
    words: [
      "conscientious",
      "people",
      "are",
      "apt",
      "to",
      "see",
      "their",
      "duty",
      "in",
      "that",
      "which",
      "is",
      "the",
      "most",
      "painful",
      "course",
    ],
    id: "Conscientious people are apt to see their duty in that which is the most painful course.",
    author: "George Eliot",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "written", "i", "only", "person", "his", "own", "blood"],
    words: [
      "of",
      "all",
      "that",
      "is",
      "written",
      "i",
      "love",
      "only",
      "what",
      "a",
      "person",
      "has",
      "with",
      "his",
      "own",
      "blood",
    ],
    id: "Of all that is written, I love only what a person has written with his own blood.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["shining", "one", "few", "horror", "movies", "i", "actually", "scared"],
    words: [
      "the",
      "shining",
      "is",
      "one",
      "of",
      "few",
      "horror",
      "movies",
      "that",
      "i",
      "actually",
      "like",
      "and",
      "it",
      "scared",
      "me",
    ],
    id: "The Shining' is one of the few horror movies that I actually like and it actually scared me.",
    author: "Robert Eggers",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["freedom", "struggle", "together", "only", "as", "black", "citizens", "white"],
    words: [
      "freedom",
      "is",
      "a",
      "struggle",
      "and",
      "we",
      "do",
      "it",
      "together",
      "not",
      "only",
      "as",
      "black",
      "citizens",
      "but",
      "white",
    ],
    id: "Freedom is a struggle, and we do it together. Not only together as black citizens, but black and white together.",
    author: "Andrew Young",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["wonderful", "news", "our", "lord", "god", "mercy", "responds", "repentance"],
    words: [
      "the",
      "wonderful",
      "news",
      "is",
      "that",
      "our",
      "lord",
      "a",
      "god",
      "of",
      "mercy",
      "and",
      "he",
      "responds",
      "to",
      "repentance",
    ],
    id: "The wonderful news is that our Lord is a God of mercy, and He responds to repentance.",
    author: "Billy Graham",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "believe", "i'm", "comfortable", "saying", "laying", "out", "there"],
    words: [
      "i",
      "know",
      "what",
      "believe",
      "want",
      "to",
      "do",
      "and",
      "i'm",
      "just",
      "comfortable",
      "saying",
      "it",
      "laying",
      "out",
      "there",
    ],
    id: "I know what I believe, I know what I want to do, and I'm just comfortable saying it, and laying it out there.",
    author: "Joe Biden",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "reach", "end", "your", "tie", "knot", "hang", "on"],
    words: [
      "when",
      "you",
      "reach",
      "the",
      "end",
      "of",
      "your",
      "rope",
      "tie",
      "a",
      "knot",
      "in",
      "it",
      "and",
      "hang",
      "on",
    ],
    id: "When you reach the end of your rope, tie a knot in it and hang on.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "around", "time", "ethers", "swim", "amniotic", "fluid", "soul"],
    words: [
      "love",
      "is",
      "all",
      "around",
      "us",
      "the",
      "time",
      "ethers",
      "that",
      "we",
      "swim",
      "in",
      "amniotic",
      "fluid",
      "of",
      "soul",
    ],
    id: "Love is all around us all the time. Love is the ethers that we swim in. Love is the amniotic fluid of the soul.",
    author: "Marianne Williamson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "courage",
      "rooted",
      "god",
      "appears",
      "when",
      "disappeared",
      "anxiety",
      "doubt",
    ],
    words: [
      "the",
      "courage",
      "to",
      "be",
      "is",
      "rooted",
      "in",
      "god",
      "who",
      "appears",
      "when",
      "has",
      "disappeared",
      "anxiety",
      "of",
      "doubt",
    ],
    id: "The courage to be is rooted in the God who appears when God has disappeared in the anxiety of doubt.",
    author: "Paul Tillich",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["as", "leader", "first", "person", "i", "lead", "should", "change"],
    words: [
      "as",
      "a",
      "leader",
      "the",
      "first",
      "person",
      "i",
      "need",
      "to",
      "lead",
      "is",
      "me",
      "that",
      "should",
      "try",
      "change",
    ],
    id: "As a leader, the first person I need to lead is me. The first person that I should try to change is me.",
    author: "John C. Maxwell",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["there's", "always", "curve", "you've", "got", "your", "subject", "all"],
    words: [
      "there's",
      "always",
      "a",
      "learning",
      "curve",
      "where",
      "you've",
      "got",
      "to",
      "learn",
      "what",
      "your",
      "subject",
      "is",
      "all",
      "about",
    ],
    id: "There's always a learning curve, where you've got to learn what your subject is all about.",
    author: "Brad Gilbert",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["set", "sails", "direction", "wind", "determines", "which", "way", "will"],
    words: [
      "it",
      "is",
      "the",
      "set",
      "of",
      "sails",
      "not",
      "direction",
      "wind",
      "that",
      "determines",
      "which",
      "way",
      "we",
      "will",
      "go",
    ],
    id: "It is the set of the sails, not the direction of the wind that determines which way we will go.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["swing", "hard", "hit", "home", "run", "got", "timing", "it'll"],
    words: [
      "you",
      "don't",
      "have",
      "to",
      "swing",
      "hard",
      "hit",
      "a",
      "home",
      "run",
      "if",
      "got",
      "the",
      "timing",
      "it'll",
      "go",
    ],
    id: "You don't have to swing hard to hit a home run. If you got the timing, it'll go.",
    author: "Yogi Berra",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "our",
      "party",
      "attaches",
      "great",
      "importance",
      "young",
      "firmly",
      "believes",
    ],
    words: [
      "our",
      "party",
      "attaches",
      "great",
      "importance",
      "to",
      "the",
      "work",
      "with",
      "young",
      "people",
      "and",
      "firmly",
      "believes",
      "in",
      "them",
    ],
    id: "Our Party attaches great importance to the work with young people and firmly believes in them.",
    author: "Kim Jong-un",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "went", "factory", "first", "thing", "i'd", "join", "union"],
    words: [
      "if",
      "i",
      "went",
      "to",
      "work",
      "in",
      "a",
      "factory",
      "the",
      "first",
      "thing",
      "i'd",
      "do",
      "is",
      "join",
      "union",
    ],
    id: "If I went to work in a factory the first thing I'd do is join a union.",
    author: "Franklin D. Roosevelt",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["remember", "happiness", "depend", "upon", "or", "depends", "solely", "on"],
    words: [
      "remember",
      "happiness",
      "doesn't",
      "depend",
      "upon",
      "who",
      "you",
      "are",
      "or",
      "what",
      "have",
      "it",
      "depends",
      "solely",
      "on",
      "think",
    ],
    id: "Remember happiness doesn't depend upon who you are or what you have; it depends solely on what you think.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["teach", "o", "lord", "disciplines", "patience", "often", "harder", "than"],
    words: [
      "teach",
      "us",
      "o",
      "lord",
      "the",
      "disciplines",
      "of",
      "patience",
      "for",
      "to",
      "wait",
      "is",
      "often",
      "harder",
      "than",
      "work",
    ],
    id: "Teach us, O Lord, the disciplines of patience, for to wait is often harder than to work.",
    author: "Peter Marshall",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "trumpet", "-", "put", "anything", "into", "get", "out"],
    words: [
      "life",
      "is",
      "like",
      "a",
      "trumpet",
      "-",
      "if",
      "you",
      "don't",
      "put",
      "anything",
      "into",
      "it",
      "get",
      "out",
      "of",
    ],
    id: "Life is like a trumpet - if you don't put anything into it, you don't get anything out of it.",
    author: "William Christopher Handy",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "all",
      "intelligent",
      "thoughts",
      "already",
      "been",
      "thought",
      "necessary",
      "only",
    ],
    words: [
      "all",
      "intelligent",
      "thoughts",
      "have",
      "already",
      "been",
      "thought",
      "what",
      "is",
      "necessary",
      "only",
      "to",
      "try",
      "think",
      "them",
      "again",
    ],
    id: "All intelligent thoughts have already been thought; what is necessary is only to try to think them again.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["member", "british", "royal", "family", "never", "tired", "all", "hospitals"],
    words: [
      "you",
      "are",
      "a",
      "member",
      "of",
      "the",
      "british",
      "royal",
      "family",
      "we",
      "never",
      "tired",
      "and",
      "all",
      "love",
      "hospitals",
    ],
    id: "You are a member of the British royal family. We are never tired, and we all love hospitals.",
    author: "Mary of Teck",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["loving", "liberates", "lover", "as", "beloved", "kind", "comes", "age"],
    words: [
      "loving",
      "someone",
      "liberates",
      "the",
      "lover",
      "as",
      "well",
      "beloved",
      "and",
      "that",
      "kind",
      "of",
      "love",
      "comes",
      "with",
      "age",
    ],
    id: "Loving someone liberates the lover as well as the beloved. And that kind of love comes with age.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["dost", "thou", "life", "then", "squander", "time", "stuff", "made"],
    words: [
      "dost",
      "thou",
      "love",
      "life",
      "then",
      "do",
      "not",
      "squander",
      "time",
      "for",
      "that",
      "is",
      "the",
      "stuff",
      "made",
      "of",
    ],
    id: "Dost thou love life? Then do not squander time, for that is the stuff life is made of.",
    author: "Benjamin Franklin",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["men", "real", "man", "lives", "as", "was", "meant", "live"],
    words: [
      "let",
      "men",
      "see",
      "them",
      "know",
      "a",
      "real",
      "man",
      "who",
      "lives",
      "as",
      "he",
      "was",
      "meant",
      "to",
      "live",
    ],
    id: "Let men see, let them know, a real man, who lives as he was meant to live.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["would", "tell", "away", "from", "hockey", "i'm", "really", "competitive"],
    words: [
      "and",
      "people",
      "who",
      "know",
      "me",
      "would",
      "tell",
      "you",
      "that",
      "away",
      "from",
      "hockey",
      "i'm",
      "really",
      "not",
      "competitive",
    ],
    id: "And people who know me would tell you that away from hockey I'm really not that competitive.",
    author: "Wayne Gretzky",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "comforting",
      "reflect",
      "disproportion",
      "things",
      "world",
      "seems",
      "only",
      "arithmetical",
    ],
    words: [
      "it",
      "is",
      "comforting",
      "to",
      "reflect",
      "that",
      "the",
      "disproportion",
      "of",
      "things",
      "in",
      "world",
      "seems",
      "be",
      "only",
      "arithmetical",
    ],
    id: "It is comforting to reflect that the disproportion of things in the world seems to be only arithmetical.",
    author: "Franz Kafka",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["born", "on", "third", "base", "through", "life", "hit", "triple"],
    words: [
      "some",
      "people",
      "are",
      "born",
      "on",
      "third",
      "base",
      "and",
      "go",
      "through",
      "life",
      "thinking",
      "they",
      "hit",
      "a",
      "triple",
    ],
    id: "Some people are born on third base and go through life thinking they hit a triple.",
    author: "Barry Switzer",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "believe", "greatest", "gift", "your", "family", "world", "healthy"],
    words: [
      "i",
      "believe",
      "that",
      "the",
      "greatest",
      "gift",
      "you",
      "can",
      "give",
      "your",
      "family",
      "and",
      "world",
      "is",
      "a",
      "healthy",
    ],
    id: "I believe that the greatest gift you can give your family and the world is a healthy you.",
    author: "Joyce Meyer",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["even", "hubble", "hasn't", "found", "yet", "end", "universe", "any"],
    words: [
      "even",
      "hubble",
      "hasn't",
      "found",
      "yet",
      "the",
      "end",
      "of",
      "this",
      "universe",
      "and",
      "we",
      "don't",
      "know",
      "that",
      "it",
      "has",
      "any",
    ],
    id: "Even Hubble hasn't found yet the end of this universe, and we don't know that it has any end.",
    author: "Billy Graham",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["play", "time", "long", "balls", "lose", "possession", "momentum", "i"],
    words: [
      "if",
      "you",
      "play",
      "every",
      "time",
      "long",
      "balls",
      "lose",
      "possession",
      "and",
      "momentum",
      "i",
      "don't",
      "want",
      "to",
      "be",
      "like",
      "that",
    ],
    id: "If you play every time, long balls, you lose possession and you lose momentum. I don't want to be like that.",
    author: "Unai Emery",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "second", "option", "person", "or", "nothing", "way", "prefer"],
    words: [
      "i",
      "am",
      "not",
      "a",
      "second",
      "option",
      "person",
      "it",
      "is",
      "that",
      "or",
      "nothing",
      "if",
      "the",
      "way",
      "see",
      "prefer",
      "to",
    ],
    id: "I am not a second option person. It is that or nothing. If it is not the way I see it I prefer not to see it.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["sometimes", "way", "things", "happen", "movies", "unreal", "actually", "life"],
    words: [
      "people",
      "sometimes",
      "say",
      "the",
      "way",
      "things",
      "happen",
      "in",
      "movies",
      "is",
      "unreal",
      "but",
      "actually",
      "it's",
      "to",
      "you",
      "life",
      "that's",
    ],
    id: "People sometimes say the way things happen in the movies is unreal, but actually, it's the way things happen to you in life that's unreal.",
    author: "Andy Warhol",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "gardeners",
      "at",
      "nurturing",
      "great",
      "quality",
      "patience",
      "tender",
      "persistent",
    ],
    words: [
      "gardeners",
      "are",
      "good",
      "at",
      "nurturing",
      "and",
      "they",
      "have",
      "a",
      "great",
      "quality",
      "of",
      "patience",
      "they're",
      "tender",
      "to",
      "be",
      "persistent",
    ],
    id: "Gardeners are good at nurturing, and they have a great quality of patience, they're tender. They have to be persistent.",
    author: "Ralph Fiennes",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["sometimes", "movies", "i", "still", "hero", "all", "important", "anymore"],
    words: [
      "sometimes",
      "in",
      "movies",
      "i",
      "still",
      "have",
      "to",
      "be",
      "the",
      "hero",
      "but",
      "it's",
      "not",
      "all",
      "that",
      "important",
      "me",
      "anymore",
    ],
    id: "Sometimes in movies, I still have to be the hero, but it's not all that important to me anymore.",
    author: "Dennis Quaid",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["one", "lessons", "history", "nothing", "often", "thing", "always", "clever"],
    words: [
      "one",
      "of",
      "the",
      "lessons",
      "history",
      "is",
      "that",
      "nothing",
      "often",
      "a",
      "good",
      "thing",
      "to",
      "do",
      "and",
      "always",
      "clever",
      "say",
    ],
    id: "One of the lessons of history is that nothing is often a good thing to do and always a clever thing to say.",
    author: "Will Durant",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["best", "thing", "rejection", "make", "experience", "-", "great", "teacher"],
    words: [
      "the",
      "best",
      "thing",
      "we",
      "can",
      "do",
      "with",
      "rejection",
      "is",
      "to",
      "make",
      "it",
      "a",
      "learning",
      "experience",
      "-",
      "great",
      "teacher",
    ],
    id: "The best thing we can do with rejection is to make it a learning experience - rejection is a great teacher.",
    author: "Adena Friedman",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "everything",
      "happens",
      "meant",
      "happen",
      "sometimes",
      "at",
      "time",
      "disaster",
    ],
    words: [
      "everything",
      "that",
      "happens",
      "is",
      "meant",
      "to",
      "be",
      "it's",
      "happen",
      "like",
      "but",
      "sometimes",
      "you",
      "don't",
      "know",
      "at",
      "the",
      "time",
      "disaster",
    ],
    id: "Everything that happens is meant to be. It's meant to happen like that. But sometimes you don't know at the time that it's meant to be disaster.",
    author: "Tony Kaye",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["real", "trouble", "war", "modern", "one", "chance", "kill", "right"],
    words: [
      "the",
      "real",
      "trouble",
      "with",
      "war",
      "modern",
      "is",
      "that",
      "it",
      "gives",
      "no",
      "one",
      "a",
      "chance",
      "to",
      "kill",
      "right",
      "people",
    ],
    id: "The real trouble with war (modern war) is that it gives no one a chance to kill the right people.",
    author: "Ezra Pound",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "cannot", "formula", "success", "failure", "-", "which", "everybody"],
    words: [
      "i",
      "cannot",
      "give",
      "you",
      "the",
      "formula",
      "for",
      "success",
      "but",
      "can",
      "failure",
      "-",
      "which",
      "is",
      "try",
      "to",
      "please",
      "everybody",
    ],
    id: "I cannot give you the formula for success, but I can give you the formula for failure - which is: Try to please everybody.",
    author: "Herbert Bayard Swope",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["problem", "seeds", "its", "own", "solution", "any", "problems", "get"],
    words: [
      "every",
      "problem",
      "has",
      "in",
      "it",
      "the",
      "seeds",
      "of",
      "its",
      "own",
      "solution",
      "if",
      "you",
      "don't",
      "have",
      "any",
      "problems",
      "get",
    ],
    id: "Every problem has in it the seeds of its own solution. If you don't have any problems, you don't get any seeds.",
    author: "Norman Vincent Peale",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "i",
      "idea",
      "isolation",
      "reality",
      "realize",
      "knowledge",
      "inevitably",
      "rewarding",
    ],
    words: [
      "i",
      "like",
      "the",
      "idea",
      "of",
      "isolation",
      "reality",
      "it",
      "you",
      "realize",
      "what",
      "are",
      "not",
      "that",
      "knowledge",
      "is",
      "inevitably",
      "rewarding",
    ],
    id: "I like the idea of isolation. I like the reality of it. You realize what you are... not that the knowledge is inevitably rewarding.",
    author: "Joseph Brodsky",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["feel", "your", "heart", "right-", "you'll", "criticized", "anyway", "damned"],
    words: [
      "do",
      "what",
      "you",
      "feel",
      "in",
      "your",
      "heart",
      "to",
      "be",
      "right-",
      "for",
      "you'll",
      "criticized",
      "anyway",
      "damned",
      "if",
      "and",
      "don't",
    ],
    id: "Do what you feel in your heart to be right- for you'll be criticized anyway. You'll be damned if you do, and damned if you don't.",
    author: "Eleanor Roosevelt",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["success", "overseas", "-", "also", "leading", "village", "better", "future"],
    words: [
      "success",
      "to",
      "us",
      "is",
      "not",
      "just",
      "a",
      "girl",
      "overseas",
      "going",
      "school",
      "-",
      "but",
      "also",
      "leading",
      "her",
      "village",
      "better",
      "future",
    ],
    id: "Success to us is not just a girl overseas going to school - but also leading her village to a better future.",
    author: "Craig Kielburger",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "getting",
      "1",
      "rating",
      "households",
      "sign",
      "momentum",
      "bragging",
      "rights",
    ],
    words: [
      "getting",
      "to",
      "a",
      "1",
      "rating",
      "in",
      "households",
      "is",
      "sign",
      "that",
      "we're",
      "building",
      "momentum",
      "it",
      "gives",
      "us",
      "bragging",
      "rights",
    ],
    id: "Getting to a 1 rating in households is a sign that we're building momentum. It gives us bragging rights.",
    author: "Bonnie Hammer",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "certain", "god", "make", "mistakes", "miracles", "one", "too"],
    words: [
      "i",
      "know",
      "for",
      "certain",
      "that",
      "god",
      "does",
      "not",
      "make",
      "mistakes",
      "but",
      "he",
      "miracles",
      "am",
      "one",
      "you",
      "are",
      "too",
    ],
    id: "I know for certain that God does not make mistakes, but he does make miracles. I am one. You are, too.",
    author: "Nick Vujicic",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "truth",
      "obscure",
      "these",
      "times",
      "falsehood",
      "established",
      "unless",
      "cannot",
    ],
    words: [
      "truth",
      "is",
      "so",
      "obscure",
      "in",
      "these",
      "times",
      "and",
      "falsehood",
      "established",
      "that",
      "unless",
      "we",
      "love",
      "the",
      "cannot",
      "know",
      "it",
    ],
    id: "Truth is so obscure in these times, and falsehood so established, that, unless we love the truth, we cannot know it.",
    author: "Blaise Pascal",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["change", "tough", "necessary", "take", "two", "aspirins", "call", "morning"],
    words: [
      "change",
      "is",
      "tough",
      "people",
      "don't",
      "like",
      "it",
      "but",
      "necessary",
      "take",
      "two",
      "aspirins",
      "and",
      "call",
      "me",
      "in",
      "the",
      "morning",
    ],
    id: "Change is tough, people don't like it, but it is necessary. Take two aspirins and call me in the morning.",
    author: "Christopher Bond",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "surmount",
      "difficulties",
      "face",
      "steadfastly",
      "happiness",
      "prosperity",
      "our",
      "country",
    ],
    words: [
      "we",
      "have",
      "to",
      "surmount",
      "the",
      "difficulties",
      "that",
      "face",
      "us",
      "and",
      "work",
      "steadfastly",
      "for",
      "happiness",
      "prosperity",
      "of",
      "our",
      "country",
    ],
    id: "We have to surmount the difficulties that face us and work steadfastly for the happiness and prosperity of our country.",
    author: "Lal Bahadur Shastri",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "embrace",
      "whole",
      "world",
      "friendship",
      "wisdom",
      "changeable",
      "bloom",
      "fade",
    ],
    words: [
      "to",
      "embrace",
      "the",
      "whole",
      "world",
      "in",
      "friendship",
      "is",
      "wisdom",
      "this",
      "not",
      "changeable",
      "like",
      "flowers",
      "that",
      "bloom",
      "and",
      "fade",
    ],
    id: "To embrace the whole world in friendship is wisdom. This wisdom is not changeable like the flowers that bloom and fade.",
    author: "Thiruvalluvar",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "rules",
      "friendship",
      "tacit",
      "unconscious",
      "rational",
      "business",
      "though",
      "rationally",
    ],
    words: [
      "the",
      "rules",
      "of",
      "friendship",
      "are",
      "tacit",
      "unconscious",
      "they",
      "not",
      "rational",
      "in",
      "business",
      "though",
      "you",
      "have",
      "to",
      "think",
      "rationally",
    ],
    id: "The rules of friendship are tacit, unconscious; they are not rational. In business, though, you have to think rationally.",
    author: "Steven Pinker",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["religion", "masterpiece", "art", "training", "trains", "as", "how", "shall"],
    words: [
      "religion",
      "is",
      "the",
      "masterpiece",
      "of",
      "art",
      "animal",
      "training",
      "for",
      "it",
      "trains",
      "people",
      "as",
      "to",
      "how",
      "they",
      "shall",
      "think",
    ],
    id: "Religion is the masterpiece of the art of animal training, for it trains people as to how they shall think.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["hollywood", "things", "at", "night", "fast", "enough", "olympics", "time"],
    words: [
      "in",
      "hollywood",
      "you",
      "can",
      "see",
      "things",
      "at",
      "night",
      "that",
      "are",
      "fast",
      "enough",
      "to",
      "be",
      "the",
      "olympics",
      "day",
      "time",
    ],
    id: "In Hollywood you can see things at night that are fast enough to be in the Olympics in the day time.",
    author: "Will Rogers",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["keep", "on", "saying", "things", "bad", "chance", "being", "prophet"],
    words: [
      "if",
      "you",
      "keep",
      "on",
      "saying",
      "things",
      "are",
      "going",
      "to",
      "be",
      "bad",
      "have",
      "a",
      "good",
      "chance",
      "of",
      "being",
      "prophet",
    ],
    id: "If you keep on saying things are going to be bad, you have a good chance of being a prophet.",
    author: "Isaac Bashevis Singer",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["happiness", "must", "happen", "same", "holds", "success", "by", "caring"],
    words: [
      "happiness",
      "must",
      "happen",
      "and",
      "the",
      "same",
      "holds",
      "for",
      "success",
      "you",
      "have",
      "to",
      "let",
      "it",
      "by",
      "not",
      "caring",
      "about",
    ],
    id: "Happiness must happen, and the same holds for success: you have to let it happen by not caring about it.",
    author: "Viktor E. Frankl",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "office",
      "government",
      "confer",
      "happiness",
      "men",
      "opportunity",
      "out",
      "themselves",
    ],
    words: [
      "the",
      "office",
      "of",
      "government",
      "is",
      "not",
      "to",
      "confer",
      "happiness",
      "but",
      "give",
      "men",
      "opportunity",
      "work",
      "out",
      "for",
      "themselves",
    ],
    id: "The office of government is not to confer happiness, but to give men the opportunity to work out happiness for themselves.",
    author: "William Ellery Channing",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["woman", "ever", "hates", "man", "being", "many", "hate", "friend"],
    words: [
      "no",
      "woman",
      "ever",
      "hates",
      "a",
      "man",
      "for",
      "being",
      "in",
      "love",
      "with",
      "her",
      "but",
      "many",
      "hate",
      "friend",
      "to",
    ],
    id: "No woman ever hates a man for being in love with her, but many a woman hate a man for being a friend to her.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["could", "first", "whither", "tending", "then", "better", "judge", "how"],
    words: [
      "if",
      "we",
      "could",
      "first",
      "know",
      "where",
      "are",
      "and",
      "whither",
      "tending",
      "then",
      "better",
      "judge",
      "what",
      "to",
      "do",
      "how",
      "it",
    ],
    id: "If we could first know where we are, and whither we are tending, we could then better judge what to do, and how to do it.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["start", "i", "york", "little", "bit", "whole", "world", "comes"],
    words: [
      "to",
      "start",
      "with",
      "i",
      "love",
      "new",
      "york",
      "it's",
      "a",
      "little",
      "bit",
      "of",
      "the",
      "whole",
      "world",
      "in",
      "comes",
      "you",
    ],
    id: "To start with, I love New York... It's a little bit of the whole world... In New York, the whole world comes to you.",
    author: "Billy Graham",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "being",
      "jackass",
      "hailstorm",
      "there's",
      "nothing",
      "stand",
      "there",
      "take",
    ],
    words: [
      "being",
      "president",
      "is",
      "like",
      "a",
      "jackass",
      "in",
      "hailstorm",
      "there's",
      "nothing",
      "to",
      "do",
      "but",
      "stand",
      "there",
      "and",
      "take",
      "it",
    ],
    id: "Being president is like being a jackass in a hailstorm. There's nothing to do but to stand there and take it.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["how", "live", "harmony", "first", "all", "madly", "same", "god"],
    words: [
      "how",
      "can",
      "we",
      "live",
      "in",
      "harmony",
      "first",
      "need",
      "to",
      "know",
      "are",
      "all",
      "madly",
      "love",
      "with",
      "the",
      "same",
      "god",
    ],
    id: "How can we live in harmony? First we need to know we are all madly in love with the same God.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "deep",
      "joy",
      "take",
      "company",
      "whom",
      "recently",
      "fallen",
      "undisguisable",
    ],
    words: [
      "the",
      "deep",
      "joy",
      "we",
      "take",
      "in",
      "company",
      "of",
      "people",
      "with",
      "whom",
      "have",
      "just",
      "recently",
      "fallen",
      "love",
      "is",
      "undisguisable",
    ],
    id: "The deep joy we take in the company of people with whom we have just recently fallen in love is undisguisable.",
    author: "John Cheever",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["anyone", "who's", "chef", "food", "ultimately", "all", "matters", "pleasure"],
    words: [
      "anyone",
      "who's",
      "a",
      "chef",
      "who",
      "loves",
      "food",
      "ultimately",
      "knows",
      "that",
      "all",
      "matters",
      "is",
      "it",
      "good",
      "does",
      "give",
      "pleasure",
    ],
    id: "Anyone who's a chef, who loves food, ultimately knows that all that matters is: 'Is it good? Does it give pleasure?'",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["test", "artist", "lie", "will", "which", "goes", "excellence", "produces"],
    words: [
      "the",
      "test",
      "of",
      "artist",
      "does",
      "not",
      "lie",
      "in",
      "will",
      "with",
      "which",
      "he",
      "goes",
      "to",
      "work",
      "but",
      "excellence",
      "produces",
    ],
    id: "The test of the artist does not lie in the will with which he goes to work, but in the excellence of the work he produces.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i'm", "afraid", "death", "hurry", "die", "i", "much", "first"],
    words: [
      "i'm",
      "not",
      "afraid",
      "of",
      "death",
      "but",
      "in",
      "no",
      "hurry",
      "to",
      "die",
      "i",
      "have",
      "so",
      "much",
      "want",
      "do",
      "first",
    ],
    id: "I'm not afraid of death, but I'm in no hurry to die. I have so much I want to do first.",
    author: "Stephen Hawking",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "when",
      "difficult",
      "get",
      "momentum",
      "your",
      "team-mates",
      "regularly",
      "team",
    ],
    words: [
      "when",
      "you",
      "are",
      "new",
      "it",
      "is",
      "difficult",
      "to",
      "get",
      "momentum",
      "and",
      "know",
      "your",
      "team-mates",
      "not",
      "regularly",
      "in",
      "the",
      "team",
    ],
    id: "When you are new, it is difficult to get momentum and get to know your team-mates when you are not regularly in the team.",
    author: "Christian Eriksen",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "philosophy", "i", "any", "invest", "ventures", "sitting", "around"],
    words: [
      "my",
      "philosophy",
      "is",
      "that",
      "if",
      "i",
      "have",
      "any",
      "money",
      "invest",
      "it",
      "in",
      "new",
      "ventures",
      "and",
      "not",
      "sitting",
      "around",
    ],
    id: "My philosophy is that if I have any money I invest it in new ventures and not have it sitting around.",
    author: "Richard Branson",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["one", "possibly", "happen", "happening", "each", "time", "first", "only"],
    words: [
      "no",
      "one",
      "can",
      "possibly",
      "know",
      "what",
      "is",
      "about",
      "to",
      "happen",
      "it",
      "happening",
      "each",
      "time",
      "for",
      "the",
      "first",
      "only",
    ],
    id: "No one can possibly know what is about to happen: it is happening, each time, for the first time, for the only time.",
    author: "James Baldwin",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["many", "television", "movies", "may", "only", "way", "understand", "aren't"],
    words: [
      "for",
      "so",
      "many",
      "people",
      "television",
      "and",
      "movies",
      "may",
      "be",
      "the",
      "only",
      "way",
      "they",
      "understand",
      "who",
      "aren't",
      "like",
      "them",
    ],
    id: "For so many people, television and movies may be the only way they understand people who aren't like them.",
    author: "Michelle Obama",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "noble", "manner", "being", "poor", "will", "never", "rich"],
    words: [
      "there",
      "is",
      "a",
      "noble",
      "manner",
      "of",
      "being",
      "poor",
      "and",
      "who",
      "does",
      "not",
      "know",
      "it",
      "will",
      "never",
      "be",
      "rich",
    ],
    id: "There is a noble manner of being poor, and who does not know it will never be rich.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["age", "thing", "all", "up", "happiness", "understand", "before", "get"],
    words: [
      "this",
      "age",
      "thing",
      "is",
      "all",
      "up",
      "to",
      "you",
      "it's",
      "like",
      "happiness",
      "just",
      "have",
      "understand",
      "what",
      "it",
      "before",
      "get",
    ],
    id: "This age thing is all up to you. It's like happiness is up to you. You just have to understand what it is before you get it.",
    author: "Elaine Stritch",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["tell", "how", "get", "there", "you'll", "amazed", "at", "results"],
    words: [
      "if",
      "you",
      "tell",
      "people",
      "where",
      "to",
      "go",
      "but",
      "not",
      "how",
      "get",
      "there",
      "you'll",
      "be",
      "amazed",
      "at",
      "the",
      "results",
    ],
    id: "If you tell people where to go, but not how to get there, you'll be amazed at the results.",
    author: "George S. Patton",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["anyone", "sell", "overnight", "success", "or", "wealth", "interested", "your"],
    words: [
      "anyone",
      "who",
      "wants",
      "to",
      "sell",
      "you",
      "overnight",
      "success",
      "or",
      "wealth",
      "is",
      "not",
      "interested",
      "in",
      "your",
      "they",
      "are",
      "money",
    ],
    id: "Anyone who wants to sell you overnight success or wealth is not interested in your success; they are interested in your money.",
    author: "Bo Bennett",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "hence",
      "general",
      "skilful",
      "attack",
      "whose",
      "opponent",
      "defend",
      "defense",
    ],
    words: [
      "hence",
      "that",
      "general",
      "is",
      "skilful",
      "in",
      "attack",
      "whose",
      "opponent",
      "does",
      "not",
      "know",
      "what",
      "to",
      "defend",
      "and",
      "he",
      "defense",
    ],
    id: "Hence that general is skilful in attack whose opponent does not know what to defend; and he is skilful in defense whose opponent does not know what to attack.",
    author: "Sun Tzu",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["almost", "always", "fault", "one", "realize", "when", "ceases", "loved"],
    words: [
      "it",
      "is",
      "almost",
      "always",
      "a",
      "fault",
      "of",
      "one",
      "who",
      "loves",
      "not",
      "to",
      "realize",
      "when",
      "he",
      "ceases",
      "be",
      "loved",
    ],
    id: "It is almost always a fault of one who loves not to realize when he ceases to be loved.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["begin", "by", "committing", "ourselves", "truth", "tell", "find", "live"],
    words: [
      "let",
      "us",
      "begin",
      "by",
      "committing",
      "ourselves",
      "to",
      "the",
      "truth",
      "see",
      "it",
      "like",
      "is",
      "and",
      "tell",
      "find",
      "speak",
      "live",
    ],
    id: "Let us begin by committing ourselves to the truth to see it like it is, and tell it like it is, to find the truth, to speak the truth, and to live the truth.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "three",
      "things",
      "necessary",
      "salvation",
      "man",
      "ought",
      "believe",
      "desire",
    ],
    words: [
      "three",
      "things",
      "are",
      "necessary",
      "for",
      "the",
      "salvation",
      "of",
      "man",
      "to",
      "know",
      "what",
      "he",
      "ought",
      "believe",
      "desire",
      "and",
      "do",
    ],
    id: "Three things are necessary for the salvation of man: to know what he ought to believe; to know what he ought to desire; and to know what he ought to do.",
    author: "Thomas Aquinas",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["use", "saying", "doing", "our", "best", "got", "succeed", "necessary"],
    words: [
      "it",
      "is",
      "no",
      "use",
      "saying",
      "we",
      "are",
      "doing",
      "our",
      "best",
      "you",
      "have",
      "got",
      "to",
      "succeed",
      "in",
      "what",
      "necessary",
    ],
    id: "It is no use saying, 'We are doing our best.' You have got to succeed in doing what is necessary.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["man", "enjoys", "true", "taste", "life", "ready", "willing", "quit"],
    words: [
      "no",
      "man",
      "enjoys",
      "the",
      "true",
      "taste",
      "of",
      "life",
      "but",
      "he",
      "who",
      "is",
      "ready",
      "and",
      "willing",
      "to",
      "quit",
      "it",
    ],
    id: "No man enjoys the true taste of life, but he who is ready and willing to quit it.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["though", "may", "by", "thousand", "names", "one", "same", "all"],
    words: [
      "though",
      "we",
      "may",
      "know",
      "him",
      "by",
      "a",
      "thousand",
      "names",
      "he",
      "is",
      "one",
      "and",
      "the",
      "same",
      "to",
      "us",
      "all",
    ],
    id: "Though we may know Him by a thousand names, He is one and the same to us all.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["hating", "because", "their", "color", "wrong", "matter", "which", "plain"],
    words: [
      "hating",
      "people",
      "because",
      "of",
      "their",
      "color",
      "is",
      "wrong",
      "and",
      "it",
      "doesn't",
      "matter",
      "which",
      "does",
      "the",
      "it's",
      "just",
      "plain",
    ],
    id: "Hating people because of their color is wrong. And it doesn't matter which color does the hating. It's just plain wrong.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["american", "entitled", "his", "views", "directly", "only", "through", "press"],
    words: [
      "the",
      "american",
      "people",
      "are",
      "entitled",
      "to",
      "see",
      "president",
      "and",
      "hear",
      "his",
      "views",
      "directly",
      "not",
      "him",
      "only",
      "through",
      "press",
    ],
    id: "The American people are entitled to see the president and to hear his views directly, and not to see him only through the press.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "no-fail",
      "incontrovertible",
      "reality",
      "get",
      "teach",
      "can't",
      "anything",
      "except",
    ],
    words: [
      "it",
      "is",
      "a",
      "no-fail",
      "incontrovertible",
      "reality",
      "if",
      "you",
      "get",
      "give",
      "learn",
      "teach",
      "can't",
      "do",
      "anything",
      "with",
      "that",
      "except",
    ],
    id: "It is a no-fail, incontrovertible reality: If you get, give. If you learn, teach. You can't do anything with that except do it.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "minister", "preach", "sermon", "without", "addressing", "sinners", "how"],
    words: [
      "i",
      "do",
      "think",
      "that",
      "a",
      "minister",
      "who",
      "can",
      "preach",
      "sermon",
      "without",
      "addressing",
      "sinners",
      "does",
      "not",
      "know",
      "how",
      "to",
    ],
    id: "I do think that a minister who can preach a sermon without addressing sinners does not know how to preach.",
    author: "Charles Spurgeon",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["god", "prosper", "financially", "plenty", "fulfill", "destiny", "laid", "out"],
    words: [
      "god",
      "wants",
      "us",
      "to",
      "prosper",
      "financially",
      "have",
      "plenty",
      "of",
      "money",
      "fulfill",
      "the",
      "destiny",
      "he",
      "has",
      "laid",
      "out",
      "for",
    ],
    id: "God wants us to prosper financially, to have plenty of money, to fulfill the destiny He has laid out for us.",
    author: "Joel Osteen",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "idea", "absolute", "happiness", "bed", "on", "rainy", "blankie"],
    words: [
      "my",
      "idea",
      "of",
      "absolute",
      "happiness",
      "is",
      "to",
      "be",
      "in",
      "bed",
      "on",
      "a",
      "rainy",
      "day",
      "with",
      "blankie",
      "cat",
      "and",
      "dog",
    ],
    id: "My idea of absolute happiness is to be in bed on a rainy day, with my blankie, my cat, and my dog.",
    author: "Anne Lamott",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "opportunity",
      "others",
      "or",
      "incite",
      "change",
      "feels",
      "moral",
      "obligation",
    ],
    words: [
      "if",
      "you",
      "have",
      "an",
      "opportunity",
      "to",
      "help",
      "others",
      "or",
      "incite",
      "change",
      "it",
      "feels",
      "like",
      "a",
      "moral",
      "obligation",
      "do",
      "so",
    ],
    id: "If you have an opportunity to help others or incite change, it feels like a moral obligation to do so.",
    author: "Elizabeth Chambers",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "whenever",
      "angry",
      "assured",
      "only",
      "present",
      "evil",
      "increased",
      "habit",
    ],
    words: [
      "whenever",
      "you",
      "are",
      "angry",
      "be",
      "assured",
      "that",
      "it",
      "is",
      "not",
      "only",
      "a",
      "present",
      "evil",
      "but",
      "have",
      "increased",
      "habit",
    ],
    id: "Whenever you are angry, be assured that it is not only a present evil, but that you have increased a habit.",
    author: "Epictetus",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "life",
      "simple",
      "easy",
      "understand",
      "complicate",
      "beliefs",
      "ideas",
      "create",
    ],
    words: [
      "life",
      "is",
      "very",
      "simple",
      "and",
      "easy",
      "to",
      "understand",
      "but",
      "we",
      "complicate",
      "it",
      "with",
      "the",
      "beliefs",
      "ideas",
      "that",
      "create",
    ],
    id: "Life is very, very simple and easy to understand, but we complicate it with the beliefs and ideas that we create.",
    author: "Don Miguel Ruiz",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "associate",
      "yourself",
      "quality",
      "better",
      "alone",
      "than",
      "bad",
      "company",
    ],
    words: [
      "associate",
      "yourself",
      "with",
      "people",
      "of",
      "good",
      "quality",
      "for",
      "it",
      "is",
      "better",
      "to",
      "be",
      "alone",
      "than",
      "in",
      "bad",
      "company",
    ],
    id: "Associate yourself with people of good quality, for it is better to be alone than in bad company.",
    author: "Booker T. Washington",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "take", "chance", "on", "something", "full", "balls", "wall"],
    words: [
      "i",
      "say",
      "if",
      "you're",
      "going",
      "to",
      "take",
      "a",
      "chance",
      "on",
      "something",
      "you",
      "just",
      "go",
      "full",
      "balls",
      "the",
      "wall",
    ],
    id: "I say if you're going to take a chance on something, you just go full balls to the wall.",
    author: "Toby Keith",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "anything", "possible", "mindset", "will", "desire", "put", "time"],
    words: [
      "i",
      "think",
      "anything",
      "is",
      "possible",
      "if",
      "you",
      "have",
      "the",
      "mindset",
      "and",
      "will",
      "desire",
      "to",
      "do",
      "it",
      "put",
      "time",
      "in",
    ],
    id: "I think anything is possible if you have the mindset and the will and desire to do it and put the time in.",
    author: "Roger Clemens",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "there",
      "secrets",
      "success",
      "result",
      "preparation",
      "hard",
      "from",
      "failure",
    ],
    words: [
      "there",
      "are",
      "no",
      "secrets",
      "to",
      "success",
      "it",
      "is",
      "the",
      "result",
      "of",
      "preparation",
      "hard",
      "work",
      "and",
      "learning",
      "from",
      "failure",
    ],
    id: "There are no secrets to success. It is the result of preparation, hard work, and learning from failure.",
    author: "Colin Powell",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["patience", "virtue", "life", "course", "something", "f1", "too", "much"],
    words: [
      "patience",
      "is",
      "a",
      "virtue",
      "in",
      "life",
      "of",
      "course",
      "but",
      "it's",
      "not",
      "something",
      "we",
      "f1",
      "people",
      "have",
      "too",
      "much",
    ],
    id: "Patience is a virtue in life, of course, but it's not something we F1 people have too much of.",
    author: "Niki Lauda",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["takes", "long", "time", "courtroom", "last", "place", "world", "truth"],
    words: [
      "it",
      "takes",
      "a",
      "long",
      "time",
      "to",
      "learn",
      "that",
      "courtroom",
      "is",
      "the",
      "last",
      "place",
      "in",
      "world",
      "for",
      "learning",
      "truth",
    ],
    id: "It takes a long time to learn that a courtroom is the last place in the world for learning the truth.",
    author: "Alice Koller",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["true", "friend", "thinks", "egg", "even", "though", "slightly", "cracked"],
    words: [
      "a",
      "true",
      "friend",
      "is",
      "someone",
      "who",
      "thinks",
      "that",
      "you",
      "are",
      "good",
      "egg",
      "even",
      "though",
      "he",
      "knows",
      "slightly",
      "cracked",
    ],
    id: "A true friend is someone who thinks that you are a good egg even though he knows that you are slightly cracked.",
    author: "Bernard Meltzer",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "wear", "my", "heart", "god", "put", "on", "inside"],
    words: [
      "i",
      "like",
      "people",
      "and",
      "them",
      "to",
      "me",
      "but",
      "wear",
      "my",
      "heart",
      "where",
      "god",
      "put",
      "it",
      "on",
      "the",
      "inside",
    ],
    id: "I like people and I like them to like me, but I wear my heart where God put it, on the inside.",
    author: "F. Scott Fitzgerald",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "confusion",
      "stay",
      "each",
      "together",
      "without",
      "uttering",
      "single",
      "word",
    ],
    words: [
      "in",
      "the",
      "confusion",
      "we",
      "stay",
      "with",
      "each",
      "other",
      "happy",
      "to",
      "be",
      "together",
      "speaking",
      "without",
      "uttering",
      "a",
      "single",
      "word",
    ],
    id: "In the confusion we stay with each other, happy to be together, speaking without uttering a single word.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["change", "culture", "topic", "make", "ok", "mental", "health", "suicide"],
    words: [
      "we",
      "need",
      "to",
      "change",
      "the",
      "culture",
      "of",
      "this",
      "topic",
      "and",
      "make",
      "it",
      "ok",
      "speak",
      "about",
      "mental",
      "health",
      "suicide",
    ],
    id: "We need to change the culture of this topic and make it OK to speak about mental health and suicide.",
    author: "Luke Richardson",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "always",
      "best",
      "policy",
      "truth",
      "unless",
      "course",
      "exceptionally",
      "liar",
    ],
    words: [
      "it",
      "is",
      "always",
      "the",
      "best",
      "policy",
      "to",
      "speak",
      "truth",
      "unless",
      "of",
      "course",
      "you",
      "are",
      "an",
      "exceptionally",
      "good",
      "liar",
    ],
    id: "It is always the best policy to speak the truth, unless, of course, you are an exceptionally good liar.",
    author: "Jerome K. Jerome",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["walk", "away", "from", "landing", "use", "airplane", "next", "outstanding"],
    words: [
      "if",
      "you",
      "can",
      "walk",
      "away",
      "from",
      "a",
      "landing",
      "it's",
      "good",
      "use",
      "the",
      "airplane",
      "next",
      "day",
      "an",
      "outstanding",
    ],
    id: "If you can walk away from a landing, it's a good landing. If you use the airplane the next day, it's an outstanding landing.",
    author: "Chuck Yeager",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "freedom", "forgiveness", "hard", "once", "get", "into", "habit"],
    words: [
      "there",
      "is",
      "freedom",
      "in",
      "forgiveness",
      "and",
      "it's",
      "not",
      "that",
      "hard",
      "to",
      "do",
      "once",
      "you",
      "get",
      "into",
      "the",
      "habit",
    ],
    id: "There is freedom in forgiveness, and it's not that hard to do once you get into the habit.",
    author: "Dana Perino",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["would", "convince", "man", "wrong", "right", "men", "will", "believe"],
    words: [
      "if",
      "you",
      "would",
      "convince",
      "a",
      "man",
      "that",
      "he",
      "does",
      "wrong",
      "do",
      "right",
      "men",
      "will",
      "believe",
      "what",
      "they",
      "see",
    ],
    id: "If you would convince a man that he does wrong, do right. Men will believe what they see.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "believe", "show", "problems", "solutions", "will", "moved", "act"],
    words: [
      "i",
      "believe",
      "that",
      "if",
      "you",
      "show",
      "people",
      "the",
      "problems",
      "and",
      "them",
      "solutions",
      "they",
      "will",
      "be",
      "moved",
      "to",
      "act",
    ],
    id: "I believe that if you show people the problems and you show them the solutions they will be moved to act.",
    author: "Bill Gates",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["chew", "morsel", "our", "food", "way", "become", "grateful", "when"],
    words: [
      "if",
      "we",
      "chew",
      "every",
      "morsel",
      "of",
      "our",
      "food",
      "in",
      "that",
      "way",
      "become",
      "grateful",
      "and",
      "when",
      "you",
      "are",
      "happy",
    ],
    id: "If we chew every morsel of our food, in that way we become grateful, and when you are grateful, you are happy.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["any", "version", "windows", "lots", "great", "things", "use", "tough"],
    words: [
      "any",
      "version",
      "of",
      "windows",
      "is",
      "going",
      "to",
      "have",
      "lots",
      "great",
      "new",
      "things",
      "that",
      "people",
      "use",
      "and",
      "are",
      "tough",
    ],
    id: "Any version of Windows is going to have lots of great new things that people use and things that are tough.",
    author: "Bill Gates",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "always", "can't", "dare", "messing", "up", "nobody", "elses"],
    words: [
      "i",
      "always",
      "say",
      "have",
      "a",
      "good",
      "day",
      "and",
      "if",
      "you",
      "can't",
      "don't",
      "dare",
      "go",
      "messing",
      "up",
      "nobody",
      "elses",
    ],
    id: "I always say, 'Have a good day and if you can't, don't you dare go messing up nobody else's.'",
    author: "Tabitha Brown",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "strong",
      "headstrong",
      "make",
      "your",
      "place",
      "how",
      "patience",
      "important",
    ],
    words: [
      "you",
      "have",
      "to",
      "be",
      "very",
      "strong",
      "and",
      "headstrong",
      "make",
      "your",
      "place",
      "know",
      "how",
      "say",
      "no",
      "patience",
      "is",
      "important",
    ],
    id: "You have to be very strong and headstrong to make your place. You have to know how to say no. Patience is very important.",
    author: "Yami Gautam",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "knowledge",
      "value",
      "must",
      "circulate",
      "circulating",
      "increase",
      "quantity",
      "hopefully",
    ],
    words: [
      "knowledge",
      "is",
      "like",
      "money",
      "to",
      "be",
      "of",
      "value",
      "it",
      "must",
      "circulate",
      "and",
      "in",
      "circulating",
      "can",
      "increase",
      "quantity",
      "hopefully",
    ],
    id: "Knowledge is like money: to be of value it must circulate, and in circulating it can increase in quantity and, hopefully, in value.",
    author: "Louis L'Amour",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["live", "world", "mental", "health", "real", "emotional", "feel", "one"],
    words: [
      "we",
      "live",
      "in",
      "a",
      "world",
      "where",
      "mental",
      "health",
      "is",
      "real",
      "emotional",
      "and",
      "people",
      "feel",
      "like",
      "no",
      "one",
      "cares",
    ],
    id: "We live in a world where mental health is real. Emotional health is real, and people feel like no one cares.",
    author: "Malik Yoba",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "believe", "public", "conclusion", "drawn", "from", "my", "career"],
    words: [
      "i",
      "don't",
      "believe",
      "that",
      "the",
      "public",
      "knows",
      "what",
      "it",
      "wants",
      "this",
      "is",
      "conclusion",
      "have",
      "drawn",
      "from",
      "my",
      "career",
    ],
    id: "I don't believe that the public knows what it wants; this is the conclusion that I have drawn from my career.",
    author: "Charlie Chaplin",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "swimming", "pool", "dive", "into", "can't", "how", "deep"],
    words: [
      "this",
      "life",
      "is",
      "like",
      "a",
      "swimming",
      "pool",
      "you",
      "dive",
      "into",
      "the",
      "water",
      "but",
      "can't",
      "see",
      "how",
      "deep",
      "it",
    ],
    id: "This life is like a swimming pool. You dive into the water, but you can't see how deep it is.",
    author: "Dennis Rodman",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "certainly", "one", "those", "prodded", "fact", "anything", "prod"],
    words: [
      "i",
      "am",
      "certainly",
      "not",
      "one",
      "of",
      "those",
      "who",
      "need",
      "to",
      "be",
      "prodded",
      "in",
      "fact",
      "if",
      "anything",
      "the",
      "prod",
    ],
    id: "I am certainly not one of those who need to be prodded. In fact, if anything, I am the prod.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "how",
      "absurd",
      "somebody",
      "younger",
      "than",
      "yourself",
      "everybody",
      "should",
    ],
    words: [
      "how",
      "absurd",
      "and",
      "delicious",
      "it",
      "is",
      "to",
      "be",
      "in",
      "love",
      "with",
      "somebody",
      "younger",
      "than",
      "yourself",
      "everybody",
      "should",
      "try",
    ],
    id: "How absurd and delicious it is to be in love with somebody younger than yourself. Everybody should try it.",
    author: "Barbara Pym",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "individual",
      "leader",
      "isn't",
      "controversial",
      "means",
      "never",
      "stood",
      "anything",
    ],
    words: [
      "if",
      "an",
      "individual",
      "wants",
      "to",
      "be",
      "a",
      "leader",
      "and",
      "isn't",
      "controversial",
      "that",
      "means",
      "he",
      "never",
      "stood",
      "for",
      "anything",
    ],
    id: "If an individual wants to be a leader and isn't controversial, that means he never stood for anything.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["live", "image", "world", "one", "lives", "different", "space", "time"],
    words: [
      "you",
      "live",
      "in",
      "the",
      "image",
      "have",
      "of",
      "world",
      "every",
      "one",
      "us",
      "lives",
      "a",
      "different",
      "with",
      "space",
      "and",
      "time",
    ],
    id: "You live in the image you have of the world. Every one of us lives in a different world, with different space and different time.",
    author: "Alejandro Jodorowsky",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["as", "travel", "attract", "your", "thoughts", "brought", "will", "take"],
    words: [
      "as",
      "you",
      "think",
      "travel",
      "and",
      "love",
      "attract",
      "are",
      "today",
      "where",
      "your",
      "thoughts",
      "have",
      "brought",
      "will",
      "be",
      "tomorrow",
      "take",
    ],
    id: "As you think, you travel, and as you love, you attract. You are today where your thoughts have brought you; you will be tomorrow where your thoughts take you.",
    author: "James Lane Allen",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "enjoy", "being", "hopefully", "my", "happiness", "music", "life"],
    words: [
      "i",
      "enjoy",
      "being",
      "happy",
      "every",
      "day",
      "and",
      "hopefully",
      "you",
      "can",
      "hear",
      "my",
      "happiness",
      "in",
      "music",
      "life",
      "is",
      "beautiful",
    ],
    id: "I enjoy being happy every day, and hopefully you can hear my happiness in my music. Life is beautiful.",
    author: "Christina Milian",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "birthday", "i", "really", "as", "special", "honest", "another"],
    words: [
      "my",
      "birthday",
      "i",
      "don't",
      "really",
      "see",
      "it",
      "as",
      "a",
      "special",
      "day",
      "to",
      "be",
      "honest",
      "it's",
      "just",
      "another",
      "for",
      "me",
    ],
    id: "My birthday, I don't really see it as a special day, to be honest. It's just another day for me.",
    author: "Zion Williamson",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["world", "habit", "making", "room", "man", "whose", "actions", "show"],
    words: [
      "the",
      "world",
      "has",
      "habit",
      "of",
      "making",
      "room",
      "for",
      "man",
      "whose",
      "actions",
      "show",
      "that",
      "he",
      "knows",
      "where",
      "is",
      "going",
    ],
    id: "The world has the habit of making room for the man whose actions show that he knows where he is going.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "enough",
      "world",
      "i",
      "carry",
      "cosmic",
      "perspective",
      "could",
      "life-changing",
    ],
    words: [
      "not",
      "enough",
      "people",
      "in",
      "this",
      "world",
      "i",
      "think",
      "carry",
      "a",
      "cosmic",
      "perspective",
      "with",
      "them",
      "it",
      "could",
      "be",
      "life-changing",
    ],
    id: "Not enough people in this world, I think, carry a cosmic perspective with them. It could be life-changing.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["content", "wish", "change", "nor", "dread", "your", "last", "long"],
    words: [
      "be",
      "content",
      "with",
      "what",
      "you",
      "are",
      "and",
      "wish",
      "not",
      "change",
      "nor",
      "dread",
      "your",
      "last",
      "day",
      "long",
      "for",
      "it",
    ],
    id: "Be content with what you are, and wish not change; nor dread your last day, nor long for it.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["your", "life", "offered", "second", "chance", "you've", "got", "take"],
    words: [
      "it's",
      "not",
      "every",
      "day",
      "in",
      "your",
      "life",
      "that",
      "you're",
      "offered",
      "a",
      "second",
      "chance",
      "and",
      "you've",
      "got",
      "to",
      "take",
      "it",
    ],
    id: "It's not every day in your life that you're offered a second chance and you've got to take it.",
    author: "Wendie Renard",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["may", "as", "thats", "valiant", "dare", "his", "on", "lip"],
    words: [
      "you",
      "may",
      "as",
      "well",
      "say",
      "thats",
      "a",
      "valiant",
      "flea",
      "that",
      "dare",
      "eat",
      "his",
      "breakfast",
      "on",
      "the",
      "lip",
      "of",
      "lion",
    ],
    id: "You may as well say, 'That's a valiant flea that dare eat his breakfast on the lip of a lion.",
    author: "William Tecumseh Sherman",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["begin", "end", "way", "seem", "battle", "war", "growing", "up"],
    words: [
      "love",
      "does",
      "not",
      "begin",
      "and",
      "end",
      "the",
      "way",
      "we",
      "seem",
      "to",
      "think",
      "it",
      "is",
      "a",
      "battle",
      "war",
      "growing",
      "up",
    ],
    id: "Love does not begin and end the way we seem to think it does. Love is a battle, love is a war; love is a growing up.",
    author: "James Baldwin",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "hardwired",
      "connection",
      "there's",
      "arguing",
      "bioscience",
      "badly",
      "trying",
      "hot-wire",
    ],
    words: [
      "we're",
      "hardwired",
      "for",
      "connection",
      "there's",
      "no",
      "arguing",
      "with",
      "the",
      "bioscience",
      "but",
      "we",
      "can",
      "want",
      "it",
      "so",
      "badly",
      "trying",
      "to",
      "hot-wire",
    ],
    id: "We're hardwired for connection. There's no arguing with the bioscience. But we can want it so badly we're trying to hot-wire it.",
    author: "Brene Brown",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["find", "god", "cannot", "our", "own", "hearts", "living", "being"],
    words: [
      "where",
      "can",
      "we",
      "go",
      "to",
      "find",
      "god",
      "if",
      "cannot",
      "see",
      "him",
      "in",
      "our",
      "own",
      "hearts",
      "and",
      "every",
      "living",
      "being",
    ],
    id: "Where can we go to find God if we cannot see Him in our own hearts and in every living being.",
    author: "Swami Vivekananda",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "fact",
      "affection",
      "security",
      "will",
      "secure",
      "their",
      "feelings",
      "behavior",
    ],
    words: [
      "the",
      "fact",
      "is",
      "that",
      "people",
      "are",
      "good",
      "give",
      "affection",
      "and",
      "security",
      "they",
      "will",
      "be",
      "secure",
      "in",
      "their",
      "feelings",
      "behavior",
    ],
    id: "The fact is that people are good, Give people affection and security, and they will give affection and be secure in their feelings and their behavior.",
    author: "Abraham Maslow",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["born", "woman", "-", "although", "at", "women", "must", "labor"],
    words: [
      "to",
      "be",
      "born",
      "woman",
      "is",
      "know",
      "-",
      "although",
      "they",
      "do",
      "not",
      "speak",
      "of",
      "it",
      "at",
      "school",
      "women",
      "must",
      "labor",
      "beautiful",
    ],
    id: "To be born woman is to know - although they do not speak of it at school - women must labor to be beautiful.",
    author: "William Butler Yeats",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "education",
      "private",
      "schools",
      "can't",
      "afford",
      "tough",
      "luck",
      "public",
    ],
    words: [
      "if",
      "you",
      "want",
      "a",
      "good",
      "education",
      "go",
      "to",
      "private",
      "schools",
      "can't",
      "afford",
      "it",
      "tough",
      "luck",
      "can",
      "the",
      "public",
      "school",
    ],
    id: "If you want a good education, go to private schools. If you can't afford it, tough luck. You can go to the public school.",
    author: "Paul LePage",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["problem", "isn't", "johnny", "can't", "read", "even", "confuses", "feeling"],
    words: [
      "the",
      "problem",
      "isn't",
      "that",
      "johnny",
      "can't",
      "read",
      "even",
      "think",
      "is",
      "doesn't",
      "know",
      "what",
      "thinking",
      "he",
      "confuses",
      "it",
      "with",
      "feeling",
    ],
    id: "The problem isn't that Johnny can't read. The problem isn't even that Johnny can't think. The problem is that Johnny doesn't know what thinking is; he confuses it with feeling.",
    author: "Thomas Sowell",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "collect", "records", "any", "right", "i'm", "taking", "walk"],
    words: [
      "i",
      "collect",
      "records",
      "and",
      "cats",
      "don't",
      "have",
      "any",
      "right",
      "now",
      "but",
      "if",
      "i'm",
      "taking",
      "a",
      "walk",
      "see",
      "cat",
      "happy",
    ],
    id: "I collect records. And cats. I don't have any cats right now. But if I'm taking a walk and I see a cat, I'm happy.",
    author: "Haruki Murakami",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["way", "loved", "far", "more", "definition", "than", "loving", "any"],
    words: [
      "learning",
      "to",
      "love",
      "in",
      "the",
      "way",
      "someone",
      "wants",
      "be",
      "loved",
      "is",
      "far",
      "more",
      "definition",
      "of",
      "than",
      "just",
      "loving",
      "any",
    ],
    id: "Learning to love in the way someone wants to be loved is far more the definition of love than just loving in any way.",
    author: "Jay Shetty",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["special", "results", "feel", "things", "together", "spirit", "or", "live"],
    words: [
      "if",
      "you",
      "want",
      "special",
      "results",
      "have",
      "to",
      "feel",
      "things",
      "and",
      "do",
      "together",
      "can",
      "speak",
      "about",
      "spirit",
      "or",
      "live",
      "it",
    ],
    id: "If you want special results, you have to feel special things and do special things together. You can speak about spirit, or you can live it.",
    author: "Jurgen Klopp",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["took", "quite", "long", "time", "develop", "voice", "i", "silent"],
    words: [
      "it",
      "took",
      "me",
      "quite",
      "a",
      "long",
      "time",
      "to",
      "develop",
      "voice",
      "and",
      "now",
      "that",
      "i",
      "have",
      "am",
      "not",
      "going",
      "be",
      "silent",
    ],
    id: "It took me quite a long time to develop a voice, and now that I have it, I am not going to be silent.",
    author: "Madeleine Albright",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "i've",
      "learned",
      "important",
      "limit",
      "yourself",
      "whatever",
      "really",
      "matter",
    ],
    words: [
      "i've",
      "learned",
      "it's",
      "important",
      "not",
      "to",
      "limit",
      "yourself",
      "you",
      "can",
      "do",
      "whatever",
      "really",
      "love",
      "no",
      "matter",
      "what",
      "it",
      "is",
    ],
    id: "I've learned it's important not to limit yourself. You can do whatever you really love to do, no matter what it is.",
    author: "Ryan Gosling",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["nation", "afraid", "its", "judge", "truth", "falsehood", "open", "market"],
    words: [
      "a",
      "nation",
      "that",
      "is",
      "afraid",
      "to",
      "let",
      "its",
      "people",
      "judge",
      "the",
      "truth",
      "and",
      "falsehood",
      "in",
      "an",
      "open",
      "market",
      "of",
    ],
    id: "A nation that is afraid to let its people judge the truth and falsehood in an open market is a nation that is afraid of its people.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "dad", "biggest", "promoters", "estate", "tax", "popular", "position"],
    words: [
      "me",
      "and",
      "my",
      "dad",
      "are",
      "the",
      "biggest",
      "promoters",
      "of",
      "an",
      "estate",
      "tax",
      "in",
      "us",
      "it's",
      "not",
      "a",
      "popular",
      "position",
    ],
    id: "Me and my dad are the biggest promoters of an estate tax in the US. It's not a popular position.",
    author: "Bill Gates",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["dream", "then", "achieve", "will", "get", "all", "life", "enough"],
    words: [
      "if",
      "you",
      "can",
      "dream",
      "it",
      "then",
      "achieve",
      "will",
      "get",
      "all",
      "want",
      "in",
      "life",
      "help",
      "enough",
      "other",
      "people",
      "what",
      "they",
    ],
    id: "If you can dream it, then you can achieve it. You will get all you want in life if you help enough other people get what they want.",
    author: "Zig Ziglar",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["talking", "isn't", "doing", "kind", "deed", "yet", "words", "deeds"],
    words: [
      "talking",
      "isn't",
      "doing",
      "it",
      "is",
      "a",
      "kind",
      "of",
      "good",
      "deed",
      "to",
      "say",
      "well",
      "and",
      "yet",
      "words",
      "are",
      "not",
      "deeds",
    ],
    id: "Talking isn't doing. It is a kind of good deed to say well; and yet words are not deeds.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "were", "woman", "would", "lots", "kids", "men", "believe"],
    words: [
      "if",
      "i",
      "were",
      "a",
      "woman",
      "would",
      "love",
      "to",
      "have",
      "lots",
      "of",
      "kids",
      "but",
      "for",
      "men",
      "don't",
      "believe",
      "in",
      "it",
    ],
    id: "If I were a woman, I would love to have lots of kids. But for men, I don't believe in it.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["hang", "on", "scaffold", "i", "will", "shout", "freedom", "class"],
    words: [
      "if",
      "they",
      "want",
      "to",
      "hang",
      "me",
      "let",
      "them",
      "and",
      "on",
      "the",
      "scaffold",
      "i",
      "will",
      "shout",
      "freedom",
      "for",
      "working",
      "class",
    ],
    id: "If they want to hang me, let them. And on the scaffold I will shout Freedom for the working class!",
    author: "Mary Harris Jones",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["little", "knowledge", "dangerous", "man", "much", "as", "out", "danger"],
    words: [
      "if",
      "a",
      "little",
      "knowledge",
      "is",
      "dangerous",
      "where",
      "the",
      "man",
      "who",
      "has",
      "so",
      "much",
      "as",
      "to",
      "be",
      "out",
      "of",
      "danger",
    ],
    id: "If a little knowledge is dangerous, where is the man who has so much as to be out of danger?",
    author: "Thomas Huxley",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["thing", "my", "pictures", "i", "was", "afraid", "fall", "these"],
    words: [
      "a",
      "thing",
      "that",
      "you",
      "see",
      "in",
      "my",
      "pictures",
      "is",
      "i",
      "was",
      "not",
      "afraid",
      "to",
      "fall",
      "love",
      "with",
      "these",
      "people",
    ],
    id: "A thing that you see in my pictures is that I was not afraid to fall in love with these people.",
    author: "Annie Leibovitz",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["injury", "done", "man", "should", "severe", "his", "vengeance", "feared"],
    words: [
      "if",
      "an",
      "injury",
      "has",
      "to",
      "be",
      "done",
      "a",
      "man",
      "it",
      "should",
      "so",
      "severe",
      "that",
      "his",
      "vengeance",
      "need",
      "not",
      "feared",
    ],
    id: "If an injury has to be done to a man it should be so severe that his vengeance need not be feared.",
    author: "Niccolo Machiavelli",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "trying",
      "create",
      "company",
      "baking",
      "all",
      "ingredients",
      "right",
      "proportion",
    ],
    words: [
      "if",
      "you're",
      "trying",
      "to",
      "create",
      "a",
      "company",
      "it's",
      "like",
      "baking",
      "cake",
      "you",
      "have",
      "all",
      "the",
      "ingredients",
      "in",
      "right",
      "proportion",
    ],
    id: "If you're trying to create a company, it's like baking a cake. You have to have all the ingredients in the right proportion.",
    author: "Elon Musk",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["most", "believe", "trying", "make", "only", "ways", "which", "approve"],
    words: [
      "most",
      "of",
      "us",
      "believe",
      "in",
      "trying",
      "to",
      "make",
      "other",
      "people",
      "happy",
      "only",
      "if",
      "they",
      "can",
      "be",
      "ways",
      "which",
      "we",
      "approve",
    ],
    id: "Most of us believe in trying to make other people happy only if they can be happy in ways which we approve.",
    author: "Robert Staughton Lynd",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "surround",
      "yourself",
      "really",
      "i",
      "important",
      "thing",
      "because",
      "reflection",
    ],
    words: [
      "surround",
      "yourself",
      "with",
      "really",
      "good",
      "people",
      "i",
      "think",
      "that's",
      "an",
      "important",
      "thing",
      "because",
      "the",
      "you",
      "are",
      "a",
      "reflection",
      "of",
    ],
    id: "Surround yourself with really good people. I think that's an important thing. Because the people you surround yourself are a reflection of you.",
    author: "Aaron Rodgers",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["should", "opaque", "his", "patients", "mirror", "show", "nothing", "shown"],
    words: [
      "the",
      "doctor",
      "should",
      "be",
      "opaque",
      "to",
      "his",
      "patients",
      "and",
      "like",
      "a",
      "mirror",
      "show",
      "them",
      "nothing",
      "but",
      "what",
      "is",
      "shown",
      "him",
    ],
    id: "The doctor should be opaque to his patients and, like a mirror, should show them nothing but what is shown to him.",
    author: "Sigmund Freud",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "i",
      "may",
      "seem",
      "surprising",
      "dialog",
      "conversational",
      "flow",
      "difficult",
    ],
    words: [
      "i",
      "know",
      "it",
      "may",
      "seem",
      "surprising",
      "to",
      "people",
      "but",
      "learning",
      "dialog",
      "that",
      "has",
      "a",
      "conversational",
      "flow",
      "is",
      "not",
      "difficult",
    ],
    id: "I know it may seem surprising to people, but learning dialog that has a conversational flow to it is not that difficult.",
    author: "Deidre Hall",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["perfect", "sell", "your", "possessions", "poor", "will", "treasure", "heaven"],
    words: [
      "if",
      "you",
      "want",
      "to",
      "be",
      "perfect",
      "go",
      "sell",
      "your",
      "possessions",
      "and",
      "give",
      "the",
      "poor",
      "will",
      "have",
      "treasure",
      "in",
      "heaven",
    ],
    id: "If you want to be perfect, go, sell your possessions and give to the poor, and you will have treasure in heaven.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "freedom", "cycling", "terms", "being", "able", "jump", "on"],
    words: [
      "i",
      "know",
      "the",
      "freedom",
      "that",
      "cycling",
      "gives",
      "you",
      "in",
      "terms",
      "of",
      "being",
      "able",
      "to",
      "just",
      "jump",
      "on",
      "and",
      "go",
    ],
    id: "I know the freedom that cycling gives you in terms of being able to just jump on and go.",
    author: "Bradley Wiggins",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "since",
      "i'm",
      "democrats",
      "controlled",
      "senate",
      "understandable",
      "saying",
      "done",
    ],
    words: [
      "since",
      "i'm",
      "the",
      "president",
      "and",
      "democrats",
      "have",
      "controlled",
      "house",
      "senate",
      "it's",
      "understandable",
      "that",
      "people",
      "are",
      "saying",
      "you",
      "know",
      "what",
      "done",
    ],
    id: "Since I'm the president and Democrats have controlled the House and the Senate, it's understandable that people are saying, you know, 'What have you done?'",
    author: "Barack Obama",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["death", "challenge", "tells", "waste", "time", "tell", "each", "right"],
    words: [
      "death",
      "is",
      "a",
      "challenge",
      "it",
      "tells",
      "us",
      "not",
      "to",
      "waste",
      "time",
      "tell",
      "each",
      "other",
      "right",
      "now",
      "that",
      "we",
      "love",
    ],
    id: "Death is a challenge. It tells us not to waste time... It tells us to tell each other right now that we love each other.",
    author: "Leo Buscaglia",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "capitalism",
      "shortfalls",
      "necessarily",
      "take",
      "poor",
      "underfunds",
      "innovation",
      "offset",
    ],
    words: [
      "capitalism",
      "has",
      "shortfalls",
      "it",
      "doesn't",
      "necessarily",
      "take",
      "care",
      "of",
      "the",
      "poor",
      "and",
      "underfunds",
      "innovation",
      "so",
      "we",
      "have",
      "to",
      "offset",
      "that",
    ],
    id: "Capitalism has shortfalls. It doesn't necessarily take care of the poor, and it underfunds innovation, so we have to offset that.",
    author: "Bill Gates",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["accept", "responsibility", "your", "life", "will", "get", "one", "else"],
    words: [
      "accept",
      "responsibility",
      "for",
      "your",
      "life",
      "know",
      "that",
      "it",
      "is",
      "you",
      "who",
      "will",
      "get",
      "where",
      "want",
      "to",
      "go",
      "no",
      "one",
      "else",
    ],
    id: "Accept responsibility for your life. Know that it is you who will get you where you want to go, no one else.",
    author: "Les Brown",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "universe",
      "may",
      "purpose",
      "nothing",
      "suggests",
      "any",
      "similarity",
      "ours",
    ],
    words: [
      "the",
      "universe",
      "may",
      "have",
      "a",
      "purpose",
      "but",
      "nothing",
      "we",
      "know",
      "suggests",
      "that",
      "if",
      "so",
      "this",
      "has",
      "any",
      "similarity",
      "to",
      "ours",
    ],
    id: "The universe may have a purpose, but nothing we know suggests that, if so, this purpose has any similarity to ours.",
    author: "Bertrand Russell",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "wake", "up", "birthday", "chance", "great", "make", "decisions"],
    words: [
      "when",
      "you",
      "wake",
      "up",
      "every",
      "day",
      "it's",
      "like",
      "a",
      "new",
      "birthday",
      "chance",
      "to",
      "be",
      "great",
      "again",
      "and",
      "make",
      "decisions",
    ],
    id: "When you wake up every day, it's like a new birthday: it's a new chance to be great again and make great decisions.",
    author: "Poo Bear",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["healthy", "sick", "i", "come", "call", "righteous", "sinners", "repentance"],
    words: [
      "it",
      "is",
      "not",
      "the",
      "healthy",
      "who",
      "need",
      "a",
      "doctor",
      "but",
      "sick",
      "i",
      "have",
      "come",
      "to",
      "call",
      "righteous",
      "sinners",
      "repentance",
    ],
    id: "It is not the healthy who need a doctor, but the sick. I have not come to call the righteous, but sinners to repentance.",
    author: "Jesus Christ",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["as", "long", "hard", "doing", "you'll", "chance", "getting", "somewhere"],
    words: [
      "as",
      "long",
      "you're",
      "working",
      "hard",
      "for",
      "what",
      "you",
      "love",
      "doing",
      "you'll",
      "have",
      "a",
      "good",
      "chance",
      "of",
      "getting",
      "somewhere",
      "with",
      "it",
    ],
    id: "As long as you're working hard for what you love doing, you'll have a good chance of getting somewhere with it.",
    author: "Nyjah Huston",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["technology", "or", "evil", "itself", "all", "how", "choose", "use"],
    words: [
      "new",
      "technology",
      "is",
      "not",
      "good",
      "or",
      "evil",
      "in",
      "and",
      "of",
      "itself",
      "it's",
      "all",
      "about",
      "how",
      "people",
      "choose",
      "to",
      "use",
      "it",
    ],
    id: "New technology is not good or evil in and of itself. It's all about how people choose to use it.",
    author: "David Wong",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "tv", "antenna", "everything", "air", "then", "my", "way"],
    words: [
      "i",
      "am",
      "like",
      "a",
      "tv",
      "antenna",
      "catch",
      "everything",
      "that",
      "is",
      "in",
      "the",
      "air",
      "and",
      "then",
      "do",
      "it",
      "my",
      "way",
    ],
    id: "I am like a TV antenna. I catch everything that is in the air, and then I do it my way.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["surround", "yourself", "honest", "look", "out", "your", "best", "interests"],
    words: [
      "surround",
      "yourself",
      "with",
      "good",
      "people",
      "who",
      "are",
      "going",
      "to",
      "be",
      "honest",
      "you",
      "and",
      "look",
      "out",
      "for",
      "your",
      "best",
      "interests",
    ],
    id: "Surround yourself with good people. People who are going to be honest with you and look out for your best interests.",
    author: "Derek Jeter",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "unencumbered",
      "there's",
      "way",
      "move",
      "idea",
      "freedom",
      "exclusiveness",
      "inclusiveness",
    ],
    words: [
      "you",
      "have",
      "to",
      "dance",
      "unencumbered",
      "there's",
      "no",
      "other",
      "way",
      "move",
      "the",
      "idea",
      "of",
      "is",
      "freedom",
      "it",
      "not",
      "exclusiveness",
      "it's",
      "inclusiveness",
    ],
    id: "You have to dance unencumbered. There's no other way to move. The idea of dance is freedom. It is not exclusiveness, it's inclusiveness.",
    author: "Judith Jamison",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "looking", "lot", "men", "infinite", "capacity", "can't", "done"],
    words: [
      "i",
      "am",
      "looking",
      "for",
      "a",
      "lot",
      "of",
      "men",
      "who",
      "have",
      "an",
      "infinite",
      "capacity",
      "to",
      "not",
      "know",
      "what",
      "can't",
      "be",
      "done",
    ],
    id: "I am looking for a lot of men who have an infinite capacity to not know what can't be done.",
    author: "Henry Ford",
    grammar: null,
    word_count: 20,
    all_intersection_count: 12,
    new_words_count: 8,
  },
  {
    row_new_words: ["on", "nuclear", "arms", "race", "all", "make", "rubble", "bounce"],
    words: [
      "if",
      "you",
      "go",
      "on",
      "with",
      "this",
      "nuclear",
      "arms",
      "race",
      "all",
      "are",
      "going",
      "to",
      "do",
      "is",
      "make",
      "the",
      "rubble",
      "bounce",
    ],
    id: "If you go on with this nuclear arms race, all you are going to do is make the rubble bounce.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["our", "youth", "superficial", "compared", "old", "man", "his", "wife"],
    words: [
      "the",
      "love",
      "we",
      "have",
      "in",
      "our",
      "youth",
      "is",
      "superficial",
      "compared",
      "to",
      "that",
      "an",
      "old",
      "man",
      "has",
      "for",
      "his",
      "wife",
    ],
    id: "The love we have in our youth is superficial compared to the love that an old man has for his old wife.",
    author: "Will Durant",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "terrible", "lot", "lies", "world", "worst", "half", "true"],
    words: [
      "there",
      "are",
      "a",
      "terrible",
      "lot",
      "of",
      "lies",
      "going",
      "about",
      "the",
      "world",
      "and",
      "worst",
      "it",
      "is",
      "that",
      "half",
      "them",
      "true",
    ],
    id: "There are a terrible lot of lies going about the world, and the worst of it is that half of them are true.",
    author: "Winston Churchill",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "few", "ashamed", "having", "been", "when", "longer", "each"],
    words: [
      "there",
      "are",
      "very",
      "few",
      "people",
      "who",
      "not",
      "ashamed",
      "of",
      "having",
      "been",
      "in",
      "love",
      "when",
      "they",
      "no",
      "longer",
      "each",
      "other",
    ],
    id: "There are very few people who are not ashamed of having been in love when they no longer love each other.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 19,
    all_intersection_count: 11,
    new_words_count: 8,
  },
  {
    row_new_words: ["free", "society", "cannot", "many", "poor", "save", "few", "rich"],
    words: [
      "if",
      "a",
      "free",
      "society",
      "cannot",
      "help",
      "the",
      "many",
      "who",
      "are",
      "poor",
      "it",
      "save",
      "few",
      "rich",
    ],
    id: "If a free society cannot help the many who are poor, it cannot save the few who are rich.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["success", "mean", "healthy", "rested", "really", "look", "or", "feel"],
    words: [
      "success",
      "doesn't",
      "mean",
      "that",
      "you",
      "are",
      "healthy",
      "you're",
      "happy",
      "rested",
      "really",
      "look",
      "good",
      "or",
      "feel",
    ],
    id: "Success doesn't mean that you are healthy, success doesn't mean that you're happy, success doesn't mean that you're rested. Success really doesn't mean that you look good, or feel good, or are good.",
    author: "Victoria Principal",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["embrace", "thee", "sour", "adversity", "wise", "men", "wisest", "course"],
    words: [
      "let",
      "me",
      "embrace",
      "thee",
      "sour",
      "adversity",
      "for",
      "wise",
      "men",
      "say",
      "it",
      "is",
      "the",
      "wisest",
      "course",
    ],
    id: "Let me embrace thee, sour adversity, for wise men say it is the wisest course.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "comes", "i", "lot", "patience", "otherwise", "quite", "impatient"],
    words: [
      "when",
      "it",
      "comes",
      "to",
      "work",
      "i",
      "have",
      "a",
      "lot",
      "of",
      "patience",
      "but",
      "otherwise",
      "am",
      "quite",
      "impatient",
    ],
    id: "When it comes to work, I have a lot of patience, but otherwise, I am quite impatient.",
    author: "Kratika Sengar",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "baking", "rewarding", "follow", "recipe", "will", "get", "success"],
    words: [
      "i",
      "think",
      "baking",
      "is",
      "very",
      "rewarding",
      "and",
      "if",
      "you",
      "follow",
      "a",
      "good",
      "recipe",
      "will",
      "get",
      "success",
    ],
    id: "I think baking is very rewarding, and if you follow a good recipe, you will get success.",
    author: "Mary Berry",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["throw", "obstacles", "way", "complete", "education", "putting", "out", "eyes"],
    words: [
      "to",
      "throw",
      "obstacles",
      "in",
      "the",
      "way",
      "of",
      "a",
      "complete",
      "education",
      "is",
      "like",
      "putting",
      "out",
      "eyes",
    ],
    id: "To throw obstacles in the way of a complete education is like putting out the eyes.",
    author: "Elizabeth Cady Stanton",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "fine",
      "celebrate",
      "success",
      "more",
      "important",
      "heed",
      "lessons",
      "failure",
    ],
    words: [
      "it's",
      "fine",
      "to",
      "celebrate",
      "success",
      "but",
      "it",
      "is",
      "more",
      "important",
      "heed",
      "the",
      "lessons",
      "of",
      "failure",
    ],
    id: "It's fine to celebrate success but it is more important to heed the lessons of failure.",
    author: "Bill Gates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i've", "seen", "lot", "movies", "get", "made", "one", "control"],
    words: [
      "i've",
      "seen",
      "a",
      "lot",
      "of",
      "movies",
      "get",
      "made",
      "where",
      "no",
      "one",
      "has",
      "control",
      "likes",
      "it",
    ],
    id: "I've seen a lot of movies get made where no one has control. No one likes it.",
    author: "Seth Rogen",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["giving", "up", "even", "ounce", "precious", "freedom", "serious", "thing"],
    words: [
      "giving",
      "up",
      "even",
      "an",
      "ounce",
      "of",
      "precious",
      "freedom",
      "is",
      "a",
      "very",
      "serious",
      "thing",
      "to",
      "do",
    ],
    id: "Giving up even an ounce of precious freedom is a very serious thing to do.",
    author: "Chuck Schumer",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["family", "important", "become", "more", "as", "i've", "got", "older"],
    words: [
      "family",
      "is",
      "very",
      "important",
      "to",
      "me",
      "and",
      "has",
      "become",
      "more",
      "so",
      "as",
      "i've",
      "got",
      "older",
    ],
    id: "Family is very important to me and has become more so as I've got older.",
    author: "David Essex",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "affections",
      "lightning",
      "cannot",
      "tell",
      "will",
      "strike",
      "till",
      "fallen",
    ],
    words: [
      "the",
      "affections",
      "are",
      "like",
      "lightning",
      "you",
      "cannot",
      "tell",
      "where",
      "they",
      "will",
      "strike",
      "till",
      "have",
      "fallen",
    ],
    id: "The affections are like lightning: you cannot tell where they will strike till they have fallen.",
    author: "Lee Iacocca",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "derive",
      "happiness",
      "oneself",
      "from",
      "day's",
      "illuminating",
      "fog",
      "surrounds",
    ],
    words: [
      "derive",
      "happiness",
      "in",
      "oneself",
      "from",
      "a",
      "good",
      "day's",
      "work",
      "illuminating",
      "the",
      "fog",
      "that",
      "surrounds",
      "us",
    ],
    id: "Derive happiness in oneself from a good day's work, from illuminating the fog that surrounds us.",
    author: "Henri Matisse",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["private", "take", "risks", "way", "government", "often", "isn't", "willing"],
    words: [
      "well",
      "private",
      "money",
      "can",
      "take",
      "risks",
      "in",
      "a",
      "way",
      "that",
      "government",
      "often",
      "isn't",
      "willing",
      "to",
    ],
    id: "Well private money can take risks in a way that government money often isn't willing to.",
    author: "Bill Gates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "our",
      "friends",
      "interpret",
      "world",
      "ourselves",
      "take",
      "tenderly",
      "truly",
    ],
    words: [
      "our",
      "friends",
      "interpret",
      "the",
      "world",
      "and",
      "ourselves",
      "to",
      "us",
      "if",
      "we",
      "take",
      "them",
      "tenderly",
      "truly",
    ],
    id: "Our friends interpret the world and ourselves to us, if we take them tenderly and truly.",
    author: "Amos Bronson Alcott",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["writing", "takes", "too", "much", "patience", "out", "attempt", "often"],
    words: [
      "writing",
      "takes",
      "too",
      "much",
      "patience",
      "and",
      "it",
      "out",
      "of",
      "you",
      "for",
      "me",
      "to",
      "want",
      "attempt",
      "often",
    ],
    id: "Writing takes too much patience and it takes too much out of you for me to want to attempt it too often.",
    author: "Vikramaditya Motwane",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["rules", "game", "then", "play", "better", "than", "anyone", "else"],
    words: [
      "you",
      "have",
      "to",
      "learn",
      "the",
      "rules",
      "of",
      "game",
      "and",
      "then",
      "play",
      "better",
      "than",
      "anyone",
      "else",
    ],
    id: "You have to learn the rules of the game. And then you have to play better than anyone else.",
    author: "Unknown",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["how", "knuckleball", "until", "stops", "rolling", "then", "pick", "up"],
    words: [
      "how",
      "do",
      "you",
      "catch",
      "a",
      "knuckleball",
      "wait",
      "until",
      "it",
      "stops",
      "rolling",
      "then",
      "go",
      "pick",
      "up",
    ],
    id: "How do you catch a knuckleball? You wait until it stops rolling, then go pick it up.",
    author: "Bob Uecker",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["success", "seems", "largely", "matter", "hanging", "on", "after", "others"],
    words: [
      "success",
      "seems",
      "to",
      "be",
      "largely",
      "a",
      "matter",
      "of",
      "hanging",
      "on",
      "after",
      "others",
      "have",
      "let",
      "go",
    ],
    id: "Success seems to be largely a matter of hanging on after others have let go.",
    author: "William Feather",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "look", "at", "prince", "charles", "royal", "family", "knew"],
    words: [
      "when",
      "you",
      "look",
      "at",
      "prince",
      "charles",
      "don't",
      "think",
      "that",
      "someone",
      "in",
      "the",
      "royal",
      "family",
      "knew",
    ],
    id: "When you look at Prince Charles, don't you think that someone in the Royal family knew someone in the Royal family?",
    author: "Robin Williams",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "successful",
      "person",
      "fair",
      "amount",
      "self-control",
      "-",
      "nature",
      "success",
    ],
    words: [
      "every",
      "successful",
      "person",
      "has",
      "a",
      "fair",
      "amount",
      "of",
      "self-control",
      "-",
      "it's",
      "just",
      "the",
      "nature",
      "success",
    ],
    id: "Every successful person has a fair amount of self-control - it's just the nature of success.",
    author: "Laird Hamilton",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "wish", "would", "everybody", "else", "way", "better", "world"],
    words: [
      "i",
      "wish",
      "people",
      "would",
      "love",
      "everybody",
      "else",
      "the",
      "way",
      "they",
      "me",
      "it",
      "be",
      "a",
      "better",
      "world",
    ],
    id: "I wish people would love everybody else the way they love me. It would be a better world.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["miserable", "thing", "life", "living", "clover", "only", "isn't", "enough"],
    words: [
      "what",
      "a",
      "miserable",
      "thing",
      "life",
      "is",
      "you're",
      "living",
      "in",
      "clover",
      "only",
      "the",
      "isn't",
      "good",
      "enough",
    ],
    id: "What a miserable thing life is: you're living in clover, only the clover isn't good enough.",
    author: "Bertolt Brecht",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "full", "moons", "entire", "year", "special", "particular", "names"],
    words: [
      "all",
      "of",
      "the",
      "full",
      "moons",
      "for",
      "entire",
      "year",
      "are",
      "special",
      "in",
      "that",
      "they",
      "have",
      "particular",
      "names",
    ],
    id: "All of the full moons for the entire year are special in that they have particular names.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "wholly",
      "civilized",
      "distinction",
      "drawn",
      "between",
      "stealing",
      "office",
      "purse",
    ],
    words: [
      "no",
      "people",
      "is",
      "wholly",
      "civilized",
      "where",
      "a",
      "distinction",
      "drawn",
      "between",
      "stealing",
      "an",
      "office",
      "and",
      "purse",
    ],
    id: "No people is wholly civilized where a distinction is drawn between stealing an office and stealing a purse.",
    author: "Theodore Roosevelt",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "khamoshi",
      "gave",
      "chance",
      "prove",
      "expressions",
      "also",
      "dialogue",
      "could",
    ],
    words: [
      "khamoshi",
      "gave",
      "me",
      "the",
      "chance",
      "to",
      "prove",
      "that",
      "expressions",
      "can",
      "also",
      "say",
      "what",
      "dialogue",
      "could",
    ],
    id: "'Khamoshi' gave me the chance to prove that expressions can also say what dialogue could.",
    author: "Nana Patekar",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "music",
      "even",
      "these",
      "dial-up",
      "connections",
      "internet",
      "practical",
      "download",
    ],
    words: [
      "music",
      "even",
      "with",
      "these",
      "dial-up",
      "connections",
      "you",
      "have",
      "to",
      "the",
      "internet",
      "is",
      "very",
      "practical",
      "download",
    ],
    id: "Music, even with these dial-up connections you have to the Internet, is very practical to download.",
    author: "Bill Gates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["damn", "poor", "mind", "only", "one", "way", "spell", "word"],
    words: [
      "it's",
      "a",
      "damn",
      "poor",
      "mind",
      "that",
      "can",
      "only",
      "think",
      "of",
      "one",
      "way",
      "to",
      "spell",
      "word",
    ],
    id: "It's a damn poor mind that can only think of one way to spell a word.",
    author: "Unknown",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "meditation",
      "get",
      "insight",
      "understanding",
      "compassion",
      "when",
      "compelled",
      "act",
    ],
    words: [
      "meditation",
      "is",
      "to",
      "get",
      "insight",
      "understanding",
      "and",
      "compassion",
      "when",
      "you",
      "have",
      "them",
      "are",
      "compelled",
      "act",
    ],
    id: "Meditation is to get insight, to get understanding and compassion, and when you have them, you are compelled to act.",
    author: "Thich Nhat Hanh",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["man", "chooses", "evil", "because", "only", "mistakes", "happiness", "seeks"],
    words: [
      "no",
      "man",
      "chooses",
      "evil",
      "because",
      "it",
      "is",
      "he",
      "only",
      "mistakes",
      "for",
      "happiness",
      "the",
      "good",
      "seeks",
    ],
    id: "No man chooses evil because it is evil; he only mistakes it for happiness, the good he seeks.",
    author: "Mary Wollstonecraft",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["real", "deep", "kind", "somber", "person", "i", "can't", "really"],
    words: [
      "the",
      "real",
      "me",
      "is",
      "this",
      "deep",
      "kind",
      "of",
      "somber",
      "person",
      "i",
      "can't",
      "really",
      "help",
      "that",
    ],
    id: "The real me is this deep, kind of somber person. I can't really help that.",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["children", "dear", "loving", "alone", "console", "woman", "loss", "beauty"],
    words: [
      "children",
      "dear",
      "and",
      "loving",
      "can",
      "alone",
      "console",
      "a",
      "woman",
      "for",
      "the",
      "loss",
      "of",
      "her",
      "beauty",
    ],
    id: "Children, dear and loving children, can alone console a woman for the loss of her beauty.",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "always",
      "remember",
      "most",
      "important",
      "thing",
      "marriage",
      "happiness",
      "stability",
    ],
    words: [
      "always",
      "remember",
      "that",
      "the",
      "most",
      "important",
      "thing",
      "in",
      "a",
      "good",
      "marriage",
      "is",
      "not",
      "happiness",
      "but",
      "stability",
    ],
    id: "Always remember that the most important thing in a good marriage is not happiness, but stability.",
    author: "Gabriel Garcia Marquez",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["die", "old", "empty", "goal", "life", "cemetery", "disappoint", "graveyard"],
    words: [
      "don't",
      "die",
      "old",
      "empty",
      "that's",
      "the",
      "goal",
      "of",
      "life",
      "go",
      "to",
      "cemetery",
      "and",
      "disappoint",
      "graveyard",
    ],
    id: "Don't die old, die empty. That's the goal of life. Go to the cemetery and disappoint the graveyard.",
    author: "Myles Munroe",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "stand", "here", "before", "as", "prophet", "humble", "servant"],
    words: [
      "i",
      "stand",
      "here",
      "before",
      "you",
      "not",
      "as",
      "a",
      "prophet",
      "but",
      "humble",
      "servant",
      "of",
      "the",
      "people",
    ],
    id: "I stand here before you not as a prophet, but as a humble servant of you, the people.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["overeem", "really", "smart", "when", "fighting", "had", "patience", "right"],
    words: [
      "overeem",
      "is",
      "really",
      "smart",
      "when",
      "he's",
      "fighting",
      "had",
      "the",
      "patience",
      "to",
      "wait",
      "for",
      "right",
      "moment",
    ],
    id: "Overeem is really smart when he's fighting, had the patience to wait for the right moment.",
    author: "Fabricio Werdum",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "talked", "great", "men", "how", "differ", "from", "others"],
    words: [
      "i",
      "have",
      "talked",
      "with",
      "great",
      "men",
      "and",
      "do",
      "not",
      "see",
      "how",
      "they",
      "differ",
      "from",
      "others",
    ],
    id: "I have talked with great men, and I do not see how they differ from others.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["ever", "been", "its", "own", "depth", "until", "hour", "separation"],
    words: [
      "ever",
      "has",
      "it",
      "been",
      "that",
      "love",
      "knows",
      "not",
      "its",
      "own",
      "depth",
      "until",
      "the",
      "hour",
      "of",
      "separation",
    ],
    id: "Ever has it been that love knows not its own depth until the hour of separation.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["never", "old", "age", "happiness", "time", "brings", "count", "years"],
    words: [
      "let",
      "us",
      "never",
      "know",
      "what",
      "old",
      "age",
      "is",
      "the",
      "happiness",
      "time",
      "brings",
      "not",
      "count",
      "years",
    ],
    id: "Let us never know what old age is. Let us know the happiness time brings, not count the years.",
    author: "Ausonius",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["great", "deal", "more", "causes", "physical", "disease", "than", "health"],
    words: [
      "we",
      "know",
      "a",
      "great",
      "deal",
      "more",
      "about",
      "the",
      "causes",
      "of",
      "physical",
      "disease",
      "than",
      "do",
      "health",
    ],
    id: "We know a great deal more about the causes of physical disease than we do about the causes of physical health.",
    author: "M. Scott Peck",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "markets",
      "change",
      "companies",
      "individuals",
      "choose",
      "compete",
      "those",
      "must",
    ],
    words: [
      "markets",
      "change",
      "tastes",
      "so",
      "the",
      "companies",
      "and",
      "individuals",
      "who",
      "choose",
      "to",
      "compete",
      "in",
      "those",
      "must",
    ],
    id: "Markets change, tastes change, so the companies and the individuals who choose to compete in those markets must change.",
    author: "An Wang",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["isn't", "mountains", "ahead", "wear", "out", "pebble", "your", "shoe"],
    words: [
      "it",
      "isn't",
      "the",
      "mountains",
      "ahead",
      "to",
      "climb",
      "that",
      "wear",
      "you",
      "out",
      "it's",
      "pebble",
      "in",
      "your",
      "shoe",
    ],
    id: "It isn't the mountains ahead to climb that wear you out; it's the pebble in your shoe.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["key", "success", "get", "out", "into", "store", "listen", "associates"],
    words: [
      "the",
      "key",
      "to",
      "success",
      "is",
      "get",
      "out",
      "into",
      "store",
      "and",
      "listen",
      "what",
      "associates",
      "have",
      "say",
    ],
    id: "The key to success is to get out into the store and listen to what the associates have to say.",
    author: "Sam Walton",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["important", "neighbor", "next", "door", "down", "street", "another", "race"],
    words: [
      "it's",
      "very",
      "important",
      "to",
      "know",
      "the",
      "neighbor",
      "next",
      "door",
      "and",
      "people",
      "down",
      "street",
      "in",
      "another",
      "race",
    ],
    id: "It's very important to know the neighbor next door and the people down the street and the people in another race.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "opportunity",
      "missed",
      "by",
      "most",
      "because",
      "dressed",
      "overalls",
      "looks",
    ],
    words: [
      "opportunity",
      "is",
      "missed",
      "by",
      "most",
      "people",
      "because",
      "it",
      "dressed",
      "in",
      "overalls",
      "and",
      "looks",
      "like",
      "work",
    ],
    id: "Opportunity is missed by most people because it is dressed in overalls and looks like work.",
    author: "Thomas A. Edison",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "black", "americans", "slave", "names", "white", "master", "given"],
    words: [
      "all",
      "black",
      "americans",
      "have",
      "slave",
      "names",
      "they",
      "white",
      "that",
      "the",
      "master",
      "has",
      "given",
      "to",
      "them",
    ],
    id: "All black Americans have slave names. They have white names; names that the slave master has given to them.",
    author: "Muhammad Ali",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "most",
      "impactful",
      "dollars",
      "australia",
      "spend",
      "actually",
      "goes",
      "poorest",
    ],
    words: [
      "the",
      "most",
      "impactful",
      "dollars",
      "that",
      "australia",
      "can",
      "spend",
      "are",
      "actually",
      "what",
      "goes",
      "to",
      "help",
      "poorest",
    ],
    id: "The most impactful dollars that Australia can spend are actually what goes to help the poorest.",
    author: "Bill Gates",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["soul", "which", "spirit", "dwell", "dust", "carried", "along", "blood"],
    words: [
      "the",
      "soul",
      "which",
      "is",
      "spirit",
      "can",
      "not",
      "dwell",
      "in",
      "dust",
      "it",
      "carried",
      "along",
      "to",
      "blood",
    ],
    id: "The soul, which is spirit, can not dwell in dust; it is carried along to dwell in the blood.",
    author: "Saint Augustine",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "space",
      "exploration",
      "force",
      "nature",
      "unto",
      "itself",
      "society",
      "rival",
    ],
    words: [
      "space",
      "exploration",
      "is",
      "a",
      "force",
      "of",
      "nature",
      "unto",
      "itself",
      "that",
      "no",
      "other",
      "in",
      "society",
      "can",
      "rival",
    ],
    id: "Space exploration is a force of nature unto itself that no other force in society can rival.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "stop",
      "humble",
      "enough",
      "understand",
      "there",
      "something",
      "called",
      "mystery",
    ],
    words: [
      "we",
      "have",
      "to",
      "stop",
      "and",
      "be",
      "humble",
      "enough",
      "understand",
      "that",
      "there",
      "is",
      "something",
      "called",
      "mystery",
    ],
    id: "We have to stop and be humble enough to understand that there is something called mystery.",
    author: "Paulo Coelho",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "i",
      "realized",
      "important",
      "your",
      "life",
      "friendship",
      "mentors",
      "family",
    ],
    words: [
      "i",
      "realized",
      "it's",
      "important",
      "to",
      "have",
      "love",
      "in",
      "your",
      "life",
      "friendship",
      "very",
      "mentors",
      "and",
      "family",
    ],
    id: "I realized it's important to have love in your life, it's important to have friendship, it's very important to have mentors and family.",
    author: "Sergei Polunin",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "failed",
      "providence",
      "god",
      "apparent",
      "failure",
      "often",
      "proves",
      "blessing",
    ],
    words: [
      "we",
      "failed",
      "but",
      "in",
      "the",
      "good",
      "providence",
      "of",
      "god",
      "apparent",
      "failure",
      "often",
      "proves",
      "a",
      "blessing",
    ],
    id: "We failed, but in the good providence of God apparent failure often proves a blessing.",
    author: "Robert E. Lee",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i'm", "even", "scared", "devil", "confronted", "i'd", "confront", "as"],
    words: [
      "i'm",
      "not",
      "even",
      "scared",
      "of",
      "the",
      "devil",
      "if",
      "confronted",
      "me",
      "i'd",
      "confront",
      "him",
      "as",
      "well",
    ],
    id: "I'm not even scared of the devil. If the devil confronted me, I'd confront him as well.",
    author: "Tyson Fury",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["thankless", "position", "father", "family", "-", "provider", "all", "enemy"],
    words: [
      "that",
      "is",
      "the",
      "thankless",
      "position",
      "of",
      "father",
      "in",
      "family",
      "-",
      "provider",
      "for",
      "all",
      "and",
      "enemy",
    ],
    id: "That is the thankless position of the father in the family - the provider for all, and the enemy of all.",
    author: "August Strindberg",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "child", "may", "only", "thing", "could", "true", "happiness"],
    words: [
      "i",
      "think",
      "a",
      "child",
      "may",
      "be",
      "the",
      "only",
      "thing",
      "that",
      "could",
      "give",
      "me",
      "true",
      "happiness",
    ],
    id: "I think a child may be the only thing that could give me true happiness.",
    author: "Nicki Minaj",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["holy", "humility", "confounds", "pride", "all", "men", "world", "things"],
    words: [
      "holy",
      "humility",
      "confounds",
      "pride",
      "and",
      "all",
      "the",
      "men",
      "of",
      "this",
      "world",
      "things",
      "that",
      "are",
      "in",
    ],
    id: "Holy humility confounds pride and all the men of this world and all things that are in the world.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["measure", "yourself", "your", "success", "by", "others", "receive", "return"],
    words: [
      "measure",
      "yourself",
      "and",
      "your",
      "success",
      "by",
      "what",
      "you",
      "give",
      "to",
      "others",
      "not",
      "receive",
      "in",
      "return",
    ],
    id: "Measure yourself and your success by what you give to others, not what you receive in return.",
    author: "Will Hurd",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "forests",
      "precious",
      "resources",
      "country",
      "wealth",
      "handed",
      "down",
      "posterity",
    ],
    words: [
      "forests",
      "are",
      "precious",
      "resources",
      "of",
      "the",
      "country",
      "and",
      "a",
      "wealth",
      "to",
      "be",
      "handed",
      "down",
      "posterity",
    ],
    id: "Forests are precious resources of the country and a wealth to be handed down to posterity.",
    author: "Kim Jong-un",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["your", "own", "bone", "gnaw", "at", "bury", "unearth", "still"],
    words: [
      "do",
      "what",
      "you",
      "love",
      "know",
      "your",
      "own",
      "bone",
      "gnaw",
      "at",
      "it",
      "bury",
      "unearth",
      "and",
      "still",
    ],
    id: "Do what you love. Know your own bone; gnaw at it, bury it, unearth it, and gnaw it still.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["might", "watching", "basketball", "mean", "you'll", "get", "on", "court"],
    words: [
      "like",
      "you",
      "might",
      "love",
      "watching",
      "basketball",
      "but",
      "that",
      "don't",
      "mean",
      "you'll",
      "get",
      "on",
      "the",
      "court",
    ],
    id: "Like you might love watching basketball, but that don't mean that you'll get on the court.",
    author: "Lil Baby",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "shall", "never", "ashamed", "citing", "bad", "author", "line"],
    words: [
      "i",
      "shall",
      "never",
      "be",
      "ashamed",
      "of",
      "citing",
      "a",
      "bad",
      "author",
      "if",
      "the",
      "line",
      "is",
      "good",
    ],
    id: "I shall never be ashamed of citing a bad author if the line is good.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["as", "flies", "wanton", "boys", "gods", "kill", "their", "sport"],
    words: [
      "as",
      "flies",
      "to",
      "wanton",
      "boys",
      "are",
      "we",
      "the",
      "gods",
      "they",
      "kill",
      "us",
      "for",
      "their",
      "sport",
    ],
    id: "As flies to wanton boys, are we to the gods; they kill us for their sport.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["most", "movies", "there", "prince", "charming", "rides", "up", "saves"],
    words: [
      "in",
      "most",
      "movies",
      "there",
      "is",
      "a",
      "prince",
      "charming",
      "who",
      "rides",
      "up",
      "and",
      "saves",
      "the",
      "girl",
    ],
    id: "In most movies there is a Prince Charming who rides up and saves the girl.",
    author: "Zoe Lister-Jones",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "look", "at", "music", "art", "form", "almost", "painting"],
    words: [
      "i",
      "look",
      "at",
      "music",
      "like",
      "an",
      "art",
      "form",
      "so",
      "it's",
      "almost",
      "painting",
      "for",
      "me",
      "you",
      "know",
    ],
    id: "I look at music like an art form, so it's almost like painting for me, you know?",
    author: "Melanie Martinez",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["man", "property", "his", "own", "person", "nobody", "right", "himself"],
    words: [
      "every",
      "man",
      "has",
      "a",
      "property",
      "in",
      "his",
      "own",
      "person",
      "this",
      "nobody",
      "right",
      "to",
      "but",
      "himself",
    ],
    id: "Every man has a property in his own person. This nobody has a right to, but himself.",
    author: "John Locke",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["woman", "take", "family", "takes", "man", "provide", "structure", "stability"],
    words: [
      "a",
      "woman",
      "can",
      "take",
      "care",
      "of",
      "the",
      "family",
      "it",
      "takes",
      "man",
      "to",
      "provide",
      "structure",
      "stability",
    ],
    id: "A woman can take care of the family. It takes a man to provide structure, to provide stability.",
    author: "Tom DeLay",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["take", "thought", "right", "or", "wrong", "better", "than", "against"],
    words: [
      "take",
      "no",
      "thought",
      "of",
      "who",
      "is",
      "right",
      "or",
      "wrong",
      "better",
      "than",
      "be",
      "not",
      "for",
      "against",
    ],
    id: "Take no thought of who is right or wrong or who is better than. Be not for or against.",
    author: "Bruce Lee",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["wise", "persuade", "things", "make", "was", "their", "own", "idea"],
    words: [
      "it",
      "is",
      "wise",
      "to",
      "persuade",
      "people",
      "do",
      "things",
      "and",
      "make",
      "them",
      "think",
      "was",
      "their",
      "own",
      "idea",
    ],
    id: "It is wise to persuade people to do things and make them think it was their own idea.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["ultimate", "tragedy", "oppression", "cruelty", "by", "bad", "silence", "over"],
    words: [
      "the",
      "ultimate",
      "tragedy",
      "is",
      "not",
      "oppression",
      "and",
      "cruelty",
      "by",
      "bad",
      "people",
      "but",
      "silence",
      "over",
      "that",
      "good",
    ],
    id: "The ultimate tragedy is not the oppression and cruelty by the bad people but the silence over that by the good people.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["man", "discovered", "something", "will", "die", "isn't", "fit", "live"],
    words: [
      "if",
      "a",
      "man",
      "has",
      "not",
      "discovered",
      "something",
      "that",
      "he",
      "will",
      "die",
      "for",
      "isn't",
      "fit",
      "to",
      "live",
    ],
    id: "If a man has not discovered something that he will die for, he isn't fit to live.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "two", "languages", "one", "as", "things", "seem", "knowledge"],
    words: [
      "there",
      "are",
      "two",
      "languages",
      "one",
      "as",
      "things",
      "seem",
      "to",
      "us",
      "and",
      "the",
      "other",
      "of",
      "knowledge",
    ],
    id: "There are two languages: one as things seem to us and the other of knowledge.",
    author: "Yehuda Amichai",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["reveal", "your", "secrets", "wind", "should", "blame", "revealing", "trees"],
    words: [
      "if",
      "you",
      "reveal",
      "your",
      "secrets",
      "to",
      "the",
      "wind",
      "should",
      "not",
      "blame",
      "for",
      "revealing",
      "them",
      "trees",
    ],
    id: "If you reveal your secrets to the wind, you should not blame the wind for revealing them to the trees.",
    author: "Khalil Gibran",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["renown", "on", "scraps", "dote", "grow", "immortal", "as", "quote"],
    words: [
      "some",
      "for",
      "renown",
      "on",
      "scraps",
      "of",
      "learning",
      "dote",
      "and",
      "think",
      "they",
      "grow",
      "immortal",
      "as",
      "quote",
    ],
    id: "Some for renown, on scraps of learning dote, And think they grow immortal as they quote.",
    author: "Edward Young",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "learned", "honor", "its", "coming", "all", "my", "heart"],
    words: [
      "i",
      "have",
      "learned",
      "not",
      "to",
      "worry",
      "about",
      "love",
      "but",
      "honor",
      "its",
      "coming",
      "with",
      "all",
      "my",
      "heart",
    ],
    id: "I have learned not to worry about love; but to honor its coming with all my heart.",
    author: "Alice Walker",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["certainly", "woman", "should", "never", "disguise", "fact", "woman's", "body"],
    words: [
      "certainly",
      "a",
      "woman",
      "should",
      "never",
      "disguise",
      "the",
      "fact",
      "that",
      "she",
      "is",
      "woman's",
      "body",
      "very",
      "beautiful",
    ],
    id: "Certainly a woman should never disguise the fact that she is a woman. A woman's body is very beautiful.",
    author: "Billy Graham",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "quality", "life", "finding", "balance", "between", "friends", "family"],
    words: [
      "it's",
      "all",
      "about",
      "quality",
      "of",
      "life",
      "and",
      "finding",
      "a",
      "happy",
      "balance",
      "between",
      "work",
      "friends",
      "family",
    ],
    id: "It's all about quality of life and finding a happy balance between work and friends and family.",
    author: "Philip Green",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["our", "deeds", "still", "travel", "from", "afar", "been", "makes"],
    words: [
      "our",
      "deeds",
      "still",
      "travel",
      "with",
      "us",
      "from",
      "afar",
      "and",
      "what",
      "we",
      "have",
      "been",
      "makes",
      "are",
    ],
    id: "Our deeds still travel with us from afar, and what we have been makes us what we are.",
    author: "George Eliot",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["defeat", "finish", "man", "quit", "finished", "when", "defeated", "quits"],
    words: [
      "defeat",
      "doesn't",
      "finish",
      "a",
      "man",
      "quit",
      "does",
      "is",
      "not",
      "finished",
      "when",
      "he's",
      "defeated",
      "he",
      "quits",
    ],
    id: "Defeat doesn't finish a man, quit does. A man is not finished when he's defeated. He's finished when he quits.",
    author: "Richard M. Nixon",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "happiness",
      "radiates",
      "fragrance",
      "from",
      "draws",
      "all",
      "things",
      "towards",
    ],
    words: [
      "happiness",
      "radiates",
      "like",
      "the",
      "fragrance",
      "from",
      "a",
      "flower",
      "and",
      "draws",
      "all",
      "good",
      "things",
      "towards",
      "you",
    ],
    id: "Happiness radiates like the fragrance from a flower and draws all good things towards you.",
    author: "Maharishi Mahesh Yogi",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "child",
      "monsieur",
      "image",
      "two",
      "beings",
      "sentiments",
      "spontaneously",
      "blended",
    ],
    words: [
      "what",
      "is",
      "a",
      "child",
      "monsieur",
      "but",
      "the",
      "image",
      "of",
      "two",
      "beings",
      "fruit",
      "sentiments",
      "spontaneously",
      "blended",
    ],
    id: "What is a child, monsieur, but the image of two beings, the fruit of two sentiments spontaneously blended?",
    author: "Honore de Balzac",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["right", "i'm", "delighted", "alive", "had", "nice", "long", "bath"],
    words: [
      "right",
      "now",
      "i'm",
      "just",
      "delighted",
      "to",
      "be",
      "alive",
      "and",
      "have",
      "had",
      "a",
      "nice",
      "long",
      "bath",
    ],
    id: "Right now I'm just delighted to be alive and to have had a nice long bath.",
    author: "Richard Branson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["two", "ears", "one", "mouth", "listen", "twice", "as", "much"],
    words: [
      "we",
      "have",
      "two",
      "ears",
      "and",
      "one",
      "mouth",
      "so",
      "that",
      "can",
      "listen",
      "twice",
      "as",
      "much",
      "speak",
    ],
    id: "We have two ears and one mouth so that we can listen twice as much as we speak.",
    author: "Epictetus",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["more", "i", "realize", "there", "nothing", "artistic", "than", "others"],
    words: [
      "the",
      "more",
      "i",
      "think",
      "about",
      "it",
      "realize",
      "there",
      "is",
      "nothing",
      "artistic",
      "than",
      "to",
      "love",
      "others",
    ],
    id: "The more I think about it, the more I realize there is nothing more artistic than to love others.",
    author: "Vincent Van Gogh",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "coach", "rest", "your", "personal", "trainer", "health", "fitness"],
    words: [
      "a",
      "life",
      "coach",
      "does",
      "for",
      "the",
      "rest",
      "of",
      "your",
      "what",
      "personal",
      "trainer",
      "health",
      "and",
      "fitness",
    ],
    id: "A life coach does for the rest of your life what a personal trainer does for your health and fitness.",
    author: "Elaine MacDonald",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "policy",
      "rest",
      "upon",
      "philosophical",
      "public",
      "opinion",
      "permanently",
      "maintained",
    ],
    words: [
      "no",
      "policy",
      "that",
      "does",
      "not",
      "rest",
      "upon",
      "some",
      "philosophical",
      "public",
      "opinion",
      "can",
      "be",
      "permanently",
      "maintained",
    ],
    id: "No policy that does not rest upon some philosophical public opinion can be permanently maintained.",
    author: "Abraham Lincoln",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["something", "free", "come", "back", "never", "had", "comes", "forever"],
    words: [
      "if",
      "you",
      "love",
      "something",
      "let",
      "it",
      "go",
      "free",
      "doesn't",
      "come",
      "back",
      "never",
      "had",
      "comes",
      "forever",
    ],
    id: "If you love something let it go free. If it doesn't come back, you never had it. If it comes back, love it forever.",
    author: "Douglas Horton",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["amount", "law", "enforcement", "solve", "problem", "goes", "back", "family"],
    words: [
      "no",
      "amount",
      "of",
      "law",
      "enforcement",
      "can",
      "solve",
      "a",
      "problem",
      "that",
      "goes",
      "back",
      "to",
      "the",
      "family",
    ],
    id: "No amount of law enforcement can solve a problem that goes back to the family.",
    author: "J. Edgar Hoover",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "on", "roll", "stay", "busy", "-", "keep", "momentum"],
    words: [
      "when",
      "you're",
      "on",
      "a",
      "roll",
      "you",
      "want",
      "to",
      "stay",
      "busy",
      "-",
      "keep",
      "that",
      "momentum",
      "going",
    ],
    id: "When you're on a roll, you want to stay busy - you want to keep that momentum going.",
    author: "Robbie Lawler",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["whatever", "universal", "nature", "assigns", "any", "man", "at", "time"],
    words: [
      "whatever",
      "the",
      "universal",
      "nature",
      "assigns",
      "to",
      "any",
      "man",
      "at",
      "time",
      "is",
      "for",
      "good",
      "of",
      "that",
    ],
    id: "Whatever the universal nature assigns to any man at any time is for the good of that man at that time.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["hope", "peace", "gift", "from", "god", "only", "one", "another"],
    words: [
      "hope",
      "is",
      "like",
      "peace",
      "it",
      "not",
      "a",
      "gift",
      "from",
      "god",
      "only",
      "we",
      "can",
      "give",
      "one",
      "another",
    ],
    id: "Hope is like peace. It is not a gift from God. It is a gift only we can give one another.",
    author: "Elie Wiesel",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "la",
      "america",
      "general",
      "incredible",
      "hustle",
      "there's",
      "sense",
      "momentum",
    ],
    words: [
      "la",
      "and",
      "america",
      "in",
      "general",
      "have",
      "this",
      "incredible",
      "hustle",
      "about",
      "them",
      "there's",
      "sense",
      "of",
      "momentum",
    ],
    id: "L.A. and America, in general, have this incredible hustle about them. There's this sense of momentum.",
    author: "Alycia Debnam-Carey",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["come", "home", "party", "after", "get", "hangover", "everything", "negative"],
    words: [
      "you",
      "come",
      "home",
      "and",
      "party",
      "but",
      "after",
      "that",
      "get",
      "a",
      "hangover",
      "everything",
      "about",
      "is",
      "negative",
    ],
    id: "You come home, and you party. But after that, you get a hangover. Everything about that is negative.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "knowing",
      "your",
      "own",
      "darkness",
      "best",
      "method",
      "dealing",
      "darknesses",
    ],
    words: [
      "knowing",
      "your",
      "own",
      "darkness",
      "is",
      "the",
      "best",
      "method",
      "for",
      "dealing",
      "with",
      "darknesses",
      "of",
      "other",
      "people",
    ],
    id: "Knowing your own darkness is the best method for dealing with the darknesses of other people.",
    author: "Carl Jung",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["principal", "point", "cleverness", "how", "value", "things", "as", "deserve"],
    words: [
      "the",
      "principal",
      "point",
      "of",
      "cleverness",
      "is",
      "to",
      "know",
      "how",
      "value",
      "things",
      "just",
      "as",
      "they",
      "deserve",
    ],
    id: "The principal point of cleverness is to know how to value things just as they deserve.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["true", "friend", "there", "when", "he'd", "rather", "anywhere", "else"],
    words: [
      "a",
      "true",
      "friend",
      "is",
      "someone",
      "who",
      "there",
      "for",
      "you",
      "when",
      "he'd",
      "rather",
      "be",
      "anywhere",
      "else",
    ],
    id: "A true friend is someone who is there for you when he'd rather be anywhere else.",
    author: "Len Wein",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["man", "passed", "through", "inferno", "his", "passions", "never", "overcome"],
    words: [
      "a",
      "man",
      "who",
      "has",
      "not",
      "passed",
      "through",
      "the",
      "inferno",
      "of",
      "his",
      "passions",
      "never",
      "overcome",
      "them",
    ],
    id: "A man who has not passed through the inferno of his passions has never overcome them.",
    author: "Carl Jung",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["wife", "make", "husband", "glad", "come", "home", "sorry", "leave"],
    words: [
      "let",
      "the",
      "wife",
      "make",
      "husband",
      "glad",
      "to",
      "come",
      "home",
      "and",
      "him",
      "her",
      "sorry",
      "see",
      "leave",
    ],
    id: "Let the wife make the husband glad to come home, and let him make her sorry to see him leave.",
    author: "Martin Luther",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["heart", "which", "perceives", "god", "reason", "faith", "perceived", "by"],
    words: [
      "it",
      "is",
      "the",
      "heart",
      "which",
      "perceives",
      "god",
      "and",
      "not",
      "reason",
      "that",
      "what",
      "faith",
      "perceived",
      "by",
    ],
    id: "It is the heart which perceives God and not the reason. That is what faith is: God perceived by the heart, not by the reason.",
    author: "Blaise Pascal",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["man", "thinks", "his", "mistress", "own", "sake", "mightily", "mistaken"],
    words: [
      "the",
      "man",
      "that",
      "thinks",
      "he",
      "loves",
      "his",
      "mistress",
      "for",
      "her",
      "own",
      "sake",
      "is",
      "mightily",
      "mistaken",
    ],
    id: "The man that thinks he loves his mistress for her own sake is mightily mistaken.",
    author: "Francois de La Rochefoucauld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["writers", "put", "up", "editor", "thing", "ageless", "eternal", "wrong"],
    words: [
      "writers",
      "have",
      "to",
      "put",
      "up",
      "with",
      "this",
      "editor",
      "thing",
      "it",
      "is",
      "ageless",
      "and",
      "eternal",
      "wrong",
    ],
    id: "Writers have to put up with this editor thing; it is ageless and eternal and wrong.",
    author: "Charles Bukowski",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["evil", "men", "lives", "after", "oft", "interred", "their", "bones"],
    words: [
      "the",
      "evil",
      "that",
      "men",
      "do",
      "lives",
      "after",
      "them",
      "good",
      "is",
      "oft",
      "interred",
      "with",
      "their",
      "bones",
    ],
    id: "The evil that men do lives after them; the good is oft interred with their bones.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "i",
      "careful",
      "confuse",
      "excellence",
      "perfection",
      "reach",
      "god's",
      "business",
    ],
    words: [
      "i",
      "am",
      "careful",
      "not",
      "to",
      "confuse",
      "excellence",
      "with",
      "perfection",
      "can",
      "reach",
      "for",
      "is",
      "god's",
      "business",
    ],
    id: "I am careful not to confuse excellence with perfection. Excellence I can reach for; perfection is God's business.",
    author: "Michael J. Fox",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "journey", "-", "never", "may", "your", "next", "destination"],
    words: [
      "life",
      "to",
      "me",
      "is",
      "a",
      "journey",
      "-",
      "you",
      "never",
      "know",
      "what",
      "may",
      "be",
      "your",
      "next",
      "destination",
    ],
    id: "Life to me is a journey - you never know what may be your next destination.",
    author: "David Russell",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["abdomen", "reason", "why", "man", "readily", "take", "himself", "god"],
    words: [
      "the",
      "abdomen",
      "is",
      "reason",
      "why",
      "man",
      "does",
      "not",
      "readily",
      "take",
      "himself",
      "to",
      "be",
      "a",
      "god",
    ],
    id: "The abdomen is the reason why man does not readily take himself to be a god.",
    author: "Friedrich Nietzsche",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["may", "all", "come", "on", "different", "ships", "same", "boat"],
    words: [
      "we",
      "may",
      "have",
      "all",
      "come",
      "on",
      "different",
      "ships",
      "but",
      "we're",
      "in",
      "the",
      "same",
      "boat",
      "now",
    ],
    id: "We may have all come on different ships, but we're in the same boat now.",
    author: "Martin Luther King, Jr.",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "maturity",
      "ability",
      "reap",
      "without",
      "apology",
      "complain",
      "when",
      "things",
    ],
    words: [
      "maturity",
      "is",
      "the",
      "ability",
      "to",
      "reap",
      "without",
      "apology",
      "and",
      "not",
      "complain",
      "when",
      "things",
      "don't",
      "go",
      "well",
    ],
    id: "Maturity is the ability to reap without apology and not complain when things don't go well.",
    author: "Jim Rohn",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "look", "up", "my", "family", "because", "i'm", "sap"],
    words: [
      "i",
      "don't",
      "have",
      "to",
      "look",
      "up",
      "my",
      "family",
      "tree",
      "because",
      "know",
      "that",
      "i'm",
      "the",
      "sap",
    ],
    id: "I don't have to look up my family tree, because I know that I'm the sap.",
    author: "Fred Allen",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "much",
      "society",
      "research",
      "means",
      "investigate",
      "something",
      "or",
      "understand",
    ],
    words: [
      "in",
      "much",
      "of",
      "society",
      "research",
      "means",
      "to",
      "investigate",
      "something",
      "you",
      "do",
      "not",
      "know",
      "or",
      "understand",
    ],
    id: "In much of society, research means to investigate something you do not know or understand.",
    author: "Neil Armstrong",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["looks", "eyes", "mind", "therefore", "winged", "cupid", "painted", "blind"],
    words: [
      "love",
      "looks",
      "not",
      "with",
      "the",
      "eyes",
      "but",
      "mind",
      "and",
      "therefore",
      "is",
      "winged",
      "cupid",
      "painted",
      "blind",
    ],
    id: "Love looks not with the eyes, but with the mind, And therefore is winged Cupid painted blind.",
    author: "William Shakespeare",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "believe", "at", "first", "sight", "connection", "then", "problems"],
    words: [
      "i",
      "believe",
      "in",
      "love",
      "at",
      "first",
      "sight",
      "you",
      "want",
      "that",
      "connection",
      "and",
      "then",
      "some",
      "problems",
    ],
    id: "I believe in love at first sight. You want that connection, and then you want some problems.",
    author: "Keanu Reeves",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "as", "everyone", "else", "educate", "myself", "normal", "teenager"],
    words: [
      "i",
      "just",
      "want",
      "to",
      "be",
      "as",
      "everyone",
      "else",
      "educate",
      "myself",
      "and",
      "like",
      "a",
      "normal",
      "teenager",
    ],
    id: "I just want to be just as everyone else. I want to educate myself and be just like a normal teenager.",
    author: "Greta Thunburg",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["grows", "by", "giving", "away", "only", "keep", "way", "retain"],
    words: [
      "love",
      "grows",
      "by",
      "giving",
      "the",
      "we",
      "give",
      "away",
      "is",
      "only",
      "keep",
      "way",
      "to",
      "retain",
      "it",
    ],
    id: "Love grows by giving. The love we give away is the only love we keep. The only way to retain love is to give it away.",
    author: "Elbert Hubbard",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["then", "thyself", "presume", "god", "scan", "proper", "mankind", "man"],
    words: [
      "know",
      "then",
      "thyself",
      "presume",
      "not",
      "god",
      "to",
      "scan",
      "the",
      "proper",
      "study",
      "of",
      "mankind",
      "is",
      "man",
    ],
    id: "Know then thyself, presume not God to scan; The proper study of mankind is man.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["often", "god", "being", "even-handed", "biased", "favor", "weak", "despised"],
    words: [
      "people",
      "often",
      "speak",
      "of",
      "god",
      "being",
      "even-handed",
      "is",
      "not",
      "biased",
      "in",
      "favor",
      "the",
      "weak",
      "despised",
    ],
    id: "People often speak of God being even-handed. God is not even-handed. God is biased, in favor of the weak, of the despised.",
    author: "Desmond Tutu",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "beware", "all", "enterprises", "require", "clothes", "rather", "wearer"],
    words: [
      "i",
      "say",
      "beware",
      "of",
      "all",
      "enterprises",
      "that",
      "require",
      "new",
      "clothes",
      "and",
      "not",
      "rather",
      "a",
      "wearer",
    ],
    id: "I say, beware of all enterprises that require new clothes, and not rather a new wearer of clothes.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "christian", "right", "dominates", "america", "way", "media", "believe"],
    words: [
      "i",
      "don't",
      "think",
      "the",
      "christian",
      "right",
      "dominates",
      "america",
      "in",
      "way",
      "some",
      "media",
      "believe",
      "they",
      "do",
    ],
    id: "I don't think the Christian Right dominates America in the way some in the media believe they do.",
    author: "Billy Graham",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "years",
      "happiness",
      "lost",
      "foolish",
      "gratification",
      "momentary",
      "desire",
      "pleasure",
    ],
    words: [
      "years",
      "of",
      "happiness",
      "can",
      "be",
      "lost",
      "in",
      "the",
      "foolish",
      "gratification",
      "a",
      "momentary",
      "desire",
      "for",
      "pleasure",
    ],
    id: "Years of happiness can be lost in the foolish gratification of a momentary desire for pleasure.",
    author: "Ezra Taft Benson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["key", "everything", "patience", "get", "by", "hatching", "egg", "smashing"],
    words: [
      "the",
      "key",
      "to",
      "everything",
      "is",
      "patience",
      "you",
      "get",
      "chicken",
      "by",
      "hatching",
      "egg",
      "not",
      "smashing",
      "it",
    ],
    id: "The key to everything is patience. You get the chicken by hatching the egg, not by smashing it.",
    author: "Arnold H. Glasow",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["always", "actions", "show", "man", "find", "kindness", "therefore", "kind"],
    words: [
      "not",
      "always",
      "actions",
      "show",
      "the",
      "man",
      "we",
      "find",
      "who",
      "does",
      "a",
      "kindness",
      "is",
      "therefore",
      "kind",
    ],
    id: "Not always actions show the man; we find who does a kindness is not therefore kind.",
    author: "Alexander Pope",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "freedom",
      "without",
      "rules",
      "communities",
      "unless",
      "regulated",
      "by",
      "etiquette",
    ],
    words: [
      "freedom",
      "without",
      "rules",
      "doesn't",
      "work",
      "and",
      "communities",
      "do",
      "not",
      "unless",
      "they",
      "are",
      "regulated",
      "by",
      "etiquette",
    ],
    id: "Freedom without rules doesn't work. And communities do not work unless they are regulated by etiquette.",
    author: "Judith Martin",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "much", "really", "through", "sounds", "inconsistent", "joe", "biden"],
    words: [
      "i",
      "don't",
      "say",
      "very",
      "much",
      "really",
      "think",
      "through",
      "know",
      "that",
      "sounds",
      "inconsistent",
      "with",
      "joe",
      "biden",
    ],
    id: "I don't say very much I don't really think through. I know that sounds inconsistent with Joe Biden.",
    author: "Joe Biden",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "harder",
      "better",
      "universe",
      "will",
      "towards",
      "giving",
      "more",
      "happiness",
    ],
    words: [
      "try",
      "harder",
      "to",
      "be",
      "better",
      "and",
      "the",
      "universe",
      "will",
      "work",
      "towards",
      "giving",
      "you",
      "more",
      "happiness",
    ],
    id: "Try harder to be better and the universe will work towards giving you more happiness.",
    author: "Heart Evangelista",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "morality",
      "doctrine",
      "how",
      "may",
      "make",
      "ourselves",
      "worthy",
      "happiness",
    ],
    words: [
      "morality",
      "is",
      "not",
      "the",
      "doctrine",
      "of",
      "how",
      "we",
      "may",
      "make",
      "ourselves",
      "happy",
      "but",
      "worthy",
      "happiness",
    ],
    id: "Morality is not the doctrine of how we may make ourselves happy, but how we may make ourselves worthy of happiness.",
    author: "Immanuel Kant",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["greatest", "use", "life", "spend", "on", "something", "will", "outlast"],
    words: [
      "the",
      "greatest",
      "use",
      "of",
      "a",
      "life",
      "is",
      "to",
      "spend",
      "it",
      "on",
      "something",
      "that",
      "will",
      "outlast",
    ],
    id: "The greatest use of a life is to spend it on something that will outlast it.",
    author: "William James",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["function", "muscle", "pull", "push", "except", "case", "genitals", "tongue"],
    words: [
      "the",
      "function",
      "of",
      "muscle",
      "is",
      "to",
      "pull",
      "and",
      "not",
      "push",
      "except",
      "in",
      "case",
      "genitals",
      "tongue",
    ],
    id: "The function of muscle is to pull and not to push, except in the case of the genitals and the tongue.",
    author: "Leonardo da Vinci",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["secret", "happiness", "variety", "all", "spices", "knowing", "when", "use"],
    words: [
      "the",
      "secret",
      "of",
      "happiness",
      "is",
      "variety",
      "but",
      "like",
      "all",
      "spices",
      "knowing",
      "when",
      "to",
      "use",
      "it",
    ],
    id: "The secret of happiness is variety, but the secret of variety, like the secret of all spices, is knowing when to use it.",
    author: "Daniel Gilbert",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "successor", "only", "willing", "hands", "accept", "torch", "generation"],
    words: [
      "i",
      "don't",
      "need",
      "a",
      "successor",
      "only",
      "willing",
      "hands",
      "to",
      "accept",
      "the",
      "torch",
      "for",
      "new",
      "generation",
    ],
    id: "I don't need a successor, only willing hands to accept the torch for a new generation.",
    author: "Billy Graham",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "problems", "cannot", "solve", "together", "few", "by", "ourselves"],
    words: [
      "there",
      "are",
      "no",
      "problems",
      "we",
      "cannot",
      "solve",
      "together",
      "and",
      "very",
      "few",
      "that",
      "can",
      "by",
      "ourselves",
    ],
    id: "There are no problems we cannot solve together, and very few that we can solve by ourselves.",
    author: "Lyndon B. Johnson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "ought", "raise", "age", "at", "which", "juveniles", "gun"],
    words: [
      "i",
      "think",
      "we",
      "ought",
      "to",
      "raise",
      "the",
      "age",
      "at",
      "which",
      "juveniles",
      "can",
      "have",
      "a",
      "gun",
    ],
    id: "I think we ought to raise the age at which juveniles can have a gun.",
    author: "George W. Bush",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["literally", "true", "succeed", "best", "quickest", "by", "helping", "others"],
    words: [
      "it",
      "is",
      "literally",
      "true",
      "that",
      "you",
      "can",
      "succeed",
      "best",
      "and",
      "quickest",
      "by",
      "helping",
      "others",
      "to",
    ],
    id: "It is literally true that you can succeed best and quickest by helping others to succeed.",
    author: "Napoleon Hill",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["ever", "get", "chance", "something", "outside", "box", "i", "step"],
    words: [
      "if",
      "you",
      "ever",
      "get",
      "a",
      "chance",
      "to",
      "do",
      "something",
      "outside",
      "the",
      "box",
      "i",
      "say",
      "step",
    ],
    id: "If you ever get a chance to do something outside the box... I say step outside the box.",
    author: "Tom Izzo",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["lord", "make", "instrument", "thy", "peace", "there", "hatred", "sow"],
    words: [
      "lord",
      "make",
      "me",
      "an",
      "instrument",
      "of",
      "thy",
      "peace",
      "where",
      "there",
      "is",
      "hatred",
      "let",
      "sow",
      "love",
    ],
    id: "Lord, make me an instrument of thy peace. Where there is hatred, let me sow love.",
    author: "Francis of Assisi",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "all",
      "passions",
      "strongest",
      "attacks",
      "simultaneously",
      "head",
      "heart",
      "senses",
    ],
    words: [
      "love",
      "is",
      "of",
      "all",
      "passions",
      "the",
      "strongest",
      "for",
      "it",
      "attacks",
      "simultaneously",
      "head",
      "heart",
      "and",
      "senses",
    ],
    id: "Love is of all passions the strongest, for it attacks simultaneously the head, the heart and the senses.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "freedom", "movement", "my", "phone", "definitely", "transformed", "life"],
    words: [
      "i",
      "love",
      "the",
      "freedom",
      "of",
      "movement",
      "that",
      "my",
      "phone",
      "gives",
      "me",
      "has",
      "definitely",
      "transformed",
      "life",
    ],
    id: "I love the freedom of movement that my phone gives me. That has definitely transformed my life.",
    author: "Richard Branson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["any", "jackass", "kick", "down", "barn", "takes", "carpenter", "one"],
    words: [
      "any",
      "jackass",
      "can",
      "kick",
      "down",
      "a",
      "barn",
      "but",
      "it",
      "takes",
      "good",
      "carpenter",
      "to",
      "build",
      "one",
    ],
    id: "Any jackass can kick down a barn, but it takes a good carpenter to build one.",
    author: "Sam Rayburn",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "friendship",
      "respect",
      "unite",
      "as",
      "much",
      "common",
      "hatred",
      "something",
    ],
    words: [
      "love",
      "friendship",
      "and",
      "respect",
      "do",
      "not",
      "unite",
      "people",
      "as",
      "much",
      "a",
      "common",
      "hatred",
      "for",
      "something",
    ],
    id: "Love, friendship and respect do not unite people as much as a common hatred for something.",
    author: "Anton Chekhov",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["can't", "expect", "hit", "jackpot", "put", "few", "nickels", "machine"],
    words: [
      "you",
      "can't",
      "expect",
      "to",
      "hit",
      "the",
      "jackpot",
      "if",
      "don't",
      "put",
      "a",
      "few",
      "nickels",
      "in",
      "machine",
    ],
    id: "You can't expect to hit the jackpot if you don't put a few nickels in the machine.",
    author: "Flip Wilson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["wealth", "sea-water", "more", "thirstier", "become", "same", "true", "fame"],
    words: [
      "wealth",
      "is",
      "like",
      "sea-water",
      "the",
      "more",
      "we",
      "drink",
      "thirstier",
      "become",
      "and",
      "same",
      "true",
      "of",
      "fame",
    ],
    id: "Wealth is like sea-water; the more we drink, the thirstier we become; and the same is true of fame.",
    author: "Arthur Schopenhauer",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "begin",
      "understand",
      "without",
      "trying",
      "change",
      "then",
      "undergoes",
      "transformation",
    ],
    words: [
      "if",
      "you",
      "begin",
      "to",
      "understand",
      "what",
      "are",
      "without",
      "trying",
      "change",
      "it",
      "then",
      "undergoes",
      "a",
      "transformation",
    ],
    id: "If you begin to understand what you are without trying to change it, then what you are undergoes a transformation.",
    author: "Jiddu Krishnamurti",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "nature",
      "requires",
      "obtainable",
      "within",
      "easy",
      "reach",
      "superfluous",
      "sweat",
    ],
    words: [
      "what",
      "nature",
      "requires",
      "is",
      "obtainable",
      "and",
      "within",
      "easy",
      "reach",
      "it",
      "for",
      "the",
      "superfluous",
      "we",
      "sweat",
    ],
    id: "What nature requires is obtainable, and within easy reach. It is for the superfluous we sweat.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "into", "fight", "mad", "my", "birthday", "i'm", "doing"],
    words: [
      "i",
      "don't",
      "go",
      "into",
      "a",
      "fight",
      "mad",
      "like",
      "it's",
      "my",
      "birthday",
      "love",
      "what",
      "i'm",
      "doing",
    ],
    id: "I don't go into a fight mad. I go into a fight like it's my birthday. I love what I'm doing.",
    author: "Paige VanZant",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "yesterday's",
      "past",
      "tomorrow's",
      "future",
      "gift",
      "why",
      "called",
      "present",
    ],
    words: [
      "yesterday's",
      "the",
      "past",
      "tomorrow's",
      "future",
      "but",
      "today",
      "is",
      "a",
      "gift",
      "that's",
      "why",
      "it's",
      "called",
      "present",
    ],
    id: "Yesterday's the past, tomorrow's the future, but today is a gift. That's why it's called the present.",
    author: "Bil Keane",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "sequel", "waste", "time", "movies", "should", "illuminate", "stories"],
    words: [
      "i",
      "think",
      "a",
      "sequel",
      "is",
      "waste",
      "of",
      "money",
      "and",
      "time",
      "movies",
      "should",
      "illuminate",
      "new",
      "stories",
    ],
    id: "I think a sequel is a waste of money and time. I think movies should illuminate new stories.",
    author: "Francis Ford Coppola",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["hungry", "because", "those", "authority", "up", "too", "much", "taxes"],
    words: [
      "the",
      "people",
      "are",
      "hungry",
      "it",
      "is",
      "because",
      "those",
      "in",
      "authority",
      "eat",
      "up",
      "too",
      "much",
      "taxes",
    ],
    id: "The people are hungry: It is because those in authority eat up too much in taxes.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "death",
      "commingling",
      "eternity",
      "time",
      "man",
      "seen",
      "looking",
      "through",
    ],
    words: [
      "death",
      "is",
      "a",
      "commingling",
      "of",
      "eternity",
      "with",
      "time",
      "in",
      "the",
      "good",
      "man",
      "seen",
      "looking",
      "through",
    ],
    id: "Death is a commingling of eternity with time; in the death of a good man, eternity is seen looking through time.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "management",
      "by",
      "objective",
      "-",
      "objectives",
      "ninety",
      "percent",
      "time",
    ],
    words: [
      "management",
      "by",
      "objective",
      "works",
      "-",
      "if",
      "you",
      "know",
      "the",
      "objectives",
      "ninety",
      "percent",
      "of",
      "time",
      "don't",
    ],
    id: "Management by objective works - if you know the objectives. Ninety percent of the time you don't.",
    author: "Peter Drucker",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["unfortunate", "fact", "secure", "peace", "only", "by", "preparing", "war"],
    words: [
      "it",
      "is",
      "an",
      "unfortunate",
      "fact",
      "that",
      "we",
      "can",
      "secure",
      "peace",
      "only",
      "by",
      "preparing",
      "for",
      "war",
    ],
    id: "It is an unfortunate fact that we can secure peace only by preparing for war.",
    author: "John F. Kennedy",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "objective",
      "cleaning",
      "clean",
      "feel",
      "happiness",
      "living",
      "within",
      "environment",
    ],
    words: [
      "the",
      "objective",
      "of",
      "cleaning",
      "is",
      "not",
      "just",
      "to",
      "clean",
      "but",
      "feel",
      "happiness",
      "living",
      "within",
      "that",
      "environment",
    ],
    id: "The objective of cleaning is not just to clean, but to feel happiness living within that environment.",
    author: "Marie Kondo",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["pursue", "path", "however", "narrow", "crooked", "which", "walk", "reverence"],
    words: [
      "pursue",
      "some",
      "path",
      "however",
      "narrow",
      "and",
      "crooked",
      "in",
      "which",
      "you",
      "can",
      "walk",
      "with",
      "love",
      "reverence",
    ],
    id: "Pursue some path, however narrow and crooked, in which you can walk with love and reverence.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["author", "way", "portrays", "himself", "his", "even", "against", "will"],
    words: [
      "every",
      "author",
      "in",
      "some",
      "way",
      "portrays",
      "himself",
      "his",
      "works",
      "even",
      "if",
      "it",
      "be",
      "against",
      "will",
    ],
    id: "Every author in some way portrays himself in his works, even if it be against his will.",
    author: "Johann Wolfgang von Goethe",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["each", "body", "mind", "soul", "been", "freed", "fulfill", "themselves"],
    words: [
      "let",
      "each",
      "know",
      "that",
      "for",
      "the",
      "body",
      "mind",
      "and",
      "soul",
      "have",
      "been",
      "freed",
      "to",
      "fulfill",
      "themselves",
    ],
    id: "Let each know that for each the body, the mind and the soul have been freed to fulfill themselves.",
    author: "Nelson Mandela",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "greatest",
      "gifts",
      "your",
      "children",
      "roots",
      "responsibility",
      "wings",
      "independence",
    ],
    words: [
      "the",
      "greatest",
      "gifts",
      "you",
      "can",
      "give",
      "your",
      "children",
      "are",
      "roots",
      "of",
      "responsibility",
      "and",
      "wings",
      "independence",
    ],
    id: "The greatest gifts you can give your children are the roots of responsibility and the wings of independence.",
    author: "Denis Waitley",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["live", "kind", "society", "almost", "all", "cases", "hard", "rewarded"],
    words: [
      "we",
      "live",
      "in",
      "the",
      "kind",
      "of",
      "society",
      "where",
      "almost",
      "all",
      "cases",
      "hard",
      "work",
      "is",
      "rewarded",
    ],
    id: "We live in the kind of society where, in almost all cases, hard work is rewarded.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "all",
      "dream",
      "reasoning",
      "arguments",
      "use",
      "truth",
      "knowledge",
      "nothing",
    ],
    words: [
      "where",
      "all",
      "is",
      "but",
      "dream",
      "reasoning",
      "and",
      "arguments",
      "are",
      "of",
      "no",
      "use",
      "truth",
      "knowledge",
      "nothing",
    ],
    id: "Where all is but dream, reasoning and arguments are of no use, truth and knowledge nothing.",
    author: "John Locke",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["life", "piano", "get", "out", "depends", "on", "how", "play"],
    words: [
      "life",
      "is",
      "like",
      "a",
      "piano",
      "what",
      "you",
      "get",
      "out",
      "of",
      "it",
      "depends",
      "on",
      "how",
      "play",
    ],
    id: "Life is like a piano. What you get out of it depends on how you play it.",
    author: "Tom Lehrer",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "intimidate", "anybody", "before", "fight", "nonsense", "by", "hitting"],
    words: [
      "i",
      "don't",
      "try",
      "to",
      "intimidate",
      "anybody",
      "before",
      "a",
      "fight",
      "that's",
      "nonsense",
      "people",
      "by",
      "hitting",
      "them",
    ],
    id: "I don't try to intimidate anybody before a fight. That's nonsense. I intimidate people by hitting them.",
    author: "Mike Tyson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "feel",
      "difficult",
      "change",
      "will",
      "probably",
      "harder",
      "time",
      "succeeding",
    ],
    words: [
      "if",
      "you",
      "feel",
      "like",
      "it's",
      "difficult",
      "to",
      "change",
      "will",
      "probably",
      "have",
      "a",
      "harder",
      "time",
      "succeeding",
    ],
    id: "If you feel like it's difficult to change, you will probably have a harder time succeeding.",
    author: "Andrea Jung",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["baseball", "poker", "game", "nobody", "quit", "when", "losing", "ahead"],
    words: [
      "baseball",
      "is",
      "like",
      "a",
      "poker",
      "game",
      "nobody",
      "wants",
      "to",
      "quit",
      "when",
      "he's",
      "losing",
      "you",
      "you're",
      "ahead",
    ],
    id: "Baseball is like a poker game. Nobody wants to quit when he's losing; nobody wants you to quit when you're ahead.",
    author: "Jackie Robinson",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["hardest", "there", "which", "probably", "reason", "why", "few", "engage"],
    words: [
      "thinking",
      "is",
      "the",
      "hardest",
      "work",
      "there",
      "which",
      "probably",
      "reason",
      "why",
      "so",
      "few",
      "engage",
      "in",
      "it",
    ],
    id: "Thinking is the hardest work there is, which is probably the reason why so few engage in it.",
    author: "Henry Ford",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "difficult",
      "express",
      "words",
      "feelings",
      "happiness",
      "pride",
      "which",
      "fill",
    ],
    words: [
      "it",
      "is",
      "difficult",
      "to",
      "express",
      "in",
      "words",
      "the",
      "feelings",
      "of",
      "happiness",
      "and",
      "pride",
      "which",
      "fill",
      "me",
    ],
    id: "It is difficult to express in words the feelings of happiness and pride which fill me.",
    author: "Gherman Titov",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["only", "take", "great", "resolves", "indomitable", "faith", "god", "fear"],
    words: [
      "only",
      "he",
      "can",
      "take",
      "great",
      "resolves",
      "who",
      "has",
      "indomitable",
      "faith",
      "in",
      "god",
      "and",
      "fear",
      "of",
    ],
    id: "Only he can take great resolves who has indomitable faith in God and has fear of God.",
    author: "Mahatma Gandhi",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["find", "out", "one", "fitted", "secure", "opportunity", "key", "happiness"],
    words: [
      "to",
      "find",
      "out",
      "what",
      "one",
      "is",
      "fitted",
      "do",
      "and",
      "secure",
      "an",
      "opportunity",
      "it",
      "the",
      "key",
      "happiness",
    ],
    id: "To find out what one is fitted to do, and to secure an opportunity to do it, is the key to happiness.",
    author: "John Dewey",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["there", "many", "world", "cannot", "read", "or", "french", "whatever"],
    words: [
      "there",
      "are",
      "so",
      "many",
      "people",
      "in",
      "the",
      "world",
      "who",
      "cannot",
      "read",
      "english",
      "or",
      "french",
      "whatever",
    ],
    id: "There are so many people in the world who cannot read English or French or whatever.",
    author: "Karl Lagerfeld",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "life",
      "isn't",
      "algebra",
      "geometry",
      "by",
      "making",
      "mistakes",
      "duplicating",
    ],
    words: [
      "life",
      "isn't",
      "about",
      "algebra",
      "and",
      "geometry",
      "learning",
      "by",
      "making",
      "mistakes",
      "not",
      "duplicating",
      "them",
      "is",
      "what",
    ],
    id: "Life isn't about algebra and geometry. Learning by making mistakes and not duplicating them is what life is about.",
    author: "Lindsay Fox",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["middle", "road", "white", "line", "-", "worst", "place", "drive"],
    words: [
      "the",
      "middle",
      "of",
      "road",
      "is",
      "where",
      "white",
      "line",
      "-",
      "and",
      "that's",
      "worst",
      "place",
      "to",
      "drive",
    ],
    id: "The middle of the road is where the white line is - and that's the worst place to drive.",
    author: "Robert Frost",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["law", "will", "never", "make", "man", "free", "men", "got"],
    words: [
      "the",
      "law",
      "will",
      "never",
      "make",
      "a",
      "man",
      "free",
      "it",
      "is",
      "men",
      "who",
      "have",
      "got",
      "to",
    ],
    id: "The law will never make a man free; it is men who have got to make the law free.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "pretty",
      "much",
      "plant",
      "alive",
      "result",
      "eons",
      "natural",
      "cross-breeding",
    ],
    words: [
      "pretty",
      "much",
      "every",
      "plant",
      "and",
      "animal",
      "alive",
      "today",
      "is",
      "the",
      "result",
      "of",
      "eons",
      "natural",
      "cross-breeding",
    ],
    id: "Pretty much every plant and animal alive today is the result of eons of natural cross-breeding.",
    author: "Neil deGrasse Tyson",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "fail", "from", "mistakes", "made", "motivates", "even", "harder"],
    words: [
      "when",
      "you",
      "fail",
      "learn",
      "from",
      "the",
      "mistakes",
      "made",
      "and",
      "it",
      "motivates",
      "to",
      "work",
      "even",
      "harder",
    ],
    id: "When you fail you learn from the mistakes you made and it motivates you to work even harder.",
    author: "Natalie Gulbis",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "fibers",
      "all",
      "things",
      "their",
      "tension",
      "strained",
      "strings",
      "instrument",
    ],
    words: [
      "the",
      "fibers",
      "of",
      "all",
      "things",
      "have",
      "their",
      "tension",
      "and",
      "are",
      "strained",
      "like",
      "strings",
      "an",
      "instrument",
    ],
    id: "The fibers of all things have their tension and are strained like the strings of an instrument.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["wide", "world", "all", "fence", "yourselves", "cannot", "forever", "out"],
    words: [
      "the",
      "wide",
      "world",
      "is",
      "all",
      "about",
      "you",
      "can",
      "fence",
      "yourselves",
      "in",
      "but",
      "cannot",
      "forever",
      "it",
      "out",
    ],
    id: "The wide world is all about you: you can fence yourselves in, but you cannot forever fence it out.",
    author: "J. R. R. Tolkien",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "these",
      "well-fed",
      "long-haired",
      "men",
      "i",
      "fear",
      "pale",
      "hungry-looking",
    ],
    words: [
      "it",
      "is",
      "not",
      "these",
      "well-fed",
      "long-haired",
      "men",
      "that",
      "i",
      "fear",
      "but",
      "the",
      "pale",
      "and",
      "hungry-looking",
    ],
    id: "It is not these well-fed long-haired men that I fear, but the pale and the hungry-looking.",
    author: "Julius Caesar",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["will", "survive", "find", "happiness", "only", "comes", "when", "looking"],
    words: [
      "people",
      "will",
      "survive",
      "and",
      "they",
      "find",
      "happiness",
      "only",
      "comes",
      "when",
      "you're",
      "not",
      "looking",
      "for",
      "it",
    ],
    id: "People will survive, and they will find happiness. Happiness only comes when you're not looking for it.",
    author: "Hugh Laurie",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["get", "rich", "never", "risk", "your", "health", "truth", "wealth"],
    words: [
      "to",
      "get",
      "rich",
      "never",
      "risk",
      "your",
      "health",
      "for",
      "it",
      "is",
      "the",
      "truth",
      "that",
      "wealth",
      "of",
    ],
    id: "To get rich never risk your health. For it is the truth that health is the wealth of wealth.",
    author: "Richard Baker",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["wary", "man", "urges", "action", "which", "himself", "incurs", "risk"],
    words: [
      "be",
      "wary",
      "of",
      "the",
      "man",
      "who",
      "urges",
      "an",
      "action",
      "in",
      "which",
      "he",
      "himself",
      "incurs",
      "no",
      "risk",
    ],
    id: "Be wary of the man who urges an action in which he himself incurs no risk.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["gift", "consists", "done", "or", "given", "intention", "giver", "doer"],
    words: [
      "a",
      "gift",
      "consists",
      "not",
      "in",
      "what",
      "is",
      "done",
      "or",
      "given",
      "but",
      "the",
      "intention",
      "of",
      "giver",
      "doer",
    ],
    id: "A gift consists not in what is done or given, but in the intention of the giver or doer.",
    author: "Lucius Annaeus Seneca",
    grammar: null,
    word_count: 16,
    all_intersection_count: 8,
    new_words_count: 8,
  },
  {
    row_new_words: ["death", "greatest", "loss", "life", "dies", "inside", "while", "live"],
    words: [
      "death",
      "is",
      "not",
      "the",
      "greatest",
      "loss",
      "in",
      "life",
      "what",
      "dies",
      "inside",
      "us",
      "while",
      "we",
      "live",
    ],
    id: "Death is not the greatest loss in life. The greatest loss is what dies inside us while we live.",
    author: "Norman Cousins",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "depends", "on", "how", "look", "at", "things", "themselves"],
    words: [
      "it",
      "all",
      "depends",
      "on",
      "how",
      "we",
      "look",
      "at",
      "things",
      "and",
      "not",
      "they",
      "are",
      "in",
      "themselves",
    ],
    id: "It all depends on how we look at things, and not how they are in themselves.",
    author: "Carl Jung",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["limit", "child", "your", "own", "was", "born", "another", "time"],
    words: [
      "don't",
      "limit",
      "a",
      "child",
      "to",
      "your",
      "own",
      "learning",
      "for",
      "he",
      "was",
      "born",
      "in",
      "another",
      "time",
    ],
    id: "Don't limit a child to your own learning, for he was born in another time.",
    author: "Rabindranath Tagore",
    grammar: null,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "health",
      "commodity",
      "should",
      "distributed",
      "according",
      "sick",
      "lot",
      "shouldn't",
    ],
    words: [
      "health",
      "care",
      "is",
      "a",
      "need",
      "it's",
      "not",
      "commodity",
      "and",
      "it",
      "should",
      "be",
      "distributed",
      "according",
      "to",
      "if",
      "you're",
      "very",
      "sick",
      "you",
      "have",
      "lot",
      "of",
      "shouldn't",
    ],
    id: "Health care is a need; it's not a commodity, and it should be distributed according to need. If you're very sick, you should have a lot of it. If you're not sick, you shouldn't have a lot of it.",
    author: "Marcia Angell",
    grammar: null,
    word_count: 24,
    all_intersection_count: 16,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "i",
      "everyone",
      "always",
      "surprised",
      "friendship",
      "vixen",
      "supportive",
      "each",
    ],
    words: [
      "i",
      "think",
      "everyone",
      "is",
      "always",
      "so",
      "surprised",
      "to",
      "see",
      "the",
      "friendship",
      "that",
      "have",
      "with",
      "vixen",
      "we",
      "are",
      "very",
      "supportive",
      "of",
      "each",
      "other",
    ],
    id: "I think everyone is always so surprised to see the friendship that I have with The Vixen. We are very supportive of each other.",
    author: "Aquaria",
    grammar: null,
    word_count: 22,
    all_intersection_count: 14,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "business",
      "life",
      "first",
      "all",
      "commitment",
      "dedication",
      "passion",
      "doing",
    ],
    words: [
      "this",
      "is",
      "a",
      "learning",
      "in",
      "the",
      "business",
      "life",
      "that",
      "first",
      "of",
      "all",
      "you",
      "need",
      "to",
      "have",
      "commitment",
      "dedication",
      "and",
      "passion",
      "for",
      "what",
      "are",
      "doing",
    ],
    id: "This is a learning in the business life that first of all you need to have commitment, dedication and passion for what you are doing.",
    author: "Lakshmi Mittal",
    grammar: null,
    word_count: 24,
    all_intersection_count: 16,
    new_words_count: 8,
  },
  {
    row_new_words: ["hiv", "make", "dangerous", "shake", "their", "hands", "hug", "heaven"],
    words: [
      "hiv",
      "does",
      "not",
      "make",
      "people",
      "dangerous",
      "to",
      "know",
      "so",
      "you",
      "can",
      "shake",
      "their",
      "hands",
      "and",
      "give",
      "them",
      "a",
      "hug",
      "heaven",
      "knows",
      "they",
      "need",
      "it",
    ],
    id: "HIV does not make people dangerous to know, so you can shake their hands and give them a hug: Heaven knows they need it.",
    author: "Princess Diana",
    grammar: null,
    word_count: 24,
    all_intersection_count: 16,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "interesting",
      "partnership",
      "friendship",
      "whatever",
      "call",
      "even",
      "special",
      "celebrating",
    ],
    words: [
      "it's",
      "an",
      "interesting",
      "partnership",
      "that",
      "we",
      "have",
      "friendship",
      "whatever",
      "you",
      "want",
      "to",
      "call",
      "it",
      "don't",
      "even",
      "know",
      "what",
      "but",
      "special",
      "and",
      "we're",
      "celebrating",
    ],
    id: "It's an interesting partnership that we have, friendship, whatever you want to call it. We don't even know what to call it. But we know that it's special and we're celebrating that.",
    author: "Scott Moir",
    grammar: null,
    word_count: 23,
    all_intersection_count: 15,
    new_words_count: 8,
  },
  {
    row_new_words: ["part", "i", "much", "writing", "unlike", "myself", "places", "my"],
    words: [
      "part",
      "of",
      "what",
      "i",
      "love",
      "so",
      "much",
      "about",
      "writing",
      "is",
      "learning",
      "people",
      "who",
      "are",
      "unlike",
      "myself",
      "and",
      "going",
      "to",
      "places",
      "in",
      "my",
      "that",
      "don't",
      "know",
      "very",
      "well",
    ],
    id: "Part of what I love so much about writing is learning about people who are unlike myself and going to places in my writing that I don't know very well.",
    author: "Nia DaCosta",
    grammar: null,
    word_count: 27,
    all_intersection_count: 19,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "music", "straightforward", "because", "i", "alone", "through", "things"],
    words: [
      "my",
      "music",
      "is",
      "straightforward",
      "because",
      "i",
      "want",
      "to",
      "give",
      "people",
      "me",
      "and",
      "let",
      "them",
      "know",
      "they're",
      "not",
      "alone",
      "in",
      "going",
      "through",
      "the",
      "things",
      "that",
      "they",
      "go",
    ],
    id: "My music is straightforward because I want to give people me and let them know they're not alone in going through the things that they go through.",
    author: "Juice Wrld",
    grammar: null,
    word_count: 26,
    all_intersection_count: 18,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "satisfied",
      "giving",
      "enough",
      "got",
      "your",
      "hearts",
      "spread",
      "everywhere",
    ],
    words: [
      "let",
      "us",
      "not",
      "be",
      "satisfied",
      "with",
      "just",
      "giving",
      "money",
      "is",
      "enough",
      "can",
      "got",
      "but",
      "they",
      "need",
      "your",
      "hearts",
      "to",
      "love",
      "them",
      "so",
      "spread",
      "everywhere",
      "you",
      "go",
    ],
    id: "Let us not be satisfied with just giving money. Money is not enough, money can be got, but they need your hearts to love them. So, spread your love everywhere you go.",
    author: "Mother Teresa",
    grammar: null,
    word_count: 26,
    all_intersection_count: 18,
    new_words_count: 8,
  },
  {
    row_new_words: ["gift", "from", "god", "there's", "guarantee", "tells", "make", "most"],
    words: [
      "every",
      "day",
      "is",
      "a",
      "gift",
      "from",
      "god",
      "there's",
      "no",
      "guarantee",
      "of",
      "tomorrow",
      "so",
      "that",
      "tells",
      "me",
      "to",
      "see",
      "the",
      "good",
      "in",
      "this",
      "make",
      "most",
      "it",
    ],
    id: "Every day is a gift from God. There's no guarantee of tomorrow, so that tells me to see the good in this day to make the most of it.",
    author: "Joel Osteen",
    grammar: null,
    word_count: 25,
    all_intersection_count: 17,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "deficiency",
      "motivation",
      "will",
      "lead",
      "life-long",
      "pursuit",
      "fix",
      "appreciate",
    ],
    words: [
      "deficiency",
      "motivation",
      "doesn't",
      "work",
      "it",
      "will",
      "lead",
      "to",
      "a",
      "life-long",
      "pursuit",
      "of",
      "try",
      "fix",
      "me",
      "learn",
      "appreciate",
      "what",
      "you",
      "have",
      "and",
      "where",
      "who",
      "are",
    ],
    id: "Deficiency motivation doesn't work. It will lead to a life-long pursuit of try to fix me. Learn to appreciate what you have and where and who you are.",
    author: "Wayne Dyer",
    grammar: null,
    word_count: 24,
    all_intersection_count: 16,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "innovation",
      "hand",
      "arrogance",
      "success",
      "did",
      "yesterday",
      "will",
      "sufficient",
    ],
    words: [
      "learning",
      "and",
      "innovation",
      "go",
      "hand",
      "in",
      "the",
      "arrogance",
      "of",
      "success",
      "is",
      "to",
      "think",
      "that",
      "what",
      "you",
      "did",
      "yesterday",
      "will",
      "be",
      "sufficient",
      "for",
      "tomorrow",
    ],
    id: "Learning and innovation go hand in hand. The arrogance of success is to think that what you did yesterday will be sufficient for tomorrow.",
    author: "William Pollard",
    grammar: null,
    word_count: 23,
    all_intersection_count: 15,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "goodness",
      "something",
      "makes",
      "serene",
      "content",
      "magnificent",
      "those",
      "evil",
    ],
    words: [
      "goodness",
      "is",
      "something",
      "that",
      "makes",
      "us",
      "serene",
      "and",
      "content",
      "it",
      "magnificent",
      "those",
      "who",
      "are",
      "not",
      "good",
      "evil",
    ],
    id: "Goodness is something that makes us serene and content; it is magnificent. Those who are not good are evil.",
    author: "Bhumibol Adulyadej",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "one", "great", "things", "being", "musician", "never", "stop"],
    words: [
      "i",
      "think",
      "one",
      "of",
      "the",
      "great",
      "things",
      "about",
      "being",
      "a",
      "musician",
      "is",
      "that",
      "you",
      "never",
      "stop",
      "learning",
    ],
    id: "I think one of the great things about being a musician is that you never stop learning.",
    author: "Yo-Yo Ma",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["one", "life", "soon", "will", "past", "god", "all", "last"],
    words: [
      "we",
      "have",
      "one",
      "life",
      "it",
      "soon",
      "will",
      "be",
      "past",
      "what",
      "do",
      "for",
      "god",
      "is",
      "all",
      "that",
      "last",
    ],
    id: "We have one life; it soon will be past; what we do for God is all that will last.",
    author: "Charles Studd",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["as", "species", "at", "times", "dimwitted", "cruel", "also", "capable"],
    words: [
      "as",
      "a",
      "species",
      "we",
      "can",
      "at",
      "times",
      "be",
      "dimwitted",
      "and",
      "cruel",
      "but",
      "we're",
      "also",
      "capable",
      "of",
      "learning",
    ],
    id: "As a species, we can at times be dimwitted and cruel. But we're also capable of learning.",
    author: "K. A. Applegate",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["reputed", "sick", "often", "happens", "sicker", "man", "nurse", "sounder"],
    words: [
      "some",
      "are",
      "reputed",
      "sick",
      "and",
      "not",
      "it",
      "often",
      "happens",
      "that",
      "the",
      "sicker",
      "man",
      "is",
      "nurse",
      "to",
      "sounder",
    ],
    id: "Some are reputed sick and some are not. It often happens that the sicker man is the nurse to the sounder.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["only", "thing", "holds", "true", "happiness", "when", "nothing", "controlled"],
    words: [
      "the",
      "only",
      "thing",
      "that",
      "holds",
      "true",
      "happiness",
      "is",
      "moment",
      "when",
      "you're",
      "in",
      "it",
      "nothing",
      "can",
      "be",
      "controlled",
    ],
    id: "The only thing that holds true happiness is that moment when you're in it. Nothing can be controlled.",
    author: "Eliza Doolittle",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["true", "won", "all", "our", "wars", "paid", "victories", "anymore"],
    words: [
      "it",
      "is",
      "true",
      "we",
      "have",
      "won",
      "all",
      "our",
      "wars",
      "but",
      "paid",
      "for",
      "them",
      "don't",
      "want",
      "victories",
      "anymore",
    ],
    id: "It is true we have won all our wars, but we have paid for them. We don't want victories anymore.",
    author: "Golda Meir",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "only",
      "their",
      "liabilities",
      "would",
      "riches",
      "possess",
      "stop",
      "worrying",
    ],
    words: [
      "if",
      "only",
      "the",
      "people",
      "who",
      "worry",
      "about",
      "their",
      "liabilities",
      "would",
      "think",
      "riches",
      "they",
      "do",
      "possess",
      "stop",
      "worrying",
    ],
    id: "If only the people who worry about their liabilities would think about the riches they do possess, they would stop worrying.",
    author: "Dale Carnegie",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["may", "control", "all", "events", "happen", "decide", "reduced", "by"],
    words: [
      "you",
      "may",
      "not",
      "control",
      "all",
      "the",
      "events",
      "that",
      "happen",
      "to",
      "but",
      "can",
      "decide",
      "be",
      "reduced",
      "by",
      "them",
    ],
    id: "You may not control all the events that happen to you, but you can decide not to be reduced by them.",
    author: "Maya Angelou",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["enlarge", "or", "illustrate", "power", "effect", "set", "candle", "sun"],
    words: [
      "to",
      "enlarge",
      "or",
      "illustrate",
      "this",
      "power",
      "and",
      "effect",
      "of",
      "love",
      "is",
      "set",
      "a",
      "candle",
      "in",
      "the",
      "sun",
    ],
    id: "To enlarge or illustrate this power and effect of love is to set a candle in the sun.",
    author: "Robert Burton",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["whatever", "i", "all", "because", "support", "received", "from", "haryana"],
    words: [
      "whatever",
      "i",
      "am",
      "today",
      "is",
      "all",
      "because",
      "of",
      "the",
      "love",
      "and",
      "support",
      "have",
      "received",
      "from",
      "people",
      "haryana",
    ],
    id: "Whatever I am today is all because of the love and support I have received from the people of Haryana.",
    author: "Sangram Singh",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["matter", "how", "much", "wisdom", "position", "nothing", "tragedy", "india"],
    words: [
      "it",
      "doesn't",
      "matter",
      "how",
      "much",
      "wisdom",
      "you",
      "have",
      "if",
      "don't",
      "position",
      "nothing",
      "that's",
      "the",
      "tragedy",
      "of",
      "india",
    ],
    id: "It doesn't matter how much wisdom you have. If you don't have position, you have nothing. That's the tragedy of India.",
    author: "Rahul Gandhi",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "when",
      "encounter",
      "difficulties",
      "contradictions",
      "break",
      "bend",
      "gentleness",
      "time",
    ],
    words: [
      "when",
      "you",
      "encounter",
      "difficulties",
      "and",
      "contradictions",
      "do",
      "not",
      "try",
      "to",
      "break",
      "them",
      "but",
      "bend",
      "with",
      "gentleness",
      "time",
    ],
    id: "When you encounter difficulties and contradictions, do not try to break them, but bend them with gentleness and time.",
    author: "Saint Francis de Sales",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["great", "feeling", "i", "friend", "many", "through", "my", "movies"],
    words: [
      "it's",
      "a",
      "great",
      "feeling",
      "to",
      "think",
      "that",
      "i",
      "can",
      "be",
      "friend",
      "so",
      "many",
      "people",
      "through",
      "my",
      "movies",
    ],
    id: "It's a great feeling to think that I can be a friend to so many people through my movies.",
    author: "River Phoenix",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["success", "nothing", "gain", "life", "or", "accomplish", "yourself", "others"],
    words: [
      "success",
      "has",
      "nothing",
      "to",
      "do",
      "with",
      "what",
      "you",
      "gain",
      "in",
      "life",
      "or",
      "accomplish",
      "for",
      "yourself",
      "it's",
      "others",
    ],
    id: "Success has nothing to do with what you gain in life or accomplish for yourself. It's what you do for others.",
    author: "Danny Thomas",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "remember",
      "creating",
      "successful",
      "marriage",
      "farming",
      "start",
      "over",
      "morning",
    ],
    words: [
      "remember",
      "that",
      "creating",
      "a",
      "successful",
      "marriage",
      "is",
      "like",
      "farming",
      "you",
      "have",
      "to",
      "start",
      "over",
      "again",
      "every",
      "morning",
    ],
    id: "Remember that creating a successful marriage is like farming: you have to start over again every morning.",
    author: "H. Jackson Brown, Jr.",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["never", "tell", "how", "things", "will", "surprise", "their", "ingenuity"],
    words: [
      "never",
      "tell",
      "people",
      "how",
      "to",
      "do",
      "things",
      "them",
      "what",
      "and",
      "they",
      "will",
      "surprise",
      "you",
      "with",
      "their",
      "ingenuity",
    ],
    id: "Never tell people how to do things. Tell them what to do and they will surprise you with their ingenuity.",
    author: "George S. Patton",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["i'm", "grateful", "my", "fans", "i", "hope", "keep", "doing"],
    words: [
      "i'm",
      "just",
      "so",
      "happy",
      "and",
      "grateful",
      "for",
      "my",
      "fans",
      "i",
      "hope",
      "keep",
      "doing",
      "work",
      "that",
      "they",
      "love",
    ],
    id: "I'm just so happy, and I'm grateful for my fans. I just hope I keep doing work that they love.",
    author: "Jennifer Aniston",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "moderation",
      "inseparable",
      "companion",
      "wisdom",
      "genius",
      "even",
      "nodding",
      "acquaintance",
    ],
    words: [
      "moderation",
      "is",
      "the",
      "inseparable",
      "companion",
      "of",
      "wisdom",
      "but",
      "with",
      "it",
      "genius",
      "has",
      "not",
      "even",
      "a",
      "nodding",
      "acquaintance",
    ],
    id: "Moderation is the inseparable companion of wisdom, but with it genius has not even a nodding acquaintance.",
    author: "Charles Caleb Colton",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["value", "article", "merchandise", "your", "own", "soul", "all", "foolishness"],
    words: [
      "you",
      "know",
      "the",
      "value",
      "of",
      "every",
      "article",
      "merchandise",
      "but",
      "if",
      "don't",
      "your",
      "own",
      "soul",
      "it's",
      "all",
      "foolishness",
    ],
    id: "You know the value of every article of merchandise, but if you don't know the value of your own soul, it's all foolishness.",
    author: "Rumi",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["here", "west", "often", "listening", "their", "leaders", "even", "right"],
    words: [
      "here",
      "in",
      "the",
      "west",
      "people",
      "often",
      "don't",
      "like",
      "listening",
      "to",
      "their",
      "leaders",
      "even",
      "if",
      "they",
      "are",
      "right",
    ],
    id: "Here in the West, people often don't like listening to their leaders, even if they are right.",
    author: "Elon Musk",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "ultimately",
      "sense",
      "camaraderie",
      "friendship",
      "local",
      "core",
      "my",
      "journeys",
    ],
    words: [
      "ultimately",
      "it's",
      "a",
      "sense",
      "of",
      "camaraderie",
      "and",
      "friendship",
      "with",
      "local",
      "people",
      "that",
      "is",
      "core",
      "to",
      "my",
      "journeys",
    ],
    id: "Ultimately, it's a sense of camaraderie and friendship with local people that is core to my journeys.",
    author: "Tim Cope",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["once", "enjoyed", "never", "lose", "all", "deeply", "becomes", "part"],
    words: [
      "what",
      "we",
      "have",
      "once",
      "enjoyed",
      "can",
      "never",
      "lose",
      "all",
      "that",
      "love",
      "deeply",
      "becomes",
      "a",
      "part",
      "of",
      "us",
    ],
    id: "What we have once enjoyed we can never lose. All that we love deeply becomes a part of us.",
    author: "Helen Keller",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["problem", "our", "industry", "patience", "instant", "nba", "stars", "happen"],
    words: [
      "that's",
      "the",
      "problem",
      "with",
      "our",
      "industry",
      "patience",
      "people",
      "want",
      "instant",
      "nba",
      "stars",
      "and",
      "not",
      "going",
      "to",
      "happen",
    ],
    id: "That's the problem with our industry: patience. People want instant NBA stars, and that's not going to happen.",
    author: "Dwane Casey",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["rules", "there", "will", "power", "predominates", "lacking", "one", "shadow"],
    words: [
      "where",
      "love",
      "rules",
      "there",
      "is",
      "no",
      "will",
      "to",
      "power",
      "and",
      "predominates",
      "lacking",
      "the",
      "one",
      "shadow",
      "of",
      "other",
    ],
    id: "Where love rules, there is no will to power; and where power predominates, there love is lacking. The one is the shadow of the other.",
    author: "Carl Jung",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["most", "never", "learned", "one", "main", "aims", "life", "enjoy"],
    words: [
      "most",
      "people",
      "have",
      "never",
      "learned",
      "that",
      "one",
      "of",
      "the",
      "main",
      "aims",
      "in",
      "life",
      "is",
      "to",
      "enjoy",
      "it",
    ],
    id: "Most people have never learned that one of the main aims in life is to enjoy it.",
    author: "Samuel Butler",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["all", "friendship", "its", "base", "mansion", "built", "upon", "sand"],
    words: [
      "all",
      "love",
      "that",
      "has",
      "not",
      "friendship",
      "for",
      "its",
      "base",
      "is",
      "like",
      "a",
      "mansion",
      "built",
      "upon",
      "the",
      "sand",
    ],
    id: "All love that has not friendship for its base, is like a mansion built upon the sand.",
    author: "Ella Wheeler Wilcox",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["begin", "-", "half", "still", "remain", "thou", "wilt", "finished"],
    words: [
      "begin",
      "-",
      "to",
      "is",
      "half",
      "the",
      "work",
      "let",
      "still",
      "remain",
      "again",
      "this",
      "and",
      "thou",
      "wilt",
      "have",
      "finished",
    ],
    id: "Begin - to begin is half the work, let half still remain; again begin this, and thou wilt have finished.",
    author: "Marcus Aurelius",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["when", "asked", "george", "washington", "id", "whipped", "out", "quarter"],
    words: [
      "do",
      "you",
      "think",
      "that",
      "when",
      "they",
      "asked",
      "george",
      "washington",
      "for",
      "id",
      "he",
      "just",
      "whipped",
      "out",
      "a",
      "quarter",
    ],
    id: "Do you think that when they asked George Washington for ID that he just whipped out a quarter?",
    author: "Steven Wright",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["best", "thing", "being", "rich", "freedom", "whatever", "whenever", "suck"],
    words: [
      "the",
      "best",
      "thing",
      "about",
      "being",
      "rich",
      "is",
      "freedom",
      "to",
      "do",
      "whatever",
      "you",
      "want",
      "whenever",
      "it",
      "doesn't",
      "suck",
    ],
    id: "The best thing about being rich is the freedom; freedom to do whatever you want whenever you want. It doesn't suck.",
    author: "Tommy Lee",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "life", "i", "can't", "change", "future", "anything", "past"],
    words: [
      "my",
      "life",
      "is",
      "what",
      "it",
      "and",
      "i",
      "can't",
      "change",
      "can",
      "the",
      "future",
      "but",
      "do",
      "anything",
      "about",
      "past",
    ],
    id: "My life is what it is, and I can't change it. I can change the future, but I can't do anything about the past.",
    author: "Alex Trebek",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["i", "enamoured", "my", "own", "opinions", "disregard", "others", "may"],
    words: [
      "for",
      "i",
      "am",
      "not",
      "so",
      "enamoured",
      "of",
      "my",
      "own",
      "opinions",
      "that",
      "disregard",
      "what",
      "others",
      "may",
      "think",
      "them",
    ],
    id: "For I am not so enamoured of my own opinions that I disregard what others may think of them.",
    author: "Nicolaus Copernicus",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["strange", "business", "haven't", "got", "aim", "strongly", "full", "troubles"],
    words: [
      "money",
      "is",
      "a",
      "strange",
      "business",
      "people",
      "who",
      "haven't",
      "got",
      "it",
      "aim",
      "strongly",
      "have",
      "are",
      "full",
      "of",
      "troubles",
    ],
    id: "Money is a strange business. People who haven't got it aim it strongly. People who have are full of troubles.",
    author: "Ayrton Senna",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["my", "travels", "been", "chance", "place", "already", "exists", "imagination"],
    words: [
      "for",
      "me",
      "my",
      "travels",
      "have",
      "been",
      "the",
      "chance",
      "to",
      "go",
      "a",
      "place",
      "that",
      "already",
      "exists",
      "in",
      "imagination",
    ],
    id: "For me, my travels have been the chance to go to a place that already exists in my imagination.",
    author: "Hugo Pratt",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["treat", "those", "goodness", "also", "thus", "attained", "honest", "honesty"],
    words: [
      "treat",
      "those",
      "who",
      "are",
      "good",
      "with",
      "goodness",
      "and",
      "also",
      "not",
      "thus",
      "is",
      "attained",
      "be",
      "honest",
      "to",
      "honesty",
    ],
    id: "Treat those who are good with goodness, and also treat those who are not good with goodness. Thus goodness is attained. Be honest to those who are honest, and be also honest to those who are not honest. Thus honesty is attained.",
    author: "Lao Tzu",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["worried", "should", "into", "politics", "real", "actors", "take", "chances"],
    words: [
      "if",
      "you",
      "are",
      "worried",
      "about",
      "what",
      "people",
      "think",
      "of",
      "should",
      "go",
      "into",
      "politics",
      "real",
      "actors",
      "take",
      "chances",
    ],
    id: "If you are worried about what people think of you, you should go into politics. Real actors take chances.",
    author: "Rob Lowe",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["one", "life's", "terrible", "truths", "women", "guys", "seem", "doing"],
    words: [
      "one",
      "of",
      "life's",
      "terrible",
      "truths",
      "is",
      "that",
      "women",
      "like",
      "guys",
      "who",
      "seem",
      "to",
      "know",
      "what",
      "they're",
      "doing",
    ],
    id: "One of life's terrible truths is that women like guys who seem to know what they're doing.",
    author: "Anthony Bourdain",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "important",
      "thing",
      "men",
      "should",
      "purpose",
      "life",
      "something",
      "useful",
    ],
    words: [
      "the",
      "important",
      "thing",
      "is",
      "that",
      "men",
      "should",
      "have",
      "a",
      "purpose",
      "in",
      "life",
      "it",
      "be",
      "something",
      "useful",
      "good",
    ],
    id: "The important thing is that men should have a purpose in life. It should be something useful, something good.",
    author: "Dalai Lama",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["fortunately", "public", "company", "-", "private", "group", "companies", "i"],
    words: [
      "fortunately",
      "we're",
      "not",
      "a",
      "public",
      "company",
      "-",
      "private",
      "group",
      "of",
      "companies",
      "and",
      "i",
      "can",
      "do",
      "what",
      "want",
    ],
    id: "Fortunately we're not a public company - we're a private group of companies, and I can do what I want.",
    author: "Richard Branson",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "proof",
      "poet",
      "his",
      "country",
      "absorbs",
      "as",
      "affectionately",
      "absorbed",
    ],
    words: [
      "the",
      "proof",
      "of",
      "a",
      "poet",
      "is",
      "that",
      "his",
      "country",
      "absorbs",
      "him",
      "as",
      "affectionately",
      "he",
      "has",
      "absorbed",
      "it",
    ],
    id: "The proof of a poet is that his country absorbs him as affectionately as he has absorbed it.",
    author: "Walt Whitman",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "patience",
      "was",
      "something",
      "came",
      "naturally",
      "cooking",
      "quintessential",
      "skill",
    ],
    words: [
      "patience",
      "was",
      "not",
      "something",
      "that",
      "came",
      "naturally",
      "to",
      "me",
      "but",
      "in",
      "cooking",
      "it",
      "is",
      "the",
      "quintessential",
      "skill",
    ],
    id: "Patience was not something that came naturally to me, but in cooking it is the quintessential skill.",
    author: "Gail Simmons",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["one", "reasons", "stop", "become", "less", "willing", "risk", "failure"],
    words: [
      "one",
      "of",
      "the",
      "reasons",
      "people",
      "stop",
      "learning",
      "is",
      "that",
      "they",
      "become",
      "less",
      "and",
      "willing",
      "to",
      "risk",
      "failure",
    ],
    id: "One of the reasons people stop learning is that they become less and less willing to risk failure.",
    author: "John W. Gardner",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  {
    row_new_words: ["appears", "law", "cannot", "deep", "sympathy", "both", "man", "nature"],
    words: [
      "it",
      "appears",
      "to",
      "be",
      "a",
      "law",
      "that",
      "you",
      "cannot",
      "have",
      "deep",
      "sympathy",
      "with",
      "both",
      "man",
      "and",
      "nature",
    ],
    id: "It appears to be a law that you cannot have a deep sympathy with both man and nature.",
    author: "Henry David Thoreau",
    grammar: null,
    word_count: 17,
    all_intersection_count: 9,
    new_words_count: 8,
  },
  // ...quotes2,
  // ...quotes3,
  // ...quotes4,
  // ...quotes5,
  // ...quotes6,
  // ...quotes7,
  // ...quotes8,
  // ...quotes9,
];

export default quotesData;
