import CloseIcon from "@mui/icons-material/Close";
import { Drawer, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import usePersonalLesson from "../../../hooks/usePersonalLesson";
import { useLessonsStore } from "../../../store/lessonsStore";
import LinearProgress from "../../User/LinearProgress";
import ExampleSentenceItem from "../../Word/WordTranslationSection/ExampleSentenceItem";
import Box from "../Box";
import Button from "../Button";
import FullScreenLoader from "../FullScreenLoader";
import HStack from "../HStack";
import IconButton from "../IconButton";
import { DifferenceWithSentence, Word } from "../../../types/supabase";
import supabaseClient from "../../../common/supabaseClient";
import getSentenceWordMatch from "../../Word/WordTranslationSection/getSentenceWordMatch";
import getSentenceWords from "../../../common/getSentenceWords";
import firstLetterUppercase from "../../../common/firstLetterUppercase";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import useLessonWords from "../../../hooks/useLessonWords";

interface SentenceTranslationSectionProps {
  sliderId: string;
  lessonId: string;
  partSentencesIds?: number[];
  lessonType: "difference" | "personal";
  differenceSentences?: DifferenceWithSentence[];
}

export const SentenceTranslationSection: React.FC<
  SentenceTranslationSectionProps
> = ({
  sliderId,
  lessonId,
  partSentencesIds,
  lessonType,
  differenceSentences,
}) => {
  const [slider, setSlider] = useState<Slider | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const sliderIndex = useLessonsStore(
    (state) => state.englishGrammarCourseSliderPositions[sliderId] || 0,
  );
  const setSliderIndex = useLessonsStore(
    (state) => state.setEnglishGrammarCourseSliderPositions,
  );

  const {
    personalLessonsSentences,
    setPersonalLessonsSentences,
    fetchPersonalSentences,
  } = usePersonalLesson(lessonId, partSentencesIds);
  // console.log(
  //   "🚀 ~ personalLessonsSentences:",
  //   personalLessonsSentences,
  //   lessonId,
  //   partSentencesIds,
  // );

  const { lessonWords } = useLessonWords({
    sentences: personalLessonsSentences?.map((s) => s.en) || [],
    trigger: isOpen,
  });

  // const personalLessonsSentencesWords = useMemo(() => {
  //   if (!personalLessonsSentences) return;
  //   const words = personalLessonsSentences.map((s) => getSentenceWords(s.en));

  //   const uniqueWords = Array.from(new Set(words.flat())).map((w) =>
  //     w.toLowerCase(),
  //   );
  //   const uniqueWordsWithFirstUppercase = Array.from(new Set(words.flat())).map(
  //     (w) => firstLetterUppercase(w),
  //   );

  //   return [...uniqueWords, ...uniqueWordsWithFirstUppercase];
  // }, [personalLessonsSentences]);

  // const [lessonWords, setLessonWords] = useState<Word[]>([]);

  // useMountedWaitAsyncEffect(async () => {
  //   if (!personalLessonsSentencesWords) return;

  //   const { data } = await supabaseClient
  //     .from("words")
  //     .select()
  //     .in("name", personalLessonsSentencesWords)
  //     .returns<Word[]>();

  //   if (data) setLessonWords(data);
  // }, [isOpen, personalLessonsSentencesWords]);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const handleSlideChange = async (index: number) => {
    setSliderIndex(sliderId, index);
  };

  const [isOpenEnd, setIsOpenEnd] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (isOpen) {
      timeout = setTimeout(() => {
        setIsOpenEnd(true);
      }, 300);
    } else {
      setIsOpenEnd(false);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isOpen]);

  const [resetingSlider, setResetingSlider] = useState(false);
  const resetSlider = () => {
    setResetingSlider(true);
    setSlider(null);
    handleSlideChange(0);
    setTimeout(() => {
      setSlider(null);
      setResetingSlider(false);
    }, 200);
  };

  if (lessonType === "difference") {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={async () => {
              setIsOpen(true);
              if (!differenceSentences?.length) {
                await fetchPersonalSentences();
              }
              slider?.slickGoTo(sliderIndex);
            }}
          >
            Переклад речень ({differenceSentences?.length})
          </Button>
          <Drawer open={isOpen} onClose={handleDrawerClose} anchor="bottom">
            {differenceSentences && (
              <LinearProgress
                elementsCount={differenceSentences.length - 1}
                currentValue={sliderIndex || 0}
              />
            )}
            <Box
              sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}
              px={2}
            >
              {(!isOpenEnd || resetingSlider) && <FullScreenLoader />}
              {differenceSentences && (
                <Typography variant="caption" p={2}>
                  {differenceSentences.length !== sliderIndex + 1 &&
                    `${sliderIndex + 1} / ${differenceSentences.length}`}
                </Typography>
              )}
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 8,
                  zIndex: 2,
                }}
              >
                <CloseIcon />
              </IconButton>
              {!resetingSlider && differenceSentences && (
                <Slider
                  lazyLoad="ondemand"
                  infinite={false}
                  initialSlide={sliderIndex}
                  ref={(slider) => setSlider(slider)}
                  swipe={false}
                  afterChange={handleSlideChange}
                >
                  {differenceSentences.map((sentence, index) => (
                    <ExampleSentenceItem
                      en={sentence.en.en}
                      key={index}
                      audioSmall={false}
                      slideIndex={index}
                      sliderId={sliderId}
                      meaning={sentence.en.meaning}
                      fullScreen
                      audioUrl={sentence.en.en_audio_url}
                      ua={sentence.en.ua}
                      isTranslation
                      lessonWords={[]}
                    >
                      <HStack>
                        <Button
                          variant="outlined"
                          color="warning"
                          onClick={() => {
                            const newIndex = sliderIndex + 9;
                            const updatedSentences = [...differenceSentences];
                            if (newIndex < differenceSentences.length) {
                              updatedSentences.splice(
                                newIndex,
                                0,
                                updatedSentences[sliderIndex],
                              );
                            } else {
                              updatedSentences.push(
                                updatedSentences[sliderIndex],
                              );
                            }
                            // setPersonalLessonsSentences(
                            //   lessonId,
                            //   updatedSentences,
                            // );
                            slider?.slickNext();
                          }}
                        >
                          складно
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={async () => {
                            if (
                              sliderIndex ===
                              differenceSentences.length - 1
                            ) {
                              resetSlider();
                            } else {
                              slider?.slickNext();
                            }

                            if (!authUser) return;

                            const { count: wordExists } = await supabaseClient
                              .from("user-sentences")
                              .select("*", { count: "exact", head: true })
                              .eq("id", `${authUser.uuid}-${sentence.en.en}`);

                            if (!wordExists) {
                              const result = await supabaseClient.rpc(
                                "add_user_sentence",
                                {
                                  sentence: sentence.en.en,
                                },
                              );
                            }
                          }}
                        >
                          легко
                        </Button>
                      </HStack>
                    </ExampleSentenceItem>
                  ))}
                </Slider>
              )}
            </Box>
          </Drawer>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={async () => {
            setIsOpen(true);
            if (!personalLessonsSentences?.length) {
              await fetchPersonalSentences();
            }
            slider?.slickGoTo(sliderIndex);
          }}
        >
          Переклад речень ({partSentencesIds?.length})
        </Button>
        <Drawer open={isOpen} onClose={handleDrawerClose} anchor="bottom">
          {personalLessonsSentences && (
            <LinearProgress
              elementsCount={personalLessonsSentences.length - 1}
              currentValue={sliderIndex || 0}
            />
          )}
          <Box
            sx={{ height: "100vh", width: "100vw", overflow: "hidden" }}
            px={2}
          >
            {(!isOpenEnd || resetingSlider) && <FullScreenLoader />}
            {personalLessonsSentences && (
              <Typography variant="caption" p={2}>
                {personalLessonsSentences.length !== sliderIndex + 1 &&
                  `${sliderIndex + 1} / ${personalLessonsSentences.length}`}
              </Typography>
            )}
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 8,
                zIndex: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
            {!resetingSlider && personalLessonsSentences && (
              <Slider
                lazyLoad="ondemand"
                infinite={false}
                initialSlide={sliderIndex}
                ref={(slider) => setSlider(slider)}
                swipe={false}
                afterChange={handleSlideChange}
              >
                {personalLessonsSentences.map((sentence, index) => {
                  return (
                    <ExampleSentenceItem
                      en={sentence.en}
                      key={index}
                      audioSmall={false}
                      slideIndex={index}
                      sliderId={sliderId}
                      fullScreen
                      audioUrl={sentence.en_audio_url}
                      ua={sentence.ua}
                      isTranslation
                      lessonWords={lessonWords}
                    >
                      <HStack>
                        <Button
                          variant="outlined"
                          color="warning"
                          onClick={() => {
                            const newIndex = sliderIndex + 9;
                            const updatedSentences = [
                              ...personalLessonsSentences,
                            ];
                            if (newIndex < personalLessonsSentences.length) {
                              updatedSentences.splice(
                                newIndex,
                                0,
                                updatedSentences[sliderIndex],
                              );
                            } else {
                              updatedSentences.push(
                                updatedSentences[sliderIndex],
                              );
                            }
                            setPersonalLessonsSentences(
                              lessonId,
                              updatedSentences,
                            );
                            slider?.slickNext();
                          }}
                        >
                          складно
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            if (
                              sliderIndex ===
                              personalLessonsSentences.length - 1
                            ) {
                              resetSlider();
                            } else {
                              slider?.slickNext();
                            }
                          }}
                        >
                          легко
                        </Button>
                      </HStack>
                    </ExampleSentenceItem>
                  );
                })}
              </Slider>
            )}
          </Box>
        </Drawer>
      </Grid>
    </Grid>
  );
};
