import { Typography } from "@mui/material";
import { FoodTrackingRow } from "../../../types/supabase";
import { useEffect, useState } from "react";

const FoodItemRecord: React.FC<{ item: FoodTrackingRow; isActive: boolean; onContextMenu: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: any) => void }> = ({ item, isActive, onContextMenu }) => {
  // const [isActive, setIsActive] = useState(false);

  // useEffect(() => {
  //   setIsActive(false);
  // }, [item]);
  console.log("🚀 ~ item:", item);

  return (
    <>
      <Typography
        fontSize={14}
        onContextMenu={(event) => {
          onContextMenu(event, item);
          // setIsActive(true);
        }}
        color={isActive ? "primary" : "black"}
        sx={
          {
            // "&:hover": {
            //   backgroundColor: "#4aa805",
            //   color: "white",
            // },
            // "&:active": {
            //   backgroundColor: "#4aa805",
            //   color: "white",
            // },
          }
        }
      >
        {item.item} - {item.weight}
        {item.weight_type}
      </Typography>
    </>
  );
};

export default FoodItemRecord;
