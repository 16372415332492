import { Link, Outlet, useLocation } from "react-router-dom";
import Box from "../../Unknown/Box";
import Movie1Src from "./books-image/movie-1.jpg";
import Movie2Src from "./books-image/movie-2.jpg";
import Movie3Src from "./books-image/movie-3.jpg";
import Movie4Src from "./books-image/movie-4.jpg";
import Movie5Src from "./books-image/movie-5.jpg";
import Movie6Src from "./books-image/movie-6.jpg";
import Movie7Src from "./books-image/movie-7.jpg";
import { PropsWithChildren } from "react";
import { Typography } from "@mui/material";

const MovieCard: React.FC<
  PropsWithChildren & {
    available?: boolean;
  }
> = ({ children, available }) => {
  return (
    <Box
      position="relative"
      maxWidth={200}
      sx={{
        pointerEvents: available ? "auto" : "none",
      }}
    >
      {!available && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgba(255, 255, 255, 0.6)",
              color: "#e03131",
              display: "block",
              width: "100%",
              textAlign: "center",
              fontSize: 25,
              fontWeight: "bold",
            }}
          >
            Not available
          </Typography>
        </Box>
      )}
      {children}
    </Box>
  );
};

const MovieTitle: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      color="white"
      p={3}
      fontFamily="Rubik"
      overflow="hidden"
      sx={{
        backdropFilter: "blur(4px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Typography
        sx={{
          zIndex: 2,
          position: "relative",
          fontFamily: "Rubik",
          fontWeight: "bold",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const HarryPotterMovieCards = () => {
  return (
    <>
      <MovieCard available>
        <Link to="/reading/harry-potter/harry-potter-and-the-sorcerer-stone">
          <Box
            component="img"
            sx={{
              display: "inline-block",
              objectFit: "contained",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
            }}
            src={Movie1Src}
          />
          <MovieTitle text="Harry Potter and the Philosopher's Stone" />
        </Link>
      </MovieCard>
      <MovieCard>
        <Link to="/reading/harry-potter/chamber-of-secrets">
          <Box
            component="img"
            sx={{
              display: "inline-block",
              objectFit: "contained",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
            }}
            src={Movie2Src}
          />
          <MovieTitle text="Harry Potter and the Chamber of Secrets" />
        </Link>
      </MovieCard>
      <MovieCard>
        <Link to="/reading/harry-potter/prisoner-of-azkaban">
          <Box
            component="img"
            sx={{
              display: "inline-block",
              objectFit: "contained",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
            }}
            src={Movie3Src}
          />
          <MovieTitle text="Harry Potter and the Prisoner of Azkaban" />
        </Link>
      </MovieCard>
      <MovieCard>
        <Link to="/reading/harry-potter/goblet-of-fire">
          <Box
            component="img"
            sx={{
              display: "inline-block",
              objectFit: "contained",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
            }}
            src={Movie4Src}
          />
          <MovieTitle text="Harry Potter and the Goblet of Fire" />
        </Link>
      </MovieCard>
      <MovieCard>
        <Link to="/reading/harry-potter/order-of-the-phoenix">
          <Box
            component="img"
            sx={{
              display: "inline-block",
              objectFit: "contained",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
            }}
            src={Movie5Src}
          />
          <MovieTitle text="Harry Potter and the Order of the Phoenix" />
        </Link>
      </MovieCard>
      <MovieCard>
        <Link to="/reading/harry-potter/half-blood-prince">
          <Box
            component="img"
            sx={{
              display: "inline-block",
              objectFit: "contained",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
            }}
            src={Movie6Src}
          />
          <MovieTitle text="Harry Potter and the Half-Blood Prince" />
        </Link>
      </MovieCard>
      <MovieCard>
        <Link to="/reading/harry-potter/deathly-hallows">
          <Box
            component="img"
            sx={{
              display: "inline-block",
              objectFit: "contained",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
            }}
            src={Movie7Src}
          />
          <MovieTitle text="Harry Potter and the Deathly Hallows" />
        </Link>
      </MovieCard>
    </>
  );
};

const HarryPotterScreen = () => {
  const params = useLocation();

  return (
    <Box>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={3}
        my={5}
        sx={{
          height: params.pathname !== "/reading/harry-potter" ? 0 : "auto",
          overflow: "hidden",
        }}
      >
        {/* {params.pathname === "/reading/harry-potter" && (
          <HarryPotterMovieCards />
        )} */}
        <HarryPotterMovieCards />
        {/* <Link to="">Harry Potter and the Deathly Hallows: Part 2</Link> */}
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default HarryPotterScreen;
