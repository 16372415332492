import LoadingButton from "@mui/lab/LoadingButton";
import { addSeconds } from "date-fns";
import { useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import Box from "../../Unknown/Box";
import HStack from "../../Unknown/HStack";
import Typography from "../../Unknown/Typography";

const UserNewLessonWords: React.FC<{
  words: string[];
  userUuid: string;
  onNewLessonWordsInserted: () => void;
}> = ({ words, userUuid, onNewLessonWordsInserted }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    // const lessonWordsMap = lessonWords.reduce<Record<string, Word>>(
    //   (prev, curr) => {
    //     return {
    //       ...prev,
    //       [curr.name]: curr,
    //     };
    //   },
    //   {},
    // );

    await supabaseClient.from("user-words").upsert(
      words.map((word, i) => {
        return {
          id: `${userUuid}-${word}`,
          word,
          user: userUuid,
          repeat: addSeconds(new Date(), i * 2).toISOString(),
        };
      }),
    );

    onNewLessonWordsInserted();

    setLoading(false);
  };

  if (!words.length) return null;

  return (
    <Box>
      <Typography variant="h3">USER new lesson words:</Typography>
      <HStack>
        {words.map((word) => {
          return (
            <Box key={word}>
              <Typography>{word}</Typography>
            </Box>
          );
        })}
      </HStack>
      <LoadingButton
        variant="contained"
        color="info"
        loading={loading}
        onClick={handleSubmit}
      >
        Add USER new words
      </LoadingButton>
    </Box>
  );
};

export default UserNewLessonWords;
