import { Box, Tooltip, styled } from "@mui/material";
import firstLetterUppercase from "../../../common/firstLetterUppercase";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import { Word } from "../../../types/supabase";
import WordAudio from "../../Audio/WordAudio";
import Typography from "../../Unknown/Typography";
import { useMemo } from "react";

const CustomTooltip = styled(({ className, ...props }: any) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: 20,
  },
});

const PersonalEnPronounce = ({ sentence, lessonWords }: { sentence: string; lessonWords: Word[] }) => {
  const wordTranscriptionMap = useMemo(() => {
    if (!lessonWords.length) return {};

    return lessonWords.reduce<
      Record<
        string,
        {
          transcription: string;
          translation: string;
          url: string;
        }
      >
    >((acc, word) => {
      return {
        ...acc,
        [word.name]: {
          transcription: word.transcription,
          translation: word.translation,
          url: word.audio_url,
        },
      };
    }, {});
  }, [lessonWords]);

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
      {sentence.split(" ").map((w, index) => {
        const wordProps = wordTranscriptionMap[removePunctuationFromString(w.toLowerCase())] || wordTranscriptionMap[removePunctuationFromString(firstLetterUppercase(w))];

        const sentence = (
          <Typography
            key={index}
            component="span"
            fontStyle="italic"
            // fontSize={fullScreen ? 30 : 20}
            fontSize={30}
            color="secondary.dark"
            fontWeight={500}
            textAlign="center"
            sx={{
              padding: 1,
              lineHeight: 2,
              position: "relative",
            }}
          >
            {w}
          </Typography>
        );

        const pronounceBlock = (
          <>
            {wordProps?.transcription && (
              <Typography
                component="span"
                fontSize={15}
                fontStyle="initial"
                color="gray"
                fontWeight={400}
                fontFamily="Roboto"
                textAlign="center"
                sx={{
                  paddingLeft: 1,
                  paddingRight: 1,
                  position: "absolute",
                  bottom: -5,
                  lineHeight: 1,
                  left: "50%",
                  transform: "translateX(-50%)",
                  whiteSpace: "nowrap",
                }}
              >
                {wordProps.transcription}
                <WordAudio url={wordProps.url} small boxHide />
              </Typography>
            )}
          </>
        );

        if (wordProps?.translation) {
          return (
            <Box position="relative" component="span" pb={1}>
              <CustomTooltip title={wordProps.translation} placement="top" enterTouchDelay={0} enterDelay={0} arrow leaveTouchDelay={3000}>
                {sentence}
              </CustomTooltip>
              {pronounceBlock}
            </Box>
          );
        }

        return (
          <Box position="relative" component="span">
            {sentence}
            <Box position="relative" component="span">
              {pronounceBlock}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default PersonalEnPronounce;
