const greetings = ["Супер!", "Фантастично!", "Браво!", "Неймовірно!", "Ти молодець!", "Прекрасна робота!", "Чудово!", "Вітаємо!", "Так тримати!", "Класно!", "Вражаєш!", "Неймовірна робота!", "Ти справжня зірка!", "Здорово!", "Вражаюче!", "Велика робота!", "Відмінно!", "Високий рівень!", "Чудова робота!", "Аплодисменти!", "Вражаюча робота!", "Браво, молодець!", "Ти вражаєш!", "Феноменально!", "Продовжуй в тому ж дусі!", "Ти робиш дива!", "Браво, мій друже!", "Вражаюча робота!", "Ти мовний майстер!"];

const getRandomGreetings = (additionalText?: string) => {
  const random = greetings[Math.floor(Math.random() * greetings.length)];

  if (additionalText) return `${random} ${additionalText}`;

  return random;
};

export default getRandomGreetings;
