import { Button } from "@mui/material";
import { memo } from "react";

interface TranslateButtonProps {
  isVisible: boolean;
  onTranslate: () => void;
}

const TranslateButton = ({ isVisible, onTranslate }: TranslateButtonProps) => {
  if (!isVisible) return null;

  return (
    <Button variant="contained" onClick={onTranslate}>
      Переклад
    </Button>
  );
};

export default memo(TranslateButton);
