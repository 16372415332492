import { Box, Button, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import HStack from "../../Unknown/HStack";
import supabaseClient from "../../../common/supabaseClient";
import getSkipWords from "../../../common/getSkipWords";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";

interface DisplayToSkipWordsProps {
  words: string[];
  newWords: string[];
  onUpdateSkipWords?: (wordsToSkip: string[]) => void;
}

interface DisplayToSkipWordsWordProps {
  word: string;
  toSkip: boolean;
  onToggle: (word: string) => void;
}

const DisplayToSkipWordsWord: React.FC<DisplayToSkipWordsWordProps> = ({
  word,
  toSkip,
  onToggle,
}) => {
  return (
    <Button
      variant={toSkip ? "contained" : "text"}
      size="small"
      onClick={() => {
        onToggle(word);
      }}
    >
      {word}
    </Button>
  );
};

const DisplayToSkipWords: React.FC<DisplayToSkipWordsProps> = ({
  words,
  newWords,
  onUpdateSkipWords,
}) => {
  const [wordsToSkip, setWordsToSkip] = useState<string[]>([]);

  useMountedWaitAsyncEffect(async () => {
    const words = await getSkipWords(newWords);
    if (onUpdateSkipWords && words.length) {
      onUpdateSkipWords(words);
    }
  }, [newWords]);

  const handleOnToggle = (word: string) => {
    if (wordsToSkip.includes(word)) {
      setWordsToSkip((prev) => prev.filter((w) => w !== word));
    } else {
      setWordsToSkip((prev) => [...prev, word]);
    }
  };

  const handleAddSckipWords = async () => {
    await supabaseClient
      .from("skip-words")
      .insert(wordsToSkip.map((word) => ({ id: word })));

    if (onUpdateSkipWords) onUpdateSkipWords(wordsToSkip);
  };

  return (
    <>
      {Boolean(words) && <Typography>Lesson words: {words.length}</Typography>}
      {Boolean(newWords.length) && (
        <Box mb={5}>
          <Typography>New lesson words:</Typography>
          <HStack>
            {newWords.map((w) => (
              <DisplayToSkipWordsWord
                key={w}
                word={w}
                toSkip={wordsToSkip.includes(w)}
                onToggle={handleOnToggle}
              />
            ))}
          </HStack>
        </Box>
      )}
      {Boolean(wordsToSkip.length) && (
        <Button variant="outlined" onClick={handleAddSckipWords}>
          Add skip words
        </Button>
      )}
    </>
  );
};

export default DisplayToSkipWords;
