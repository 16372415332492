import { Box, IconButton, Tooltip } from "@mui/material";
import { memo, useMemo } from "react";

export enum HintType {
  sentence = "sentence",
  word = "word",
}

interface HintProps {
  text: string;
  type: HintType;
}

const Hint = ({ text, type }: HintProps) => {
  const hint = useMemo(() => {
    if (type === HintType.sentence) {
      const [first, second, third] = text.split(" ");
      if (!second) return `${first.slice(3)}...`;
      if (!third) return `${first}...`;
      return `${first} ${second}...`;
    }

    const length = text.length > 2 ? 2 : 1;
    return `${text.slice(0, length)}...`;
  }, [text]);

  return (
    <Box position="absolute" top={20} right={20}>
      <Tooltip
        title={hint}
        placement="left"
        enterTouchDelay={0}
        leaveTouchDelay={4000}
      >
        <IconButton>🔍</IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(Hint);
