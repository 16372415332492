import {
  Badge,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import ModalCloseButton from "../../../ModalCloseButton";
import useGlobalWords from "../../../../hooks/getGlobalWords";
import WordAudio from "../../../../Audio/WordAudio";
import VStack from "../../../VStack";
import WordTranscription from "../../../../Word/WordTranscription";
import useLessonWords from "../../../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import { fullScreenModalStyle } from "../../../../../common/constants";
import AccentStrong from "../../../../Description/ModuleDescription/utils/AccentString";
import AccentWord from "../../../../Difference/components/AccentWord";
import removePunctuationFromString from "../../../../../common/removePunctuationFromString";

type CreateDataProp = {
  title?: any;
  en: any;
  lesson: number;
};

function createData(item1: CreateDataProp, item2: CreateDataProp, item3: CreateDataProp) {
  return {
    item1,
    item2,
    item3,
  };
}

const rows = (lessonNumber: number) => [
  createData(
    {
      title: "about",
      en: (
        <Typography>
          <strong>about</strong>
        </Typography>
      ),
      lesson: 4,
    },
    {
      en: <Typography>think about</Typography>,
      lesson: 4,
    },
    {
      en: (
        <Typography>
          What do you <strong>think about</strong> it?
        </Typography>
      ),
      lesson: 4,
    },
  ),
  createData(
    {
      en: "",
      lesson: 4,
    },
    {
      en: <Typography>worry about</Typography>,
      lesson: 4,
    },
    {
      en: (
        <Typography>
          Don't <strong>worry about</strong> it.
        </Typography>
      ),
      lesson: 4,
    },
  ),
  createData(
    {
      en: "",
      lesson: 4,
    },
    {
      en: <Typography>care about</Typography>,
      lesson: 4,
    },
    {
      en: (
        <Typography>
          Do you <strong>care about</strong> what other people think?
        </Typography>
      ),
      lesson: 4,
    },
  ),
  createData(
    {
      en: "",
      lesson: 10,
    },
    {
      en: <Typography>tell about</Typography>,
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>
            <strong>Tell</strong> me everything <strong>about</strong> him.
          </Typography>
          <Typography>
            She doesn't want to <strong>tell</strong> me <strong>about</strong> that.
          </Typography>
        </>
      ),
      lesson: 10,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>of</strong>
        </Typography>
      ),
      lesson: 5,
    },
    {
      en: <Typography>cup of tea</Typography>,
      lesson: 5,
    },
    {
      en: (
        <Typography>
          Please give me a <strong>cup of tea</strong>.
        </Typography>
      ),
      lesson: 5,
    },
  ),
  createData(
    {
      en: "",
      lesson: 5,
    },
    {
      en: <Typography>cup of coffee</Typography>,
      lesson: 5,
    },
    {
      en: (
        <Typography>
          Do you want a <strong>cup of coffee</strong>?
        </Typography>
      ),
      lesson: 5,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>to say</strong>
        </Typography>
      ),
      lesson: 5,
    },
    {
      en: <Typography>to say hello</Typography>,
      lesson: 5,
    },
    {
      en: (
        <Typography>
          I want <strong>to say hello</strong> to the parrot.
        </Typography>
      ),
      lesson: 5,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>to let</strong>
        </Typography>
      ),
      lesson: 6,
    },
    {
      en: <Typography>let go</Typography>,
      lesson: 6,
    },
    {
      en: (
        <Typography>
          <strong>Let</strong> it <strong>go.</strong> / <strong>Let go</strong> of the rope.
        </Typography>
      ),
      lesson: 6,
    },
  ),
  createData(
    {
      en: "",
      lesson: 6,
    },
    {
      en: <Typography>let's go</Typography>,
      lesson: 6,
    },
    {
      en: (
        <Typography>
          <strong>Let's go</strong> to the zoo to see the animals.
        </Typography>
      ),
      lesson: 6,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>to climb</strong>
        </Typography>
      ),
      lesson: 7,
    },
    {
      en: <Typography>climb a tree</Typography>,
      lesson: 7,
    },
    {
      en: (
        <Typography>
          A monkey can <strong>climb</strong> a tree, but a goat can't.
        </Typography>
      ),
      lesson: 7,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>thank</strong>
        </Typography>
      ),
      lesson: 9,
    },
    {
      en: (
        <>
          <Typography>thank for</Typography>
          <Typography variant="caption">дякувати за</Typography>
        </>
      ),
      lesson: 9,
    },
    {
      en: (
        <>
          <Typography>
            <strong>Thank</strong> you <strong>for</strong> the tea.
          </Typography>
          <Typography>
            Please <strong>thank</strong> him <strong>for</strong> me.
          </Typography>
        </>
      ),
      lesson: 9,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>alright</strong>
        </Typography>
      ),
      lesson: 9,
    },
    {
      en: (
        <>
          <Typography>it's alright</Typography>
          <Typography variant="caption">нічого страшного / все гаразд</Typography>
        </>
      ),
      lesson: 9,
    },
    {
      en: (
        <>
          <Typography>
            <strong>It's alright</strong> if you can't play the violin.
          </Typography>
        </>
      ),
      lesson: 9,
    },
  ),
  createData(
    {
      en: "",
      lesson: 9,
    },
    {
      en: (
        <>
          <Typography>that's alright</Typography>
          <Typography variant="caption">нічого страшного / все гаразд</Typography>
        </>
      ),
      lesson: 9,
    },
    {
      en: (
        <>
          <Typography>
            Sorry. I can't do that. - <strong>That's alright.</strong>
          </Typography>
        </>
      ),
      lesson: 9,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>to listen</strong>
        </Typography>
      ),
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>to listen to</Typography>
          <Typography variant="caption">слухати (когось/щось)</Typography>
        </>
      ),
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>
            Can you <strong>listen to</strong> me?
          </Typography>
          <Typography>
            I'm <strong>listening</strong> to a podcast.
          </Typography>
        </>
      ),
      lesson: 10,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>to wait</strong>
        </Typography>
      ),
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>wait for</Typography>
          <Typography variant="caption">чекати на (когось/щось)</Typography>
        </>
      ),
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>
            Please <strong>wait for</strong> me.
          </Typography>
          <Typography>
            Let's <strong>wait for</strong> him.
          </Typography>
        </>
      ),
      lesson: 10,
    },
  ),
  createData(
    {
      en: "",
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>can't wait</Typography>
          <Typography variant="caption">неможу дочекатися</Typography>
        </>
      ),
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>
            I <strong>can't wait</strong> to see you again.
          </Typography>
          <Typography>
            She <strong>can't wait</strong> to go to school.
          </Typography>
        </>
      ),
      lesson: 10,
    },
  ),
  createData(
    {
      en: "",
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>wait a moment</Typography>
          <Typography variant="caption">зачекай / зажди</Typography>
        </>
      ),
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>
            Can you <strong>wait a moment</strong>, please?
          </Typography>
          <Typography>
            <strong>Wait a moment!</strong> I'm thinking.
          </Typography>
        </>
      ),
      lesson: 10,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>to see</strong>
        </Typography>
      ),
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>see you</Typography>
          <Typography>see you again</Typography>
          <Typography variant="caption">зустрінемося / побачимося</Typography>
        </>
      ),
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>
            <strong>See you!</strong>
          </Typography>
          <Typography>
            <strong>See you again.</strong>
          </Typography>
          <Typography>
            <strong>See you again</strong> tomorrow.
          </Typography>
        </>
      ),
      lesson: 10,
    },
  ),
  createData(
    {
      en: (
        <Typography>
          <strong>to ask</strong>
        </Typography>
      ),
      lesson: 10,
    },
    {
      en: <Typography>to ask</Typography>,
      lesson: 10,
    },
    {
      en: (
        <Typography>
          She wants to <strong>ask</strong> something.
        </Typography>
      ),
      lesson: 10,
    },
  ),
  createData(
    {
      en: "",
      lesson: 81,
    },
    {
      en: <Typography>to ask for, використовується з грошима</Typography>,
      lesson: 81,
      // https://www.youtube.com/watch?v=CSZQNa0AM2E
    },
    {
      en: (
        <Typography>
          She wants to <strong>ask</strong> you <strong>for</strong> something.
        </Typography>
      ),
      lesson: 81,
    },
  ),
  createData(
    {
      en: "",
      lesson: 10,
    },
    {
      en: <Typography>to ask about</Typography>,
      lesson: 10,
    },
    {
      en: (
        <Typography>
          I want to <strong>ask</strong> you <strong>about</strong> that thing.
        </Typography>
      ),
      lesson: 10,
    },
  ),
  createData(
    {
      en: "",
      lesson: 10,
    },
    {
      en: <Typography>to ask to</Typography>,
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>
            I want to <strong>ask</strong> you <strong>to</strong> do that today.
          </Typography>
          <Typography>
            I want to <strong>ask</strong> <strong>to</strong> do that.
          </Typography>
        </>
      ),
      lesson: 10,
    },
  ),
];

const ColorcationsTable: React.FC<{}> = ({}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [isHeaderCellModalOpen, setIsHeaderCellModalOpen] = useState(false);
  const [activeWord, setActiveWord] = useState("");
  const [activeTranslation, setActiveTranslation] = useState("");
  const [activeDescription, setActiveDescription] = useState("");
  const [activeExamples, setActiveExamples] = useState<any[]>([]);
  const [headerCellModalBody, setHeaderCellModalBody] = useState<any>(null);
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  const globalWordsOptions = useMemo(() => {
    if (activeWord) {
      return { words: [activeWord] };
    }
    return { words: [] };
  }, [activeWord]);

  const [word] = useGlobalWords(globalWordsOptions);

  return (
    <Box>
      <Modal
        open={isCellModalOpen}
        onClose={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsCellModalOpen(false)} />
          <VStack alignItems="flex-start">
            <Typography variant="h3">{activeWord}</Typography>
            <Typography>{activeTranslation}</Typography>
            {word && <WordTranscription word={word} play small={false} autoplay={false} />}
            <Typography>{activeDescription}</Typography>
            <VStack alignItems="flex-start">
              {activeExamples.map((e) => {
                return (
                  <Typography variant="h3" key={e.title}>
                    {e.title}
                  </Typography>
                );
              })}
            </VStack>
          </VStack>
        </Box>
      </Modal>
      <Modal
        open={isHeaderCellModalOpen}
        onClose={() => {
          setIsHeaderCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsHeaderCellModalOpen(false)} />
          <VStack alignItems="flex-start">{headerCellModalBody}</VStack>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            {/* <TableRow>
              <TableCell sx={{ width: 120, minWidth: 120 }}>скорочення</TableCell>
              <TableCell sx={{ width: 230, minWidth: 230 }}>повністю</TableCell>
              <TableCell sx={{ width: 400, minWidth: 400 }}>приклади</TableCell>
            </TableRow> */}
          </TableHead>
          <TableBody>
            {rows(lessonNumber)
              .filter((r) => r.item1.lesson <= lessonNumber)
              .map((row, index) => (
                <TableRow
                  key={row.item1?.title || ""}
                  sx={
                    {
                      // "&:last-child td, &:last-child th": { border: 0 }
                    }
                  }
                >
                  <CustomTableCell lesson={row.item1.lesson} en={row.item1.en} />
                  <CustomTableCell lesson={row.item2.lesson} en={row.item2.en}></CustomTableCell>
                  <CustomTableCell lesson={row.item3.lesson} en={row.item3.en}></CustomTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CustomTableCell: React.FC<{
  en: string;
  lesson: number;
}> = ({ en, lesson }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady = lesson <= lessonNumber;

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        m: 0,
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lesson === lessonNumber ? "#ddffc5" : lesson <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        py: 0.5,
      }}
    >
      {en}
    </TableCell>
  );
};

export default ColorcationsTable;
