import Box from "@mui/material/Box";
import { PropsWithChildren } from "react";

const WhiteBoxWrap: React.FC<PropsWithChildren & { py?: number; isDark?: boolean }> = ({ children, py, isDark }) => {
  return (
    <Box position="relative" zIndex={2} sx={{ backgroundColor: isDark ? "black" : "white" }} width="100%" p={2} mb={2} py={py || 5}>
      {children}
    </Box>
  );
};

export default WhiteBoxWrap;
