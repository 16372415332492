import { Button } from "@mui/material";
import CopyNewWordsForGemini from "../../Admin/CopyNewWordsForGemini";
import Box from "../../Unknown/Box";
import HStack from "../../Unknown/HStack";
import Typography from "../../Unknown/Typography";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import supabaseClient from "../../../common/supabaseClient";

const NewLessonWord: React.FC<{
  word: string;
  onChange: (isActive: boolean) => void;
}> = ({ word, onChange }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Box key={word}>
      <Button
        variant={isActive ? "contained" : "text"}
        sx={{ py: 0, px: 1, minWidth: 0 }}
        onClick={() => {
          setIsActive(!isActive);
          onChange(!isActive);
        }}
      >
        {word}
      </Button>
    </Box>
  );
};
const NewLessonWords: React.FC<{
  words: string[];
  onUpdateLessonWords: () => void;
}> = ({ words, onUpdateLessonWords }) => {
  const [wordsToSkip, setWordsToSkip] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  if (!words.length) return null;

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        New lesson words:
      </Typography>
      <HStack mb={3}>
        {words.map((word) => {
          return (
            <NewLessonWord
              key={word}
              word={word}
              onChange={(isActive: boolean) => {
                if (isActive && !wordsToSkip.includes(word)) {
                  setWordsToSkip([...wordsToSkip, word]);
                } else if (!isActive) {
                  setWordsToSkip([...wordsToSkip].filter((w) => w !== word));
                }
              }}
            />
          );
        })}
      </HStack>
      {!!wordsToSkip.length && (
        <LoadingButton
          loading={loading}
          variant="contained"
          color="info"
          onClick={async () => {
            setLoading(true);

            await supabaseClient
              .from("skip-words")
              .upsert(wordsToSkip.map((w) => ({ id: w })));

            setWordsToSkip([]);
            onUpdateLessonWords();
            setLoading(false);
          }}
        >
          Add skip words
        </LoadingButton>
      )}
      {!wordsToSkip.length && <CopyNewWordsForGemini words={words} />}
    </Box>
  );
};

export default NewLessonWords;
