import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const NotFound = () => {
  return (
    <Box width="100%" textAlign="center" py={10}>
      <Typography variant="h5">Сторінка не знайдена.</Typography>
    </Box>
  );
};

export default NotFound;
