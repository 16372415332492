import { Box } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import useIsInViewport from "../../../hooks/useIsInViewport";
import { BanglaSentence } from "../../../types/supabase";
import RepetitionButtons from "../RepetitionButtons";
import TranslateButton from "../TranslateButton";
import UkrainianWord from "../UkrainianWord";

interface BanglaRepetitionSentenceProps {
  sentence: BanglaSentence;
  wordsCount: number;
  goToNextSlide: (id: number, period: string) => void;
}

const BanglaRepetitionSentence = ({ sentence, wordsCount, goToNextSlide }: BanglaRepetitionSentenceProps) => {
  const [isTranslated, setIsTranslated] = useState(false);
  const ref = useRef<HTMLElement>();
  const isInViewport = useIsInViewport(ref);

  const resetIsTranslatedAfterSlideChange = () => {
    setTimeout(() => {
      setIsTranslated(false);
    }, 500);
  };

  useEffect(() => {
    if (!isInViewport) resetIsTranslatedAfterSlideChange();
  }, [isInViewport]);

  return (
    <Box pt={10} pb={10} display="flex" alignItems="center" position="relative" minHeight="calc(100vh - 200px)">
      <Box gap={3} width="100%" display="flex" position="relative" textAlign="center" alignItems="center" flexDirection="column" pt={10}>
        {/* <Hint text={sentence.id} type={HintType.sentence} /> */}
        {/* center box to detect i in viewport */}
        <Box ref={ref} width={10} height={10} />
        <UkrainianWord translation={sentence.script} />
        <UkrainianWord translation={sentence.transliteration} />

        {isTranslated && (
          <>
            <UkrainianWord translation={sentence.translation} />
          </>
        )}

        {/* <SanskritEnglishSentence en={sentence.en} ua={sentence.ua} romanId={sentence.id} script={sentence.script} transcription={sentence.id} isVisible={isTranslated} /> */}
        <TranslateButton isVisible={!isTranslated} onTranslate={() => setIsTranslated(true)} />
        <RepetitionButtons
          isVisible={isTranslated}
          wordsCount={wordsCount}
          goToNextSlide={(period) => {
            goToNextSlide(sentence.id, period);
            setIsTranslated(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(BanglaRepetitionSentence);
