import { alwaysUpperWords } from "./constants";
import removePunctuationFromString from "./removePunctuationFromString";

const getSentenceWords = (sentence: string) => {
  return Array.from(
    new Set(
      sentence
        .split(/[\s\n]/)
        .map((w) => removePunctuationFromString(w))
        .map((w) => {
          if (!alwaysUpperWords.includes(w)) {
            return w.toLowerCase();
          }
          return w;
        }),
    ),
  );
};

export default getSentenceWords;
