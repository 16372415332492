import { defineMessages } from "react-intl";

export default defineMessages({
  inputEnLable: {
    id: "Admin.AudioRecord.inputEnLable",
    defaultMessage: "A phrase or a word",
  },
  inputUaLable: {
    id: "Admin.AudioRecord.inputUaLable",
    defaultMessage: "Фраза або слово",
  },
});
