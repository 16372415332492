import { Box, BoxProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface VStackProps extends BoxProps {
  gap?: number;
  wrap?: boolean;
}

const HStack: React.FC<PropsWithChildren<VStackProps>> = ({
  children,
  wrap = true,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      flexWrap={wrap ? "wrap" : "nowrap"}
      alignItems="center"
      gap={2}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default HStack;
