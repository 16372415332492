const storiesData = [
  {
    row_new_words: ["-", "library", "hospital", "okay"],
    words: [
      "-",
      "where",
      "are",
      "you",
      "I'm",
      "in",
      "the",
      "library",
      "we're",
      "park",
      "am",
      "I",
      "you're",
      "hospital",
      "yes",
      "and",
      "okay",
    ],
    link: "https://www.youtube.com/watch?v=fdks8_yyJgg&list=PL31D7CB49ABAAB4DB&index=3",
    id: "Where are you?\nI'm in the library.\n\nWhere are you?\nWe're in the park.\n\nWhere am I?\nYou're in the hospital. \nThe hospital?\nYes.\nYou're in the hospital and you're okay.\nI'm okay. I'm in the hospital, and I'm okay.",
    story_id: 969,
    word_count: 17,
    all_intersection_count: 13,
    new_words_count: 4,
  },
  {
    row_new_words: ["will", "moon", "shine", "even", "when", "whole"],
    words: [
      "I",
      "will",
      "be",
      "like",
      "the",
      "moon",
      "and",
      "learn",
      "to",
      "shine",
      "even",
      "when",
      "am",
      "not",
      "whole",
    ],
    link: "https://www.familyfriendpoems.com/poem/like-the-moon",
    id: "I will be\nlike the moon\nand learn to shine\neven when\nI am not whole.",
    story_id: 916,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["-", "Bob", "tall", "or", "short", "bill"],
    words: ["-", "is", "Bob", "tall", "or", "short", "he's", "bill"],
    link: "https://www.youtube.com/watch?v=QDeZGtpSsMU&list=PL31D7CB49ABAAB4DB&index=6",
    id: "- Is Bob tall or short?\n- He's tall.\n- Is Bill tall or short?\n- He's short.",
    story_id: 970,
    word_count: 8,
    all_intersection_count: 2,
    new_words_count: 6,
  },
  {
    row_new_words: ["problem", "my", "heart", "unusual", "heartbeat", "oh"],
    words: [
      "doctor",
      "do",
      "I",
      "have",
      "a",
      "problem",
      "with",
      "my",
      "heart",
      "yes",
      "you",
      "very",
      "unusual",
      "heartbeat",
      "oh",
    ],
    link: "https://www.youtube.com/watch?v=fvRabsiahk4&list=PL31D7CB49ABAAB4DB&index=17",
    id: "Doctor, do I have a problem with my heart?\nYes. You have a very unusual heartbeat.\nOh, my.",
    story_id: 988,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["talk", "stop", "train", "chat", "make", "call", "plane"],
    words: [
      "we",
      "can't",
      "talk",
      "now",
      "I",
      "have",
      "to",
      "go",
      "work",
      "stop",
      "catch",
      "a",
      "train",
      "chat",
      "make",
      "call",
      "plane",
    ],
    link: "https://www.youtube.com/watch?v=9ozhdznI3xU&list=PL31D7CB49ABAAB4DB&index=21",
    id: "We can't talk now.\nWe can't talk now.\nWe can't talk now.\nWe can't talk now.\nI can't talk now.\nI have to go to work.\nI can't stop now.\nI have to catch a train.\nI can't chat now.\nI have to make a call.\nI can't stop now.\nI have to catch a plane.\nWe can't talk now.\nWe have to go to work.\nWe can't stop now.\nWe have to catch a train.\nWe can't chat now.\nWe have to make a call.\nWe can't stop now.\nWe have to catch a plane.\nWe can't talk now.\nWe can't talk now.\nWe can't talk now.\nWe can't talk now.",
    story_id: 1002,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["why", "crying", "because", "sad", "always", "cry", "when"],
    words: ["why", "are", "you", "crying", "I'm", "because", "sad", "I", "always", "cry", "when"],
    link: "https://www.youtube.com/watch?v=vaq6pX7q15s&list=PL31D7CB49ABAAB4DB&index=18",
    id: "Why are you crying?\nI'm crying because I'm sad.\nI always cry when I sad.",
    story_id: 990,
    word_count: 11,
    all_intersection_count: 4,
    new_words_count: 7,
  },
  {
    row_new_words: ["what's", "fran", "doing", "late", "why", "monday", "always", "on"],
    words: [
      "what's",
      "fran",
      "doing",
      "she's",
      "working",
      "late",
      "why",
      "is",
      "she",
      "that",
      "it's",
      "monday",
      "always",
      "works",
      "on",
    ],
    link: "(2)https://www.youtube.com/watch?v=SzL6Ww7xUWc&list=PL31D7CB49ABAAB4DB&index=19",
    id: "What's Fran doing?\nShe's working late.\nWorking late? Why is she doing that?\nIt's Monday. She always works late on Monday.",
    story_id: 996,
    word_count: 15,
    all_intersection_count: 7,
    new_words_count: 8,
  },
  {
    row_new_words: ["autumn", "proof", "change", "thing", "even", "though", "hard", "at", "first"],
    words: [
      "let",
      "autumn",
      "be",
      "proof",
      "that",
      "change",
      "is",
      "a",
      "beautiful",
      "thing",
      "even",
      "though",
      "it",
      "can",
      "hard",
      "to",
      "see",
      "at",
      "first",
    ],
    link: "https://www.familyfriendpoems.com/poem/change-is-a-beautiful-thing",
    id: "let autumn be proof that\nchange is a beautiful thing\neven though it can be\nhard to see at first.",
    story_id: 932,
    word_count: 19,
    all_intersection_count: 10,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "despite",
      "all",
      "cannot",
      "bring",
      "myself",
      "regret",
      "single",
      "spent",
      "loving",
    ],
    words: [
      "and",
      "despite",
      "it",
      "all",
      "I",
      "cannot",
      "bring",
      "myself",
      "to",
      "regret",
      "a",
      "single",
      "moment",
      "spent",
      "loving",
      "you",
    ],
    link: "https://www.familyfriendpoems.com/poem/despite-it-all",
    id: "And despite\nit all,\nI cannot\nbring myself\nto regret\na single\nmoment\nI spent\nloving you.",
    story_id: 930,
    word_count: 16,
    all_intersection_count: 7,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "where's",
      "Betty",
      "bedroom",
      "what's",
      "doing",
      "reading",
      "book",
      "Betty's",
      "who's",
    ],
    words: [
      "where's",
      "Betty",
      "she's",
      "in",
      "the",
      "bedroom",
      "what's",
      "she",
      "doing",
      "reading",
      "a",
      "book",
      "Betty's",
      "who's",
    ],
    link: "(2)https://www.youtube.com/watch?v=Ay2D8chFBKw&t=50s",
    id: "Where's Betty? She's in the bedroom.\nWhat's she doing? Reading a book.\nBetty's in the bedroom reading a book.\nBetty's in the bedroom reading a book.\nWho's in the bedroom? Betty's in the bedroom.\nWhat's she doing? Reading a book.",
    story_id: 955,
    word_count: 14,
    all_intersection_count: 5,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "sing",
      "luckiest",
      "world",
      "how's",
      "nice",
      "feelings",
      "nothing",
      "more",
      "than",
    ],
    words: [
      "can",
      "you",
      "sing",
      "yes",
      "I",
      "people",
      "who",
      "need",
      "are",
      "the",
      "luckiest",
      "in",
      "world",
      "how's",
      "that",
      "very",
      "nice",
      "no",
      "can't",
      "feelings",
      "nothing",
      "more",
      "than",
      "see",
      "sorry",
    ],
    link: "https://www.youtube.com/watch?v=RPDHqfX4PZQ&list=PL31D7CB49ABAAB4DB&index=20",
    id: "Can you sing?\nYes. I can. \n\nPeople,\npeople who need \npeople\nare the luckiest people in the world.\n\nHow's that?\nVery nice.\n\nCan you sing?\nNo, I can't.\n\nFeelings, nothing more than feelings.\n\nYou see, I can't sing. Sorry.",
    story_id: 998,
    word_count: 25,
    all_intersection_count: 16,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "present",
      "continuous",
      "use",
      "sentences",
      "making",
      "video",
      "watching",
      "teaching",
      "listening",
    ],
    words: [
      "present",
      "continuous",
      "that's",
      "where",
      "you",
      "use",
      "sentences",
      "like",
      "this",
      "I",
      "am",
      "making",
      "a",
      "video",
      "are",
      "watching",
      "teaching",
      "English",
      "learning",
      "speaking",
      "listening",
    ],
    link: "(2)https://www.youtube.com/watch?v=rFdhrR6Dpco",
    id: "Present continuous.\n\nThat's where you use sentences like this:\nI am making a video.\nYou are watching a video.\nI am teaching you English.\nYou are learning English.\nI am speaking.\nYou are listening.",
    story_id: 1033,
    word_count: 21,
    all_intersection_count: 12,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "washing",
      "your",
      "car",
      "wash",
      "often",
      "washed",
      "yesterday",
      "gonna",
      "really",
      "sure",
    ],
    words: [
      "I",
      "see",
      "you're",
      "washing",
      "your",
      "car",
      "yes",
      "am",
      "do",
      "you",
      "wash",
      "it",
      "very",
      "often",
      "washed",
      "yesterday",
      "I'm",
      "now",
      "and",
      "gonna",
      "tomorrow",
      "really",
      "like",
      "to",
      "sure",
      "every",
      "day",
    ],
    link: "https://www.youtube.com/watch?v=w24IRWsCL48&list=PLD7AA7B1BC72ACC28",
    id: "I see you're washing your car.\nYes. I am.\nDo you wash it very often?\nYes. I washed it yesterday. I'm washing it now, and I'm gonna wash it tomorrow.\nYou really like to wash your car.\nI sure do. I wash it every day.",
    story_id: 1011,
    word_count: 27,
    all_intersection_count: 17,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "hungarian",
      "romanian",
      "languages",
      "chinese",
      "Spanish",
      "japanese",
      "korean",
      "afraid",
      "portuguese",
      "anything",
      "laryngitis",
    ],
    words: [
      "can",
      "you",
      "speak",
      "hungarian",
      "no",
      "I",
      "can't",
      "but",
      "romanian",
      "what",
      "languages",
      "English",
      "and",
      "chinese",
      "Spanish",
      "japanese",
      "korean",
      "I'm",
      "afraid",
      "portuguese",
      "anything",
      "today",
      "have",
      "laryngitis",
      "sorry",
    ],
    link: "(3)https://www.youtube.com/watch?v=RPDHqfX4PZQ&list=PL31D7CB49ABAAB4DB&index=20",
    id: "Can you speak Hungarian?\nNo, I can't. But I can speak Romanian.\n\nWhat languages can you speak?\nI can speak English and Chinese.\n\nI can't speak Chinese, but I can speak Spanish.\n\nI can't speak Spanish, but I can speak Japanese.\n\nJapanese? No. I can't speak Japanese, but I can speak Korean.\n\nNo. I'm afraid I can't speak Korean, but I can speak Portuguese.\n\nI can't speak anything today. I have laryngitis. Sorry.",
    story_id: 1000,
    word_count: 25,
    all_intersection_count: 14,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "goes",
      "sees",
      "roars",
      "long",
      "trunk",
      "turtle",
      "slow",
      "rabbit",
      "soft",
      "fur",
      "gorilla",
    ],
    words: [
      "going",
      "to",
      "the",
      "zoo",
      "she",
      "goes",
      "sees",
      "a",
      "lion",
      "roars",
      "an",
      "elephant",
      "has",
      "long",
      "trunk",
      "turtle",
      "is",
      "slow",
      "rabbit",
      "soft",
      "fur",
      "gorilla",
      "eating",
      "banana",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke001.htm",
    id: "Going to the zoo\n\nShe goes to the zoo. She sees a lion. The lion roars. She sees an elephant. The elephant has a long trunk. She sees a turtle. The turtle is slow. She sees a rabbit. The rabbit has soft fur. She sees a gorilla. The gorilla is eating a banana.",
    story_id: 324,
    word_count: 24,
    all_intersection_count: 13,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "cannot",
      "slow",
      "setting",
      "sun",
      "only",
      "choose",
      "how",
      "spend",
      "your",
      "time",
      "light",
    ],
    words: [
      "you",
      "cannot",
      "slow",
      "the",
      "setting",
      "of",
      "sun",
      "can",
      "only",
      "choose",
      "how",
      "to",
      "spend",
      "your",
      "time",
      "in",
      "light",
    ],
    link: "https://www.familyfriendpoems.com/poem/choose-how-you-spend-your-time",
    id: "You cannot\nslow the setting\nof the sun.\nYou can only choose\nhow to spend\nyour time\nin the light.",
    story_id: 915,
    word_count: 17,
    all_intersection_count: 6,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "treats",
      "children",
      "takes",
      "asks",
      "wrong",
      "tells",
      "everything",
      "okay",
      "at",
      "end",
      "prescription",
      "parents",
    ],
    words: [
      "she",
      "is",
      "a",
      "doctor",
      "treats",
      "children",
      "takes",
      "care",
      "of",
      "them",
      "asks",
      "what",
      "wrong",
      "tells",
      "everything",
      "going",
      "to",
      "be",
      "okay",
      "gives",
      "candy",
      "at",
      "the",
      "end",
      "love",
      "her",
      "prescription",
      "parents",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2051.htm",
    id: "She is a doctor\n\nShe is a doctor. She treats children. She takes care of them. She asks them what is wrong. She tells them everything is going to be okay. She gives them candy at the end. The children love her. She gives the prescription to the parents.",
    story_id: 470,
    word_count: 28,
    all_intersection_count: 16,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "smile",
      "when",
      "frown",
      "sad",
      "blush",
      "embarrassed",
      "shout",
      "mad",
      "smiling",
      "frowning",
      "blushing",
      "shouting",
    ],
    words: [
      "I",
      "smile",
      "when",
      "I'm",
      "happy",
      "frown",
      "sad",
      "blush",
      "embarrassed",
      "and",
      "shout",
      "mad",
      "are",
      "you",
      "smiling",
      "yes",
      "frowning",
      "blushing",
      "shouting",
      "we",
      "we're",
    ],
    link: "(6)https://www.youtube.com/watch?v=vaq6pX7q15s&list=PL31D7CB49ABAAB4DB&index=18",
    id: "I smile when I'm happy.\nI frown when I'm sad.\nI blush when I'm embarrassed, and I shout when I'm mad.\nAre you smiling?\nYes. I'm happy.\nAre you frowning? Yes. I'm sad.\nAre you blushing? I'm embarrassed.\nAre you shouting? Yes. I'm mad.\nWe smile when we're happy.\nWe frown when we're sad.\nWe blush when we're embarrassed, and we shout when we're mad.\nWe smile when we're happy.\nWe frown when we're sad.\nWe blush when we're embarrassed, and we shout when we're mad.",
    story_id: 994,
    word_count: 21,
    all_intersection_count: 9,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "enough",
      "always",
      "been",
      "will",
      "forever",
      "matter",
      "how",
      "feel",
      "or",
      "lies",
      "may",
      "believe",
    ],
    words: [
      "you",
      "are",
      "enough",
      "have",
      "always",
      "been",
      "and",
      "will",
      "forever",
      "be",
      "no",
      "matter",
      "what",
      "see",
      "how",
      "feel",
      "or",
      "the",
      "lies",
      "may",
      "believe",
    ],
    link: "https://www.familyfriendpoems.com/poem/you-are-enough",
    id: "You are enough\nYou have always been enough, and\nYou will forever be\nEnough\n\nno matter what you see\nhow you feel, or the lies you may\nbelieve",
    story_id: 927,
    word_count: 21,
    all_intersection_count: 9,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "how",
      "much",
      "head",
      "lettuce",
      "cost",
      "95¢",
      "lot",
      "right",
      "expensive",
      "pound",
      "dollar",
      "25",
    ],
    words: [
      "how",
      "much",
      "does",
      "a",
      "head",
      "of",
      "lettuce",
      "cost",
      "95¢",
      "that's",
      "lot",
      "money",
      "you're",
      "right",
      "is",
      "very",
      "expensive",
      "this",
      "week",
      "pound",
      "apples",
      "dollar",
      "25",
      "are",
    ],
    link: "(2)https://www.youtube.com/watch?v=SBG0nsPuI6Q&list=PLD7AA7B1BC72ACC28&index=5",
    id: "How much does a head of lettuce cost?\n95¢.\n95¢? That's a lot of money.\nYou're right. Lettuce is very expensive this week.\n\nHow much does a pound of apples cost?\nA dollar 25.\nA dollar 25? That's a lot of money.\nYou're right. Apples are very expensive this week.",
    story_id: 1023,
    word_count: 24,
    all_intersection_count: 12,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "gonna",
      "your",
      "wife",
      "birthday",
      "necklace",
      "gave",
      "last",
      "year",
      "how",
      "2",
      "years",
      "ago",
      "really",
    ],
    words: [
      "what",
      "are",
      "you",
      "gonna",
      "give",
      "your",
      "wife",
      "for",
      "her",
      "birthday",
      "I",
      "don't",
      "know",
      "can't",
      "a",
      "necklace",
      "gave",
      "last",
      "year",
      "how",
      "about",
      "flowers",
      "no",
      "2",
      "years",
      "ago",
      "well",
      "really",
      "have",
      "to",
      "think",
      "it",
    ],
    link: "https://www.youtube.com/watch?v=_lA-Min1MGk&list=PLD7AA7B1BC72ACC28&index=2",
    id: "What are you gonna give your wife for her birthday?\nI don't know.\nI can't give her a necklace. I gave her a necklace last year.\nHow about flowers?\nNo. I can't give her flowers. I gave her flowers 2 years ago.\nWell, what are you gonna give her?\nI don't know. I really have to think about it.",
    story_id: 1012,
    word_count: 32,
    all_intersection_count: 19,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "doing",
      "washing",
      "dishes",
      "bathtub",
      "strange",
      "usually",
      "wash",
      "never",
      "why",
      "because",
      "my",
      "sink",
      "broken",
    ],
    words: [
      "what",
      "are",
      "you",
      "doing",
      "I'm",
      "washing",
      "the",
      "dishes",
      "in",
      "bathtub",
      "that's",
      "strange",
      "do",
      "usually",
      "wash",
      "no",
      "I",
      "never",
      "but",
      "today",
      "why",
      "that",
      "because",
      "my",
      "sink",
      "is",
      "broken",
      "sorry",
      "to",
      "hear",
    ],
    link: "https://www.youtube.com/watch?v=SzL6Ww7xUWc&list=PL31D7CB49ABAAB4DB&index=19",
    id: "What are you doing?\nI'm washing the dishes in the bathtub.\nThat's strange. Do you usually wash the dishes in the bathtub?\nNo. I never wash the dishes in the bathtub, but I'm washing the dishes in the bathtub today.\nWhy are you doing that?\nBecause my sink is broken.\nI'm sorry to hear that.",
    story_id: 995,
    word_count: 30,
    all_intersection_count: 17,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "glow",
      "fireplace",
      "fills",
      "room",
      "Christmas",
      "cheer",
      "my",
      "family",
      "raise",
      "glass",
      "full",
      "wishes",
      "year",
    ],
    words: [
      "the",
      "orange",
      "glow",
      "of",
      "fireplace",
      "fills",
      "room",
      "with",
      "Christmas",
      "cheer",
      "to",
      "my",
      "family",
      "I",
      "raise",
      "glass",
      "full",
      "wishes",
      "for",
      "year",
    ],
    link: "https://www.familyfriendpoems.com/poem/wishes-6",
    id: "The orange glow of the fireplace\nfills the room with Christmas cheer.\nTo my family I raise my glass,\nfull of wishes for the year.",
    story_id: 924,
    word_count: 20,
    all_intersection_count: 7,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "changes",
      "sometimes",
      "lot",
      "barely",
      "life",
      "never",
      "stops",
      "evolves",
      "growing",
      "even",
      "when",
      "doing",
      "best",
    ],
    words: [
      "every",
      "day",
      "changes",
      "you",
      "sometimes",
      "a",
      "lot",
      "barely",
      "but",
      "life",
      "never",
      "stops",
      "it",
      "evolves",
      "and",
      "are",
      "growing",
      "even",
      "when",
      "can't",
      "see",
      "you're",
      "doing",
      "the",
      "best",
      "can",
    ],
    link: "https://www.familyfriendpoems.com/poem/every-day-changes-you",
    id: "every day changes you\nsometimes a lot\nsometimes barely\nbut life never stops\nit evolves\nand you are growing\neven when you can’t see it\nyou’re doing the best you can",
    story_id: 918,
    word_count: 26,
    all_intersection_count: 13,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "goes",
      "newsstand",
      "on",
      "reads",
      "cover",
      "story",
      "first",
      "murderer",
      "loose",
      "scary",
      "looks",
      "at",
      "cartoons",
      "funny",
    ],
    words: [
      "she",
      "buys",
      "a",
      "newspaper",
      "goes",
      "to",
      "the",
      "newsstand",
      "wants",
      "buy",
      "know",
      "what",
      "is",
      "going",
      "on",
      "for",
      "week",
      "reads",
      "cover",
      "story",
      "first",
      "it",
      "about",
      "murderer",
      "loose",
      "scary",
      "looks",
      "at",
      "cartoons",
      "funny",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2078.htm",
    id: "She buys a newspaper\n\nShe goes to the newsstand. She wants to buy a newspaper. She wants to know what is going on. She buys the newspaper for the week. She reads the cover story first. It is about a murderer on the loose. It is scary. She looks at the cartoons. It is funny.",
    story_id: 497,
    word_count: 30,
    all_intersection_count: 16,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "May",
      "looking",
      "jacket",
      "here's",
      "nice",
      "purple",
      "okay",
      "jackets",
      "popular",
      "year",
      "pair",
      "gloves",
      "these",
      "green",
    ],
    words: [
      "May",
      "I",
      "help",
      "you",
      "yes",
      "please",
      "I'm",
      "looking",
      "for",
      "a",
      "jacket",
      "here's",
      "nice",
      "but",
      "this",
      "is",
      "purple",
      "that's",
      "okay",
      "jackets",
      "are",
      "very",
      "popular",
      "year",
      "can",
      "pair",
      "of",
      "gloves",
      "these",
      "green",
    ],
    link: "(6)https://www.youtube.com/watch?v=HVJNOeDvpME&list=PL31D7CB49ABAAB4DB&index=11",
    id: "May I help you?\nYes, please. I'm looking for a jacket.\nHere's a nice jacket.\nBut this is a purple jacket.\nThat's okay, purple jackets are very popular this year.\n\nCan I help you?\nYes, please. I'm looking for a pair of gloves.\nHere's a nice pair of gloves.\nBut these are green gloves.\nThat's okay. Green gloves are very popular this year.",
    story_id: 984,
    word_count: 30,
    all_intersection_count: 16,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "eggs",
      "or",
      "peas",
      "bread",
      "beans",
      "cheese",
      "pasta",
      "pie",
      "pizza",
      "chips",
      "my",
      "mum",
      "because",
      "things",
    ],
    words: [
      "I",
      "don't",
      "like",
      "eggs",
      "or",
      "rice",
      "peas",
      "bread",
      "beans",
      "cheese",
      "pasta",
      "pie",
      "and",
      "pizza",
      "fish",
      "chips",
      "meat",
      "my",
      "mum",
      "likes",
      "because",
      "she",
      "knows",
      "they're",
      "good",
      "for",
      "me",
      "doesn't",
      "the",
      "things",
      "so",
      "what",
      "eat",
    ],
    link: "https://learnenglishkids.britishcouncil.org/grammar-vocabulary/grammar-chants/i-dont-eggs",
    id: "I don't like eggs or rice or peas\nI don't like bread or beans or cheese\nI like pasta, pie and pizza\nFish and chips and meat.\n\nMy mum likes eggs and rice and peas\nBecause she knows they're good for me\nShe doesn't like the things I like\nSo what she likes I eat!",
    story_id: 823,
    word_count: 33,
    all_intersection_count: 19,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "what's",
      "Tom",
      "doing",
      "martha",
      "watching",
      "TV",
      "your",
      "sleeping",
      "where's",
      "Charlie",
      "kitchen",
      "lunch",
      "charlie's",
      "who's",
    ],
    words: [
      "what's",
      "Tom",
      "doing",
      "he's",
      "eating",
      "martha",
      "she's",
      "watching",
      "TV",
      "your",
      "dog",
      "it's",
      "sleeping",
      "where's",
      "Charlie",
      "in",
      "the",
      "kitchen",
      "he",
      "lunch",
      "charlie's",
      "who's",
    ],
    link: "https://www.youtube.com/watch?v=Ay2D8chFBKw&t=50s",
    id: "What's Tom doing? \nHe's eating. \nWhat's Martha doing? \nShe's watching TV. \nWhat's your dog doing? \nIt's sleeping.\n\nWhere's Charlie? He's in the kitchen. \nWhat's he doing? Eating lunch. \nCharlie's in the kitchen, eating lunch.\n\nCharlie's in the kitchen, eating lunch. \nWho's in the kitchen? \nCharlie's in the kitchen. \nWhat's he doing? Eating lunch.",
    story_id: 953,
    word_count: 22,
    all_intersection_count: 8,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "loving",
      "on",
      "school's",
      "team",
      "after",
      "room",
      "kitchen",
      "even",
      "bathroom",
      "anyone",
      "passionate",
      "best",
      "dancer",
      "world",
    ],
    words: [
      "loving",
      "dance",
      "she",
      "loves",
      "to",
      "dances",
      "on",
      "her",
      "school's",
      "team",
      "after",
      "school",
      "in",
      "room",
      "the",
      "kitchen",
      "even",
      "bathroom",
      "anyone",
      "can",
      "see",
      "that",
      "is",
      "passionate",
      "people",
      "say",
      "best",
      "dancer",
      "world",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke044.htm",
    id: "Loving dance\n\nShe loves to dance. She dances on her school's team. She dances after school. She loves to dance in her room. She loves to dance in the kitchen. She even loves to dance in the bathroom. Anyone can see that she is passionate. People say she is the best dancer in the world.",
    story_id: 360,
    word_count: 29,
    all_intersection_count: 15,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "believes",
      "should",
      "killed",
      "hard",
      "out",
      "most",
      "tells",
      "his",
      "parents",
      "become",
      "vegetarians",
      "laugh",
      "at",
      "up",
    ],
    words: [
      "he",
      "does",
      "not",
      "eat",
      "meat",
      "is",
      "a",
      "vegetarian",
      "believes",
      "that",
      "animals",
      "should",
      "be",
      "killed",
      "it",
      "hard",
      "for",
      "him",
      "to",
      "go",
      "out",
      "with",
      "people",
      "most",
      "like",
      "eating",
      "tells",
      "his",
      "parents",
      "become",
      "vegetarians",
      "they",
      "laugh",
      "at",
      "can't",
      "give",
      "up",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2079.htm",
    id: "He does not eat meat\n\nHe is a vegetarian. He does not eat meat. He believes that animals should not be killed. It is hard for him to go out with people. Most people like eating meat. He tells his parents to become vegetarians. They laugh at him. They can't give up meat.",
    story_id: 498,
    word_count: 37,
    all_intersection_count: 23,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "twelve",
      "o'clock",
      "morning",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
    ],
    words: [
      "it's",
      "twelve",
      "o'clock",
      "in",
      "the",
      "morning",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "am",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
    ],
    link: "https://www.englishcentral.com/video/39524",
    id: "It's twelve o'clock in the morning.\nIt's one o'clock in the morning.\nIt's two o'clock in the morning.\nIt's three o'clock in the morning.\nIt's four o'clock in the morning.\nIt's five o'clock in the morning.\nIt's six a.m.\nIt's seven a.m.\nIt's eight a.m.\nIt's nine a.m.\nIt's ten a.m.\nIt's eleven a.m.",
    story_id: 939,
    word_count: 18,
    all_intersection_count: 4,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "did",
      "washed",
      "my",
      "floors",
      "your",
      "all",
      "cleaned",
      "painted",
      "porch",
      "fixed",
      "sink",
      "worked",
      "at",
      "home",
    ],
    words: [
      "what",
      "did",
      "you",
      "do",
      "today",
      "I",
      "washed",
      "my",
      "floors",
      "your",
      "yes",
      "all",
      "day",
      "cleaned",
      "house",
      "painted",
      "porch",
      "fixed",
      "sink",
      "we",
      "worked",
      "at",
      "home",
    ],
    link: "(2)https://www.youtube.com/watch?v=xyvNu254A-Y&list=PL31D7CB49ABAAB4DB&index=24",
    id: "What did you do today?\nI washed my floors.\nYour floors?\nYes. I washed my floors all day.\n\nWhat did you do today?\nI cleaned my house.\nYour house?\nYes. I cleaned my house all day.\n\nWhat did you do today?\nI painted my porch.\nYour porch?\nYes. I painted my porch all day.\n\nWhat did you do today?\nI fixed my sink.\nYour sink?\nYes. I fixed my sink all day.\n\nI washed my floors.\nI cleaned my house.\nI painted my porch.\nI fixed my sink.\nWe worked at home all day.",
    story_id: 1010,
    word_count: 23,
    all_intersection_count: 9,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "museum",
      "at",
      "looks",
      "painting",
      "long",
      "time",
      "something",
      "another",
      "man",
      "painter's",
      "information",
      "name",
      "Ryan",
      "Howard",
    ],
    words: [
      "going",
      "to",
      "the",
      "museum",
      "he",
      "is",
      "at",
      "looks",
      "a",
      "painting",
      "it",
      "of",
      "dog",
      "for",
      "long",
      "time",
      "something",
      "about",
      "so",
      "beautiful",
      "another",
      "man",
      "dancing",
      "painter's",
      "information",
      "name",
      "Ryan",
      "Howard",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2090.htm",
    id: "Going to the museum\n\nHe is at the museum. He looks at a painting. It is a painting of a dog. He looks at it for a long time. Something about it is so beautiful. He looks at another painting. It is a painting of a man dancing. He looks at the painter's information. The painter's name is Ryan Howard.",
    story_id: 502,
    word_count: 28,
    all_intersection_count: 14,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "winter",
      "cold",
      "ice",
      "mug",
      "hot",
      "chocolate",
      "would",
      "nice",
      "long",
      "dark",
      "nights",
      "kids",
      "bundle",
      "up",
    ],
    words: [
      "winter",
      "cold",
      "and",
      "ice",
      "a",
      "mug",
      "of",
      "hot",
      "chocolate",
      "would",
      "be",
      "nice",
      "long",
      "dark",
      "nights",
      "kids",
      "bundle",
      "up",
    ],
    link: "",
    id: "Winter, winter\ncold and ice\nA mug of hot chocolate\nwould be nice\nWinter, winter\nlong, dark nights\nKids bundle up",
    story_id: 900,
    word_count: 18,
    all_intersection_count: 4,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "how",
      "drive",
      "Katherine",
      "turn",
      "16",
      "excited",
      "will",
      "able",
      "herself",
      "friends",
      "movies",
      "grocery",
      "stores",
      "even",
      "states",
    ],
    words: [
      "learning",
      "how",
      "to",
      "drive",
      "Katherine",
      "is",
      "about",
      "turn",
      "16",
      "she",
      "excited",
      "will",
      "be",
      "able",
      "school",
      "herself",
      "and",
      "her",
      "friends",
      "movies",
      "grocery",
      "stores",
      "even",
      "other",
      "states",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1089.htm",
    id: "Learning how to drive\n\nKatherine is about to turn 16. She is excited about learning to drive. She will be able to drive to school. She will be able to drive herself and her friends to movies. She will be able to drive to grocery stores. She will even be able to drive to other states.",
    story_id: 306,
    word_count: 25,
    all_intersection_count: 10,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "always",
      "get",
      "on",
      "time",
      "usually",
      "here",
      "by",
      "8",
      "sometimes",
      "early",
      "never",
      "late",
      "gets",
      "rarely",
      "right",
    ],
    words: [
      "I",
      "always",
      "get",
      "to",
      "work",
      "on",
      "time",
      "I'm",
      "usually",
      "here",
      "by",
      "8",
      "sometimes",
      "early",
      "never",
      "late",
      "no",
      "he",
      "gets",
      "he's",
      "rarely",
      "right",
    ],
    link: "https://www.youtube.com/watch?v=16kzXiwYeEo&list=PL31D7CB49ABAAB4DB&index=16",
    id: "I always get to work on time.\nI'm usually here by 8.\nI sometimes get here early.\nI never get here late.\nNo. I never get here late.\n\nHe always gets to work on time.\nHe's usually here by 8.\nHe sometimes gets here early.\nHe rarely gets here late.\n\nNo. I never get here late.\nRight. He never gets here late.",
    story_id: 987,
    word_count: 22,
    all_intersection_count: 7,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "ugly",
      "boots",
      "his",
      "brown",
      "spots",
      "thinks",
      "unique",
      "too",
      "plain",
      "make",
      "special",
      "mom",
      "tells",
      "get",
      "says",
    ],
    words: [
      "ugly",
      "boots",
      "people",
      "think",
      "his",
      "are",
      "they",
      "brown",
      "have",
      "yellow",
      "spots",
      "he",
      "does",
      "not",
      "care",
      "what",
      "other",
      "loves",
      "thinks",
      "that",
      "unique",
      "too",
      "plain",
      "for",
      "him",
      "the",
      "make",
      "special",
      "mom",
      "tells",
      "to",
      "get",
      "new",
      "says",
      "no",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2027.htm",
    id: "Ugly boots\n\nPeople think his boots are ugly. They are brown. They have yellow spots. He does not care what other people think. He loves his boots. He thinks that they are unique. Other boots are too plain for him. The yellow spots make the boots special. His mom tells him to get new boots. He says no.",
    story_id: 446,
    word_count: 35,
    all_intersection_count: 20,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "pet",
      "Kate",
      "sees",
      "wags",
      "its",
      "tail",
      "collar",
      "takes",
      "home",
      "washes",
      "names",
      "Toby",
      "vet",
      "healthy",
      "each",
    ],
    words: [
      "a",
      "new",
      "pet",
      "Kate",
      "is",
      "walking",
      "she",
      "sees",
      "dog",
      "the",
      "wags",
      "its",
      "tail",
      "likes",
      "it",
      "has",
      "no",
      "collar",
      "takes",
      "home",
      "washes",
      "names",
      "him",
      "Toby",
      "to",
      "vet",
      "healthy",
      "walks",
      "every",
      "day",
      "they",
      "love",
      "each",
      "other",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke011.htm",
    id: 'A new pet\n\nKate is walking. She sees a dog. The dog wags its tail. Kate likes the dog. It has no collar. Kate takes it home. She washes the dog. She names him "Toby." She takes Toby to the vet. Toby is healthy. Kate walks Toby every day. They love each other.',
    story_id: 341,
    word_count: 34,
    all_intersection_count: 19,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "taking",
      "toy",
      "everywhere",
      "takes",
      "during",
      "recess",
      "out",
      "his",
      "backpack",
      "when",
      "goes",
      "swim",
      "practice",
      "coach",
      "did",
    ],
    words: [
      "taking",
      "the",
      "toy",
      "everywhere",
      "he",
      "has",
      "a",
      "plays",
      "with",
      "takes",
      "it",
      "to",
      "school",
      "during",
      "recess",
      "out",
      "of",
      "his",
      "backpack",
      "and",
      "when",
      "goes",
      "eat",
      "swim",
      "practice",
      "wants",
      "coach",
      "did",
      "not",
      "let",
      "him",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2034.htm",
    id: "Taking the toy everywhere\n\nHe has a toy. He plays with the toy. He takes the toy everywhere. He takes it to school. During recess, he takes it out of his backpack and plays with it. He takes it when he goes out to eat. He takes it when he goes to swim practice. He wants to swim with his toy. His swim coach did not let him.",
    story_id: 460,
    word_count: 31,
    all_intersection_count: 16,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "there",
      "strength",
      "haven't",
      "even",
      "begun",
      "find",
      "often",
      "how",
      "life",
      "won't",
      "strong",
      "until",
      "world",
      "forces",
      "look",
    ],
    words: [
      "there",
      "is",
      "a",
      "strength",
      "in",
      "you",
      "haven't",
      "even",
      "begun",
      "to",
      "find",
      "and",
      "that's",
      "often",
      "how",
      "life",
      "works",
      "won't",
      "know",
      "strong",
      "are",
      "until",
      "the",
      "world",
      "forces",
      "look",
    ],
    link: "https://www.familyfriendpoems.com/poem/the-strength-in-you",
    id: "There is\na strength in you\nyou haven’t even\nbegun to find.\n\nAnd that’s often\nhow life works.\n\nYou won’t know\nhow strong you are\nuntil the world\nforces you\nto look.",
    story_id: 917,
    word_count: 26,
    all_intersection_count: 11,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "always",
      "Jane",
      "called",
      "said",
      "was",
      "dinner",
      "asked",
      "call",
      "back",
      "would",
      "next",
      "lunch",
      "snack",
      "when",
      "aren't",
    ],
    words: [
      "always",
      "eating",
      "Jane",
      "called",
      "Lisa",
      "said",
      "she",
      "was",
      "dinner",
      "asked",
      "to",
      "call",
      "her",
      "back",
      "would",
      "the",
      "next",
      "day",
      "lunch",
      "breakfast",
      "a",
      "snack",
      "no",
      "you",
      "me",
      "when",
      "aren't",
    ],
    link: "https://www.eslfast.com/supereasy/se/supereasy128.htm",
    id: 'Always eating\n\nJane called Lisa. Lisa said she was eating. She was eating dinner. Lisa asked Jane to call her back. Jane said she would call Lisa back. She called Lisa back the next day. Lisa said she was eating. She was eating lunch. Lisa asked Jane to call her back. Jane said she would call Lisa back. She called Lisa back the next day. Lisa said she was eating. She was eating breakfast. Lisa asked Jane to call her back. Jane said she would call Lisa back. The next day Jane called Lisa back. Lisa was eating. She was eating a snack. Lisa asked Jane to call her back. Jane said, "No. You call me back. You call me back when you aren\'t eating."',
    story_id: 152,
    word_count: 27,
    all_intersection_count: 12,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "what's",
      "Bob",
      "doing",
      "cooking",
      "spaghetti",
      "why",
      "Wednesday",
      "always",
      "cooks",
      "on",
      "Gary",
      "bathing",
      "his",
      "Friday",
      "bathes",
    ],
    words: [
      "what's",
      "Bob",
      "doing",
      "he's",
      "cooking",
      "spaghetti",
      "why",
      "is",
      "he",
      "that",
      "it's",
      "Wednesday",
      "always",
      "cooks",
      "on",
      "Gary",
      "bathing",
      "his",
      "cat",
      "Friday",
      "bathes",
    ],
    link: "(3)https://www.youtube.com/watch?v=SzL6Ww7xUWc&list=PL31D7CB49ABAAB4DB&index=19",
    id: "What's Bob doing?\nHe's cooking spaghetti.\nCooking spaghetti? Why is he doing that?\nIt's Wednesday. He always cooks spaghetti on Wednesday.\n\nWhat's Gary doing?\nHe's bathing his cat.\nBathing his cat?\nWhy is he doing that?\nIt's Friday. He always bathes his cat on Friday.",
    story_id: 997,
    word_count: 21,
    all_intersection_count: 6,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "Incy",
      "Wincy",
      "spider",
      "climbed",
      "up",
      "spout",
      "down",
      "came",
      "rain",
      "washed",
      "poor",
      "out",
      "sunshine",
      "dried",
      "all",
    ],
    words: [
      "Incy",
      "Wincy",
      "spider",
      "climbed",
      "up",
      "the",
      "water",
      "spout",
      "down",
      "came",
      "rain",
      "and",
      "washed",
      "poor",
      "out",
      "sunshine",
      "dried",
      "all",
      "again",
    ],
    link: "https://learnenglishkids.britishcouncil.org/listen-watch/songs/incy-wincy-spider",
    id: "Incy Wincy spider\n\nIncy Wincy spider\nClimbed up the water spout\nDown came the rain\nAnd washed poor Incy out\nOut came the sunshine\nand dried up all the rain\nAnd Incy Wincy spider\nClimbed up the spout again.\n\nIncy Wincy spider\nClimbed up the water spout\nDown came the rain\nAnd washed poor Incy out\nOut came the sunshine\nand dried up all the rain\nAnd Incy Wincy spider\nClimbed up the spout again.",
    story_id: 872,
    word_count: 19,
    all_intersection_count: 4,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "stanley",
      "cook",
      "japanese",
      "food",
      "on",
      "Sunday",
      "when",
      "cooks",
      "Friday",
      "excuse",
      "ma'am",
      "question",
      "chinese",
      "monday",
      "Wednesday",
    ],
    words: [
      "does",
      "stanley",
      "cook",
      "japanese",
      "food",
      "on",
      "Sunday",
      "no",
      "he",
      "doesn't",
      "when",
      "cooks",
      "Friday",
      "excuse",
      "me",
      "yes",
      "ma'am",
      "I",
      "have",
      "a",
      "question",
      "chinese",
      "monday",
      "Wednesday",
      "see",
      "thank",
      "you",
    ],
    link: "(3)https://www.youtube.com/watch?v=X9QluYxyV00",
    id: "Does Stanley cook Japanese food on Sunday?\nNo, he doesn't.\nWhen does he cook Japanese food?\nHe cooks Japanese food on Friday.\n\nExcuse me?\nYes, ma'am?\nI have a question. Does Stanley cook Chinese food on Monday?\nNo, he doesn't. When does he cook Chinese food?\nHe cooks Chinese food on Wednesday.\nI see. Thank you.",
    story_id: 962,
    word_count: 27,
    all_intersection_count: 12,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "paint",
      "draw",
      "sing",
      "little",
      "my",
      "these",
      "things",
      "sleep",
      "out",
      "night",
      "homework",
      "because",
      "read",
      "or",
      "write",
    ],
    words: [
      "I",
      "can",
      "paint",
      "and",
      "draw",
      "dance",
      "sing",
      "speak",
      "a",
      "little",
      "English",
      "but",
      "my",
      "cat",
      "can't",
      "do",
      "these",
      "things",
      "he",
      "sleep",
      "eat",
      "go",
      "out",
      "every",
      "night",
      "homework",
      "because",
      "read",
      "or",
      "write",
    ],
    link: "https://learnenglishkids.britishcouncil.org/grammar-vocabulary/grammar-chants/i-can-paint",
    id: "I can paint and I can draw\nI can dance and I can sing\nI can speak a little English\nBut my cat can't do these things.\n\nHe can sleep and he can eat\nHe can go out every night\nBut he can't do my homework\nBecause he can't read or write.",
    story_id: 822,
    word_count: 30,
    all_intersection_count: 15,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "kids",
      "class",
      "teacher",
      "teaching",
      "math",
      "tired",
      "sun",
      "slides",
      "also",
      "swings",
      "stare",
      "at",
      "clock",
      "moves",
      "slowly",
      "continue",
    ],
    words: [
      "waiting",
      "the",
      "kids",
      "are",
      "in",
      "class",
      "teacher",
      "is",
      "teaching",
      "math",
      "tired",
      "they",
      "want",
      "to",
      "play",
      "see",
      "sun",
      "like",
      "slides",
      "also",
      "swings",
      "don't",
      "stare",
      "at",
      "clock",
      "moves",
      "slowly",
      "continue",
      "wait",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke004.htm",
    id: "Waiting\n\nThe kids are in class. The teacher is teaching math. The kids are tired. They want to play. They want to see the sun. They like slides. They also like swings. They don't like math. They stare at the clock. The clock moves slowly. The kids continue to wait.",
    story_id: 327,
    word_count: 29,
    all_intersection_count: 13,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "makes",
      "speech",
      "make",
      "birds",
      "nervous",
      "talking",
      "front",
      "mess",
      "up",
      "fun",
      "tells",
      "teacher",
      "scared",
      "relax",
      "all",
      "students",
    ],
    words: [
      "she",
      "makes",
      "a",
      "speech",
      "has",
      "to",
      "make",
      "about",
      "birds",
      "is",
      "nervous",
      "does",
      "not",
      "like",
      "talking",
      "in",
      "front",
      "of",
      "people",
      "want",
      "mess",
      "up",
      "fun",
      "her",
      "tells",
      "teacher",
      "scared",
      "the",
      "relax",
      "all",
      "students",
      "are",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2071.htm",
    id: "She makes a speech\n\nShe has to make a speech about birds. She is nervous. She does not like talking in front of people. She does not want to mess up. She does not want people to make fun of her. She tells her teacher she is scared. The teacher tells her to relax. All the students are scared.",
    story_id: 490,
    word_count: 32,
    all_intersection_count: 16,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "lot",
      "pants",
      "two",
      "pairs",
      "jeans",
      "one",
      "light",
      "blue",
      "navy",
      "formal",
      "khaki",
      "gray",
      "casual",
      "on",
      "hearts",
      "wears",
    ],
    words: [
      "a",
      "lot",
      "of",
      "pants",
      "she",
      "has",
      "two",
      "pairs",
      "jeans",
      "one",
      "them",
      "is",
      "light",
      "blue",
      "the",
      "other",
      "navy",
      "formal",
      "khaki",
      "gray",
      "casual",
      "flowers",
      "on",
      "it",
      "hearts",
      "wears",
      "every",
      "day",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke099.htm",
    id: "A lot of pants\n\nShe has a lot of pants. She has two pairs of jeans. One of them is light blue. The other one is navy blue. She has two pairs of formal pants. One of them is khaki. The other one is gray. She has two pairs of casual pants. One of them has flowers on it. The other one has hearts on it. She wears pants every day.",
    story_id: 418,
    word_count: 28,
    all_intersection_count: 12,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "taking",
      "tests",
      "test",
      "instead",
      "video",
      "games",
      "next",
      "takes",
      "hard",
      "anything",
      "turns",
      "his",
      "blank",
      "teacher",
      "scolds",
      "F",
    ],
    words: [
      "taking",
      "tests",
      "he",
      "has",
      "a",
      "test",
      "tomorrow",
      "does",
      "not",
      "want",
      "to",
      "study",
      "instead",
      "plays",
      "video",
      "games",
      "the",
      "next",
      "day",
      "takes",
      "it",
      "is",
      "very",
      "hard",
      "know",
      "anything",
      "turns",
      "in",
      "his",
      "blank",
      "teacher",
      "scolds",
      "him",
      "and",
      "gives",
      "an",
      "F",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke033.htm",
    id: 'Taking tests\n\nHe has a test tomorrow. He does not want to study. Instead, he plays video games. The next day, he takes the test. It is very hard. He does not know anything. He turns in his test blank. The teacher scolds him and gives him an "F."',
    story_id: 363,
    word_count: 37,
    all_intersection_count: 21,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "simple",
      "things",
      "bring",
      "joy",
      "being",
      "fills",
      "my",
      "soul",
      "everything",
      "freedom",
      "kindness",
      "smile",
      "sets",
      "heart",
      "on",
      "fire",
    ],
    words: [
      "it's",
      "the",
      "simple",
      "things",
      "that",
      "bring",
      "me",
      "joy",
      "being",
      "with",
      "you",
      "fills",
      "my",
      "soul",
      "everything",
      "money",
      "can't",
      "buy",
      "like",
      "love",
      "and",
      "freedom",
      "kindness",
      "a",
      "smile",
      "sets",
      "heart",
      "on",
      "fire",
    ],
    link: "https://www.familyfriendpoems.com/poem/its-the-simple-things",
    id: "It’s the simple things\nthat bring me joy\nit’s being with you\nthat fills my soul\nit’s everything\nthat money can’t buy\nlike love\nand freedom\nand kindness\nand a smile\nthat sets my heart\non fire",
    story_id: 929,
    word_count: 29,
    all_intersection_count: 13,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "Lord",
      "my",
      "prayer",
      "oh",
      "dear",
      "heart",
      "aching",
      "mind",
      "isn't",
      "clear",
      "left",
      "hurts",
      "soul",
      "without",
      "feel",
      "whole",
    ],
    words: [
      "Lord",
      "hear",
      "my",
      "prayer",
      "oh",
      "so",
      "dear",
      "heart",
      "is",
      "aching",
      "mind",
      "isn't",
      "clear",
      "love",
      "has",
      "left",
      "me",
      "it",
      "hurts",
      "soul",
      "without",
      "that",
      "I",
      "don't",
      "feel",
      "whole",
    ],
    link: "https://www.familyfriendpoems.com/poem/my-love-is-gone-3",
    id: "Lord, hear my prayer oh so dear.\nMy heart is aching; my mind isn't clear.\nMy love has left me; it hurts my soul.\nWithout that love, I don't feel whole.",
    story_id: 920,
    word_count: 26,
    all_intersection_count: 10,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "killing",
      "ant",
      "cannot",
      "kill",
      "person",
      "against",
      "law",
      "will",
      "jail",
      "else's",
      "too",
      "one",
      "ants",
      "your",
      "food",
      "invade",
    ],
    words: [
      "killing",
      "an",
      "ant",
      "you",
      "cannot",
      "kill",
      "a",
      "person",
      "that",
      "is",
      "against",
      "the",
      "law",
      "will",
      "go",
      "to",
      "jail",
      "someone",
      "else's",
      "dog",
      "too",
      "can",
      "no",
      "one",
      "likes",
      "ants",
      "eat",
      "your",
      "food",
      "invade",
      "house",
      "not",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2042.htm",
    id: "Killing an ant\n\nYou cannot kill a person. That is against the law. You will go to jail. You cannot kill someone else's dog. That is against the law, too. You can kill an ant. No one likes ants. Ants eat your food. Ants invade your house. Killing ants is not against the law.",
    story_id: 454,
    word_count: 32,
    all_intersection_count: 16,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "one",
      "flue",
      "sick",
      "goes",
      "medicine",
      "still",
      "after",
      "his",
      "mom",
      "tells",
      "sleep",
      "lies",
      "bed",
      "all",
      "rests",
      "flu",
    ],
    words: [
      "no",
      "one",
      "likes",
      "the",
      "flue",
      "he",
      "is",
      "sick",
      "goes",
      "to",
      "doctor",
      "gives",
      "him",
      "some",
      "medicine",
      "still",
      "after",
      "week",
      "his",
      "mom",
      "tells",
      "go",
      "sleep",
      "lies",
      "in",
      "bed",
      "all",
      "day",
      "rests",
      "drinks",
      "chicken",
      "soup",
      "and",
      "eats",
      "vegetables",
      "flu",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke039.htm",
    id: "No one likes the flue\n\nHe is sick. He goes to the doctor. The doctor gives him some medicine. He is still sick after one week. His mom tells him to go to sleep. He lies in bed all day. He rests. He drinks chicken soup and eats vegetables. He is still sick. No one likes the flu.",
    story_id: 355,
    word_count: 36,
    all_intersection_count: 20,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "were",
      "before",
      "said",
      "smart",
      "admired",
      "your",
      "smarts",
      "funny",
      "laughed",
      "at",
      "jokes",
      "loved",
      "spoke",
      "those",
      "three",
      "words",
    ],
    words: [
      "you",
      "were",
      "beautiful",
      "before",
      "they",
      "said",
      "are",
      "smart",
      "admired",
      "your",
      "smarts",
      "funny",
      "laughed",
      "at",
      "jokes",
      "loved",
      "spoke",
      "those",
      "three",
      "words",
    ],
    link: "https://www.familyfriendpoems.com/poem/you-already-were",
    id: "You were beautiful\nbefore they said, you are beautiful\n\nYou were smart\nbefore they admired your smarts\n\nYou were funny\nbefore they laughed at your jokes\n\nYou were loved\nbefore they spoke those three words",
    story_id: 931,
    word_count: 20,
    all_intersection_count: 4,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "lot",
      "ants",
      "slice",
      "drops",
      "crumb",
      "smell",
      "crawl",
      "towards",
      "notices",
      "kill",
      "gets",
      "puts",
      "inside",
      "opens",
      "window",
      "lets",
    ],
    words: [
      "a",
      "lot",
      "of",
      "ants",
      "she",
      "eats",
      "slice",
      "cake",
      "drops",
      "crumb",
      "the",
      "can",
      "smell",
      "it",
      "they",
      "crawl",
      "towards",
      "notices",
      "does",
      "not",
      "want",
      "to",
      "kill",
      "them",
      "gets",
      "cup",
      "puts",
      "inside",
      "opens",
      "window",
      "lets",
      "go",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke018.htm",
    id: "A lot of ants\n\nShe eats a slice of cake. She drops a crumb. The ants can smell it. They crawl towards the crumb. She notices the ants. She does not want to kill them. She gets a cup. She puts the ants inside. She opens the window. She lets the ants go.",
    story_id: 334,
    word_count: 32,
    all_intersection_count: 16,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "lot",
      "line",
      "went",
      "amusement",
      "there",
      "rides",
      "scary",
      "kids",
      "food",
      "choices",
      "cotton",
      "corn",
      "many",
      "saturday",
      "off",
      "lines",
      "everything",
    ],
    words: [
      "a",
      "lot",
      "of",
      "people",
      "in",
      "line",
      "they",
      "went",
      "to",
      "an",
      "amusement",
      "park",
      "there",
      "are",
      "rides",
      "some",
      "scary",
      "and",
      "for",
      "kids",
      "food",
      "choices",
      "is",
      "cotton",
      "candy",
      "corn",
      "dogs",
      "so",
      "many",
      "it",
      "saturday",
      "off",
      "work",
      "school",
      "lines",
      "everything",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2068.htm",
    id: "A lot of people in line\n\nThey went to an amusement park. There are a lot of rides. Some are scary and some are for kids. There are a lot of food choices. There is cotton candy. There are corn dogs. There are so many people. It is Saturday. People are off work and school. There are lines for everything.",
    story_id: 480,
    word_count: 36,
    all_intersection_count: 19,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "little",
      "baby",
      "oh",
      "small",
      "one",
      "will",
      "big",
      "tall",
      "watch",
      "while",
      "laugh",
      "my",
      "grows",
      "tell",
      "whole",
      "heart",
      "way",
    ],
    words: [
      "little",
      "baby",
      "oh",
      "so",
      "small",
      "one",
      "day",
      "you",
      "will",
      "be",
      "big",
      "and",
      "tall",
      "I",
      "watch",
      "while",
      "laugh",
      "play",
      "my",
      "love",
      "for",
      "grows",
      "every",
      "tell",
      "this",
      "with",
      "whole",
      "heart",
      "just",
      "the",
      "way",
      "are",
    ],
    link: "https://www.familyfriendpoems.com/poem/mothers-short-poem-to-her-little-baby",
    id: "Little baby, oh so small\nOne day you will be big and tall\nI watch you while you laugh and play\nMy love for you grows every day\nI tell you this with my whole heart\nI love you just the way you are",
    story_id: 914,
    word_count: 32,
    all_intersection_count: 15,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "bored",
      "tries",
      "fun",
      "yarn",
      "scratches",
      "his",
      "tummy",
      "takes",
      "nap",
      "milk",
      "still",
      "sees",
      "cheese",
      "steals",
      "angry",
      "chases",
      "having",
    ],
    words: [
      "a",
      "cat",
      "and",
      "mouse",
      "the",
      "is",
      "bored",
      "he",
      "tries",
      "to",
      "have",
      "fun",
      "plays",
      "with",
      "yarn",
      "scratches",
      "his",
      "tummy",
      "takes",
      "nap",
      "drinks",
      "milk",
      "still",
      "sees",
      "eating",
      "cheese",
      "steals",
      "angry",
      "chases",
      "having",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke013.htm",
    id: "A cat and a mouse\n\nThe cat is bored. He tries to have fun. He plays with yarn. He scratches his tummy. He takes a nap. He drinks milk. He is still bored. He sees a mouse. The mouse is eating cheese. The cat steals the cheese. The mouse is angry. The mouse chases the cat. The cat is having fun.",
    story_id: 343,
    word_count: 30,
    all_intersection_count: 13,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "white",
      "short",
      "legs",
      "pointy",
      "ears",
      "runs",
      "fast",
      "throws",
      "toy",
      "across",
      "room",
      "tells",
      "get",
      "watches",
      "back",
      "dog's",
      "mouth",
    ],
    words: [
      "a",
      "white",
      "dog",
      "she",
      "has",
      "it",
      "is",
      "short",
      "legs",
      "pointy",
      "ears",
      "runs",
      "very",
      "fast",
      "plays",
      "with",
      "the",
      "throws",
      "toy",
      "across",
      "room",
      "tells",
      "to",
      "go",
      "get",
      "watches",
      "back",
      "her",
      "in",
      "dog's",
      "mouth",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2043.htm",
    id: "A white dog\n\nShe has a dog. It is white. It has short legs. It has pointy ears. It runs very fast. She plays with the dog. She throws a toy across the room. She tells the dog to go get it. The dog runs. She watches it. The dog runs back to her. The toy is in the dog's mouth.",
    story_id: 455,
    word_count: 31,
    all_intersection_count: 14,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "will",
      "mister",
      "henderson",
      "back",
      "soon",
      "he'll",
      "20",
      "minutes",
      "smith",
      "here",
      "she'll",
      "half",
      "hour",
      "train",
      "arrive",
      "it'll",
      "5",
    ],
    words: [
      "will",
      "mister",
      "henderson",
      "be",
      "back",
      "soon",
      "yes",
      "he",
      "he'll",
      "in",
      "about",
      "20",
      "minutes",
      "doctor",
      "smith",
      "here",
      "she",
      "she'll",
      "half",
      "an",
      "hour",
      "the",
      "train",
      "arrive",
      "it",
      "it'll",
      "5",
    ],
    link: "https://www.youtube.com/watch?v=5Nzr30SYy3g&list=PLD7AA7B1BC72ACC28&index=7",
    id: "Will mister Henderson be back soon?\nYes. He will.\nHe'll be back in about 20 minutes.\n\nWill doctor Smith be here soon?\nYes. She will. She'll be here in half an hour.\n\nWill the train arrive soon?\nYes, it will. It'll arrive in 5 minutes.",
    story_id: 1027,
    word_count: 27,
    all_intersection_count: 10,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "returning",
      "dress",
      "brings",
      "back",
      "store",
      "too",
      "big",
      "smaller",
      "size",
      "returns",
      "gets",
      "sees",
      "another",
      "pretty",
      "cheaper",
      "than",
      "dresses",
    ],
    words: [
      "returning",
      "the",
      "dress",
      "she",
      "brings",
      "her",
      "back",
      "to",
      "store",
      "it",
      "is",
      "too",
      "big",
      "needs",
      "a",
      "smaller",
      "size",
      "does",
      "not",
      "have",
      "returns",
      "gets",
      "money",
      "sees",
      "another",
      "likes",
      "very",
      "pretty",
      "buys",
      "cheaper",
      "than",
      "other",
      "dresses",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2057.htm",
    id: "Returning the dress\n\nShe brings her dress back to the store. It is too big. She needs a smaller size. The store does not have a smaller size. She returns it. She gets her money back. She sees another dress she likes. It is very pretty. She buys it. It is cheaper than the other dresses.",
    story_id: 483,
    word_count: 33,
    all_intersection_count: 16,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "trip",
      "library",
      "Mark",
      "book",
      "his",
      "mom",
      "takes",
      "borrow",
      "books",
      "free",
      "enters",
      "there",
      "many",
      "pirates",
      "science",
      "borrows",
      "all",
    ],
    words: [
      "a",
      "trip",
      "to",
      "the",
      "library",
      "Mark",
      "needs",
      "book",
      "he",
      "does",
      "not",
      "have",
      "money",
      "his",
      "mom",
      "takes",
      "him",
      "can",
      "borrow",
      "books",
      "for",
      "free",
      "enters",
      "there",
      "are",
      "so",
      "many",
      "about",
      "animals",
      "pirates",
      "science",
      "borrows",
      "them",
      "all",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke017.htm",
    id: "A trip to the library\n\nMark needs a book. He does not have money. His mom takes him to the library. Mark can borrow books for free. Mark enters the library. There are so many books. There are books about animals. There are books about pirates. There are books about science. Mark borrows them all.",
    story_id: 333,
    word_count: 34,
    all_intersection_count: 17,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "18th",
      "birthday",
      "18",
      "lot",
      "cigarettes",
      "bad",
      "though",
      "full-time",
      "throws",
      "party",
      "herself",
      "come",
      "gets",
      "gifts",
      "cousin",
      "pack",
      "says",
    ],
    words: [
      "the",
      "18th",
      "birthday",
      "she",
      "is",
      "now",
      "18",
      "can",
      "do",
      "a",
      "lot",
      "buy",
      "cigarettes",
      "are",
      "bad",
      "for",
      "you",
      "though",
      "work",
      "full-time",
      "throws",
      "party",
      "herself",
      "of",
      "people",
      "come",
      "gets",
      "gifts",
      "her",
      "cousin",
      "gives",
      "pack",
      "says",
      "does",
      "not",
      "want",
      "it",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2089.htm",
    id: "The 18th birthday\n\nShe is now 18. She can do a lot now. She can buy cigarettes. Cigarettes are bad for you, though. She can work full-time. She throws a party for herself. A lot of people come. She gets a lot of gifts. Her cousin gives her a pack of cigarettes. She says she does not want it.",
    story_id: 501,
    word_count: 37,
    all_intersection_count: 20,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "puts",
      "shoes",
      "on",
      "leaves",
      "apartment",
      "runs",
      "look",
      "at",
      "houses",
      "feeling",
      "wind",
      "face",
      "gets",
      "sees",
      "teacher",
      "back",
      "home",
    ],
    words: [
      "she",
      "likes",
      "running",
      "puts",
      "her",
      "shoes",
      "on",
      "leaves",
      "apartment",
      "runs",
      "to",
      "school",
      "look",
      "at",
      "other",
      "houses",
      "the",
      "feeling",
      "of",
      "wind",
      "face",
      "gets",
      "sees",
      "teacher",
      "does",
      "not",
      "like",
      "that",
      "back",
      "home",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2037.htm",
    id: "She likes running\n\nShe puts her running shoes on. She leaves her apartment. She runs to her school. She likes to look at other houses. She likes the feeling of the wind on her face. She gets to the school. She sees her teacher. She does not like that teacher. She runs back home.",
    story_id: 463,
    word_count: 30,
    all_intersection_count: 13,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "-",
      "task",
      "was",
      "appointed",
      "find",
      "way",
      "one",
      "will",
      "then",
      "must",
      "afraid",
      "even",
      "smallest",
      "person",
      "change",
      "course",
      "future",
    ],
    words: [
      "-",
      "this",
      "task",
      "was",
      "appointed",
      "to",
      "you",
      "and",
      "if",
      "do",
      "not",
      "find",
      "a",
      "way",
      "no",
      "one",
      "will",
      "then",
      "I",
      "know",
      "what",
      "must",
      "it's",
      "just",
      "I'm",
      "afraid",
      "it",
      "even",
      "the",
      "smallest",
      "person",
      "can",
      "change",
      "course",
      "of",
      "future",
    ],
    link: "https://www.youtube.com/watch?v=oRjMmvgwX8k",
    id: "- This task was appointed to you, and if you do not find a way, no one will.\n- Then I know what I must do. It's just... I'm afraid to do it.\n- Even the smallest person can change the course of the future.",
    story_id: 942,
    word_count: 36,
    all_intersection_count: 19,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "writes",
      "essay",
      "taking",
      "class",
      "write",
      "on",
      "penguins",
      "finds",
      "information",
      "from",
      "internet",
      "books",
      "first",
      "sentence",
      "riddle",
      "last",
      "answer",
    ],
    words: [
      "she",
      "writes",
      "an",
      "essay",
      "is",
      "taking",
      "English",
      "class",
      "has",
      "to",
      "write",
      "the",
      "on",
      "penguins",
      "finds",
      "information",
      "from",
      "internet",
      "books",
      "her",
      "first",
      "sentence",
      "a",
      "riddle",
      "about",
      "last",
      "answer",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2009.htm",
    id: "She writes an essay\n\nShe is taking an English class. She has to write an essay. The essay is on penguins. She finds information on penguins from the Internet. She finds information on penguins from books. Her first sentence is a riddle about penguins. Her last sentence is the answer to the riddle.",
    story_id: 435,
    word_count: 27,
    all_intersection_count: 10,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "clothes",
      "get",
      "fit",
      "into",
      "old",
      "anymore",
      "goes",
      "mall",
      "dresses",
      "skirts",
      "jackets",
      "pants",
      "shirts",
      "lot",
      "shopping",
      "bags",
      "wear",
    ],
    words: [
      "she",
      "needs",
      "new",
      "clothes",
      "to",
      "get",
      "does",
      "not",
      "fit",
      "into",
      "her",
      "old",
      "anymore",
      "goes",
      "the",
      "mall",
      "buys",
      "some",
      "dresses",
      "skirts",
      "jackets",
      "pants",
      "shirts",
      "has",
      "a",
      "lot",
      "of",
      "shopping",
      "bags",
      "can't",
      "wait",
      "wear",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2024.htm",
    id: "She needs new clothes\n\nShe needs to get new clothes. She does not fit into her old clothes anymore. She goes to the mall. She buys some dresses. She buys some skirts. She buys some jackets. She buys some pants. She buys some shirts. She has a lot of shopping bags. She can't wait to wear her new clothes.",
    story_id: 443,
    word_count: 32,
    all_intersection_count: 15,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "pouch",
      "interesting",
      "kangaroo's",
      "baby",
      "goes",
      "into",
      "safe",
      "there",
      "James",
      "would",
      "put",
      "food",
      "video",
      "games",
      "inside",
      "kangaroos",
      "different",
    ],
    words: [
      "the",
      "pouch",
      "of",
      "a",
      "kangaroo",
      "is",
      "an",
      "interesting",
      "animal",
      "it",
      "has",
      "kangaroo's",
      "baby",
      "goes",
      "into",
      "safe",
      "there",
      "James",
      "would",
      "like",
      "to",
      "have",
      "he",
      "not",
      "put",
      "in",
      "food",
      "and",
      "video",
      "games",
      "inside",
      "people",
      "kangaroos",
      "are",
      "different",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke063.htm",
    id: "The pouch of a kangaroo\n\nA kangaroo is an interesting animal. It has a pouch. A kangaroo's baby goes into the pouch. It is safe there. James would like to have a pouch. He would not put a baby in there. He would put food and video games inside. People and kangaroos are different.",
    story_id: 379,
    word_count: 35,
    all_intersection_count: 18,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "bad",
      "manners",
      "never",
      "shows",
      "any",
      "respect",
      "mother",
      "present",
      "grandparents",
      "come",
      "asks",
      "leave",
      "classmate",
      "something",
      "steals",
      "dad",
      "favor",
    ],
    words: [
      "bad",
      "manners",
      "she",
      "has",
      "never",
      "shows",
      "any",
      "respect",
      "her",
      "mother",
      "buys",
      "a",
      "present",
      "does",
      "not",
      "say",
      "thank",
      "you",
      "grandparents",
      "come",
      "to",
      "house",
      "asks",
      "them",
      "leave",
      "classmate",
      "something",
      "wants",
      "steals",
      "it",
      "dad",
      "for",
      "favor",
      "do",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke079.htm",
    id: "Bad manners\n\nShe has bad manners. She never shows any respect. Her mother buys her a present. She does not say thank you. Her grandparents come to her house. She asks them to leave. Her classmate has something she wants. She steals it. Her dad asks her for a favor. She does not do it.",
    story_id: 398,
    word_count: 34,
    all_intersection_count: 17,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "everything",
      "four",
      "inches",
      "long",
      "shiny",
      "pink",
      "eraser",
      "on",
      "one",
      "end",
      "even",
      "name",
      "uses",
      "write",
      "letters",
      "homework",
      "tests",
    ],
    words: [
      "a",
      "pencil",
      "for",
      "everything",
      "she",
      "has",
      "it",
      "is",
      "four",
      "inches",
      "long",
      "shiny",
      "and",
      "pink",
      "an",
      "eraser",
      "on",
      "one",
      "end",
      "even",
      "her",
      "name",
      "uses",
      "to",
      "write",
      "letters",
      "do",
      "homework",
      "tests",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke053.htm",
    id: "A pencil for everything\n\nShe has a pencil. It is four inches long. It is shiny and pink. It has an eraser on one end. It even has her name on it. She uses her pencil for everything. She uses it to write letters. She uses it to do her homework. She uses it on her tests.",
    story_id: 376,
    word_count: 29,
    all_intersection_count: 12,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "snack",
      "shop",
      "Kevin",
      "hungry",
      "goes",
      "sees",
      "potato",
      "chips",
      "much",
      "trying",
      "sweets",
      "avoid",
      "cavities",
      "healthy",
      "messy",
      "25",
      "cents",
    ],
    words: [
      "the",
      "snack",
      "shop",
      "Kevin",
      "is",
      "hungry",
      "he",
      "goes",
      "to",
      "sees",
      "potato",
      "chips",
      "doesn't",
      "like",
      "that",
      "much",
      "candy",
      "trying",
      "not",
      "eat",
      "sweets",
      "avoid",
      "cavities",
      "a",
      "banana",
      "likes",
      "bananas",
      "they",
      "are",
      "healthy",
      "it's",
      "messy",
      "buys",
      "for",
      "25",
      "cents",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1023.htm",
    id: "The snack shop\n\nKevin is hungry. He goes to the snack shop. He sees potato chips. He doesn't like potato chips that much. He sees candy. He is trying not to eat sweets to avoid cavities. He sees a banana. He likes bananas. They are healthy. It's not messy to eat. He buys a banana for 25 cents.",
    story_id: 247,
    word_count: 36,
    all_intersection_count: 19,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "-",
      "my",
      "father",
      "what's",
      "his",
      "name",
      "Paul",
      "paris",
      "doing",
      "standing",
      "front",
      "eiffel",
      "tower",
      "really",
      "nice",
      "photograph",
      "favorite",
    ],
    words: [
      "-",
      "who",
      "is",
      "he",
      "he's",
      "my",
      "father",
      "what's",
      "his",
      "name",
      "Paul",
      "where",
      "in",
      "paris",
      "doing",
      "standing",
      "front",
      "of",
      "the",
      "eiffel",
      "tower",
      "this",
      "a",
      "really",
      "nice",
      "photograph",
      "thank",
      "you",
      "it's",
      "favorite",
    ],
    link: "https://www.youtube.com/watch?v=KkV9MBLOomI&list=PL31D7CB49ABAAB4DB&index=8",
    id: "- Who is he?\n- He's my father.\n- What's his name?\n- His name is Paul.\n- Where is he?\n- He's in Paris.\n- What's he doing?\n- He's standing in front of the Eiffel Tower.\n- This is a really nice photograph.\n- Thank you.\n- It's my favorite photograph of my father.",
    story_id: 974,
    word_count: 30,
    all_intersection_count: 13,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "breaking",
      "up",
      "boyfriend",
      "lazy",
      "never",
      "anything",
      "job",
      "watches",
      "TV",
      "video",
      "games",
      "break",
      "hurt",
      "his",
      "feelings",
      "plans",
      "out",
    ],
    words: [
      "breaking",
      "up",
      "she",
      "does",
      "not",
      "like",
      "her",
      "boyfriend",
      "he",
      "is",
      "lazy",
      "never",
      "anything",
      "for",
      "have",
      "a",
      "job",
      "go",
      "to",
      "school",
      "just",
      "watches",
      "TV",
      "and",
      "plays",
      "video",
      "games",
      "wants",
      "break",
      "with",
      "him",
      "want",
      "hurt",
      "his",
      "feelings",
      "plans",
      "out",
      "what",
      "going",
      "say",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2063.htm",
    id: "Breaking up\n\nShe does not like her boyfriend. He is lazy. He never does anything for her. He does not have a job. He does not go to school. He just watches TV and plays video games. She wants to break up with him. She does not want to hurt his feelings. She plans out what she is going to say.",
    story_id: 475,
    word_count: 40,
    all_intersection_count: 23,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "smells",
      "hungry",
      "something",
      "coming",
      "from",
      "kitchen",
      "leaves",
      "living-room",
      "goes",
      "sees",
      "plate",
      "hot",
      "gets",
      "fork",
      "knife",
      "cuts",
      "piece",
      "sticks",
    ],
    words: [
      "chicken",
      "smells",
      "good",
      "he",
      "is",
      "hungry",
      "something",
      "it",
      "coming",
      "from",
      "the",
      "kitchen",
      "leaves",
      "living-room",
      "goes",
      "to",
      "sees",
      "a",
      "plate",
      "of",
      "hot",
      "gets",
      "fork",
      "and",
      "knife",
      "cuts",
      "piece",
      "sticks",
      "in",
      "eats",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2018.htm",
    id: "Chicken smells good\n\nHe is hungry. He smells something good. It is coming from the kitchen. He leaves the living-room. He goes to the kitchen. He sees a plate of chicken. It is hot. He gets a fork and knife. He cuts a piece of chicken. He sticks the fork in it. He eats the chicken.",
    story_id: 430,
    word_count: 30,
    all_intersection_count: 12,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "giving",
      "food",
      "car",
      "waits",
      "front",
      "move",
      "traffic",
      "bad",
      "man",
      "on",
      "street",
      "knocks",
      "window",
      "opens",
      "asks",
      "sandwich",
      "another",
      "driver",
    ],
    words: [
      "giving",
      "food",
      "she",
      "is",
      "in",
      "the",
      "car",
      "waits",
      "for",
      "front",
      "to",
      "move",
      "traffic",
      "bad",
      "a",
      "man",
      "on",
      "street",
      "knocks",
      "her",
      "window",
      "opens",
      "he",
      "asks",
      "money",
      "has",
      "no",
      "gives",
      "him",
      "sandwich",
      "so",
      "happy",
      "another",
      "driver",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2074.htm",
    id: "Giving food\n\nShe is in the car. She waits for the car in front to move. The traffic is bad. A man on the street knocks on her window. She opens the window. He asks for money. She has no money. She gives him a sandwich. He is so happy. He asks another driver for money.",
    story_id: 493,
    word_count: 34,
    all_intersection_count: 16,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "lemons",
      "sour",
      "stuff",
      "peels",
      "skin",
      "off",
      "lemon",
      "bites",
      "into",
      "spits",
      "out",
      "seeds",
      "mom",
      "says",
      "weird",
      "most",
      "another",
      "food",
    ],
    words: [
      "she",
      "eats",
      "lemons",
      "likes",
      "sour",
      "stuff",
      "are",
      "yellow",
      "peels",
      "the",
      "skin",
      "off",
      "lemon",
      "bites",
      "into",
      "it",
      "tastes",
      "good",
      "spits",
      "out",
      "seeds",
      "not",
      "her",
      "mom",
      "says",
      "is",
      "weird",
      "most",
      "people",
      "eat",
      "with",
      "another",
      "food",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2017.htm",
    id: "She eats lemons\n\nShe likes sour stuff. Lemons are sour. Lemons are yellow. She peels the skin off the lemon. She bites into the lemon. It tastes good. She spits out the seeds. The seeds are not good. Her mom says she is weird. Most people eat lemon with another food.",
    story_id: 429,
    word_count: 33,
    all_intersection_count: 15,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "parent-teacher",
      "conference",
      "mom",
      "meet",
      "Lisa's",
      "teacher",
      "smiles",
      "all",
      "sit",
      "down",
      "talks",
      "grades",
      "doing",
      "smart",
      "also",
      "behavior",
      "friendly",
      "everyone",
    ],
    words: [
      "parent-teacher",
      "conference",
      "Lisa",
      "and",
      "her",
      "mom",
      "go",
      "to",
      "school",
      "they",
      "meet",
      "with",
      "Lisa's",
      "teacher",
      "the",
      "smiles",
      "all",
      "sit",
      "down",
      "talks",
      "about",
      "grades",
      "she",
      "is",
      "doing",
      "very",
      "well",
      "smart",
      "also",
      "behavior",
      "friendly",
      "everyone",
      "happy",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke068.htm",
    id: "Parent-teacher conference\n\nLisa and her mom go to school. They meet with Lisa's teacher. The teacher smiles. They all sit down. The teacher talks about Lisa's grades. She is doing very well. She is very smart. The teacher also talks about Lisa's behavior. She is friendly to everyone. Her mom is happy.",
    story_id: 384,
    word_count: 33,
    all_intersection_count: 15,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "moving",
      "out",
      "another",
      "state",
      "excited",
      "also",
      "sad",
      "place",
      "meet",
      "start",
      "life",
      "leave",
      "family",
      "friends",
      "will",
      "miss",
      "lot",
      "things",
    ],
    words: [
      "moving",
      "out",
      "she",
      "is",
      "to",
      "another",
      "state",
      "excited",
      "also",
      "sad",
      "be",
      "in",
      "a",
      "new",
      "place",
      "meet",
      "people",
      "start",
      "life",
      "leave",
      "her",
      "family",
      "friends",
      "will",
      "miss",
      "lot",
      "of",
      "things",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2100.htm",
    id: "Moving out\n\nShe is moving to another state. She is excited. She is also sad. She is excited to be in a new place. She is excited to meet new people. She is excited to start a new life. She is sad to leave her family. She is sad to leave her friends. She will miss a lot of things.",
    story_id: 519,
    word_count: 28,
    all_intersection_count: 10,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "-",
      "saw",
      "yesterday",
      "didn't",
      "really",
      "when",
      "at",
      "4",
      "o'clock",
      "were",
      "jogging",
      "through",
      "wasn't",
      "was",
      "tennis",
      "guess",
      "made",
      "mistake",
    ],
    words: [
      "-",
      "I",
      "saw",
      "you",
      "yesterday",
      "but",
      "didn't",
      "see",
      "me",
      "really",
      "when",
      "at",
      "about",
      "4",
      "o'clock",
      "were",
      "jogging",
      "through",
      "the",
      "park",
      "that",
      "wasn't",
      "was",
      "playing",
      "tennis",
      "guess",
      "made",
      "a",
      "mistake",
    ],
    link: "(2)https://www.youtube.com/watch?v=jkOXNxoMClA",
    id: "- I saw you yesterday, but you didn't see me. Really?\n- When?\n- At about 4 o'clock. You were jogging through the park.\n- That wasn't me. Yesterday, at 4 o'clock, I was playing tennis.\n- I guess I made a mistake.",
    story_id: 967,
    word_count: 29,
    all_intersection_count: 11,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "mom",
      "dad",
      "living",
      "room",
      "doing",
      "watching",
      "channel",
      "7",
      "Betty's",
      "bedroom",
      "mom's",
      "dad's",
      "charlie's",
      "kitchen",
      "where's",
      "Charlie",
      "what's",
      "lunch",
    ],
    words: [
      "where",
      "are",
      "mom",
      "and",
      "dad",
      "they're",
      "in",
      "the",
      "living",
      "room",
      "what",
      "they",
      "doing",
      "watching",
      "channel",
      "7",
      "Betty's",
      "bedroom",
      "mom's",
      "dad's",
      "charlie's",
      "kitchen",
      "where's",
      "Charlie",
      "he's",
      "what's",
      "he",
      "eating",
      "lunch",
    ],
    link: "(3)https://www.youtube.com/watch?v=Ay2D8chFBKw&t=50s",
    id: "Where are mom and dad?\nThey're in the living room.\nWhat are they doing?\nWatching channel 7.\nBetty's in the bedroom.\nMom's in the living room.\nDad's in the living room.\nCharlie's in the kitchen.\nWhere's Charlie?\nHe's in the kitchen.\nWhat's he doing? Eating lunch.",
    story_id: 956,
    word_count: 29,
    all_intersection_count: 11,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "how",
      "much",
      "salt",
      "should",
      "put",
      "little",
      "too",
      "many",
      "onions",
      "salad",
      "few",
      "pepper",
      "stew",
      "eggs",
      "omelette",
      "one",
      "or",
      "two",
    ],
    words: [
      "how",
      "much",
      "salt",
      "should",
      "I",
      "put",
      "in",
      "the",
      "soup",
      "just",
      "a",
      "little",
      "not",
      "too",
      "many",
      "onions",
      "salad",
      "few",
      "pepper",
      "stew",
      "eggs",
      "omelette",
      "one",
      "or",
      "two",
    ],
    link: "(4)https://www.youtube.com/watch?v=WzZbCGxryIk&list=PLD7AA7B1BC72ACC28&index=4",
    id: "How much salt should I put in the soup?\nJust a little, not too much.\nHow many onions should I put in the salad?\nJust a few, not too many.\nHow much pepper should I put in the stew?\nJust a little, not too much.\nHow many eggs should I put in the omelette?\nJust a few, not too many.\nSalt in the soup, pepper in the stew.\nEggs in the omelette.\nJust a few.\nJust a little, not too much.\nNot too many, just a few.\nJust a few, not too many, not too many.\nJust one or two.",
    story_id: 1020,
    word_count: 25,
    all_intersection_count: 7,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "touch",
      "big",
      "German",
      "Shepherd",
      "around",
      "town",
      "strangers",
      "always",
      "up",
      "pet",
      "without",
      "asking",
      "gets",
      "mad",
      "touching",
      "pulls",
      "away",
      "from",
    ],
    words: [
      "don't",
      "touch",
      "the",
      "dog",
      "she",
      "has",
      "a",
      "big",
      "it",
      "is",
      "German",
      "Shepherd",
      "loves",
      "her",
      "walks",
      "around",
      "town",
      "strangers",
      "always",
      "go",
      "up",
      "to",
      "they",
      "pet",
      "without",
      "asking",
      "gets",
      "mad",
      "does",
      "not",
      "like",
      "people",
      "touching",
      "pulls",
      "away",
      "from",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2045.htm",
    id: "Don't touch the dog\n\nShe has a big dog. It is a German Shepherd. She loves her dog. She walks it around town. Strangers always go up to her dog. They pet it. They pet it without asking. She gets mad. She does not like people touching her dog. She pulls her dog away from people.",
    story_id: 457,
    word_count: 36,
    all_intersection_count: 18,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "bicycle",
      "left",
      "out",
      "storm",
      "protected",
      "rust",
      "begins",
      "form",
      "unless",
      "your",
      "slowly",
      "away",
      "provide",
      "little",
      "kindness",
      "when",
      "sky",
      "grey",
    ],
    words: [
      "love",
      "is",
      "like",
      "a",
      "bicycle",
      "left",
      "out",
      "in",
      "the",
      "storm",
      "if",
      "it's",
      "not",
      "protected",
      "rust",
      "begins",
      "to",
      "form",
      "so",
      "unless",
      "you",
      "want",
      "your",
      "slowly",
      "away",
      "provide",
      "little",
      "kindness",
      "when",
      "sky",
      "grey",
    ],
    link: "https://www.familyfriendpoems.com/poem/dont-neglect-your-love",
    id: "Love is like a bicycle\nLeft out in the storm\nIf it's not protected\nRust begins to form\n\nSo unless you want your bicycle\nTo slowly rust away,\nProvide a little kindness\nWhen the sky begins to grey.",
    story_id: 919,
    word_count: 31,
    all_intersection_count: 13,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "feelings",
      "shallow",
      "deep",
      "make",
      "smile",
      "weep",
      "we'll",
      "savor",
      "won't",
      "grounding",
      "uplifting",
      "long-lasting",
      "constantly",
      "shifting",
      "matter",
      "feeling",
      "only",
      "away",
    ],
    words: [
      "some",
      "feelings",
      "are",
      "shallow",
      "deep",
      "make",
      "us",
      "smile",
      "weep",
      "we",
      "love",
      "don't",
      "we'll",
      "savor",
      "won't",
      "grounding",
      "uplifting",
      "long-lasting",
      "constantly",
      "shifting",
      "no",
      "matter",
      "what",
      "I'm",
      "feeling",
      "today",
      "I",
      "know",
      "tomorrow",
      "is",
      "only",
      "a",
      "day",
      "away",
    ],
    link: "https://www.familyfriendpoems.com/poem/a-day-away-2",
    id: "Some feelings are shallow, some feelings are deep.\nSome make us smile, some make us weep.\n\nSome we love, some we don't.\nSome we'll savor, some we won't.\n\nSome grounding, some uplifting,\nSome long-lasting, some constantly shifting.\n\nNo matter what feelings I'm feeling today,\nI know tomorrow is only a day away.",
    story_id: 912,
    word_count: 34,
    all_intersection_count: 16,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "aunt",
      "uncle",
      "visits",
      "his",
      "different",
      "from",
      "each",
      "tall",
      "skinny",
      "fat",
      "short",
      "only",
      "quiet",
      "loud",
      "still",
      "when",
      "always",
      "fun",
    ],
    words: [
      "aunt",
      "and",
      "uncle",
      "he",
      "visits",
      "his",
      "they",
      "are",
      "very",
      "different",
      "from",
      "each",
      "other",
      "is",
      "tall",
      "skinny",
      "fat",
      "short",
      "likes",
      "vegetables",
      "only",
      "eats",
      "meat",
      "quiet",
      "loud",
      "still",
      "love",
      "like",
      "it",
      "when",
      "always",
      "has",
      "fun",
      "with",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke064.htm",
    id: "Aunt and uncle\n\nHe visits his aunt and uncle. They are very different from each other. His aunt is tall and skinny. His uncle is fat and short. His aunt likes vegetables. His uncle only eats meat. His aunt is quiet. His uncle is loud. They still love each other. They like it when he visits. He always has fun with his aunt and uncle.",
    story_id: 380,
    word_count: 34,
    all_intersection_count: 16,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "keeps",
      "finds",
      "on",
      "street",
      "there",
      "collar",
      "takes",
      "into",
      "his",
      "washes",
      "veterinarian",
      "sick",
      "will",
      "die",
      "soon",
      "surgery",
      "much",
      "decides",
    ],
    words: [
      "he",
      "keeps",
      "a",
      "dog",
      "finds",
      "on",
      "the",
      "street",
      "there",
      "is",
      "no",
      "collar",
      "likes",
      "dogs",
      "takes",
      "it",
      "into",
      "his",
      "house",
      "washes",
      "to",
      "veterinarian",
      "sick",
      "will",
      "die",
      "soon",
      "surgery",
      "not",
      "help",
      "that",
      "much",
      "decides",
      "let",
      "go",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2044.htm",
    id: "He keeps a dog\n\nHe finds a dog on the street. There is no collar. He likes dogs. He takes it into his house. He washes it. He takes it to the veterinarian. The dog is sick. It will die soon. The surgery will not help that much. He decides to let the dog go.",
    story_id: 456,
    word_count: 34,
    all_intersection_count: 16,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "nice",
      "teacher",
      "teaches",
      "third-graders",
      "says",
      "morning",
      "asks",
      "students",
      "how",
      "doing",
      "at",
      "job",
      "get",
      "lot",
      "teachers",
      "should",
      "more",
      "hard",
    ],
    words: [
      "a",
      "nice",
      "teacher",
      "she",
      "is",
      "teaches",
      "third-graders",
      "says",
      "good",
      "morning",
      "every",
      "day",
      "asks",
      "her",
      "students",
      "how",
      "they",
      "are",
      "doing",
      "the",
      "love",
      "at",
      "job",
      "does",
      "not",
      "get",
      "lot",
      "of",
      "money",
      "teachers",
      "should",
      "more",
      "work",
      "so",
      "hard",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2002.htm",
    id: 'A nice teacher\n\nShe is a teacher. She teaches third-graders. She says "good morning" every day. She asks her students how they are doing. The students love her. She is good at her job. She does not get a lot of money. Teachers should get more money. They work so hard.',
    story_id: 421,
    word_count: 35,
    all_intersection_count: 17,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "excited",
      "mail",
      "waits",
      "mailman",
      "package",
      "supposed",
      "come",
      "sits",
      "by",
      "window",
      "sees",
      "goes",
      "outside",
      "says",
      "goodbye",
      "each",
      "back",
      "his",
    ],
    words: [
      "excited",
      "about",
      "the",
      "mail",
      "boy",
      "waits",
      "for",
      "mailman",
      "a",
      "package",
      "is",
      "supposed",
      "to",
      "come",
      "today",
      "he",
      "sits",
      "by",
      "window",
      "sees",
      "walking",
      "goes",
      "outside",
      "says",
      "hello",
      "gives",
      "him",
      "they",
      "say",
      "goodbye",
      "each",
      "other",
      "back",
      "his",
      "house",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke050.htm",
    id: "Excited about the mail\n\nThe boy waits for the mailman. A package is supposed to come today. He sits by the window. He sees the mailman walking. He goes outside. He says hello to the mailman. The mailman gives him the package. They say goodbye to each other. The boy goes back to his house.",
    story_id: 373,
    word_count: 35,
    all_intersection_count: 17,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "hi",
      "doing",
      "fixing",
      "my",
      "sink",
      "what's",
      "Bob",
      "his",
      "car",
      "Mary",
      "cleaning",
      "room",
      "our",
      "apartment",
      "your",
      "children",
      "their",
      "homework",
    ],
    words: [
      "hi",
      "what",
      "are",
      "you",
      "doing",
      "I'm",
      "fixing",
      "my",
      "sink",
      "what's",
      "Bob",
      "he's",
      "his",
      "car",
      "Mary",
      "she's",
      "cleaning",
      "her",
      "room",
      "we're",
      "our",
      "apartment",
      "your",
      "children",
      "they're",
      "their",
      "homework",
    ],
    link: "https://www.youtube.com/watch?v=OLieQ0U3TA4&t=181s",
    id: "Hi.\nWhat are you doing?\nI'm fixing my sink.\nWhat's Bob doing?\nHe's fixing his car.\nWhat's Mary doing?\nShe's cleaning her room.\nWhat are you doing?\nWe're cleaning our apartment.\nWhat are your Children doing?\nThey're doing their homework.",
    story_id: 957,
    word_count: 27,
    all_intersection_count: 9,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "getting",
      "ready",
      "gets",
      "at",
      "library",
      "puts",
      "his",
      "science",
      "textbook",
      "backpack",
      "pencils",
      "pens",
      "eraser",
      "snack",
      "might",
      "get",
      "hungry",
      "notebook",
    ],
    words: [
      "getting",
      "ready",
      "to",
      "study",
      "he",
      "gets",
      "at",
      "the",
      "library",
      "puts",
      "his",
      "science",
      "textbook",
      "in",
      "backpack",
      "pencils",
      "and",
      "pens",
      "an",
      "eraser",
      "a",
      "snack",
      "might",
      "get",
      "hungry",
      "notebook",
      "is",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2006.htm",
    id: "Getting ready to study\n\nHe gets ready to study at the library. He puts his science textbook in his backpack. He puts his pencils and pens in his backpack. He puts an eraser in his backpack. He puts a snack in his backpack. He might get hungry. He puts a notebook in his backpack. He is ready to study!",
    story_id: 425,
    word_count: 27,
    all_intersection_count: 9,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "first",
      "song",
      "sits",
      "car",
      "dad",
      "turns",
      "on",
      "radio",
      "taps",
      "feet",
      "sways",
      "head",
      "laughs",
      "at",
      "too",
      "over",
      "different",
      "quietly",
    ],
    words: [
      "the",
      "first",
      "song",
      "she",
      "sits",
      "in",
      "car",
      "her",
      "dad",
      "turns",
      "on",
      "radio",
      "a",
      "plays",
      "taps",
      "feet",
      "sways",
      "head",
      "laughs",
      "at",
      "he",
      "likes",
      "too",
      "is",
      "over",
      "different",
      "does",
      "not",
      "like",
      "new",
      "quietly",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke006.htm",
    id: "The first song\n\nShe sits in the car. Her dad turns on the radio. A song plays. She taps her feet. She sways her head. Her dad laughs at her. He likes the song too. The song is over. The radio plays a different song. She does not like the new song. She sits quietly.",
    story_id: 329,
    word_count: 31,
    all_intersection_count: 13,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "luck",
      "charm",
      "ugly",
      "shirt",
      "when",
      "wears",
      "things",
      "happen",
      "wear",
      "something",
      "important",
      "happening",
      "on",
      "days",
      "tests",
      "impress",
      "feeling",
      "sad",
    ],
    words: [
      "good",
      "luck",
      "charm",
      "she",
      "has",
      "an",
      "ugly",
      "shirt",
      "it",
      "is",
      "her",
      "when",
      "wears",
      "things",
      "happen",
      "likes",
      "to",
      "wear",
      "something",
      "important",
      "happening",
      "on",
      "days",
      "tests",
      "wants",
      "impress",
      "someone",
      "feeling",
      "sad",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2065.htm",
    id: "Good luck charm\n\nShe has an ugly shirt. It is her good luck charm. When she wears it, good things happen. She likes to wear it when something important is happening. She wears it on days when she has tests. She wears it when she wants to impress someone. She wears it when she is feeling sad.",
    story_id: 477,
    word_count: 29,
    all_intersection_count: 11,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "germs",
      "wash",
      "your",
      "hands",
      "after",
      "toilet",
      "wet",
      "use",
      "soap",
      "carefully",
      "count",
      "20",
      "as",
      "make",
      "sure",
      "turn",
      "tap",
      "off",
      "dry",
    ],
    words: [
      "say",
      "no",
      "to",
      "germs",
      "wash",
      "your",
      "hands",
      "after",
      "you",
      "go",
      "the",
      "toilet",
      "wet",
      "with",
      "water",
      "use",
      "soap",
      "carefully",
      "count",
      "20",
      "as",
      "make",
      "sure",
      "turn",
      "tap",
      "off",
      "dry",
      "well",
    ],
    link: "https://learnenglishkids.britishcouncil.org/read-write/reading-practice/level-1-reading/bathroom-sign",
    id: "Say NO! To germs. Wash your hands after you go to the toilet!\n\nWet your hands with water.\nUse soap.\nWash your hands carefully.\nCount to 20 as you wash.\nMake sure you turn the tap off!\nDry your hands well.",
    story_id: 880,
    word_count: 28,
    all_intersection_count: 9,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "digs",
      "dig",
      "his",
      "favorite",
      "thing",
      "goes",
      "into",
      "owner's",
      "takes",
      "necklace",
      "puts",
      "mouth",
      "runs",
      "backyard",
      "hole",
      "paws",
      "inside",
      "covers",
      "dirt",
    ],
    words: [
      "the",
      "dog",
      "that",
      "digs",
      "likes",
      "to",
      "dig",
      "it",
      "is",
      "his",
      "favorite",
      "thing",
      "do",
      "he",
      "goes",
      "into",
      "owner's",
      "house",
      "takes",
      "a",
      "necklace",
      "puts",
      "in",
      "mouth",
      "runs",
      "backyard",
      "hole",
      "with",
      "paws",
      "inside",
      "covers",
      "dirt",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke058.htm",
    id: "The dog that digs\n\nThe dog likes to dig. It is his favorite thing to do. He goes into his owner's house. He takes a necklace. He puts the necklace in his mouth. He runs to the backyard. He digs a hole with his paws. He puts the necklace inside. He covers the hole with dirt.",
    story_id: 387,
    word_count: 32,
    all_intersection_count: 13,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "glass",
      "milk",
      "on",
      "desk",
      "near",
      "edge",
      "child",
      "looks",
      "at",
      "too",
      "both",
      "walk",
      "towards",
      "faster",
      "jumps",
      "tips",
      "over",
      "all",
      "floor",
    ],
    words: [
      "glass",
      "of",
      "milk",
      "on",
      "the",
      "desk",
      "a",
      "is",
      "it",
      "near",
      "edge",
      "child",
      "looks",
      "at",
      "wants",
      "to",
      "drink",
      "cat",
      "too",
      "they",
      "both",
      "walk",
      "towards",
      "faster",
      "jumps",
      "tips",
      "over",
      "all",
      "floor",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke047.htm",
    id: "Glass of milk on the desk\n\nA glass of milk is on the desk. It is near the edge. The child looks at the milk. The child wants to drink it. The cat looks at the milk. The cat wants to drink it too. They both walk towards the desk. The cat is faster. It jumps on the desk. The glass of milk tips over. The milk is all over the floor.",
    story_id: 370,
    word_count: 29,
    all_intersection_count: 10,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "his",
      "Brian",
      "three",
      "first",
      "one",
      "black",
      "second",
      "brown",
      "third",
      "gray",
      "big",
      "medium-sized",
      "small",
      "all",
      "at",
      "enjoy",
      "always",
      "seem",
      "when",
    ],
    words: [
      "he",
      "walks",
      "his",
      "dogs",
      "Brian",
      "has",
      "three",
      "the",
      "first",
      "one",
      "is",
      "black",
      "second",
      "brown",
      "third",
      "gray",
      "dog",
      "very",
      "big",
      "medium-sized",
      "small",
      "loves",
      "all",
      "of",
      "them",
      "with",
      "at",
      "park",
      "enjoy",
      "it",
      "they",
      "always",
      "seem",
      "happy",
      "when",
      "are",
      "walking",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke052.htm",
    id: "He walks his dogs\n\nBrian has three dogs. The first one is black. The second one is brown. The third one is gray. The black dog is very big. The brown dog is medium-sized. The gray dog is very small. Brian loves all of them. He walks with his dogs at the park The dogs enjoy it. They always seem happy when they are walking.",
    story_id: 375,
    word_count: 37,
    all_intersection_count: 18,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "selling",
      "bars",
      "Barbara",
      "$1",
      "each",
      "brings",
      "during",
      "lunch",
      "time",
      "sells",
      "classmates",
      "$3",
      "days",
      "one",
      "sell",
      "20",
      "earns",
      "uses",
      "things",
    ],
    words: [
      "selling",
      "candy",
      "bars",
      "Barbara",
      "buys",
      "for",
      "$1",
      "each",
      "she",
      "brings",
      "them",
      "to",
      "school",
      "during",
      "lunch",
      "time",
      "sells",
      "her",
      "classmates",
      "$3",
      "some",
      "days",
      "no",
      "one",
      "other",
      "can",
      "sell",
      "20",
      "earns",
      "money",
      "uses",
      "the",
      "buy",
      "things",
      "needs",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1010.htm",
    id: "Selling candy bars\n\nBarbara buys candy bars for $1 each. She brings them to school. During lunch time, she sells them to her classmates. She sells them for $3 each. Some days, no one buys her candy bars. Other days, she can sell 20 candy bars. She earns some money. She uses the money to buy things she needs.",
    story_id: 241,
    word_count: 35,
    all_intersection_count: 16,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "goes",
      "hat",
      "store",
      "bald",
      "get",
      "cover",
      "his",
      "baldness",
      "there",
      "lot",
      "different",
      "hats",
      "caps",
      "wear",
      "summer",
      "beanies",
      "winter",
      "beanie",
      "cap",
    ],
    words: [
      "he",
      "goes",
      "to",
      "a",
      "hat",
      "store",
      "is",
      "bald",
      "wants",
      "get",
      "cover",
      "his",
      "baldness",
      "there",
      "are",
      "lot",
      "of",
      "different",
      "hats",
      "caps",
      "they",
      "good",
      "wear",
      "in",
      "the",
      "summer",
      "beanies",
      "for",
      "winter",
      "buys",
      "beanie",
      "and",
      "cap",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2023.htm",
    id: "He goes to a hat store\n\nHe is bald. He wants to get a hat to cover his baldness. He goes to a hat store. There are a lot of different hats. There are caps. They are good to wear in the summer. There are beanies. They are good to wear for the winter. He buys a beanie and a cap.",
    story_id: 442,
    word_count: 33,
    all_intersection_count: 14,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "brush",
      "bus",
      "hey",
      "everybody",
      "find",
      "your",
      "toothbrush",
      "toothpaste",
      "fun",
      "teeth",
      "all",
      "won't",
      "show",
      "how",
      "move",
      "round",
      "brushed",
      "at",
      "back",
    ],
    words: [
      "brush",
      "bus",
      "hey",
      "everybody",
      "it's",
      "the",
      "find",
      "your",
      "toothbrush",
      "and",
      "toothpaste",
      "let's",
      "have",
      "fun",
      "teeth",
      "all",
      "them",
      "now",
      "won't",
      "you",
      "show",
      "me",
      "how",
      "move",
      "round",
      "brushed",
      "at",
      "back",
      "yes",
    ],
    link: "https://learnenglishkids.britishcouncil.org/listen-watch/songs/brush-bus",
    id: "Brush bus\n\nHey everybody it’s the brush bus\nFind your toothbrush and your toothpaste\nLet’s have fun and brush.\n\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\n\nMove your brush round and round\nMove your brush round and round\nMove your brush round and round\nMove your brush round and round.\n\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\n\nAll brushed at the back, brush at the back\nAll brushed at the back, brush at the back\nAll brushed at the back, brush at the back\nAll brushed at the back, yes, you brush at the back.",
    story_id: 853,
    word_count: 29,
    all_intersection_count: 10,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "fluffy",
      "rabbit",
      "pet",
      "his",
      "rabbit's",
      "name",
      "female",
      "white",
      "black",
      "eyes",
      "soft",
      "playful",
      "weighs",
      "seven",
      "pounds",
      "hop",
      "around",
      "carrots",
      "five",
    ],
    words: [
      "fluffy",
      "the",
      "rabbit",
      "he",
      "has",
      "a",
      "pet",
      "it",
      "is",
      "his",
      "rabbit's",
      "name",
      "female",
      "she",
      "white",
      "black",
      "eyes",
      "very",
      "soft",
      "playful",
      "weighs",
      "seven",
      "pounds",
      "likes",
      "to",
      "hop",
      "around",
      "house",
      "eat",
      "carrots",
      "gives",
      "five",
      "day",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke055.htm",
    id: "Fluffy the rabbit\n\nHe has a pet. It is a rabbit. His rabbit's name is Fluffy. Fluffy is female. She is white. She has black eyes. She is very soft. She is playful. She weighs seven pounds. She likes to hop around the house. She likes to eat carrots. He gives Fluffy five carrots a day.",
    story_id: 378,
    word_count: 33,
    all_intersection_count: 14,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "frying",
      "tofu",
      "slices",
      "up",
      "ten",
      "pieces",
      "puts",
      "pan",
      "on",
      "stove",
      "turns",
      "oil",
      "waits",
      "turn",
      "brown",
      "flips",
      "over",
      "side",
      "off",
    ],
    words: [
      "frying",
      "tofu",
      "he",
      "slices",
      "up",
      "in",
      "ten",
      "pieces",
      "puts",
      "the",
      "pan",
      "on",
      "stove",
      "turns",
      "oil",
      "waits",
      "for",
      "to",
      "turn",
      "brown",
      "flips",
      "over",
      "now",
      "other",
      "side",
      "is",
      "again",
      "off",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2019.htm",
    id: "Frying tofu\n\nHe slices up tofu in ten pieces. He puts the pan on the stove. He turns on the stove. He puts oil in the pan. He puts the tofu in the pan. He waits for the tofu to turn brown. He flips over the tofu. Now the other side is frying. He waits for the tofu to turn brown again. He turns off the stove.",
    story_id: 431,
    word_count: 28,
    all_intersection_count: 9,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "when",
      "nervous",
      "bite",
      "my",
      "nails",
      "perspire",
      "giggle",
      "guess",
      "angry",
      "shout",
      "face",
      "turns",
      "red",
      "never",
      "get",
      "whistle",
      "easy",
      "smile",
      "sing",
    ],
    words: [
      "what",
      "do",
      "you",
      "when",
      "you're",
      "nervous",
      "I",
      "bite",
      "my",
      "nails",
      "I'm",
      "let",
      "me",
      "see",
      "perspire",
      "giggle",
      "guess",
      "now",
      "angry",
      "shout",
      "face",
      "turns",
      "red",
      "don't",
      "know",
      "never",
      "get",
      "happy",
      "whistle",
      "that's",
      "easy",
      "smile",
      "sing",
      "like",
      "this",
    ],
    link: "(5)https://www.youtube.com/watch?v=vaq6pX7q15s&list=PL31D7CB49ABAAB4DB&index=18",
    id: "What do you do when you're nervous?\nI bite my nails.\n\nWhen I'm nervous, let me see. I perspire.\n\nI giggle. I guess I'm nervous now.\n\nWhat do you do when you're angry?\nI shout.\n\nWhen I'm angry, my face turns red.\n\nI don't know.\n\nI never get angry.\n\nWhat do you do when you're happy?\nI whistle.\n\nWhat do I do when I'm happy? That's easy. I smile.\n\nWhen I'm happy, I sing, like this...",
    story_id: 993,
    word_count: 35,
    all_intersection_count: 16,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "goes",
      "nurse",
      "out",
      "runs",
      "around",
      "falls",
      "down",
      "hurts",
      "cries",
      "gets",
      "up",
      "nice",
      "looks",
      "friendly",
      "lollipop",
      "bandage",
      "okay",
      "back",
      "class",
    ],
    words: [
      "she",
      "goes",
      "to",
      "the",
      "nurse",
      "out",
      "play",
      "runs",
      "around",
      "falls",
      "down",
      "it",
      "hurts",
      "cries",
      "gets",
      "up",
      "is",
      "nice",
      "looks",
      "friendly",
      "gives",
      "her",
      "a",
      "lollipop",
      "tastes",
      "good",
      "bandage",
      "okay",
      "now",
      "walks",
      "back",
      "class",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke003.htm",
    id: "She goes to the nurse\n\nShe goes out to play. She runs around. She falls down. It hurts. She cries. She gets up. She goes to the nurse. The nurse is nice. She looks friendly. The nurse gives her a lollipop. It tastes good. The nurse gives her a bandage. She is okay now. She walks back to class.",
    story_id: 326,
    word_count: 32,
    all_intersection_count: 13,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "how",
      "much",
      "milk",
      "too",
      "little",
      "okay",
      "here",
      "thanks",
      "many",
      "cookies",
      "few",
      "would",
      "nothat's",
      "fine",
      "take",
      "your",
      "sugar",
      "what's",
      "oh",
    ],
    words: [
      "how",
      "much",
      "milk",
      "do",
      "you",
      "want",
      "not",
      "too",
      "just",
      "a",
      "little",
      "okay",
      "here",
      "are",
      "thanks",
      "many",
      "cookies",
      "few",
      "would",
      "like",
      "some",
      "coffee",
      "yes",
      "please",
      "is",
      "that",
      "nothat's",
      "fine",
      "take",
      "in",
      "your",
      "but",
      "that's",
      "sugar",
      "so",
      "what's",
      "new",
      "with",
      "about",
      "oh",
    ],
    link: "https://www.youtube.com/watch?v=WzZbCGxryIk&list=PLD7AA7B1BC72ACC28&index=4",
    id: "How much milk do you want?\nNot too much, just a little.\nOkay. Here you are.\nThanks.\n\nHow many cookies do you want?\nNot too many, just a few.\nOkay. Here you are.\nThanks.\n\nWould you like some coffee?\nYes, please. Just a little.\nIs that too much?\nNo.That's fine. Thanks.\nDo you take milk in your coffee?\nYes, please. But not too much.\nThat's fine.\nSugar?\nJust a little, please.\nSo what's new with you?\nNot too much. How about you?\nOh, not too much.",
    story_id: 1016,
    word_count: 40,
    all_intersection_count: 21,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "on",
      "fire",
      "smells",
      "smoke",
      "sees",
      "coming",
      "out",
      "looks",
      "bad",
      "burning",
      "down",
      "calls",
      "local",
      "firefighters",
      "come",
      "ten",
      "minutes",
      "get",
      "hose",
    ],
    words: [
      "a",
      "house",
      "on",
      "fire",
      "he",
      "is",
      "walking",
      "to",
      "work",
      "smells",
      "smoke",
      "sees",
      "coming",
      "out",
      "of",
      "it",
      "looks",
      "bad",
      "the",
      "burning",
      "down",
      "calls",
      "local",
      "firefighters",
      "come",
      "in",
      "ten",
      "minutes",
      "they",
      "get",
      "people",
      "hose",
      "with",
      "water",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1087.htm",
    id: "A house on fire\n\nHe is walking to work. He smells smoke. He sees smoke coming out of a house. It looks bad. The house is burning down. He calls the local firefighters. The firefighters come in ten minutes. They get the people in the house out. They hose down the house with water.",
    story_id: 304,
    word_count: 34,
    all_intersection_count: 15,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "lonely",
      "everything",
      "alone",
      "watches",
      "TV",
      "by",
      "herself",
      "even",
      "watching",
      "family",
      "at",
      "restaurants",
      "on",
      "school's",
      "basketball",
      "team",
      "talk",
      "players",
      "game",
    ],
    words: [
      "the",
      "lonely",
      "girl",
      "she",
      "is",
      "does",
      "everything",
      "alone",
      "watches",
      "TV",
      "by",
      "herself",
      "not",
      "even",
      "like",
      "watching",
      "with",
      "her",
      "family",
      "eats",
      "at",
      "restaurants",
      "on",
      "school's",
      "basketball",
      "team",
      "talk",
      "to",
      "other",
      "players",
      "just",
      "plays",
      "game",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2064.htm",
    id: "The lonely girl\n\nShe is lonely. She does everything alone. She watches TV by herself. She does not even like watching TV with her family. She even eats alone at restaurants. She is on her school's basketball team. She does not talk to the other players. She just plays the game.",
    story_id: 476,
    word_count: 33,
    all_intersection_count: 14,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "cereal",
      "milk",
      "wakes",
      "up",
      "morning",
      "downstairs",
      "feels",
      "gets",
      "bowl",
      "spoon",
      "pours",
      "then",
      "takes",
      "bite",
      "sour",
      "looks",
      "at",
      "carton",
      "expired",
    ],
    words: [
      "cereal",
      "and",
      "milk",
      "for",
      "breakfast",
      "he",
      "wakes",
      "up",
      "in",
      "the",
      "morning",
      "walks",
      "downstairs",
      "feels",
      "like",
      "eating",
      "gets",
      "a",
      "bowl",
      "spoon",
      "pours",
      "then",
      "takes",
      "bite",
      "it",
      "tastes",
      "sour",
      "looks",
      "at",
      "carton",
      "is",
      "expired",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1020.htm",
    id: "Cereal and milk for breakfast\n\nHe wakes up in the morning. He walks downstairs. He feels like eating cereal. He gets a bowl and a spoon. He pours the cereal in the bowl. He then pours the milk in the bowl. He takes a bite. It tastes sour. He looks at the milk carton. The milk is expired.",
    story_id: 237,
    word_count: 32,
    all_intersection_count: 13,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "saving",
      "hears",
      "meow",
      "coming",
      "from",
      "looks",
      "closely",
      "at",
      "sees",
      "cute",
      "white",
      "stuck",
      "up",
      "brings",
      "down",
      "cat's",
      "owner",
      "$15",
      "own",
    ],
    words: [
      "saving",
      "a",
      "cat",
      "she",
      "hears",
      "meow",
      "it",
      "is",
      "coming",
      "from",
      "the",
      "tree",
      "looks",
      "closely",
      "at",
      "sees",
      "cute",
      "white",
      "stuck",
      "climbs",
      "up",
      "brings",
      "down",
      "cat's",
      "owner",
      "happy",
      "gives",
      "her",
      "$15",
      "buys",
      "own",
      "with",
      "money",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2041.htm",
    id: "Saving a cat\n\nShe hears a \"meow.\" It is coming from the tree. She looks closely at the tree. She sees a cute, white cat. It is stuck. She climbs up the tree. She brings the cat down. The cat's owner is happy. The cat's owner gives her $15. She buys her own cat with the money.",
    story_id: 453,
    word_count: 33,
    all_intersection_count: 14,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "lessons",
      "Betty",
      "sound",
      "look",
      "too",
      "taking",
      "class",
      "5",
      "students",
      "all",
      "beginners",
      "teacher",
      "skilled",
      "violinist",
      "been",
      "20",
      "years",
      "excited",
      "come",
    ],
    words: [
      "violin",
      "lessons",
      "Betty",
      "loves",
      "the",
      "sound",
      "of",
      "she",
      "likes",
      "look",
      "it",
      "too",
      "is",
      "taking",
      "now",
      "her",
      "class",
      "has",
      "5",
      "other",
      "students",
      "they",
      "are",
      "all",
      "beginners",
      "teacher",
      "a",
      "skilled",
      "violinist",
      "he",
      "been",
      "playing",
      "for",
      "20",
      "years",
      "excited",
      "to",
      "come",
      "every",
      "week",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1063.htm",
    id: "Violin lessons\n\nBetty loves the sound of the violin. She likes the look of it too. She is taking violin lessons now. Her violin class has 5 other students. They are all beginners. The teacher is a skilled violinist. He has been playing violin for 20 years. Betty is excited to come to the class every week.",
    story_id: 280,
    word_count: 40,
    all_intersection_count: 21,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "chili",
      "sauce",
      "lover",
      "noodles",
      "plain",
      "adds",
      "tries",
      "lot",
      "better",
      "starts",
      "put",
      "everything",
      "puts",
      "on",
      "french",
      "fries",
      "egg",
      "rolls",
      "even",
    ],
    words: [
      "chili",
      "sauce",
      "lover",
      "he",
      "eats",
      "noodles",
      "it",
      "is",
      "plain",
      "adds",
      "tries",
      "the",
      "tastes",
      "a",
      "lot",
      "better",
      "starts",
      "to",
      "put",
      "in",
      "everything",
      "puts",
      "on",
      "french",
      "fries",
      "soup",
      "egg",
      "rolls",
      "even",
      "rice",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke100.htm",
    id: "Chili sauce lover\n\nHe eats noodles. It is plain. He adds chili sauce. He tries the noodles. It tastes a lot better. He starts to put chili sauce in everything. He puts chili sauce on french fries. He puts chili sauce in soup. He puts chili sauce on egg rolls. He even puts chili sauce on rice.",
    story_id: 419,
    word_count: 30,
    all_intersection_count: 11,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "hot",
      "toppings",
      "there",
      "at",
      "baseball",
      "game",
      "one",
      "his",
      "dad",
      "himself",
      "ketchup",
      "mustard",
      "on",
      "relish",
      "or",
      "onions",
      "thinks",
      "too",
      "sweet",
    ],
    words: [
      "hot",
      "dog",
      "toppings",
      "there",
      "are",
      "dogs",
      "at",
      "the",
      "baseball",
      "game",
      "he",
      "buys",
      "one",
      "for",
      "his",
      "dad",
      "and",
      "himself",
      "likes",
      "ketchup",
      "mustard",
      "on",
      "but",
      "no",
      "relish",
      "or",
      "onions",
      "thinks",
      "is",
      "too",
      "sweet",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1018.htm",
    id: "Hot dog toppings\n\nThere are hot dogs at the baseball game. He buys one for his dad and one for himself. He likes ketchup and mustard on his hot dog, but no relish or onions. His dad likes onions and mustard on his hot dog, but no ketchup or relish. He thinks ketchup is too sweet.",
    story_id: 235,
    word_count: 31,
    all_intersection_count: 12,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "group",
      "project",
      "Ms",
      "Howard",
      "assigns",
      "students",
      "groups",
      "Adam",
      "Joe",
      "bill",
      "smart",
      "draws",
      "cooperative",
      "make",
      "turn",
      "their",
      "high",
      "grade",
      "joyful",
    ],
    words: [
      "group",
      "project",
      "Ms",
      "Howard",
      "assigns",
      "a",
      "she",
      "wants",
      "the",
      "students",
      "to",
      "work",
      "in",
      "groups",
      "Adam",
      "works",
      "with",
      "Joe",
      "and",
      "bill",
      "is",
      "smart",
      "draws",
      "well",
      "cooperative",
      "they",
      "make",
      "good",
      "turn",
      "their",
      "likes",
      "it",
      "gives",
      "them",
      "high",
      "grade",
      "are",
      "joyful",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke012.htm",
    id: "Group project\n\nMs. Howard assigns a project. She wants the students to work in groups. Adam works with Joe and Bill. Adam is smart. Joe draws well. Bill is cooperative. They make a good group. They turn in their project. Ms. Howard likes it. She gives them a high grade. Adam, Joe, and Bill are joyful.",
    story_id: 342,
    word_count: 38,
    all_intersection_count: 19,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "old",
      "first",
      "Barbara",
      "looks",
      "at",
      "backpack",
      "holes",
      "dirty",
      "one",
      "mom",
      "takes",
      "shopping",
      "there",
      "many",
      "backpacks",
      "stars",
      "another",
      "rainbow",
      "stripes",
    ],
    words: [
      "new",
      "and",
      "old",
      "tomorrow",
      "is",
      "the",
      "first",
      "day",
      "of",
      "school",
      "Barbara",
      "looks",
      "at",
      "her",
      "backpack",
      "it",
      "has",
      "holes",
      "dirty",
      "she",
      "needs",
      "a",
      "one",
      "mom",
      "takes",
      "shopping",
      "there",
      "are",
      "so",
      "many",
      "backpacks",
      "yellow",
      "stars",
      "another",
      "rainbow",
      "stripes",
      "dogs",
      "likes",
      "this",
      "buys",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke015.htm",
    id: "New and old\n\nTomorrow is the first day of school. Barbara looks at her backpack. It has holes. It is dirty. She needs a new one. Her mom takes her shopping. There are so many backpacks. One backpack has yellow stars. Another one has rainbow stripes. Another one has dogs. Barbara likes this one. She buys it.",
    story_id: 331,
    word_count: 40,
    all_intersection_count: 21,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "busy",
      "washing",
      "my",
      "hair",
      "roger",
      "cleaning",
      "his",
      "kitchen",
      "Julie",
      "fixing",
      "bicycle",
      "doing",
      "our",
      "exercises",
      "Tom",
      "Susan",
      "painting",
      "their",
      "garage",
    ],
    words: [
      "are",
      "you",
      "busy",
      "yes",
      "I",
      "am",
      "I'm",
      "washing",
      "my",
      "hair",
      "is",
      "roger",
      "he",
      "he's",
      "cleaning",
      "his",
      "kitchen",
      "Julie",
      "she",
      "she's",
      "fixing",
      "her",
      "bicycle",
      "we",
      "we're",
      "doing",
      "our",
      "exercises",
      "Tom",
      "and",
      "Susan",
      "they",
      "they're",
      "painting",
      "their",
      "garage",
    ],
    link: "(2)https://www.youtube.com/watch?v=OLieQ0U3TA4&t=181s",
    id: "Are you busy?\nYes, I am.\nI'm washing my hair.\nIs Roger busy.\nYes, he is. He's cleaning his kitchen.\nIs Julie busy. Yes, she is. She's fixing her bicycle\nAre you busy? Yes, we are.\nWe're doing our exercises.\nAre Tom and Susan busy.\nYes, they are. They're painting their garage.",
    story_id: 958,
    word_count: 36,
    all_intersection_count: 17,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "teacher",
      "introduces",
      "vision",
      "life",
      "make",
      "as",
      "sharp",
      "knife",
      "sight",
      "reach",
      "greatest",
      "height",
      "flight",
      "makes",
      "human",
      "being",
      "most",
      "intelligent",
      "respectable",
      "creature",
    ],
    words: [
      "a",
      "teacher",
      "introduces",
      "us",
      "to",
      "new",
      "vision",
      "of",
      "life",
      "make",
      "as",
      "sharp",
      "knife",
      "someone",
      "who",
      "gives",
      "sight",
      "reach",
      "the",
      "greatest",
      "height",
      "give",
      "flight",
      "so",
      "makes",
      "human",
      "being",
      "most",
      "intelligent",
      "and",
      "respectable",
      "creature",
    ],
    link: "https://www.familyfriendpoems.com/poem/a-teacher",
    id: "A teacher introduces us to a new vision of life,\nto make us as sharp as a knife.\n\nSomeone who gives us a new sight,\nto make us reach the greatest height,\nto give us the greatest flight.\n\nSo a teacher\nmakes a human being\nthe most intelligent and\nrespectable creature.",
    story_id: 922,
    word_count: 32,
    all_intersection_count: 12,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "pretend",
      "fun",
      "wears",
      "white",
      "coat",
      "pretends",
      "room",
      "medical",
      "office",
      "dad",
      "patient",
      "goes",
      "into",
      "tells",
      "how",
      "feeling",
      "says",
      "keeps",
      "coughing",
      "lot",
    ],
    words: [
      "playing",
      "a",
      "doctor",
      "she",
      "likes",
      "to",
      "pretend",
      "is",
      "for",
      "fun",
      "wears",
      "white",
      "coat",
      "pretends",
      "her",
      "room",
      "medical",
      "office",
      "dad",
      "he",
      "patient",
      "goes",
      "into",
      "tells",
      "how",
      "feeling",
      "says",
      "that",
      "keeps",
      "coughing",
      "him",
      "drink",
      "lot",
      "of",
      "water",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke060.htm",
    id: "Playing a doctor\n\nShe likes to pretend she is a doctor for fun. She wears a white coat. She pretends her room is a medical office. Her dad pretends he is a patient. Her dad goes into her room. He tells her how he is feeling. He says that he keeps coughing. She tells him to drink a lot of water.",
    story_id: 389,
    word_count: 35,
    all_intersection_count: 15,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "Christmas",
      "time",
      "dad",
      "Tim",
      "toy",
      "box",
      "takes",
      "off",
      "lid",
      "sees",
      "car",
      "red",
      "makes",
      "noises",
      "moves",
      "fast",
      "gift",
      "hugs",
      "his",
      "smiles",
    ],
    words: [
      "Christmas",
      "time",
      "it",
      "is",
      "dad",
      "gives",
      "Tim",
      "a",
      "toy",
      "the",
      "in",
      "box",
      "takes",
      "off",
      "lid",
      "he",
      "sees",
      "car",
      "red",
      "makes",
      "noises",
      "moves",
      "fast",
      "likes",
      "gift",
      "hugs",
      "his",
      "smiles",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke002.htm",
    id: "Christmas time\n\nIt is Christmas. Dad gives Tim a toy. The toy is in the box. Tim takes off the lid. He sees the toy. It is a car. The car is red. The car makes noises. The car moves fast. Tim likes the gift. He hugs his dad. Dad smiles.",
    story_id: 325,
    word_count: 28,
    all_intersection_count: 8,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "baby",
      "wanted",
      "loved",
      "said",
      "okay",
      "will",
      "both",
      "each",
      "make",
      "talked",
      "his",
      "parents",
      "asked",
      "problem",
      "two",
      "babies",
      "same",
      "thing",
      "had",
      "then",
    ],
    words: [
      "they",
      "want",
      "a",
      "baby",
      "she",
      "wanted",
      "to",
      "have",
      "girl",
      "he",
      "boy",
      "loved",
      "her",
      "said",
      "okay",
      "we",
      "will",
      "him",
      "both",
      "each",
      "other",
      "make",
      "happy",
      "talked",
      "his",
      "parents",
      "asked",
      "them",
      "what",
      "do",
      "no",
      "problem",
      "two",
      "babies",
      "and",
      "the",
      "same",
      "thing",
      "so",
      "had",
      "then",
    ],
    link: "https://www.eslfast.com/supereasy/se/supereasy165.htm",
    id: 'They want a baby\n\nShe wanted to have a baby. She wanted to have a baby girl. He wanted to have a baby. He wanted to have a baby boy. He loved her. He said, "Okay, we will have a baby girl." She loved him. She said, "Okay, we will have a baby boy." They both loved each other. They both wanted to make each other happy. He talked to his parents. He asked them what to do. She talked to her parents. She asked them what to do. His parents said, "No problem. Have two babies. Have a baby girl and a baby boy." Her parents said the same thing! So they had two babies. They had a baby girl. Then they had a baby boy.',
    story_id: 181,
    word_count: 41,
    all_intersection_count: 21,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "manners",
      "Michelle",
      "family",
      "peas",
      "asks",
      "mom",
      "will",
      "pass",
      "smiles",
      "plate",
      "gets",
      "gift",
      "from",
      "dad",
      "thanks",
      "sees",
      "classmate",
      "cry",
      "tissue",
      "really",
    ],
    words: [
      "good",
      "manners",
      "Michelle",
      "eats",
      "with",
      "her",
      "family",
      "she",
      "wants",
      "to",
      "eat",
      "peas",
      "asks",
      "mom",
      "will",
      "you",
      "please",
      "pass",
      "me",
      "the",
      "smiles",
      "gives",
      "plate",
      "gets",
      "a",
      "gift",
      "from",
      "dad",
      "thanks",
      "him",
      "sees",
      "classmate",
      "cry",
      "tissue",
      "has",
      "really",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke081.htm",
    id: 'Good manners\n\nMichelle eats with her family. She wants to eat peas. She asks her mom, "Will you please pass me the peas?" The mom smiles. She gives her the plate. Michelle gets a gift from her dad. She thanks him. Michelle sees a classmate cry. She gives the classmate a tissue. Michelle has really good manners.',
    story_id: 407,
    word_count: 36,
    all_intersection_count: 16,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "present",
      "his",
      "girlfriend",
      "favorite",
      "book",
      "gets",
      "wrapping",
      "paper",
      "puts",
      "on",
      "top",
      "folds",
      "tapes",
      "down",
      "writes",
      "name",
      "bow",
      "wrapped",
      "gift",
      "bag",
    ],
    words: [
      "he",
      "buys",
      "a",
      "present",
      "for",
      "his",
      "girlfriend",
      "it",
      "is",
      "her",
      "favorite",
      "book",
      "gets",
      "wrapping",
      "paper",
      "puts",
      "the",
      "on",
      "top",
      "of",
      "folds",
      "tapes",
      "down",
      "writes",
      "name",
      "bow",
      "wrapped",
      "in",
      "gift",
      "bag",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2059.htm",
    id: "He buys a present\n\nHe buys a present for his girlfriend. It is her favorite book. He gets wrapping paper. He puts the book on top of the paper. He folds the paper. He tapes the paper down. He writes her name on it. He puts a bow on the wrapped book. He puts the present in a gift bag.",
    story_id: 485,
    word_count: 30,
    all_intersection_count: 10,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "sits",
      "down",
      "on",
      "floor",
      "stare",
      "at",
      "puts",
      "his",
      "fingers",
      "strings",
      "song",
      "tap",
      "their",
      "feet",
      "ask",
      "another",
      "girlfriend",
      "arrives",
      "sings",
      "louder",
    ],
    words: [
      "he",
      "plays",
      "the",
      "guitar",
      "sits",
      "down",
      "on",
      "floor",
      "people",
      "stare",
      "at",
      "him",
      "puts",
      "his",
      "fingers",
      "strings",
      "a",
      "song",
      "is",
      "about",
      "love",
      "tap",
      "their",
      "feet",
      "they",
      "ask",
      "to",
      "play",
      "another",
      "happy",
      "girlfriend",
      "arrives",
      "sings",
      "louder",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2091.htm",
    id: "He plays the guitar\n\nHe sits down on the floor. People stare at him. He puts his fingers on the guitar strings. He plays a song. The song is about love. People tap their feet. They ask him to play another song. He is happy. He plays another song. His girlfriend arrives. He sings louder.",
    story_id: 503,
    word_count: 34,
    all_intersection_count: 14,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "stealing",
      "bad",
      "Donna",
      "uncle",
      "supplies",
      "shop",
      "sees",
      "pink",
      "eraser",
      "any",
      "puts",
      "pocket",
      "runs",
      "out",
      "store",
      "tells",
      "back",
      "makes",
      "apologize",
      "manager",
    ],
    words: [
      "stealing",
      "is",
      "bad",
      "Donna",
      "and",
      "her",
      "uncle",
      "go",
      "to",
      "a",
      "school",
      "supplies",
      "shop",
      "sees",
      "pink",
      "eraser",
      "she",
      "likes",
      "it",
      "does",
      "not",
      "have",
      "any",
      "money",
      "puts",
      "the",
      "in",
      "pocket",
      "runs",
      "out",
      "of",
      "store",
      "he",
      "tells",
      "that",
      "back",
      "makes",
      "apologize",
      "manager",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke084.htm",
    id: "Stealing is bad\n\nDonna and her uncle go to a school supplies shop. Donna sees a pink eraser. She likes it. She does not have any money. She puts the eraser in her pocket. She runs out of the store. Her uncle sees her. He tells her that stealing is bad. He puts the eraser back. He makes Donna apologize to the manager.",
    story_id: 410,
    word_count: 39,
    all_intersection_count: 19,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "stanley's",
      "international",
      "restaurant",
      "kind",
      "food",
      "stanley",
      "cook",
      "on",
      "monday",
      "cooks",
      "italian",
      "Wednesday",
      "chinese",
      "right",
      "Greek",
      "Tuesday",
      "puerto",
      "rican",
      "Thursday",
      "thanks",
    ],
    words: [
      "stanley's",
      "international",
      "restaurant",
      "what",
      "kind",
      "of",
      "food",
      "does",
      "stanley",
      "cook",
      "on",
      "monday",
      "he",
      "cooks",
      "italian",
      "thank",
      "you",
      "Wednesday",
      "chinese",
      "yes",
      "that's",
      "right",
      "Greek",
      "Tuesday",
      "puerto",
      "rican",
      "Thursday",
      "thanks",
    ],
    link: "(2)https://www.youtube.com/watch?v=X9QluYxyV00",
    id: "Stanley's International Restaurant.\nWhat kind of food does Stanley cook on Monday?\nOn Monday, he cooks Italian food.\nThank you.\n\nStanley's International Restaurant.\nWhat kind of food does Stanley cook on Wednesday?\nOn Wednesday, he cooks Chinese food.\nChinese food?\nYes. That's right. \nThank you.\n\nStanley's International Restaurant.\nDoes Stanley cook Greek food on Tuesday?\nYes. He does.\nThank you.\n\nStanley's restaurant.\nDoes Stanley cook Puerto Rican food on Thursday?\nYes, he does.\nThanks.",
    story_id: 961,
    word_count: 28,
    all_intersection_count: 8,
    new_words_count: 20,
  },
];

export default storiesData;
