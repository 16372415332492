import { Box, Button, Typography } from "@mui/material";
import { memo, useRef, useState } from "react";
import { Sentence } from "../../../types/supabase";
import UkrainianWord from "../../Sentence/UkrainianWord";
import ExampleSentenceItem from "./ExampleSentenceItem";

interface WordTranslationSectionProps {
  translation: string;
  examples?: boolean;
  short?: boolean;
  training?: boolean;
  isVisible?: boolean;
  sentences: Sentence[];
}

const WordTranslationSection = ({
  translation,
  short,
  training,
  isVisible,
  sentences,
}: WordTranslationSectionProps) => {
  const [isTranslated, setIsTranslated] = useState(false);

  const ref = useRef<HTMLElement>();

  if (isVisible === false) return null;

  if (training) {
    if (!isTranslated) {
      return (
        <Box textAlign="center">
          <Button variant="contained" onClick={() => setIsTranslated(true)}>
            Перекласти
          </Button>
        </Box>
      );
    }
    return (
      <Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          textAlign="center"
        >
          {/* center box to detect i in viewport */}
          <Box ref={ref} width={20} height={20} />
          <UkrainianWord translation={translation} />
        </Box>
      </Box>
    );
  }

  if (short) {
    return (
      <Box>
        <Typography variant="h5" color="primary.dark" gutterBottom>
          <UkrainianWord translation={translation} row />
        </Typography>
        {sentences.map(({ en, ua, new_word }, index) => {
          return (
            <Box py={0.3} key={index}>
              <ExampleSentenceItem
                en={en}
                ua={ua}
                highlight={new_word}
                lessonWords={[]}
              />
            </Box>
          );
        })}
      </Box>
    );
  }

  return null;
};

export default memo(WordTranslationSection);
