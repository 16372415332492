import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";

const DoWorkJob = () => {
  return (
    <>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I&nbsp;
          <Typography component="span" variant="h3" color="primary">
            do&nbsp;
          </Typography>
          my&nbsp;
          <Typography component="span" variant="h3" color="primary">
            job
          </Typography>
          .
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I&nbsp;
          <Typography component="span" variant="h3" color="primary">
            do&nbsp;
          </Typography>
          my&nbsp;
          <Typography component="span" variant="h3" color="primary">
            work
          </Typography>
          .
        </Typography>
      </Box>
      ❓
      <Typography variant="h3" gutterBottom fontWeight={400}>
        I&nbsp;
        <Typography component="span" variant="h3" color="primary">
          work
        </Typography>
        &nbsp;a&nbsp;
        <Typography component="span" variant="h3" color="primary">
          job
        </Typography>{" "}
        in finance.
      </Typography>
      <Typography variant="h3" gutterBottom fontWeight={400}>
        I&nbsp;
        <Typography component="span" variant="h3" color="primary">
          do
        </Typography>
        &nbsp;a&nbsp;
        <Typography component="span" variant="h3" color="primary">
          job
        </Typography>{" "}
        in finance.
      </Typography>
      <Typography variant="h3" gutterBottom fontWeight={400}>
        I&nbsp;have&nbsp;a&nbsp;
        <Typography component="span" variant="h3" color="primary">
          job
        </Typography>{" "}
        in finance.
      </Typography>
      <Typography variant="h3" gutterBottom fontWeight={400}>
        I&nbsp;
        <Typography component="span" variant="h3" color="primary">
          work
        </Typography>
        &nbsp;at&nbsp;a&nbsp;
        <Typography component="span" variant="h3" color="primary">
          job
        </Typography>{" "}
        in finance.
      </Typography>
    </>
  );
};

export default DoWorkJob;
