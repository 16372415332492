import { Box, BoxProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface VStackProps extends BoxProps {
  gap?: number;
}

const VStack: React.FC<PropsWithChildren<VStackProps>> = ({ children, ...rest }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      position="relative"
      gap={2}
      flexDirection="column"
      width="100%"
      overflow="auto"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default VStack;
