import {
  Badge,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useLessonsStore } from "../../../store/lessonsStore";
import AccentWord from "../../Difference/components/AccentWord";
import WordTranscription from "../../Word/WordTranscription";
import useGlobalWords from "../../hooks/getGlobalWords";
import ModalCloseButton from "../ModalCloseButton";
import VStack from "../VStack";

type CreateDataProp = {
  title: string;
  en: string;
  ua: string;
  description: string;
  examples: any[];
};

function createData(
  subjectPronoun: CreateDataProp,
  objectPronoun: CreateDataProp,
  adjectivePossessivePronoun: CreateDataProp,
  intensivePronoun: CreateDataProp,
  reflexivePronoun: CreateDataProp,
  independentPossessivePronoun: CreateDataProp,
) {
  return {
    subjectPronoun,
    objectPronoun,
    adjectivePossessivePronoun,
    intensivePronoun,
    reflexivePronoun,
    independentPossessivePronoun,
  };
}

const rows = [
  createData(
    {
      title: "Subject pronoun",
      en: "I",
      ua: "я",
      description: "Займенник, що означає мовця.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="I" /> speak English.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="I" /> go to work.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="I" /> study in school.
            </>
          ),
        },
      ],
    },
    {
      title: "Object pronoun",
      en: "me",
      ua: "мене, мені",
      description:
        "Означає мовця, використовується в об'єктному відмінку (після дієслова чи прийменника).",
      examples: [
        {
          lesson: 3,
          title: (
            <>
              Please give <AccentWord word="me" /> coffee.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Do you love <AccentWord word="me" />?
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Give it to <AccentWord word="me" />.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Give <AccentWord word="me" /> flowers.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Who do you want <AccentWord word="me" /> to be?
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Please give <AccentWord word="me" /> fruit
            </>
          ),
        },
      ],
    },
    {
      title: "Possessive pronoun (adjective)",
      en: "my",
      ua: "мій, моя, моє, мої",
      description: "Вказує на приналежність мовцю, стоїть перед іменником.",
      examples: [],
    },
    {
      title: "Intensive pronoun",
      en: "myself",
      ua: "сам, сама, себе",
      description: "Підкреслює, що дія виконується мовцем самостійно.",
      examples: [],
    },
    {
      title: "Reflexive pronoun",
      en: "myself",
      ua: "(себе)",
      description:
        "Вказує, що дія спрямована на самого мовця, часто перекладається дієсловом з '-ся' або взагалі опускається.",
      examples: [],
    },
    {
      title: "Independent possessive pronoun",
      en: "mine",
      ua: "мій, моя, моє, мої",
      description: "Замінює іменник з присвійним значенням.",
      examples: [],
    },
  ),
  createData(
    {
      title: "Subject pronoun",
      en: "you",
      ua: "ти, ви",
      description: "Займенник, що означає співрозмовника, однина або множина.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              Do <AccentWord word="you" /> speak English?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              Do <AccentWord word="you" /> study in school?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="You" /> need to do it.
            </>
          ),
        },
      ],
    },
    {
      title: "Object pronoun",
      en: "you",
      ua: "тебе, тобі, вас, вам",
      description:
        "Означає співрозмовника, однина або множина, використовується в об'єктному відмінку.",
      examples: [
        {
          lesson: 3,
          title: (
            <>
              I love <AccentWord word="you" />.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              I have to do it for <AccentWord word="you" />.
            </>
          ),
        },
      ],
    },
    {
      title: "Possessive pronoun (adjective)",
      en: "your",
      ua: "твій, твоя, твоє, твої, ваш, ваша, ваше, ваші",
      description:
        "Вказує на приналежність співрозмовнику, однина або множина, стоїть перед іменником.",
      examples: [],
    },
    {
      title: "Intensive pronoun",
      en: "yourself/yourselves",
      ua: "сам, сама, самі, себе",
      description: "Підкреслює, що дія виконується співрозмовником самостійно.",
      examples: [],
    },
    {
      title: "Reflexive pronoun",
      en: "yourself/yourselves",
      ua: "(себе)",
      description:
        "Вказує, що дія спрямована на самого співрозмовника, часто перекладається дієсловом з '-ся' або взагалі опускається.",
      examples: [],
    },
    {
      title: "Independent possessive pronoun",
      en: "yours",
      ua: "твій, твоя, твоє, твої, ваш, ваша, ваше, ваші",
      description: "Замінює іменник з присвійним значенням.",
      examples: [],
    },
  ),
  createData(
    {
      title: "Subject pronoun",
      en: "we",
      ua: "ми",
      description: "Займенник, що означає мовця та інших осіб.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="We" /> study in school.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="We" /> speak English.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="We" /> know it.
            </>
          ),
        },
      ],
    },
    {
      title: "Object pronoun",
      en: "us",
      ua: "нас, нам",
      description: "Означає мовця та інших осіб, використовується в об'єктному відмінку.",
      examples: [
        {
          lesson: 3,
          title: (
            <>
              Do they love <AccentWord word="us" />?
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              They love <AccentWord word="us" />.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              They do it for <AccentWord word="us" />.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              What do they what <AccentWord word="us" /> to do?
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              They don't want to do it for <AccentWord word="us" />.
            </>
          ),
        },
      ],
    },
    {
      title: "Possessive pronoun (adjective)",
      en: "our",
      ua: "наш, наша, наше, наші",
      description: "Вказує на приналежність мовцю та іншим особам, стоїть перед іменником.",
      examples: [],
    },
    {
      title: "Intensive pronoun",
      en: "ourselves",
      ua: "самі, собою",
      description: "Підкреслює, що дія виконується мовцем та іншими особами самостійно.",
      examples: [],
    },
    {
      title: "Reflexive pronoun",
      en: "ourselves",
      ua: "(себе)",
      description:
        "Вказує, що дія спрямована на мовця та інших осіб, часто перекладається дієсловом з '-ся' або взагалі опускається.",
      examples: [],
    },
    {
      title: "Independent possessive pronoun",
      en: "ours",
      ua: "наш, наша, наше, наші",
      description: "Замінює іменник з присвійним значенням.",
      examples: [],
    },
  ),
  createData(
    {
      title: "Subject pronoun",
      en: "they",
      ua: "вони",
      description: "Займенник, що означає інших осіб або предмети.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="They" /> study in school.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="They" /> study English.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="They" /> don't know it.
            </>
          ),
        },
      ],
    },
    {
      title: "Object pronoun",
      en: "them",
      ua: "їх, їм",
      description: "Означає інших осіб або предмети, використовується в об'єктному відмінку.",
      examples: [
        {
          lesson: 3,
          title: (
            <>
              What do we need to do for <AccentWord word="them" />?
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Pluck fruits but don't eat <AccentWord word="them" />.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Do we have to do it for <AccentWord word="them" />?
            </>
          ),
        },
      ],
    },
    {
      title: "Possessive pronoun (adjective)",
      en: "their",
      ua: "їхній, їхня, їхнє, їхні",
      description: "Вказує на приналежність іншим особам або предметам, стоїть перед іменником.",
      examples: [],
    },
    {
      title: "Intensive pronoun",
      en: "themself/themselves",
      ua: "самі, собою",
      description: "Підкреслює, що дія виконується іншими особами або предметами самостійно.",
      examples: [],
    },
    {
      title: "Reflexive pronoun",
      en: "themself/themselves",
      ua: "(себе)",
      description:
        "Вказує, що дія спрямована на самих себе (інші особи або предмети), часто перекладається дієсловом з '-ся' або взагалі опускається.",
      examples: [],
    },
    {
      title: "Independent possessive pronoun",
      en: "theirs",
      ua: "їхній, їхня, їхнє, їхні",
      description: "Замінює іменник з присвійним значенням.",
      examples: [],
    },
  ),
  createData(
    {
      title: "Subject pronoun",
      en: "he",
      ua: "він",
      description: "Займенник, що означає особу чоловічої статі.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="He" /> doesn't know it.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="He" /> works every day.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="He" /> studies English.
            </>
          ),
        },
      ],
    },
    {
      title: "Object pronoun",
      en: "him",
      ua: "його, йому",
      description: "Означає особу чоловічої статі, використовується в об'єктному відмінку.",
      examples: [
        {
          lesson: 3,
          title: (
            <>
              She wants <AccentWord word="him" /> to be happy.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              She does it for <AccentWord word="him" />.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Who do you want <AccentWord word="him" /> to be?
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              What do you want to give <AccentWord word="him" />?
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              What does she want to do for <AccentWord word="him" />?
            </>
          ),
        },
      ],
    },
    {
      title: "Possessive pronoun (adjective)",
      en: "his",
      ua: "його",
      description: "Вказує на приналежність особі чоловічої статі, стоїть перед іменником.",
      examples: [],
    },
    {
      title: "Intensive pronoun",
      en: "himself",
      ua: "сам, себе",
      description: "Підкреслює, що дія виконується особою чоловічої статі самостійно.",
      examples: [],
    },
    {
      title: "Reflexive pronoun",
      en: "himself",
      ua: "(себе)",
      description:
        "Вказує, що дія спрямована на саму особу чоловічої статі, часто перекладається дієсловом з '-ся' або взагалі опускається.",
      examples: [],
    },
    {
      title: "Independent possessive pronoun",
      en: "his",
      ua: "його",
      description: "Замінює іменник з присвійним значенням.",
      examples: [],
    },
  ),
  createData(
    {
      title: "Subject pronoun",
      en: "she",
      ua: "вона",
      description: "Займенник, що означає особу жіночої статі.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="She" /> knows it.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="She" /> studies in school.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="She" /> learns English.
            </>
          ),
        },
      ],
    },
    {
      title: "Object pronoun",
      en: "her",
      ua: "її, їй",
      description: "Означає особу жіночої статі, використовується в об'єктному відмінку.",
      examples: [
        {
          lesson: 3,
          title: (
            <>
              Do you love <AccentWord word="her" />?
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Does he love <AccentWord word="her" />?
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              He loves <AccentWord word="her" />.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Please give it to <AccentWord word="her" />.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              He wants to pluck flowers for <AccentWord word="her" />.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              He wants <AccentWord word="her" /> to be happy.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              What does he want to do for <AccentWord word="her" />?
            </>
          ),
        },
      ],
    },
    {
      title: "Possessive pronoun (adjective)",
      en: "her",
      ua: "її",
      description: "Вказує на приналежність особі жіночої статі, стоїть перед іменником.",
      examples: [],
    },
    {
      title: "Intensive pronoun",
      en: "herself",
      ua: "сама, себе",
      description: "Підкреслює, що дія виконується особою жіночої статі самостійно.",
      examples: [],
    },
    {
      title: "Reflexive pronoun",
      en: "herself",
      ua: "(себе)",
      description:
        "Вказує, що дія спрямована на саму особу жіночої статі, часто перекладається дієсловом з '-ся' або взагалі опускається.",
      examples: [],
    },
    {
      title: "Independent possessive pronoun",
      en: "hers",
      ua: "її",
      description: "Замінює іменник з присвійним значенням.",
      examples: [],
    },
  ),
  createData(
    {
      title: "Subject pronoun",
      en: "it",
      ua: "воно",
      description: "Займенник, що означає неживий предмет або тварину.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="It" /> doesn't work.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              Does <AccentWord word="it" /> work?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="It" /> works.
            </>
          ),
        },
      ],
    },
    {
      title: "Object pronoun",
      en: "it",
      ua: "його, її",
      description: "Означає неживий предмет або тварину, використовується в об'єктному відмінку.",
      examples: [
        {
          lesson: 3,
          title: (
            <>
              Please give <AccentWord word="it" /> for him.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              Please give <AccentWord word="it" /> for her.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              I have to do <AccentWord word="it" /> for you.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              She does <AccentWord word="it" /> for him.
            </>
          ),
        },
        {
          lesson: 3,
          title: (
            <>
              They don't want to do <AccentWord word="it" /> for us.
            </>
          ),
        },
      ],
    },
    {
      title: "Possessive pronoun (adjective)",
      en: "its",
      ua: "його, її",
      description: "Вказує на приналежність неживому предмету або тварині, стоїть перед іменником.",
      examples: [],
    },
    {
      title: "Intensive pronoun",
      en: "itself",
      ua: "сам, сама, саме, себе",
      description: "Підкреслює, що дія виконується неживим предметом або твариною самостійно.",
      examples: [],
    },
    {
      title: "Reflexive pronoun",
      en: "itself",
      ua: "себе",
      description:
        "Вказує, що дія спрямована на сам неживий предмет або тварину, часто перекладається дієсловом з '-ся' або взагалі опускається.",
      examples: [],
    },
    {
      title: "Independent possessive pronoun",
      en: "its",
      ua: "його, її",
      description: "Замінює іменник з присвійним значенням. Рідко вживається в цій функції.",
      examples: [],
    },
  ),
  createData(
    {
      title: "Subject pronoun",
      en: "who",
      ua: "хто",
      description: "Питальний займенник, вживається для позначення осіб у питальних реченнях.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="Who" /> knows it?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="Who" /> speak English?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="Who" /> study in school?
            </>
          ),
        },
      ],
    },
    {
      title: "Object pronoun",
      en: "whom",
      ua: "Кого",
      description:
        "Питальний займенник в об'єктному відмінку, вживається для позначення осіб у питальних реченнях.",
      examples: [],
    },
    {
      title: "Possessive pronoun (adjective)",
      en: "whose",
      ua: "Чий, чия, чиє, чиї",
      description: "Питальний займенник, що вказує на приналежність, стоїть перед іменником.",
      examples: [],
    },
    { title: "Intensive pronoun", en: "-", ua: "-", description: "N/A", examples: [] },
    { title: "Reflexive pronoun", en: "-", ua: "-", description: "N/A", examples: [] },
    {
      title: "Independent possessive pronoun",
      en: "whose",
      ua: "Чий, чия, чиє, чиї",
      description:
        "Питальний займенник, що замінює іменник з присвійним значенням у питальних реченнях.",
      examples: [],
    },
  ),
  createData(
    {
      title: "Subject pronoun",
      en: "one/a person",
      ua: "хтось/людина",
      description: "Вказує на невизначену особу в загальному сенсі.",
      examples: [],
    },
    {
      title: "Object pronoun",
      en: "one",
      ua: "комусь, людині, одному",
      description: "",
      examples: [],
    },
    {
      title: "Possessive pronoun (adjective)",
      en: "one's",
      ua: "чийсь, чиясь, чиєсь, чиїсь",
      description: "Вказує на приналежність невизначеній особі.",
      examples: [],
    },
    {
      title: "Intensive pronoun",
      en: "oneself",
      ua: "сам, сама, саме, себе",
      description:
        "Підкреслює, що дія виконується невизначеною особою самостійно, без сторонньої допомоги.",
      examples: [],
    },
    {
      title: "Reflexive pronoun",
      en: "oneself",
      ua: "себе",
      description:
        "Вказує, що дія, яку виконує невизначена особа, спрямована на неї саму. Часто перекладається дієсловом з '-ся' або взагалі опускається.",
      examples: [],
    },
    {
      title: "Independent possessive pronoun",
      en: "-",
      ua: "-",
      description: "",
      examples: [],
    },
  ),
];

const style = {
  position: "absolute" as "absolute",
  top: "25px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 400,
  overflow: "auto",
  maxHeight: "calc(100% - 50px)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const PronounTable: React.FC<{}> = ({}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [isHeaderCellModalOpen, setIsHeaderCellModalOpen] = useState(false);
  const [activeWord, setActiveWord] = useState("");
  const [activeTranslation, setActiveTranslation] = useState("");
  const [activeDescription, setActiveDescription] = useState("");
  const [activeExamples, setActiveExamples] = useState<any[]>([]);
  const [headerCellModalBody, setHeaderCellModalBody] = useState<any>(null);

  const globalWordsOptions = useMemo(() => {
    if (activeWord) {
      return { words: [activeWord] };
    }
    return { words: [] };
  }, [activeWord]);
  console.log("🚀 ~ globalWordsOptions:", globalWordsOptions);
  const [word] = useGlobalWords(globalWordsOptions);
  console.log("🚀 ~ word:", word);

  return (
    <>
      <Modal
        open={isCellModalOpen}
        onClose={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Box sx={style}>
          <ModalCloseButton onClose={() => setIsCellModalOpen(false)} />
          <VStack alignItems="flex-start">
            <Typography variant="h3">{activeWord}</Typography>
            <Typography>{activeTranslation}</Typography>
            {word && <WordTranscription word={word} play small={false} autoplay={false} />}
            <Typography>{activeDescription}</Typography>
            <VStack alignItems="flex-start">
              {activeExamples.map((e, i) => {
                return (
                  <Typography variant="h3" fontSize={18} fontWeight={500} key={i}>
                    {e.title}
                  </Typography>
                );
              })}
            </VStack>
          </VStack>
        </Box>
      </Modal>
      <Modal
        open={isHeaderCellModalOpen}
        onClose={() => {
          setIsHeaderCellModalOpen(false);
        }}
      >
        <Box sx={style}>
          <ModalCloseButton onClose={() => setIsHeaderCellModalOpen(false)} />
          <VStack alignItems="flex-start">{headerCellModalBody}</VStack>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <TableRow>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">Subject pronoun</Typography>,
                    <Typography variant="body1">Субʼєктний займенник</Typography>,
                    <Typography variant="body1">
                      Займенники, які вказують на особу, яка виконує дію.
                    </Typography>,
                  ]);
                }}
              >
                Subject pronoun
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">Object pronoun</Typography>,
                    <Typography variant="body1">Обʼєктний займенник</Typography>,
                    <Typography variant="body1">
                      Займенники, які вказують на обʼєкт, до якого відбувається дія.
                    </Typography>,
                  ]);
                }}
              >
                Object pronoun
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">Possessive pronoun (adjective)</Typography>,
                    <Typography variant="body1">Присвійний займенник (прикметник)</Typography>,
                    <Typography variant="body1">
                      Займенники, які вказують на належність об'єкта або особи до певної особи.
                      Завжди використовуються в парі з іменником.
                    </Typography>,
                  ]);
                }}
              >
                Possessive pronoun (adjective)
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">Intensive pronoun</Typography>,
                    <Typography variant="caption">Інтенсивний займенники</Typography>,
                    <Typography variant="body1">
                      Інтенсивні займенники виглядають так само як і зворотні. Закінчуються на –self
                      або –selves. Головна відмінність: можна прибрати з речення і суть його не
                      зміниться.
                    </Typography>,
                  ]);
                }}
              >
                Intensive pronoun
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">Reflexive pronoun</Typography>,
                    <Typography variant="body1">Зворотній займенник</Typography>,
                    <Typography variant="body1">
                      Зворотні займенники є формами особових займенників які закінчуються на –self
                      або –selves.
                    </Typography>,
                  ]);
                }}
              >
                Reflexive pronoun
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">Independent possessive pronoun</Typography>,
                    <Typography variant="body1">Незалежний присвійний займенник</Typography>,
                    <Typography variant="body1">
                      Займенники, які вказують на належність об'єкта або особи до певної особи.
                      Використовуються незалежно від іменника.
                    </Typography>,
                  ]);
                }}
              >
                Independent possessive pronoun
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={
                  {
                    // "&:last-child td, &:last-child th": { border: 0 }
                  }
                }
              >
                <CustomTableCell
                  onClick={() => {
                    setActiveWord(row.subjectPronoun.en);
                    setActiveTranslation(row.subjectPronoun.ua);
                    setActiveDescription(row.subjectPronoun.description);
                    setActiveExamples(row.subjectPronoun.examples);
                    setIsCellModalOpen(true);
                  }}
                  lessons={row.subjectPronoun.examples.map((e) => e.lesson)}
                  en={row.subjectPronoun.en}
                />
                <CustomTableCell
                  onClick={() => {
                    setActiveWord(row.objectPronoun.en);
                    setActiveTranslation(row.objectPronoun.ua);
                    setActiveDescription(row.objectPronoun.description);
                    setActiveExamples(row.objectPronoun.examples);
                    setIsCellModalOpen(true);
                  }}
                  lessons={row.objectPronoun.examples.map((e) => e.lesson)}
                  en={row.objectPronoun.en}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    setActiveWord(row.adjectivePossessivePronoun.en);
                    setActiveTranslation(row.adjectivePossessivePronoun.ua);
                    setActiveDescription(row.adjectivePossessivePronoun.description);
                    setActiveExamples(row.adjectivePossessivePronoun.examples);
                    setIsCellModalOpen(true);
                  }}
                  lessons={row.adjectivePossessivePronoun.examples.map((e) => e.lesson)}
                  en={row.adjectivePossessivePronoun.en}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    setActiveWord(row.intensivePronoun.en);
                    setActiveTranslation(row.intensivePronoun.ua);
                    setActiveDescription(row.intensivePronoun.description);
                    setActiveExamples(row.intensivePronoun.examples);
                    setIsCellModalOpen(true);
                  }}
                  lessons={row.intensivePronoun.examples.map((e) => e.lesson)}
                  en={row.intensivePronoun.en}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    setActiveWord(row.reflexivePronoun.en);
                    setActiveTranslation(row.reflexivePronoun.ua);
                    setActiveDescription(row.reflexivePronoun.description);
                    setActiveExamples(row.reflexivePronoun.examples);
                    setIsCellModalOpen(true);
                  }}
                  lessons={row.reflexivePronoun.examples.map((e) => e.lesson)}
                  en={row.reflexivePronoun.en}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    setActiveWord(row.independentPossessivePronoun.en);
                    setActiveTranslation(row.independentPossessivePronoun.ua);
                    setActiveDescription(row.independentPossessivePronoun.description);
                    setActiveExamples(row.independentPossessivePronoun.examples);
                    setIsCellModalOpen(true);
                  }}
                  lessons={row.independentPossessivePronoun.examples.map((e) => e.lesson)}
                  en={row.independentPossessivePronoun.en}
                ></CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const CustomTableCell: React.FC<{
  en: string;
  lessons: number[];
  onClick: () => void;
}> = ({ en, lessons, onClick }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady =
    (lessons?.length && lessons.includes(lessonNumber)) ||
    (lessons?.length && Math.min(...lessons) <= lessonNumber);

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={onClick}
      sx={{
        // p: 0,
        m: 0,
        //
        // backgroundColor: "#4aa805",
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lessons?.length && lessons.includes(lessonNumber)
            ? "#ddffc5"
            : lessons?.length && Math.min(...lessons) <= lessonNumber
            ? "#fcffdb"
            : "#d0d0d0",
        // boxSizing: "content-box",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
      }}
    >
      <Box
        sx={
          {
            // borderTop: "1px solid rgba(224, 224, 224, 1)",
          }
        }
      >
        {lessons.includes(lessonNumber) ? (
          <Badge
            badgeContent=" "
            color="secondary"
            variant="dot"
            sx={{
              // color: "red",
              "& .MuiBadge-badge": {
                position: "absolute",
                left: -15,
                top: 0,
                backgroundColor: "red",
                width: 0,
                padding: "0 4px",
                opacity: 0,
              },
            }}
          >
            {en}
          </Badge>
        ) : (
          en
        )}
      </Box>
    </TableCell>
  );
};

export default PronounTable;
