import filterSkipedWords from "./filterSkipedWords";
import getSentenceWords from "./getSentenceWords";
import getSkipWords from "./getSkipWords";
import supabaseClient from "./supabaseClient";

interface CheckNewWordsRes {
  words: string[];
  newWords: string[];
}

const checkNewWords = async (text: string[]): Promise<CheckNewWordsRes> => {
  const skipWords = await getSkipWords(getSentenceWords(text.join(" ")));

  const sentenceWords = Array.from(
    new Set(text.map((s) => filterSkipedWords(getSentenceWords(s), skipWords)).flat()),
  );

  const { data: exsitingWords } = await supabaseClient
    .from("words")
    .select("name")
    .in("name", sentenceWords);

  if (!exsitingWords) throw new Error("Unexpected empty words");

  const rowExsistingWords = exsitingWords.map((w) => w.name);
  // const rowExsistingWords = [
  //   "I",
  //   "speak",
  //   "English",
  //   "you",
  //   "we",
  //   "they",
  //   "he",
  //   "speaks",
  //   "she",
  //   "know",
  //   "knows",
  //   "do",
  //   "does",
  //   "don't",
  //   "doesn't",
  //   "study",
  //   "in",
  //   "school",
  //   "studies",
  //   "and",
  //   "work",
  //   "works",
  //   "every",
  //   "day",
  //   "it",
  //   "learn",
  //   "learns",
  //   "very",
  //   "well",
  //   "go",
  //   "to",
  //   "today",
  //   "who",
  // ];
  const newWords = sentenceWords.filter((w) => !rowExsistingWords.includes(w));

  return {
    words: sentenceWords,
    newWords: newWords,
  };
};

export default checkNewWords;
