import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";
import AccentWord from "./AccentWord";

const BadBadly = () => {
  return (
    <>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I feel <AccentWord word="bad" />.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I feel <AccentWord word="badly" />.
        </Typography>
      </Box>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I want it <AccentWord word="badly" />.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I want it <AccentWord word="bad" />.
        </Typography>
      </Box>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          He sings <AccentWord word="badly" />.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          He sings <AccentWord word="bad" />.
        </Typography>
      </Box>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          The room was so <AccentWord word="badly" />.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          The room was so <AccentWord word="badly" /> decorated.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          The room was so <AccentWord word="bad" />.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          The room was <AccentWord word="bad" />.
        </Typography>
      </Box>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          She looked <AccentWord word="bad" />.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          She looked <AccentWord word="badly" />.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          She looked <AccentWord word="badly" /> dressed.
        </Typography>
      </Box>
    </>
  );
};

export default BadBadly;
