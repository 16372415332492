import { PropsWithChildren } from "react";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import Box from "../../../Box";
import Button from "../../../Button";
import Typography from "../../../Typography";

const BackgroundBoxWraper: React.FC<
  PropsWithChildren & {
    lesson: number;
    isWide?: boolean;
    mt?: number;
    mb?: number;
    isSpan?: boolean;
    isBold?: boolean;
    px?: number;
  }
> = ({ children, isWide, mt, mb, isSpan, lesson, isBold, px }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  if (lessonNumber < lesson) return null;

  if (isWide) {
    return (
      <Box
        width={700}
        position="absolute"
        left={25}
        top={0}
        border="1px solid rgba(224, 224, 224, 1)"
        component={isSpan ? "span" : "div"}
        fontWeight={isBold ? "bold" : 400}
        color={isSpan ? "red" : "black"}
        p={2}
        mt={mt || 0}
        mb={mb || 0}
        sx={{
          zIndex: 1,
          backgroundColor: lessonNumber === lesson ? "#deffc5" : "#fcffdb",
        }}
      >
        {children}
      </Box>
    );
  }

  if (lessonNumber === lesson) {
    return (
      <Box
        px={isSpan ? 1 : px || 3}
        mt={mt || 0}
        mb={mb || 0}
        py={0.3}
        component={isSpan ? "span" : "div"}
        fontWeight={isBold ? "bold" : 400}
        color={isSpan ? "red" : "black"}
        fontSize={isSpan ? 16 : 18}
        sx={{
          zIndex: 1,
          backgroundColor: lessonNumber === lesson ? "#deffc5" : "#fcffdb",
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      px={isSpan ? 1 : px || 3}
      mt={mt || 0}
      mb={mb || 0}
      component={isSpan ? "span" : "div"}
      color={isSpan ? "red" : "black"}
      fontSize={isSpan ? 16 : 18}
      fontWeight={isBold ? "bold" : 400}
    >
      {children}
    </Box>
  );
};

export default BackgroundBoxWraper;
