import supabaseClient from "./supabaseClient";

const getSkipWords = async (words?: string[]): Promise<string[]> => {
  if (words?.length) {
    const { data: wordsToSkipData } = await supabaseClient
      .from("skip-words")
      .select()
      .in("id", words);

    const wordsToSkip = (wordsToSkipData || []).map(({ id }) => id);

    return wordsToSkip;
  } else {
    const { data: wordsToSkipData } = await supabaseClient
      .from("skip-words")
      .select();

    const wordsToSkip = (wordsToSkipData || []).map(({ id }) => id);

    return wordsToSkip;
  }
};

export default getSkipWords;
