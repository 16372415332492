const rawSentences = [
  "I don't care for the color purple. (mindly dislike)",
  "I'm caring for my aunt's dogs while she's out of town.",
  "Her house is large, like a mansion.",
  "The couch is blue, like the sky.",
  "My son looks like me.",
  "She likes the rain, as do I.",
  "The student is not as tall as the teacher.",
  "My house is as big as your house.",
  "She looks like her mother.",
  "The fish, like the dolphin, loves to swim.",
  "He’s like a brother to me.",
  "We should do something fun tonight, like going to a concert or a party.",
  "She speaks Spanish like a native speaker.",
  "The old dog can’t walk as he used to.",
  "He makes tomato sauce as his grandmother did.",
  "I worked as a barista for 10 years.",
  "Lucy went to bed early as she didn’t feel well.",
  "The cake is sweet, as it should be.",
];

export default rawSentences;
