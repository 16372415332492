const a1Words = [
  {
    topic: "Hello and Goodbye",
    level: "A1",
    words: [
      "hello",
      "goodbye",
      "hi",
      "bye",
      "good morning",
      "good afternoon",
      "good evening",
      "good night",
      "thank you",
      "thanks",
      "please",
      "ok",
      "yes",
      "no",
      "sorry",
      "welcome",
    ],
  },
  {
    topic: "People",
    level: "A1",
    words: [
      "man",
      "woman",
      "boy",
      "girl",
      "friend",
      "boyfriend",
      "girlfriend",
      "person",
      "people",
      "adult",
      "baby",
      "Mr",
      "Mrs",
      "miss",
    ],
  },
  {
    topic: "Family",
    level: "A1",
    words: [
      "parent",
      "mother",
      "father",
      "dad",
      "mom",
      "wife",
      "husband",
      "child",
      "sister",
      "brother",
      "son",
      "daughter",
      "family",
      "grandmother",
      "grandfather",
      "grandchild",
      "aunt",
      "uncle",
      "cousin",
      "grandparent",
      "niece",
      "nephew",
    ],
  },
  {
    topic: "Colors",
    level: "A1",
    words: [
      "blue",
      "black",
      "white",
      "green",
      "yellow",
      "red",
      "pink",
      "orange",
      "purple",
      "gray",
      "brown",
      "dark",
      "light",
      "color",
    ],
  },
  {
    topic: "Months and Seasons",
    level: "A1",
    words: [
      "month",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
      "season",
      "spring",
      "summer",
      "fall",
      "winter",
    ],
  },
  {
    topic: "Time and Date",
    level: "A1",
    words: [
      "clock",
      "year",
      "time",
      "date",
      "day",
      "hour",
      "o'clock",
      "minute",
      "second",
      "morning",
      "afternoon",
      "evening",
      "night",
      "week",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "weekend",
      "next",
      "half",
    ],
  },
  {
    topic: "Personal information",
    level: "A1",
    words: [
      "name",
      "last name",
      "age",
      "address",
      "birthday",
      "birthdate",
      "single",
      "married",
      "passport",
      "phone number",
      "question",
      "answer",
    ],
  },
  {
    topic: "Body",
    level: "A1",
    words: [
      "body",
      "hand",
      "arm",
      "foot",
      "head",
      "leg",
      "knee",
      "back",
      "stomach",
    ],
  },
  {
    topic: "The head and face",
    level: "A1",
    words: [
      "hair",
      "neck",
      "face",
      "eye",
      "nose",
      "ear",
      "cheek",
      "chin",
      "mouth",
      "tooth",
      "lip",
    ],
  },
  {
    topic: "Opposite adjectives",
    level: "A1",
    words: [
      "good",
      "bad",
      "high",
      "low",
      "big",
      "small",
      "heavy",
      "light",
      "expensive",
      "cheap",
      "old",
      "new",
      "beautiful",
      "ugly",
      "clean",
      "dirty",
      "easy",
      "difficult",
      "fast",
      "slow",
      "different",
      "same",
      "right",
      "wrong",
      "open",
      "closed",
      "true",
      "false",
      "rich",
      "poor",
      "sure",
      "unsure",
      "correct",
      "incorrect",
    ],
  },
  {
    topic: "",
    level: "A1",
    words: [""],
  },
  {
    topic: "Home",
    level: "A1",
    words: [
      "house",
      "apartment",
      "bedroom",
      "bathroom",
      "kitchen",
      "living",
      "room",
      "garden",
      "door",
      "window",
      "wall",
    ],
  },
  {
    topic: "Food and Drink",
    level: "A1",
    words: [
      "bread",
      "milk",
      "cheese",
      "butter",
      "egg",
      "apple",
      "banana",
      "orange",
      "water",
      "juice",
      "coffee",
      "tea",
      "breakfast",
      "lunch",
      "dinner",
    ],
  },
  {
    topic: "Animals",
    level: "A1",
    words: [
      "dog",
      "cat",
      "bird",
      "fish",
      "elephant",
      "lion",
      "horse",
      "cow",
      "sheep",
      "pig",
    ],
  },
  {
    topic: "Numbers and Time",
    level: "A1",
    words: [
      "one",
      "two",
      "three",
      "four",
      "five",
      "ten",
      "hundred",
      "thousand",
      "million",
      "day",
      "week",
      "month",
      "year",
      "hour",
      "minute",
      "second",
    ],
  },
  {
    topic: "Daily Routine",
    level: "A1",
    words: [
      "wake up",
      "get up",
      "get dressed",
      "eat breakfast",
      "go to school/work",
      "study/work",
      "have lunch",
      "go home",
      "have dinner",
      "watch TV",
      "go to bed",
      "sleep",
    ],
  },
  {
    topic: "School",
    level: "A1",
    words: [
      "student",
      "teacher",
      "classroom",
      "book",
      "pen",
      "pencil",
      "desk",
      "chair",
      "lesson",
      "homework",
      "exam",
    ],
  },
  {
    topic: "Work",
    level: "A1",
    words: [
      "job",
      "work",
      "office",
      "computer",
      "email",
      "phone",
      "meeting",
      "business",
      "colleague",
      "boss",
    ],
  },
  {
    topic: "City and Transportation",
    level: "A1",
    words: [
      "city",
      "town",
      "street",
      "car",
      "bus",
      "train",
      "taxi",
      "bike",
      "airport",
      "station",
    ],
  },
  {
    topic: "Shops and Shopping",
    level: "A1",
    words: [
      "shop",
      "supermarket",
      "clothes",
      "shoes",
      "food",
      "drink",
      "buy",
      "sell",
      "pay",
      "money",
    ],
  },
  {
    topic: "Travel and Vacation",
    level: "A1",
    words: [
      "travel",
      "trip",
      "vacation",
      "hotel",
      "beach",
      "mountain",
      "plane",
      "boat",
      "passport",
      "ticket",
    ],
  },
  {
    topic: "Hobbies and Activities",
    level: "A1",
    words: [
      "sport",
      "music",
      "dance",
      "reading",
      "writing",
      "painting",
      "playing games",
      "watching movies",
      "listening to music",
    ],
  },
  {
    topic: "Weather",
    level: "A1",
    words: [
      "sun",
      "rain",
      "snow",
      "wind",
      "cloud",
      "hot",
      "cold",
      "warm",
      "cool",
    ],
  },
  {
    topic: "Feelings and Emotions",
    level: "A1",
    words: [
      "happy",
      "sad",
      "angry",
      "tired",
      "bored",
      "excited",
      "scared",
      "surprised",
      "worried",
    ],
  },
  {
    topic: "Body and Health",
    level: "A1",
    words: [
      "head",
      "arm",
      "leg",
      "hand",
      "foot",
      "eye",
      "ear",
      "nose",
      "mouth",
      "doctor",
      "hospital",
    ],
  },
  {
    topic: "Colors",
    level: "A1",
    words: [
      "red",
      "blue",
      "green",
      "yellow",
      "black",
      "white",
      "brown",
      "pink",
      "orange",
      "purple",
    ],
  },
  {
    topic: "Clothes",
    level: "A1",
    words: [
      "shirt",
      "pants",
      "dress",
      "skirt",
      "jacket",
      "coat",
      "shoes",
      "boots",
      "hat",
      "gloves",
    ],
  },
  {
    topic: "Adjectives",
    level: "A1",
    words: [
      "big",
      "small",
      "long",
      "short",
      "tall",
      "young",
      "old",
      "good",
      "bad",
      "beautiful",
    ],
  },
  {
    topic: "Verbs",
    level: "A1",
    words: [
      "be",
      "have",
      "do",
      "go",
      "come",
      "say",
      "see",
      "eat",
      "drink",
      "sleep",
    ],
  },
  {
    topic: "Adverbs",
    level: "A1",
    words: [
      "here",
      "there",
      "now",
      "then",
      "today",
      "tomorrow",
      "yesterday",
      "always",
      "never",
      "sometimes",
    ],
  },
  {
    topic: "Prepositions",
    level: "A1",
    words: [
      "in",
      "on",
      "at",
      "to",
      "from",
      "with",
      "without",
      "for",
      "about",
      "after",
    ],
  },
  {
    topic: "Countries and Nationalities",
    level: "A1",
    words: [
      "England",
      "English",
      "Spain",
      "Spanish",
      "China",
      "Chinese",
      "Japan",
      "Japanese",
    ],
  },
  {
    topic: "Technology",
    level: "A1",
    words: ["computer", "phone", "internet", "television", "camera", "radio"],
  },
  {
    topic: "Nature",
    level: "A1",
    words: ["tree", "flower", "plant", "mountain", "river", "sea", "forest"],
  },
  {
    topic: "Music",
    level: "A1",
    words: ["song", "band", "singer", "guitar", "piano", "concert", "music"],
  },
  {
    topic: "Sports",
    level: "A1",
    words: [
      "football",
      "basketball",
      "tennis",
      "swimming",
      "running",
      "cycling",
    ],
  },
  {
    topic: "Restaurants and Food",
    level: "A1",
    words: [
      "restaurant",
      "cafe",
      "menu",
      "waiter",
      "food",
      "drink",
      "order",
      "pay",
    ],
  },
  {
    topic: "Holidays and celebrations",
    level: "A1",
    words: ["Christmas", "Easter", "birthday", "party", "present", "cake"],
  },
  {
    topic: "Arts and Entertainment",
    level: "A1",
    words: ["movie", "theatre", "book", "painting", "museum"],
  },
  {
    topic: "Communication",
    level: "A1",
    words: [
      "talk",
      "speak",
      "listen",
      "write",
      "read",
      "understand",
      "question",
      "answer",
    ],
  },
  {
    topic: "Descriptions",
    level: "A1",
    words: [
      "big",
      "small",
      "long",
      "short",
      "tall",
      "young",
      "old",
      "good",
      "bad",
      "beautiful",
      "hot",
      "cold",
      "warm",
      "cool",
      "new",
      "old",
      "clean",
      "dirty",
      "easy",
      "difficult",
      "happy",
      "sad",
      "angry",
      "tired",
    ],
  },
  {
    topic: "Actions",
    level: "A1",
    words: [
      "walk",
      "run",
      "jump",
      "swim",
      "dance",
      "sing",
      "play",
      "read",
      "write",
      "eat",
      "drink",
      "sleep",
      "work",
      "study",
      "talk",
      "listen",
      "watch",
      "help",
      "cook",
      "clean",
      "drive",
    ],
  },
  {
    topic: "Objects",
    level: "A1",
    words: [
      "book",
      "pen",
      "pencil",
      "paper",
      "table",
      "chair",
      "bed",
      "door",
      "window",
      "car",
      "bus",
      "train",
      "plane",
      "phone",
      "computer",
      "television",
      "radio",
      "camera",
    ],
  },
  {
    topic: "Places",
    level: "A1",
    words: [
      "school",
      "home",
      "work",
      "city",
      "town",
      "village",
      "country",
      "park",
      "beach",
      "mountain",
      "river",
      "sea",
    ],
  },
  {
    topic: "Time and Dates",
    level: "A1",
    words: [
      "day",
      "week",
      "month",
      "year",
      "hour",
      "minute",
      "second",
      "morning",
      "afternoon",
      "evening",
      "night",
      "today",
      "tomorrow",
      "yesterday",
    ],
  },
  {
    topic: "People",
    level: "A1",
    words: [
      "man",
      "woman",
      "boy",
      "girl",
      "child",
      "baby",
      "friend",
      "family",
      "teacher",
      "student",
      "doctor",
      "police officer",
    ],
  },
  {
    topic: "Questions and Answers",
    level: "A1",
    words: [
      "what",
      "where",
      "when",
      "why",
      "who",
      "how",
      "yes",
      "no",
      "maybe",
      "I don't know",
    ],
  },
  {
    topic: "Numbers",
    level: "A1",
    words: [
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
      "twenty",
    ],
  },
  {
    topic: "Directions",
    level: "A1",
    words: [
      "up",
      "down",
      "left",
      "right",
      "front",
      "back",
      "inside",
      "outside",
      "next to",
      "between",
      "near",
      "far",
    ],
  },
  {
    topic: "Quantities",
    level: "A1",
    words: [
      "some",
      "any",
      "many",
      "much",
      "a lot of",
      "a few",
      "a little",
      "all",
      "none",
    ],
  },
  {
    topic: "Materials and Textures",
    level: "A1",
    words: [
      "wood",
      "metal",
      "plastic",
      "glass",
      "paper",
      "cotton",
      "wool",
      "leather",
      "water",
      "stone",
    ],
  },
  {
    topic: "Opposites",
    level: "A1",
    words: [
      "good - bad",
      "big - small",
      "hot - cold",
      "happy - sad",
      "long - short",
      "new - old",
      "clean - dirty",
      "easy - difficult",
    ],
  },
  {
    topic: "Verbs (past tense)",
    level: "A1",
    words: [
      "went",
      "saw",
      "did",
      "ate",
      "drank",
      "slept",
      "worked",
      "studied",
      "talked",
      "listened",
      "watched",
      "helped",
      "played",
    ],
  },
  {
    topic: "Verbs (future tense)",
    level: "A1",
    words: [
      "will go",
      "will see",
      "will do",
      "will eat",
      "will drink",
      "will sleep",
      "will work",
      "will study",
      "will talk",
      "will listen",
      "will watch",
      "will help",
      "will play",
    ],
  },
  {
    topic: "Prepositions of Place",
    level: "A1",
    words: [
      "in",
      "on",
      "at",
      "under",
      "next to",
      "between",
      "in front of",
      "behind",
      "above",
      "below",
    ],
  },
  {
    topic: "Shapes",
    level: "A1",
    words: ["circle", "square", "triangle", "rectangle", "star", "heart"],
  },
  {
    topic: "Sounds",
    level: "A1",
    words: ["loud", "quiet", "music", "noise", "voice", "laugh", "cry"],
  },
  {
    topic: "Tastes and Smells",
    level: "A1",
    words: [
      "sweet",
      "sour",
      "salty",
      "bitter",
      "delicious",
      "bad",
      "good",
      "strong",
      "weak",
    ],
  },
  {
    topic: "Feelings (physical)",
    level: "A1",
    words: ["hot", "cold", "hungry", "thirsty", "tired", "sick", "healthy"],
  },
  {
    topic: "Adjectives ending in -ed and -ing",
    level: "A1",
    words: [
      "bored",
      "boring",
      "interested",
      "interesting",
      "excited",
      "exciting",
      "surprised",
      "surprising",
    ],
  },
  {
    topic: "Verbs with -ing",
    level: "A1",
    words: [
      "running",
      "swimming",
      "dancing",
      "singing",
      "playing",
      "reading",
      "writing",
      "eating",
      "drinking",
    ],
  },
  {
    topic: "Parts of Speech",
    level: "A1",
    words: ["noun", "verb", "adjective", "adverb", "preposition"],
  },
  {
    topic: "Classroom Objects",
    level: "A1",
    words: [
      "desk",
      "chair",
      "board",
      "book",
      "pen",
      "pencil",
      "paper",
      "computer",
    ],
  },
  {
    topic: "Tools",
    level: "A1",
    words: ["hammer", "screwdriver", "saw", "drill", "wrench"],
  },
  {
    topic: "Vehicles",
    level: "A1",
    words: ["car", "bus", "train", "plane", "bike", "boat", "motorcycle"],
  },
  {
    topic: "Buildings",
    level: "A1",
    words: [
      "house",
      "apartment",
      "school",
      "hospital",
      "shop",
      "library",
      "museum",
    ],
  },
  {
    topic: "Furniture",
    level: "A1",
    words: ["bed", "chair", "table", "sofa", "wardrobe", "desk"],
  },
  {
    topic: "Occupations",
    level: "A1",
    words: [
      "doctor",
      "teacher",
      "police officer",
      "firefighter",
      "nurse",
      "builder",
      "driver",
    ],
  },
  {
    topic: "Seasons",
    level: "A1",
    words: ["spring", "summer", "autumn", "winter"],
  },
  {
    topic: "Weather Words",
    level: "A1",
    words: ["sunny", "rainy", "cloudy", "windy", "snowy", "foggy"],
  },
  {
    topic: "Days of the Week",
    level: "A1",
    words: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
  },
  {
    topic: "Months of the Year",
    level: "A1",
    words: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
  {
    topic: "Family Relationships",
    level: "A1",
    words: [
      "mother",
      "father",
      "sister",
      "brother",
      "son",
      "daughter",
      "aunt",
      "uncle",
      "cousin",
      "grandparent",
      "husband",
      "wife",
    ],
  },
  {
    topic: "Sports Equipment",
    level: "A1",
    words: ["ball", "bat", "racket", "net", "goal", "helmet", "gloves"],
  },
  {
    topic: "Musical Instruments",
    level: "A1",
    words: ["guitar", "piano", "drums", "violin", "trumpet", "saxophone"],
  },
  {
    topic: "Technology Devices",
    level: "A1",
    words: [
      "computer",
      "phone",
      "tablet",
      "television",
      "camera",
      "headphones",
    ],
  },
  {
    topic: "Food Preparation",
    level: "A1",
    words: ["cook", "bake", "fry", "boil", "cut", "chop", "mix"],
  },
  {
    topic: "Verbs with prepositions",
    level: "A1",
    words: [
      "look at",
      "listen to",
      "talk to",
      "think about",
      "go to",
      "come from",
    ],
  },
  {
    topic: "Adverbs of Frequency",
    level: "A1",
    words: ["always", "usually", "often", "sometimes", "rarely", "never"],
  },
  {
    topic: "Question Words",
    level: "A1",
    words: ["what", "where", "when", "why", "who", "how", "which", "whose"],
  },
  {
    topic: "Clothing Verbs",
    level: "A1",
    words: ["wear", "put on", "take off", "dress", "undress"],
  },
  {
    topic: "Opposite Actions",
    level: "A1",
    words: [
      "come",
      "go",
      "buy",
      "sell",
      "open",
      "close",
      "start",
      "finish",
      "win",
      "lose",
    ],
  },
  {
    topic: "Places in a City",
    level: "A1",
    words: [
      "park",
      "school",
      "hospital",
      "library",
      "museum",
      "restaurant",
      "shop",
      "bank",
    ],
  },
  {
    topic: "Landforms",
    level: "A1",
    words: ["mountain", "hill", "valley", "river", "lake", "ocean", "island"],
  },
  {
    topic: "School Subjects",
    level: "A1",
    words: [
      "math",
      "science",
      "history",
      "English",
      "art",
      "music",
      "geography",
    ],
  },
  {
    topic: "Common Expressions",
    level: "A1",
    words: [
      "hello",
      "goodbye",
      "thank you",
      "please",
      "excuse me",
      "how are you?",
    ],
  },
  {
    topic: "Nationalities",
    level: "A1",
    words: [
      "American",
      "British",
      "Chinese",
      "French",
      "German",
      "Spanish",
      "Italian",
    ],
  },
  {
    topic: "Materials for Building",
    level: "A1",
    words: ["wood", "brick", "concrete", "metal", "glass", "plastic"],
  },
  {
    topic: "Verbs for Communication",
    level: "A1",
    words: [
      "talk",
      "speak",
      "listen",
      "write",
      "read",
      "understand",
      "explain",
    ],
  },
  {
    topic: "Sizes",
    level: "A1",
    words: ["small", "medium", "large", "big", "huge", "tiny"],
  },
  {
    topic: "Verbs of Motion",
    level: "A1",
    words: ["walk", "run", "jump", "swim", "fly", "drive", "ride", "climb"],
  },
  {
    topic: "Household Chores",
    level: "A1",
    words: ["clean", "cook", "wash dishes", "do laundry", "vacuum", "dust"],
  },
  {
    topic: "Places in a House",
    level: "A1",
    words: [
      "kitchen",
      "bathroom",
      "bedroom",
      "living room",
      "dining room",
      "basement",
      "attic",
    ],
  },
  {
    topic: "Animals (young)",
    level: "A1",
    words: ["puppy", "kitten", "chick", "calf", "lamb", "cub"],
  },
  {
    topic: "Emotions (strong)",
    level: "A1",
    words: ["love", "hate", "fear", "anger", "joy", "sadness"],
  },
  {
    topic: "Verbs with Objects",
    level: "A1",
    words: [
      "read a book",
      "write a letter",
      "eat an apple",
      "drink water",
      "play a game",
    ],
  },
  {
    topic: "Adjectives with -ful",
    level: "A1",
    words: ["beautiful", "wonderful", "helpful", "careful", "colorful"],
  },
  {
    topic: "Adjectives with -less",
    level: "A1",
    words: ["helpless", "careless", "homeless", "painless", "useless"],
  },
  {
    topic: "Types of Stores",
    level: "A1",
    words: [
      "grocery store",
      "clothing store",
      "bookstore",
      "toy store",
      "shoe store",
    ],
  },
  {
    topic: "Types of Music",
    level: "A1",
    words: ["rock", "pop", "classical", "jazz", "country", "hip hop"],
  },
];

export default a1Words;
