import { Typography } from "@mui/material";

const AW: React.FC<{ word: string; weight?: number }> = ({ word }) => {
  return (
    <Typography component="span" color="primary">
      {word}
    </Typography>
  );
};

export default AW;
