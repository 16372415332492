import { MutableRefObject, useEffect, useMemo, useState } from "react";

const useIsInViewport = (
  ref: MutableRefObject<HTMLElement | undefined>,
  visibleUniqueText?: string,
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          return visibleUniqueText
            ? setIsIntersecting(entry.target.textContent === visibleUniqueText)
            : setIsIntersecting(entry.isIntersecting);
        },
        {
          root: document.getElementById("root"),
        },
      ),
    [visibleUniqueText],
  );

  useEffect(() => {
    if (ref.current) observer.observe(ref.current);

    return () => {
      // eslint-disable-next-line
      if (ref.current) observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export default useIsInViewport;
