import { Box, Button, IconButton, Tab, Tabs, TextField, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { addDays } from "date-fns";
import { memo, useCallback, useEffect, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { ExtendedUserWord } from "../../../types/supabase";
import MenuLayout from "../../Layout/MenuLayout";
import RepetitionUserWord from "../../Sentence/RepetitionUserWord";
import Loader from "../../Unknown/Loader";
import VStack from "../../Unknown/VStack";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";
import HStack from "../../Unknown/HStack";
import isAdmin from "../../../common/isAdmin";
import { Popover, PopoverContent, PopoverTrigger } from "../../Reading/HarryPotterChapter/Popover";
import UkrainianWord from "../../Sentence/UkrainianWord";
import WordTranscription from "../WordTranscription";
import HoverDetailsWord from "../HoverDetailsWord";
import HoverDetails from "../HoverDetails";
import CopyNewWordsForGemini from "../../Admin/CopyNewWordsForGemini";
import CopyToClipboard from "react-copy-to-clipboard";
import AdminUsers from "../../AdminUsers";
import { RPCUser } from "../../../common/fetchCurrentUser";
import { adminUserId } from "../../../common/constants";
import { usePrevious } from "react-use";
import RepetitionUserStory from "../RepetitionUserStory";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={2}>{children}</Box>}
    </div>
  );
}

const RepetitionUserWordsScreen: React.FC = () => {
  const [count, setCount] = useState(0);
  const authUser = useLessonsStore((state) => state.authUser);
  const [currentUser, setCurrentUser] = useState<RPCUser | null>(null);
  const prevCurrentUser = usePrevious(currentUser);

  const getTodayWordsCount = useCallback(async () => {
    if (!authUser) return;

    const { count: spWordCount } = await supabaseClient
      .from("user-words")
      .select("*", { count: "exact", head: true })
      .like("id", `${currentUser?.uuid || authUser.uuid}-%`)
      .lte("repeat", addDays(new Date().setHours(0, 0, 0, 0), 1).toISOString());

    if (spWordCount && (spWordCount < count || (count === 0 && spWordCount >= 2)))
      setCount(spWordCount);
    if (prevCurrentUser !== currentUser && currentUser && spWordCount) setCount(spWordCount);
  }, [authUser, currentUser, prevCurrentUser, count]);

  const [words, setWords] = useState<ExtendedUserWord[] | null>(null);

  useMountedWaitAsyncEffect(async () => {
    const { data: spWords } = await supabaseClient
      .from("user-words")
      .select()
      .eq("user", authUser!.uuid);

    if (words === null && authUser) {
      const { data: spWords } = await supabaseClient
        .from("user-words")
        .select("*, words(audio_url, transcription, translation, examples, ua_examples)")
        // .like("id", `${currentUser?.uuid || authUser.uuid}-%`)
        .eq("user", authUser.uuid)
        .order("repeat")
        .order("word")
        .limit(45)
        .returns<ExtendedUserWord[]>();

      if (spWords) setWords(spWords);
    }
  }, [words, authUser]);

  const goToNextSlide = async (word: string, period: string) => {
    if (!words || !authUser) return;

    const [currentWord, ...restWords] = words;

    if (period.includes("d") && authUser) {
      setWords(restWords);
      if (count) setCount((prev) => prev - 1);

      await supabaseClient.rpc("update_user_word", {
        userprop: authUser.id,
        input_word: word,
        period: addDays(new Date(), +period.replace("d", "")),
      });

      const { data: spWord } = await supabaseClient
        .from("user-words")
        .select("*, words(audio_url, transcription, translation, ua_examples)")
        .order("repeat")
        .order("word")
        .eq("user", authUser.uuid)
        // .is("repeat", null)
        .range(+period, +period + 1)
        .not("word", "in", `(${restWords.map((w) => w.word).join(",")})`)
        .limit(1)
        .returns<ExtendedUserWord[]>()
        .single();

      console.log("🚀 ~ new loaded word:", spWord);

      if (spWord) setWords((prev) => [...restWords, spWord]);
    } else {
      setWords(
        restWords.reduce<ExtendedUserWord[]>((prev, cW, index) => {
          const updated = [...prev];
          if (index + 1 === +period) updated.push(currentWord);
          updated.push(cW);
          return updated;
        }, []),
      );
    }

    getTodayWordsCount();
  };

  useEffect(() => {
    if (!words || (currentUser && currentUser !== prevCurrentUser)) getTodayWordsCount();
  }, [words, authUser, currentUser, prevCurrentUser, getTodayWordsCount]);

  const [todayEnglishWords, setTodayEnglishWords] = useState<
    | {
        word: string;
        transcription?: string;
        translation?: string;
        audio_url?: string;
        description?: string;
        repeat: string;
      }[]
    | null
  >(null);
  const [open, setOpen] = useState(false);

  // useMountedWaitAsyncEffect(async () => {
  //   if ((!todayEnglishWords && authUser) || (authUser && currentUser && currentUser !== prevCurrentUser)) {
  //     const { data } = await supabaseClient.rpc("get_user_words", {
  //       user_id: currentUser?.uuid || authUser.uuid,
  //     });

  //     if (data) {
  //       setTodayEnglishWords(data);
  //     }
  //   }
  // }, [authUser, todayEnglishWords, currentUser, prevCurrentUser]);
  useEffect(() => {
    (async () => {
      if (
        (!todayEnglishWords && authUser) ||
        (authUser && currentUser && currentUser !== prevCurrentUser)
      ) {
        const { data } = await supabaseClient.rpc("get_user_words", {
          user_id: currentUser?.uuid || authUser.uuid,
        });

        if (data) {
          setTodayEnglishWords(data);
        }
      }
    })();
  }, [authUser, todayEnglishWords, currentUser, prevCurrentUser]);

  const [tabsValue, setTabsValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  const [users, setUsers] = useState<RPCUser[]>([]);
  const [changedUserUid, setChangedUserUid] = useState<string | null>(null);

  useMountedWaitAsyncEffect(async () => {
    if (users.length || !authUser) return;

    if (authUser.id !== adminUserId) {
      setUsers([authUser]);
      return;
    }

    // for admin only
    const { data: spUsers } = await supabaseClient.from("users").select();

    if (spUsers) setUsers(spUsers);
  }, [users]);

  useMountedWaitAsyncEffect(() => {
    if (!authUser) return;

    let currentUser: RPCUser | undefined = undefined;
    if (changedUserUid) {
      currentUser = users.find((u) => changedUserUid === u.uuid);
    } else {
      currentUser = users.find((u) => authUser.uuid === u.uuid);
    }

    if (!currentUser) return;

    setCurrentUser(currentUser);
  }, [users, authUser, changedUserUid]);

  const [word] = words || [];

  if (words?.length === 0)
    return (
      <MenuLayout>
        <Box p={2} textAlign="center" sx={{ backgroundColor: "white" }}>
          <Typography variant="h3" py={20}>
            Поки що нема слів для повторення
          </Typography>
        </Box>
      </MenuLayout>
    );

  if (!word)
    return (
      <MenuLayout>
        <WhiteBoxWrap>
          <Loader />
        </WhiteBoxWrap>
      </MenuLayout>
    );

  if (words && words.length < 30) {
    return (
      <MenuLayout>
        <WhiteBoxWrap>
          <VStack px={4} textAlign="center">
            <Typography>Недостатня кількість слів для повторення.</Typography>
            <Typography>
              Після проходження 1-2 уроків, слова автоматично добавляться сюди та буде можливість їх
              повторювати.
            </Typography>
          </VStack>
        </WhiteBoxWrap>
      </MenuLayout>
    );
  }

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }}>
        <Box px={5} py={2}>
          {Boolean(count) && (
            <Typography textAlign="center" pt={2}>
              Залишилось повторити: {count}
            </Typography>
          )}
          {!Boolean(count) && (
            <Typography textAlign="center" pt={2}>
              На сьогодні всі слова повторені, але ти можеш продовжити&nbsp;🚀
            </Typography>
          )}
        </Box>
        <Box px={5} pb={5}>
          <Tabs value={tabsValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Ukr-Eng" />
            <Tab label="Eng-Ukr" />
            {/* <Tab label="Word List" /> */}
          </Tabs>
        </Box>
      </Box>
      <CustomTabPanel value={tabsValue} index={0}>
        <Box sx={{ backgroundColor: "white" }}>
          <RepetitionUserWord
            word={word}
            wordsCount={words!.length}
            goToNextSlide={goToNextSlide}
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabsValue} index={1}>
        <Box sx={{ backgroundColor: "white" }}>
          <RepetitionUserWord
            word={word}
            wordsCount={words!.length}
            reversed
            goToNextSlide={goToNextSlide}
          />
        </Box>
      </CustomTabPanel>
      {/* <CustomTabPanel value={tabsValue} index={2}>
        <Box sx={{ backgroundColor: "white" }} p={5}>
          <HStack gap={5} my={5}>
            {isAdmin(authUser?.id) && (
              
            {authUser && (
              <AdminUsers
                // userId={changedUserUid || authUser.uuid}
                users={users}
                onChange={(user) => {
                  setChangedUserUid(user.uuid);
                }}
              />
            )}
          </HStack>
          {isAdmin(authUser?.id) && (
            <Box mb={5}>
              <RepetitionUserStory />
            </Box>
          )}
          {todayEnglishWords && (
            <HoverDetails
              words={todayEnglishWords.slice(0, 200).map((item, index) => {
                {
                  return {
                    audio_url: item.audio_url,
                    description: item.description,
                    id: item.word,
                    label: index !== 199 ? `${item.word}, ` : item.word,
                    page: 1,
                    transcription: item.transcription,
                    translation: item.translation,
                    word: item.word,
                  };
                }
              })}
            />
          )}
        </Box>
      </CustomTabPanel> */}
    </MenuLayout>
  );
};

export default memo(RepetitionUserWordsScreen);
