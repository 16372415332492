import { Container, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import wait from "../../../common/wait";
import { Word } from "../../../types/supabase";
import UpdateWordTranslationItem from "../UpdateWordTranslationItem";

const WordUpdate = () => {
  const [word, setWord] = useState("");
  const [fetchedWord, setFetchedWord] = useState<Word>();
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    let mounted = true;
    (async () => {
      await wait(500);

      if (!mounted) return;

      const { data: spWord } = await supabaseClient
        .from("words")
        .select()
        .eq("name", word)
        .maybeSingle();

      if (!mounted || !word) return;

      setFetchedWord(spWord);
    })();

    return () => {
      mounted = false;
    };
  }, [word, trigger]);

  const handleRefreshData = () => {
    setTrigger(trigger + 1);
  };

  return (
    <Container maxWidth="sm">
      <Grid container pb={200}>
        <Grid item xs={12}>
          <Typography>Check and update a word.</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            color="primary"
            value={word}
            onChange={(event) => {
              setWord(event.target.value);
            }}
            type="text"
            variant="outlined"
          />
        </Grid>

        {fetchedWord && (
          <UpdateWordTranslationItem
            word={fetchedWord}
            refreshData={handleRefreshData}
          />
        )}
      </Grid>
    </Container>
  );
};

export default WordUpdate;
