import { Box, Button, Link as MuiLink, Typography } from "@mui/material";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import getRandomGreetings from "../../../common/getRandomGreetings";
import isAdmin from "../../../common/isAdmin";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import {
  EnglishQuote,
  EnglishStory,
  Module,
  Sentence,
  UserSentence,
  Word,
} from "../../../types/supabase";
import AudioPlayer from "../../Audio/AudioPlayer";
import WordAudio from "../../Audio/WordAudio";
import MenuLayout from "../../Layout/MenuLayout";
import MicSentences from "../../Mic/MicSentences";
import SentencePazleItem from "../../Sentence/SentencePazleItem";
import UkrainianWord from "../../Sentence/UkrainianWord";
import HStack from "../../Unknown/HStack";
import Loader from "../../Unknown/Loader";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import GrammarHomework from "./tabs/GrammarHomework";
import Quotes from "../Quotes";
import Stories from "../Stories";
import LinearProgress from "../../User/LinearProgress";
import BuildWords from "./tabs/BuildWords";
import getStyleForTab from "./utils/getStyleForTab";
import WordsCarousel from "./tabs/WordsCarousel";
import BuildSentences from "./tabs/BuildSentences";
import Listening from "./tabs/Listening";
import Speaking from "./tabs/Speaking";
import QuotesDrawer from "./tabs/QuotesDrawer";

const GrammarLesson: React.FC = () => {
  const { lessonId, lessonType } = useParams();
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const setLessonNumber = useLessonsStore((state) => state.setLessonNumber);

  useEffect(() => {
    if (lessonId) setLessonNumber(+lessonId);
  }, [lessonId]);

  const [lessonSentences, setLessonSentences] = useState<Sentence[]>([]);
  const [module, setModule] = useState<Module>();
  const [currentModuleWords, setCurrentModuleWords] = useState<Word[]>([]);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  // console.log("🚀 ~ curerntModuleWordsMap:", curerntModuleWordsMap);

  useEffect(() => {
    if (module) {
      const map = globalWords
        .filter((w) => module.words.includes(w.name) || module.words.includes(w.name.toLowerCase()))
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [w.name.toLowerCase()]: w,
          };
        }, {});
      // console.log("🚀 ~ map:", map);

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, module]);

  useMountedWaitAsyncEffect(async () => {
    if (module?.words) {
      const existingWords = globalWords.filter(
        (w) => module.words.includes(w.name) || module.words.includes(w.name.toLowerCase()),
      );

      if (existingWords.length === module.words.length) {
        setCurrentModuleWords(existingWords);
      } else {
        const rowExistingWords = existingWords.map((w) => w.name);
        const notExistingWords = module.words.filter(
          (w) => !rowExistingWords.includes(w) && !rowExistingWords.includes(w.toLowerCase()),
        );

        const { data } = await supabaseClient.from("words").select().in("name", notExistingWords);

        if (data) {
          setCurrentModuleWords([...existingWords, ...data]);

          setGlobalWords(data);
        }
      }
    }
  }, [module]);

  useMountedWaitAsyncEffect(async () => {
    const { data: module } = await supabaseClient
      .from("grammar_course")
      .select()
      .eq("module", lessonId)
      .returns<Module>()
      .single();
    if (module) setModule(module);

    const { data } = await supabaseClient
      .from("grammar_course_sentences")
      .select("*, sentence(*)")
      .eq("module", lessonId)
      .order("order");
    if (data) {
      // setLessonSentences(data.map((s) => s.sentence).slice(0, 2));
      setLessonSentences(data.map((s) => s.sentence));
    }
  }, [lessonId]);

  const [buildSentenceCount, setBuildSentenceCount] = useState(0);
  const [wordIndex, setWordIndex] = useState(0);

  const authUser = useLessonsStore((state) => state.authUser);
  const [userSentences, setUserSentences] = useState<string[]>([]);
  const [userWords, setUserWords] = useState<string[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (module?.words) {
      // const { data } = await supabaseClient.from("user-words").select().in("word", module.words);
      // if (!data) return;

      // const rowWords = data.map((w) => w.word);
      // console.log("🚀 ~ rowWords:", rowWords);

      const { data: notExistingWords } = await supabaseClient.rpc("get_not_existing_words", {
        word_list: [...module.words, "Dursley"],
      });
      // console.log("🚀 ~ notExistingWords:", notExistingWords);

      // const newWords = module.words.filter((w) => !rowWords.includes(w));
      // console.log("🚀 ~ newWords:", newWords);
      if (notExistingWords.length) {
        await Promise.all(
          notExistingWords.map((w: string) => {
            return supabaseClient.rpc("add_user_word", {
              userprop: authUser!.id,
              word: w,
            });
          }),
        );
      }

      // if
    }
  }, [module]);

  const getUserSentences = useCallback(async () => {
    const { data: userExistingSentences } = await supabaseClient
      .from("user-sentences")
      .select("en")
      .in(
        "id",
        Array.from(new Set(lessonSentences)).map((s) => `${authUser!.uuid}-${s.en}`),
      )
      .returns<UserSentence[]>();
    console.log("🚀 ~ userExistingSentences:", userExistingSentences);
    if (userExistingSentences) {
      setUserSentences(userExistingSentences.map(({ en }) => en));
    }
  }, [lessonSentences, authUser]);

  useMountedWaitAsyncEffect(async () => {
    if (lessonSentences.length && authUser) {
      await getUserSentences();
    }
  }, [lessonSentences]);

  useEffect(() => {
    const channel = supabaseClient
      .channel("room7")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "user-sentences" },
        async (payload) => {
          console.log("🚀 ~ payload:", payload);
          await getUserSentences();
          if (payload.new?.en) {
            setUserSentences((prev) => [...prev, payload.new.en]);
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const prevWords = useMemo(() => {
    if (!module) return [];
    const w = [...module.write_words];
    w.length = wordIndex;
    return w;
  }, [module, wordIndex]);

  const currentWord = useMemo(() => {
    if (!module) return null;

    const w = module.write_words[wordIndex];
    if (!w) return null;

    return w;
  }, [module, wordIndex]);
  // console.log("🚀 ~ currentWord:", currentWord);

  const wordsGreeting = useMemo(() => {
    return getRandomGreetings("Всі слова складені.");
  }, []);

  const [quotes, setQuotes] = useState<EnglishQuote[]>([]);
  const [stories, setStories] = useState<EnglishStory[]>([]);
  const areThereQuotes = !!module?.quotes?.length;
  const areThereStories = !!module?.stories?.length;
  const setIsGrammarFeedOpen = useLessonsStore((state) => state.setIsGrammarFeedOpen);

  useMountedWaitAsyncEffect(async () => {
    if (!areThereQuotes) return;

    const { data } = await supabaseClient.from("english_quotes").select().in("id", module.quotes);

    if (data) setQuotes(data);
  }, [module, areThereQuotes]);

  useMountedWaitAsyncEffect(async () => {
    if (!areThereStories || !module.stories) return;

    const { data } = await supabaseClient.from("english_verses").select().in("id", module.stories);

    if (data) setStories(data);
  }, [module, areThereStories]);

  const allWordsAreBuilt = currentWord === null;

  // console.log("isAdmin(authUser?.uuid)", isAdmin(authUser?.id));

  return (
    <MenuLayout isFluid>
      <Box width="100%" overflow="hidden" position="relative">
        <Box sx={{ backgroundColor: "white" }} py={3} px={3} mb={2} width="100%">
          <VStack gap={1}>
            <MuiLink color="secondary.dark" component={Link} to={`/grammar`}>
              <Typography>Усі уроки</Typography>
            </MuiLink>
            <HStack>
              <VStack>
                <Typography variant="h3">Урок {lessonNumber}</Typography>
                <WordsCarousel currentWord={currentWord} words={module?.write_words || []} />
                {areThereStories && <Stories stories={stories} />}
                <Button
                  variant="outlined"
                  // size="small"
                  onClick={() => {
                    setLessonNumber(+(lessonId || 0));
                    setIsGrammarFeedOpen(true);
                  }}
                >
                  Граматика
                </Button>
                <BuildSentences
                  lessonSentences={lessonSentences}
                  userSentences={userSentences}
                  curerntModuleWordsMap={curerntModuleWordsMap}
                />
                <BuildWords
                  curerntModuleWordsMap={curerntModuleWordsMap}
                  allWordsAreBuilt={allWordsAreBuilt}
                  areThereQuotes={areThereQuotes}
                  wordsGreeting={wordsGreeting}
                  prevWords={prevWords}
                  moduleWords={module?.write_words || []}
                  wordsLength={module?.write_words?.length || 0}
                />
                <Listening lessonSentences={lessonSentences} />
                <Speaking lessonSentences={lessonSentences} />
                {areThereQuotes && <QuotesDrawer quotes={quotes} />}
                <GrammarHomework sentences={lessonSentences} userSentences={userSentences} />
              </VStack>
            </HStack>
            <HStack>
              {isAdmin(authUser?.id) && (
                <MuiLink
                  color={lessonType === "build-words-list" ? "primary" : "secondary.dark"}
                  component={Link}
                  to={`/grammar/grammar-lesson/${lessonId}/build-words-list`}
                >
                  <Typography color="red">
                    Нові слова {module?.write_words.length ? `(${module.write_words.length})` : ""}
                  </Typography>
                </MuiLink>
              )}
              {isAdmin(authUser?.id) && (
                <MuiLink
                  color={lessonType === "build-sentences-list" ? "primary" : "secondary.dark"}
                  component={Link}
                  to={`/grammar/grammar-lesson/${lessonId}/build-sentences-list`}
                >
                  <Typography color="red">Список речень</Typography>
                </MuiLink>
              )}
            </HStack>
          </VStack>
        </Box>
        <Box sx={getStyleForTab(lessonType, "build-words-list")}>
          <Box sx={{ backgroundColor: "white" }} py={5} px={3} mb={2} width="100%">
            {/* {!lessonSentences.length && <Loader />} */}
            {!!module?.write_words && (
              <VStack gap={7}>
                {module.write_words.map((word) => {
                  if (!curerntModuleWordsMap[word]) return null;
                  const globalWord = globalWords.find((w) => w.name === word);

                  return (
                    <VStack gap={0}>
                      <Typography variant="h3">{word}</Typography>
                      <Typography>{globalWord?.transcription}</Typography>
                      <WordAudio url={globalWord?.audio_url || ""} />
                      <UkrainianWord translation={globalWord?.translation || ""} />
                      {/* <Typography gutterBottom>{globalWord?.translation}</Typography> */}
                    </VStack>
                  );
                })}
              </VStack>
            )}
          </Box>

          {!!prevWords.length && (
            <VStack sx={{ backgroundColor: "white" }}>
              <Box pt={5} pb={allWordsAreBuilt ? 0 : 5} px={3}>
                {prevWords.map((w) => {
                  return (
                    <HoverDetails
                      // isCenter
                      words={[
                        {
                          ...curerntModuleWordsMap[w],
                          id: w,
                          page: 1,
                          word: w,
                          label: w,
                        },
                      ]}
                    />
                  );
                })}
              </Box>
            </VStack>
          )}
        </Box>
        <Box sx={getStyleForTab(lessonType, "build-sentences-list")}>
          <Box sx={{ backgroundColor: "white" }} py={5} px={3} mb={2} width="100%">
            {!lessonSentences.length && <Loader />}
            {!!lessonSentences.length && (
              <VStack alignItems="flex-start">
                {lessonSentences.map((sentence) => {
                  return (
                    <Box key={sentence.en}>
                      <Typography>{sentence.en}</Typography>
                      <Typography variant="caption">{sentence.ua}</Typography>
                    </Box>
                  );
                })}
              </VStack>
            )}
          </Box>
        </Box>
      </Box>
    </MenuLayout>
  );
};

export default GrammarLesson;
