import { Box, LinearProgress as MuiLP } from "@mui/material";
import { useMemo } from "react";

interface LinearProgressProps {
  height?: number;
  isBottom?: boolean;
  currentValue: number;
  elementsCount: number;
}

const LinearProgress = ({ height, isBottom = false, currentValue, elementsCount }: LinearProgressProps) => {
  const { wrapperSx, muiLpSx } = useMemo(() => {
    const wrapperProps: Record<string, unknown> = {};
    const muiLpProps: Record<string, unknown> = {
      width: "calc(100% + 30px)",
      position: "absolute",
      height: height || 8,
      zIndex: 100,
    };

    if (isBottom) {
      wrapperProps.position = "fixed";
      wrapperProps.width = "100vw";
      wrapperProps.bottom = 0;
      wrapperProps.left = 0;
      wrapperProps.height = height || 5;
    } else {
      muiLpProps.top = -37;
      muiLpProps.left = -15;
    }

    return { wrapperSx: wrapperProps, muiLpSx: muiLpProps };
  }, [isBottom]);

  return (
    <Box position="relative" sx={wrapperSx}>
      <MuiLP
        variant="determinate"
        value={(100 / elementsCount) * currentValue}
        color="secondary"
        style={{
          height: height || 10,
        }}
      />
    </Box>
  );
};

export default LinearProgress;
