import { InitialDialog } from "./types";

const a1dialogs: InitialDialog[] = [
  {
    context:
      "Дві нові колеги, Анна та Марія, знайомляться під час обідньої перерви та розмовляють про свої сім'ї.",
    topic: ["family"],
    dialogType: "casual",
    level: "A1",
    dialog: [
      {
        person: 1,
        en: "Hi, I'm Anna. Nice to meet you!",
        ua: "Привіт, я Анна. Приємно познайомитися!",
      },
      {
        person: 2,
        en: "Hi Anna, I'm Maria. Nice to meet you too!",
        ua: "Привіт, Анно, я Марія. Мені теж приємно познайомитися!",
      },
      {
        person: 1,
        en: "Do you have a big family, Maria?",
        ua: "У тебе велика сім'я, Маріє?",
      },
      {
        person: 2,
        en: "Yes, I do. I have a mother, father, and two brothers.",
        ua: "Так. У мене є мама, тато і два брати.",
      },
      {
        person: 1,
        en: "That's nice! Do your brothers live near you?",
        ua: "Це чудово! Твої брати живуть поруч з тобою?",
      },
      {
        person: 2,
        en: "One of them does. He has a wife and two children, a son and a daughter. So I'm an aunt!",
        ua: "Один з них так. У нього є дружина і двоє дітей, син і дочка. Тож я тітка!",
      },
      {
        person: 1,
        en: "That's wonderful! I bet you love being an aunt.",
        ua: "Це чудово! Впевнена, тобі подобається бути тіткою.",
      },
      {
        person: 2,
        en: "I do! What about you, Anna? Do you have any siblings?",
        ua: "Так! А як щодо тебе, Анно? У тебе є брати або сестри?",
      },
      {
        person: 1,
        en: "Yes, I have a younger sister. She still lives with my parents.",
        ua: "Так, у мене є молодша сестра. Вона досі живе з моїми батьками.",
      },
      {
        person: 2,
        en: "Do you see each other often?",
        ua: "Ви часто бачитеся?",
      },
      {
        person: 1,
        en: "Yes, we try to get together every week. We're very close.",
        ua: "Так, ми намагаємося зустрічатися щотижня. Ми дуже близькі.",
      },
      {
        person: 2,
        en: "That's nice. Do you have a large extended family?",
        ua: "Це чудово. У тебе велика родина?",
      },
      {
        person: 1,
        en: "Yes, I have several aunts, uncles, and cousins. My grandparents live in another state, but we visit them a few times a year.",
        ua: "Так, у мене є кілька тіток, дядьків і двоюрідних братів та сестер. Мої бабуся і дідусь живуть в іншому штаті, але ми відвідуємо їх кілька разів на рік.",
      },
      {
        person: 2,
        en: "It sounds like you have a lovely family, Anna.",
        ua: "Здається, у тебе чудова сім'я, Анно.",
      },
    ],
  },
  {
    context:
      "Двоє друзів, Бен та Олівер, розмовляють про плани на літні канікули.",
    topic: ["family"],
    dialogType: "casual",
    level: "A1",
    dialog: [
      {
        person: 1,
        en: "Hey Oliver, do you have any plans for the summer vacation?",
        ua: "Привіт, Олівере, у тебе є якісь плани на літні канікули?",
      },
      {
        person: 2,
        en: "Hi Ben! Yes, my family and I are going to visit my grandparents.",
        ua: "Привіт, Бене! Так, ми з сім'єю збираємося відвідати моїх бабусю і дідуся.",
      },
      {
        person: 1,
        en: "That sounds nice. Where do they live?",
        ua: "Це звучить чудово. Де вони живуть?",
      },
      {
        person: 2,
        en: "They live near the beach. My sister and I are excited to go swimming and build sandcastles.",
        ua: "Вони живуть біля пляжу. Ми з сестрою з нетерпінням чекаємо, щоб поплавати та побудувати піщані замки.",
      },
      {
        person: 1,
        en: "That's cool! Do you have any cousins there?",
        ua: "Це класно! У тебе там є двоюрідні брати або сестри?",
      },
      {
        person: 2,
        en: "Yes, I have two cousins who live nearby. We always have fun playing together.",
        ua: "Так, у мене є двоє двоюрідних братів, які живуть неподалік. Нам завжди весело грати разом.",
      },
      {
        person: 1,
        en: "That sounds like fun. What about your parents, what are they planning to do?",
        ua: "Звучить весело. А що щодо твоїх батьків, що вони планують робити?",
      },
      {
        person: 2,
        en: "My mother is looking forward to relaxing and reading books on the beach, and my father wants to go fishing with my uncle.",
        ua: "Моя мама з нетерпінням чекає на відпочинок і читання книг на пляжі, а мій тато хоче порибалити з моїм дядьком.",
      },
      {
        person: 1,
        en: "Sounds like everyone has something to enjoy. And what about you, Ben? Any summer plans?",
        ua: "Здається, кожен має чим насолодитися. А як щодо тебе, Бене? Якісь плани на літо?",
      },
      {
        person: 2,
        en: "My aunt and uncle are coming to visit us. My cousin is coming too, and we're planning to go camping and hiking in the mountains.",
        ua: "Моя тітка і дядько приїжджають до нас у гості. Мій двоюрідний брат теж приїжджає, і ми плануємо піти в похід і кемпінг в горах.",
      },
      {
        person: 1,
        en: "Wow, that sounds adventurous! Your son and daughter must be excited.",
        ua: "Вау, це звучить захоплююче! Твій син і дочка, мабуть, у захваті.",
      },
      {
        person: 2,
        en: "They are! They love spending time outdoors.",
        ua: "Так! Вони люблять проводити час на свіжому повітрі.",
      },
    ],
  },
  {
    context: "Софія та її бабуся розмовляють про сімейні фотографії.",
    topic: ["family"],
    dialogType: "casual",
    level: "A1",
    dialog: [
      {
        person: 1,
        en: "Grandma, can we look at old family photos?",
        ua: "Бабусю, ми можемо подивитися старі сімейні фотографії?",
      },
      {
        person: 2,
        en: "Of course, dear. I love looking at them too.",
        ua: "Звичайно, люба. Я теж люблю їх розглядати.",
      },
      {
        person: 1,
        en: "Who is this little boy, Grandma?",
        ua: "Хто цей маленький хлопчик, бабусю?",
      },
      {
        person: 2,
        en: "That's your father when he was a child. He loved playing with his toy cars.",
        ua: "Це твій тато, коли він був дитиною. Він любив грати зі своїми машинками.",
      },
      {
        person: 1,
        en: "He looks so different! And who is this little girl next to him?",
        ua: "Він виглядає так інакше! А хто ця маленька дівчинка поруч з ним?",
      },
      {
        person: 2,
        en: "That's your aunt Sarah. She was always a very curious child.",
        ua: "Це твоя тітка Сара. Вона завжди була дуже допитливою дитиною.",
      },
      {
        person: 1,
        en: "And who are these people, Grandma?",
        ua: "А хто ці люди, бабусю?",
      },
      {
        person: 2,
        en: "Those are your great-grandparents. They came to this country many years ago.",
        ua: "Це твої прабабуся і прадідусь. Вони приїхали до цієї країни багато років тому.",
      },
      {
        person: 1,
        en: "Wow, they look so young! And who is this baby?",
        ua: "Вау, вони виглядають такими молодими! А хто ця дитина?",
      },
      {
        person: 2,
        en: "That's your uncle Tom when he was just a few months old. He was such a sweet baby.",
        ua: "Це твій дядько Том, коли йому було лише кілька місяців. Він був такою милою дитиною.",
      },
      {
        person: 1,
        en: "I love looking at these photos, Grandma. They tell so many stories.",
        ua: "Я люблю розглядати ці фотографії, бабусю. Вони розповідають так багато історій.",
      },
      {
        person: 2,
        en: "Me too, dear. They remind us of where we come from and how much we've grown as a family.",
        ua: "Я теж, люба. Вони нагадують нам, звідки ми родом і наскільки ми виросли як сім'я.",
      },
    ],
  },
  {
    context: "Дві подруги, Емілі та Хлоя, обговорюють свої житлові умови.",
    topic: ["home"],
    dialogType: "casual",
    level: "A1",
    dialog: [
      {
        person: 1,
        en: "I love your apartment, Chloe! It's so cozy.",
        ua: "Мені подобається твоя квартира, Хлоє! Вона така затишна.",
      },
      {
        person: 2,
        en: "Thanks, Emily! I really like it here. It's small, but it has everything I need.",
        ua: "Дякую, Емілі! Мені тут дуже подобається. Вона невелика, але в ній є все, що мені потрібно.",
      },
      {
        person: 1,
        en: "How many bedrooms do you have?",
        ua: "Скільки у тебе спалень?",
      },
      {
        person: 2,
        en: "Just one, but it's a good size. And I have a nice living room with a big window.",
        ua: "Тільки одна, але вона хорошого розміру. І у мене є гарна вітальня з великим вікном.",
      },
      {
        person: 1,
        en: "That's nice. Does your apartment have a balcony?",
        ua: "Це добре. У твоїй квартирі є балкон?",
      },
      {
        person: 2,
        en: "No, it doesn't. But I have a small garden in the back of the building.",
        ua: "Ні, немає. Але у мене є маленький сад позаду будівлі.",
      },
      {
        person: 1,
        en: "A garden? That's amazing! Do you grow any vegetables or flowers?",
        ua: "Сад? Це чудово! Ти вирощуєш овочі або квіти?",
      },
      {
        person: 2,
        en: "I have some flowers and herbs. It's nice to have a little bit of nature in the city.",
        ua: "У мене є трохи квітів і трав. Приємно мати трохи природи в місті.",
      },
      {
        person: 1,
        en: "I agree. What about your kitchen? Is it big?",
        ua: "Я згодна. А як щодо твоєї кухні? Вона велика?",
      },
      {
        person: 2,
        en: "It's not very big, but it has everything I need to cook. And I have a small table and chairs.",
        ua: "Вона не дуже велика, але в ній є все, що мені потрібно для приготування їжі. І у мене є невеликий стіл і стільці.",
      },
      {
        person: 1,
        en: "And what about your bathroom? Is it modern?",
        ua: "А як щодо твоєї ванної кімнати? Вона сучасна?",
      },
      {
        person: 2,
        en: "It's pretty basic, but it's clean and functional. I have a shower, toilet, and sink.",
        ua: "Вона досить проста, але чиста і функціональна. У мене є душ, туалет і раковина.",
      },
      {
        person: 1,
        en: "That sounds good. I like that your apartment has a lot of natural light. The windows are quite large.",
        ua: "Це звучить добре. Мені подобається, що в твоїй квартирі багато природного світла. Вікна досить великі.",
      },
      {
        person: 2,
        en: "Yes, I love the natural light. And the walls are a nice light color, so it makes the apartment feel even bigger.",
        ua: "Так, я люблю природне світло. І стіни приємного світлого кольору, тому квартира здається ще більшою.",
      },
      {
        person: 1,
        en: "It's really a lovely apartment, Chloe.",
        ua: "Це дійсно чудова квартира, Хлоє.",
      },
      {
        person: 2,
        en: "Thanks, Emily. I'm glad you like it.",
        ua: "Дякую, Емілі. Я рада, що тобі подобається.",
      },
    ],
  },
  {
    context:
      "Девід та Ліза розмовляють про пошук нового будинку для своєї сім'ї.",
    topic: ["home"],
    dialogType: "casual",
    level: "A1",
    dialog: [
      {
        person: 1,
        en: "We really need to find a bigger house, Lisa.",
        ua: "Нам дійсно потрібно знайти більший будинок, Лізо.",
      },
      {
        person: 2,
        en: "I know, David. Our apartment is getting too small for us and the kids.",
        ua: "Я знаю, Девіде. Наша квартира стає занадто маленькою для нас і дітей.",
      },
      {
        person: 1,
        en: "We need at least three bedrooms.",
        ua: "Нам потрібно щонайменше три спальні.",
      },
      {
        person: 2,
        en: "Yes, and a bigger living room would be nice.",
        ua: "Так, і більша вітальня була б непоганою.",
      },
      {
        person: 1,
        en: "And a garden for the kids to play in.",
        ua: "І сад, щоб діти могли гратися.",
      },
      {
        person: 2,
        en: "That would be perfect! And maybe a bigger kitchen too.",
        ua: "Це було б ідеально! І, можливо, більша кухня теж.",
      },
      {
        person: 1,
        en: "Yes, our kitchen is definitely too small. We can barely fit in there when we're cooking together.",
        ua: "Так, наша кухня точно занадто маленька. Ми ледве поміщаємося там, коли готуємо разом.",
      },
      {
        person: 2,
        en: "And we only have one bathroom. That can be a bit chaotic in the mornings.",
        ua: "І у нас тільки одна ванна кімната. Вранці це може бути трохи хаотично.",
      },
      {
        person: 1,
        en: "I know, two bathrooms would be much better.",
        ua: "Я знаю, дві ванні кімнати були б набагато краще.",
      },
      {
        person: 2,
        en: "And it would be great if the house had lots of windows and natural light.",
        ua: "І було б чудово, якби в будинку було багато вікон і природного світла.",
      },
      {
        person: 1,
        en: "Yes, that's important. And we need a house with a strong front door and secure windows.",
        ua: "Так, це важливо. І нам потрібен будинок з міцними вхідними дверима і надійними вікнами.",
      },
      {
        person: 2,
        en: "Definitely. We need to start looking at houses soon.",
        ua: "Безумовно. Нам потрібно скоро почати шукати будинки.",
      },
    ],
  },
  {
    context: "Марк та Сара обговорюють ремонт у своїй новій квартирі.",
    topic: ["home"],
    dialogType: "casual",
    level: "A1",
    dialog: [
      {
        person: 1,
        en: "I think we should paint the living room a different color, Sarah.",
        ua: "Я думаю, нам слід пофарбувати вітальню в інший колір, Саро.",
      },
      {
        person: 2,
        en: "You're right, Mark. The current color is a bit dull.",
        ua: "Ти маєш рацію, Марк. Поточний колір трохи тьмяний.",
      },
      {
        person: 1,
        en: "What color do you like?",
        ua: "Який колір тобі подобається?",
      },
      {
        person: 2,
        en: "Maybe a light blue or green. Something calming.",
        ua: "Можливо, світло-блакитний або зелений. Щось заспокійливе.",
      },
      {
        person: 1,
        en: "That sounds good. And we need to do something about the kitchen walls. They're a bit dirty.",
        ua: "Це звучить добре. І нам потрібно щось зробити зі стінами на кухні. Вони трохи брудні.",
      },
      {
        person: 2,
        en: "Maybe we can put up some tiles. That would be easier to clean.",
        ua: "Можливо, ми можемо покласти плитку. Її буде легше чистити.",
      },
      {
        person: 1,
        en: "Good idea. And we definitely need new curtains for the bedroom windows.",
        ua: "Гарна ідея. І нам точно потрібні нові штори для вікон спальні.",
      },
      {
        person: 2,
        en: "Yes, the current ones are a bit old-fashioned.",
        ua: "Так, поточні трохи старомодні.",
      },
      {
        person: 1,
        en: "And we need to fix the door to the bathroom. It doesn't close properly.",
        ua: "І нам потрібно полагодити двері до ванної кімнати. Вони не закриваються належним чином.",
      },
      {
        person: 2,
        en: "I noticed that too. We should probably call a handyman.",
        ua: "Я теж це помітила. Мабуть, нам слід викликати майстра.",
      },
      {
        person: 1,
        en: "Yes, and maybe we can also ask him to install a new light fixture in the living room.",
        ua: "Так, і можливо, ми також можемо попросити його встановити новий світильник у вітальні.",
      },
    ],
  },
  {
    context:
      "Анна та її донька Емілі розмовляють про прибирання в їхньому будинку.",
    topic: ["home"],
    dialogType: "casual",
    level: "A1",
    dialog: [
      {
        person: 1,
        en: "Emily, can you help me clean the house today?",
        ua: "Емілі, ти можеш допомогти мені прибрати будинок сьогодні?",
      },
      {
        person: 2,
        en: "Sure, Mom. What do you need me to do?",
        ua: "Звичайно, мамо. Що тобі потрібно, щоб я зробила?",
      },
      {
        person: 1,
        en: "Can you please clean your bedroom? Your clothes are all over the floor.",
        ua: "Ти можеш, будь ласка, прибрати свою спальню? Твій одяг розкиданий по всій підлозі.",
      },
      {
        person: 2,
        en: "Okay, I'll put them away.",
        ua: "Добре, я їх приберу.",
      },
      {
        person: 1,
        en: "And can you please take out the trash? It's full.",
        ua: "І ти можеш, будь ласка, винести сміття? Воно повне.",
      },
      {
        person: 2,
        en: "Sure, I can do that.",
        ua: "Звичайно, я можу це зробити.",
      },
      {
        person: 1,
        en: "Thank you, Emily. I'm going to clean the kitchen and living room.",
        ua: "Дякую, Емілі. Я збираюся прибрати кухню і вітальню.",
      },
      {
        person: 2,
        en: "Can I help you with anything in the kitchen?",
        ua: "Я можу тобі чимось допомогти на кухні?",
      },
      {
        person: 1,
        en: "Yes, please. Can you wash the dishes?",
        ua: "Так, будь ласка. Ти можеш помити посуд?",
      },
      {
        person: 2,
        en: "No problem.",
        ua: "Немає проблем.",
      },
      {
        person: 1,
        en: "And after that, can you please water the plants in the garden?",
        ua: "А після цього, ти можеш, будь ласка, полити рослини в саду?",
      },
      {
        person: 2,
        en: "Sure, Mom.",
        ua: "Звичайно, мамо.",
      },
    ],
  },
];

export default a1dialogs;
