import { useLessonsStore } from "../../../../../store/lessonsStore";
import Box from "../../../Box";
import Button from "../../../Button";
import Typography from "../../../Typography";

const PresentSimpleTenseTitle = () => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  return (
    <>
      <Box
        width={750}
        position="absolute"
        border="1px solid rgba(224, 224, 224, 1)"
        left={0}
        top={0}
        p={2}
        sx={{
          zIndex: 1,
          backgroundColor: lessonNumber === 1 ? "#deffc5" : "#fcffdb",
        }}
      >
        <Typography variant="h3">Present Simple</Typography>
        <Typography>
          <strong>Теперішній (теперішній простий)</strong> час використовується для вираження <br />
          рутинних справ і регулярних подій, відображаючи те, <br /> що відбувається завжди або
          зазвичай.
        </Typography>
        {/* <Button variant="outlined" size="small">
          Stative verbs
        </Button> */}
      </Box>
    </>
  );
};

export default PresentSimpleTenseTitle;
