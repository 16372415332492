import { Box } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import useIsInViewport from "../../../hooks/useIsInViewport";
import { SanskritWord } from "../../../types/supabase";
import EnglishAudioSentence from "../EnglishAudioSentence";
import Hint, { HintType } from "../Hint";
import RepetitionButtons from "../RepetitionButtons";
import TranslateButton from "../TranslateButton";
import UkrainianWord from "../UkrainianWord";
import SanskritEnglishSentence from "../SanskritEnglishSentence";

interface SanskritRepetitionWordProps {
  word: SanskritWord;
  wordsCount: number;
  goToNextSlide: (word: string, period: string) => void;
}

const SanskritRepetitionWord = ({
  word,
  wordsCount,
  goToNextSlide,
}: SanskritRepetitionWordProps) => {
  const [isTranslated, setIsTranslated] = useState(false);
  const ref = useRef<HTMLElement>();
  const isInViewport = useIsInViewport(ref);

  const resetIsTranslatedAfterSlideChange = () => {
    setTimeout(() => {
      setIsTranslated(false);
    }, 500);
  };

  useEffect(() => {
    if (!isInViewport) resetIsTranslatedAfterSlideChange();
  }, [isInViewport]);

  return (
    <Box
      pt={10}
      pb={10}
      display="flex"
      alignItems="center"
      position="relative"
      minHeight="calc(100vh - 200px)"
    >
      <Box
        gap={3}
        width="100%"
        display="flex"
        position="relative"
        textAlign="center"
        alignItems="center"
        flexDirection="column"
        pt={10}
      >
        <Hint text={word.id} type={HintType.word} />
        {/* center box to detect i in viewport */}
        <Box ref={ref} width={10} height={10} />
        <UkrainianWord translation={word.ua} />

        <SanskritEnglishSentence
          en={word.en}
          ua={word.ua}
          romanId={word.id}
          script={word.script}
          transcription={word.id}
          isVisible={isTranslated}
        />
        <TranslateButton
          isVisible={!isTranslated}
          onTranslate={() => setIsTranslated(true)}
        />
        <RepetitionButtons
          isVisible={isTranslated}
          wordsCount={wordsCount}
          goToNextSlide={(period) => {
            goToNextSlide(word.id, period);
            setIsTranslated(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(SanskritRepetitionWord);
