import Box from "../../Unknown/Box";

const FoodItemImage: React.FC<{ image: string; size?: "big" }> = ({ image, size }) => {
  return (
    <Box
      width="100%"
      maxWidth={320}
      height={size ? 250 : 100}
      overflow="hidden"
      sx={{
        backgroundImage: `url(food-items-images/${image}.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
};

export default FoodItemImage;
