import { Sentence, Word } from "../../../../types/supabase";
import { Drawer, Link as MuiLink } from "@mui/material";
import Box from "../../../Unknown/Box";
import Button from "../../../Unknown/Button";
import Typography from "../../../Unknown/Typography";
import VStack from "../../../Unknown/VStack";
import HoverDetails from "../../../Word/HoverDetails";
import WordLetterPazleItem from "../../../Word/WordLetterPazleItem";
import { Link, useParams } from "react-router-dom";
import LinearProgress from "../../../User/LinearProgress";
import { useState } from "react";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import AudioPlayer from "../../../Audio/AudioPlayer";
import MicSentences from "../../../Mic/MicSentences";

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const Speaking: React.FC<{
  lessonSentences: Sentence[];
}> = ({ lessonSentences }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Говоріння
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9999999,
        }}
      >
        <Box sx={drawerContentStyle}>
          <ModalCloseButton onClose={handleDrawerClose} />
          <Box sx={{ backgroundColor: "white" }} py={15} px={3} mb={2} width="100%">
            <MicSentences audioUrls={lessonSentences} />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Speaking;
