import { Box, Button, IconButton, Link as MuiLink, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { addDays } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import MenuLayout from "../../Layout/MenuLayout";
import RepetitionUserWord from "../../Sentence/RepetitionUserWord";
import Loader from "../../Unknown/Loader";
import VStack from "../../Unknown/VStack";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";
import { BanglaSentence, BanglaWord, EnglishQuote, FunctionGetWordsWithAdditionalReturn, HoverDetailsWords, Module, Sentence, UserSentence, Word } from "../../../types/supabase";
import SentencePazleItem from "../../Sentence/SentencePazleItem";
import RowSentencePazleItem from "../../Sentence/RowSentencePazleItem";
import { Link, useParams } from "react-router-dom";
import HoverDetails from "../../Word/HoverDetails";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import getRandomGreetings from "../../../common/getRandomGreetings";
import HStack from "../../Unknown/HStack";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import BanglaSentencePazleItem from "../../Sentence/BanglaSentencePazleItem";
import getSentenceWords from "../../../common/getSentenceWords";

const BanglaGrammarLesson: React.FC = () => {
  const { lessonId, lessonType } = useParams();
  console.log("🚀 ~ lessonType:", lessonType);

  const [lessonSentences, setLessonSentences] = useState<BanglaSentence[]>([]);
  const [currentModuleRomanRawWords, setCurrentModuleRomanRawWords] = useState<string[]>([]);
  const [currentModuleWords, setCurrentModuleWords] = useState<BanglaWord[]>([]);
  const [currentModuleScriptRawWords, setCurrentModuleScriptRawWords] = useState<string[]>([]);
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, BanglaWord>>({});
  console.log("🚀 ~ curerntModuleWordsMap:", curerntModuleWordsMap);

  console.log("🚀 ~ lessonSentences:", lessonSentences);

  useMountedWaitAsyncEffect(async () => {
    if (currentModuleRomanRawWords.length) {
      console.log("🚀 ~ currentModuleScriptRawWords:", currentModuleScriptRawWords);
      const { data } = await supabaseClient.from("bangla-words").select().in("script", currentModuleScriptRawWords);

      if (data) {
        setCurrentModuleWords(data);
        setCurrentModuleWordsMap(
          data.reduce((prev, curr) => {
            return {
              ...prev,
              [curr.transliteration]: curr,
            };
          }, {}),
        );
      }
    }
  }, [currentModuleScriptRawWords]);

  useMountedWaitAsyncEffect(async () => {
    const { data } = await supabaseClient.from("bangla-sentences").select("*").eq("module", lessonId).order("created_at");

    if (data) {
      setLessonSentences(data);
      setCurrentModuleRomanRawWords(Array.from(new Set(data.map((s) => getSentenceWords(s.transliteration)).flat())));
      setCurrentModuleScriptRawWords(Array.from(new Set(data.map((s) => getSentenceWords(s.script)).flat())));
    }
  }, [lessonId]);

  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [wordIndex, setWordIndex] = useState(0);

  const prevSentences = useMemo(() => {
    const s = [...lessonSentences];
    s.length = sentenceIndex;
    return s;
  }, [lessonSentences, sentenceIndex]);

  const authUser = useLessonsStore((state) => state.authUser);

  const currentSentence = useMemo(() => {
    const s = lessonSentences[sentenceIndex];
    if (!s) return null;

    return s;
  }, [lessonSentences, sentenceIndex]);

  const sentenceGreeting = useMemo(() => {
    return getRandomGreetings("Всі реченя складені.");
  }, []);
  const wordsGreeting = useMemo(() => {
    return getRandomGreetings("Всі слова складені.");
  }, []);

  const allSentencesAreBuilt = !!lessonSentences.length && currentSentence === null;
  // const allWordsAreBuilt = currentWord === null;

  return (
    <MenuLayout isFluid>
      <Box width="100%" overflow="hidden" position="relative">
        <Box sx={{ backgroundColor: "white" }} py={3} px={3} mb={2} width="100%">
          <VStack gap={1}>
            <MuiLink color="secondary.dark" component={Link} to={`/bangla-grammar`}>
              <Typography>Усі уроки</Typography>
            </MuiLink>
            <HStack>
              <MuiLink color={lessonType === "build-sentences" ? "primary" : "secondary.dark"} component={Link} to={`/grammar/grammar-lesson/${lessonId}/build-sentences`}>
                <Typography>Речення {lessonSentences.length ? `(${lessonSentences.length})` : ""}</Typography>
              </MuiLink>
              {/* <MuiLink color={lessonType === "build-words" ? "primary" : "secondary.dark"} component={Link} to={`/grammar/grammar-lesson/${lessonId}/build-words`}>
                <Typography>Слова {module?.write_words.length ? `(${module.write_words.length})` : ""}</Typography>
              </MuiLink> */}
              {/* {areThereQuotes && (
                <MuiLink color={lessonType === "build-quotes" ? "primary" : "secondary.dark"} component={Link} to={`/grammar/grammar-lesson/${lessonId}/build-quotes`}>
                  <Typography>Цитати</Typography>
                </MuiLink>
              )}

              <MuiLink color={lessonType === "hm" ? "primary" : "secondary.dark"} component={Link} to={`/grammar/grammar-lesson/${lessonId}/hm`}>
                <Typography>Д/з</Typography>
              </MuiLink> */}
            </HStack>
          </VStack>
        </Box>
        <Box
          sx={{
            opacity: lessonType === "build-sentences" ? 1 : 0,
            pointerEvents: lessonType === "build-sentences" ? "auto" : "none",
            left: lessonType === "build-sentences" ? 0 : -999999,
            position: lessonType === "build-sentences" ? "relative" : "absolute",
          }}
        >
          <Box sx={{ backgroundColor: "white" }} py={5} px={3} mb={2} width="100%">
            {!lessonSentences.length && <Loader />}
            {allSentencesAreBuilt && (
              <Box py={4}>
                <Typography color="primary" textAlign="center">
                  {sentenceGreeting}
                </Typography>
                <Typography textAlign="center">Тепер прочитай всі складені речення та переклади.</Typography>
              </Box>
            )}
            {currentSentence !== null && (
              <Box>
                <IconButton
                  onClick={() => {
                    // navigator.clipboard.writeText(`https://supabase.com/dashboard/project/rjrjsfbijrnincmyibxa/editor/36813?schema=public&filter=transliteration%3Aeq%3A${encodeURIComponent(currentSentence.transliteration)}.`);
                    navigator.clipboard.writeText(`https://supabase.com/dashboard/project/rjrjsfbijrnincmyibxa/editor/36813?schema=public&filter=transliteration%3Aeq%3A${currentSentence.transliteration}`);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
                <BanglaSentencePazleItem
                  sentence={currentSentence}
                  // belongsToUser={userSentences.includes(currentSentence.en)}
                  handleNext={() => {
                    setSentenceIndex((prev) => prev + 1);
                  }}
                  moduleWordsMap={curerntModuleWordsMap}
                />
              </Box>
            )}
          </Box>

          {!!prevSentences.length && (
            <VStack sx={{ backgroundColor: "white" }}>
              <Box pt={5} pb={allSentencesAreBuilt ? 0 : 5} px={3}>
                {prevSentences.map((s) => {
                  return (
                    <HoverDetails
                      // isCenter
                      words={
                        s.transliteration.split(" ").map((w: any, index: number) => {
                          const cleanWord = removePunctuationFromString(w);
                          const word = curerntModuleWordsMap[cleanWord] || curerntModuleWordsMap[cleanWord.toLowerCase()];

                          return {
                            ...(word ? word : {}),
                            word: word?.en || cleanWord,
                            label: w,
                          };
                        }) as any
                      }
                    />
                  );
                })}
              </Box>
              {allSentencesAreBuilt && (
                <VStack pb={5}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setSentenceIndex(0);
                    }}
                  >
                    Почати з початку
                  </Button>
                  <MuiLink color={lessonType === "build-words" ? "primary" : "secondary.dark"} component={Link} to={`/grammar/grammar-lesson/${lessonId}/build-words`}>
                    <Typography>Перейти до слів</Typography>
                  </MuiLink>
                </VStack>
              )}
            </VStack>
          )}
        </Box>

        <Box
          sx={{
            opacity: lessonType === "build-words" ? 1 : 0,
            pointerEvents: lessonType === "build-words" ? "auto" : "none",
            left: lessonType === "build-words" ? 0 : -999999,
            position: lessonType === "build-words" ? "relative" : "absolute",
          }}
        >
          <Box sx={{ backgroundColor: "white" }} py={5} px={3} mb={2} width="100%">
            {/* {!lessonSentences.length && <Loader />} */}
            {/* {allWordsAreBuilt && (
              <Box py={4}>
                <Typography color="primary" textAlign="center">
                  {wordsGreeting}
                </Typography>
                <Typography textAlign="center">Тепер прочитай всі складені слова та переклади.</Typography>
              </Box>
            )} */}
            {/* {currentWord !== null && curerntModuleWordsMap[currentWord] && (
              <Box>
                <WordLetterPazleItem
                  word={curerntModuleWordsMap[currentWord]}
                  handleNext={() => {
                    setWordIndex((prev) => prev + 1);
                  }}
                />
              </Box>
            )} */}
          </Box>

          {/* {!!prevWords.length && (
            <VStack sx={{ backgroundColor: "white" }}>
              <Box pt={5} pb={allWordsAreBuilt ? 0 : 5} px={3}>
                {prevWords.map((w) => {
                  return (
                    <HoverDetails
                      // isCenter
                      words={[
                        {
                          ...curerntModuleWordsMap[w],
                          id: w,
                          page: 1,
                          word: w,
                          label: w,
                        },
                      ]}
                    />
                  );
                })}
              </Box>
              {allWordsAreBuilt && (
                <VStack pb={5}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setWordIndex(0);
                    }}
                  >
                    Почати з початку
                  </Button>
                  <MuiLink color="secondary.dark" component={Link} to={`/grammar/grammar-lesson/${lessonId}/build-words`}>
                    <Typography>Повернутися до речень</Typography>
                  </MuiLink>
                  {areThereQuotes && (
                    <MuiLink color="secondary.dark" component={Link} to={`/grammar/grammar-lesson/${lessonId}/build-quotes`}>
                      <Typography>Перейти до цитат</Typography>
                    </MuiLink>
                  )}
                </VStack>
              )}
            </VStack>
          )} */}
        </Box>

        {/* <Box
          sx={{
            opacity: lessonType === "build-quotes" ? 1 : 0,
            pointerEvents: lessonType === "build-quotes" ? "auto" : "none",
            left: lessonType === "build-quotes" ? 0 : -999999,
            position: lessonType === "build-quotes" ? "relative" : "absolute",
          }}
        >
          <Box sx={{ backgroundColor: "white" }} py={5} px={3} mb={2} width="100%">
            <Quotes quotes={quotes} />
          </Box>
        </Box> */}

        {/* <Box
          sx={{
            opacity: lessonType === "hm" ? 1 : 0,
            pointerEvents: lessonType === "hm" ? "auto" : "none",
            left: lessonType === "hm" ? 0 : -999999,
            position: lessonType === "hm" ? "relative" : "absolute",
          }}
        >
          <Box sx={{ backgroundColor: "white" }} pb={10} px={3} mb={2} width="100%">
            <GrammarHomework sentences={lessonSentences} userSentences={userSentences} />
          </Box>
        </Box> */}
      </Box>
    </MenuLayout>
  );
};

export default BanglaGrammarLesson;
