import { DifferenceWithSentence } from "../../../types/supabase";
import { SentenceListeningSection } from "./SentenceListeningSection";

export interface PartSentence {
  en: string;
  ua: string;
  audioUrl: string;
  words: string[];
  isGreeting?: boolean;
  meaning?: string;
}

export interface LearnWordsSectionProps {
  sliderId: string;
  lessonId: string;
  lessonType: "difference" | "personal";
  partSentencesIds?: number[];
  differenceSentences?: DifferenceWithSentence[];
}

export default SentenceListeningSection;
