import LoadingButton from "@mui/lab/LoadingButton";
import { addSeconds } from "date-fns";
import { useCallback, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import Box from "../../Unknown/Box";
import HStack from "../../Unknown/HStack";
import Typography from "../../Unknown/Typography";

const AdminUserNewLessonSentences: React.FC<{
  sentences: string[];
  userUuid: string;
  onInsertUserSentences: () => void;
}> = ({ sentences, userUuid, onInsertUserSentences }) => {
  const [loading, setLoading] = useState(false);

  const handleCreateNewSentences = useCallback(async () => {
    setLoading(true);

    console.log("🚀 ~ userUuid:", userUuid);
    const result = await supabaseClient.from("user-sentences").insert(
      sentences.map((s, i) => {
        return {
          id: `${userUuid}-${s}`,
          user: userUuid,
          en: s,
          repeat: addSeconds(new Date(), i * 2).toISOString(),
        };
      }),
    );
    console.log("🚀 ~ result:", result);
    console.log(
      "🚀 ~ result:",
      sentences.map((s, i) => {
        return {
          id: `${userUuid}-${s}`,
          user: userUuid,
          en: s,
          repeat: addSeconds(new Date(), i * 2).toISOString(),
        };
      }),
    );
    onInsertUserSentences();

    setLoading(false);
  }, [onInsertUserSentences, sentences, userUuid]);

  if (!sentences.length) return null;

  return (
    <Box>
      <Typography variant="h3">New USER lesson sentences:</Typography>
      <LoadingButton
        loading={loading}
        variant="contained"
        color="info"
        onClick={handleCreateNewSentences}
      >
        Create USER new sentences.
      </LoadingButton>
      <HStack>
        {sentences.map((sentence, i) => {
          return (
            <Box key={i} width="100%">
              <Typography>{sentence}</Typography>
            </Box>
          );
        })}
      </HStack>
    </Box>
  );
};

export default AdminUserNewLessonSentences;
