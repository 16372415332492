import { Box, Button, Modal, Typography } from "@mui/material";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import supabaseClient from "../../common/supabaseClient";
import { useMemo, useState } from "react";
import { Word } from "../../types/supabase";
import LearnWordSection from "../Unknown/LearnWordsSection";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const WordsModalCarousel: React.FC<{
  words: string[];
  buttonLabel: string;
}> = ({ words: inputWords, buttonLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log("🚀 ~ isOpen:", isOpen);
  const [words, setWords] = useState<Word[]>([]);
  const [rawWords, setRawWords] = useState<string[]>([]);

  const currentWord = useMemo(() => {
    if (!words.length) return null;
    return words[0];
  }, []);

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box>
        <LearnWordSection
          isOpen={isOpen}
          withoutButton
          currentWords={rawWords}
          lessonId={`words_modal_carousel`}
          onUpdateWords={(newWords) => {
            setRawWords(newWords);
          }}
          onDrawerClose={() => {
            setIsOpen(false);
          }}
        />
      </Box>
      <Button
        variant="contained"
        size="small"
        // sx={{ px: 2, display: "block", width: "auto", maxWidth: "auto" }}
        onClick={async () => {
          // setDateModalOpen(true);
          console.log("🚀 ~ inputWords:", inputWords);
          if (typeof inputWords[0] === "string") {
            if (!words?.length) {
              const { data: wordsData } = await supabaseClient.from("words").select().in("name", inputWords);
              if (wordsData) setWords(wordsData);
              if (wordsData) setRawWords(wordsData.map((w) => w.name));
            }
          }

          setIsOpen(true);
        }}
      >
        {buttonLabel}
      </Button>
      {/* <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Box sx={style}>{currentWord && <Box></Box>}</Box>
      </Modal> */}
    </Box>
  );
};

export default WordsModalCarousel;
