import LoadingButton from "@mui/lab/LoadingButton";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from "react";
import SwitchSelector from "react-switch-selector";
import { useFirebaseApp } from "reactfire";
import getSentenceWords from "../../../common/getSentenceWords";
import supabaseClient from "../../../common/supabaseClient";
import { InitialDialog } from "../../CreateDialogLesson/types";
import Box from "../Box";
import HStack from "../HStack";
import Typography from "../Typography";
import VStack from "../VStack";
import DialogRepl from "./DialogRepl";
import filterSkipedWords from "../../../common/filterSkipedWords";

interface DialogProps {
  isAdmin?: boolean;
  dialog: InitialDialog;
  skipWords?: string[];
  onCreate?: (id: string) => void;
}

const personOptions = [
  {
    label: "Перша",
    value: "first",
  },
  {
    label: "Обидві",
    value: "both",
  },
  {
    label: "Друга",
    value: "second",
  },
];

const toShowOptions = [
  {
    label: "En",
    value: "en",
  },
  {
    label: "En + Ua",
    value: "both",
  },
  {
    label: "Ua",
    value: "ua",
  },
];

const personGenderOptions = [
  {
    label: "Girl",
    value: "girl",
  },
  {
    label: "Boy",
    value: "boy",
  },
];

const Dialog: React.FC<DialogProps> = ({
  dialog,
  isAdmin,
  skipWords = [],
  onCreate,
}) => {
  const [person, setPerson] = useState<"both" | "first" | "second">("both");
  const [loading, setLoading] = useState(false);
  const [visiblility, setVisibility] = useState<"both" | "ua" | "en">("both");
  const [firstPersonGender, setFirstPersonGender] = useState<"girl" | "boy">(
    "girl",
  );
  const [secondPersonGender, setSecondPersonGender] = useState<"girl" | "boy">(
    "boy",
  );

  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");

  const textToSpeechFunction = httpsCallable(
    functions,
    "recordEnglishTextToSpeechAndReturnStorageUrl",
  );

  const createAudio = async (word: string, voice: string) => {
    try {
      const result = await textToSpeechFunction({
        en: word.replace("(verb) ", ""),
        voiceName: voice,
      });
      return result;
    } catch (err) {
      console.log("🚀 try/catch create audio error:", err);
    }
  };

  return (
    <VStack justifyContent="flex-start" alignItems="flex-start">
      <HStack>
        <Typography
          sx={{
            textDecorationLine: "underline",
          }}
        >
          Тип:
        </Typography>
        <Typography>{dialog.dialogType}</Typography>
      </HStack>
      <HStack>
        <Typography
          sx={{
            textDecorationLine: "underline",
          }}
        >
          Рівень:
        </Typography>
        <Typography>{dialog.level}</Typography>
      </HStack>
      <HStack>
        <Typography
          sx={{
            textDecorationLine: "underline",
          }}
        >
          Теми:
        </Typography>
        <Typography>{dialog.topic.join(", ")}</Typography>
      </HStack>
      <HStack>
        <Typography
          component="span"
          sx={{
            textDecorationLine: "underline",
          }}
        >
          Контекст:
        </Typography>
        <br />
        <Typography color="primary">{dialog.context}</Typography>
      </HStack>
      <Box width={300}>
        <SwitchSelector
          name="Особа"
          onChange={(value) => {
            setPerson(value as "both" | "first" | "second");
          }}
          options={personOptions}
          initialSelectedIndex={personOptions.findIndex(
            ({ value }) => value === person,
          )}
        />
      </Box>
      <Box width={300}>
        <SwitchSelector
          name="Показати"
          onChange={(value) => {
            setVisibility(value as "both" | "ua" | "en");
          }}
          options={toShowOptions}
        />
      </Box>
      <Box width={300}>
        <SwitchSelector
          name="FirstPersonGender"
          onChange={(value) => {
            setFirstPersonGender(value as "girl" | "boy");
          }}
          options={personGenderOptions}
        />
      </Box>
      <Box width={300}>
        <SwitchSelector
          name="SecondPersonGender"
          onChange={(value) => {
            setSecondPersonGender(value as "girl" | "boy");
          }}
          options={personGenderOptions}
        />
      </Box>
      {dialog.dialog.map((repl, index) => {
        if (
          person === "both" ||
          (person === "first" && repl.person === 1) ||
          (person === "second" && repl.person === 2)
        ) {
          return (
            <DialogRepl
              key={index}
              en={repl.en}
              ua={repl.ua}
              onUaChanged={() => {}}
              enVisible={visiblility === "en" || visiblility === "both"}
              uaVisible={visiblility === "ua" || visiblility === "both"}
            />
          );
        } else {
          return <Box> ... </Box>;
        }
      })}
      {isAdmin && (
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={async () => {
            setLoading(true);
            const dialogWithAudio = [];

            for (let i = 0; i < dialog.dialog.length; i++) {
              const repl = dialog.dialog[i];

              const boy1 = "en-US-Standard-J";
              const boy2 = "en-US-News-L";
              const girl1 = "en-US-Standard-G";
              const girl2 = "en-US-Standard-F";

              const firstPerson = firstPersonGender === "girl" ? girl1 : boy1;
              const secondPerson =
                firstPersonGender === "girl" && secondPersonGender === "girl"
                  ? girl2
                  : firstPersonGender === "boy" && secondPersonGender === "boy"
                  ? boy2
                  : secondPersonGender === "girl"
                  ? girl1
                  : boy1;

              const voice = i % 2 === 0 ? firstPerson : secondPerson;

              const audioResponse: any = await createAudio(repl.en, voice);

              dialogWithAudio.push({
                ...repl,
                audio: audioResponse?.data?.url,
              });
            }

            await supabaseClient.from("dialogs").insert({
              id: dialog.context,
              level: dialog.level,
              type: dialog.dialogType,
              topics: dialog.topic,
              dialog: dialogWithAudio,
              words: Array.from(
                new Set(
                  dialog.dialog
                    .map(({ en }) =>
                      filterSkipedWords(getSentenceWords(en), skipWords),
                    )
                    .flat(),
                ),
              ),
            });

            if (onCreate) {
              onCreate(dialog.context);
            }

            setLoading(false);
          }}
        >
          submit
        </LoadingButton>
      )}
    </VStack>
  );
};

export default Dialog;
