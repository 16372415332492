import filterSkipedWords from "../../common/filterSkipedWords";
import getSentenceWords from "../../common/getSentenceWords";
import getSkipWords from "../../common/getSkipWords";
import supabaseClient from "../../common/supabaseClient";
import { UserWord, Word } from "../../types/supabase";

const analyzeAllSentencesWords = async ({
  currentUserUuid,
  textFieldMultipleString,
}: {
  currentUserUuid: string;
  textFieldMultipleString: string;
}): Promise<{
  lessonWords: Word[];
  newRowLessonWords: string[];
  allSentenceWords: string[];
  rowUserExistingWords: string[];
  newUserLessonWords: string[];
}> => {
  const rowEnSentences = textFieldMultipleString
    .split("\n")
    .map((s) => s.trim())
    .filter(Boolean);

  const skipWords = await getSkipWords(
    getSentenceWords(rowEnSentences.join(" ")),
  );

  const allSentenceWords = Array.from(
    new Set(
      rowEnSentences
        .map((s) => filterSkipedWords(getSentenceWords(s), skipWords))
        .flat(),
    ),
  );
  console.log("🚀 ~ allSentenceWords:", allSentenceWords);

  const { data: existingWords } = await supabaseClient
    .from("words")
    .select()
    .in("name", allSentenceWords)
    .returns<Word[]>();
  console.log("🚀 ~ existingWords:", existingWords);

  const rowExistingWords = existingWords!.map((w) => w.name);
  const newRowLessonWords = allSentenceWords.filter(
    (w) => !rowExistingWords.includes(w),
  );

  const { data: userExistingWords } = await supabaseClient
    .from("user-words")
    .select()
    .in(
      "id",
      allSentenceWords.map((w) => `${currentUserUuid}-${w}`),
    )
    .returns<UserWord[]>();

  const rowUserExistingWords = userExistingWords!.map((w) => w.word);

  const newUserLessonWords = allSentenceWords.filter(
    (w) => !rowUserExistingWords.includes(w),
  );

  return {
    lessonWords: existingWords!,
    newRowLessonWords,
    allSentenceWords,
    rowUserExistingWords,
    newUserLessonWords,
  };
};

export default analyzeAllSentencesWords;
