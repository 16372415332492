import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";
import AccentWord from "./AccentWord";

const BigLargeGreat = () => {
  return (
    <>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          He has a <AccentWord word="large" /> collection of books.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          He has a <AccentWord word="big" /> collection of books.
        </Typography>
      </Box>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I have a <AccentWord word="great" /> idea.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I have a <AccentWord word="big" /> idea.
        </Typography>
      </Box>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I want to be a <AccentWord word="big" /> person.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          I want to be a <AccentWord word="great" /> person.
        </Typography>
      </Box>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          He has <AccentWord word="great" /> hopes for the future.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          He has <AccentWord word="big" /> hopes for the future.
        </Typography>
      </Box>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          A <AccentWord word="great" /> storm.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          A <AccentWord word="big" /> storm.
        </Typography>
      </Box>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          A <AccentWord word="great" /> decision.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          A <AccentWord word="big" /> decision.
        </Typography>
      </Box>
    </>
  );
};

export default BigLargeGreat;
