import { Box, Button, Tooltip, Typography } from "@mui/material";
import { motion } from "framer-motion";
import {
  PropsWithChildren,
  ReactElement,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import firstLetterUppercase from "../../../common/firstLetterUppercase";
import useIsInViewport from "../../../hooks/useIsInViewport";
import WordAudio from "../../Audio/WordAudio";
import VStack from "../../Unknown/VStack";

import { HighlightWord } from "../../Sentence/UkrainianWord";
import getSentenceWordMatch from "./getSentenceWordMatch";
import { Word } from "../../../types/supabase";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import PersonalEnPronounce from "./PersonalEnPronounce";

interface WordTranslationSectionProps {
  sentence?: string;
  ua?: string;
  en?: string;
  fullScreen?: boolean;
  audioUrl?: string;
  highlight?: string;
  audioSmall?: boolean;
  position?: "left";
  sliderId?: string;
  slideIndex?: number;
  words?: string[];
  isTranslation?: boolean;
  meaning?: string;
  isCentered?: boolean;
  lessonWords: Word[];
}

const HideBlock: React.FC<{ delay: number }> = ({ delay }) => (
  <motion.div
    initial={{ opacity: 1 }}
    animate={{ opacity: 0 }}
    transition={{
      duration: 0.4,
      delay: delay - 0.2,
    }}
    style={{
      left: "-3px",
      top: "-3px",
      width: "calc(100% + 6px)",
      height: "calc(100% + 6px)",
      zIndex: 1,
      position: "absolute",
      pointerEvents: "none",
      backdropFilter: "blur(8px)",
      // backgroundColor: "red",
    }}
  />
);

async function getAudioDuration(audioUrl: string): Promise<number> {
  const duration = await new Promise((resolve, reject) => {
    const audio = new Audio();
    audio.src = audioUrl;

    audio.addEventListener("loadedmetadata", () => {
      const duration = audio.duration;
      audio.remove(); // Clean up the audio element
      resolve(duration);
    });

    audio.addEventListener("error", (error) => {
      audio.remove(); // Clean up the audio element
      reject(error);
    });

    // Load the audio to trigger the loadedmetadata event
    audio.load();
  });

  return (duration || 0) as number;
}

const ExampleSentenceItem = ({
  sentence,
  en: inputEn,
  ua: inputUa,
  fullScreen = false,
  audioSmall = true,
  audioUrl,
  highlight,
  children,
  meaning,
  isCentered,
  isTranslation,
  lessonWords,
}: WordTranslationSectionProps & PropsWithChildren) => {
  const ref = useRef<HTMLElement>();
  const isInViewport = useIsInViewport(ref, inputEn);
  const [fullScreenAudio, setFullScreenAudio] = useState<ReactElement | null>(
    <WordAudio url={""} small={audioSmall} />,
  );

  const [audioDuration, setAudioDuration] = useState(0);

  useMemo(async () => {
    if (!audioUrl) return;

    const duration = await getAudioDuration(audioUrl);

    setAudioDuration(duration);
  }, [audioUrl]);

  useEffect(() => {
    if (fullScreen && audioUrl && isInViewport && !fullScreenAudio?.props.url) {
      setFullScreenAudio(
        <WordAudio url={audioUrl} autoPlay small={audioSmall} />,
      );
    } else if (
      fullScreen &&
      audioUrl &&
      !isInViewport &&
      fullScreenAudio?.props.url
    ) {
      // setFullScreenAudio(<WordAudio url="" small={audioSmall} />);
    }
    // eslint-disable-next-line
  }, [isInViewport, fullScreenAudio, audioUrl]);

  const { en, ua } = useMemo(() => {
    if (sentence) {
      const [first, second] = sentence.split(" - ");
      return {
        en: first,
        // ua: second,
        ua: <HighlightWord text={second} row isLast />,
      };
    }

    return {
      en: inputEn || "",
      ua: firstLetterUppercase(inputUa || "")
        .split(" / ")
        .map((sentence, index) => (
          <Typography
            fontStyle="italic"
            key={index}
            variant="body2"
            fontSize={isTranslation ? 30 : 20}
            fontWeight={300}
            color="secondary.dark"
            py={fullScreen ? 1 : 0}
            position="relative"
          >
            {/* {!isTranslation && Boolean(audioDuration) && !audioDuration && (
              <HideBlock delay={2} />
            )}
            {!isTranslation && Boolean(audioDuration) && audioDuration && (
              <HideBlock delay={audioDuration} />
            )} */}
            {meaning && <Typography fontStyle="italic">{meaning}</Typography>}
            <span>
              <HighlightWord text={sentence} row isLast />
            </span>
          </Typography>
        )),
    };
    // eslint-disable-next-line
  }, [sentence, inputEn, inputUa, audioDuration]);

  const enSentence = useMemo(() => {
    if (highlight) return getSentenceWordMatch(en, highlight);

    return firstLetterUppercase(en);
  }, [highlight, en]);

  const [isTranslated, setIsTranslated] = useState(false);

  if (isTranslation) {
    return (
      <Box
        py={0.3}
        height={fullScreen ? "100vh" : "auto"}
        display="flex"
        flexDirection="column"
        alignItems={fullScreen ? "center" : "flex-start"}
        justifyContent={fullScreen ? "center" : "flex-start"}
      >
        <VStack>
          {/* <Box
            sx={{
              border: "1px solid #fe6a17",
              padding: 4,
            }}
          >
            <Typography variant="caption">Контекст</Typography>
            <Typography
              // variant="h3"
              sx={{
                // paddingLeft: fullScreen ? 0 : 5,
                // textAlign: fullScreen ? "center" : "left",
                // my: fullScreen ? 4 : 0,
                color: "#fe6a17",
              }}
            >
              {meaning}
            </Typography>
          </Box> */}
          <Typography
            variant="h3"
            sx={{
              paddingLeft: fullScreen ? 0 : 5,
              textAlign: fullScreen ? "center" : "left",
              my: fullScreen ? 4 : 0,
            }}
          >
            {ua}
          </Typography>

          <VStack position="relative" mb={4} px={5} py={2}>
            {!isTranslated && <HideBlock delay={400000} />}
            {!isTranslated && Boolean(audioUrl) && audioUrl && (
              <WordAudio url="" />
            )}
            {isTranslated && Boolean(audioUrl) && audioUrl && (
              <WordAudio url={audioUrl} autoPlay />
            )}
            <Typography
              fontStyle="italic"
              fontSize={fullScreen ? 30 : 20}
              color="secondary.dark"
              fontWeight={500}
              textAlign="center"
              sx={{
                pointerEvents: !isTranslated ? "none" : "initial",
              }}
            >
              <PersonalEnPronounce
                sentence={(enSentence || "") as string}
                lessonWords={lessonWords}
              />
            </Typography>

            {isTranslated && <Box py={5}> {children}</Box>}

            {!isTranslated && (
              <Button
                variant="contained"
                onClick={() => {
                  setIsTranslated(true);
                }}
                sx={{
                  position: "relative",
                  zIndex: 2,
                }}
              >
                Перекласти
              </Button>
            )}
          </VStack>
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      py={0.3}
      height={fullScreen ? "100vh" : "auto"}
      display="flex"
      flexDirection="column"
      alignItems={fullScreen ? "center" : "flex-start"}
      justifyContent={fullScreen ? "center" : "flex-start"}
    >
      {/* center box to detect i in viewport */}
      {Boolean(fullScreen) && (
        <Box
          ref={ref}
          width={20}
          height={20}
          sx={{
            opacity: 0,
            pointerEvents: "none",
          }}
        >
          {inputEn}
        </Box>
      )}
      <VStack
        position="relative"
        mb={1}
        alignItems={isCentered ? "center" : "flex-start"}
      >
        {Boolean(fullScreen) && fullScreenAudio}
        {!Boolean(fullScreen) && audioUrl ? (
          <WordAudio url={audioUrl} small={audioSmall} />
        ) : null}
        {Boolean(fullScreen) && !audioDuration && <HideBlock delay={2} />}
        {Boolean(fullScreen) && Boolean(audioDuration) && (
          <HideBlock delay={audioDuration} />
        )}
        {/* {<HideBlock delay={audioDuration} />} */}
        {/* <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "red",
          }}
        ></Box> */}
        <Box>
          <Typography
            fontStyle="italic"
            fontSize={fullScreen ? 30 : 20}
            color="secondary.dark"
            fontWeight={500}
            textAlign="center"
          >
            {enSentence}
          </Typography>
        </Box>
        <Box
          sx={{
            // paddingLeft: fullScreen ? 0 : 5,
            textAlign: fullScreen ? "center" : "left",
            my: fullScreen ? 4 : 0,
          }}
        >
          {ua}
        </Box>
      </VStack>
      {children}
    </Box>
  );
};

export default memo(ExampleSentenceItem);
