import React from "react";
import AdminTabs from "../AdminTabs";
import MenuLayout from "../../Layout/MenuLayout";
import Box from "../../Unknown/Box";
import { NavigationButton } from "../../Unknown/Navigation";
import { Grid } from "@mui/material";

const AdminScreen: React.FC = () => {
  return (
    <MenuLayout>
      <Grid container>
        <Grid item>
          <NavigationButton to="/add-bangla-sentence" label="Add Bangla Sentence" />
        </Grid>
        <Grid item>
          <NavigationButton to="/create-grammar-course" label="Create English Grammar Course" />
        </Grid>
        <Grid item>
          <NavigationButton
            to="/create-bangla-grammar-course"
            label="Create Bangla Grammar Course"
          />
        </Grid>
        <Grid item>
          <NavigationButton to="/add-verse" label="Add English Verse" />
        </Grid>
        <Grid item>
          <NavigationButton to="/check-user-quotes" label="Check user quote" />
        </Grid>
        <Grid item>
          <NavigationButton to="/check-user-stories" label="Check user stories" />
        </Grid>
        <Grid item>
          <NavigationButton to="/upload-video" label="Upload video" />
        </Grid>
      </Grid>
      <AdminTabs />
    </MenuLayout>
  );
};

export default AdminScreen;
