import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import checkNewWords from "../../common/checkNewWords";
import getSkipWords from "../../common/getSkipWords";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import DisplayToSkipWords from "../Admin/DisplayToSkipWords";
import Dialog from "../Unknown/Dialog";
import HStack from "../Unknown/HStack";
import a1dialogs from "./a1dialogs";
import VStack from "../Unknown/VStack";

const CreateDialogLesson: React.FC = () => {
  const [dialogValue, setDialogValue] = useState(a1dialogs);
  const [dialogWords, setDialogWords] = useState<string[]>([]);
  const [dialogNewWords, setDialogNewWords] = useState<string[]>([]);

  const [skipWords, setSkipWords] = useState<string[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (!skipWords.length) {
      const skipWords = await getSkipWords(dialogWords);
      setSkipWords(skipWords);
    }
  }, [dialogWords]);

  return (
    <Box>
      <Button
        variant="contained"
        color="info"
        onClick={async () => {
          const dialogs = dialogValue.map(({ dialog }) => dialog);
          const { words, newWords } = await checkNewWords(
            dialogs.flat().map(({ en }) => en),
          );
          setDialogWords(words);
          setDialogNewWords(newWords);
        }}
      >
        Check new words
      </Button>

      <VStack py={5} alignItems="flex-start">
        <DisplayToSkipWords words={dialogWords} newWords={dialogNewWords} />
        <CopyNewWordsForGemini words={dialogNewWords} />
        {dialogValue.map((dialog) => {
          return (
            <Box pb={10}>
              <Dialog
                dialog={dialog}
                isAdmin
                skipWords={skipWords}
                onCreate={(id: string) => {
                  setDialogValue(dialogValue.filter((d) => d.context !== id));
                }}
              />
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
};

export default CreateDialogLesson;
