const checkDuplication = ({
  sentences1,
  sentences2,
}: {
  sentences1: string[];
  sentences2: string[];
}) => {
  if (sentences1.length !== sentences2.length) {
    for (let i = 0; i < sentences1.length; i++) {
      const s = sentences1[i];
      if (s !== sentences2[i]) {
        console.error(`Duplicated sentence: "${s}"`);

        return true;
      }
    }
  }

  return false;
};

export default checkDuplication;
