import { Button, Container, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import wait from "../../../common/wait";
import { BookChapterJsonParagraph, Chapter, Word } from "../../../types/supabase";
import UpdateWordTranslationItem from "../UpdateWordTranslationItem";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import Loader from "../../Unknown/Loader";
import CopyNewWordsForGemini from "../CopyNewWordsForGemini";
import removePunctuationFromString from "../../../common/removePunctuationFromString";

const replaceWordMap: Record<string, string> = {
  THE: "the",
  BOY: "boy",
  WHO: "who",
  Stone: "stone",
  Sorcerer: "sorcerer",
  "Sorcerer's": "sorcerer's",
  They: "they",
  We: "we",
  He: "he",
  She: "she",
  You: "you",
  LIVED: "lived",
  CHAPTER: "chapter",
  ONE: "one",
  TWO: "two",
  THREE: "three",
  FOUR: "four",
  FIVE: "five",
  SIX: "six",
  SEVEN: "seven",
  EIGHT: "eight",
  NINE: "nine",
  TEN: "ten",
  ELEVEN: "eleven",
  TWELVE: "twelve",
  THIRTEEN: "thirteen",
  FOURTEEN: "fourteen",
  FIFTEEN: "fifteen",
  SIXTEEN: "sixteen",
  SEVENTEEN: "seventeen",
  Harry: "Harry Potter",
  Potter: "Harry Potter",
  Mr: "Mr.",
  Ms: "Ms.",
  Mrs: "Mrs.",
  Privet: "Privet Drive",
  Drive: "Privet Drive",
};

const BookSpliter2 = () => {
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [splitedText, setSplitedText] = useState<any>();
  const [allTextRowWords, setAllTextRowWords] = useState<string[]>([]);
  const [newWords, setNewWords] = useState<any>([]);
  const [jsonToInsert, setJsonToInsert] = useState<string>("");

  const [books, setBooks] = useState<string[]>([]);
  const [currentBook, setCurrentBook] = useState<string>("");
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [currentChapter, setCurrentChapter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  // useMountedWaitAsyncEffect(async () => {
  //   const { data } = await supabaseClient.from("words").select().eq("name", "reason");
  //   console.log("🚀 ~ data:", data);
  // }, []);
  useMountedWaitAsyncEffect(async () => {
    if (text) {
      const paragraphs = text.split("\n\n").filter(Boolean);
      console.log("🚀 ~ paragraphs:", paragraphs.length);

      const allWords: string[] = [];
      const jsonData: any = [];

      let jsonDataId = 0;
      // console.log("🚀 ~ jsonDataId:", jsonDataId);
      for (let i = 0; i < paragraphs.length; i++) {
        const words = paragraphs[i].split(/[\s\n]+/).filter(Boolean);

        // allWords.push(...words);

        for (let j = 0; j < words.length; j++) {
          jsonDataId += 1;
          let word = removePunctuationFromString(words[j]).trim();
          if (!word) continue;
          if (replaceWordMap[word]) {
            word = replaceWordMap[word];
          }
          allWords.push(word);

          // const isLengthMore = word.length > 1;
          // const isUppercase = word.toUpperCase() === word

          jsonData.push({
            word,
            noWord: false,
            // id: jsonDataId.toString(),
            additional: [],
            label: words[j],
          });
        }

        jsonDataId += 1;

        jsonData.push({
          // type: "_______",
          word: "____",
          // id: jsonDataId.toString(),
          additional: [],
          questions: [],
        });
      }

      // setSplitedText(JSON.stringify(jsonData));
      setSplitedText(jsonData.filter((jd: any) => !!jd.word));
      console.log("jsonData.length", jsonData.length);
      console.log("🚀 ~ jsonData:", jsonData);
      console.log("🚀 ~ allWords:", allWords);
      setAllTextRowWords(allWords);
    }
  }, [text]);

  const handleLoadWords = async () => {
    if (splitedText) {
      setIsLoading(true);
      const words = splitedText.map((word: any) => word.word);
      console.log("🚀 Array.from(new Set(words)):", Array.from(new Set(words)));

      const uniqueWords = Array.from(new Set(words)).filter(Boolean);
      const loverWords = uniqueWords.map((w: any) => w.toLowerCase());
      const upperWords = uniqueWords.map((w: any) => w.toUpperCase());

      const { data } = await supabaseClient
        .from("words")
        .select()
        .in("name", Array.from(new Set([...(uniqueWords as any), ...loverWords, ...upperWords])));
      console.log("🚀 ~ data:", data?.length);

      const existingWords = data?.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.name]: curr,
        };
      }, {});

      const updatedSplitedText = [];
      const acmNewWords: string[] = [];
      for (let i = 0; i < splitedText.length; i++) {
        const w = splitedText[i];
        const existingWord = existingWords[w.word] || existingWords[w.word.toLowerCase()] || existingWords[w.word.toUpperCase()];

        if (!existingWord) {
          acmNewWords.push(w.word);
          updatedSplitedText.push({
            ...w,
            noWord: true,
          });
        } else {
          updatedSplitedText.push({
            ...w,
            word: existingWord.name,
            transcription: existingWord.transcription,
            translation: existingWord.translation,
            audioUrl: existingWord?.audio_url?.replace("https://storage.googleapis.com/leeearning.appspot.com/enAudio/", "") || "",
          });
        }
      }

      setSplitedText(updatedSplitedText.filter((jd: any) => !!jd.word));
      setNewWords(acmNewWords);
    }
    setIsLoading(false);
  };

  useMountedWaitAsyncEffect(async () => {
    if (!books.length) {
      const { data: books } = await supabaseClient.rpc("get_unique_book_ids");
      setBooks(books);
    }
  }, [books]);

  useMountedWaitAsyncEffect(async () => {
    if (!chapters.length) {
      const { data: chapters } = await supabaseClient.from("chapters").select().eq("book_id", currentBook).order("chapter_number").throwOnError();
      if (chapters) setChapters(chapters);
    }
  }, [currentBook]);

  useMountedWaitAsyncEffect(async () => {
    if (currentChapter) {
      const { data: harryPotterWord } = await supabaseClient.from("harry_potter_words").select("page").eq("chapter", currentChapter).limit(1).order("page").maybeSingle();
      console.log("🚀 ~ harryPotterWord:", harryPotterWord);
      // if (chapters) setChapters(chapters);
    }
  }, [currentChapter]);

  return (
    <Container maxWidth="sm">
      <Grid container pb={200}>
        <Grid item>{isLoading && <Loader />}</Grid>
        <Grid item xs={12}>
          <Select
            value={currentBook}
            variant="standard"
            style={{
              height: "16px",
              fontSize: "12px",
              width: 300,
              minHeight: 0,
            }}
            onChange={(e) => {
              setCurrentBook(e.target.value);
            }}
          >
            {books.map((book, index) => (
              <MenuItem key={book} value={book}>
                {book}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Select
            value={currentChapter}
            variant="standard"
            style={{
              height: "16px",
              fontSize: "12px",
              width: 300,
              minHeight: 0,
            }}
            onChange={(e) => {
              setCurrentChapter(e.target.value);
            }}
          >
            {chapters.map((chapter, index) => (
              <MenuItem key={chapter.id} value={chapter.id}>
                {chapter.chapter_title}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography>Chapter uuid: {currentChapter}</Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Typography>Text size: {text.length}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            color="primary"
            value={currentPage}
            label="Page number"
            multiline={true}
            onChange={(event) => {
              setCurrentPage(+event.target.value);
            }}
            type="text"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!splitedText || isLoading}
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(splitedText));
            }}
          >
            Copy
          </Button>
          <Button disabled={isLoading} onClick={() => handleLoadWords()}>
            Load words
          </Button>
          {!!newWords.length && <Typography>New words count: {Array.from(new Set(newWords)).length}</Typography>}
          {/* {!!newWords.length && newWords.length > 14 && <CopyNewWordsForGemini copy10 words={newWords} />} */}
          {!!newWords.length && <CopyNewWordsForGemini words={Array.from(new Set(newWords))} />}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            color="primary"
            value={text}
            placeholder="Text"
            multiline={true}
            onChange={(event) => {
              setText(event.target.value);
            }}
            type="text"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            color="primary"
            value={jsonToInsert}
            placeholder="JSON"
            multiline={true}
            onChange={(event) => {
              setJsonToInsert(event.target.value);
            }}
            type="text"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={isLoading}
            onClick={async () => {
              if (jsonToInsert) {
                const toInsert = JSON.parse(jsonToInsert).map((obj: any) => {
                  return {
                    word: obj.word,
                    additional: obj.additional,
                    label: obj.label,
                    chapter: currentChapter,
                    page: currentPage,
                  };
                });
                console.log("🚀 ~ toInsert:", toInsert);

                for (let i = 0; i < toInsert.length; i++) {
                  await supabaseClient.from("harry_potter_words").insert(toInsert[i]);
                }
              }
            }}
          >
            Upload
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BookSpliter2;
