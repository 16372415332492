import { HttpsCallableResult } from "firebase/functions";
import { Sentence } from "../../types/supabase";
import wait from "../../common/wait";
import supabaseClient from "../../common/supabaseClient";

interface CreateAudioResponse {
  url: string;
}

const createNewNotExistingSentenceFromRowSentences = async ({
  notExistingSentences,
  createAudio,
}: {
  notExistingSentences: { en: string; ua: string }[];
  createAudio: (text: string) => Promise<string>;
}): Promise<Sentence[]> => {
  const sentenceToInsert: Sentence[] = [];

  const { data: lastId } = await supabaseClient
    .from("sentences")
    .select("id")
    .order("id", { ascending: false })
    .limit(1)
    .single();
  console.log("🚀 ~ lastId:", lastId);

  if (!lastId) throw new Error("Last id not found");

  for (let i = 0; i < notExistingSentences.length; i++) {
    const { en, ua } = notExistingSentences[i];

    const audio = await createAudio(en);
    wait(300);

    const newSentence = {
      en,
      ua,
      id: lastId.id + i + 1,
      en_audio_url: audio,
      ua_audio_url: "",
    } as Sentence;

    sentenceToInsert.push(newSentence);
  }

  return sentenceToInsert;
};

export default createNewNotExistingSentenceFromRowSentences;
