import {
  Badge,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import ModalCloseButton from "../ModalCloseButton";
import useGlobalWords from "../../hooks/getGlobalWords";
import WordAudio from "../../Audio/WordAudio";
import VStack from "../VStack";
import WordTranscription from "../../Word/WordTranscription";
import useLessonWords from "../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../store/lessonsStore";
import { fullScreenModalStyle } from "../../../common/constants";
import AccentStrong from "../../Description/ModuleDescription/utils/AccentString";
import AccentWord from "../../Difference/components/AccentWord";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import WithDotBadge from "../../../unknown/WithDotBadge";
import BackgroundBoxWraper from "../grammar/Feed/BackgroundBoxWraper";

type CreateDataProp = {
  title: any;
  withoutBadge?: boolean;
  en: string;
  ua: string;
  startBadge?: number;
  description: string;
  examples: any[];
};

function createData(
  item1?: CreateDataProp | null,
  item2?: CreateDataProp | null,
  item3?: CreateDataProp | null,
  item4?: CreateDataProp | null,
  item5?: CreateDataProp | null,
  item6?: CreateDataProp | null,
  item7?: CreateDataProp | null,
  item8?: CreateDataProp | null,
  item9?: CreateDataProp | null,
) {
  return {
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item7,
    item8,
    item9,
  };
}

const rows = (lessonNumber: number) => [
  createData(
    {
      title: "what?",
      en: "what?",
      ua: "що? який? яка? яке? які? що за...? чого?",
      description: "Питання про предмет, річ, дію, ідею, стан, причину.",
      examples: [
        {
          lesson: 2,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="What" /> do you know?
            </>
          ),
        },
        {
          lesson: 2,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="What" />
              &nbsp;does she love?
            </>
          ),
        },
        {
          lesson: 2,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="What" />
              &nbsp;language do you learn?
            </>
          ),
        },
        {
          lesson: 2,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="What" /> language do you speak?
            </>
          ),
        },
      ],
    },
    {
      title: "who?",
      en: "who?",
      ua: "хто? кого? ким?",
      startBadge: 2,
      description: "Питання про особу",
      examples: [
        {
          lesson: 1,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="Who" /> works today?
            </>
          ),
        },
        {
          lesson: 1,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="Who" /> knows it well?
            </>
          ),
        },
        {
          lesson: 2,
          // withoutBadge: true,
          title: (
            <>
              <AccentWord word="Who" />
              &nbsp;do you want to be?
            </>
          ),
        },
        {
          lesson: 2,
          // withoutBadge: true,
          title: (
            <>
              <AccentWord word="Who" />
              &nbsp;does she love?
            </>
          ),
        },
      ],
    },
    {
      title: "how?",
      en: "how?",
      ua: "як? яким чином? наскільки?",
      description: "Питання про спосіб, метод, стан, ступінь.",
      examples: [],
    },
    {
      title: "which one(s)?",
      en: "which one(s)?",
      ua: "котрий? котра? котре? котрі?",
      description: "Питання про вибір з обмеженої кількості.",
      examples: [],
    },
    {
      title: "when?",
      en: "when?",
      ua: "коли? в який час? з якого часу? до якого часу?",
      description: "Питання про час, момент, період.",
      examples: [],
    },
    {
      title: "where?",
      en: "where?",
      ua: "де? куди? звідки?",
      description: "Питання про місцезнаходження, напрямок руху.",
      examples: [
        {
          lesson: 5,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="Where" /> are you?
            </>
          ),
        },
        {
          lesson: 5,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="Where" /> is she?
            </>
          ),
        },
        {
          lesson: 5,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="Where" /> do you study English?
            </>
          ),
        },
      ],
    },
    {
      title: "why?",
      en: "why?",
      ua: "чому? з якої причини?",
      description: "Питання про причину, мотив.",
      examples: [],
    },
    {
      title: "whom?",
      en: "whom?",
      ua: "кого? кому?",
      description: "Формальне питання про особу в ролі об'єкта.",
      examples: [],
    },
    {
      title: "whose?",
      en: "whose?",
      ua: "чий? чия? чиє? чиї?",
      description: "Питання про приналежність.",
      examples: [],
    },
  ),
  createData(
    {
      title: "what if?",
      en: "what if?",
      ua: "що, якщо...? а якби...?",
      description: "Гіпотетичне питання про можливу ситуацію.",
      examples: [
        {
          lesson: 2,
          withoutBadge: true,
          title: (
            <>
              <AccentWord word="What if" /> I don't want to do it?
            </>
          ),
        },
      ],
    },
    null,
    {
      title: "how far?",
      en: "how far?",
      ua: "як далеко? на яку відстань?",
      description: "Питання про відстань.",
      examples: [],
    },
    {
      title: "which way?",
      en: "which way?",
      ua: "яким шляхом? в якому напрямку?",
      description: "Питання про напрямок руху.",
      examples: [],
    },
  ),
  createData(
    {
      title: "what time?",
      en: "what time?",
      ua: "о котрій годині? коли саме?",
      description: "Питання про точний час.",
      examples: [],
    },
    null,
    {
      title: "how long?",
      en: "how long?",
      ua: "як довго? протягом якого часу?",
      description: "Питання про тривалість часу.",
      examples: [],
    },
    {
      title: "which is better?",
      en: "which is better?",
      ua: "що краще? який кращий? яка краща? які кращі?",
      description: "Питання про порівняння та перевагу.",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
  ),
  createData(
    {
      title: "what kind of?",
      en: "what kind of?",
      ua: "який вид? який тип? яка саме?",
      description: "Питання про вид, тип, сорт.",
      examples: [],
    },
    null,
    {
      title: "how often?",
      en: "how often?",
      ua: "як часто? скільки разів?",
      description: "Питання про частоту, періодичність.",
      examples: [],
    },
    {
      title: "which worse?",
      en: "which worse?",
      ua: "що гірше? який гірший? яка гірша? які гірші?",
      description: "Питання про порівняння та негативну перевагу.",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
  ),
  createData(
    {
      title: "what sort of?",
      en: "what sort of?",
      ua: "який тип? який вид? який рід?",
      description: "Питання про тип, вид, характер, подібне до 'what kind of'.",
      examples: [],
    },
    null,
    {
      title: "how soon?",
      en: "how soon?",
      ua: "як скоро? через який час?",
      description: "Питання про час, що залишився до якоїсь події.",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
    null,
  ),
  createData(
    {
      title: "what color?",
      en: "what color?",
      ua: "якого кольору?",
      description: "Питання про колір.",
      examples: [],
    },
    null,
    {
      title: "how much?",
      en: "how much?",
      ua: "скільки? (про незліченні)",
      description: "Питання про кількість чогось незліченного.",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
    null,
  ),
  createData(
    {
      title: "what size?",
      en: "what size?",
      ua: "якого розміру?",
      description: "Питання про розмір.",
      examples: [],
    },
    null,
    {
      title: "how many?",
      en: "how many?",
      ua: "скільки? (про зліченні)",
      description: "Питання про кількість чогось зліченного.",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
    null,
  ),
  createData(
    {
      title: "what for?",
      en: "what for?",
      ua: "для чого? з якою метою? навіщо?",
      description: "Питання про мету, причину, призначення.",
      examples: [],
    },
    null,
    {
      title: "how old?",
      en: "how old?",
      ua: "скільки років? якого віку?",
      description: "Питання про вік.",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
    null,
  ),
  createData(
    {
      title: "what about ...?",
      en: "what about ...?",
      ua: "а як щодо...? що стосується...?",
      description: "Питання, що пропонує розглянути інший варіант, тему.",
      examples: [],
    },
    null,
    {
      title: "how come?",
      en: "how come?",
      ua: "як так? чому? (неформально)",
      description: "Неформальне питання про причину, подібне до 'Why?'.",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
    null,
  ),
  createData(
    {
      title: "what else?",
      en: "what else?",
      ua: "що ще? що інакше?",
      description: "Питання про додаткову інформацію, предмети, дії.",
      examples: [],
    },
    null,
    {
      title: "how about?",
      en: "how about?",
      ua: "як щодо...? а може...?",
      description: "Питання, що пропонує варіант, ідею, подібне до 'What about?'",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
    null,
  ),
  createData(
    {
      title: "in what way?",
      en: "in what way?",
      ua: "яким чином? яким способом?",
      description: "Питання про спосіб, метод, підхід.",
      examples: [],
    },
    null,
    {
      title: "how do you...?",
      en: "how do you...?",
      ua: "як ти...? як ви...? як це робиться?",
      description: "Питання про спосіб виконання дії, процес.",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
    null,
  ),
  createData(
    {
      title: "at what time?",
      en: "at what time?",
      ua: "о котрій годині? коли саме?",
      description: "Питання про точний час події.",
      examples: [],
    },
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ),
];

const QuestionWordsTable: React.FC<{}> = ({}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [isHeaderCellModalOpen, setIsHeaderCellModalOpen] = useState(false);
  const [activeWord, setActiveWord] = useState("");
  const [activeTranslation, setActiveTranslation] = useState("");
  const [activeDescription, setActiveDescription] = useState("");
  const [activeExamples, setActiveExamples] = useState<any[]>([]);
  const [headerCellModalBody, setHeaderCellModalBody] = useState<any>(null);
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  const globalWordsOptions = useMemo(() => {
    if (activeWord) {
      return { words: [activeWord] };
    }
    return { words: [] };
  }, [activeWord]);

  const [word] = useGlobalWords(globalWordsOptions);

  return (
    <Box>
      <Modal
        open={isCellModalOpen}
        onClose={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsCellModalOpen(false)} />
          <VStack alignItems="flex-start">
            <Typography variant="h3">{activeWord}</Typography>
            <Typography>{activeTranslation}</Typography>
            {word && <WordTranscription word={word} play small={false} autoplay={false} />}
            <Typography>{activeDescription}</Typography>
            <VStack alignItems="flex-start">
              {activeExamples
                .filter((e) => e.lesson <= lessonNumber)
                .map((e, i) => {
                  const item = (
                    <Typography variant="h3" fontSize={18} fontWeight={500} key={i}>
                      {e.title}
                    </Typography>
                  );

                  if (e.lesson === lessonNumber && !e.withoutBadge) {
                    // return <WithDotBadge>{item}</WithDotBadge>;
                    return <BackgroundBoxWraper lesson={2}>{item}</BackgroundBoxWraper>;
                  }
                  return item;
                })}
            </VStack>
          </VStack>
        </Box>
      </Modal>
      <Modal
        open={isHeaderCellModalOpen}
        onClose={() => {
          setIsHeaderCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsHeaderCellModalOpen(false)} />
          <VStack alignItems="flex-start">{headerCellModalBody}</VStack>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <TableRow>
              <TableCell
                sx={{ cursor: "pointer", width: 130, minWidth: 130 }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">what</Typography>,
                    // <Typography variant="body1">
                    //   Допоміжні дієслова утворені від "to do" дієслова
                    // </Typography>,
                    // <Typography variant="body1">
                    //   Займенники, які вказують на особу, яка виконує дію.
                    // </Typography>,
                  ]);
                }}
              >
                what
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer", width: 80, minWidth: 80 }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">who</Typography>,
                    // <Typography variant="body1">
                    //   Допоміжні дієслова утворені від "to be" дієслова
                    // </Typography>,
                    // <Typography variant="body1">
                    //   Займенники, які вказують на обʼєкт, до якого відбувається дія.
                    // </Typography>,
                  ]);
                }}
              >
                who
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">where</Typography>,
                    // <Typography variant="body1">
                    //   Напівмодальні допоміжні (іноді діють як головні дієслова, іноді як допоміжні)
                    // </Typography>,
                    // <Typography variant="body1">
                    // </Typography>,
                  ]);
                }}
              >
                where
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer", width: 130, minWidth: 130 }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">how</Typography>,
                    // <Typography variant="body1">
                    //   Допоміжні дієслова утворені від "to have" дієслова
                    // </Typography>,
                    // <Typography variant="body1">
                    //   Займенники, які вказують на належність об'єкта або особи до певної особи.
                    //   Завжди використовуються в парі з іменником.
                    // </Typography>,
                  ]);
                }}
              >
                how
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer", width: 135, minWidth: 135 }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">which</Typography>,
                    // <Typography variant="body1">
                    //   Допоміжні дієслова утворені від "to have" дієслова
                    // </Typography>,
                    // <Typography variant="body1">
                    //   Займенники, які вказують на належність об'єкта або особи до певної особи.
                    //   Завжди використовуються в парі з іменником.
                    // </Typography>,
                  ]);
                }}
              >
                which
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">when</Typography>,
                    // <Typography variant="body1">
                    //   Модальні допоміжні засоби (передають можливість, дозвіл тощо)
                    // </Typography>,
                    // <Typography variant="body1">
                    // </Typography>,
                  ]);
                }}
              >
                when
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">why</Typography>,
                    // <Typography variant="body1">
                    //   Напівмодальні допоміжні (іноді діють як головні дієслова, іноді як допоміжні)
                    // </Typography>,
                    // <Typography variant="body1">
                    // </Typography>,
                  ]);
                }}
              >
                why
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">whom</Typography>,
                    // <Typography variant="body1">
                    //   Напівмодальні допоміжні (іноді діють як головні дієслова, іноді як допоміжні)
                    // </Typography>,
                    // <Typography variant="body1">
                    // </Typography>,
                  ]);
                }}
              >
                whom
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">whose</Typography>,
                    // <Typography variant="body1">
                    //   Напівмодальні допоміжні (іноді діють як головні дієслова, іноді як допоміжні)
                    // </Typography>,
                    // <Typography variant="body1">
                    // </Typography>,
                  ]);
                }}
              >
                whose
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows(lessonNumber).map((row, index) => (
              <TableRow
                key={row.item1?.title || ""}
                sx={
                  {
                    // "&:last-child td, &:last-child th": { border: 0 }
                  }
                }
              >
                <CustomTableCell
                  onClick={() => {
                    if (row.item1) {
                      setActiveWord(removePunctuationFromString(row.item1.en));
                      setActiveTranslation(row.item1.ua);
                      setActiveDescription(row.item1.description);
                      setActiveExamples(row.item1.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  startBadge={row.item1?.startBadge}
                  lessons={row.item1?.examples.map((e) => e.lesson) || []}
                  en={row.item1?.en || "-"}
                />
                <CustomTableCell
                  onClick={() => {
                    if (row.item2) {
                      setActiveWord(removePunctuationFromString(row.item2.en));
                      setActiveTranslation(row.item2.ua);
                      setActiveDescription(row.item2.description);
                      setActiveExamples(row.item2.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  startBadge={row.item2?.startBadge}
                  lessons={row.item2?.examples.map((e) => e.lesson) || []}
                  en={row.item2?.en || "-"}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    if (row.item6) {
                      setActiveWord(removePunctuationFromString(row.item6.en));
                      setActiveTranslation(row.item6.ua);
                      setActiveDescription(row.item6.description);
                      setActiveExamples(row.item6.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item6?.examples.map((e) => e.lesson) || []}
                  startBadge={row.item6?.startBadge}
                  en={row.item6?.en || "-"}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    if (row.item3) {
                      setActiveWord(removePunctuationFromString(row.item3.en));
                      setActiveTranslation(row.item3.ua);
                      setActiveDescription(row.item3.description);
                      setActiveExamples(row.item3.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item3?.examples.map((e) => e.lesson) || []}
                  startBadge={row.item3?.startBadge}
                  en={row.item3?.en || "-"}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    if (row.item4) {
                      setActiveWord(removePunctuationFromString(row.item4.en));
                      setActiveTranslation(row.item4.ua);
                      setActiveDescription(row.item4.description);
                      setActiveExamples(row.item4.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item4?.examples.map((e) => e.lesson) || []}
                  startBadge={row.item4?.startBadge}
                  en={row.item4?.en || "-"}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    if (row.item5) {
                      setActiveWord(removePunctuationFromString(row.item5.en));
                      setActiveTranslation(row.item5.ua);
                      setActiveDescription(row.item5.description);
                      setActiveExamples(row.item5.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item5?.examples.map((e) => e.lesson) || []}
                  startBadge={row.item5?.startBadge}
                  en={row.item5?.en || "-"}
                ></CustomTableCell>

                <CustomTableCell
                  onClick={() => {
                    if (row.item6) {
                      setActiveWord(removePunctuationFromString(row.item6.en));
                      setActiveTranslation(row.item6.ua);
                      setActiveDescription(row.item6.description);
                      setActiveExamples(row.item6.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item7?.examples.map((e) => e.lesson) || []}
                  startBadge={row.item7?.startBadge}
                  en={row.item7?.en || "-"}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    if (row.item7) {
                      setActiveWord(removePunctuationFromString(row.item7.en));
                      setActiveTranslation(row.item7.ua);
                      setActiveDescription(row.item7.description);
                      setActiveExamples(row.item7.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item8?.examples.map((e) => e.lesson) || []}
                  startBadge={row.item8?.startBadge}
                  en={row.item8?.en || "-"}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    if (row.item8) {
                      setActiveWord(removePunctuationFromString(row.item8.en));
                      setActiveTranslation(row.item8.ua);
                      setActiveDescription(row.item8.description);
                      setActiveExamples(row.item8.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  startBadge={row.item9?.startBadge}
                  lessons={row.item9?.examples.map((e) => e.lesson) || []}
                  en={row.item9?.en || "-"}
                ></CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CustomTableCell: React.FC<{
  en: string;
  startBadge?: number;
  lessons: number[];
  onClick: () => void;
}> = ({ en, lessons, startBadge, onClick }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady =
    (lessons?.length && lessons.includes(lessonNumber)) ||
    (lessons?.length && Math.min(...lessons) <= lessonNumber);

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={onClick}
      sx={{
        // p: 0,
        m: 0,
        //
        // backgroundColor: "#4aa805",
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lessons?.length && lessons.includes(lessonNumber)
            ? "#ddffc5"
            : lessons?.length && Math.min(...lessons) <= lessonNumber
            ? "#fcffdb"
            : "#d0d0d0",
        // boxSizing: "content-box",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
      }}
    >
      <Box
        sx={
          {
            // borderTop: "1px solid rgba(224, 224, 224, 1)",
          }
        }
      >
        {lessons.includes(lessonNumber) && startBadge && startBadge <= lessonNumber
          ? // <Badge
            //   badgeContent=" "
            //   color="secondary"
            //   variant="dot"
            //   sx={{
            //     // color: "red",
            //     display: "block",
            //     "& .MuiBadge-badge": {
            //       position: "absolute",
            //       left: -15,
            //       top: 0,
            //       backgroundColor: "red",
            //       width: 2,
            //       height: 2,
            //       // padding: "0 4px",
            //       // opacity: 0,
            //     },
            //   }}
            // >
            // <WithDotBadge>{en}</WithDotBadge>
            en
          : // </Badge>
            en}
      </Box>
    </TableCell>
  );
};

export default QuestionWordsTable;
