import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";

const ReceiveGet = () => {
  return (
    <>
      <Box mb={5}>
        ❓
        <Typography variant="h3" gutterBottom fontWeight={400}>
          He{" "}
          <Typography component="span" variant="h3" color="primary">
            received
          </Typography>{" "}
          a promotion at work.
        </Typography>
        <Typography variant="h3" gutterBottom fontWeight={400}>
          He{" "}
          <Typography component="span" variant="h3" color="primary">
            got
          </Typography>{" "}
          a promotion at work.
        </Typography>
      </Box>
    </>
  );
};

export default ReceiveGet;
