import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, Link, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { RPCUser } from "../../common/fetchCurrentUser";
import supabaseClient from "../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import { Sentence } from "../../types/supabase";
import MenuLayout from "../Layout/MenuLayout";
import VStack from "../Unknown/VStack";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import HStack from "../Unknown/HStack";
import useCreateAudio from "../../common/useCreateAudio";
import storiesData from "./stories";

const Story: React.FC<{ story: any; onUpdateStories: () => void }> = ({
  story,
  onUpdateStories,
}) => {
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { createAudio } = useCreateAudio();

  return (
    <Box key={story.id} mb={3}>
      <Button
        component="a"
        target="_blank"
        href={`https://www.google.com/search?q=${story.author}`}
      >
        {story.author}
      </Button>
      <CopyToClipboard
        text={`
  Explain this phase of ${story.author}:
  ${story.id}

  When it was said (year and place)? What does it mean?

  Give me a description of this phrase in a format:
  [Phrase]

  [When was said]
  [The meaning]

  The answer must be in Ukrainian language. 
  Don't put some title in the answer. Just follow my answer pattern.
`}
      >
        <Button>What does it mean?</Button>
      </CopyToClipboard>
      <Typography>{story.id}</Typography>
      <Box color="brown">Words: {story.word_count}</Box>
      <Box color="brown">User intersection: {story.all_intersection_count}</Box>
      <Box color="brown">User new words: {story.new_words_count}</Box>
      <HStack>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(story.story_id);
          }}
        >
          Copy story id
        </Button>
        {story.link && (
          <Button color="info" component={Link} target="_blank" href={story.link}>
            external story
          </Button>
        )}
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            setIsLoading(true);
            const audio = await createAudio(story.id);
            console.log("🚀 ~ story:", story);
            console.log("🚀 ~ audio:", audio);
            await supabaseClient
              .from("english_verses")
              .update({
                audio,
              })
              .eq("id", story.id);
            setIsLoading(false);
          }}
        >
          Create audio
        </LoadingButton>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            setIsLoading(true);
            await supabaseClient
              .from("english_verses")
              .update({
                description,
              })
              .eq("id", story.id);
            setIsLoading(false);
          }}
        >
          Update
        </LoadingButton>
        <TextField
          color="primary"
          value={description}
          multiline={true}
          sx={{
            width: 500,
          }}
          placeholder="Description"
          onChange={(event) => {
            setDescription(event.target.value);
          }}
          type="text"
          variant="outlined"
        />
      </HStack>
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          setIsLoading(true);
          console.log("🚀 ~ story:", story);
          await supabaseClient.from("english_verses").delete().eq("verse", story.id);
          onUpdateStories();
          setIsLoading(false);
        }}
      >
        remove
      </Button>
      <Button
        variant="contained"
        onClick={async () => {
          setIsLoading(true);
          const result = await supabaseClient
            .from("english_verses")
            .update({
              grammar: true,
            })
            .eq("id", story.story_id);
          setIsLoading(false);

          console.log("🚀 ~ result:", result);
          // setStories(stories.filter((q) => q.id != story.id));
        }}
      >
        add to grammar
      </Button>

      {story.row_new_words && (
        <>
          <Button
            variant="outlined"
            // color="error"
            onClick={async () => {
              setIsLoading(true);
              const { data } = await supabaseClient
                .from("words")
                .select()
                .in("name", story.row_new_words);
              console.log("🚀 ~ data:", data);
              if (data) {
                setGlobalWords(data);
              }
              setIsLoading(false);
              // setStories(stories.filter((q) => q.id != story.id));
            }}
          >
            check words
          </Button>
          <CopyNewWordsForGemini words={story.row_new_words} />
          <Box color="brown">
            <Typography variant="h4">New words:</Typography>
            {story.row_new_words.map((word: string) => {
              const existingWord = globalWords.find((w) => w.name === word);
              return (
                <Button key={word}>
                  {word} ({existingWord?.examples?.length})
                </Button>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
const AdminCheckUserStories: React.FC = () => {
  const [stories, setStories] = useState(storiesData);

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }} p={5}>
        <Grid container>
          <Grid item xs={12}>
            {stories.map((story: any) => {
              return (
                <Story
                  key={story.id}
                  story={story}
                  onUpdateStories={() => {
                    setStories(stories.filter((q: any) => q.id != story.id));
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </MenuLayout>
  );
};

export default AdminCheckUserStories;
