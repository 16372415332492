import {
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import supabaseClient from "../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import {
  DialogType,
  ExtendedUserDialogType,
  UserDialogType,
} from "../../types/supabase";
import MenuLayout from "../Layout/MenuLayout";
import Loader from "../Unknown/Loader";
import PersonalDialogPreview from "../Unknown/PersonalDialogPreview";
import WhiteBoxWrap from "../Unknown/WhiteBoxWrap";
import { RPCUser } from "../../common/fetchCurrentUser";
import { adminUserId } from "../../common/constants";
import a1Words from "../../common/a1-c2-words/a1-words";
import HStack from "../Unknown/HStack";
import VStack from "../Unknown/VStack";
import ReactPlayer from "react-player";
import a2Words from "../../common/a1-c2-words/a2-words";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const WordModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  word: string;
}> = ({ isOpen, word, onClose }) => {
  // useEffect(() => {

  // }, [isOpen])

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ReactPlayer
          controls
          width="800px"
          height="100%"
          playing
          // style={{
          //   width: "100%",
          //   height: "100%",
          //   position: "absolute",
          //   top: 0,
          //   left: 0,
          // }}
          muted={false}
          url={`http://localhost:4000/videos/${word.split(" ").join("_")}.mp4`}
        />

        <Typography id="modal-modal-title" variant="h6" component="h2">
          Text in a modal
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography>
      </Box>
    </Modal>
  );
};

const AdminUsers: React.FC<{
  users: RPCUser[];
  onChange: (user: RPCUser) => void;
}> = ({ users, onChange }) => {
  const authUserId = useLessonsStore((state) => state.authUser?.id);
  const [currentUser, setCurrentUser] = useState<RPCUser | null>(null);

  if (authUserId !== adminUserId) return null;

  return (
    <Select
      value={currentUser?.id || ""}
      variant="standard"
      style={{
        height: "16px",
        fontSize: "12px",
        width: 200,
        minHeight: 0,
      }}
      onChange={(value) => {
        const newUser = users.find((u) => u.id === value.target.value)!;
        setCurrentUser(newUser);
        onChange(newUser);
      }}
    >
      {users.map((w, index) => (
        <MenuItem key={w.id} value={w.id}>
          {w.id}
        </MenuItem>
      ))}
    </Select>
  );
};

interface TopicsListProps {
  topics: string[];
  activeTopic: string;
  onChange: (topic: string) => void;
}

const TopicsList: React.FC<TopicsListProps> = ({
  topics,
  activeTopic,
  onChange,
}) => {
  return (
    <Box>
      {topics.map((topic) => (
        <Button
          key={topic}
          // variant={activeTopic === topic ? "contained" : "outlined"}
          sx={{
            textDecoration: "underline",
            color: activeTopic === topic ? "green" : "black",
            fontSize: 20,
            fontStyle: "italic",
          }}
          onClick={() => {
            onChange(topic);
          }}
        >
          {topic}
        </Button>
      ))}
    </Box>
  );
};

const additionalTopics = ["знайомство", "зустріч"];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PersonalDialogs: React.FC = () => {
  const authUser = useLessonsStore((state) => state.authUser);
  const [users, setUsers] = useState<RPCUser[]>([]);
  const [changedUserUid, setChangedUserUid] = useState<string | null>(null);
  const [activeWord, setActiveWord] = useState("");

  const [tabValue, setTabValue] = React.useState(0);
  const [currentUser, setCurrentUser] = useState<RPCUser | null>(null);
  console.log("🚀 ~ currentUser:", currentUser);
  const [currentUserDialogs, setCurrentUserDialogs] = useState<
    ExtendedUserDialogType[]
  >([]);
  const [person, setPerson] = useState<"both" | "first" | "second">("both");
  const [activeRepl, setActiveRepl] = useState(0);
  const [expandedLabel, setExpandedLabel] = useState("");

  const handleDialogExpnsion = (label: string) => {
    setExpandedLabel(label);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useMountedWaitAsyncEffect(async () => {
    if (users.length || !authUser) return;

    if (authUser.id !== adminUserId) {
      setUsers([authUser]);
      return;
    }

    // for admin only
    const { data: spUsers } = await supabaseClient.from("users").select();

    if (spUsers) setUsers(spUsers);
  }, [users]);

  useEffect(() => {
    const channel = supabaseClient
      .channel("room2")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "dialog-admin" },
        (payload) => {
          console.log("🚀 ~ payload:", payload);
          if (payload.new.id === "active-person") {
            setPerson(payload.new.value as any);
          } else if (payload.new.id === "active-repl") {
            setActiveRepl(payload.new.value as any);
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useMountedWaitAsyncEffect(() => {
    if (!authUser) return;

    let currentUser: RPCUser | undefined = undefined;
    if (changedUserUid) {
      currentUser = users.find((u) => changedUserUid === u.uuid);
    } else {
      currentUser = users.find((u) => authUser.uuid === u.uuid);
    }

    if (!currentUser) return;

    setCurrentUser(currentUser);
  }, [users, authUser, changedUserUid]);

  useMountedWaitAsyncEffect(async () => {
    if (!authUser) return;

    const { data: dialogs } = await supabaseClient
      .from("user-dialogs")
      .select("*, dialog(*)")
      .eq("user_id", changedUserUid || authUser.uuid)
      .returns<ExtendedUserDialogType[]>();

    if (dialogs) setCurrentUserDialogs(dialogs);
  }, [authUser, changedUserUid]);

  useEffect(() => {
    const channel = supabaseClient
      .channel("room1")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "user-dialogs" },
        async (payload) => {
          console.log("🚀 ~ payload:", payload);
          if (payload.eventType === "DELETE") {
            setCurrentUserDialogs((prev) => {
              return [...prev].filter((d) => d.id !== payload.old.id);
            });
          } else if (payload.eventType === "INSERT") {
            const { data: dialog } = await supabaseClient
              .from("user-dialogs")
              .select("*, dialog(*)")
              .eq("id", payload.new.id)
              .returns<ExtendedUserDialogType>()
              .single();

            if (dialog) {
              setCurrentUserDialogs((prev) => {
                return [...prev, dialog];
              });
            }
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [authUser, changedUserUid]);

  const [isActiveWordModalOpen, setIsActiveWordModalOpen] = useState(false);

  return (
    <MenuLayout>
      <WordModal
        isOpen={isActiveWordModalOpen}
        word={activeWord}
        onClose={() => {
          setIsActiveWordModalOpen(false);
        }}
      />
      <WhiteBoxWrap>
        <Grid container width="100%" spacing={5}>
          <Grid item xs={12}>
            {authUser && (
              <AdminUsers
                // userId={changedUserUid || authUser.uuid}
                users={users}
                onChange={(user) => {
                  setChangedUserUid(user.uuid);
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="A1 діалоги" {...a11yProps(0)} />
                  <Tab label="A2 діалоги" {...a11yProps(1)} />
                  <Tab label="Тематичні діалоги" {...a11yProps(2)} />
                  <Tab label="Мої діалоги" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={tabValue} index={0}>
                {/* {a1Words.map(({ words }) => words).flat().length} */}
                {a1Words.map(({ topic, words }) => {
                  return (
                    <VStack gap={1} alignItems="flex-start" mb={3}>
                      <Box>{topic}</Box>
                      <HStack display="flex" gap={1}>
                        {words.map((w) => {
                          return (
                            <Chip
                              component={Box}
                              display="inline-block"
                              label={w}
                              onClick={() => {
                                setActiveWord(w);
                                setIsActiveWordModalOpen(true);
                              }}
                            />
                          );
                        })}
                      </HStack>
                    </VStack>
                  );
                })}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                {/* {a1Words.map(({ words }) => words).flat().length} */}
                {Object.entries(
                  a2Words.reduce<Record<string, string[]>>((prev, curr) => {
                    return {
                      ...prev,
                      ...curr.topics
                        .map((topic) => {
                          if (prev[topic]) {
                            return {
                              topic,
                              words: [...prev[topic], curr.word],
                            };
                          } else {
                            return {
                              topic,
                              words: [curr.word],
                            };
                          }
                        })
                        .reduce((prevTopics, { topic, words }) => {
                          return {
                            ...prevTopics,
                            [topic]: words,
                          };
                        }, {}),
                    };
                  }, {}),
                ).map(([topic, words]) => {
                  return (
                    <VStack gap={1} alignItems="flex-start" mb={3}>
                      <Box>{topic}</Box>
                      <HStack display="flex" gap={1}>
                        {words.map((w) => {
                          return (
                            <Chip
                              component={Box}
                              display="inline-block"
                              label={w}
                              onClick={() => {
                                setActiveWord(w);
                                setIsActiveWordModalOpen(true);
                              }}
                            />
                          );
                        })}
                      </HStack>
                    </VStack>
                  );
                })}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                <AllDialogs
                  person={person}
                  activeRepl={activeRepl}
                  expandedLabel={expandedLabel}
                  changedUserUid={changedUserUid}
                  currentUserDialogs={currentUserDialogs}
                  onDialogExpnsion={handleDialogExpnsion}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={3}>
                <CurrentUserDialogs
                  person={person}
                  activeRepl={activeRepl}
                  expandedLabel={expandedLabel}
                  changedUserUid={changedUserUid}
                  currentUserDialogs={currentUserDialogs.filter(
                    ({ ignored }) => !ignored,
                  )}
                  onDialogExpnsion={handleDialogExpnsion}
                />
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
      </WhiteBoxWrap>
    </MenuLayout>
  );
};

interface AllDialogsProps {
  person: "both" | "first" | "second";
  activeRepl: number;
  expandedLabel: string;
  changedUserUid: string | null;
  currentUserDialogs: ExtendedUserDialogType[];
  onDialogExpnsion: (label: string) => void;
}

const AllDialogs: React.FC<AllDialogsProps> = ({
  person,
  activeRepl,
  expandedLabel,
  changedUserUid,
  currentUserDialogs,
  onDialogExpnsion,
}) => {
  const [topics, setTopics] = useState<string[]>([]);
  const [activeTopic, setActiveTopic] = useState("");
  const [dialogs, setDialogs] = useState<Record<string, DialogType[]>>({});
  const [isLoading, setIsLoading] = useState(false);

  const rowCurrentUserDialogs = useMemo(() => {
    return currentUserDialogs
      .filter(({ ignored }) => !ignored)
      .map(({ dialog }) => dialog.id);
  }, [currentUserDialogs]);

  const rowCurrentUserIgnoredDialogs = useMemo(() => {
    return currentUserDialogs
      .filter(({ ignored }) => !!ignored)
      .map(({ dialog }) => dialog.id);
  }, [currentUserDialogs]);

  useMountedWaitAsyncEffect(async () => {
    if (!topics.length) {
      const { data: spTopics } = await supabaseClient.rpc("get_dialogs_topics");

      setTopics(spTopics.filter((t: string) => !additionalTopics.includes(t)));
    }
  }, [topics]);

  useMountedWaitAsyncEffect(async () => {
    if (activeTopic) {
      const { data: dialogs } = await supabaseClient
        .from("dialogs")
        .select()
        .contains("topics", [activeTopic])
        .order("created_at")
        .returns<DialogType[]>();

      if (dialogs)
        setDialogs(
          dialogs.reduce<Record<string, DialogType[]>>((prev, dialog) => {
            return {
              ...prev,
              [dialog.level]: [...(prev[dialog.level] || []), dialog],
              //   .sort(
              //   (a, b) => (rowCurrentUserDialogs.includes(a.id) ? 1 : -1),
              // ),
            };
          }, {}),
        );

      setIsLoading(false);
    }
  }, [changedUserUid, activeTopic]);

  useEffect(() => {
    const channel = supabaseClient
      .channel("room3")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "dialogs" },
        (payload) => {
          if (dialogs) {
            const dialogsEntries = Object.entries(dialogs);
            const newDialogEntries: Record<string, DialogType[]>[] =
              dialogsEntries.map(([level, dialogs]) => {
                const updatedDialogs: DialogType[] = dialogs.map((dialog) => {
                  if (dialog.id === payload.new.id)
                    return payload.new.id as DialogType;
                  return dialog;
                });
                return [level, updatedDialogs];
              }) as unknown as Record<string, DialogType[]>[];
            const updateDialogs = newDialogEntries.reduce((prev, entries) => {
              return {
                ...prev,
                ...entries,
              };
            }, {});

            setDialogs(updateDialogs);
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [dialogs]);

  if (!topics.length) return <Loader />;

  return (
    <Grid container py={8}>
      {/* <Grid item xs={12} md={4}>
        <TopicsList
          topics={additionalTopics}
          onChange={(topic) => {
            setIsLoading(true);
            setAditionalActiveTopic(topic);
          }}
          activeTopic={aditionalActiveTopic}
        />
      </Grid> */}
      <Grid item xs={12} mb={10}>
        <TopicsList
          topics={topics}
          onChange={(topic) => {
            setIsLoading(true);
            setActiveTopic(topic);
          }}
          activeTopic={activeTopic}
        />
      </Grid>
      <Grid container item xs={12} spacing={5} mb={10}>
        {isLoading && <Loader />}
        {!isLoading &&
          Object.entries(dialogs).map(([level, dialogs]) => {
            console.log("🚀 ~ -------------", dialogs);
            return (
              <Grid item xs={12}>
                <Box mb={5}>
                  <Typography gutterBottom variant="h3">
                    Рівень {level}
                  </Typography>
                </Box>
                {dialogs.map((dialog) => {
                  return (
                    <PersonalDialogPreview
                      type={dialog.type}
                      label={dialog.id}
                      person={person}
                      activeRepl={activeRepl}
                      dialog={dialog.dialog}
                      topics={dialog.topics}
                      expandedLabel={expandedLabel}
                      belongsToCurrentUser={rowCurrentUserDialogs.includes(
                        dialog.id,
                      )}
                      ignoredByUser={rowCurrentUserIgnoredDialogs.includes(
                        dialog.id,
                      )}
                      onDialogExpnsion={onDialogExpnsion}
                      anotherUserUid={changedUserUid}
                      dialogWords={dialog.words}
                    />
                  );
                })}
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

interface CurrentUserDialogsProps {
  person: "both" | "first" | "second";
  activeRepl: number;
  expandedLabel: string;
  changedUserUid: string | null;
  currentUserDialogs: ExtendedUserDialogType[];
  onDialogExpnsion: (label: string) => void;
}

const CurrentUserDialogs: React.FC<CurrentUserDialogsProps> = ({
  person,
  activeRepl,
  expandedLabel,
  changedUserUid,
  currentUserDialogs,
  onDialogExpnsion,
}) => {
  console.log("🚀 ~ currentUserDialogs:", currentUserDialogs);
  const [topics, setTopics] = useState<string[]>([]);
  const [isLoading] = useState(false);

  useMountedWaitAsyncEffect(async () => {
    if (!topics.length) {
      const { data: spTopics } = await supabaseClient.rpc("get_dialogs_topics");
      setTopics(spTopics.filter((t: string) => !additionalTopics.includes(t)));
    }
  }, [topics]);
  if (!topics.length) return <Loader />;

  return (
    <Grid container py={8}>
      <Grid container item xs={12} spacing={2} mb={10}>
        {isLoading && <Loader />}
        {!isLoading &&
          currentUserDialogs.map(({ dialog }) => {
            return (
              <Grid item xs={12}>
                <PersonalDialogPreview
                  type={dialog.type}
                  label={dialog.id}
                  person={person}
                  activeRepl={activeRepl}
                  dialog={dialog.dialog}
                  topics={dialog.topics}
                  expandedLabel={expandedLabel}
                  belongsToCurrentUser={false}
                  ignoredByUser={false}
                  anotherUserUid={changedUserUid}
                  dialogWords={dialog.words}
                  onDialogExpnsion={onDialogExpnsion}
                />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default PersonalDialogs;
