import { Box, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import isAdmin from "../../../common/isAdmin";
import { useLessonsStore } from "../../../store/lessonsStore";
import { EditText } from "react-edit-text";

interface UkrainianAudioSentenceProps {
  translation: string;
  alignLeft?: boolean;
  isVisible?: boolean;
  small?: boolean;
  row?: boolean;
  fontSize?: number;
  onAdminChange?: (value: string) => Promise<void>;
}

interface HighlightWordProps {
  text: string;
  row?: boolean;
  isLast?: boolean;
  fontSize?: number;
}

const Comma: React.FC<{ fontSize?: number; isItalic?: boolean }> = ({ fontSize, isItalic }) => (
  <Typography {...(isItalic ? { fontStyle: "italic", fontSize: 20 } : { fontSize: fontSize || 25 })} color="secondary.600" component="span">
    {","}&nbsp;
  </Typography>
);

export const HighlightWord: React.FC<HighlightWordProps> = ({ text, row, isLast, fontSize }) => {
  // Define the regular expression pattern to match words with or without punctuation
  const regex = /\(([^)]+)\)/g;

  // Use the regular expression to find matches in the text
  const matches = text.match(regex);

  if (!matches) {
    const isItalic = text.startsWith("*") && text.endsWith("*");

    return (
      <Typography fontFamily="Roboto" component="span" color={isItalic ? "primary.500" : "secondary.600"} {...(isItalic ? { fontStyle: "italic", fontSize: 20 } : { fontSize: fontSize || 25 })}>
        {isItalic ? text.slice(1) : text}
        {row && !isLast ? <Comma fontSize={fontSize} isItalic={isItalic} /> : null}
      </Typography>
    );
  }

  const [match] = matches;

  // else if (
  //   ["(артикль)", "(заперечна форма)", "(визначений артикль)"].includes(match)
  // ) {
  //   return (
  //     <Typography
  //       fontSize={25}
  //       component="span"
  //       color="secondary.600"
  //       textAlign="center"
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Typography
  //         variant="caption"
  //         component="span"
  //         pr={1}
  //         fontSize={25}
  //         sx={{
  //           display: "flex",
  //           justifyContent: "center",
  //         }}
  //       >
  //         {match}
  //       </Typography>
  //       {row && !isLast ? <Comma /> : null}
  //     </Typography>
  //   );
  // }

  const startsWithExample = text.startsWith(match);
  const endsWithExample = text.endsWith(match);

  if (startsWithExample && text.includes("...")) {
    return (
      <>
        <Typography fontFamily="Roboto" variant="caption" component="span" pr={1}>
          {match}{" "}
        </Typography>
        <Typography fontFamily="Roboto" fontSize={fontSize || 25} component="span" color="secondary.600">
          {text.replace(match, "").replace("...", "")}
        </Typography>
        <Typography fontFamily="Roboto" variant="caption" component="span" fontSize={fontSize || 16} sx={{ alignSelf: "flex-end" }}>
          ...
        </Typography>
        {row && !isLast ? <Comma /> : null}
      </>
    );
  } else if (startsWithExample) {
    return (
      <Box component="span" display="flex" alignItems="center">
        <Typography fontFamily="Roboto" variant="caption" component="span" pr={1}>
          {match}
        </Typography>
        &nbsp;
        <Typography fontFamily="Roboto" fontSize={fontSize || 25} color={match === "(пф.)" ? "black" : "secondary.600"} component="span">
          {text.replace(match, "")}
        </Typography>
        {row && !isLast ? <Comma /> : null}
      </Box>
    );
  } else if (endsWithExample) {
    return (
      <>
        <Typography fontFamily="Roboto" fontSize={fontSize || 25} color="secondary.600" component="span">
          {text.replace(match, "")}
        </Typography>
        <Typography fontFamily="Roboto" fontSize={fontSize || 16} variant="caption" component="span" pl={1}>
          {match}
        </Typography>
        {row && !isLast ? <Comma /> : null}
      </>
    );
  }

  return (
    <Typography fontFamily="Roboto" color="secondary.600" textAlign="center" component="span">
      {text}
      {row && !isLast ? <Comma /> : null}
    </Typography>
  );
};

const UkrainianWord: React.FC<UkrainianAudioSentenceProps> = ({ isVisible = true, translation, small, fontSize, alignLeft, onAdminChange, row }) => {
  const groupedTranslations = useMemo(() => {
    return translation?.split(",").map((t) => t.trim());
  }, [translation]);

  if (!isVisible) return null;

  return (
    <Box fontWeight={400} display="flex" justifyContent={alignLeft ? "flex-start" : "center"} alignItems="center" flexWrap="wrap">
      {groupedTranslations?.map((t, index) => {
        return <HighlightWord text={t} fontSize={fontSize} key={index} row isLast={groupedTranslations.length - 1 === index} />;
      })}
    </Box>
  );
};

export default memo(UkrainianWord);
