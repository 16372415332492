import { Box, Button, Container, TextField, Typography } from "@mui/material";
// import PoolIcon from "@mui/icons-material/Pool";
import React, { useState } from "react";
import supabaseClient from "../../../../../common/supabaseClient";
// import Auth from "../../Auth/Page";
import { RPCUser } from "../../../../../common/fetchCurrentUser";
import { Word } from "../../../../../types/supabase";
import AdminUsers from "../../../../AdminUsers";
import useMountedWaitAsyncEffect from "../../../../../hooks/useMountedWaitAsyncEffect";
import rawSentences from "../../../../../data/sentences";

const AdminFeed: React.FC<{ users: RPCUser[] | null }> = ({ users }) => {
  const [changedUserUuid, setChangedUserUuid] = useState<string | null>(null);
  const [adminNewUserWords, setAdminNewUserWords] = useState<Word[]>([]);
  const [sentence, setSentence] = useState("");
  const [selectedS, setSelectedS] = useState<string[]>([]);
  const [selectedS2, setSelectedS2] = useState<string[]>([]);

  useMountedWaitAsyncEffect(() => {
    if (sentence) {
      const selectedS: string[] = [];
      const selectedS2: string[] = [];

      for (let i = 0; i < rawSentences.length; i++) {
        const s = rawSentences[i].toLowerCase();

        if (s.match(sentence)) {
          selectedS.push(rawSentences[i]);
        } else if (sentence.match(" ")) {
          const [first, second] = sentence.split(" ");

          if (first && second) {
            if (s.match(first) && s.match(second)) {
              selectedS2.push(rawSentences[i]);
            }
          }
        }
      }

      setSelectedS(selectedS.sort((a, b) => a.length - b.length));
      setSelectedS2(selectedS2.sort((a, b) => a.length - b.length));
    }
  }, [sentence]);

  return (
    <>
      {users && users?.length && (
        <AdminUsers
          users={users}
          onChange={(user) => {
            setChangedUserUuid(user.uuid);
          }}
        />
      )}
      <br />
      <Button
        onClick={async () => {
          const { data: newWords } = await supabaseClient.rpc(
            "get_user_new_words_from_grammar_course",
            { user_id: changedUserUuid },
          );
          setAdminNewUserWords(newWords);
          console.log("🚀 ~ newWords:", newWords);
        }}
      >
        Fetch words
      </Button>
      <br />
      {!!adminNewUserWords.length && <>{adminNewUserWords.join(", ")}</>}
      <br />
      <Button
        onClick={async () => {
          await Promise.all(
            adminNewUserWords.map((word) =>
              supabaseClient.from("user-words").insert({
                id: `${changedUserUuid}-${word}`,
                user: changedUserUuid,
                word,
              }),
            ),
          );
        }}
      >
        Upload
      </Button>
      <Container sx={{ p: 10 }}>
        <Box mb={3}>
          <TextField
            fullWidth
            color="primary"
            value={sentence}
            multiline={true}
            placeholder="Sentence"
            onChange={(event) => {
              setSentence(event.target.value.toLowerCase());
            }}
            type="text"
            variant="outlined"
          />
        </Box>

        {!!selectedS.length && (
          <>
            <Typography variant="h2">Strict match</Typography>
            <Box>
              {selectedS.map((s) => (
                <Typography>{s}</Typography>
              ))}
            </Box>
          </>
        )}
        {!!selectedS2.length && (
          <>
            <Typography variant="h2">Match by a word</Typography>
            <Box>
              {selectedS2.map((s) => (
                <Typography>{s}</Typography>
              ))}
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default AdminFeed;
