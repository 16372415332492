import supabaseClient from "../common/supabaseClient";
import { useLessonsStore } from "../store/lessonsStore";
import { Sentence } from "../types/supabase";

const usePersonalLesson = (lessonId: string, sentenceIds?: number[]) => {
  const personalLessonsSentences = useLessonsStore(
    (state) => state.personalLessonsSentences[lessonId],
  );
  const setPersonalLessonsSentences = useLessonsStore(
    (state) => state.setPersonalLessonsSentences,
  );

  const fetchPersonalSentences = async () => {
    if (!personalLessonsSentences && sentenceIds) {
      const { data: personalSentences } = await supabaseClient
        .from("sentences")
        .select()
        .in("id", sentenceIds)
        .returns<Sentence[]>();

      if (personalSentences) {
        setPersonalLessonsSentences(lessonId, personalSentences);
      }
    }
  };

  return {
    personalLessonsSentences,
    setPersonalLessonsSentences,
    fetchPersonalSentences,
  };
};

export default usePersonalLesson;
