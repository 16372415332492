import { Badge } from "@mui/material";
import { PropsWithChildren } from "react";

const WithDotBadge: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Badge
      badgeContent=""
      color="secondary"
      variant="dot"
      sx={{
        pl: 3,
        display: "block",
        "& .MuiBadge-badge": {
          position: "absolute",
          left: 0,
          top: "calc(50% - 2px)",
          height: 4,
          backgroundColor: "red",
          // backgroundColor: "red",
          width: 4,
          minWidth: 0,
          p: 0,
        },
      }}
    >
      {children}
    </Badge>
  );
};

export default WithDotBadge;
