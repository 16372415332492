import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, ButtonPropsVariantOverrides, Modal } from "@mui/material";
import { memo, useState } from "react";
import ReactPlayer from "react-player";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import VStack from "../../Unknown/VStack";

const VideoModal: React.FC<{
  videoUrl: string;
  position: "center" | "flex-end" | "flex-start";
  withIcon?: boolean;
  buttonLabel: string;
  buttonVariant: "text" | "outlined" | "contained";
}> = ({ videoUrl, buttonLabel, buttonVariant, withIcon, position }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <VStack alignItems={position}>
        {withIcon && (
          <Button variant={buttonVariant} color="info" onClick={handleOpen}>
            <InfoIcon />
            &nbsp; {buttonLabel}
          </Button>
        )}
        {!withIcon && (
          <Button variant={buttonVariant} color="info" onClick={handleOpen}>
            {buttonLabel}
          </Button>
        )}
      </VStack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            p: 4,
            overflow: "hidden",
            maxWidth: {
              xs: "100%",
            },
            height: {
              xs: "100%",
            },
            maxHeight: {
              xs: "56vw",
            },
            paddingBottom: {
              xs: "52%",
              sm: 0,
            },
            bgcolor: "background.paper",
          }}
        >
          <ModalCloseButton onClose={handleClose} />
          <ReactPlayer
            controls
            width="100%"
            height="100%"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            url={videoUrl}
            playing
            muted={false}
            volume={1}
          />
        </Box>
      </Modal>
    </>
  );
};

export default memo(VideoModal);
