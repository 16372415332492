import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EditText, EditTextarea } from "react-edit-text";
import { useFirebaseApp } from "reactfire";
import getGCRandomVoice from "../../../common/getGCRandomVoice";
import supabaseClient from "../../../common/supabaseClient";
import wait from "../../../common/wait";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { Word } from "../../../types/supabase";
import WordAudio from "../../Audio/WordAudio";
import HStack from "../../Unknown/HStack";
import VStack from "../../Unknown/VStack";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import { useLessonsStore } from "../../../store/lessonsStore";
import DisplayToSkipWords from "../DisplayToSkipWords";
import CopyNewWordsForGemini from "../CopyNewWordsForGemini";
import getSkipWords from "../../../common/getSkipWords";
import filterSkipedWords from "../../../common/filterSkipedWords";

const getUnverifiedWords = async () => {
  try {
    const { data: unverifiedWords } = await supabaseClient
      .from("words")
      .select()
      .is("verified", false)
      .limit(10)
      .throwOnError()
      .returns<Word[]>();

    if (!unverifiedWords)
      throw new Error("Something went worng. Unverified words are empty.");

    return unverifiedWords;
  } catch (err) {
    console.error("ERROR", err);
  }

  return [];
};

const AdminVerifyWords = () => {
  const [unverifiedWords, setUnverifiedWords] = useState<Word[]>([]);
  const [allWords, setAllWords] = useState<string[]>([]);
  const newWords = useLessonsStore((state) => state.newWords);
  const setNewWords = useLessonsStore((state) => state.setNewWords);

  // useMountedWaitAsyncEffect(async () => {
  //   if (newWords.length) {
  //     const skipWords = await getSkipWords(newWords);
  //     console.log(
  //       "🚀 ~ skipWords:",
  //       skipWords,
  //       filterSkipedWords(newWords, skipWords),
  //     );
  //     setNewWords(filterSkipedWords(newWords, skipWords));
  //   }
  // }, [newWords]);

  useMountedWaitAsyncEffect(async () => {
    if (!allWords.length) {
      const { data } = await supabaseClient.rpc("get_all_words");

      if (data) setAllWords(data);
    }
  }, [allWords]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      await wait(1000);
      if (!mounted || unverifiedWords.length) return;

      const words = await getUnverifiedWords();
      setUnverifiedWords(words);
    })();

    return () => {
      mounted = false;
    };
  }, [unverifiedWords]);

  return (
    <Container>
      <Grid container>
        <DisplayToSkipWords
          words={newWords}
          newWords={newWords}
          onUpdateSkipWords={(wordsToSkip) => {
            console.log("🚀 ~ wordsToSkip:", wordsToSkip);
            setNewWords(
              newWords.filter((w) => !wordsToSkip.includes(w)),
              true,
            );
          }}
        />
        <CopyNewWordsForGemini words={newWords} />
        {!Boolean(unverifiedWords.length) && (
          <Typography>No unverified word found.</Typography>
        )}
        {unverifiedWords.map((word, index) => (
          <AdminWordItem word={word} key={index} allWords={allWords} />
        ))}
      </Grid>
    </Container>
  );
};

const ExampleItem: React.FC<{
  example: { en: string; ua: string };
  allWords: string[];
  onAddNewWord: (word: string) => void;
}> = ({ example, allWords, onAddNewWord }) => {
  const [enSplit, setEnSplit] = useState<any>();
  // const skipWords = await getSkipWords(getSentenceWords(text.join(" ")));

  useEffect(() => {
    setEnSplit(
      example.en.split(" ").map((item, i) => {
        const cleanItem = removePunctuationFromString(item);

        const exist1 = allWords.includes(cleanItem);
        const exist2 = allWords.includes(cleanItem.toLowerCase());
        const exist3 = allWords.includes(cleanItem.replace("’", "'"));
        const exist4 = allWords.includes(
          cleanItem.toLowerCase().replace("’", "'"),
        );
        if ((exist3 || exist4) && !exist1 && !exist2) {
          console.log("🚀 ~ cleanItem:", cleanItem);
        }

        if (!exist1 && !exist2 && !exist3 && !exist4) {
          console.log("🚀 ~ cleanItem:", cleanItem);
          onAddNewWord(cleanItem);
        }

        return {
          sentenceWord: item,
          isSkiped: false,
          word: exist1
            ? cleanItem
            : exist2
            ? cleanItem.toLowerCase()
            : exist3
            ? cleanItem.replace("’", "'")
            : exist4
            ? cleanItem.toLowerCase().replace("’", "'")
            : "",
        };
      }),
    );
  }, [example.en]);

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Button>Submit</Button>
        <Box>{example.en}</Box>
        <Box>{example.ua}</Box>
      </HStack>
      <HStack>
        {enSplit?.map((item: any, i: any) => {
          return (
            <VStack key={i}>
              <Box
                sx={{
                  backgroundColor: item.word ? "#e0ffe0" : "red",
                }}
              >
                {item.sentenceWord}
              </Box>
              <Box>{item.word}</Box>
            </VStack>
          );
        })}
      </HStack>
    </VStack>
  );
};

const AdminWordItem: React.FC<{ word: any; allWords: string[] }> = memo(
  ({ word, allWords }) => {
    const [transcription, setTranscription] = useState(
      word.mainTranscription || word.transcription,
    );

    const newWords = useLessonsStore((state) => state.newWords);
    const setNewWords = useLessonsStore((state) => state.setNewWords);
    // console.log("🚀 ~ newWords:", newWords);
    const [name, setName] = useState(word.name);
    const [translation, setTranslation] = useState(word.translation);
    const [spExamples, setSPExamples] = useState<{ en: string; ua: string }[]>(
      [],
    );
    const handleChange = (e: any, setFn: any) => {
      setFn(e.target.value);
    };
    const app = useFirebaseApp();
    const functions = getFunctions(app, "europe-west3");
    const [isAudioLoading, setIsAudioLoading] = useState(false);

    const textToSpeechFunction = httpsCallable(
      functions,
      "recordEnglishTextToSpeechAndReturnStorageUrl",
    );
    const voiceName = getGCRandomVoice();

    const createAudio = async (word: string) => {
      try {
        const result = await textToSpeechFunction({
          en: word.replace("(verb) ", ""),
          voiceName,
        });
        return result;
      } catch (err) {
        console.log("🚀 try/catch create audio error", err);
      }
    };

    const [newAudioUrl, setNewAudioUrl] = useState("");

    const updateAudio = async () => {
      setIsAudioLoading(true);
      const audioResponse: any = await createAudio(name);
      setNewAudioUrl(audioResponse?.data?.url);
      setIsAudioLoading(false);
    };

    const getSupabaseWordExamples = useCallback(async () => {
      const { data: wordsExamples } = await supabaseClient
        .from("sentences")
        .select()
        .in("new_word", [
          word.name.replace("(verb) to ", "to "),
          name,
          `(verb) to ${name}`,
          `(verb) ${name}`,
          `to ${name}`,
        ]);
      if (wordsExamples) {
        setSPExamples(
          wordsExamples.map((e) => ({
            en: e.en,
            ua: e.ua,
          })),
        );
      }
    }, [name, word.name]);

    useMountedWaitAsyncEffect(async () => {
      await wait(1000);
      await getSupabaseWordExamples();
    }, [getSupabaseWordExamples]);

    const [isUpdated, setIsUpdated] = useState(false);

    const handleSubmit = async () => {
      const updatedDoc = {
        // ...word,
        // name: name || word.name,
        examples: word.examples,
        // audioUrl: newAudioUrl || word.audioUrl,
        // translation: translation || word.translation,
        // transcription: transcription || word.mainTranscription,
        verified: true,
      };

      await supabaseClient
        .from("words")
        .update(updatedDoc)
        .eq("name", word.name);
      setIsUpdated(true);
    };

    // const deleteDocument = async () => {
    //   await supabaseClient.from("words").delete().eq("name", word.name);

    //   setIsUpdated(true);
    // };

    // const audio = useMemo(() => {
    //   if ((!word.audioUrl && !newAudioUrl) || isAudioLoading) return null;

    //   return <WordAudio url={newAudioUrl || word.audioUrl} />;
    // }, [isAudioLoading, newAudioUrl, word]);

    if (isUpdated) return null;

    return (
      <Grid item xs={12}>
        <Box mb={10}>
          {/* {word.audioUrl && <WordAudio url={word.audioUrl} />} */}
          {/* <HStack>
          {name}
          <CopyToClipboard text={name} onCopy={() => {}}>
            <Button variant="outlined">Copy</Button>
          </CopyToClipboard>
        </HStack> */}
          {/* <Typography
          variant="h2"
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <EditText
            name="textbox1"
            defaultValue={word.name}
            style={{
              fontSize: "30px",
            }}
            value={name}
            onChange={(e) => handleChange(e, setName)}
          />
        </Typography> */}
          {/* <Typography variant="h3">
          <EditText
            name="textbox2"
            defaultValue={word.mainTranscription}
            style={{
              fontSize: "30px",
              color: "gray",
              fontFamily: "Roboto",
            }}
            value={transcription}
            onChange={(e) => {
              handleChange(e, setTranscription);
            }}
          />
        </Typography> */}
          <Typography
            variant="h2"
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            {/* <Button
            variant="outlined"
            onClick={() => {
              setTranslation(`(він/вона) ${translation}`);
            }}
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            (він/вона)
          </Button> */}
            {/* <EditTextarea
            name="textbox3"
            style={{
              fontSize: "30px",
              color: "orange",
              width: "100%",
              height: "140px",
            }}
            value={translation}
            // inputClassName="bg-success"
            onChange={(e) => handleChange(e, setTranslation)}
          /> */}
          </Typography>
          <Typography variant="h3">Examples:</Typography>
          {(word.examples || spExamples || [])
            .filter((example: any) => !example.words)
            .map((e: any, i: number) => (
              <ExampleItem
                example={e}
                key={i}
                allWords={allWords}
                onAddNewWord={(newWord) => {
                  setNewWords([newWord]);
                }}
              />
            ))}
          <HStack>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            {/* {audio} */}
            {/* <Button
            variant="contained"
            disabled={isAudioLoading}
            onClick={updateAudio}
          >
            Update/Create audio
          </Button> */}
            {/* <Button variant="contained" color="error" onClick={deleteDocument}>
            Delete document
          </Button> */}
            {/* <Button variant="contained" onClick={getSupabaseWordExamples}>
            get examples
          </Button> */}
          </HStack>
        </Box>
      </Grid>
    );
  },
);

export default memo(AdminVerifyWords);
