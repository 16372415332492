const getStyleForTab = (lessonType?: string, type?: string) => {
  return {
    opacity: lessonType === type ? 1 : 0,
    pointerEvents: lessonType === type ? "auto" : "none",
    left: lessonType === type ? 0 : -999999,
    position: lessonType === type ? "relative" : "absolute",
  };
};

export default getStyleForTab;
