import CloseIcon from "@mui/icons-material/Close";
import IconButton from "../IconButton";

interface ModalCloseButtonProps {
  onClose: () => void;
}

const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({ onClose }) => {
  return (
    <IconButton
      onClick={onClose}
      sx={{
        position: "absolute",
        backgroundColor: "#4aa805",
        color: "white",
        right: 10,
        top: 8,
        zIndex: 10,
        "&:hover": {
          backgroundColor: "green",
        },
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default ModalCloseButton;
