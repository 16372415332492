import { Box, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import useIsInViewport from "../../../hooks/useIsInViewport";
import { ExtendedUserSentence } from "../../../types/supabase";
import EnglishAudioSentence from "../EnglishAudioSentence";
import Hint, { HintType } from "../Hint";
import RepetitionButtons from "../RepetitionButtons";
import TranslateButton from "../TranslateButton";
import UkrainianAudioSentence from "../UkrainianAudioSentence";
import useLessonWords from "../../../hooks/useLessonWords";
import PersonalEnPronounce from "../../Word/WordTranslationSection/PersonalEnPronounce";
import { useLessonsStore } from "../../../store/lessonsStore";

interface RepetitionUserSentenceProps {
  sentence: ExtendedUserSentence;
  wordsCount: number;
  goToNextSlide: (sentence: string, period: string) => Promise<void>;
}

const RepetitionUserSentence = ({
  sentence,
  wordsCount,
  goToNextSlide,
}: RepetitionUserSentenceProps) => {
  const isPersonalLesson = useLessonsStore((state) => state.isPersonalLesson);
  const [isTranslated, setIsTranslated] = useState(false);
  const ref = useRef<HTMLElement>();
  const isInViewport = useIsInViewport(ref);

  const resetIsTranslatedAfterSlideChange = () => {
    setTimeout(() => {
      setIsTranslated(false);
    }, 400);
  };

  useEffect(() => {
    if (!isInViewport) resetIsTranslatedAfterSlideChange();
  }, [isInViewport]);

  const { lessonWords } = useLessonWords({
    sentences: [sentence.en],
    trigger: sentence.en,
  });

  return (
    <Box
      pt={10}
      pb={10}
      display="flex"
      alignItems="center"
      position="relative"
      minHeight="calc(100vh - 200px)"
    >
      <Box
        gap={3}
        width="100%"
        display="flex"
        position="relative"
        textAlign="center"
        alignItems="center"
        flexDirection="column"
        pt={10}
      >
        <Hint text={sentence.en} type={HintType.sentence} />
        {/* center box to detect i in viewport */}
        <Box ref={ref} width={10} height={10} />
        <UkrainianAudioSentence
          sentence={sentence.sentences.ua}
          // audioUrl=""
          // isAudioPlay={isInViewport}
        />
        <EnglishAudioSentence
          sentence={sentence.en}
          audioUrl={sentence.sentences.en_audio_url}
          isVisible={isTranslated}
        >
          {isPersonalLesson && (
            <Typography
              fontStyle="italic"
              fontSize={25}
              color="secondary.dark"
              fontWeight={500}
              textAlign="center"
            >
              <PersonalEnPronounce
                sentence={sentence.en}
                lessonWords={lessonWords}
              />
            </Typography>
          )}
        </EnglishAudioSentence>
        <TranslateButton
          isVisible={!isTranslated}
          onTranslate={() => setIsTranslated(true)}
        />
        <RepetitionButtons
          isVisible={isTranslated}
          wordsCount={wordsCount}
          goToNextSlide={(period) => {
            goToNextSlide(sentence.en, period);
            setIsTranslated(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(RepetitionUserSentence);
