import React, { useState, useEffect, useRef } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { Howl, Howler } from "howler";
import { Sentence } from "../../../types/supabase";
import VStack from "../../Unknown/VStack";
import HStack from "../../Unknown/HStack";
import { Box, Button, IconButton, Slider, Typography } from "@mui/material";
import "./styles.css";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import wait from "../../../common/wait";

const AudioPlayer: React.FC<{ audioUrls: Sentence[] }> = ({ audioUrls }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1.0);
  const sound = useRef<Howl | null>(null);
  const [speed, setSpeed] = useState(50);

  useMountedWaitAsyncEffect(
    async () => {
      await wait(1000 - speed * 10);

      if (currentTrackIndex === undefined) return;

      if (sound.current) {
        sound.current.unload();
      }

      if (audioUrls[currentTrackIndex]) {
        sound.current = new Howl({
          src: [audioUrls[currentTrackIndex].en_audio_url],
          html5: true,
          onend: () => {
            if (currentTrackIndex < audioUrls.length - 1) {
              setCurrentTrackIndex(currentTrackIndex + 1);
            } else {
              setIsPlaying(false); // Stop at the end of the playlist
            }
          },
          onloaderror: (soundId, error) => {
            console.error("Error loading audio:", error);
          },
        });
        // Play the new sound if isPlaying is true
        if (isPlaying) {
          sound.current.once("load", () => sound.current?.play());
        }
      }

      return () => {
        if (sound.current) {
          sound.current.unload();
        }
      };
    },
    [currentTrackIndex, sound.current, speed],
    0,
  );

  useEffect(() => {
    if (sound.current) {
      Howler.volume(volume);

      if (isPlaying) {
        sound.current.play();
      } else {
        sound.current.pause();
      }
    }
  }, [isPlaying, volume]);

  const handlePlayPause = () => {
    if (currentTrackIndex === undefined) {
      setCurrentTrackIndex(0);
    }
    setIsPlaying(!isPlaying);
  };

  const handleNextTrack = () => {
    if (currentTrackIndex === undefined) {
      setCurrentTrackIndex(0);
    } else {
      const nextIndex = (currentTrackIndex + 1) % audioUrls.length;
      setCurrentTrackIndex(nextIndex);
    }
  };

  const handlePreviousTrack = () => {
    if (currentTrackIndex === undefined) {
      setCurrentTrackIndex(0);
    } else {
      const prevIndex = (currentTrackIndex - 1 + audioUrls.length) % audioUrls.length;
      setCurrentTrackIndex(prevIndex);
    }
  };

  const handleReset = () => {
    setCurrentTrackIndex(0);
  };

  const trackListRef = useRef<HTMLUListElement | null>(null);

  // Helper function to get CSS classes for track items
  const getTrackItemClasses = (index: number) => {
    const classes = ["track-item"];
    if (index === currentTrackIndex) {
      classes.push("active");
    } else if (currentTrackIndex !== undefined && index < currentTrackIndex) {
      classes.push("above-active");
    } else {
      classes.push("below-active");
    }
    return classes.join(" ");
  };

  useEffect(() => {
    const trackListElement = trackListRef.current;
    if (!trackListElement) return;

    const activeTrackElement: any = currentTrackIndex !== undefined && trackListElement.children[currentTrackIndex];
    if (!activeTrackElement) return;

    // Calculate scroll position to center the active track
    const trackListHeight = trackListElement.clientHeight;
    const activeTrackHeight = activeTrackElement.clientHeight;
    const scrollTop = activeTrackElement.offsetTop - trackListHeight / 2 + activeTrackHeight / 2;

    // Smoothly scroll to the calculated position
    trackListElement.scrollTo({
      top: scrollTop,
      behavior: "smooth",
    });
  }, [currentTrackIndex]);

  return (
    <VStack>
      <Box maxWidth="90%" width="100%">
        <Slider
          defaultValue={speed}
          value={speed}
          aria-label="Default"
          valueLabelDisplay="auto"
          onChange={(e, newValue) => {
            setSpeed(newValue as number);
          }}
        />
      </Box>
      <HStack mb={3}>
        <IconButton onClick={handlePreviousTrack} disabled={!audioUrls.length}>
          <SkipPreviousIcon sx={{ fontSize: 35 }} />
        </IconButton>
        <IconButton onClick={handlePlayPause} disabled={!audioUrls.length}>
          {!isPlaying ? <PlayArrowIcon sx={{ fontSize: 35 }} /> : <PauseIcon sx={{ fontSize: 35 }} />}
        </IconButton>
        <IconButton onClick={handleNextTrack} disabled={!audioUrls.length}>
          <SkipNextIcon sx={{ fontSize: 35 }} />
        </IconButton>
        <IconButton onClick={handleReset} disabled={!audioUrls.length}>
          <RestartAltIcon sx={{ fontSize: 35 }} />
        </IconButton>
      </HStack>
      <Box className="track-list" ref={trackListRef} style={{ height: "350px", overflow: "auto", width: "100%", display: "block" }}>
        {audioUrls.map((url, index) => (
          <Box key={index} fontSize={20} className={getTrackItemClasses(index)} onClick={() => setCurrentTrackIndex(index)}>
            {url.en}
          </Box>
        ))}
      </Box>
    </VStack>
  );
};

export default AudioPlayer;
