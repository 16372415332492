import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useLessonsStore } from "../../../store/lessonsStore";
import isAdmin from "../../../common/isAdmin";
import Loader from "../Loader";
import ModalCloseButton from "../ModalCloseButton";

const style = {
  position: "absolute" as "absolute",
  top: "20px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  overflow: "auto",
  height: "calc(100% - 40px)",
  width: "100%",
  maxWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SimpleModalWrap: React.FC<PropsWithChildren & { buttonLabel: string }> = ({
  buttonLabel,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box position="fixed" top={0} right={0} zIndex={2}>
            <ModalCloseButton onClose={handleClose} />
          </Box>
          <Box sx={style}>
            <Box py={4}>{children}</Box>
          </Box>
        </>
      </Modal>
      <Button variant="outlined" size="small" onClick={handleOpen} sx={{ zIndex: 2 }}>
        {buttonLabel}
      </Button>
    </>
  );
};

export default SimpleModalWrap;
