import { useLessonsStore } from "../../../../../store/lessonsStore";
import Box from "../../../Box";
import Button from "../../../Button";
import HStack from "../../../HStack";
import SimpleModalWrap from "../../../SimpleModalWrap";
import Typography from "../../../Typography";
import VStack from "../../../VStack";

const PresentContinuousTenseTitle = () => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  return (
    <>
      <Box
        width={750}
        position="absolute"
        borderBottom="1px solid rgba(224, 224, 224, 1)"
        borderRight="1px solid rgba(224, 224, 224, 1)"
        left={0}
        top={0}
        p={2}
        sx={{
          zIndex: 4,
          backgroundColor: lessonNumber === 8 ? "#deffc5" : "#fcffdb",
        }}
      >
        <Typography variant="h3">Present Continuous</Typography>
        <Typography>
          <strong>Теперішній тривалий</strong> час вказує на дію, <br /> що відбувається в момент
          промови або в даний час, <br />
          підкреслюючи тривалість дії.
        </Typography>
        <HStack gap={1}>
          <SimpleModalWrap buttonLabel="Stative verbs">
            <Box mb={5}>
              <Typography gutterBottom variant="h3" color="primary">
                Stative verbs - стативні дієслова
              </Typography>
              <Typography>
                Слова які не можуть використовуватися з <strong>-ing</strong> закінченням, тому що:
              </Typography>
              <Typography>- часто вже передають процес</Typography>
              <Typography>
                - такі слова передають почуття, емоції або стан де початок та кінець не залежить від
                нас
              </Typography>
            </Box>
            <Box mb={5}>
              <Typography gutterBottom variant="h4">
                Думки
              </Typography>
              agree, believe, doubt, forget, guess, imagine, know, mean, recognise, remember,
              suspect, think, understand recognize, support, suppose
            </Box>
            <Box mb={5}>
              <Typography gutterBottom variant="h4">
                Емоції та почуття
              </Typography>
              dislike, hate, like, love, prefer, want, wish, envy, fear, hope, need, value
            </Box>
            <Box mb={5}>
              <Typography gutterBottom variant="h4">
                Сприйняття
              </Typography>
              appear, be, feel, hear, look, see, seem, smell, taste, sense, sound
            </Box>
            <Box mb={5}>
              <Typography gutterBottom variant="h4">
                Володіння та вимірювання
              </Typography>
              belong, have, has, measure, own, possess, weigh, comprise, consist, contain, include
            </Box>
            <Box mb={5}>
              <Typography gutterBottom variant="h4">
                Інші
              </Typography>
              cost, depend, involve, have measure, owe, resemble, span
            </Box>
            <Typography gutterBottom variant="h4">
              Слова які інколи можуть бути стативними
            </Typography>
            <VStack alignItems="flex-start">
              <Box>
                <Typography>
                  I <strong>think</strong> it's a good idea.
                </Typography>
                <Typography>
                  Wait a moment! I'm <strong>thinking</strong>.
                </Typography>
              </Box>
              <Box>
                <Typography>
                  I <strong>have</strong> a ball.
                </Typography>
                <Typography>
                  I'm <strong>having</strong> a quick break.
                </Typography>
              </Box>
              <Box>
                <Typography>
                  Do you <strong>see</strong> that?
                </Typography>
                <Typography>
                  We're <strong>seeing</strong> Lisa tomorrow.
                </Typography>
              </Box>
              <Box>
                <Typography>
                  It<strong>'s</strong> so interesting!
                </Typography>
                <Typography>
                  It<strong>'s</strong> <strong>being</strong> very unhelpful.
                </Typography>
              </Box>
              <Box>
                <Typography>
                  This coffee <strong>tastes</strong> delicious.
                </Typography>
                <Typography>
                  Look! The chef is <strong>tasting</strong> the soup.
                </Typography>
              </Box>
            </VStack>
          </SimpleModalWrap>
          <SimpleModalWrap buttonLabel="Markers">
            <Box mb={5}>
              <Typography gutterBottom variant="h3" color="primary">
                Present Continuous Markers
              </Typography>
              <Typography gutterBottom>
                У лінгвістиці маркер — це вільна або зв’язана морфема, яка вказує на граматичну
                функцію позначеного слова, фрази чи речення.
              </Typography>
              <Box>
                <strong>now</strong> – зараз
                <br />
                <strong>right now</strong> – прямо зараз
                <br />
                <strong>today</strong> – сьогодні (удень)
                <br />
                <strong>tonight</strong> – сьогодні (ввечері)
                <br />
                <strong>still</strong> – все ще
                <br />
                <strong>currently</strong> – тепер, в цей час
                <br />
                <strong>at the moment</strong> – в цей момент
                <br />
                <strong>at present</strong> – в теперішній час
                <br />
                <strong>this morning</strong> – цього ранку, цим ранком
                <br />
                <strong>this evening</strong> – цього вечора
                <br />
                <strong>this afternoon</strong> – сьогодні після обіду
                <br />
                <strong>these days</strong> – на днях
                <br />
                <strong>nowadays</strong> – в наші дні
              </Box>
            </Box>
          </SimpleModalWrap>
        </HStack>
      </Box>
    </>
  );
};

export default PresentContinuousTenseTitle;
