import { Button, Container, Grid } from "@mui/material";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Box from "../../Unknown/Box";
import HarryPotterImageSrc from "./harry-potter-image.jpg";
import Logo from "../../Unknown/Logo";
import { SideBarNavigationButton } from "../../Unknown/Navigation";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import supabaseClient from "../../../common/supabaseClient";

const Reading = () => {
  const params = useLocation();
  const userWords = useLessonsStore((state) => state.userWords);
  const setUserWords = useLessonsStore((state) => state.setUserWords);
  const authUser = useLessonsStore((state) => state.authUser);

  useMountedWaitAsyncEffect(async () => {
    if (!userWords.length && authUser) {
      const { data } = await supabaseClient.rpc("get_all_words_by_user_id", {
        user_id: authUser.uuid,
      });

      if (data) setUserWords(data.filter(Boolean));
    }
  }, [userWords, authUser]);

  return (
    <Container>
      <Box>
        <Button to="/" component={Link}>
          {/* <Logo fontSize={20} isAccentWhite /> */}
          <Grid container alignItems="center">
            <Grid item>
              <Logo fontSize={20} />
            </Grid>
            <Grid item container>
              {params.pathname !== "/reading" && (
                <Grid item>
                  <Link to="/reading">Всі книги</Link>
                </Grid>
              )}
              {params.pathname !== "/reading/harry-potter" &&
                params.pathname.startsWith("/reading/harry-potter") && (
                  <Grid item>
                    {" "}
                    <Link to="/reading/harry-potter">
                      Всі книги Harry Potter
                    </Link>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Button>
      </Box>
      {params.pathname === "/reading" && (
        <Box py={5} display="flex" justifyContent="center">
          <Link to="/reading/harry-potter">
            <Box
              component="img"
              sx={{
                display: "inline-block",
                objectFit: "contained",
                width: "100%",
                height: "100%",
                maxWidth: "200px",
              }}
              src={HarryPotterImageSrc}
            />
          </Link>
        </Box>
      )}
      <Box>
        <Outlet />
      </Box>
    </Container>
  );
};

export default Reading;
