const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "o",
  "p",
  "r",
  "s",
  "t",
  "y",
  "x",
  "z",
  "w",
  "v",
];

const getRandomLetter = (filterLetters?: string[]) => {
  if (filterLetters) {
    const filteredLetters = letters.filter((l) => !filterLetters.includes(l));
    const random = filteredLetters[Math.floor(Math.random() * filteredLetters.length)];

    return random;
  }

  const random = letters[Math.floor(Math.random() * letters.length)];

  return random;
};

export default getRandomLetter;
