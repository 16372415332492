import { Box, Button, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import useIsInViewport from "../../../hooks/useIsInViewport";
import { ExtendedUserWord } from "../../../types/supabase";
import EnglishAudioSentence from "../EnglishAudioSentence";
import Hint, { HintType } from "../Hint";
import RepetitionButtons from "../RepetitionButtons";
import TranslateButton from "../TranslateButton";
import UkrainianWord from "../UkrainianWord";
import ExampleItem from "../../Reading/ExampleItem";
import isAdmin from "../../../common/isAdmin";
import CopyToClipboard from "react-copy-to-clipboard";
import useLessonWords from "../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../store/lessonsStore";

interface RepetitionUserWordProps {
  word: ExtendedUserWord;
  wordsCount: number;
  reversed?: boolean;
  goToNextSlide: (word: string, period: string) => void;
}

const RepetitionUserWord = ({
  word,
  wordsCount,
  reversed,
  goToNextSlide,
}: RepetitionUserWordProps) => {
  const [isTranslated, setIsTranslated] = useState(false);
  const ref = useRef<HTMLElement>();
  const isInViewport = useIsInViewport(ref);
  const authUser = useLessonsStore((state) => state.authUser);

  const resetIsTranslatedAfterSlideChange = () => {
    setTimeout(() => {
      setIsTranslated(false);
    }, 500);
  };

  useEffect(() => {
    if (!isInViewport) resetIsTranslatedAfterSlideChange();
  }, [isInViewport]);

  if (reversed) {
    return (
      <Box
        pt={0}
        pb={10}
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="calc(100vh - 200px)"
      >
        <Box
          gap={3}
          width="100%"
          display="flex"
          position="relative"
          textAlign="center"
          alignItems="center"
          flexDirection="column"
          pt={0}
        >
          {/* <Hint text={word.word} type={HintType.word} /> */}

          {/* center box to detect i in viewport */}
          <Box ref={ref} width={10} height={10} />
          {/* <UkrainianWord translation={word.word} /> */}
          {/* <EnglishAudioSentence sentence={word.word} audioUrl={word.words.audio_url} transcription={word.words.transcription} isVisible /> */}
          {
            <>
              {word.words.examples.map((example) => {
                return (
                  <Box>
                    <ExampleItem en={example.en} ua={""} highlight={word.word} />
                  </Box>
                );
              })}
            </>
          }

          <EnglishAudioSentence
            sentence={""}
            audioUrl={word.words.audio_url}
            transcription={word.words.transcription}
            isVisible={isTranslated}
          />

          <UkrainianWord translation={word.words.translation} isVisible={isTranslated} />
          <TranslateButton isVisible={!isTranslated} onTranslate={() => setIsTranslated(true)} />
          <RepetitionButtons
            isVisible={isTranslated}
            wordsCount={wordsCount}
            goToNextSlide={(period) => {
              goToNextSlide(word.word, period);
              setIsTranslated(false);
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      pt={0}
      pb={10}
      display="flex"
      alignItems="center"
      position="relative"
      minHeight="calc(100vh - 200px)"
    >
      <Box
        gap={3}
        width="100%"
        display="flex"
        position="relative"
        textAlign="center"
        alignItems="center"
        flexDirection="column"
        pt={0}
      >
        {/* <Hint text={word.word} type={HintType.word} /> */}
        {/* center box to detect i in viewport */}
        {isAdmin(authUser?.id) && (
          <CopyToClipboard
            text={`
        English word: ${word.word}
        Give me all ukrainian translations in sentences.
        Наприклад візьмемо слово "drink", це слово має два переклади, тоді мені потрібен подібна відповідь:
      
        [
          {
           ua: "Я люблю 'пити' помаранчевий сік.",
           en: "I like to drink orange juice"
          },
          {
           ua: "Ти хочеш 'напій'?",
           en: "Do you want a drink?"
          }
        ]

        
        Для кожного значення слова (всі можливі заначення) яке я дав дай мені по одному реченню. Саме слово переклад у реченні повинно бути взято в одинарні лапки.

        en: повинно містити слово яке я дав. Також речення повинно бути природнім та граматично правильним
        ua: переклад en речення
        
        Відповідь у JSON форматі: {ua: string, en: string}[]

        Провірь двічі свою відповідь. Всі речення граматично правильні? Всі речення природні? Ти знайшов учі можливі переклади даного слова?
        
      `}
          >
            <Button variant="outlined">Copy</Button>
          </CopyToClipboard>
        )}
        <Box ref={ref} width={10} height={10} />
        {word.words.ua_examples && (
          <Box>
            {word.words.ua_examples.map((e) => {
              const regex = /\'([^)]+)\'/g;
              const matches = e.ua.match(regex);
              if (!matches) return null;

              const [match] = matches;
              const parts = ` ${e.ua} `.split(match);

              return (
                <Box>
                  {parts.map((part, index) => {
                    const returnParts = [<Typography component="span">{part.trim()}</Typography>];

                    if (parts.length - 1 !== index) {
                      returnParts.push(
                        <Typography component="span" color="primary">{` ${match.slice(
                          1,
                          match.length - 1,
                        )} `}</Typography>,
                      );
                    }

                    return returnParts;
                  })}
                </Box>
              );
              // return <Typography>{e.ua}</Typography>;
            })}
          </Box>
        )}
        <UkrainianWord translation={word.words.translation} />

        <EnglishAudioSentence
          sentence={word.word}
          audioUrl={word.words.audio_url}
          transcription={word.words.transcription}
          isVisible={isTranslated}
        />
        <TranslateButton isVisible={!isTranslated} onTranslate={() => setIsTranslated(true)} />
        <RepetitionButtons
          isVisible={isTranslated}
          wordsCount={wordsCount}
          goToNextSlide={(period) => {
            goToNextSlide(word.word, period);
            setIsTranslated(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(RepetitionUserWord);
