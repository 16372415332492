import supabaseClient from "../../common/supabaseClient";
import WordAudio from "../Audio/WordAudio";
import Box from "../Unknown/Box";
import Button from "../Unknown/Button";
import HStack from "../Unknown/HStack";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

const AudioAndBelongsToUserStar: React.FC<{ text: string; audio: string; belongsToUser: boolean }> = ({ audio, text, belongsToUser }) => {
  return (
    <>
      <HStack gap={3} position="relative" alignItems="center" justifyContent="center">
        <Box display="inline-block" pr={2}>
          <WordAudio url={audio} small autoPlay={true} />
        </Box>
        <Button
          sx={{
            position: "absolute",
            top: "calc(50% - 15px)",
            left: "calc(50% + 10px)",
            pointerEvents: belongsToUser ? "none" : "auto",
          }}
          onClick={async () => {
            if (!belongsToUser) {
              const result = await supabaseClient.rpc("add_user_sentence", {
                sentence: text,
              });
            }
          }}
        >
          {belongsToUser ? (
            <StarIcon
              sx={{
                fontSize: 20,
              }}
            />
          ) : (
            <StarBorderIcon
              sx={{
                fontSize: 20,
              }}
            />
          )}
        </Button>
      </HStack>
    </>
  );
};

export default AudioAndBelongsToUserStar;
