import { Box, Button, Link as MuiLink, Typography } from "@mui/material";
import { addDays } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import MenuLayout from "../../Layout/MenuLayout";
import RepetitionUserWord from "../../Sentence/RepetitionUserWord";
import Loader from "../../Unknown/Loader";
import VStack from "../../Unknown/VStack";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";
import {
  BanglaSentence,
  EnglishQuote,
  FunctionGetWordsWithAdditionalReturn,
  HoverDetailsWords,
  Module,
  Sentence,
  Word,
} from "../../../types/supabase";
import SentencePazleItem from "../../Sentence/SentencePazleItem";
import RowSentencePazleItem from "../../Sentence/RowSentencePazleItem";
import { Link, useParams } from "react-router-dom";
import HoverDetails from "../../Word/HoverDetails";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import getRandomGreetings from "../../../common/getRandomGreetings";
import HStack from "../../Unknown/HStack";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import WordAudio from "../../Audio/WordAudio";

const Quote: React.FC<{ quote: EnglishQuote }> = ({ quote }) => {
  const [isTranslated, setIsTranslated] = useState(false);

  const [translation, ...description] = useMemo(() => {
    return quote.description
      .split("\n")
      .map((l) => l.trim())
      .filter(Boolean);
  }, [quote]);

  return (
    <VStack alignItems="flex-start">
      <VStack alignItems="flex-start">
        <WordAudio url={quote.audio} />
        <Typography color="secondary.dark" fontWeight={700}>
          {" "}
          {quote.id}
        </Typography>
      </VStack>
      {isTranslated && (
        <Box>
          <Typography color="#a77301">{translation}</Typography>
          <br />
          {description.map((d) => {
            return (
              <Typography key={d} gutterBottom color="secondary.dark">
                {d}
              </Typography>
            );
          })}
        </Box>
      )}
      {!isTranslated && (
        <Button
          variant="outlined"
          onClick={() => {
            setIsTranslated(true);
          }}
        >
          Перекласти
        </Button>
      )}
    </VStack>
  );
};

const Quotes: React.FC<{ quotes: EnglishQuote[] }> = ({ quotes }) => {
  return (
    <>
      {quotes.map((quote) => {
        return (
          <Box mb={3}>
            {" "}
            <Quote key={quote.id} quote={quote} />
          </Box>
        );
      })}
      {/* <Link html */}
      {/* https://www.youtube.com/watch?v=d-diB65scQU */}
    </>
  );
};

export default Quotes;
