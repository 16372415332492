import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/Unknown/App";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reportWebVitals from "./reportWebVitals";

import { FirebaseAppProvider } from "reactfire";

import "intro.js/introjs.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const firebaseConfig = {
  apiKey: "AIzaSyAUV7t8S0rH2SalW0ZhHZtkIG04INpqDfI",
  authDomain: "leeearning.firebaseapp.com",
  projectId: "leeearning",
  storageBucket: "leeearning.appspot.com",
  messagingSenderId: "312990922161",
  appId: "1:312990922161:web:f158a980d454f6a2c43990",
  measurementId: "G-DM8EF1PB58",
  databaseURL:
    "https://leeearning-default-rtdb.europe-west1.firebasedatabase.app",
};

// const app = firebase.initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
