import { memo } from "react";
import { Difference } from "./Difference";

export const esistingLessons = [
  "it-this",
  "do-work-job",
  "receive-get",
  "big-large-great",
  "bad-badly",
];

export default memo(Difference);
